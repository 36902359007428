import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import { validate, validators } from 'validate-redux-form';
import NavbarModulo from '../components/NavbarModulo';
import {
  renderNumber,
  renderField,
  SelectField
} from "../Utils/renderField/renderField";
import moment from 'moment';

const meses = [
  {
    value: 'ENERO',
    label: 'ENERO'
  },
  {
    value: 'FEBRERO',
    label: 'FEBRERO'
  },
  {
    value: 'MARZO',
    label: 'MARZO'
  },
  {
    value: 'ABRIL',
    label: 'ABRIL'
  },
  {
    value: 'MAYO',
    label: 'MAYO'
  },
  {
    value: 'JUNIO',
    label: 'JUNIO'
  },
  {
    value: 'JULIO',
    label: 'JULIO'
  },
  {
    value: 'AGOSTO',
    label: 'AGOSTO'
  },
  {
    value: 'SEPTIEMBRE',
    label: 'SEPTIEMBRE'
  },
  {
    value: 'OCTUBRE',
    label: 'OCTUBRE'
  },
  {
    value: 'NOVIEMBRE',
    label: 'NOVIEMBRE'
  },
  {
    value: 'DICIEMBRE',
    label: 'DICIEMBRE'
  },
];

const estado = [
  { label: "ABIERTO", value: "A" },
  { label: "CERRADO", value: "C" },
];


class PeriodosForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      mes: '0',
      fechaI: '00/00/0000',
      fechaF: '00/00/0000',
    };
    this.handleChange = this.handleChange.bind(this);
    this.fechaMes = this.fechaMes.bind(this);
  }

  handleChange(event) {
    this.setState({ mes: this.renderSwitch(event.target.value) });
    this.setState({ value: event.target.value });

  }

  renderSwitch(param) {
    switch (param) {
      case 'ENERO':
        return '01';
      case 'FEBRERO':
        return '02';
      case 'MARZO':
        return '03';
      case 'ABRIL':
        return '04';
      case 'MAYO':
        return '05';
      case 'JUNIO':
        return '06';
      case 'JULIO':
        return '07';
      case 'AGOSTO':
        return '08';
      case 'SEPTIEMBRE':
        return '09';
      case 'OCTUBRE':
        return '10';
      case 'NOVIEMBRE':
        return '11';
      case 'DICIEMBRE':
        return '12';

      default:
        return 'Seleccione un mes';
    }
  }

  fechaMes(mes, anio) {
    if (anio.length === 4) {
      let fec = anio + '-' + mes;
      let firstdate = moment(fec).startOf('month').format('DD/MM/YYYY');
      let lastdate = moment(fec).endOf('month').format("DD/MM/YYYY");
      this.setState({
        fechaI: firstdate,
        fechaF: lastdate
      });
    }
    if (anio.length === 0) {
      this.setState({
        fechaI: '00/00/0000',
        fechaF: '00/00/0000'
      });
    }
  }


  render() {
    const { handleSubmit, crear, lectura, modulo } = this.props;
    const editar = window.location.href.includes('editar');
    let titulo = editar ? 'Modificar Periodo' : 'Crear Periodo';
    let disabled = false;
    if (crear === false && editar === false) {
      disabled = true;
      titulo = 'Ver Periodo'
    }
    if (editar === true) {
      disabled = true;
    }
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    return (
      <div>
        <NavbarModulo
          modulo={modulo}
          titulo={titulo}
        />
        <form onSubmit={handleSubmit}>
          <br />
          <div className="mb-4 card card-small text-white bg-dark mb-3">
            <div className="p-0 pt-3 d-flex flex-column flex-md-row">
              <div className="d-flex flex-column flex-1 mx-4">
                <div className='mb-2 w-25'>
                  {disabled ?
                    <Field
                      name="mes"
                      options={meses}
                      crear={false}
                      dato={lectura.mes}
                      component={SelectField}
                      className="form-select"
                      disabled={disabled}
                      msj="Mes"
                    />
                    :
                    <div className="form-floating">
                      <select className="form-select" id="mes" name="mes"
                        value={this.state.value} onChange={this.handleChange} >
                        <option selected>Seleccionar...</option>
                        {meses.map(
                          mes => <option key={mes.value} value={mes.value}
                          >{mes.label}
                          </option>)
                        }
                      </select>
                      <label className='form-label' htmlFor="mes">Mes</label>
                    </div>
                  }
                </div>
                <div className='row mb-2 w-50'>
                  <div className='col-6'>
                    <Field
                      id="anio"
                      name="anio"
                      placeholder="Año"
                      className="form-control"
                      disabled={disabled}
                      component={renderNumber}
                      onChange={(e, value) => {
                        this.fechaMes(this.state.mes, value)
                        localStorage.setItem('periodo', JSON.stringify(this.state));
                      }}
                      msj="Año"
                    />
                  </div>
                  <div className='col-6'>
                    <div className="form-floating">
                      <Field
                        name="estado"
                        options={estado}
                        crear={crear}
                        dato={crear === true ? 'A' : lectura.estado}
                        component={SelectField}
                        className="form-select"
                        disabled={(disabled == false || editar === true) ? false : true}
                        msj="Estado"
                      />
                    </div>
                  </div>
                </div>
                <div className='row mb-2 w-50'>
                  <div className='col-6'>
                    {disabled ?
                      <Field
                        id="fecI"
                        name="fecha_inicial"
                        placeholder="Fecha Inicial"
                        component={renderField}
                        className="form-control"
                        disabled={disabled}
                        msj="Fecha Inicial"
                      />
                      :
                      <div className="form-floating">
                        <input
                          id="fecI"
                          type="text"
                          name="fecha_inicial"
                          className="form-control"
                          value={this.state.fechaI}
                        />
                        <label className='form-label' htmlFor="fecI">
                          Fecha Inicial
                        </label>
                      </div>
                    }
                  </div>
                  <div className='col-6'>
                    {disabled ?
                      <Field
                        id="fecF"
                        name="fecha_fin"
                        placeholder="Fecha Final"
                        component={renderField}
                        className="form-control"
                        disabled={disabled}
                        msj="Fecha Final"
                      />
                      :
                      <div className="form-floating">
                        <input
                          id="fecF"
                          type="text"
                          name="fecha_fin"
                          className="form-control"
                          value={this.state.fechaF}
                        />
                        <label className='form-label' htmlFor="fecF">
                          Fecha Final
                        </label>
                      </div>
                    }
                  </div>
                </div>
                <div className='d-flex flex-row mt-3'>
                  {(disabled == false || editar === true) &&
                    <button
                      className={`btn mr-2 mb-3 ${editar ? 'btn-primary' : 'btn-primary'}`}
                      type='submit'
                    >
                      {editar ? 'Modificar' : 'Guardar'}
                    </button>
                  }
                  {id_emp[4] === 'produccion' &&
                    <a
                      href={`/#/produccion/${id_emp[5]}/periodos`}
                      className='btn btn-secondary mb-3'
                    >
                      Regresar
                    </a>
                  }
                  {id_emp[4] === 'inventario' &&
                    <a
                      href={`/#/inventario/${id_emp[5]}/periodos`}
                      className='btn btn-secondary mb-3'
                    >
                      Regresar
                    </a>
                  }
                  {id_emp[4] === 'facturacion' &&
                    <a
                      href={`/#/facturacion/${id_emp[5]}/periodos`}
                      className='btn btn-secondary mb-3'
                    >
                      Regresar
                    </a>
                  }
                  {id_emp[4] === 'bancos' &&
                    <a
                      href={`/#/bancos/${id_emp[5]}/periodos`}
                      className='btn btn-secondary mb-3'
                    >
                      Regresar
                    </a>
                  }
                  {id_emp[4] === 'compras' &&
                    <a
                      href={`/#/compras/${id_emp[5]}/periodos`}
                      className='btn btn-secondary mb-3'
                    >
                      Regresar
                    </a>
                  }
                  {id_emp[4] === 'contabilidad' &&
                    <a
                      href={`/#/contabilidad/${id_emp[5]}/periodos`}
                      className='btn btn-secondary mb-3'
                    >
                      Regresar
                    </a>
                  }
                  {id_emp[4] === 'ajustes' &&
                    <a
                      href={`/#/ajustes/${id_emp[5]}/periodos`}
                      className='btn btn-secondary mb-3'
                    >
                      Regresar
                    </a>
                  }
                  {id_emp[4] === 'nomina' &&
                    <a
                      href={`/#/nomina/${id_emp[5]}/periodos`}
                      className='btn btn-secondary mb-3'
                    >
                      Regresar
                    </a>
                  }
                  {id_emp[4] === 'visitador_medico' &&
                    <a
                      href={`/#/visitador_medico/${id_emp[5]}/periodos`}
                      className='btn btn-secondary mb-3'
                    >
                      Regresar
                    </a>
                  }
                  {id_emp[4] === 'punto_venta' &&
                    <a
                      href={`/#/punto_venta/${id_emp[5]}/periodos`}
                      className='btn btn-secondary mb-3'
                    >
                      Regresar
                    </a>
                  }
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default reduxForm({
  form: 'FormPeriodos',
  validate: (data) => {
    return validate(data, {
      anio: validators.exists()('Este campo es requerido'),
      estado: validators.exists()('Seleccione estado del periodo'),
    });
  },

})(PeriodosForm);
