import React, { Component } from 'react';
import Grid from '../../Utils/Grid';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';
import { standardActions } from '../../Utils/Grid/StandardActions';

import {
    renderCurrencyFloat
} from "../../Utils/renderField/renderField";

class ConfigCuentasList extends Component{
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false
    };

    componentDidMount = () => {
        const { listarImpuestos, page } = this.props;
        listarImpuestos(page);
    }
    
    
    render(){
        const { 
            impuesto, 
            loader, 
            listarImpuestos: onPageChange, 
            registroImpuesto
        } = this.props;
        
        let ruta = window.location.href;
        let id_emp = ruta.split('/');
        return(
            <React.Fragment>
                <br />
                <h3 className="p-2 bg-secondary title">Impuestos</h3>
                <div className="row mb-2">
                    <div className='col-3'>
                        <Field
                            name="porcentaje_iva"
                            placeholder="Porcentaje iva"
                            className="form-control"
                            component={renderCurrencyFloat}
                            msj="Porcentaje iva"
                        />
                    </div>
                    <div className='col-3'>
                        <Field
                            name="costo_galon_super"
                            placeholder="Galón super"
                            className="form-control"
                            component={renderCurrencyFloat}
                            msj="Galón super"
                        />
                    </div>
                    <div className='col-3'>
                        <Field
                            name="costo_galon_regular"
                            placeholder="Galón regular"
                            className="form-control"
                            component={renderCurrencyFloat}
                            msj="Galón regular"
                        />
                    </div>
                    <div className='col-3'>
                        <Field
                            name="costo_galon_diesel"
                            placeholder="Galón diesel"
                            className="form-control"
                            component={renderCurrencyFloat}
                            msj="Galón diesel"
                        />
                    </div>
                </div>    
                <button
                    className='btn mr-2 mb-3 btn-primary'
                    onClick={registroImpuesto}
                >
                    Guardar nuevo impuesto
                </button>
                <a
                    href = {`/#/ajustes/${id_emp[5]}`}
                    className='btn btn-secondary mb-3'
                >
                Regresar
                </a>
                {impuesto &&
                    <Grid 
                        hover 
                        striped 
                        data={impuesto} 
                        loading={loader} 
                        headerStyle={ { background: 'black' } } 
                        onPageChange={onPageChange}
                        //onSortChange={onSortChange} 
                    >
                        <TableHeaderColumn
                            isKey
                            dataAlign='right'
                            headerAlign="center"
                            dataField="porcentaje_iva"
                            //width="150"
                            dataFormat={(cell, row)=>{
                                if (parseFloat(cell) === 0.00){
                                    return ''
                                }else{
                                    return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(cell);
                                }
                            }}
                            dataSort
                            thStyle={ 
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Porcentaje iva
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataAlign='right'
                            headerAlign="center"
                            dataField="costo_galon_super"
                            dataFormat={(cell, row)=>{
                                if (parseFloat(cell) === 0.00){
                                    return ''
                                }else{
                                    return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(cell);
                                }
                            }}
                            dataSort
                            thStyle={ 
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Impuesto galón super
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataAlign='right'
                            headerAlign="center"
                            dataField="costo_galon_regular"
                            dataFormat={(cell, row)=>{
                                if (parseFloat(cell) === 0.00){
                                    return ''
                                }else{
                                    return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(cell);
                                }
                            }}
                            dataSort
                            thStyle={ 
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Impuesto galón regular
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataAlign='right'
                            headerAlign="center"
                            dataField="costo_galon_diesel"
                            dataFormat={(cell, row)=>{
                                if (parseFloat(cell) === 0.00){
                                    return ''
                                }else{
                                    return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(cell);
                                }
                            }}
                            dataSort
                            thStyle={ 
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Impuesto galón diesel
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            thStyle={
                            {color: 'white'}}
                            dataField="id"
                            width="180"
                            dataAlign="center"
                            dataSort
                            dataFormat={standardActions({ 
                                editar_imp: "impuestos", 
                            })} 
                            
                        >
                            Acciones
                        </TableHeaderColumn>
                    </Grid>
                }
            </React.Fragment>
        );
    }
}
export default reduxForm({
    form: 'FormImpuestoEmpresa',
})(ConfigCuentasList );
