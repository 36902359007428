import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import { validar } from "../../../common/components/components/ValidarNit";
import Swal from 'sweetalert2';

const LISTADO_CLIENTE = 'LISTADO_CLIENTE';
const GUARDAR_CLIENTE = 'GUARDAR_CLIENTE';
const LISTADO_PAISES = 'LISTADO_PAISES';
const LOADER = 'LOADER';
const PARAMETRO = 'PARAMETRO';
const MODULO = 'MODULO';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    //const params = { page, id };
    const params = { id };
    dispatch(setLoader(true));
    api.get('/cliente', params).then((response)=>{
        dispatch({ type: LISTADO_CLIENTE, data: response });
        
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar clientes',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const registroCliente = (datos) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormCliente.values;

    if (datos.validar_nit === 'S' && data.tipo_cliente === 'L'){
        if (data.identificadorCli === 'CF'){
            const formData = {
                identificador: data.identificadorCli,
                nombre: data.nombre,
                direccion: data.direccionCli,
                telefono: data.telefonoCli,
                correo: data.correo,
                limite: data.limite_credito,
                tipo_cliente: data.tipo_cliente,
                dias_credito: data.dias_credito,
                pais: data.pais,
                modulo: 'FAC',
                codigo_productor: data.codigo_productor,
                listado_precios: datos.listado_precios === 'S' ? data.cliente_listado_precio.value : '',
                empresa: id_emp[5]
            }
            dispatch(setLoader(true));
            api.post('/cliente/', formData).then(() => {
                NotificationManager.success(
                    'Cliente creado',
                    'Exito',
                    3000
                );
                let ruta = `/facturacion/${id_emp[5]}/clientes`
                dispatch(push(ruta));
            }).catch((error) => {
                NotificationManager.error(
                    error.msj,
                    'Error',
                    2000
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }else{
            let valid = validar.valNit(data.identificadorCli);
            if (valid === false){
                Swal.fire({
                    title: 'Cliente',
                    text: 'Ingrese Nit válido',
                    type: 'error',
                })
            }else{
                const formData = {
                    identificador: data.identificadorCli,
                    nombre: data.nombre,
                    direccion: data.direccionCli,
                    telefono: data.telefonoCli,
                    correo: data.correo,
                    limite: data.limite_credito,
                    tipo_cliente: data.tipo_cliente,
                    dias_credito: data.dias_credito,
                    pais: data.pais,
                    codigo_productor: data.codigo_productor,
                    modulo: 'FAC',
                    listado_precios: datos.listado_precios === 'S' ? data.cliente_listado_precio.value : '',
                    empresa: id_emp[5]
                }
                dispatch(setLoader(true));
                api.post('/cliente/', formData).then(() => {
                    NotificationManager.success(
                        'Cliente creado',
                        'Exito',
                        3000
                    );
                    let ruta = `/facturacion/${id_emp[5]}/clientes`
                    dispatch(push(ruta));
                }).catch((error) => {
                    NotificationManager.error(
                        error.msj,
                        'Error',
                        2000
                    );
                }).finally(() => {
                    dispatch(setLoader(false));
                });
            }
        }
    }
    if (datos.validar_dpi === 'S' && data.tipo_cliente === 'P'){
        let valid = validar.valCui(data.identificadorCli);
        if (valid[0].validar === false){
            Swal.fire({
                title: 'Cliente',
                text: 'DPI con formato inválido',
                type: 'error',
            })
        }else{
            const formData = {
                identificador: data.identificadorCli,
                nombre: data.nombre,
                direccion: data.direccionCli,
                telefono: data.telefonoCli,
                correo: data.correo,
                limite: data.limite_credito,
                tipo_cliente: data.tipo_cliente,
                dias_credito: data.dias_credito,
                pais: data.pais,
                modulo: 'FAC',
                listado_precios: datos.listado_precios === 'S' ? data.cliente_listado_precio.value : '',
                empresa: id_emp[5]
            }
            dispatch(setLoader(true));
            api.post('/cliente/', formData).then(() => {
                NotificationManager.success(
                    'Cliente creado',
                    'Exito',
                    3000
                );
                let ruta = `/facturacion/${id_emp[5]}/clientes`
                dispatch(push(ruta));
            }).catch((error) => {
                NotificationManager.error(
                    error.msj,
                    'Error',
                    2000
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }
    }    
    if (datos.validar_nit === 'S' && data.tipo_cliente === 'E'){
        const formData = {
            identificador: data.identificadorCli,
            nombre: data.nombre,
            direccion: data.direccionCli,
            telefono: data.telefonoCli,
            correo: data.correo,
            limite: data.limite_credito,
            tipo_cliente: data.tipo_cliente,
            dias_credito: data.dias_credito,
            pais: data.pais,
            modulo: 'FAC',
            codigo_productor: data.codigo_productor,
            listado_precios: datos.listado_precios === 'S' ? data.cliente_listado_precio.value : '',
            empresa: id_emp[5]
        }
        dispatch(setLoader(true));
        api.post('/cliente/', formData).then(() => {
            NotificationManager.success(
                'Cliente creado',
                'Exito',
                3000
            );
            let ruta = `/facturacion/${id_emp[5]}/clientes`
            dispatch(push(ruta));
        }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                2000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
    if (datos.validar_nit === 'N' && data.tipo_cliente === 'E'){
        const formData = {
            identificador: data.identificadorCli,
            nombre: data.nombre,
            direccion: data.direccionCli,
            telefono: data.telefonoCli,
            correo: data.correo,
            limite: data.limite_credito,
            tipo_cliente: data.tipo_cliente,
            dias_credito: data.dias_credito,
            pais: data.pais,
            modulo: 'FAC',
            codigo_productor: data.codigo_productor,
            listado_precios: datos.listado_precios === 'S' ? data.cliente_listado_precio.value : '',
            empresa: id_emp[5]
        }
        dispatch(setLoader(true));
        api.post('/cliente/', formData).then(() => {
            NotificationManager.success(
                'Cliente creado',
                'Exito',
                3000
            );
            let ruta = `/facturacion/${id_emp[5]}/clientes`
            dispatch(push(ruta));
        }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                2000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
}           

export const modificarCliente = (parametros) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const datos = getStore().form.FormCliente.values;
    
    let listado = datos.cliente_listado_precios;
    if (listado === undefined){
        listado = datos.listado_precios_ID;
    }else{
        listado = datos.cliente_listado_precios.value;
    }
    const id = datos.id;
    const formData = {
        id: id,
        identificador: datos.identificadorCli,
        nombre: datos.nombre,
        tipo_cliente: datos.tipo_cliente,
        direccion: datos.direccionCli,
        telefono: datos.telefonoCli,
        correo: datos.correo,
        limite: datos.limite_credito,
        dias_credito: datos.dias_credito,
        activo: datos.activo,
        pais: datos.pais,
        codigo_productor: parametros.agricola === 'S' ? datos.codigo_productor : '',
        listado_precios: parametros.listado_precios === 'S' ? listado : '',
        empresa: id_emp[5]
    }
    dispatch(setLoader(true));
    api.put(`/cliente/${id}/`, formData).then(() => {
        NotificationManager.success(
            'Cliente modificado correctamente',
            'Exito',
            3000
        );
        let ruta = `/facturacion/${id_emp[5]}/clientes`
        dispatch(push(ruta));
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al modificar cliente',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/cliente/${id}`).then((response) => {
        response.identificadorCli = response.identificador
        response.direccionCli = response.direccion
        response.telefonoCli = response.telefono
        dispatch({type: GUARDAR_CLIENTE, lectura: response });
        dispatch(initializeForm('FormCliente', response ));
    }).catch((error)=>{
        
        NotificationManager.error(
            'Ocurrió un error al consultar cliente',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

const listarPaises = () => (dispatch) => {
    return api.get("/paises").then(response=>{
        const pais = [];
        response.results.forEach(item=>{
            pais.push({
                value: item.id,
                label: item.pais
            })
        })
        dispatch({ type: LISTADO_PAISES, listaP: pais });
    }).catch(error=>{
        
        NotificationManager.error(
            'Ocurrió un error al listar paises',
            'Error',
            0
        );
    })
}

export const parametrosEmpresa = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let params = {id}
    dispatch(setLoader(true));
    api.get('/empresa/empresa_parametros', params).then((response) => {
        dispatch({type: PARAMETRO, datos: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar parametros',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const listarListadoPrecios = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id, search}
    return api.get('/listado_precios/empresa_listado_precios',params).then(data=>{
        const list = [];
        if(data){
            data.forEach(item=>{
                list.push({
                    value: item.id,
                    label: item.nombre_lista
                })
            })
        }
        return list ;
    }).catch((error)=>{
        
        return [];
    })
}

export const actions = {
    listar,
    registroCliente,
    modificarCliente,
    listarPaises,
    leer,
    getModulo,
    parametrosEmpresa,
    listarListadoPrecios
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_CLIENTE]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [LISTADO_PAISES]: (state, { listaP }) => {
        return {
            ...state,
            listaP,
        };
    },
    [GUARDAR_CLIENTE]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
    [PARAMETRO]: (state, { datos }) => {
        return {
            ...state,
            datos,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
   
};

export const initialState = {
    loader: false,
    lectura: [],
    listaP: [],
    datos: [],
    modulo: [],
    data: [],
    page: 1,
};

export default handleActions(reducers, initialState)