import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from '../../components/Modal';
import PropTypes from 'prop-types';

function FormSeleccionarPeriodo(props) {
    const {
        periodoL,
        calcularCosto,
        leerPeriodoD,
        periodoD
    } = props;
    
    const [id, setId] = useState("");
    
    const handleChange = e => {
        setId(e.target.value);
        leerPeriodoD(e.target.value);
    }
   
    const [estadoModal, cambiarEstadoModal] = useState(true);
    let ruta = window.location.href;
    return (
        <div className='container'>
            <Modal
                estado={estadoModal}
                cambiarEstado={cambiarEstadoModal}
                titulo="Calcular costo"
                botonCerrar="false"
            >
                {periodoL && 
                    <div className="form-floating">
                        <select className="form-select" id="periodo"
                            value={id} onChange={handleChange} >
                            <option selected>Seleccionar...</option>
                            {periodoL.map(mes =>
                                <option key={mes.value} value={mes.value}>{mes.label}</option>)
                            }

                        </select>
                        <label className='form-label' htmlfor="periodo">Periodo</label>
                    </div>
                }
                <br />
                <Contenido>
                    {periodoL.length > 0 && periodoD.estado == 'A' &&
                        (id &&
                            <button
                                className='btn mr-2 mb-3 btn-primary'
                                onClick={() => calcularCosto(id, ruta)}
                            >
                                Calcular costo
                            </button>
                        )
                    }
                    {periodoL.length > 0 && periodoD.estado == 'C' &&
                        <div>
                            <h4 className='title'>Periodo se encuentra cerrado</h4>
                        </div>
                    }
                </Contenido>
            </Modal>
        </div>
    );

}
FormSeleccionarPeriodo.propTypes = {
    periodoL: PropTypes.string.isRequired,
    listaEs: PropTypes.string.isRequired
};

export default (FormSeleccionarPeriodo);

const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;