import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import Modal from '../../../components/ModalB'
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import DetalleInventario from './DetalleInventario';
import DetalleInventarioVer from './DetalleInventarioVer';

import {
    renderField,
    renderDayPickerB,
    SelectField,
    AsyncSelectField,
    AsyncSelectFieldIn,
    renderNumber,
    renderCurrencyFloat,
    renderCurrencyFloatTC
} from "../../../Utils/renderField/renderField";

function FacturacionInventarioForm(props) {
    const {
        crear,
        lectura,
        bloqueo,
        editar,
        tipos,
        listarClientes,
        registroCliente,
        obtenerPaises,
        datos,
        listarBodegas,
        detalleProducto,
        eliminarLineaProducto,
        leerProductos,
        estable,
        pro,
        registroProductoInventario,
        periodoD,
        monedas,
    } = props;

    let autoFocus = true;
    
    const [total, setTotal] = useState(0.0);
    const [tipoD, setTipoD] = useState(0);
    const [editarT, setEditarT] = useState(editar);

    const [estadoModal, cambiarEstadoModal] = useState(false);
    const [cantidad, setCantidad] = useState(0.00);

    let ocultar = false;

    const handleChange = (e) => {
        localStorage.setItem('tipoDocumentoFacIn', e.target.value);
        setEditarT(false);
        setTipoD(e.target.value);
    }

    const tipoF = [
        { label: "LOCAL", value: "LOC" },
        { label: "EXPORTACION", value: "EXP" },
    ];

    const tiposCL = [
        {
            value: 'L',
            label: 'LOCAL'
        },
        {
            value: 'E',
            label: 'EXTERIOR'
        },
        {
            value: 'P',
            label: 'PERSONA'
        },
    ];
    const estilo = {
        overflow: "auto",
        height: "200px",
    };
    
    
    const [docs, setDocs] = useState(false);
    const handleOnChange = value => {
        leerProductos(value.value);
        localStorage.setItem('bodegaFactura', value.value)
        setDocs(true);
    };
    
    return (
        <div>
            {crear === false && editar == false &&
                <div className='row mb-2'>
                    <div className='col-3'>
                        <Field
                            name="codigo"
                            placeholder="Codigo establecimiento"
                            className="form-control"
                            component={renderField}
                            disabled={true}
                            msj="Código establecimiento"
                        />
                    </div>
                    <div className='col-3'>
                        <Field
                            name="establecimiento"
                            placeholder="Nombre establecimiento"
                            className="form-control"
                            component={renderField}
                            disabled={true}
                            msj="Nombre establecimiento"
                        />
                    </div>
                </div>
            }
            {editar === true &&
                <div className='row mb-2'>
                    <div className='col-3'>
                        <Field
                            name="codigo"
                            placeholder="Codigo establecimiento"
                            className="form-control"
                            component={renderField}
                            disabled={true}
                            msj="Código establecimiento"
                        />
                    </div>
                    <div className='col-3'>
                        <Field
                            name="establecimiento"
                            placeholder="Nombre establecimiento"
                            className="form-control"
                            component={renderField}
                            disabled={true}
                            msj="Nombre establecimiento"
                        />
                    </div>
                </div>
            }
            {crear === true &&
                <div className='row mb-2'>
                    <div className='col-3'>
                        <div className="form-floating">
                            <input
                                placeholder="Codigo establecimiento"
                                type="text"
                                autocomplete="off"
                                name="codigo"
                                className="form-control"
                                value={estable.codigo}
                                disabled={true}
                            />
                            <label className='form-label' htmlFor="codigo">
                                Código establecimiento
                            </label>
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className="form-floating">
                            <input
                                placeholder="Nombre establecimiento"
                                type="text"
                                autocomplete="off"
                                name="nombre"
                                className="form-control"
                                value={estable.nombre_comercial}
                                disabled={true}
                            />
                            <label className='form-label' htmlFor="nombre">
                                Nombre establecimiento
                            </label>
                        </div>
                    </div>
                </div>
            }
            <div className='row mb-2'>
                <div className='col-3'>
                    <Field
                        autoFocus={autoFocus}
                        disabled={bloqueo}
                        name="fechaRegistro"
                        component={renderDayPickerB}
                        msj="Fecha Registro"
                    />
                </div>
                {((crear === false && editar == false) || editar === true) &&

                    <div className='col-6'>
                        <Field
                            name="nombreCli"
                            placeholder="Cliente"
                            className="form-control"
                            component={renderField}
                            disabled={true}
                            msj="Cliente"
                        />
                    </div>
                }
                {(crear === true) &&
                    <div className='col-6'>
                        <Field
                            name="cliente_documento"
                            placeholder="Cliente..."
                            className="form-control-find"
                            component={AsyncSelectField}
                            loadOptions={listarClientes}
                            msj="Cliente"
                        />
                    </div>
                }
                {(crear === true) &&
                    <div className='col-3 mt-2'>
                        <button
                            className='btn mr-2 mb-3 btn-secondary'
                            onClick={() => cambiarEstadoModal(!estadoModal)}
                        >
                            Crear nuevo cliente
                        </button>
                    </div>
                }
            </div>
            {(editar === true) &&
                <div className='row mb-2'>
                    <div className='col-3'>
                    </div>
                    <div className='col-6'>
                        <Field
                            name="cliente_documento"
                            placeholder="Cliente..."
                            className="form-control-find"
                            component={AsyncSelectField}
                            loadOptions={listarClientes}
                            msj="Clientes"
                        />
                    </div>
                </div>
            }
            <div className='row mb-2'>
                {crear === false && editar == false &&
                    <div className='col-3'>
                        {tipos &&
                            <div className="form-floating">
                                <Field
                                    name="tipo_documento"
                                    options={tipos}
                                    crear={crear}
                                    dato={crear === true ? 0 : lectura.tipo_documento}
                                    component={SelectField}
                                    className="form-select"
                                    disabled={bloqueo}
                                    msj="Tipo de documento"
                                />
                            </div>
                        }
                    </div>
                }
                {(crear === true || editar == true) &&
                    <div className='col-3'>
                        <div className="form-floating">
                            <select className="form-select" id="tipo" name="tipo"
                                value={editarT === true ? lectura.tipo_documento : tipoD} onChange={handleChange} >
                                <option selected>Seleccionar...</option>
                                {tipos.map(
                                    tip => <option key={tip.value} value={tip.value}
                                    >{tip.label}
                                    </option>)
                                }
                            </select>
                            <label className='form-label' htmlFor="mes">Tipo de documento</label>
                        </div>
                    </div>
                }
                {(crear === false && editar === false) &&
                    <div className='col-3'>
                        <div className="form-floating">
                            <Field
                                name="tipo_factura"
                                options={tipoF}
                                crear={crear}
                                dato={crear === true ? 0 : lectura.tipo_factura}
                                component={SelectField}
                                className="form-select"
                                disabled={bloqueo}
                                msj="Tipo Factura"
                            />
                        </div>
                    </div>
                }
                {(crear === true || editar === true) &&
                    <div className='col-3'>
                        <div className="form-floating">
                            <Field
                                name="tipo_factura"
                                options={tipoF}
                                crear={crear}
                                dato={crear === true ? 0 : lectura.tipo_factura}
                                component={SelectField}
                                className="form-select"
                                disabled={bloqueo}
                                msj="Tipo Factura"
                            />
                        </div>
                    </div>
                }
                <div className='col-3'>
                    <Field
                        name="serie"
                        placeholder="Nombre"
                        className="form-control"
                        component={renderField}
                        disabled={bloqueo}
                        msj="Serie"
                    />
                </div>
                <div className='col-3'>
                    <Field
                        name="numero"
                        placeholder="Dias credito"
                        className="form-control"
                        component={renderNumber}
                        disabled={bloqueo}
                        msj="Numero"
                    />
                </div>
            </div>
            <div className='row mb-2'>
                {(crear === false && editar === false) &&
                    <div className='col-3'>
                        <div className="form-floating">
                            <Field
                                name="moneda"
                                options={monedas}
                                crear={crear}
                                dato={crear === true ? 0 : lectura.moneda}
                                component={SelectField}
                                className="form-select"
                                disabled={bloqueo}
                                msj="Moneda"
                                />
                        </div>
                    </div>
                }
                {(crear === true || editar === true) &&
                    <div className='col-3'>
                        {monedas &&
                            <div className="form-floating">
                                <Field
                                    name="moneda"
                                    options={monedas}
                                    crear={crear}
                                    dato={crear === true ? 0 : lectura.moneda}
                                    component={SelectField}
                                    className="form-select"
                                    disabled={bloqueo}
                                    msj="Moneda"
                                />
                            </div>
                        }
                    </div>
                }
                {ocultar &&
                    <div className='col-3'>
                        <div className="form-floating">
                            <Field
                                disabled={bloqueo}
                                placeholder="Tipo de cambio"
                                name="tipoCambio"
                                component={renderCurrencyFloatTC}
                                msj="Tipo de cambio"
                            />
                        </div>
                    </div>
                }
                
            </div>
            <div className='mb-2'>
                <Field
                    name="descripcion"
                    placeholder="Descripcion"
                    component={renderField}
                    className="form-control"
                    disabled={bloqueo}
                    msj="Referencia"
                />
            </div>
            <h5 className="p-2 bg-secondary title mt-1">Detalle factura</h5>
            <div>
                {(crear === true || editar === true) &&
                    <div>
                        <div className='row mb-2'>
                            <div className='col-3'>
                                <Field
                                    name="bodega"
                                    placeholder="Bodega..."
                                    className="form-control-find"
                                    loadOptions={listarBodegas}
                                    component={AsyncSelectField}
                                    onChange={handleOnChange}
                                    msj="Bodega"
                                />
                            </div>
                            <div className='col-9'>
                                <Field
                                    name="producto"
                                    placeholder="Producto..."
                                    className="form-control-find"
                                    loadOptions={pro}
                                    component={AsyncSelectFieldIn}
                                    msj="Producto"
                                />
                            </div>
                            
                        </div>
                    
                
                        <div className='row mb-2'>
                            <div className='col-2'>
                                <Field
                                    name="cantidad"
                                    placeholder="Cantidad"
                                    className="form-control"
                                    component={renderCurrencyFloatTC}
                                    disabled={bloqueo}
                                    onChange={(e, value) => {
                                        if (value) {
                                            setCantidad(value);
                                        }
                                    }}
                                    msj="Cantidad"
                                />
                            </div>
                            <div className='col-2'>
                                <Field
                                    name="precio"
                                    placeholder="Precio Unitario"
                                    className="form-control"
                                    component={renderCurrencyFloat}
                                    disabled={bloqueo}
                                    onChange={(e, value) => {
                                        if (value) {
                                            let total = parseFloat(parseFloat(value) * parseFloat(cantidad)).toFixed(2);
                                            setTotal(total);
                                            localStorage.setItem('total_linea_factura', total)
                                        }
                                    }}
                                    msj="Precio Unitario"
                                />
                            </div>
                            <div className='col-2'>
                                <div className="form-floating">
                                    <NumberFormat
                                        className={classNames('form-control')}
                                        decimalScale={2}
                                        disabled={true}
                                        placeholder="Base"
                                        fixedDecimalScale
                                        thousandSeparator
                                        value={total}
                                        name="total"
                                    />
                                <label className='form-label' htmlFor="total">
                                    Total
                                </label>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
            {(crear === true || editar === true) &&
                <Contenido>
                    <button
                        className='btn mr-2 mb-3 btn-primary'
                        onClick={() => registroProductoInventario(periodoD, datos)}
                    >
                        Agregar linea
                    </button>
                </Contenido>
            }
            <br/>
            {(crear === true || editar === true) &&
                <div style={estilo}>
                    <DetalleInventario
                        detalle={detalleProducto}
                        eliminarLineaProducto={eliminarLineaProducto}
                    />
                </div>
            }
            {(crear === false && editar === false) &&
                <div style={estilo}>
                    <DetalleInventarioVer
                        detalle={detalleProducto}
                        eliminarLineaProducto={eliminarLineaProducto}
                    />
                </div>
            }
            <br/>
                {(crear === false && editar === false) &&
                    <div>
                        <div className='row mb-2'>
                            <div className='col-3'>

                            </div>
                            <div className='col-2'>
                                <Field
                                    name="base"
                                    disabled={true}
                                    placeholder="Base"
                                    className="form-control"
                                    component={renderCurrencyFloat}
                                    msj="Base"
                                />
                            </div>
                            <div className='col-2'>
                                <Field
                                    name="iva"
                                    disabled={true}
                                    placeholder="Iva"
                                    className="form-control"
                                    component={renderCurrencyFloat}
                                    msj="Iva"
                                />
                            </div>
                            <div className='col-2'>
                                <Field
                                    name="total"
                                    disabled={true}
                                    placeholder="Total"
                                    className="form-control"
                                    component={renderCurrencyFloat}
                                    msj="Total"
                                />
                            </div>
                            <div className='col-3'>
                                       
                            </div>
                        </div>
                    </div>
                }
            <br />
            <Modal
                estado={estadoModal}
                cambiarEstado={cambiarEstadoModal}
                titulo="Crear cliente"
                botonCerrar="true"
            >
                <div className="d-flex flex-column flex-1 mx-4">

                    <div className='mb-2 w-25'>
                        <Field
                            autoFocus={autoFocus}
                            name="identificador"
                            placeholder="Identificador"
                            className="form-control"
                            component={renderField}
                            msj="Identificador"
                        />
                    </div>
                    <div className='row'>
                        <div className='mb-2 col-8'>
                            <Field
                                name="nombre"
                                placeholder="Nombre"
                                className="form-control"
                                component={renderField}
                                msj="Nombre"
                            />
                        </div>
                        {obtenerPaises &&
                            <div className='col-4'>
                                <div className="form-floating">
                                    <Field
                                        name="pais"
                                        options={obtenerPaises}
                                        crear={crear}
                                        component={SelectField}
                                        className="form-select"
                                        msj="Pais"
                                    />
                                </div>
                            </div>
                        }
                    </div>

                    <div className='mb-2 w-75'>
                        <Field
                            name="direccion"
                            placeholder="Direccion"
                            className="form-control"
                            component={renderField}
                            msj="Dirección"
                        />
                    </div>
                    <div className='row'>
                        <div className='col-4'>
                            <Field
                                name="telefono"
                                placeholder="Telefono"
                                className="form-control"
                                component={renderField}
                                msj="Telefono"
                            />
                        </div>
                        <div className='col-4'>
                            <Field
                                name="correo"
                                placeholder="Correo electrónico"
                                className="form-control"
                                component={renderField}
                                msj="Correo electronico"
                            />
                        </div>
                        <div className='col-4'>
                            <Field
                                name="dias_credito"
                                placeholder="Dias credito"
                                className="form-control"
                                component={renderNumber}
                                msj="Dias crédito"
                            />
                        </div>
                    </div>
                    <div className='row mt-2'>
                        <div className='col-4'>
                            <Field
                                name="limite_credito"
                                placeholder="Limite credito"
                                className="form-control"
                                component={renderNumber}
                                msj="Limite crédito"
                            />
                        </div>
                        <div className='col-4'>
                            <div className="form-floating">
                                <Field
                                    name="tipo_cliente"
                                    options={tiposCL}
                                    component={SelectField}
                                    className="form-select"
                                    msj="Tipo cliente"
                                />
                            </div>
                        </div>
                    </div>
                    <div className='d-flex flex-row mt-3'>

                        <button
                            className='btn mr-2 mb-3 btn-primary'
                            onClick={() => registroCliente(datos)}
                        >
                            Guardar
                        </button>

                        <button
                            className='btn mr-2 mb-3 btn-secondary'
                            onClick={() => cambiarEstadoModal(false)}
                        >
                            Salir
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

FacturacionInventarioForm.propTypes = {
    crear: PropTypes.bool,
    lectura: PropTypes.object,
    tipos: PropTypes.object,
    editar: PropTypes.bool,
    bloqueo: PropTypes.bool,
    registroCliente: PropTypes.func,
    obtenerPaises: PropTypes.object,
    fechaInicio: PropTypes.string,
    fechaFinal: PropTypes.string,
    impuesto: PropTypes.object,
    datos: PropTypes.object,
    listarBodegas: PropTypes.object,
    detalleProducto: PropTypes.object,
    eliminarLineaProducto: PropTypes.func,
    leerProductos: PropTypes.func,
    estable: PropTypes.object,
    leerProductoSaldo: PropTypes.func,
    pro: PropTypes.object,
    periodoD: PropTypes.object,
    registroProductoInventario: PropTypes.func,
    monedas: PropTypes.object,
    listarProductoServicios: PropTypes.func
};

const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;

export default reduxForm({
    form: 'FormInventarioFactura',
})(FacturacionInventarioForm);
