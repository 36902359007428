import React from 'react';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import * as BsIcons from "react-icons/bs";

export const SidebarDataPuntoVenta = [
    // Principal PDV
    {
        title: 'Inicio',
        path: '/punto_venta',
        icon: <BsIcons.BsGrid1X2Fill />,
    },
    // Punto de venta
    {
        title: 'PDV',
        label: 'punto_venta',
        path: '/punto_venta/facturacion',
        icon: <FaIcons.FaStore />,
    },
    // Punto de venta
    {
        title: 'Cajas',
        label: 'cajas',
        path: '/punto_venta/cajas',
        icon: <FaIcons.FaMoneyBill />,
    },
    // Productos
    {
        title: 'Productos',
        path: '#',
        icon: <FaIcons.FaBox />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav: [
            {
                title: 'Productos',
                path: '/punto_venta',
                path_s: 'producto',
                icon: <FaIcons.FaBox />,
            },
            {
                title: 'Tipo Producto',
                path: '/punto_venta',
                path_s: 'tipo_producto',
                icon: <FaIcons.FaClone />,
            },
            {
                title: 'Presentación',
                path: '/punto_venta',
                path_s: 'presentacion_producto',
                icon: <FaIcons.FaBoxTissue />,
            },
            {
                title: 'Unida Medida',
                path: '/punto_venta',
                path_s: 'unidad_medida',
                icon: <FaIcons.FaRulerCombined />,
            },
        ],
    },
    // Productos
    // {
    //     title: 'Productos',
    //     label: 'productos',
    //     path: '/punto_venta/productos',
    //     icon: <FaIcons.FaBox />,
    // },
    // Clientes
    {
        title: 'Clientes',
        label: 'clientes',
        path: '/punto_venta/clientes',
        icon: <FaIcons.FaUsers />,
    },
    // Bodega
    {
        title: 'Bodegas',
        label: 'bodegas',
        path: '/punto_venta/bodegas',
        icon: <FaIcons.FaWarehouse />,
    },
];
