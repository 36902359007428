import { handleActions } from "redux-actions";
import { api } from "api";
import { NotificationManager } from "react-notifications";
import { guardarContribuyente } from "./contribuyentes";

const guardarLibroCompras = (data) => (dispatch) => {
    data.empresa = localStorage.getItem("fnzEmpresaId");
    data.mes = data.mes.value;
    data.year = data.year.value;
    return api
        .postAttachments(
            "finanzas/libro_compras",
            { ...data, libro_compras: null },
            [{ name: "libro_compras", file: data.libro_compras }]
        )
        .then((response) => {
            NotificationManager.success(
                "Libro de compras guardado",
                "Exito",
                3000,
                null
            );
            return false;
        })
        .catch((error) => {
            if (error.contribuyentes_faltantes) {
                return error.contribuyentes_faltantes;
            }
            if (error.nits_invalidos) {
                let errorStr = ``;
                for (
                    let index = 0;
                    index < error.nits_invalidos.length;
                    index++
                ) {
                    errorStr += `${error.nits_invalidos[index]}, `;
                }
                NotificationManager.error(
                    `${error.msj}: ${errorStr}`,
                    "Error",
                    999999,
                    null
                );
            }
            NotificationManager.error(
                error.msj
                    ? error.msj
                    : "Ocurrio un error al subir el libro de compras",
                "Error",
                25000,
                null
            );
        });
};

const chequearMesesIngresados = (year) => (dispatch) => {
    let data = {};
    data.empresa = localStorage.getItem("fnzEmpresaId");
    year ? (data.year = year) : (data.year = "0");

    return api
        .get("/finanzas/libro_compras/meses_ingresados/", data)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            NotificationManager.error(
                "Ocurrió un error al chequear los meses ingresados",
                "Error",
                2000
            );
        });
};

export const obtenerResumen = (data) => (dispatch) => {
    data.empresa = localStorage.getItem("fnzEmpresaId");
    data.year = data.year.value;
    data.mes = data.mes.value;

    return api
        .get("/finanzas/libro_compras/resumen", data)
        .then((response) => {
            const excelBinaryData = atob(response.excel);
            const blob = new Blob(
                [
                    new Uint8Array(excelBinaryData.length).map((_, i) =>
                        excelBinaryData.charCodeAt(i)
                    ),
                ],
                {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                }
            );
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = "Libro de compras.xlsx";
            document.body.appendChild(a);
            let resumen = JSON.parse(response.resumen);
            let excelLinkElement = a;
            return { resumen, excelLinkElement };
        })
        .catch((error) => {
            NotificationManager.error(error.body.msj, "Error", 2000);
        });
};

export const agregarProveedor = (data) => (dispatch) => {
    data.empresa = localStorage.getItem("fnzEmpresaId");
    return api
        .post("finanzas/libro_compras/contribuyentes/proveedores", data)
        .then((response) => {
            NotificationManager.success(
                response.msj,
                "Exito",
                6000,
                null,
                true
            );
        })
        .catch((error) => {
            NotificationManager.error(
                error.msj
                    ? error.msj
                    : "Ocurrio un error al guardar los contribuyentes",
                "Error",
                2000,
                null,
                true
            );
        });
};

export const listarProveedores = () => (dispatch) => {
    const empresa = localStorage.getItem("fnzEmpresaId");
    return api
        .get("finanzas/libro_compras/contribuyentes/proveedores", { empresa })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            NotificationManager.error(
                error.msj
                    ? error.msj
                    : "Ocurrio un error al listar los proveedores",
                "Error",
                2000,
                null,
                true
            );
        });
};

export const listarContribuyentesComboBox = (search) => (dispatch) => {
    return api
        .get("/finanzas/libro_compras/contribuyentes", { search })
        .then((response) => {
            const options = response.map((item) => ({
                value: item.id,
                label: `${item.nit} | ${item.razon_social}`,
            }));
            return options;
        })
        .catch((error) => {
            NotificationManager.error(
                "Ocurrió un error al listar los contribuyentes",
                "Error",
                2000
            );
        });
};

export const actions = {
    guardarLibroCompras,
    chequearMesesIngresados,
    guardarContribuyente,
    obtenerResumen,
    agregarProveedor,
    listarProveedores,
    listarContribuyentesComboBox,
};

export const reducers = {};

export const initialState = {};

export default handleActions(reducers, initialState);
