import { handleActions } from 'redux-actions';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';

const LOADER = 'LOADER';
const MODULO = 'MODULO';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listarSiembra = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let tipo = 'fecha_cosecha'
    const params = { id, search, tipo };
    return api.get('/siembra', params).then((response) => {
        const siembra = [];
        if (response){
            response.forEach(item=>{
                siembra.push({
                    value: item.id,
                    label: item.descripcion
                })
            })
        }
        return siembra;
    }).catch((error) => {
        return [];
    })
}

export const registroFechaCosecha = () => (dispatch, getStore) => {
    const data = getStore().form.FechaFechaCosechaForm.values;
    if (data.siembra === undefined || data.siembra === null) {
        Swal.fire({
            title: 'Fecha cosecha',
            text: 'Debe de seleccionar cosecha',
            type: 'error',
        })
    } else {
        if (data.fecha_cosecha === undefined || data.fecha_cosecha === null) {
            Swal.fire({
                title: 'Fecha cosecha',
                text: 'Debe de ingresar fecha cosecha',
                type: 'error',
            })
        } else {
            const formData = {
                id: data.siembra.value,
                fecha_cosecha: data.fecha_cosecha
            }
            dispatch(setLoader(true));
            api.post('/siembra/fecha_cosecha/', formData).then(() => {
                NotificationManager.success(
                    'Fecha de cosecha guardada correctamente',
                    'Exito',
                    3000
                );
                setTimeout(function() {
                    window.location.reload(true);
                }, 400);

            }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                2000
            );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }
    }
}

export const modificarFechaCosecha = () => () => {
    
}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const registroFechaFinalCosecha = () => (dispatch, getStore) => {
    const data = getStore().form.FechaFechaFinalCosechaForm.values;
    if (data.siembra === undefined || data.siembra === null) {
        Swal.fire({
            title: 'Fecha final cosecha',
            text: 'Debe de seleccionar cosecha',
            type: 'error',
        })
    } else {
        if (data.fecha_cosecha === undefined || data.fecha_cosecha === null) {
            Swal.fire({
                title: 'Fecha final cosecha',
                text: 'Debe de ingresar fecha final de cosecha',
                type: 'error',
            })
        } else {
            const formData = {
                id: data.siembra.value,
                fecha_final_cosecha: data.fecha_cosecha
            }
            dispatch(setLoader(true));
            api.post('/siembra/fecha_fin_cosecha/', formData).then(() => {
                NotificationManager.success(
                'Fecha final de cosecha guardada correctamente',
                'Exito',
                3000
            );
            setTimeout(function() {
                window.location.reload(true);
            }, 400);
            }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                2000
            );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }
    }
}

export const listarSiembraFin = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let tipo = 'fecha_fin_cosecha'
    const params = { id, search, tipo };
    return api.get('/siembra', params).then((response) => {
        const siembra = [];
        if (response){
            response.forEach(item=>{
                siembra.push({
                    value: item.id,
                    label: item.descripcion
                })
            })
        }
        return siembra;
    }).catch((error) => {
        return [];
    })
}

export const actions = {
    listarSiembra,
    registroFechaCosecha,
    modificarFechaCosecha,
    getModulo,
    registroFechaFinalCosecha,
    listarSiembraFin
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
};

export const initialState = {
    loader: false,
    modulo: [],
};

export default handleActions(reducers, initialState)
 