import React, { Component } from 'react';
import Formulario from './OtrosGastosForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";

class OtrosGastos extends Component{
    state={
        archivo: null
    }

    componentDidMount = () => {
        const { getModulo, leerPeriodoD } = this.props;
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        leerPeriodoD(modulo[5],modulo[7]);
        getModulo(modulo[5],modulo[4]);
    }
    
    setArchivo = (archivo) => {
        this.setState({archivo});
    };

    subirOtrosGastos = (data) => {
        const { subirOtrosGastos } = this.props;
        subirOtrosGastos( {...data, archivo: null},
        [{ "file": this.state.archivo, "name": 'archivo' },])
    }

    render(){
        const { listarColaborador, loader, modulo, periodoD } = this.props;
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    onSubmit = {this.subirOtrosGastos}
                    modulo = {modulo}
                    listarColaborador = {listarColaborador}
                    setArchivo = {this.setArchivo} 
                    periodoD = {periodoD}
                />
            </LoadMask>
        );
    }
}

export default OtrosGastos;