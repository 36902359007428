import React, { Component } from 'react';
import Grid from '../../Utils/GridFiltro';
import PropTypes from 'prop-types';
import { standardActions } from '../../Utils/GridFiltro/StandardActions';
import NavbarModulo from '../../components/NavbarModulo';
import NavbarModuloLF from '../../components/NavbarModuloFage';
const valEmp = __CONFIG__.empresa;

class ListISR extends Component{
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: true
    };

    componentDidMount = () => {
        const { listar, getModulo } = this.props;
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        listar();
        if (valEmp === 'fage'){
            getModulo(modulo[6],modulo[5]);
        }else{
            getModulo(modulo[5],modulo[4]);
        }
    }
    render(){
        const { data, loader, eliminar, modulo } = this.props;
        let ruta = window.location.href;
        let id_emp = ruta.split('/');
        return(
            <React.Fragment>
                {valEmp === 'fage' &&
                    <NavbarModuloLF
                        modulo={modulo}
                    />
                }
                {valEmp === '' &&
                    <NavbarModulo
                        modulo={modulo}
                    />
                }
                <br />
                <h3 className="p-2 bg-secondary title">ISR</h3>
                <br />
                {valEmp === 'fage' &&
                    <div className="d-flex flex-row justify-content-start mb-2">
                        <a
                            href={`/#/lfg/rr_hh/${id_emp[6]}/isr/${id_emp[8]}/crear`}
                            className='btn btn-primary'
                        >
                            Crear descuento
                        </a>
                    </div>
                }
                {valEmp === '' &&
                    <div className="d-flex flex-row justify-content-start mb-2">
                    <a
                            href={`/#/rr_hh/${id_emp[5]}/isr/${id_emp[7]}/crear`}
                            className='btn btn-primary'
                        >
                            Crear descuento
                        </a>
                    </div>
                }
                {data &&
                    <Grid 
                        hover 
                        striped 
                        data={data}
                        loading={loader} 
                        headerStyle={ { background: 'black' } } 
                        //onPageChange={onPageChange}
                        //onSortChange={onSortChange} 
                    >
                        <TableHeaderColumn
                            isKey
                            dataField="codigo"
                            dataAlign='left'
                            width="150"
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Codigo
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="nombre"
                            dataAlign='left'
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Nombre
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataAlign="center"
                            dataField="activo"
                            width="110"
                            dataFormat={(cell, row)=>{
                                if (cell === true){
                                    return 'A';
                                }else{
                                    return 'I';
                                }
                            }}
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Estado ISR
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            headerAlign="center"
                            dataAlign="right"
                            dataField="descuento"
                            width="200"
                            dataSort
                            dataFormat={(cell, row)=>{
                                return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Math.abs(cell));
                            }}
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Monto
                        </TableHeaderColumn>
                        {valEmp === 'fage' &&
                            <TableHeaderColumn
                                thStyle={
                                {color: 'white'}}
                                dataField="id"
                                width="150"
                                dataAlign="center"
                                dataSort
                                dataFormat={standardActions({ 
                                    editar_lfg: "isr", 
                                    ver_lfg: "isr",
                                    eliminarISR: eliminar,
                                    modulo: id_emp[5],
                                    empresa: id_emp[6],
                                    periodo: id_emp[8],
                                })} 
                                
                            >
                                Acciones
                            </TableHeaderColumn>
                        }
                        {valEmp === '' &&
                            <TableHeaderColumn
                                thStyle={
                                {color: 'white'}}
                                dataField="id"
                                width="150"
                                dataAlign="center"
                                dataSort
                                dataFormat={standardActions({ 
                                    editar_a: "isr", 
                                    ver_a: "isr",
                                    eliminarISR: eliminar,
                                    modulo: id_emp[4],
                                    empresa: id_emp[5],
                                    periodo: id_emp[7],
                                })} 
                                
                            >
                                Acciones
                            </TableHeaderColumn>
                        }
                    </Grid>
                }
            </React.Fragment>
        );
    }
}

export default ListISR;
