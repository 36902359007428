import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/auto_ventas/combustible';
import CombustibleList from './CombustibleList';


const ms2p = (state) => {
  return {
    ...state.auto_ventas_combustible,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(CombustibleList);
