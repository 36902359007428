import { handleActions } from 'redux-actions';
import { NotificationManager } from "react-notifications";
import { initialize as initializeForm } from 'redux-form';
import { api } from "api";
import Swal from 'sweetalert2';

const LOADER = 'LOADER';
const DOCUMENTOS = 'DOCUMENTOS'; 
const MONEDA = 'MONEDA';
const DETALLE_ABONOS = 'DETALLE_ABONOS';
const EMPRESA = 'EMPRESA';
const LISTADO_EMPRESAS = 'LISTADO_EMPRESAS';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listarEmpresas = () => () => {
    return api.get('/empresa/empresas').then(data=>{
        const list_empresas = [];
        if(data){
            data.forEach(item=>{
                list_empresas.push({
                    value: item.id,
                    label: item.razon_social
                })
            })
        }
        return list_empresas ;
    }).catch((error)=>{
        
        return [];
    })
}

export const subirProductos = (data={},attachments=[]) => (dispatch) => {
    if (data.empresa === undefined){
        Swal.fire({
            title: 'Subir productos',
            text: 'Debe de seleccionar empresa',
            type: 'error',
        })       
    }
    else
    {
        const formData = {
            empresa: data.empresa.value
        }
        dispatch(setLoader(true));
        api.postAttachments('/producto/productos_excel/', formData, attachments).then(() => {
            NotificationManager.success(
                'Productos creados',
                'Exito',
                8000
            );
        }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                6000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
}

export const subirCuentasContables = (data={},attachments=[]) => (dispatch) => {
    if (data.empresa === undefined){
        Swal.fire({
            title: 'Subir cuentas contables',
            text: 'Debe de seleccionar empresa',
            type: 'error',
        })       
    }
    else
    {
        const formData = {
            empresa: data.empresa.value
        }
        dispatch(setLoader(true));
        api.postAttachments('/cuentas/cuentas_excel/', formData, attachments).then(() => {
            NotificationManager.success(
                'Cuentas contables creadas',
                'Exito',
                8000
            );
        }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                6000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
}

export const subirProveedores = (data={},attachments=[]) => (dispatch) => {
    if (data.empresa === undefined){
        Swal.fire({
            title: 'Subir proveedores',
            text: 'Debe de seleccionar empresa',
            type: 'error',
        })       
    }
    else
    {
        const formData = {
            empresa: data.empresa.value
        }
        
        dispatch(setLoader(true));
        api.postAttachments('/proveedor/proveedores_excel/', formData, attachments).then(() => {
            NotificationManager.success(
                'Proveedores creados',
                'Exito',
                8000
            );
        }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                6000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
    
}

export const saldos = () => (dispatch, getStore) => {
    const data = getStore().form.FormSaldos.values;
    
    if (data.empresa === undefined){
        Swal.fire({
            title: 'Saldos de facturas',
            text: 'Debe de seleccionar empresa',
            type: 'error',
        })       
    }else{
        let empresa = data.empresa.value;
        let params = { empresa}
        dispatch(setLoader(true));
        api.get('/factura/factura_actualizar_saldos', params).then((response) => {
            NotificationManager.success(
                'Saldos generados',
                'Exito',
                3000
            );
        }).catch((error)=>{
            NotificationManager.error(
                'Ocurrió un error al generar saldos',
                'Error',
                0
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });

    }
    
}

export const subirOcupaciones = (data={},attachments=[]) => (dispatch) => {
    dispatch(setLoader(true));
    api.postAttachments('/ocupacion/ocupaciones_excel/', data, attachments).then(() => {
        NotificationManager.success(
            'Ocupaciones creadas',
            'Exito',
            8000
        );
    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            6000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
    
}

export const subirPaises = (data={},attachments=[]) => (dispatch) => {
    dispatch(setLoader(true));
    api.postAttachments('/paises/paises_excel/', data, attachments).then(() => {
        NotificationManager.success(
            'Paises creados',
            'Exito',
            8000
        );
    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            6000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
    
}

export const subirLineas = (data={},attachments=[]) => (dispatch) => {
    const formData = {}
    dispatch(setLoader(true));
    api.postAttachments('/polizas/linea_poliza_excel/', formData, attachments).then(() => {
        NotificationManager.success(
            'Cuentas contables creadas',
            'Exito',
            8000
        );
    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            6000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const listarDocumentos = (tipo) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let empresa = id_emp[7];
    let params = {tipo, empresa}
    dispatch(setLoader(true));
    api.get('/factura/listar_facturas', params).then(data=>{
        const list_documentos = [];
        if(data){
            data.forEach(item=>{
                if (item.numero_autorizacion){
                    list_documentos.push({
                        value: item.id,
                        label: item.serie_FEL + ' - ' + item.numero_FEL,
                    })
                }else{
                    list_documentos.push({
                        value: item.id,
                        label: item.serie + ' - ' + item.numero,
                    })
                }
            })
        }
        dispatch({ type: DOCUMENTOS, doc: list_documentos });
        return list_documentos ;
    }).catch((error)=>{
        return [];
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leerDocumento = (doc, tipo) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let empresa = id_emp[7];
    let params = {empresa, doc, tipo}
    dispatch(setLoader(true));
    api.get('/factura/buscar_documento', params).then((response) => {
        if (response.documento.numero_autorizacion){
            response.serie = response.documento.serie_FEL;
            response.numero = response.documento.numero_FEL;
        }else{
            response.serie = response.documento.serie;
            response.numero = response.documento.numero;
        }
        response.fecha = response.documento.fecha;
        response.monto = response.documento.total;
        if (response.documento.nombreCli){
            response.nombre = response.documento.nombreCli;
        }else{
            response.nombre = response.documento.clienteN;
        }
        response.descripcion = response.documento.descripcion;
        dispatch({type: MONEDA, moneda: response.moneda });
        dispatch({type: DETALLE_ABONOS, detalle: response.detAbonos });
        dispatch(initializeForm('FormBuscarDocumento', response ));
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar documento',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const limpiar = () => (dispatch) => {
    dispatch({ type: DETALLE_ABONOS, detalle: [] });
}

export const leerEmpresa = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let empresa = id_emp[7];
    dispatch(setLoader(true));
    api.get(`/empresa/${empresa}/`).then((response) => {
        dispatch({type: EMPRESA, empresaNom: response.razon_social})
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar empressa',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const productosClasificacion = (data={},attachments=[]) => (dispatch) => {
    if (data.empresa === undefined){
        Swal.fire({
            title: 'Productos clasificación',
            text: 'Debe de seleccionar empresa',
            type: 'error',
        })       
    }
    else
    {
        const formData = {
            empresa: data.empresa.value
        }
        dispatch(setLoader(true));
        api.postAttachments('/producto/productos_excel_actualizar_clasificacion/', formData, attachments).then(() => {
            NotificationManager.success(
                'Productos con clasificación actualizados',
                'Exito',
                8000
            );
        }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                6000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
}

export const listarEmpresa = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get('/empresa/empresas').then((response)=>{
        dispatch({ type: LISTADO_EMPRESAS, empresa: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar las empresas',
            'Error',
            6000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}


export const actions = {
    listarEmpresas,
    subirProductos,
    subirCuentasContables,
    subirProveedores,
    saldos,
    subirOcupaciones,
    subirPaises,
    subirLineas,
    listarDocumentos,
    leerDocumento,
    limpiar,
    leerEmpresa,
    productosClasificacion,
    listarEmpresa,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DOCUMENTOS]: (state, { doc }) => {
        return {
            ...state,
            doc,
        };
    },
    [MONEDA]: (state, { moneda }) => {
        return {
            ...state,
            moneda,
        };
    },
    [DETALLE_ABONOS]: (state, { detalle }) => {
        return {
            ...state,
            detalle,
        };
    },
    [EMPRESA]: (state, { empresaNom }) => {
        return {
            ...state,
            empresaNom,
        };
    },
    [LISTADO_EMPRESAS]: (state, { empresa }) => {
        return {
            ...state,
            empresa,
        };
    },
};

export const initialState = {
    loader: false,
    doc: [],
    moneda: [], 
    detalle: [],
    empresaNom: '',
    empresa: [],
};

export default handleActions(reducers, initialState)