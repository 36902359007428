import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";

const LISTADO_BODEGA = 'LISTADO_BODEGA';
const GUARDAR_BODEGA = 'GUARDAR_BODEGA';
const LOADER = 'LOADER';
const MODULO = 'MODULO';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    //const params = { page, id };
    const params = { id };
    api.get('/bodega', params).then((response) => {
        dispatch({ type: LISTADO_BODEGA, data: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar bodegas',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const registroBodega = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormBodega.values;
    const formData = {
        numero: data.numero,
        bodega: data.bodega,
        mrp: data.mrp,
        empresa: id_emp[5],
    }
    dispatch(setLoader(true));
    api.post('/bodega/', formData).then(() => {
        NotificationManager.success(
            'Bodega creada',
            'Exito',
            3000
        );
        let ruta = `/punto_venta/${id_emp[5]}/bodegas`;
        dispatch(push(ruta));
    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const modificarBodega = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const datos = getStore().form.FormBodega.values;
    const id = datos.id;
    const formData = {
        id: id,
        numero: datos.numero,
        bodega: datos.bodega,
        mrp: datos.mrp
    }
    dispatch(setLoader(true));
    api.put(`/bodega/${id}/`, formData).then((response) => {
        NotificationManager.success(
            'Bodega modificada correctamente',
            'Exito',
            3000
        );
        let ruta = `/punto_venta/${id_emp[5]}/bodegas`;
        dispatch(push(ruta));
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al modificar la bodega',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/bodega/${id}`).then((response) => {
        dispatch({ type: GUARDAR_BODEGA, lectura: response });
        dispatch(initializeForm('FormBodega', response));
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar bodega',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = { id, modulo }
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response) => {
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const reporteBodegas = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let reporte = 'bodegas';
    const params = { id, reporte }
    api.getPdf('/bodega/reportes', params).then((response) => {
        if (response.headers['content-type'] == 'application/pdf') {
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'reporte_bodegas.pdf';
            window.open(link, '_blank');
            return false;
        }
    }).catch((error) => {
        if (error.status === 404) {
            NotificationManager.error(
                "No se encontraron datos",
                "Error",
                2000
            );
        }
        if (error.status === 500) {
            NotificationManager.error(
                'Ocurrió un error al generar reporte',
                'Error',
                0
            );
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const actions = {
    listar,
    registroBodega,
    modificarBodega,
    leer,
    getModulo,
    reporteBodegas,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_BODEGA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [GUARDAR_BODEGA]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
};

export const initialState = {
    loader: false,
    data: [],
    lectura: [],
    modulo: [],
};

export default handleActions(reducers, initialState);
