import React from "react";
import styled from "styled-components";
import { FicInput } from "../../Utils/FormInputComponents";
import Banner from "../common/Banner";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { validar } from "../../components/ValidarNit";
import GenBtn from "../../components/GenBtn";
import InputsContainer from "../common/InputsContainer";
import { getEntry } from "./actions";
import { withRouter } from "react-router";

const idName = "aseguradoraId"

const schema = yup.object({
    nombre: yup.string().required("El nombre es requerido"),
});

function Ver({ history, ...props }) {
    const params = props.match.params;
    const vehiculoId = params[idName];

    const { control } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: async () => {
            const formData = await getEntry(vehiculoId);
            // setLoadingState(false);
            return formData;
        },
    });

    return (
        <React.Fragment>
            <Banner>Ver</Banner>
            <GenBtn
                style={{ margin: "1rem 0" }}
                onPress={() => history.goBack()}
            >
                Regresar
            </GenBtn>
            <form onSubmit={() => {}}>
                <InputsContainer>
                    <FicInput control={control} label="Nombre" name="nombre" isDisabled/>
                </InputsContainer>
            </form>
        </React.Fragment>
    );
}

export default withRouter(Ver);
