import React, { Component } from 'react';
import Formulario from './UsuarioForm';
import LoadMask from "../Utils/LoadMask/LoadMask";

class Usuario extends Component{
    state = {
        crear: true
    }

    componentDidMount = () => {
        const { match, leer } = this.props;
        const id = match.params.id;
        if (id){
            this.setState({crear: false});
            leer(id);
        }
    }
    render(){
        const { registroUsuario, loader } = this.props;
        const { crear } = this.state;
        const funcionEnvio = crear ? registroUsuario : '';
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    crear = {crear}
                    onSubmit={funcionEnvio}
                />
           </LoadMask>
        );
    }
}

export default Usuario;