import React, { useEffect, useState } from 'react';
import Formulario from './ConsumoProductoForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";

function ConsumoProducto(props) {
    const { loader, periodoD, modulo, datos,
        listarClientes, detalleProducto, listarBodegas,
        detalleOrden, registroOrden, agregarLinea, detalleOrd,
        eliminarLineaProductoNuevo, leerProductos, pro, listarProyectos
    } = props;        
        
    useEffect(() => {
        const { match, leer, getModulo, leerPeriodoD, 
            parametrosEmpresa, limpiarOrden } = props;
        const id = match.params.id;
        if (id) {
            setCrear(false);
            leer(id);
        }
        let ruta = window.location.href;
        let periodo = ruta.split('/');
        leerPeriodoD(periodo[5], periodo[7]);
        getModulo(periodo[5], periodo[4]);
        parametrosEmpresa();
        limpiarOrden();
    }, [])

    const [crear, setCrear] = useState(true);

    const editar = window.location.href.includes('editar');
    let titulo = 'Crear consumo de materiales';
    let disabled = false;
    if (crear === false && editar === false) {
        disabled = true;
        titulo = 'Ver consumo de materiales';
    }

    return (
        <LoadMask loading={loader} dark>
            <Formulario
                registroOrden = {registroOrden}
                datos = {datos}
                crear = {crear}
                titulo = {titulo}
                editar = {editar}
                periodoD = {periodoD}
                modulo = {modulo}
                bloqueo = {disabled}
                listarClientes = {listarClientes}
                detalleProducto = {detalleProducto}
                detalleOrden = {detalleOrden}
                agregarLinea = {agregarLinea}
                detalleOrd = {detalleOrd}
                eliminarLineaProductoNuevo = {eliminarLineaProductoNuevo}
                leerProductos = {leerProductos}
                pro = {pro}
                listarBodegas = {listarBodegas}
                listarProyectos = {listarProyectos}
            />
        </LoadMask>
    );
}

export default ConsumoProducto;