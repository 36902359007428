import React, { Component } from 'react';
import Grid from '../../Utils/GridConsolidacionEnvio';
import moment from 'moment';

class DetalleEnvio extends Component{
    
    render(){
        const { loader, detalle } = this.props;
        return(
            <React.Fragment>
                {detalle &&
                <Grid 
                    hover 
                    striped 
                    data={detalle} 
                    loading={loader}
                    footer
                    headerStyle={ { background: 'black' } } 
                >
                <TableHeaderColumn
                    isKey
                    dataField="fecha"
                    dataAlign='right'
                    width="100"
                    headerAlign="center"
                    editable={false}
                    dataSort
                    dataFormat={(cell, row)=>{
                        return moment(cell).format("DD/MM/YYYY");
                    }}
                    thStyle={
                        {color: 'white'}}
                    tdStyle={
                        {color: 'white'}}
                >
                    Fecha
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="envio"
                    width="100"
                    dataSort
                    editable={false}
                    thStyle={
                        {color: 'white'}}
                    tdStyle={
                        {color: 'white'}}
                    >
                    Envio
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="cajas"
                    dataAlign='right'
                    width="100"
                    headerAlign="center"
                    editable={false}
                    dataSort
                    dataFormat={(cell, row)=>{
                        return new Intl.NumberFormat("en-US",{minimumFractionDigits: 0}).format(cell);
                    }}
                    thStyle={
                        {color: 'white'}}
                    tdStyle={
                        {color: 'white'}}
                >
                    Cajas
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="libras_envio"
                    dataAlign='right'
                    width="100"
                    headerAlign="center"
                    editable={false}
                    dataSort
                    dataFormat={(cell, row)=>{
                        return new Intl.NumberFormat("en-US",{minimumFractionDigits: 0}).format(cell);
                    }}
                    thStyle={
                        {color: 'white'}}
                    tdStyle={
                        {color: 'white'}}
                >
                    Libras
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="grape_15_1"
                    dataAlign='right'
                    headerAlign="center"
                    width="125"
                    thStyle={
                        {color: 'white'}}
                    tdStyle={
                        {color: 'white'}}
                >
                    Grape 15*1
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="libras_9_4"
                    dataAlign='right'
                    headerAlign="center"
                    width="125"
                    dataFormat={(cell, row)=>{
                        return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(cell);
                    }}
                    thStyle={
                        {color: 'white'}}
                    tdStyle={
                        {color: 'white'}}
                >
                    9.4 Libras
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="grape_12_15"
                    dataAlign='right'
                    headerAlign="center"
                    width="125"
                    thStyle={
                        {color: 'white'}}
                    tdStyle={
                        {color: 'white'}}
                >
                    Grape 12*15
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="libras_18"
                    dataAlign='right'
                    headerAlign="center"
                    width="125"
                    dataFormat={(cell, row)=>{
                        return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(cell);
                    }}
                    thStyle={
                        {color: 'white'}}
                    tdStyle={
                        {color: 'white'}}
                >
                    18 Libras
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="merma"
                    dataAlign='right'
                    headerAlign="center"
                    width="125"
                    dataFormat={(cell, row)=>{
                        return new Intl.NumberFormat("en-US",{minimumFractionDigits: 6}).format(cell);
                    }}
                    thStyle={
                        {color: 'white'}}
                    tdStyle={
                        {color: 'white'}}
                    editable={false}
                >
                    Merma
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="status"
                    width="125"
                    thStyle={
                        {color: 'white'}}
                    tdStyle={
                        {color: 'white'}}
                    editable={false}
                    dataFormat={(cell, row)=>{
                        if (cell === ''){
                            return 'ABIERTO'
                        }else{
                            return 'CERRADO'
                        }
                    }}
                >
                    Estado
                </TableHeaderColumn>
                </Grid>
                }
            </React.Fragment>
        );
    }
}

export default DetalleEnvio;
