import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
    FicComboBox,
    FicDatePicker,
    FicInput,
    FicNumber,
} from "../../Utils/FormInputComponents";
import Banner from "../common/Banner";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { validar } from "../../components/ValidarNit";
import GenBtn from "../../components/GenBtn";
import InputsContainer from "../common/InputsContainer";
import { crear } from "./actions";
import LoadMask from "../../Utils/LoadMask/LoadMask";
import { withRouter } from "react-router";
import { getPeriodoDates } from "../utils";
import AbonosTable from "./components/AbonosTable";
import DocumentosTable from "./components/DocumentosTable";
import { useListData, AsyncListData, useAsyncList } from "react-stately";
import { SaldoProvider } from "./context/provider";
import { getPatologias, getClientes, getTiposAbono } from "./actions";
import { useRef } from "react";
import useUpdateEffect from "../../../../hooks/useUpdateEffect";
import useDebounce from "../../../../hooks/useDebounce";
import OptionsBar from "../common/OptionsBar";
import { getSchema, getDefaultValues } from "./helpers/getFormSettings";

const BANNER_TEXT = "Abono";

const TablesGroupContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(650px, 1fr));
    gap: 3rem;
    padding: 2rem;
    background-color: #2b2b2b;
    min-width: 460px;
    overflow: auto;
`;

const TableContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    color: #bd0034;
    gap: 5px;
    min-width: 734px;

    h3 {
        align-self: center;
    }
`;

function Crear({ history, ...props }) {
    const [isLoading, setIsLoading] = useState(false);
    const schema = getSchema();
    const { startOfMonthPeriodo, endOfMonthPeriodo } = getPeriodoDates();
    const contextRef = useRef(null);
    const { control, reset, handleSubmit, watch, setValue, formState } =
        useForm({
            mode: "onChange",
            resolver: yupResolver(schema),
            defaultValues: getDefaultValues(),
        });

    let documentosData = useAsyncList({
        async load() {
            if (!cliente) return { items: [] };
            const data = await getPatologias(cliente);
            return { items: data };
        },
        getKey: (item) => item.id,
    });

    let abonosData = useListData({
        initialItems: [],
    });

    async function onSubmit(data) {
        setIsLoading(true);
        data.ingresos_patologia = abonosData.items.map((item) => ({
            ingreso_patologia: item.id,
            monto_abono: item.abono,
        }));
        data.cliente = data.cliente.value;
        data.tipo_abono = data.tipo_abono.value;
        await crear(data);
        setIsLoading(false);
    }

    const cliente = watch("cliente") ? watch("cliente").value : "";
    const monto = watch("monto") ? watch("monto") : 0;

    const resetTables = () => {
        documentosData.reload();
        abonosData.setSelectedKeys("all");
        abonosData.removeSelectedItems();
        contextRef.current.resetSaldo();
    };

    useUpdateEffect(() => {
        resetTables();
    }, [cliente]);

    useDebounce(
        () => {
            resetTables();
        },
        200,
        [monto]
    );

    return (
        <SaldoProvider saldoInicial={monto} ref={contextRef}>
            <OptionsBar />
            <Banner>{BANNER_TEXT}</Banner>
            <GenBtn
                onPress={() => history.goBack()}
                style={{ margin: "5px 0" }}
            >
                Regresar
            </GenBtn>
            <LoadMask loading={isLoading} color="#bd0034" blur={true}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <InputsContainer>
                        <FicComboBox
                            control={control}
                            label="Tipo de abono"
                            name="tipo_abono"
                            loadOptions={getTiposAbono}
                        />
                        <FicDatePicker
                            control={control}
                            label="Fecha de registro"
                            name="fecha_registro"
                            minValue={startOfMonthPeriodo}
                            maxValue={endOfMonthPeriodo}
                        />
                        <FicInput
                            control={control}
                            label="Serie"
                            name="serie"
                        />
                        <FicInput
                            control={control}
                            label="Numero"
                            name="numero"
                        />
                        <FicNumber
                            control={control}
                            label="Monto"
                            name="monto"
                            minValue={1}
                        />
                        <FicInput
                            control={control}
                            label="Referencia"
                            name="referencia"
                        />
                    </InputsContainer>
                    <FicComboBox
                        control={control}
                        label="Cliente"
                        name="cliente"
                        loadOptions={getClientes}
                    />
                    <TablesGroupContainer>
                        <TableContainer>
                            <h3>Documentos</h3>
                            <DocumentosTable list={documentosData} />
                        </TableContainer>
                        <TableContainer>
                            <h3>Abonos</h3>
                            <AbonosTable list={abonosData} />
                            <small style={{ color: "#8a8a8a" }}>
                                Los documentos con 0 abono seran ignorados
                            </small>
                        </TableContainer>
                    </TablesGroupContainer>
                    <GenBtn style={{ margin: "1rem 0" }} type="submit">
                        Guardar
                    </GenBtn>
                </form>
            </LoadMask>
        </SaldoProvider>
    );
}
export default withRouter(Crear);
