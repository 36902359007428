import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";

const LISTADO_GASTOS = 'LISTADO_GASTOS';
const LOADER = 'LOADER';
const MODULO = 'MODULO';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    api.get('/tipo_gasto_prd', params).then((response)=>{
        dispatch({ type: LISTADO_GASTOS, data: response });
    }).catch(()=>{
        NotificationManager.error(
            'Ocurrió un error al listar gastos',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const registroGasto = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormTipoGasto.values;
    const formData = {
        descripcion: data.descripcion,
        empresa: id_emp[5]
    }
    dispatch(setLoader(true));
    api.post('/tipo_gasto_prd/', formData).then(() => {
        NotificationManager.success(
        'Gasto creado',
        'Exito',
        3000
    );
    let ruta = `/produccion/${id_emp[5]}/tipo_gasto`
    dispatch(push(ruta));
    }).catch((error) => {
    NotificationManager.error(
        error.msj,
        'Error',
        2000
    );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const modificarGasto = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const datos = getStore().form.FormTipoGasto.values;
    const id = datos.id;
    const formData = {
        id: id,
        descripcion: datos.descripcion
    }
    dispatch(setLoader(true));
    api.put(`/tipo_gasto_prd/${id}/`, formData).then(() => {
        NotificationManager.success(
            'Gasto modificado correctamente',
            'Exito',
            3000
        );
        let ruta = `/produccion/${id_emp[5]}/tipo_gasto`
        dispatch(push(ruta));
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al modificar gasto',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/tipo_gasto_prd/${id}`).then((response) => {
        dispatch(initializeForm('FormTipoGasto', response ));
    }).catch((error)=>{
        console.log("error: ", error)
        NotificationManager.error(
            'Ocurrió un error al consultar gasto',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch((error) => {
        if (error.status === 401){
            NotificationManager.error(
              error.body.msj,
              'Error',
              0
            );
        }
        if (error.status === 400){
            NotificationManager.error(
              'Ocurrió un error en buscar modulo',
              'Error',
              0
            );
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const actions = {
    listar,
    registroGasto,
    modificarGasto,
    leer,
    getModulo
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_GASTOS]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
};

export const initialState = {
    loader: false,
    modulo: [],
    data: [],
    page: 1,
};

export default handleActions(reducers, initialState)
 