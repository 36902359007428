import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';
const valEmp = __CONFIG__.empresa;

const LISTADO_PAISES = 'LISTADO_PAISES';
const LISTADO_PAIS = 'LISTADO_PAIS';
const LISTADO_DOCUMENTOS = 'LISTADO_DOCUMENTOS';
const PAIS = 'PAIS';
const PAIS_LIST = 'PAIS_LIST';
const PAGE = 'PAGE';
const LOADER = 'LOADER';
const MODULO = 'MODULO';

const setPage = page => ({
    type: PAGE,
    page,
});

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params;
    let id;
    if (valEmp === 'fage'){
        id = id_emp[6];
        //params = { page, id };
        params = { id };
    }else{
        id = id_emp[5];
        params = { id };
    }
    dispatch(setLoader(true));
    api.get('/pais_colaborador', params).then((response)=>{
        dispatch({ type: LISTADO_PAISES, data: response });
        
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar paises',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const registroPais = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormPais.values;
    let id_empresa = '';
    if (valEmp === 'fage'){
        id_empresa = id_emp[6]
    }else{
        id_empresa = id_emp[5]
    }
    const formData = {
        pais_colaborador: data.pais,
        empresa: id_empresa
    }
    dispatch(setLoader(true));
    api.post('/pais_colaborador/', formData).then(() => {
        NotificationManager.success(
        'Pais creado',
        'Exito',
        3000
    );
    let ruta = '';
    if (valEmp === 'fage'){
        ruta = `/lfg/rr_hh/${id_emp[6]}/pais`
        dispatch(push(ruta));
    }else{
        ruta = `/rr_hh/${id_emp[5]}/pais`
        dispatch(push(ruta));
    }
    }).catch((error) => {
    NotificationManager.error(
        error.msj,
        'Error',
        2000
    );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const modificarPais = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const datos = getStore().form.FormPais.values;
    const id = datos.id;
    const formData = {
        id: id,
        pais_colaborador: datos.pais
    }
    dispatch(setLoader(true));
    api.put(`/pais_colaborador/${id}/`, formData).then(() => {
        NotificationManager.success(
            'Pais modificado correctamente',
            'Exito',
            3000
        );
        let ruta = '';
        if (valEmp === 'fage'){
            ruta = `/lfg/rr_hh/${id_emp[6]}/pais`
            dispatch(push(ruta));
        }else{
            ruta = `/rr_hh/${id_emp[5]}/pais`
            dispatch(push(ruta));
        }
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al modificar pais',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/pais_colaborador/${id}`).then((response) => {
        dispatch(initializeForm('FormPais', response ));
        dispatch({ type: PAIS, lectura: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar pais',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const paisList = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let empresa = '';
    if (valEmp === 'fage'){
        empresa = id_emp[6];
    }else{
        empresa = id_emp[5];
    }
    return api.get(`/pais_colaborador/pais_empresa/?id=${empresa}`).then((data)=>{
        const pais = [];
        data.results.forEach(item=>{
            pais.push({
                value: item.id,
                label: item.pais
            })
        })
        dispatch({ type: PAIS_LIST, pais: pais });
    }).catch(error=>{
        NotificationManager.error(
            'Ocurrió un error al listar paises',
            'Error',
            0
        );
    })
}

const listarPaises = () => (dispatch) => {
    return api.get("/paises").then(response => {
        const pais = [];
        response.results.forEach(item => {
            pais.push({
                value: item.id,
                label: item.pais.toUpperCase()
            })
        })
        dispatch({ type: LISTADO_PAIS, listaP: pais });
    }).catch(error => {
        NotificationManager.error(
            'Ocurrió un error al listar paises',
            'Error',
            8000
        );
    })
}

export const leerPais = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/pais_colaborador/${id}`).then((response) => {
        dispatch(initializeForm('FormTipoDocumento', response ));
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar pais',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const registroDocumento = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormTipoDocumento.values;
    let id_empresa = '';
    let id_pais = '';
    if (valEmp === 'fage'){
        id_empresa = id_emp[6]
        id_pais = id_emp[8]
    }else{
        id_empresa = id_emp[5]
        id_pais = id_emp[7]
    }
    if (data.tipo_documento === undefined || data.tipo_documento === null) {
        Swal.fire({
            title: 'Documento',
            text: 'Debe de ingresar documento',
            type: 'error',
        })
    } else {
        const formData = {
            id: id_pais,
            documento: data.tipo_documento,
            empresa: id_empresa
        }
        dispatch(setLoader(true));
        api.post('/pais_colaborador/documento/', formData).then((response) => {
            response.pais = response.pais_colaborador.toUpperCase()
            response.tipo_documento = '';
            dispatch(initializeForm('FormTipoDocumento', response ));
            dispatch(listarDocumentos(1));
            NotificationManager.success(
            'Documento creado',
            'Exito',
            3000
        );
        }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            2000
        );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
}

export const listarDocumentos = (page = 1) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params;
    let id;
    let pais;
    if (valEmp === 'fage'){
        id = id_emp[6];
        pais = id_emp[8];
        params = { page, id, pais };
    }else{
        id = id_emp[5];
        pais = id_emp[7];
        params = { page, id, pais };
    }
    api.get('/pais_colaborador/listar_documentos', params).then((response)=>{
        dispatch({ type: LISTADO_DOCUMENTOS, documentos: response });
        dispatch(setPage(page));
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar paises',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const eliminarDocumento = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`/pais_colaborador/${id}`).then(() => {
        dispatch(listarDocumentos());
    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


export const actions = {
    listar,
    registroPais,
    modificarPais,
    leer,
    getModulo,
    paisList,
    listarPaises,
    leerPais,
    listarDocumentos,
    registroDocumento,
    eliminarDocumento
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_PAISES]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [PAIS_LIST]: (state, { pais }) => {
        return {
            ...state,
            pais,
        };
    },
    [LISTADO_PAIS]: (state, { listaP }) => {
        return {
            ...state,
            listaP,
        };
    },
    [LISTADO_DOCUMENTOS]: (state, { documentos }) => {
        return {
            ...state,
            documentos,
        };
    },
    [PAIS]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
};

export const initialState = {
    loader: false,
    modulo: [],
    pais: [],
    lectura: [],
    listaP: [],
    documentos: [],
    data: [],
    page: 1,
};

export default handleActions(reducers, initialState)
 