import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import { validate, validators } from 'validate-redux-form';
import PropTypes from 'prop-types';
import { validar } from "../../components/ValidarNit";
import NavbarModulo from '../../components/NavbarModulo';

import {
  renderField,
  renderNumber,
  SelectField,
  renderFieldCheck,
  renderFieldCheckB
} from "../../Utils/renderField/renderField";

function ProveedorForm(props) {
  const { handleSubmit, crear, lectura, obtenerPaises, datos, modulo } = props;
  const editar = window.location.href.includes('editar');
  let titulo = editar ? 'Modificar Proveedor' : 'Crear Proveedor';
  let disabled = false;
  if (crear === false && editar === false) {
    disabled = true;
    titulo = 'Ver Proveedor'
  }
  
  const tipos = [
    {
      value: 'L',
      label: 'LOCAL'
    },
    {
      value: 'E',
      label: 'EXTERIOR'
    },
    {
      value: 'P',
      label: 'PERSONA'
    },
  ];

  const nextFocusNombre = document.querySelector(
    `input[name=nombre]`
  );

  const handleChange = (e) => {
    localStorage.setItem('tipoProveedor', e.target.value);
    setEditarT(false);
    setTipoPro(e.target.value);
  }
  
  let ruta = window.location.href;
  let id_emp = ruta.split('/');
  const [identificador, setIdentificador] = useState("");
  const [error, setError] = useState('');
  const [activo, setActivo] = useState(true);
  const [tipoPro, setTipoPro] = useState(0);
  const [editarT, setEditarT] = useState(editar);

  const checkKeyPress = (e) => {
    const { key, keyCode } = e;
    if (keyCode === 13) {
      if (identificador && tipoPro === 'L') {
        if (identificador === 'CF') {
          setError('');
          nextFocusNombre.focus();
        } else {
          let valor = validar.valNit(identificador);
          if (valor === true) {
            setError('');
            nextFocusNombre.focus();
          } else {
            setError('Ingrese Nit válido')
          }
        }
      }
      if (identificador && tipoPro === 'P') {
        if (datos.validar_dpi === 'S'){
          let valor = validar.valCui(identificador);
          if (valor[0].validar === true) {
            setError('');
            nextFocusNombre.focus();
          } else {
            setError(valor[0].msj)
          }
        }
        if (datos.validar_dpi === 'N'){
          nextFocusNombre.focus();
        }
      }
      if (identificador && tipoPro === 'E') {
        nextFocusNombre.focus();
      }
    }
  };

  const divError = {
    color: 'red',
  };

  const divMsj = {
    color: 'white',
  };

  const onCheckboxClicked = (idx, isChecked) => {
    setActivo(isChecked);
  }

  const nitValidation = value =>
    value && !/^[0-9,K,c,f,C,F]*$/i.test(value) ?
      'Nit no válido' : undefined

  return (
    <div>
      <NavbarModulo
        modulo={modulo}
        titulo={titulo}
      />
      <br />
      <div className="mb-4 card card-small text-white bg-dark mb-3">
        <div className="p-0 pt-3 d-flex flex-column flex-md-row">
          <div className="d-flex flex-column flex-1 mx-4">
            <div className='row mb-2'>
              <div className='col-3'>
                {(crear === true || editar == true) &&
                  <div className="form-floating">
                    <select className="form-select" id="tipo" name="tipo"
                      value={editarT === true ? lectura.tipo_proveedor : tipoPro } onChange={handleChange} >
                      <option selected>Seleccionar...</option>
                      {tipos.map(
                        tip => <option key={tip.value} value={tip.value}
                        >{tip.label}
                        </option>)
                      }
                    </select>
                    <label className='form-label' htmlFor="tipo">Tipo proveedor</label>
                  </div>

                }
                {crear === false && editar == false &&
                  <div className="form-floating">
                    <Field
                      name="tipo_proveedor"
                      options={tipos}
                      dato={crear === true ? 0 : lectura.tipo_proveedor}
                      component={SelectField}
                      className="form-select"
                      disabled={disabled}
                      msj="Tipo proveedor"
                    />
                  </div>
                }
              </div>
              <div className='col-3'>
                {obtenerPaises &&
                    <div className="form-floating">
                      <Field
                        name="pais"
                        options={obtenerPaises}
                        crear={crear}
                        dato={crear === true ? 0 : lectura.pais}
                        component={SelectField}
                        className="form-select"
                        disabled={disabled}
                        msj="Pais"
                      />
                    </div>
                }
              </div>
              <div className='col-4'>
                {(datos.validar_nit === 'S') && crear === true &&
                  <Field
                    name="identificadorPro"
                    placeholder="Identificador"
                    className="form-control"
                    component={renderField}
                    validate={nitValidation}
                    disabled={editar ? true : disabled}
                    onChange={(e, value) => {
                      setIdentificador(value);
                    }}
                    onKeyDown={checkKeyPress}
                    msj="Identificador"
                  />
                }
                {crear === false && editar === false &&
                  <Field
                    name="identificadorPro"
                    placeholder="Identificador"
                    className="form-control"
                    component={renderField}
                    disabled={disabled}
                    msj="Identificador"
                  />
                }
                {(datos.validar_nit === 'S') && editar === true &&
                  <Field
                    name="identificadorPro"
                    placeholder="Identificador"
                    className="form-control"
                    component={renderField}
                    disabled={true}
                    msj="Identificador"
                  />
                }
                {(datos.validar_nit === 'S') && crear === true && editar === false &&
                  <div>
                    <div style={divMsj}>
                      Presione enter para validar identificador
                    </div>
                  </div>
                }
                {error &&
                  <div className='col-12'>
                    <div style={divError}>
                      {error}
                    </div>
                  </div>

                }
                {(datos.validar_nit === 'N') && crear === true &&
                  <Field
                    name="identificadorPro"
                    placeholder="Identificador"
                    className="form-control"
                    component={renderField}
                    disabled={editar ? true : disabled}
                    msj="Identificador"
                  />
                }
                {(datos.validar_nit === 'N') && editar === true &&
                  <Field
                    name="identificadorPro"
                    placeholder="Identificador"
                    className="form-control"
                    component={renderField}
                    disabled={true}
                    msj="Identificador"
                  />
                }
              </div>
            </div>
            <div className='mb-2 w-50'>
              <Field
                name="nombre"
                placeholder="Nombre"
                className="form-control"
                component={renderField}
                disabled={disabled}
                msj="Nombre"
              />
            </div>
            <div className='mb-2 w-75'>
              <Field
                name="direccionPro"
                placeholder="Direccion"
                className="form-control"
                component={renderField}
                disabled={disabled}
                msj="Dirección"
              />
            </div>
            <div className='row'>
              <div className='col-4'>
                <Field
                  name="telefonoPro"
                  placeholder="Telefono"
                  className="form-control"
                  component={renderField}
                  disabled={disabled}
                  msj="Telefono"
                />
              </div>
              <div className='col-4'>
                <Field
                  name="correo"
                  placeholder="Correo electrónico"
                  className="form-control"
                  component={renderField}
                  disabled={disabled}
                  msj="Correo electronico"
                />
              </div>
              <div className='col-4'>
                <Field
                  name="dias_credito"
                  placeholder="Dias credito"
                  className="form-control"
                  component={renderNumber}
                  disabled={disabled}
                  msj="Dias crédito"
                />
              </div>
            </div>
            <div className='row mt-2'>
              <div className='col-4'>
                <Field
                  name="limite_credito"
                  placeholder="Limite credito"
                  className="form-control"
                  component={renderNumber}
                  disabled={disabled}
                  msj="Limite crédito"
                />
              </div>

              <div className='col-4 mt-3'>
                {crear === true ? (
                  <Field
                    name="activo"
                    label="Activo"
                    checked={activo}
                    onChange={onCheckboxClicked}
                    component={renderFieldCheckB}
                  />
                ) :
                  <Field
                    disabled={disabled}
                    name="activo"
                    label="Activo"
                    component={renderFieldCheck}
                  />
                }
              </div>
            </div>
            <div className='d-flex flex-row mt-3'>
              {disabled == false &&
                <button
                  className='btn mr-2 mb-3 btn-primary'
                  onClick={handleSubmit}
                >
                  {editar ? 'Modificar' : 'Guardar'}
                </button>
              }
              <a
                href={`/#/compras/${id_emp[5]}/proveedores`}
                className='btn btn-secondary mb-3'
              >
                Regresar
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ProveedorForm.propTypes = {
  handleSubmit: PropTypes.func,
  crear: PropTypes.bool,
  lectura: PropTypes.object,
  obtenerPaises: PropTypes.object,
  datos: PropTypes.object,
  modulo: PropTypes.object,
};

export default reduxForm({
  form: 'FormProveedor',
  validate: (data) => {
    return validate(data, {
      identificador: validators.exists()('Este campo es requerido'),
      nombre: validators.exists()('Este campo es requerido'),
      tipo_proveedor: validators.exists()('Seleccione tipo'),
    });
  },
})(ProveedorForm);
