import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import Grid from '../../Utils/GridVDocumento';
import moment from 'moment';
import styled from 'styled-components';
import TablaDetalle from './NDDetalle';
import TablaVerDetalle from './NDDetalleVer';
import Swal from 'sweetalert2';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';

import {
    renderDayPickerB,
    renderField,
    SelectField,
    AsyncSelectField,
    renderCurrencyFloat,
    renderCurrencyFloatTC,
    AsyncSelectFieldIn

} from "../../Utils/renderField/renderField";

function NotaDebitoClienteForm(props) {
    const { crear, lectura, editar, estable, eliminarDocumento,
        leerDocumentos, doc, listarClientes, monedas,
        loader, bloqueo, detalleND, tipoCambio,
        fechaFinal, list_doc, periodoD, agregarLinea, detalleDocN } = props;

    let autoFocus = true;
    const [tipoM, setTipoM] = useState(0);
    const [tipoCambioD, setTipoCambioD] = useState(1.000000);

    const handleOnChange = value => {
        if (tipoM === 0 || tipoM === undefined) {
            Swal.fire({
                title: 'Nota de débito',
                text: 'Debe de seleccionar moneda',
                type: 'error',
            })
        } else {
            localStorage.setItem('TC_ND', tipoCambioD)
            leerDocumentos(value.value, fechaFinal, tipoM);
        }
    };

    const handleChangeM = e => {
        setTipoM(e.target.value)
        localStorage.setItem('moneda_TCND', e.target.value)
    };

    const estilo = {
        overflow: "auto",
        height: "200px",

    };
    
    return (
        <div>
            {crear === false && editar == false &&
                <div className='row mb-2'>
                    <div className='col-3'>
                        <Field
                            name="codigo"
                            placeholder="Codigo establecimiento"
                            className="form-control"
                            component={renderField}
                            disabled={true}
                            msj="Código establecimiento"
                        />
                    </div>
                    <div className='col-6'>
                        <Field
                            name="establecimiento"
                            placeholder="Nombre establecimiento"
                            className="form-control"
                            component={renderField}
                            disabled={true}
                            msj="Nombre establecimiento"
                        />
                    </div>
                    <div className='col-3'>
                        <Field
                            name="correlativo"
                            placeholder="Documento interno"
                            className="form-control"
                            component={renderField}
                            disabled={true}
                            msj="Documento interno"
                        />
                    </div>
                </div>
            }
            {((crear === false && editar === false) || editar === true) &&
                <div className='row mb-2'>
                    <div className='col-3'>
                        <Field
                            disabled={true}
                            name="fecha_registro"
                            component={renderDayPickerB}
                            msj="Fecha Registro"
                        />
                    </div>
                    <div className='col-3'>
                        <Field
                            name="abono"
                            placeholder="Monto"
                            className="form-control"
                            component={renderCurrencyFloat}
                            disabled={true}
                            msj="Monto"
                        />
                    </div>
                    <div className='col-3'>
                        <div className="form-floating">
                            <Field
                                name="moneda"
                                options={monedas}
                                crear={crear}
                                dato={lectura.moneda}
                                component={SelectField}
                                className="form-select"
                                disabled={true}
                                msj="Moneda"
                            />
                        </div>
                    </div>
                    <div className='col-3'>
                        <div className="form-floating">
                            <Field
                                disabled={true}
                                placeholder="Tipo de cambio"
                                name="tipo_cambio"
                                component={renderCurrencyFloatTC}
                                msj="Tipo de cambio"
                            />
                        </div>
                    </div>
                </div>
            }

            {((crear === false && editar === false) || editar === true) &&
                <div>
                    <div className='row mb-2'>
                        <div className='col-3'>
                            <Field
                                name="serie_FEL"
                                placeholder="Serie"
                                className="form-control"
                                component={renderField}
                                disabled={true}
                                msj="Serie"
                            />
                        </div>
                        <div className='col-3'>
                            <Field
                                name="numero_FEL"
                                placeholder="Serie"
                                className="form-control"
                                component={renderField}
                                disabled={true}
                                msj="Serie"
                            />
                        </div>
                        <div className='col-6'>
                            <Field
                                name="numero_autorizacion"
                                placeholder="Serie"
                                className="form-control"
                                component={renderField}
                                disabled={true}
                                msj="Numero autorización"
                            />
                        </div>


                    </div>
                </div>
            }
            {(crear === true) &&
                <div>
                    <div className='row mb-2'>
                        <div className='col-3'>
                            <div className="form-floating">
                                <input
                                    placeholder="Codigo establecimiento"
                                    type="text"
                                    autocomplete="off"
                                    name="codigo"
                                    className="form-control"
                                    value={estable.codigo}
                                    disabled={true}
                                />
                                <label className='form-label' htmlFor="codigo">
                                    Código establecimiento
                                </label>
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className="form-floating">
                                <input
                                    placeholder="Nombre establecimiento"
                                    type="text"
                                    autocomplete="off"
                                    name="nombre"
                                    className="form-control"
                                    value={estable.nombre_comercial}
                                    disabled={true}
                                />
                                <label className='form-label' htmlFor="nombre">
                                    Nombre establecimiento
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-3'>
                            <Field
                                autoFocus={autoFocus}
                                disabled={bloqueo}
                                name="fecha_registro"
                                component={renderDayPickerB}
                                msj="Fecha Registro"
                            />
                        </div>
                        <div className='col-3'>
                            <Field
                                name="monto"
                                placeholder="Monto"
                                className="form-control"
                                component={renderCurrencyFloat}
                                disabled={bloqueo}
                                msj="Monto"
                            />
                        </div>
                        <div className='col-3'>
                            <div className="form-floating">
                                <select className="form-select" id="moneda" name="moneda"
                                    value={tipoM} onChange={handleChangeM} >
                                    <option selected>Seleccionar...</option>
                                    {monedas.map(
                                        tip => <option key={tip.value} value={tip.value}
                                        >{tip.label}
                                        </option>)
                                    }
                                </select>
                                <label className='form-label' htmlFor="moneda">Moneda</label>
                            </div>
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-3'>
                            <div className="form-floating">
                                <NumberFormat
                                    className={classNames('form-control')}
                                    decimalScale={6}
                                    fixedDecimalScale
                                    placeholder="Tipo de cambio"
                                    value={tipoCambioD}
                                    thousandSeparator
                                    onValueChange={(values) => {
                                        setTipoCambioD(values.value)
                                        localStorage.setItem('TC_ND', values.value)
                                    }}
                                />
                                <label className='form-label' htmlFor="cuenta">
                                    Tipo de cambio
                                </label>
                            </div>
                        </div>
                        <div className='col-3 mt-2'>
                            <button
                                className='btn mr-2 mb-3 btn-secondary'
                                onClick={() => {
                                    let tc = tipoCambio(periodoD);
                                    if (tc === undefined) {
                                        setTipoCambioD('1.000000');
                                        localStorage.setItem('TC_ND', values.value)
                                    } else {
                                        tc.then(d => {
                                            setTipoCambioD(d);
                                            localStorage.setItem('TC_ND', values.value)
                                        });
                                    }
                                }}
                            >
                                Buscar tipo de cambio
                            </button>
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <Field
                            name="cliente_documento"
                            placeholder="Cliente..."
                            className="form-control-find"
                            component={AsyncSelectField}
                            loadOptions={listarClientes}
                            onChange={handleOnChange}
                            msj="Cliente"
                        />
                    </div>
                </div>
            }
            {((crear === false && editar == false) || editar === true) &&
                <div className='mb-2'>
                    <Field
                        name="clienteN"
                        placeholder="Cliente"
                        className="form-control"
                        component={renderField}
                        disabled={true}
                        msj="Cliente"
                    />
                </div>
            }
            <div className='mb-2'>
                <Field
                    name="descripcion"
                    placeholder="Descripcion"
                    component={renderField}
                    className="form-control"
                    disabled={bloqueo}
                    msj="Descripción"
                />
            </div>
            {(crear === true) &&
                <div>
                    <h5 className="p-2 bg-secondary title mt-1">Documentos para aplicar nota de débito</h5>
                    <div style={estilo}>
                        <Grid
                            hover
                            striped
                            data={doc}
                            loading={loader}
                            headerStyle={{ background: 'black' }}

                        >
                            <TableHeaderColumn
                                isKey
                                dataField="fecha"
                                dataSort
                                dataFormat={(cell, row) => {
                                    return moment(cell).format("DD/MM/YYYY");
                                }}
                                thStyle={
                                    { color: 'white' }}
                                tdStyle={
                                    { color: 'white' }}
                            >
                                Fecha
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="serie"
                                dataSort
                                thStyle={
                                    { color: 'white' }}
                                tdStyle={
                                    { color: 'white' }}
                            >
                                Serie
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="numero"
                                dataSort
                                thStyle={
                                    { color: 'white' }}
                                tdStyle={
                                    { color: 'white' }}
                            >
                                Numero
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="clienteN"
                                width="350"
                                dataSort
                                thStyle={
                                    { color: 'white' }}
                                tdStyle={
                                    { color: 'white' }}
                            >
                                Cliente
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="total"
                                headerAlign="center"
                                dataAlign='right'
                                dataFormat={(cell, row) => {
                                    if (parseFloat(cell) === 0.00) {
                                        return ''
                                    } else {
                                        return new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(cell);
                                    }
                                }}
                                dataSort
                                thStyle={
                                    { color: 'white' }}
                                tdStyle={
                                    { color: 'white' }}
                            >
                                Total
                            </TableHeaderColumn>
                        </Grid>
                    </div>
                    <br />
                    <div className='row mb-2'>
                        <div className='col-8 mt-2'>
                            <Field
                                name="documento"
                                placeholder="Documento..."
                                className="form-control-find"
                                loadOptions={list_doc}
                                component={AsyncSelectFieldIn}
                                msj="Documento"
                            />
                        </div>
                        <div className='col-3 mt-2'>
                            <div className="form-floating">
                                <Field
                                    name="abono"
                                    placeholder="Monto nota de débito"
                                    className="form-control"
                                    component={renderCurrencyFloat}
                                    disabled={false}
                                    msj="Monto nota de débito"
                                />
                            </div>
                        </div>
                    </div>
                    {(crear === true) &&
                        <Contenido>
                            <button
                                className='btn mr-2 mb-3 btn-primary'
                                onClick={() => agregarLinea(periodoD, tipoM)}
                            >
                                Agregar documento
                            </button>
                        </Contenido>
                    }
                    {(crear === true) &&
                        <div>
                            <h5 className="p-2 bg-secondary title mt-1">Documentos agregados</h5>
                            <div style={estilo}>
                                <TablaDetalle
                                    detalle={detalleDocN}
                                    eliminarDocumento={eliminarDocumento}
                                />
                            </div>
                        </div>
                    }
                </div>
            }
            {((crear === false && editar === false) || editar === true) &&
                <div>
                    <h5 className="p-2 bg-secondary title mt-1">Documentos agregados</h5>
                    <div style={estilo}>
                        <TablaVerDetalle
                            detalle={detalleND}
                        />
                    </div>
                </div>
            }
        </div>

    );
}

NotaDebitoClienteForm.propTypes = {
    funcionEnvio: PropTypes.func,
    crear: PropTypes.bool,
    editar: PropTypes.bool,
    lectura: PropTypes.object,
    listaM: PropTypes.object,
    listarClientes: PropTypes.object,
    leerDocumentos: PropTypes.func,
    doc: PropTypes.object,
    loader: PropTypes.bool,
    bloqueo: PropTypes.bool,
    detalleDoc: PropTypes.object,
    eliminarDocumento: PropTypes.func,
    cuentasBan: PropTypes.object,
    tipos: PropTypes.array,
    monedas: PropTypes.array,
    list_doc: PropTypes.object,
    periodoD: PropTypes.object,
    agregarLinea: PropTypes.func,
    detalleDocN: PropTypes.object,
    estable: PropTypes.object,
};

export default reduxForm({
    form: 'FormNotaDebitoCliente',
})(NotaDebitoClienteForm);

const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;