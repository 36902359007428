import React, { Component } from 'react';
import Formulario from './EstablecimientoForm';
import LoadMask from "../Utils/LoadMask/LoadMask";

class Establecimiento extends Component{
    state = {
        crear: true
    }

    componentDidMount = () => {
        const { match, leer, mostrarEmpresa } = this.props;
        const id = match.params.id;
        if (id){
            this.setState({crear: false});
            leer(id);
        }else{
            mostrarEmpresa();
        }
        
    }
    render(){
        const { registroEstablecimiento, modificarEstablecimiento, loader } = this.props;
        const { crear } = this.state;
        const funcionEnvio = crear ? registroEstablecimiento : modificarEstablecimiento;
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    onSubmit={funcionEnvio}
                    crear={crear}
                />
            </LoadMask>
        );
    }
}

export default Establecimiento;