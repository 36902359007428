import React, { Component } from 'react';
import Formulario from './SeleccionarFormPeriodo';


class SeleccionarPeriodo extends Component{

    componentDidMount = () => {
        const { listarPeriodos } = this.props;
        listarPeriodos();
    }
    render(){
        const { periodos_nomina } = this.props;
        return(
           
            <Formulario
                periodoL={ periodos_nomina } 
            />
           
        );
    } 
}

export default SeleccionarPeriodo;