import React, { Component } from 'react';
import Formulario from './CuentaBancariaForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";
import { getModulo } from '../../../../redux/modules/bancos/cuentaBancaria';

class CuentaBancaria extends Component{
    state = {
        crear: true,
    }

    componentDidMount = () => {
        const { match, leer, listarBancos, listarMonedas, 
            parametrosEmpresa, getModulo } = this.props;
        const id = match.params.id;
        if (id){
            this.setState({crear: false});
            leer(id);
        }
        listarBancos();
        listarMonedas();
        parametrosEmpresa();
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        getModulo(modulo[5],modulo[4]);
    }
    
    render(){
        const { registroCuenta, modificarCuenta, loader, lectura, banco, 
            listaM, listarCuentas, listarCC, datos, modulo  } = this.props;
        const { crear } = this.state;
        const funcionEnvio = crear ? registroCuenta : modificarCuenta;
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    onSubmit = {funcionEnvio}
                    crear = {crear}
                    lectura = {lectura}
                    bancos = {banco}
                    listaM = {listaM}
                    listarCuentas = {listarCuentas}
                    listarCC = {listarCC}
                    datos = {datos}
                    modulo = {modulo}
                />
            </LoadMask>
        );
    }
}

export default CuentaBancaria
