import React from "react";
import FormDialogModal from "../../../components/FormDialogModal";
import GenBtn from "../../../components/GenBtn";
import {
    FicComboBox,
    FicInput,
    FicRadio,
    FicCheckbox,
    FicDatePicker,
    FicDatePickerSI,
} from "../../../Utils/FormInputComponents/index";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import styled from "styled-components";

const FormContent = styled.div`
    display: flex;
    overflow: visible;
    flex-direction: column;
    // gap: 15px;
    // min-width: 28rem;
    min-height: 8rem;
    padding: 10px;
`;

const InputsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    padding: 3rem;
`;

const schema = yup
    .object({
        fecha_final: yup.string().required("Ingrese una fecha"),
        fecha_inicial: yup.string().required("Ingrese una opcion"),
    })
    .required();

function ListadoDeVentas({
    dialogRef,
    onSubmit,
    listarVendedoresForComboBox,
    ...props
}) {
    // onSubmit({estado: { label: "TODOS", value: "TODOS" }});
    const {
        handleSubmit,
        reset,
        control,
        formState: { errors },
        setFocus,
    } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
        defaultValues: {
            fecha_final: "",
            fecha_inicial: "",
            vendedor: { value: "", label: "" },
        },
    });

    function submitFunc(data) {
        onSubmit(data);
        reset();
    }

    return (
        <FormDialogModal
            style={{ overflow: "visible", minWidth: "340px" }}
            dialogRef={dialogRef}
            onSubmit={handleSubmit(submitFunc)}
            title={"Listado de ventas"}
        >
            <FormContent>
                <InputsContainer>
                    <FicDatePickerSI
                        autofocus
                        control={control}
                        label="Fecha inicial"
                        name="fecha_inicial"
                    />
                    <FicDatePickerSI
                        control={control}
                        label="Fecha final"
                        name="fecha_final"
                    />
                    <FicComboBox
                        componentStyle={{ minWidth: "255px" }}
                        control={control}
                        loadOptions={listarVendedoresForComboBox}
                        label="Vendedor"
                        name="vendedor"
                        placeholder="Dejar en blanco para listar todas las ventas"
                    />
                </InputsContainer>
                <div className="d-flex justify-content-end mt-auto">
                    <GenBtn
                        type="submit"
                        style={{
                            width: "90px",
                            height: "40px",
                            marginBottom: "8px",
                        }}
                    >
                        Generar
                    </GenBtn>
                </div>
            </FormContent>
        </FormDialogModal>
    );
}

export default ListadoDeVentas;
