import React, { Component } from 'react';
import Formulario from './RegistroMarcaFormVer';
import LoadMask from "../../Utils/LoadMask/LoadMask";

class RegistroMarca extends Component{
    state = {
        crear: true,
    }

    componentDidMount = () => {
        const { match, leer, getModulo, listarAgentes, listarStatus,
            listarTitulares, listarPaises, listarComercializada,
            listarPrincipio, detalleObs } = this.props;
        const id = match.params.id;
        if (id){
            this.setState({crear: false});
            leer(id);
        }
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        getModulo(modulo[5],modulo[4])
        listarAgentes();
        listarStatus();
        listarTitulares();
        listarComercializada();
        listarPrincipio();
        listarPaises();
    }
    setLogo = (logo) => {
        this.setState({logo});
    };

    registroMarca = (data) => {
        const { registroMarca } = this.props;
        registroMarca( {...data, logo: null},
        [{ "file": this.state.logo, "name": 'logo' },])
    }
    
    modificarMarca = (data) => {
        const { modificarMarca } = this.props;
        modificarMarca( {...data, logo: null},
        [{ "file": this.state.logo, "name": 'logo' },])
    }

    render(){
        const { loader, modulo, agentes, lectura,
            listaPais, paises, status, titulares, detalleObs,
            comercial, principio_activo, detalleHis } = this.props;
        const { crear } = this.state;
        const funcionEnvio = crear ? this.registroMarca : this.modificarMarca;
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    onSubmit = {funcionEnvio}
                    crear = {crear}
                    modulo = {modulo}
                    agentes = {agentes}
                    listaPais = {listaPais}
                    paises = {paises}
                    status = {status}
                    setLogo = {this.setLogo}
                    titulares = {titulares}
                    comercial = {comercial}
                    principio_activo = {principio_activo}
                    lectura = {lectura}
                    detalleHis = {detalleHis}
                    detalleObs = {detalleObs}
                />
            </LoadMask>
        );
    }
}


export default RegistroMarca;
