import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';
import { validar } from "../../../common/components/components/ValidarNit";

const LISTADO_PROVEEDOR = 'LISTADO_PROVEEDOR';
const GUARDAR_PROVEEDOR = 'GUARDAR_PROVEEDOR';
const PARAMETRO = 'PARAMETRO';
const LISTADO_PAISES = 'LISTADO_PAISES';
//const PAGE = 'PAGE';
const LOADER = 'LOADER';
const MODULO = 'MODULO';

/*const setPage = page => ({
    type: PAGE,
    page,
});*/

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    dispatch(setLoader(true));
    api.get('/proveedor', params).then((response)=>{
        dispatch({ type: LISTADO_PROVEEDOR, data: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar proveedores',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const registroProveedor = () => (dispatch, getStore) => {
    const data = getStore().form.FormProveedor.values;
    let tipo = localStorage.getItem('tipoProveedor')
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    if (data.validar_nit === 'S' && tipo === 'L'){
        if (data.identificadorPro === 'CF'){
            const formData = {
                identificador: data.identificadorPro,
                nombre: data.nombre,
                direccion: data.direccionPro,
                telefono: data.telefonoPro,
                correo: data.correo,
                limite: data.limite_credito,
                tipo_proveedor: localStorage.getItem('tipoProveedor'),
                dias_credito: data.dias_credito,
                pais: data.pais,
                modulo: 'CMP',
                empresa: id_emp[5]
            }
            dispatch(setLoader(true));
            api.post('/proveedor/', formData).then((response) => {
                NotificationManager.success(
                    'Proveedor creado',
                    'Exito',
                    3000
                );
                let ruta = `/compras/${id_emp[5]}/proveedores`
                dispatch(push(ruta));
            }).catch((error) => {
                NotificationManager.error(
                    error.msj,
                    'Error',
                    8000
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }else{
            let valid = validar.valNit(data.identificadorPro);
            if (valid === false){
                Swal.fire({
                    title: 'Proveedor',
                    text: 'Ingrese Nit válido',
                    type: 'error',
                })
            }else{
                const formData = {
                    identificador: data.identificadorPro,
                    nombre: data.nombre,
                    direccion: data.direccionPro,
                    telefono: data.telefonoPro,
                    correo: data.correo,
                    limite: data.limite_credito,
                    tipo_proveedor: localStorage.getItem('tipoProveedor'),
                    dias_credito: data.dias_credito,
                    pais: data.pais,
                    modulo: 'CMP',
                    empresa: id_emp[5]
                }
                dispatch(setLoader(true));
                api.post('/proveedor/', formData).then((response) => {
                    NotificationManager.success(
                        'Proveedor creado',
                        'Exito',
                        3000
                    );
                    let ruta = `/compras/${id_emp[5]}/proveedores`
                    dispatch(push(ruta));
                }).catch((error) => {
                    NotificationManager.error(
                        error.msj,
                        'Error',
                        8000
                    );
                }).finally(() => {
                    dispatch(setLoader(false));
                });
            }
        }
    }
    if (data.validar_dpi === 'S' && tipo === 'P'){
        let valid = validar.valCui(data.identificadorPro);
        if (valid === false){
            Swal.fire({
                title: 'Proveedor',
                text: 'DPI con formato inválido',
                type: 'error',
            })
        }else{
            const formData = {
                identificador: data.identificadorPro,
                nombre: data.nombre,
                direccion: data.direccionPro,
                telefono: data.telefonoPro,
                correo: data.correo,
                limite: data.limite_credito,
                tipo_proveedor: localStorage.getItem('tipoProveedor'),
                dias_credito: data.dias_credito,
                pais: data.pais,
                modulo: 'CMP',
                empresa: id_emp[5]
            }
            dispatch(setLoader(true));
            api.post('/proveedor/', formData).then((response) => {
                NotificationManager.success(
                    'Proveedor creado',
                    'Exito',
                    3000
                );
                let ruta = `/compras/${id_emp[5]}/proveedores`
                dispatch(push(ruta));
            }).catch((error) => {
                NotificationManager.error(
                    error.msj,
                    'Error',
                    8000
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }
    }
    if (data.validar_nit === 'S' && tipo === 'E'){
        const formData = {
            identificador: data.identificadorPro,
            nombre: data.nombre,
            direccion: data.direccionPro,
            telefono: data.telefonoPro,
            correo: data.correo,
            limite: data.limite_credito,
            tipo_proveedor: localStorage.getItem('tipoProveedor'),
            dias_credito: data.dias_credito,
            pais: data.pais,
            modulo: 'CMP',
            empresa: id_emp[5]
        }
        dispatch(setLoader(true));
        api.post('/proveedor/', formData).then((response) => {
            NotificationManager.success(
                'Proveedor creado',
                'Exito',
                3000
            );
            let ruta = `/compras/${id_emp[5]}/proveedores`
            dispatch(push(ruta));
        }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                8000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
    if (data.validar_nit === 'N' && tipo === 'L'){
        const formData = {
            identificador: data.identificadorPro,
            nombre: data.nombre,
            direccion: data.direccionPro,
            telefono: data.telefonoPro,
            correo: data.correo,
            limite: data.limite_credito,
            tipo_proveedor: localStorage.getItem('tipoProveedor'),
            dias_credito: data.dias_credito,
            pais: data.pais,
            modulo: 'CMP',
            empresa: id_emp[5]
        }
        dispatch(setLoader(true));
        api.post('/proveedor/', formData).then((response) => {
            NotificationManager.success(
                'Proveedor creado',
                'Exito',
                3000
            );
            let ruta = `/compras/${id_emp[5]}/proveedores`
            dispatch(push(ruta));
        }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                8000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
    if (data.validar_nit === 'N' && tipo === 'E'){
        const formData = {
            identificador: data.identificadorPro,
            nombre: data.nombre,
            direccion: data.direccionPro,
            telefono: data.telefonoPro,
            correo: data.correo,
            limite: data.limite_credito,
            tipo_proveedor: localStorage.getItem('tipoProveedor'),
            dias_credito: data.dias_credito,
            pais: data.pais,
            modulo: 'CMP',
            empresa: id_emp[5]
        }
        dispatch(setLoader(true));
        api.post('/proveedor/', formData).then((response) => {
            NotificationManager.success(
                'Proveedor creado',
                'Exito',
                3000
            );
            let ruta = `/compras/${id_emp[5]}/proveedores`
            dispatch(push(ruta));
        }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                8000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
    if (data.validar_dpi === 'N' && tipo === 'P'){
        const formData = {
            identificador: data.identificadorPro,
            nombre: data.nombre,
            direccion: data.direccionPro,
            telefono: data.telefonoPro,
            correo: data.correo,
            limite: data.limite_credito,
            tipo_proveedor: localStorage.getItem('tipoProveedor'),
            dias_credito: data.dias_credito,
            pais: data.pais,
            modulo: 'CMP',
            empresa: id_emp[5]
        }
        dispatch(setLoader(true));
        api.post('/proveedor/', formData).then((response) => {
            NotificationManager.success(
                'Proveedor creado',
                'Exito',
                3000
            );
            let ruta = `/compras/${id_emp[5]}/proveedores`
            dispatch(push(ruta));
        }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                8000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
}

export const modificarProveedor = () => (dispatch, getStore) => {
    const datos = getStore().form.FormProveedor.values;
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const id = datos.id;
    const formData = {
        id: id,
        identificador: datos.identificador,
        nombre: datos.nombre,
        tipo_proveedor: datos.tipo_proveedor,
        direccion: datos.direccionPro,
        telefono: datos.telefonoPro,
        correo: datos.correo,
        limite: datos.limite_credito,
        dias_credito: datos.dias_credito,
        pais: datos.pais,
        activo: datos.activo,
        empresa: id_emp[5]
    }
    dispatch(setLoader(true));
    api.put(`/proveedor/${id}/`, formData).then((response) => {
        NotificationManager.success(
            'Proveedor modificado correctamente',
            'Exito',
            3000
        );
        let ruta = `/compras/${id_emp[5]}/proveedores`
        dispatch(push(ruta));
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al modificar proveedor',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/proveedor/${id}`).then((response) => {
        dispatch({type: GUARDAR_PROVEEDOR, lectura: response });
        response.identificadorPro = response.identificador
        response.direccionPro = response.direccion
        response.telefonoPro = response.telefono
        dispatch(initializeForm('FormProveedor', response ));
    }).catch((error)=>{
        console.log("error: ", error)
        NotificationManager.error(
            'Ocurrió un error al consultar proveedor',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

const listarPaises = () => (dispatch) => {
    return api.get("/paises").then(response=>{
        const pais = [];
        response.results.forEach(item=>{
            pais.push({
                value: item.id,
                label: item.pais
            })
        })
        dispatch({ type: LISTADO_PAISES, listaP: pais });
    }).catch(error=>{
        NotificationManager.error(
            'Ocurrió un error al listar paises',
            'Error',
            8000
        );
    })
}

export const parametrosEmpresa = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let params = {id}
    dispatch(setLoader(true));
    api.get('/empresa/empresa_parametros', params).then((response) => {
        dispatch(initializeForm('FormProveedor', response ));
        dispatch({type: PARAMETRO, datos: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar parametros',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


export const actions = {
    listar,
    registroProveedor,
    modificarProveedor,
    listarPaises,
    leer,
    parametrosEmpresa,
    getModulo
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_PROVEEDOR]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [LISTADO_PAISES]: (state, { listaP }) => {
        return {
            ...state,
            listaP,
        };
    },
    [GUARDAR_PROVEEDOR]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
    [PARAMETRO]: (state, { datos }) => {
        return {
            ...state,
            datos,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
};

export const initialState = {
    loader: false,
    lectura: [],
    listaP: [],
    datos: [],
    modulo: [],
    data: [],
    /*data: {
        results: [],
        count: 0,
    },*/
    page: 1,
};

export default handleActions(reducers, initialState)