import React, { useState, useEffect } from "react";
import {
    Button,
    Calendar,
    CalendarCell,
    CalendarGrid,
    CalendarGridBody,
    CalendarGridHeader,
    CalendarHeaderCell,
    DateInput,
    DatePicker,
    DateSegment,
    Dialog,
    Group,
    Heading,
    Label,
    Popover,
    Text,
} from "react-aria-components";
import { I18nProvider } from "@react-aria/i18n";
import { parseDate } from "@internationalized/date";
import { useController } from "react-hook-form";
import "./FicDatePicker.css";

function FicDatePickerTest({
    label,
    description,
    errorMessage,
    control,
    name,
    noBtn,
    nonForm,
    ...props
}) {
    if (nonForm) {
        return (
            <DatePicker
                {...props}
                isInvalid={errorMessage ? true : false}
                onChange={props.onChange}
            >
                <Label>{label}</Label>
                <Group>
                    <DateInput>
                        {(segment) => <DateSegment segment={segment} />}
                    </DateInput>
                    {noBtn ? "" : <Button>▼</Button>}
                </Group>
                {description && <Text slot="description">{description}</Text>}
                {errorMessage && (
                    <Text slot="errorMessage">{errorMessage}</Text>
                )}
                <Popover>
                    <Dialog>
                        <Calendar>
                            <header>
                                <Button slot="previous">&#8249;</Button>
                                <Heading />
                                <Button slot="next">&#8250;</Button>
                            </header>
                            <CalendarGrid>
                                {(date) => <CalendarCell date={date} />}
                            </CalendarGrid>
                            {errorMessage && (
                                <Text slot="errorMessage">{errorMessage}</Text>
                            )}
                        </Calendar>
                    </Dialog>
                </Popover>
            </DatePicker>
        );
    }
    const { field, fieldState } = useController({ control, name });
    const { onChange: fieldOnChange, value, ...rest } = field;
    const parsedValue = value ? parseDate(value) : null;
    return (
        <DatePicker
            {...props}
            {...rest}
            value={parsedValue}
            isInvalid={fieldState.invalid}
            onChange={(e) => {
                const date = e ? e.toString() : "";
                props.onChange ? props.onChange(e) : "";
                fieldOnChange(date);
            }}
        >
            <Label>{label}</Label>
            <Group>
                <DateInput>
                    {(segment) => <DateSegment segment={segment} />}
                </DateInput>
                {noBtn ? "" : <Button>▼</Button>}
            </Group>
            {description && <Text slot="description">{description}</Text>}
            {fieldState.error && (
                <Text slot="errorMessage">{fieldState.error.message}</Text>
            )}
            {errorMessage && <Text slot="errorMessage">{errorMessage}</Text>}
            <Popover>
                <Dialog>
                    <Calendar>
                        <header>
                            <Button slot="previous">&#8249;</Button>
                            <Heading />
                            <Button slot="next">&#8250;</Button>
                        </header>
                        <CalendarGrid>
                            {(date) => <CalendarCell date={date} />}
                        </CalendarGrid>
                        {fieldState.error && (
                            <Text slot="errorMessage">
                                {fieldState.error.message}
                            </Text>
                        )}
                    </Calendar>
                </Dialog>
            </Popover>
        </DatePicker>
    );
}
export default function FicDatePickeri18n(props) {
    const browserLanguage = window.navigator.language;
    return (
        <I18nProvider locale={browserLanguage}>
            <FicDatePickerTest {...props} />
        </I18nProvider>
    );
}
