import React, { Component, useState } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import { validate, validators } from 'validate-redux-form';
import {
  renderField,
} from "../../Utils/renderField/renderField";

class NivelesForm extends Component {

  render() {
    const { handleSubmit } = this.props;
    let nv = localStorage.getItem('niveles');
    let tot = localStorage.getItem('niveles_cuentas');
    let disabled = false;
    if (nv === 'true' && tot === '1') {
      disabled = true;
    }
    if (nv === 'true' && parseInt(tot) >= 2 ){
      disabled = true;
    }
    let titulo = disabled ? 'Ver Nivel' : 'Crear Nivel';
    if (nv === 'true' && tot === '0') {
      titulo = 'Modificar Nivel';
    }
    let autoFocus = true;
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    return (
      <div>
        <form onSubmit={handleSubmit}>
          <br />
          <h3 className="p-2 bg-secondary title">{titulo}</h3>
          <div className="mb-4 card card-small text-white bg-dark mb-3">
            <div className="p-0 pt-3 d-flex flex-column flex-md-row">
              <div className="d-flex flex-column flex-1 mx-4">
                <div className='row mb-2 w-75'>
                  <div className='col-4'>
                    <div className='mb-2'>
                      <Field
                        autoFocus={autoFocus}
                        type="number"
                        name="nivel_uno"
                        placeholder="Nivel Uno"
                        component={renderField}
                        className="form-control"
                        msj="Nivel Uno"
                        disabled={disabled}
                      />
                    </div>
                  </div>
                  <div className='col-4'>
                    <div className='mb-2'>
                      <Field
                        type="number"
                        name="nivel_dos"
                        placeholder="Nivel Dos"
                        component={renderField}
                        className="form-control"
                        msj="Nivel Dos"
                        disabled={disabled}
                      />
                    </div>
                  </div>
                  <div className='col-4'>
                    <div className='mb-2'>
                      <Field
                        type="number"
                        name="nivel_tres"
                        placeholder="Nivel Tres"
                        component={renderField}
                        className="form-control"
                        msj="Nivel Tres"
                        disabled={disabled}
                      />
                    </div>
                  </div>
                </div>
                <div className='row mb-2 w-75'>
                  <div className='col-4'>
                    <div className='mb-2'>
                      <Field
                        type="number"
                        name="nivel_cuatro"
                        placeholder="Nivel Cuatro"
                        component={renderField}
                        className="form-control"
                        msj="Nivel Cuatro"
                        disabled={disabled}
                      />
                    </div>
                  </div>
                  <div className='col-4'>
                    <div className='mb-2'>
                      <Field
                        type="number"
                        name="nivel_cinco"
                        placeholder="Nivel Cinco"
                        component={renderField}
                        className="form-control"
                        msj="Nivel Cinco"
                        disabled={disabled}
                      />
                    </div>
                  </div>
                  <div className='col-4'>
                    <div className='mb-2'>
                      <Field
                        type="number"
                        name="nivel_seis"
                        placeholder="Nivel Seis"
                        component={renderField}
                        className="form-control"
                        msj="Nivel Seis"
                        disabled={disabled}
                      />
                    </div>
                  </div>
                </div>
                <div className='d-flex flex-row mt-3'>
                  {disabled == false && nv === 'true' &&
                    <button
                      className={'btn mr-2 mb-3 btn-primary'}
                      type='submit'
                    >
                      Modificar
                    </button>
                  }
                  {disabled == false && nv === 'false' &&
                    <button
                      className={'btn mr-2 mb-3 btn-primary'}
                      type='submit'
                    >
                      Guardar
                    </button>
                  }
                  <a
                    href={`/#/contabilidad/${id_emp[5]}`}
                    className='btn btn-secondary mb-3'
                  >
                    Regresar
                  </a>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default reduxForm({
  form: 'FormNivel',
  validate: (data) => {
    return validate(data, {
      nivelUno: validators.exists()('Este campo es requerido'),
      nivelDos: validators.exists()('Este campo es requerido'),
    });
  },
})(NivelesForm);
