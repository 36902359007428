import React, { Component } from 'react';
import Formulario from './DescuentosForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";
const valEmp = __CONFIG__.empresa;

class HorasExtras extends Component{
    state = {
        crear: true,
    }

    componentDidMount = () => {
        const { match, getModulo, leer, leerPeriodoNomina, listarColaborador,
            listarTiposDesc } = this.props;
        const id = match.params.id;
        if (id){
            this.setState({crear: false});
            leer(id);
        }

        let ruta = window.location.href;
        let modulo = ruta.split('/');
        
        if (valEmp === 'fage'){
            getModulo(modulo[6], modulo[5])
            leerPeriodoNomina(modulo[6],modulo[8]);
        }else{
            getModulo(modulo[5],modulo[4]);
            leerPeriodoNomina(modulo[5],modulo[7]);
        }
        listarColaborador();
        listarTiposDesc();
    }

    render(){
        const { loader, modulo, periodoN, correlativo, 
            periodoId, emp, hora_extra, datosColaborador, registroDescuento,
            lectura, modificarDescuento, tipos } = this.props;
        const { crear } = this.state;
        
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    modulo = {modulo}
                    crear = {crear}
                    periodoN = {periodoN}
                    correlativo = {correlativo}
                    periodoId = {periodoId}
                    emp = {emp}
                    hora_extra = {hora_extra}
                    datosColaborador = {datosColaborador}
                    registroDescuento = {registroDescuento}
                    modificarDescuento = {modificarDescuento}
                    lectura = {lectura}
                    tipos = {tipos}
                />
            </LoadMask>
        );
    }
}

export default HorasExtras;