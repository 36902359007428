import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
// import { SidebarDataUserAutoVentas } from '../Sidebar/SideBarDataUserAutoVentas';
import { BaseSidebarDataUser } from "../Sidebar/BaseSideBarDataUsercopy";
import { IconContext } from "react-icons";
import styled from "styled-components";
import SubMenu from "../Sidebar/SubmenuInventario";
import PropTypes from "prop-types";
const defaultAvatar = require("assets/img/avatar-placeholder.png");
import MenuItems from "./NavbarMenuImp";
import MenuItemsUser from "./NavbarMenuUserImp";
import { useEffect } from "react";

function BaseNavBar({ links, ...props }) {
    const { logOut, user, empresaData } = props;
    const [navBarLinks, setNavBarLinks] = useState([]);

    useEffect(() => {
        if (links instanceof Function) {
            links().then((res) => {
                setNavBarLinks(res);
            });
        } else {
            setNavBarLinks(links);
        }
    }, [links]);
    // const [sidebar, setSidebar] = useState(false);
    // const showSidebar = () => {
    //     setSidebar(!sidebar);
    // };
    const StyledLink = styled(Link)`
        height: 100%;
    `;

    return (
        <React.Fragment>
            <NavBar>
                {/* <NavIcon to="#">
                    <FaIcons.FaBars onClick={showSidebar} />
                </NavIcon> */}
                <StyledLink to="/empresas">
                    <Image
                        src={require("assets/img/SIFW-LOGO-Red.png")}
                        alt="Logo"
                    />
                </StyledLink>
                <div className="titleNav">
                    <Titulo>{empresaData.razon_social}</Titulo>
                </div>
                <div className="imageBoxLogo d-table m-auto">
                    {empresaData.logo && (
                        <ImageLogo src={empresaData.logo} alt="Logo" />
                    )}
                </div>
                <NavBarMenuUser>
                    {BaseSidebarDataUser.map((menu, index) => {
                        return (
                            <MenuItemsUser
                                pitems={menu}
                                key={index}
                                pimg={
                                    user.avatar
                                        ? __CONFIG__.api + user.avatar
                                        : defaultAvatar
                                }
                                puser={user.username}
                                plogOut={logOut}
                            />
                        );
                    })}
                </NavBarMenuUser>
            </NavBar>
            <NavBarMenu>
                {/* <ul className="menus flex-row navbar-nav"> */}
                {navBarLinks &&
                    navBarLinks.map((menu, index) => {
                        return <MenuItems items={menu} key={index} />;
                    })}
                {/* </ul> */}
            </NavBarMenu>
            {/* <SidebarNav sidebar={sidebar}>
                <SiderbarWrap>
                    <NavIcon to="#">
                        <AiIcons.AiOutlineClose onClick={showSidebar} />
                    </NavIcon>
                    {navBarLinks.map((item, index) => {
                        return <SubMenu item={item} key={index} />;
                    })}
                </SiderbarWrap>
            </SidebarNav> */}
        </React.Fragment>
    );
}

BaseNavBar.propTypes = {
    logOut: PropTypes.func,
    user: PropTypes.object,
};

export default BaseNavBar;

const NavBar = styled.div`
    background: linear-gradient(to right, #333333, #121212);
    // background-color: #333333;
    height: 61px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: "fff";

    body {
        background-color: #454545;
    }
`;

const NavBarMenu = styled.div`
    background-color: #121212;
    height: 45px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    align-items: center;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.5);
    overflow-x: auto;
    overflow-y: hidden;

    scrollbar-width: thin;
    scrollbar-color: #bd0034 #0c0809;

    ::-webkit-scrollbar {
        width: 8px;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #bd0034;
    }
    ::-webkit-scrollbar-track {
        background: #0c0809;
    }
`;

const NavBarMenuUser = styled.div`
    display: flex;
    padding: 0;
    background-color: transparent;
    position: relative;
    height: 61px;
    width: 450px;
    display: flex;

    ::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: #bd0034;
        height: 100%;
        width: 2px;
    }
`;

const Titulo = styled.div`
    color: white;
    padding-top: 5px;
    padding-left: 10px;
    font-weight: bold;
    font-size: 1.5rem;
`;

const Image = styled.img`
    margin: 0.25rem;
    max-height: 80%;
    display: block;
    @media (max-width: 402px) {
        display: none;
    }
`;
const ImageLogo = styled.img`
    max-width: 100%;
    max-height: 100%;
    display: inline-block;
    position: absolute;
    bottom: 0;
    margin: 0 auto;
    left: 0;
    right: 0;
    @media (max-width: 402px) {
        display: none;
    }
`;
