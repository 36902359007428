import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";

const LISTADO_BANCOS = 'LISTADO_BANCOS';
const GUARDAR_BANCO = 'GUARDAR_BANCO';
const LOADER = 'LOADER';
const MODULO = 'MODULO';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    //const params = { page, id };
    const params = { id };
    dispatch(setLoader(true));
    api.get('/banco', params).then((response)=>{
        dispatch({ type: LISTADO_BANCOS, data: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar bancos',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const registroBanco = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormBancos.values;
    const formData = {
        banco: data.banco,
        empresa: id_emp[5]
    }
    dispatch(setLoader(true));
    api.post('/banco/', formData).then(() => {
        NotificationManager.success(
            'Banco creado',
            'Exito',
            3000
        );
        let ruta = `/bancos/${id_emp[5]}/bancos`
        dispatch(push(ruta));
    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const modificarBanco = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const datos = getStore().form.FormBancos.values;
    const id = datos.id;
    const formData = {
        id: id,
        banco: datos.banco,
        empresa: id_emp[5]
    }
    dispatch(setLoader(true));
    api.put(`/banco/${id}/`, formData).then(() => {
        NotificationManager.success(
            'Banco modificado correctamente',
            'Exito',
            3000
        );
        let ruta = `/bancos/${id_emp[5]}/bancos`
        dispatch(push(ruta));
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al modificar el banco',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/banco/${id}`).then((response) => {
        dispatch({type: GUARDAR_BANCO, lectura: response });
        dispatch(initializeForm('FormBancos', response ));
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar el banco',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        if (error.status === 401){
            NotificationManager.error(
              error.body.msj,
              'Error',
              0
            );
        }
        if (error.status === 400){
            NotificationManager.error(
              'Ocurrió un error en buscar modulo',
              'Error',
              0
            );
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const actions = {
    listar,
    registroBanco,
    modificarBanco,
    leer,
    getModulo
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_BANCOS]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [GUARDAR_BANCO]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
};

export const initialState = {
    loader: false,
    lectura: [],
    modulo: [],
    data: [],
    page: 1,
};

export default handleActions(reducers, initialState)