import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';
import moment from 'moment';

const LISTADO_DEV = 'LISTADO_DEV';
const GUARDAR_DEV = 'GUARDAR_DEV';
const LOADER = 'LOADER';
const MODULO = 'MODULO';
const PERIODO_D = 'PERIODO_D';
const DETALLE_PRODUCTOS = 'DETALLE_PRODUCTOS';
const PARAMETRO = 'PARAMETRO';
const ENVIOS = 'ENVIOS';
const PRODUCTOS = 'PRODUCTOS';
const DETALLE = 'DETALLE';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setLoaderC = loader => ({
    type: LOADER,
    loader,
});


export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let periodo = id_emp[7];
    //const params = { page, id, periodo };
    const params = { id, periodo };
    api.get('/devolucion_producto', params).then((response) => {
        dispatch({ type: LISTADO_DEV, data: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar devoluciones',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/devolucion_producto/${id}`).then((response) => {
        response.descripcion = response.observaciones
        dispatch({ type: GUARDAR_DEV, lectura: response });
        dispatch(initializeForm('FormDevolucionProducto', response));
        dispatch(leerDetalleProductos(response.id));
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar devolución',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = { id, modulo }
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response) => {
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const leerPeriodoD = (id, id_periodo) => (dispatch) => {
    const params = { id, id_periodo };
    api.get('/periodos/periodo', params).then((response) => {
        dispatch({ type: PERIODO_D, periodoD: response.periodo });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar periodo',
            'Error',
            2000
        );
    })
}

export const registroDevolucion = (periodoD, numeroSerie) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormDevolucionProducto.values;
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fecha)
    
    if (fecha >= fechaI && fecha <= fechaF) {

        const formData = {
            fecha: data.fecha,
            serie: data.serie.label,
            numero: numeroSerie,
            descripcion: data.descripcion,
            detalleDev: data.detalleD,
            bodega: data.bodega.value,
            cliente: data.cliente_documento.value,
            envio: data.envio.value,
            empresa: id_emp[5],
            periodo: id_emp[7]
        }
        dispatch(setLoader(true));
        api.post('/devolucion_producto/', formData).then(() => {
            NotificationManager.success(
                'Devolución de producto guardado correctamente',
                'Exito',
                3000
            );

            let ruta = `/produccion/${id_emp[5]}/devolucion_producto/${id_emp[7]}`
            dispatch(push(ruta));
        }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                2000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    } else {
        Swal.fire({
            title: 'Devolución de producto',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })
    }
}

const leerDetalleProductos = id => (dispatch) => {
    api.get(`/devolucion_producto_detalle/${id}`).then((response) => {
        dispatch({ type: DETALLE_PRODUCTOS, detalleProducto: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};

const eliminarLineaProducto = id => (dispatch) => {
    const detalle = localStorage.getItem('id_detalle_consumo_prd');
    dispatch(setLoader(true));
    api.eliminar(`/movimiento_consumo_prd/${id}`).then(() => {
        dispatch(leerDetalleProductos(detalle));
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const confirmarSalida = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    if (localStorage.getItem('id_detalle_consumo_prd')) {
        localStorage.removeItem('id_detalle_consumo_prd');
        NotificationManager.success(
            'Salida de inventario guardada correctamente',
            'Exito',
            3000
        );
        const editar = window.location.href.includes('editar');
        if (editar) {
            ruta = `/produccion/${id_emp[5]}/consumo/${id_emp[7]}`
            dispatch(push(ruta));
        } else {
            window.location.reload(true);
        }

    } else {
        Swal.fire({
            title: 'Consumo',
            text: 'No hay productos ingresados',
            type: 'error',
        })
    }
}

export const parametrosEmpresa = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id }
    dispatch(setLoader(true));
    api.get('/empresa/empresa_parametros', params).then((response) => {
        dispatch({ type: PARAMETRO, datos: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar parametros',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const listarEnvios = (id) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id_empre = id_emp[5];
    const params = { id, id_empre }
    api.get('/envio_produccion/empresa_envio_devolucion_producto', params).then(data => {
        const list_envios = [];
        if (data) {
            data.forEach(item => {
                list_envios.push({
                    value: item.id,
                    label: item.serie + ' - ' + item.numero
                })
            })
        }
        dispatch({ type: ENVIOS, envio: list_envios })
    }).catch((error) => {
        return [];
    })
}

export const listarProductos = (id) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let idEmpre = id_emp[5];
    const params = { id, idEmpre }
    api.get('/producto/empresa_productos_envio_prd/', params).then((response) => {
        const producto = [];
        response.forEach(item => {
            producto.push({
                value: item.id + ' # ' + item.cantidad,
                label: item.sku + ' / ' + item.productoN + ' -- ' + ' Cantidad: ' + new Intl.NumberFormat("en-US", { minimumFractionDigits: 6 }).format(item.cantidad)
            })

        })
        dispatch({ type: PRODUCTOS, pro: producto })
    })
}

let detalle = [];
export const agregarLinea = (periodoD) => (dispatch, getStore) => {
    const data = getStore().form.FormDevolucionProducto.values;

    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fecha);

    if (fecha >= fechaI && fecha <= fechaF) {

        if (data.producto === undefined || data.producto === null) {
            Swal.fire({
                title: 'Devolución producto',
                text: 'Debe de seleccionar producto',
                type: 'error',
            })
        } else {
            if (data.cantidad === undefined || data.cantidad === 0.000000) {
                Swal.fire({
                    title: 'Devolución producto',
                    text: 'Debe de ingresar cantidad',
                    type: 'error',
                })
            } else {

                let proN = data.producto.label.split("--");
                let proID = proN[0].split("/");
                let pro = data.producto.value.split('#');
                if (parseFloat(data.cantidad) > parseFloat(pro[1].trim())) {
                    Swal.fire({
                        title: 'Devolución producto',
                        text: 'Cantidad ingresada es mayor a la existencia',
                        type: 'error',
                    })
                } else {
                    if (data.costo === undefined || data.costo === 0.00) {
                        Swal.fire({
                            title: 'Devolución producto',
                            text: 'Debe de ingresar costo',
                            type: 'error',
                        })
                    }else{
                        detalle.push({
                            id: pro[0].trim()+'/'+data.cantidad,
                            cantidad: data.cantidad,
                            unidad: data.unidad ? data.unidad : 0,
                            costo: data.costo,
                            idPro: pro[0].trim(),
                            sku: proID[0].trim(),
                            producto: proID[1].trim(),
                        })
                        data.cantidad = 0;
                        data.detalleD = detalle;
                        NotificationManager.success(
                            'Producto ingresado correctamente',
                            'Exito',
                            3000
                        );
                        dispatch(actualizar());
                    }
                }
            }
        }

    } else {
        Swal.fire({
            title: 'Devolución producto',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })
    }
}

const actualizar = () => (dispatch, getStore) => {
    const data = getStore().form.FormDevolucionProducto.values;
    dispatch(initializeForm('FormDevolucionProducto', data));
    dispatch({ type: DETALLE, detalleCon: data.detalleD })
};

const vaciar = () => (dispatch) => {
    detalle = [];
    dispatch(initializeForm('FormDevolucionProducto'));
    dispatch({ type: DETALLE, detalleCon: detalle });
};

export const listarClientes = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/cliente/empresa_clientes', params).then(data => {
        const list_cliente = [];
        if (data) {
            data.forEach(item => {
                list_cliente.push({
                    value: item.id,
                    label: item.identificador + ' ' + item.nombre
                })
            })
        }
        return list_cliente;
    }).catch((error) => {

        return [];
    })
}

export const listarBodegas = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search };
    return api.get('/bodega', params).then((response) => {
        const bodega = [];
        if (response){
            response.forEach(item=>{
                bodega.push({
                    value: item.id,
                    label: item.numero + ' / ' + item.bodega
                })
            })
        }
        return bodega;
    }).catch((error) => {
        return [];
    })
}

export const numeroSerie = (id) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let idEmpre = id_emp[5];
    let tipo = 'DEV_PROD';
    const params = { id, idEmpre, tipo }
    dispatch(setLoaderC(true));
    return api.get('/serie_prd/serie/', params).then((response) => {
        return response;
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar numero de serie',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoaderC(false));
    });

}

export const listarSeries = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let tipo = 'DEV_PROD';
    const params = { id, search, tipo }
    return api.get('/serie_prd', params).then(data => {
        const list_serie = [];
        if (data) {
            data.forEach(item => {
                list_serie.push({
                    value: item.id,
                    label: item.serie
                })
            })
        }
        return list_serie;
    }).catch((error) => {
        return [];
    })
}

const eliminarNota = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`/devolucion_producto/${id}`).then(() => {
        dispatch(listar(1));
        NotificationManager.success(
            'Nota de cobro borrada correctamente',
            'Exito',
            3000
        );
    }).catch((error) => {
        
        if (error.abono) {
            Swal.fire({
                title: 'Nota de cobro',
                text: error.abono,
                type: 'error',
            })    
        }else{
            NotificationManager.error(
                'Ocurrió un error en eliminar nota de cobro',
                'Error',
                6000
            );
        }
        
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const eliminarLinea = (id) => (dispatch) => {
    let d = id.split('/');
    detalle.forEach((element, index) => {
        if (element.idPro === d[0] && element.cantidad === d[1]) {
            detalle.splice(index, 1);
        }
    })
    dispatch(actualizar());
};


export const actions = {
    listar,
    leer,
    getModulo,
    leerPeriodoD,
    registroDevolucion,
    leerDetalleProductos,
    eliminarLineaProducto,
    parametrosEmpresa,
    listarEnvios,
    listarProductos,
    agregarLinea,
    actualizar,
    vaciar,
    listarClientes,
    listarBodegas,
    numeroSerie,
    listarSeries,
    eliminarNota,
    eliminarLinea
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_DEV]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [GUARDAR_DEV]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [PERIODO_D]: (state, { periodoD }) => {
        return {
            ...state,
            periodoD,
        };
    },
    [DETALLE_PRODUCTOS]: (state, { detalleProducto }) => {
        return {
            ...state,
            detalleProducto,
        };
    },
    [PARAMETRO]: (state, { datos }) => {
        return {
            ...state,
            datos,
        };
    },
    [PRODUCTOS]: (state, { pro }) => {
        return {
            ...state,
            pro,
        };
    },
    [ENVIOS]: (state, { envio }) => {
        return {
            ...state,
            envio,
        };
    },
    [DETALLE]: (state, { detalleCon }) => {
        return {
            ...state,
            detalleCon,
        };
    },
};

export const initialState = {
    loader: false,
    lectura: [],
    modulo: [],
    periodoD: [],
    datos: [],
    envio: [],
    pro: [],
    detalleCon: [],
    detalleProducto: [],
    data: [],
    page: 1,
};

export default handleActions(reducers, initialState)
