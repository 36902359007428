import {handleActions} from 'redux-actions';
import { NotificationManager } from "react-notifications";
import { push } from "react-router-redux";
import { api } from "api";
import { initialize as initializeForm } from 'redux-form';
const valEmp = __CONFIG__.empresa;

const LOADER = 'LOADER';
const PAGE = 'PAGE';
const LISTADO_USUARIOS = 'LISTADO_USUARIOS';
const PARAMETRO = 'PARAMETRO';

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setPage = page => ({
    type:PAGE,
    page,
});


export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/user/${id}`).then((response) => {
        dispatch(initializeForm('FormUsuario', response ));
    }).catch((error)=>{
        
        NotificationManager.error(
            'Ocurrió un error al consultar usuario',
            'Error',
            6000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const registroUsuario = () => (dispatch, getStore) => {
    const data = getStore().form.FormUsuario.values;
    let ruta = window.location.href;
    let url = ruta.split('/');
    
    let urlToken = '';
    let idEmpresa = '';
    let tipo = '';
    if (valEmp === 'fage'){
        urlToken = url[0]+'/'+url[1]+'/'+url[2]+'/';
        idEmpresa = url[6];
        tipo = 'lfg';
    }else{
        urlToken = url[0]+'/'+url[1]+'/'+url[2]+'/';
        idEmpresa = url[5];
        tipo = '';
    }
    
    const formData = {
        username: data.username,
        first_name: data.first_name,
        last_name: data.last_name,
        url: urlToken,
        empresa: idEmpresa,
        tipo: tipo
    }
    dispatch(setLoader(true));
    api.post('/user/crear/', formData).then(() => {
        NotificationManager.success(
            'Usuario creado',
            'Exito',
            3000
        );
        let ruta = '';
        if (valEmp === 'fage'){
            ruta = `/lfg/ajustes/${url[6]}/usuarios`
            dispatch(push(ruta));
        }
        if (valEmp === ''){
            ruta = `/ajustes/${url[5]}/usuarios`
            dispatch(push(ruta));
        }
    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}


export const listaUser = (page = 1) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params;
    let id = ''
    if (valEmp === 'fage'){
        id = id_emp[6];
        params = {page, id}
    }else{
        id = id_emp[5];
        params = {page, id}
    }
    let activo = '';
    dispatch(setLoader(true));
    api.get('/user/usuario_empresa/',params).then((response)=>{
        const user = [];
        response.results.forEach(item=>{
            if (item.usuario.is_active === true){
                activo='S'
            }else{
                activo='N'
            }
            user.push({
                id: item.usuario.id,
                userName: item.usuario.username,
                lastLogin: item.usuario.last_login,
                nombre: item.usuario.first_name + ' ' + item.usuario.last_name,
                activo: activo
            })
        })
        response.results = user
        dispatch({ type: LISTADO_USUARIOS, data: response });
        dispatch(setPage(page));
        const params = {id}
        api.get('/parametro', params).then((datos)=>{
            dispatch({ type: PARAMETRO, parametro: datos });
        }).catch((error)=>{
            NotificationManager.error(
                'Ocurrió un error consultar parametro',
                'Error',
                6000
            );
        })
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar los usuarios',
            'Error',
            6000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const eliminar = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`/user/${id}`).then(() => {
        NotificationManager.success(
            'Usuario inactivado correctamente',
            'Exito',
            3000
        );
        let page = 1;
        dispatch(listaUser(page));
    }).catch((error) => {
        console.log(error);
        NotificationManager.error(
            'Ocurrió un error al inactivar el usuario',
            'Error',
            6000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}


const validar = () => (dispatch) => {
    let ruta = window.location.href;
    let url = ruta.split('/');
    let id = url[5];
    let token = url[6].split('=');
    let Token = token[1];
    const params = { id, Token };
    api.get('/user/validar_user/', params).then((response) => {
        dispatch(initializeForm('FormUserPass', response));
    }).catch((error) => {
        if (error.status === 404){
            NotificationManager.info(
                error.body.msj,
                'Información',
                6000
            );
            if (valEmp === 'fage'){
                dispatch(push("/lfg"))
            }else{
                dispatch(push("/login"))
            }
        }
        if (error.status === 400){
            NotificationManager.error(
                error.body.msj,
                'Error',
                10000
            );
            dispatch(push("/login"))
        }
    })
}

export const activarUsuario = () => (dispatch, getStore) => {
    const data = getStore().form.FormUserPass.values;
    const formData = {
        username: data.username,
        password: data.password
    }
    dispatch(setLoader(true));
    api.put('/user/activar_cuenta_user/', formData).then(() => {
        NotificationManager.success(
            'Usuario activado',
            'Exito',
            3000
        );
        if (valEmp === 'fage'){
            dispatch(push("/lfg"));
        }else{
            dispatch(push("/login"));
        }
    }).catch((error) => {
        NotificationManager.error(
            error.body.msj,
            'Error',
            6000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}


export const actions = {
   listaUser,
   registroUsuario,
   leer,
   eliminar,
   validar,
   activarUsuario
}

export const initialState = {
    usuario: null,
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    page: 1,
    parametro: null,
}

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_USUARIOS]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [PARAMETRO]: (state, { parametro }) => {
        return {
            ...state,
            parametro,
        };
    },
}


export default handleActions(reducers, initialState)

