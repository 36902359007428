import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';
import moment from 'moment';

const LISTADO_GASTOS = 'LISTADO_GASTOS';
const LOADER = 'LOADER';
const MODULO = 'MODULO';
const PERIODO_D = 'PERIODO_D';
const DETALLE = 'DETALLE';
const DETALLE_GASTO = 'DETALLE_GASTO';
const LECTURA = 'LECTURA';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let periodo = id_emp[7];
    const params = { id, periodo };
    dispatch(setLoader(true));
    api.get('/gasto_prd', params).then((response) => {
        dispatch({ type: LISTADO_GASTOS, data: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar gastos',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/gasto_prd/${id}`).then((response) => {
        localStorage.setItem('id_detalle_gasto_indirecto', response.id);
        response.fecha = response.fecha_registro;
        dispatch({ type: LECTURA, lectura: response });
        dispatch(initializeForm('FormGasto', response));
        dispatch(leerDetalleGasto(response.id));
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar gasto',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = { id, modulo }
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response) => {
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const leerPeriodoD = (id, id_periodo) => (dispatch) => {
    const params = { id, id_periodo };
    api.get('/periodos/periodo', params).then((response) => {
        dispatch({ type: PERIODO_D, periodoD: response.periodo });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar periodo',
            'Error',
            2000
        );
    })
}

export const listarFases = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/fase_prd/buscar_fase', params).then(data => {
        const list_fase = [];
        if (data) {
            data.forEach(item => {
                list_fase.push({
                    value: item.id,
                    label: item.descripcion
                })
            })
        }
        return list_fase;
    }).catch((error) => {
        return [];
    })
}

export const listarSiembras = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/siembra/buscar_siembra', params).then(data => {
        const list_siembra = [];
        if (data) {
            data.forEach(item => {
                list_siembra.push({
                    value: item.id,
                    label: item.descripcion
                })
            })
        }
        return list_siembra;
    }).catch((error) => {
        return [];
    })
}

export const listarGastos = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/tipo_gasto_prd/buscar_gasto', params).then(data => {
        const list_gasto = [];
        if (data) {
            data.forEach(item => {
                list_gasto.push({
                    value: item.id,
                    label: item.descripcion
                })
            })
        }
        return list_gasto;
    }).catch((error) => {
        return [];
    })
}

export const registroGasto = (periodoD) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormGasto.values;

    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fecha)
    if (fecha >= fechaI && fecha <= fechaF) {

        const formData = {
            fecha_registro: data.fecha,
            gasto: data.gasto.value,
            detalleGasto: data.detalleD,
            tipoGasto : data.tipo,
            empresa: id_emp[5],
            periodo: id_emp[7]
        }
        dispatch(setLoader(true));
        api.post('/gasto_prd/', formData).then(() => {
            NotificationManager.success(
                'Gasto guardado correctamente',
                'Exito',
                3000
            );
            let ruta = `/produccion/${id_emp[5]}/gasto/${id_emp[7]}`
            dispatch(push(ruta));
        }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                0
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });

    } else {
        Swal.fire({
            title: 'Gasto',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })
    }
}

export const modificarGasto = (periodoD) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormGasto.values;
    
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fecha_registro);
    let id = data.id;
    if (fecha >= fechaI && fecha <= fechaF) {
        let gasto = data.gasto_mod;
        if (gasto === undefined) {
            gasto = data.gasto;
        } else {
            gasto = data.gasto_mod.value;
        }
        const formData = {
            id: id,
            gasto: gasto,
            fecha_registro: data.fecha_registro,
            tipoGasto: data.tipo_gasto,
        }
        dispatch(setLoader(true));
        api.put(`/gasto_prd/${id}/`, formData).then(() => {
            NotificationManager.success(
                'Gasto modificado correctamente',
                'Exito',
                3000
            );
            let ruta = `/produccion/${id_emp[5]}/gasto/${id_emp[7]}`
            dispatch(push(ruta));
        }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                0
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    } else {
        Swal.fire({
            title: 'Gasto',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })
    }
}

export const nuevaLineaGasto = () => (dispatch, getStore) => {
    const data = getStore().form.FormGasto.values;
    if (data.monto === undefined || parseFloat(data.monto) === 0) {
        Swal.fire({
            title: 'Gasto',
            text: 'Debe de ingresar monto',
            type: 'error',
        })
    } else {
        if (data.fase === undefined || data.fase === null) {
            Swal.fire({
                title: 'Gasto',
                text: 'Debe de seleccionar fase',
                type: 'error',
            })
        } else {
            const formData = {
                idFase: data.fase.value,
                fase: data.fase.label,
                monto: data.monto,
                detalle: data.id,
            }
            dispatch(setLoader(true));
            api.post('/gasto_detalle_prd/', formData).then((response) => {
                response.fecha_registro = response.encabezado.fecha_registro;
                response.id = response.encabezado.id;
                response.tipo_gasto = response.encabezado.tipo_gasto;
                response.descripcion_gasto = response.encabezado.descripcion_gasto;
                response.gasto = response.encabezado.gasto;
                dispatch(initializeForm('FormGasto', response));
                dispatch(leerDetalleGasto(response.id));
                NotificationManager.success(
                    'Dato ingresado correctamente',
                    'Exito',
                    3000
                );
            }).catch((error) => {
                NotificationManager.error(
                    error.msj,
                    'Error',
                    2000
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }
    }
}


const eliminarLineaGasto = id => (dispatch) => {
    const detalle = localStorage.getItem('id_detalle_gasto_indirecto');
    dispatch(setLoader(true));
    api.eliminar(`/gasto_detalle_prd/${id}`).then(() => {
        dispatch(leerDetalleGasto(detalle));
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

let detalle = [];
export const agregarLinea = (periodoD) => (dispatch, getStore) => {
    const data = getStore().form.FormGasto.values;
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    if (data.fecha === undefined || data.fecha === '') {
        Swal.fire({
            title: 'Gasto',
            text: 'Debe de ingresar fecha',
            type: 'error',
        })
    } else {
        let fecha = moment(data.fecha);
        if (fecha >= fechaI && fecha <= fechaF) {
            if (data.gasto === undefined || data.gasto === null) {
                Swal.fire({
                    title: 'Gasto',
                    text: 'Debe de seleccionar gasto',
                    type: 'error',
                })
            } else {
                if (data.monto === undefined || parseFloat(data.monto) === 0) {
                    Swal.fire({
                        title: 'Gasto',
                        text: 'Debe de ingresar monto',
                        type: 'error',
                    })
                } else {
                    if (data.fase === undefined || data.fase === null) {
                        Swal.fire({
                            title: 'Gasto',
                            text: 'Debe de seleccionar fase',
                            type: 'error',
                        })
                    } else {
                        detalle.push({
                            id: data.gasto.value,
                            idFase: data.fase.value,
                            fase: data.fase.label,
                            monto: data.monto
                        })

                        data.monto = 0;
                        data.detalleD = detalle;
                        NotificationManager.success(
                            'Datos ingresados correctamente',
                            'Exito',
                            3000
                        );

                        dispatch(actualizar());
                    }
                }
            }
        } else {
            Swal.fire({
                title: 'Gasto',
                text: 'Fecha fuera del periodo',
                type: 'error',
            })
        }
    }
}


const eliminarLinea = (id) => (dispatch) => {
    detalle.forEach((element, index) => {
        if (element.id === id) {
            detalle.splice(index, 1);
        }
    })
    dispatch(actualizar());
};

const vaciar = () => (dispatch) => {
    detalle = [];
    dispatch(initializeForm('FormGastoIndirecto'));
    dispatch({ type: DETALLE, detallePro: detalle });
};

const actualizar = () => (dispatch, getStore) => {
    const data = getStore().form.FormGasto.values;
    dispatch(initializeForm('FormGasto', data));
    dispatch({ type: DETALLE, detallePro: data.detalleD })
};

const leerDetalleGasto = id => (dispatch) => {
    api.get(`/gasto_detalle_prd/${id}`).then((response) => {
        dispatch({ type: DETALLE_GASTO, detalleS: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};

const eliminarGasto = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`/gasto_prd/${id}`).then(() => {
        dispatch(listar(1));
        NotificationManager.success(
            'Gasto borrado correctamente',
            'Exito',
            3000
        );
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar gasto',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};



export const actions = {
    listar,
    leer,
    getModulo,
    leerPeriodoD,
    registroGasto,
    modificarGasto,
    agregarLinea,
    listarFases,
    eliminarLineaGasto,
    nuevaLineaGasto,
    eliminarLinea,
    vaciar,
    actualizar,
    listarSiembras,
    listarGastos,
    eliminarGasto
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_GASTOS]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [PERIODO_D]: (state, { periodoD }) => {
        return {
            ...state,
            periodoD,
        };
    },
    [DETALLE]: (state, { detallePro }) => {
        return {
            ...state,
            detallePro,
        };
    },
    [DETALLE_GASTO]: (state, { detalleS }) => {
        return {
            ...state,
            detalleS,
        };
    },
    [LECTURA]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
};

export const initialState = {
    loader: false,
    modulo: [],
    periodoD: [],
    detallePro: [],
    detalleS: [],
    data: [],
    lectura: [],
};

export default handleActions(reducers, initialState)
