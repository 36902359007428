import React, { Component } from 'react';
import Grid from '../../Utils/GridFiltro';
import PropTypes from 'prop-types';
import { standardActions } from '../../Utils/GridFiltro/StandardActions';
import NavbarModulo from '../../components/NavbarModulo';
import NavbarModuloLF from '../../components/NavbarModuloFage';
const valEmp = __CONFIG__.empresa;

class TipoDescuentoList extends Component{
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        loading: true
    };

    componentDidMount = () => {
        const { listar, getModulo} = this.props;
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        listar();
        if (valEmp === 'fage'){
            getModulo(modulo[6],modulo[5]);
        }else{
            getModulo(modulo[5],modulo[4]);
        }
    }

    render(){
        const { data, loader, modulo } = this.props;
        let ruta = window.location.href;
        let id_emp = ruta.split('/');
        return(
            <React.Fragment>
                {valEmp === 'fage' &&
                    <NavbarModuloLF
                        modulo={modulo}
                    />
                }
                {valEmp === '' &&
                    <NavbarModulo
                        modulo={modulo}
                    />
                }
                <br />
                <h3 className="p-2 bg-secondary title">Tipos de descuento</h3>
                <br />
                {valEmp === 'fage' &&
                    <div className="d-flex flex-row justify-content-start mb-2">
                        <a
                            href={`/#/lfg/nomina/${id_emp[6]}/tipo_descuento/crear`}
                            className='btn btn-primary'
                        >
                            Crear tipo de descuento
                        </a>
                    </div>
                }
                {valEmp === '' &&
                    <div className="d-flex flex-row justify-content-start mb-2">
                        <a
                            href={`/#/nomina/${id_emp[5]}/tipo_descuento/crear`}
                            className='btn btn-primary'
                        >
                            Crear tipo de descuento
                        </a>
                    </div>
                }
                {data &&
                    <Grid 
                        hover 
                        striped 
                        data={data} 
                        loading={loader} 
                        headerStyle={ { background: 'black' } } 
                        //onPageChange={onPageChange}
                        //onSortChange={onSortChange} 
                    >
                        <TableHeaderColumn
                            isKey
                            dataField="descripcion"
                            headerAlign="center"
                            width="150"
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Descripción
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            thStyle={
                            {color: 'white'}}
                            dataField="id"
                            width="180"
                            dataAlign="center"
                            dataSort
                            dataFormat={standardActions({ 
                                editar: "tipo_descuento", 
                                ver: "tipo_descuento",
                            })} 
                            
                        >
                            Acciones
                        </TableHeaderColumn>
                    </Grid>
                }
            </React.Fragment>
        );
    }
}

export default TipoDescuentoList;
