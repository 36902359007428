import React, { useState, useEffect } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import {
  renderFieldCheckB,
} from "../Utils/renderField/renderField";

function ParametrosCompras(props) {
  const { enviar } = props;
  const [nit, setNit] = useState(false);
  const [dpi, setDPI] = useState(false);
  const [tc, setTipoCambio] = useState(false);

  useEffect(() => {
    const { leerParametroCompras } = props;
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const id = id_emp[5];
    let data;
    if (id) {
      data = leerParametroCompras(id);
      data.then(value => {
        if (value[0].nit === 'S') {
          setNit(true);
        }
        if (value[0].dpi === 'S') {
          setDPI(true);
        }
        if (value[0].tc === 'S') {
          setTipoCambio(true);
        }
      });
    }
  }, [])

  const onCheckboxClickedNit = (idx, isChecked) => {
    setNit(isChecked);
  }
  const onCheckboxClickedDPI = (idx, isChecked) => {
    setDPI(isChecked);
  }
  const onCheckboxClickedTC = (idx, isChecked) => {
    setTipoCambio(isChecked);
  }
  let ruta = window.location.href;
  let id_emp = ruta.split('/');
  return (
    <div>
      <br />
      <div className="mb-4 card card-small text-white bg-dark mb-3">
        <div className="p-0 pt-3 d-flex flex-column flex-md-row">
          <div className="d-flex flex-column flex-1 mx-4">
            <div className='mb-2 w-50'>
              <div className="mb-3">
                <strong className="text-muted d-block mb-2">Parametros para Guatemala</strong>
                <div className='row'>
                  <div className='col-4'>
                    <Field
                      name="nit"
                      label="Validar NIT"
                      checked={nit}
                      onChange={onCheckboxClickedNit}
                      component={renderFieldCheckB}
                    />
                  </div>
                  <div className='col-4'>
                    <Field
                      name="dpi"
                      label="Validar CUI"
                      checked={dpi}
                      onChange={onCheckboxClickedDPI}
                      component={renderFieldCheckB}
                    />
                  </div>
                  <div className='col-4'>
                    <Field
                      name="tcambio"
                      label="Tipo de cambio"
                      checked={tc}
                      onChange={onCheckboxClickedTC}
                      component={renderFieldCheckB}
                    />
                  </div>
                </div>

              </div>
            </div>
            <div className='d-flex flex-row mt-3'>

              <button
                className="btn mr-2 mb-3 btn-primary"
                type='submit'
                onClick={enviar}
              >
                Guardar
              </button>

              <a
                href={`/#/ajustes/${id_emp[5]}`}
                className='btn btn-secondary mb-3'
              >
                Regresar
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default reduxForm({
  form: 'FormParametrosCompras',

})(ParametrosCompras);
