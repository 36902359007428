import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';
import moment from 'moment';

const LISTADO_SIEMBRA = 'LISTADO_SALIDA';
const LOADER = 'LOADER';
const MODULO = 'MODULO';
const PERIODO_D = 'PERIODO_D';
const DETALLE = 'DETALLE';
const DETALLE_SIEMBRA = 'DETALLE_SIEMBRA';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let periodo = id_emp[7];
    const params = { id, periodo };
    dispatch(setLoader(true));
    api.get('/siembra', params).then((response) => {
        dispatch({ type: LISTADO_SIEMBRA, data: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar siembras',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/siembra/${id}`).then((response) => {
        localStorage.setItem('id_detalle_siembra', response.id);
        response.fecha = response.fecha_inicio;
        dispatch(initializeForm('FormSiembraProduccion', response));
        dispatch(leerDetalleSiembra(response.id));
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar siembra',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = { id, modulo }
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response) => {
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const leerPeriodoD = (id, id_periodo) => (dispatch) => {
    const params = { id, id_periodo };
    api.get('/periodos/periodo', params).then((response) => {
        dispatch({ type: PERIODO_D, periodoD: response.periodo });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar periodo',
            'Error',
            2000
        );
    })
}

export const listarFases = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/fase_prd/buscar_fase', params).then(data => {
        const list_fase = [];
        if (data) {
            data.forEach(item => {
                list_fase.push({
                    value: item.id,
                    label: item.descripcion
                })
            })
        }
        return list_fase;
    }).catch((error) => {
        return [];
    })
}

export const registroSiembra = (periodoD) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormSiembraProduccion.values;

    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fecha)
    if (fecha >= fechaI && fecha <= fechaF) {

        const formData = {
            fecha: data.fecha,
            descripcion: data.descripcion,
            detalleS: data.detalleD,
            empresa: id_emp[5],
            periodo: id_emp[7]
        }
        dispatch(setLoader(true));
        api.post('/siembra/', formData).then(() => {
            NotificationManager.success(
                'Siembra guardada correctamente',
                'Exito',
                3000
            );
            let ruta = `/produccion/${id_emp[5]}/siembra/${id_emp[7]}`
            dispatch(push(ruta));
        }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                0
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });

    } else {
        Swal.fire({
            title: 'Siembra',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })
    }
}

export const modificarSiembra = (periodoD) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormSiembraProduccion.values;

    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fecha);
    let id = data.id;
    if (fecha >= fechaI && fecha <= fechaF) {
        const formData = {
            id: id,
            descripcion: data.descripcion,
            fecha: data.fecha,
        }
        dispatch(setLoader(true));
        api.put(`/siembra/${id}/`, formData).then(() => {
            NotificationManager.success(
                'Siembra modificada correctamente',
                'Exito',
                3000
            );
            let ruta = `/produccion/${id_emp[5]}/siembra/${id_emp[7]}`
            dispatch(push(ruta));
        }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                0
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    } else {
        Swal.fire({
            title: 'Siembra',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })
    }
}

export const nuevaLineaSiembra = () => (dispatch, getStore) => {
    const data = getStore().form.FormSiembraProduccion.values;
    const detalle = localStorage.getItem('id_detalle_siembra');
    if (data.variedad === undefined || data.variedad === '') {
        Swal.fire({
            title: 'Siembra',
            text: 'Debe de ingresar variedad',
            type: 'error',
        })
    } else {
        if (data.fase === undefined || data.fase === null) {
            Swal.fire({
                title: 'Siembra',
                text: 'Debe de seleccionar fase',
                type: 'error',
            })
        } else {
            if (data.metros === undefined || parseInt(data.metros) === 0) {
                Swal.fire({
                    title: 'Siembra',
                    text: 'Debe de ingresar metro cuadrado',
                    type: 'error',
                })
            }else{
                if (data.metros === undefined || parseInt(data.metros) === 0) {
                    Swal.fire({
                        title: 'Siembra',
                        text: 'Debe de ingresar kilo metro cuadrado',
                        type: 'error',
                    })
                }else{
                    const formData = {
                        idFase: data.fase.value,
                        fase: data.fase.label,
                        variedad: data.variedad,
                        metros: data.metros,
                        kilos: data.kilos,
                        detalle: detalle,
                    }
                    dispatch(setLoader(true));
                    api.post('/siembra/', formData).then((response) => {
                        response.descripcion = response.encabezado.descripcion;
                        response.fecha = response.encabezado.fecha_inicio;
                        response.id = response.encabezado.id;
                        dispatch(initializeForm('FormSiembraProduccion', response));
                        dispatch(leerDetalleSiembra(response.id));
                        NotificationManager.success(
                            'Dato ingresado correctamente',
                            'Exito',
                            3000
                        );
                    }).catch((error) => {
                        NotificationManager.error(
                            error.msj,
                            'Error',
                            2000
                        );
                    }).finally(() => {
                        dispatch(setLoader(false));
                    });
                }
            }
        }
    }

}

const eliminarLineaSiembra = id => (dispatch) => {
    const detalle = localStorage.getItem('id_detalle_siembra');
    dispatch(setLoader(true));
    api.eliminar(`/siembra_detalle/${id}`).then(() => {
        dispatch(leerDetalleSiembra(detalle));
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

let detalle = [];
export const agregarLinea = (periodoD) => (dispatch, getStore) => {
    const data = getStore().form.FormSiembraProduccion.values;
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fecha);
    if (fecha >= fechaI && fecha <= fechaF) {
        if (data.descripcion === undefined || data.descripcion === '') {
            Swal.fire({
                title: 'Siembra',
                text: 'Debe de ingresar descripción de la siembra',
                type: 'error',
            })
        } else {
            if (data.fecha === undefined || data.fecha === '') {
                Swal.fire({
                    title: 'Siembra',
                    text: 'Debe de ingresar fecha de inicio',
                    type: 'error',
                })
            } else {
                if (data.variedad === undefined || data.variedad === '') {
                    Swal.fire({
                        title: 'Siembra',
                        text: 'Debe de ingresar variedad',
                        type: 'error',
                    })
                } else {
                    if (data.fase === undefined || data.fase === null) {
                        Swal.fire({
                            title: 'Siembra',
                            text: 'Debe de seleccionar fase',
                            type: 'error',
                        })
                    } else {
                        if (data.metros === undefined || parseInt(data.metros) === 0) {
                            Swal.fire({
                                title: 'Siembra',
                                text: 'Debe de ingresar metro cuadrado',
                                type: 'error',
                            })
                        }else{
                            if (data.kilos === undefined || parseInt(data.kilos) === 0) {
                                Swal.fire({
                                    title: 'Siembra',
                                    text: 'Debe de ingresar kilo metro cuadrado',
                                    type: 'error',
                                })
                            }else{
                                detalle.push({
                                    id: data.fase.value,
                                    idFase: data.fase.value,
                                    fase: data.fase.label,
                                    variedad: data.variedad,
                                    metros: data.metros,
                                    kilos: data.kilos,
                                })
                                        
                                data.metros = 0;
                                data.kilos = 0;
                                data.variedad = '';
                                data.detalleD = detalle;
                                NotificationManager.success(
                                    'Datos ingresados correctamente',
                                    'Exito',
                                    3000
                                );
            
                                dispatch(actualizar());
                            }
                        }
                    }
                }
            }
        }
    } else {
        Swal.fire({
            title: 'Siembra',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })
    }
}


const eliminarLinea = (id) => (dispatch) => {
    detalle.forEach((element, index) => {
        if (element.id === id) {
            detalle.splice(index, 1);
        }
    })
    dispatch(actualizar());
};

const vaciar = () => (dispatch) => {
    detalle = [];
    dispatch(initializeForm('FormSiembraProduccion'));
    dispatch({ type: DETALLE, detallePro: detalle });
};

const actualizar = () => (dispatch, getStore) => {
    const data = getStore().form.FormSiembraProduccion.values;
    dispatch(initializeForm('FormSiembraProduccion', data));
    dispatch({ type: DETALLE, detallePro: data.detalleD })
};

const leerDetalleSiembra = id => (dispatch) => {
    api.get(`/siembra_detalle/${id}`).then((response) => {
        dispatch({ type: DETALLE_SIEMBRA, detalleS: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};


export const actions = {
    listar,
    leer,
    getModulo,
    leerPeriodoD,
    registroSiembra,
    modificarSiembra,
    agregarLinea,
    listarFases,
    eliminarLineaSiembra,
    nuevaLineaSiembra,
    eliminarLinea,
    vaciar,
    actualizar
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_SIEMBRA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [PERIODO_D]: (state, { periodoD }) => {
        return {
            ...state,
            periodoD,
        };
    },
    [DETALLE]: (state, { detallePro }) => {
        return {
            ...state,
            detallePro,
        };
    },
    [DETALLE_SIEMBRA]: (state, { detalleS }) => {
        return {
            ...state,
            detalleS,
        };
    },
};

export const initialState = {
    loader: false,
    modulo: [],
    periodoD: [],
    detallePro: [],
    detalleS: [],
    data: [],
    page: 1,
};

export default handleActions(reducers, initialState)
