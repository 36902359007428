import React, { Component, useReducer } from 'react';
import Grid from '../Utils/Grid';
import { standardActions } from '../Utils/Grid/StandardActions';
const valEmp = __CONFIG__.empresa;

class EmpresasList extends Component{
    componentDidMount = () => {
        const { listar } = this.props;
        listar();
    }
    render(){
        const { empresa, loader, parametro } = this.props;
        let ruta = window.location.href;
        let id_emp = ruta.split('/');
        return(
            <React.Fragment>
                <br />
                <h3 className="p-2 bg-secondary title">Empresas</h3>
                <br />
                {valEmp === 'fage' &&
                    <div className="d-flex flex-row justify-content-start mb-2">
                        {parametro && parametro.totalEmp<parametro.parametro[0].limite_empresas && parametro.parametro[0].admin==true ?
                        <a
                            href={`/#/lfg/ajustes/${id_emp[6]}/empresas/crear`}
                            className='btn btn-primary'
                        >
                            Crear Empresa
                        </a>
                        : ''}
                        <a
                            href={`/#/lfg/ajustes/${id_emp[6]}`}
                            className='btn btn-secondary ml-2'
                        >
                            Regresar
                        </a>
                    </div>
                }
                {valEmp === '' &&
                    <div className="d-flex flex-row justify-content-start mb-2">
                        {parametro && parametro.totalEmp<parametro.parametro[0].limite_empresas && parametro.parametro[0].admin==true ?
                        <a
                            href={`/#/ajustes/${id_emp[5]}/empresas/crear`}
                            className='btn btn-primary'
                        >
                            Crear Empresa
                        </a>
                        : ''}
                        <a
                            href={`/#/ajustes/${id_emp[5]}`}
                            className='btn btn-secondary ml-2'
                        >
                            Regresar
                        </a>
                    </div>
                }
                {empresa &&
                    <Grid 
                        hover 
                        striped 
                        data={empresa} 
                        loading={loader} 
                        headerStyle={ { background: 'black' } } 
                        //onPageChange={listar} 
                        //onSortChange={onSortChange} 
                    >
                        <TableHeaderColumn
                            isKey
                            dataField="razon_social"
                            width="500"
                            dataSort
                            thStyle={ 
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Razón Social
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="direccion"
                            width="500"
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Dirección
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            thStyle={
                            {color: 'white'}}
                            dataField="id"
                            width="100"
                            dataAlign="center"
                            dataSort
                            dataFormat={standardActions({ 
                                editar: "empresas", 
                                ver: "empresas",
                            })} 
                            
                        >
                            Acciones
                        </TableHeaderColumn>
                    </Grid>
                }
            </React.Fragment>
        );
    }
}

export default EmpresasList;
