import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/visitador_medico/producto';
import ProductoList from './ProductoList';


const ms2p = (state) => {
  return {
    ...state.vm_producto,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ProductoList);
