import { handleActions } from 'redux-actions';
import { initialize as initializeForm } from 'redux-form';
import { push } from "react-router-redux";
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';
import moment from 'moment';

const LISTADO_ENVIO = 'LISTADO_INGRESO';
const LOADER = 'LOADER';
const MODULO = 'MODULO';
const PERIODO_D = 'PERIODO_D';
const DETALLE_CENVIO = 'DETALLE_CENVIO';
const PARAMETRO = 'PARAMETRO';
const CLIENTE = 'CLIENTE';
const DETALLE_ENVIO = 'DETALLE_ENVIO';
const LISTADO_ENVIOS = 'LISTADO_ENVIOS';
const NUMERO_ENVIO = 'NUMERO_ENVIO';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let periodo = id_emp[7];
    const params = { id, periodo };
    dispatch(setLoader(true));
    api.get('/consolidacion_envio', params).then((response) => {
        dispatch({ type: LISTADO_ENVIO, data: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar consolidaciones',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const detalleCEnvio = id => (dispatch) => {
    api.get(`/consolidacion_envio_detalle/${id}`).then((response) => {
        dispatch({ type: DETALLE_CENVIO, detalleCEnv: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/consolidacion_envio/${id}`).then((response) => {
        response.fechaRegistro = response.fecha;
        dispatch(initializeForm('FormConsolidacionEnvio', response));
        dispatch(detalleCEnvio(response.id));
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar consolidacion',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = { id, modulo }
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response) => {
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const leerPeriodoD = (id, id_periodo) => (dispatch) => {
    const params = { id, id_periodo };
    api.get('/periodos/periodo', params).then((response) => {
        dispatch({ type: PERIODO_D, periodoD: response.periodo });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar periodo',
            'Error',
            2000
        );
    })
}

export const listarClientes = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/cliente/empresa_clientes', params).then(data => {
        const list_cliente = [];
        if (data) {
            data.forEach(item => {
                list_cliente.push({
                    value: item.id,
                    label: item.identificador + ' ' + item.nombre
                })
            })
        }
        return list_cliente;
    }).catch((error) => {

        return [];
    })
}

export const listarEnvios = (cliente) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, cliente };
    api.get('/envio_produccion/envios', params).then((response)=>{
        const list_envios = [];
        response.forEach(item=>{
            list_envios.push({
                value: item.idEnvio+'#'+item.cajas+'#'+item.libras+'#'+item.fecha+'#'+item.serie+'#'+item.numero+'#'+item.detalleLinea+'#'+item.idProducto,
                label: moment(item.fecha).format("DD/MM/YYYY") + ' --- ' + item.serie + ' - ' + item.numero
            })
        })
        dispatch({ type: LISTADO_ENVIOS, envios: list_envios})
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar envios',
            'Error',
            3000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const numeroDevolucion = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    api.get('/consolidacion_envio/numero_consolidacion', params).then((response)=>{
        dispatch({ type: NUMERO_ENVIO, numeroDev: parseInt(response.numero) + 1})
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al mostrar numero de consolidacion',
            'Error',
            3000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const registroConsolidacion = (periodoD, numero) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormConsolidacionEnvio.values;
    
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fechaRegistro)
    
    if (fecha>=fechaI && fecha<=fechaF){
        const formData = {
            fecha: data.fechaRegistro,
            serie: 'CE',
            numero: numero,
            detalleEnvio: data.detalleEnvio,
            cliente_id: data.cliente.value,
            empresa: id_emp[5],
            periodo: id_emp[7]
        }
        dispatch(setLoader(true));
        api.post('/consolidacion_envio/', formData).then(() => {
            NotificationManager.success(
                'Consolidación guardada correctamente',
                'Exito',
                3000
            );

            let ruta = `/produccion/${id_emp[5]}/consolidacion_envio/${id_emp[7]}`
            dispatch(push(ruta));
        }).catch((error) => {
            NotificationManager.error(
                'Error en guardar consolidación',
                'Error',
                2000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }else {
        Swal.fire({
            title: 'Consolidación envío',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })        
    }
}

const actualizar = () => (dispatch, getStore) => {
    const data = getStore().form.FormConsolidacionEnvio.values;
    dispatch(initializeForm('FormConsolidacionEnvio', data));
    dispatch({ type: DETALLE_ENVIO, detalleEnv: data.detalleEnvio })
};

let detalleEnvio = [];
export const agregarLinea = (periodoD) => (dispatch, getStore) => {
    const data = getStore().form.FormConsolidacionEnvio.values;
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fechaRegistro);
    
    if (fecha >= fechaI && fecha <= fechaF) {
        if (data.grape15 === undefined || data.grape15 === 0) {
            Swal.fire({
                title: 'Consolidación envío',
                text: 'Debe de ingresar grape 15*1 ',
                type: 'error',
            })
        } else {
            let envio = data.envio.value.split('#');
            if (detalleEnvio.length > 0) {
                let dato = detalleEnvio.find(item => item.id === envio[0].trim());
                if (dato) {
                    NotificationManager.error(
                        'Envío ya fue ingresado',
                        'Error',
                        3000
                    );
                } else {
                    let producto = detalleEnvio.find(item => item.producto === envio[7].trim());
                    if (producto){
                        let libra9 = parseFloat(parseFloat(data.grape15)*9.4)
                        let libra18 = data.grape12 ? parseFloat(parseFloat(data.grape12)*18) : 0;
                        detalleEnvio.push({
                            id: envio[0],
                            fecha: envio[3],
                            cajas: envio[1],
                            libras_envio: envio[2],
                            envio: envio[4]+' - '+ envio[5], 
                            grape15: data.grape15,
                            libra9: libra9,
                            grape12: data.grape12 ? data.grape12 : 0,
                            libra18: libra18,
                            status: data.status,
                            producto: envio[7],
                            merma: parseFloat(parseFloat(parseFloat(libra18) + parseFloat(libra9)) - parseInt(envio[2])).toFixed(0)
                        })
                        data.grape15 = 0;
                        data.libra9 = 0;
                        data.grape12 = 0;
                        data.libra18 = 0;
                        data.detalleEnvio = detalleEnvio;
                        NotificationManager.success(
                            'Envío ingresado correctamente',
                            'Exito',
                            3000
                        );
                        dispatch(actualizar());
                    }else{
                        NotificationManager.error(
                            'Producto es distinto, revise detalle de envío',
                            'Error',
                            3000
                        );
                    }
                }
            }else{
                let libra9 = parseFloat(parseFloat(data.grape15)*9.4)
                let libra18 = data.grape12 ? parseFloat(parseFloat(data.grape12)*18) : 0;
                detalleEnvio.push({
                    id: envio[0],
                    fecha: envio[3],
                    cajas: envio[1],
                    libras_envio: envio[2],
                    envio: envio[4]+' - '+ envio[5], 
                    grape15: data.grape15,
                    libra9: libra9,
                    grape12: data.grape12 ? data.grape12 : 0,
                    libra18: libra18,
                    status: data.status,
                    producto: envio[7],
                    merma: parseFloat(parseFloat(parseFloat(libra18) + parseFloat(libra9)) - parseInt(envio[2])).toFixed(0)
                })
                data.grape15 = 0;
                data.libra9 = 0;
                data.grape12 = 0;
                data.libra18 = 0;
                data.detalleEnvio = detalleEnvio;
                NotificationManager.success(
                    'Envio ingresado correctamente',
                    'Exito',
                    3000
                );
                dispatch(actualizar());
            }
        }
    } else {
        Swal.fire({
            title: 'Consolidación envío',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })
    }
}

const eliminarLineaEnvio = (id) => (dispatch) => {
    detalleEnvio.forEach((element, index) => {
        if (element.id === id) {
            detalleEnvio.splice(index, 1);
        }
    })
    dispatch(actualizar());
};

const limpiar = () => (dispatch) => {
    detalleEnvio = []
    dispatch(initializeForm('FormConsolidacionEnvio'));
    dispatch({ type: DETALLE_ENVIO, detalleEnv: [] })
};

const eliminarConsolidacion = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`/consolidacion_envio/${id}`).then(() => {
        dispatch(listar());
        NotificationManager.success(
            'Consolidacion borrada correctamente',
            'Exito',
            3000
        );
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const validarEnvio = (envio) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, envio };
    return api.get('/envio_produccion/validar_envio', params).then((response)=>{
        if (parseInt(response.lineas) >= 2){
            Swal.fire({
                title: 'Consolidación envío',
                text: 'Envio tiene mas de dos productos',
                type: 'error',
            })
            return true;
        }else{
            return false;
        }
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar envios',
            'Error',
            3000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const actions = {
    listar,
    leer,
    getModulo,
    leerPeriodoD,
    listarClientes,
    listarEnvios,
    numeroDevolucion,
    registroConsolidacion,
    agregarLinea,
    eliminarLineaEnvio,
    limpiar,
    eliminarConsolidacion,
    validarEnvio
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_ENVIO]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [PERIODO_D]: (state, { periodoD }) => {
        return {
            ...state,
            periodoD,
        };
    },
    [PARAMETRO]: (state, { datos }) => {
        return {
            ...state,
            datos,
        };
    },
    [DETALLE_CENVIO]: (state, { detalleCEnv }) => {
        return {
            ...state,
            detalleCEnv,
        };
    },
    [CLIENTE]: (state, { cli }) => {
        return {
            ...state,
            cli,
        };
    },
    [DETALLE_ENVIO]: (state, { detalleEnv }) => {
        return {
            ...state,
            detalleEnv,
        };
    },
    [LISTADO_ENVIOS]: (state, { envios }) => {
        return {
            ...state,
            envios
        }
    },
    [NUMERO_ENVIO]: (state, { numeroDev }) => {
        return {
            ...state,
            numeroDev
        }
    },
};

export const initialState = {
    loader: false,
    lectura: [],
    modulo: [],
    periodoD: [],
    cli: [],
    detalleEnv: [],
    numeroS: [],
    datos: [],
    data: [],
    monedas: [],
    envios: [],
    numeroDev: 0,
    detalleCEnv: []
};

export default handleActions(reducers, initialState)