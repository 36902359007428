import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import { validate, validators } from 'validate-redux-form';
import PropTypes from 'prop-types';
import NavbarModulo from '../../components/NavbarModulo';

import {
  renderField,
  renderNumber,
  SelectField,
  renderFieldCheck,
  renderFieldCheckB,
  AsyncSelectField
} from "../../Utils/renderField/renderField";

function ClientesForm(props) {
  const { registroCliente, modificarCliente, 
    crear, lectura, obtenerPaises, datos, modulo,
    listarListadoPrecios } = props;
  const editar = window.location.href.includes('editar');
  let titulo = editar ? 'Modificar Cliente' : 'Crear Cliente';
  let disabled = false;
  if (crear === false && editar === false) {
    disabled = true;
    titulo = 'Ver Cliente'
  }
  localStorage.setItem('tituloForm', titulo);
  let autoFocus = true;

  const tipos = [
    {
      value: 'L',
      label: 'LOCAL'
    },
    {
      value: 'E',
      label: 'EXTERIOR'
    },
    {
      value: 'P',
      label: 'PERSONA'
    },
  ];
  
  const [activo, setActivo] = useState(true);
  
  const onCheckboxClicked = (idx, isChecked) => {
    setActivo(isChecked);
  }

  const handleChange = (e) => {
    setTipoCli(e.target.value);
  }
  const [tipoCli, setTipoCli] = useState(0);

  let ruta = window.location.href;
  let id_emp = ruta.split('/');
  
  return (
    <div>
      <NavbarModulo
        modulo={modulo}
        titulo={titulo}
      />
      <br />
      <div className="mb-4 card card-small text-white bg-dark mb-3">
        <div className="p-0 pt-3 d-flex flex-column flex-md-row">
          <div className="d-flex flex-column flex-1 mx-4">
            <div className='mb-2 w-25'>
              <Field
                autoFocus={autoFocus}
                name="identificadorCli"
                placeholder="Identificador"
                className="form-control"
                component={renderField}
                disabled={editar ? true : disabled}
                msj="Identificador"
              />
            </div>
            <div className='row'>
              <div className='mb-2 col-8'>
                <Field
                  name="nombre"
                  placeholder="Nombre"
                  className="form-control"
                  component={renderField}
                  disabled={disabled}
                  msj="Nombre"
                />
              </div>
              {obtenerPaises &&
                <div className='col-4'>
                  <div className="form-floating">
                    <Field
                      name="pais"
                      options={obtenerPaises}
                      crear={crear}
                      dato={crear === true ? 0 : lectura.pais}
                      component={SelectField}
                      className="form-select"
                      disabled={disabled}
                      msj="Pais"
                    />
                  </div>
                </div>
              }
            </div>

            <div className='mb-2 w-75'>
              <Field
                name="direccionCli"
                placeholder="Direccion"
                className="form-control"
                component={renderField}
                disabled={disabled}
                msj="Dirección"
              />
            </div>
            <div className='row'>
              <div className='col-4'>
                <Field
                  name="telefonoCli"
                  placeholder="Telefono"
                  className="form-control"
                  component={renderField}
                  disabled={disabled}
                  msj="Telefono"
                />
              </div>
              <div className='col-4'>
                <Field
                  name="correo"
                  placeholder="Correo electrónico"
                  className="form-control"
                  component={renderField}
                  disabled={disabled}
                  msj="Correo electronico"
                />
              </div>
              <div className='col-4'>
                <Field
                  name="dias_credito"
                  placeholder="Dias credito"
                  className="form-control"
                  component={renderNumber}
                  disabled={disabled}
                  msj="Dias crédito"
                />
              </div>
            </div>
            <div className='row mt-2'>
              <div className='col-4'>
                <Field
                  name="limite_credito"
                  placeholder="Limite credito"
                  className="form-control"
                  component={renderNumber}
                  disabled={disabled}
                  msj="Limite crédito"
                />
              </div>
              <div className='col-4'>
                {(crear === true) &&
                  <div className="form-floating">
                    <Field
                      name="tipo_cliente"
                      options={tipos}
                      dato={0}
                      component={SelectField}
                      className="form-select"
                      disabled={disabled}
                      msj="Tipo cliente"
                    />
                </div>
                }
                {editar === true &&
                  <div className="form-floating">
                    <Field
                      name="tipo_cliente"
                      options={tipos}
                      dato={lectura.tipo_cliente}
                      component={SelectField}
                      className="form-select"
                      disabled={disabled}
                      msj="Tipo cliente"
                    />
                  </div>
                }
                {crear === false && editar == false &&
                
                  <div className="form-floating">
                    <Field
                      name="tipo_cliente"
                      options={tipos}
                      dato={lectura.tipo_cliente}
                      component={SelectField}
                      className="form-select"
                      disabled={disabled}
                      msj="Tipo cliente"
                    />
                  </div>
                }
              </div>
              <div className='col-4 mt-3'>
                {crear === true ? (
                  <Field
                    name="activo"
                    label="Activo"
                    checked={activo}
                    onChange={onCheckboxClicked}
                    component={renderFieldCheckB}
                  />
                ) :
                  <Field
                    disabled={disabled}
                    name="activo"
                    label="Activo"
                    component={renderFieldCheck}
                  />
                }
              </div>
            </div>
            {datos.listado_precios === 'S' && crear === false && editar == false &&
              <div className='row mt-2'>
                <div className='col-6'>
                  <Field
                    name="nombre_listado_precios"
                    placeholder="Listado de precios"
                    className="form-control"
                    component={renderField}
                    disabled={true}
                    msj="Listado de precios"
                  />
                </div>
              </div>
            }
            {datos.listado_precios === 'S' && crear === true &&
              <div className='row mt-2'>
                <div className='col-6'>
                  <Field
                    name="cliente_listado_precio"
                    placeholder="Listado de precios..."
                    className="form-control-find"
                    component={AsyncSelectField}
                    loadOptions={listarListadoPrecios}
                    msj="Listado de precios"
                  />
                </div>
              </div>
            }
            {datos.listado_precios === 'S' && editar == true &&
              <div>
                <div className='row mt-2'>
                  <div className='col-6'>
                    <Field
                      name="nombre_listado_precios"
                      placeholder="Listado de precios"
                      className="form-control"
                      component={renderField}
                      disabled={true}
                      msj="Listado de precios"
                    />
                  </div>
                </div>
                <div className='row mt-2'>
                  <div className='col-6'>
                    <Field
                      name="cliente_listado_precios"
                      placeholder="Listado de precios..."
                      className="form-control-find"
                      component={AsyncSelectField}
                      loadOptions={listarListadoPrecios}
                      msj="Listado de precios"
                    />
                  </div>
                </div>
              </div>
            }
            {datos.agricola === 'S' && crear === true &&
              <div className='row mt-2'>
                <div className='col-3'>
                  <Field
                    name="codigo_productor"
                    placeholder="Codigo productor"
                    className="form-control-find"
                    component={renderField}
                    msj="Codigo productor"
                  />
                </div>
              </div>
            }
            {datos.agricola === 'S' && editar === true &&
              <div className='row mt-2'>
                <div className='col-3'>
                  <Field
                    name="codigo_productor"
                    placeholder="Codigo productor"
                    className="form-control-find"
                    component={renderField}
                    msj="Codigo productor"
                  />
                </div>
              </div>
            }
            {datos.agricola === 'S' && crear === false && editar === false &&
              <div className='row mt-2'>
                <div className='col-3'>
                  <Field
                    name="codigo_productor"
                    placeholder="Codigo productor"
                    className="form-control-find"
                    component={renderField}
                    disabled={true}
                    msj="Codigo productor"
                  />
                </div>
              </div>
            }
            <div className='d-flex flex-row mt-3'>
              {disabled == false && editar === false &&
                <button
                  className='btn mr-2 mb-3 btn-primary'
                  onClick={() => registroCliente(datos)}
                >
                  Guardar
                </button>
              }
              {disabled == false && editar === true &&
                <button
                  className='btn mr-2 mb-3 btn-primary'
                  onClick={() => modificarCliente(datos)}
                >
                  Modificar
                </button>
              }

              <a
                href={`/#/facturacion/${id_emp[5]}/clientes`}
                className='btn btn-secondary mb-3'
              >
                Regresar
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ClientesForm.propTypes = {
  handleSubmit: PropTypes.func,
  crear: PropTypes.bool,
  lectura: PropTypes.object,
  obtenerPaises: PropTypes.object,
  datos: PropTypes.object,
  modulo: PropTypes.object,
};

export default reduxForm({
  form: 'FormCliente',
  validate: (data) => {
    return validate(data, {
      identificador: validators.exists()('Este campo es requerido'),
      nombre: validators.exists()('Este campo es requerido'),
      tipo_proveedor: validators.exists()('Seleccione tipo'),
    });
  },
})(ClientesForm);
