import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';
import moment from 'moment';
import { validar } from "../../../common/components/components/ValidarNit";

const LISTADO_COMPRA = 'LISTADO_COMPRA';
const LISTADO_TIPOS_DOCU = 'LISTADO_TIPOS_DOCU';
const LISTADO_PROVEEDOR = 'LISTADO_PROVEEDOR';
const GUARDAR_LIQUIDACION = 'GUARDAR_LIQUIDACION';
const GUARDAR_POLIZA = 'GUARDAR_POLIZA';
const DETALLE_POLIZA = 'DETALLE_POLIZA';
const DETALLE_LIQUIDACION = 'DETALLE_LIQUIDACION';
const CONSULTA_FACTURA = 'CONSULTA_FACTURA';
const VER_FACTURA = 'VER_FACTURA';
const LISTADO_PROYECTOS = 'LISTADO_PROYECTOS';
const LISTADO_SUBPROYECTOS = 'LISTADO_SUBPROYECTOS';
const LISTADO_FASES = 'LISTADO_FASES';
const DETALLE_MATERIALES = 'DETALLE_MATERIALES';
const ENCABEZADO_MATERIALES = 'ENCABEZADO_MATERIALES';
const LOADER = 'LOADER';
const LOADERC = 'LOADERC';
const NIVEL = 'NIVEL';
const PERIODO_D = 'PERIODOD';
const MODULO = 'MODULO';
const PARAMETRO = 'PARAMETRO';
const LISTADO_PAISES = 'LISTADO_PAISES';
const LISTADO_IMPUESTO = 'LISTADO_IMPUESTO';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setLoaderC = loaderC => ({
    type: LOADERC,
    loaderC,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let id_period = id_emp[7];
    //const params = { page, id, id_period };
    const params = { id, id_period };
    dispatch(setLoader(true));
    api.get('/liquidacion_gasto', params).then((response) => {
        dispatch({ type: LISTADO_COMPRA, data: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar compras',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const listarTiposDocu = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    api.get('/tipos', params).then((response) => {
        const tiposD = [];
        response.results.forEach(item => {
            if (item.tipo_documento === 'CARGO') {
                tiposD.push({
                    value: item.id,
                    label: item.tipo + ' - ' + item.descripcion,
                    search: item.categoria
                })
            }
        })
        dispatch({ type: LISTADO_TIPOS_DOCU, tipos: tiposD });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar tipos de documento',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const listarProveedores = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let tipo = 'liquidacion';
    const params = { id, search, tipo }
    return api.get('/proveedor/empresa_proveedores', params).then(data => {
        const list_proveedor = [];
        if (data) {
            data.forEach(item => {
                list_proveedor.push({
                    value: item.id,
                    label: item.identificador + ' ' + item.nombre
                })
            })
        }
        return list_proveedor;
    }).catch((error) => {
        return [];
    })
}

export const listarCuentas = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/cuentas/empresa_cuentas/', params).then(data => {
        const list_cuentas = [];
        if (data) {
            data.forEach(item => {
                if (item.tipo === 'A') {
                    list_cuentas.push({
                        value: item.id,
                        label: item.cuenta + ' ' + item.nombre,
                        isDisabled: true
                    })
                } else {
                    list_cuentas.push({
                        value: item.id,
                        label: item.cuenta + ' ' + item.nombre
                    })
                }
            })
        }
        return list_cuentas;
    }).catch((error) => {
        return [];
    })
}

export const listarCC = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/centrocosto/empresa_centro_costo/', params).then(data => {
        const list_cc = [];
        if (data) {
            data.forEach(item => {
                list_cc.push({
                    value: item.id,
                    label: item.codigo + ' ' + item.descripcion
                })
            })
        }
        return list_cc;
    }).catch((error) => {
        return [];
    })
}

const leerDetalle = id => (dispatch) => {
    api.get(`/poliza_detalle/${id}`).then((response) => {
        dispatch({ type: DETALLE_POLIZA, detalle: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            2000
        );
    })
};

export const listarNiveles = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    dispatch(setLoader(true));
    api.get('/niveles/nivel_cuenta', params).then((response) => {
        dispatch({ type: NIVEL, nivel: response });
    }).catch((error) => {

        NotificationManager.error(
            'Ocurrió un error al listar nivel',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const eliminarLinea = id => (dispatch) => {
    const poliza = localStorage.getItem('idPolizaCompraLiq');
    dispatch(setLoader(true));
    api.eliminar(`poliza_detalle/${id}`).then(() => {
        const editar = window.location.href.includes('editar');
        if (editar) {
            dispatch(leerDetalleMod(poliza));
        } else {
            dispatch(leerDetalle(poliza));
        }
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar linea de poliza',
            'Error',
            3000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const registroCuenta = (formato) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');

    const data = getStore().form.LiquidacionFormTabs.values;
    let cuenta = data.cuenta
    let f = formato.split('-');
    let cont = 0;
    let formatoCuenta = '';
    let digito = '';

    if (f.length === 3) {
        let nivel1 = f[0].length
        digito = cuenta.substr(cont, nivel1);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel2 = f[1].length;
        cont = cont + nivel1;
        digito = cuenta.substr(cont, nivel2);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel3 = f[2].length;
        cont = cont + nivel2;
        digito = cuenta.substr(cont, nivel3);
        formatoCuenta = formatoCuenta + digito;

    }

    if (f.length === 4) {
        let nivel1 = f[0].length
        digito = cuenta.substr(cont, nivel1);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel2 = f[1].length;
        cont = cont + nivel1;
        digito = cuenta.substr(cont, nivel2);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel3 = f[2].length;
        cont = cont + nivel2;
        digito = cuenta.substr(cont, nivel3);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel4 = f[3].length;
        cont = cont + nivel3;
        digito = cuenta.substr(cont, nivel4);
        formatoCuenta = formatoCuenta + digito;
    }

    if (f.length === 5) {
        let nivel1 = f[0].length
        digito = cuenta.substr(cont, nivel1);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel2 = f[1].length;
        cont = cont + nivel1;
        digito = cuenta.substr(cont, nivel2);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel3 = f[2].length;
        cont = cont + nivel2;
        digito = cuenta.substr(cont, nivel3);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel4 = f[3].length;
        cont = cont + nivel3;
        digito = cuenta.substr(cont, nivel4);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel5 = f[4].length;
        cont = cont + nivel4;
        digito = cuenta.substr(cont, nivel5);
        formatoCuenta = formatoCuenta + digito;
    }

    if (f.length === 6) {
        let nivel1 = f[0].length
        digito = cuenta.substr(cont, nivel1);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel2 = f[1].length;
        cont = cont + nivel1;
        digito = cuenta.substr(cont, nivel2);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel3 = f[2].length;
        cont = cont + nivel2;
        digito = cuenta.substr(cont, nivel3);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel4 = f[3].length;
        cont = cont + nivel3;
        digito = cuenta.substr(cont, nivel4);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel5 = f[4].length;
        cont = cont + nivel4;
        digito = cuenta.substr(cont, nivel5);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel6 = f[5].length;
        cont = cont + nivel5;
        digito = cuenta.substr(cont, nivel6);
        formatoCuenta = formatoCuenta + digito;
    }
    let idPoliza = localStorage.getItem('idPoliza')
    const formData = {
        cuenta: formatoCuenta,
        nombre: data.nombre,
        tipo: data.tipoC,
        naturaleza: data.naturaleza,
        empresa: id_emp[5],
        poliza: idPoliza
    }
    dispatch(setLoaderC(true));
    api.post('/cuentas/', formData).then((response) => {
        if (idPoliza) {
            NotificationManager.success(
                'Cuenta creada' + ' ' + response.cuenta.cuenta + ' ' + response.cuenta.nombre,
                'Exito',
                0
            );
            response.cuenta = '';
            response.nombre = '';
            response.fecha = response.poliza.fecha;
            response.tipo = response.poliza.tipo;
            response.numero_poliza = response.poliza.numero_poliza;
            response.descripcion = response.poliza.descripcion;
            listarCuentas();

        } else {
            NotificationManager.success(
                'Cuenta creada' + ' ' + response.cuenta + ' ' + response.nombre,
                'Exito',
                0
            );
            response.cuenta = '';
            response.nombre = '';
            listarCuentas();
        }

    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoaderC(false));
    });
}

export const reporteCuentas = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let reporte = 'reporte';
    const params = { id, reporte };
    dispatch(setLoaderC(true));;
    api.getPdf('/cuentas/', params).then((response) => {
        if (response.headers['content-type'] == 'application/pdf') {
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'reporte_cuentas_contables.pdf';
            window.open(link, '_blank');
            return false;
        }
    }).catch((error) => {

        NotificationManager.error(
            'Ocurrió un error al generar reporte',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoaderC(false));
    });
}

export const registroCompra = (impuesto, periodoD, datos) => (dispatch, getStore) => {
    const data = getStore().form.FormLiquidacionGastos.values;

    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fechaRegistro);
    let poliza = '';
    let fechaFinalD = moment(data.fecha);
    if (fecha >= fechaI && fecha <= fechaF) {
        if (fechaFinalD.isAfter(periodoD.fecha_fin)) {
            Swal.fire({
                title: 'Liquidación de gastos',
                text: 'Fecha es posterior al periodo: ' + moment(periodoD.fecha_fin).format("DD/MM/YYYY"),
                type: 'error',
            })
        } else {
            let doc = localStorage.getItem('tipoDocumento');
            if (doc === 'Seleccionar...' || doc === null) {
                Swal.fire({
                    title: 'Liquidación de gastos',
                    text: 'Debe de seleccionar tipo de documento',
                    type: 'error',
                })
            } else {
                if (data.fecha === undefined || data.fecha === null) {
                    Swal.fire({
                        title: 'Liquidación de gastos',
                        text: 'Debe de ingresar fecha de documento',
                        type: 'error',
                    })
                } else {
                    if (data.proveedor_documento === undefined || data.proveedor_documento === null) {
                        Swal.fire({
                            title: 'Liquidación de gastos',
                            text: 'Debe de seleccionar proveedor',
                            type: 'error',
                        })
                    } else {
                        if (datos.contabilidad === 'S') {
                            if (data.cuenta === undefined || data.cuenta === null) {
                                Swal.fire({
                                    title: 'Liquidación de gastos',
                                    text: 'Debe de seleccionar cuenta',
                                    type: 'error',
                                })
                            } else {
                                const detalleLiq = localStorage.getItem('detalleID');
                                let ruta = window.location.href;
                                let id_emp = ruta.split('/');
                                let fec = data.fechaRegistro
                                let fecR = fec.split('-')
                                let serieL = fecR[0] + '-' + fecR[1]

                                let PIva = parseFloat(parseFloat(parseFloat(impuesto.porcentaje_iva) / 100) + 1).toFixed(2);
                                let CGSuper = parseFloat(impuesto.costo_galon_super).toFixed(2);
                                let CGRegular = parseFloat(impuesto.costo_galon_regular).toFixed(2);
                                let CGDiesel = parseFloat(impuesto.costo_galon_diesel).toFixed(2);

                                let comb = data.combustible;
                                let SumIDP = 0.00;
                                let SumBase = 0.00;
                                let IdpSuper = 0.00;
                                let IdpRegular = 0.00;
                                let IdpDiesel = 0.00;
                                let BaseSuper = 0.00;
                                let BaseRegular = 0.00;
                                let BaseDiesel = 0.00;
                                let BaseOtros = 0.00;
                                let IvaSup = 0.00;
                                let IvaReg = 0.00;
                                let IvaDie = 0.00;
                                let IvaOtros = 0.00;
                                let SumIva = 0.00;
                                let SumTotalC = 0.00;

                                let gaSuper = data.gsuper;
                                let gaRegular = data.gregular;
                                let gaDiesel = data.gdiesel;

                                let costoSuper = data.csuper;
                                let costoRegular = data.cregular;
                                let costoDiesel = data.cdiesel;
                                let otros = data.otros;
                                if (gaSuper === undefined) {
                                    gaSuper = 0.00
                                }
                                if (gaRegular === undefined) {
                                    gaRegular = 0.00
                                }
                                if (gaDiesel === undefined) {
                                    gaDiesel = 0.00
                                }

                                if (costoSuper === undefined) {
                                    costoSuper = 0.00
                                }
                                if (costoRegular === undefined) {
                                    costoRegular = 0.00
                                }
                                if (costoDiesel === undefined) {
                                    costoDiesel = 0.00
                                }
                                if (otros === undefined) {
                                    otros = 0.00
                                }

                                let baseB = 0.00;
                                let ivaB = 0.00;
                                let SumBaD = 0.00;
                                let baseS = 0.00;
                                let ivaS = 0.00;
                                let SumIvD = 0.00;
                                let SumTotalD = 0.00;

                                let pro = data.proveedor_liquidacion;
                                if (pro === undefined) {
                                    pro = data.proveedor;
                                } else {
                                    pro = data.proveedor_liquidacion.value;
                                }
                                if (datos.contabilidad === 'S') {
                                    poliza = 'compras';
                                }
                                if (datos.contabilidad === 'N') {
                                    poliza = '';
                                }

                                let bienes = data.bienes;
                                let servicios = data.servicios;
                                let exento = data.exento;

                                if (bienes === undefined) {
                                    bienes = 0.00
                                }
                                if (servicios === undefined) {
                                    servicios = 0.00
                                }
                                if (exento === undefined) {
                                    exento = 0.00
                                }
                                let iva = JSON.parse(localStorage.getItem('A_iva'));

                                //Verificar si la factura es mas de dos meses
                                let fechaI = moment(periodoD.fecha_inicio)
                                let date_f = moment(fechaI).subtract(2, 'months').format();
                                let date_fin = moment(date_f).subtract(1, 'days').format();
                                let fecha = moment(data.fecha).isBetween(date_fin, fechaI, 'days');
                                let dif = fechaI.diff(data.fecha, 'days')
                                if (fecha === false && dif >= 1) {
                                    if (comb === true) {
                                        iva = false;
                                        SumIDP = 0.00;
                                        BaseSuper = parseFloat(costoSuper).toFixed(2);
                                        BaseRegular = parseFloat(costoRegular).toFixed(2);
                                        BaseDiesel = parseFloat(costoDiesel).toFixed(2);
                                        BaseOtros = parseFloat(otros).toFixed(2);
                                        SumBase = parseFloat(parseFloat(BaseSuper) + parseFloat(BaseRegular) + parseFloat(BaseDiesel) + parseFloat(BaseOtros)).toFixed(2);
                                        SumIva = 0.00;
                                        SumTotalC = parseFloat(parseFloat(costoSuper) + parseFloat(costoRegular) + parseFloat(costoDiesel) + parseFloat(otros)).toFixed(2);
                                    }else{
                                        iva = false;
                                        baseB = parseFloat(bienes).toFixed(2);
                                        ivaB = 0.00;

                                        baseS = parseFloat(servicios).toFixed(2);
                                        ivaS = 0.00;

                                        SumBaD = parseFloat(parseFloat(baseB) + parseFloat(baseS)).toFixed(2);

                                        SumIvD = parseFloat(parseFloat(ivaB) + parseFloat(ivaS)).toFixed(2);
                                        SumTotalD = parseFloat(parseFloat(bienes) + parseFloat(servicios) + parseFloat(exento)).toFixed(2);
                                    }
                                } else {
                                    if (comb === true) {

                                        IdpSuper = parseFloat(parseFloat(gaSuper).toFixed(3) * CGSuper).toFixed(2);
                                        IdpRegular = parseFloat(parseFloat(gaRegular).toFixed(3) * CGRegular).toFixed(2);
                                        IdpDiesel = parseFloat(parseFloat(gaDiesel).toFixed(3) * CGDiesel).toFixed(2)

                                        SumIDP = parseFloat(parseFloat(IdpSuper) + parseFloat(IdpRegular) + parseFloat(IdpDiesel)).toFixed(2);

                                        BaseSuper = parseFloat((parseFloat(costoSuper) - parseFloat(IdpSuper)) / PIva).toFixed(2);
                                        BaseRegular = parseFloat((parseFloat(costoRegular) - parseFloat(IdpRegular)) / PIva).toFixed(2);
                                        BaseDiesel = parseFloat((parseFloat(costoDiesel) - parseFloat(IdpDiesel)) / PIva).toFixed(2);
                                        BaseOtros = parseFloat(parseFloat(otros) / PIva).toFixed(2);

                                        SumBase = parseFloat(parseFloat(BaseSuper) + parseFloat(BaseRegular) + parseFloat(BaseDiesel) + parseFloat(BaseOtros)).toFixed(2);

                                        IvaSup = parseFloat((parseFloat(costoSuper) - parseFloat(IdpSuper)) - parseFloat(BaseSuper)).toFixed(2);
                                        IvaReg = parseFloat((parseFloat(costoRegular) - parseFloat(IdpRegular)) - parseFloat(BaseRegular)).toFixed(2);
                                        IvaDie = parseFloat((parseFloat(costoDiesel) - parseFloat(IdpDiesel)) - parseFloat(BaseDiesel)).toFixed(2);
                                        IvaOtros = parseFloat(parseFloat(otros) - parseFloat(BaseOtros)).toFixed(2);

                                        SumIva = parseFloat(parseFloat(IvaSup) + parseFloat(IvaReg) + parseFloat(IvaDie) + parseFloat(IvaOtros)).toFixed(2);

                                        SumTotalC = parseFloat(parseFloat(costoSuper) + parseFloat(costoRegular) + parseFloat(costoDiesel) + parseFloat(otros)).toFixed(2);

                                    }

                                    if (comb === undefined) {
                                        comb = false;
                                    }
                                    if (iva === true && comb === false) {
                                        baseB = parseFloat(parseFloat(bienes) / PIva).toFixed(2);
                                        ivaB = parseFloat(parseFloat(bienes) - parseFloat(baseB)).toFixed(2);

                                        baseS = parseFloat(parseFloat(servicios) / PIva).toFixed(2);
                                        ivaS = parseFloat(parseFloat(servicios) - parseFloat(baseS)).toFixed(2);

                                        SumBaD = parseFloat(parseFloat(baseB) + parseFloat(baseS)).toFixed(2);

                                        SumIvD = parseFloat(parseFloat(ivaB) + parseFloat(ivaS)).toFixed(2);
                                        SumTotalD = parseFloat(parseFloat(bienes) + parseFloat(servicios) + parseFloat(exento)).toFixed(2);

                                    }
                                    if (iva === false) {
                                        SumBaD = parseFloat(parseFloat(bienes) + parseFloat(servicios) + parseFloat(exento)).toFixed(2);
                                        SumIvD = 0.00;
                                        SumTotalD = parseFloat(parseFloat(bienes) + parseFloat(servicios) + parseFloat(exento)).toFixed(2);
                                    }
                                }

                                const formData = {
                                    fecha_registro: data.fechaRegistro,
                                    serie: serieL,
                                    numero: 1,
                                    descripcionL: data.observaciones,
                                    fecha: data.fecha,
                                    serieD: data.serieD ? data.serieD.toUpperCase() : '',
                                    numeroD: data.numero.toUpperCase(),
                                    afectaIva: iva,
                                    combustible: data.combustible,
                                    gSuper: data.gsuper,
                                    cSuper: data.csuper,
                                    gRegular: data.gregular,
                                    cRegular: data.cregular,
                                    gDiesel: data.gdiesel,
                                    cDiesel: data.cdiesel,
                                    otros: data.otros,
                                    idp: SumIDP,
                                    bienes: data.bienes,
                                    servicios: data.servicios,
                                    exento: data.exento,
                                    tipo: localStorage.getItem('tipoDocumento'),
                                    periodo: id_emp[7],
                                    proveedor_liq: pro,
                                    proveedor: data.proveedor_documento.value,
                                    descripcion: data.descripcion,
                                    iva: comb ? SumIva : SumIvD,
                                    base: comb ? SumBase : SumBaD,
                                    total: comb ? SumTotalC : SumTotalD,
                                    modulo: 'CMP',
                                    empresa: id_emp[5],
                                    poliza: poliza,
                                    cuenta: datos.contabilidad === 'S' ? data.cuenta.value : '',
                                    detalle: detalleLiq,
                                    idPoliza: localStorage.getItem('idPolizaCompraLiq')
                                }

                                dispatch(setLoader(true));
                                api.post('/liquidacion_gasto/', formData).then((response) => {
                                    const editar = window.location.href.includes('editar');
                                    if (editar) {
                                        response.fechaRegistro = response.compra.fecha_registro;
                                        response.numeroL = response.compra.numero;
                                        response.serie = response.compra.serie;
                                        response.observaciones = response.compra.descripcion;
                                        response.identificador = response.compra.nit_proveedor;
                                        response.proveedor = response.compra.proveedor;
                                        response.proveedorN = response.compra.proveedorN;
                                        response.totalLiq = response.total;
                                        response.id = response.compra.id;

                                        localStorage.setItem('LIQ', true);
                                        if (response.compra.poliza) {
                                            dispatch(leerDetalleMod(response.compra.poliza));
                                        }
                                        dispatch(initializeForm('FormLiquidacionGastos', response));
                                        dispatch(leerDetalleLiquidacion(response.detalle));

                                    }else if(detalleLiq){
                                        response.fechaRegistro = response.compra.fecha_registro;
                                        response.numeroL = response.compra.numero;
                                        response.serie = response.compra.serie;
                                        response.observaciones = response.compra.descripcion;
                                        response.identificador = response.compra.nit_proveedor;
                                        response.proveedor = response.compra.proveedor;
                                        response.proveedorN = response.compra.proveedorN;
                                        response.totalLiq = response.total;
                                        response.id = response.compra.id;

                                        localStorage.setItem('LIQ', true);
                                        if (response.idPoliza) {
                                            localStorage.setItem('idPolizaCompraLiq', response.idPoliza);
                                            dispatch(leerDetalle(response.idPoliza));
                                        }
                                        dispatch(initializeForm('FormLiquidacionGastos', response));
                                        dispatch(leerDetalleLiquidacion(response.detalle));

                                    }else {
                                        response.fechaRegistro = response.compra.fecha_registro;
                                        response.numeroL = response.compra.numero;
                                        response.serie = response.compra.serie;
                                        response.observaciones = response.compra.descripcion;
                                        response.identificador = response.compra.nit_proveedor;
                                        response.proveedor = response.compra.proveedor;
                                        response.proveedorN = response.compra.proveedorN;
                                        response.totalLiq = response.total;
                                        response.id = response.compra.id;

                                        localStorage.setItem('LIQ', true);

                                        if (response.idPoliza) {
                                            localStorage.setItem('idPolizaCompraLiq', response.idPoliza);
                                            dispatch(leerDetalle(response.idPoliza));
                                        }
                                        localStorage.setItem('detalleID', response.detalle);
                                        dispatch(initializeForm('FormLiquidacionGastos', response));
                                        dispatch(leerDetalleLiquidacion(response.detalle));
                                    }

                                    NotificationManager.success(
                                        'Compra creada correctamente',
                                        'Exito',
                                        3000
                                    );

                                }).catch((error) => {
                                    NotificationManager.error(
                                        error.msj,
                                        'Error',
                                        0
                                    );
                                }).finally(() => {
                                    dispatch(setLoader(false));
                                });
                            }
                        } else {
                            const detalleLiq = localStorage.getItem('detalleID');
                            let ruta = window.location.href;
                            let id_emp = ruta.split('/');
                            let fec = data.fechaRegistro
                            let fecR = fec.split('-')
                            let serieL = fecR[0] + '-' + fecR[1]

                            let PIva = parseFloat(parseFloat(parseFloat(impuesto.porcentaje_iva) / 100) + 1).toFixed(2);
                            let CGSuper = parseFloat(impuesto.costo_galon_super).toFixed(2);
                            let CGRegular = parseFloat(impuesto.costo_galon_regular).toFixed(2);
                            let CGDiesel = parseFloat(impuesto.costo_galon_diesel).toFixed(2);

                            let comb = data.combustible;
                            let SumIDP = 0.00;
                            let SumBase = 0.00;
                            let IdpSuper = 0.00;
                            let IdpRegular = 0.00;
                            let IdpDiesel = 0.00;
                            let BaseSuper = 0.00;
                            let BaseRegular = 0.00;
                            let BaseDiesel = 0.00;
                            let BaseOtros = 0.00;
                            let IvaSup = 0.00;
                            let IvaReg = 0.00;
                            let IvaDie = 0.00;
                            let IvaOtros = 0.00;
                            let SumIva = 0.00;
                            let SumTotalC = 0.00;

                            let gaSuper = data.gsuper;
                            let gaRegular = data.gregular;
                            let gaDiesel = data.gdiesel;

                            let costoSuper = data.csuper;
                            let costoRegular = data.cregular;
                            let costoDiesel = data.cdiesel;
                            let otros = data.otros;
                            if (gaSuper === undefined) {
                                gaSuper = 0.00
                            }
                            if (gaRegular === undefined) {
                                gaRegular = 0.00
                            }
                            if (gaDiesel === undefined) {
                                gaDiesel = 0.00
                            }

                            if (costoSuper === undefined) {
                                costoSuper = 0.00
                            }
                            if (costoRegular === undefined) {
                                costoRegular = 0.00
                            }
                            if (costoDiesel === undefined) {
                                costoDiesel = 0.00
                            }
                            if (otros === undefined) {
                                otros = 0.00
                            }

                            let baseB = 0.00;
                            let ivaB = 0.00;
                            let SumBaD = 0.00;
                            let baseS = 0.00;
                            let ivaS = 0.00;
                            let SumIvD = 0.00;
                            let SumTotalD = 0.00;

                            let pro = data.proveedor_liquidacion;
                            if (pro === undefined) {
                                pro = data.proveedor;
                            } else {
                                pro = data.proveedor_liquidacion.value;
                            }
                            if (datos.contabilidad === 'S') {
                                poliza = 'compras';
                            }
                            if (datos.contabilidad === 'N') {
                                poliza = '';
                            }

                            let bienes = data.bienes;
                            let servicios = data.servicios;
                            let exento = data.exento;

                            if (bienes === undefined) {
                                bienes = 0.00
                            }
                            if (servicios === undefined) {
                                servicios = 0.00
                            }
                            if (exento === undefined) {
                                exento = 0.00
                            }
                            let iva = JSON.parse(localStorage.getItem('A_iva'));

                            //Verificar si la factura es mas de dos meses
                            let fechaI = moment(periodoD.fecha_inicio)
                            let date_f = moment(fechaI).subtract(2, 'months').format();
                            let date_fin = moment(date_f).subtract(1, 'days').format();
                            let fecha = moment(data.fecha).isBetween(date_fin, fechaI, 'days');
                            let dif = fechaI.diff(data.fecha, 'days')
                            if (fecha === false && dif >= 1) {
                                if (comb === true) {
                                    iva = false;
                                    SumIDP = 0.00;
                                    BaseSuper = parseFloat(costoSuper).toFixed(2);
                                    BaseRegular = parseFloat(costoRegular).toFixed(2);
                                    BaseDiesel = parseFloat(costoDiesel).toFixed(2);
                                    BaseOtros = parseFloat(otros).toFixed(2);
                                    SumBase = parseFloat(parseFloat(BaseSuper) + parseFloat(BaseRegular) + parseFloat(BaseDiesel) + parseFloat(BaseOtros)).toFixed(2);
                                    SumIva = 0.00;
                                    SumTotalC = parseFloat(parseFloat(costoSuper) + parseFloat(costoRegular) + parseFloat(costoDiesel) + parseFloat(otros)).toFixed(2);
                                }else{
                                    iva = false;
                                    baseB = parseFloat(bienes).toFixed(2);
                                    ivaB = 0.00;

                                    baseS = parseFloat(servicios).toFixed(2);
                                    ivaS = 0.00;

                                    SumBaD = parseFloat(parseFloat(baseB) + parseFloat(baseS)).toFixed(2);

                                    SumIvD = parseFloat(parseFloat(ivaB) + parseFloat(ivaS)).toFixed(2);
                                    SumTotalD = parseFloat(parseFloat(bienes) + parseFloat(servicios) + parseFloat(exento)).toFixed(2);
                                }
                            } else {
                                if (comb === true) {

                                    IdpSuper = parseFloat(parseFloat(gaSuper).toFixed(3) * CGSuper).toFixed(2);
                                    IdpRegular = parseFloat(parseFloat(gaRegular).toFixed(3) * CGRegular).toFixed(2);
                                    IdpDiesel = parseFloat(parseFloat(gaDiesel).toFixed(3) * CGDiesel).toFixed(2)

                                    SumIDP = parseFloat(parseFloat(IdpSuper) + parseFloat(IdpRegular) + parseFloat(IdpDiesel)).toFixed(2);

                                    BaseSuper = parseFloat((parseFloat(costoSuper) - parseFloat(IdpSuper)) / PIva).toFixed(2);
                                    BaseRegular = parseFloat((parseFloat(costoRegular) - parseFloat(IdpRegular)) / PIva).toFixed(2);
                                    BaseDiesel = parseFloat((parseFloat(costoDiesel) - parseFloat(IdpDiesel)) / PIva).toFixed(2);
                                    BaseOtros = parseFloat(parseFloat(otros) / PIva).toFixed(2);

                                    SumBase = parseFloat(parseFloat(BaseSuper) + parseFloat(BaseRegular) + parseFloat(BaseDiesel) + parseFloat(BaseOtros)).toFixed(2);

                                    IvaSup = parseFloat((parseFloat(costoSuper) - parseFloat(IdpSuper)) - parseFloat(BaseSuper)).toFixed(2);
                                    IvaReg = parseFloat((parseFloat(costoRegular) - parseFloat(IdpRegular)) - parseFloat(BaseRegular)).toFixed(2);
                                    IvaDie = parseFloat((parseFloat(costoDiesel) - parseFloat(IdpDiesel)) - parseFloat(BaseDiesel)).toFixed(2);
                                    IvaOtros = parseFloat(parseFloat(otros) - parseFloat(BaseOtros)).toFixed(2);

                                    SumIva = parseFloat(parseFloat(IvaSup) + parseFloat(IvaReg) + parseFloat(IvaDie) + parseFloat(IvaOtros)).toFixed(2);

                                    SumTotalC = parseFloat(parseFloat(costoSuper) + parseFloat(costoRegular) + parseFloat(costoDiesel) + parseFloat(otros)).toFixed(2);

                                }

                                if (comb === undefined) {
                                    comb = false;
                                }
                                if (iva === true && comb === false) {
                                    baseB = parseFloat(parseFloat(bienes) / PIva).toFixed(2);
                                    ivaB = parseFloat(parseFloat(bienes) - parseFloat(baseB)).toFixed(2);

                                    baseS = parseFloat(parseFloat(servicios) / PIva).toFixed(2);
                                    ivaS = parseFloat(parseFloat(servicios) - parseFloat(baseS)).toFixed(2);

                                    SumBaD = parseFloat(parseFloat(baseB) + parseFloat(baseS)).toFixed(2);

                                    SumIvD = parseFloat(parseFloat(ivaB) + parseFloat(ivaS)).toFixed(2);
                                    SumTotalD = parseFloat(parseFloat(bienes) + parseFloat(servicios) + parseFloat(exento)).toFixed(2);

                                }
                                if (iva === false) {
                                    SumBaD = parseFloat(parseFloat(bienes) + parseFloat(servicios) + parseFloat(exento)).toFixed(2);
                                    SumIvD = 0.00;
                                    SumTotalD = parseFloat(parseFloat(bienes) + parseFloat(servicios) + parseFloat(exento)).toFixed(2);
                                }
                            }

                            const formData = {
                                fecha_registro: data.fechaRegistro,
                                serie: serieL,
                                numero: 1,
                                descripcionL: data.observaciones,
                                fecha: data.fecha,
                                serieD: data.serieD ? data.serieD.toUpperCase() : '',
                                numeroD: data.numero.toUpperCase(),
                                afectaIva: iva,
                                combustible: data.combustible,
                                gSuper: data.gsuper,
                                cSuper: data.csuper,
                                gRegular: data.gregular,
                                cRegular: data.cregular,
                                gDiesel: data.gdiesel,
                                cDiesel: data.cdiesel,
                                otros: data.otros,
                                idp: SumIDP,
                                bienes: data.bienes,
                                servicios: data.servicios,
                                exento: data.exento,
                                tipo: localStorage.getItem('tipoDocumento'),
                                periodo: id_emp[7],
                                proveedor_liq: pro,
                                proveedor: data.proveedor_documento.value,
                                descripcion: data.descripcion,
                                iva: comb ? SumIva : SumIvD,
                                base: comb ? SumBase : SumBaD,
                                total: comb ? SumTotalC : SumTotalD,
                                modulo: 'CMP',
                                empresa: id_emp[5],
                                poliza: poliza,
                                cuenta: datos.contabilidad === 'S' ? data.cuenta.value : '',
                                detalle: detalleLiq,
                                idPoliza: localStorage.getItem('idPolizaCompraLiq')
                            }

                            dispatch(setLoader(true));
                            api.post('/liquidacion_gasto/', formData).then((response) => {

                                if (detalleLiq) {
                                    response.fechaRegistro = response.compra.fecha_registro;
                                    response.numeroL = response.compra.numero;
                                    response.serie = response.compra.serie;
                                    response.observaciones = response.compra.descripcion;
                                    response.identificador = response.compra.nit_proveedor;
                                    response.proveedor = response.compra.proveedor;
                                    response.proveedorN = response.compra.proveedorN;
                                    response.totalLiq = response.total;
                                    response.id = response.compra.id;

                                    localStorage.setItem('LIQ', true);
                                    dispatch(initializeForm('FormLiquidacionGastos', response));
                                    dispatch(leerDetalleLiquidacion(response.detalle));

                                } else {
                                    response.fechaRegistro = response.compra.fecha_registro;
                                    response.numeroL = response.compra.numero;
                                    response.serie = response.compra.serie;
                                    response.observaciones = response.compra.descripcion;
                                    response.identificador = response.compra.nit_proveedor;
                                    response.proveedor = response.compra.proveedor;
                                    response.proveedorN = response.compra.proveedorN;
                                    response.totalLiq = response.total;
                                    response.id = response.compra.id;

                                    localStorage.setItem('LIQ', true);
                                    localStorage.setItem('detalleID', response.detalle);
                                    dispatch(initializeForm('FormLiquidacionGastos', response));
                                    dispatch(leerDetalleLiquidacion(response.detalle));
                                }

                                NotificationManager.success(
                                    'Compra creada correctamente',
                                    'Exito',
                                    3000
                                );

                            }).catch((error) => {
                                NotificationManager.error(
                                    error.msj,
                                    'Error',
                                    0
                                );
                            }).finally(() => {
                                dispatch(setLoader(false));
                            });
                        }
                    }
                }
            }
        }
    } else {
        Swal.fire({
            title: 'Liquidación de gastos',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })
    }
}

export const modificarCompra = (periodoD) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormLiquidacionGastos.values;
    let id = data.id;
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fechaRegistro);
    if (fecha >= fechaI && fecha <= fechaF) {
        let pro = data.proveedor_liquidacion;
        if (pro === undefined) {
            pro = data.proveedor;
        } else {
            pro = data.proveedor_liquidacion.value;
        }
        const formData = {
            id: id,
            fecha_registro: data.fechaRegistro,
            serie: 'A',
            numero: 1,
            descripcionL: data.observaciones,
            proveedor_liq: pro,
        }

        dispatch(setLoader(true));
        api.put(`/liquidacion_gasto/${id}/`, formData).then(() => {

            NotificationManager.success(
                'Liquidación de gastos modificada correctamente',
                'Exito',
                3000
            );
            ruta = `/compras/${id_emp[5]}/liquidacion_gastos/${id_emp[7]}`;
            dispatch(push(ruta));
        }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                3000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    } else {
        Swal.fire({
            title: 'Liquidación de gastos',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })
    }
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/liquidacion_gasto/${id}`).then((response) => {
        response.observaciones = response.descripcion;
        response.fechaRegistro = response.fecha_registro;
        response.totalLiq = response.total;
        response.numeroL = response.numero;
        response.numero = '';
        response.descripcion = '';
        response.identificador = response.nit_proveedor;
        localStorage.setItem('detalleID', response.id);
        dispatch({ type: GUARDAR_LIQUIDACION, lecturaLq: response });
        dispatch(initializeForm('FormLiquidacionGastos', response));
        dispatch(leerDetalleLiquidacion(response.id));
        if (response.poliza) {
            localStorage.setItem('idPolizaCompraLiq', response.poliza);
            dispatch(leerDetalleMod(response.poliza));
        }
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar liquidacion',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const listarProveedoresD = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let search = '';
    let tipo = 'liquidacion';
    const params = { id, search, tipo };
    dispatch(setLoader(true));
    return api.get('/proveedor/empresa_proveedores', params).then(data => {
        const list_proveedor = [];
        if (data) {
            data.forEach(item => {
                list_proveedor.push({
                    value: item.id,
                    label: item.nombre,
                    search: item.identificador
                })
            })
        }
        dispatch({ type: LISTADO_PROVEEDOR, prov: list_proveedor });
    }).catch((error) => {

        NotificationManager.error(
            'Ocurrió un error al listar proveedores',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const registroPolizaDetalle = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.LiquidacionFormTabs.values;
    const poliza = localStorage.getItem('idPolizaCompraLiq');
    const formData = {
        empresa: id_emp[5],
        periodo: id_emp[7],
        cc: data.cc.value,
        cuenta: data.cuenta.value,
        debe: data.debe,
        haber: data.haber,
        poliza: poliza
    }
    if (formData.debe === undefined && formData.haber === undefined) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'Debe de ingresar valor en debe o haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) === 0.00 && parseFloat(formData.haber) === 0.00) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'No puede llevar valores a 0.00 en debe o haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) >= 0.01 && parseFloat(formData.haber) >= 0.01) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'No puede llevar valores en debe y haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) >= 0.01) {
        formData.haber = 0.00;
        dispatch(setLoader(true));
        api.post('/polizas/encabezado_polizas/', formData).then((response) => {
            const poliza = localStorage.getItem('idPolizaCompraLiq')
            if (poliza === null) {
                response.debe = 0.00;
                response.haber = 0.00;
                dispatch(initializeForm('FormLiquidacionGastos', response));
                dispatch(leerDetalle(response.detalle.encabezado));
            } else {
                const editar = window.location.href.includes('editar');
                if (editar) {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('FormLiquidacionGastos', response));
                    dispatch(leerDetalleMod(poliza));
                } else {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('FormLiquidacionGastos', response));
                    dispatch(leerDetalle(poliza));
                }
            }
        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al registrar la poliza',
                'Error',
                2000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    } else if (parseFloat(formData.haber) >= 0.01) {
        formData.debe = 0.00;
        dispatch(setLoader(true));
        api.post('/polizas/encabezado_polizas/', formData).then((response) => {
            localStorage.removeItem('tipo_poliza');
            const poliza = localStorage.getItem('idPolizaCompraLiq')
            if (poliza === null) {
                response.debe = 0.00;
                response.haber = 0.00;
                dispatch(initializeForm('FormLiquidacionGastos', response));
                dispatch(leerDetalle(response.encabezado));
            } else {
                const editar = window.location.href.includes('editar');
                if (editar) {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('FormLiquidacionGastos', response));
                    dispatch(leerDetalleMod(poliza));
                } else {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('FormLiquidacionGastos', response));
                    dispatch(leerDetalle(poliza));
                }
            }

        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al registrar la poliza',
                'Error',
                0
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
}

export const registroPolizaDetalleSCC = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.LiquidacionFormTabs.values;
    const poliza = localStorage.getItem('idPolizaCompraLiq');
    const formData = {
        empresa: id_emp[5],
        periodo: id_emp[7],
        cc: 0,
        cuenta: data.cuenta.value,
        debe: data.debe,
        haber: data.haber,
        poliza: poliza
    }
    if (formData.debe === undefined && formData.haber === undefined) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'Debe de ingresar valor en debe o haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) === 0.00 && parseFloat(formData.haber) === 0.00) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'No puede llevar valores a 0.00 en debe o haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) >= 0.01 && parseFloat(formData.haber) >= 0.01) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'No puede llevar valores en debe y haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) >= 0.01) {
        formData.haber = 0.00;
        dispatch(setLoader(true));
        api.post('/polizas/encabezado_polizas/', formData).then((response) => {
            const poliza = localStorage.getItem('idPolizaCompraLiq')
            if (poliza === null) {
                response.debe = 0.00;
                response.haber = 0.00;
                dispatch(initializeForm('FormLiquidacionGastos', response));
                dispatch(leerDetalle(response.detalle.encabezado));
            } else {
                const editar = window.location.href.includes('editar');
                if (editar) {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('FormLiquidacionGastos', response));
                    dispatch(leerDetalleMod(poliza));
                } else {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('FormLiquidacionGastos', response));
                    dispatch(leerDetalle(poliza));
                }
            }

        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al registrar la poliza',
                'Error',
                0
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    } else if (parseFloat(formData.haber) >= 0.01) {
        formData.debe = 0.00;
        api.post('/polizas/encabezado_polizas/', formData).then((response) => {
            localStorage.removeItem('tipo_poliza');
            const poliza = localStorage.getItem('idPoliza')
            if (poliza === null) {
                response.debe = 0.00;
                response.haber = 0.00;
                dispatch(initializeForm('FormLiquidacionGastos', response));
                dispatch(leerDetalle(response.detalle.encabezado));
            } else {
                const editar = window.location.href.includes('editar');
                if (editar) {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('FormLiquidacionGastos', response));
                    dispatch(leerDetalleMod(poliza));
                } else {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('FormLiquidacionGastos', response));
                    dispatch(leerDetalle(poliza));
                }
            }

        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al registrar la poliza',
                'Error',
                2000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
}

export const confirmarPoliza = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let dif = localStorage.getItem('dif');
    if (parseFloat(dif) === 0.00) {
        localStorage.removeItem('idPolizaCompraLiq');
        localStorage.removeItem('dif');
        NotificationManager.success(
            'Póliza guardada correctamente',
            'Exito',
            3000
        );
        localStorage.removeItem('idPolizaCompraLiq');
        localStorage.removeItem('detalleID');
        localStorage.removeItem('dataD');
        localStorage.setItem('LIQ', false);
        const editar = window.location.href.includes('editar');
        if (editar) {
            ruta = `/compras/${id_emp[5]}/liquidacion_gastos/${id_emp[7]}`;
            dispatch(push(ruta));
        } else {
            window.location.reload(true);
        }

    } else {
        Swal.fire({
            title: 'Modificar poliza',
            text: `Poliza con diferencia: ${new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(dif)}`,
            type: 'error',
        })
    }
}

const leerDetalleMod = id => (dispatch) => {
    api.get(`/poliza_detalle/${id}`).then((response) => {
        response.polizaDetalle_polizaEncabezado = response
        dispatch({ type: GUARDAR_POLIZA, lectura: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            2000
        );
    })
};

const leerDetalleLiquidacion = id => (dispatch) => {
    api.get(`/liquidacion_gasto_detalle/${id}`).then((response) => {
        dispatch({ type: DETALLE_LIQUIDACION, detalleLiq: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};

const eliminarDocumento = id => (dispatch) => {

    const detalle = localStorage.getItem('detalleID');
    dispatch(setLoader(true));
    api.eliminar(`/liquidacion_gasto_detalle/${id}`).then((response) => {
        if (detalle) {
            const poliza = localStorage.getItem('idPolizaCompraLiq');
            const editar = window.location.href.includes('editar');
            response.fechaRegistro = response.compra.fecha_registro;
            response.numeroL = response.compra.numero;
            response.serie = response.compra.serie;
            response.proveedorN = response.compra.proveedorN;
            response.observaciones = response.compra.descripcion;
            response.identificador = response.compra.nit_proveedor;
            response.totalLiq = response.total;
            response.tipo_documento = response.tipoD;
            response.id = response.compra.id;
            response.combustible = localStorage.getItem('liqComb');;
            if (editar) {
                dispatch(leerDetalleLiquidacion(detalle));
                if (response.poliza) {
                    dispatch(leerDetalleMod(poliza));
                }
                dispatch(initializeForm('FormLiquidacionGastos', response));
            } else {
                dispatch(leerDetalleLiquidacion(detalle));
                if (response.poliza) {
                    dispatch(leerDetalle(poliza));
                }
                dispatch(initializeForm('FormLiquidacionGastos', response));
            }
        }
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar el documento',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const imprimirLiquidacion = id => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let idE = id_emp[5];
    let idLiq = id
    let reporte = 'imprimirLiq';
    const params = { idE, reporte, idLiq };
    dispatch(setLoader(true));
    api.getPdf('/liquidacion_gasto', params).then((response) => {
        if (response.headers['content-type'] == 'application/pdf') {
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'reporte_liquidacion_gastos.pdf';
            window.open(link, '_blank');
            return false;
        }
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al generar reporte',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const excelLiquidacion = id => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let idE = id_emp[5];
    let idLiq = id
    let reporte = 'excelLiq';
    const params = { idE, reporte, idLiq };
    dispatch(setLoader(true));
    api.get('/liquidacion_gasto', params).then((response) => {

        let name_excel = 'Liquidacion_de_gastos';

        let uri = 'data:application/vnd.ms-excel;base64,';
        let template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
        let base64 = function (s) {
            return window.btoa(unescape(encodeURIComponent(s)))
        };

        let format = function (s, c) {
            return s.replace(/{(\w+)}/g, function (m, p) {
                return c[p];
            })
        };

        let htmls = response.text;

        let ctx = {
            worksheet: 'Worksheet',
            table: htmls
        }

        let link = document.createElement("a");
        link.pars
        link.download = name_excel + ".xls";
        link.href = uri + base64(format(template, ctx));
        link.click();
    }).catch((error) => {

        NotificationManager.error(
            'Ocurrió un error al generar reporte',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}


const eliminarLiquidacion = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`liquidacion_gasto/${id}`).then(() => {
        dispatch(listar(1));
        NotificationManager.success(
            'Liquidacion borrada correctamente',
            'Exito',
            3000
        );
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar liquidacion',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const registroProveedor = (datos) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormLiquidacionGastos.values;
    if (datos.validar_nit === 'S') {
        if (data.identificadorPro === 'cf' || data.identificadorPro === 'CF' || data.identificadorPro === 'c/f' || data.identificadorPro === 'C/F') {
            const formData = {
                identificador: data.identificadorPro,
                nombre: data.nombre,
                direccion: data.direccion,
                telefono: data.telefono,
                correo: data.correo,
                limite: data.limite_credito,
                tipo_proveedor: data.tipo_proveedor,
                dias_credito: data.dias_credito,
                modulo: 'CMP',
                pais: data.pais,
                empresa: id_emp[5]
            }
            dispatch(setLoader(true));
            api.post('/proveedor/', formData).then(() => {
                NotificationManager.success(
                    'Proveedor creado',
                    'Exito',
                    3000
                );

            }).catch((error) => {
                NotificationManager.error(
                    error.msj,
                    'Error',
                    0
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        } else {
            let valid = validar.valNit(data.identificadorPro);
            if (valid === false) {
                Swal.fire({
                    title: 'Proveedor',
                    text: 'Ingrese Nit válido',
                    type: 'error',
                })
            } else {
                const formData = {
                    identificador: data.identificadorPro,
                    nombre: data.nombre,
                    direccion: data.direccion,
                    telefono: data.telefono,
                    correo: data.correo,
                    limite: data.limite_credito,
                    tipo_proveedor: data.tipo_proveedor,
                    dias_credito: data.dias_credito,
                    modulo: 'CMP',
                    pais: data.pais,
                    empresa: id_emp[5]
                }
                dispatch(setLoader(true));
                api.post('/proveedor/', formData).then(() => {
                    NotificationManager.success(
                        'Proveedor creado',
                        'Exito',
                        3000
                    );
                }).catch((error) => {
                    NotificationManager.error(
                        error.msj,
                        'Error',
                        0
                    );
                }).finally(() => {
                    dispatch(setLoader(false));
                });
            }
        }
    } else {
        const formData = {
            identificador: data.identificadorPro,
            nombre: data.nombre,
            direccion: data.direccion,
            telefono: data.telefono,
            correo: data.correo,
            limite: data.limite_credito,
            tipo_proveedor: data.tipo_proveedor,
            dias_credito: data.dias_credito,
            modulo: 'CMP',
            pais: data.pais,
            empresa: id_emp[5]
        }
        dispatch(setLoader(true));
        api.post('/proveedor/', formData).then(() => {
            NotificationManager.success(
                'Proveedor creado',
                'Exito',
                2000
            );

        }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                0
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
}

export const buscarFactura = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormBuscarFactura.values;
    if (data === undefined) {
        Swal.fire({
            title: 'Buscar factura',
            text: 'Debe de ingresar datos para buscar factura',
            type: 'error',
        })
    } else {
        if (data.serie === null || data.serie === null) {
            Swal.fire({
                title: 'Buscar factura',
                text: 'Debe de ingresar serie de la factura',
                type: 'error',
            })
        } else {
            if (data.numero === null || data.numero === null) {
                Swal.fire({
                    title: 'Buscar factura',
                    text: 'Debe de ingresar numero de la factura',
                    type: 'error',
                })
            } else {
                const formdata = {
                    empresa: id_emp[5],
                    serie: data.serie,
                    numero: data.numero
                };
                dispatch(setLoader(true));
                api.get('/liquidacion_gasto_detalle/buscar_factura/', formdata).then((response) => {
                    if (response.length >= 1) {
                        dispatch({ type: CONSULTA_FACTURA, factura: response });
                    } else {
                        NotificationManager.error(
                            'No se encontró la factura',
                            'Error',
                            2000
                        );
                    }
                }).catch((error) => {
                    NotificationManager.error(
                        'No se encontró la factura',
                        'Error',
                        2000
                    );
                }).finally(() => {
                    dispatch(setLoader(false));
                });
            }
        }
    }
}

export const leerFactura = (id) => (dispatch) => {
    let params = { id };
    dispatch(setLoader(true));
    api.get('/liquidacion_gasto_detalle/buscar_factura_id/', params).then((response) => {
        dispatch({ type: VER_FACTURA, fact: response });
        dispatch(initializeForm('FormConsultaFactura', response));
        dispatch(leerDetalleMateriales(response.id))
    }).catch((error) => {
        NotificationManager.error(
            'No se encontró la factura',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const listarProyectos = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5]
    const params = { id };
    api.get('/proyecto', params).then((response) => {
        const pro = [];
        response.forEach(item => {
            pro.push({
                value: item.id,
                label: item.proyecto
            })
        })
        dispatch({ type: LISTADO_PROYECTOS, listaProyectos: pro });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar los proyectos',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const listarSubProyectos = (id) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let idE = id_emp[5];
    let idPro = id;
    let detPro = 'proyecto'
    const params = { idE, idPro, detPro };
    api.get('/subproyecto', params).then((response) => {
        const subpro = [];
        response.forEach(item => {
            subpro.push({
                value: item.id,
                label: item.subproyecto
            })
        })
        dispatch({ type: LISTADO_SUBPROYECTOS, listaSubProyectos: subpro });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar los subproyectos',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const listarFases = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    api.get('/fase', params).then((response) => {
        const fase = [];
        response.forEach(item => {
            fase.push({
                value: item.id,
                label: item.numero + ' - ' + item.descripcion
            })
        })
        dispatch({ type: LISTADO_FASES, listaFases: fase });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar fases',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const listarMateriales = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/material/detalle_materiales', params).then(data => {
        const list_material = [];
        if (data) {
            data.forEach(item => {
                list_material.push({
                    value: item.id,
                    label: item.descripcion
                })
            })
        }
        return list_material;
    }).catch((error) => {
        return [];
    })
}

const leerDetalleMateriales = id => (dispatch) => {
    api.get(`/compra_material_detalle/${id}`).then((response) => {
        dispatch({ type: DETALLE_MATERIALES, detalleMaterial: response.detalle });
        dispatch({ type: ENCABEZADO_MATERIALES, encabezado: response.encabezado });
        if (response.encabezado.id) {
            localStorage.setItem('id_detalle_material', response.encabezado.id);
        }
        if (response.encabezado.proyecto) {
            dispatch(listarSubProyectos(response.encabezado.proyecto))
        }

    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            2000
        );
    })
};

export const registroDetalleMaterial = (impuesto) => (dispatch, getStore) => {

    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormFacturaDetallePro.values;
    const detalle = localStorage.getItem('id_detalle_material');
    let PIva = parseFloat(parseFloat(parseFloat(impuesto.porcentaje_iva) / 100) + 1).toFixed(2);

    const formData = {
        proyecto: localStorage.getItem('proyecto_compra'),
        subproyecto: data.subproyecto,
        fase: data.fase,
        material: data.material.value,
        cantidad: data.cantidad,
        precio_unitario: data.precio,
        total: localStorage.getItem('total_material'),
        id_liquidacion: localStorage.getItem('liquidacion_id'),
        tipoDoc: 'LIQ',
        empresa: id_emp[5],
        base: parseFloat(parseFloat(localStorage.getItem('total_material')) / PIva).toFixed(2),
        detalle: detalle
    }
    dispatch(setLoader(true));
    api.post('/compra_material/', formData).then((response) => {

        if (detalle) {
            dispatch(initializeForm('FormFacturaDetallePro', response));
            dispatch(leerDetalleMateriales(response.id_detalle));
        } else {
            dispatch(initializeForm('FormFacturaDetallePro', response));
            localStorage.setItem('id_detalle_material', response.id_detalle);
            dispatch(leerDetalleMateriales(response.id_detalle));
        }
        NotificationManager.success(
            'Producto creado correctamente',
            'Exito',
            3000
        );
    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const eliminarLineaProducto = id => (dispatch) => {

    const detalle = localStorage.getItem('id_detalle_material');
    dispatch(setLoader(true));
    api.eliminar(`/compra_material_detalle/${id}`).then((response) => {
        const editar = window.location.href.includes('editar');
        if (editar) {
            dispatch(leerDetalleMateriales(detalle));
        } else {
            dispatch(leerDetalleMateriales(response.id));
        }
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const leerPeriodoD = (id, id_periodo) => (dispatch) => {
    const params = { id, id_periodo };
    api.get('/periodos/periodo', params).then((response) => {
        dispatch({ type: PERIODO_D, periodoD: response.periodo });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar periodo',
            'Error',
            2000
        );
    })
}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = { id, modulo }
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response) => {
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const parametrosEmpresa = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let params = { id }
    dispatch(setLoader(true));
    api.get('/empresa/empresa_parametros', params).then((response) => {
        dispatch({ type: PARAMETRO, datos: response });
        api.get('/empresa/empresa_impuesto', params).then((imp) => {
            if (imp.validar === 'false') {
                Swal.fire({
                    title: 'Liquidación de gastos',
                    text: imp.msj,
                    type: 'info',
                    allowOutsideClick: false,
                    confirmButtonText: "Continuar",
                }).then((result) => {
                    if (result.value) {
                        let ruta = `/ajustes/${id_emp[5]}/impuestos`
                        dispatch(push(ruta));
                    }
                });
            }
            if (imp.validar === 'true') {
                api.get('/empresa/empresa_configuracion_poliza', params).then((datos) => {
                    if (datos.validar === 'false') {
                        Swal.fire({
                            title: 'Liquidación de gastos',
                            text: datos.msj,
                            type: 'info',
                            allowOutsideClick: false,
                            confirmButtonText: "Continuar",
                        }).then((result) => {
                            if (result.value) {
                                let ruta = `/contabilidad/${id_emp[5]}/config`
                                dispatch(push(ruta));
                            }
                        });
                    }

                }).catch((error) => {

                    NotificationManager.error(
                        'Ocurrió un error al consultar impuesto',
                        'Error',
                        8000
                    );
                })
            }

        }).catch((error) => {

            NotificationManager.error(
                'Ocurrió un error al consultar parametros',
                'Error',
                8000
            );
        })
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar parametros',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

const listarPaises = () => (dispatch) => {
    return api.get("/paises").then(response => {
        const pais = [];
        response.results.forEach(item => {
            pais.push({
                value: item.id,
                label: item.pais.toUpperCase()
            })
        })
        dispatch({ type: LISTADO_PAISES, listaP: pais });
    }).catch(error => {

        NotificationManager.error(
            'Ocurrió un error al listar paises',
            'Error',
            8000
        );
    })
}

export const listarImpuestos = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5]
    const params = { id };
    dispatch(setLoader(true));
    api.get('/impuesto/impuesto_empresa', params).then((response) => {
        dispatch({ type: LISTADO_IMPUESTO, impuesto: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar impuesto',
            'Error',
            3000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const actions = {
    listar,
    registroCompra,
    modificarCompra,
    leer,
    listarTiposDocu,
    listarProveedores,
    leerDetalle,
    eliminarLinea,
    listarCuentas,
    listarCC,
    listarNiveles,
    registroCuenta,
    reporteCuentas,
    registroPolizaDetalle,
    confirmarPoliza,
    registroPolizaDetalleSCC,
    leerDetalleLiquidacion,
    eliminarDocumento,
    listarProveedoresD,
    imprimirLiquidacion,
    eliminarLiquidacion,
    excelLiquidacion,
    registroProveedor,
    buscarFactura,
    leerFactura,
    listarProyectos,
    listarSubProyectos,
    listarFases,
    listarMateriales,
    eliminarLineaProducto,
    leerDetalleMateriales,
    registroDetalleMaterial,
    leerPeriodoD,
    getModulo,
    parametrosEmpresa,
    listarPaises,
    listarImpuestos
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_COMPRA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [DETALLE_POLIZA]: (state, { detalle }) => {
        return {
            ...state,
            detalle,
        };
    },
    [LISTADO_TIPOS_DOCU]: (state, { tipos }) => {
        return {
            ...state,
            tipos,
        };
    },
    [LISTADO_PROVEEDOR]: (state, { prov }) => {
        return {
            ...state,
            prov,
        };
    },
    [DETALLE_LIQUIDACION]: (state, { detalleLiq }) => {
        return {
            ...state,
            detalleLiq,
        };
    },
    [NIVEL]: (state, { nivel }) => {
        return {
            ...state,
            nivel,
        };
    },
    [LOADERC]: (state, { loaderC }) => {
        return {
            ...state,
            loaderC,
        };
    },
    [GUARDAR_LIQUIDACION]: (state, { lecturaLq }) => {
        return {
            ...state,
            lecturaLq,
        };
    },
    [GUARDAR_POLIZA]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
    [CONSULTA_FACTURA]: (state, { factura }) => {
        return {
            ...state,
            factura,
        };
    },
    [LISTADO_PROYECTOS]: (state, { listaProyectos }) => {
        return {
            ...state,
            listaProyectos,
        };
    },
    [LISTADO_SUBPROYECTOS]: (state, { listaSubProyectos }) => {
        return {
            ...state,
            listaSubProyectos,
        };
    },
    [LISTADO_FASES]: (state, { listaFases }) => {
        return {
            ...state,
            listaFases,
        };
    },
    [DETALLE_MATERIALES]: (state, { detalleMaterial }) => {
        return {
            ...state,
            detalleMaterial,
        };
    },
    [VER_FACTURA]: (state, { fact }) => {
        return {
            ...state,
            fact,
        };
    },
    [PERIODO_D]: (state, { periodoD }) => {
        return {
            ...state,
            periodoD,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [PARAMETRO]: (state, { datos }) => {
        return {
            ...state,
            datos,
        };
    },
    [LISTADO_PAISES]: (state, { listaP }) => {
        return {
            ...state,
            listaP,
        };
    },
    [LISTADO_IMPUESTO]: (state, { impuesto }) => {
        return {
            ...state,
            impuesto,
        };
    },
    [ENCABEZADO_MATERIALES]: (state, { encabezado }) => {
        return {
            ...state,
            encabezado,
        };
    },
};

export const initialState = {
    loader: false,
    tipos: [],
    prov: [],
    factura: [],
    fact: [],
    listaProyectos: [],
    listaSubProyectos: [],
    listaFases: [],
    nivel: [],
    encabezado: [],
    periodoD: [],
    impuesto: [],
    modulo: [],
    loaderC: false,
    detalle: [],
    datos: [],
    listaP: [],
    detalleLiq: [],
    data: [],
    page: 1,
    lectura: [],
    lecturaLq: [],
};

export default handleActions(reducers, initialState)