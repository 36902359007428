import React, { useState } from 'react';
import Modal from '../components/Modal';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LoadMask from "../Utils/LoadMask/LoadMask";
import NavbarModulo from '../components/NavbarModulo';
import NavbarModuloLF from '../components/NavbarModuloFage';
const valEmp = __CONFIG__.empresa;

import {
    SelectField,
    renderNumber,
    AsyncSelectField,
    SelectFieldReporte
} from "../Utils/renderField/renderField";


function InformesForm(props) {
    const {
        modulo,
        loader,
        listaPeriodo,
        listarPlanillas,
        reportePlanilla,
        reportePlanillaMensual,
        reporteCuotasIgss,
        excelCuotasIgss,
        reporteRecibosPeriodo,
        listarColaborador,
        listarPlanillasRecibos,
        reportePromedioIngresos,
        deptos
    } = props;
    
    const [modalPlanilla, setModalPlanilla] = useState(false);
    const [modalPlanillaMensual, setModalPlanillaMensual] = useState(false);
    const [modalCuotaIgss, setModalCuotaIgss] = useState(false);
    const [modalRecibosMes, setModalRecibosMes] = useState(false);
    const [modalPromedioIngreso, setModalPromedioIngreso] = useState(false);
    
    let titulo = 'Informes'
    let ruta = window.location.href;
    let id_emp = ruta.split('/');

    const handleOnChange = (e,value) => {
        if (value.length == 4) {
            listarPlanillas(value)
        }
        
    }

    const handleOnChangeRecibo = (e,value) => {
        if (value.length == 4) {
            listarPlanillasRecibos(value)
        }
        
    }

    const meses = [
        {
            label: 'ENERO',
            value: '01'
          
        },
        {
            label: 'FEBRERO',
            value: '02'
        },
        {
            label: 'MARZO',
            value: '03',
        },
        {
            label: 'ABRIL',
            value: '04',
        },
        {
            label: 'MAYO',
            value: '05',
        },
        {
            label: 'JUNIO',
            value: '06',
        },
        {
            label: 'JULIO',
            value: '07',
        },
        {
            label: 'AGOSTO',
            value: '08',
        },
        {
            label: 'SEPTIEMBRE',
            value: '09',
        },
        {
            label: 'OCTUBRE',
            value: '10'
        },
        {
            label: 'NOVIEMBRE',
            value: '11',
        },
        {
            label: 'DICIEMBRE',
            value: '12',
        },
    ];
    

    let rutaInforme = '';
    if (valEmp === 'fage'){
        rutaInforme = `/#/lfg/nomina/${id_emp[6]}/informes`
        
    }else{
        rutaInforme = `/#/nomina/${id_emp[5]}/informes`
    }

    return (
        <div>
            {valEmp === 'fage' &&
                <NavbarModuloLF
                    modulo={modulo}
                    titulo={titulo}
                />
            }
            {valEmp === '' &&
                <NavbarModulo
                    modulo={modulo}
                    titulo={titulo}
                />
            }
        
            <div className="container">
                <br/>
                <h4 className='mt-1' style={{ color: "white" }}>Informes</h4>
                <div className="row">
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <a class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => setModalPlanilla(!modalPlanilla)}
                                data-bs-toggle="list"
                                role="tab"
                                href={rutaInforme}
                                aria-controls="list-home">Planillas</a>
                        </div>
                    </div>
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <a class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => setModalPlanillaMensual(!modalPlanillaMensual)}
                                data-bs-toggle="list"
                                role="tab"
                                href={rutaInforme}
                                aria-controls="list-home">Planilla mensual</a>
                        </div>
                    </div>
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <a class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => setModalRecibosMes(!modalRecibosMes)}
                                data-bs-toggle="list"
                                role="tab"
                                href={rutaInforme}
                                aria-controls="list-home">Recibos de nómina</a>
                        </div>
                    </div>
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <a class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => setModalCuotaIgss(!modalCuotaIgss)}
                                data-bs-toggle="list"
                                role="tab"
                                href={rutaInforme}
                                aria-controls="list-home">Cuotas de IGSS</a>
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <a class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => setModalPromedioIngreso(!modalPromedioIngreso)}
                                data-bs-toggle="list"
                                role="tab"
                                href={rutaInforme}
                                aria-controls="list-home">Promedio de ingresos</a>
                        </div>
                    </div>
                </div>
                <Modal
                    estado={modalPlanilla} //Planillas
                    cambiarEstado={setModalPlanilla}
                    titulo="Planillas"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className='col-4 mb-2'>
                                <Field
                                    name="anio"
                                    placeholder="Año"
                                    className="form-control"
                                    component={renderNumber}
                                    onChange={handleOnChange}
                                    msj="Año"
                                />
                            </div>
                            {listaPeriodo &&
                                <div className='col-12 mb-3'>
                                    <Field
                                        name="periodo"
                                        options={listaPeriodo}
                                        component={SelectField}
                                        className="form-select"
                                        msj="Periodos"
                                    />
                                </div>
                            }
                        </Contenido>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div className='row'>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={reportePlanilla}
                                    >
                                    Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn btn-secondary'
                                        onClick={() => setModalPlanilla(false)}
                                    >
                                    Cancelar
                                    </button>
                                </div>
                            </Contenido>
                        </LoadMask>
                </Modal>  
                <Modal
                    estado={modalPlanillaMensual} //Planillas Mensual
                    cambiarEstado={setModalPlanillaMensual}
                    titulo="Planilla mensual"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className='col-6 mb-2'>
                                <div className="form-floating">
                                    <Field
                                        name="mes"
                                        options={meses}
                                        component={SelectField}
                                        className="form-select"
                                        msj="Mes"
                                    />
                                </div>
                            </div>
                            <div className='col-6 mb-3'>
                                <div className="form-floating">
                                    <Field
                                        name="year"
                                        component={renderNumber}
                                        className="form-control"
                                        msj="Año"
                                    />
                                </div>
                            </div>
                        </Contenido>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div className='row'>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={reportePlanillaMensual}
                                    >
                                    Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn btn-secondary'
                                        onClick={() => setModalPlanillaMensual(false)}
                                    >
                                    Cancelar
                                    </button>
                                </div>
                            </Contenido>
                        </LoadMask>
                </Modal>  
                <Modal
                    estado={modalCuotaIgss} //Cuotas IGSS
                    cambiarEstado={setModalCuotaIgss}
                    titulo="Cuotas de IGSS"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className='col-6 mb-2'>
                                <div className="form-floating">
                                    <Field
                                        name="mes"
                                        options={meses}
                                        component={SelectField}
                                        className="form-select"
                                        msj="Mes"
                                    />
                                </div>
                            </div>
                            <div className='col-6 mb-3'>
                                <div className="form-floating">
                                    <Field
                                        name="year"
                                        component={renderNumber}
                                        className="form-control"
                                        msj="Año"
                                    />
                                </div>
                            </div>
                        </Contenido>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div className='mt-2'>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={reporteCuotasIgss}
                                    >
                                        Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={excelCuotasIgss}
                                    >
                                        Exportar Excel
                                    </button>
                                </div>    
                                <button
                                    className='btn mr-2 mb-3 btn btn-secondary'
                                    onClick={() => setModalCuotaIgss(false)}
                                >
                                    Cancelar
                                </button>
                            </Contenido>
                        </LoadMask>
                </Modal> 
                <Modal
                    estado={modalRecibosMes} //Recibos nomina
                    cambiarEstado={setModalRecibosMes}
                    titulo="Recibos de nómina"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className='col-12 mb-2'>
                                <Field
                                    name="colaborador"
                                    placeholder="Colaborador..."
                                    className="form-control-find"
                                    component={AsyncSelectField}
                                    loadOptions={listarColaborador}
                                    msj="Colaborador"
                                />
                            </div>
                            <div className='col-4 mb-2'>
                                <Field
                                    name="year"
                                    placeholder="Año"
                                    className="form-control"
                                    component={renderNumber}
                                    onChange={handleOnChangeRecibo}
                                    msj="Año"
                                />
                            </div>
                            {listaPeriodo &&
                                <div className='col-12 mb-3'>
                                    <Field
                                        name="periodo"
                                        options={listaPeriodo}
                                        component={SelectField}
                                        className="form-select"
                                        msj="Periodos"
                                    />
                                </div>
                            }
                            {deptos &&
                                <div className='col-12 mb-3'>
                                    <Field
                                        name="departamento"
                                        options={deptos}
                                        component={SelectFieldReporte}
                                        className="form-select"
                                        msj="Departamento"
                                    />
                                </div>
                            }
                        </Contenido>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div className='row'>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={reporteRecibosPeriodo}
                                    >
                                    Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn btn-secondary'
                                        onClick={() => setModalRecibosMes(false)}
                                    >
                                    Cancelar
                                    </button>
                                </div>
                            </Contenido>
                        </LoadMask>
                </Modal>  
                <Modal
                    estado={modalPromedioIngreso} //Promedio de ingresos
                    cambiarEstado={setModalPromedioIngreso}
                    titulo="Promedio de ingresos"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className='col-12 mb-2'>
                                <Field
                                    name="colaborador"
                                    placeholder="Colaborador..."
                                    className="form-control-find"
                                    component={AsyncSelectField}
                                    loadOptions={listarColaborador}
                                    msj="Colaborador"
                                />
                            </div>
                            <div className='row col-12 mb-2'>
                                <div className='col-6'>
                                    <div className="form-floating">
                                        <Field
                                            name="mes_inicial"
                                            options={meses}
                                            component={SelectField}
                                            className="form-select"
                                            msj="Mes inicial"
                                        />
                                    </div>
                                </div>
                                <div className='col-4'>
                                    <div className="form-floating">
                                        <Field
                                            name="year_inicial"
                                            component={renderNumber}
                                            className="form-control"
                                            msj="Año inicial"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='row col-12 mb-3'>
                                <div className='col-6'>
                                    <div className="form-floating">
                                        <Field
                                            name="mes_final"
                                            options={meses}
                                            component={SelectField}
                                            className="form-select"
                                            msj="Mes final"
                                        />
                                    </div>
                                </div>
                                <div className='col-4'>
                                    <div className="form-floating">
                                        <Field
                                            name="year_final"
                                            component={renderNumber}
                                            className="form-control"
                                            msj="Año final"
                                        />
                                    </div>
                                </div>
                            </div>
                        </Contenido>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div className='row'>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={reportePromedioIngresos}
                                    >
                                    Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn btn-secondary'
                                        onClick={() => setModalPromedioIngreso(false)}
                                    >
                                    Cancelar
                                    </button>
                                </div>
                            </Contenido>
                        </LoadMask>
                </Modal>   
            </div>
        </div>
    );
}

InformesForm.propTypes = {
    loader: PropTypes.bool,
    modulo: PropTypes.object,
    listarProyectos: PropTypes.func,
    reporteDetalleGastosProyecto: PropTypes.func,
};

export default reduxForm({
    form: 'FormInformesNomina',
})(InformesForm);


const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;
