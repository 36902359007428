import React, { Component, useState } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import { validate, validators } from 'validate-redux-form';
import NavbarModulo from '../../components/NavbarModulo';

import {
  renderField,
  renderNumber,
  SelectField
} from "../../Utils/renderField/renderField"; 

class CuentasContablesForm extends Component {
  render() {
    const { handleSubmit, niveles, crear, lectura, registroCuenta, modificarCuenta, modulo } = this.props;
    const editar = window.location.href.includes('editar');
    let titulo = editar ? 'Modificar Cuenta Contable' : 'Crear Cuenta Contable';
    let disabled = false;
    if (crear === false && editar === false) {
      disabled = true;
      titulo = 'Ver Cuenta'
    }
    let autoFocus = true;
    const getNivel = () => {
        let formato = ''
          if (niveles.length > 0){
            for (let a = 0; a < niveles[0].nivel_uno; a++){
              formato = formato + '#'
            }
            for (let b = 0; b < niveles[0].nivel_dos; b++){
              if (b === 0){
                formato = formato + '-' + '#'
              }else{
                formato = formato + '#'
              }
            }
            for (let c = 0; c < niveles[0].nivel_tres; c++){
              if (c === 0){
                formato = formato + '-' + '#'
              }else {
                formato = formato + '#'
              }
            }
            for (let d = 0; d < niveles[0].nivel_cuatro; d++){
              if (d === 0){
                formato = formato + '-' + '#';
              }else {
                formato = formato + '#';
              }
            }
            for (let e = 0; e < niveles[0].nivel_cinco; e++){
              if (e === 0){
                formato = formato + '-' + '#';
              }else {
                formato = formato + '#';
              }
            }
            for (let f = 0; f < niveles[0].nivel_seis; f++){
              formato = formato + '#';
            }
          }
        
        return formato
    };

    const tipos = [
      {
        value: 'A',
        label: 'Acumulativa'
      },
      {
        value: 'D',
        label: 'Detalle'
      },
    ];
    
    const naturaleza = [
      {
        value: 'ACTIVO',
        label: 'Activo'
      },
      {
        value: 'PASIVO',
        label: 'Pasivo'
      },
      {
        value: 'CAPITAL',
        label: 'Capital'
      },
      {
        value: 'INGRESOS',
        label: 'Ingresos'
      },
      {
        value: 'COSTOS',
        label: 'Costos'
      },
      {
        value: 'GASTOS',
        label: 'Gastos'
      },
    ];
    
    localStorage.setItem('tituloForm', titulo);
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    return (
        <div>
          <NavbarModulo
            modulo={modulo}
            titulo={titulo}
          />
            <form onSubmit={handleSubmit}>
               <br />
                <div className="mb-4 card card-small text-white bg-dark mb-3">
                    <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                        <div className="d-flex flex-column flex-1 mx-4">
                            <div className='mb-2 w-25'>
                                <Field
                                    autoFocus={autoFocus}
                                    type="number"
                                    id="cuenta"
                                    name="cuenta"
                                    placeholder="Cuenta"
                                    className="form-control"
                                    numberFormat={getNivel()}
                                    component={renderNumber}
                                    disabled={editar ? true : disabled}
                                    msj="Cuenta"
                                />
                            </div>
                            <div className='mb-2 w-75'>
                                <Field
                                    id="nombre"
                                    name="nombre"
                                    placeholder="Nombre Cuenta"
                                    className="form-control"
                                    component={renderField}
                                    disabled={disabled}
                                    msj="Nombre Cuenta"
                                />
                            </div>
                            <div className='mb-2 w-25'>
                              <div className="form-floating">
                                <Field
                                  id="tipo"
                                  name="tipo"
                                  options={tipos}
                                  crear={crear}
                                  dato={crear === true ? 0 : lectura.tipo}
                                  component={SelectField}
                                  className="form-select"
                                  disabled={disabled}
                                  msj="Tipo"
                                />
                              </div>
                            </div>
                            <div className='mb-2 w-25'>
                              <div className="form-floating">
                                <Field
                                  id="naturaleza"
                                  name="naturaleza"
                                  options={naturaleza}
                                  crear={crear}
                                  dato={crear === true ? 0 : lectura.naturaleza}
                                  component={SelectField}
                                  className="form-select"
                                  disabled={disabled}
                                  msj="Naturaleza"
                                />
                              </div>
                            </div>
                            <div className='d-flex flex-row mt-3'>
                              {crear === true && disabled === false &&
                                <button
                                  className='btn mr-2 mb-3 btn-primary'
                                  onClick={() => registroCuenta(getNivel())}
                                >
                                  Guardar
                                </button>
                              }
                              {crear === false && disabled === false &&
                                <button
                                  className='btn mr-2 mb-3 btn-primary'
                                  onClick={modificarCuenta}
                                >
                                  Modificar
                                </button>
                              }
                              <a
                                href={`/#/contabilidad/${id_emp[5]}/cuentas_contables`}
                                className='btn btn-secondary mb-3'
                              >
                                Regresar
                              </a>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
      );
  }
}

export default reduxForm({
  form: 'FormCuentas',
  validate: (data) => {
    return validate(data, {
      cuenta: validators.exists()('Este campo es requerido'),
      nombre: validators.exists()('Este campo es requerido'),
      tipo: validators.exists()('Seleccione tipo'),
      naturaleza: validators.exists()('Seleccione naturaleza'),
    });
  },
})(CuentasContablesForm);
