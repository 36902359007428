import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { NavLink } from 'react-router-dom';

const defaultCompany = require("assets/img/buildings.jpg");

function EmpresaCard(props) {
  const {
    logo,
    name,
    id,
    estab,
   
  } = props;

  const LinkBtn = React.forwardRef(function LinkBtn(props, ref) { // eslint-disable-line
    return <NavLink to={props.to} {...props} innerRef={ref} />; // eslint-disable-line
  });
  let img = '';
  if ( logo === null || logo === ''){
    img = defaultCompany;
  }else{
    img = __CONFIG__.api+logo;
  }
  
  return (
    <Card style={{ width: '300px', height: '280px' }}>
      <CardMedia
        component="img"
        height="150"
        image={img}
        title={name}
      />
      <CardContent >
        <Typography noWrap gutterBottom variant="h5"  component="h2">
          {name}
        </Typography>
      </CardContent>
      <CardActions >
        <div >
            <Button size="small" variant="outlined"  component={LinkBtn} color="secondary" to={'/ajustes'} >
              Seleccionar
            </Button>
        </div>
      </CardActions>
    </Card>
  );
}

EmpresaCard.propTypes = {
  logo: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default (EmpresaCard);
