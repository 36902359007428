import React, { Component } from 'react';
//import Grid from '../../Utils/Grid';
import Grid from '../../Utils/GridFiltro';
import PropTypes from 'prop-types';
import { standardActions } from '../../Utils/Grid/StandardActions';

class ProveedorList extends Component{
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
        
    };
    
    static defaultProps = {
        loading: true
    };

    componentDidMount = () => {
        const { listar } = this.props;
        listar();
    }

    render(){
        const { data, loader } = this.props;
        let ruta = window.location.href;
        let id_emp = ruta.split('/');
        
        return(
            <React.Fragment>
                <br />
                <h3 className="p-2 bg-secondary title">Proveedores</h3>
                <br />
                <div className="d-flex flex-row justify-content-start mb-2">
                    <a
                        href={`/#/compras/${id_emp[5]}/proveedores/crear`}
                        className='btn btn-primary'
                    >
                        Crear Proveedor
                    </a>
                </div>
                
                {data &&
                    <Grid 
                        hover 
                        striped 
                        data={data} 
                        loading={loader} 
                        headerStyle={ { background: 'black' } } 
                        //onPageChange={onPageChange}
                        //onSearch={this.handleSearch}
                        //onSortChange={onSortChange} 
                    >
                        <TableHeaderColumn
                            isKey
                            dataField="identificador"
                            width="200"
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Identificador
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="nombre"
                            width="550"
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Nombre
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="tipo_proveedor"
                            dataFormat={(cell, row)=>{
                                if (cell === 'L'){
                                    return 'LOCAL';
                                }else if (cell === 'E') {
                                    return 'EXTERIOR';
                                }else{
                                    return 'PERSONA'
                                }
                            }}
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Tipo
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="activo"
                            dataAlign='center'
                            dataFormat={(cell, row)=>{
                                if (cell === true){
                                    return 'A';
                                }else{
                                    return 'I';
                                }
                            }}
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Estado
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            thStyle={
                            {color: 'white'}}
                            dataField="id"
                            width="180"
                            dataAlign="center"
                            dataSort
                            dataFormat={standardActions({ 
                                editar: "proveedores", 
                                ver: "proveedores",
                            })} 
                            
                        >
                            Acciones
                        </TableHeaderColumn>
                    </Grid>
                }
            </React.Fragment>
        );
    }
}

export default ProveedorList;
