import { api } from "api";
import { NotificationManager } from "react-notifications";
import { parseDate } from "@internationalized/date";
import { MESES } from "../utils";

const baseurl = "laboratorio/periodos";
const writeSuccessMsg = "Unidad agregada";
const editSuccessMsg = "Unidad editado";

export const getPeriodos = (search) => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let empresa = id_emp[5];
    const params = { id: empresa, search };
    return api
        .get("periodos/periodo_empresa", params)
        .then((response) => {
            return response.map((item) => {
                return {
                    value: item.id,
                    label: item.periodo,
                    iso: item.fecha_inicio,
                };
            });
        })
        .catch((error) => {
            console.log(error);
            return [];
        });
};
