import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import NavbarModulo from '../../components/NavbarModulo';
import TablaDetalle from './DetalleGastos';
import TablaDetalleMod from './DetalleGastoMod';
import TablaDetalleVer from './DetalleGastosVer';
import styled from 'styled-components';

import {
    AsyncSelectField,
    renderField,
    renderDayPickerB,
    renderCurrencyFloat
} from "../../Utils/renderField/renderField";

function DetalleOrdenServicioForm(props) {
    const {
        periodoD,
        modulo,
        datos,
        listarProyectos,
        titulo,
        crear,
        editar,
        bloqueo,
        agregarLinea,
        registroGasto,
        detalleOrden,
        detalleOrd,
        eliminarLineaProductoNuevo,
        listarGastos,
        agregarLineaNueva,
        modificarGasto,
        eliminarLineaGasto
    } = props;

    let autoFocus = true;
    const estilo = {
        overflow: "auto",
        height: "200px",

    };
    
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    return (
        <div>
            <NavbarModulo
                periodoD={periodoD}
                modulo={modulo}
                titulo={titulo}
            />
            <br />
            <div className="mb-4 card card-small text-white bg-dark mb-3">
                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                    <div className="d-flex flex-column flex-1 mx-4">
                        <div className='row mb-2'>
                            <div className='col-2'>
                                <Field
                                    autoFocus={autoFocus}
                                    disabled={bloqueo}
                                    name="fecha"
                                    component={renderDayPickerB}
                                    msj="Fecha"
                                />
                            </div>
                            {crear === true &&
                                <div className='col-10'>
                                    <Field
                                        name="proyecto"
                                        placeholder="Orden de servicio..."
                                        className="form-control-find"
                                        component={AsyncSelectField}
                                        loadOptions={listarProyectos}
                                        msj="Orden de servicio / proyecto"
                                    />
                                </div>
                            }
                            {crear === false &&
                                <div className='col-10'>
                                    <Field
                                        name="nombreProyecto"
                                        placeholder="Proyecto"
                                        className="form-control"
                                        component={renderField}
                                        disabled={true}
                                        msj="Proyecto"
                                    />
                                </div>
                            }
                            
                        </div>
                        {crear === false &&
                            <div className='row mb-2'>
                                <div className='col-2'>
                                    <Field
                                        name="numero"
                                        placeholder="Numero"
                                        className="form-control"
                                        component={renderField}
                                        disabled={true}
                                        msj="Numero"
                                    />
                                </div>
                                <div className='col-2'>
                                    <Field
                                        name="total"
                                        placeholder="Total"
                                        className="form-control"
                                        component={renderCurrencyFloat}
                                        disabled={bloqueo}
                                        msj="Total"
                                    />
                                </div>
                            </div>
                        }
                        <div className='mb-2'>
                            <Field
                                name="observaciones"
                                placeholder="Observaciones"
                                component={renderField}
                                className="form-control"
                                disabled={bloqueo}
                                msj="Observaciones"
                            />
                        </div>
                        <h5 className="p-2 bg-secondary title mt-1">Detalle gastos</h5>
                        {crear === true &&
                            <div>
                                <div className='row mb-2'>
                                    <div className='col-10'>
                                        <Field
                                            name="gasto"
                                            placeholder="Gasto..."
                                            className="form-control-find"
                                            component={AsyncSelectField}
                                            loadOptions={listarGastos}
                                            msj="Gasto"
                                        />
                                    </div>
                                    <div className='col-2'>
                                        <Field
                                            name="total_gasto"
                                            placeholder="Total"
                                            className="form-control"
                                            component={renderCurrencyFloat}
                                            disabled={bloqueo}
                                            msj="Total"
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {editar === true &&
                            <div>
                                <div className='row mb-2'>
                                    <div className='col-10'>
                                        <Field
                                            name="gasto"
                                            placeholder="Gasto..."
                                            className="form-control-find"
                                            component={AsyncSelectField}
                                            loadOptions={listarGastos}
                                            msj="Gasto"
                                        />
                                    </div>
                                    <div className='col-2'>
                                        <Field
                                            name="total_gasto"
                                            placeholder="Total"
                                            className="form-control"
                                            component={renderCurrencyFloat}
                                            disabled={bloqueo}
                                            msj="Total"
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {(crear === true) &&
                            <Contenido>
                                <button
                                    className='btn mr-2 mb-3 btn-primary'
                                    onClick={() => {
                                        agregarLinea(periodoD, datos);
                                    }}
                                >
                                    Agregar
                                </button>
                            </Contenido>
                        }
                        {(editar === true) &&
                            <Contenido>
                                <button
                                    className='btn mr-2 mb-3 btn-primary'
                                    onClick={() => {
                                        agregarLineaNueva(periodoD, datos);
                                    }}
                                >
                                    Agregar
                                </button>
                            </Contenido>
                        }
                        {crear === true && editar === false &&
                            <div style={estilo}>
                                <TablaDetalle
                                    detalle={detalleOrden}
                                    eliminarLineaProducto={eliminarLineaProductoNuevo}
                                />
                            </div>
                        }
                        {crear === false && editar === true &&
                            <div style={estilo}>
                                <TablaDetalleMod
                                    detalle={detalleOrd}
                                    eliminarLineaGasto={eliminarLineaGasto}
                                />
                            </div>
                        }
                        {crear === false && editar === false &&
                            <div style={estilo}>
                                <TablaDetalleVer
                                    detalle={detalleOrd}
                                />
                            </div>
                        }
                        <br />
                        <div className='d-flex flex-row mt-3'>
                            {crear === true && editar === false &&
                                <button
                                    className='btn mr-2 mb-3 btn-primary'
                                    onClick={() => registroGasto(periodoD, detalleOrden)}
                                >
                                    Guardar
                                </button>
                            }
                            {editar === true && crear === false &&
                                <button
                                    className='btn mr-2 mb-3 btn-primary'
                                    onClick={() => modificarGasto(periodoD, detalleOrden)}
                                >
                                    Modificar
                                </button>
                            }
                            <a
                                href={`/#/inventario/${id_emp[5]}/gastos_proyecto/${id_emp[7]}`}
                                className='btn btn-secondary mb-3'
                            >
                                Regresar
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

DetalleOrdenServicioForm.propTypes = {
    crear: PropTypes.bool,
};

export default reduxForm({
    form: 'FormGastosProyectoINV',
})(DetalleOrdenServicioForm);

const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;