import { api } from "api";
import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { logOut, getMe, getModulo } from "./redux/modules/cuenta/login";
import { VerifyLogin } from "./common/components/layout";
import styled, { createGlobalStyle } from "styled-components";
import { Helmet } from "react-helmet";
import BaseNavBar from "./common/components/layout/Navbar/BaseNavBar";
const backgroundImg = require("../assets/img/background-image.svg");
import { setSettingsEmpresa } from "./redux/modules/settings/actions";

const GlobalStyle = createGlobalStyle`
    html, body {
        min-width: 460px;
    }
    body {
        background-color: #3d3d3d;
        /* background-image: url(${backgroundImg}); */
        background: url(${backgroundImg}) no-repeat center center fixed;
        background-size: cover;
        
    }
`;

export const fetchEmpresaSettings = (id) => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let empresa = id_emp[5];
    const params = { id: empresa };
    // const url = id? baseurl + `/${id}`: baseurl;
    return api
        .get("empresa/empresa_parametros", params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return {};
        });
};

function ProtectedRoute({ setSettingsEmpresa, title, ...props }) {
    const getSettings = async () => {
        const settings = await fetchEmpresaSettings();
        setSettingsEmpresa(settings);
    };
    useEffect(() => {
        const { getModulo } = props;
        let ruta = window.location.href;
        let modulo = ruta.split("/");
        // getModulo(modulo[5], modulo[4]);
        getSettings();
    }, []);

    const checkAuth = () => {
        const token = localStorage.getItem("token");
        const {
            getMe,
            login: { me },
        } = props;
        if (!!token && !!me.username) {
            return true;
        } else if (token) {
            getMe();
            return "Verifying";
        }
        return false;
    };

    const links = props.links;
    const NavBar = props.navbar;

    const {
        component: Component,
        logOut,
        login: { me, empresaData },
        ...rest
    } = props;
    const isAuthenticated = checkAuth();

    return (
        <Route
            {...rest}
            render={(props) =>
                isAuthenticated ? (
                    isAuthenticated === true ? (
                        <div>
                            <GlobalStyle />
                            <Helmet>
                                <title>
                                    {title ? title : "Everything is awesome"}
                                </title>
                            </Helmet>
                            <main className="main-content p-0">
                                <div className="main-navbar fondo sticky-top">
                                    <div className="p-0">
                                        <NavBar
                                            links={links}
                                            logOut={logOut}
                                            user={me}
                                            empresaData={empresaData}
                                        />
                                    </div>
                                </div>
                                <div className="main-content-container px-4 container-fluid">
                                    <Component {...props} />
                                </div>
                            </main>
                        </div>
                    ) : (
                        <VerifyLogin />
                    )
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    );
}

const mstp = (state) => ({ ...state });

const mdtp = { logOut, getMe, getModulo, setSettingsEmpresa };

const BaseProtectedRoute = connect(mstp, mdtp)(ProtectedRoute);

export default BaseProtectedRoute;
