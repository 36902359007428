import React, { Component } from 'react';
import Grid from '../../Utils/GridDatosNominaLiquidacion';
import PropTypes from 'prop-types';

class ListDatosSueldo extends Component{
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        //onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: true
    };

    render(){
        const { data, loader } = this.props;

        return(
            <React.Fragment>
                <br />
                {data &&
                    <Grid 
                        hover 
                        striped 
                        data={data}
                        loading={loader} 
                        headerStyle={ { background: 'black' } } 
                        //onPageChange={onPageChange}
                        //onSortChange={onSortChange} 
                    >
                        <TableHeaderColumn
                            isKey
                            dataAlign='right'
                            headerAlign="center"
                            dataField="sueldoBase"
                            width="150"
                            dataSort
                            dataFormat={(cell, row)=>{
                                if (parseFloat(cell) === 0.00){
                                    return ''
                                }else{
                                    return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Math.abs(cell));
                                }
                            }}
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Sueldo
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataAlign='right'
                            headerAlign="center"
                            dataField="bonificacionDecreto"
                            width="150"
                            dataSort
                            dataFormat={(cell, row)=>{
                                if (parseFloat(cell) === 0.00){
                                    return ''
                                }else{
                                    return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Math.abs(cell));
                                }
                            }}
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Bonificación
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataAlign='right'
                            headerAlign="center"
                            dataField="total"
                            width="150"
                            dataSort
                            dataFormat={(cell, row)=>{
                                if (parseFloat(cell) === 0.00){
                                    return ''
                                }else{
                                    return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Math.abs(cell));
                                }
                            }}
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Total
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            headerAlign="center"
                            dataAlign='right'
                            dataField="dias"
                            width="150"
                            dataFormat={(cell, row)=>{
                                return new Intl.NumberFormat("en-US",{minimumFractionDigits: 0}).format(cell);
                            }}
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Dias
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataAlign='right'
                            headerAlign="center"
                            dataField="base"
                            width="150"
                            dataSort
                            dataFormat={(cell, row)=>{
                                if (parseFloat(cell) === 0.00){
                                    return ''
                                }else{
                                    return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Math.abs(cell));
                                }
                            }}
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Base
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataAlign='right'
                            headerAlign="center"
                            dataField="bonificacion"
                            width="150"
                            dataSort
                            dataFormat={(cell, row)=>{
                                if (parseFloat(cell) === 0.00){
                                    return ''
                                }else{
                                    return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Math.abs(cell));
                                }
                            }}
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Bonificación
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            headerAlign="center"
                            dataAlign='right'
                            dataField="isr"
                            width="150"
                            dataFormat={(cell, row)=>{
                                if (parseFloat(cell) === 0.00) {
                                    return ''
                                }else{
                                    return '( ' + new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Math.abs(cell)) + ' )';
                                }
                            }}
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            ISR
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            headerAlign="center"
                            dataAlign='right'
                            dataField="igss"
                            width="150"
                            dataFormat={(cell, row)=>{
                                if (parseFloat(cell) === 0.00) {
                                    return ''
                                }else{
                                    return '( ' + new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Math.abs(cell)) + ' )';
                                }
                            }}
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Igss
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataAlign='right'
                            headerAlign="center"
                            dataField="liquido"
                            width="150"
                            dataSort
                            dataFormat={(cell, row)=>{
                                if (parseFloat(cell) === 0.00){
                                    return ''
                                }else{
                                    return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Math.abs(cell));
                                }
                            }}
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Liquido
                        </TableHeaderColumn>
                    </Grid>
                }
            </React.Fragment>
        );
    }
}

export default ListDatosSueldo;
