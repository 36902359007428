import React from 'react';
import { Helmet } from 'react-helmet';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import { reduxForm } from 'redux-form/immutable';

function ToolsHome() {
    let description = '';
    return (
        <div>
            <Helmet>
                <title>{description}</title>
            </Helmet>
            <Toolbar >
                <div style={{ color: "white" }}>
                    <Typography variant="h3">
                        {description}
                    </Typography>
                </div>
            </Toolbar>
        </div>
    );
}


export default reduxForm({
    form: 'FormTHome',

})(ToolsHome);