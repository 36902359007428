import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/nomina/horas_extras';
import Horas from './HorasExtrasList';

const ms2p = (state) => {
  return {
    ...state.hora_extra_nomina,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Horas);