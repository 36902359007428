import React, { Component, useState } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import { validate, validators } from 'validate-redux-form';
import NavbarModulo from '../../components/NavbarModulo';
import {
  renderField,
} from "../../Utils/renderField/renderField";

class CentroCostoForm extends Component {

  render() {
    const { handleSubmit, crear, modulo } = this.props;

    const editar = window.location.href.includes('editar');
    let titulo = editar ? 'Modificar centro costo' : 'Crear centro costo';
    let disabled = false;
    if (crear === false && editar === false) {
      disabled = true;
      titulo = 'Ver centro costo'
    }
    localStorage.setItem('tituloForm', titulo);
    let autoFocus = true;
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    return (
      <div>
        <NavbarModulo
          modulo={modulo}
          titulo={titulo}
        />
        <form onSubmit={handleSubmit}>
          <br />
          <div className="mb-4 card card-small text-white bg-dark mb-3">
            <div className="p-0 pt-3 d-flex flex-column flex-md-row">
              <div className="d-flex flex-column flex-1 mx-4">

                <div className='mb-2 w-25'>
                  <Field
                    autoFocus={autoFocus}
                    name="codigo"
                    placeholder="Codigo"
                    component={renderField}
                    className="form-control"
                    disabled={editar === true ? true : (crear === false && editar === false) ? true : false}
                    msj="Número"
                  />
                </div>
                <div className='mb-2 w-75'>
                  <Field
                    name="descripcion"
                    placeholder="Descripcion"
                    className="form-control"
                    component={renderField}
                    disabled={disabled}
                    msj="Descripción"
                  />
                </div>
               
                <div className='d-flex flex-row mt-3'>
                  {disabled == false &&
                    <button
                      className={`btn mr-2 mb-3 ${editar ? 'btn-primary' : 'btn-primary'}`}
                      type='submit'
                    >
                      {editar ? 'Modificar' : 'Guardar'}
                    </button>
                  }
                  <a
                    href={`/#/contabilidad/${id_emp[5]}/centro_costo`}
                    className='btn btn-secondary mb-3'
                  >
                    Regresar
                  </a>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default reduxForm({
  form: 'FormCentroCosto',
  validate: (data) => {
    return validate(data, {
      codigo: validators.exists()('Este campo es requerido'),
      descripcon: validators.exists()('Este campo es requerido'),
    });
  },
})(CentroCostoForm);
