import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import NavbarModulo from '../../components/NavbarModulo';
import TablaDetalle from './DetalleOrdenCompra';
import TablaDetalleVer from './DetalleOrdenCompraVer';
import styled from 'styled-components';

import {
    SelectField,
    AsyncSelectField,
    renderField,
    renderCurrencyFloat,
    renderDayPickerB
} from "../../Utils/renderField/renderField";

function DetalleOrdenCompraForm(props) {
    const {
        periodoD,
        modulo,
        listarProductos,
        listarProveedores,
        titulo,
        crear,
        editar,
        bloqueo,
        monedas,
        tipoCambio,
        agregarLinea,
        registroOrden,
        detalleOrden,
        numeroOrden,
        detalleOrd,
        lectura,
        eliminarLineaProductoNuevo
    } = props;

    const [cantidad, setCantidad] = useState(0.00);
    const [total, setTotal] = useState(0.0);
    const [descuento, setDescuento] = useState(0.0);
    const [precioU, setPrecioUnitario] = useState(0.00);
    
    let autoFocus = true;
    const estilo = {
        overflow: "auto",
        height: "200px",

    };
    const [tipoCambioD, setTipoCambioD] = useState(1.000000);
    const [moneda, setMoneda] = useState('');
    const [tipo, setTipo] = useState('');
    
    const tipoLinea = [
        { label: "BIENES", value: "B" },
        { label: "SERVICIOS", value: "S" },
    ];

    const handleChangeMoneda = (e) => {
        setMoneda(e.target.value);
    };

    const handleChangeLinea = (e) => {
        setTipo(e[0]);
    };
    

    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    return (
        <div>
            <NavbarModulo
                periodoD={periodoD}
                modulo={modulo}
                titulo={titulo}
            />
            <br />
            <div className="mb-4 card card-small text-white bg-dark mb-3">
                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                    <div className="d-flex flex-column flex-1 mx-4">
                        <div className='row mb-2'>
                            <div className='col-3'>
                                <Field
                                    autoFocus={autoFocus}
                                    disabled={bloqueo}
                                    name="fecha_orden_compra"
                                    component={renderDayPickerB}
                                    msj="Fecha orden de compra"
                                />
                            </div>
                            <div className='col-3'>
                                <Field
                                    disabled={bloqueo}
                                    name="fecha_compra"
                                    component={renderDayPickerB}
                                    msj="Fecha de compra"
                                />
                            </div>
                            <div className='col-3'>
                                <Field
                                    name="tipo_pedido"
                                    placeholder="Tipo de pedido"
                                    className="form-control"
                                    component={renderField}
                                    disabled={bloqueo}
                                    msj="Tipo de pedido"
                                />
                            </div>
                            {crear === true &&
                                <div className='col-3 mb-2'>
                                    <div className="form-floating">
                                        <input
                                            placeholder="Numero de orden"
                                            type="number"
                                            autocomplete="off"
                                            name="numeroe"
                                            className="form-control"
                                            value={numeroOrden}
                                            disabled={bloqueo}
                                        />
                                        <label className='form-label' htmlFor="proveedor">
                                            Numero de orden
                                        </label>
                                    </div>
                                </div>
                            }
                            {crear === false &&
                                <div className='col-3'>
                                    <Field
                                        name="numero"
                                        placeholder="Número"
                                        className="form-control"
                                        component={renderField}
                                        disabled={bloqueo}
                                        msj="Número"
                                    />
                                </div>
                            }
                        </div>
                        <div className='row mb-2'>
                            <div className='col-3'>
                                <Field
                                    name="incoterm"
                                    placeholder="Incoterm"
                                    className="form-control"
                                    component={renderField}
                                    disabled={bloqueo}
                                    msj="Iconterm"
                                />
                            </div>
                            <div className='col-3'>
                                <Field
                                    name="tipo_pago"
                                    placeholder="Tipo de pago"
                                    className="form-control"
                                    component={renderField}
                                    disabled={bloqueo}
                                    msj="Tipo de pago"
                                />
                            </div>
                            <div className='col-3'>
                                <Field
                                    name="medio_envio"
                                    placeholder="Medio de envio"
                                    className="form-control"
                                    component={renderField}
                                    disabled={bloqueo}
                                    msj="Medio de envio"
                                />
                            </div>
                            <div className='col-3'>
                                <Field
                                    name="embarcador"
                                    placeholder="Embarcador"
                                    className="form-control"
                                    component={renderField}
                                    disabled={bloqueo}
                                    msj="Embarcador"
                                />
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <div className='col-3'>
                                <Field
                                    name="seguro"
                                    placeholder="Seguro"
                                    className="form-control"
                                    component={renderField}
                                    disabled={bloqueo}
                                    msj="Seguro"
                                />
                            </div>
                            {(crear === false && editar === false) &&
                                <div className='col-3'>
                                    <div className="form-floating">
                                        <Field
                                            name="moneda"
                                            options={monedas}
                                            crear={crear}
                                            dato={crear === true ? 0 : lectura.moneda}
                                            component={SelectField}
                                            className="form-select"
                                            disabled={bloqueo}
                                            msj="Moneda"
                                        />
                                    </div>
                                </div>
                            }
                            {(crear === true) &&
                                <div className='col-3'>
                                    <div className="form-floating">
                                        <select className="form-select" id="tipo" name="tipo"
                                            value={editar === true ? lectura.concepto : moneda} onChange={handleChangeMoneda}>
                                            <option selected>Seleccionar...</option>
                                            {monedas.map(
                                                tip => <option key={tip.value} value={tip.value}
                                                >{tip.label}
                                                </option>)
                                            }
                                        </select>
                                        <label className='form-label' htmlFor="mes">Moneda</label>
                                    </div>
                                </div>
                            }

                            {(crear === true) && (
                                <div className='col-3'>
                                    <div className="form-floating">
                                        <NumberFormat
                                            className={classNames('form-control')}
                                            decimalScale={6}
                                            fixedDecimalScale
                                            placeholder="Tipo de cambio"
                                            value={tipoCambioD}
                                            thousandSeparator
                                            onValueChange={(values) => {
                                                setTipoCambioD(values.value)
                                            }}
                                        />
                                        <label className='form-label' htmlFor="tipo">
                                            Tipo de cambio
                                        </label>
                                    </div>
                                </div>
                            )}
                            {(crear === true) && (
                                <div className='col-3 mt-2'>
                                    <button
                                        className='btn mr-2 mb-3 btn-secondary'
                                        onClick={() => {
                                            let tc = tipoCambio(periodoD);
                                            if (tc === undefined) {
                                                setTipoCambioD('1.000000');
                                            } else {
                                                tc.then(d => {
                                                    if (d === undefined) {
                                                        setTipoCambioD('1.000000');
                                                    } else {
                                                        setTipoCambioD(d);
                                                    }
                                                });
                                            }
                                        }}
                                    >
                                        Buscar tipo de cambio
                                    </button>
                                </div>
                            )}
                            {crear === false &&
                                <div className='col-3'>
                                    <Field
                                        name="tipo_cambio"
                                        placeholder="Tipo de cambio"
                                        className="form-control"
                                        component={renderField}
                                        disabled={bloqueo}
                                        msj="Tipo de cambio"
                                    />
                                </div>
                            }
                        </div>
                        {crear === true &&
                            <div className='row mb-2'>
                                <div className='col-12'>
                                    <Field
                                        name="proveedor"
                                        placeholder="Proveedor..."
                                        className="form-control-find"
                                        component={AsyncSelectField}
                                        loadOptions={listarProveedores}
                                        msj="Proveedor"
                                    />
                                </div>
                            </div>
                        }
                        {crear === false &&
                            <div className='row mb-2'>
                                <div className='col-12'>
                                    <Field
                                        name="proveedorN"
                                        placeholder="Proveedor"
                                        className="form-control"
                                        component={renderField}
                                        disabled={bloqueo}
                                        msj="Proveedor"
                                    />
                                </div>
                            </div>
                        }
                        <div className='row mb-2'>
                            <div className='col-6'>
                                <Field
                                    name="solicitante"
                                    placeholder="Descripcion"
                                    component={renderField}
                                    className="form-control"
                                    msj="Solicitante"
                                />
                            </div>
                            <div className='col-6'>
                                <Field
                                    name="autorizado"
                                    placeholder="Descripcion"
                                    component={renderField}
                                    className="form-control"
                                    msj="Autorizado"
                                />
                            </div>
                        </div>
                        <div className='mb-2'>
                            <Field
                                name="observaciones"
                                placeholder="Observaciones"
                                component={renderField}
                                className="form-control"
                                disabled={bloqueo}
                                msj="Observaciones"
                            />
                        </div>
                        <h5 className="p-2 bg-secondary title mt-1">Detalle orden de compra</h5>
                        {crear === true &&
                            <div>
                                <div className='row mb-2'>
                                    <div className='col-2'>
                                        <div className="form-floating">
                                            <Field
                                                name="tipo_linea"
                                                options={tipoLinea}
                                                crear={crear}
                                                dato={'B'}
                                                component={SelectField}
                                                className="form-select"
                                                onChange={(e, value) => {
                                                    handleChangeLinea(e);
                                                }}
                                                msj="Tipo"
                                            />
                                        </div>
                                    </div>
                                    {tipo === 'B' &&
                                        <div className='col-10'>
                                            <Field
                                                name="producto"
                                                placeholder="Producto..."
                                                className="form-control-find"
                                                component={AsyncSelectField}
                                                loadOptions={listarProductos}
                                                msj="Producto"
                                            />
                                        </div>
                                    }
                                    {tipo === 'S' &&
                                        <div className='col-9'>
                                            <Field
                                                name="descripcion_serv"
                                                placeholder="Descripcion"
                                                component={renderField}
                                                className="form-control"
                                                msj="Descripción"
                                            />
                                        </div>
                                    }

                                </div>
                                <div className='row mb-2'>
                                    <div className='col-2'>
                                        <Field
                                            name="cantidad"
                                            placeholder="Cantidad"
                                            className="form-control"
                                            component={renderCurrencyFloat}
                                            onChange={(e, value) => {
                                                if (value) {
                                                    setCantidad(value);
                                                }
                                            }}
                                            msj="Cantidad"
                                        />
                                    </div>
                                    <div className='col-2'>
                                        <Field
                                            name="precio_unitario"
                                            placeholder="Precio unitario"
                                            className="form-control"
                                            component={renderCurrencyFloat}
                                            onChange={(e, value) => {
                                                if (value) {
                                                    setPrecioUnitario(value);
                                                    let total = parseFloat(parseFloat(value) * parseFloat(cantidad)).toFixed(2);
                                                    let totalDes = parseFloat(parseFloat(total) - parseFloat(descuento)).toFixed(2);
                                                    setTotal(totalDes);
                                                }
                                            }}
                                            msj="Precio unitario"
                                        />
                                    </div>
                                    <div className='col-2'>
                                        <Field
                                            name="descuento"
                                            placeholder="Descuento"
                                            className="form-control"
                                            component={renderCurrencyFloat}
                                            onChange={(e, value) => {
                                                if (value) {
                                                    let totalLinea = parseFloat(parseFloat(precioU) * parseFloat(cantidad)).toFixed(2);
                                                    setDescuento(value);
                                                    let totalD = parseFloat(parseFloat(totalLinea) - parseFloat(value)).toFixed(2);
                                                    setTotal(totalD);
                                                }
                                            }}
                                            msj="Descuento"
                                        />
                                    </div>
                                    <div className='col-2'>
                                        <div className="form-floating">
                                            <NumberFormat
                                                className={classNames('form-control')}
                                                decimalScale={2}
                                                disabled={true}
                                                placeholder="Base"
                                                fixedDecimalScale
                                                thousandSeparator
                                                value={total}
                                                name="total"
                                            />
                                            <label className='form-label' htmlFor="total">
                                                Total
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {(crear === true) &&
                            <Contenido>
                                <button
                                    className='btn mr-2 mb-3 btn-primary'
                                    onClick={() => {
                                        agregarLinea(periodoD);
                                        setTotal(0.00);
                                    }}
                                >
                                    Agregar
                                </button>
                            </Contenido>
                        }
                        {crear === true &&
                            <div style={estilo}>
                                <TablaDetalle
                                    detalle={detalleOrden}
                                    eliminarLineaProducto={eliminarLineaProductoNuevo}
                                />
                            </div>
                        }
                        {crear === false &&
                            <div style={estilo}>
                                <TablaDetalleVer
                                    detalle={detalleOrd}
                                />
                            </div>
                        }

                        <br />
                        <div className='d-flex flex-row mt-3'>

                            <button
                                className='btn mr-2 mb-3 btn-primary'
                                onClick={() => registroOrden(periodoD, moneda, tipoCambioD, numeroOrden)}
                            >
                                Guardar
                            </button>

                            <a
                                href={`/#/compras/${id_emp[5]}/orden_compra/${id_emp[7]}`}
                                className='btn btn-secondary mb-3'
                            >
                                Regresar
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

DetalleOrdenCompraForm.propTypes = {
    crear: PropTypes.bool,
    listarProductos: PropTypes.object
};

export default reduxForm({
    form: 'FormOrdenCompraCMP',
})(DetalleOrdenCompraForm);

const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;