import React, { Component } from 'react';
import Formulario from './BajaCajasForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";

class BajaCajas extends Component{
    state = {
        crear: true,
    }

    componentDidMount = () => {
        const { match, leer, getModulo,leerPeriodoD, 
        vaciar, numeroBaja } = this.props;
        const id = match.params.id;
        if (id){
            this.setState({crear: false});
            leer(id);
        }
        let ruta = window.location.href;
        let periodo = ruta.split('/');
        leerPeriodoD(periodo[5], periodo[7]);
        getModulo(periodo[5], periodo[4]);
        numeroBaja();
        vaciar();
        
        localStorage.removeItem('id_detalle_baja_cajas');
    }
    
    render(){
        const { loader, modulo, periodoD, registroBaja, 
            detalleInsumo, detallePro, listarClientes, eliminarLineaInsumo,
            nuevaLineaInsumo, listarPilotos, numeroS, numeroIngreso, lectura,
            listarFases, agregarLinea, modificarBaja, eliminarLinea,
            numeroB, listarInvernaderos, insumo, leerInsumos } = this.props;
        const { crear } = this.state;
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    crear = {crear}
                    modulo = {modulo}
                    periodoD = {periodoD}
                    detalleCon = {detallePro}
                    registroBaja = {registroBaja}
                    modificarBaja = {modificarBaja}
                    detalleInsumo = {detalleInsumo}
                    numeroS = {numeroS}
                    listarFases = {listarFases}
                    agregarLinea = {agregarLinea}
                    listarPilotos = {listarPilotos}
                    listarClientes = {listarClientes}
                    eliminarLinea = {eliminarLinea}
                    nuevaLineaInsumo = {nuevaLineaInsumo}
                    eliminarLineaInsumo = {eliminarLineaInsumo}
                    numeroIngreso = {numeroIngreso}
                    lectura = {lectura}
                    numeroB = {numeroB}
                    listarInvernaderos = {listarInvernaderos}
                    leerInsumos = {leerInsumos}
                    insumo = {insumo}
                />
            </LoadMask>
        );
    }
}

export default BajaCajas
