import React from "react";
import styled from "styled-components";
import { FicInput, FicNumber } from "../../Utils/FormInputComponents";
import Banner from "../common/Banner";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { validar } from "../../components/ValidarNit";
import GenBtn from "../../components/GenBtn";
import InputsContainer from "../common/InputsContainer";
import { crearCliente } from "./actions";

const schema = yup.object({
    nit: yup
        .string()
        .test("nit-validation", "El NIT no es valido", function (value) {
            return validar.valNit(value);
        })
        .required("El nit es requerido"),
    nombre: yup.string().required("El nombre es requerido"),
    celular: yup
        .number()
        .typeError("Debe de ser un numero (sin separadores)")
        .required("El celular es requerido"),
    direccion: yup.string().required("La direccion es requerida"),
    email: yup
        .string()
        .email("Ingrese un email valido")
        .required("El correo es requerido"),
    telefono: yup
        .number()
        .typeError("Debe de ser un numero (sin separadores)")
        .required("El telefono es requerido"),
});

const onSubmit = (data) => {
    crearCliente(data);
};

export default function Crear() {
    const { control, reset, handleSubmit } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: {
            nit: "",
            nombre: "",
            celular: "",
            direccion: "",
            email: "",
            telefono: "",
            contacto: "",
            celular_contacto: "",
        },
    });

    return (
        <React.Fragment>
            <Banner>Ficha clientes</Banner>
            <GenBtn
                style={{ margin: "1rem 0" }}
                onPress={() => history.goBack()}
            >
                Regresar
            </GenBtn>
            <form onSubmit={handleSubmit(onSubmit)}>
                <InputsContainer style={{ minWidth: "645px" }}>
                    <FicInput control={control} label="Nit" name="nit" />
                    <FicInput
                        control={control}
                        label="Nombre"
                        name="nombre"
                        style={{ gridColumn: "span 2" }}
                    />
                    <FicInput
                        control={control}
                        label="Direccion"
                        name="direccion"
                        style={{ gridColumn: "span 2" }}
                    />
                    <FicInput
                        control={control}
                        label="Celular"
                        name="celular"
                        inputMode="numeric"
                    />
                    <FicInput control={control} label="E-mail" name="email" />
                    <FicInput
                        control={control}
                        label="Telefono"
                        name="telefono"
                        inputMode="numeric"
                    />
                    <FicInput
                        control={control}
                        label="Contacto"
                        name="contacto"
                    />
                    <FicInput
                        control={control}
                        label="Celular contacto"
                        name="celular_contacto"
                        inputMode="numeric"
                    />
                </InputsContainer>
                <GenBtn style={{ margin: "1rem 0" }} type="submit">
                    Guardar
                </GenBtn>
            </form>
        </React.Fragment>
    );
}
