import React, { Component } from 'react';
import Formulario from './LiquidacionForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";
const valEmp = __CONFIG__.empresa;

class Liquidacion extends Component{
    state = {
        crear: true,
    }

    componentDidMount = () => {
        const { match, getModulo, listarColaborador, cargarPrestaciones, leer, limpiarDatos } = this.props;
        const id = match.params.id;
        if (id){
            this.setState({crear: false});
            leer(id);
        }else{
            listarColaborador();
            cargarPrestaciones()
    
        }
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        if (valEmp === 'fage'){
            getModulo(modulo[6],modulo[5])
        }else{
            getModulo(modulo[5],modulo[4]);
        }
        limpiarDatos();
    }
    
    render(){
        const { listarColaborador, loader, modulo, listarPaises, emp, preGrabar,
            datosColaborador, fechaAlta, calcularDatos, salario, boni, 
            agregarPrestacion, prest, cargo, confirmar, lectura, detalleLiq,
            recalcularDatos, eliminar, guardarPrestacion, preGrabarMod,
            confirmarMod, datosNomina, idColaborador, datosNom,
            promedio, promedioHora, promedioComision, calcularSueldo,
            nominaSueldo, salarioLiquido, totalPrestamo, detalleNominaSueldo,
            calcularSueldoMod, nominaSueldoMod } = this.props;
        const { crear } = this.state;
        const editar = window.location.href.includes('editar');
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    modulo = {modulo}
                    listarColaborador = {listarColaborador}
                    listarPaises = {listarPaises}
                    emp = {emp}
                    datosColaborador = {datosColaborador}
                    fechaAlta = {fechaAlta}
                    calcularDatos = {calcularDatos}
                    salario = {salario}
                    boni = {boni}
                    agregarPrestacion = {agregarPrestacion}
                    prest = {prest}
                    cargo = {cargo}
                    preGrabar = {preGrabar}
                    confirmar = {confirmar}
                    crear = {crear}
                    editar = {editar}
                    lectura = {lectura}
                    detalleLiq = {detalleLiq}
                    recalcularDatos = {recalcularDatos}
                    eliminar = {eliminar}
                    guardarPrestacion = {guardarPrestacion}
                    preGrabarMod = {preGrabarMod}
                    confirmarMod = {confirmarMod}
                    datosNomina = {datosNomina}
                    idColaborador = {idColaborador}
                    datosNom = {datosNom}
                    promedio = {promedio}
                    promedioHora = {promedioHora}
                    promedioComision = {promedioComision}
                    calcularSueldo = {calcularSueldo}
                    nominaSueldo = {nominaSueldo}
                    salarioLiquido = {salarioLiquido}
                    totalPrestamo = {totalPrestamo}
                    detalleNominaSueldo = {detalleNominaSueldo}
                    calcularSueldoMod = {calcularSueldoMod}
                    nominaSueldoMod = {nominaSueldoMod}
                />
            </LoadMask>
        );
    }
}

export default Liquidacion;