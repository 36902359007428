import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import './acciones.css';
import Swal from 'sweetalert2';
import * as FaIcons from "react-icons/fa";

class Acciones extends Component {
    constructor(props) {
        super(props);
    }

    eliminar = (id) => {
        return () => {
            Swal.fire({
                title: '¿Eliminar?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.eliminar(id);
                }
            });
        }
    };

    eliminar_pol_a = (id, tipo_poliza, numero_poliza) => {
        return () => {
            Swal.fire({
                title: '¿Eliminar póliza '+ tipo_poliza +' número: ' + numero_poliza +'?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.eliminar_pol_a(id);
                }
            });
        }
    };

    imprimir = (id) => {
        return () => {
            this.props.imprimir(id);
        }
    };

    imprimirCK = (id) => {
        return () => {
            this.props.imprimirCK(id);
        }
    };

    imprimirAlta = (id) => {
        return () => {
            this.props.imprimirAlta(id);
        }
    };

    imprimir_e = (id) => {
        return () => {
            this.props.imprimir_e(id);
        }
    };

    excel = (id) => {
        return () => {
            this.props.excel(id);
        }
    };

    anularCK = (id, id_ck, cuenta_ban_ck) => {
        return () => {
            Swal.fire({
                title: '¿Anular número de cheque: '+ id + '?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, anular!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.anularCK(id, id_ck, cuenta_ban_ck);
                }
            });
        }
    };

    anularFac = (id, serie_fac, numero_fac) => {
        return () => {
            Swal.fire({
                title: '¿Anular factura: '+ serie_fac + ' -- '+ numero_fac + '?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, anular!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.anularFac(id);
                }
            });
        }
    };

    anularNotaCobro = (id, serie_fac, numero_fac) => {
        return () => {
            Swal.fire({
                title: '¿Anular nota de cobro: '+ serie_fac + ' -- '+ numero_fac + '?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, anular!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.anularNotaCobro(id);
                }
            });
        }
    };

    anularEnvio = (id, serie_fac, numero_fac) => {
        return () => {
            Swal.fire({
                title: '¿Anular envío: '+ serie_fac + ' -- '+ numero_fac + '?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, anular!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.anularEnvio(id);
                }
            });
        }
    };

    eliminarFac = (id, serie_fac, numero_fac) => {
        return () => {
            Swal.fire({
                title: '¿Eliminar factura: '+ serie_fac + ' -- '+ numero_fac + '?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.eliminarFac(id);
                }
            });
        }
    };

    eliminarNC = (id, numero_fac) => {
        return () => {
            Swal.fire({
                title: '¿Eliminar nota de crédito: ' + ' '+ numero_fac + '?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.eliminarNC(id);
                }
            });
        }
    };

    eliminarDep = (id, numero_fac) => {
        return () => {
            Swal.fire({
                title: '¿Eliminar depósito: ' + ' '+ numero_fac + '?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.eliminarDep(id);
                }
            });
        }
    };

    eliminar_e = (id, serie_fac, numero_fac) => {
        return () => {
            Swal.fire({
                title: '¿Eliminar Envío: '+ serie_fac + ' -- '+ numero_fac + '?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.eliminar_e(id);
                }
            });
        }
    };

    eliminar_nota = (id, serie_fac, numero_fac) => {
        return () => {
            Swal.fire({
                title: '¿Eliminar Nota de cobro: '+ serie_fac + ' -- '+ numero_fac + '?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.eliminar_nota(id);
                }
            });
        }
    };

    anularFac_fel = (id, serie_fac, numero_fac) => {
        return () => {
            Swal.fire({
                title: '¿Anular factura: '+ serie_fac + ' -- '+ numero_fac + '?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, anular!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    Swal.fire({
                        title: 'Motivo de anulación',
                        html:
                            '<div class="mx-auto mt-4" style="width: 450px;">' +
                            `<input class="form-control" id="mensaje" class="mx-auto mb-4" type="text">` +
                            '</div',
                        background: "black",
                        showCancelButton: true,
                        confirmButtonColor: "#4040ff",
                        confirmButtonText: "Aceptar",
                        cancelButtonText: "Cancelar",
                        cancelButtonColor: "#646464",
                        showCloseButton: true,
                        customClass: {
                            title: 'sweet_titleImportant',
                        },
                        preConfirm: () => {
                            let texto = document.getElementById('mensaje').value
                            if (texto){
                                this.props.anularFac_fel(id, texto);
                            }
                            if (!texto) {
                                Swal.showValidationMessage(
                                    'Este campo es requerido'
                                )
                            }
                        }
                    })
                    
                }
            });
        }
    };

    eliminar_insumo = (id, numero) => {
        return () => {
            Swal.fire({
                title: '¿Eliminar correlativo: '+ numero + '?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.eliminar_insumo(id);
                }
            });
        }
    };

    eliminarND = (id, numero_fac) => {
        return () => {
            Swal.fire({
                title: '¿Eliminar nota de débito: ' + ' '+ numero_fac + '?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.eliminarND(id);
                }
            });
        }
    };

    eliminarNTDev = (id, serie_fac, numero_fac) => {
        return () => {
            Swal.fire({
                title: '¿Eliminar nota de devolución: ' + ' '+ serie_fac + ' - ' + numero_fac + '?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.eliminarNTDev(id);
                }
            });
        }
    };

    eliminarAnticipo = (id, serie_fac, numero_fac) => {
        return () => {
            Swal.fire({
                title: '¿Eliminar nota de devolución: ' + ' '+ serie_fac + ' - ' + numero_fac + '?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.eliminarAnticipo(id);
                }
            });
        }
    };

    render() {
        const { id, ver, editar, eliminar, imprimir, excel, anularCK, val_envio, editar_i, editar_e,
                ver_a, editar_a, periodo, empresa, modulo, tipo_pol, anularFac, serie_fac, tipo_documento,
                editar_pol_a, eliminar_pol_a, ver_inv, editar_inv, tipo_F, numero_fac, anularNotaCobro,
                anularEnvio, status_envio, imprimir_e, eliminarFac, eliminarNC, eliminarDep, fact_envio,
                tipo_poliza, numero_poliza, eliminar_e, eliminar_nota, editar_ck, ver_inv_fel, editar_fac_fel,
                anularFac_fel, editar_lfg, ver_lfg, imprimirAlta, imprimirCK, tipo_fact_fel, ver_fac_fel,
                eliminar_insumo, eliminarND, numeroND, vigente_impuesto, editar_imp, id_ck, cuenta_ban_ck,
                factura_fel, asignar_tipo_documento, asignar_tipo_documento_lfg, eliminarNTDev, eliminarAnticipo,
                estado_liq, editar_liq, editar_lfg_liq, ver_liq, ver_lfg_liq } = this.props;
            
        return (
            <div className="d-flex justify-content-center">
                {(asignar_tipo_documento !== undefined) && (
                    <a style={{color: "white"}} href={`/#/${modulo}/${empresa}/${asignar_tipo_documento}/${id}/editar`}><i className="material-icons">perm_contact_calendar</i></a>
                )}
                {(asignar_tipo_documento_lfg !== undefined) && (
                    <a style={{color: "white"}} href={`/#/lfg/${modulo}/${empresa}/${asignar_tipo_documento_lfg}/${id}/editar`}><i className="material-icons">perm_contact_calendar</i></a>
                )}
                {(ver_inv !== undefined && tipo_F === 'B') && (
                    <a style={{color: "white"}} href={`/#/${modulo}/${empresa}/${ver_inv}/${periodo}/${id}/bienes`} className="px-2" ><i className="material-icons">remove_red_eye</i></a>
                )}
                {(editar_inv !== undefined && tipo_F === 'B') && (
                    <a style={{color: "white"}} href={`/#/${modulo}/${empresa}/${editar_inv}/${periodo}/${id}/editar/bienes`}><i className="material-icons">edit</i></a>
                )}
                {(ver_inv !== undefined && tipo_F === 'S') && (
                    <a style={{color: "white"}} href={`/#/${modulo}/${empresa}/${ver_inv}/${periodo}/${id}/servicios`} className="px-2" ><i className="material-icons">remove_red_eye</i></a>
                )}
                {(editar_inv !== undefined && tipo_F === 'S') && (
                    <a style={{color: "white"}} href={`/#/${modulo}/${empresa}/${editar_inv}/${periodo}/${id}/editar/servicios`}><i className="material-icons">edit</i></a>
                )}
                {(ver_fac_fel !== undefined) && (
                    <a style={{color: "white"}} href={`/#/${modulo}/${empresa}/${ver_fac_fel}/${periodo}/${id}`} className="px-2" ><i className="material-icons">remove_red_eye</i></a>
                )}
                {(ver_inv !== undefined && tipo_F === null) && (
                    <a style={{color: "white"}} href={`/#/${modulo}/${empresa}/${ver_inv}/${periodo}/${id}`} className="px-2" ><i className="material-icons">remove_red_eye</i></a>
                )}
                {(ver_inv_fel !== undefined && fact_envio === 'N' && tipo_fact_fel === 'S') && (
                    <a style={{color: "white"}} href={`/#/${modulo}/${empresa}/${ver_inv_fel}/${periodo}/${id}/servicios_fel`} className="px-2" ><i className="material-icons">remove_red_eye</i></a>
                )}
                {(ver_inv_fel !== undefined && fact_envio === 'N' && tipo_fact_fel === 'B') && (
                    <a style={{color: "white"}} href={`/#/${modulo}/${empresa}/${ver_inv_fel}/${periodo}/${id}/bienes_s_envio`} className="px-2" ><i className="material-icons">remove_red_eye</i></a>
                )}
                {(ver_inv_fel !== undefined && fact_envio === 'S' && tipo_fact_fel === 'B') && (
                    <a style={{color: "white"}} href={`/#/${modulo}/${empresa}/${ver_inv_fel}/${periodo}/${id}/bienes_envio`} className="px-2" ><i className="material-icons">remove_red_eye</i></a>
                )}
                {(editar_fac_fel !== undefined) && (
                    <a style={{color: "white"}} href={`/#/${modulo}/${empresa}/${editar_fac_fel}/${periodo}/${id}/editar`}><i className="material-icons">edit</i></a>
                )}
                {(editar_inv !== undefined && tipo_F === null) && (
                    <a style={{color: "white"}} href={`/#/${modulo}/${empresa}/${editar_inv}/${periodo}/${id}/editar`}><i className="material-icons">edit</i></a>
                )}
                {(ver_a !== undefined) && (
                    <a style={{color: "white"}} href={`/#/${modulo}/${empresa}/${ver_a}/${periodo}/${id}/`} className="px-2" ><i className="material-icons">remove_red_eye</i></a>
                )}
                {(ver_lfg_liq !== undefined) && (
                    <a style={{color: "white"}} href={`/#/${modulo}/${empresa}/${ver_lfg_liq}/${id}/`} className="px-2" ><i className="material-icons">remove_red_eye</i></a>
                )}
                {(ver_liq !== undefined) && (
                    <a style={{color: "white"}} href={`/#/${modulo}/${empresa}/${ver_liq}/${id}/`} className="px-2" ><i className="material-icons">remove_red_eye</i></a>
                )}
                {(ver_lfg !== undefined) && (
                    <a style={{color: "white"}} href={`/#/lfg/${modulo}/${empresa}/${ver_lfg}/${periodo}/${id}/`} className="px-2" ><i className="material-icons">remove_red_eye</i></a>
                )}
                {(editar_e !== undefined && tipo_documento === null && status_envio === '**ACTIVO**') && (
                    <a style={{color: "white"}} href={`/#/${modulo}/${empresa}/${editar_e}/${periodo}/${id}/editar`}><i className="material-icons">edit</i></a>
                )}
                {(eliminar_e !== undefined && tipo_documento === null && status_envio === '**ACTIVO**') && (
                    <a className="px-2" style={{cursor: "pointer", color: "white"}} onClick={this.eliminar_e(id, serie_fac, numero_fac)}><i className="material-icons">delete</i></a>
                )}
                {(editar_i !== undefined && val_envio === null) && (
                    <a style={{color: "white"}} href={`/#/${modulo}/${empresa}/${editar_i}/${periodo}/${id}/editar`}><i className="material-icons">edit</i></a>
                )}
                {(editar_imp !== undefined && vigente_impuesto === true) && (
                    <a style={{color: "white"}} href={`/#/${modulo}/${empresa}/${editar_imp}/${periodo}/${id}/editar`}><i className="material-icons">edit</i></a>
                )}
                {(editar_a !== undefined) && (
                    <a style={{color: "white"}} href={`/#/${modulo}/${empresa}/${editar_a}/${periodo}/${id}/editar`}><i className="material-icons">edit</i></a>
                )}
                {(editar_liq !== undefined && estado_liq === 'P') && (
                    <a style={{color: "white"}} href={`/#/${modulo}/${empresa}/${editar_liq}/${id}/editar`}><i className="material-icons">edit</i></a>
                )}
                {(editar_lfg_liq !== undefined && estado_liq === 'P') && (
                    <a style={{color: "white"}} href={`/#/lfg/${modulo}/${empresa}/${editar_lfg_liq}/${periodo}/${id}/editar`}><i className="material-icons">edit</i></a>
                )}
                {(editar_lfg !== undefined) && (
                    <a style={{color: "white"}} href={`/#/lfg/${modulo}/${empresa}/${editar_lfg}/${periodo}/${id}/editar`}><i className="material-icons">edit</i></a>
                )}
                {(editar_ck !== undefined && status_envio === '**ACTIVO**') && (
                    <a style={{color: "white"}} href={`/#/${modulo}/${empresa}/${editar_ck}/${periodo}/${id}/editar`}><i className="material-icons">edit</i></a>
                )}
                {(ver !== undefined) && (
                    <Link style={{color: "white"}} to={`${ver}/${id}/`} className="px-2" ><i className="material-icons">remove_red_eye</i></Link>
                )}
                {(editar !== undefined) && (
                    <Link style={{color: "white"}} to={`${editar}/${id}/editar`} ><i className="material-icons">edit</i></Link>
                )}
                {(eliminarFac !== undefined) && (
                    <a className="px-2" style={{cursor: "pointer", color: "white"}} onClick={this.eliminarFac(id, serie_fac, numero_fac)}><i className="material-icons">delete</i></a>
                )}
                {(eliminar !== undefined) && (
                    <a className="px-2" style={{cursor: "pointer", color: "white"}} onClick={this.eliminar(id)}><i className="material-icons">delete</i></a>
                )}
                {(eliminar_insumo !== undefined) && (
                    <a className="px-2" style={{cursor: "pointer", color: "white"}} onClick={this.eliminar_insumo(id, numero_fac)}><i className="material-icons">delete</i></a>
                )}
                {(eliminarNC !== undefined) && (
                    <a className="px-2" style={{cursor: "pointer", color: "white"}} onClick={this.eliminarNC(id, numero_fac)}><i className="material-icons">delete</i></a>
                )}
                {(eliminarND !== undefined) && (
                    <a className="px-2" style={{cursor: "pointer", color: "white"}} onClick={this.eliminarND(id, numeroND)}><i className="material-icons">delete</i></a>
                )}
                 {(eliminarAnticipo !== undefined) && (
                    <a className="px-2" style={{cursor: "pointer", color: "white"}} onClick={this.eliminarAnticipo(id, serie_fac, numero_fac)}><i className="material-icons">delete</i></a>
                )}
                {(eliminarNTDev !== undefined && tipo_documento === null ) && (
                    <a className="px-2" style={{cursor: "pointer", color: "white"}} onClick={this.eliminarNTDev(id, serie_fac, numero_fac)}><i className="material-icons">delete</i></a>
                )}
                {(eliminarDep !== undefined) && (
                    <a className="px-2" style={{cursor: "pointer", color: "white"}} onClick={this.eliminarDep(id, numero_fac)}><i className="material-icons">delete</i></a>
                )}
                {(editar_pol_a !== undefined && tipo_pol === 'CNT') && (
                    <a style={{color: "white"}} href={`/#/${modulo}/${empresa}/${editar_pol_a}/${periodo}/${id}/editar`}><i className="material-icons">edit</i></a>
                )}
                {(eliminar_pol_a !== undefined && tipo_pol === 'CNT') && (
                    <a className="px-2" style={{cursor: "pointer", color: "white"}} onClick={this.eliminar_pol_a(id, tipo_poliza, numero_poliza)}><i className="material-icons">delete</i></a>
                )}
                {(eliminar_nota !== undefined) && (
                    <a className="px-2" style={{cursor: "pointer", color: "white"}} onClick={this.eliminar_nota(id, serie_fac, numero_fac)}><i className="material-icons">delete</i></a>
                )}
                {(imprimirCK !== undefined && status_envio === '**ACTIVO**') && (
                    <a className="px-2" style={{cursor: "pointer", color: "white"}} onClick={this.imprimirCK(id)}><i className="material-icons">print</i></a>
                )}
                {(imprimir !== undefined) && (
                    <a className="px-2" style={{cursor: "pointer", color: "white"}} onClick={this.imprimir(id)}><i className="material-icons">print</i></a>
                )}
                {(imprimirAlta !== undefined) && (
                    <a className="px-2" style={{cursor: "pointer", color: "white"}} onClick={this.imprimirAlta(id)}><i className="material-icons">print</i></a>
                )}
                {(imprimir_e !== undefined && status_envio === '**ACTIVO**') && (
                    <a className="px-2" style={{cursor: "pointer", color: "white"}} onClick={this.imprimir_e(id)}><i className="material-icons">print</i></a>
                )}
                {(excel !== undefined) && (
                    <a className="px-2" style={{cursor: "pointer", color: "white"}} onClick={this.excel(id)}><FaIcons.FaFileExcel/></a>
                )}
                {(anularCK !== undefined && status_envio === '**ACTIVO**') && (
                    <a className="px-2" style={{cursor: "pointer", color: "red"}} onClick={this.anularCK(id, id_ck, cuenta_ban_ck)}><i className="material-icons">cancel</i></a>
                )}
                {(anularFac !== undefined) && (
                    <a className="px-2" style={{cursor: "pointer", color: "red"}} onClick={this.anularFac(id, serie_fac, numero_fac)}><i className="material-icons">cancel</i></a>
                )}
                {(anularFac_fel !== undefined && factura_fel) && (
                    <a className="px-2" style={{cursor: "pointer", color: "red"}} onClick={this.anularFac_fel(id, serie_fac, numero_fac)}><i className="material-icons">cancel</i></a>
                )}
                {(anularNotaCobro !== undefined) && (
                    <a className="px-2" style={{cursor: "pointer", color: "red"}} onClick={this.anularNotaCobro(id, serie_fac, numero_fac)}><i className="material-icons">cancel</i></a>
                )}
                 {(anularEnvio !== undefined && tipo_documento === null && status_envio === '**ACTIVO**') && (
                    <a className="px-2" style={{cursor: "pointer", color: "red"}} onClick={this.anularEnvio(id, serie_fac, numero_fac)}><i className="material-icons">cancel</i></a>
                )}
            </div>
        );
    }
}
Acciones.propTypes = {
};

export function standardActions(acciones) {
    return (cell, row) => {
        
        return ( <Acciones id={cell} tipo_pol={row.modulo} tipo_fact_fel={row.tipo}
            tipo_F={row.tipo} serie_fac={row.serie} numero_fac={row.numero} numeroND={row.numero_cheque}
            val_envio={row.envio} tipo_documento={row.tipo_documento} fact_envio={row.fac_envio}
            status_envio={row.estatus} tipo_poliza={row.tipo} numero_poliza={row.numero_poliza}
            vigente_impuesto={row.vigente} id_ck={row.id} cuenta_ban_ck={row.cuenta_bancaria}
            factura_fel={row.numero_autorizacion} estado_liq={row.estado}
            {...acciones}/> )
    };
}
