import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { renderField } from '../../Utils/renderField';
import Lista from "../../../components/components/ListaErrores";
import { validar } from "../../../components/components/ValidarPass";
const estilo = {
    color: "white",
};
const estilo2 = {
    fontSize: "14px",
};

function CambiarPasswordIntForm(props) {
    const { handleSubmit } = props;
    const [error, setError] = useState('');
    const divError = {
        color: 'red',
    };
    return (
        <form className="form-validate mb-lg">
 
            <div className='row mb-2'>
                <div className='col-6'>
                    <Field
                        name="password"
                        placeholder="Contraseña"
                        component={renderField}
                        type="password"
                        onChange={(e, value) => {
                            if (value) {
                                let valor = validar.validarPassword(value);
                                if (valor.length > 0) {
                                    setError(valor);
                                }
                                if (valor === true){
                                    setError('');
                                }
                            }
                        }}
                        className="form-control"
                        msj="Contraseña"
                    />
                </div>
                <div className='col-6'>
                    <Field
                        name="confirmPassword"
                        placeholder="Confirmar Contraseña"
                        component={renderField}
                        type="password"
                        className="form-control"
                        msj="Confirmar Contraseña"
                    />
                </div>
            </div>
            {error &&
                <div className='col-12'>
                    <div style={divError}>
                        <Lista 
                            items = {error}
                        />
                    </div>
                </div>
            }
            <div className='mb-2'>
                <h5 style={estilo}>La contraseña debe cumplir los siguientes requerimientos:</h5>
                <div className="row">
                    <div className="col-6">
                        <ul>
                            <li style={estilo}>Al menos <strong>una letra minúscula</strong></li>
                            <li style={estilo}>Al menos <strong>una letra mayúscula</strong></li>
                            <li style={estilo}>Al menos <strong>un número</strong></li>
                        </ul>
                    </div>
                    <div className="col-6">
                        <ul>
                            <li style={estilo}>Al menos <strong>un carácter especial</strong></li>
                            <li style={estilo}>Mínimo <strong>8 carácteres</strong></li>
                        </ul>
                    </div>
                </div>
                
            </div>
            <div className="buttons-box">
                <button type="submit" style={estilo2} onClick={ handleSubmit }
                    className="btn btn-primary m-1 align-self-center">Cambiar Contraseña</button>
            </div>
        </form>
    );
};

export const matchPassword = (pass, confirm) => validatorFromFunction(value => {
    return pass === confirm;
});

export default reduxForm({
    form: 'CambiarPassFormInt', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            confirmPassword: combine(
                validators.exists()('Este campo es requerido'),
                matchPassword(data.password, data.confirmPassword)()('Las contraseñas no coinciden')
            ),
            password: validators.exists()('Este campo es requerido'),
        });
    },
})(CambiarPasswordIntForm);
