import { handleActions } from 'redux-actions';
import { NotificationManager } from "react-notifications";
import { initialize as initializeForm } from 'redux-form';
import { api } from "api";
import { push } from "react-router-redux";
import Swal from 'sweetalert2';
const valEmp = __CONFIG__.empresa;

const PERIODOS = 'PERIODOS';
const LISTADO_FRECUENCIAS = 'LISTADO_FRECUENCIAS';
const LOADER = 'LOADER';
const MODULO = 'MODULO';
const PERIODO_D = 'PERIODO_D';
const PERIODO_N = 'PERIODO_N';
const NUMERO_PERIODO = 'NUMERO_PERIODO';
const PERIODO_NOMINA = 'PERIODO_NOMINA';
const LECTURA = 'LECTURA';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params;
    let id_periodo = '';
    let id = ''
    if (valEmp === 'fage'){
        id = id_emp[6];
        id_periodo = id_emp[8];
        params = { id, id_periodo };
    }else{
        id = id_emp[5];
        id_periodo = id_emp[7];
        params = { id, id_periodo };
    }
    dispatch(setLoader(true));
    api.get('/periodos_nomina/periodo_empresa', params).then((response)=>{
        dispatch({ type: PERIODOS, data: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar periodos',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const registroPeriodo = (periodo, numero) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id_empresa = '';
    let id_periodo = '';
    if (valEmp === 'fage'){
        id_empresa = id_emp[6]
        id_periodo = id_emp[9]
    }else{
        id_empresa = id_emp[5]
        id_periodo = id_emp[8]
    }
    const data = getStore().form.FormPeriodosNomina.values;
    if (data === undefined) {
        Swal.fire({
            title: 'Periodo',
            text: 'Debe de seleccionar frecuencia de pago',
            type: 'error',
        })
    } else {
        const formData = {
            frecuencia: data.frecuencia,
            fecha_fin: data.fecha_fin,
            fecha_inicio: data.fecha_inicial,
            empresa: id_empresa,
            periodo_nomina: periodo,
            id_periodo: id_periodo,
            numero: numero,
            bon: data.bonificacion_comision
        }
        dispatch(setLoader(true));
        api.post('/periodos_nomina/', formData).then(() => {
            NotificationManager.success(
                'Periodo creado',
                'Exito',
                3000
            );
            if (valEmp === 'fage'){
                let ruta = `/lfg/nomina/${id_emp[6]}/periodo_nomina/${id_emp[9]}`;
                dispatch(push(ruta));
            }else{
                let ruta = `/nomina/${id_emp[5]}/periodo_nomina/${id_emp[8]}`;
                dispatch(push(ruta));
            }
        }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                2000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
}

export const modificarPeriodo = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const datos = getStore().form.FormPeriodosNomina.values;
    
    const id = datos.id;
    const formData = {
        id: id,
        fecha_inicial: datos.fecha_inicial,
        fecha_fin: datos.fecha_fin,
        bonificacion: datos.bonficacion_comision,
        frecuencia_pago: datos.frecuencia ? datos.frecuencia : datos.frecuencia_pago,
    }
    dispatch(setLoader(true));
    api.put(`/periodos_nomina/${id}/`, formData).then(() => {
        NotificationManager.success(
            'Periodo modificado correctamente',
            'Exito',
            3000
        );
        if (valEmp === 'fage'){
            let ruta = `/lfg/nomina/${id_emp[6]}/periodo_nomina/${id_emp[8]}`;
            dispatch(push(ruta));
        }else{
            let ruta = `/nomina/${id_emp[5]}/periodo_nomina/${id_emp[7]}`;
            dispatch(push(ruta));
        }
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al modificar periodo de nomina',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        if (error.status === 401){
            NotificationManager.error(
              error.body.msj,
              'Error',
              0
            );
        }
        if (error.status === 400){
            NotificationManager.error(
              'Ocurrió un error en buscar modulo',
              'Error',
              0
            );
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const listarFrecuencia = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params;
    let id = ''
    if (valEmp === 'fage'){
        id = id_emp[6];
        params = { id };
    }else{
        id = id_emp[5];
        params = { id };
    }
    api.get('/frecuencia_pago', params).then((response) => {
        const frecuencia = [];
        response.forEach(item => {
            frecuencia.push({
                value: item.id,
                label: item.descripcion,
            })
        })
        dispatch({ type: LISTADO_FRECUENCIAS, frec: frecuencia });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar frecuencias',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leerPeriodoD = (id, id_periodo) => (dispatch) => {
    const params = { id, id_periodo };
    api.get('/periodos/periodo', params).then((response) => {
        dispatch({ type: PERIODO_D, periodoD: response.periodo });
        let per = response.periodo.fecha_inicio.split('-');
        let periodoNN = per[0] + '-' + per[1];
        dispatch({ type: PERIODO_N, periodoN: periodoNN });
        const paramsN = { id, periodoNN };
        api.get('/periodos_nomina/numero_periodo/', paramsN).then((response) => {
            let numero = parseInt(response.numero)+1;
            dispatch({ type: NUMERO_PERIODO, numeroPer: numero });
        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al consultar correlativo',
                'Error',
                2000
            );
        })
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar periodo',
            'Error',
            2000
        );
    })
}

export const listarPeriodos = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    
    let params;
    let id_periodo = '';
    let id = ''
    if (valEmp === 'fage'){
        id = id_emp[6];
        id_periodo = id_emp[8];
        params = { id, id_periodo };
    }else{
        id = id_emp[5];
        id_periodo = id_emp[7];
        params = { id, id_periodo };
    }
    dispatch(setLoader(true));
    api.get('/periodos_nomina/periodo_empresa_nomina', params).then((response)=>{
        dispatch({ type: PERIODO_NOMINA, periodos_nomina: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar periodos',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/periodos_nomina/${id}`).then((response) => {
        response.fecha_inicial = response.fecha_inicio;
        dispatch({ type: LECTURA, lectura: response });
        dispatch(initializeForm('FormPeriodosNomina', response ));
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar el periodo',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const listarPeriodosOtros = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    
    let params;
    let id_periodo = '';
    let id = ''
    if (valEmp === 'fage'){
        id = id_emp[6];
        id_periodo = id_emp[8];
        params = { id, id_periodo };
    }else{
        id = id_emp[5];
        id_periodo = id_emp[7];
        params = { id, id_periodo };
    }
    dispatch(setLoader(true));
    api.get('/periodos_nomina/periodo_empresa_nomina_otros', params).then((response)=>{
        dispatch({ type: PERIODO_NOMINA, periodos_nomina: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar periodos',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const actions = {
    listar,
    getModulo,
    listarFrecuencia,
    leerPeriodoD,
    registroPeriodo,
    listarPeriodos,
    leer,
    modificarPeriodo,
    listarPeriodosOtros
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [PERIODOS]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [LISTADO_FRECUENCIAS]: (state, { frec }) => {
        return {
            ...state,
            frec,
        };
    },
    [PERIODO_D]: (state, { periodoD }) => {
        return {
            ...state,
            periodoD,
        };
    },
    [PERIODO_N]: (state, { periodoN }) => {
        return {
            ...state,
            periodoN,
        };
    },
    [NUMERO_PERIODO]: (state, { numeroPer }) => {
        return {
            ...state,
            numeroPer,
        };
    },
    [PERIODO_NOMINA]: (state, { periodos_nomina }) => {
        return {
            ...state,
            periodos_nomina,
        };
    },
    [LECTURA]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
};

export const initialState = {
    loader: false,
    modulo: [],
    data: [],
    frec: [],
    periodoD: [],
    periodoN: [],
    numeroPer: 0,
    periodos_nomina: [],
    lectura: [],
};

export default handleActions(reducers, initialState)