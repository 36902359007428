import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import NavbarModulo from '../components/NavbarModulo';

import {
  renderCurrencyFloatTC,
  renderDayPickerB,
} from "../Utils/renderField/renderField";

function TipoCambioForm(props) {
  const { registroTC, modificarTC, crear, modulo, periodo } = props;
  const editar = window.location.href.includes('editar');
  let titulo = editar ? 'Modificar tipo de cambio' : 'Crear tipo de cambio';
  let disabled = false;
  if (crear === false && editar === false) {
    disabled = true;
    titulo = 'Ver tipo de cambio'
  }
  let autoFocus = true;
  let ruta = window.location.href;
  let id_emp = ruta.split('/');
  
  return (
    <div>
      <NavbarModulo
        modulo={modulo}
        titulo={titulo}
        periodoD={periodo}
      />
      <br />
        <div className="mb-4 card card-small text-white bg-dark mb-3">
          <div className="p-0 pt-3 d-flex flex-column flex-md-row">
            <div className="d-flex flex-column flex-1 mx-4">
              <div className='row mb-2'>
                { crear === true &&
                  <div className='col-3'>
                    <Field
                      autoFocus={autoFocus}
                      disabled={disabled}
                      name="fecha"
                      component={renderDayPickerB}
                      msj="Fecha Registro"
                    />
                  </div>
                }
                {editar === true &&
                  <div className='col-3'>
                    <Field
                      autoFocus={autoFocus}
                      disabled={true}
                      name="fecha"
                      component={renderDayPickerB}
                      msj="Fecha Registro"
                    />
                  </div>
                }
                <div className='col-3'>
                  <Field
                    disabled={disabled}
                    name="tipo_cambio"
                    component={renderCurrencyFloatTC}
                    msj="Tipo de cambio"
                  />
                </div>
              </div>

              <div className='d-flex flex-row mt-3'>
                {crear === false &&
                  <button
                    className='btn mr-2 mb-3 btn-primary'
                    type='submit'
                    onClick={modificarTC}
                  >
                    {editar ? 'Modificar' : 'Guardar'}
                  </button>
                }
                {crear === true &&
                  <button
                    className='btn mr-2 mb-3 btn-primary'
                    onClick={() => registroTC(periodo)}
                  >
                    {editar ? 'Modificar' : 'Guardar'}
                  </button>
                }
                { id_emp[4] === 'compras' &&
                  <a
                    href={`/#/compras/${id_emp[5]}/tipo_cambio/${id_emp[7]}`}
                    className='btn btn-secondary mb-3'
                  >
                    Regresar
                  </a>
                }
                { id_emp[4] === 'facturacion' &&
                  <a
                    href={`/#/facturacion/${id_emp[5]}/tipo_cambio/${id_emp[7]}`}
                    className='btn btn-secondary mb-3'
                  >
                    Regresar
                  </a>
                }
              </div>
            </div>
          </div>
        </div>
    </div>
  );
}

TipoCambioForm.propTypes = {
  registroTc: PropTypes.func,
  modificarTc: PropTypes.func,
  crear: PropTypes.bool,
  modulo: PropTypes.object,
  periodo: PropTypes.object,
};

export default reduxForm({
  form: 'FormTipoCambio',
})(TipoCambioForm);
