import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';
const valEmp = __CONFIG__.empresa;

const LISTADO_DESCUENTOS = 'LISTADO_DESCUENTOS';
const PERIODO_NOMINA = 'PERIODO_NOMINA';
const PERIODOS_LIST = 'PERIODOS_LIST';
const PERIODO_D = 'PERIODO_D';
const PERIODO_N = 'PERIODO_N';
const CORRELATIVO = 'CORRELATIVO';
const PERIODO_ID = 'PERIODO_ID';
const COLABORADOR = 'COLABORADOR';
const HORA_EXTRA = 'HORA_EXTRA';
const LECTURA = 'LECTURA';
const LOADER = 'LOADER';
const MODULO = 'MODULO';
const LISTADO_TIPOS_DESC = 'LISTADO_TIPOS_DESC';
const ESTADO = 'ESTADO';
const DATOS_PERIODO = 'DATOS_PERIODO';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params;
    let empresa = '';
    let periodo = '';
    let reporte = 'listarDescuentos';
    if (valEmp === 'fage'){
        empresa = id_emp[6];
        periodo = id_emp[8];
        params = { empresa, periodo, reporte };
    }else{
        empresa = id_emp[5];
        periodo = id_emp[7];
        params = { empresa, periodo, reporte };
    }
    dispatch(setLoader(true));
    api.get('/descuento_nomina/', params).then((response)=>{
        dispatch({ type: DATOS_PERIODO, datos_per: response.datos_periodo.periodo_nomina + ' -- ' + response.datos_periodo.numero });
        dispatch({ type: ESTADO, estado_periodo: response.datos_periodo.estado });
        dispatch({ type: LISTADO_DESCUENTOS, data: response.datos });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar horas extras',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const listarPeriodos = (id_periodo) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    
    let params;
    let id = ''
    if (valEmp === 'fage'){
        id = id_emp[6];
        params = { id, id_periodo };
    }else{
        id = id_emp[5];
        params = { id, id_periodo };
    }
    dispatch(setLoader(true));
    api.get('/periodos_nomina/periodo_empresa_nomina_datos_desc', params).then((response)=>{
        dispatch({ type: PERIODO_NOMINA, periodos_nomina: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar periodos',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const periodosList = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = ''
    if (valEmp === 'fage'){
        id = id_emp[6];
    }else{
        id = id_emp[5];
    }
    return api.get(`/periodos/periodo_empresa/?id=${id}`).then((data)=>{
        const periodo = [];
        data.forEach(item=>{
            periodo.push({
                value: item.id,
                label: item.periodo
            })
        })
        dispatch({ type: PERIODOS_LIST, periodoL: periodo });
    }).catch(error=>{
        NotificationManager.error(
            'Ocurrió un error al listar los periodos',
            'Error',
            0
        );
    })
}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = { id, modulo }
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response) => {
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        if (error.status === 401) {
            NotificationManager.error(
                error.body.msj,
                'Error',
                0
            );
        }
        if (error.status === 400) {
            NotificationManager.error(
                'Ocurrió un error en buscar modulo',
                'Error',
                0
            );
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const leerPeriodoD = (id, id_periodo) => (dispatch) => {
    const params = { id, id_periodo };
    api.get('/periodos/periodo', params).then((response) => {
        dispatch({ type: PERIODO_D, periodoD: response.periodo });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar periodo',
            'Error',
            2000
        );
    })
}

export const leerPeriodoN = (id, id_periodo) => (dispatch) => {
    const params = { id, id_periodo };
    api.get('/periodos_nomina/periodo_empresa_informacion', params).then((response) => {
        dispatch(leerPeriodoD(id, response.periodo));
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar periodo',
            'Error',
            2000
        );
    })
}

export const leerPeriodoNomina = (id, id_periodo) => (dispatch) => {
    const params = { id, id_periodo };
    api.get('/nomina/periodo', params).then((response) => {
        dispatch({ type: PERIODO_N, periodoN: response.periodo_nomina });
        dispatch({ type: CORRELATIVO, correlativo: response.numero });
        dispatch({ type: PERIODO_ID, periodoId: response.id });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar periodo',
            'Error',
            2000
        );
    })
}

export const listarColaborador = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params;
    let periodo = ''
    if (valEmp === 'fage') {
        periodo = id_emp[8];
        params = { periodo };
    } else {
        periodo = id_emp[7];
        params = { periodo };
    }
    api.get('/nomina/colaborador_planilla', params).then(data => {
        const lista = [];
        if (data) {
            data.forEach(item => {
                lista.push({
                    value: item.idColaborador,
                    label: item.codigo + ' - ' + item.nombre
                })
            })
        }
        dispatch({ type: COLABORADOR, emp: lista })
    }).catch((error) => {
        return [];
    })
}


export const datosColaborador = (emp) => (dispatch) => {
    let params = {
        id_colaborador: emp,
    }
    api.get('/colaborador/empresa_colaborador_hora_extra_nomina', params).then((response) => {
        dispatch({ type: HORA_EXTRA, hora_extra: response.hora_extra })
    }).catch((error) => {
        return [];
    })
}

export const registroDescuento = (periodoId) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id_empresa = '';
    if (valEmp === 'fage') {
        id_empresa = id_emp[6]
    } else {
        id_empresa = id_emp[5]
    }
    const data = getStore().form.FormDescuentosNomina.values;
    let tipo = data.tipo ? data.tipo : data.tipo_descuento;
    
    if (data === undefined) {
        Swal.fire({
            title: 'Descuento',
            text: 'Debe de seleccionar colaborador',
            type: 'error',
        })
    } else {
        if (tipo === undefined || tipo === '') {
            Swal.fire({
                title: 'Descuento',
                text: 'Debe de seleccionar tipo de descuento',
                type: 'error',
            })
        } else {
            if (data.monto === undefined || parseFloat(data.tipo_descuento) === 0.00) {
                Swal.fire({
                    title: 'Descuento',
                    text: 'Debe de ingresar monto',
                    type: 'error',
                })
            } else {
                const formData = {
                    colaborador: data.colaborador_descuento.value,
                    tipo_descuento: tipo,
                    monto: data.monto,
                    descripcion: data.descripcion,
                    empresa: id_empresa,
                    periodo: periodoId,
                }
                dispatch(setLoader(true));
                api.post('/descuento_nomina/', formData).then((response) => {
                    NotificationManager.success(
                        'Descuento creado',
                        'Exito',
                        3000
                    );
                    
                    response.monto = 0.00;
                    response.descripcion = '';
                    dispatch(initializeForm('FormDescuentosNomina', response));
                }).catch((error) => {
                    NotificationManager.error(
                        error.msj,
                        'Error',
                        2000
                    );
                }).finally(() => {
                    dispatch(setLoader(false));
                });
            }
        }
    }
}

export const modificarDescuento = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    
    const datos = getStore().form.FormDescuentosNomina.values;
    
    let tipo = datos.tipo ? datos.tipo : datos.tipo_descuento;
    const id = datos.id;
    if (datos === undefined) {
        Swal.fire({
            title: 'Descuento',
            text: 'Debe de seleccionar colaborador',
            type: 'error',
        })
    } else {
        if (tipo === undefined || tipo === '') {
            Swal.fire({
                title: 'Descuento',
                text: 'Debe de seleccionar tipo de descuento',
                type: 'error',
            })
        } else {
            if (datos.monto === undefined || parseFloat(datos.monto) === 0.00) {
                Swal.fire({
                    title: 'Descuento',
                    text: 'Debe de ingresar monto',
                    type: 'error',
                })
            } else {
                const formData = {
                    id: datos.id,
                    monto: datos.monto,
                    tipo_descuento: tipo,
                    descripcion: datos.descripcion,
                }
            
                dispatch(setLoader(true));
                api.put(`/descuento_nomina/${id}/`, formData).then(() => {
                    NotificationManager.success(
                        'Descuento modificado',
                        'Exito',
                        3000
                    );
                    if (valEmp === 'fage'){
                        let ruta = `/lfg/nomina/${id_emp[6]}/descuentos/${id_emp[8]}`
                        dispatch(push(ruta));
                    }
                    if (valEmp === ''){
                        let ruta = `/nomina/${id_emp[5]}/descuentos/${id_emp[7]}`
                        dispatch(push(ruta));
                    }
                }).catch((error) => {
                    NotificationManager.error(
                        error.msj,
                        'Error',
                        2000
                    );
                }).finally(() => {
                    dispatch(setLoader(false));
                });
            }
        }
    }
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/descuento_nomina/${id}`).then((response) => {
        dispatch({ type: LECTURA, lectura: response });
        dispatch(initializeForm('FormDescuentosNomina', response ));
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar descuento',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

const eliminarDescuento = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`descuento_nomina/${id}`).then(() => {
        dispatch(listar());
        NotificationManager.success(
            'Descuento borrado correctamente',
            'Exito',
            3000
        );
    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const listarTiposDesc = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params;
    let id = '';
    if (valEmp === 'fage') {
        id = id_emp[6];
        params = { id };
    } else {
        id = id_emp[5];
        params = { id };
    }
    api.get('/tipo_descuento', params).then((response) => {
        const tiposD = [];
        response.forEach(item => {
            tiposD.push({
                value: item.id,
                label: item.descripcion,
            })
        })
        dispatch({ type: LISTADO_TIPOS_DESC, tipos: tiposD });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar tipos de descuento',
            'Error',
            0
        );
    });
}

export const actions = {
    listar,
    leer,
    listarPeriodos,
    periodosList,
    getModulo,
    leerPeriodoD,
    leerPeriodoN,
    leerPeriodoNomina,
    listarColaborador,
    datosColaborador,
    registroDescuento,
    modificarDescuento,
    eliminarDescuento,
    listarTiposDesc
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [LISTADO_DESCUENTOS]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [PERIODO_NOMINA]: (state, { periodos_nomina }) => {
        return {
            ...state,
            periodos_nomina,
        };
    },
    [PERIODOS_LIST]: (state, { periodoL }) => {
        return {
            ...state,
            periodoL,
        };
    },
    [PERIODO_D]: (state, { periodoD }) => {
        return {
            ...state,
            periodoD,
        };
    },
    [PERIODO_N]: (state, { periodoN }) => {
        return {
            ...state,
            periodoN,
        };
    },
    [CORRELATIVO]: (state, { correlativo }) => {
        return {
            ...state,
            correlativo,
        };
    },
    [PERIODO_ID]: (state, { periodoId }) => {
        return {
            ...state,
            periodoId,
        };
    },
    [COLABORADOR]: (state, { emp }) => {
        return {
            ...state,
            emp,
        };
    },
    [HORA_EXTRA]: (state, { hora_extra }) => {
        return {
            ...state,
            hora_extra,
        };
    },
    [LECTURA]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
    [LISTADO_TIPOS_DESC]: (state, { tipos }) => {
        return {
            ...state,
            tipos,
        };
    },
    [ESTADO]: (state, { estado_periodo }) => {
        return {
            ...state,
            estado_periodo,
        };
    },
    [DATOS_PERIODO]: (state, { datos_per }) => {
        return {
            ...state,
            datos_per,
        };
    },
};

export const initialState = {
    loader: false,
    modulo: [],
    data: [],
    periodos_nomina: [],
    periodoL: [],
    periodoD: [],
    periodoN: [],
    correlativo: [],
    periodoId: [],
    emp: [],
    hora_extra: [],
    lectura: [],
    tipos: [],
    estado_periodo: [],
    datos_per: [],
};

export default handleActions(reducers, initialState)