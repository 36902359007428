import { handleActions } from 'redux-actions';
import { NotificationManager } from "react-notifications";
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { api } from "api";
import Swal from 'sweetalert2';

const LOADER = 'LOADER';
const MODULO = 'MODULO';
const PERIODO_D = 'PERIODO_D';
const LISTADO = 'LISTADO';
const DETALLE_DOCUMENTOS = 'DETALLE_DOCUMENTOS';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listarColaborador = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let params = { id, search };
    return api.get('/visitador_medico/visitador/buscar_colaborador', params).then(data => {
        const lista = [];
        if (data) {
            data.forEach(item => {
                lista.push({
                    value: item.id,
                    label: item.codigo+' - ' + item.nombre
                })
            })
        }
        return lista;
    }).catch((error) => {
        return [];
    })
}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const leerPeriodoD = (id, id_periodo) => (dispatch) => {
    const params = { id, id_periodo };
    api.get('/periodos/periodo', params).then((response) => {
        dispatch({ type: PERIODO_D, periodoD: response.periodo });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar periodo',
            'Error',
            2000
        );
    })
}

export const subirLiquidaciones = (data={},attachments=[]) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');

    if (data === undefined) {
        Swal.fire({
            title: 'Liquidación',
            text: 'Debe de seleccionar visitador médico',
            type: 'error',
        });
    }else{
        if (data.colaborador === undefined || data.colaborador === null) {
            Swal.fire({
                title: 'Liquidación',
                text: 'Debe de seleccionar visitador médico',
                type: 'error',
            });
        }else{
            if (data.descripcion === undefined || data.descripcion === null) {
                Swal.fire({
                    title: 'Liquidación',
                    text: 'Debe de ingresar descripción',
                    type: 'error',
                });
            }else{
                if (attachments[0].file === null) {
                    Swal.fire({
                        title: 'Liquidación',
                        text: 'Debe de seleccionar archivo',
                        type: 'error',
                    });
                }else{
                    const formData = {
                        colaborador: data.colaborador.value,
                        descripcion: data.descripcion,
                        empresa: id_emp[5],
                        periodo: id_emp[7],
                    }
                    dispatch(setLoader(true));
                    api.postAttachments('/visitador_medico/liquidacion/liquidaciones_excel/', formData, attachments).then((response) => {
                        let nombre = response.visitador;
                        if (parseInt(response.errores) >= 1) {
                            NotificationManager.error(
                                response.msj,
                                'Error',
                                0
                            );
                            api.getExcel('/visitador_medico/liquidacion/generar_excel/', formData).then((res) => {
                                const blob = res;
                                const fileName = nombre + '-' + 'errores' + '.xlsx';

                                // Descargar el archivo utilizando la función saveAs de file-saver
                                const file = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                                saveAs(file, fileName);
                                window.location.reload();
                            }).catch(error => {
                                if (error === 404) {
                                    NotificationManager.error(
                                        "No se encontraron datos",
                                        'Informacion',
                                        5000
                                    );
                                }
                                if (error === 500) {
                                    NotificationManager.error(
                                        'Ocurrió un error al generar reporte',
                                        'Error',
                                        0
                                    );
                                }
                            })
                        }else{
                            NotificationManager.success(
                                response.msj,
                                'Exito',
                                8000
                            );
                            let ruta = `/visitador_medico/${id_emp[5]}/liquidaciones/${id_emp[7]}`;
                            dispatch(push(ruta));
                        }

                    }).catch((error) => {
                        NotificationManager.error(
                            error.msj,
                            'Error',
                            6000
                        );
                    }).finally(() => {
                        dispatch(setLoader(false));
                    });
                }
            }
        }
    }
}

export const listar = (periodo) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let params = { id, periodo };
    return api.get('/visitador_medico/liquidacion', params).then((response)=>{
        dispatch({type: LISTADO, data: response })
    }).catch(error=>{
        NotificationManager.error(
            'Ocurrió un error al listar liquidaciones',
            'Error',
            0
        );
    })
}

const leerDetalleDocumentos = id => (dispatch) => {
    api.get(`/visitador_medico/liquidacion_detalle/${id}`).then((response) => {
        dispatch({ type: DETALLE_DOCUMENTOS, detalleDoc: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/visitador_medico/liquidacion/${id}`).then((response) => {
        dispatch(leerDetalleDocumentos(response.id));
        dispatch(initializeForm('FormLiquidacionDetalle', response ));
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar liquidación',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

const eliminarLiquidacion = id => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    dispatch(setLoader(true));
    api.eliminar(`/visitador_medico/liquidacion/${id}`).then(() => {
        dispatch(listar(id_emp[7]));
        NotificationManager.success(
            'Liquidacion borrada correctamente',
            'Exito',
            3000
        );
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar liquidacion',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const actions = {
    listar,
    listarColaborador,
    getModulo,
    leerPeriodoD,
    subirLiquidaciones,
    leer,
    eliminarLiquidacion
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [PERIODO_D]: (state, { periodoD }) => {
        return {
            ...state,
            periodoD,
        };
    },
    [LISTADO]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [DETALLE_DOCUMENTOS]: (state, { detalleDoc }) => {
        return {
            ...state,
            detalleDoc,
        };
    },
};

export const initialState = {
    loader: false,
    modulo: [],
    periodoD: {},
    data: [],
    detalleDoc: []
};

export default handleActions(reducers, initialState)
 