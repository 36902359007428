import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
    SelectField,
    renderField
} from "../../Utils/renderField/renderField";

function PromocionPuestoForm(props) {
    const { listaDepartamento, listaPuesto, cargo,
        depto, puesto_N, depto_N } = props;

    return (
        <div>
            <div className='mb-2'>
                <div className='row mb-2'>
                    <div className='col-6'>
                        <div className="form-floating">
                            <input
                                className={classNames('form-control')}
                                type="text"
                                value={depto_N}
                                disabled={true}
                            />
                            <label className='form-label'>Departamento actual</label>
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className="form-floating">
                            <input
                                className={classNames('form-control')}
                                type="text"
                                value={puesto_N}
                                disabled={true}
                            />
                            <label className='form-label'>Cargo actual</label>
                        </div>
                    </div>
                </div>
                <div className='row mb-2'>
                    <div className='col-3'>
                        <Field
                            name="departamento_trabajo"
                            options={listaDepartamento}
                            dato={depto}
                            component={SelectField}
                            className="form-select"
                            msj="Departamento"
                        />
                    </div>
                    <div className='col-3'>
                        <Field
                            name="puesto"
                            options={listaPuesto}
                            dato={cargo}
                            component={SelectField}
                            className="form-select"
                             msj="Puesto"
                        />
                    </div>
                    <div className='col-3'>
                        <Field
                            name="jefe"
                            placeholder="Jefe inmediato"
                            className="form-control"
                            component={renderField}
                            msj="Jefe inmediato"
                        />
                    </div>
                    <div className='col-3'>
                        <Field
                            name="lugarTrabajo"
                            placeholder="Lugar de trabajo"
                            className="form-control"
                            component={renderField}
                            msj="Lugar de trabajo"
                        />
                    </div>
                </div>
                <div className='row mb-2'>
                    <div className='col-3'>
                        <Field
                            name="horarioTrabajo"
                            placeholder="Horario de trabajo"
                            className="form-control"
                            component={renderField}
                            msj="Horario de trabajo"
                        />
                    </div>
                </div>
            </div>
        </div>

    );
}

PromocionPuestoForm.propTypes = {
    handleSubmit: PropTypes.func,
    crear: PropTypes.bool,
    lectura: PropTypes.object,
    modulo: PropTypes.object
};

export default reduxForm({
    form: 'FormCambioPuestoDetalleColaborador',
})(PromocionPuestoForm);
