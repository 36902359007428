import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import DetalleOrden from './DetalleOrdenCompraVer';
import DetalleInventario from './DetalleInventarioVer';
import NavbarModulo from '../../components/NavbarModulo';

import {
    renderField,
    renderDayPickerB,
    AsyncSelectField,
    renderNumber,
    SelectField
} from "../../Utils/renderField/renderField";
import propTypes from 'prop-types';

function IngresoInventarioForm(props) {
    const {
        crear,
        editar,
        periodoD,
        modulo,
        registroIngreso,
        listarOrdenes,
        leerDetalleOrden,
        detalleOrd,
        prov,
        titulo,
        listaBodegas,
        detalleProducto
    } = props;

    let autoFocus = true;
    
   
    const estilo = {
        overflow: "auto",
        height: "300px",
    };
    let ruta = window.location.href;
    let id_empresa = ruta.split('/');
    
    let disabled = false;
    if (crear === false && editar === false) {
        disabled = true;
    }
    
    const handleOnChange = value => {
        leerDetalleOrden(value.value);
    };
    
    return (
        <div>
            <NavbarModulo
                periodoD={periodoD}
                modulo={modulo}
                titulo={titulo}
            />
            <br />
            <div className="mb-4 card card-small text-white bg-dark mb-3">
                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                    <div className="d-flex flex-column flex-1 mx-4">
                        {(crear === false && editar === false) &&
                            <div className='row mb-2'>
                                <div className='col-3'>
                                    <Field
                                        disabled={disabled}
                                        name="fecha"
                                        component={renderDayPickerB}
                                        msj="Fecha Registro"
                                    />
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="serie"
                                        placeholder="Nombre"
                                        className="form-control"
                                        component={renderField}
                                        disabled={disabled}
                                        msj="Serie"
                                    />
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="numero"
                                        placeholder="Dias credito"
                                        className="form-control"
                                        component={renderNumber}
                                        disabled={disabled}
                                        msj="Numero"
                                    />
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="ordenCompra"
                                        placeholder="Ingreso de producción"
                                        className="form-control"
                                        component={renderField}
                                        disabled={disabled}
                                        msj="Orden de compra"
                                    />
                                </div>
                            </div>
                        }
                        {(crear === false && editar === false) &&
                            <div className='row mb-2'>
                                <div className='col-3'>
                                    <Field
                                        name="serie_compra"
                                        placeholder="Serie factura"
                                        className="form-control"
                                        component={renderField}
                                        disabled={disabled}
                                        msj="Serie factura"
                                    />
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="numero_compra"
                                        placeholder="Serie factura"
                                        className="form-control"
                                        component={renderField}
                                        disabled={disabled}
                                        msj="Numero factura"
                                    />
                                </div>
                            </div>
                        }
                        {crear === true &&
                            <div className='row mb-2'>
                                <div className='col-3'>
                                    <Field
                                        autoFocus={autoFocus}
                                        disabled={disabled}
                                        name="fechaRegistro"
                                        component={renderDayPickerB}
                                        msj="Fecha Registro"
                                    />
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="ordenCompra"
                                        placeholder="Orden de compra..."
                                        className="form-control-find"
                                        loadOptions={listarOrdenes}
                                        component={AsyncSelectField}
                                        onChange={handleOnChange}
                                        msj="Ingreso de producción"
                                    />
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="serieFactura"
                                        placeholder="Serie"
                                        className="form-control"
                                        component={renderField}
                                        msj="Serie factura"
                                    />
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="numeroFactura"
                                        placeholder="Numero"
                                        className="form-control"
                                        component={renderNumber}
                                        msj="Numero factura"
                                    />
                                </div>
                            </div>
                        }
                        {(crear === true) &&
                            <div>
                                <div className='row mb-2'>
                                    <div className='col-12'>
                                        <div className="form-floating">
                                            <input
                                                placeholder="Cliente"
                                                type="text"
                                                autocomplete="off"
                                                name="proveedor"
                                                className="form-control"
                                                value={detalleOrd.length === 0 ? '' : prov}
                                                disabled={true}
                                            />
                                            <label className='form-label' htmlFor="cuenta">
                                                Proveedor
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <div className='col-12'>
                                        <Field
                                            name="descripcion"
                                            disabled={disabled}
                                            placeholder="Observaciones"
                                            className="form-control"
                                            component={renderField}
                                            msj="Descripción"
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {(crear === false && editar === false) &&
                            <div>
                                <div className='row mb-2'>
                                    <div className='col-12'>
                                        <Field
                                            name="proveedor"
                                            disabled={disabled}
                                            placeholder="Cliente"
                                            className="form-control"
                                            component={renderField}
                                            msj="Proveedor"
                                        />
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <div className='col-12'>
                                        <Field
                                            name="descripcion"
                                            disabled={disabled}
                                            placeholder="Observaciones"
                                            className="form-control"
                                            component={renderField}
                                            msj="Descripción"
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {crear === true &&
                            <div>
                                <h5 className="p-2 bg-secondary title mt-1">Detalle orden de compra</h5>
                                <div className='row mb-4 mt-2'>
                                    <div className='col-4'>
                                        <div className="form-floating">
                                            <Field
                                                name="bodega"
                                                options={listaBodegas}
                                                component={SelectField}
                                                className="form-select"
                                                msj="Bodega"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div style={estilo}>
                                    <DetalleOrden
                                        detalle={detalleOrd}
                                    />
                                </div>
                            </div>
                        }
                        {(crear === false && editar === false) &&
                            <div>
                                <h5 className="p-2 bg-secondary title mt-1">Detalle ingreso</h5>
                                <br/>
                                <div style={estilo}>
                                    <DetalleInventario
                                        detalle={detalleProducto}
                                    />
                                </div>
                            </div>
                        }
                        <br/>
                        {(crear === true || editar === true) &&
                            <div className='mb-2'>
                                <button
                                    className='btn mr-2 mb-2 btn-primary'
                                    onClick={() => registroIngreso(periodoD, detalleOrd)}
                                >
                                {editar ? 'Modificar' : 'Guardar'}
                                </button>
                                <a
                                    href={`/#/inventario/${id_empresa[5]}/ingreso_inventario/${id_empresa[7]}`}
                                    className='btn btn-secondary mb-2'
                                >
                                Regresar
                                </a>
                            </div>
                        }
                        {(crear === false && editar === false) &&
                            <div className='mb-2'>
                                <a
                                    href={`/#/inventario/${id_empresa[5]}/ingreso_inventario/${id_empresa[7]}`}
                                    className='btn btn-secondary mb-2'
                                >
                                    Regresar
                                </a>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

IngresoInventarioForm.propTypes = {
    crear: PropTypes.bool,
    editar: PropTypes.bool,
    bloqueo: PropTypes.bool,
    fechaInicio: PropTypes.string,
    fechaFinal: PropTypes.string,
    datos: PropTypes.object,
    detallePro: PropTypes.object,
    periodoD: PropTypes.object,
    modulo: PropTypes.object,
    detalleIngreso: PropTypes.func,
    detalleProducto: PropTypes.object,
    cli: propTypes.object,
    numeroSerie: propTypes.func,
    detalleEnv: PropTypes.object
};


export default reduxForm({
    form: 'FormIngresoInventario',
})(IngresoInventarioForm);
