import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import NavbarModulo from '../../components/NavbarModulo';

import {
    renderFilePickerB,
} from "../../Utils/renderField/renderField";

function SubirCompraForm(props) {
    const { handleSubmit, setArchivo, modulo, periodoD } = props;
    let titulo = 'Subir compras';
    let ruta = window.location.href;
    let empresa = ruta.split('/');
    return (
        <div>
            <NavbarModulo
                periodoD={periodoD}
                modulo={modulo}
                titulo={titulo}
            />
            <form onSubmit={handleSubmit}>
            <br />
            <div className="mb-4 card card-small text-white bg-dark mb-3">
                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                    <div className="d-flex flex-column flex-1 mx-4">
                        <div className='mb-2 w-25'>
                            <Field
                                photo={''}
                                setFile={setArchivo}
                                name="archivo"
                                component={renderFilePickerB}
                            />
                        </div>
                        <div className='d-flex flex-row mt-3'>

                            <button
                                className='btn mr-2 mb-3 btn-primary'
                                type='submit'
                            >
                                Subir compras
                            </button>

                            <a
                                href={`/#/visitador_medico/${empresa[5]}`}
                                className='btn btn-secondary mb-3'
                            >
                                Regresar
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            </form>
        </div>
    );
}

SubirCompraForm.propTypes = {
    subirCompras: PropTypes.func,
};

export default reduxForm({
    form: 'FormSubircompra'
})(SubirCompraForm);
