import { handleActions } from 'redux-actions';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';
const valEmp = __CONFIG__.empresa;

const COLABORADOR = 'COLABORADOR';
const LOADER = 'LOADER';
const MODULO = 'MODULO';
const PUESTO = 'PUESTO';
const LISTADO = 'LISTADO';
const FECHA_ALTA = 'FECHA_ALTA';
const DIAS = 'DIAS';
const DIAS_TOMADOS = 'DIAS_TOMADOS';

const setLoader = loader => ({
    type: LOADER,
    loader,
});


export const listarColaborador = (pais) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params;
    let id = ''
    if (valEmp === 'fage'){
        id = id_emp[6];
        params = { id, pais };
    }else{
        id = id_emp[5];
        params = { id, pais };
    }
    api.get('/colaborador/empresa_colaborador_pais', params).then(data => {
        const lista = [];
        if (data) {
            data.forEach(item => {
                lista.push({
                    value: item.id,
                    label: item.codigo+' - ' + item.nombre
                })
            })
        }
        dispatch({type: COLABORADOR, emp: lista })
    }).catch((error) => {
        return [];
    })
}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = { id, modulo }
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response) => {
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const listarPaises = () => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let empresa = '';
    if (valEmp === 'fage'){
        empresa = id_emp[6];
    }else{
        empresa = id_emp[5];
    }
    return api.get(`/pais_colaborador/pais_empresa/?id=${empresa}`).then((data)=>{
        const pais = [];
        data.results.forEach(item=>{
            pais.push({
                value: item.id,
                label: item.pais
            })
        })
        return pais;
    }).catch(error=>{
        NotificationManager.error(
            'Ocurrió un error al listar paises',
            'Error',
            0
        );
    })
}

export const datosColaborador = (emp) => (dispatch) => {
    api.get(`/colaborador/colaborador_vacaciones/?id=${emp}`).then((response) => {
        dispatch({type: PUESTO, cargo: response[0].cargo })
        dispatch({type: FECHA_ALTA, fechaAlta: response[0].fecha_alta })
        dispatch({type: DIAS, diasV: response[0].dias_vacaciones })
        dispatch({type: DIAS_TOMADOS, diasT: response[0].dias_vacaciones_tomados })
    }).catch((error) => {
        return [];
    })
}

export const guardarDatos = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormVacacionesColaborador.values;
    let id_empresa = '';
    if (valEmp === 'fage'){
        id_empresa = id_emp[6]
    }else{
        id_empresa = id_emp[5]
    }
    if (data === undefined) {
        Swal.fire({
            title: 'Vacaciones',
            text: 'Debe de seleccionar colaborador',
            type: 'error',
        })
    } else {
        if (data.fecha_corte === undefined || data.fecha_corte === '') {
            Swal.fire({
                title: 'Vacaciones',
                text: 'Debe de ingresar fecha de corte',
                type: 'error',
            })
        } else {
            if (data.dias === undefined || data.dias === 0.00) {
                Swal.fire({
                    title: 'Vacaciones',
                    text: 'Debe de ingresar dias a tomar',
                    type: 'error',
                })
            } else {
                if (data.fecha_inicio === undefined || data.fecha_inicio === '') {
                    Swal.fire({
                        title: 'Vacaciones',
                        text: 'Debe de ingresar fecha de inicio',
                        type: 'error',
                    })
                } else {
                    if (data.fecha_inicio === undefined || data.fecha_fin === '') {
                        Swal.fire({
                            title: 'Vacaciones',
                            text: 'Debe de ingresar fecha final',
                            type: 'error',
                        })
                    } else {
                        const formData = {
                            colaborador: data.colaborador.value,
                            fecha_corte: data.fecha_corte,
                            fecha_inicio: data.fecha_inicio,
                            fecha_fin: data.fecha_fin,
                            dias: data.dias,
                            descripcion: data.descripcion,
                            empresa: id_empresa
                        }
                        dispatch(setLoader(true));
                        api.post('/rrhh_vacaciones/', formData).then(() => {
                            NotificationManager.success(
                                'Vacaciones guardado correctamente',
                                'Exito',
                                2000
                            );
                            setTimeout(() => {
                                window.location.reload(true);
                            }, 700);
                            
                        }).catch((error) => {
                            NotificationManager.error(
                                error.msj,
                                'Error',
                                2000
                            );
                        }).finally(() => {
                            dispatch(setLoader(false));
                        });
                    }
                }
            }
        }
    }
}

export const listaVacaciones = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormVacacionesColaborador.values;
    let id_empresa = '';
    if (valEmp === 'fage'){
        id_empresa = id_emp[6]
    }else{
        id_empresa = id_emp[5]
    }
    if (data === undefined) {
        Swal.fire({
            title: 'Vacaciones',
            text: 'Debe de seleccionar colaborador',
            type: 'error',
        })
    } else {
        let idCol = data.colaborador.value;
        const params = { id_empresa, idCol }
        dispatch(setLoader(true));
        api.get('/rrhh_vacaciones/', params).then((response) => {
            dispatch({ type: LISTADO, lista: response });
        }).catch(() => {
            NotificationManager.error(
                'Ocurrió un error en buscar modulo',
                'Error',
                2000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
};

const eliminarLinea = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`/rrhh_vacaciones/${id}`).then(() => {
        dispatch(listaVacaciones())
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar registro',
            'Error',
            3000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const limpiarLista = () => (dispatch) => {
    dispatch({ type: LISTADO, lista: [] });
    dispatch({ type: PUESTO, cargo: [] });
    dispatch({ type: FECHA_ALTA, fechaAlta: [] });
    dispatch({ type: DIAS, diasV: 0 });
    dispatch({ type: DIAS_TOMADOS, diasT: 0 });
}

export const actions = {
    listarColaborador,
    getModulo,
    listarPaises,
    guardarDatos,
    datosColaborador,
    listaVacaciones,
    eliminarLinea,
    limpiarLista
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [COLABORADOR]: (state, { emp }) => {
        return {
            ...state,
            emp,
        };
    },
    [PUESTO]: (state, { cargo }) => {
        return {
            ...state,
            cargo,
        };
    },
    [FECHA_ALTA]: (state, { fechaAlta }) => {
        return {
            ...state,
            fechaAlta,
        };
    },
    [DIAS]: (state, { diasV }) => {
        return {
            ...state,
            diasV,
        };
    },
    [LISTADO]: (state, { lista }) => {
        return {
            ...state,
            lista,
        };
    },
    [DIAS_TOMADOS]: (state, { diasT }) => {
        return {
            ...state,
            diasT,
        };
    },
};

export const initialState = {
    loader: false,
    modulo: [],
    emp: [],
    cargo: [],
    historialSal: [],
    fechaAlta: '',
    diasV: 0,
    lista: [],
    diasT: 0
};

export default handleActions(reducers, initialState)
