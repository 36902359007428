import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';

const LISTADO_PRODUCTO = 'LISTADO_PRODUCTO';
const LOADER = 'LOADER';
const MODULO = 'MODULO';
const LISTADO_DEPTO = 'LISTADO_DEPTO';
const LECTURA = 'LECTURA';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let params = { id };
    dispatch(setLoader(true));
    api.get('/visitador_medico/producto', params).then((response)=>{
        dispatch({ type: LISTADO_PRODUCTO, data: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar productos',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const registroProducto = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormProductoVM.values;
    let id_empresa = id_emp[5];

    if (data === undefined){
        Swal.fire({
            title: 'Producto',
            text: 'Debe de ingresar datos',
            type: 'error',
        })
    }else {
        if (data.codigo === undefined || data.codigo === ''){
            Swal.fire({
                title: 'Producto',
                text: 'Debe de ingresar codigo',
                type: 'error',
            })
        }else {
            if (data.producto === undefined || data.producto === ''){
                Swal.fire({
                    title: 'Producto',
                    text: 'Debe de ingresar descripcion de producto',
                    type: 'error',
                })
            }else {
                if (data.tipo === undefined || data.tipo === ''){
                    Swal.fire({
                        title: 'Producto',
                        text: 'Debe de seleccionar departamento',
                        type: 'error',
                    })
                }else {
                    if (data.cuenta_contable === undefined || data.cuenta_contable === ''){
                        Swal.fire({
                            title: 'Producto',
                            text: 'Debe de ingresar cuenta contable',
                            type: 'error',
                        })
                    }else {
                        const formData = {
                            codigo: data.codigo,
                            producto: data.producto,
                            tipo: data.tipo,
                            cuenta_contable: data.cuenta_contable,
                            empresa: id_empresa
                        }
                        dispatch(setLoader(true));
                        api.post('/visitador_medico/producto/', formData).then(() => {
                            NotificationManager.success(
                                'Producto creado',
                                'Exito',
                                3000
                            );
                            let ruta = `/visitador_medico/${id_emp[5]}/producto`
                            dispatch(push(ruta));
                        }).catch((error) => {
                        NotificationManager.error(
                            error.msj,
                            'Error',
                            2000
                        );
                        }).finally(() => {
                            dispatch(setLoader(false));
                        });                
                    }
                }
            }
        }
    }
}

export const modificarProducto = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const datos = getStore().form.FormProductoVM.values;
    const id = datos.id;
    if (datos.codigo === undefined || datos.codigo === ''){
        Swal.fire({
            title: 'Producto',
            text: 'Debe de ingresar codigo',
            type: 'error',
        })
    }else {
        if (datos.producto === undefined || datos.producto === ''){
            Swal.fire({
                title: 'Producto',
                text: 'Debe de ingresar descripcion de producto',
                type: 'error',
            })
        }else {
            if (datos.tipo === undefined || datos.tipo === ''){
                Swal.fire({
                    title: 'Producto',
                    text: 'Debe de seleccionar departamento',
                    type: 'error',
                })
            }else {
                if (datos.cuenta_contable === undefined || datos.cuenta_contable === ''){
                    Swal.fire({
                        title: 'Producto',
                        text: 'Debe de ingresar cuenta contable',
                        type: 'error',
                    })
                }else {
                    const formData = {
                        id: id,
                        codigo: datos.codigo,
                        producto: datos.producto,
                        tipo: datos.tipo,
                        cuenta_contable: datos.cuenta_contable,
                        descripcion: datos.descripcion,
                    }
                    dispatch(setLoader(true));
                    api.put(`/visitador_medico/producto/${id}/`, formData).then(() => {
                        NotificationManager.success(
                            'Producto modificado correctamente',
                            'Exito',
                            3000
                        );
                        let ruta = `/visitador_medico/${id_emp[5]}/producto`
                        dispatch(push(ruta));
                    }).catch((error) => {
                        NotificationManager.error(
                            'Ocurrió un error al modificar producto',
                            'Error',
                            2000
                        );
                    }).finally(() => {
                        dispatch(setLoader(false));
                    });
                }
            }
        }
    }
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/visitador_medico/producto/${id}`).then((response) => {
        dispatch(initializeForm('FormProductoVM', response ));
        dispatch({ type: LECTURA, lectura: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar producto',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        if (error.status === 401){
            NotificationManager.error(
              error.body.msj,
              'Error',
              0
            );
        }
        if (error.status === 400){
            NotificationManager.error(
              'Ocurrió un error en buscar modulo',
              'Error',
              0
            );
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const listarDepartamentos = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let params = { id };
    dispatch(setLoader(true));
    api.get("/visitador_medico/departamento", params).then(response => {
        const deptos = [];
        response.forEach(item => {
            deptos.push({
                value: item.id,
                label: item.descripcion
            })
        })
        dispatch({ type: LISTADO_DEPTO, listaDep: deptos });
    }).catch(error => {
        NotificationManager.error(
            'Ocurrió un error al listar departamentos',
            'Error',
            8000
        );
    })
}


export const actions = {
    listar,
    registroProducto,
    modificarProducto,
    leer,
    getModulo,
    listarDepartamentos
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_PRODUCTO]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [LISTADO_DEPTO]: (state, { listaDep }) => {
        return {
            ...state,
            listaDep,
        };
    },
    [LECTURA]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
};

export const initialState = {
    loader: false,
    modulo: [],
    data: [],
    listaDep: [],
    lectura: [],
};

export default handleActions(reducers, initialState)
 