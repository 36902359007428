import { handleActions } from 'redux-actions';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';
import { initialize as initializeForm } from 'redux-form';
const valEmp = __CONFIG__.empresa;

const COLABORADOR = 'COLABORADOR';
const LOADER = 'LOADER';
const MODULO = 'MODULO';
const HISTORIAL = 'HISTORIAL';
const LISTADO_DEPARTAMENTOS = 'LISTADO_DEPARTAMENTOS';
const LISTADO_PUESTOS = 'LISTADO_PUESTOS';
const DEPARTAMENTO = 'DEPARTAMENTO';
const PUESTO = 'PUESTO';
const JEFE = 'JEFE';
const LUGAR = 'LUGAR';
const HORARIO = 'HORARIO';
const LISTADO = 'LISTADO';
const DEPARTAMENTO_NOMBRE = 'DEPARTAMENTO_NOMBRE';
const PUESTO_NOMBRE = 'PUESTO_NOMBRE';


const setLoader = loader => ({
    type: LOADER,
    loader,
});


export const listarColaborador = (pais) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params;
    let id = ''
    if (valEmp === 'fage'){
        id = id_emp[6];
        params = { id, pais };
    }else{
        id = id_emp[5];
        params = { id, pais };
    }
    api.get('/colaborador/empresa_colaborador_pais', params).then(data => {
        const lista = [];
        if (data) {
            data.forEach(item => {
                lista.push({
                    value: item.id,
                    label: item.codigo+' - ' + item.nombre
                })
            })
        }
        dispatch({type: COLABORADOR, emp: lista })
    }).catch((error) => {
        return [];
    })
}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = { id, modulo }
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response) => {
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const listarPaises = () => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let empresa = '';
    if (valEmp === 'fage'){
        empresa = id_emp[6];
    }else{
        empresa = id_emp[5];
    }
    return api.get(`/pais_colaborador/pais_empresa/?id=${empresa}`).then((data)=>{
        const pais = [];
        data.results.forEach(item=>{
            pais.push({
                value: item.id,
                label: item.pais
            })
        })
        return pais;
    }).catch(error=>{
        NotificationManager.error(
            'Ocurrió un error al listar paises',
            'Error',
            0
        );
    })
}

export const datosColaborador = (emp) => (dispatch) => {
    api.get(`/colaborador/${emp}`).then((response) => {
        dispatch({ type: PUESTO, cargo: response.puestoTrabajo });
        dispatch({ type: DEPARTAMENTO, depto: response.departamentoTrabajo });
        dispatch({ type: JEFE, jefe: response.jefe });
        dispatch({ type: LUGAR, lugar: response.lugarTrabajo });
        dispatch({ type: HORARIO, horario: response.horarioTrabajo });
        dispatch({ type: PUESTO_NOMBRE, puesto_N: response.puestoNombre });
        dispatch({ type: DEPARTAMENTO_NOMBRE, depto_N: response.departamentoNombre });
        dispatch(listaPromocionPuesto(response.empresa, response.id));
        dispatch(initializeForm('FormCambioPuestoDetalleColaborador', response));
    }).catch((error) => {
        return [];
    })
}

export const guardarPuesto = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const datos = getStore().form.FormCambioPuestoColaborador.values;
    
    let id_empresa = '';
    if (valEmp === 'fage') {
        id_empresa = id_emp[6]
    } else {
        id_empresa = id_emp[5]
    }
    if (datos === undefined) {
        Swal.fire({
            title: 'Promoción de puesto',
            text: 'Debe de seleccionar colaborador',
            type: 'error',
        })
    } else {
        const data = getStore().form.FormCambioPuestoDetalleColaborador.values;
        let depto = data.departamento_trabajo;
        if (depto === undefined) {
            depto = data.departamentoTrabajo
            ;
        } else {
            depto = data.departamento_trabajo;
        }
        let puesto = data.puesto;
        if (puesto === undefined) {
            puesto = data.puestoTrabajo;
        } else {
            puesto = data.puesto;
        }
        const formData = {
            colaborador: datos.colaborador.value,
            departamento: depto,
            puesto: puesto,
            jefe: data.jefe,
            lugar: data.lugarTrabajo,
            horario: data.horarioTrabajo,
            empresa: id_empresa
        }
        
        dispatch(setLoader(true));
        api.post('/rrhh_historial_cargo/', formData).then(() => {
            NotificationManager.success(
                'Nuevo puesto guardado',
                'Exito',
                2000
            );
            setTimeout(() => {
                window.location.reload(true);
            }, 700);
        }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                2000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
}

const eliminarLinea = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`/rrhh_historial_cargo/${id}`).then((response) => {
        dispatch(listaPromocionPuesto(response.empresa, response.idColaborador));
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar departamento',
            'Error',
            3000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const listarDepartamentos = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = '';
    let params = '';
    let reporte = 'rep';
    if (valEmp === 'fage') {
        id = id_emp[6];
        params = { id, reporte }
    } else {
        id = id_emp[5];
        params = { id, reporte }
    }
    return api.get("/departamento", params).then(response => {
        const depto = [];
        response.forEach(item => {
            depto.push({
                value: item.id,
                label: item.descripcion
            })
        })
        dispatch({ type: LISTADO_DEPARTAMENTOS, listaDepartamento: depto });
    }).catch(error => {
        NotificationManager.error(
            'Ocurrió un error al listar departamentos',
            'Error',
            8000
        );
    })
}

const listarPuestos = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = '';
    let params = '';
    let reporte = 'rep';
    if (valEmp === 'fage') {
        id = id_emp[6];
        params = { id, reporte };
    } else {
        id = id_emp[5];
        params = { id, reporte };
    }
    return api.get("/puesto", params).then(response => {
        const puesto = [];
        response.forEach(item => {
            puesto.push({
                value: item.id,
                label: item.descripcion
            })
        })
        dispatch({ type: LISTADO_PUESTOS, listaPuesto: puesto });
    }).catch(error => {
        NotificationManager.error(
            'Ocurrió un error al listar puestos',
            'Error',
            8000
        );
    })
}

export const listaPromocionPuesto = (empresa,idCol) => (dispatch) => {
    const params = { empresa, idCol }
    dispatch(setLoader(true));
    api.get('/rrhh_historial_cargo/', params).then((response) => {
        dispatch({ type: LISTADO, lista: response });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const limpiarDatos = () => (dispatch) => {
    dispatch({ type: PUESTO, cargo: [] });
    dispatch({ type: DEPARTAMENTO, depto: [] });
    dispatch({ type: JEFE, jefe: [] });
    dispatch({ type: LUGAR, lugar: [] });
    dispatch({ type: HORARIO, horario: [] });
    dispatch({ type: PUESTO_NOMBRE, puesto_N: [] });
    dispatch({ type: DEPARTAMENTO_NOMBRE, depto_N: [] });
    dispatch({ type: LISTADO, lista: [] });
};

export const actions = {
    listarColaborador,
    getModulo,
    listarPaises,
    guardarPuesto,
    datosColaborador,
    eliminarLinea,
    listarDepartamentos,
    listarPuestos,
    limpiarDatos
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [COLABORADOR]: (state, { emp }) => {
        return {
            ...state,
            emp,
        };
    },
    [HISTORIAL]: (state, { historialSal }) => {
        return {
            ...state,
            historialSal,
        };
    },
    [LISTADO_DEPARTAMENTOS]: (state, { listaDepartamento }) => {
        return {
            ...state,
            listaDepartamento,
        };
    },
    [LISTADO_PUESTOS]: (state, { listaPuesto }) => {
        return {
            ...state,
            listaPuesto,
        };
    },
    [PUESTO]: (state, { cargo }) => {
        return {
            ...state,
            cargo,
        };
    },
    [DEPARTAMENTO]: (state, { depto }) => {
        return {
            ...state,
            depto,
        };
    },
    [JEFE]: (state, { jefe }) => {
        return {
            ...state,
            jefe,
        };
    },
    [LUGAR]: (state, { lugar }) => {
        return {
            ...state,
            lugar,
        };
    },
    [HORARIO]: (state, { horario }) => {
        return {
            ...state,
            horario,
        };
    },
    [LISTADO]: (state, { lista }) => {
        return {
            ...state,
            lista,
        };
    },
    [PUESTO_NOMBRE]: (state, { puesto_N }) => {
        return {
            ...state,
            puesto_N,
        };
    },
    [DEPARTAMENTO_NOMBRE]: (state, { depto_N }) => {
        return {
            ...state,
            depto_N,
        };
    },

};

export const initialState = {
    loader: false,
    modulo: [],
    emp: [],
    historialSal: [],
    listaDepartamento: [],
    listaPuesto: [],  
    cargo: 0,
    depto: 0,
    jefe: '',
    horario: '',
    lugar: '',
    lista: [],
    depto_N: [],
    puesto_N: []
};

export default handleActions(reducers, initialState)
