import React, { useState, useMemo, useRef, useEffect } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import XBtn from "./X-Btn";
import "./DataTableWSearch.css";
import { useSearchFieldState } from "react-stately";
import { useSearchField } from "react-aria";

function SearchField(props) {
    let state = useSearchFieldState(props);
    let ref = useRef(null);
    let { inputProps } = useSearchField(props, state, ref);

    return (
        <div style={{ display: "flex", flexDirection: "column", width: 300 }}>
            <input {...inputProps} ref={ref} />
        </div>
    );
}

const tableStyles = {
    table: {
        style: {
            background: "#1e1e1e",
        },
    },
    tableWrapper: {
        style: {
            borderRadius: "7px 7px 0 0",
        },
    },
    responsiveWrapper: {
        style: {
            borderRadius: "7px 7px 0 0",
            border: "1px solid black",
            borderBottom: "none",
        },
    },
    rows: {
        style: {
            // color: "white",
            borderStyle: "none",
            // borderRadius: "7px",
        },
    },
    head: {
        style: {
            // background: "hsla(343, 100%, 42%, 0.2)",
            background: "#431824",
            borderBottom: "1px solid #bd0034",
            // padding: "15px",s
            color: "white",
        },
    },
    pagination: {
        style: {
            border: "1px solid black",
            borderTop: "1px solid #bd0034",
            background: "#1e1e1e",
            borderRadius: "0 0 7px 7px",
        },
    },
    expanderRow: {
        style: {
            borderBottom: "1px dashed #bd0034",
            background: "#121212",
        },
    },
};

const FilterComponent = ({
    filterText,
    onFilter,
    onClear,
    filterPlaceHolder,
}) => (
    <div className="search-input-container">
        {/* <input
            id="search"
            type="text"
            placeholder={filterPlaceHolder}
            aria-label="Search Input"
            value={filterText}
            onChange={onFilter}
        /> */}
        <SearchField
            id="search"
            aria-label="Search"
            placeholder={filterPlaceHolder}
            onChange={onFilter}
            value={filterText}
        />
        {/* {filterText !== "" && ( */}
        <XBtn
            aria-label="Clear"
            onPress={onClear}
            style={{
                display: filterText ? "flex" : "none",
            }}
        >
            &times;
        </XBtn>
        {/* )} */}
    </div>
);

const sortIcon = <ArrowDropDownIcon />;

createTheme(
    "blackDivider",
    {
        background: {
            default: "hsla(0, 0%, 0%, 0)",
        },
        divider: {
            default: "#000000",
        },
        subHeader: {
            style: {
                backgroundColor: "hsla(0, 0%, 0%, 0)",
            },
            default: "hsla(0, 0%, 0%, 0)",
        },
    },
    "dark"
);

function DataTableWSearch({
    noSearch,
    data,
    fieldsToFilter,
    filterPlaceHolder,
    ...props
}) {
    const tableData = data ? data : [];
    const [filterText, setFilterText] = useState("");
    const [debouncedFilterText, setDebouncedFilterText] = useState("");
    useEffect(() => {
        const debounceTimeout = setTimeout(() => {
            setDebouncedFilterText(filterText);
        }, 250);
        return () => clearTimeout(debounceTimeout);
    }, [filterText]);
    let filteredItems;

    if (!debouncedFilterText) {
        filteredItems = tableData;
    }

    if (debouncedFilterText) {
        filteredItems = tableData.filter((item) => {
            // If fieldsToFilter no existe, filtrar todos los campos por defecto
            if (!fieldsToFilter) {
                return Object.values(item).some(
                    (value) =>
                        value &&
                        value
                            .toString()
                            .toLowerCase()
                            .includes(debouncedFilterText.toLowerCase())
                );
            }

            return fieldsToFilter.some((field) => {
                const fieldValue = item[field];
                return (
                    fieldValue &&
                    fieldValue
                        .toString()
                        .toLowerCase()
                        .includes(debouncedFilterText.toLowerCase())
                );
            });
        });
    }

    if (noSearch) {
        return (
            <DataTable
                pagination
                sortIcon={sortIcon}
                theme="blackDivider"
                data={filteredItems}
                paginationPerPage={25}
                paginationComponentOptions={{
                    rowsPerPageText: "Filas por página",
                    rangeSeparatorText: "de",
                }}
                noDataComponent="No hay datos"
                customStyles={tableStyles}
                {...props}
            />
        );
    }
    const filterField = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setFilterText("");
            }
        };
        return (
            <FilterComponent
                onClear={handleClear}
                filterText={filterText}
                onFilter={(e) => {
                    setFilterText(e);
                }}
                filterPlaceHolder={
                    filterPlaceHolder ? filterPlaceHolder : "Buscar"
                }
            />
        );
    }, [filterText]);

    return (
        <DataTable
            pagination
            sortIcon={sortIcon}
            theme="blackDivider"
            subHeader
            subHeaderComponent={filterField}
            {...props}
            data={filteredItems}
            paginationPerPage={25}
            paginationComponentOptions={{
                rowsPerPageText: "Filas por página",
                rangeSeparatorText: "de",
            }}
            noDataComponent="No hay datos"
            customStyles={tableStyles}
        />
    );
}

export default DataTableWSearch;
