import { handleActions } from "redux-actions";
import { api } from "api";
import { NotificationManager } from "react-notifications";
import { listarVendedores, reporteVentaDeVehiculos } from "./ventas";

export const datosDeVenta = (ventaId) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let id = id_emp[5];
    let action = "editar";
    const params = { id, action };
    return api
        .get(`auto_ventas/ventas/${ventaId}`, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            NotificationManager.error(
                "Ocurrio un error al obtener los datos",
                "Error",
                2000,
                null,
                true
            );
            return [];
        });
};

export const editarVenta = (data, ventaId) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let id = id_emp[5];
    data.empresa = id;
    data.vendedor = data.vendedor.value;
    const params = { id, ...data };
    return api
        .put(`auto_ventas/ventas/${ventaId}`, params)
        .then((response) => {
            NotificationManager.success(
                "Venta editada",
                "Exito",
                3000,
                null,
                true
            );
            return true;
        })
        .catch((error) => {
            NotificationManager.error(error.msj, "Error", 2000, null, true);
            return false;
        });
};

export const actions = {
    datosDeVenta,
    listarVendedores,
    reporteVentaDeVehiculos,
    editarVenta,
};

export const reducers = {};

export const initialState = {};

export default handleActions(reducers, initialState);
