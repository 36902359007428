import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { withRouter } from "react-router";
import { FicInput, FicCheckbox } from "../../Utils/FormInputComponents";
import { setSettingsLaboratorio } from "../../../../redux/modules/laboratorio/actions";
import GenBtn from "../../components/GenBtn";
import LoadMask from "../../Utils/LoadMask/LoadMask";
import InfoPopover from "../../components/Popover";
import useUpdateEffect from "../../../../hooks/useUpdateEffect";
import { getEntry, editar, crear } from "../Ajustes/actions";

const SettingsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    border-radius: 5px;
    background-color: #2b2b2b;
`;

const SettingGroup = styled.div`
    display: flex;
    align-items: flex-end;
    gap: 1rem;
`;

const schema = yup.object({});

function CrearSerieAutomatica({ history, setSettingsLaboratorio, ...props }) {
    const [isLoading, setIsLoading] = useState(false);
    const [entryId, setEntryId] = useState(null);
    const [loadedSerie, setLoadedSerie] = useState("");

    const { control, reset, handleSubmit, formState, watch, setValue } =
        useForm({
            mode: "onChange",
            resolver: yupResolver(schema),
            defaultValues: async () => {
                setIsLoading(true);
                const formData = await getEntry();
                if (formData) {
                    setEntryId(formData.id);
                    setLoadedSerie(formData.serie);
                }
                setIsLoading(false);
                return formData;
            },
        });

    const onSubmit = (data) => {
        const setData = async (settingsData) => {
            setSettingsLaboratorio(settingsData);
        };
        if (entryId) {
            editar(data, entryId).then((settings) => setData(settings));
        } else {
            crear(data).then((settings) => setData(settings));
            // reset(data);
        }
    };
    const manual_series = watch("manual_series");

    useUpdateEffect(() => {
        if (manual_series) {
            setValue("serie", loadedSerie);
        }
    }, [manual_series]);

    return (
        <React.Fragment>
            <LoadMask loading={isLoading} color="#bd0034" blur={true}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <SettingsContainer>
                        <React.Fragment>
                            <SettingGroup>
                                <FicInput
                                    control={control}
                                    label="Serie"
                                    name="serie"
                                    isDisabled={manual_series}
                                />
                                <InfoPopover style={{ padding: "0.5rem" }}>
                                    <div>
                                        La serie que se usará para los
                                        documentos.
                                    </div>
                                </InfoPopover>
                            </SettingGroup>
                        </React.Fragment>
                    </SettingsContainer>
                    <GenBtn style={{ margin: "1rem 0" }} type="submit">
                        Guardar
                    </GenBtn>
                </form>
            </LoadMask>
        </React.Fragment>
    );
}

export default connect(null, { setSettingsLaboratorio })(
    withRouter(CrearSerieAutomatica)
);
