import React from "react";
import Seguros from "./modales/Seguros";
import styled from "styled-components";
import Banner from "../common/Banner";

const ModalTriggerContainer = styled.div`
    background-color: #2b2b2b;
    padding: 2rem;
    margin: 2rem 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: stretch;
    border-radius: 5px;
    gap: 12px;
    min-width: 460px;
`;

export default function Informes() {
    return (
        <React.Fragment>
            <Banner>Informes</Banner>
            <ModalTriggerContainer>
                <Seguros />
            </ModalTriggerContainer>
        </React.Fragment>
    );
}
