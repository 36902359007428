import React from "react";
import NavbarModulo from "../components/NavbarModulo";

export default function SegurosHome() {
    return (
        <React.Fragment>
            <NavbarModulo modulo={{ modulo: "Modulos" }} titulo={"Seguros"} />
        </React.Fragment>
    );
}
