import React, { useEffect, useRef } from "react";
import GenBtn from "../../components/GenBtn";
import * as Fa from "react-icons/fa";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import RecepcionDeVehiculos from "./Modales/RecepcionDeVehiculos";
import ListadoDeAutos from "./Modales/ListadoDeAutos";
import ListadoDeVentas from "./Modales/ListadoDeVentas";
import ListadoDeVendedores from "./Modales/ListadoDeVendedores";

const ButtonContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 10px;
    // margin-top: 10px;
`;

function IngresosList(props) {
    const {
        data,
        reporteListadoDeVehiculos,
        reporteListadoDeVentas,
        reporteListadoDeVendedores,
        listarVendedoresForComboBox,
        history,
    } = props;
    const RecepcionDeVehiculosRef = useRef(null);
    const ListadoDeAutosRef = useRef(null);
    const ListadoDeVentasRef = useRef(null);
    const ListadoDeVendedoresRef = useRef(null);

    return (
        <React.Fragment>
            <br />
            <h3
                className="p-2  title rounded shadow"
                style={{
                    background:
                        "linear-gradient(to top right, #790021, #2d2d2d)",
                }}
            >
                Informes
            </h3>
            <br />

            <ButtonContainer>
                <GenBtn onPress={() => ListadoDeAutosRef.current.showModal()}>
                    LISTADO DE AUTOS
                </GenBtn>
                <GenBtn onPress={() => ListadoDeVentasRef.current.showModal()}>
                    LISTADO DE VENTAS
                </GenBtn>
                <GenBtn
                    onPress={() => ListadoDeVendedoresRef.current.showModal()}
                >
                    LISTADO DE VENDEDORES
                </GenBtn>
            </ButtonContainer>

            {/* <RecepcionDeVehiculos
                dialogRef={RecepcionDeVehiculosRef}
                listadoDeIngresos={listadoDeIngresos}
                onSubmit={reporteRecepcionDeVehiculos}
            ></RecepcionDeVehiculos> */}

            <ListadoDeAutos
                dialogRef={ListadoDeAutosRef}
                onSubmit={reporteListadoDeVehiculos}
            />
            <ListadoDeVentas
                dialogRef={ListadoDeVentasRef}
                onSubmit={reporteListadoDeVentas}
                listarVendedoresForComboBox={listarVendedoresForComboBox}
            />
            <ListadoDeVendedores
                dialogRef={ListadoDeVendedoresRef}
                onSubmit={reporteListadoDeVendedores}
            />
        </React.Fragment>
    );
}

export default withRouter(IngresosList);
