import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/facturacion/facturaFarm';
import FacturaB from './FacturaBienesTabs';


const ms2p = (state) => {
  return {
    ...state.factura_farm,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(FacturaB);
