import React, { useEffect } from "react";
import BaseProtectedRoute from "./BaseProtectedRoute";
import NavbarAutoVentas from "./common/components/layout/Navbar/NavbarAutoVentas";
import NavbarFinanzas from "./common/components/layout/Navbar/NavbarFinanzas";
import BaseNavBar from "./common/components/layout/Navbar/BaseNavBar";
import NavBarSubModulo from "./common/components/layout/Navbar/NavBarSubModulo";
import { SidebarDataSeguros } from "./common/components/layout/Sidebar/SidebarDataSeguros";

export default function ProtectedRouteSeguros(props) {
    return (
        <BaseProtectedRoute
            navbar={BaseNavBar}
            links={SidebarDataSeguros}
            {...props}
        />
    );
}
