import { handleActions } from 'redux-actions';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';

const COLABORADOR = 'COLABORADOR';
const LISTADO = 'LISTADO';
const PUESTO = 'PUESTO';
const LOADER = 'LOADER';
const MODULO = 'MODULO';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listarColaborador = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let params = { id };
    api.get('/colaborador/empresa_colaborador_pais', params).then(data => {
        const lista = [];
        if (data) {
            data.forEach(item => {
                lista.push({
                    value: item.id,
                    label: item.codigo+' - ' + item.nombre
                })
            })
        }
        dispatch({type: COLABORADOR, emp: lista })
    }).catch((error) => {
        return [];
    })
}

export const datosColaborador = (emp) => (dispatch) => {
    api.get(`/colaborador/${emp}`).then((response) => {
        dispatch({type: PUESTO, cargo: response.cargo })
    }).catch((error) => {
        return [];
    })
}

export const agregarVisitador = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormVisitadorVM.values;
    
    if (data === undefined) {
        Swal.fire({
            title: 'Visitador médico',
            text: 'Debe de seleccionar colaborador',
            type: 'error',
        })
    } else {
        const formData = {
            visitador: data.colaborador.value,
            empresa: id_emp[5]
        }
        dispatch(setLoader(true));
        api.post('/visitador_medico/visitador/', formData).then(() => {
            NotificationManager.success(
                'Visitador médico agregado',
                'Exito',
                3000
            );
            dispatch(listar());
        }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                3000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let params = { id };
    return api.get('/visitador_medico/visitador', params).then((response)=>{
        dispatch({type: LISTADO, data: response })
    }).catch(error=>{
        NotificationManager.error(
            'Ocurrió un error al listar visitadores',
            'Error',
            0
        );
    })
}

const limpiarDatos = () => (dispatch) => {
    dispatch({ type: PUESTO, cargo: [] });
};

const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`/visitador_medico/visitador/${id}`).then(() => {
        NotificationManager.success(
            'Visitador médico eliminado correctamente',
            'Exito',
            3000
        );
        dispatch(listar());
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error al eliminar visitador médico',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}


export const actions = {
    listarColaborador,
    getModulo,
    agregarVisitador,
    datosColaborador,
    listar,
    limpiarDatos,
    eliminar
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [COLABORADOR]: (state, { emp }) => {
        return {
            ...state,
            emp,
        };
    },
    [PUESTO]: (state, { cargo }) => {
        return {
            ...state,
            cargo,
        };
    },
    [LISTADO]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
};

export const initialState = {
    loader: false,
    modulo: [],
    data: [],
    emp: [],
    cargo: '',
};

export default handleActions(reducers, initialState)
 