import React, { Component } from 'react';
import Formulario from './InformesForm';

class Informes extends Component{

    componentDidMount = () => {
        const { getModulo, listarPaises, listarDepartamentos } = this.props;
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        getModulo(modulo[5],modulo[4])
        listarPaises();
        listarDepartamentos();
    }
    render(){
        const { loader, modulo, listaPeriodo, listarPlanillas,
            listaP, reportePlanilla, reportePlanillaMensual, listarColaborador,
            reporteCuotasIgss, excelCuotasIgss, reporteRecibosPeriodo,
            listarPlanillasRecibos, reportePromedioIngresos, deptos } = this.props;
        return(
            <div>
                <Formulario
                    loader = {loader}
                    modulo = {modulo}
                    obtenerPaises = {listaP}
                    listaPeriodo = {listaPeriodo}
                    listarPlanillas = {listarPlanillas}
                    reportePlanilla = {reportePlanilla}
                    reportePlanillaMensual = {reportePlanillaMensual}
                    reporteCuotasIgss = {reporteCuotasIgss}
                    excelCuotasIgss = {excelCuotasIgss}
                    reporteRecibosPeriodo = {reporteRecibosPeriodo}
                    listarColaborador = {listarColaborador}
                    listarPlanillasRecibos = {listarPlanillasRecibos}
                    reportePromedioIngresos = {reportePromedioIngresos}
                    deptos = {deptos}
                />
            </div>
        );
    }
}

export default Informes;
