
import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';
import moment from 'moment';

const LISTADO_ORDEN = 'LISTADO_ORDEN';
const LOADER = 'LOADER';
const MODULO = 'MODULO';
const PERIODO_D = 'PERIODOD';
const PARAMETRO = 'PARAMETRO';
const DETALLE = 'DETALLE';
const DETALLE_ORDEN = 'DETALLE_ORDEN';

const setLoader = loader => ({
    type: LOADER,
    loader,
});


export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let id_period = id_emp[7];
    const params = { id, id_period };
    dispatch(setLoader(true));
    api.get('/inv_gasto_proyecto', params).then((response) => {
        dispatch({ type: LISTADO_ORDEN, data: response });
    
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar gastos de proyecto',
            'Error',
            3000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}


export const leerPeriodoD = (id, id_periodo) => (dispatch) => {
    const params = { id, id_periodo };
    api.get('/periodos/periodo', params).then((response) => {
        dispatch({ type: PERIODO_D, periodoD: response.periodo });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar periodo',
            'Error',
            2000
        );
    })
}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        if (error.status === 401){
            NotificationManager.error(
              error.body.msj,
              'Error',
              0
            );
        }
        if (error.status === 400){
            NotificationManager.error(
              'Ocurrió un error en buscar modulo',
              'Error',
              0
            );
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


export const parametrosEmpresa = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id }
    dispatch(setLoader(true));
    api.get('/empresa/empresa_parametros', params).then((response) => {
        dispatch({ type: PARAMETRO, datos: response });
    }).catch((error) => {

        NotificationManager.error(
            'Ocurrió un error al consultar parametros',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}
//Ordenes de servicio
export const listarProyectos = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id, search}
    return api.get('/orden_servicio/proyectos',params).then(data=>{
        const list_proyectos = [];
        if(data){
            data.forEach(item=>{
                list_proyectos.push({
                    value: item.id,
                    label: 'IOS' + ' -- ' + item.numero + ' / ' + item.nombre_proyecto
                })
            })
        }
        return list_proyectos;
    }).catch((error)=>{
        
        return [];
    })
}


let detalle = [];
export const agregarLinea = (periodoD) => (dispatch, getStore) => {
    const data = getStore().form.FormGastosProyectoINV.values;

    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fecha);

    if (fecha >= fechaI && fecha <= fechaF) {
        if (data.total_gasto === 0.00 || data.total_gasto === undefined) {
            Swal.fire({
                title: 'Gastos de proyecto',
                text: 'Debe de ingresar total',
                type: 'error',
            })
        }else{
            if (data.gasto === undefined || data.gasto === null) {
                Swal.fire({
                    title: 'Gastos de proyecto',
                    text: 'Debe de selecionar un gasto',
                    type: 'error',
                })
            } else {
                let gasto = data.gasto.value.split("#");
                if (detalle.length > 0) {
                    const dato = detalle.find(item => item.id === gasto[0].trim());
                    if (dato) {
                        NotificationManager.error(
                            'Gasto ya fue ingresado',
                            'Error',
                            3000
                        );
                    } else {
                        detalle.push({
                            id: gasto[0].trim(),
                            gasto: gasto[1].trim(),
                            idGasto: gasto[0].trim(),
                            total: data.total_gasto,
                        })
                        data.total = 0.00;
                        data.detalleD = detalle;
                        NotificationManager.success(
                            'Gasto ingresado correctamente',
                            'Exito',
                            3000
                        );
                        dispatch(actualizar());
                    }
                } else {
                    detalle.push({
                        id: gasto[0].trim(),
                        gasto: gasto[1].trim(),
                        idGasto: gasto[0].trim(),
                        total: data.total_gasto,
                    })
                    data.total_gasto = 0.00;
                    data.detalleD = detalle;
                    NotificationManager.success(
                        'Gasto ingresado correctamente',
                        'Exito',
                        3000
                    );
                    dispatch(actualizar());
                }
            }
        }
    }else {
        Swal.fire({
            title: 'Orden de servicio',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })
    }
}

const actualizar = () => (dispatch, getStore) => {
    const data = getStore().form.FormGastosProyectoINV.values;
    dispatch(initializeForm('FormGastosProyectoINV', data));
    dispatch({ type: DETALLE, detalleOrden: data.detalleD })
};

const limpiarOrden = () => (dispatch) => {
    detalle = [];
    dispatch({ type: DETALLE, detalleOrden: detalle })
};


export const registroGasto = (periodoD, detalleO) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormGastosProyectoINV.values;
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fecha)
    if (fecha>=fechaI && fecha<=fechaF){
        if (data.proyecto === undefined || data.proyecto === null) {
            Swal.fire({
                title: 'Gastos de proyecto',
                text: 'Debe de seleccionar un proyecto',
                type: 'error',
            })
        } else {
            if (detalleO.length === 0) {
                Swal.fire({
                    title: 'Gastos de proyecto',
                    text: 'Debe de ingresar gastos',
                    type: 'error',
                    })
            } else {
                const formData = {
                    fecha: data.fecha,
                    proyecto: data.proyecto.value,
                    observaciones: data.observaciones,
                    detalleGasto: data.detalleD,
                    empresa: id_emp[5],
                    periodo: id_emp[7]
                }
                dispatch(setLoader(true));
                api.post('/inv_gasto_proyecto/', formData).then(() => {
                    NotificationManager.success(
                        'Gastos de proyecto guardado correctamente',
                        'Exito',
                        3000
                    );
                    let ruta = `/inventario/${id_emp[5]}/gastos_proyecto/${id_emp[7]}`
                    dispatch(push(ruta));
                }).catch((error) => {
                    NotificationManager.error(
                        error.msj,
                        'Error',
                        0
                    );
                }).finally(() => {
                    dispatch(setLoader(false));
                });
            }
        }
    }else {
        Swal.fire({
            title: 'Gastos de proyecto',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })    
    }
}

const leerDetalleOrden = id => (dispatch) => {
    api.get(`/inv_gasto_proyecto_detalle/${id}`).then((response) => {
        dispatch({ type: DETALLE_ORDEN, detalleOrd: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/inv_gasto_proyecto/${id}`).then((response) => {
        dispatch(leerDetalleOrden(response.id));
        dispatch(initializeForm('FormGastosProyectoINV', response));
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar gastos de proyecto',
            'Error',
            3000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const eliminarLineaProductoNuevo = (id) => (dispatch, getStore) => {
    const data = getStore().form.FormGastosProyectoINV.values;
    
    detalle.forEach((element, index) => {
        if (element.id === id) {
            detalle.splice(index, 1);
        }
    })
    data.detalleD = detalle;
    dispatch(actualizar());
};

export const listarGastos = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id, search}
    return api.get('/inv_tipo_gasto/gastos',params).then(data=>{
        const list_gasto = [];
        if(data){
            data.forEach(item=>{
                list_gasto.push({
                    value: item.id + '#' + item.gasto,
                    label: item.gasto
                })
            })
        }
        return list_gasto ;
    }).catch((error)=>{
        return [];
    })
}

export const agregarLineaNueva = (periodoD) => (dispatch, getStore) => {
    const data = getStore().form.FormGastosProyectoINV.values;
    let ruta = window.location.href;
    let id_emp = ruta.split('/');

    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fecha);
    
    if (fecha >= fechaI && fecha <= fechaF) {
        if (data.gasto === undefined || data.gasto === null) {
            Swal.fire({
                title: 'Gastos de proyecto',
                text: 'Debe de seleccionar gasto',
                type: 'error',
            })
        } else {
            if (data.total_gasto === undefined || data.total_gasto === 0.000000) {
                Swal.fire({
                    title: 'Gastos de proyecto',
                    text: 'Debe de ingresar total',
                    type: 'error',
                })
            } else {
                let gasto = data.gasto.value.split("#");
                const formData = {
                    total: data.total_gasto,
                    idGasto: gasto[0].trim(),
                    id: id_emp[8],
                }
                dispatch(setLoader(true));
                api.post('/inv_gasto_proyecto_detalle/', formData).then((response) => {
                    dispatch(leerDetalleOrden(response.id));
                    NotificationManager.success(
                        'Gasto ingresado correctamente',
                        'Exito',
                        3000
                    );
                }).catch((error) => {
                    NotificationManager.error(
                        error.msj,
                        'Error',
                        0
                    );
                }).finally(() => {
                    dispatch(setLoader(false));
                });
            }
        }

    } else {
        Swal.fire({
            title: 'Gastos de proyecto',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })
    }
}

export const modificarGasto = (periodoD) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormGastosProyectoINV.values;
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fecha)
    
    let id = id_emp[8]
    if (fecha>=fechaI && fecha<=fechaF){
        
        const formData = {
            id: id,
            fecha: data.fecha,
            observaciones: data.observaciones,
        }
        dispatch(setLoader(true));
        api.put(`/inv_gasto_proyecto/${id}/`, formData).then(() => {
            NotificationManager.success(
                'Gastos de proyecto modificado correctamente',
                'Exito',
                3000
            );

            let ruta = `/inventario/${id_emp[5]}/gastos_proyecto/${id_emp[7]}`
            dispatch(push(ruta));
        }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                0
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }else {
        Swal.fire({
            title: 'Gastos de proyecto',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })        
    }
}

const eliminarLineaGasto = id => (dispatch) => {
    let ruta = window.location.href.split('/');
    let id_emp = ruta[8];
    dispatch(setLoader(true));
    api.eliminar(`/inv_gasto_proyecto_detalle/${id}`).then(() => {
        dispatch(leerDetalleOrden(id_emp));
        NotificationManager.success(
            'Gasto eliminado correctamente',
            'Exito',
            3000
        );
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const actions = {
    listar,
    registroGasto,
    modificarGasto,
    leer,
    limpiarOrden,
    listarGastos,
    listarProyectos,
    leerPeriodoD,
    getModulo,
    parametrosEmpresa,
    agregarLinea,
    eliminarLineaGasto,
    agregarLineaNueva,
    eliminarLineaProductoNuevo
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_ORDEN]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [PERIODO_D]: (state, { periodoD }) => {
        return {
            ...state,
            periodoD,
        };
    },
    [PARAMETRO]: (state, { datos }) => {
        return {
            ...state,
            datos,
        };
    },
    [DETALLE]: (state, { detalleOrden }) => {
        return {
            ...state,
            detalleOrden,
        };
    },
    [DETALLE_ORDEN]: (state, { detalleOrd }) => {
        return {
            ...state,
            detalleOrd,
        };
    },
};

export const initialState = {
    loader: false,
    tipos: [],
    periodoS: null,
    monedas: [],
    modulo: [],
    periodoD: [],
    data: [],
    detalleOrden: [],
    detalleOrd: [],
};

export default handleActions(reducers, initialState)