import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/produccion/fase';
import Fases from './Fase';


const ms2p = (state) => {
    return {
        ...state.fase_prd,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Fases);
