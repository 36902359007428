import React from "react";
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import * as BsIcons from "react-icons/bs";
import * as IoIcons from "react-icons/io";
import * as AiIcons from "react-icons/ai";
import * as GiIcons from "react-icons/gi";

export const SidebarData = [
    {
        title: "Tools",
        path: "/tools/home",
        icon: <FaIcons.FaArrowLeft />,
    },
    {
        title: "Inicio",
        path: "/tools/finanzas",
        icon: <FaIcons.FaChevronRight />,
    },
    {
        title: "Empresas",
        icon: <FaIcons.FaChevronRight />,
        dropdown: [
            {
                title: "Listado",
                path: "/tools/finanzas/empresas",
                icon: <FaIcons.FaChevronRight />,
            },
            // {
            //     title: 'Agregar',
            //     path: '/tools/finanzas/agregar-empresa',
            //     icon: <FaIcons.FaChevronRight />
            // }
        ],
    },
];
