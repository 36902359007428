import React from 'react';
import Formulario from './AutoVentasHome';

function AutoVentas(props){

    const {listarAutos, listaDeAutos } = props;
    return(        
        <Formulario
            listarAutos={listarAutos}
            listaDeAutos={listaDeAutos}
        />
        );
    }

export default AutoVentas;
