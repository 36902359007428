import React from 'react';
import Formulario from './IngresosForm';

function Ingresos(props) {
    
    const { 
        listarMarcas,
        listarLineas,
        listarCombustibles,
        listarTipos,
        crearIngreso,
        reporteRecepcionDeVehiculos
    } = props;


    return(
        <Formulario
            listarMarcas = {listarMarcas}
            listarLineas = {listarLineas}
            listarCombustibles = {listarCombustibles}
            listarTipos = {listarTipos}
            crearIngreso = {crearIngreso}
            reporteRecepcionDeVehiculos = {reporteRecepcionDeVehiculos}
        />
    )
}


export default Ingresos;