import React, { useState } from 'react';
import Modal from '../components/Modal';
import ModalInf from '../components/ModalInf';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LoadMask from "../Utils/LoadMask/LoadMask";
import NavbarModulo from '../components/NavbarModulo';

import {
    renderNumber,
    SelectField,
    AsyncSelectField,
    renderDayPickerB,
    renderFieldCheckB
} from "../Utils/renderField/renderField";

const meses = [
    {
        label: 'ENERO',
        value: '01'
      
    },
    {
        label: 'FEBRERO',
        value: '02'
    },
    {
        label: 'MARZO',
        value: '03',
    },
    {
        label: 'ABRIL',
        value: '04',
    },
    {
        label: 'MAYO',
        value: '05',
    },
    {
        label: 'JUNIO',
        value: '06',
    },
    {
        label: 'JULIO',
        value: '07',
    },
    {
        label: 'AGOSTO',
        value: '08',
    },
    {
        label: 'SEPTIEMBRE',
        value: '09',
    },
    {
        label: 'OCTUBRE',
        value: '10'
    },
    {
        label: 'NOVIEMBRE',
        value: '11',
    },
    {
        label: 'DICIEMBRE',
        value: '12',
    },
];

const tipos = [
    {
      value: 'T',
      label: 'Todos'
    },
    {
      value: 'L',
      label: 'Local'
    },
    {
        value: 'E',
        label: 'Exterior'
    },
];

const tiposPro = [
    {
        label: 'TODOS',
        value: 'TODOS'
    },
    {
        value: 'LOCAL',
        label: 'LOCAL'
    },
    {
        value: 'EXTERIOR',
        label: 'EXTERIOR'
    },
    {
        value: 'PERSONA',
        label: 'PERSONA'
    },
];

function InformesForm(props) {
    const {
        modulo,
        reporteListaFacturas,
        reporteLibroCompras,
        loader,
        excelLibroCompras,
        monedas,
        listarProveedores,
        reporteEstadoCuenta,
        reporteAntiguedadSaldos,
        reporteProveedores,
        reporteListadoCompras,
        reporteListadoAbonos,
        reporteAnticipos,
        excelAntiguedadSaldos,
        excelEstadoCuenta
    } = props;

    const [estadoModalTop, cambiarEstadoModalTop] = useState(false);
    const [estadoModalLibro, cambiarEstadoModalLibro] = useState(false);
    const [estadoModalListadoCompras, cambiarEstadoModalListadoCompras] = useState(false);
    const [estadoModalListadoAbonos, cambiarEstadoModalListadoAbonos] = useState(false);
    const [folioLC,  cambiarFolioLC] = useState(1);
    const [estadoModalAntiguedad, cambiarEstadoModalAntiguedad] = useState(false);
    const [estadoModalEstadoCuenta, cambiarEstadoModalEstadoCuenta] = useState(false);
    const [estadoModalProv, cambiarEstadoModalProv] = useState(false);
    const [estadoModalAnticiposNoAplicados, cambiarEstadoModalAnticiposNoAplicados] = useState(false);
 
    localStorage.setItem('folioLC', folioLC);
    const handleChangeLC = e => {
        localStorage.setItem('folioLC', e.target.value);
        cambiarFolioLC(e.target.value);
    }
    let autoFocus = true;
    const [detalle, setDetalle] = useState(false);
    const onCheckboxClicked = (idx, isChecked) => {
        setDetalle(isChecked);
    }
    let titulo = 'Informes';
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    return (
        <div>
            <NavbarModulo
                modulo={modulo}
                titulo={titulo}
            />
        
            <div className="container">
                <br/>
                <h4 className='mt-1' style={{ color: "white" }}>Informes</h4>
                <div className="row">
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <button class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => cambiarEstadoModalProv(!estadoModalProv)}
                                data-bs-toggle="list"
                                role="tab"
                                href={`/#/compras/${id_emp[5]}/informes`}
                                aria-controls="list-home">Listado de Proveedores
                            </button>
                        </div>
                    </div>
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <button class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => cambiarEstadoModalTop(!estadoModalTop)}
                                data-bs-toggle="list"
                                role="tab"
                                href={`/#/compras/${id_emp[5]}/informes`}
                                aria-controls="list-home">Proveedores Top
                            </button>
                        </div>
                    </div>
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <button class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => cambiarEstadoModalLibro(!estadoModalLibro)}
                                data-bs-toggle="list"
                                role="tab"
                                href={`/#/compras/${id_emp[5]}/informes`}
                                aria-controls="list-home">Libro de Compras
                            </button>
                        </div>
                    </div>
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <button class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => cambiarEstadoModalListadoCompras(!estadoModalListadoCompras)}
                                data-bs-toggle="list"
                                role="tab"
                                href={`/#/compras/${id_emp[5]}/informes`}
                                aria-controls="list-home">Listado de compras
                            </button>
                        </div>
                    </div>
                    {/*<div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <a class="list-group-item list-group-item-action"
                                id="list-home-list"
                                data-bs-toggle="list"
                                role="tab"
                                href="/#/compras/informes"
                                aria-controls="list-home">Listado de Facturas</a>
                        </div>
                    </div>*/}
                </div>

                <div className="row">
                    <div className="col-xxl-3 mt-2">
                        <div className="list-group" id="list-tab" role="tablist">
                            <button class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => cambiarEstadoModalListadoAbonos(!estadoModalListadoAbonos)}
                                data-bs-toggle="list"
                                role="tab"
                                href={`/#/compras/${id_emp[5]}/informes`}
                                aria-controls="list-home">Listado de abonos
                            </button>
                        </div>
                    </div>
                    <div className="col-xxl-3 mt-2">
                        <div className="list-group" id="list-tab" role="tablist">
                            <button class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => cambiarEstadoModalAnticiposNoAplicados(!estadoModalAnticiposNoAplicados)}
                                data-bs-toggle="list"
                                role="tab"
                                href={`/#/compras/${id_emp[5]}/informes`}
                                aria-controls="list-home">Anticipos no aplicados
                            </button>
                        </div>
                    </div>
                </div>
                
                <h4 className='mt-5' style={{ color: "white" }}>Cuenta por pagar</h4>
                <div className="row">
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <a class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => cambiarEstadoModalEstadoCuenta(!estadoModalAntiguedad)}
                                data-bs-toggle="list"
                                role="tab"
                                href={`/#/compras/${id_emp[5]}/informes`}
                                aria-controls="list-home">Estado de Cuenta</a>
                        </div>
                    </div>
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <a class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => cambiarEstadoModalAntiguedad(!estadoModalAntiguedad)}
                                data-bs-toggle="list"
                                role="tab"
                                href={`/#/compras/${id_emp[5]}/informes`}
                                aria-controls="list-home">Antiguedad de saldos</a>
                        </div>
                    </div>
                </div>
                <Modal
                    estado={estadoModalTop} //listado facturas
                    cambiarEstado={cambiarEstadoModalTop}
                    titulo="Proveedores Top"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className='col-6 mb-2'>
                                <div className="form-floating">
                                    <Field
                                        autoFocus={autoFocus}
                                        name="mes"
                                        options={meses}
                                        component={SelectField}
                                        className="form-select"
                                        msj="Mes"
                                    />
                                </div>
                            </div>
                            <div className='col-6 mb-3'>
                                <div className="form-floating">
                                    <Field
                                        name="year"
                                        component={renderNumber}
                                        className="form-control"
                                        msj="Año"
                                    />
                                </div>
                            </div>
                        </Contenido>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div className='row'>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={reporteListaFacturas}
                                    >
                                    Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn btn-secondary'
                                        onClick={() => cambiarEstadoModalTop(false)}
                                    >
                                    Cancelar
                                    </button>
                                </div>
                            </Contenido>
                        </LoadMask>
                </Modal>
                <Modal
                    estado={estadoModalLibro} //libro compras
                    cambiarEstado={cambiarEstadoModalLibro}
                    titulo="Libro de compras"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className='col-6 mb-2'>
                                <div className="form-floating">
                                    <Field
                                        autoFocus={autoFocus}
                                        name="mes"
                                        options={meses}
                                        component={SelectField}
                                        className="form-select"
                                        msj="Mes"
                                    />
                                </div>
                            </div>
                            <div className='col-6 mb-2'>
                                <div className="form-floating">
                                    <Field
                                        name="year"
                                        component={renderNumber}
                                        className="form-control"
                                        msj="Año"
                                    />
                                </div>
                            </div>
   
                        </Contenido>
                    
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={reporteLibroCompras}
                                    >
                                    Generar Reporte
                                    </button>
                                    
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={excelLibroCompras}
                                    >
                                    Exportar Excel
                                    </button>
                                </div>
                                <button
                                    className='btn mr-2 mb-3 btn btn-secondary'
                                    onClick={() => cambiarEstadoModalLibro(false)}
                                >
                                    Cancelar
                                </button>
                            </Contenido>
                        </LoadMask>
                </Modal>
                <ModalInf
                    estado={estadoModalAntiguedad} //Antiguedad saldos
                    cambiarEstado={cambiarEstadoModalAntiguedad}
                    titulo="Antiguedad de saldos"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className='row'>
                                <div className='col-6 mb-2'>
                                    {monedas &&
                                        <div className="form-floating">
                                            <Field
                                                autoFocus={autoFocus}
                                                name="moneda"
                                                options={monedas}
                                                dato={0}
                                                component={SelectField}
                                                className="form-select"
                                                msj="Moneda"
                                            />
                                        </div>
                                    }
                                </div>
                                <div className='col-6 mb-2'>
                                    <Field
                                        name="tipoProveedor"
                                        options={tipos}
                                        dato={0}
                                        component={SelectField}
                                        className="form-select"
                                        msj="Tipo"
                                    />
                                </div>
                            </div>
                            <div className='col-6 mb-2'>
                                <Field
                                    name="fechaFinal"
                                    component={renderDayPickerB}
                                    msj="Fecha al"
                                        
                                />
                            </div>
                            <div className='col-12 mb-2'>
                                <Field
                                    name="proveedor"
                                    placeholder="Proveedor..."
                                    className="form-control-find"
                                    component={AsyncSelectField}
                                    loadOptions={listarProveedores}
                                    msj="Proveedor"
                                />
                            </div>
                            <div className='col-4 ml-5'>
                                <Field
                                    name="detallado"
                                    checked={detalle}
                                    label="Detallado"
                                    component={renderFieldCheckB}
                                    onChange={onCheckboxClicked}
                                />
                            </div>
                        </Contenido>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={reporteAntiguedadSaldos}
                                    >
                                    Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={excelAntiguedadSaldos}
                                    >
                                    Exportar Excel
                                    </button>
                                </div>
                                <button
                                    className='btn mr-2 mb-3 btn btn-secondary'
                                    onClick={() => cambiarEstadoModalAntiguedad(false)}
                                >
                                Cancelar
                                </button>
                            </Contenido>
                        </LoadMask>
                </ModalInf>
                <ModalInf
                    estado={estadoModalEstadoCuenta} //Estado de cuenta
                    cambiarEstado={cambiarEstadoModalEstadoCuenta}
                    titulo="Estado de cuenta"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className='row'>
                                <div className='col-6 mb-2'>
                                    {monedas &&
                                        <div className="form-floating">
                                            <Field
                                                autoFocus={autoFocus}
                                                name="moneda"
                                                options={monedas}
                                                dato={0}
                                                component={SelectField}
                                                className="form-select"
                                                msj="Moneda"
                                            />
                                        </div>
                                    }
                                </div>
                                <div className='col-6 mb-2'>
                                    <Field
                                        name="tipoProveedor"
                                        options={tipos}
                                        dato={0}
                                        component={SelectField}
                                        className="form-select"
                                        msj="Tipo"
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-6 mb-2'>
                                    <Field
                                        name="fechaInicial"
                                        component={renderDayPickerB}
                                        msj="Fecha del"
                                            
                                    />
                                </div>
                                <div className='col-6 mb-2'>
                                    <Field
                                        name="fechaFinal"
                                        component={renderDayPickerB}
                                        msj="Fecha al"
                                            
                                    />
                                </div>
                            </div>
                            
                            <div className='col-12 mb-2'>
                                <Field
                                    name="proveedor"
                                    placeholder="Proveedor..."
                                    className="form-control-find"
                                    component={AsyncSelectField}
                                    loadOptions={listarProveedores}
                                    msj="Proveedor"
                                />
                            </div>
                        </Contenido>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={reporteEstadoCuenta}
                                    >
                                    Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={excelEstadoCuenta}
                                    >
                                    Exportar Excel
                                    </button>
                                </div>
                                <button
                                    className='btn mr-2 mb-3 btn btn-secondary'
                                    onClick={() => cambiarEstadoModalEstadoCuenta(false)}
                                >
                                Cancelar
                                </button>
                            </Contenido>
                        </LoadMask>
                </ModalInf>
                <Modal
                    estado={estadoModalProv} //listado proveedores
                    cambiarEstado={cambiarEstadoModalProv}
                    titulo="Listado de proveedores"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className="form-floating mb-4 col-6">
                                <Field
                                    autoFocus={autoFocus}
                                    name="tipo_proveedor"
                                    dato={'TODOS'}
                                    options={tiposPro}
                                    component={SelectField}
                                    className="form-select"
                                    msj="Tipo proveedor"
                                />
                            </div>
                        </Contenido>
                        
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div className='row'>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={reporteProveedores}
                                    >
                                    Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn btn-secondary'
                                        onClick={() => cambiarEstadoModalProv(false)}
                                    >
                                    Cancelar
                                    </button>
                                </div>
                            </Contenido>
                        </LoadMask>
                </Modal>
                <Modal
                    estado={estadoModalListadoCompras} //Listado de compras
                    cambiarEstado={cambiarEstadoModalListadoCompras}
                    titulo="Listado de compras"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className='row'>
                                <div className='col-6 mb-2'>
                                    <Field
                                        autoFocus={autoFocus}
                                        name="fechaInicial"
                                        component={renderDayPickerB}
                                        msj="Fecha del"
                                    />
                                </div>
                                <div className='col-6 mb-2'>
                                    <Field
                                        name="fechaFinal"
                                        component={renderDayPickerB}
                                        msj="Fecha al"
                                    />
                                </div>
                            </div>
                            <div className='col-12 mb-2'>
                                <Field
                                    name="proveedor"
                                    placeholder="Proveedor..."
                                    className="form-control-find"
                                    component={AsyncSelectField}
                                    loadOptions={listarProveedores}
                                    msj="Proveedor"
                                />
                            </div>
                            <div className="mb-3 col-sm-12 col-md-4">
                            </div>
                        </Contenido>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div className='row'>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={reporteListadoCompras}
                                    >
                                    Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn btn-secondary'
                                        onClick={() => cambiarEstadoModalListadoCompras(false)}
                                    >
                                        Cancelar
                                    </button>
                            </div>
                            </Contenido>
                        </LoadMask>
                </Modal> 

                <Modal
                    estado={estadoModalListadoAbonos} //Listado de abonos
                    cambiarEstado={cambiarEstadoModalListadoAbonos}
                    titulo="Listado de abonos"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className='row'>
                                <div className='col-6 mb-2'>
                                    <Field
                                        autoFocus={autoFocus}
                                        name="fechaInicial"
                                        component={renderDayPickerB}
                                        msj="Fecha del"
                                    />
                                </div>
                                <div className='col-6 mb-2'>
                                    <Field
                                        name="fechaFinal"
                                        component={renderDayPickerB}
                                        msj="Fecha al"
                                    />
                                </div>
                            </div>
                            <div className='col-12 mb-2'>
                                <Field
                                    name="proveedor"
                                    placeholder="Proveedor..."
                                    className="form-control-find"
                                    component={AsyncSelectField}
                                    loadOptions={listarProveedores}
                                    msj="Proveedor"
                                />
                            </div>
                            <div className="mb-3 col-sm-12 col-md-4">
                            </div>
                        </Contenido>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div className='row'>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={reporteListadoAbonos}
                                    >
                                    Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn btn-secondary'
                                        onClick={() => cambiarEstadoModalListadoAbonos(false)}
                                    >
                                        Cancelar
                                    </button>
                            </div>
                            </Contenido>
                        </LoadMask>
                </Modal>
                <Modal
                    estado={estadoModalAnticiposNoAplicados} //Anticipos no aplicacdos
                    cambiarEstado={cambiarEstadoModalAnticiposNoAplicados}
                    titulo="Anticipos no aplicados"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className='col-12 mb-2'>
                                <Field
                                    name="proveedor"
                                    placeholder="Proveedor..."
                                    className="form-control-find"
                                    component={AsyncSelectField}
                                    loadOptions={listarProveedores}
                                    msj="Proveedor"
                                />
                            </div>
                            <div className='col-6 mb-2'>
                                <Field
                                    name="fechaFinal"
                                    component={renderDayPickerB}
                                    msj="Fecha al"
                                          
                                />
                            </div>
                        </Contenido>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div className='row'>
                                    <button
                                        className='btn mr-2 mt-2 mb-3 btn-primary'
                                        onClick={reporteAnticipos}
                                    >
                                    Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn btn-secondary'
                                        onClick={() => cambiarEstadoModalAnticiposNoAplicados(false)}
                                    >
                                        Cancelar
                                    </button>
                            </div>
                            </Contenido>
                        </LoadMask>
                </Modal>  
            </div>
        </div>
    );

}

InformesForm.propTypes = {
    reporteListaFacturas: PropTypes.func,
    reporteLibroCompras: PropTypes.func,
    loader: PropTypes.bool,
    excelLibroCompras: PropTypes.func,
    modulo: PropTypes.object,
    listarProveedores: PropTypes.object,
    monedas: PropTypes.object,
    reporteEstadoCuenta: PropTypes.func,
    reporteAntiguedadSaldos: PropTypes.func,
    reporteProveedores: PropTypes.func,
    reporteListadoCompras: PropTypes.func,
    reporteListadoAbonos: PropTypes.func
};

export default reduxForm({
    form: 'FormInformesC',

})(InformesForm);


const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;
