import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';
const valEmp = __CONFIG__.empresa;

const LOADER = 'LOADER';
const LISTADO_EMPRESAS = 'LISTADO_EMPRESAS';
const GUARDAR_EMPRESA = 'GUARDAR_EMPRESA';
const LISTADO_MONEDAS = 'LISTADO_MONEDAS';
const LISTADO_PAISES = 'LISTADO_PAISES';
const PARAMETRO = 'PARAMETRO';
const MODULOS = 'MODULOS';
const MODULOS_ASIGNADOS = 'MODULOS_ASIGNADOS';
const CONTADOR = 'CONTADOR';
const CONTABILIDAD = 'CONTABILIDAD';
const COMPRAS = 'COMPRAS';
const FACTURACION = 'FACTURACION';

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listar = (page = 1) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params;
    let id = ''
    if (valEmp === 'fage'){
        id = id_emp[6];
        params = { page, id };
    }else{
        id = id_emp[5];
        params = { page, id };
    }
    
    dispatch(setLoader(true));
    api.get('/empresa', params).then((response)=>{
        dispatch({ type: LISTADO_EMPRESAS, empresa: response });
        api.get('/parametro').then((datos)=>{
            dispatch({ type: PARAMETRO, parametro: datos });
        }).catch((error)=>{
            NotificationManager.error(
                'Ocurrió un error consultar parametro',
                'Error',
                6000
            );
        })
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar las empresas',
            'Error',
            6000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/empresa/${id}`).then((response) => {
        dispatch({type: GUARDAR_EMPRESA, lectura: response });
        dispatch(initializeForm('FormEmpresa', response ));
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar la empresa',
            'Error',
            6000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const registroEmpresa = (data={},attachments=[]) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const formData = {
        identificador: data.identificador,
        razon_social: data.razon_social,
        direccion: data.direccion,
        telefono: data.telefono,
        pais: data.pais
    }
    dispatch(setLoader(true));
    api.postAttachments('/empresa/', formData, attachments).then(() => {
        NotificationManager.success(
            'Empresa creada',
            'Exito',
            3000
        );
        let ruta = '';
        if (valEmp === 'fage'){
            ruta = `/lfg/ajustes/${id_emp[6]}/empresas`
            dispatch(push(ruta));
        }
        if (valEmp === ''){
            ruta = `/ajustes/${id_emp[5]}/empresas`
            dispatch(push(ruta));
        }
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al registrar la empresa',
            'Error',
            6000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const modificarEmpresa = (data={},attachments=[]) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const id = data.id;
    const formData = {
        id: id,
        identificador: data.identificador,
        razon_social: data.razon_social,
        direccion: data.direccion,
        telefono: data.telefono,
        pais: data.pais
    }
    dispatch(setLoader(true));
    api.putAttachments(`/empresa/${id}/`, formData, attachments).then((response) => {
        NotificationManager.success(
            'Empresa modificada correctamente',
            'Exito',
            3000
        );
        let ruta = '';
        if (valEmp === 'fage'){
            ruta = `/lfg/ajustes/${id_emp[6]}/empresas`
            dispatch(push(ruta));
        }
        if (valEmp === ''){
            ruta = `/ajustes/${id_emp[5]}/empresas`
            dispatch(push(ruta));
        }
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al modificar la empresa',
            'Error',
            6000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const modificarParametro = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const datos = getStore().form.FormParametrosConta.values;
    const formData = {
        id: id,
        centro_costo: datos.centro_costo
    }
    dispatch(setLoader(true));
    api.put('/empresa/empresa_parametro/', formData).then((response) => {
        NotificationManager.success(
            'Parametros modificados correctamente',
            'Exito',
            3000
        );
        let ruta = '';
        if (valEmp === 'fage'){
            ruta = `/lfg/ajustes/${id_emp[6]}`
            dispatch(push(ruta));
        }
        if (valEmp === ''){
            ruta = `/ajustes/${id_emp[5]}`
            dispatch(push(ruta));
        }
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al modificar parametro',
            'Error',
            6000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const listarMonedas = () => (dispatch) => {
    api.get('/monedas').then((response)=>{
        const moneda = [];
        response.results.forEach(item=>{
            moneda.push({
                value: item.id,
                label: item.moneda + ' - ' + item.simbolo
            })
        })
        dispatch({ type: LISTADO_MONEDAS, listaM: moneda });
       
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar monedas',
            'Error',
            6000
        );
    })
}

const listarPaises = () => (dispatch) => {
    return api.get("/paises").then(response=>{
        const pais = [];
        response.results.forEach(item=>{
            pais.push({
                value: item.id,
                label: item.pais
            })
        })
        dispatch({ type: LISTADO_PAISES, listaP: pais });
    }).catch(error=>{
        
        NotificationManager.error(
            'Ocurrió un error al listar paises',
            'Error',
            6000
        );
    })
}

const leerParametro = (id) => () => {
    return api.get(`/empresa/${id}`).then((response) => {
        return response.centro_costo;
    }).catch(error=>{
        NotificationManager.error(
            'Ocurrió un error al consultar parametros módulo contabilidad',
            'Error',
            6000
        );
    })
}

const leerParametroCompras = (id) => () => {
    return api.get(`/empresa/${id}`).then((response) => {
        const params = [];
        params.push({
            dpi: response.validar_dpi,
            nit: response.validar_nit,
            tc: response.tipo_cambio
        })
        return params
    }).catch(error=>{
        NotificationManager.error(
            'Ocurrió un error al consultar parametros módulo compras',
            'Error',
            6000
        );
    })
}

const leerParametroFacturacion = (id) => () => {
    return api.get(`/empresa/${id}`).then((response) => {
        const params = [];
        params.push({
            nit: response.validar_nit,
            tc: response.tipo_cambio,
            listaPrecio: response.listado_precios
        })
        return params
    }).catch(error=>{
        NotificationManager.error(
            'Ocurrió un error al consultar parametros módulo facturación',
            'Error',
            6000
        );
    })
}

export const modificarParametroCompras = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const datos = getStore().form.FormParametrosCompras.values;
    const formData = {
        id: id,
        nit: datos.nit,
        dpi: datos.dpi,
        tcambio: datos.tcambio
    }

    dispatch(setLoader(true));
    api.put('/empresa/empresa_parametro_compras/', formData).then(() => {
        NotificationManager.success(
            'Parametros modificados correctamente',
            'Exito',
            3000
        );
        let ruta = '';
        if (valEmp === 'fage'){
            ruta = `/lfg/ajustes/${id_emp[6]}`
            dispatch(push(ruta));
        }
        if (valEmp === ''){
            ruta = `/ajustes/${id_emp[5]}`
            dispatch(push(ruta));
        }
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al modificar parametro',
            'Error',
            6000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const modificarParametroFacturacion = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const datos = getStore().form.FormParametrosFacturacion.values;
    const formData = {
        id: id,
        nit: datos.nit,
        tcambio: datos.tcambio,
        listaPrecio: datos.lista_precios
    }

    dispatch(setLoader(true));
    api.put('/empresa/empresa_parametro_facturacion/', formData).then(() => {
        NotificationManager.success(
            'Parametros modificados correctamente',
            'Exito',
            3000
        );
        let ruta = '';
        if (valEmp === 'fage'){
            ruta = `/lfg/ajustes/${id_emp[6]}`
            dispatch(push(ruta));
        }
        if (valEmp === ''){
            ruta = `/ajustes/${id_emp[5]}`
            dispatch(push(ruta));
        }
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al modificar parametro',
            'Error',
            6000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const listarModulos = () => (dispatch) => {
    let modulos = []
    dispatch(setLoader(true));
    api.get('/modulos').then((response)=>{
        response.results.forEach(item=>{
            if (item.codigo === '01' || item.codigo === '02' || item.codigo === '03' || item.codigo === '04' || item.codigo === '05' || item.codigo === '09'){
                modulos.push({
                    value: item.id,
                    label: item.modulo + ' [ ' + item.descripcion + ' ]',
                })
            }
        })
        dispatch({ type: MODULOS, modulo: modulos });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar modulos',
            'Error',
            6000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const getModulos = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5]
    let modulos_asig = []
    let params = {id}
    dispatch(setLoader(true));
    api.get('/modulos/modulo_empresa/', params).then((response)=>{
        if (response.admin === 1){
            response.modulos.forEach(item=>{
                modulos_asig.push({
                    modulo: item.modulo,
                    fecha_alta: item.fecha_alta
                })
            })
        }
        dispatch({ type: MODULOS_ASIGNADOS, moduloAsig: modulos_asig });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar modulos asignados',
            'Error',
            6000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const getModulosParametros = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5]
    let params = {id}
    dispatch(setLoader(true));
    api.get('/modulos/modulo_empresa/', params).then((response)=>{
        let ajuste = '';
        let conta = '';
        let compra = '';
        let facturacion = ''; 
        let cant = 0;
        if (response.admin === 1){
            response.modulos.forEach(item=>{
                if (item.modulo === 'AJUSTES'){
                    ajuste = '00';
                    cant += 1;
                } 
                if (item.modulo === 'CONTABILIDAD'){
                    conta =  '01';
                    cant += 1;
                } 
                if (item.modulo === 'COMPRAS Y CXP'){
                    compra = '02';
                    cant += 1;
                }
                if (item.modulo === 'FACTURACIÓN Y CXC'){
                    facturacion = '04';
                    cant += 1;
                }
            })
        }
        dispatch({ type: CONTADOR, cantidadMod: cant });
        dispatch({ type: CONTABILIDAD, contaPr: conta });
        dispatch({ type: COMPRAS, compraPr: compra });
        dispatch({ type: FACTURACION, factPr: facturacion });
    }).catch(()=>{
        NotificationManager.error(
            'Ocurrió un error al listar modulos asignados',
            'Error',
            6000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}


export const registroModulo = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormConfigModulos.values;
    if (data === undefined || data.modulo === ''){
        Swal.fire({
            title: 'Módulos',
            text: 'Debe de seleccionar un módulo',
            type: 'error',
        })
    }else{
        const formData = {
            modulo: data.modulo,
            empresa: id_emp[5]
        }
        dispatch(setLoader(true));
        api.post('/modulos/asignar/', formData).then(() => {
            NotificationManager.success(
                'Módulo agregado',
                'Exito',
                3000
            );
            dispatch(getModulos());
        }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                2000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
}

export const actions = {
    registroEmpresa,
    modificarEmpresa,
    listar,
    leer,
    listarPaises,
    listarMonedas,
    modificarParametro,
    leerParametro,
    leerParametroCompras,
    modificarParametroCompras,
    listarModulos,
    getModulos,
    registroModulo,
    leerParametroFacturacion,
    modificarParametroFacturacion,
    getModulosParametros
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_EMPRESAS]: (state, { empresa }) => {
        return {
            ...state,
            empresa,
        };
    },
    [GUARDAR_EMPRESA]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
    [LISTADO_MONEDAS]: (state, { listaM }) => {
        return {
            ...state,
            listaM,
        };
    },
    [LISTADO_PAISES]: (state, { listaP }) => {
        return {
            ...state,
            listaP,
        };
    },
    [PARAMETRO]: (state, { parametro }) => {
        return {
            ...state,
            parametro,
        };
    },
    [MODULOS]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [MODULOS_ASIGNADOS]: (state, { moduloAsig }) => {
        return {
            ...state,
            moduloAsig,
        };
    },
    [CONTADOR]: (state, { cantidadMod }) => {
        return {
            ...state,
            cantidadMod,
        };
    },
    [CONTABILIDAD]: (state, { contaPr }) => {
        return {
            ...state,
            contaPr,
        };
    },
    [COMPRAS]: (state, { compraPr }) => {
        return {
            ...state,
            compraPr,
        };
    },
    [FACTURACION]: (state, { factPr }) => {
        return {
            ...state,
            factPr,
        };
    },
};

export const initialState = {
    loader: false,
    empresa: null,
    lectura: [],
    listaM: [],
    listaP: [],
    parametro: null,
    logo: [],
    modulo: [],
    moduloAsig: [],
    moduloAsigPr: [],
    cantidadMod: [],
    contaPr: [],
    compraPr: [],
    factPr: []
};

export default handleActions(reducers, initialState)