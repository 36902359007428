import React, { Component } from 'react';
import Formulario from './SerieForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";

class Serie extends Component{
    state = {
        crear: true,
    }

    componentDidMount = () => {
        const { getModulo, listarSeries, listarBodegas} = this.props;
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        
        getModulo(modulo[5],modulo[4]);
        listarSeries();
        listarBodegas();
    }
    
    render(){
        const { registroSerie, loader, lectura, modulo, lista, listaBodegas, eliminarSerie } = this.props;
        const { crear } = this.state;
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    crear = {crear}
                    lectura = {lectura}
                    modulo = {modulo}
                    listar = {lista}
                    registroSerie = {registroSerie}
                    listaBodegas = {listaBodegas}
                    eliminarSerie = {eliminarSerie}
                />
            </LoadMask>
        );
    }
}

export default Serie;

