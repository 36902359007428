import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/produccion/corteCaja';
import CorteCajaList from './CorteCajaList';


const ms2p = (state) => {
  return {
    ...state.corte_caja_prd,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(CorteCajaList);
