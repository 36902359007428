import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/auto_ventas/marcas';
import MarcasList from './MarcasList';


const ms2p = (state) => {
  return {
    ...state.auto_ventas_marcas,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(MarcasList);
