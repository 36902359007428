import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/auto_ventas/vendedores/vendedores';
import Vendedores from './Vendedores';


const ms2p = (state) => {
  return {
    ...state.auto_ventas_vendedores,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p,)(Vendedores);
