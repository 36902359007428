import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/registro_marca/agente';
import AgenteList from './AgenteList';


const ms2p = (state) => {
  return {
    ...state.agente_rm,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(AgenteList);
