import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/produccion/piloto';
import Piloto from './PilotoList';


const ms2p = (state) => {
    return {
        ...state.piloto,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Piloto);
