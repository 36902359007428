import React from "react";
import styled from "styled-components";
import { keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const Overlay = styled.div`
    font-size: 1.5rem;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
    border-radius: 1rem;
    animation: ${({ isActive }) => (isActive ? fadeIn : fadeOut)} 0.3s
        ease-in-out forwards;
    pointer-events: ${({ isActive }) => (isActive ? "auto" : "none")};
    cursor: ${({ isActive }) => (isActive ? "default" : "initial")};
    z-index: 20;
`;

export default Overlay;
