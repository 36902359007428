import React, { useEffect } from 'react';
import Grid from '../../Utils/GridFiltro';
import PropTypes from 'prop-types';
import moment from 'moment';
//import Swal from 'sweetalert2';
import { standardActions } from '../../Utils/Grid/StandardActions';
import NavbarModulo from '../../components/NavbarModulo';

function ImportacionList(props) {
    const {
        data, loader, 
        //eliminarImportacion, 
        periodoD, modulo,
        imprimirProrrateo
    } = props;

    useEffect(() => {
        const { listar, leerPeriodoD, getModulo } = props;
        let ruta = window.location.href;
        let periodo = ruta.split('/');
        leerPeriodoD(periodo[5], periodo[7]);
        getModulo(periodo[5],periodo[4]);
        listar();
    }, [])
    
    let ruta = window.location.href;
    let datos = ruta.split('/');
    return (
        <React.Fragment>
            <NavbarModulo
                periodoD = {periodoD}
                modulo = {modulo}
            />
            <br />
            <h3 className="p-2 bg-secondary title">Importaciones</h3>
            <br />
            {periodoD.estado == 'A' &&
                <div className="d-flex flex-row justify-content-start mb-2">
                    <a
                        href={`/#/compras/${datos[5]}/importaciones/${datos[7]}/crear`}
                        className='btn btn-primary'
                    >
                        Crear Importación
                    </a>
                </div>
            }
            {data && periodoD.estado == 'A' &&
                <Grid
                    hover
                    striped
                    data={data}
                    loading={loader}
                    headerStyle={{ background: 'black' }}
                    //onPageChange={onPageChange}
                    //onSortChange={onSortChange} 
                >
                    <TableHeaderColumn
                        isKey
                        dataField="fecha"
                        width="200"
                        dataSort
                        dataFormat={(cell, row) => {
                            return moment(cell).format("DD/MM/YYYY");
                        }}
                        thStyle={
                            { color: 'white' }}
                        tdStyle={
                            { color: 'white' }}
                    >
                        Fecha
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="declaracion"
                        dataSort
                        width="250"
                        thStyle={
                            { color: 'white' }}
                        tdStyle={
                            { color: 'white' }}
                    >
                        Declaración
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="descripcion"
                        dataSort
                        thStyle={
                            { color: 'white' }}
                        tdStyle={
                            { color: 'white' }}
                    >
                        Descripción
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        width="200"
                        thStyle={
                            { color: 'white' }}
                        dataField="id"
                        dataAlign="center"
                        dataSort
                        dataFormat={standardActions({
                            editar_a: "importaciones",
                            ver_a: "importaciones",
                            imprimir: imprimirProrrateo,
                            empresa: datos[5],
                            periodo: datos[7],
                            modulo: datos[4],
                        })}

                    >
                        Acciones
                    </TableHeaderColumn>
                </Grid>
            }
            {data && periodoD.estado == 'C' &&
                <Grid
                    hover
                    striped
                    data={data}
                    loading={loader}
                    headerStyle={{ background: 'black' }}
                    //onPageChange={onPageChange}
                    //onSortChange={onSortChange} 
                >
                    <TableHeaderColumn
                        isKey
                        dataField="fecha"
                        width="200"
                        dataSort
                        dataFormat={(cell, row) => {
                            return moment(cell).format("DD/MM/YYYY");
                        }}
                        thStyle={
                            { color: 'white' }}
                        tdStyle={
                            { color: 'white' }}
                    >
                        Fecha
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="declaracion"
                        dataSort
                        width="250"
                        thStyle={
                            { color: 'white' }}
                        tdStyle={
                            { color: 'white' }}
                    >
                        Declaración
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="descripcion"
                        dataSort
                        thStyle={
                            { color: 'white' }}
                        tdStyle={
                            { color: 'white' }}
                    >
                        Descripción
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        width="200"
                        thStyle={
                            { color: 'white' }}
                        dataField="id"
                        dataAlign="center"
                        dataSort
                        dataFormat={standardActions({
                            ver_a: "importaciones",
                            imprimir: imprimirProrrateo,
                            empresa: datos[5],
                            periodo: datos[7],
                            modulo: datos[4],
                        })}

                    >
                        Acciones
                    </TableHeaderColumn>
                </Grid>
            }
        </React.Fragment>
    );
}

ImportacionList.propTypes = {
    data: PropTypes.object,
    loader: PropTypes.bool,
    onPageChange: PropTypes.func,
    onSortChange: PropTypes.func,
    eliminarImportacion: PropTypes.func,
    //revisarTipoCambio: PropTypes.func,
    periodoD: PropTypes.object,
    modulo: PropTypes.object
};


export default ImportacionList;
