import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { reduxForm } from 'redux-form';
import { Link, Redirect } from 'react-router-dom';

const estilo = {
    fontSize: "18px",
};
const estilo2 = {
    color: "white",
};
function ActivarCuenta(props) {
    
    useEffect(() => {
        const { activar } = props;
        activar();
    }, [])
    return (
        <div className="blue-gradient-bg">
            <div className="d-flex flex-column align-items-center pt-3 bienvenida title">
                <img className="mt-5 imageReg"
                    src={require('assets/img/SIFW-LOGO-Red.png')}
                    alt="Logo" />
            </div>
            <br />
            <div className="login-wrapper">
                <div className="card2 card border-0 px-4 py-5 bg-dark col-lg-6 col-md-4 col-11">
                    <h2 className="text-center" style={estilo2}>Su cuenta esta activa</h2>
                    <h4 className="text-center" style={estilo2}>Ya puede iniciar sesión</h4>
                    <Link to="/login"
                        style={estilo}
                        className="btn btn-secondary btn-sm mr-2 align-self-center"
                    >
                        Iniciar sesión
                    </Link>
                </div>
            </div>
        </div>
    );

}
ActivarCuenta.propTypes = {
    activar: PropTypes.func,
};
export default reduxForm({
    form: 'Registro', //identificador unico
})(ActivarCuenta)

