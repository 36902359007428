import React, { useState, useEffect } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import {
  renderFieldCheckB,
} from "../Utils/renderField/renderField";

function ParametrosContabilidad(props) {
  const { handleSubmit } = props;
  const [cc, setCC] = useState(false);

  useEffect(() => {
    const { leerParametro } = props;
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const id = id_emp[5];
    let data;
    if (id) {
      data = leerParametro(id);
      let es;
      data.then(value => {
        es = value
        if (es === 'S') {
          setCC(true);
        }
      });
    }
  }, [])

  const onCheckboxClicked = (idx, isChecked) => {
    setCC(isChecked);
  }
  let ruta = window.location.href;
  let id_emp = ruta.split('/');

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <br />
        <div className="mb-4 card card-small text-white bg-dark mb-3">
          <div className="p-0 pt-3 d-flex flex-column flex-md-row">
            <div className="d-flex flex-column flex-1 mx-4">
              <div className='mb-2 w-50'>
                <div className="mb-3">
                  <strong className="text-muted d-block mb-2">Parametros</strong>
                  <Field
                    name="centro_costo"
                    label="Centro de Costo"
                    checked={cc}
                    onChange={onCheckboxClicked}
                    component={renderFieldCheckB}
                  />
                </div>
              </div>
              <div className='d-flex flex-row mt-3'>

                <button
                  className="btn mr-2 mb-3 btn-primary"
                  type='submit'
                >
                  Guardar
                </button>

                <a
                  href={`/#/ajustes/${id_emp[5]}`}
                  className='btn btn-secondary mb-3'
                >
                  Regresar
                </a>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );

}

export default reduxForm({
  form: 'FormParametrosConta',

})(ParametrosContabilidad);
