import { handleActions, createAction } from 'redux-actions';
import { NotificationManager } from "react-notifications";
import { api } from "api";


const ListarLineasAction = createAction("LISTAR_LINEAS")

export const listarLineas = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id }
    return api.get('auto_ventas/linea', params).then(response => {
        // dispatch({type: LISTAR_TIPOS, data: response})
        dispatch(ListarLineasAction(response));
    }).catch((error) => {
        return [];
    })
}

export const editarLinea = (data, lineaId) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const formData = {
        empresa: id_emp[5],
        nombre: data.linea,
        marca: data.marca.value,
    }
    return api.put(`auto_ventas/linea/${lineaId}`, formData).then(() => {
        NotificationManager.success(
            'Linea Editada',
            'Exito',
            3000
        );
    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            2000
        );
    })
}



export const listarMarcas = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('auto_ventas/marca', params).then(data => {
        const listaLineas = [];
        if (data) {
            data.forEach(item => {
                listaLineas.push({
                    value: item.id,
                    label: item.nombre
                })
            })
        }
        return listaLineas;
    }).catch((error) => {
        return [];
    })
}

export const crearLinea = (data) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const formData = {
        nombre: data.linea,
        marca: data.marca.value,
        empresa: id_emp[5],
    }
    return api.post('auto_ventas/linea', formData).then(() => {
        NotificationManager.success(
            'linea agregada',
            'Exito',
            3000
            );
    })
    .catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            2000
        );
    })
}



export const actions = {
    crearLinea,
    editarLinea,
    listarMarcas,
    listarLineas
};

export const reducers = {
    [ListarLineasAction]: (state, action) => {
        return {
            ...state,
            data: action.payload
        }
    },
};

export const initialState = {
    data: [],
    modulo: []
};


export default handleActions(reducers, initialState)