import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
    FicComboBox,
    FicInput,
    FicTextArea,
    FicNumber,
} from "../../Utils/FormInputComponents/index";
import "../../../../../style/autoVentas/VentasForm.css";
import GenBtn from "../../components/GenBtn";
import { withRouter } from "react-router";
import styled, { keyframes } from "styled-components";
import LoadMask from "../../Utils/LoadMask/LoadMask";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const Overlay = styled.div`
    font-size: 1.5rem;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
    border-radius: 1rem;
    animation: ${({ isActive }) => (isActive ? fadeIn : fadeOut)} 0.3s
        ease-in-out forwards;
    pointer-events: ${({ isActive }) => (isActive ? "auto" : "none")};
    cursor: ${({ isActive }) => (isActive ? "default" : "initial")};
    z-index: 1;
`;

const FicComboBoxStyled = styled(FicComboBox)`
    & > .rs__menu {
        z-index: 3;
    }
`;

const DialogContent = styled.div`
    height: 205px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;
`;

function VendedoresForm(props) {
    const { datosDeVenta, history } = props;

    const urlParams = props.match.params;
    const empresa = urlParams.empresa;
    const ventaId = urlParams.ventaId;
    const [loadingState, setLoadingState] = useState(true);

    const {
        control,
        formState: { errors },
    } = useForm({
        mode: "all",
        defaultValues: async () => {
            const formData = await datosDeVenta(ventaId);
            setLoadingState(false);
            return formData;
        },
    });

    return (
        <React.Fragment>
            <br />
            <h3
                className="p-2  title rounded shadow"
                style={{
                    background:
                        "linear-gradient(to top right, #790021, #2d2d2d)",
                }}
            >
                Ver Venta
            </h3>
            <br />
            <div className="d-flex flex-row justify-content-start mb-2">
                <GenBtn
                    BtnColor="#787878"
                    style={{ width: "100px", height: "40px" }}
                    onPress={(e) => {
                        history.push(`/auto_ventas/${empresa}/ventas`);
                    }}
                >
                    Regresar
                </GenBtn>
            </div>
            <LoadMask loading={loadingState} color="#bd0034" blur={true}>
                <form encType="multipart/form-data" onSubmit={() => {}}>
                    <div style={{ color: "#bd0034" }}>DATOS DEL VEHICULO</div>
                    <div className="ventas-form__auto-data">
                        <div className="ventas-form__inputs-container">
                            <FicInput
                                label="Placa"
                                name="placa"
                                control={control}
                                isDisabled={true}
                            />
                            <FicInput
                                label="Codigo"
                                name="codigo"
                                control={control}
                                isDisabled={true}
                            />
                            <FicInput
                                label="Tipo de carro"
                                name="tipo_carro"
                                control={control}
                                isDisabled={true}
                            />
                            <FicInput
                                label="Modelo"
                                name="modelo"
                                control={control}
                                isDisabled={true}
                            />
                            <FicInput
                                label="Linea"
                                name="linea"
                                control={control}
                                isDisabled={true}
                            />

                            <FicInput
                                label="Numero de serie"
                                name="num_serie"
                                control={control}
                                isDisabled={true}
                            />
                            <FicInput
                                label="Modalidad"
                                name="modalidad"
                                control={control}
                                isDisabled={true}
                            />
                        </div>
                    </div>

                    <FicInput
                        componentStyle={{
                            marginTop: "15px",
                            marginBottom: "15px",
                        }}
                        control={control}
                        name="carro"
                        label="Carro"
                        isDisabled={true}
                    />

                    <div className="ventas-form__form-container">
                        <div style={{ color: "#bd0034" }}>
                            DATOS DE LA VENTA
                        </div>
                        <div className="ventas-form__inputs-container">
                            <FicInput
                                control={control}
                                name="fecha_venta"
                                label="Fecha"
                                isDisabled={true}
                            />
                            <FicInput
                                control={control}
                                name="vendedor"
                                label="Vendedor"
                                isDisabled={true}
                            />
                            <FicInput
                                label="Comprador"
                                name="comprador_nombre"
                                control={control}
                                isDisabled={true}
                            />
                            <FicInput
                                label="Numero de DPI"
                                name="comprador_dpi"
                                control={control}
                                isDisabled={true}
                            />
                            <FicInput
                                label="Domicilio fiscal"
                                name="comprador_domicilio"
                                control={control}
                                isDisabled={true}
                            />
                            <FicInput
                                label="Departamento"
                                name="comprador_departamento"
                                control={control}
                                isDisabled={true}
                            />
                            <FicInput
                                label="Profesion u oficio"
                                name="comprador_profesion"
                                control={control}
                                isDisabled={true}
                            />
                            <FicInput
                                label="Numero de Telefono"
                                name="comprador_num_telefono"
                                control={control}
                                isDisabled={true}
                            />
                            <FicInput
                                label="NIT"
                                name="comprador_nit"
                                control={control}
                                isDisabled={true}
                            />
                            <FicInput
                                label="Edad"
                                name="comprador_edad"
                                control={control}
                                isDisabled={true}
                            />
                            <FicInput
                                label="Estado civil"
                                name="comprador_estado_civil"
                                control={control}
                                isDisabled={true}
                            />
                            <FicNumber
                                formatOptions={{
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }}
                                label="Valor de venta"
                                name="valor_venta"
                                control={control}
                                isDisabled={true}
                            />
                            <FicTextArea
                                label="Observaciones"
                                name="observaciones"
                                control={control}
                                isDisabled={true}
                                style={{ gridColumn: "span 2" }}
                            />
                        </div>
                    </div>
                </form>
            </LoadMask>
        </React.Fragment>
    );
}

export default withRouter(VendedoresForm);
