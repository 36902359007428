import React, { Component } from 'react';
import Formulario from './PolizaParqueoForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";

class PolizaParqueo extends Component{
    state = {
        crear: true,
    }
    componentDidMount() {
        const { match, leer, getModulo, limpiar, leerPeriodoD } = this.props;
        const id = match.params.id;
        if (id){
            this.setState({ crear: false });
            leer(id);
        }
        let ruta = window.location.href;
        let periodo = ruta.split('/');
        leerPeriodoD(periodo[5], periodo[7]);
        getModulo(periodo[5],periodo[4]);
        limpiar();
    }
    
    render(){
        const { loader, modulo, generarPoliza, detalle_poliza,
            listarCuentas, polizas, periodoD, guardarPoliza } = this.props;
        const { crear } = this.state;
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    crear = {crear}
                    modulo={modulo}
                    listarCuentas = {listarCuentas}
                    polizas = {polizas}
                    generarPoliza = {generarPoliza}
                    detalle_poliza = {detalle_poliza}
                    periodoD = {periodoD}
                    guardarPoliza = {guardarPoliza}
                />
           </LoadMask>
        );
    }
}

export default PolizaParqueo;
