import React, { Component } from 'react';
import Formulario from './InformesForm';

class Informes extends Component{
    componentDidMount = () => {
        const { getModulo } = this.props;
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        getModulo(modulo[5],modulo[4]);
    }
    render(){
        const { 
            loader,
            modulo, 
            listarCuentasBancarias, 
            listarProveedores, 
            listarClientes, 
            reporteDiarioBancos, 
            reporteListadoEgresos,
            reporteListadoIngresos,
            excelDiarioBancos,
            reporteConciliacion
        } = this.props;
        return(
            <div>
                <Formulario
                    loader = {loader}
                    modulo = {modulo}
                    listarCuentasBancarias = {listarCuentasBancarias}
                    listarProveedores = {listarProveedores}
                    listarClientes= {listarClientes}
                    reporteDiarioBancos = { reporteDiarioBancos }
                    reporteListadoEgresos = {reporteListadoEgresos}
                    reporteListadoIngresos = {reporteListadoIngresos}
                    excelDiarioBancos = {excelDiarioBancos}
                    reporteConciliacion = {reporteConciliacion}
                />
                
            </div>
        );
    }
}

export default Informes;