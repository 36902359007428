import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BootstrapTable } from 'react-bootstrap-table';
//import { BootstrapTable } from 'react-bootstrap-table-next';
import LoadMask from "../LoadMask/LoadMask";


export default class Grid extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false
    };

    render() {
        const {
            loading,
            data,
            page,
            remote=true,
            expandableRow,
            expandComponent,
            cellEditProp,
            afterSave,
            onPageChange,
            onSortChange,
            pagination,
            expanding,
            onExpand,
            footer,
            trClassName,
            ...other
        } = this.props;
        const options = {
            sizePerPage: 10,
            hideSizePerPage: true,
            paginationSize: 5,
            alwaysShowAllBtns: true,
            noDataText: loading ? 'Cargando...' : <span className='title'>No hay datos</span>,
            page,
            onPageChange: onPageChange ? onPageChange : () => {},
            onSortChange: onSortChange ? onSortChange : () => {},
            onExpand:onExpand
        };
        const footerData = [
            [
              {
                label: <strong className='title'>Total:</strong>,
                columnIndex: 1,
                align: 'center',
              },
              {
                label: 'Total value',
                columnIndex: 2,
                align: 'right',
                formatter: (tableData) => {
                    const debe = tableData.reduce(function (resultado, elemento) {
                        return parseFloat(resultado) + parseFloat(elemento.total_debe);
                    }, 0.00);
                    return (
                        <strong className='title'>{ new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(debe) }</strong>
                    );
                }
              },
              {
                label: 'Total value',
                columnIndex: 3,
                align: 'right',
                formatter: (tableData) => {
                    const haber = tableData.reduce(function (resultado, elemento) {
                        return parseFloat(resultado) + parseFloat(elemento.total_haber);
                    }, 0.00);
                    return (
                        <strong className='title'>{ new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(haber) }</strong>
                    );
                }
              },
            ],
            [
                {
                    label: <strong className='title'>Diferencia:</strong>,
                    columnIndex: 0,
                    align: 'center',
                },
                {
                    label: 'Total value',
                    columnIndex: 2,
                    align: 'right',
                    formatter: (tableData) => {
                        const debe = tableData.reduce(function (resultado, elemento) {
                            return parseFloat(resultado) + parseFloat(elemento.total_debe);
                        }, 0.00);
                        const haber = tableData.reduce(function (resultado, elemento) {
                            return parseFloat(resultado) + parseFloat(elemento.total_haber);
                        }, 0.00);
                        let dif = Math.abs(parseFloat(haber)-parseFloat(debe))
                        localStorage.setItem('dif', parseFloat(dif).toFixed(2));
                        return (
                            <strong className='title'>{ new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(dif) }</strong>
                        );
                    }
                  },
            ]
        ];

        const paginar = !(pagination === false);

        return (
            <div>
                <LoadMask loading={loading} dark blur>
                    <BootstrapTable
                        expandableRow={expandableRow}
                        expandComponent={expandComponent}
                        trClassName={trClassName}
                        cellEdit={cellEditProp}
                        data={loading ? [] : data}
                        afterSaveCell={afterSave}
                        remote={remote}
                        footerData={ footerData }
                        footer={footer}
                        fetchInfo={{ dataTotalSize: data.count }}
                        options={options}
                        {...other}
                    />
                </LoadMask>
            </div>

        )
    }
}
