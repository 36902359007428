import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import NavbarModulo from '../../components/NavbarModulo';
import DetalleProducto from './DetalleProducto';
import DetalleProductoMod from './DetalleProductoMod';
import DetalleProductoVer from './DetalleProductoVer';

import {
    renderField,
    SelectField,
} from "../../Utils/renderField/renderField";

function ProductoForm(props) {
    const { registroProducto, modificarProducto, crear, modulo,  obtenerPrincipio,
        obtenerFamilia, obtenerForma, obtenerGrupos, lectura, eliminarLineaProducto,
        obtenerLinea, obtenerConcentracion, detallePro, agregarLinea, eliminarLinea, 
        detalleProducto, agregarLineaProducto  } = props;
    
    const editar = window.location.href.includes('editar');
    let titulo = editar ? 'Modificar producto' : 'Crear producto';
    let disabled = false;
    if (crear === false && editar === false) {
        disabled = true;
        titulo = 'Ver producto'
    }
    let autoFocus = true;
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    
    const estilo = {
        overflow: "auto",
        height: "300px",
    };
    return (
        <div>
            <NavbarModulo
                modulo={modulo}
                titulo={titulo}
            />
            <div>
                <br />
                <div className="mb-4 card card-small text-white bg-dark mb-3">
                    <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                        <div className="d-flex flex-column flex-1 mx-4">
                            <div className='mb-2 row'>
                                <div className='col-12'>
                                    <Field
                                        autoFocus={autoFocus}
                                        name="descripcion"
                                        placeholder="Producto"
                                        className="form-control"
                                        component={renderField}
                                        disabled={disabled}
                                        msj="Producto"
                                    />
                                </div>
                            </div>
                            <div className='mb-2 row'>
                                <div className='col-6'>
                                    <div className="form-floating">
                                        {obtenerGrupos &&
                                            <Field
                                                name="grupo"
                                                options={obtenerGrupos}
                                                crear={crear}
                                                dato={crear === true ? 0 : lectura.grupo_terapeutico}
                                                component={SelectField}
                                                className="form-select"
                                                disabled={disabled}
                                                msj="Grupo Terapeutico"
                                            />
                                        }
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className="form-floating">
                                        {obtenerLinea &&
                                            <Field
                                                name="linea"
                                                options={obtenerLinea}
                                                crear={crear}
                                                dato={crear === true ? 0 : lectura.linea}
                                                component={SelectField}
                                                className="form-select"
                                                disabled={disabled}
                                                msj="Linea"
                                            />
                                        }
                                    </div>
                                </div>
                                
                            </div>
                            <div className='mb-2 row'>
                                <div className='col-6'>
                                    <div className="form-floating">
                                        {obtenerFamilia &&
                                            <Field
                                                name="familia"
                                                options={obtenerFamilia}
                                                crear={crear}
                                                dato={crear === true ? 0 : lectura.familia}
                                                component={SelectField}
                                                className="form-select"
                                                disabled={disabled}
                                                msj="Familia"
                                            />
                                        }
                                        
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className="form-floating">
                                        {obtenerForma &&
                                            <Field
                                                name="forma"
                                                options={obtenerForma}
                                                crear={crear}
                                                dato={crear === true ? 0 : lectura.forma_farmaceutica}
                                                component={SelectField}
                                                className="form-select"
                                                disabled={disabled}
                                                msj="Forma farmacéutica"
                                            />
                                        }
                                        
                                    </div>
                                </div>
                            </div>
                            <h5 className="p-2 bg-secondary title mt-1">Detalle producto</h5>
                            {(crear === true || editar === true) &&
                                <div className='mb-2 row'>
                                    <div className='col-6'>
                                        <div className="form-floating">
                                            {obtenerPrincipio &&
                                                <Field
                                                    name="principio"
                                                    options={obtenerPrincipio}
                                                    crear={crear}
                                                    dato={crear === true ? 0 : lectura.via_administracion}
                                                    component={SelectField}
                                                    className="form-select"
                                                    disabled={disabled}
                                                    msj="Principio activo"
                                                />
                                            }
                                            
                                        </div>
                                    </div>
                                    <div className='col-3'>
                                        <div className="form-floating">
                                            {obtenerConcentracion &&
                                                <Field
                                                    name="concentracion"
                                                    options={obtenerConcentracion}
                                                    crear={crear}
                                                    dato={crear === true ? 0 : lectura.grupo_terapeutico}
                                                    component={SelectField}
                                                    className="form-select"
                                                    disabled={disabled}
                                                    msj="Concentración"
                                            />
                                            }
                                        </div>
                                    </div>
                                    <div className='col-3'>
                                        <div className="form-floating">
                                            <Field
                                                name="unidad"
                                                placeholder="Cantidad"
                                                className="form-control"
                                                component={renderField}
                                                disabled={disabled}
                                                msj="Unidad de dosis"
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                            {(crear === true) &&
                                <Contenido>
                                    <button
                                        className='btn mr-2 mt-3 btn-primary'
                                        onClick={() => agregarLinea()}
                                    >
                                        Agregar linea
                                    </button>
                                </Contenido>
                            }
                            {(editar === true) &&
                                <Contenido>
                                    <button
                                        className='btn mr-2 mt-3 btn-primary'
                                        onClick={() => agregarLineaProducto()}
                                    >
                                        Agregar linea
                                    </button>
                                </Contenido>
                            }
                            <br/>
                            {crear === true &&
                                <div style={estilo}>
                                    <DetalleProducto
                                        detalle={detallePro}
                                        eliminarLinea={eliminarLinea}
                                    />
                                </div>
                            }
                            {editar === true &&
                                <div style={estilo}>
                                    <DetalleProductoMod
                                        detalle={detalleProducto}
                                        eliminarLinea={eliminarLineaProducto}
                                    />
                                </div>
                            }
                            {(crear === false && editar === false) &&
                            <div style={estilo}>
                                <DetalleProductoVer
                                    detalle={detalleProducto}
                                />
                            </div>
                        }
                            <div className='d-flex flex-row mt-3'>
                                {crear == true &&
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        type='submit'
                                        onClick={registroProducto}
                                    >
                                        Guardar
                                    </button>
                                }
                                {editar == true &&
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        type='submit'
                                        onClick={modificarProducto}
                                    >
                                        Modificar
                                    </button>
                                }
                                <a
                                    href={`/#/registro_sanitario/${id_emp[5]}/producto`}
                                    className='btn btn-secondary mb-3'
                                >
                                    Regresar
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

ProductoForm.propTypes = {
    handleSubmit: PropTypes.func,
    crear: PropTypes.bool,
    lectura: PropTypes.object,
    modulo: PropTypes.object,
};

const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;

export default reduxForm({
    form: 'FormProductoRS',
    
})(ProductoForm);
