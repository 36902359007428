import React from "react";
import { Async, Select } from "react-select";
import "./FicComboBox.css";
import { useController } from "react-hook-form";
import { Label } from "react-aria-components";

export default function AsyncSelectField({
    disabled,
    errorMsj,
    description,
    componentStyle,
    label,
    placeholder,
    nonForm,
    nonAsync,
    nonFormNonAsync,
    options,
    ...props
}) {
    const filterOptions = (inputValue) => {
        return options.filter((i) =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    const loadOptions = (inputValue, callback) => {
        callback(filterOptions(inputValue));
    };

    let classPrefix = errorMsj ? "rs-invalid" : "rs";
    if (nonFormNonAsync) {
        return (
            <div
                className={`fic-input-container `}
                style={{ width: "100%", ...componentStyle }}
            >
                <label aria-label="select-label" className="fic-input-label">
                    {label}
                </label>
                <Async
                    isDisabled={disabled}
                    placeholder={placeholder || "Seleccionar..."}
                    defaultOptions
                    backspaceRemovesValue={true}
                    isClearable={true}
                    classNamePrefix={classPrefix}
                    {...props}
                    loadOptions={loadOptions}
                />
                {description && (
                    <span className="description">{description}</span>
                )}
                {errorMsj && (
                    <span className="input-invalid-error-msj">{errorMsj}</span>
                )}
            </div>
        );
    }

    if (nonForm) {
        return (
            <div
                className={`fic-input-container `}
                style={{ width: "100%", ...componentStyle }}
            >
                <label aria-label="select-label" className="fic-input-label">
                    {label}
                </label>
                <Async
                    isDisabled={disabled}
                    placeholder={placeholder || "Seleccionar..."}
                    defaultOptions
                    backspaceRemovesValue={true}
                    cacheOptions
                    isClearable={true}
                    classNamePrefix={classPrefix}
                    {...props}
                />
                {description && (
                    <span className="description">{description}</span>
                )}
                {errorMsj && (
                    <span className="input-invalid-error-msj">{errorMsj}</span>
                )}
            </div>
        );
    }
    const { field, fieldState } = useController(props);
    classPrefix = fieldState.invalid ? "rs-invalid" : "rs";

    if (nonAsync) {
        return (
            <div
                className={`fic-input-container `}
                style={{ width: "100%", ...componentStyle }}
            >
                <label aria-label="select-label" className="fic-input-label">
                    {label}
                </label>
                <Async
                    isDisabled={disabled}
                    placeholder={placeholder || "Seleccionar..."}
                    defaultOptions
                    backspaceRemovesValue={true}
                    cacheOptions
                    isClearable={true}
                    classNamePrefix={classPrefix}
                    {...field}
                    {...props}
                    loadOptions={loadOptions}
                />
                {description && (
                    <span className="description">{description}</span>
                )}
                {fieldState.error && (
                    <span className="input-invalid-error-msj">
                        {fieldState.error.message}
                    </span>
                )}
            </div>
        );
    }

    return (
        <div
            className={`fic-input-container `}
            style={{ width: "100%", ...componentStyle }}
        >
            <label aria-label="select-label" className="fic-input-label">
                {label}
            </label>
            <Async
                isDisabled={disabled}
                placeholder={placeholder || "Seleccionar..."}
                defaultOptions
                backspaceRemovesValue={true}
                cacheOptions
                isClearable={true}
                classNamePrefix={classPrefix}
                {...field}
                {...props}
            />
            {description && <span className="description">{description}</span>}
            {fieldState.error && (
                <span className="input-invalid-error-msj">
                    {fieldState.error.message}
                </span>
            )}
        </div>
    );
}
