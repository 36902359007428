import React, { Component } from 'react';
import Formulario from './PeriodosForm';
import LoadMask from "../Utils/LoadMask/LoadMask";
const valEmp = __CONFIG__.empresa;

class Periodos extends Component{
    state = {
        crear: true,
    }

    componentDidMount() {
        const { match, leer, getModulo } = this.props;
        const id = match.params.id;
        if (id){
            this.setState({ crear: false });
            leer(id);
        }
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        if (valEmp === 'fage'){
            getModulo(modulo[6],modulo[5])
        }else{
            getModulo(modulo[5],modulo[4]);
        }
    }
    render(){
        const { registroPeriodo, modificarPeriodo, loader, lectura, modulo } = this.props;
        const { crear } = this.state;
        const funcionEnvio = crear ? registroPeriodo : modificarPeriodo;
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    onSubmit = {funcionEnvio}
                    crear = {crear}
                    lectura = {lectura}
                    modulo = {modulo}
                />
           </LoadMask>
        );
    } 
}

export default Periodos;