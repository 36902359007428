import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import { validate, validators } from 'validate-redux-form';
import PropTypes from 'prop-types';
import NavbarModulo from '../../components/NavbarModulo';
import TablaObs from './TablaObservaciones';
import classNames from 'classnames';

import {
    renderDayPickerB,
    renderTextArea,
    AsyncSelectField
} from "../../Utils/renderField/renderField";

function ObservacionesForm(props) {
    const { modulo, registroObservacion, listarMarcas, datosMarca,
        detalleObs
    } = props;
    

    const [agente, setAgente] = useState('');
    const [pais, setPais] = useState(''); 

    let titulo = 'Ingresar observación';
    const handleOnChangeMarca = value => {
        let datosM = value.label.split(' -- ');
        setAgente(datosM[1].split(' / ')[0]);
        setPais(datosM[1].split(' / ')[1]);    
        datosMarca(value.value);
        
    };

    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    return (
        <div>
            <NavbarModulo
                modulo={modulo}
                titulo={titulo}
            />
            <br />
            <div className="mb-4 card card-small text-white bg-dark mb-3">
                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                    <div className="d-flex flex-column flex-1 mx-4">
                        <div className='mb-2 row'>
                            <div className='col-12'>
                                <div className="form-floating">
                                    <Field
                                        name="marca"
                                        placeholder="Marca..."
                                        className="form-control-find"
                                        loadOptions={listarMarcas}
                                        component={AsyncSelectField}
                                        onChange={handleOnChangeMarca}
                                        msj="Marca"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <div className='col-2'>
                                <Field
                                    name="fecha"
                                    component={renderDayPickerB}
                                    msj="Fecha"
                                />
                            </div>
                            <div className='col-5'>
                                <div className="form-floating">
                                    <input
                                        className = {classNames('form-control')}
                                        type="text"
                                        value = {agente}
                                        disabled = {true}
                                    />
                                    <label className='form-label'>Agente</label>
                                </div>
                            </div>
                            <div className='col-5'>
                                <div className="form-floating">
                                    <input
                                        className = {classNames('form-control')}
                                        type="text"
                                        value = {pais}
                                        disabled = {true}
                                    />
                                    <label className='form-label'>Pais</label>
                                </div>
                            </div>
                        </div>
                        <div className='mb-2 row'>
                            <div className='col-12'>
                                <Field
                                    name="observacion"
                                    placeholder="Limitación"
                                    className="form-control"
                                    component={renderTextArea}
                                    msj="Observaciones"
                                />
                            </div>
                        </div>
                        <div className='d-flex flex-row mt-3'>
                            <button
                                className='btn mr-2 mb-3 btn-primary'
                                onClick={registroObservacion}
                            >
                                Guardar
                            </button>
                            <a
                                href={`/#/registro_marca/${id_emp[5]}`}
                                className='btn btn-secondary mb-3'
                            >
                                Regresar
                            </a>
                        </div>
                        <div>
                            <TablaObs
                                detalle={detalleObs}
                            />
                        </div>
                        <br />
                        <br />
                    </div>
                </div>
            </div>
        </div>
    );
}

ObservacionesForm.propTypes = {
    modulo: PropTypes.object,
};

export default reduxForm({
    form: 'FormObservacionRM',
    validate: (data) => {
        return validate(data, {
            fecha: validators.exists()('Este campo es requerido'),
            observacion: validators.exists()('Este campo es requerido'),
        });
    },
    
})(ObservacionesForm);
