import { handleActions, createAction} from 'redux-actions';
import { NotificationManager } from "react-notifications";
import { api } from "api";


const ListarCombustiblesAction = createAction("LISTAR_COMBUSTIBLES")

export const listarCombustibles = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id }
    return api.get('auto_ventas/combustible', params).then(response => {
        // dispatch({type: LISTAR_TIPOS, data: response})
        dispatch(ListarCombustiblesAction(response));
    }).catch((error) => {
        return [];
    })
}


export const crearCombustible = (data) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const formData = {
        nombre: data.combustible,
        empresa: id_emp[5],
    }
    return api.post('auto_ventas/combustible', formData).then(() => {
        NotificationManager.success(
            'Combustible agregado',
            'Exito',
            3000
        );
    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            2000
        );
    })
}

export const editarCombustible = (data, marcaId) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const formData = {
        empresa: id_emp[5],
        nombre: data.combustible
    }
    return api.put(`auto_ventas/combustible/${marcaId}`, formData).then(() => {
        NotificationManager.success(
            'combustible Editado',
            'Exito',
            3000
        );
    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            2000
        );
    })
}

export const actions = {
    crearCombustible,
    editarCombustible,
    listarCombustibles
};


export const reducers = {
    [ListarCombustiblesAction]: (state, action) => {
        return {
            ...state,
            data: action.payload
        }
    },
}
export const initialState = {
    data: [],
};




export default handleActions(reducers, initialState)