import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/recursos_humanos/descuentoFijo';
import ListIsr from './DescuentoFijoList';


const ms2p = (state) => {
  return {
    ...state.descuento_fijo_rrhh,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ListIsr);