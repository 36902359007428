import React, { useState } from 'react';
import Modal from '../components/Modal';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LoadMask from "../Utils/LoadMask/LoadMask";
import { validate, validators } from 'validate-redux-form';
import NavbarModulo from '../components/NavbarModulo';

import {
    renderDayPickerB,
    SelectField,
    AsyncSelectField,
} from "../Utils/renderField/renderField";

const tipo = [
    { label: "DIARIO", value: "DR" },
    { label: "BANCOS", value: "BAN" },
    { label: "CUENTAS POR COBRAR", value: "CXC" },
    { label: "CUENTAS POR PAGAR", value: "CXP" },
];

const nivel = [
    { label: "NIVELES", value: "NIVEL" },
    { label: "COLUMNA", value: "COLUMNA" },
];


const naturaleza = [
    {
        label: 'Todas',
        value: 'TODAS'
    },
    {
        label: 'Activo',
        value: 'ACTIVO'
    },
    {
        label: 'Pasivo',
        value: 'PASIVO'
    },
    {
        label: 'Capital',
        value: 'CAPITAL'
    },
    {
        label: 'Ingresos',
        value: 'INGRESOS',
    },
    {
        label: 'Costos',
        value: 'COSTOS',
    },
    {
        label: 'Gastos',
        value: 'GASTOS',
    },
  ];

function InformesForm(props) {
    const {
        listarCuentas,
        listarCC,
        libroMayor,
        balanceG,
        balanceS,
        reporteCuentas,
        estadoResultado,
        loader,
        modulo,
        datos,
        libroDiario,
        excelBalanceSaldos,
        excelLibroMayor,
        excelBalanceG,
        excelEstadoResultado
    } = props;

    const [folio, cambiarFolio] = useState(1);
    const [folioBG, cambiarFolioBG] = useState(1);
    const [folioER, cambiarFolioER] = useState(1);
    const [estadoModalLD, cambiarEstadoModalLD] = useState(false);
    const [estadoModalLM, cambiarEstadoModalLM] = useState(false);
    const [estadoModalBG, cambiarEstadoModalBG] = useState(false);
    const [estadoModalBS, cambiarEstadoModalBS] = useState(false);
    const [estadoModalCC, cambiarEstadoModalCC] = useState(false);
    const [estadoModalER, cambiarEstadoModalER] = useState(false);
    
    localStorage.setItem('folio', folio);
    localStorage.setItem('folioBG', folioBG);
    localStorage.setItem('folioER', folioER);
    
    const handleChange = e => {
        localStorage.setItem('folio', e.target.value);
        cambiarFolio(e.target.value);
    }

    const handleChangeBG = e => {
        localStorage.setItem('folioBG', e.target.value);
        cambiarFolioBG(e.target.value);
    }

    const handleChangeER = e => {
        localStorage.setItem('folioEG', e.target.value);
        cambiarFolioER(e.target.value);
    }
    let autoFocus = true;
    let titulo = 'Informes'
    
    return (
        <div>
            <NavbarModulo
                modulo={modulo}
                titulo={titulo}
            />
            <div className="container">
                <br />
                <h4 className='mt-1' style={{ color: "white" }}>Libros Contables</h4>
                <div className="row">
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <button class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => cambiarEstadoModalLD(!estadoModalLD)}
                                data-bs-toggle="list"
                                role="tab"
                                aria-controls="list-home">Libro diario
                            </button>
                        </div>
                    </div>
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <button class="list-group-item list-group-item-action"
                                id="list-home-list"
                                data-bs-toggle="list"
                                onClick={() => cambiarEstadoModalLM(!estadoModalLM)}
                                role="tab"
                                aria-controls="list-home">Diario mayor general
                            </button>
                        </div>
                    </div>
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <button class="list-group-item list-group-item-action"
                                id="list-home-list"
                                data-bs-toggle="list"
                                onClick={() => cambiarEstadoModalBG(!estadoModalBG)}
                                role="tab"
                                aria-controls="list-home">Balance general
                            </button>
                        </div>
                    </div>
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <button class="list-group-item list-group-item-action"
                                id="list-home-list"
                                data-bs-toggle="list"
                                onClick={() => cambiarEstadoModalER(!estadoModalER)}
                                role="tab"
                                aria-controls="list-home">Estado de resultados
                            </button>
                        </div>
                    </div>
                </div>
                <h4 className='mt-5' style={{ color: "white" }}>Auxiliares</h4>
                <div className="row">
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <button class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => cambiarEstadoModalCC(!estadoModalCC)}
                                data-bs-toggle="list"
                                role="tab"
                                aria-controls="list-home">Cuentas contables
                            </button>
                        </div>
                    </div>
                    <div className="col-xxl-3">
                        {/*<div className="list-group" id="list-tab" role="tablist">
                            <button class="list-group-item list-group-item-action"
                                id="list-home-list"
                                data-bs-toggle="list"
                                
                                role="tab"
                                aria-controls="list-home">Polizas
                            </button>
                        </div>*/}
                    </div>
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <button class="list-group-item list-group-item-action"
                                id="list-home-list"
                                data-bs-toggle="list"
                                onClick={() => cambiarEstadoModalBS(!estadoModalBS)}
                                role="tab"
                                aria-controls="list-home">Balance de saldos
                            </button>
                        </div>
                    </div>
                    
                </div>
                <Modal
                estado={estadoModalLD}
                cambiarEstado={cambiarEstadoModalLD}  //libro Diario
                titulo="Libro Diario"
                botonCerrar="true"
                >
                    <div className="form-floating mb-2">
                        <Field
                            autoFocus={autoFocus}
                            id="tipo"
                            name="tipo"
                            options={tipo}
                            component={SelectField}
                            className="form-select"
                            msj="Tipo poliza"
                        />
                    </div>
                    <div className='row mb-2'>
                        <div className='col-6'>
                            <Field
                                name="fechaInicial"
                                component={renderDayPickerB}
                                msj="Fecha del"
                            
                            />
                        </div>
                        <div className='col-6'>
                            <Field
                                name="fechaFinal"
                                component={renderDayPickerB}
                                msj="Fecha al"
                            
                            />
                        </div>
                    </div>
                    <LoadMask loading={loader} dark>
                        <Contenido>
                            <div className='row mt-2'>
                                <button
                                    className='btn mr-2 mb-3 btn-primary'
                                    onClick={libroDiario}
                                >
                                Generar Reporte
                                </button>
                                <button
                                    className='btn mr-2 mb-3 btn btn-secondary'
                                    onClick={() => cambiarEstadoModalLD(false)}
                                >
                                Cancelar
                                </button>
                            </div>
                        </Contenido>
                    </LoadMask>
                </Modal>
                
                <Modal
                    estado={estadoModalLM} // libro Mayor
                    cambiarEstado={cambiarEstadoModalLM}
                    titulo="Diario mayor general"
                    botonCerrar="true"
                    >
                        <div className="col-12 mb-2">
                            <Field
                                autoFocus={autoFocus}
                                name="cuenta"
                                placeholder="Cuenta contable..."
                                className="form-control-find"
                                component={AsyncSelectField}
                                loadOptions={listarCuentas}
                                msj="Cuenta"
                            />
                        </div>
                        {datos.centro_costo === 'S' &&
                            <div className="col-12 mb-2">
                                <Field
                                    autoFocus={autoFocus}
                                    name="cc"
                                    placeholder="Centro costo..."
                                    className="form-control-find"
                                    component={AsyncSelectField}
                                    loadOptions={listarCC}
                                    msj="Centro costo"
                                />
                            </div>
                        }
                        <div className='row mb-2'>

                            <div className='col-6'>
                                <Field
                                    name="fechaInicial"
                                    component={renderDayPickerB}
                                    msj="Fecha del"
                                
                                />
                            </div>
                            <div className='col-6'>
                                <Field
                                    name="fechaFinal"
                                    component={renderDayPickerB}
                                    msj="Fecha al"
                                
                                />
                            </div>
                        </div>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div className="form-floating mb-2">
                                    <Field
                                        name="tipo"
                                        options={tipo}
                                        component={SelectField}
                                        className="form-select"
                                        msj="Tipo poliza"
                                    />
                                </div>
                                <div className='mt-2'>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={libroMayor}
                                    >
                                        Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={excelLibroMayor}
                                    >
                                        Exportar Excel
                                    </button>
                                </div>    
                                <button
                                    className='btn mr-2 mb-3 btn btn-secondary'
                                    onClick={() => cambiarEstadoModalLM(false)}
                                >
                                    Cancelar
                                </button>
                            </Contenido>
                        </LoadMask>
                </Modal>
            
                <Modal
                    estado={estadoModalBG} //balance general
                    cambiarEstado={cambiarEstadoModalBG}
                    titulo="Balance General"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className='col-6 mb-2'>
                                <Field
                                    autoFocus={autoFocus}
                                    name="fechaFinal"
                                    component={renderDayPickerB}
                                    msj="Fecha al"
                                        
                                />
                            </div>
                            <div className='col-6 mb-2'>
                                <div className="form-floating mb-2">
                                    <Field
                                        name="tipo"
                                        dato={'NIVEL'}
                                        options={nivel}
                                        component={SelectField}
                                        className="form-select"
                                        msj="Seleccionar"
                                    />
                                </div>
                            </div>
                        </Contenido>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={balanceG}
                                    >
                                    Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={excelBalanceG}
                                    >
                                    Exportar Excel
                                    </button>
                                </div>
                                <button
                                    className='btn mr-2 mb-3 btn btn-secondary'
                                    onClick={() => cambiarEstadoModalBG(false)}
                                >
                                    Cancelar
                                </button>
                            </Contenido>
                        </LoadMask>
                </Modal>

                <Modal
                    estado={estadoModalBS} //Balance Saldos
                    cambiarEstado={cambiarEstadoModalBS}
                    titulo="Balance de Saldos"
                    botonCerrar="true"
                    >
                        <div className='row mb-2'>
                            <div className='col-6'>
                                <Field
                                    autoFocus={autoFocus}
                                    name="fechaInicial"
                                    component={renderDayPickerB}
                                    msj="Fecha del"
                                
                                />
                            </div>
                            <div className='col-6'>
                                <Field
                                    name="fechaFinal"
                                    component={renderDayPickerB}
                                    msj="Fecha al"
                                
                                />
                            </div>
                        </div>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={balanceS}
                                    >
                                    Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={excelBalanceSaldos}
                                    >
                                    Exportar Excel
                                    </button>
                                </div>
                                <button
                                    className='btn mr-2 mb-3 btn btn-secondary'
                                    onClick={() => cambiarEstadoModalBS(false)}
                                >
                                Cancelar
                                </button>
                            </Contenido>
                        </LoadMask>
                </Modal>

                <Modal
                    estado={estadoModalCC} //cuentas contables
                    cambiarEstado={cambiarEstadoModalCC}
                    titulo="Cuentas Contables"
                    botonCerrar="true"
                    >
                        <div className="form-floating mb-2">
                            <Field
                                autoFocus={autoFocus}
                                name="naturaleza"
                                dato={'TODAS'}
                                options={naturaleza}
                                component={SelectField}
                                className="form-select"
                                msj="Naturaleza"
                            />
                        </div>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div className='row mt-2'>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={reporteCuentas}
                                    >
                                    Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn btn-secondary'
                                        onClick={() => cambiarEstadoModalCC(false)}
                                    >
                                    Cancelar
                                    </button>
                                </div>
                            </Contenido>
                        </LoadMask>
                </Modal>

                <Modal
                    estado={estadoModalER} //Estado de resultados
                    cambiarEstado={cambiarEstadoModalER}
                    titulo="Estado de Resultados"
                    botonCerrar="true"
                    >
                        <div className='row mb-2'>
                            <div className='col-6'>
                                <Field
                                    autoFocus={autoFocus}
                                    name="fechaInicial"
                                    component={renderDayPickerB}
                                    msj="Fecha del"
                                
                                />
                            </div>
                            <div className='col-6'>
                                <Field
                                    name="fechaFinal"
                                    component={renderDayPickerB}
                                    msj="Fecha al"
                                
                                />
                            </div>
                        </div>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div className='col-6 mb-2'>
                                    <div className="form-floating mb-2">
                                        <Field
                                            name="tipo"
                                            dato={'NIVEL'}
                                            options={nivel}
                                            component={SelectField}
                                            className="form-select"
                                            msj="Seleccionar"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={estadoResultado}
                                    >
                                        Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={excelEstadoResultado}
                                    >
                                        Exportar Excel
                                    </button>
                                </div>
                                <button
                                    className='btn mr-2 mb-3 btn btn-secondary'
                                    onClick={() => cambiarEstadoModalER(false)}
                                >
                                    Cancelar
                                 </button>
                            </Contenido>
                        </LoadMask>
                </Modal>
            </div>
        </div>
    );

}

InformesForm.propTypes = {
    listarCuentas: PropTypes.func,
    listarCC: PropTypes.func,
    libroMayor: PropTypes.func,
    balanceS: PropTypes.func,
    balanceG: PropTypes.func,
    reporteCuentas: PropTypes.func,
    estadoResultado: PropTypes.func,
    loader: PropTypes.bool,
    modulo: PropTypes.object,
    datos: PropTypes.object,
    libroDiario: PropTypes.func
};

export default reduxForm({
    form: 'FormInformes',
    validate: (data) => {
        return validate(data, {
          fechaInicial: validators.exists()('Este campo es requerido'),
          fechaFinal: validators.exists()('Este campo es requerido'),
        });
      },
})(InformesForm);


const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;
