import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';

const LOADER = 'LOADER';
const MODULO = 'MODULO';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        if (error.status === 401){
            NotificationManager.error(
              error.body.msj,
              'Error',
              0
            );
        }
        if (error.status === 400){
            NotificationManager.error(
              'Ocurrió un error en buscar modulo',
              'Error',
              0
            );
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


//Ordenes de servicio
export const listarProyectos = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id, search}
    return api.get('/orden_servicio/proyectos',params).then(data=>{
        const list_proyectos = [];
        if(data){
            data.forEach(item=>{
                list_proyectos.push({
                    value: item.id,
                    label: 'IOS' + ' -- ' + item.numero + ' / ' + item.nombre_proyecto
                })
            })
        }
        return list_proyectos;
    }).catch((error)=>{
        
        return [];
    })
}

export const cierreProyecto = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormCierreProyectoINV.values;
    if (data === undefined){
        Swal.fire({
            title: 'Cierre de proyecto',
            text: 'Debe de seleccionar un proyecto',
            type: 'error',
        })
    }else{
        if (data.fecha == null || data.fecha == undefined){
            Swal.fire({
                title: 'Cierre de proyecto',
                text: 'Debe de ingresar fecha de cierre',
                type: 'error',
            })
        }else{
            if (data.proyecto == null || data.proyecto == undefined){
                Swal.fire({
                    title: 'Cierre de proyecto',
                    text: 'Debe de seleccionar un proyecto',
                    type: 'error',
                })
            }else{
                const formData = {
                    fecha: data.fecha,
                    proyecto: data.proyecto.value,
                    empresa: id_emp[5]
                }
                dispatch(setLoader(true));
                api.post('/inv_cierre_proyecto/cierre_proyecto/', formData).then(() => {
                    NotificationManager.success(
                        'Se ha cerrado el proyecto',
                        'Exito',
                        3000
                    );
                    let ruta = `/inventario/${id_emp[5]}`
                    dispatch(push(ruta));
                }).catch((error) => {
                    NotificationManager.error(
                        error.msj,
                        'Error',
                        2000
                    );
                }).finally(() => {
                    dispatch(setLoader(false));
                });
            }
        }
    }
}

export const actions = {
    listarProyectos,
    getModulo,
    cierreProyecto
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
};

export const initialState = {
    loader: false,
    modulo: []
};

export default handleActions(reducers, initialState)