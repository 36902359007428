import React, { Component } from 'react';
import Grid from '../../Utils/GridPolizaSCentroCosto';
import { standardActions } from '../../Utils/GridPolizaSCentroCosto/StandardActions';

class CuentaContableList extends Component{
    
    render(){
        const { loader, eliminarLinea,  data } = this.props;
        return(
            <React.Fragment>
                {data &&
                    <Grid 
                    hover 
                    striped 
                    data={data} 
                    loading={loader} 
                    headerStyle={ { background: 'black' } } 
                    //onSortChange={onSortChange} 
                >
                    <TableHeaderColumn
                        isKey
                        dataField="numeroCuenta"
                        width="150"
                        dataSort
                        thStyle={ 
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Cuenta
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="nombreCuenta"
                        width="350"
                        dataSort
                        thStyle={ 
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Nombre cuenta
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        thStyle={
                        {color: 'white'}}
                        dataField="id"
                        width="80"
                        dataAlign="center"
                        dataSort
                        dataFormat={standardActions({
                            eliminarCuentaContable: eliminarLinea })} 
                    >
                        Acciones
                    </TableHeaderColumn>
                </Grid>
                }
            </React.Fragment>
        );
    }
}

export default CuentaContableList;
