import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import DetalleListado from './NominaOtrosList';
import DetalleListadoVer from './NominaOtrosListVer';
import PropTypes from 'prop-types';
import NavbarModulo from '../../components/NavbarModulo';
import NavbarModuloLF from '../../components/NavbarModuloFage';
import { FaPrint } from 'react-icons/fa';
import {
    AsyncSelectFieldIn,
    renderCurrencyFloat,
    renderField
} from "../../Utils/renderField/renderField";

const valEmp = __CONFIG__.empresa;

function NominaOtrosForm(props) {
    const { modulo, data, periodoN, correlativo,
        fecha_inicio, fecha_fin, frecuencia, periodoD, periodoG, loader,
        periodoId, reporteNomina,
        confirmarNomina, revisarNomina, eliminarLinea,
        emp, registroNomina  } = props;
    let titulo = 'Nomina otros ingresos';
    
    let ruta = window.location.href;
    let id_emp = ruta.split('/');

    const editar = window.location.href.includes('editar');
    const crear = window.location.href.includes('crear');
    
    return (
        <div>
            {valEmp === 'fage' &&
                <NavbarModuloLF
                    periodoD={periodoD}    
                    modulo={modulo}
                    titulo={titulo}
                />
            }
            {valEmp === '' &&
                <NavbarModulo
                    periodoD={periodoD}
                    modulo={modulo}
                    titulo={titulo}
                />
            }

            <br />
            <div className="mb-4 card card-small text-white bg-dark mb-3" style={{ overflowX: "auto" }}>
                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                    <div className="d-flex flex-column flex-1 mx-4" style={{ minWidth: "1500px" }}>
                        <div className='row mb-2'>
                            <div className='col-2'>
                                <div className="form-floating">
                                    <input
                                        placeholder="Periodo"
                                        type="text"
                                        autocomplete="off"
                                        name="periodo"
                                        className="form-control"
                                        value={periodoN}
                                        disabled={true}
                                    />
                                    <label className='form-label' htmlFor="periodo">
                                        Periodo
                                    </label>
                                </div>
                            </div>
                            <div className='col-1'>
                                <div className="form-floating">
                                    <input
                                        placeholder="Numero"
                                        type="text"
                                        autocomplete="off"
                                        name="numero"
                                        className="form-control"
                                        value={correlativo}
                                        disabled={true}
                                    />
                                    <label className='form-label' htmlFor="numero">
                                        Numero
                                    </label>
                                </div>
                            </div>
                            <div className='col-2'>
                                <div className="form-floating">
                                    <input
                                        placeholder="Fecha inicio"
                                        type="text"
                                        autocomplete="off"
                                        name="numero"
                                        className="form-control"
                                        value={fecha_inicio}
                                        disabled={true}
                                    />
                                    <label className='form-label' htmlFor="numero">
                                        Fecha inicio
                                    </label>
                                </div>
                            </div>
                            <div className='col-2'>
                                <div className="form-floating">
                                    <input
                                        placeholder="Fecha final"
                                        type="text"
                                        autocomplete="off"
                                        name="numero"
                                        className="form-control"
                                        value={fecha_fin}
                                        disabled={true}
                                    />
                                    <label className='form-label' htmlFor="numero">
                                        Fecha final
                                    </label>
                                </div>
                            </div>
                            <div className='col-2'>
                                <div className="form-floating">
                                    <input
                                        placeholder="Frecuencia de pago"
                                        type="text"
                                        autocomplete="off"
                                        name="numero"
                                        className="form-control"
                                        value={frecuencia}
                                        disabled={true}
                                    />
                                    <label className='form-label' htmlFor="numero">
                                        Frecuencia de pago
                                    </label>
                                </div>
                            </div>
                        </div>
                        {(crear === true || editar === true) &&
                            <div>
                                <h5 className="p-2 bg-secondary title mt-1">Colaborador</h5>
                                <div className='row mb-2'>
                                    <div className='col-9'>
                                        <Field
                                            name="colaborador"
                                            placeholder="Colaborador..."
                                            className="form-control-find"
                                            component={AsyncSelectFieldIn}
                                            loadOptions={emp}
                                            msj="Colaborador"
                                        />
                                    </div>
                                </div>   
                                <div className='row mb-2'>
                                    <div className='col-3'>
                                        <div className="form-floating">
                                            <Field
                                                name="base"
                                                placeholder="Base"
                                                className="form-control"
                                                component={renderCurrencyFloat}
                                                msj="Base"
                                            />
                                        </div>
                                    </div>
                                    <div className='col-3'>
                                        <div className="form-floating">
                                            <Field
                                                name="bonificacion"
                                                placeholder="Bonifiación"
                                                className="form-control"
                                                component={renderCurrencyFloat}
                                                msj="Bonificación"
                                            />
                                        </div>
                                    </div>
                                    <div className='col-3'>
                                        <div className="form-floating">
                                            <Field
                                                name="comision"
                                                placeholder="Comisión"
                                                className="form-control"
                                                component={renderCurrencyFloat}
                                                msj="Comisión"
                                            />
                                        </div>
                                    </div>
                                </div>    
                                <div className='row mb-2'>
                                    <div className='col-md-9'>
                                        <Field
                                            name="descripcion"
                                            placeholder="Descripcion"
                                            component={renderField}
                                            className="form-control"
                                            msj="Descripcion"
                                        />
                                    </div>
                                </div>   
                                <div className='d-flex flex-row mt-3'>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={() => registroNomina(periodoId)}
                                    >
                                        Agregar colaborador
                                    </button>              
                                </div>
                            </div>
                        }
                        <div>
                            {(crear === true || editar === true) &&
                                <h5 className="p-2 bg-secondary title mt-1">Detalle nómina</h5>
                            }
                            <br/>
                            {(crear === true || editar === true) &&
                                <div className='mt-2 mb-3'>
                                    <DetalleListado
                                        loader={loader}
                                        detalle={data}
                                        eliminarLinea={eliminarLinea}
                                    />
                                </div>
                            }
                            {(crear === false && editar === false) &&
                                <div className='mt-2 mb-3'>
                                    <DetalleListadoVer
                                        loader={loader}
                                        detalle={data}
                                    />
                                </div>
                            }
                        </div>
                        
                        <div className='d-flex flex-row mt-3'>
                            {(crear === true || editar === true) &&
                                <div>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={() => confirmarNomina(periodoId,periodoG)}
                                    >
                                        Guardar nómina
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={() => revisarNomina(periodoId,periodoG)}
                                    >
                                        Revisión de nómina
                                    </button>
                                </div>
                            }
                            {valEmp === 'fage' &&
                                <a
                                    href={`/#/lfg/nomina/${id_emp[6]}/listar_nomina_otros/${periodoG}`}
                                    className='btn btn-secondary mb-3'
                                >
                                    Regresar
                                </a>
                            }
                            {valEmp === '' &&
                                <a
                                    href={`/#/nomina/${id_emp[5]}/listar_nomina_otros/${periodoG}`}
                                    className='btn btn-secondary mb-3'
                                >
                                    Regresar
                                </a>
                            }
                            <button
                                className='btn btn-secondary mb-3 ml-2'
                                onClick={() => reporteNomina(periodoId)}
                            >
                                <span><FaPrint /></span> Ver nómina
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

NominaOtrosForm.propTypes = {
    handleSubmit: PropTypes.func,
    modulo: PropTypes.object
};

export default reduxForm({
    form: 'FormNominaOtros',
})(NominaOtrosForm);
