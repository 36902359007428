import React, { Component } from 'react';
import Formulario from './ConciliacionForm';

class Conciliacion extends Component{
    
    componentDidMount = () => {
        const { listarCuentasBancarias, getModulo, limpiarDatos } = this.props;
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        getModulo(modulo[5],modulo[4]);
        listarCuentasBancarias();
        limpiarDatos();
    }
    
    render(){
        const { loader, cuenta, listar, data, modulo, 
            conciliar, actualizar, loaderC} = this.props;
        
        return(
            <Formulario
                cuenta = {cuenta}
                listar = {listar}
                data = {data}
                modulo = {modulo}
                conciliar = {conciliar}
                actualizar = {actualizar}
                loaderC = {loaderC}
                loader = {loader}
            />
        );
    }
}

export default Conciliacion;
