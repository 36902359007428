import { handleActions } from "redux-actions";
import { api } from "api";
import { NotificationManager } from "react-notifications";

const subirArchivoNomenclatura = (data) => (dispatch) => {
    data.empresa = localStorage.getItem("fnzEmpresaId");
    return api
        .postAttachments(
            "finanzas/estados_financieros/nomenclatura/subir_excel",
            { ...data, nomenclatura: null },
            [{ name: "nomenclatura", file: data.nomenclatura }]
        )
        .then((response) => {
            NotificationManager.success(
                response.msj ? response.msj : "Nomenclatura guardada",
                "Exito",
                3000,
                null,
                true
            );
            return response;
        })
        .catch((error) => {
            NotificationManager.error(
                error.msj ? error.msj : "Ocurrio un error",
                "Error",
                2000,
                null,
                true
            );
            return false;
        });
};

export const guardarNomenclatura = (data) => (dispatch) => {
    data.empresa = localStorage.getItem("fnzEmpresaId");
    data.naturaleza = data.naturaleza.value;
    data.tipo = data.tipo.value;
    return api
        .post("finanzas/estados_financieros/nomenclatura", data)
        .then((response) => {
            NotificationManager.success(
                response.msj ? response.msj : "Nomenclatura guardada",
                "Exito",
                3000,
                null,
                true
            );
        })
        .catch((error) => {
            NotificationManager.error(
                error.msj ? error.msj : "Ocurrio un error",
                "Error",
                2000,
                null,
                true
            );
        });
};

export const listarContribuyentes = () => (dispatch) => {
    const params = { empresa: localStorage.getItem("fnzEmpresaId") };
    return api
        .get("/finanzas/estados_financieros/nomenclatura", params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            NotificationManager.error(
                "Ocurrió un error al listar las empresas",
                "Error",
                2000
            );
            return [];
        });
};

export const actions = {
    subirArchivoNomenclatura,
    guardarNomenclatura,
    listarContribuyentes,
};

export const reducers = {};

export const initialState = {};

export default handleActions(reducers, initialState);
