import React, { Component } from 'react';
import Formulario from './CorteCajaForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";

class CorteCaja extends Component{
    state = {
        crear: true,
    }
    componentDidMount() {
        const { match, leer, getModulo, limpiar, fechaCorte } = this.props;
        const id = match.params.id;
        if (id){
            this.setState({ crear: false });
            leer(id);
        }
        let ruta = window.location.href;
        let periodo = ruta.split('/');
        getModulo(periodo[5],periodo[4]);
        limpiar();
        fechaCorte(periodo[5],periodo[7]);
    }
    
    render(){
        const { loader, modulo, mostrarRecibos, recibos,
            totalEfectivo, totalCheque, corteCaja, detalleDocumentos,
            fechaMes, fechaCierre } = this.props;
        const { crear } = this.state;
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    crear = {crear}
                    modulo={modulo}
                    mostrarRecibos = {mostrarRecibos}
                    recibos = {recibos}
                    totalEfectivo = {totalEfectivo}
                    totalCheque = {totalCheque}
                    corteCaja = {corteCaja}
                    detalleDocumentos = {detalleDocumentos}
                    fechaMes = {fechaMes}
                    fechaCierre = {fechaCierre}
                />
           </LoadMask>
        );
    }
}

export default CorteCaja;
