import React, { Component } from 'react';
import Grid from '../../Utils/GridPolizaSCentroCosto';

class TablaPolizaVerSCentroCosto extends Component{
    
    render(){
        const { loader, lectura } = this.props;
        return(
            <React.Fragment>
                <br/>
                {lectura &&
                    <Grid 
                    hover 
                    striped 
                    data={lectura.polizaDetalle_polizaEncabezado} 
                    loading={loader} 
                    footer
                    headerStyle={ { background: 'black' } } 
                    //onSortChange={onSortChange} 
                >
                    <TableHeaderColumn
                        isKey
                        dataField="cuenta"
                        dataFormat={(cell, row)=>{
                            if ( cell === undefined ){
                                return "";
                            }else{
                                return cell.cuenta+' - '+cell.nombre;
                            }
                            
                        }}
                       
                        width="500"
                        dataSort
                        thStyle={ 
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Cuenta
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataAlign='right'
                        headerAlign="center"
                        dataField="debe"
                        dataFormat={(cell, row)=>{
                            if (parseFloat(cell) === 0.00){
                                return ''
                            }else{
                                return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(cell);    
                            }
                        }}
                        width="150"
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Debe
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataAlign='right'
                        dataField="haber"
                        headerAlign="center"
                        dataFormat={(cell, row)=>{
                            if (parseFloat(cell) === 0.00){
                                return ''
                            }else{
                                return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(cell);
                            }
                        }}
                        width="150"
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Haber
                    </TableHeaderColumn>
                </Grid>
                }
            </React.Fragment>
        );
    }
}

export default TablaPolizaVerSCentroCosto;
