import { NotificationManager } from "react-notifications";
import { api } from "api";
import { getPeriodoId } from "../../utils";

const baseurl = "laboratorio/informes_patologia";
const writeSuccessMsg = "Informe agregado";
const editSuccessMsg = "Informe editado";

export const getList = () => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let empresa = id_emp[5];
    const periodo = getPeriodoId();
    const params = { empresa, periodo };
    return api
        .get(baseurl, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return [];
        });
};

export const getEntry = (id) => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let empresa = id_emp[5];
    const params = { empresa };
    return api
        .get(baseurl + `/${id}`, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return [];
        });
};

export const getPatologia = (id) => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let empresa = id_emp[5];
    const params = { empresa };
    return api
        .get(`laboratorio/ingreso_de_patologia` + `/${id}`, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return [];
        });
};

export const getPatologias = (search) => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let empresa = id_emp[5];
    const tipo = "afiliada";
    const informe = true;
    const params = { empresa, search, tipo, informe };
    return api
        .get("laboratorio/ingreso_de_patologia/patologias_por_tipo", params)
        .then((response) => {
            return response.map((item) => ({
                value: item.id,
                label: `${item.numero_patologia} | ${item.tipo}  `,
            }));
        })
        .catch((error) => {
            return [];
        });
};

export const getInforme = (id) => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let empresa = id_emp[5];
    let tipo = "afiliada";
    let informe = id;
    const params = { empresa, tipo, informe };
    return api
        .getPdf(baseurl + `/informe_patologia`, params)
        .then((response) => {
            const contentType = "application/pdf";
            if (response.headers["content-type"] === contentType) {
                const blob = new Blob([response.text], {
                    type: `${contentType};charset=utf-8`,
                });
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = "informe_patologia_privada.pdf";
                window.open(link, "_blank");
                return false;
            }
        })
        .catch((error) => {
            const infoMessage = "Informacion";
            const errorMessage = "Error";
            if (error.status === 404) {
                NotificationManager.info(error.body.msj, infoMessage, 5000);
            } else if (error.status === 500) {
                NotificationManager.error(
                    "Ocurrió un error al generar informe",
                    errorMessage,
                    2000
                );
            } else {
                NotificationManager.error(
                    "Unexpected error occurred",
                    errorMessage,
                    2000
                );
            }
        });
};

export const crear = (data) => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    const periodo = getPeriodoId();
    if (!periodo) {
        NotificationManager.error(
            "No hay un periodo seleccionado",
            "Error",
            10000
        );
        return;
    }
    const formData = {
        ...data,
        empresa: id_emp[5],
        periodo,
    };
    return api
        .post(baseurl, formData)
        .then((response) => {
            NotificationManager.success(writeSuccessMsg, "Exito", 3000);
            return response;
        })
        .catch((error) => {
            NotificationManager.error("", "Error", 2000);
        });
};

export const editar = (data, id) => {
    let ruta = window.location.href;
    let empresa = ruta.split("/")[5];
    const formData = {
        ...data,
        empresa,
    };
    return api
        .put(baseurl + `/${id}`, formData)
        .then(() => {
            NotificationManager.success(editSuccessMsg, "Exito", 3000);
            return true;
        })
        .catch((error) => {
            NotificationManager.error(error.msj, "Error", 2000);
            return false;
        });
};
