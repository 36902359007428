import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';

import {
    SelectField,
    AsyncSelectField,
    renderCurrencyFloatTC,
    renderDayPickerB,
    renderField
} from "../../Utils/renderField/renderField";

function DetalleInventarioForm(props) {
    const {
        crear,
        lectura,
        bloqueo,
        editar,
        obtenerBodegas,
        listarProductos,
        datos
    } = props;

    const [cantidad, setCantidad] = useState(0.00);
    const [total, setTotal] = useState(0.0);
    console.log('datos', datos);
    return (
        <div>
            <div className='row mb-2'>
                {(crear === true || editar == true) &&
                    <div className='col-3'>
                        {obtenerBodegas &&
                            <Field
                                name="bodega"
                                options={obtenerBodegas}
                                crear={crear}
                                dato={crear === true ? 0 : lectura.bodega}
                                component={SelectField}
                                className="form-select"
                                msj="Bodega"
                            />
                        }
                    </div>
                }
                {(crear === true || editar == true) &&
                    <div className='col-9'>
                        <Field
                            name="producto"
                            placeholder="Producto..."
                            className="form-control-find"
                            component={AsyncSelectField}
                            loadOptions={listarProductos}
                            msj="Producto"
                        />
                    </div>
                }
            </div>
            {(crear === true || editar == true) &&
                <div className='row mb-2'>
                    <div className='col-2'>
                        <Field
                            name="cantidad"
                            placeholder="Cantidad"
                            className="form-control"
                            component={renderCurrencyFloatTC}
                            disabled={bloqueo}
                            onChange={(e, value) => {
                                if (value) {
                                    setCantidad(value);
                                }
                            }}
                            msj="Cantidad"
                        />
                    </div>
                    <div className='col-2'>
                        <Field
                            name="precio"
                            placeholder="Precio unitario"
                            className="form-control"
                            component={renderCurrencyFloatTC}
                            onChange={(e, value) => {
                                if (value) {
                                    let total = parseFloat(parseFloat(value)*parseFloat(cantidad)).toFixed(2);
                                    setTotal(total);
                                }
                            }}
                            msj="Precio unitario"
                        />
                    </div>
                    <div className='col-2'>
                        <div className="form-floating">
                            <NumberFormat
                                className={classNames('form-control')}
                                decimalScale={2}
                                disabled={true}
                                placeholder="Base"
                                fixedDecimalScale
                                thousandSeparator
                                value={total}
                                name="total"
                            />
                            <label className='form-label' htmlFor="total">
                                Total
                            </label>
                        </div>
                    </div>
                    {datos.farmaceutica === 'S' &&
                        <div className='col-2'>
                            <Field
                                name="lote"
                                placeholder="Lote"
                                className="form-control"
                                component={renderField}
                                msj="Lote"
                            />
                        </div>
                    }
                    {datos.farmaceutica === 'S' &&
                        <div className='col-2'>
                            <Field
                                name="fecha_vencimiento"
                                component={renderDayPickerB}
                                msj="Fecha vencimiento"
                            />
                        </div>
                    }
                </div>
            }
        </div>
    );
}

DetalleInventarioForm.propTypes = {
    crear: PropTypes.bool,
    lectura: PropTypes.object,
    editar: PropTypes.bool,
    bloqueo: PropTypes.bool,
    obtenerBodegas: PropTypes.array,
    listarProductos: PropTypes.object
};

export default reduxForm({
    form: 'FormInventario',
})(DetalleInventarioForm);
