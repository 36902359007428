import React, { Component } from 'react';
import Formulario from './PromocionPuestoForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";
const valEmp = __CONFIG__.empresa;

class PromocionPuesto extends Component{
    
    componentDidMount = () => {
        const { getModulo, listarDepartamentos, listarPuestos, limpiarDatos } = this.props;
        
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        if (valEmp === 'fage'){
            getModulo(modulo[6],modulo[5])
        }else{
            getModulo(modulo[5],modulo[4]);
        }
        listarDepartamentos();
        listarPuestos();
        limpiarDatos();
    }
    
    render(){
        const { listarColaborador, loader, modulo, listarPaises, emp, guardarPuesto,
            datosColaborador, listaDepartamento, cargo, depto, jefe, horario,
            listaPuesto, lugar, lista, depto_N, puesto_N, eliminarLinea } = this.props;
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    modulo = {modulo}
                    listarColaborador = {listarColaborador}
                    listarPaises = {listarPaises}
                    emp = {emp}
                    guardarPuesto = {guardarPuesto}
                    datosColaborador = {datosColaborador}
                    listaDepartamento = {listaDepartamento}
                    listaPuesto = {listaPuesto}
                    cargo = {cargo}
                    depto = {depto}
                    jefe = {jefe}
                    horario = {horario}
                    lugar = {lugar}
                    lista = {lista}
                    depto_N = {depto_N}
                    puesto_N = {puesto_N}
                    eliminarLinea = {eliminarLinea}
                />
            </LoadMask>
        );
    }
}

export default PromocionPuesto;