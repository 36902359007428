import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/recursos_humanos/corteVacaciones';
import SeleccionarPais from './SeleccionarPais';


const ms2p = (state) => {
  return {
    ...state.corte_vacaciones_rh,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(SeleccionarPais);
