import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import './acciones.css';
import Swal from 'sweetalert2';
import * as FaIcons from "react-icons/fa";

class Acciones extends Component {
    constructor(props) {
        super(props);
    }

    eliminar = (id) => {
        return () => {
            Swal.fire({
                title: '¿Eliminar?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.eliminar(id);
                }
            });
        }
    };

    eliminar_pol_a = (id, tipo_poliza, numero_poliza) => {
        return () => {
            Swal.fire({
                title: '¿Eliminar póliza '+ tipo_poliza +' número: ' + numero_poliza +'?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.eliminar_pol_a(id);
                }
            });
        }
    };

    imprimir = (id) => {
        return () => {
            this.props.imprimir(id);
        }
    };

    imprimirCK = (id) => {
        return () => {
            this.props.imprimirCK(id);
        }
    };

    imprimirAlta = (id) => {
        return () => {
            this.props.imprimirAlta(id);
        }
    };

    imprimir_e = (id) => {
        return () => {
            this.props.imprimir_e(id);
        }
    };

    excel = (id) => {
        return () => {
            this.props.excel(id);
        }
    };

    anularCK = (id, id_ck, cuenta_ban_ck) => {
        return () => {
            Swal.fire({
                title: '¿Anular número de cheque: '+ id + '?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, anular!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.anularCK(id, id_ck, cuenta_ban_ck);
                }
            });
        }
    };

    anularFac = (id, serie_fac, numero_fac) => {
        return () => {
            Swal.fire({
                title: '¿Anular factura: '+ serie_fac + ' -- '+ numero_fac + '?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, anular!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.anularFac(id);
                }
            });
        }
    };

    anularNotaCobro = (id, serie_fac, numero_fac) => {
        return () => {
            Swal.fire({
                title: '¿Anular nota de cobro: '+ serie_fac + ' -- '+ numero_fac + '?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, anular!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.anularNotaCobro(id);
                }
            });
        }
    };

    anularEnvio = (id, serie_fac, numero_fac) => {
        return () => {
            Swal.fire({
                title: '¿Anular envío: '+ serie_fac + ' -- '+ numero_fac + '?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, anular!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.anularEnvio(id);
                }
            });
        }
    };

    eliminarFac = (id, serie_fac, numero_fac) => {
        return () => {
            Swal.fire({
                title: '¿Eliminar factura: \n'
                    + serie_fac + ' -- '+ numero_fac + '?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.eliminarFac(id);
                }
            });
        }
    };

    eliminarNC = (id, numero_fac) => {
        return () => {
            Swal.fire({
                title: '¿Eliminar nota de crédito: ' + ' '+ numero_fac + '?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.eliminarNC(id);
                }
            });
        }
    };

    eliminarDep = (id, numero_fac) => {
        return () => {
            Swal.fire({
                title: '¿Eliminar depósito: ' + ' '+ numero_fac + '?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.eliminarDep(id);
                }
            });
        }
    };

    eliminar_e = (id, serie_fac, numero_fac) => {
        return () => {
            Swal.fire({
                title: '¿Eliminar Envío: '+ serie_fac + ' -- '+ numero_fac + '?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.eliminar_e(id);
                }
            });
        }
    };

    eliminar_nota = (id, serie_fac, numero_fac) => {
        return () => {
            Swal.fire({
                title: '¿Eliminar Nota de cobro: '+ serie_fac + ' -- '+ numero_fac + '?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.eliminar_nota(id);
                }
            });
        }
    };

    anularFac_fel = (id, serie_fac, numero_fac, certificadorFel) => {
        return () => {
            Swal.fire({
                title: '¿Anular documento: \n'
                    + serie_fac + ' -- '+ numero_fac + '?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, anular!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    Swal.fire({
                        title: 'Motivo de anulación',
                        html:
                            '<div class="mx-auto mt-4" style="width: 450px;">' +
                            `<input class="form-control" id="mensaje" class="mx-auto mb-4" type="text">` +
                            '</div',
                        background: "black",
                        showCancelButton: true,
                        confirmButtonColor: "#4040ff",
                        confirmButtonText: "Aceptar",
                        cancelButtonText: "Cancelar",
                        cancelButtonColor: "#646464",
                        showCloseButton: true,
                        customClass: {
                            title: 'sweet_titleImportant',
                        },
                        preConfirm: () => {
                            let texto = document.getElementById('mensaje').value
                            if (texto){
                                this.props.anularFac_fel(id, texto, certificadorFel);
                            }
                            if (!texto) {
                                Swal.showValidationMessage(
                                    'Este campo es requerido'
                                )
                            }
                        }
                    })
                    
                }
            });
        }
    };

    eliminar_insumo = (id, numero) => {
        return () => {
            Swal.fire({
                title: '¿Eliminar correlativo: '+ numero + '?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.eliminar_insumo(id);
                }
            });
        }
    };

    eliminarND = (id, numero_fac) => {
        return () => {
            Swal.fire({
                title: '¿Eliminar nota de débito: ' + ' '+ numero_fac + '?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.eliminarND(id);
                }
            });
        }
    };

    eliminarNTDev = (id, serie_fac, numero_fac) => {
        return () => {
            Swal.fire({
                title: '¿Eliminar nota de devolución: ' + ' '+ serie_fac + ' - ' + numero_fac + '?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.eliminarNTDev(id);
                }
            });
        }
    };

    eliminarAnticipo = (id, serie_fac, numero_fac) => {
        return () => {
            Swal.fire({
                title: '¿Eliminar nota de devolución: ' + ' '+ serie_fac + ' - ' + numero_fac + '?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.eliminarAnticipo(id);
                }
            });
        }
    };

    eliminarHora = (id, codigo) => {
        return () => {
            Swal.fire({
                title: '¿Eliminar hora extra codigo colaborador: ' + codigo + '?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.eliminarHora(id);
                }
            });
        }
    };

    eliminarDescuento = (id, codigo) => {
        return () => {
            Swal.fire({
                title: '¿Eliminar descuento codigo colaborador: ' + codigo + '?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.eliminarDescuento(id);
                }
            });
        }
    };

    eliminarISR = (id, codigo) => {
        return () => {
            Swal.fire({
                title: '¿Eliminar codigo colaborador: ' + codigo + '?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.eliminarISR(id);
                }
            });
        }
    };

    eliminarIGSS = (id, codigo) => {
        return () => {
            Swal.fire({
                title: '¿Eliminar codigo colaborador: ' + codigo + '?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.eliminarIGSS(id);
                }
            });
        }
    };

    eliminarAltaIgss = (id, codigo) => {
        return () => {
            Swal.fire({
                title: '¿Eliminar codigo colaborador: ' + codigo + '?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.eliminarAltaIgss(id);
                }
            });
        }
    };

    imprimirLiquidacion = (id) => {
        return () => {
            this.props.imprimirLiquidacion(id);
        }
    };

    eliminarGasto = (id, desc_gasto) => {
        return () => {
            Swal.fire({
                title: '¿Eliminar gasto: ' + desc_gasto + '?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.eliminarGasto(id);
                }
            });
        }
    };

    eliminarProducto = (id, desc) => {
        return () => {
            Swal.fire({
                title: '¿Eliminar producto: \n'+ desc + '?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.eliminarProducto(id);
                }
            });
        }
    };

    anularReciboCaja = (id, serie_fac, numero_fac) => {
        return () => {
            Swal.fire({
                title: '¿Anular recibo: \n'
                    + serie_fac + ' -- '+ numero_fac + '?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, anular!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.anularReciboCaja(id);
                }
            });
        }
    };

    eliminar_registro_sanitario = (id, reg) => {
        return () => {
            Swal.fire({
                title: '¿Eliminar registro: \n'
                    + reg + '?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.eliminar_registro_sanitario(id);
                }
            });
        }
    };

    imprimirFactura = (id) => {
        return () => {
            this.props.imprimirFactura(id);
        }
    };

    anularNotaCredito = (id, serie_fac, numero_fac) => {
        return () => {
            Swal.fire({
                title: '¿Anular nota de crédito: \n'
                    + serie_fac + ' -- '+ numero_fac + '?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, anular!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.anularNotaCredito(id);
                }
            });
        }
    };

    eliminarNC_FACT = (id, serie_nc, numero_nc) => {
        return () => {
            Swal.fire({
                title: '¿Eliminar nota de crédito: \n' 
                + ' ' + serie_nc + ' -- ' + numero_nc + '?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.eliminarNC_FACT(id);
                }
            });
        }
    };

    eliminar_registro_coempaque = (id, reg) => {
        return () => {
            Swal.fire({
                title: '¿Eliminar registro coempaque: \n'
                    + reg + '?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.eliminar_registro_coempaque(id);
                }
            });
        }
    };

    anularOC = (id, numero_fac) => {
        return () => {
            Swal.fire({
                title: '¿Anular orden de compra: \n'
                    + 'No. ' + numero_fac + '?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, anular!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.anularOC(id);
                }
            });
        }
    };

    anularConsumoPro = (id, numero_fac) => {
        return () => {
            Swal.fire({
                title: '¿Anular consumo material: \n'
                    + 'No. ' + numero_fac + '?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, anular!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.anularConsumoPro(id);
                }
            });
        }
    };

    descargarFactura = (id) => {
        return () => {
            this.props.descargarFactura(id);
        }
    };

    eliminarCEnvio = (id, serie_nc, numero_nc) => {
        return () => {
            Swal.fire({
                title: '¿Eliminar consolidación envío: \n' 
                + ' ' + serie_nc + ' -- ' + numero_nc + '?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.eliminarCEnvio(id);
                }
            });
        }
    };

    render() {
        const { id, ver, editar, eliminar, imprimir, excel, anularCK, val_envio, editar_i, editar_e,
                ver_a, editar_a, periodo, empresa, modulo, tipo_pol, anularFac, serie_fac, tipo_documento,
                editar_pol_a, eliminar_pol_a, ver_inv, editar_inv, tipo_F, numero_fac, anularNotaCobro,
                anularEnvio, status_envio, imprimir_e, eliminarFac, eliminarNC, eliminarDep, fact_envio,
                tipo_poliza, numero_poliza, eliminar_e, eliminar_nota, editar_ck, ver_inv_fel, editar_fac_fel,
                anularFac_fel, editar_lfg, ver_lfg, imprimirAlta, imprimirCK, tipo_fact_fel, ver_fac_fel,
                eliminar_insumo, eliminarND, numeroND, vigente_impuesto, editar_imp, id_ck, cuenta_ban_ck,
                factura_fel, asignar_tipo_documento, asignar_tipo_documento_lfg, eliminarNTDev, eliminarAnticipo,
                estado_liq, editar_liq, editar_lfg_liq, ver_liq, ver_lfg_liq, editar_nomina, ver_nomina, ver_nomina_lfg, 
                editar_nomina_lfg, estado_nomina, editar_periodo_nomina, editar_periodo_nomina_lfg,
                codigo_colaborador, eliminarHora, eliminarDescuento, eliminarISR, estado_descuento_nomina,
                editar_nom_desc, editar_nom_desc_lfg, estado_baja_igss, editar_baja_igss, eliminarIGSS,
                editar_baja_igss_lfg, eliminarAltaIgss, editar_alta_igss, editar_alta_igss_lfg, ver_lfg_a, 
                editar_lfg_a, editar_pol, imprimirLiquidacion, idColaborador, eliminarGasto, desc_gasto,
                eliminarProducto, producto_reg_sanitario, anularReciboCaja, numero_registro_sanitario,
                eliminar_registro_sanitario, imprimirFactura, numero_autorizacion_fel, anularNotaCredito,
                eliminarNC_FACT, serie_nc, numero_nc, ver_fac_inventario_fel, ver_fac_fel_especial, certificadorFel,
                ver_fac_inventario_sfel, editar_fac_inventario_sfel, numero_autorizacion_rs_co,
                eliminar_registro_coempaque, anularOC, ingreso_inventario, editar_os, fecha_c_proyecto,
                anularConsumoPro, descargarFactura, eliminarCEnvio } = this.props;
            
        return (
            <div className="d-flex justify-content-center">
                {(asignar_tipo_documento !== undefined) && (
                    <a style={{color: "white"}} href={`/#/${modulo}/${empresa}/${asignar_tipo_documento}/${id}/editar`}><i className="material-icons">perm_contact_calendar</i></a>
                )}
                {(asignar_tipo_documento_lfg !== undefined) && (
                    <a style={{color: "white"}} href={`/#/lfg/${modulo}/${empresa}/${asignar_tipo_documento_lfg}/${id}/editar`}><i className="material-icons">perm_contact_calendar</i></a>
                )}
                {(ver_inv !== undefined && tipo_F === 'B') && (
                    <a style={{color: "white"}} href={`/#/${modulo}/${empresa}/${ver_inv}/${periodo}/${id}/bienes`} className="px-2" ><i className="material-icons">remove_red_eye</i></a>
                )}
                {(editar_inv !== undefined && tipo_F === 'B') && (
                    <a style={{color: "white"}} href={`/#/${modulo}/${empresa}/${editar_inv}/${periodo}/${id}/editar/bienes`}><i className="material-icons">edit</i></a>
                )}
                {(ver_inv !== undefined && tipo_F === 'S') && (
                    <a style={{color: "white"}} href={`/#/${modulo}/${empresa}/${ver_inv}/${periodo}/${id}/servicios`} className="px-2" ><i className="material-icons">remove_red_eye</i></a>
                )}
                {(editar_inv !== undefined && tipo_F === 'S') && (
                    <a style={{color: "white"}} href={`/#/${modulo}/${empresa}/${editar_inv}/${periodo}/${id}/editar/servicios`}><i className="material-icons">edit</i></a>
                )}
                {(ver_nomina !== undefined && estado_nomina === 'F') && (
                    <a style={{color: "white"}} href={`/#/${modulo}/${empresa}/${ver_nomina}/${id}`} className="px-2" ><i className="material-icons">remove_red_eye</i></a>
                )}
                {(ver_nomina_lfg !== undefined && estado_nomina === 'F') && (
                    <a style={{color: "white"}} href={`/#/lfg/${modulo}/${empresa}/${ver_nomina_lfg}/${id}`} className="px-2" ><i className="material-icons">remove_red_eye</i></a>
                )}
                {(editar_nomina !== undefined && estado_nomina === 'R') && (
                    <a style={{color: "white"}} href={`/#/${modulo}/${empresa}/${editar_nomina}/${id}/editar`}><i className="material-icons">edit</i></a>
                )}
                {(editar_nomina_lfg !== undefined && estado_nomina === 'R') && (
                    <a style={{color: "white"}} href={`/#/lfg/${modulo}/${empresa}/${editar_nomina_lfg}/${id}/editar`}><i className="material-icons">edit</i></a>
                )}
                {(ver_fac_fel !== undefined) && (
                    <a style={{color: "white"}} href={`/#/${modulo}/${empresa}/${ver_fac_fel}/${periodo}/${id}/fel`} className="px-2" ><i className="material-icons">remove_red_eye</i></a>
                )}
                {(ver_fac_fel_especial !== undefined) && (
                    <a style={{color: "white"}} href={`/#/${modulo}/${empresa}/${ver_fac_fel_especial}/${periodo}/${id}`} className="px-2" ><i className="material-icons">remove_red_eye</i></a>
                )}
                {(ver_fac_inventario_fel !== undefined && tipo_fact_fel === 'B') && (
                    <a style={{color: "white"}} href={`/#/${modulo}/${empresa}/${ver_fac_inventario_fel}/${periodo}/${id}/bienes_farm_fel`} className="px-2" ><i className="material-icons">remove_red_eye</i></a>
                )}
                {(ver_fac_inventario_sfel !== undefined && tipo_fact_fel === 'B') && (
                    <a style={{color: "white"}} href={`/#/${modulo}/${empresa}/${ver_fac_inventario_sfel}/${periodo}/${id}/bienes_farm`} className="px-2" ><i className="material-icons">remove_red_eye</i></a>
                )}
                {(ver_fac_inventario_sfel !== undefined && tipo_fact_fel === 'S') && (
                    <a style={{color: "white"}} href={`/#/${modulo}/${empresa}/${ver_fac_inventario_sfel}s/${periodo}/${id}/servicios`} className="px-2" ><i className="material-icons">remove_red_eye</i></a>
                )}
                {(editar_fac_inventario_sfel !== undefined && tipo_fact_fel === 'B') && (
                    <a style={{color: "white"}} href={`/#/${modulo}/${empresa}/${editar_fac_inventario_sfel}/${periodo}/${id}/editar/bienes_farm`}><i className="material-icons">edit</i></a>
                )}
                {(editar_fac_inventario_sfel !== undefined && tipo_fact_fel === 'S') && (
                    <a style={{color: "white"}} href={`/#/${modulo}/${empresa}/${editar_fac_inventario_sfel}s/${periodo}/${id}/editar/servicios`}><i className="material-icons">edit</i></a>
                )}
                {(ver_fac_inventario_fel !== undefined && tipo_fact_fel === 'S') && (
                    <a style={{color: "white"}} href={`/#/${modulo}/${empresa}/${ver_fac_inventario_fel}/${periodo}/${id}/servicios_farm_fel`} className="px-2" ><i className="material-icons">remove_red_eye</i></a>
                )}
                {(ver_inv !== undefined && tipo_F === null) && (
                    <a style={{color: "white"}} href={`/#/${modulo}/${empresa}/${ver_inv}/${periodo}/${id}`} className="px-2" ><i className="material-icons">remove_red_eye</i></a>
                )}
                {(ver_inv_fel !== undefined && fact_envio === 'N' && tipo_fact_fel === 'S') && (
                    <a style={{color: "white"}} href={`/#/${modulo}/${empresa}/${ver_inv_fel}/${periodo}/${id}/servicios_fel`} className="px-2" ><i className="material-icons">remove_red_eye</i></a>
                )}
                {(ver_inv_fel !== undefined && fact_envio === 'N' && tipo_fact_fel === 'B') && (
                    <a style={{color: "white"}} href={`/#/${modulo}/${empresa}/${ver_inv_fel}/${periodo}/${id}/bienes_s_envio`} className="px-2" ><i className="material-icons">remove_red_eye</i></a>
                )}
                {(ver_inv_fel !== undefined && fact_envio === 'S' && tipo_fact_fel === 'B') && (
                    <a style={{color: "white"}} href={`/#/${modulo}/${empresa}/${ver_inv_fel}/${periodo}/${id}/bienes_envio`} className="px-2" ><i className="material-icons">remove_red_eye</i></a>
                )}
                {(editar_fac_fel !== undefined) && (
                    <a style={{color: "white"}} href={`/#/${modulo}/${empresa}/${editar_fac_fel}/${periodo}/${id}/editar`}><i className="material-icons">edit</i></a>
                )}
                {(editar_inv !== undefined && tipo_F === null) && (
                    <a style={{color: "white"}} href={`/#/${modulo}/${empresa}/${editar_inv}/${periodo}/${id}/editar`}><i className="material-icons">edit</i></a>
                )}
                {(ver_a !== undefined) && (
                    <a style={{color: "white"}} href={`/#/${modulo}/${empresa}/${ver_a}/${periodo}/${id}/`} className="px-2" ><i className="material-icons">remove_red_eye</i></a>
                )}
                {(ver_lfg_liq !== undefined) && (
                    <a style={{color: "white"}} href={`/#/lfg/${modulo}/${empresa}/${ver_lfg_liq}/${id}/`} className="px-2" ><i className="material-icons">remove_red_eye</i></a>
                )}
                {(ver_liq !== undefined) && (
                    <a style={{color: "white"}} href={`/#/${modulo}/${empresa}/${ver_liq}/${id}/`} className="px-2" ><i className="material-icons">remove_red_eye</i></a>
                )}
                {(ver_lfg !== undefined) && (
                    <a style={{color: "white"}} href={`/#/lfg/${modulo}/${empresa}/${ver_lfg}/${periodo}/${id}/`} className="px-2" ><i className="material-icons">remove_red_eye</i></a>
                )}
                {(ver_lfg_a !== undefined) && (
                    <a style={{color: "white"}} href={`/#/lfg/${modulo}/${empresa}/${ver_lfg_a}/${id}/`} className="px-2" ><i className="material-icons">remove_red_eye</i></a>
                )}
                {(editar_e !== undefined && tipo_documento === null && status_envio === '**ACTIVO**') && (
                    <a style={{color: "white"}} href={`/#/${modulo}/${empresa}/${editar_e}/${periodo}/${id}/editar`}><i className="material-icons">edit</i></a>
                )}
                {(eliminar_e !== undefined && tipo_documento === null && status_envio === '**ACTIVO**') && (
                    <a className="px-2" style={{cursor: "pointer", color: "white"}} onClick={this.eliminar_e(id, serie_fac, numero_fac)}><i className="material-icons">delete</i></a>
                )}
                {(editar_i !== undefined && val_envio === null) && (
                    <a style={{color: "white"}} href={`/#/${modulo}/${empresa}/${editar_i}/${periodo}/${id}/editar`}><i className="material-icons">edit</i></a>
                )}
                {(editar_imp !== undefined && vigente_impuesto === true) && (
                    <a style={{color: "white"}} href={`/#/${modulo}/${empresa}/${editar_imp}/${periodo}/${id}/editar`}><i className="material-icons">edit</i></a>
                )}
                {(editar_a !== undefined) && (
                    <a style={{color: "white"}} href={`/#/${modulo}/${empresa}/${editar_a}/${periodo}/${id}/editar`}><i className="material-icons">edit</i></a>
                )}
                {(editar_os !== undefined && fecha_c_proyecto === null) && (
                    <a style={{color: "white"}} href={`/#/${modulo}/${empresa}/${editar_os}/${periodo}/${id}/editar`}><i className="material-icons">edit</i></a>
                )}
                {(editar_nom_desc !== undefined && estado_descuento_nomina !== 'F') && (
                    <a style={{color: "white"}} href={`/#/${modulo}/${empresa}/${editar_nom_desc}/${periodo}/${id}/editar`}><i className="material-icons">edit</i></a>
                )}
                {(editar_periodo_nomina !== undefined && estado_nomina === 'A') && (
                    <a style={{color: "white"}} href={`/#/${modulo}/${empresa}/${editar_periodo_nomina}/${periodo}/${id}/editar`}><i className="material-icons">edit</i></a>
                )}
                {(editar_periodo_nomina_lfg !== undefined && estado_nomina === 'A') && (
                    <a style={{color: "white"}} href={`/#/lfg/${modulo}/${empresa}/${editar_periodo_nomina_lfg}/${periodo}/${id}/editar`}><i className="material-icons">edit</i></a>
                )}
                {(editar_liq !== undefined && estado_liq === 'P') && (
                    <a style={{color: "white"}} href={`/#/${modulo}/${empresa}/${editar_liq}/${id}/editar`}><i className="material-icons">edit</i></a>
                )}
                {(editar_lfg_liq !== undefined && estado_liq === 'P') && (
                    <a style={{color: "white"}} href={`/#/lfg/${modulo}/${empresa}/${editar_lfg_liq}/${id}/editar`}><i className="material-icons">edit</i></a>
                )}
                {(editar_lfg !== undefined) && (
                    <a style={{color: "white"}} href={`/#/lfg/${modulo}/${empresa}/${editar_lfg}/${periodo}/${id}/editar`}><i className="material-icons">edit</i></a>
                )}
                {(editar_lfg_a !== undefined) && (
                    <a style={{color: "white"}} href={`/#/lfg/${modulo}/${empresa}/${editar_lfg_a}/${id}/editar`}><i className="material-icons">edit</i></a>
                )}
                {(editar_nom_desc_lfg !== undefined && estado_descuento_nomina !== 'F') && (
                    <a style={{color: "white"}} href={`/#/lfg/${modulo}/${empresa}/${editar_nom_desc_lfg}/${periodo}/${id}/editar`}><i className="material-icons">edit</i></a>
                )}
                {(editar_ck !== undefined && status_envio === '**ACTIVO**') && (
                    <a style={{color: "white"}} href={`/#/${modulo}/${empresa}/${editar_ck}/${periodo}/${id}/editar`}><i className="material-icons">edit</i></a>
                )}
                {(ver !== undefined) && (
                    <Link style={{color: "white"}} to={`${ver}/${id}/`} className="px-2" ><i className="material-icons">remove_red_eye</i></Link>
                )}
                {(editar !== undefined) && (
                    <Link style={{color: "white"}} to={`${editar}/${id}/editar`} ><i className="material-icons">edit</i></Link>
                )}
                {(eliminarFac !== undefined) && (
                    <a className="px-2" style={{cursor: "pointer", color: "white"}} onClick={this.eliminarFac(id, serie_fac, numero_fac)}><i className="material-icons">delete</i></a>
                )}
                {(eliminar !== undefined) && (
                    <a className="px-2" style={{cursor: "pointer", color: "white"}} onClick={this.eliminar(id)}><i className="material-icons">delete</i></a>
                )}
                {(editar_baja_igss !== undefined && estado_baja_igss === true) && (
                    <a style={{color: "white"}} href={`/#/${modulo}/${empresa}/${editar_baja_igss}/${id}/editar`}><i className="material-icons">edit</i></a>
                )}
                {(editar_baja_igss_lfg !== undefined && estado_baja_igss === true) && (
                    <a style={{color: "white"}} href={`/#/lfg/${modulo}/${empresa}/${editar_baja_igss_lfg}/${id}/editar`}><i className="material-icons">edit</i></a>
                )}
                {(editar_alta_igss !== undefined) && (
                    <a style={{color: "white"}} href={`/#/${modulo}/${empresa}/${editar_alta_igss}/${id}/editar`}><i className="material-icons">edit</i></a>
                )}
                {(editar_alta_igss_lfg !== undefined) && (
                    <a style={{color: "white"}} href={`/#/lfg/${modulo}/${empresa}/${editar_alta_igss_lfg}/${id}/editar`}><i className="material-icons">edit</i></a>
                )}
                {(eliminarHora !== undefined && estado_descuento_nomina !== 'F') && (
                    <a className="px-2" style={{cursor: "pointer", color: "white"}} onClick={this.eliminarHora(id, codigo_colaborador)}><i className="material-icons">delete</i></a>
                )}
                {(eliminarDescuento !== undefined && estado_descuento_nomina !== 'F') && (
                    <a className="px-2" style={{cursor: "pointer", color: "white"}} onClick={this.eliminarDescuento(id, codigo_colaborador)}><i className="material-icons">delete</i></a>
                )}
                {(eliminarISR !== undefined) && (
                    <a className="px-2" style={{cursor: "pointer", color: "white"}} onClick={this.eliminarISR(id, codigo_colaborador)}><i className="material-icons">delete</i></a>
                )}
                {(eliminarIGSS !== undefined && estado_baja_igss === true ) && (
                    <a className="px-2" style={{cursor: "pointer", color: "white"}} onClick={this.eliminarIGSS(id, codigo_colaborador)}><i className="material-icons">delete</i></a>
                )}
                {(eliminarAltaIgss !== undefined) && (
                    <a className="px-2" style={{cursor: "pointer", color: "white"}} onClick={this.eliminarAltaIgss(id, codigo_colaborador)}><i className="material-icons">delete</i></a>
                )}
                {(eliminar_insumo !== undefined) && (
                    <a className="px-2" style={{cursor: "pointer", color: "white"}} onClick={this.eliminar_insumo(id, numero_fac)}><i className="material-icons">delete</i></a>
                )}
                {(eliminar_registro_sanitario !== undefined) && (
                    <a className="px-2" style={{cursor: "pointer", color: "white"}} onClick={this.eliminar_registro_sanitario(id, numero_registro_sanitario)}><i className="material-icons">delete</i></a>
                )}
                {(eliminar_registro_coempaque !== undefined) && (
                    <a className="px-2" style={{cursor: "pointer", color: "white"}} onClick={this.eliminar_registro_coempaque(id, numero_autorizacion_rs_co)}><i className="material-icons">delete</i></a>
                )}
                {(eliminarNC !== undefined) && (
                    <a className="px-2" style={{cursor: "pointer", color: "white"}} onClick={this.eliminarNC(id, numero_fac)}><i className="material-icons">delete</i></a>
                )}
                {(eliminarNC_FACT !== undefined) && (
                    <a className="px-2" style={{cursor: "pointer", color: "white"}} onClick={this.eliminarNC_FACT(id, serie_nc, numero_nc)}><i className="material-icons">delete</i></a>
                )}
                 {(eliminarProducto !== undefined) && (
                    <a className="px-2" style={{cursor: "pointer", color: "white"}} onClick={this.eliminarProducto(id,  producto_reg_sanitario)}><i className="material-icons">delete</i></a>
                )}
                {(eliminarND !== undefined) && (
                    <a className="px-2" style={{cursor: "pointer", color: "white"}} onClick={this.eliminarND(id, numeroND)}><i className="material-icons">delete</i></a>
                )}
                 {(eliminarAnticipo !== undefined) && (
                    <a className="px-2" style={{cursor: "pointer", color: "white"}} onClick={this.eliminarAnticipo(id, serie_fac, numero_fac)}><i className="material-icons">delete</i></a>
                )}
                {(eliminarNTDev !== undefined && tipo_documento === null ) && (
                    <a className="px-2" style={{cursor: "pointer", color: "white"}} onClick={this.eliminarNTDev(id, serie_fac, numero_fac)}><i className="material-icons">delete</i></a>
                )}
                {(eliminarDep !== undefined) && (
                    <a className="px-2" style={{cursor: "pointer", color: "white"}} onClick={this.eliminarDep(id, numero_fac)}><i className="material-icons">delete</i></a>
                )}
                {(eliminarGasto !== undefined) && (
                    <a className="px-2" style={{cursor: "pointer", color: "white"}} onClick={this.eliminarGasto(id, desc_gasto)}><i className="material-icons">delete</i></a>
                )}
                {(editar_pol_a !== undefined && tipo_pol === 'CNT') && (
                    <a style={{color: "white"}} href={`/#/${modulo}/${empresa}/${editar_pol_a}/${periodo}/${id}/editar`}><i className="material-icons">edit</i></a>
                )}
                {(editar_pol !== undefined) && (
                    <a style={{color: "white"}} href={`/#/${modulo}/${empresa}/${editar_pol}/${periodo}/${id}/editar`}><i className="material-icons">edit</i></a>
                )}
                {(eliminar_pol_a !== undefined && tipo_pol === 'CNT') && (
                    <a className="px-2" style={{cursor: "pointer", color: "white"}} onClick={this.eliminar_pol_a(id, tipo_poliza, numero_poliza)}><i className="material-icons">delete</i></a>
                )}
                {(eliminar_nota !== undefined) && (
                    <a className="px-2" style={{cursor: "pointer", color: "white"}} onClick={this.eliminar_nota(id, serie_fac, numero_fac)}><i className="material-icons">delete</i></a>
                )}
                {(imprimirCK !== undefined && status_envio === '**ACTIVO**') && (
                    <a className="px-2" style={{cursor: "pointer", color: "white"}} onClick={this.imprimirCK(id)}><i className="material-icons">print</i></a>
                )}
                {(imprimir !== undefined) && (
                    <a className="px-2" style={{cursor: "pointer", color: "white"}} onClick={this.imprimir(id)}><i className="material-icons">print</i></a>
                )}
                {(imprimirAlta !== undefined) && (
                    <a className="px-2" style={{cursor: "pointer", color: "white"}} onClick={this.imprimirAlta(id)}><i className="material-icons">print</i></a>
                )}
                {(imprimirLiquidacion !== undefined) && (
                    <a className="px-2" style={{cursor: "pointer", color: "white"}} onClick={this.imprimirLiquidacion(idColaborador)}><i className="material-icons">print</i></a>
                )}
                {(imprimir_e !== undefined && status_envio === '**ACTIVO**') && (
                    <a className="px-2" style={{cursor: "pointer", color: "white"}} onClick={this.imprimir_e(id)}><i className="material-icons">print</i></a>
                )}
                {(imprimirFactura !== undefined) && (
                    <a className="px-2" style={{cursor: "pointer", color: "white"}} onClick={this.imprimirFactura(numero_autorizacion_fel)}><i className="material-icons">print</i></a>
                )}
                {(descargarFactura !== undefined) && (
                    <a className="px-2" style={{cursor: "pointer", color: "white"}} onClick={this.descargarFactura(numero_autorizacion_fel)}><i className="material-icons">file_download</i></a>
                )}
                {(excel !== undefined) && (
                    <a className="px-2" style={{cursor: "pointer", color: "white"}} onClick={this.excel(id)}><FaIcons.FaFileExcel/></a>
                )}
                {(anularCK !== undefined && status_envio === '**ACTIVO**') && (
                    <a className="px-2" style={{cursor: "pointer", color: "red"}} onClick={this.anularCK(id, id_ck, cuenta_ban_ck)}><i className="material-icons">cancel</i></a>
                )}
                {(anularFac !== undefined) && (
                    <a className="px-2" style={{cursor: "pointer", color: "red"}} onClick={this.anularFac(id, serie_fac, numero_fac)}><i className="material-icons">cancel</i></a>
                )}
                {(anularNotaCredito !== undefined) && (
                    <a className="px-2" style={{cursor: "pointer", color: "red"}} onClick={this.anularNotaCredito(id, serie_fac, numero_fac)}><i className="material-icons">cancel</i></a>
                )}
                {(anularOC !== undefined && ingreso_inventario === false) && (
                    <a className="px-2" style={{cursor: "pointer", color: "red"}} onClick={this.anularOC(id, numero_fac)}><i className="material-icons">cancel</i></a>
                )}
                {(anularConsumoPro !== undefined && fecha_c_proyecto === null) && (
                    <a className="px-2" style={{cursor: "pointer", color: "red"}} onClick={this.anularConsumoPro(id, numero_fac)}><i className="material-icons">cancel</i></a>
                )}
                {(anularFac_fel !== undefined && factura_fel) && (
                    <a className="px-2" style={{cursor: "pointer", color: "red"}} onClick={this.anularFac_fel(id, serie_fac, numero_fac, certificadorFel)}><i className="material-icons">cancel</i></a>
                )}
                {(anularNotaCobro !== undefined) && (
                    <a className="px-2" style={{cursor: "pointer", color: "red"}} onClick={this.anularNotaCobro(id, serie_fac, numero_fac)}><i className="material-icons">cancel</i></a>
                )}
                 {(anularEnvio !== undefined && tipo_documento === null && status_envio === '**ACTIVO**') && (
                    <a className="px-2" style={{cursor: "pointer", color: "red"}} onClick={this.anularEnvio(id, serie_fac, numero_fac)}><i className="material-icons">cancel</i></a>
                )}
                {(anularReciboCaja !== undefined) && (
                    <a className="px-2" style={{cursor: "pointer", color: "red"}} onClick={this.anularReciboCaja(id, serie_fac, numero_fac)}><i className="material-icons">cancel</i></a>
                )}
                {(eliminarCEnvio !== undefined) && (
                    <a className="px-2" style={{cursor: "pointer", color: "white"}} onClick={this.eliminarCEnvio(id, serie_nc, numero_nc)}><i className="material-icons">delete</i></a>
                )}
            </div>
        );
    }
}
Acciones.propTypes = {
};

export function standardActions(acciones) {
    return (cell, row) => {
        return ( <Acciones id={cell} tipo_pol={row.modulo} tipo_fact_fel={row.tipo}
            tipo_F={row.tipo} serie_fac={row.serie} numero_fac={row.numero} numeroND={row.numero_cheque}
            val_envio={row.envio} tipo_documento={row.tipo_documento} fact_envio={row.fac_envio}
            status_envio={row.estatus} tipo_poliza={row.tipo} numero_poliza={row.numero_poliza}
            vigente_impuesto={row.vigente} id_ck={row.id} cuenta_ban_ck={row.cuenta_bancaria}
            factura_fel={row.numero_autorizacion} estado_liq={row.estado} estado_nomina={row.estado}
            codigo_colaborador = {row.codigo} estado_descuento_nomina = {row.estado}
            estado_baja_igss = {row.activo} idColaborador = {row.colaborador}
            desc_gasto = {row.descripcion_gasto} producto_reg_sanitario = {row.descripcion}
            numero_registro_sanitario = {row.registro} numero_autorizacion_fel = {row.numero_autorizacion}
            serie_nc = {row.serie} numero_nc = {row.numero} numero_autorizacion_rs_co = {row.numero_autorizacion}
            ingreso_inventario = {row.ingreso_inventario} fecha_c_proyecto = {row.fecha_cierre_proyecto}
            {...acciones}/> )
    };
}
