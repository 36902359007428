import React, { useState } from "react";
import styled from "styled-components";
import {
    FicInput,
    FicCheckbox,
    FicComboBox,
} from "../../Utils/FormInputComponents";
import Banner from "../common/Banner";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { validar } from "../../components/ValidarNit";
import GenBtn from "../../components/GenBtn";
import { getEntry, editar, getUsers, getPermisos } from "./actions";
import { withRouter } from "react-router";
import LoadMask from "../../Utils/LoadMask/LoadMask";
import Overlay from "../common/Overlay";
import useUpdateEffect from "../../../../hooks/useUpdateEffect";

const InputsContainer = styled.div`
    background-color: #2b2b2b;
    padding: 2rem;
    /* display: flex;
    flex-wrap: wrap;
    justify-content: stretch; */
    /* margin-bottom: 1rem; */
    border-radius: 5px;
    /* justify-content: center; */
    gap: 12px;
    /* justify-items: center; */
    /* gap: 0.325rem; */
    /* grid-template-columns: 1fr 1fr 1fr; */
    display: grid;
    /* overflow-x: scroll; */
    overflow: visible;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    min-width: 460px;
`;

const idName = "unidadId";

const schema = yup.object({
    // nombre: yup.string().required("El nombre es requerido"),
});

const Separator = ({ children }) => {
    return (
        <React.Fragment>
            <h2
                style={{
                    color: "#bd0034",
                    margin: "1rem 0 0 0",
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                    textShadow: "0 0 2px #000",
                }}
            >
                {children}
            </h2>
            <div
                style={{
                    width: "100%",
                    height: 1,
                    backgroundColor: "#bd0034",
                    margin: "0.25rem 0 1rem 0",
                }}
            ></div>
        </React.Fragment>
    );
};

function Editar({ history, ...props }) {
    const [isLoading, setIsLoading] = useState(false);
    const [activeOverlay, setActiveOverlay] = useState(false);
    const params = props.match.params;
    // const entryId = params[idName];

    const { control, reset, resetField, handleSubmit, watch, setValue } =
        useForm({
            mode: "onChange",
            resolver: yupResolver(schema),
            defaultValues: {
                usuario: null,
                ajustes: false,
                permisos: false,
                series: false,
                clientes: false,
                pacientes: false,
                periodos: false,
                unidades_igss: false,
                informes: false,
                ingreso_patologias: false,
                laminas: false,
                abonos: false,
                tipos_abono: false,
            },
            // defaultValues: async () => {
            //     setIsLoading(true);
            //     const formData = await getEntry(entryId);
            //     setIsLoading(false);
            //     return formData;
            // },
        });

    const resetFields = () => {
        resetField("ajustes");
        resetField("permisos");
        resetField("series");
        resetField("clientes");
        resetField("pacientes");
        resetField("periodos");
        resetField("unidades_igss");
        resetField("informes");
        resetField("ingreso_patologias");
        resetField("laminas");
        resetField("abonos");
        resetField("tipos_abono");
    };

    const setPermisos = (permisos) => {
        if (!permisos) return;
        permisos.forEach((item) => {
            setValue(item.nombre, true);
        });
    };

    async function fetchPermisos() {
        if (!usuario) return;
        resetFields();
        let data = await getPermisos(usuario.value);
        if (!data) return;
        setPermisos(data.permisos);
    }

    const onSubmit = (data) => {
        // let usuario = data.usuario.value;
        let usuario = data.usuario && data.usuario.value;
        delete data.usuario;
        let permisos = Object.keys(data).filter((key) => {
            return data[key];
        });

        let newData = {
            usuario,
            permisos,
        };

        editar(newData);
        reset();
    };

    const usuario = watch("usuario");
    useUpdateEffect(() => {
        fetchPermisos();
    }, [usuario]);

    return (
        <React.Fragment>
            <Banner>Asignar permisos</Banner>
            <GenBtn
                onPress={() => history.goBack()}
                style={{ margin: "5px 0" }}
            >
                Regresar
            </GenBtn>
            <FicComboBox
                label="Usuario"
                name="usuario"
                control={control}
                loadOptions={getUsers}
            />
            <LoadMask loading={isLoading} color="#bd0034" blur={true}>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    style={{ position: "relative" }}
                >
                    <Overlay
                        isActive={!usuario}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            minWidth: "460px",
                            textAlign: "center",
                        }}
                    >
                        <h1 style={{ color: "#bd0034" }}>
                            Seleccione un usuario
                        </h1>
                    </Overlay>
                    <Separator>Catalogo</Separator>
                    <InputsContainer>
                        <FicCheckbox
                            control={control}
                            label="Ajustes"
                            name="ajustes"
                            isDisabled={!usuario}
                        />
                        <FicCheckbox
                            control={control}
                            label="Permisos"
                            name="permisos"
                            isDisabled={!usuario}
                        />
                        <FicCheckbox
                            control={control}
                            label="Series"
                            name="series"
                            isDisabled={!usuario}
                        />
                        <FicCheckbox
                            control={control}
                            label="Clientes"
                            name="clientes"
                            isDisabled={!usuario}
                        />
                        <FicCheckbox
                            control={control}
                            label="Pacientes"
                            name="pacientes"
                            isDisabled={!usuario}
                        />
                        <FicCheckbox
                            control={control}
                            label="Periodos"
                            name="periodos"
                            isDisabled={!usuario}
                        />
                        <FicCheckbox
                            control={control}
                            label="Unidades IGSS"
                            name="unidades_igss"
                            isDisabled={!usuario}
                        />
                    </InputsContainer>
                    <Separator>Patologias</Separator>
                    <InputsContainer>
                        <FicCheckbox
                            control={control}
                            label="Informes"
                            name="informes"
                            isDisabled={!usuario}
                        />
                        <FicCheckbox
                            control={control}
                            label="Ingreso de patologias"
                            name="ingreso_patologias"
                            isDisabled={!usuario}
                        />

                        <FicCheckbox
                            control={control}
                            label="Laminas"
                            name="laminas"
                            isDisabled={!usuario}
                        />
                    </InputsContainer>
                    <Separator>Abonos</Separator>
                    <InputsContainer>
                        <FicCheckbox
                            control={control}
                            label="Abonos"
                            name="abonos"
                            isDisabled={!usuario}
                        />
                        {/* <FicCheckbox
                            control={control}
                            label="Empresas"
                            name="empresas"
                        /> */}
                        <FicCheckbox
                            control={control}
                            label="Tipos de abono"
                            name="tipos_abono"
                            isDisabled={!usuario}
                        />
                    </InputsContainer>
                    <GenBtn
                        style={{ margin: "1rem 0" }}
                        type="submit"
                        isDisabled={!usuario}
                    >
                        Guardar
                    </GenBtn>
                </form>
            </LoadMask>
        </React.Fragment>
    );
}

export default withRouter(Editar);
