import React from "react";
import {
    Button,
    Checkbox,
    Collection,
    useTableOptions,
    Cell,
    Column,
    Row,
    Table,
    TableBody,
    TableHeader,
    TextField,
    Input,
} from "react-aria-components";
import styled from "styled-components";
import { FicNumber } from "../Utils/FormInputComponents";
import { useState, useEffect, useMemo } from "react";
import { useKeyboard } from "react-aria";
import * as Fa from "react-icons/fa";
import TestComboBox from "./ComboBox";
import GenBtn from "../components/GenBtn";
import { isNumber, isObject } from "lodash";
import InfoPopover from "../components/Popover";
import { useFilter } from "react-aria";

const EmptyStateText = styled.div`
    color: #04d338;
    font-weight: 900;
    padding: 1rem;
    text-shadow: #121212 2px 0 10px;
`;

const StyledCheckBox = styled(Checkbox)`
    --border-color: #020202;
    --overlay-background: #2b2b2b;
    --focus-ring-color: #e0d7d7;
    --highlight-background: #929292;
    --highlight-foreground: white;
    --text-color-disabled: #5f4b4b;
    --highlight-hover: #993232;
    --highlight-pressed: #4e1818;
    --selected-color: #929292;
    --selected-color-pressed: #929292;
    --checkmark-color: #00e639;

    display: flex;
    align-items: center;
    gap: 0.571rem;
    font-size: 1.143rem;
    color: var(--text-color);
    forced-color-adjust: none;

    .checkbox {
        width: 1.143rem;
        height: 1.143rem;
        border: 2px solid var(--border-color);
        border-radius: 4px;
        transition: all 200ms;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    svg {
        width: 1rem;
        height: 1rem;
        fill: none;
        stroke: var(--checkmark-color);
        stroke-width: 3px;
        stroke-dasharray: 22px;
        stroke-dashoffset: 66;
        transition: all 200ms;
    }

    &[data-pressed] .checkbox {
        border-color: var(--border-color-pressed);
    }

    &[data-focus-visible] .checkbox {
        outline: 2px solid var(--focus-ring-color);
        outline-offset: 2px;
    }

    &[data-selected],
    &[data-indeterminate] {
        .checkbox {
            border-color: var(--selected-color);
            background: var(--selected-color);
        }

        &[data-pressed] .checkbox {
            border-color: var(--selected-color-pressed);
            background: var(--selected-color-pressed);
        }

        svg {
            stroke-dashoffset: 44;
        }
    }

    &[data-indeterminate] {
        & svg {
            stroke: none;
            fill: var(--checkmark-color);
        }
    }
`;

const StyledTable = styled(Table)`
    --border-color: #020202;
    --overlay-background: #2b2b2b;
    --focus-ring-color: #e0d7d7;
    --highlight-background: #929292;
    --highlight-foreground: white;
    --text-color-disabled: #5f4b4b;
    --highlight-hover: #993232;
    --highlight-pressed: #4e1818;
    --selected-color: #929292;
    --selected-color-pressed: #929292;
    --checkmark-color: #00e639;
    --text-color: #e0e0e0;

    padding: 0.286rem;
    border: 1px solid var(--border-color);
    border-radius: 6px;
    background: var(--overlay-background);
    outline: none;
    border-spacing: 0;
    min-height: 100px;
    align-self: start;
    max-width: 100%;
    word-break: break-word;
    forced-color-adjust: none;
    width: auto;
    overflow-x: auto;

    &[data-focus-visible] {
        outline: 2px solid var(--focus-ring-color);
        outline-offset: -1px;
    }

    .react-aria-TableHeader {
        color: var(--text-color);
        width: fit-content;

        &:after {
            content: "";
            display: table-row;
            height: 2px;
        }

        & tr:last-child .react-aria-Column {
            border-bottom: 1px solid #bd0034;
            cursor: default;
        }
    }

    .react-aria-Row {
        --radius-top: 6px;
        --radius-bottom: 6px;
        --radius: var(--radius-top) var(--radius-top) var(--radius-bottom)
            var(--radius-bottom);
        border-radius: var(--radius);
        clip-path: inset(0 round var(--radius)); /* firefox */
        outline: none;
        cursor: default;
        color: var(--text-color);
        font-size: 1.072rem;
        position: relative;
        transform: scale(1);

        &[data-focus-visible] {
            outline: 2px solid var(--focus-ring-color);
            outline-offset: -2px;
        }

        &[data-pressed] {
            background: var(--gray-100);
        }

        &[data-selected] {
            background: var(--highlight-background);
            color: var(--highlight-foreground);
            --focus-ring-color: var(--highlight-foreground);

            &[data-focus-visible],
            .react-aria-Cell[data-focus-visible] {
                outline-offset: -4px;
            }
        }

        &[data-disabled] {
            color: var(--text-color-disabled);
        }
    }

    .react-aria-Cell,
    .react-aria-Column {
        white-space: nowrap;
        padding: 4px 22px;
        text-align: left;
        outline: none;

        &[data-focus-visible] {
            outline: 2px solid var(--focus-ring-color);
            outline-offset: -2px;
        }
    }
    .react-aria-Column {
        max-height: 253px;
        background-color: #121212;
    }

    .react-aria-Cell {
        border-bottom: #2b2b2b57;
        transform: translateZ(0);

        &:first-child {
            border-radius: var(--radius-top) 0 0 var(--radius-bottom);
        }

        &:last-child {
            border-radius: 0 var(--radius-top) var(--radius-bottom) 0;
        }
    }
`;

function MyColumn(props) {
    return (
        <Column {...props}>
            {({ allowsSorting, sortDirection }) => (
                <React.Fragment>
                    {props.children}
                    {allowsSorting && (
                        <span aria-hidden="true" className="sort-indicator">
                            {sortDirection === "ascending" ? "▲" : "▼"}
                        </span>
                    )}
                </React.Fragment>
            )}
        </Column>
    );
}

function MyTableHeader({ columns, children }) {
    let { selectionBehavior, selectionMode, allowsDragging } =
        useTableOptions();

    return (
        <TableHeader>
            {/* Add extra columns for drag and drop and selection. */}
            {/* {allowsDragging && <Column />}
            {selectionBehavior === "toggle" && (
                <Column>
                    {selectionMode === "multiple" && (
                        <MyCheckbox slot="selection" />
                    )}
                </Column>
            )} */}
            <Collection items={columns}>{children}</Collection>
            <Column />
        </TableHeader>
    );
}

function MyRow({ id, columns, children, data, ...otherProps }) {
    let { selectionBehavior, allowsDragging } = useTableOptions();

    return (
        <Row id={id} {...otherProps}>
            {allowsDragging && (
                <Cell>
                    <Button slot="drag">≡</Button>
                </Cell>
            )}
            {/* {selectionBehavior === "toggle" && (
                <Cell>
                    <MyCheckbox slot="selection" />
                </Cell>
            )} */}
            <Collection items={columns}>{children}</Collection>
            <Cell>
                <GenBtn
                    textColor="hsl(4, 100%, 39%)"
                    background="hsla(4, 100%, 39%, 0.2)"
                    onPress={(e) => data.remove(id)}
                    title="Eliminar"
                    style={{
                        fontWeight: "bold",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Fa.FaTrashAlt />
                </GenBtn>
            </Cell>
        </Row>
    );
}

function MyTableBody({ onPlusClick, items, children, ...props }) {
    return (
        <TableBody {...props}>
            <Collection items={items}>{children}</Collection>
            <MyRow style={{ display: "flex", justifyContent: "center" }}>
                <Cell>
                    <button onClick={onPlusClick}>+</button>
                </Cell>
            </MyRow>
        </TableBody>
    );
}

function MyCheckbox({ children, ...props }) {
    return (
        <StyledCheckBox {...props}>
            {({ isIndeterminate }) => (
                <React.Fragment>
                    <div className="checkbox">
                        <svg viewBox="0 0 18 18" aria-hidden="true">
                            {isIndeterminate ? (
                                <rect x={1} y={7.5} width={15} height={3} />
                            ) : (
                                <polyline points="1 9 7 14 15 4" />
                            )}
                        </svg>
                    </div>
                    {children}
                </React.Fragment>
            )}
        </StyledCheckBox>
    );
}

function EditableCell({ onSave, item, column, options }) {
    const [value, setValue] = useState(item[column.id]);
    const [comboBoxValue, setComboBoxValue] = useState({
        id: "",
        label: item[column.id],
    });
    const [isEditing, setIsEditing] = useState(false);
    const interactionKeys = [
        "Escape",
        "ArrowLeft",
        "ArrowUp",
        "ArrowRight",
        "ArrowDown",
        "Enter",
    ];
    let { keyboardProps } = useKeyboard({
        onKeyUp: (e) => {
            e.continuePropagation();
            e.preventDefault();
            if (interactionKeys.includes(e.key)) handleBlur();
        },
    });

    let { testKeys } = useKeyboard({
        onKeyUp: (e) => {
            if (e.key === "Enter") handleFocus();
        },
    });

    const handleFocus = () => {
        setIsEditing(true);
    };

    const handleBlur = () => {
        setIsEditing(false);
    };

    useEffect(() => {
        if (!value) setIsEditing(true);
        const newItem = { ...item, [column.id]: value };
        onSave(newItem, item.key);
    }, [value]);

    // useEffect(() => {
    //     // console.log(isEditing);
    // }, [isEditing]);

    //ComboBox filtering
    let { startsWith } = useFilter({ sensitivity: "base" });
    let [filterValue, setFilterValue] = useState("");
    let filteredItems = useMemo(
        () => ({
            items: options.items.filter((item) =>
                startsWith(item.label, filterValue)
            ),
        }),
        [options, filterValue]
    );

    return (
        <React.Fragment>
            {isEditing || value === "" ? (
                <Cell>
                    {/* <TextField
                        {...keyboardProps}
                        autoFocus
                        value={value}
                        onChange={setValue}
                        // onFocus={handleFocus}
                        onBlur={handleBlur}
                    >
                        <Input />
                    </TextField> */}
                    {column.input === "input" && (
                        <TextField
                            {...keyboardProps}
                            autoFocus={value}
                            value={value}
                            onChange={setValue}
                            // onFocus={handleFocus}
                            onBlur={handleBlur}
                        >
                            <Input />
                        </TextField>
                    )}
                    {column.input === "number" && (
                        <FicNumber
                            style={{ margin: "5px", marginTop: "-20px" }}
                            nonForm
                            {...keyboardProps}
                            autoFocus={value}
                            value={value}
                            onChange={setValue}
                            onBlur={handleBlur}
                        />
                    )}
                    {column.input === "select" && (
                        <TestComboBox
                            {...keyboardProps}
                            options={filteredItems}
                            autoFocus
                            // value={{ id: "", value: value }}
                            // inputValue={value}
                            onInputChange={setFilterValue}
                            // selectedKey={}
                            onSelectionChange={(id) => {
                                if (id) {
                                    setValue(options.getItem(id));
                                }
                            }}
                            menuTrigger="focus"
                            // onSelectionChange={(e) => console.log(e)}
                            // onFocus={handleFocus}
                            onBlur={handleBlur}
                        />
                    )}
                </Cell>
            ) : (
                <Cell {...testKeys}>
                    <div
                        onDoubleClick={handleFocus}
                        style={{ minWidth: "200px" }}
                    >
                        {isObject(item[column.id])
                            ? item[column.id].label
                            : isNumber(item[column.id])
                            ? item[column.id].toLocaleString()
                            : item[column.id]}
                    </div>
                </Cell>
            )}
        </React.Fragment>
    );
}

export default function EditableTable({ data, options, columns, ...props }) {
    const handleUpdate = (value, field) => {
        data.update(field, value);
    };

    const handleAdd = () => {
        const newObj = {
            key: data.items.length + 1,
            vehiculo: "",
            monto: "",
        };
        data.append(newObj);
    };

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <InfoPopover>
                    <div>
                        Para modificar hacer doble click en los valores
                        <hr />
                        Las filas con valores vacios seran ignoradas
                    </div>
                </InfoPopover>
                <GenBtn
                    style={{ alignSelf: "flex-end", margin: "0.5rem" }}
                    onPress={handleAdd}
                >
                    AGREGAR
                </GenBtn>
            </div>
            <StyledTable aria-label="Files" {...props}>
                <MyTableHeader columns={columns}>
                    {(column) => (
                        <Column isRowHeader={column.isRowHeader}>
                            {column.name}
                        </Column>
                    )}
                </MyTableHeader>
                <TableBody
                    items={data.items}
                    renderEmptyState={() => (
                        <EmptyStateText>
                            Presione el boton "AGREGAR" para agregar vehiculos
                        </EmptyStateText>
                    )}
                >
                    {(item) => (
                        <MyRow columns={columns} data={data}>
                            {(column) => (
                                <EditableCell
                                    item={item}
                                    column={column}
                                    onSave={handleUpdate}
                                    options={options}
                                />
                            )}
                        </MyRow>
                    )}
                </TableBody>
            </StyledTable>
        </div>
    );
}
