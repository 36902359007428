import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import DetalleIngreso from './DetalleIngreso';
import DetalleEnvio from './DetalleEnvio';
import NavbarModulo from '../../components/NavbarModulo';

import {
    renderField,
    renderDayPickerB,
    AsyncSelectField,
    renderNumber
} from "../../Utils/renderField/renderField";
import propTypes from 'prop-types';

function EnvioForm(props) {
    const {
        crear,
        cli,
        periodoD,
        modulo,
        listarSeries,
        listarIngresos,
        funcionEnvio,
        detalleIngreso,
        detalleProducto,
        numeroSerie,
        detalleEnv,
        listarPilotos
    } = props;

    let autoFocus = true;
    
   
    const estilo = {
        overflow: "auto",
        height: "300px",
    };
    let ruta = window.location.href;
    let id_empresa = ruta.split('/');
    
    const editar = window.location.href.includes('editar');
    let titulo = editar ? 'Modificar envio' : 'Crear envio';
    let disabled = false;
    if (crear === false && editar === false) {
        disabled = true;
        titulo = 'Ver envio'
    }
    const [numeroSerieEnv, setNumeroSerieEnv] = useState('');
    let ns;
    const handleOnChangeS = value => {
        ns = numeroSerie(value.label);
        ns.then(d => setNumeroSerieEnv(d) )
    };
    
    const handleOnChange = value => {
        detalleIngreso(value.value);
    };
    
    return (
        <div>
            <NavbarModulo
                periodoD={periodoD}
                modulo={modulo}
                titulo={titulo}
            />
            <br />
            <div className="mb-4 card card-small text-white bg-dark mb-3">
                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                    <div className="d-flex flex-column flex-1 mx-4">
                        {(crear === false && editar === false) &&
                            <div className='row mb-2'>
                                <div className='col-3'>
                                    <Field
                                        autoFocus={autoFocus}
                                        disabled={disabled}
                                        name="fechaRegistro"
                                        component={renderDayPickerB}
                                        msj="Fecha Registro"
                                    />
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="serie"
                                        placeholder="Nombre"
                                        className="form-control"
                                        component={renderField}
                                        disabled={disabled}
                                        msj="Serie"
                                    />
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="numero"
                                        placeholder="Dias credito"
                                        className="form-control"
                                        component={renderNumber}
                                        disabled={disabled}
                                        msj="Numero"
                                    />
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="ingresoPro"
                                        placeholder="Ingreso de producción"
                                        className="form-control"
                                        component={renderField}
                                        disabled={disabled}
                                        msj="Ingreso de producción"
                                    />
                                </div>
                            </div>
                        }
                        {crear === true &&
                            <div className='row mb-2'>
                                <div className='col-3'>
                                    <Field
                                        autoFocus={autoFocus}
                                        disabled={disabled}
                                        name="fechaRegistro"
                                        component={renderDayPickerB}
                                        msj="Fecha Registro"
                                    />
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="serie"
                                        placeholder="Serie..."
                                        className="form-control-find"
                                        loadOptions={listarSeries}
                                        component={AsyncSelectField}
                                        onChange={handleOnChangeS}
                                        msj="Serie"
                                    />
                                </div>
                                <div className='col-3'>
                                    <div className="form-floating">
                                        <input
                                            placeholder="Numero"
                                            type="number"
                                            autocomplete="off"
                                            name="numero"
                                            className="form-control"
                                            value={numeroSerieEnv}
                                            onChange={e =>  setNumeroSerieEnv(e.target.value) }
                                        />
                                        <label className='form-label' htmlFor="cuenta">
                                            Numero
                                        </label>
                                    </div>
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="ingreso"
                                        placeholder="Ingreso de producción..."
                                        className="form-control-find"
                                        loadOptions={listarIngresos}
                                        component={AsyncSelectField}
                                        onChange={handleOnChange}
                                        msj="Ingreso de producción"
                                    />
                                </div>
                                
                            </div>
                        }
                        {(editar === true) &&
                            <div className='row mb-2'>
                                <div className='col-3'>
                                    <Field
                                        autoFocus={autoFocus}
                                        disabled={disabled}
                                        name="fechaRegistro"
                                        component={renderDayPickerB}
                                        msj="Fecha Registro"
                                    />
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="serie"
                                        placeholder="Nombre"
                                        className="form-control"
                                        component={renderField}
                                        disabled={true}
                                        msj="Serie"
                                    />
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="numero"
                                        placeholder="Dias credito"
                                        className="form-control"
                                        component={renderNumber}
                                        disabled={true}
                                        msj="Numero"
                                    />
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="ingresoPro"
                                        placeholder="Ingreso de producción"
                                        className="form-control"
                                        component={renderField}
                                        disabled={true}
                                        msj="Ingreso de producción"
                                    />
                                </div>
                            </div>
                        }
                        {(crear === true) &&
                        <div>
                            <div className='row mb-2'>
                                <div className='col-12'>
                                    <div className="form-floating">
                                        <input
                                            placeholder="Cliente"
                                            type="text"
                                            autocomplete="off"
                                            name="cliente"
                                            className="form-control"
                                            value={detalleProducto.length === 0 ? '' : cli.clienteN}
                                            disabled={true}
                                        />
                                        <label className='form-label' htmlFor="cuenta">
                                            Cliente
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-12'>
                                    <Field
                                        name="observaciones"
                                        disabled={disabled}
                                        placeholder="Observaciones"
                                        className="form-control"
                                        component={renderField}
                                        msj="Observaciones"
                                    />
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-6'>
                                    <Field
                                        name="camion"
                                        disabled={disabled}
                                        placeholder="Camión"
                                        className="form-control"
                                        component={renderField}
                                        msj="Camión"
                                    />
                                </div>
                                <div className='col-6'>
                                    <Field
                                        name="piloto"
                                        placeholder="Piloto..."
                                        className="form-control-find"
                                        loadOptions={listarPilotos}
                                        component={AsyncSelectField}
                                        msj="Piloto"
                                    />
                                </div>
                            </div>
                        </div>
                        }
                        {(crear === false && editar === false) &&
                            <div>
                                <div className='row mb-2'>
                                    <div className='col-12'>
                                        <Field
                                            name="clienteN"
                                            disabled={disabled}
                                            placeholder="Cliente"
                                            className="form-control"
                                            component={renderField}
                                            msj="Cliente"
                                        />
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <div className='col-12'>
                                        <Field
                                            name="observaciones"
                                            disabled={disabled}
                                            placeholder="Observaciones"
                                            className="form-control"
                                            component={renderField}
                                            msj="Observaciones"
                                        />
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <div className='col-6'>
                                        <Field
                                            name="camion"
                                            disabled={disabled}
                                            placeholder="Camión"
                                            className="form-control"
                                            component={renderField}
                                            msj="Camión"
                                        />
                                    </div>
                                    <div className='col-6'>
                                        <Field
                                            name="pilotoN"
                                            disabled={disabled}
                                            placeholder="Piloto"
                                            className="form-control"
                                            component={renderField}
                                            msj="Piloto"
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {(editar === true) &&
                            <div>
                                <div className='row mb-2'>
                                    <div className='col-12'>
                                        <Field
                                            name="clienteN"
                                            disabled={true}
                                            placeholder="Cliente"
                                            className="form-control"
                                            component={renderField}
                                            msj="Cliente"
                                        />
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <div className='col-12'>
                                        <Field
                                            name="observaciones"
                                            disabled={disabled}
                                            placeholder="Observaciones"
                                            className="form-control"
                                            component={renderField}
                                            msj="Observaciones"
                                        />
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <div className='col-6'>
                                        <Field
                                            name="camion"
                                            disabled={disabled}
                                            placeholder="Camión"
                                            className="form-control"
                                            component={renderField}
                                            msj="Camión"
                                        />
                                    </div>
                                    <div className='col-6'>
                                        <Field
                                            name="pilotoN"
                                            disabled={disabled}
                                            placeholder="Piloto"
                                            className="form-control"
                                            component={renderField}
                                            msj="Piloto"
                                        />
                                        <Field
                                            name="piloto"
                                            placeholder="Piloto..."
                                            className="form-control-find"
                                            loadOptions={listarPilotos}
                                            component={AsyncSelectField}
                                            msj="Piloto"
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {crear === true &&
                            <div>
                                <h5 className="p-2 bg-secondary title mt-1">Detalle producción</h5>
                                <br/>
                                <div style={estilo}>
                                    <DetalleIngreso
                                        detalle={detalleProducto}
                                        
                                    />
                                </div>
                            </div>
                        }
                        {(editar === true) &&
                            <div>
                                <h5 className="p-2 bg-secondary title mt-1">Detalle envío</h5>
                                <br/>
                                <div style={estilo}>
                                    <DetalleEnvio
                                        detalle={detalleEnv}
                                    />
                                </div>
                            </div>
                        }
                        {(crear === false && editar === false) &&
                            <div>
                                <h5 className="p-2 bg-secondary title mt-1">Detalle envío</h5>
                                <br/>
                                <div style={estilo}>
                                    <DetalleEnvio
                                        detalle={detalleEnv}
                                    />
                                </div>
                            </div>
                        }
                        <br/>
                        {(crear === true || editar === true) &&
                            <div className='mb-2'>
                                <button
                                    className='btn mr-2 mb-2 btn-primary'
                                    onClick={() => funcionEnvio(periodoD, numeroSerieEnv, cli.cliente)}
                                >
                                {editar ? 'Modificar' : 'Guardar envio'}
                                </button>
                                <a
                                    href={`/#/produccion/${id_empresa[5]}/envio/${id_empresa[7]}`}
                                    className='btn btn-secondary mb-2'
                                >
                                Regresar
                                </a>
                            </div>
                        }
                        {(crear === false && editar === false) &&
                            <div className='mb-2'>
                                <a
                                    href={`/#/produccion/${id_empresa[5]}/envio/${id_empresa[7]}`}
                                    className='btn btn-secondary mb-2'
                                >
                                    Regresar
                                </a>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

EnvioForm.propTypes = {
    crear: PropTypes.bool,
    editar: PropTypes.bool,
    bloqueo: PropTypes.bool,
    fechaInicio: PropTypes.string,
    fechaFinal: PropTypes.string,
    datos: PropTypes.object,
    detallePro: PropTypes.object,
    periodoD: PropTypes.object,
    modulo: PropTypes.object,
    detalleIngreso: PropTypes.func,
    detalleProducto: PropTypes.object,
    cli: propTypes.object,
    numeroSerie: propTypes.func,
    detalleEnv: PropTypes.object
};


export default reduxForm({
    form: 'FormEnvioProduccion',
})(EnvioForm);
