import React from "react";
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import * as BsIcons from "react-icons/bs";
import * as IoIcons from "react-icons/io";
import * as AiIcons from "react-icons/ai";
import * as GiIcons from "react-icons/gi";

export const SidebarData = [
    {
        title: "Tools",
        path: "/tools/home",
        icon: <FaIcons.FaArrowLeft style={{ color: "purple" }} />,
    },
    {
        title: "Finanzas",
        path: "/tools/finanzas",
        icon: <FaIcons.FaCoins />,
    },
    {
        title: "Inicio",
        path: "/tools/finanzas/compras-ventas",
        icon: <FaIcons.FaCoins />,
    },
    {
        title: "Archivos",
        path: "/tools/finanzas",
        icon: <FaIcons.FaFile />,
        dropdown: [
            {
                title: "Libro compras",
                path: "/tools/finanzas/compras-ventas/archivos/libro-compras",
                icon: <FaIcons.FaChevronRight />,
            },
            {
                title: "Contribuyentes",
                path: "/tools/finanzas/compras-ventas/archivos/contribuyentes",
                icon: <FaIcons.FaChevronRight />,
            },
        ],
    },
    {
        title: "Libro compras",
        path: "/tools/finanzas/compras-ventas/libro-compras",
        icon: <FaIcons.FaBook />,
    },
    // {
    //     title: "Balance de saldos",
    //     path: "/tools/finanzas/",
    //     icon: <FaIcons.FaCoins />,
    //     dropdown: [
    //         {
    //             title: "Balance de saldos",
    //             path: "/tools/finanzas/estados-financieros/balance-de-saldos",
    //             icon: <FaIcons.FaChevronRight />,
    //         },
    //         {
    //             title: "Cierres parciales",
    //             path: "/tools/finanzas/estados-financieros/cierres-parciales",
    //             icon: <FaIcons.FaChevronRight />,
    //         },
    //     ],
    // },
    // {
    //     title: 'Empresas',
    //     icon: <FaIcons.FaChevronRight />,
    //     dropdown: [
    //         {
    //             title: 'Listado',
    //             path: '/tools/finanzas/empresas',
    //             icon: <FaIcons.FaChevronRight />
    //         },
    //         {
    //             title: 'Agregar',
    //             path: '/tools/finanzas/agregar-empresa',
    //             icon: <FaIcons.FaChevronRight />
    //         }
    //     ]
    // },
    // {
    //     title: 'Empresas2',
    //     icon: <FaIcons.FaChevronRight />,
    //     dropdown: [
    //         {
    //             title: 'Listado',
    //             path: '/tools/finanzas/empresas2',
    //             icon: <FaIcons.FaChevronRight />
    //         },
    //         {
    //             title: 'Agregar',
    //             path: '/tools/finanzas/agregar-empresa',
    //             icon: <FaIcons.FaChevronRight />
    //         }
    //     ]
    // },
];
