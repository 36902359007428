import React, { Component } from 'react';
import Grid from '../../Utils/GridNominaOtros';
import { standardActions } from '../../Utils/GridNominaOtros/StandardActions';

class NominaList extends Component{

    render(){
        const { loader, detalle, mostrarDescuentos, eliminarLinea } = this.props;
        return(
            <div>
                {detalle &&
                    <Grid 
                        hover 
                        striped 
                        data={detalle} 
                        loading={loader}
                        footer
                        headerStyle={ { background: 'black' } }
                        mostrarDescuentos = {mostrarDescuentos}
                    >
                        <TableHeaderColumn
                            isKey
                            dataField="codigo"
                            width="100"
                            dataAlign='left'
                            dataSort
                            thStyle={
                                {color: 'white' }}
                            tdStyle={
                                {color: 'white' }}
                        >
                            Codigo
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="nombre"
                            dataAlign='left'
                            width="450"
                            dataSort
                            thStyle={
                                {color: 'white' }}
                            tdStyle={
                                {color: 'white' }}
                        >
                            Nombre
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            headerAlign="center"
                            dataAlign='right'
                            dataField="base"
                            width="150"
                            dataFormat={(cell, row)=>{
                                if (parseFloat(cell) === 0.00) {
                                    return ''
                                }else{
                                    return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Math.abs(cell));
                                }
                            }}
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Base
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            headerAlign="center"
                            dataAlign='right'
                            dataField="bonificacion"
                            width="150"
                            dataFormat={(cell, row)=>{
                                if (parseFloat(cell) === 0.00) {
                                    return ''
                                }else{
                                    return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Math.abs(cell));
                                }
                            }}
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Bonificación
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            headerAlign="center"
                            dataAlign='right'
                            dataField="comision"
                            width="150"
                            dataFormat={(cell, row)=>{
                                if (parseFloat(cell) === 0.00) {
                                    return ''
                                }else{
                                    return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(cell);
                                }
                            }}
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Comisiones
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            headerAlign="center"
                            dataAlign='right'
                            dataField="igss"
                            width="150"
                            dataFormat={(cell, row)=>{
                                if (parseFloat(cell) === 0.00) {
                                    return ''
                                }else{
                                    return '( ' + new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Math.abs(cell)) + ' )';
                                }
                            }}
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Igss
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            headerAlign="center"
                            dataAlign='right'
                            dataField="judicial"
                            width="150"
                            dataFormat={(cell, row)=>{
                                if (parseFloat(cell) === 0.00) {
                                    return ''
                                }else{
                                    return '( ' + new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Math.abs(cell)) + ' )';
                                }
                            }}
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Judicial
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            headerAlign="center"
                            dataAlign='right'
                            dataField="total_descuentos"
                            width="150"
                            dataFormat={(cell, row)=>{
                                if (parseFloat(cell) === 0.00) {
                                    return ''
                                }else{
                                    return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Math.abs(cell));
                                }
                            }}
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Total descuentos
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            headerAlign="center"
                            dataAlign='right'
                            dataField="total_recibir"
                            width="150"
                            dataFormat={(cell, row)=>{
                                return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Math.abs(cell));
                            }}
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Total a recibir
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            thStyle={
                                { color: 'white' }}
                            dataField="id"
                            width="150"
                            dataAlign="center"
                            dataSort
                            dataFormat={standardActions({
                                eliminar: eliminarLinea,
                            })}

                        >
                            Acciones
                        </TableHeaderColumn>
                    </Grid>
                }
            </div>
        );
    }
}

export default NominaList;
