import React, { Component } from 'react';
import Grid from '../../Utils/GridFiltro';
import moment from 'moment';

class DetalleHistorial extends Component{
    
    render(){
        const { loader, detalle } = this.props;
        return(
            <React.Fragment>
                {detalle &&
                <Grid 
                    hover 
                    striped 
                    data={detalle} 
                    loading={loader}
                    headerStyle={ { background: 'black' } } 
                >
                    <TableHeaderColumn
                        isKey
                        dataField="numero"
                        width="100"
                        dataAlign='right'
                        headerAlign="center"
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Numero
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="fecha"
                        width="250"
                        dataAlign='left'
                        headerAlign="center"
                        dataSort
                        dataFormat={(cell, row)=>{
                            return moment(cell).format("DD/MM/YYYY HH:mm:ss");
                        }}
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Fecha
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="usuarioN"
                        dataAlign='left'
                        headerAlign="center"
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Usuario
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="tipo_operacion"
                        dataAlign='left'
                        headerAlign="center"
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Tipo de operación
                    </TableHeaderColumn>
                </Grid>
                }
            </React.Fragment>
        );
    }
}

export default DetalleHistorial;
