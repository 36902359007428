import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/produccion/envio';
import Envio from './EnvioList';


const ms2p = (state) => {
  return {
    ...state.envio_prd,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Envio);
