import React, { Component } from 'react';
import Grid from '../Utils/GridUsuario';
import { standardActions } from '../Utils/GridUsuario/StandardActions';
import moment from 'moment';
import PropTypes from 'prop-types';
const valEmp = __CONFIG__.empresa;

class UsuariosList extends Component{
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: true
    };

    componentDidMount = () => {
        const { listaUser, page } = this.props;
        listaUser(page);
        
    }
    render(){
        const { data, loader, listaUser: onPageChange, parametro, eliminar, activar } = this.props;
        let ruta = window.location.href;
        let id_emp = ruta.split('/');
        return( 
            <React.Fragment>
                <br />
                <h3 className="p-2 bg-secondary title">Usuarios</h3>
                <br />
                {valEmp === 'fage' &&
                    <div className="d-flex flex-row justify-content-start mb-2">
                        {parametro && parametro.totalUser<parametro.parametro[0].limite_usuarios && parametro.parametro[0].admin==true ?
                            <a
                                href={`/#/lfg/ajustes/${id_emp[6]}/usuarios/crear`}
                                className='btn btn-primary'
                            >
                                Crear Usuario
                            </a>
                        : ''}
                        <a
                            href={`/#/lfg/ajustes/${id_emp[6]}`}
                            className='btn btn-secondary ml-2'
                        >
                            Regresar
                        </a>
                    </div>
                }
                {valEmp === '' &&
                    <div className="d-flex flex-row justify-content-start mb-2">
                        {parametro && parametro.totalUser<parametro.parametro[0].limite_usuarios && parametro.parametro[0].admin==true ?
                            <a
                                href={`/#/ajustes/${id_emp[5]}/usuarios/crear`}
                                className='btn btn-primary'
                            >
                                Crear Usuario
                            </a>
                        : ''}
                        <a
                            href={`/#/ajustes/${id_emp[5]}`}
                            className='btn btn-secondary ml-2'
                        >
                            Regresar
                        </a>
                    </div>
                }
                {data &&
                    <Grid 
                        hover 
                        striped 
                        data={data} 
                        loading={loader} 
                        headerStyle={ { background: 'black' } } 
                        onPageChange={onPageChange} 
                        //onSortChange={onSortChange} 
                    >
                        <TableHeaderColumn
                            isKey
                            dataField="userName"
                            width="400"
                            dataSort
                            thStyle={ 
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Usuario
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="nombre"
                            width="200"
                            dataSort
                            thStyle={ 
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Nombre
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataAlign="center"
                            dataField="activo"
                            dataFormat={(cell, row)=>{
                                if (cell === 'S'){
                                    return 'A';
                                }else{
                                    return 'I';
                                }
                            }}
                            width="100"
                            dataSort
                            thStyle={ 
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Activo
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="lastLogin"
                            width="150"
                            dataFormat={(cell, row)=>{
                                if (cell === null){
                                    return '';
                                }else{
                                    return moment(cell).format("DD/MM/YYYY h:mm:ss a");
                                }
                            }}
                            dataSort
                            thStyle={ 
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Ultima conexión
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            thStyle={
                            {color: 'white'}}
                            dataField="id"
                            width="100"
                            dataAlign="center"
                            dataSort
                            dataFormat={standardActions({ 
                                ver: "usuarios",
                             })} 
                            
                        >
                            Acciones
                        </TableHeaderColumn>
                    </Grid>
                }
            </React.Fragment>
        );
    }
}

export default UsuariosList;
