import { NotificationManager } from "react-notifications";
import { api } from "api";
import { handleActions, createAction } from 'redux-actions';
import { listadoDeIngresos } from "../informes";

export const listarVentas = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id }
    return api.get('auto_ventas/ventas', params).then(response => {
        return response;
    }).catch((error) => {
        return [];
    })
}

export const listarVendedores = (search) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('auto_ventas/vendedores/listado_resumido', params).then(response => {
        let vendedoresList = []
        response.forEach(item => {
            vendedoresList.push({
                    value: item.vendedor.id,
                    label: item.vendedor.value 
                })
            }
        )
        return vendedoresList;
    }).catch((error) => {
        return [];
    })
}

export const datosDelIngreso = (ingresoId) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let action = "ver"
    const params = { id, action }
    return api.get(`auto_ventas/ingresos/${ingresoId}`, params).then(response => {
        return response;
    }).catch((error) => {
        NotificationManager.error(
            "Ocurrio un error al obtener los datos",
            'Error',
            2000,
            null,
            true
        );
        return [];
    })
}

export const crearVenta = (data) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    data.empresa = id 
    data.carro = data.carro.value
    data.vendedor = data.vendedor.value
    const params = { id, data }
    return api.post('auto_ventas/ventas', params).then((response) => {
            NotificationManager.success(
                'Venta agregada',
                'Exito',
                3000,
                null,
                true
            );
            return response;
        }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                2000,
                null,
                true
            );
            return false;
        })
}

export const reporteVentaDeVehiculos = (ventaId) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    // let carroId = data.marca.value
    let rep = 'venta_de_vehiculos';
    const params = { id, rep, ventaId };
    api.getPdf('/auto_ventas/ventas/reportes', params).then((response)=>{
        if (response.headers['content-type'] == 'application/pdf'){
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'recepcion_de_vehiculos.pdf';
            window.open(link, '_blank');
            return false;
          }
    }).catch((error)=>{
        if (error.status === 404){
            NotificationManager.info(
              error.body.msj,
              'Informacion',
              5000
            );
        }
        if (error.status === 500){
            NotificationManager.error(
              'Ocurrió un error al generar reporte',
              'Error',
              2000
            );
        }
    })
}


export const actions = {
    crearVenta,
    datosDelIngreso,
    listarVendedores,
    listadoDeIngresos,
    listarVentas,
    reporteVentaDeVehiculos
};

export const reducers = {

}

export const initialState = {

};

export default handleActions(reducers, initialState)