import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/produccion/serie';
import Serie from './Serie';


const ms2p = (state) => {
    return {
        ...state.serie_prd,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Serie);