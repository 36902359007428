import React, { useState, useEffect, useRef } from "react";
import FormDialogModal from "../../components/FormDialogModal";

import DataTableWSearch from "../../components/DataTableWSearch";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import GenBtn from "../../components/GenBtn";
import { FicInput } from "../../Utils/FormInputComponents";
import * as Fa from "react-icons/fa";

const schema = yup.object({
    combustible: yup.string().required("El combustible es requerido"),
});

function CombustibleList(props) {
    const {
        handleSubmit,
        control,
        formState: { errors },
        reset,
    } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: {
            combustible: "",
        },
    });

    const {
        handleSubmit: editSubmit,
        reset: editReset,
        control: editControl,
        formState: { errors: editErrors },
    } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: {
            combustible: "",
        },
    });

    const { crearCombustible, listarCombustibles, editarCombustible, data } =
        props;
    const [combustibleFieldData, setCombustibleFieldData] = useState();
    const [combustibleId, setCombustibleId] = useState();

    const dialogRef = useRef(null);
    const editDialogRef = useRef(null);

    async function onSubmit(formData) {
        await crearCombustible(formData);
        reset();
        listarCombustibles();
    }

    async function onEditSubmit(formData) {
        await editarCombustible(formData, combustibleId);
        listarCombustibles();
        editDialogRef.current.close();
        editReset();
    }

    useEffect(() => {
        listarCombustibles();
    }, []);

    const columns = [
        {
            name: "Nombre",
            selector: (row) => row.nombre,
            sortable: true,
        },
        {
            cell: (row) => (
                <GenBtn
                    key={row.nombre}
                    textColor="hsl(0, 0%, 80%)"
                    background="hsla(0, 100%, 100%, 0.2)"
                    onPress={(e) => {
                        editDialogRef.current.showModal();
                        setCombustibleFieldData(row.nombre);
                        setCombustibleId(row.id);
                    }}
                >
                    <Fa.FaPen />
                </GenBtn>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    return (
        <React.Fragment>
            <br />
            <h3
                className="p-2  title rounded shadow"
                style={{
                    background:
                        "linear-gradient(to top right, #790021, #2d2d2d)",
                }}
            >
                Combustible
            </h3>
            <br />
            <div className="d-flex flex-row justify-content-start mb-2">
                <GenBtn
                    onClick={() => {
                        dialogRef.current.showModal();
                    }}
                >
                    Agregar
                </GenBtn>
            </div>

            <DataTableWSearch
                columns={columns}
                data={data}
                fieldToFilter="nombre"
                filterPlaceHolder="Buscar por nombre"
            />

            <FormDialogModal
                dialogRef={dialogRef}
                onSubmit={handleSubmit(onSubmit)}
                title={"Agregar marca"}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "15px",
                        width: "25rem",
                        height: "10rem",
                        padding: "15px",
                    }}
                >
                    <FicInput
                        name="combustible"
                        placeholder="Combustible"
                        control={control}
                    />
                    <div className="d-flex justify-content-end mt-auto">
                        <GenBtn
                            onPress={handleSubmit(onSubmit)}
                            style={{
                                width: "90px",
                                height: "40px",
                                marginBottom: "8px",
                            }}
                        >
                            Agregar
                        </GenBtn>
                    </div>
                </div>
            </FormDialogModal>

            <FormDialogModal
                dialogRef={editDialogRef}
                onSubmit={editSubmit(onEditSubmit)}
                title={"Modificar combustible"}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "15px",
                        width: "25rem",
                        height: "10rem",
                        padding: "15px",
                    }}
                >
                    <FicInput
                        name="combustible"
                        placeholder={`${combustibleFieldData}`}
                        control={editControl}
                    />
                    <div className="d-flex justify-content-end mt-auto">
                        <GenBtn
                            type="submit"
                            style={{
                                width: "90px",
                                height: "40px",
                                marginBottom: "8px",
                            }}
                        >
                            Guardar
                        </GenBtn>
                    </div>
                </div>
            </FormDialogModal>
        </React.Fragment>
    );
}

export default CombustibleList;
{
    /* <Triangle
height = "80"
width = "80"
radius = "9"
color = '#bc0535'
type = 'Triangle'     
wrapperStyle
wrapperClass
/> */
}
