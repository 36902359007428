import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import NavbarModulo from '../../components/NavbarModulo';
import TablaDetalle from './DetalleAbonos';

import {
    renderField,
    renderDayPickerB,
    SelectField,
    renderCurrencyFloat,
    AsyncSelectFieldIn,
} from "../../Utils/renderField/renderField";

function BuscarDocumentoForm(props) {
    const {
        doc,
        listarDocumentos,
        leerDocumento,
        moneda,
        detalle,
        empresaNom
    } = props;

    let titulo = 'Consulta de documentos';
    const [tipoD, setTipoD] = useState('');
    
    const monedas = [
        { label: "Quetzales", value: "Q" },
        { label: "Quetzales", value: "D" },
    ];

    const tipo = [
        { label: "FACTURA", value: "FAC" },
        { label: "COMPRA", value: "CMP" },
        { label: "NOTA DE COBRO", value: "NTC" },
    ];
    
    const handleChangeTipo = (e) => {
        setTipoD(e.target.value);
        listarDocumentos(e.target.value);
    };
    
    const handleOnChange = value => {
        leerDocumento(value.value, tipoD);
    };

    const estilo = {
        overflow: "auto",
        height: "200px",

    };
    console.log(empresaNom);
    return (
        <div>
            <NavbarModulo
                modulo = {''}
                titulo = {titulo}
            />
            <br/>
            <div className="mb-4 card card-small text-white bg-dark mb-3">
                <div className="border-bottom card-header"><h6 className="m-0">{empresaNom}</h6></div>
                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                    <div className="d-flex flex-column flex-1 mx-4">
                        <div className='row mb-2'>
                            <div className='col-3'>
                                <div className="form-floating">
                                    <select className="form-select" id="tipo" name="tipo"
                                        value={tipoD} onChange={handleChangeTipo}>
                                        <option selected>Seleccionar...</option>
                                        {tipo.map(
                                            tip => <option key={tip.value} value={tip.value}
                                            >{tip.label}
                                            </option>)
                                        }
                                    </select>
                                    <label className='form-label' htmlFor="mes">Tipo</label>
                                </div>
                            </div>
                            <div className='col-6'>
                                <Field
                                    name="document"
                                    placeholder="Documento..."
                                    className="form-control-find"
                                    loadOptions={doc}
                                    component={AsyncSelectFieldIn}
                                    onChange={handleOnChange}
                                    msj="Documento"
                                />
                            </div>
                        </div>
                        <div className='row mt-1'>
                            <div className='col-3'>
                                <Field
                                    disabled={true}
                                    name="fecha"
                                    component={renderDayPickerB}
                                    msj="Fecha factura"
                                />
                            </div>
                            <div className='col-3'>
                                <Field
                                    name="serie"
                                    placeholder="Serie"
                                    className="form-control"
                                    component={renderField}
                                    disabled={true}
                                    msj="Serie"
                                />
                            </div>
                            <div className='col-3'>
                                <Field
                                    name="numero"
                                    placeholder="Numero"
                                    className="form-control"
                                    component={renderField}
                                    disabled={true}
                                    msj="Numero"
                                />
                            </div>
                            <div className='col-3'>
                                <Field
                                    name="monto"
                                    placeholder="Monto"
                                    className="form-control"
                                    component={renderCurrencyFloat}
                                    disabled={true}
                                    msj="Monto"
                                />
                            </div>
                        </div>
                        <div className='row mt-1'>
                            <div className='col-12'>
                                <Field
                                    name="nombre"
                                    placeholder="Proveedor"
                                    className="form-control"
                                    component={renderField}
                                    disabled={true}
                                    msj="Cliente/Proveedor"
                                />
                            </div>
                        </div>
                        <div className='row mt-1'>
                            <div className='col-3'>
                                <div className="form-floating">
                                <Field
                                    name="moneda"
                                    options={monedas}
                                    dato={moneda}
                                    component={SelectField}
                                    className="form-select"
                                    disabled={true}
                                    msj="Moneda"
                                />
                                </div>
                            </div>
                            <div className='col-3'>
                            </div>
                            <div className='col-3'>
                                <Field
                                    name="totalAbono"
                                    placeholder="Abonos"
                                    className="form-control"
                                    component={renderCurrencyFloat}
                                    disabled={true}
                                    msj="Abonos"
                                />
                            </div>
                            <div className='col-3'>
                                <Field
                                    name="saldo"
                                    placeholder="Saldo"
                                    className="form-control"
                                    component={renderCurrencyFloat}
                                    disabled={true}
                                    msj="Saldo"
                                />
                            </div>
                        </div>
                        <div className='row mt-1'>
                            <div className='col-12'>
                                <Field
                                    id="descripcion"
                                    name="descripcion"
                                    placeholder="Descripcion"
                                    component={renderField}
                                    className="form-control"
                                    disabled={true}
                                    msj="Descripción"
                                />
                            </div>
                        </div>
                        <br/>
                        <div>
                            <div style={estilo}>
                                <TablaDetalle
                                    detalle={detalle}
                                />
                            </div>
                        </div>
                        <div className='d-flex flex-row mt-3'>
                            <a
                                href={`/#/tools/home/seleccionar_empresa/`}
                                className='btn btn-secondary mb-3'
                            >
                                Regresar
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

BuscarDocumentoForm.propTypes = {
    handleSubmit: PropTypes.func,
    crear: PropTypes.bool,
    lectura: PropTypes.object,
    tipos: PropTypes.object,
    datos: PropTypes.object,
};

export default reduxForm({
    form: 'FormBuscarDocumento',
})(BuscarDocumentoForm);
