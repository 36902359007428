import React, { Component } from 'react';
import Grid from '../../Utils/GridPoliza';
import { standardActions } from '../../Utils/GridPoliza/StandardActions';

class TablaPolizaCentroCosto extends Component{
    
    render(){
        const { loader, eliminarLinea,  detallePoliza } = this.props;
        return(
            <React.Fragment>
                {detallePoliza &&
                    <Grid 
                    hover 
                    striped 
                    data={detallePoliza} 
                    loading={loader} 
                    footer
                    headerStyle={ { background: 'black' } } 
                    //onSortChange={onSortChange} 
                >
                    <TableHeaderColumn
                        isKey
                        dataField="cuenta"
                        dataFormat={(cell, row)=>{
                            if ( cell === undefined ){
                                return "";
                            }else{
                                return cell.cuenta+' - '+cell.nombre;
                            }
                            
                        }}
                       
                        width="500"
                        dataSort
                        thStyle={ 
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Cuenta
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataAlign='center'
                        dataField="cc"
                        dataFormat={(cell, row)=>{
                            if ( cell === undefined || cell === null ){
                                return "";
                            }else{
                                return cell.codigo;
                            }
                            
                        }}
                        width="150"
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Centro Costo
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataAlign='right'
                        headerAlign="center"
                        dataField="debe"
                        width="150"
                        dataFormat={(cell, row)=>{
                            if (parseFloat(cell) === 0.00){
                                return ''
                            }else{
                                return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(cell);
                            }
                        }}
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Debe
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataAlign='right'
                        headerAlign="center"
                        dataField="haber"
                        dataFormat={(cell, row)=>{
                            if (parseFloat(cell) === 0.00){
                                return ''
                            }else{
                                return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(cell);
                            }
                        }}
                        width="150"
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Haber
                    </TableHeaderColumn>
                    
                    <TableHeaderColumn
                        thStyle={
                        {color: 'white'}}
                        dataField="id"
                        width="150"
                        dataAlign="center"
                        dataSort
                        dataFormat={standardActions({
                            eliminar: eliminarLinea })}
                        
                    >
                        Acciones
                    </TableHeaderColumn>
                </Grid>
                }
            </React.Fragment>
        );
    }
}

export default TablaPolizaCentroCosto;






