import React from 'react';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import * as BsIcons from "react-icons/bs";

export const SidebarData = [
    {
        title: 'Inicio',
        path: '/registro_sanitario',
        icon: <BsIcons.BsGrid1X2Fill />,
    },
    {
        title: 'Catalogos',
        path: '#',
        icon: <FaIcons.FaBook />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav: [
            {
                title: 'Linea',
                path: '/registro_sanitario',
                path_s: 'linea',
                icon: <BsIcons.BsFillGridFill />,
            },
            {
                title: 'Familia',
                path: '/registro_sanitario',
                path_s: 'familia',
                icon: <BsIcons.BsFillGridFill />,
            },
            {
                title: 'Forma farmacéutica',
                path: '/registro_sanitario',
                path_s: 'forma',
                icon: <BsIcons.BsFillGridFill />,
            },
            {
                title: 'Principio activo',
                path: '/registro_sanitario',
                path_s: 'principio_activo',
                icon: <BsIcons.BsFillGridFill />,
            },
            {
                title: 'Concentración',
                path: '/registro_sanitario',
                path_s: 'concentracion',
                icon: <BsIcons.BsFillGridFill />,
            },
            {
                title: 'Estatus',
                path: '/registro_sanitario',
                path_s: 'estatus',
                icon: <BsIcons.BsCheckCircleFill />,
            },
            {
                title: 'Grupo Terapéutico',
                path: '/registro_sanitario',
                path_s: 'grupo_terapeutico',
                icon: <BsIcons.BsArchiveFill />,
            },
            {
                title: 'ELZM',
                path: '/registro_sanitario',
                path_s: 'elzm',
                icon: <BsIcons.BsFillGridFill />,
            },
            {
                title: 'Pais',
                path: '/registro_sanitario',
                path_s: 'pais',
                icon: <FaIcons.FaAtlas />,
            },
        ]
    },
    {
        title: 'Productos',
        path: '/registro_sanitario/producto',
        icon: <FaIcons.FaDollyFlatbed />,
    },
    {
        title: 'Registro sanitario',
        path: '#',
        icon: <FaIcons.FaBook />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav: [
            {
                title: 'Registro sanitario',
                path: '/registro_sanitario',
                path_s: 'seleccionar_registro',
                icon: <FaIcons.FaDiceD6 />,
            },
            {
                title: 'Registro coempaque',
                path: '/registro_sanitario',
                path_s: 'seleccionar_registro_coempaque',
                icon: <FaIcons.FaDiceD6 />,
            },
            {
                title: 'Tipo de registro',
                path: '/registro_sanitario',
                path_s: 'tipo_registro',
                icon: <BsIcons.BsFillGridFill />,
            },
            {
                title: 'Fabricante',
                path: '/registro_sanitario',
                path_s: 'fabricante',
                icon: <FaIcons.FaBuilding />,
            },
            {
                title: 'Acondicionador',
                path: '/registro_sanitario',
                path_s: 'acondicionador',
                icon: <FaIcons.FaBuilding />,
            },
            {
                title: 'Distribuidor',
                path: '/registro_sanitario',
                path_s: 'comercializador',
                icon: <FaIcons.FaBuilding />,
            },
            {
                title: 'Clasificación',
                path: '/registro_sanitario',
                path_s: 'clasificacion',
                icon: <BsIcons.BsFillGridFill />,
            },
            {
                title: 'Licencia',
                path: '/registro_sanitario',
                path_s: 'licencia',
                icon: <BsIcons.BsFillGridFill />,
            },
            {
                title: 'Via administración',
                path: '/registro_sanitario',
                path_s: 'via_administracion',
                icon: <BsIcons.BsHourglassSplit />,
            },
        ]
    },
    {
        title: 'Informes',
        path: '/registro_sanitario/informes',
        icon: <FaIcons.FaClipboard />,
    }
]
