import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import {
    FicFileDrop,
    FicComboBox,
    FicInput,
} from "../../../Utils/FormInputComponents";
import GenBtn from "../../../components/GenBtn";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormDialogModal from "../../../components/FormDialogModal";
import * as XLSX from "sheetjs-style";
import { Tabs, TabList, Tab, TabPanel } from "react-aria-components";
import DataTableWSearch from "../../../components/DataTableWSearch";

const optionesNaturaleza = [
    { value: "PASIVO", label: "Pasivo" },
    { value: "ACTIVO", label: "Activo" },
    { value: "CAPITAL", label: "Capital" },
    { value: "INGRESOS", label: "Ingresos" },
    { value: "COSTOS", label: "Costos" },
    { value: "GASTOS", label: "Gastos" },
];

const opcionesTipo = [
    { value: "ACUMULATIVA", label: "Acumulativa" },
    { value: "DETALLE", label: "Detalle" },
];

const FormContainer = styled.form`
    border-top: 1px dashed #bd0035;
    display: flex;
    margin-top: 1%;
    margin-bottom: 1%;
    gap: 1%;
    align-items: center;
`;

const DialogFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    margin: 1.5rem;
`;

const schema = yup.object({
    nomenclatura: yup.mixed().required("La nomenclatura es requerida"),
});

const modalSchema = yup.object({
    cuenta: yup.string().required("La cuenta es requerida"),
    descripcion: yup.string().required("La descripcion es requerida"),
    naturaleza: yup.mixed().required("La naturaleza es requerida"),
    tipo: yup.mixed().required("El tipo es requerido"),
});

function NomenclaturaHome(props) {
    const {
        guardarNomenclatura,
        subirArchivoNomenclatura,
        listarContribuyentes,
    } = props;
    const [tableData, setTableData] = useState([]);
    const [fileExists, setFileExists] = useState(false);
    const [data, setData] = useState([]);

    const TableContainer = styled.div`
        width: fit-content;
        overflow: scroll;
        height: 75vh;
        border: ${fileExists ? "2px solid #dd0000" : ""};
        resize: vertical;

        & ::-webkit-resizer {
            display: ${fileExists ? "none" : ""};
            color: #00ff00;
        }
    `;

    const dialogRef = useRef(null);

    const defaultValues = {
        cuenta: "",
        descripcion: "",
        naturaleza: null,
        tipo: null,
    };

    const columns = [
        {
            name: "Cuenta",
            selector: (row) => row.cuenta,
            sortable: true,
        },
        {
            name: "Descripcion",
            selector: (row) => row.descripcion,
            sortable: true,
        },
        {
            name: "Naturaleza",
            selector: (row) => row.naturaleza,
            sortable: true,
        },
    ];

    const { handleSubmit, control, reset, watch } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: {
            nomenclatura: null,
        },
    });

    const {
        handleSubmit: modalHandleSubmit,
        control: modalControl,
        reset: modalReset,
    } = useForm({
        mode: "onChange",
        resolver: yupResolver(modalSchema),
        defaultValues: defaultValues,
    });

    const onSubmit = async (data) => {
        const shouldReset = await subirArchivoNomenclatura(data);
        if (shouldReset) {
            reset();
            setTableData([]);
            setFileExists(false);
            fetchData();
        }
    };

    const modalOnSubmit = (data) => {
        guardarNomenclatura(data);
        modalReset(defaultValues);
    };

    async function fetchData() {
        const fetchedData = await listarContribuyentes();
        setData(fetchedData);
    }

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onload = (event) => {
            const data = event.target.result;
            const workbook = XLSX.read(data, { type: "binary" });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(sheet);

            setTableData(jsonData);
        };

        reader.readAsBinaryString(file);
        setFileExists(true);
    };

    let file = watch("nomenclatura");

    useEffect(() => {
        file ? setFileExists(true) : setFileExists(false);
    }, [file]);

    useEffect(() => {
        fetchData();
    }, []);
    
    return (
        <React.Fragment>
            {/* <FicFileDrop nonForm type="file" /> */}
            <h4 style={{ margin: "2%" }}>Nomenclatura</h4>

            <Tabs>
                <TabList aria-label="History of Ancient Rome">
                    <Tab id="SubirNom">Agregar</Tab>
                    <Tab id="ListarNom">Listado</Tab>
                </TabList>
                <TabPanel id="SubirNom">
                    <GenBtn
                        onPress={() => dialogRef.current.showModal()}
                        style={{ margin: "0.5rem" }}
                    >
                        Agregar cuenta
                    </GenBtn>
                    <FormContainer
                        encType="multipart/form-data"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <FicFileDrop
                            control={control}
                            name="nomenclatura"
                            type="file"
                            style={{ height: "39px", margin: "0.5rem" }}
                            labelText="Subir nomenclatura"
                            accept=".xlsx, .xls, .csv"
                            onChange={handleFileUpload}
                        />
                        {fileExists && <GenBtn type="submit">Guardar</GenBtn>}
                    </FormContainer>
                    <TableContainer>
                        <table style={{ padding: "2%" }}>
                            <thead
                                style={{
                                    backgroundColor: "#2e2e2e",
                                    color: "#fff",
                                }}
                            >
                                <tr>
                                    {tableData.length > 0 &&
                                        Object.keys(tableData[4]).map(
                                            (header, index) => (
                                                <th key={index}>{header}</th>
                                            )
                                        )}
                                </tr>
                            </thead>
                            <tbody
                                style={{
                                    backgroundColor: "#1e1e1e",
                                    color: "#fff",
                                }}
                            >
                                {tableData.map((row, rowIndex) => (
                                    <tr key={rowIndex}>
                                        {Object.values(row).map(
                                            (cell, cellIndex) => (
                                                <td
                                                    key={cellIndex}
                                                    style={{
                                                        border: "1px solid #bd0034",
                                                        padding: "0rem 1rem",
                                                    }}
                                                >
                                                    {cell.toLocaleString()}
                                                </td>
                                            )
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </TableContainer>
                </TabPanel>
                <TabPanel id="ListarNom">
                    <DataTableWSearch
                        title="Listado de cuentas"
                        data={data}
                        columns={columns}
                        filterPlaceHolder="Buscar"
                    />
                </TabPanel>
            </Tabs>

            <FormDialogModal
                onSubmit={modalHandleSubmit(modalOnSubmit)}
                dialogRef={dialogRef}
                title="Agregar cuenta"
                style={{
                    minWidth: "16rem",
                    width: "23rem",
                    overflow: "visible",
                }}
            >
                <DialogFormContainer>
                    <FicInput
                        name="cuenta"
                        label="Cuenta"
                        control={modalControl}
                    />
                    <FicInput
                        name="descripcion"
                        label="Descripcion"
                        control={modalControl}
                    />
                    <FicComboBox
                        nonAsync
                        name="naturaleza"
                        label="Naturaleza"
                        control={modalControl}
                        options={optionesNaturaleza}
                    />
                    <FicComboBox
                        nonAsync
                        name="tipo"
                        label="Tipo"
                        control={modalControl}
                        options={opcionesTipo}
                    />
                    <GenBtn type="submit">Agregar</GenBtn>
                </DialogFormContainer>
            </FormDialogModal>
        </React.Fragment>
    );
}

export default NomenclaturaHome;
