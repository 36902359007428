import React, { Component } from 'react';
import Formulario from './SubirCompraForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";

class SubirCompra extends Component{
    state={
        archivo: null
    }
    
    componentDidMount = () => {
        const { getModulo, leerPeriodoD } = this.props;
        let ruta = window.location.href;
        let cuenta = ruta.split('/');
        leerPeriodoD(cuenta[5], cuenta[7]);
        getModulo(cuenta[5], cuenta[4]);
    }

    setArchivo = (archivo) => {
        this.setState({archivo});
    };
    
    subirCompras = (data) => {
        const { subirCompras } = this.props;
        subirCompras( {...data, archivo: null},
        [{ "file": this.state.archivo, "name": 'archivo' },])
    }

    render(){
        const { loader, listarEmpresas, modulo, periodoD } = this.props;
    
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    onSubmit = {this.subirCompras}
                    listarEmpresas = {listarEmpresas}
                    setArchivo = {this.setArchivo} 
                    modulo = {modulo}
                    periodoD = {periodoD}
                />
            </LoadMask>
        );
    }
}

export default SubirCompra;

