import React from "react";
import {
    ComboBox,
    FieldError,
    Text,
    ValidationResult,
    Button,
    Input,
    Label,
    ListBox,
    ListBoxItem,
    Popover,
} from "react-aria-components";
import styled from "styled-components";

const StyledComboBox = styled(ComboBox)`
    --border-color: #505050;
    --overlay-background: #1e1e1e;
    --highlight-background: #929292;
    --highlight-foreground: white;
    --text-color-disabled: #5f4b4b;
    --highlight-hover: #993232;
    --highlight-pressed: #4e1818;
    --selected-color: #929292;
    --selected-color-pressed: #929292;
    --checkmark-color: #00e639;

    --field-border: #505050;
    --field-background: #1e1e1e;
    --text-color: #e0e0e0;
    --text-color-placeholder: #afafaf;
    --text-color-invalid: rgb(218, 27, 27);
    --focus-background: #bd0034;
    --focus-foreground: white;
    --focus-background-invalid: rgb(218, 27, 27);
    --focus-ring-color: #bababa;

    color: var(--text-color);
    width: fit-content;

    .react-aria-Input {
        /* width: fit-content; */
        margin: 0;
        font-size: 1.072rem;
        background: var(--field-background);
        color: var(--field-text-color);
        border: 1px solid var(--border-color);
        border-radius: 6px;
        padding: 0.286rem 2rem 0.286rem 0.571rem;
        vertical-align: middle;
        transition: outline 50ms ease;

        &[data-focused] {
            outline: none;
            outline: 2px solid var(--focus-ring-color);
            outline-offset: -1px;
        }
    }

    .react-aria-Button {
        background: hsl(343, 100%, 37%, 0.2);
        color: #bd0034;
        border-radius: 4px;
        appearance: none;
        margin-left: -1.929rem;
        width: 1.429rem;
        height: 1.429rem;
        padding: 0;
        vertical-align: middle;
        font-size: 0.857rem;
        outline: none;
        box-sizing: content-box;
        /* border: 2px solid #bd0034; */
        border: 1px solid #bd0034;

        &[data-focus-visible] {
            box-shadow: 0 0 0 2px #bd0034;
        }
    }
`;
const CustomPopover = styled(Popover)`
    background-color: #121212;
    --text-color: #e0e0e0;
    width: var(--trigger-width);

    .cb-popover-listbox {
        display: block;
        width: unset;
        max-height: 259px;
        min-height: unset;
        border: none;
        overflow-y: scroll;

        .react-aria-Header {
            padding-left: 1.571rem;
        }
    }

    .cb-popover-listbox-item {
        --highlight-background: #555555;

        padding: 0.286rem 0.571rem 0.286rem 1.571rem;
        background-color: #121212;
        color: var(--text-color);

        &[data-focus-visible] {
            outline: none;
        }

        &[data-selected] {
            font-weight: 600;
            background: unset;
            background-color: #bd003640;
            border: 1px solid #bd0034;

            &::before {
                color: #bd0034;
                content: "✓";
                content: "✓" / "";
                alt: " ";
                padding-right: 3px;
                /* position: absolute;
                top: 4px;
                left: 4px; */
            }
        }

        &[data-focused],
        &[data-pressed] {
            background: var(--highlight-background);
            color: #121212;
        }
    }
`;

function MyComboBox({ label, description, errorMessage, children, ...props }) {
    return (
        <StyledComboBox className="tstCB" {...props}>
            <Label style={{ display: "none" }}>{label}</Label>
            <div className="my-combobox-container">
                <Input />
                <Button>▼</Button>
            </div>
            {/* {description && <Text slot="description">{description}</Text>} */}
            {/* <FieldError>{errorMessage}</FieldError> */}
            <CustomPopover className="cb-popover">
                <ListBox className="cb-popover-listbox">{children}</ListBox>
            </CustomPopover>
        </StyledComboBox>
    );
}

function MyItem(props) {
    return (
        <ListBoxItem
            {...props}
            className={({ isFocused, isSelected }) =>
                `my-item ${isFocused ? "focused" : ""} ${
                    isSelected ? "selected" : ""
                }`
            }
        />
    );
}

export default function TestComboBox({ list, options, ...props }) {
    return (
        <MyComboBox
            items={options.items}
            onInputChange={options.setFilterText}
            // items={options}
            // inputValue={list.filterText}
            // onInputChange={list.setFilterText}
            menuTrigger="input"
            {...props}
        >
            {(item) => (
                <ListBoxItem className="cb-popover-listbox-item" id={item.id}>
                    {item.label}
                </ListBoxItem>
            )}
        </MyComboBox>
    );
}
