import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
const valEmp = __CONFIG__.empresa;

const LISTADO_ACTIVO = 'LISTADO_ACTIVO';
const LOADER = 'LOADER';
const MODULO = 'MODULO';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params;
    let id = ''
    if (valEmp === 'fage'){
        id = id_emp[6];
        params = { id };
    }else{
        id = id_emp[5];
        params = { id };
    }
    dispatch(setLoader(true));
    api.get('/registro_marca/principio_activo', params).then((response)=>{
        dispatch({ type: LISTADO_ACTIVO, data: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar principio activo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const registroActivo = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormPrincipioActivoRM.values;
    let id_empresa = '';
    if (valEmp === 'fage'){
        id_empresa = id_emp[6]
    }else{
        id_empresa = id_emp[5]
    }
    const formData = {
        descripcion: data.descripcion,
        empresa: id_empresa
    }
    dispatch(setLoader(true));
    api.post('/registro_marca/principio_activo/', formData).then(() => {
        NotificationManager.success(
        'Principio activo creado',
        'Exito',
        3000
    );
    let ruta = '';
    if (valEmp === 'fage'){
        ruta = `/lfg/registro_marca/${id_emp[6]}/principio_activo`
        dispatch(push(ruta));
    }else{
        ruta = `/registro_marca/${id_emp[5]}/principio_activo`
        dispatch(push(ruta));
    }
    }).catch((error) => {
    NotificationManager.error(
        error.msj,
        'Error',
        2000
    );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const modificarActivo = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const datos = getStore().form.FormPrincipioActivoRM.values;
    const id = datos.id;
    const formData = {
        id: id,
        descripcion: datos.descripcion,
    }
    dispatch(setLoader(true));
    api.put(`/registro_marca/principio_activo/${id}/`, formData).then(() => {
        NotificationManager.success(
            'Principio activo modificado correctamente',
            'Exito',
            3000
        );
        let ruta = '';
        if (valEmp === 'fage'){
            ruta = `/lfg/registro_marca/${id_emp[6]}/principio_activo`
            dispatch(push(ruta));
        }else{
            ruta = `/registro_marca/${id_emp[5]}/principio_activo`
            dispatch(push(ruta));
        }
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al modificar principio activo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/registro_marca/principio_activo/${id}`).then((response) => {
        dispatch(initializeForm('FormPrincipioActivoRM', response ));
    }).catch((error)=>{
        
        NotificationManager.error(
            'Ocurrió un error al consultar principio activo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const actions = {
    listar,
    registroActivo,
    modificarActivo,
    leer,
    getModulo
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_ACTIVO]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
};

export const initialState = {
    loader: false,
    modulo: [],
    data: []
};

export default handleActions(reducers, initialState)
 