import React from "react";
import styled from "styled-components";
import { FicInput } from "../../Utils/FormInputComponents";
import Banner from "../common/Banner";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { validar } from "../../components/ValidarNit";
import GenBtn from "../../components/GenBtn";
import InputsContainer from "../common/InputsContainer";
import {getCliente, editarCliente } from "./actions";
import { withRouter } from "react-router";

const schema = yup.object({
    nit: yup
        .string()
        .test("nit-validation", "El NIT no es valido", function (value) {
            return validar.valNit(value);
        })
        .required("El nit es requerido"),
    nombre: yup.string().required("El nombre es requerido"),
    celular: yup
        .number()
        .typeError("Debe de ser un numero (sin separadores)")
        .required("El celular es requerido"),
    direccion: yup.string().required("La direccion es requerida"),
    email: yup
        .string()
        .email("Ingrese un email valido")
        .required("El correo es requerido"),
    telefono: yup
        .number()
        .typeError("Debe de ser un numero (sin separadores)")
        .required("El telefono es requerido"),
});


function Ver({ history, ...props }) {
    const params = props.match.params;
    const clienteId = params.clienteId;
    
    const { control } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: async () => {
            const formData = await getCliente(clienteId);
            // setLoadingState(false);
            return formData;
        },
    });


    return (
        <React.Fragment>
            <Banner>Editar ficha</Banner>
                <GenBtn style={{ margin: "1rem 0" }} onPress={() => history.goBack()}>
                    Regresar
                </GenBtn>
            <form onSubmit={() => {}}>
                <InputsContainer>
                    <FicInput
                        control={control}
                        label="Nit"
                        name="nit"
                        isDisabled
                    />
                    <FicInput
                        control={control}
                        label="Nombre"
                        name="nombre"
                        style={{gridColumn: "span 2"}}
                        isDisabled
                    />
                    <FicInput
                        control={control}
                        label="Direccion"
                        name="direccion"
                        style={{gridColumn: "span 2"}}
                        isDisabled
                    />
                    <FicInput
                        control={control}
                        label="Celular"
                        name="celular"
                        isDisabled
                    />
                    <FicInput
                        control={control}
                        label="E-mail"
                        name="email"
                        isDisabled
                    />
                    <FicInput
                        control={control}
                        label="Telefono"
                        name="telefono"
                        isDisabled
                    />
                    <FicInput
                        control={control}
                        label="Contacto"
                        name="contacto"
                        isDisabled
                    />
                    <FicInput
                        control={control}
                        label="Celular contacto"
                        name="celular_contacto"
                        isDisabled
                    />
                </InputsContainer>
            </form>
        </React.Fragment>
    );
}

export default withRouter(Ver);
