import React, { useState, useEffect } from 'react';
import {reduxForm } from 'redux-form';
import Tabs, { TabPane } from 'rc-tabs';
import PropTypes from 'prop-types';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import Documento from './FacturaForm';
import Detalle from './FacturaDetalleProyectoForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";
import styled from 'styled-components';
import DetalleMateriales from './FacturaDetalleProyectoList';

function AgregarProyectoFormTab(props) {
    const {
        fact, lectura, tipos,
        listarProveedores, loader, listarSubProyectos, listarMateriales,
        listaProyectos, listaSubProyectos, encabezado,
        prov, registroProveedor, listaFases, impuesto,
        detalleMaterial, registroDetalleMaterial, eliminarLineaProducto } = props;

    useEffect(() => {
        const { match, leerFactura, listarMateriales, listarTiposDocu,
            listarProyectos, listarFases, listarImpuestos } = props;
        const id = match.params.id;
        if (id) {
            listarTiposDocu();
            leerFactura(id);
            listarProyectos();
            listarFases();
            listarMateriales();
            listarImpuestos();
            localStorage.setItem('liquidacion_id',id);
            localStorage.removeItem('id_detalle_material');
        }
        
    }, [])
    const [crearC, setCrear] = useState(false);
    const [crearPro, setCrearPro] = useState(true);
 
    const editar = false;
    let titulo = 'Agregar proyecto';
    let disabled = false;
    if (crearC === false && editar === false) {
        disabled = true;
        titulo = 'Agregar proyecto'
    }
    localStorage.setItem('tituloForm', titulo);

    const [key, setKey] = useState('PRINCIPAL_TOP');
    
    const estilo = {
        overflow: "auto",
        height: "200px",

    };
    let ruta = window.location.href;
    let id_empresa = ruta.split('/');

    return (
        <React.Fragment>
            <div className="py-1">
                <div className="mb-1">
                    <br/>
                    <div className="p-0 px-3 pt-0">
                        <Tabs
                            activeKey={key}
                            onChange={(k) => setKey(k)}
                            tabBarPosition="top"
                            renderTabBar={() => <ScrollableInkTabBar />}
                            renderTabContent={() => <TabContent />}
                        >
                            <TabPane tab="Documento" key="PRINCIPAL_TOP">
                                <div className="py-2 px-3">
                                    <div className="mb-4 card card-small text-white bg-dark mb-1">
                                        <div className="p-0 pt-4 d-flex flex-column flex-md-row">
                                            <div className="d-flex flex-column flex-1 mx-4">
                                                <LoadMask loading={loader} dark>
                                                    <Documento
                                                        crear={crearC}
                                                        lectura={fact}
                                                        bloqueo={disabled}
                                                        editar={editar}
                                                        tipos={tipos}
                                                        listarProveedores={listarProveedores}
                                                        registroProveedor={registroProveedor}
                                                        listarProveedoresD={prov}
                                                    />
                                                </LoadMask>
                                                <br />
                                                {crearC === false && editar === false &&
                                                    <div className='d-flex flex-row mt-2'>
                                                        <a
                                                            href={`/#/compras/${id_empresa[5]}/buscar_factura`}
                                                            className='btn btn-secondary mb-3'
                                                        >
                                                            Regresar
                                                        </a>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPane>
                            <TabPane tab="Detalle proyecto" key="SEGUNDO_TOP">
                                <div className="py-2 px-3">
                                    <div className="mb-4 card card-small bg-dark mb-3">
                                        <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                                            <div className="d-flex flex-column flex-1 mx-3">
                                                <LoadMask loading={loader} dark>
                                                    <Detalle
                                                        lectura={lectura}
                                                        bloqueo={disabled}
                                                        tipos={tipos}
                                                        listarMateriales={listarMateriales}
                                                        obtenerSubProyectos={listaSubProyectos}
                                                        obtenerFases={listaFases}
                                                        listarSuProyectos={listarSubProyectos}
                                                        obtenerProyectos={listaProyectos}
                                                        listarProveedores={listarProveedores}
                                                        registroProveedor={registroProveedor}
                                                        listarProveedoresD={prov}
                                                        encabezado={encabezado}
                                                        detalleMaterial={detalleMaterial}
                                                    />
                                                    {(crearPro === true || editar === true) &&
                                                        <Contenido>
                                                            <button
                                                                className='btn mr-2 mb-3 btn-primary'
                                                                onClick={() =>registroDetalleMaterial(impuesto)}
                                                            >
                                                                Agregar linea
                                                            </button>
                                                        </Contenido>
                                                    }
                                                    {(crearPro === true || editar === true) &&
                                                        <div>
                                                            <h5 className="p-2 bg-secondary title mt-1">Detalle de materiales</h5>
                                                            <div style={estilo}>
                                                                <DetalleMateriales
                                                                    detalle={detalleMaterial}
                                                                    eliminarLineaProducto={eliminarLineaProducto}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                    <br/>
                                                    <br/>
                                                </LoadMask>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )

}
AgregarProyectoFormTab.propTypes = {
    handleSubmit: PropTypes.func,
    crear: PropTypes.bool,
    lectura: PropTypes.object,
    tipos: PropTypes.array,
    listarProveedores: PropTypes.func,
    loader: PropTypes.bool,
    detalle: PropTypes.array,
    eliminarLinea: PropTypes.func,
    listarCuentas: PropTypes.func,
    listarCC: PropTypes.func,
    nivel: PropTypes.object,
    registroCuenta: PropTypes.func,
    reporteCuentas: PropTypes.func,
    loaderC: PropTypes.bool,
    prov: PropTypes.array,
    listarProveedoresD: PropTypes.func,
    registroProveedor: PropTypes.func,
    listaProyectos: PropTypes.array,
    listarSubProyectos: PropTypes.func,
    listaSubProyectos: PropTypes.array,
    listarFases: PropTypes.func,
    listaFases: PropTypes.array,
    listarMateriales: PropTypes.func,
    leerDetalleMateriales: PropTypes.func,
    registroDetalleMaterial: PropTypes.func,
    eliminarLineaProducto: PropTypes.func,
};

export default reduxForm({
    form: 'AgregarFormTabs', //identificador unico
})(AgregarProyectoFormTab)

const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;