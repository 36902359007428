import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import { validate, validators } from 'validate-redux-form';
import PropTypes from 'prop-types';
import NavbarModulo from '../../components/NavbarModulo';

import {
  renderField,
} from "../../Utils/renderField/renderField";

function UnidadMedidaForm(props) {
  const { handleSubmit, crear, modulo } = props;
  const editar = window.location.href.includes('editar');
  let titulo = editar ? 'Modificar unidad de medida' : 'Crear unidad de medida';
  let disabled = false;
  if (crear === false && editar === false) {
    disabled = true;
    titulo = 'Ver unidad de medida'
  }
  let autoFocus = true;
  let ruta = window.location.href;
  let id_emp = ruta.split('/');
  return (
    <div className='container'>
      <NavbarModulo
        modulo={modulo}
        titulo={titulo}
      />
      <br />
      <div className="mb-4 card card-small text-white bg-dark mb-3">
        <div className="p-0 pt-3 d-flex flex-column flex-md-row">
          <div className="d-flex flex-column flex-1 mx-4">
            <div className='row mb-2'>
                <div className='mb-2 col-md-4'>
                    <Field
                    autoFocus={autoFocus}
                    name="unidad_medida"
                    placeholder="Unidad de Medida"
                    className="form-control"
                    component={renderField}
                    disabled={editar ? true : disabled}
                    msj="Unidad de medida"
                    />
                </div>
                <div className='mb-2 col-md-8'>
                    <Field
                        name="descripcion"
                        placeholder="Descripción"
                        className="form-control"
                        component={renderField}
                        disabled={disabled}
                        msj="Descripción"
                    />
                </div>
            </div>            
            <div className='d-flex flex-row mt-3'>
              {disabled == false &&
                <button
                  className='btn mr-2 mb-3 btn-primary'
                  onClick={handleSubmit}
                >
                  {editar ? 'Modificar' : 'Guardar'}
                </button>
              }
              <a
                href={`/#/punto_venta/${id_emp[5]}/unidad_medida`}
                className='btn btn-secondary mb-3'
              >
                Regresar
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

UnidadMedidaForm.propTypes = {
  handleSubmit: PropTypes.func,
  crear: PropTypes.bool,
  lectura: PropTypes.object,
  modulo: PropTypes.object,
};

export default reduxForm({
  form: 'FormUnidad',
  validate: (data) => {
    return validate(data, {
      unidad_medida: validators.exists()('Este campo es requerido'),
      descripcion: validators.exists()('Este campo es requerido'),
    });
  },
})(UnidadMedidaForm);
