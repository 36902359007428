import * as yup from "yup";
import { today, getLocalTimeZone } from "@internationalized/date";

export function getSchema() {
    const schema = yup.object({
        tipo: yup.mixed().required("El tipo es requerido"),
        // paciente: yup.mixed().required("El paciente es requerido"),
        paciente: yup.string().required(),
        descripcion_macroscopica: yup
            .string()
            .required("La descripcion macroscopica es requerida"),
        // edad: yup
        //     .number()
        //     .typeError("Debe de ser un numero")
        //     .positive("Debe de ser un numero positivo")
        //     .required("La edad es requerida"),
        clinica: yup.mixed().required("La clinica es requerida"),
        medico: yup.string().required("El medico es requerido"),
        fecha_recibido: yup.mixed().required("La fecha recibido es requerida"),
        // costo: yup
        //     .number()
        //     .typeError("Debe de ser un numero")
        //     .positive("Debe de ser un numero positivo")
        //     .required("El costo es requerido"),
        especimen: yup.string().required("El especimen es requerido"),
        numero_patologia: yup
            .string()
            .required("El numero de patologia es requerido")
            .length(6, "El numero de patologia debe tener 6 caracteres"),
    });
    return schema;
}

export function getDefaultValues() {
    return {
        numero_patologia: "",
        tipo: null,
        paciente: "",
        edad: null,
        clinica: null,
        medico: "",
        fecha_recibido: today(getLocalTimeZone()).toString(),
        costo: 0,
        especimen: "",
    };
}
