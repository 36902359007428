import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/registro_sanitario/elzm';
import ELZM from './Elzm';


const ms2p = (state) => {
  return {
    ...state.rs_elzm,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ELZM);