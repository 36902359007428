import { handleActions } from "redux-actions";
import { api } from "api";
import { NotificationManager } from "react-notifications";
// import listarVendedoresForComboBox from "./utils/listados";

export const listarVendedoresForComboBox = (search) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let id = id_emp[5];
    const params = { id, search };
    return api
        .get("auto_ventas/vendedores", params)
        .then((response) => {
            let listaVendedores = [];
            if (response) {
                response.forEach((item) => {
                    listaVendedores.push({
                        value: item.id,
                        label: item.nombre,
                    });
                });
            }
            return listaVendedores;
        })
        .catch((error) => {
            return [];
        });
};

export const listadoDeIngresos = (search) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let id = id_emp[5];
    const params = { id, search };
    return api
        .get(`auto_ventas/ingresos/listado_resumido`, params)
        .then((response) => {
            let autoList = [];
            response.forEach((item) => {
                autoList.push({
                    value: item.auto.value,
                    label: item.auto.label,
                });
            });
            return autoList;
        })
        .catch((error) => {
            NotificationManager.error(
                "Ocurrio un error al obtener los datos",
                "Error",
                2000,
                null,
                true
            );
            return [];
        });
};

export const reporteListadoDeVendedores = (data) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let id = id_emp[5];
    data.estado = data.estado.value;
    let rep = "listado_de_vendedores";
    const params = { id, rep, ...data };
    api.getPdf("/auto_ventas/vendedores/reportes", params)
        .then((response) => {
            if (response.headers["content-type"] == "application/pdf") {
                var blob = new Blob([response.text], {
                    type: "application/pdf;charset=utf-8",
                });
                var link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = "listado_de_vendedores.pdf";
                window.open(link, "_blank");
                return false;
            }
        })
        .catch((error) => {
            if (error.status === 404) {
                NotificationManager.info(error.body.msj, "Informacion", 5000);
            }
            if (error.status === 500) {
                NotificationManager.error(
                    "Ocurrió un error al generar reporte",
                    "Error",
                    2000
                );
            }
        });
};

export const reporteRecepcionDeVehiculos = (data) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let id = id_emp[5];
    let carroId = data.marca.value;
    let rep = "recepcion_de_vehiculos";
    const params = { id, rep, carroId };
    api.getPdf("/auto_ventas/ingresos/reportes", params)
        .then((response) => {
            if (response.headers["content-type"] == "application/pdf") {
                var blob = new Blob([response.text], {
                    type: "application/pdf;charset=utf-8",
                });
                var link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = "recepcion_de_vehiculos.pdf";
                window.open(link, "_blank");
                return false;
            }
        })
        .catch((error) => {
            if (error.status === 404) {
                NotificationManager.info(error.body.msj, "Informacion", 5000);
            }
            if (error.status === 500) {
                NotificationManager.error(
                    "Ocurrió un error al generar reporte",
                    "Error",
                    2000
                );
            }
        });
};

export const reporteListadoDeVehiculos = (data) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let id = id_emp[5];
    let estado = data.estado.value;
    let rep = "listado_vehiculos";
    const params = { id, rep, estado };
    api.getPdf("/auto_ventas/ingresos/reportes", params)
        .then((response) => {
            if (response.headers["content-type"] == "application/pdf") {
                var blob = new Blob([response.text], {
                    type: "application/pdf;charset=utf-8",
                });
                var link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = "listado_de_vehiculos.pdf";
                window.open(link, "_blank");
                return false;
            }
        })
        .catch((error) => {
            if (error.status === 404) {
                NotificationManager.info(error.body.msj, "Informacion", 5000);
            }
            if (error.status === 500) {
                NotificationManager.error(
                    "Ocurrió un error al generar reporte",
                    "Error",
                    2000
                );
            }
        });
};

export const reporteListadoDeVentas = (data) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let id = id_emp[5];
    if (data.vendedor) {
        data.vendedor = data.vendedor.value;
    } else {
        data.vendedor = "";
    }

    let rep = "listado_de_ventas";
    const params = { id, rep, ...data };
    api.getPdf("/auto_ventas/ventas/reportes", params)
        .then((response) => {
            if (response.headers["content-type"] == "application/pdf") {
                var blob = new Blob([response.text], {
                    type: "application/pdf;charset=utf-8",
                });
                var link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = "listado_de_ventas.pdf";
                window.open(link, "_blank");
                return false;
            }
        })
        .catch((error) => {
            if (error.status === 404) {
                NotificationManager.info(error.body.msj, "Informacion", 5000);
            }
            if (error.status === 500) {
                NotificationManager.error(
                    "Ocurrió un error al generar reporte",
                    "Error",
                    2000
                );
            }
        });
};

export const actions = {
    listadoDeIngresos,
    reporteListadoDeVehiculos,
    reporteRecepcionDeVehiculos,
    reporteListadoDeVentas,
    listarVendedoresForComboBox,
    reporteListadoDeVendedores,
};

export const reducers = {};

export const initialState = {};

export default handleActions(reducers, initialState);
