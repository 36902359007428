import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import NavbarModulo from '../../components/NavbarModulo';
import NavbarModuloLF from '../../components/NavbarModuloFage';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
const valEmp = __CONFIG__.empresa;

import {
    AsyncSelectFieldIn,
    renderCurrencyFloat,
    renderNumber,
    renderField,
    SelectField,
    renderDayPickerB
} from "../../Utils/renderField/renderField";

function FormPrestamo(props) {
    const { modulo, emp, registroPrestamo, tipo,
        datosColaborador, cargo, crear, modificarPrestamo } = props;

    const editar = window.location.href.includes('editar');
    let titulo = editar ? 'Modificar préstamo' : 'Crear préstamo';
    let disabled = false;
    if (crear === false && editar === false) {
        disabled = true;
        titulo = 'Ver préstamo'
    }
    
    let ruta = window.location.href;
    let id_emp = ruta.split('/');

    const handleOnChangeEmp = value => {
        datosColaborador(value.value);
    };
    
    const tipoD = [
        { label: "1RA. QUINCENA", value: "1Q" },
        { label: "2DA. QUINCENA", value: "2Q" },
        { label: "BONO", value: "BO" },
        { label: "AGUINALDO", value: "AG" },
        { label: "50% 1RA. Q. y 50% 2DA. Q.", value: "QQ" },
    ];

    const [total, setTotal] = useState(0.00);
    const [descuento, setDescuento] = useState(0.00);

    return (
        <div>
            {valEmp === 'fage' &&
                <NavbarModuloLF
                    modulo={modulo}
                    titulo={titulo}
                />
            }
            {valEmp === '' &&
                <NavbarModulo
                    modulo={modulo}
                    titulo={titulo}
                />
            }
           
            <br />
            <div className="mb-4 card card-small text-white bg-dark mb-3">
                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                    <div className="d-flex flex-column flex-1 mx-4">
                        <div className='mb-2'>
                            {(crear === false && editar === false) &&
                                <div className='row mb-2'>
                                    <div className='col-6'>
                                        <Field
                                            name="nombre"
                                            placeholder="Colaborador"
                                            className="form-control-find"
                                            disabled={true}
                                            component={renderField}
                                            msj="Colaborador"
                                        />
                                    </div>
                                    <div className='col-6'>
                                        <Field
                                            name="puestoNombre"
                                            placeholder="Puesto"
                                            className="form-control-find"
                                            disabled={true}
                                            component={renderField}
                                            msj="Puesto"
                                        />
                                    </div>
                                </div>
                            }
                            {crear === true &&
                                <div className='row mb-2'>
                                    <div className='col-6'>
                                        <Field
                                            name="colaborador"
                                            placeholder="Colaborador..."
                                            className="form-control-find"
                                            component={AsyncSelectFieldIn}
                                            loadOptions={emp}
                                            onChange={handleOnChangeEmp}
                                            msj="Colaborador"
                                        />
                                    </div>
                                    <div className='col-6'>
                                        <div className="form-floating">
                                            <input
                                                className = {classNames('form-control')}
                                                type="text"
                                                value = {cargo}
                                                disabled = {true}
                                            />
                                            <label className='form-label'>Puesto</label>
                                        </div>
                                    </div>
                                </div>
                            }
                            {editar === true &&
                                <div className='row mb-2'>
                                    <div className='col-6'>
                                        <Field
                                            name="nombre"
                                            placeholder="Colaborador"
                                            className="form-control-find"
                                            disabled={true}
                                            component={renderField}
                                            msj="Colaborador"
                                        />
                                    </div>
                                    <div className='col-6'>
                                        <Field
                                            name="puestoNombre"
                                            placeholder="Puesto"
                                            className="form-control-find"
                                            disabled={true}
                                            component={renderField}
                                            msj="Puesto"
                                        />
                                    </div>
                                </div>
                            }
                            {crear === false && editar === false &&
                                <div className='row mb-3'>
                                    <div className='col-3'>
                                        <Field
                                            name="fecha_inicio"
                                            placeholder="Fecha inicio"
                                            className="form-control-find"
                                            disabled={true}
                                            component={renderDayPickerB}
                                            msj="Fecha inicio"
                                        />
                                    </div>
                                    <div className='col-3'>
                                        <div className="form-floating">
                                            <Field
                                                name="tipo"
                                                disabled={disabled}
                                                options={tipoD}
                                                crear={crear}
                                                dato={crear === true ? 0 : tipo}
                                                component={SelectField}
                                                className="form-select"
                                                msj="Frecuencia de pago"
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                            {editar === true &&
                                <div className='row mb-3'>
                                    <div className='col-3'>
                                        <Field
                                            name="fecha_inicio"
                                            placeholder="Fecha inicio"
                                            className="form-control-find"
                                            component={renderDayPickerB}
                                            msj="Fecha inicio"
                                        />
                                    </div>
                                    <div className='col-3'>
                                        <div className="form-floating">
                                            <Field
                                                name="tipo"
                                                disabled={disabled}
                                                options={tipoD}
                                                crear={crear}
                                                dato={crear === true ? 0 : tipo}
                                                component={SelectField}
                                                className="form-select"
                                                msj="Frecuencia de pago"
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                            {crear === true &&
                                <div className='row mb-3'>
                                    <div className='col-3'>
                                        <Field
                                            name="fecha_inicio"
                                            placeholder="Fecha inicio"
                                            className="form-control-find"
                                            component={renderDayPickerB}
                                            msj="Fecha inicio"
                                        />
                                    </div>
                                    <div className='col-3'>
                                        <div className="form-floating">
                                            <Field
                                                name="tipo"
                                                disabled={disabled}
                                                options={tipoD}
                                                crear={crear}
                                                dato={crear === true ? 0 : tipo}
                                                component={SelectField}
                                                className="form-select"
                                                msj="Frecuencia de pago"
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                            {crear === true &&
                                <div className='row mb-3'>
                                    <div className='col-3'>
                                        <Field
                                            name="total"
                                            disabled={disabled}
                                            placeholder="Total"
                                            className="form-control"
                                            component={renderCurrencyFloat}
                                            onChange={(e, value) => {
                                                if (value) {
                                                    setTotal(value);
                                                }
                                            }}
                                            msj="Total"
                                        />
                                    </div>
                                    <div className='col-3'>
                                        <Field
                                            name="meses"
                                            disabled={disabled}
                                            placeholder="Cuotas"
                                            className="form-control"
                                            component={renderNumber}
                                            onChange={(e, value) => {
                                                if (value) {
                                                    if (parseInt(value) === 0) {
                                                        setDescuento(0.00);
                                                    }else{
                                                        let descuento = parseFloat(parseFloat(total) / parseFloat(value)).toFixed(2);
                                                        setDescuento(descuento);
                                                    }
                                                }
                                            }}
                                            msj="Cuotas"
                                        />
                                    </div>
                                    <div className='col-3'>
                                        <div className="form-floating">
                                            <NumberFormat
                                                className={classNames('form-control')}
                                                decimalScale={2}
                                                placeholder="Descuento"
                                                fixedDecimalScale
                                                thousandSeparator
                                                value={descuento}
                                                onValueChange={(values) => {
                                                    const {formattedValue, value} = values;
                                                    if (value) {
                                                        setDescuento(parseFloat(value));
                                                    }
                                                }}
                                                name="cuota"
                                            />
                                            <label className='form-label' htmlFor="base">
                                            Descuento
                                            </label>
                                        </div>
                                    </div>
                                    
                                </div>
                            }
                            {crear === false && editar === false &&
                                <div className='row mb-3'>
                                    <div className='col-3'>
                                        <Field
                                            name="total"
                                            disabled={disabled}
                                            placeholder="Total"
                                            className="form-control"
                                            component={renderCurrencyFloat}
                                            msj="Total"
                                        />
                                    </div>
                                    <div className='col-3'>
                                        <Field
                                            name="meses"
                                            disabled={disabled}
                                            placeholder="Cuotas"
                                            className="form-control"
                                            component={renderNumber}
                                            msj="Cuotas"
                                        />
                                    </div>
                                    <div className='col-3'>
                                        <Field
                                            name="cuota"
                                            disabled={disabled}
                                            placeholder="Descuento"
                                            className="form-control"
                                            component={renderCurrencyFloat}
                                            msj="Descuento"
                                        />
                                    </div>
                                </div>
                            }
                            {editar === true &&
                                <div className='row mb-3'>
                                    <div className='col-3'>
                                        <Field
                                            name="total"
                                            disabled={disabled}
                                            placeholder="Total"
                                            className="form-control"
                                            component={renderCurrencyFloat}
                                            msj="Total"
                                        />
                                    </div>
                                    <div className='col-3'>
                                        <Field
                                            name="meses"
                                            disabled={disabled}
                                            placeholder="Cuotas"
                                            className="form-control"
                                            component={renderNumber}
                                            msj="Cuotas"
                                        />
                                    </div>
                                    <div className='col-3'>
                                        <Field
                                            name="cuota"
                                            disabled={disabled}
                                            placeholder="Descuento"
                                            className="form-control"
                                            component={renderCurrencyFloat}
                                            msj="Descuento"
                                        />
                                    </div>
                                </div>
                            }
                            <div className='d-flex flex-row mt-3 ml-2'>
                                {crear === true &&
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={() => registroPrestamo(descuento)}
                                    >
                                        Guardar
                                    </button>
                                }
                                {editar === true &&
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={() => modificarPrestamo()}
                                    >
                                        Modificar
                                    </button>
                                }
                                
                                {valEmp === 'fage' &&
                                    <a
                                        href={`/#/lfg/rr_hh/${id_emp[6]}/prestamos`}
                                        className='btn btn-secondary mb-3'
                                    >
                                        Regresar
                                    </a>
                                }
                                {valEmp === '' &&
                                    <a
                                        href={`/#/rr_hh/${id_emp[5]}/prestamos`}
                                        className='btn btn-secondary mb-3'
                                    >
                                        Regresar
                                    </a>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

FormPrestamo.propTypes = {
    handleSubmit: PropTypes.func,
    crear: PropTypes.bool,
    lectura: PropTypes.object,
    modulo: PropTypes.object
};

export default reduxForm({
    form: 'FormPrestamo',
})(FormPrestamo);
