import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/nomina/cierre_mes';
import Cierre from './CierreMes';

const ms2p = (state) => {
  return {
    ...state.cierre_mes_nomina,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Cierre);