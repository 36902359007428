import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { renderField } from '../Utils/renderField';
import PropTypes from 'prop-types';
import { validar } from "../../components/components/ValidarPass";
import Lista from "../../components/components/ListaErrores";

const emailValidation = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
  'Dirección de correo electrónico no válido' : undefined

const estilo = {
    color: "white",
};
const estilo2 = {
    fontSize: "14px",
};

function UsuarioFormPass(props) {
    const { handleSubmit } = props;
    const [error, setError] = useState('');
    const divError = {
        color: 'red',
    };
    return (
        <form name="loginForm" className="form-validate mb-lg"  onSubmit={handleSubmit}>

            <div className='row mb-2'>
                <div className='col-6'>
                    <Field
                        disabled={true}
                        name="first_name"
                        component={renderField}
                        placeholder="Nombre"
                        type="text"
                        className="form-control"
                        msj="Nombre"
                    />
                </div>
                <div className='col-6'>
                    <Field
                        disabled={true}
                        name="last_name"
                        placeholder="Apellido"
                        component={renderField}
                        type="text"
                        className="form-control"
                        msj="Apellido"
                    />
                </div>

            </div>
            <div className="row mb-2">
                <div className='col-6'>
                    <Field
                        disabled={true}
                        name="username"
                        placeholder="Correo electrónico"
                        component={renderField}
                        type="email"
                        validate={emailValidation}
                        className="form-control"
                        msj="Correo electrónico"
                    />
                </div>
            </div>
            <div className='row mb-2'>
                <div className='col-6'>
                    <Field
                        name="password"
                        placeholder="Contraseña"
                        component={renderField}
                        type="password"
                        onChange={(e, value) => {
                            if (value) {
                                let valor = validar.validarPassword(value);
                                if (valor.length > 0) {
                                    setError(valor);
                                }
                                if (valor === true){
                                    setError('');
                                }
                            }
                        }}
                        className="form-control"
                        msj="Contraseña"
                    />
                </div>
                <div className='col-6'>
                    <Field
                        name="confirmPassword"
                        placeholder="Confirmar Contraseña"
                        component={renderField}
                        type="password"
                        className="form-control"
                        msj="Confirmar Contraseña"
                    />
                </div>
            </div>
            {error &&
                <div className='col-12'>
                    <div style={divError}>
                        <Lista 
                            items = {error}
                        />
                    </div>
                </div>
            }
            <div className='mb-2'>
                <h5 style={estilo}>La contraseña debe cumplir los siguientes requerimientos:</h5>
                <div className="row">
                    <div className="col-6">
                        <ul>
                            <li style={estilo}>Al menos <strong>una letra minúscula</strong></li>
                            <li style={estilo}>Al menos <strong>una letra mayúscula</strong></li>
                            <li style={estilo}>Al menos <strong>un número</strong></li>
                        </ul>
                    </div>
                    <div className="col-6">
                        <ul>
                            <li style={estilo}>Al menos <strong>un carácter especial</strong></li>
                            <li style={estilo}>Al menos <strong>8 carácteres</strong></li>
                        </ul>
                    </div>
                </div>
                
            </div>
            <div className="buttons-box">
                <button type="submit" style={estilo2} className="btn btn-primary m-1 align-self-center">Activar</button>
            </div>
        </form>
    );
};
UsuarioFormPass.propTypes = {
    handleSubmit: PropTypes.func,
};
export const matchPassword = (pass, confirm) => validatorFromFunction(value => {
    return pass === confirm;
});

export default reduxForm({
    form: 'FormUserPass', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            confirmPassword: combine(
                validators.exists()('Este campo es requerido'),
                matchPassword(data.password, data.confirmPassword)()('Las contraseñas no coinciden')
            ),
            password: validators.exists()('Este campo es requerido'),
        });
    },
})(UsuarioFormPass);

