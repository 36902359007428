import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import NavbarModulo from '../../components/NavbarModulo';
import NavbarModuloLF from '../../components/NavbarModuloFage';
import classNames from 'classnames';
const valEmp = __CONFIG__.empresa;

import {
    AsyncSelectFieldIn,
    renderField,
    SelectField,
    renderDayPickerB
} from "../../Utils/renderField/renderField";

function BajaForm(props) {
    const { modulo, emp, registroBaja, tipo,
        datosColaborador, cargo, crear, modificarBaja } = props;

    const editar = window.location.href.includes('editar');
    let titulo = editar ? 'Modificar baja de IGSS' : 'Crear baja de IGSS';
    let disabled = false;
    if (crear === false && editar === false) {
        disabled = true;
        titulo = 'Ver baja de IGSS'
    }
    
    let ruta = window.location.href;
    let id_emp = ruta.split('/');

    const handleOnChangeEmp = value => {
        datosColaborador(value.value);
    };

    const tipoS = [
        { label: "ACCIDENTE", value: 1 },
        { label: "ENFERMEDAD", value: 2 },
        { label: "MATERNIDAD", value: 3 },
    ];

    return (
        <div>
            {valEmp === 'fage' &&
                <NavbarModuloLF
                    modulo={modulo}
                    titulo={titulo}
                />
            }
            {valEmp === '' &&
                <NavbarModulo
                    modulo={modulo}
                    titulo={titulo}
                />
            }
           
            <br />
            <div className="mb-4 card card-small text-white bg-dark mb-3">
                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                    <div className="d-flex flex-column flex-1 mx-4">
                        <div className='mb-2'>
                            {(crear === false && editar === false) &&
                                <div className='row mb-2'>
                                    <div className='col-6'>
                                        <Field
                                            name="nombre"
                                            placeholder="Colaborador"
                                            className="form-control-find"
                                            disabled={true}
                                            component={renderField}
                                            msj="Colaborador"
                                        />
                                    </div>
                                    <div className='col-6'>
                                        <Field
                                            name="puestoNombre"
                                            placeholder="Puesto"
                                            className="form-control-find"
                                            disabled={true}
                                            component={renderField}
                                            msj="Puesto"
                                        />
                                    </div>
                                </div>
                            }
                            {crear === true &&
                                <div className='row mb-2'>
                                    <div className='col-6'>
                                        <Field
                                            name="colaborador"
                                            placeholder="Colaborador..."
                                            className="form-control-find"
                                            component={AsyncSelectFieldIn}
                                            loadOptions={emp}
                                            onChange={handleOnChangeEmp}
                                            msj="Colaborador"
                                        />
                                    </div>
                                    <div className='col-6'>
                                        <div className="form-floating">
                                            <input
                                                className = {classNames('form-control')}
                                                type="text"
                                                value = {cargo}
                                                disabled = {true}
                                            />
                                            <label className='form-label'>Puesto</label>
                                        </div>
                                    </div>
                                </div>
                            }
                            {editar === true &&
                                <div className='row mb-2'>
                                    <div className='col-6'>
                                        <Field
                                            name="nombre"
                                            placeholder="Colaborador"
                                            className="form-control-find"
                                            disabled={true}
                                            component={renderField}
                                            msj="Colaborador"
                                        />
                                    </div>
                                    <div className='col-6'>
                                        <Field
                                            name="puestoNombre"
                                            placeholder="Puesto"
                                            className="form-control-find"
                                            disabled={true}
                                            component={renderField}
                                            msj="Puesto"
                                        />
                                    </div>
                                </div>
                            }
                            {crear === false && editar === false &&
                                <div className='row mb-3'>
                                    <div className='col-3'>
                                        <Field
                                            name="fecha_suspension"
                                            placeholder="Fecha inicial"
                                            className="form-control-find"
                                            disabled={true}
                                            component={renderDayPickerB}
                                            msj="Fecha suspensión"
                                        />
                                    </div>
                                    <div className='col-3'>
                                        <div className="form-floating">
                                            <Field
                                                name="tipo"
                                                disabled={true}
                                                options={tipoS}
                                                dato={tipo}
                                                component={SelectField}
                                                className="form-select"
                                                msj="Motivo"
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                            {editar === true &&
                                <div className='row mb-3'>
                                    <div className='col-3'>
                                        <Field
                                            name="fecha_suspension"
                                            placeholder="Fecha suspensión"
                                            className="form-control-find"
                                            component={renderDayPickerB}
                                            msj="Fecha suspensión"
                                        />
                                    </div>
                                    <div className='col-3'>
                                        <div className="form-floating">
                                            <Field
                                                name="motivoS"
                                                disabled={disabled}
                                                options={tipoS}
                                                crear={crear}
                                                dato={crear === true ? 0 : tipo}
                                                component={SelectField}
                                                className="form-select"
                                                msj="Motivo"
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                            {crear === true &&
                                <div className='row mb-3'>
                                    <div className='col-3'>
                                        <Field
                                            name="fecha_suspension"
                                            placeholder="Fecha inicial"
                                            className="form-control-find"
                                            component={renderDayPickerB}
                                            msj="Fecha suspensión"
                                        />
                                    </div>
                                    <div className='col-3'>
                                        <div className="form-floating">
                                            <Field
                                                name="motivo"
                                                disabled={disabled}
                                                options={tipoS}
                                                crear={crear}
                                                dato={crear === true ? 0 : tipo}
                                                component={SelectField}
                                                className="form-select"
                                                msj="Motivo"
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className='d-flex flex-row mt-3 ml-2'>
                                {crear === true &&
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={() => registroBaja()}
                                    >
                                        Guardar
                                    </button>
                                }
                                {editar === true &&
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={() => modificarBaja()}
                                    >
                                        Modificar
                                    </button>
                                }
                                
                                {valEmp === 'fage' &&
                                    <a
                                        href={`/#/lfg/rr_hh/${id_emp[6]}/baja_igss`}
                                        className='btn btn-secondary mb-3'
                                    >
                                        Regresar
                                    </a>
                                }
                                {valEmp === '' &&
                                    <a
                                        href={`/#/rr_hh/${id_emp[5]}/baja_igss`}
                                        className='btn btn-secondary mb-3'
                                    >
                                        Regresar
                                    </a>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

BajaForm.propTypes = {
    handleSubmit: PropTypes.func,
    crear: PropTypes.bool,
    lectura: PropTypes.object,
    modulo: PropTypes.object
};

export default reduxForm({
    form: 'FormBajaIgss',
})(BajaForm);
