import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import DetalleNota from './DetalleNotaCobro';
import DetalleEnvio from './DetalleEnvio';
import NavbarModulo from '../../components/NavbarModulo';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';

import {
    renderField,
    renderDayPickerB,
    AsyncSelectField,
    renderNumber,
    renderFieldCheckB
} from "../../Utils/renderField/renderField";
import propTypes from 'prop-types';

function NotaCobroForm(props) {
    const {
        crear,
        cli,
        periodoD,
        modulo,
        listarEnvios,
        registroNota,
        detalleEnvio,
        detalleProducto,
        detalleEnv,
        editarLinea,
        numeroSerie,
        listarSeries,
        tipoCambio,
        nota,
        listarClientes,
        tipoEnvio
    } = props;

    let autoFocus = true;


    const estilo = {
        overflow: "auto",
        height: "200px",
    };
    let ruta = window.location.href;
    let id_empresa = ruta.split('/');

    const editar = window.location.href.includes('editar');
    let titulo = editar ? 'Modificar nota de cobro' : 'Crear nota de cobro';
    let disabled = false;
    if (crear === false && editar === false) {
        disabled = true;
        titulo = 'Ver nota de cobro'
    }
    const [numeroSerieEnv, setNumeroSerieEnv] = useState('');
    const [tipoCambioD, setTipoCambioD] = useState(1.000000);

    let ns;
    const handleOnChangeS = value => {
        ns = numeroSerie(value.label);
        ns.then(d => setNumeroSerieEnv(d))
    };
    const handleOnChange = value => {
        detalleEnvio(value.value);
    };

    return (
        <div>
            <NavbarModulo
                periodoD={periodoD}
                modulo={modulo}
                titulo={titulo}
            />
            <br />
            <div className="mb-4 card card-small text-white bg-dark mb-3">
                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                    <div className="d-flex flex-column flex-1 mx-4">
                        {(crear === false && editar === false) &&
                            <div className='row mb-2'>
                                <div className='col-3'>
                                    <Field
                                        autoFocus={autoFocus}
                                        disabled={disabled}
                                        name="fechaRegistro"
                                        component={renderDayPickerB}
                                        msj="Fecha Registro"
                                    />
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="serie"
                                        placeholder="Nombre"
                                        className="form-control"
                                        component={renderField}
                                        disabled={disabled}
                                        msj="Serie"
                                    />
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="numero"
                                        placeholder="Dias credito"
                                        className="form-control"
                                        component={renderNumber}
                                        disabled={disabled}
                                        msj="Numero"
                                    />
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="envio"
                                        placeholder="Envío"
                                        className="form-control"
                                        component={renderField}
                                        disabled={disabled}
                                        msj="Envío"
                                    />
                                </div>
                            </div>
                        }
                        {crear === true &&
                            <div className='row mb-2'>
                                <div className='col-3'>
                                    <Field
                                        autoFocus={autoFocus}
                                        disabled={disabled}
                                        name="fechaRegistro"
                                        component={renderDayPickerB}
                                        msj="Fecha Registro"
                                    />
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="serie"
                                        placeholder="Serie..."
                                        className="form-control-find"
                                        loadOptions={listarSeries}
                                        component={AsyncSelectField}
                                        onChange={handleOnChangeS}
                                        msj="Serie"
                                    />
                                </div>
                                <div className='col-3'>
                                    <div className="form-floating">
                                        <input
                                            placeholder="Numero"
                                            type="number"
                                            autocomplete="off"
                                            name="numero"
                                            className="form-control"
                                            value={numeroSerieEnv}
                                            onChange={e => setNumeroSerieEnv(e.target.value)}
                                        />
                                        <label className='form-label' htmlFor="cuenta">
                                            Numero
                                        </label>
                                    </div>
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="envio"
                                        placeholder="Envio de producción..."
                                        className="form-control-find"
                                        loadOptions={listarEnvios}
                                        component={AsyncSelectField}
                                        onChange={handleOnChange}
                                        msj="Envio de producción"
                                    />
                                </div>

                            </div>
                        }
                        {(editar === true) &&
                            <div className='row mb-2'>
                                <div className='col-3'>
                                    <Field
                                        autoFocus={autoFocus}
                                        disabled={disabled}
                                        name="fechaRegistro"
                                        component={renderDayPickerB}
                                        msj="Fecha Registro"
                                    />
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="serie"
                                        placeholder="Nombre"
                                        className="form-control"
                                        component={renderField}
                                        disabled={true}
                                        msj="Serie"
                                    />
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="numero"
                                        placeholder="Dias credito"
                                        className="form-control"
                                        component={renderNumber}
                                        disabled={true}
                                        msj="Numero"
                                    />
                                </div>
                            </div>
                        }
                        {(crear === true) &&
                            <div>
                            {cli.exportacion === true &&
                                <div className='row mb-2'>
                                    <div className='col-3'>
                                        <div className="form-floating">
                                            <NumberFormat
                                                className={classNames('form-control')}
                                                decimalScale={6}
                                                fixedDecimalScale
                                                placeholder="Tipo de cambio"
                                                value={tipoCambioD}
                                                thousandSeparator
                                                onValueChange={(values) => {
                                                    setTipoCambioD(values.value)
                                                }}
                                            />
                                            <label className='form-label' htmlFor="cuenta">
                                                Tipo de cambio
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-3 mt-2'>
                                        <button
                                            className='btn mr-2 mb-3 btn-secondary'
                                            onClick={() => {
                                                let tc = tipoCambio(periodoD, cli.exportacion);
                                                if (tc === undefined) {
                                                    setTipoCambioD('1.000000');
                                                } else {
                                                    tc.then(d => {
                                                        setTipoCambioD(d);
                                                    });
                                                }
                                            }}
                                        >
                                            Buscar tipo de cambio
                                        </button>
                                    </div>
                                    <div className='col-3 mt-3'>
                                        <Field
                                            disabled={true}
                                            checked={true}
                                            name="exportacion"
                                            label="Exportación"
                                            component={renderFieldCheckB}
                                        />
                                    </div>
                                </div>
                            }
                            </div>
                        }
                        {(crear === false && editar === false) &&
                            <div>
                                {nota === true &&
                                    <div className='row mb-2'>
                                        <div className='col-3'>
                                            <Field
                                                name="tipo_cambio"
                                                placeholder="Tipo de cambio"
                                                className="form-control"
                                                component={renderField}
                                                disabled={true}
                                                msj="Tipo de cambio"
                                            />
                                        </div>
                                        <div className='col-3 mt-3'>
                                            <Field
                                                disabled={true}
                                                checked={true}
                                                name="exportacion"
                                                label="Exportación"
                                                component={renderFieldCheckB}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                        {(crear === true) &&
                            <div>
                                <div className='row mb-2'>
                                    <div className='col-12'>
                                        <div className="form-floating">
                                            <input
                                                placeholder="Cliente"
                                                type="text"
                                                autocomplete="off"
                                                name="cliente"
                                                className="form-control"
                                                value={detalleEnv.length === 0 ? '' : cli.cliente}
                                                disabled={true}
                                            />
                                            <label className='form-label' htmlFor="cuenta">
                                                Cliente
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                {tipoEnvio === 'DEV' &&
                                    <div className='row mb-2'>
                                        <div className='col-8'>
                                            <Field
                                                name="cliente_documento"
                                                placeholder="Cliente..."
                                                className="form-control-find"
                                                component={AsyncSelectField}
                                                loadOptions={listarClientes}
                                                msj="Cliente"
                                            />
                                        </div>
                                    </div>
                                }
                                <div className='row mb-2'>
                                    <div className='col-12'>
                                        <Field
                                            name="observaciones"
                                            disabled={disabled}
                                            placeholder="Observaciones"
                                            className="form-control"
                                            component={renderField}
                                            msj="Observaciones"
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {(crear === false && editar === false) &&
                            <div>
                                <div className='row mb-2'>
                                    <div className='col-12'>
                                        <Field
                                            name="clienteN"
                                            disabled={disabled}
                                            placeholder="Cliente"
                                            className="form-control"
                                            component={renderField}
                                            msj="Cliente"
                                        />
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <div className='col-12'>
                                        <Field
                                            name="observaciones"
                                            disabled={disabled}
                                            placeholder="Observaciones"
                                            className="form-control"
                                            component={renderField}
                                            msj="Observaciones"
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {(editar === true) &&
                            <div>
                                <div className='row mb-2'>
                                    <div className='col-12'>
                                        <Field
                                            name="clienteN"
                                            disabled={true}
                                            placeholder="Cliente"
                                            className="form-control"
                                            component={renderField}
                                            msj="Cliente"
                                        />
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <div className='col-12'>
                                        <Field
                                            name="observaciones"
                                            disabled={disabled}
                                            placeholder="Observaciones"
                                            className="form-control"
                                            component={renderField}
                                            msj="Observaciones"
                                        />
                                    </div>
                                </div>
                            </div>
                        }

                        {(crear === true) &&
                            <div>
                                <h5 className="p-2 bg-secondary title mt-1">Detalle nota de cobro</h5>
                                <br />
                                <div style={estilo}>
                                    <DetalleEnvio
                                        detalle={detalleEnv}
                                        editarLinea={editarLinea}
                                    />
                                </div>
                            </div>
                        }

                        {(editar === true) &&
                            <div>
                                <h5 className="p-2 bg-secondary title mt-1">Detalle nota de cobro</h5>
                                <br />
                                <div style={estilo}>
                                    <DetalleEnvio
                                        detalle={detalleEnv}
                                    />
                                </div>
                            </div>
                        }
                        {(crear === false && editar === false) &&
                            <div>
                                <h5 className="p-2 bg-secondary title mt-1">Detalle nota de cobro</h5>
                                <br />
                                <div style={estilo}>
                                    <DetalleNota
                                        detalle={detalleProducto}
                                    />
                                </div>
                            </div>
                        }
                        <br />
                        {(crear === true || editar === true) &&
                            <div className='mb-2'>
                                <button
                                    className='btn mr-2 mb-2 btn-primary'
                                    onClick={() => registroNota(periodoD, cli.idCliente, detalleEnv, numeroSerieEnv, cli.exportacion, tipoCambioD, tipoEnvio)}
                                >
                                    {editar ? 'Modificar' : 'Guardar nota de cobro'}
                                </button>
                                <a
                                    href={`/#/produccion/${id_empresa[5]}/nota_cobro/${id_empresa[7]}`}
                                    className='btn btn-secondary mb-2'
                                >
                                    Regresar
                                </a>
                            </div>
                        }
                        {(crear === false && editar === false) &&
                            <div className='mb-2'>
                                <a
                                    href={`/#/produccion/${id_empresa[5]}/nota_cobro/${id_empresa[7]}`}
                                    className='btn btn-secondary mb-2'
                                >
                                    Regresar
                                </a>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

NotaCobroForm.propTypes = {
    crear: PropTypes.bool,
    editar: PropTypes.bool,
    bloqueo: PropTypes.bool,
    fechaInicio: PropTypes.string,
    fechaFinal: PropTypes.string,
    datos: PropTypes.object,
    detallePro: PropTypes.object,
    periodoD: PropTypes.object,
    modulo: PropTypes.object,
    detalleIngreso: PropTypes.func,
    detalleProducto: PropTypes.object,
    cli: propTypes.object,
    numeroSerie: propTypes.func,
    detalleEnv: PropTypes.object
};


export default reduxForm({
    form: 'FormNotaCobro',
})(NotaCobroForm);
