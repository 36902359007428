import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import { validate, validators } from 'validate-redux-form';
import PropTypes from 'prop-types';
import NavbarModulo from '../../components/NavbarModulo';

import {
  renderField,
  SelectField,
} from "../../Utils/renderField/renderField";

function TipoAbonoForm(props) {
  const { handleSubmit, crear, lectura, modulo } = props;

  const editar = window.location.href.includes('editar');
  let titulo = editar ? 'Modificar tipo de abono' : 'Crear tipo de abono';
  let disabled = false;
  if (crear === false && editar === false) {
    disabled = true;
    titulo = 'Ver tipo de abono'
  }
  let autoFocus = true;
  let ruta = window.location.href;
  let id_emp = ruta.split('/');
  
  return (
    <div>
      <NavbarModulo
        modulo={modulo}
        titulo={titulo}
      />
      <form onSubmit={handleSubmit}>
        <br />
        <div className="mb-4 card card-small text-white bg-dark mb-3">
          <div className="p-0 pt-3 d-flex flex-column flex-md-row">
            <div className="d-flex flex-column flex-1 mx-4">
              <div className='mb-2 w-25'>
                <Field
                  autoFocus={autoFocus}
                  name="tipo"
                  placeholder="Tipo Abono"
                  className="form-control"
                  component={renderField}
                  disabled={disabled}
                  msj="Tipo Abono"
                />
              </div>
              <div className='mb-2 w-50'>
                <Field
                  name="descripcion"
                  placeholder="Descripcion"
                  className="form-control"
                  component={renderField}
                  disabled={disabled}
                  msj="Descripción"
                />
              </div>
              <div className='d-flex flex-row mt-3'>
                {disabled == false &&
                  <button
                    className='btn mr-2 mb-3 btn-primary'
                    type='submit'
                  >
                    {editar ? 'Modificar' : 'Guardar'}
                  </button>
                }
                <a
                  href={`/#/compras/${id_emp[5]}/tipo_abono`}
                  className='btn btn-secondary mb-3'
                >
                  Regresar
                </a>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

TipoAbonoForm.propTypes = {
  handleSubmit: PropTypes.func,
  crear: PropTypes.bool,
  lectura: PropTypes.object,
  modulo: PropTypes.object,
};

export default reduxForm({
  form: 'FormTipoAbono',
  validate: (data) => {
    return validate(data, {
      tipo: validators.exists()('Este campo es requerido'),
      descripcion: validators.exists()('Este campo es requerido'),
      tipo_documento: validators.exists()('Seleccione tipo'),
    });
  },
})(TipoAbonoForm);
