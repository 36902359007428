import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/auto_ventas/vendedores/vendedoresEditar';
import VendedoresFormEditar from './VendedoresFormEditar';


const ms2p = (state) => {
  return {
    ...state.auto_ventas_vendedores_editar,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p,)(VendedoresFormEditar);
