import { handleActions } from 'redux-actions';
import { NotificationManager } from "react-notifications";
import { api } from "api";

const PAGE = 'PAGE';
const LOADER = 'LOADER';
const MODULO = 'MODULO';
const LISTADO = 'LISTADO';
const LISTADO_BODEGAS = 'LISTADO_BODEGAS';

const setPage = page => ({
    type: PAGE,
    page,
});

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const registroSerie = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormSeries.values;
    const formData = {
        tipo_registro: data.tipo,
        serie: data.serie.toUpperCase(),
        bodega: data.bodega,
        movimiento: data.movimiento,
        empresa: id_emp[5]
    }
    dispatch(setLoader(true));
    api.post('/serie_prd/', formData).then(() => {
        NotificationManager.success(
        'Serie creada',
        'Exito',
        3000
    );
    dispatch(listarSeries());
    }).catch((error) => {
    NotificationManager.error(
        error.msj,
        'Error',
        2000
    );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}


export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const listarSeries = (page = 1) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { page, id };
    let cont = 1;
    let ct = localStorage.getItem("asignarListSeries");
    localStorage.setItem("asignarListSeries",cont);
    if (page===1 && ct===null){
        dispatch(setLoader(true));
    }
    
    api.get('/serie_prd', params).then((response)=>{
        dispatch({ type: LISTADO, lista: response });
        dispatch(setPage(page));
    }).catch((error)=>{
        console.log("error: ", error)
        NotificationManager.error(
            'Ocurrió un error al listar',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
  
    });
};

export const listarBodegas = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    api.get('/bodega', params).then((response) => {
        const bodega = [];
        response.forEach(item=>{
            bodega.push({
                value: item.id,
                label: item.numero + ' / ' + item.bodega
            })
        })
        dispatch({ type: LISTADO_BODEGAS, listaBodegas: bodega });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar los proyectos',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const eliminarSerie = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`/serie_prd/${id}`).then(() => {
        dispatch(listarSeries());
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const actions = {
    registroSerie,
    getModulo,
    listarSeries,
    listarBodegas,
    eliminarSerie
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [LISTADO]: (state, { lista }) => {
        return {
            ...state,
            lista,
        };
    },
    [LISTADO_BODEGAS]: (state, { listaBodegas }) => {
        return {
            ...state,
            listaBodegas,
        };
    },
};

export const initialState = {
    loader: false,
    modulo: [],
    listaCentros: [],
    lista: [],
    listaBodegas: [],
    data: {
        results: [],
        count: 0,
    },
    page: 1,
};

export default handleActions(reducers, initialState)
 