import { handleActions } from "redux-actions";
import { SET_SETTINGS_EMPRESA } from "./actions";

const initialState = {
    settings: {},
};

export const settingsReducer = (state = initialState, action) => {
    return {
        ...state,
        settings: action.payload,
    };
};

const reducers = {
    [SET_SETTINGS_EMPRESA]: settingsReducer,
};
export default handleActions(reducers, initialState);
