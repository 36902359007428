import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import NavbarModulo from '../../components/NavbarModulo';
import NavbarModuloLF from '../../components/NavbarModuloFage';
import classNames from 'classnames';
const valEmp = __CONFIG__.empresa;

import {
    AsyncSelectFieldIn,
    renderCurrencyFloat,
    renderNumber,
    renderField,
    SelectField,
    renderDayPickerB,
    renderFieldCheck
} from "../../Utils/renderField/renderField";

function FormISR(props) {
    const { modulo, emp, registroIsr, tipo,
        datosColaborador, cargo, crear, modificarIsr, year,
        fechaI, fechaF } = props;

    const editar = window.location.href.includes('editar');
    let titulo = editar ? 'Modificar ISR colaborador' : 'Crear ISR colaborador';
    let disabled = false;
    if (crear === false && editar === false) {
        disabled = true;
        titulo = 'Ver ISR colaborador'
    }
    
    let ruta = window.location.href;
    let id_emp = ruta.split('/');

    const handleOnChangeEmp = value => {
        datosColaborador(value.value);
    };

    const tipoD = [
        { label: "1RA. QUINCENA", value: "1Q" },
        { label: "2DA. QUINCENA", value: "2Q" },
        { label: "50% 1RA. Q. y 50% 2DA. Q.", value: "QQ" },
    ];

    return (
        <div>
            {valEmp === 'fage' &&
                <NavbarModuloLF
                    modulo={modulo}
                    titulo={titulo}
                />
            }
            {valEmp === '' &&
                <NavbarModulo
                    modulo={modulo}
                    titulo={titulo}
                />
            }
           
            <br />
            <div className="mb-4 card card-small text-white bg-dark mb-3">
                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                    <div className="d-flex flex-column flex-1 mx-4">
                        <div className='mb-2'>
                            {(crear === false && editar === false) &&
                                <div className='row mb-2'>
                                    <div className='col-6'>
                                        <Field
                                            name="nombre"
                                            placeholder="Colaborador"
                                            className="form-control-find"
                                            disabled={true}
                                            component={renderField}
                                            msj="Colaborador"
                                        />
                                    </div>
                                    <div className='col-6'>
                                        <Field
                                            name="puestoNombre"
                                            placeholder="Puesto"
                                            className="form-control-find"
                                            disabled={true}
                                            component={renderField}
                                            msj="Puesto"
                                        />
                                    </div>
                                </div>
                            }
                            {crear === true &&
                                <div className='row mb-2'>
                                    <div className='col-6'>
                                        <Field
                                            name="colaborador"
                                            placeholder="Colaborador..."
                                            className="form-control-find"
                                            component={AsyncSelectFieldIn}
                                            loadOptions={emp}
                                            onChange={handleOnChangeEmp}
                                            msj="Colaborador"
                                        />
                                    </div>
                                    <div className='col-6'>
                                        <div className="form-floating">
                                            <input
                                                className = {classNames('form-control')}
                                                type="text"
                                                value = {cargo}
                                                disabled = {true}
                                            />
                                            <label className='form-label'>Puesto</label>
                                        </div>
                                    </div>
                                </div>
                            }
                            {editar === true &&
                                <div className='row mb-2'>
                                    <div className='col-6'>
                                        <Field
                                            name="nombre"
                                            placeholder="Colaborador"
                                            className="form-control-find"
                                            disabled={true}
                                            component={renderField}
                                            msj="Colaborador"
                                        />
                                    </div>
                                    <div className='col-6'>
                                        <Field
                                            name="puestoNombre"
                                            placeholder="Puesto"
                                            className="form-control-find"
                                            disabled={true}
                                            component={renderField}
                                            msj="Puesto"
                                        />
                                    </div>
                                </div>
                            }
                            {crear === false && editar === false &&
                                <div className='row mb-3'>
                                    <div className='col-3'>
                                        <Field
                                            name="anio"
                                            disabled={disabled}
                                            placeholder="Año"
                                            className="form-control"
                                            component={renderNumber}
                                            msj="Año"
                                        />
                                    </div>
                                    <div className='col-3'>
                                        <Field
                                            name="fecha_inicial"
                                            placeholder="Fecha inicial"
                                            className="form-control-find"
                                            disabled={true}
                                            component={renderDayPickerB}
                                            msj="Fecha inicial"
                                        />
                                    </div>
                                    <div className='col-3'>
                                        <Field
                                            name="fecha_fin"
                                            placeholder="Fecha final"
                                            className="form-control-find"
                                            disabled={true}
                                            component={renderDayPickerB}
                                            msj="Fecha final"
                                        />
                                    </div>
                                </div>
                            }
                            {editar === true &&
                                <div className='row mb-3'>
                                    <div className='col-3'>
                                        <Field
                                            name="anio"
                                            disabled={true}
                                            placeholder="Año"
                                            className="form-control"
                                            component={renderNumber}
                                            msj="Año"
                                        />
                                    </div>
                                    <div className='col-3'>
                                        <Field
                                            name="fecha_inicial"
                                            placeholder="Fecha inicial"
                                            className="form-control-find"
                                            disabled={true}
                                            component={renderDayPickerB}
                                            msj="Fecha inicial"
                                        />
                                    </div>
                                    <div className='col-3'>
                                        <Field
                                            name="fecha_fin"
                                            placeholder="Fecha final"
                                            className="form-control-find"
                                            disabled={true}
                                            component={renderDayPickerB}
                                            msj="Fecha final"
                                        />
                                    </div>
                                </div>
                            }
                            {crear === true &&
                                <div className='row mb-3'>
                                    <div className='col-3'>
                                        <div className="form-floating">
                                            <input
                                                disabled = {true}
                                                type="number"
                                                name="fecha_inicial"
                                                className="form-control"
                                                value={year}
                                            />
                                            <label className='form-label' htmlFor="fecI">
                                                Año
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-3'>
                                        <div className="form-floating">
                                            <input
                                                disabled = {true}
                                                type="text"
                                                name="fecha_inicial"
                                                className="form-control"
                                                value={fechaI}
                                            />
                                            <label className='form-label' htmlFor="fecI">
                                                Fecha inicial
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-3'>
                                        <div className="form-floating">
                                            <input
                                                disabled = {true}
                                                type="text"
                                                name="fecha_fin"
                                                className="form-control"
                                                value={fechaF}
                                            />
                                            <label className='form-label' htmlFor="fecF">
                                                Fecha final
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className='row mb-3'>
                                <div className='col-3'>
                                    <Field
                                        name="descuento"
                                        disabled={disabled}
                                        placeholder="Descuento"
                                        className="form-control"
                                        component={renderCurrencyFloat}
                                        msj="Descuento"
                                    />
                                </div>
                                <div className='col-3'>
                                    <div className="form-floating">
                                        <Field
                                            name="tipo"
                                            disabled={disabled}
                                            options={tipoD}
                                            crear={crear}
                                            dato={crear === true ? 0 : tipo}
                                            component={SelectField}
                                            className="form-select"
                                            msj="Frecuencia de pago"
                                        />
                                    </div>
                                </div>
                                {editar === true &&
                                    <div className='col-3 mt-3'>
                                        <Field
                                            disabled={disabled}
                                            name="activo"
                                            label="Activo"
                                            component={renderFieldCheck}
                                        />
                                    </div>
                                }
                                {crear === false &&  editar === false &&
                                    <div className='col-3 mt-3'>
                                        <Field
                                            disabled={disabled}
                                            name="activo"
                                            label="Activo"
                                            component={renderFieldCheck}
                                        />
                                    </div>
                                }
                            </div>
                            <div className='d-flex flex-row mt-3 ml-2'>
                                {crear === true &&
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={() => registroIsr(year, fechaI, fechaF)}
                                    >
                                        Guardar
                                    </button>
                                }
                                {editar === true &&
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={() => modificarIsr()}
                                    >
                                        Modificar
                                    </button>
                                }
                                
                                {valEmp === 'fage' &&
                                    <a
                                        href={`/#/lfg/rr_hh/${id_emp[6]}/isr/${id_emp[8]}`}
                                        className='btn btn-secondary mb-3'
                                    >
                                        Regresar
                                    </a>
                                }
                                {valEmp === '' &&
                                    <a
                                        href={`/#/rr_hh/${id_emp[5]}/isr/${id_emp[7]}`}
                                        className='btn btn-secondary mb-3'
                                    >
                                        Regresar
                                    </a>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

FormISR.propTypes = {
    handleSubmit: PropTypes.func,
    crear: PropTypes.bool,
    lectura: PropTypes.object,
    modulo: PropTypes.object
};

export default reduxForm({
    form: 'FormIsr',
})(FormISR);
