import React, { Component } from 'react';
import Formulario from './BajaForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";
const valEmp = __CONFIG__.empresa;

class Baja extends Component{
    
    componentDidMount = () => {
        const { getModulo, listarColaborador, parametrosEmpresa } = this.props;
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        
        if (valEmp === 'fage'){
            getModulo(modulo[6],modulo[5]);
            listarColaborador(modulo[8]);
            parametrosEmpresa();
        }else{
            getModulo(modulo[5],modulo[4]);
            listarColaborador(modulo[7]);
            parametrosEmpresa();
        }
        
    }
    
    render(){
        const { listarColaborador, loader, modulo, listarPaises, emp, baja, datos } = this.props;
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    modulo = {modulo}
                    listarColaborador = {listarColaborador}
                    listarPaises = {listarPaises}
                    emp = {emp}
                    baja = {baja}
                    datos = {datos}
                />
            </LoadMask>
        );
    }
}

export default Baja;