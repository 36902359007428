import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';
const valEmp = __CONFIG__.empresa;

const LISTADO_DESCUENTOS = 'LISTADO_DESCUENTOS';
const COLABORADOR = 'COLABORADOR';
const LECTURA = 'LECTURA';
const LOADER = 'LOADER';
const MODULO = 'MODULO';
const LISTADO = 'LISTADO';
const LISTADO_TIPOS_DESC = 'LISTADO_TIPOS_DESC';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = '';
    let params;
    if (valEmp === 'fage'){
        id = id_emp[6];
        params = { id };
    }else{
        id = id_emp[5];
        params = { id };
    }
    return api.get('/rrhh_descuento_fijo/', params).then((response)=>{
        dispatch({type: LISTADO, data: response })
    }).catch(error=>{
        NotificationManager.error(
            'Ocurrió un error al listar descuentos',
            'Error',
            0
        );
    })
}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = { id, modulo }
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response) => {
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        if (error.status === 401) {
            NotificationManager.error(
                error.body.msj,
                'Error',
                0
            );
        }
        if (error.status === 400) {
            NotificationManager.error(
                'Ocurrió un error en buscar modulo',
                'Error',
                0
            );
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const listarColaborador = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params;
    let id = ''
    if (valEmp === 'fage'){
        id = id_emp[6];
        params = { id };
    }else{
        id = id_emp[5];
        params = { id };
    }
    api.get('/colaborador/empresa_colaborador_pais', params).then(data => {
        const lista = [];
        if (data) {
            data.forEach(item => {
                lista.push({
                    value: item.id,
                    label: item.codigo+' - ' + item.nombre
                })
            })
        }
        dispatch({type: COLABORADOR, emp: lista })
    }).catch((error) => {
        return [];
    })
}

export const registroDescuento = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id_empresa = '';
    if (valEmp === 'fage') {
        id_empresa = id_emp[6]
    } else {
        id_empresa = id_emp[5]
    }
    const data = getStore().form.FormDescuentoFijoRH.values;
    let tipo = data.tipo ? data.tipo : data.tipo_descuento;
    let forma = data.forma_desc ? data.forma_desc : data.forma_descuento;

    if (data === undefined) {
        Swal.fire({
            title: 'Descuento',
            text: 'Debe de seleccionar colaborador',
            type: 'error',
        })
    } else {
        if (tipo === undefined || tipo === '') {
            Swal.fire({
                title: 'Descuento',
                text: 'Debe de seleccionar tipo de descuento',
                type: 'error',
            })
        } else {
            if (data.monto === undefined || parseFloat(data.tipo_descuento) === 0.00) {
                Swal.fire({
                    title: 'Descuento',
                    text: 'Debe de ingresar monto',
                    type: 'error',
                })
            } else {
                const formData = {
                    colaborador: data.colaborador_descuento.value,
                    tipo_descuento: tipo,
                    monto: data.monto,
                    descripcion: data.descripcion,
                    fecha_inicial: data.fecha_inicio,
                    forma_descuento: forma,
                    empresa: id_empresa,
                }
                dispatch(setLoader(true));
                api.post('/rrhh_descuento_fijo/', formData).then((response) => {
                    NotificationManager.success(
                        'Descuento creado',
                        'Exito',
                        3000
                    );
                    
                    response.monto = 0.00;
                    response.descripcion = '';
                    dispatch(initializeForm('FormDescuentoFijoRH', response));
                }).catch((error) => {
                    NotificationManager.error(
                        error.msj,
                        'Error',
                        2000
                    );
                }).finally(() => {
                    dispatch(setLoader(false));
                });
            }
        }
    }
}

export const modificarDescuento = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    
    const datos = getStore().form.FormDescuentoFijoRH.values;
    
    let tipo = datos.tipo ? datos.tipo : datos.tipo_descuento;
    let forma = datos.forma_desc ? datos.forma_desc : datos.forma_descuento;
    const id = datos.id;

    if (datos === undefined) {
        Swal.fire({
            title: 'Descuento',
            text: 'Debe de seleccionar colaborador',
            type: 'error',
        })
    } else {
        if (tipo === undefined || tipo === '') {
            Swal.fire({
                title: 'Descuento',
                text: 'Debe de seleccionar tipo de descuento',
                type: 'error',
            })
        } else {
            if (datos.monto === undefined || parseFloat(datos.monto) === 0.00) {
                Swal.fire({
                    title: 'Descuento',
                    text: 'Debe de ingresar monto',
                    type: 'error',
                })
            } else {
                const formData = {
                    id: datos.id,
                    fecha_inicial: datos.fecha_inicial,
                    forma_descuento: forma,
                    monto: datos.monto,
                    tipo_descuento: tipo,
                    descripcion: datos.descripcion,
                }
            
                dispatch(setLoader(true));
                api.put(`/rrhh_descuento_fijo/${id}/`, formData).then(() => {
                    NotificationManager.success(
                        'Descuento modificado',
                        'Exito',
                        3000
                    );
                    if (valEmp === 'fage'){
                        let ruta = `/lfg/rr_hh/${id_emp[6]}/descuentos_fijos`
                        dispatch(push(ruta));
                    }
                    if (valEmp === ''){
                        let ruta = `/rr_hh/${id_emp[5]}/descuentos_fijos`
                        dispatch(push(ruta));
                    }
                }).catch((error) => {
                    NotificationManager.error(
                        error.msj,
                        'Error',
                        2000
                    );
                }).finally(() => {
                    dispatch(setLoader(false));
                });
            }
        }
    }
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/rrhh_descuento_fijo/${id}`).then((response) => {
        dispatch({ type: LECTURA, lectura: response });
        dispatch(initializeForm('FormDescuentoFijoRH', response ));
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar descuento',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

const eliminarDescuento = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`rrhh_descuento_fijo/${id}`).then(() => {
        dispatch(listar());
        NotificationManager.success(
            'Descuento borrado correctamente',
            'Exito',
            3000
        );
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar descuento',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const listarTiposDesc = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params;
    let id = '';
    if (valEmp === 'fage') {
        id = id_emp[6];
        params = { id };
    } else {
        id = id_emp[5];
        params = { id };
    }
    api.get('/rrhh_tipo_descuento', params).then((response) => {
        const tiposD = [];
        response.forEach(item => {
            tiposD.push({
                value: item.id,
                label: item.descripcion,
            })
        })
        dispatch({ type: LISTADO_TIPOS_DESC, tipos: tiposD });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar tipos de descuento',
            'Error',
            0
        );
    });
}

export const actions = {
    listar,
    leer,
    getModulo,
    listarColaborador,
    registroDescuento,
    modificarDescuento,
    eliminarDescuento,
    listarTiposDesc
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [LISTADO_DESCUENTOS]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [COLABORADOR]: (state, { emp }) => {
        return {
            ...state,
            emp,
        };
    },
    [LECTURA]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
    [LISTADO_TIPOS_DESC]: (state, { tipos }) => {
        return {
            ...state,
            tipos,
        };
    },
    [LISTADO]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
};

export const initialState = {
    loader: false,
    modulo: [],
    data: [],
    emp: [],
    lectura: [],
    tipos: [],
};

export default handleActions(reducers, initialState)