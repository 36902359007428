import { handleActions } from 'redux-actions';
import { NotificationManager } from "react-notifications";
import { push } from "react-router-redux";
import { api } from "api";
import Swal from 'sweetalert2';
const valEmp = __CONFIG__.empresa;

const COLABORADOR = 'COLABORADOR';
const LOADER = 'LOADER';
const MODULO = 'MODULO';
const LISTADO_EMP = 'LISTADO_EMP';
const PARAMETRO = 'PARAMETRO';

const setLoader = loader => ({
    type: LOADER,
    loader,
});


export const listarColaborador = (pais) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params;
    let id = ''
    if (valEmp === 'fage'){
        id = id_emp[6];
        params = { id, pais };
    }else{
        id = id_emp[5];
        params = { id, pais };
    }
    api.get('/colaborador/empresa_colaborador_pais', params).then(data => {
        const lista = [];
        if (data) {
            data.forEach(item => {
                lista.push({
                    value: item.id,
                    label: item.codigo+' - ' + item.nombre
                })
            })
        }
        dispatch({type: COLABORADOR, emp: lista })
    }).catch((error) => {
        return [];
    })
}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = { id, modulo }
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response) => {
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const listarPaises = () => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let empresa = '';
    if (valEmp === 'fage'){
        empresa = id_emp[6];
    }else{
        empresa = id_emp[5];
    }
    return api.get(`/pais_colaborador/pais_empresa/?id=${empresa}`).then((data)=>{
        const pais = [];
        data.results.forEach(item=>{
            pais.push({
                value: item.id,
                label: item.pais
            })
        })
        return pais;
    }).catch(error=>{
        NotificationManager.error(
            'Ocurrió un error al listar paises',
            'Error',
            0
        );
    })
}

export const baja = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormBajaColaborador.values;
    let id_empresa = '';
    if (valEmp === 'fage'){
        id_empresa = id_emp[6]
    }else{
        id_empresa = id_emp[5]
    }
    if (data === undefined || data === '') {
        Swal.fire({
            title: 'Colaborador',
            text: 'Debe de seleccionar datos',
            type: 'error',
        })
    }else{
        if (data.colaborador === undefined || data.colaborador === '') {
            Swal.fire({
                title: 'Colaborador',
                text: 'Debe de seleccionar colaborador',
                type: 'error',
            })
        }else {
            if (data.fecha_baja === undefined || data.fecha_baja === '') {
                Swal.fire({
                    title: 'Colaborador',
                    text: 'Debe de ingresar fecha de baja',
                    type: 'error',
                })
            }else {
                Swal.fire({
                    title: data.colaborador.label,
                    html: 
                    '<div class="col-sm-12 mt-4">' +
                    '<h4 style="color: white;">¿Esta seguro en dar de baja?</h4>' +
                    '</div>',
                    type: 'error',
                    background: "black",
                    showCancelButton: true,
                    confirmButtonText: 'Si',
                    cancelButtonText: 'No',
                    confirmButtonColor: "#4040ff",
                    cancelButtonColor: "#646464",
                    reverseButtons: true,
                    customClass: {
                        title: 'sweet_titleImportant',
                    },
                }).then((result) => {
                    if (result.value) {
                        const formData = {
                            emp: data.colaborador.value,
                            fechaBaja: data.fecha_baja,
                            empresa: id_empresa
                        }
                        dispatch(setLoader(true));
                        api.post('/colaborador/baja/', formData).then(() => {
                            NotificationManager.success(
                                'Colaborador dado de baja',
                                'Exito',
                                3000
                            );
                            let ruta = '';
                            if (valEmp === 'fage'){
                                ruta = `/lfg/rr_hh/${id_emp[6]}/baja/${id_emp[8]}`
                                dispatch(push(ruta));
                            }else{
                                ruta = `/rr_hh/${id_emp[5]}/baja/${id_emp[7]}`
                                dispatch(push(ruta));
                            }
                        }).catch((error) => {
                            NotificationManager.error(
                                error.msj,
                                'Error',
                                2000
                            );
                        }).finally(() => {
                            dispatch(setLoader(false));
                        });
                    }
                });
            }
        }
    }
}

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params;
    let id = '';
    let id_pais = '';
    if (valEmp === 'fage') {
        id = id_emp[6];
        id_pais = id_emp[8];
        params = { id, id_pais };
    } else {
        id = id_emp[5];
        id_pais = id_emp[7];
        params = { id, id_pais };
    }
    dispatch(setLoader(true));
    api.get('/colaborador/colaborador_baja', params).then((response) => {
        dispatch({ type: LISTADO_EMP, data: response });
        
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar departamentos',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const parametrosEmpresa = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params = '';
    let id = '';
    let pais = '';
    
    if (valEmp === 'fage') {
        id = id_emp[6];
        pais = id_emp[8];
        params = { id, pais }
    } else {
        id = id_emp[5];
        pais = id_emp[7];
        params = { id, pais }
    }
    dispatch(setLoader(true));
    api.get('/empresa/empresa_parametrosRH', params).then((response) => {
        //dispatch(initializeForm('FormColaborador', response));
        dispatch({ type: PARAMETRO, datos: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar parametros',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const actions = {
    listarColaborador,
    getModulo,
    listarPaises,
    baja,
    listar,
    parametrosEmpresa
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [COLABORADOR]: (state, { emp }) => {
        return {
            ...state,
            emp,
        };
    },
    [LISTADO_EMP]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [PARAMETRO]: (state, { datos }) => {
        return {
            ...state,
            datos,
        };
    },
};

export const initialState = {
    loader: false,
    modulo: [],
    emp: [],
    data: [],
    datos: [],
};

export default handleActions(reducers, initialState)
