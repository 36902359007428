import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DetalleInventario from './DetalleIngreso';
import DetalleInventarioVer from './DetalleIngresoVer';
import DetalleInventarioMod from './DetalleIngresoMod';
import NavbarModulo from '../../components/NavbarModulo';
import Modal from '../../../components/components/ModalB'

import {
    renderField,
    renderDayPickerB,
    AsyncSelectField,
    renderNumber,
    SelectField,
    renderCurrencyFloatTC,
    renderCurrencyFloat,
    renderFieldCheck,
    renderFieldCheckB
} from "../../Utils/renderField/renderField";

function IngresoProduccionForm(props) {
    const {
        crear,
        bloqueo,
        listarClientes,
        registroCliente,
        obtenerPaises,
        datos,
        detalleProducto,
        eliminarLineaProducto,
        periodoD,
        modulo,
        agregarLinea,
        detallePro,
        listarProductos,
        listarFases,
        funcionEnvio,
        nuevaLineaProduccion,
        eliminarLinea,
        listarInsumos,
        listarSeries,
        numeroSerie,
    } = props;

    let autoFocus = true;
    
    const [estadoModal, cambiarEstadoModal] = useState(false);
   
    const tiposCL = [
        {
            value: 'L',
            label: 'LOCAL'
        },
        {
            value: 'E',
            label: 'EXTERIOR'
        },
        {
            value: 'P',
            label: 'PERSONA'
        },
    ];
    
    const estilo = {
        overflow: "auto",
        height: "300px",
    };
    let ruta = window.location.href;
    let id_empresa = ruta.split('/');
    
    const editar = window.location.href.includes('editar');
    let titulo = editar ? 'Modificar ingreso de producción' : 'Crear ingreso de producción';
    let disabled = false;
    if (crear === false && editar === false) {
        disabled = true;
        titulo = 'Ver ingreso de producción'
    }
    const [numeroSeriePrd, setNumeroSeriePrd] = useState('');
    let ns;
    const handleOnChange = value => {
        ns = numeroSerie(value.label);
        ns.then(d => setNumeroSeriePrd(d) )
    };
    const [activo, setActivo] = useState(false);
    const onCheckboxClicked = (idx, isChecked) => {
        setActivo(isChecked);
    }
    return (
        <div>
            <NavbarModulo
                periodoD={periodoD}
                modulo={modulo}
                titulo={titulo}
            />
            <br />
            <div className="mb-4 card card-small text-white bg-dark mb-3">
                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                    <div className="d-flex flex-column flex-1 mx-4">
                        {crear === false && editar === false &&
                            <div className='row mb-2'>
                                <div className='col-3'>
                                    <Field
                                        autoFocus={autoFocus}
                                        disabled={disabled}
                                        name="fechaRegistro"
                                        component={renderDayPickerB}
                                        msj="Fecha Registro"
                                    />
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="serie"
                                        placeholder="Serie"
                                        className="form-control"
                                        component={renderField}
                                        disabled={disabled}
                                        msj="Serie"
                                    />
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="numero"
                                        placeholder="Numero"
                                        className="form-control"
                                        component={renderNumber}
                                        disabled={disabled}
                                        msj="Numero"
                                    />
                                </div>
                                <div className='col-3 mt-3'>
                                    <Field
                                        disabled={true}
                                        name="exportacion"
                                        label="Exportación"
                                        onChange={onCheckboxClicked}
                                        component={renderFieldCheck}
                                    />
                                </div>
                            </div>
                        }
                        {crear === true &&
                            <div className='row mb-2'>
                                <div className='col-3'>
                                    <Field
                                        autoFocus={autoFocus}
                                        disabled={disabled}
                                        name="fechaRegistro"
                                        component={renderDayPickerB}
                                        msj="Fecha Registro"
                                    />
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="serie"
                                        placeholder="Serie..."
                                        className="form-control-find"
                                        loadOptions={listarSeries}
                                        component={AsyncSelectField}
                                        onChange={handleOnChange}
                                        msj="Serie"
                                    />
                                </div>
                                <div className='col-3'>
                                    <div className="form-floating">
                                        <input
                                            placeholder="Numero"
                                            type="number"
                                            autocomplete="off"
                                            name="numero"
                                            className="form-control"
                                            value={numeroSeriePrd}
                                            onChange={e =>  setNumeroSeriePrd(e.target.value) }
                                        />
                                        <label className='form-label' htmlFor="cuenta">
                                            Numero
                                        </label>
                                    </div>
                                </div>
                                <div className='col-3 mt-3'>
                                    <Field
                                        name="exportacion"
                                        label="Exportación"
                                        checked={activo}
                                        onChange={onCheckboxClicked}
                                        component={renderFieldCheckB}
                                    />
                                </div>
                            </div>
                        }
                        {editar === true &&
                            <div className='row mb-2'>
                                <div className='col-3'>
                                    <Field
                                        autoFocus={autoFocus}
                                        disabled={disabled}
                                        name="fechaRegistro"
                                        component={renderDayPickerB}
                                        msj="Fecha Registro"
                                    />
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="serie"
                                        placeholder="Serie"
                                        className="form-control"
                                        component={renderField}
                                        disabled={true}
                                        msj="Serie"
                                    />
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="numero"
                                        placeholder="Numero"
                                        className="form-control"
                                        component={renderNumber}
                                        disabled={true}
                                        msj="Numero"
                                    />
                                </div>
                                <div className='col-3 mt-3'>
                                    <Field
                                        name="exportacion"
                                        label="Exportación"
                                        onChange={onCheckboxClicked}
                                        component={renderFieldCheck}
                                    />
                                </div>
                            </div>
                        }
                        <div className='row mb-2'>
                            {((crear === false && editar == false) || editar === true) &&
                                <div className='col-6'>
                                    <Field
                                        name="nombreCli"
                                        placeholder="Cliente"
                                        className="form-control"
                                        component={renderField}
                                        disabled={true}
                                        msj="Cliente"
                                    />
                                </div>
                            }
                            {(crear === true) &&
                                <div className='col-6'>
                                    <Field
                                        name="cliente_documento"
                                        placeholder="Cliente..."
                                        className="form-control-find"
                                        component={AsyncSelectField}
                                        loadOptions={listarClientes}
                                        msj="Cliente"
                                    />
                                </div>
                            }
                            {(crear === true) &&
                                <div className='col-3 mt-2'>
                                    <button
                                        className='btn mr-2 mb-3 btn-secondary'
                                        onClick={() => cambiarEstadoModal(!estadoModal)}
                                    >
                                        Crear nuevo cliente
                                    </button>
                                </div>
                            }
                            {(editar === true) &&
                                <div className='row mb-2'>
                                    <div className='col-6'>
                                        <Field
                                            name="cliente_documento"
                                            placeholder="Cliente..."
                                            className="form-control-find"
                                            component={AsyncSelectField}
                                            loadOptions={listarClientes}
                                            msj="Clientes"
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                        <h5 className="p-2 bg-secondary title mt-1">Detalle producción</h5>
                        <div>
                            {(crear === true || editar === true) &&
                                <div>
                                    <div className='row mb-2'>
                                        <div className='col-3'>
                                            <Field
                                                name="cantidad"
                                                placeholder="Cantidad"
                                                className="form-control"
                                                component={renderCurrencyFloatTC}
                                                disabled={bloqueo}
                                                msj="Cantidad"
                                            />
                                        </div>
                                        <div className='col-6'>
                                            <Field
                                                name="producto"
                                                placeholder="Producto..."
                                                className="form-control-find"
                                                loadOptions={listarProductos}
                                                component={AsyncSelectField}
                                                msj="Producto"
                                            />
                                        </div>
                                        <div className='col-3'>
                                            <Field
                                                name="costo"
                                                placeholder="Costo"
                                                className="form-control"
                                                component={renderCurrencyFloat}
                                                msj="Costo"
                                            />
                                        </div>
                                    </div>
                                    <div className='row mb-2'>
                                        <div className='col-3'>
                                            <Field
                                                name="unidad"
                                                placeholder="Unidad"
                                                className="form-control"
                                                component={renderNumber}
                                                msj="Unidad"
                                            />
                                        </div>
                                        <div className='col-3'>
                                            <Field
                                                name="fase"
                                                placeholder="Llave..."
                                                className="form-control-find"
                                                loadOptions={listarFases}
                                                component={AsyncSelectField}
                                                msj="Llave"
                                            />
                                        </div>
                                        <div className='col-3'>
                                            <Field
                                                name="insumo"
                                                placeholder="Insumo..."
                                                className="form-control-find"
                                                loadOptions={listarInsumos}
                                                component={AsyncSelectField}
                                                msj="Insumo"
                                            />
                                        </div>
                                        <div className='col-3'>
                                            <Field
                                                name="cantidad_insumo"
                                                placeholder="Cantidad cajas"
                                                className="form-control"
                                                component={renderNumber}
                                                msj="Cantidad cajas"
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        {(crear === true) &&
                            <Contenido>
                                <button
                                    className='btn mr-2 mb-3 btn-primary'
                                    onClick={() => agregarLinea(periodoD)}
                                >
                                    Agregar linea
                                </button>
                            </Contenido>
                        }
                        {(editar === true) &&
                            <Contenido>
                                <button
                                    className='btn mr-2 mb-3 btn-primary'
                                    onClick={nuevaLineaProduccion}
                                >
                                    Agregar linea
                                </button>
                            </Contenido>
                        }
                        <br/>
                        {crear === true &&
                            <div style={estilo}>
                                <DetalleInventario
                                    detalle={detallePro}
                                    eliminarLinea={eliminarLinea}
                                />
                            </div>
                        }
                        {(crear === false && editar === false) &&
                            <div style={estilo}>
                                <DetalleInventarioVer
                                    detalle={detalleProducto}
                                    eliminarLineaProducto={eliminarLineaProducto}
                                />
                            </div>
                        }
                        {editar === true &&
                            <div style={estilo}>
                                <DetalleInventarioMod
                                    detalle={detalleProducto}
                                    eliminarLineaProducto={eliminarLineaProducto}
                                />
                            </div>
                        }
                        <br/>
                        {(crear === true || editar === true) &&
                            <div className='mb-2'>
                                <button
                                    className='btn mr-2 mb-2 btn-primary'
                                    onClick={() => funcionEnvio(periodoD, numeroSeriePrd)}
                                >
                                {editar ? 'Modificar' : 'Guardar ingreso'}
                                </button>
                                <a
                                    href={`/#/produccion/${id_empresa[5]}/ingreso/${id_empresa[7]}`}
                                    className='btn btn-secondary mb-2'
                                >
                                Regresar
                                </a>
                            </div>
                        }
                        {(crear === false && editar === false) &&
                            <div className='mb-2'>
                                <a
                                    href={`/#/produccion/${id_empresa[5]}/ingreso/${id_empresa[7]}`}
                                    className='btn btn-secondary mb-2'
                                >
                                    Regresar
                                </a>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <Modal
                estado={estadoModal}
                cambiarEstado={cambiarEstadoModal}
                titulo="Crear cliente"
                botonCerrar="true"
            >
                <div className="d-flex flex-column flex-1 mx-4">

                    <div className='mb-2 w-25'>
                        <Field
                            autoFocus={autoFocus}
                            name="identificador"
                            placeholder="Identificador"
                            className="form-control"
                            component={renderField}
                            msj="Identificador"
                        />
                    </div>
                    <div className='row'>
                        <div className='mb-2 col-8'>
                            <Field
                                name="nombre"
                                placeholder="Nombre"
                                className="form-control"
                                component={renderField}
                                msj="Nombre"
                            />
                        </div>
                        {obtenerPaises &&
                            <div className='col-4'>
                                <div className="form-floating">
                                    <Field
                                        name="pais"
                                        options={obtenerPaises}
                                        crear={crear}
                                        component={SelectField}
                                        className="form-select"
                                        msj="Pais"
                                    />
                                </div>
                            </div>
                        }
                    </div>

                    <div className='mb-2 w-75'>
                        <Field
                            name="direccion"
                            placeholder="Direccion"
                            className="form-control"
                            component={renderField}
                            msj="Dirección"
                        />
                    </div>
                    <div className='row'>
                        <div className='col-4'>
                            <Field
                                name="telefono"
                                placeholder="Telefono"
                                className="form-control"
                                component={renderField}
                                msj="Telefono"
                            />
                        </div>
                        <div className='col-4'>
                            <Field
                                name="correo"
                                placeholder="Correo electrónico"
                                className="form-control"
                                component={renderField}
                                msj="Correo electronico"
                            />
                        </div>
                        <div className='col-4'>
                            <Field
                                name="dias_credito"
                                placeholder="Dias credito"
                                className="form-control"
                                component={renderNumber}
                                msj="Dias crédito"
                            />
                        </div>
                    </div>
                    <div className='row mt-2'>
                        <div className='col-4'>
                            <Field
                                name="limite_credito"
                                placeholder="Limite credito"
                                className="form-control"
                                component={renderNumber}
                                msj="Limite crédito"
                            />
                        </div>
                        <div className='col-4'>
                            <div className="form-floating">
                                <Field
                                    name="tipo_cliente"
                                    options={tiposCL}
                                    component={SelectField}
                                    className="form-select"
                                    msj="Tipo cliente"
                                />
                            </div>
                        </div>
                    </div>
                    <div className='d-flex flex-row mt-3'>

                        <button
                            className='btn mr-2 mb-3 btn-primary'
                            onClick={() => registroCliente(datos)}
                        >
                            Guardar
                        </button>

                        <button
                            className='btn mr-2 mb-3 btn-secondary'
                            onClick={() => cambiarEstadoModal(false)}
                        >
                            Salir
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

IngresoProduccionForm.propTypes = {
    crear: PropTypes.bool,
    editar: PropTypes.bool,
    bloqueo: PropTypes.bool,
    registroCliente: PropTypes.func,
    obtenerPaises: PropTypes.object,
    fechaInicio: PropTypes.string,
    fechaFinal: PropTypes.string,
    impuesto: PropTypes.object,
    datos: PropTypes.object,
    listarClientes: PropTypes.object,
    detallePro: PropTypes.object,
    eliminarLineaProducto: PropTypes.func,
    leerProductos: PropTypes.func,
    pro: PropTypes.object,
    periodoD: PropTypes.object,
    modulo: PropTypes.object,
    registroProductoInventario: PropTypes.func,
    monedas: PropTypes.object,
    listarProductoServicios: PropTypes.func,
    agregarLinea: PropTypes.func,
    detalleProducto: PropTypes.array,
    listarProductos: PropTypes.object,
    listarFases: PropTypes.object,
    funcionEnvio: PropTypes.func,
    nuevaLineaProduccion: PropTypes.func,
    eliminarLinea: PropTypes.func,
    listarInsumos: PropTypes.func,
    listarSeries: PropTypes.func,
    numeroSerie: PropTypes.func,
};

const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;

export default reduxForm({
    form: 'FormIngresoProduccion',
})(IngresoProduccionForm);
