import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/libros_contables/polizas';
import TrasladoForm from './Traslado';


const ms2p = (state) => {
  return {
    ...state.lc_poliza,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(TrasladoForm);
