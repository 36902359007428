import React, { Component } from 'react';
import Formulario from './SubirPaisesForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";

class SubirPaises extends Component{
    state={
        archivo: null
    }
    
    setArchivo = (archivo) => {
        this.setState({archivo});
    };
    
    subirPaises = (data) => {
        const { subirPaises } = this.props;
        subirPaises( {...data, archivo: null},
        [{ "file": this.state.archivo, "name": 'archivo' },])
    }

    render(){
        const { loader } = this.props;
    
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    onSubmit = {this.subirPaises}
                    setArchivo={this.setArchivo} 
                />
            </LoadMask>
        );
    }
}

export default SubirPaises
