import React, { Component } from 'react';
import Formulario from './FormISR';
import LoadMask from "../../Utils/LoadMask/LoadMask";
const valEmp = __CONFIG__.empresa;

class Isr extends Component{
    state = {
        crear: true,
    }

    componentDidMount = () => {
        const { match, getModulo, listarColaborador, leer,
        limpiarDatos } = this.props;
        const id = match.params.id;
        if (id){
            this.setState({crear: false});
            leer(id);
        }
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        if (valEmp === 'fage'){
            getModulo(modulo[6],modulo[5])
        }else{
            getModulo(modulo[5],modulo[4]);
        }
        listarColaborador();
        limpiarDatos();
    }
    
    render(){
        const { loader, modulo, emp, registroIsr,
            datosColaborador, cargo, tipo, modificarIsr,
            year, fechaI, fechaF } = this.props;
        const { crear } = this.state;
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    crear = {crear}
                    modulo = {modulo}
                    emp = {emp}
                    registroIsr = {registroIsr}
                    cargo = {cargo}
                    tipo = {tipo}
                    modificarIsr = {modificarIsr}
                    datosColaborador = {datosColaborador}
                    year = {year}
                    fechaI = {fechaI}
                    fechaF = {fechaF}
                />
            </LoadMask>
        );
    }
}

export default Isr;