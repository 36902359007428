import { connect } from "react-redux";
import { actions } from "../../../../redux/modules/auto_ventas/ventas/ventasEditar";
import VentasFormEditar from "./VentasFormEditar";

const ms2p = (state) => {
    return {
        ...state.auto_ventas_ventas_ver,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(VentasFormEditar);
