import { handleActions } from 'redux-actions';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';

const MODULO = 'MODULO';
const LOADER = 'LOADER';
const PARAMETRO = 'PARAMETRO';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listarCuentas = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id, search}
    return api.get('/cuentas/empresa_cuentas/',params).then(data=>{
        const list_cuentas = [];
        if(data){
            data.forEach(item=>{
                if (item.tipo === 'A'){
                    list_cuentas.push({
                        value: item.id,
                        label: item.cuenta + ' ' + item.nombre,
                        isDisabled: true
                    })
                }else{
                    list_cuentas.push({
                        value: item.id,
                        label: item.cuenta + ' ' + item.nombre
                    })
                }
            })
        }
        return list_cuentas;
    }).catch((error)=>{
        return [];
    })
}

export const listarCC = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id, search}
    return api.get('/centrocosto/empresa_centro_costo/',params).then(data=>{
        const list_cc = [];
        if(data){
            data.forEach(item=>{
                list_cc.push({
                    value: item.id,
                    label: item.codigo + ' ' + item.descripcion
                })
            })
        }
        return list_cc;
    }).catch((error)=>{
        return [];
    })
}

export const libroMayor = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const data = getStore().form.FormInformes.values;
    let reporte = 'libroMayor';
    if (data === undefined){
        Swal.fire({
            title: 'Libro Mayor',
            text: 'Debe de ingresar rango de fechas',
            type: 'error',
        })
    }else {
        if (data.fechaInicial === undefined && data.fechaFinal === undefined){
            Swal.fire({
                title: 'Libro Mayor',
                text: 'Debe de ingresar rango de fechas',
                type: 'error',
            })
        }else {
            let fechaI = data.fechaInicial
            let fechaF = data.fechaFinal
            let tipo = data.tipo
            let cuenta = '';
            let validarCC = localStorage.getItem('cc');
            if (data.cuenta){
                cuenta = data.cuenta.value    
            }
            if (tipo === undefined){
                tipo = '';
            }
            let cc = '';
            if (data.cc){
                cc = data.cc.value
            }
            let folio = localStorage.getItem('folio');
            const params = { id, reporte, fechaI, fechaF, tipo, cuenta, folio, cc, validarCC };
            dispatch(setLoader(true));
            api.getPdf('/polizas', params).then((response)=>{
                localStorage.removeItem('folio');
                if (response.headers['content-type'] == 'application/pdf'){
                    var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = 'reporte_libro_mayor.pdf';
                    window.open(link, '_blank');
                    return false;
                }
            }).catch((error)=>{
                NotificationManager.error(
                    'Ocurrió un error al generar reporte',
                    'Error',
                    2000
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }    
    }
   
}

export const balanceGeneral = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const data = getStore().form.FormInformes.values;
    let reporte = 'balanceGeneral';
    if (data === undefined){
        Swal.fire({
            title: 'Balance general',
            text: 'Debe de ingresar fecha',
            type: 'error',
        })
    }else {
        if (data.fechaFinal === undefined){
            Swal.fire({
                title: 'Balance general',
                text: 'Debe de ingresar fecha',
                type: 'error',
            })
        }else {
            let tipo = '';
            if (data.tipo === undefined){
                tipo = 'NIVEL';
            }else{
                tipo = data.tipo;
            }
            let fechaF = data.fechaFinal
            let folioBG = localStorage.getItem('folioBG');
            const params = { id, reporte, fechaF, folioBG, tipo };
            dispatch(setLoader(true));
            api.getPdf('/polizas', params).then((response)=>{
                if (response.headers['content-type'] == 'application/pdf'){
                    var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = 'reporte_balance_general.pdf';
                    window.open(link, '_blank');
                    return false;
                }
            }).catch((error)=>{
                NotificationManager.error(
                    'Ocurrió un error al generar reporte',
                    'Error',
                    2000
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }
    }
   
}

export const balanceSaldos = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const data = getStore().form.FormInformes.values;
    let reporte = 'balanceSaldos';
    if (data === undefined){
        Swal.fire({
            title: 'Balance saldos',
            text: 'Debe de ingresar rango de fechas',
            type: 'error',
        })
    }else {
        if (data.fechaInicial === undefined && data.fechaFinal === undefined){
            Swal.fire({
                title: 'Balance saldos',
                text: 'Debe de ingresar rango de fechas',
                type: 'error',
            })
        }else {
            let fechaI = data.fechaInicial
            let fechaF = data.fechaFinal
              
            const params = { id, reporte, fechaI, fechaF };
            dispatch(setLoader(true));
            api.getPdf('/polizas', params).then((response)=>{
                if (response.headers['content-type'] == 'application/pdf'){
                    var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = 'reporte_balance_saldos.pdf';
                    window.open(link, '_blank');
                    return false;
                }
            }).catch((error)=>{
                NotificationManager.error(
                    'Ocurrió un error al generar reporte',
                    'Error',
                    2000
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }    
    }
   
}

export const estadoResultado = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const data = getStore().form.FormInformes.values;
    let reporte = 'estadoResultado';
    if (data === undefined){
        Swal.fire({
            title: 'Estado de Resultados',
            text: 'Debe de ingresar rango de fechas',
            type: 'error',
        })
    }else {
        if (data.fechaInicial === undefined && data.fechaFinal === undefined){
            Swal.fire({
                title: 'Estado de Resultados',
                text: 'Debe de ingresar rango de fechas',
                type: 'error',
            })
        }else {
            let fechaI = data.fechaInicial;
            let fechaF = data.fechaFinal;
            let cuenta = '';
            let tipo = '';
            
            if (data.tipo === undefined){
                tipo = 'NIVEL';
            }else{
                tipo = data.tipo;
            }
            /*if (data.cc){
                cuenta = data.cc.value    
            }*/
            
            let folioER = localStorage.getItem('folioER');
            const params = { id, reporte, fechaI, fechaF, cuenta, folioER, tipo };
            dispatch(setLoader(true));
            api.getPdf('/polizas', params).then((response)=>{
                localStorage.removeItem('folio');
                if (response.headers['content-type'] == 'application/pdf'){
                    var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = 'reporte_libro_mayor.pdf';
                    window.open(link, '_blank');
                    return false;
                }
            }).catch((error)=>{
                NotificationManager.error(
                    'Ocurrió un error al generar reporte',
                    'Error',
                    2000
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }    
    }
   
}


export const reporteCuentas = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const data = getStore().form.FormInformes.values;
    let naturaleza = '';
    if (data === undefined){
        naturaleza = 'TODAS';
    }else{
        naturaleza = data.naturaleza;
    }
    let reporte = 'reporte';
    const params = { id, reporte, naturaleza };
    dispatch(setLoader(true));
    api.getPdf('/cuentas', params).then((response)=>{
        if (response.headers['content-type'] == 'application/pdf'){
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'reporte_cuentas_contables.pdf';
            window.open(link, '_blank');
            return false;
          }
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al generar reporte',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const parametrosEmpresa = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id}
    dispatch(setLoader(true));
    api.get('/empresa/empresa_parametros', params).then((response) => {
        dispatch({type: PARAMETRO, datos: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar parametros',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const libroDiario = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const data = getStore().form.FormInformes.values;
    
    let reporte = 'libroDiario';
    if (data === undefined){
        Swal.fire({
            title: 'Libro Mayor',
            text: 'Debe de ingresar rango de fechas',
            type: 'error',
        })
    }else {
        if (data.fechaInicial === undefined && data.fechaFinal === undefined){
            Swal.fire({
                title: 'Libro Mayor',
                text: 'Debe de ingresar rango de fechas',
                type: 'error',
            })
        }else {
            let fechaI = data.fechaInicial
            let fechaF = data.fechaFinal
            let tipo = data.tipo
            let cuenta = '';
            let validarCC = localStorage.getItem('cc');
            if (data.cuenta){
                cuenta = data.cuenta.value    
            }
            if (tipo === undefined){
                tipo = '';
            }
            let cc = '';
            if (data.cc){
                cc = data.cc.value
            }
            let folio = localStorage.getItem('folio');
            const params = { id, reporte, fechaI, fechaF, tipo, cuenta, folio, cc, validarCC };
            dispatch(setLoader(true));
            api.getPdf('/polizas', params).then((response)=>{
                localStorage.removeItem('folio');
                if (response.headers['content-type'] == 'application/pdf'){
                    var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = 'reporte_libro_mayor.pdf';
                    window.open(link, '_blank');
                    return false;
                }
            }).catch((error)=>{
                NotificationManager.error(
                    'Ocurrió un error al generar reporte',
                    'Error',
                    2000
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }    
    }
   
}

export const excelBalanceSaldos = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const data = getStore().form.FormInformes.values;
    let reporte = 'excelBalanceSaldos';
    if (data === undefined){
        Swal.fire({
            title: 'Balance saldos',
            text: 'Debe de ingresar rango de fechas',
            type: 'error',
        })
    }else {
        if (data.fechaInicial === undefined && data.fechaFinal === undefined){
            Swal.fire({
                title: 'Balance saldos',
                text: 'Debe de ingresar rango de fechas',
                type: 'error',
            })
        }else {
            let fechaI = data.fechaInicial
            let fechaF = data.fechaFinal
              
            const params = { id, reporte, fechaI, fechaF };

            dispatch(setLoader(true));
            api.getExcel('/polizas', params).then((response) => {
        
                const blob = response;
                const fileName = 'balance_de_saldos.xlsx';

                // Descargar el archivo utilizando la función saveAs de file-saver
                const file = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                saveAs(file, fileName);
            
            }).catch(error => {
                if (error === 404) {
                NotificationManager.error(
                    "No se encontraron datos",
                    'Informacion',
                    5000
                    );
                }
                if (error === 500) {
                    NotificationManager.error(
                        'Ocurrió un error al generar reporte',
                        'Error',
                        0
                    );
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
        }
    }
}

export const excelLibroMayor = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const data = getStore().form.FormInformes.values;
    let reporte = 'excelLibroMayor';
    if (data === undefined){
        Swal.fire({
            title: 'Libro Mayor',
            text: 'Debe de ingresar rango de fechas',
            type: 'error',
        })
    }else {
        if (data.fechaInicial === undefined && data.fechaFinal === undefined){
            Swal.fire({
                title: 'Libro Mayor',
                text: 'Debe de ingresar rango de fechas',
                type: 'error',
            })
        }else {
            let fechaI = data.fechaInicial
            let fechaF = data.fechaFinal
            let tipo = data.tipo
            let cuenta = '';
            let validarCC = localStorage.getItem('cc');
            if (data.cuenta){
                cuenta = data.cuenta.value    
            }
            if (tipo === undefined){
                tipo = '';
            }
            let cc = '';
            if (data.cc){
                cc = data.cc.value
            }
            const params = { id, reporte, fechaI, fechaF, tipo, cuenta, cc, validarCC };

            dispatch(setLoader(true));
            api.getExcel('/polizas', params).then((response) => {
        
                const blob = response;
                const fileName = 'libro_mayor.xlsx';

                // Descargar el archivo utilizando la función saveAs de file-saver
                const file = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                saveAs(file, fileName);
            
            }).catch(error => {
                if (error === 404) {
                NotificationManager.error(
                    "No se encontraron datos",
                    'Informacion',
                    5000
                    );
                }
                if (error === 500) {
                    NotificationManager.error(
                        'Ocurrió un error al generar reporte',
                        'Error',
                        0
                    );
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
        }
    }
}

export const excelBalanceG = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const data = getStore().form.FormInformes.values;
    let reporte = 'excelBalanceGeneral';
    if (data === undefined){
        Swal.fire({
            title: 'Balance general',
            text: 'Debe de ingresar fecha',
            type: 'error',
        })
    }else {
        if (data.fechaFinal === undefined){
            Swal.fire({
                title: 'Balance general',
                text: 'Debe de ingresar fecha',
                type: 'error',
            })
        }else {
            let tipo = '';
            if (data.tipo === undefined){
                tipo = 'NIVEL';
            }else{
                tipo = data.tipo;
            }
            let fechaF = data.fechaFinal
            let folioBG = localStorage.getItem('folioBG');
            const params = { id, reporte, fechaF, folioBG, tipo };
            dispatch(setLoader(true));
            api.getExcel('/polizas', params).then((response) => {
        
            const blob = response;
            const fileName = 'Balance_General.xlsx';
        
            // Descargar el archivo utilizando la función saveAs de file-saver
            const file = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(file, fileName);
        
            }).catch(error => {
            if (error === 404) {
                NotificationManager.error(
                "No se encontraron datos",
                'Informacion',
                5000
                );
            }
            if (error === 500) {
                NotificationManager.error(
                'Ocurrió un error al generar reporte',
                'Error',
                0
                );
            }})
            .finally(() => {
                dispatch(setLoader(false));
            });
        }
    }
}

export const excelEstadoResultado = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const data = getStore().form.FormInformes.values;
    let reporte = 'excelEstadoResultado';
    if (data === undefined){
        Swal.fire({
            title: 'Estado de Resultados',
            text: 'Debe de ingresar rango de fechas',
            type: 'error',
        })
    }else {
        if (data.fechaInicial === undefined && data.fechaFinal === undefined){
            Swal.fire({
                title: 'Estado de Resultados',
                text: 'Debe de ingresar rango de fechas',
                type: 'error',
            })
        }else {
            let fechaI = data.fechaInicial;
            let fechaF = data.fechaFinal;
            let cuenta = '';
            let tipo = '';
            
            if (data.tipo === undefined){
                tipo = 'NIVEL';
            }else{
                tipo = data.tipo;
            }
            /*if (data.cc){
                cuenta = data.cc.value    
            }*/
            
            let folioER = localStorage.getItem('folioER');
            const params = { id, reporte, fechaI, fechaF, cuenta, folioER, tipo };
            dispatch(setLoader(true));
            api.getExcel('/polizas', params).then((response) => {
        
            const blob = response;
            const fileName = 'Estado_Resultado.xlsx';
        
            // Descargar el archivo utilizando la función saveAs de file-saver
            const file = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(file, fileName);
        
            }).catch(error => {
            if (error === 404) {
                NotificationManager.error(
                "No se encontraron datos",
                'Informacion',
                5000
                );
            }
            if (error === 500) {
                NotificationManager.error(
                'Ocurrió un error al generar reporte',
                'Error',
                0
                );
            }})
            .finally(() => {
                dispatch(setLoader(false));
            });
        }    
    }
   
}

export const actions = {
    listarCuentas,
    listarCC,
    libroMayor,
    balanceGeneral,
    balanceSaldos,
    reporteCuentas,
    estadoResultado,
    getModulo,
    parametrosEmpresa,
    libroDiario,
    excelBalanceSaldos,
    excelLibroMayor,
    excelBalanceG,
    excelEstadoResultado
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [PARAMETRO]: (state, { datos }) => {
        return {
            ...state,
            datos,
        };
    },
};

export const initialState = {
    loader: false,
    modulo: [],
    datos: [],
};

export default handleActions(reducers, initialState)