import React, { Component } from 'react';
import Formulario from './FormSeleccionarPeriodo';
import LoadMask from "../../Utils/LoadMask/LoadMask";

class SeleccionarPeriodo extends Component{

    componentDidMount = () => {
        const { periodosList } = this.props;
        periodosList();
    }
    render(){
        const { periodoL, calcularCosto, loader, leerPeriodoD, periodoD } = this.props;
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    periodoL = {periodoL}
                    calcularCosto = {calcularCosto}
                    leerPeriodoD = {leerPeriodoD}
                    periodoD = {periodoD}
                />
            </LoadMask>
        );
    } 
}

export default SeleccionarPeriodo;