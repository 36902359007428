import React from "react";
import styled from "styled-components";
import { FicInput } from "../../Utils/FormInputComponents";
import Banner from "../common/Banner";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { validar } from "../../components/ValidarNit";
import GenBtn from "../../components/GenBtn";
import InputsContainer from "../common/InputsContainer";
import { getEntry, editar } from "./actions";
import { withRouter } from "react-router";

const idName = "aseguradoraId"

const schema = yup.object({
    nombre: yup.string().required("El nombre es requerido"),
});

function Editar({ history, ...props }) {
    const params = props.match.params;
    const vehiculoId = params[idName];

    const { control, reset, handleSubmit } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: async () => {
            const formData = await getEntry(vehiculoId);
            // setLoadingState(false);
            return formData;
        },
    });

    const onSubmit = (data) => {
        const shouldGoBack = editar(data, vehiculoId);
        if (shouldGoBack) {
            history.goBack();
        }
    };

    return (
        <React.Fragment>
            <Banner>Editar</Banner>
            <form onSubmit={handleSubmit(onSubmit)}>
                <InputsContainer>
                    <FicInput control={control} label="Nombre" name="nombre" />
                </InputsContainer>
                <GenBtn style={{ margin: "1rem 0" }} type="submit">
                    Guardar
                </GenBtn>
            </form>
        </React.Fragment>
    );
}

export default withRouter(Editar);
