import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import NavbarModulo from '../../components/NavbarModulo';
import NavbarModuloLF from '../../components/NavbarModuloFage';
import classNames from 'classnames';
import Grid from '../../Utils/GridLiquidacionLaboral';
import { standardActions } from '../../Utils/GridLiquidacionLaboral/StandardActions';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import DatosNomina from './ListDatosNomina';
import DatosSueldo from './ListDatosSueldo';
import VerDatosSueldo from './ListDatosNominaVer';
import styled from 'styled-components';
import Modal from '../../components/Modal';
const valEmp = __CONFIG__.empresa;

import {
    AsyncSelectFieldIn,
    renderDayPickerB,
    SelectField,
    renderCurrencyFloat,
    renderField,
    renderNumber
} from "../../Utils/renderField/renderField";

function LiquidacionForm(props) {
    const { modulo, emp, preGrabar, datosColaborador,
        fechaAlta, calcularDatos, salario, boni, idColaborador,
        agregarPrestacion, prest, cargo, confirmar,
        crear, editar, lectura, detalleLiq, recalcularDatos, eliminar,
        guardarPrestacion, preGrabarMod, confirmarMod, datosNomina, datosNom,
        promedio, promedioHora, promedioComision, calcularSueldo, nominaSueldo,
        salarioLiquido, totalPrestamo, detalleNominaSueldo, calcularSueldoMod,
        nominaSueldoMod } = props;
    let titulo = 'Liquidación laboral';
    
    let ruta = window.location.href;
    let id_emp = ruta.split('/');

    const [dias, setDias] = useState(0);
    const [diasAg, setDiasAg] = useState(0);
    const [diasBono, setDiasBono] = useState(0);
    const [diasVac, setDiasVac] = useState(0);
    const [diasSueldo, setDiasSueldo] = useState(0);
    
    const [fechaAgui, setFechaAgui] = useState('');
    const [fechaBono, setFechaBono] = useState('');
    const [fechaTerminacion, setFechaTerminacion] = useState('');

    const [prestacion, setPrestacion] = useState('');
    const [monto, setMonto] = useState(0.00);
    const [prestacionEd, setPrestacionEd] = useState('');
    const [montoEd, setMontoEd] = useState(0.00);

    const [estadoModalPrestacion, cambiarEstadoModalPrestacion] = useState(false);
    const [estadoModalPrestacionEd, cambiarEstadoModalPrestacionEd] = useState(false);
    
    const [editarD, setEditarD] = useState(editar);
    const [editarAg, setEditarAg] = useState(editar);
    const [editarBo, setEditarBo] = useState(editar);
    const [editarVa, setEditarVa] = useState(editar);
    const [editarSuel, setEditarSuel] = useState(editar);

    const [fechaIngreso, setFechaIngreso] = useState('');
    const [fechaTerm, setFechaTerm] = useState('');
    const [colaboradorId, setColaboradorID] = useState('');
    const [fechaSalario, setFechaSalario] = useState('');
    const [fechaVacaciones, setFechaVacaciones] = useState('');
    
    const handleOnChangeEmp = value => {
        datosColaborador(value.value);
    };

    const calcularDias = (value) => {
        if (editar === true){
            const fechaInicial = moment(fechaIngreso);
            const fechaFinal = moment(value);
            setFechaTerminacion(value);
            if (fechaFinal.isValid() && fechaFinal.isAfter(fechaInicial)) {
                setEditarD(false)
                const cantidadDias = fechaFinal.diff(fechaInicial, 'days');
                if (cantidadDias >= 0) {
                    setDias(parseInt(cantidadDias)+1);
                    
                    setEditarBo(false)
                    if (fechaIngreso > fechaBono){
                        setFechaBono(fechaIngreso)
                        const fechaFinalB = moment(value);
                        const cantidadDiasB = fechaFinalB.diff(fechaIngreso, 'days');
                        setDiasBono(parseInt(cantidadDiasB)+1);
                    } else {
                        setFechaBono(fechaBono)
                        const fechaFinalB = moment(value);
                        const cantidadDiasB = fechaFinalB.diff(fechaBono, 'days');
                        setDiasBono(parseInt(cantidadDiasB)+1);
                    }
                    
                    setEditarAg(false)
                    if (fechaIngreso > fechaAgui){
                        setFechaAgui(fechaIngreso)
                        const fechaFinalA = moment(value);
                        const cantidadDiasA = fechaFinalA.diff(fechaIngreso, 'days')
                        setDiasAg(parseInt(cantidadDiasA)+1);
                        datosNomina(colaboradorId, id_emp, value);
                    }else{
                        setFechaAgui(fechaAgui)
                        const fechaFinalA = moment(value);
                        const cantidadDiasA = fechaFinalA.diff(fechaAgui, 'days')
                        setDiasAg(parseInt(cantidadDiasA)+1);
                        datosNomina(colaboradorId, id_emp, value);
                    }

                    if (fechaSalario) {
                        setEditarSuel(false)
                        const fechaInicial = moment(fechaSalario);
                        const fechaFinal = moment(value);
                        if (fechaFinal.isValid() && fechaFinal.isAfter(fechaInicial)) {
                            const cantidadDiasSueldo = fechaFinal.diff(fechaInicial, 'days');
                            if (cantidadDiasSueldo >= 0) {
                                setDiasSueldo(parseInt(cantidadDiasSueldo)+1);
                                calcularSueldoMod(colaboradorId, id_emp, value, parseInt(cantidadDiasSueldo)+1);
                            }
                        }
                    }

                    setEditarVa(false)
                    const fechaInicial = moment(value);
                    const fechaFinal = moment(fechaVacaciones);
                    if (fechaFinal.isValid() && fechaFinal.isAfter(fechaInicial)) {
                        const cantidadDias = fechaFinal.diff(fechaInicial, 'days');
                        if (cantidadDias >= 0) {
                            const cantidadDiasCalcular = cantidadDias + 1;
                            let diasVacc = parseFloat(parseFloat((15/365))*parseFloat(cantidadDiasCalcular)).toFixed(2);
                            setDiasVac(diasVacc);
                        }
                    }
                }
            }
        }else{
            if (fechaAlta) {
                const fechaInicial = moment(fechaAlta);
                const fechaFinal = moment(value);
                setFechaTerminacion(value);
                if (fechaFinal.isValid() && fechaFinal.isAfter(fechaInicial)) {
                    const cantidadDias = fechaFinal.diff(fechaInicial, 'days');
                    if (cantidadDias >= 0) {
                        setDias(parseInt(cantidadDias)+1);

                        //Fecha bono
                        const fechaAnioAnteriorB = fechaFinal.subtract(1, 'year');
                        const diaB = 1;
                        const mesB = 7;
                        fechaAnioAnteriorB.set({
                            date: diaB,
                            month: mesB - 1, // Los meses en Moment.js comienzan desde 0 (enero) hasta 11 (diciembre)
                        });    
                        const fechaBono = fechaAnioAnteriorB.format('YYYY-MM-DD');
                        if (fechaAlta > fechaBono){
                            setFechaBono(fechaAlta)
                            const fechaFinalB = moment(value);
                            const cantidadDiasB = fechaFinalB.diff(fechaAlta, 'days');
                            setDiasBono(parseInt(cantidadDiasB)+1);

                        } else {
                            setFechaBono(fechaBono)
                            const fechaFinalB = moment(value);
                            const cantidadDiasB = fechaFinalB.diff(fechaBono, 'days');
                            setDiasBono(parseInt(cantidadDiasB)+1);
                        }
                        
                        // Fecha Aguinaldo
                        const fechaAnioAnterior = fechaFinal;
                        const dia = 1;
                        const mes = 12;
                        
                        fechaAnioAnterior.set({
                            date: dia,
                            month: mes - 1, // Los meses en Moment.js comienzan desde 0 (enero) hasta 11 (diciembre)
                        });
                        const fechaAguinaldo = fechaAnioAnterior.format('YYYY-MM-DD');
                        if (fechaAlta > fechaAguinaldo){
                            setFechaAgui(fechaAlta)
                            const fechaFinalA = moment(value);
                            const cantidadDiasA = fechaFinalA.diff(fechaAlta, 'days')
                            setDiasAg(parseInt(cantidadDiasA)+1);
                            datosNomina(idColaborador, id_emp, value);
                        }else{
                            setFechaAgui(fechaAguinaldo)
                            const fechaFinalA = moment(value);
                            const cantidadDiasA = fechaFinalA.diff(fechaAguinaldo, 'days')
                            setDiasAg(parseInt(cantidadDiasA)+1);
                            datosNomina(idColaborador, id_emp, value);
                        }
                    }
                }
            }
        }
    };

    const calcularDiasAguinaldo = (value) => {
        if (editar === true){
            setEditarAg(false)
            setFechaAgui(value);
            const fechaInicial = moment(value);
            const fechaFinal = moment(fechaTerm);
            if (fechaFinal.isValid() && fechaFinal.isAfter(fechaInicial)) {
                const cantidadDias = fechaFinal.diff(fechaInicial, 'days');
                if (cantidadDias >= 0) {
                    setDiasAg(parseInt(cantidadDias)+1);
                }
            }
        }else{
            if (fechaAlta) {
                setFechaAgui(value);
                const fechaInicial = moment(value);
                const fechaFinal = moment(fechaFinal);
                if (fechaFinal.isValid() && fechaFinal.isAfter(fechaInicial)) {
                    const cantidadDias = fechaFinal.diff(fechaInicial, 'days');
                    if (cantidadDias >= 0) {
                        setDiasAg(parseInt(cantidadDias)+1);
                    }
                }
            }
        }
    };

    const calcularDiasBono = (value) => {
        if (editar === true){
            setEditarBo(false)
            setFechaBono(value)
            const fechaInicial = moment(value);
            const fechaFinal = moment(fechaTerm);
            if (fechaFinal.isValid() && fechaFinal.isAfter(fechaInicial)) {
                const cantidadDias = fechaFinal.diff(fechaInicial, 'days');
                if (cantidadDias >= 0) {
                    setDiasBono(parseInt(cantidadDias)+1);
                }
            }
        }else{
            if (fechaAlta) {
                setFechaBono(value)
                const fechaInicial = moment(value);
                const fechaFinal = moment(fechaFinal);
                if (fechaFinal.isValid() && fechaFinal.isAfter(fechaInicial)) {
                    const cantidadDias = fechaFinal.diff(fechaInicial, 'days');
                    if (cantidadDias >= 0) {
                        setDiasBono(parseInt(cantidadDias)+1);
                    }
                }
            }
        }
    };

    const calcularDiasVac = (value) => {
        if (editar === true){
            setEditarVa(false)
            const fechaInicial = moment(value);
            const fechaFinal = moment(fechaTerm);
            if (fechaFinal.isValid() && fechaFinal.isAfter(fechaInicial)) {
                const cantidadDias = fechaFinal.diff(fechaInicial, 'days');
                if (cantidadDias >= 0) {
                    const cantidadDiasCalcular = cantidadDias + 1;
                    let diasVacc = parseFloat(parseFloat((15/365))*parseFloat(cantidadDiasCalcular)).toFixed(2);
                    setDiasVac(diasVacc);
                }
            }
        }else{
            if (fechaAlta) {
                const fechaInicial = moment(value);
                const fechaFinal = moment(fechaTerminacion);
                if (fechaFinal.isValid() && fechaFinal.isAfter(fechaInicial)) {
                    let cantidadDias = fechaFinal.diff(fechaInicial, 'days');
                    if (cantidadDias >= 0) {
                        const cantidadDiasCalcular = cantidadDias + 1;
                        let diasVacc = parseFloat(parseFloat((15/365))*parseFloat(cantidadDiasCalcular)).toFixed(2);
                        setDiasVac(diasVacc);
                    }
                }
            }
        }
    };

    const calcularDiasSueldo = (value) => {
        if (editar === true){
            setEditarSuel(false)
            const fechaInicial = moment(value);
            const fechaFinal = moment(fechaTerm);
            if (fechaFinal.isValid() && fechaFinal.isAfter(fechaInicial)) {
                const cantidadDiasSueldo = fechaFinal.diff(fechaInicial, 'days');
                if (cantidadDiasSueldo >= 0) {
                    setDiasSueldo(parseInt(cantidadDiasSueldo)+1);
                    calcularSueldoMod(colaboradorId, id_emp, value, parseInt(cantidadDiasSueldo)+1);
                }
            }
        }else{
            if (fechaAlta) {
                const fechaInicial = moment(value);
                const fechaFinal = moment(fechaTerminacion);
                if (fechaFinal.isValid() && fechaFinal.isAfter(fechaInicial)) {
                    const cantidadDiasSueldo = fechaFinal.diff(fechaInicial, 'days');
                    if (cantidadDiasSueldo >= 0) {
                        setDiasSueldo(parseInt(cantidadDiasSueldo)+1);
                        calcularSueldo(idColaborador, id_emp, value, parseInt(cantidadDiasSueldo)+1);
                    }
                }
            }
        }
    };


    const motivo = [
        { label: "Despido", value: 1 },
        { label: "Renuncia", value: 2 },
        { label: "Despido justificado", value: 3 },
        { label: "Renuncia con indemnización", value: 4 },
        { label: "Periodo prueba", value: 5 },
    ];

    const estilo = {
        overflow: "auto",
        width: "800px",
    };

    const tipo = [
        { label: "INGRESO", value: "ING" },
        { label: "DESCUENTO", value: "DES" }
    ];

    const datosNominaTabla = {
        overflow: "auto",
        width: "80%",
    };

    const datosNominaTablaSueldo = {
        overflow: "auto",
        width: "100%",
    };

    return (
        <div>
            {valEmp === 'fage' &&
                <NavbarModuloLF
                    modulo={modulo}
                    titulo={titulo}
                />
            }
            {valEmp === '' &&
                <NavbarModulo
                    modulo={modulo}
                    titulo={titulo}
                />
            }
           
            <br />
            <div className="mb-4 card card-small text-white bg-dark mb-3">
                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                    <div className="d-flex flex-column flex-1 mx-4">
                        <div className='mb-2'>
                            {/*<div className='row mb-2'>
                                <div className='col-6'>
                                    <Field
                                        name="pais"
                                        placeholder="Pais..."
                                        className="form-control-find"
                                        component={AsyncSelectField}
                                        loadOptions={listarPaises}
                                        onChange={handleOnChange}
                                        msj="Pais"
                                    />
                                </div>
                            </div>*/}
                            {crear === true &&
                                <div className='row mb-2'>
                                    <div className='col-6'>
                                        <Field
                                            name="colaborador"
                                            placeholder="Colaborador..."
                                            className="form-control-find"
                                            component={AsyncSelectFieldIn}
                                            loadOptions={emp}
                                            onChange={handleOnChangeEmp}
                                            msj="Colaborador"
                                        />
                                    </div>
                                    <div className='col-6'>
                                        <div className="form-floating">
                                            <input
                                                className = {classNames('form-control')}
                                                type="text"
                                                value = {cargo}
                                                disabled = {true}
                                            />
                                            <label className='form-label'>Puesto</label>
                                        </div>
                                    </div>
                                </div>
                            }
                            {((crear === false && editar === false) || (editar === true)) && 
                                <div className='row mb-2'>
                                    <div className='col-6'>
                                        <Field
                                            name="nombre"
                                            placeholder="Nombre"
                                            className="form-control"
                                            component={renderField}
                                            disabled={true}
                                            msj="Nombre"
                                        />
                                    </div>
                                    <div className='col-6'>
                                        <Field
                                            name="puesto"
                                            placeholder="Puesto"
                                            className="form-control"
                                            component={renderField}
                                            disabled={true}
                                            msj="Puesto"
                                        />
                                    </div>
                             </div>
                            }
                            {crear === true &&
                                <div className='row mb-2'>
                                    <div className='col-3'>
                                        <div className="form-floating">
                                            <Field
                                                name="motivo"
                                                options={motivo}
                                                dato={0}
                                                component={SelectField}
                                                className="form-select"
                                                msj="Motivo"
                                            />
                                        </div>
                                    </div>
                                    <div className='col-3'>
                                        <div className="form-floating">
                                            <input
                                                className={classNames('form-control')}
                                                type="date"
                                                disabled={true}
                                                value={fechaAlta}
                                            />
                                            <label className='form-label'>Fecha ingreso</label>
                                        </div>
                                    </div>
                                    <div className='col-3'>
                                        <Field
                                            name="fecha_baja"
                                            component={renderDayPickerB}
                                            msj="Fecha de terminación"
                                            onChange={(e) => calcularDias(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-2'>
                                        <div className="form-floating">
                                            <NumberFormat
                                                className={classNames('form-control')}
                                                decimalScale={0}
                                                disabled={true}
                                                placeholder="Dias laborados"
                                                fixedDecimalScale
                                                thousandSeparator
                                                value={dias}
                                                name="total"
                                            />
                                            <label className='form-label' htmlFor="total">
                                                Dias laborados
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            }
                            {crear === false && editar === false && 
                                <div className='row mb-2'>
                                    <div className='col-3'>
                                        <div className="form-floating">
                                            <Field
                                                name="motivo"
                                                options={motivo}
                                                dato={parseInt(lectura.motivo)}
                                                component={SelectField}
                                                className="form-select"
                                                msj="Motivo"
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-3'>
                                        <Field
                                            name="fecha_alta"
                                            component={renderDayPickerB}
                                            msj="Fecha ingreso"
                                            disabled={true}
                                        />
                                    </div>
                                    <div className='col-3'>
                                        <Field
                                            name="fecha_terminacion"
                                            component={renderDayPickerB}
                                            msj="Fecha de terminación"
                                            disabled={true}
                                        />
                                    </div>
                                    <div className='col-2'>
                                        <Field
                                            name="dias_laborados"
                                            placeholder="Dias laborados"
                                            className="form-control"
                                            component={renderNumber}
                                            disabled={true}
                                            msj="Dias laborados"
                                        />
                                    </div>
                                </div>

                            }
                            {editar === true && 
                                <div className='row mb-2'>
                                    <div className='col-3'>
                                        <div className="form-floating">
                                            <Field
                                                name="motivo"
                                                options={motivo}
                                                dato={parseInt(lectura.motivo)}
                                                component={SelectField}
                                                className="form-select"
                                                msj="Motivo"
                                                disabled={false}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-3'>
                                        <Field
                                            name="fecha_alta"
                                            component={renderDayPickerB}
                                            msj="Fecha ingreso"
                                            disabled={true}
                                        />
                                    </div>
                                    <div className='col-3'>
                                        <Field
                                            name="fecha_terminacion"
                                            component={renderDayPickerB}
                                            msj="Fecha de terminación"
                                            onChange={(e) => {
                                                setFechaIngreso(lectura.fecha_alta);
                                                setDiasVac(lectura.dias_vacaciones);
                                                setColaboradorID(lectura.colaborador);
                                                setFechaTerm(lectura.fecha_terminacion);
                                                setFechaBono(lectura.fecha_bono);
                                                setFechaAgui(lectura.fecha_aguinaldo);
                                                setDiasVac(lectura.dias_vacaciones);
                                                setFechaVacaciones(lectura.fecha_vacaciones);
                                                setFechaSalario(lectura.fecha_salario_pendiente);
                                                calcularDias(e.target.value);
                                            }}
                                            disabled={false}
                                        />
                                    </div>
                                    <div className='col-2'>
                                        <div className="form-floating">
                                            <NumberFormat
                                                className={classNames('form-control')}
                                                decimalScale={0}
                                                disabled={true}
                                                placeholder="Dias laborados"
                                                fixedDecimalScale
                                                thousandSeparator
                                                value={editarD === true ? lectura.dias_laborados : dias}
                                                name="dias_laborados"
                                            />
                                            <label className='form-label' htmlFor="Dias laborados">
                                            Dias laborados
                                            </label>
                                        </div>
                                    </div>
                                </div>

                            }
                            {crear === true &&
                                <div className='row mb-2'>
                                    <div className='col-3'>
                                        <div className="form-floating">
                                            <NumberFormat
                                                className={classNames('form-control')}
                                                decimalScale={2}
                                                disabled={true}
                                                placeholder="Salario ordinario"
                                                fixedDecimalScale
                                                thousandSeparator
                                                value={salario}
                                                name="salario"
                                            />
                                            <label className='form-label' htmlFor="total">
                                                Salario ordinario actual
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-3'>
                                        <div className="form-floating">
                                            <NumberFormat
                                                className={classNames('form-control')}
                                                decimalScale={2}
                                                disabled={true}
                                                placeholder="Salario ordinario"
                                                fixedDecimalScale
                                                thousandSeparator
                                                value={boni}
                                                name="bonificacion"
                                            />
                                            <label className='form-label' htmlFor="total">
                                                Bonificación
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            }
                            {((crear === false && editar === false) || (editar === true)) && 
                                <div className='row mb-2'>
                                    <div className='col-3'>
                                        <Field
                                            name="salario_ordinario"
                                            placeholder="Salario ordinario actual"
                                            className="form-control"
                                            component={renderCurrencyFloat}
                                            disabled={true}
                                            msj="Salario ordinario actual"
                                        />
                                    </div>
                                    <div className='col-3'>
                                        <Field
                                            name="bonificacion"
                                            placeholder="Bonificación"
                                            className="form-control"
                                            component={renderCurrencyFloat}
                                            disabled={true}
                                            msj="Bonificación"
                                        />
                                    </div>
                                </div>
                            }
                            {crear === true &&
                                <div className='row mb-2'>
                                    <div className='col-3'>
                                        <div className="form-floating">
                                            <NumberFormat
                                                className={classNames('form-control')}
                                                decimalScale={2}
                                                disabled={true}
                                                placeholder="Salario ordinario promedio"
                                                fixedDecimalScale
                                                thousandSeparator
                                                value={promedio}
                                                name="salario_ordinario"
                                            />
                                            <label className='form-label' htmlFor="total">
                                                Salario ordinario promedio
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-3'>
                                        <div className="form-floating">
                                            <NumberFormat
                                                className={classNames('form-control')}
                                                decimalScale={2}
                                                disabled={true}
                                                placeholder="Salario extraordinario promedio"
                                                fixedDecimalScale
                                                thousandSeparator
                                                value={promedioHora}
                                                name="salario_ext_ordinario"
                                            />
                                            <label className='form-label' htmlFor="total">
                                                Salario extraordinario promedio
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-3'>
                                        <div className="form-floating">
                                            <NumberFormat
                                                className={classNames('form-control')}
                                                decimalScale={2}
                                                disabled={true}
                                                placeholder="Comisiones promedio"
                                                fixedDecimalScale
                                                thousandSeparator
                                                value={promedioComision}
                                                name="salario_ext_ordinario"
                                            />
                                            <label className='form-label' htmlFor="total">
                                                Comisiones promedio
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            }
                            {crear === false && editar === false && 
                                <div className='row mb-2'>
                                    <div className='col-3'>
                                        <Field
                                            name="salario_promedio"
                                            placeholder="Salario ordinario promedio"
                                            className="form-control"
                                            component={renderCurrencyFloat}
                                            msj="Salario ordinario promedio"
                                            disabled={true}
                                        />
                                    </div>
                                    <div className='col-3'>
                                        <Field
                                            name="salario_ext_promedio"
                                            placeholder="Salario extraordinario promedio"
                                            className="form-control"
                                            component={renderCurrencyFloat}
                                            msj="Salario extraordinario promedio"
                                            disabled={true}
                                        />
                                    </div>
                                    <div className='col-3'>
                                        <Field
                                            name="comision_promedio"
                                            placeholder="Comisiones promedio"
                                            className="form-control"
                                            component={renderCurrencyFloat}
                                            msj="Comisiones promedio"
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            }
                            {editar === true &&
                                <div className='row mb-2'>
                                    <div className='col-3'>
                                        <Field
                                            name="salario_promedio"
                                            placeholder="Sueldo base"
                                            className="form-control"
                                            component={renderCurrencyFloat}
                                            msj="Salario ordinario promedio"
                                        />
                                    </div>
                                    <div className='col-3'>
                                        <Field
                                            name="salario_ext_promedio"
                                            placeholder="Sueldo base"
                                            className="form-control"
                                            component={renderCurrencyFloat}
                                            msj="Salario extraordinario promedio"
                                        />
                                    </div>
                                    <div className='col-3'>
                                        <Field
                                            name="comision_promedio"
                                            placeholder="Sueldo base"
                                            className="form-control"
                                            component={renderCurrencyFloat}
                                            msj="Comisiones promedio"
                                        />
                                    </div>
                                </div>
                            }
                            {crear === true &&
                                <div className='mb-4'>
                                    <Contenido>
                                        <h4 className='mt-4'>Cálculo de promedio</h4>
                                        <div style={datosNominaTabla}>
                                            <DatosNomina
                                                data={datosNom}
                                            />
                                        </div>
                                    </Contenido>
                                </div>
                            }
                            {editar === true && crear === false && datosNom.length > 0 &&
                                <div className='mb-4'>
                                    <Contenido>
                                        <h4 className='mt-4'>Cálculo de promedio</h4>
                                        <div style={datosNominaTabla}>
                                            <DatosNomina
                                                data={datosNom}
                                            />
                                        </div>
                                    </Contenido>
                                </div>
                            }
                            {crear === true &&
                                <div className='row mb-2'>
                                    <div className='col-3'>
                                        <div className="form-floating">
                                            <input
                                                className={classNames('form-control')}
                                                type="date"
                                                value={fechaAgui}
                                                onChange={(e) => calcularDiasAguinaldo(e.target.value)}
                                            />
                                            <label className='form-label'>Fecha de aguinaldo</label>
                                        </div>
                                    </div>
                                    <div className='col-2'>
                                        <div className="form-floating">
                                            <NumberFormat
                                                className={classNames('form-control')}
                                                decimalScale={0}
                                                disabled={true}
                                                placeholder="Dias de aguinaldo"
                                                fixedDecimalScale
                                                thousandSeparator
                                                value={diasAg}
                                                name="dias_aguinaldo"
                                            />
                                            <label className='form-label' htmlFor="total">
                                                Dias de aguinaldo
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-3'>
                                        <div className="form-floating">
                                            <input
                                                className={classNames('form-control')}
                                                type="date"
                                                onChange={(e) => calcularDiasBono(e.target.value)}
                                                value={fechaBono}
                                            />
                                            <label className='form-label'>Fecha de bono</label>
                                        </div>
                                    </div>
                                    <div className='col-2'>
                                        <div className="form-floating">
                                            <NumberFormat
                                                className={classNames('form-control')}
                                                decimalScale={0}
                                                disabled={true}
                                                placeholder="Dias de bono"
                                                fixedDecimalScale
                                                thousandSeparator
                                                value={diasBono}
                                                name="dias_bono"
                                            />
                                            <label className='form-label' htmlFor="total">
                                                Dias de bono
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            }
                            {crear === false && editar === false && 
                                <div className='row mb-2'>
                                    <div className='col-3'>
                                        <Field
                                            name="fecha_aguinaldo"
                                            component={renderDayPickerB}
                                            msj="Fecha de aguinaldo"
                                            disabled={true}
                                        />
                                    </div>
                                    <div className='col-2'>
                                        <Field
                                            name="dias_aguinaldo"
                                            placeholder="Dias aguinaldo"
                                            className="form-control"
                                            component={renderNumber}
                                            disabled={true}
                                            msj="Dias de aguinaldo"
                                        />
                                    </div>
                                    <div className='col-3'>
                                        <Field
                                            name="fecha_bono"
                                            component={renderDayPickerB}
                                            msj="Fecha de bono"
                                            disabled={true}
                                        />
                                    </div>
                                    <div className='col-2'>
                                        <Field
                                            name="dias_bono"
                                            placeholder="Dias aguinaldo"
                                            className="form-control"
                                            component={renderNumber}
                                            disabled={true}
                                            msj="Dias de bono"
                                        />
                                    </div>
                                </div>

                            }
                            {editar === true && 
                                <div className='row mb-2'>
                                    <div className='col-3'>
                                        <Field
                                            name="fecha_aguinaldo"
                                            component={renderDayPickerB}
                                            msj="Fecha de aguinaldo"
                                            disabled={false}
                                            onChange={(e) => {
                                                setFechaTerm(lectura.fecha_terminacion);
                                                calcularDiasAguinaldo(e.target.value);
                                            }}
                                        />
                                    </div>
                                    <div className='col-2'>
                                        <div className="form-floating">
                                            <NumberFormat
                                                className={classNames('form-control')}
                                                decimalScale={0}
                                                disabled={true}
                                                placeholder="Dias de aguinaldo"
                                                fixedDecimalScale
                                                thousandSeparator
                                                value={editarAg === true ? lectura.dias_aguinaldo : diasAg}
                                                name="dias_aguinaldo"
                                            />
                                            <label className='form-label' htmlFor="Dias de aguinaldo">
                                            Dias de aguinaldo
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-3'>
                                        <Field
                                            name="fecha_bono"
                                            component={renderDayPickerB}
                                            msj="Fecha de bono"
                                            disabled={false}
                                            onChange={(e) => {
                                                setFechaTerm(lectura.fecha_terminacion);
                                                calcularDiasBono(e.target.value);
                                            }}
                                        />
                                    </div>
                                    <div className='col-2'>
                                        <div className="form-floating">
                                            <NumberFormat
                                                className={classNames('form-control')}
                                                decimalScale={0}
                                                disabled={true}
                                                placeholder="Dias de bono"
                                                fixedDecimalScale
                                                thousandSeparator
                                                value={editarBo === true ? lectura.dias_bono : diasBono}
                                                name="dias_bono"
                                            />
                                            <label className='form-label' htmlFor="Dias de bono">
                                            Dias de bono
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            }
                            {crear === true &&
                                <div className='row mb-2'>
                                    <div className='col-3'>
                                        <Field
                                            name="fecha_vacaciones"
                                            component={renderDayPickerB}
                                            msj="Fecha de vacaciones"
                                            onChange={(e) => calcularDiasVac(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-2'>
                                        <div className="form-floating">
                                            <NumberFormat
                                                className={classNames('form-control')}
                                                decimalScale={2}
                                                disabled={false}
                                                placeholder="Dias de vacaciones"
                                                fixedDecimalScale
                                                thousandSeparator
                                                value={diasVac}
                                                name="dias_vacaciones"
                                                onChange={(e) => setDiasVac(e.target.value)}
                                            />
                                            <label className='form-label' htmlFor="total">
                                                Dias de vacaciones
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-3'>
                                        <Field
                                            name="fecha_sueldo"
                                            component={renderDayPickerB}
                                            msj="Fecha sueldo pendiente"
                                            onChange={(e) => calcularDiasSueldo(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-2'>
                                        <div className="form-floating">
                                            <NumberFormat
                                                className={classNames('form-control')}
                                                decimalScale={0}
                                                disabled={true}
                                                placeholder="Dias sueldo pendiente"
                                                fixedDecimalScale
                                                thousandSeparator
                                                value={diasSueldo}
                                                name="dias_sueldo"
                                            />
                                            <label className='form-label' htmlFor="total">
                                                Dias sueldo pendiente
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            }                            
                            {crear === false && editar === false && 
                                <div className='row mb-2'>
                                    <div className='col-3'>
                                        <Field
                                            name="fecha_vacaciones"
                                            component={renderDayPickerB}
                                            msj="Fecha de vacaciones"
                                            disabled={true}
                                        />
                                    </div>
                                    <div className='col-2'>
                                        <Field
                                            name="dias_vacaciones"
                                            placeholder="Dias de vacacioens"
                                            className="form-control"
                                            component={renderCurrencyFloat}
                                            disabled={true}
                                            msj="Dias de vacaciones"
                                        />
                                    </div>
                                    <div className='col-3'>
                                        <Field
                                            name="fecha_salario_pendiente"
                                            component={renderDayPickerB}
                                            msj="Fecha sueldo pendiente"
                                        />
                                    </div>
                                    <div className='col-2'>
                                        <Field
                                            name="dias_salario_pendiente"
                                            placeholder="Dias sueldo pendiente"
                                            className="form-control"
                                            component={renderCurrencyFloat}
                                            disabled={true}
                                            msj="Dias sueldo pendiente"
                                        />
                                    </div>
                                </div>
                            }
                            {(crear === false && editar === false && detalleNominaSueldo.length) > 0 &&
                                <div className='mb-4'>
                                    <Contenido>
                                        <h4 className='mt-4'>Nomina de sueldo</h4>
                                        <div style={datosNominaTablaSueldo}>
                                            <VerDatosSueldo
                                                data={detalleNominaSueldo}
                                            />
                                        </div>
                                    </Contenido>
                                </div>
                            }
                            {editar === true && 
                                <div className='row mb-2'>
                                    <div className='col-3'>
                                        <Field
                                            name="fecha_vacaciones"
                                            component={renderDayPickerB}
                                            msj="Fecha de vacaciones"
                                            disabled={false}
                                            onChange={(e) => {
                                                setFechaTerm(lectura.fecha_terminacion);
                                                calcularDiasVac(e.target.value);
                                            }}
                                        />
                                    </div>
                                    <div className='col-2'>
                                        <div className="form-floating">
                                            <NumberFormat
                                                className={classNames('form-control')}
                                                decimalScale={2}
                                                disabled={false}
                                                placeholder="Dias de vacaciones"
                                                fixedDecimalScale
                                                thousandSeparator
                                                value={editarVa === true ? lectura.dias_vacaciones : diasVac}
                                                onChange={(e) => {
                                                    setEditarVa(false);
                                                    setDiasVac(e.target.value);
                                                }}
                                                name="dias_bono"
                                            />
                                            <label className='form-label' htmlFor="Dias de vacaciones">
                                            Dias de vacaciones
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-3'>
                                        <Field
                                            name="fecha_salario_pendiente"
                                            component={renderDayPickerB}
                                            msj="Fecha sueldo pendiente"
                                            onChange={(e) => {
                                                setFechaTerm(lectura.fecha_terminacion);
                                                setColaboradorID(lectura.colaborador);
                                                calcularDiasSueldo(e.target.value);
                                            }}
                                        />
                                    </div>
                                    <div className='col-2'>
                                        <div className="form-floating">
                                            <NumberFormat
                                                className={classNames('form-control')}
                                                decimalScale={0}
                                                disabled={true}
                                                placeholder="Dias sueldo pendiente"
                                                fixedDecimalScale
                                                thousandSeparator
                                                value={editarSuel === true ? lectura.dias_salario_pendiente : diasSueldo}
                                                name="dias_sueldo"
                                            />
                                            <label className='form-label' htmlFor="Dias de bono">
                                                Dias sueldo pendiente
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            }
                            {(editar === true && crear === false && detalleNominaSueldo.length) > 0 &&
                                <div className='mb-4'>
                                    <Contenido>
                                        <h4 className='mt-4'>Nomina de sueldo</h4>
                                        <div style={datosNominaTablaSueldo}>
                                            <VerDatosSueldo
                                                data={detalleNominaSueldo}
                                            />
                                        </div>
                                    </Contenido>
                                </div>
                            }
                            {(editar === true && crear === false && nominaSueldoMod.length) > 0 &&
                                <div className='mb-4'>
                                    <Contenido>
                                        <h4 className='mt-4'>Nuevos datos nomina de sueldo</h4>
                                        <div style={datosNominaTablaSueldo}>
                                            <DatosSueldo
                                                data={nominaSueldoMod}
                                            />
                                        </div>
                                    </Contenido>
                                </div>
                            }
                            {(crear === true && nominaSueldo.length) > 0 &&
                                <div className='mb-4'>
                                    <Contenido>
                                        <h4 className='mt-4'>Nomina de sueldo</h4>
                                        <div style={datosNominaTablaSueldo}>
                                            <DatosSueldo
                                                data={nominaSueldo}
                                            />
                                        </div>
                                    </Contenido>
                                </div>
                            }
                            {crear === true &&
                                <div className='row mt-3'>
                                    <Contenido>
                                        <h4 className='mt-4'>Cálculo de prestaciones</h4>
                                        <div style={estilo}>
                                            <Grid
                                                hover
                                                striped
                                                data={prest}
                                                footer
                                                headerStyle={{ background: 'black' }}
                                            >
                                                <TableHeaderColumn
                                                    isKey
                                                    dataField="prestacion"
                                                    dataAlign='left'
                                                    headerAlign="center"
                                                    dataSort
                                                    thStyle={
                                                        { color: 'white' }}
                                                    tdStyle={
                                                        { color: 'white' }}
                                                >
                                                    Prestación
                                                </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="total"
                                                    dataAlign='right'
                                                    headerAlign="center"
                                                    dataSort
                                                    dataFormat={(cell, row)=>{
                                                        return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(cell);
                                                    }}
                                                    thStyle={
                                                        { color: 'white' }}
                                                    tdStyle={
                                                        { color: 'white' }}
                                                >
                                                    Valor
                                                </TableHeaderColumn>
                                            </Grid>
                                        </div>
                                        <div className='d-flex flex-row mt-3 ml-2'>
                                            <button
                                                className='btn mr-2 mb-3 btn-primary'
                                                onClick={() => { calcularDatos(dias, diasAg, diasBono, diasVac, promedio, promedioHora, promedioComision, salarioLiquido, totalPrestamo) }}
                                            >
                                                Calcular
                                            </button>
                                            <button
                                                className='btn mr-2 mb-3 btn btn-secondary'
                                                onClick={() => cambiarEstadoModalPrestacion(!estadoModalPrestacion)}
                                            >
                                                Agregar prestación
                                            </button>
                                        </div>
                                    </Contenido>
                                </div>
                            }
                            {crear === false && editar === false && 
                                <div className='row mt-3'>
                                    <Contenido>
                                        <h4 className='mt-4'>Cálculo de prestaciones</h4>
                                        <div style={estilo}>
                                            <Grid
                                                hover
                                                striped
                                                data={detalleLiq}
                                                footer
                                                headerStyle={{ background: 'black' }}
                                            >
                                                <TableHeaderColumn
                                                    isKey
                                                    dataField="prestacion"
                                                    dataAlign='left'
                                                    headerAlign="center"
                                                    dataSort
                                                    thStyle={
                                                        { color: 'white' }}
                                                    tdStyle={
                                                        { color: 'white' }}
                                                >
                                                    Prestación
                                                </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="total"
                                                    dataAlign='right'
                                                    headerAlign="center"
                                                    dataSort
                                                    dataFormat={(cell, row)=>{
                                                        return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(cell);
                                                    }}
                                                    thStyle={
                                                        { color: 'white' }}
                                                    tdStyle={
                                                        { color: 'white' }}
                                                >
                                                    Valor
                                                </TableHeaderColumn>
                                            </Grid>
                                        </div>
                                    </Contenido>
                                </div>
                            }
                            {editar === true && 
                                <div className='row mt-3'>
                                    <Contenido>
                                        <h4 className='mt-4'>Cálculo de prestaciones</h4>
                                        <div style={estilo}>
                                            <Grid
                                                hover
                                                striped
                                                data={detalleLiq}
                                                footer
                                                headerStyle={{ background: 'black' }}
                                            >
                                                <TableHeaderColumn
                                                    isKey
                                                    dataField="prestacion"
                                                    dataAlign='left'
                                                    headerAlign="center"
                                                    dataSort
                                                    thStyle={
                                                        { color: 'white' }}
                                                    tdStyle={
                                                        { color: 'white' }}
                                                >
                                                    Prestación
                                                </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="total"
                                                    dataAlign='right'
                                                    headerAlign="center"
                                                    dataSort
                                                    dataFormat={(cell, row)=>{
                                                        return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(cell);
                                                    }}
                                                    thStyle={
                                                        { color: 'white' }}
                                                    tdStyle={
                                                        { color: 'white' }}
                                                >
                                                    Valor
                                                </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    thStyle={
                                                    {color: 'white'}}
                                                    dataField="id"
                                                    width="150"
                                                    dataAlign="center"
                                                    dataSort
                                                    dataFormat={standardActions({ 
                                                        eliminar: eliminar, 
                                                    })}
                                                >
                                                    Acciones
                                                </TableHeaderColumn>
                                            </Grid>
                                        </div>
                                        <div className='d-flex flex-row mt-3 ml-2'>
                                            <button
                                                className='btn mr-2 mb-3 btn-primary'
                                                onClick={() => { recalcularDatos(dias, diasAg, diasBono, diasVac, promedio, promedioHora, promedioComision, salarioLiquido, totalPrestamo, editarAg, editarBo, editarVa, editarSuel, editarD) }}
                                            >
                                                Calcular
                                            </button>
                                            <button
                                                className='btn mr-2 mb-3 btn btn-secondary'
                                                onClick={() => cambiarEstadoModalPrestacionEd(!estadoModalPrestacionEd)}
                                            >
                                                Agregar prestación
                                            </button>
                                        </div>
                                    </Contenido>
                                </div>
                            }
                            {crear === true &&
                                <div className='d-flex flex-row mt-3 ml-2'>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={() => { preGrabar(dias, diasAg, diasBono, diasVac, prest, fechaAgui, fechaBono, promedio, promedioHora, promedioComision, diasSueldo, nominaSueldo) }}
                                    >
                                        Pre grabar
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={() => { confirmar(dias, diasAg, diasBono, diasVac, prest, fechaAgui, fechaBono, promedio, promedioHora, promedioComision, diasSueldo, nominaSueldo) }}
                                    >
                                        Confirmar
                                    </button>
                                    {valEmp === 'fage' &&
                                        <a
                                            href={`/#/lfg/rr_hh/${id_emp[6]}/liquidacion_laboral`}
                                            className='btn btn-secondary mb-3'
                                        >
                                            Regresar
                                        </a>
                                    }
                                    {valEmp === '' &&
                                        <a
                                            href={`/#/rr_hh/${id_emp[5]}/liquidacion_laboral`}
                                            className='btn btn-secondary mb-3'
                                        >
                                            Regresar
                                        </a>
                                    }
                                </div>
                            }
                            {editar === true &&
                                <div className='d-flex flex-row mt-3 ml-2'>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={() => { preGrabarMod(dias, diasAg, diasBono, diasVac, editarD, editarAg, editarBo, editarVa, editarSuel, diasSueldo, nominaSueldoMod) }}
                                    >
                                        Pre grabar
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={() => { confirmarMod(dias, diasAg, diasBono, diasVac, editarD, editarAg, editarBo, editarVa, editarSuel, diasSueldo, nominaSueldoMod) }}
                                    >
                                        Confirmar
                                    </button>
                                    {valEmp === 'fage' &&
                                        <a
                                            href={`/#/lfg/rr_hh/${id_emp[6]}/liquidacion_laboral`}
                                            className='btn btn-secondary mb-3'
                                        >
                                            Regresar
                                        </a>
                                    }
                                    {valEmp === '' &&
                                        <a
                                            href={`/#/rr_hh/${id_emp[5]}/liquidacion_laboral`}
                                            className='btn btn-secondary mb-3'
                                        >
                                            Regresar
                                        </a>
                                    }
                                </div>
                            }
                            {crear === false && editar === false && 
                                <div className='d-flex flex-row mt-3 ml-2'>
                                    {valEmp === 'fage' &&
                                        <a
                                            href={`/#/lfg/rr_hh/${id_emp[6]}/liquidacion_laboral`}
                                            className='btn btn-secondary mb-3'
                                        >
                                            Regresar
                                        </a>
                                    }
                                    {valEmp === '' &&
                                        <a
                                            href={`/#/rr_hh/${id_emp[5]}/liquidacion_laboral`}
                                            className='btn btn-secondary mb-3'
                                        >
                                            Regresar
                                        </a>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                    <Modal
                        estado={estadoModalPrestacion}
                        cambiarEstado={cambiarEstadoModalPrestacion}
                        titulo="Agregar prestación"
                        botonCerrar="true"
                        >
                            <div className='row'>
                                <div className='col-6 mb-2'>
                                    <div className="form-floating">
                                        <Field
                                            name="tipo"
                                            options={tipo}
                                            dato={0}
                                            component={SelectField}
                                            className="form-select"
                                            msj="Tipo"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 mb-2'>
                                    <div className="form-floating">
                                        <input
                                            placeholder="Prestación"
                                            type="text"
                                            autocomplete="off"
                                            name="prestacion"
                                            className="form-control"
                                            value={prestacion}
                                            onChange={e => { setPrestacion(e.target.value);  }}
                                            disabled={false}
                                        />
                                        <label className='form-label' htmlFor="proveedor">
                                            Prestación
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-6 mb-2'>
                                    <div className="form-floating">
                                        <NumberFormat
                                            className={classNames('form-control')}
                                            decimalScale={2}
                                            disabled={false}
                                            placeholder="Monto"
                                            fixedDecimalScale
                                            thousandSeparator
                                            value={monto}
                                            onChange={e => { setMonto(e.target.value); }}
                                            name="monto"
                                        />
                                        <label className='form-label' htmlFor="base">
                                            Monto
                                        </label>
                                    </div>
                                </div>
                            </div>
                            
                            <Contenido>
                                <div className='row'>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={() => { 
                                            agregarPrestacion(prest, prestacion, monto); 
                                            cambiarEstadoModalPrestacion(false); 
                                            setMonto(0.00);
                                            setPrestacion('');
                                        }}
                                    >
                                        Agregar prestación
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn btn-secondary'
                                        onClick={() => { 
                                            cambiarEstadoModalPrestacion(false);
                                            setMonto(0.00);
                                            setPrestacion('');
                                        }}
                                    >
                                        Cancelar
                                    </button>
                                </div>
                            </Contenido>
                    </Modal>
                    <Modal
                        estado={estadoModalPrestacionEd}
                        cambiarEstado={cambiarEstadoModalPrestacionEd}
                        titulo="Agregar prestación"
                        botonCerrar="true"
                        >
                            <div className='row'>
                                <div className='col-6 mb-2'>
                                    <div className="form-floating">
                                        <Field
                                            name="tipo"
                                            options={tipo}
                                            dato={0}
                                            component={SelectField}
                                            className="form-select"
                                            msj="Tipo"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 mb-2'>
                                    <div className="form-floating">
                                        <input
                                            placeholder="Prestación"
                                            type="text"
                                            autocomplete="off"
                                            name="prestacion"
                                            className="form-control"
                                            value={prestacionEd}
                                            onChange={e => { setPrestacionEd(e.target.value);  }}
                                            disabled={false}
                                        />
                                        <label className='form-label' htmlFor="proveedor">
                                            Prestación
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-6 mb-2'>
                                    <div className="form-floating">
                                        <NumberFormat
                                            className={classNames('form-control')}
                                            decimalScale={2}
                                            disabled={false}
                                            placeholder="Monto"
                                            fixedDecimalScale
                                            thousandSeparator
                                            value={montoEd}
                                            onChange={e => { setMontoEd(e.target.value); }}
                                            name="monto"
                                        />
                                        <label className='form-label' htmlFor="base">
                                            Monto
                                        </label>
                                    </div>
                                </div>
                            </div>
                            
                            <Contenido>
                                <div className='row'>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={() => { 
                                            guardarPrestacion(prestacionEd, montoEd); 
                                            cambiarEstadoModalPrestacionEd(false); 
                                            setMontoEd(0.00);
                                            setPrestacionEd('');
                                        }}
                                    >
                                        Agregar prestación
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn btn-secondary'
                                        onClick={() => cambiarEstadoModalPrestacionEd(false)}
                                    >
                                        Cancelar
                                    </button>
                                </div>
                            </Contenido>
                    </Modal>
                </div>
            </div>
        </div>
    );
}

LiquidacionForm.propTypes = {
    handleSubmit: PropTypes.func,
    crear: PropTypes.bool,
    lectura: PropTypes.object,
    modulo: PropTypes.object
};

export default reduxForm({
    form: 'FormLiquidacionColaborador',
})(LiquidacionForm);

const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;