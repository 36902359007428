import React, { useState }  from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import NavbarModulo from '../../components/NavbarModulo';
import DetalleInventario from './DetalleInventario';
import DetalleInventarioVer from './DetalleInventarioVer';
import styled from 'styled-components';

import {
    renderField,
    AsyncSelectField,
    renderDayPickerB,
    AsyncSelectFieldIn,
    renderCurrencyFloatTC,
    renderCurrencyFloat
} from "../../Utils/renderField/renderField";

function DevolucionProductoForm(props) {
    const {
        crear,
        bloqueo,
        modulo,
        periodoD,
        detalleProducto,
        registroDevolucion,
        datos,
        listarProductos,
        pro,
        envio,
        agregarLinea,
        listarEnvios,
        detalleCon,
        listarClientes,
        listarBodegas,
        listarSeries,
        numeroSerie,
        eliminarLinea
    } = props;

    
    const estilo = {
        overflow: "auto",
        height: "200px",

    };
    const editar = window.location.href.includes('editar');
    let titulo = editar ? 'Modificar devolución de producto' : 'Nuevo devolución de producto';
    let disabled = false;
    if (crear === false && editar === false) {
        disabled = true;
        titulo = 'Ver devolución de producto'
    }
    
    const handleOnChangeEnvio = value => {
        listarEnvios(value.value);
    };

    const handleOnChangeProducto = value => {
        listarProductos(value.value);
    };

    const [numeroSerieD, setNumeroSerieD] = useState('');
    let ns;
    const handleOnChangeS = value => {
        ns = numeroSerie(value.label);
        ns.then(d => setNumeroSerieD(d) )
    };

    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    
    return (
        <div>
            <NavbarModulo
                periodoD = {periodoD}
                modulo = {modulo}
                titulo = {titulo}
            />
            <div className="mb-4 card card-small text-white bg-dark mb-3">
                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                    <div className="d-flex flex-column flex-1 mx-4">
                        {(crear === false && editar === false) &&
                            <div className='row mb-2'>
                                <div className='col-3'>
                                    <Field
                                        name="fecha"
                                        component={renderDayPickerB}
                                        msj="Fecha"
                                        disabled={true}
                                    />
                                </div>
                                <div className='col-3'>
                                    <div className='mb-2'>
                                        <Field
                                            name="serie"
                                            placeholder="Serie"
                                            component={renderField}
                                            className="form-control"
                                            msj="Serie"
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className='col-3'>
                                    <div className='mb-2'>
                                        <Field
                                            name="numero"
                                            placeholder="Número"
                                            component={renderField}
                                            className="form-control"
                                            msj="Número"
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="envioD"
                                        placeholder="Envio"
                                        component={renderField}
                                        className="form-control"
                                        msj="Envio"
                                        disabled={true}
                                    />
                                </div>
                            </div>
                        }
                        {(crear === true) &&
                            <div className='row mb-2'>
                                <div className='col-3'>
                                    <Field
                                        name="fecha"
                                        component={renderDayPickerB}
                                        msj="Fecha"
                                    />
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="serie"
                                        placeholder="Serie..."
                                        className="form-control-find"
                                        loadOptions={listarSeries}
                                        component={AsyncSelectField}
                                        onChange={handleOnChangeS}
                                        msj="Serie"
                                    />
                                </div>
                                <div className='col-3'>
                                    <div className="form-floating">
                                        <input
                                            placeholder="Numero"
                                            type="number"
                                            autocomplete="off"
                                            name="numero"
                                            className="form-control"
                                            value={numeroSerieD}
                                            onChange={e =>  setNumeroSerieD(e.target.value) }
                                        />
                                        <label className='form-label' htmlFor="cuenta">
                                            Numero
                                        </label>
                                    </div>
                                </div>
                            </div>
                        }
                        {(crear === true) &&
                            <div className='row mb-2'>
                                <div className='col-6'>
                                    <Field
                                        name="cliente_documento"
                                        placeholder="Cliente..."
                                        className="form-control-find"
                                        component={AsyncSelectField}
                                        loadOptions={listarClientes}
                                        onChange={handleOnChangeEnvio}
                                        msj="Cliente"
                                    />
                                </div>
                                <div className='col-6'>
                                    <Field
                                        name="envio"
                                        placeholder="Envio..."
                                        className="form-control-find"
                                        loadOptions={envio}
                                        component={AsyncSelectFieldIn}
                                        onChange={handleOnChangeProducto}
                                        msj="Envio"
                                    />
                                </div>
                            </div>
                        }
                        {(crear === false && editar === false) &&
                            <div className='row mb-2'>
                                <div className='col-12'>
                                    <Field
                                        name="clienteN"
                                        placeholder="Cliente"
                                        component={renderField}
                                        className="form-control"
                                        msj="Cliente"
                                        disabled={true}
                                    />
                                </div>
                            </div>
                        }
                        <div className='mb-2'>
                            <Field
                                name="descripcion"
                                placeholder="Descripcion"
                                component={renderField}
                                className="form-control"
                                msj="Referencia"
                                disabled={disabled}
                            />
                        </div>
                        {(crear === true || editar === true) &&
                            <div>
                                <h5 className="p-2 bg-secondary title mt-1">Detalle devolución producto</h5>
                                <div className='row mb-2'>
                                    
                                    <div className='col-7'>
                                        <Field
                                            name="producto"
                                            placeholder="Producto..."
                                            className="form-control-find"
                                            loadOptions={pro}
                                            component={AsyncSelectFieldIn}
                                            msj="Producto"
                                        />
                                    </div>
                                    <div className='col-2'>
                                        <Field
                                            name="cantidad"
                                            placeholder="Cantidad"
                                            className="form-control"
                                            component={renderCurrencyFloatTC}
                                            disabled={bloqueo}
                                            msj="Cantidad"
                                        />
                                    </div>
                                    <div className='col-2'>
                                        <Field
                                            name="unidad"
                                            placeholder="unidad"
                                            className="form-control"
                                            component={renderCurrencyFloatTC}
                                            disabled={bloqueo}
                                            msj="Unidad"
                                        />
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <div className='col-3'>
                                        <Field
                                            name="bodega"
                                            placeholder="Bodega..."
                                            className="form-control-find"
                                            loadOptions={listarBodegas}
                                            component={AsyncSelectField}
                                            msj="Bodega"
                                        />
                                    </div>
                                    <div className='col-2'>
                                        <Field
                                            name="costo"
                                            placeholder="Costo"
                                            className="form-control"
                                            component={renderCurrencyFloat}
                                            msj="Costo"
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {(crear === true) &&
                            <Contenido>
                                <button
                                    className='btn mr-2 mb-3 btn-primary'
                                    onClick={() => agregarLinea(periodoD, datos)}
                                >
                                    Agregar linea
                                </button>
                            </Contenido>
                        }
                        {(crear === false && editar === false) &&
                            <div>
                                <h5 className="p-2 bg-secondary title mt-1">Detalle devolución producto</h5>
                                <div style={estilo}>
                                    <DetalleInventarioVer
                                        detalle={detalleProducto}
                                    />
                                </div>
                            </div>
                        }
                        <br/>
                        {(crear === true) &&
                            <div style={estilo}>
                                <DetalleInventario
                                    detalle={detalleCon}
                                    eliminarLineaProducto={eliminarLinea}
                                />
                            </div>
                        }
                        
                        <div className='d-flex flex-row mt-3'>
                            <br />
                            {crear == true &&
                                <button
                                    className='btn mr-2 mb-3 btn-primary'
                                    onClick={() => registroDevolucion(periodoD, numeroSerieD)}
                                >
                                    Guardar
                                </button>
                            }
                            <a
                                href = {`/#/produccion/${id_emp[5]}/devolucion_producto/${id_emp[7]}`}
                                className='btn btn-secondary mb-3'
                            >
                                Regresar
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

DevolucionProductoForm.propTypes = {
    crear: PropTypes.bool,
    editar: PropTypes.bool,
    bloqueo: PropTypes.bool,
    modulo: PropTypes.object,
    periodoD: PropTypes.object,
    obtenerBodegas: PropTypes.array,
    listarProductos: PropTypes.object,
    detalleProducto: PropTypes.object,
    registroProductoInventario: PropTypes.func,
    eliminarLineaProducto: PropTypes.func,
    confirmarEntrada: PropTypes.func,
    periodoD: PropTypes.object,
    datos: PropTypes.object,
    listarBodegas: PropTypes.func,
    leerProductos: PropTypes.func,
    pro: PropTypes.object,
};

const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;

export default reduxForm({
    form: 'FormDevolucionProducto',
})(DevolucionProductoForm);
