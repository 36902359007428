import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import { validate, validators } from 'validate-redux-form';
import PropTypes from 'prop-types';
import NavbarModulo from '../../components/NavbarModulo';
import DetalleCombo from './DetalleCombo';
import styled from 'styled-components';

import {
  renderField,
  renderNumber,
  AsyncSelectField
} from "../../Utils/renderField/renderField";

function ComboForm(props) {
  const { crear, modulo, confirmarCombo, eliminarLineaProducto,
    listarProductos, detalleProducto, registroCombo } = props;
  const editar = window.location.href.includes('editar');
  let titulo = editar ? 'Modificar Combo' : 'Crear Combo';
  let disabled = false;
  if (crear === false && editar === false) {
    disabled = true;
    titulo = 'Ver Combo'
  }
  let autoFocus = true;

  let ruta = window.location.href;
  let id_emp = ruta.split('/');
  const estilo = {
    
    height: "200px",
  };
  return (
    <div>
       <NavbarModulo
        modulo={modulo}
        titulo={titulo}
      />
      <br />
      <div className="mb-4 card card-small text-white bg-dark mb-3">
        <div className="p-0 pt-3 d-flex flex-column flex-md-row">
          <div className="d-flex flex-column flex-1 mx-4">
            <div className='row mb-2'>
              <div className='mb-2 col-3'>
                <Field
                  autoFocus={autoFocus}
                  name="SKU"
                  placeholder="SKU"
                  className="form-control"
                  component={renderField}
                  disabled={editar ? true : disabled}
                  msj="SKU"
                />
              </div>
              <div className='col-9'>
                <Field
                    name="producto"
                    placeholder="Producto"
                    className="form-control"
                    component={renderField}
                    disabled={disabled}
                    msj="Producto"
                />
              </div>
            </div>
            <div className='row mb-2'>
                <div className='col-6'>
                    <Field
                        name="producto_combo"
                        placeholder="Producto..."
                        className="form-control-find"
                        loadOptions={listarProductos}
                        component={AsyncSelectField}
                        msj="Producto"
                    />
                </div>
                <div className='col-2'>
                    <Field
                        name="cantidad"
                        placeholder="Cantidad"
                        className="form-control"
                        component={renderNumber}
                        msj="Cantidad"
                    />
                </div>
            </div>
              <Contenido>
                <button
                  className='btn mr-2 mb-3 btn-primary'
                  onClick={registroCombo}
                >
                  Agregar linea
                </button>
              </Contenido>
            <div>
              <div style={estilo}>
                <DetalleCombo
                  detalle={detalleProducto}
                  eliminarLineaProducto={eliminarLineaProducto}
                />
              </div>
            </div>
            <div className='d-flex flex-row mt-3'>
                <button
                  className='btn mr-2 mb-3 btn-primary'
                  onClick={confirmarCombo}
                >
                  Guardar combo
                </button>
              
              <a
                href={`/#/inventario/${id_emp[5]}/producto`}
                className='btn btn-secondary mb-3'
              >
                Regresar
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ComboForm.propTypes = {
  handleSubmit: PropTypes.func,
  crear: PropTypes.bool,
  lectura: PropTypes.object,
  modulo: PropTypes.object,
  registroCombo: PropTypes.func,
  confirmarCombo: PropTypes.func,
  eliminarLineaProducto: PropTypes.func,
};

const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;

export default reduxForm({
  form: 'FormComboProducto',
  validate: (data) => {
    return validate(data, {
      numero: validators.exists()('Este campo es requerido'),
      bodega: validators.exists()('Este campo es requerido'),
    });
  },
})(ComboForm);
