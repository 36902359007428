import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';

const LISTADO_RECIBOS = 'LISTADO_RECIBOS';
const LOADER = 'LOADER';
const MODULO = 'MODULO';
const TOTAL_EFECTIVO = 'TOTAL_EFECTIVO';
const TOTAL_CHEQUE = 'TOTAL_CHEQUE';
const PERIODO_D = 'PERIODO_D';
const LISTADO_CORTE = 'LISTADO_CORTE';
const DETALLE_DOCUMENTOS = 'DETALLE_DOCUMENTOS';
const FECHA_CIERRE = 'FECHA_CIERRE';
const FECHA_MES = 'FECHA_MES';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let periodo = id_emp[7];
    const params = { id, periodo };
    dispatch(setLoader(true));
    api.get('/corte_caja_prd', params).then((response) => {
        dispatch({ type: LISTADO_CORTE, data: response });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error al listar cortes de caja',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const mostrarRecibos = (fechaFin) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const data = getStore().form.FormCorteCajaProduccion.values;
    let fechaInicio = data.fecha_inicio;
    let fechaFinal = fechaFin;
    let reporte = "mostrarRecibos";
    const params = {id, reporte, fechaInicio, fechaFinal};
    dispatch(setLoader(true));
    api.get('/recibo_caja_prd', params).then((response)=>{
        dispatch({ type: TOTAL_EFECTIVO, totalEfectivo: response.totalE });
        dispatch({ type: TOTAL_CHEQUE, totalCheque: response.totalC });
        dispatch({ type: LISTADO_RECIBOS, recibos: response.datos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const corteCaja = (totalEfectivo, totalCheque) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormCorteCajaProduccion.values;
    let totalCorte = (parseFloat(totalEfectivo) + parseFloat(totalCheque)).toFixed(2) 
    
    const params = {
        fechaInicio: data.fecha_inicio,
        fechaFinal: data.fecha_cierre,
        totalEfectivo: totalEfectivo,
        totalCheque: totalCheque,
        total: totalCorte,
        empresa: id_emp[5],
        periodo: id_emp[7],
    };
    dispatch(setLoader(true));
    api.post('/corte_caja_prd/', params).then((response)=>{
        NotificationManager.success(
            'Corte de caja generado correctamente',
            'Exito',
            3000
        );
        Swal.fire({
            title: '¿Imprimir?',
            text: '¡Desea imprimir corte de caja!',
            type: 'question',
            showCancelButton: true,
            confirmButtonText: '¡Sí, imprimir!',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                dispatch(imprimirCorte(response.id));
                let ruta = `/produccion/${id_emp[5]}/corte_caja/${id_emp[7]}`
                dispatch(push(ruta))
            }else {
                window.location.reload(true);
            }
        });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const imprimirCorte = idC => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let idCorte = idC
    let reporte = 'imprimirCorte';
    const params = { id, reporte, idCorte };
    dispatch(setLoader(true));
    api.getPdf('/corte_caja_prd/', params).then((response) => {
        if (response.headers['content-type'] == 'application/pdf') {
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'corte_caja.pdf';
            window.open(link, '_blank');
            return false;
        }
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al generar reporte',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leerPeriodoD = (id, id_periodo) => (dispatch) => {
    const params = { id, id_periodo };
    api.get('/periodos/periodo', params).then((response) => {
        dispatch({ type: PERIODO_D, periodoD: response.periodo });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar periodo',
            'Error',
            2000
        );
    })
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/corte_caja_prd/${id}`).then((response) => {
        dispatch(initializeForm('FormCorteCajaProduccion', response ));
        dispatch(leerDetalleCorte(response.id));
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar corte de caja',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

const leerDetalleCorte = id => (dispatch) => {
    api.get(`/corte_caja_detalle_prd/${id}`).then((response)=>{
        dispatch({ type: DETALLE_DOCUMENTOS, detalleDocumentos: response });
    }).catch((error)=>{
        NotificationManager.error(
            error.body.msj,
            'Error',
            6000
        );
        dispatch({ type: DETALLE_DOCUMENTOS, detalleDocumentos: [] });;
    })
};

const limpiar = () => (dispatch) => {
    dispatch({ type: LISTADO_RECIBOS, recibos: [] });
};

export const fechaCorte = (id, id_periodo) => (dispatch) => {
    const params = { id, id_periodo };
    api.get('/corte_caja_prd/fecha_corte', params).then((response) => {
        dispatch({ type: FECHA_CIERRE, fechaCierre: response.fecha_cierre });
        dispatch({ type: FECHA_MES, fechaMes: response.FechaFinal })
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar periodo',
            'Error',
            2000
        );
    })
}


export const actions = {
    listar,
    leer,
    leerPeriodoD,
    getModulo,
    mostrarRecibos,
    corteCaja,
    imprimirCorte,
    limpiar,
    fechaCorte,
};


export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [LISTADO_RECIBOS]: (state, { recibos }) => {
        return {
            ...state,
            recibos,
        };
    },
    [TOTAL_EFECTIVO]: (state, { totalEfectivo }) => {
        return {
            ...state,
            totalEfectivo,
        };
    },
    [TOTAL_CHEQUE]: (state, { totalCheque }) => {
        return {
            ...state,
            totalCheque,
        };
    },
    [PERIODO_D]: (state, { periodoD }) => {
        return {
            ...state,
            periodoD,
        };
    },
    [LISTADO_CORTE]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [DETALLE_DOCUMENTOS]: (state, { detalleDocumentos }) => {
        return {
            ...state,
            detalleDocumentos,
        };
    },
    [FECHA_CIERRE]: (state, { fechaCierre }) => {
        return {
            ...state,
            fechaCierre,
        };
    },
    [FECHA_MES]: (state, { fechaMes }) => {
        return {
            ...state,
            fechaMes,
        };
    },
};

export const initialState = {
    loader: false,
    modulo: [],
    recibos: [],
    totalEfectivo: 0,
    totalCheque: 0,
    periodoD: [],
    data: [],
    detalleDocumentos: [],
    fechaCierre: '',
    fechaMes: '',
};

export default handleActions(reducers, initialState)
