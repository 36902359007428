import { api } from "api";
import React, { useEffect } from "react";
import BaseProtectedRoute from "./BaseProtectedRoute";
import NavbarAutoVentas from "./common/components/layout/Navbar/NavbarAutoVentas";
import NavbarFinanzas from "./common/components/layout/Navbar/NavbarFinanzas";
import BaseNavBar from "./common/components/layout/Navbar/BaseNavBar";
import NavBarSubModulo from "./common/components/layout/Navbar/NavBarSubModulo";
import SidebarDataLaboratorio from "./common/components/layout/Sidebar/SidebarDataLaboratorio";
import { connect } from "react-redux";
import {
    setSettingsLaboratorio,
    setPeriodoLaboratorio,
} from "./redux/modules/laboratorio/actions";
import { listar } from "./redux/modules/ajustes/empresa";

export const fetchSettings = (id) => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let empresa = id_emp[5];
    const params = { empresa };
    // const url = id? baseurl + `/${id}`: baseurl;
    return api
        .get("laboratorio/ajustes", params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return {};
        });
};

function ProtectedRouteLaboratorio({
    title,
    setSettingsLaboratorio,
    setPeriodoLaboratorio,
    listar,
    ...props
}) {
    useEffect(() => {
        const getSettings = async () => {
            const settings = await fetchSettings();
            setSettingsLaboratorio(settings);
        };
        const periodoValue = JSON.parse(
            localStorage.getItem("periodoLaboratorio")
        );
        if (periodoValue) {
            setPeriodoLaboratorio(periodoValue);
        }
        getSettings();
        listar();
    }, []);

    return (
        <BaseProtectedRoute
            navbar={BaseNavBar}
            links={SidebarDataLaboratorio}
            title={`Laboratorio ${title ? "-" : ""} ${title}`}
            {...props}
        />
    );
}

export default connect(null, {
    setSettingsLaboratorio,
    setPeriodoLaboratorio,
    listar,
})(ProtectedRouteLaboratorio);
