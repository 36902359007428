import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';
const valEmp = __CONFIG__.empresa;

const COLABORADOR = 'COLABORADOR';
const LISTADO = 'LISTADO';
const TIPO = 'TIPO';
const PUESTO = 'PUESTO';
const LOADER = 'LOADER';
const MODULO = 'MODULO';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listarColaborador = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params;
    let id = '';
    if (valEmp === 'fage') {
        id = id_emp[6];
        params = { id };
    } else {
        id = id_emp[5];
        params = { id };
    }
    api.get('/colaborador/empresa_colaborador_pais', params).then(data => {
        const lista = [];
        if (data) {
            data.forEach(item => {
                lista.push({
                    value: item.id,
                    label: item.codigo + ' - ' + item.nombre
                })
            })
        }
        dispatch({ type: COLABORADOR, emp: lista })
    }).catch((error) => {
        return [];
    })
}

export const datosColaborador = (emp) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let yearD = '';
    if (valEmp === 'fage') {
        yearD = id_emp[8];
    } else {
        yearD = id_emp[7];
    }
    api.get(`/colaborador/${emp}`).then((response) => {
        dispatch({ type: PUESTO, cargo: response.cargo })
    }).catch((error) => {
        return [];
    })
}

export const registroJudicial = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    const data = getStore().form.FormJudicial.values;
    let id_empresa = "";
    if (valEmp === "fage") {
        id_empresa = id_emp[6];
    } else {
        id_empresa = id_emp[5];
    }
    if (data === undefined) {
        Swal.fire({
            title: "Descuento judicial colaborador",
            text: "Debe de seleccionar colaborador",
            type: "error",
        });
    } else {
        if (data.fecha_inicio === undefined || data.fecha_inicio === "") {
            Swal.fire({
                title: "Descuento judicial colaborador",
                text: "Debe de ingresar fecha de inicio",
                type: "error",
            });
        } else {
            if (
                (data.total === undefined || data.total === "0.00") &&
                (data.descuento === undefined || data.descuento === "0.00")
            ) {
                Swal.fire({
                    title: "Descuento judicial colaborador",
                    text: "Debe de ingresar total a descontar",
                    type: "error",
                });
            } else {
                if (data.descuento && data.descuento !== "0.00" && data.porcentaje && data.porcentaje !== "0" ) {
                    Swal.fire({
                        title: "Descuento judicial colaborador",
                        text: "No puede ingresar descuento y porcentaje",
                        type: "error",
                    });
                } else {
                    const formData = {
                        colaborador: data.colaborador.value,
                        fecha_inicio: data.fecha_inicio,
                        total: data.total,
                        descuento: data.descuento,
                        porcentaje: data.porcentaje,
                        empresa: id_empresa,
                    };
                    dispatch(setLoader(true));
                    api.post("/rrhh_judicial/", formData).then(() => {
                        NotificationManager.success(
                            "Descuento judicial creado correctamente",
                            "Exito",
                            3000
                        );
                        if (valEmp === "fage") {
                            ruta = `/lfg/rr_hh/${id_emp[6]}/judicial`;
                            dispatch(push(ruta));
                        } else {
                            ruta = `/rr_hh/${id_emp[5]}/judicial`;
                            dispatch(push(ruta));
                        }
                    })
                    .catch((error) => {
                        NotificationManager.error(error.msj, "Error", 0);
                    })
                    .finally(() => {
                        dispatch(setLoader(false));
                    });
                }
            }
        }
    }
};

export const getModulo = (id, modulo) => (dispatch) => {
    const params = { id, modulo }
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response) => {
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = '';
    let year = '';
    let params;
    if (valEmp === 'fage') {
        id = id_emp[6];
        year = id_emp[8];
        params = { id, year };
    } else {
        id = id_emp[5];
        year = id_emp[7];
        params = { id, year };
    }
    return api.get('/rrhh_judicial/', params).then((response) => {
        dispatch({ type: LISTADO, data: response })
    }).catch(error => {
        NotificationManager.error(
            'Ocurrió un error al listar descuento judicial',
            'Error',
            0
        );
    })
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/rrhh_judicial/${id}`).then((response) => {
        dispatch({ type: TIPO, tipo: response.forma_descuento })
        dispatch(initializeForm('FormJudicial', response));
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar descuento judicial',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const modificarJudicial = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const datos = getStore().form.FormPrestamo.values;
    const id = datos.id;
    const formData = {
        id: id,
        fecha_inicio: datos.fecha_inicio,
        total: datos.total,
        meses: datos.meses ? datos.meses : 0,
        cuota: datos.cuota ? datos.cuota : 0.00,
        forma_descuento: datos.tipo ? datos.tipo : datos.forma_descuento,
    }
    dispatch(setLoader(true));
    api.put(`/rrhh_judicial/${id}/`, formData).then(() => {
        NotificationManager.success(
            'Descuento judicial modificado correctamente',
            'Exito',
            3000
        );
        let ruta = '';
        if (valEmp === 'fage') {
            ruta = `/lfg/rr_hh/${id_emp[6]}/judicial`
            dispatch(push(ruta));
        } else {
            ruta = `/rr_hh/${id_emp[5]}/judicial`
            dispatch(push(ruta));
        }
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al modificar descuento judicial',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const limpiarDatos = () => (dispatch) => {
    dispatch({ type: PUESTO, cargo: [] });
};

export const actions = {
    listarColaborador,
    getModulo,
    registroJudicial,
    modificarJudicial,
    datosColaborador,
    listar,
    leer,
    limpiarDatos,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [COLABORADOR]: (state, { emp }) => {
        return {
            ...state,
            emp,
        };
    },
    [PUESTO]: (state, { cargo }) => {
        return {
            ...state,
            cargo,
        };
    },
    [LISTADO]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [TIPO]: (state, { tipo }) => {
        return {
            ...state,
            tipo,
        };
    },
};

export const initialState = {
    loader: false,
    modulo: [],
    data: [],
    emp: [],
    tipo: [],
};

export default handleActions(reducers, initialState)
