import React from "react";
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import * as BsIcons from "react-icons/bs";
import * as IoIcons from "react-icons/io";
import * as AiIcons from "react-icons/ai";
import * as GiIcons from "react-icons/gi";

export const SidebarDataSeguros = [
    {
        title: "Inicio",
        path: "/seguros",
        icon: <BsIcons.BsGrid1X2Fill />,
    },
    {
        title: "Catalogos",
        path: "#",
        icon: <AiIcons.AiFillCar />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav: [
            {
                title: "Agentes",
                path: "/seguros",
                path_s: "agentes",
                icon: <FaIcons.FaHandshake />,
            },
            {
                title: "Ficha Clientes",
                path: "/seguros",
                path_s: "ficha-clientes",
                icon: <FaIcons.FaPersonBooth />,
            },
            {
                title: "Vehiculos",
                path: "/seguros",
                path_s: "vehiculos",
                icon: <FaIcons.FaCar />,
            },
            {
                title: "Aseguradoras",
                path: "/seguros",
                path_s: "aseguradoras",
                icon: <FaIcons.FaUserShield />,
            },
            // {
            //     title: "Tipo",
            //     path: "/auto_ventas",
            //     path_s: "tipo",
            //     icon: <GiIcons.GiJeep />,
            // },
            // {
            //     title: "Combustible",
            //     path: "/auto_ventas",
            //     path_s: "combustible",
            //     icon: <GiIcons.GiGasPump />,
            // },
        ],
    },
    // {
    //     title: "Agentes",
    //     path: "/seguros/agentes",
    //     icon: <FaIcons.FaHandshake />,
    // },
    // {
    //     title: "Ficha Clientes",
    //     path: "/seguros/ficha-clientes",
    //     icon: <FaIcons.FaPersonBooth />,
    //     iconClosed: <RiIcons.RiArrowDownSFill />,
    //     iconOpened: <RiIcons.RiArrowUpSFill />,
    // },
    {
        title: "Seguro",
        path: "/seguros/seguro",
        icon: <RiIcons.RiArrowLeftDownFill />,
        subNav: [
            {
                title: "Nuevo",
                path: "/seguros",
                path_s: "seguro",
                icon: <FaIcons.FaPlus />,
            },
            // {
            //     title: "Renovacion",
            //     path: "/seguros",
            //     path_s: "lineas",
            //     icon: <GiIcons.GiRecycle />,
            // },
        ],
    },
    {
        title: "Informes",
        path: "/seguros/informes",
        icon: <FaIcons.FaClipboard />,
    },
];
