import React, { Component } from 'react';
import Formulario from './TipoCambioForm';
import LoadMask from "../Utils/LoadMask/LoadMask";

class TipoCambio extends Component{
    state = {
        crear: true,
    }

    componentDidMount = () => {
        const { match, leer, getModulo, leerPeriodo } = this.props;
        const id = match.params.id;
        if (id){
            this.setState({crear: false});
            leer(id);
        }
        let ruta = window.location.href;
        let periodoT = ruta.split('/');
        leerPeriodo(periodoT[5], periodoT[7]);
        getModulo(periodoT[5],periodoT[4]);
    }
    
    render(){
        const { registroTC, modificarTC, loader, modulo, periodo } = this.props;
        const { crear } = this.state;
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    registroTC = {registroTC}
                    modificarTC = {modificarTC}
                    crear = {crear}
                    modulo = {modulo}
                    periodo = {periodo}
                />
            </LoadMask>
        );
    }
}

export default TipoCambio;
