import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/nomina/descuentos';
import SeleccionarPeriodo from './SeleccionarPeriodoNomina';


const ms2p = (state) => {
  return {
    ...state.descuento_nomina,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(SeleccionarPeriodo);
