import { handleActions } from 'redux-actions';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';
import moment from 'moment';

const LISTADO_CUENTAS = 'LISTADO_CUENTAS';
const LISTADO_CHEQUES = 'LISTADO_CHEQUES';
const LOADER = 'LOADER';
const MODULO = 'MODULO';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listarCuentasBancarias = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    api.get('/cuenta_banco', params).then((response) => {
        const cuentas = [];
        response.forEach(item => {
            cuentas.push({
                value: item.id,
                label: item.numero_cuenta + ' - ' + item.monedaN + ' - ' + item.tipo + ' [ ' + item.bancoNombre + ' ]'
            })
        })
        dispatch({ type: LISTADO_CUENTAS, cuenta: cuentas });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar cuentas bancarias',
            'Error',
            0
        );
    })
}

let detalle = [];
export const listar = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const datos = getStore().form.FormConciliacionCheques.values;

    if (datos === undefined || datos === null) {
        Swal.fire({
            title: '¡Error!',
            text: 'Todos los campos son requeridos',
            type: 'error',
            showCancelButton: false,
            confirmButtonColor: '#4040ff',
            confirmButtonText: 'Aceptar',
            reverseButtons: true
        });
    }else{
        if (datos.cuenta === undefined || datos.cuenta === null) {
            Swal.fire({
                title: '¡Error!',
                text: 'Debe seleccionar una cuenta bancaria',
                type: 'error',
                showCancelButton: false,
                confirmButtonColor: '#4040ff',
                confirmButtonText: 'Aceptar',
                reverseButtons: true
            });
        }else{
            if (datos.mes === undefined || datos.mes === null) {
                Swal.fire({
                    title: '¡Error!',
                    text: 'Debe seleccionar un mes',
                    type: 'error',
                    showCancelButton: false,
                    confirmButtonColor: '#4040ff',
                    confirmButtonText: 'Aceptar',
                    reverseButtons: true
                });
            }else{
                if (datos.year === undefined || datos.year === null) {
                    Swal.fire({
                        title: '¡Error!',
                        text: 'Debe ingresar un año',
                        type: 'error',
                        showCancelButton: false,
                        confirmButtonColor: '#4040ff',
                        confirmButtonText: 'Aceptar',
                        reverseButtons: true
                    });
                }else{
                    const formData = {
                        cuenta: datos.cuenta,
                        mes: datos.mes,
                        year: datos.year,
                        empresa: id_emp[5]
                    }
                    dispatch(setLoader(true));
                    api.get('/conciliacion', formData).then((response) => {
                        detalle = []
                        response.datos.forEach(datos => {
                            detalle.push({
                                id: datos.id,
                                numero: datos.numero,
                                tipo: datos.tipo,
                                fecha_registro: datos.fecha_registro,
                                nombre: datos.nombre,
                                debitos: datos.debitos,
                                creditos: datos.creditos,
                                operado: datos.operado,
                                documento: datos.documento,
                                fecha_operado: datos.fecha_operado,
                                fechaInicio: response.fechaPeriodoInicio,
                                fechaFinal: response.fechaPeriodoFin,
                            })
                        });
                        dispatch({ type: LISTADO_CHEQUES, data: detalle });
                    }).catch((error) => {
                        if (error.status === 400) {
                            NotificationManager.error(
                                error.body.msj,
                                'Error',
                                2000
                            );
                            dispatch({ type: LISTADO_CHEQUES, data: [] });
                        }
                    }).finally(() => {
                        dispatch(setLoader(false));
                    });
                }
            }
        }
    }
}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = { id, modulo }
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response) => {
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        if (error.status === 401) {
            NotificationManager.error(
                error.body.msj,
                'Error',
                0
            );
        }
        if (error.status === 400) {
            NotificationManager.error(
                'Ocurrió un error en buscar modulo',
                'Error',
                0
            );
        } conciliacion
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const conciliar = (data) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const datos = getStore().form.FormConciliacionCheques.values;
    
    Swal.fire({
        title: 'Revisado por:',
        html:
            '<div class="mx-auto mt-4" style="width: 450px;">' +
            `<input class="form-control" id="mensaje" autocomplete="off" class="mx-auto mb-4" type="text">` +
            '</div',
        background: "black",
        showCancelButton: true,
        confirmButtonColor: "#4040ff",
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
        cancelButtonColor: "#646464",
        showCloseButton: true,
        customClass: {
            title: 'sweet_titleImportant',
        },
        preConfirm: () => {
            let texto = document.getElementById('mensaje').value
            if (texto) {
                const id = datos.cuenta;
                const formData = {
                    cuenta: datos.cuenta,
                    mes: datos.mes,
                    year: datos.year,
                    empresa: id_emp[5],
                    datos: data,
                    revisado: texto
                }
                dispatch(setLoader(true));
                api.put(`/conciliacion/${id}/`, formData).then(() => {
                    NotificationManager.success(
                        'Conciliación finalizada correctamente',
                        'Exito',
                        3000
                    );
                    Swal.fire({
                        title: '¿Imprimir?',
                        text: '¡Desea imprimir!',
                        type: 'question',
                        showCancelButton: true,
                        confirmButtonText: '¡Sí, imprimir!',
                        cancelButtonText: 'No, cancelar',
                        reverseButtons: true
                    }).then((result) => {
                        if (result.value) {
                            let imp = 'imprimirConciliacion';
                            let empresa = id_emp[5];
                            let mes = datos.mes;
                            let year = datos.year;
                            let cuenta = datos.cuenta;
                            const params = { empresa, imp, mes, year, cuenta, texto };
                            dispatch(setLoader(true));
                            api.getPdf('/conciliacion', params).then((response)=>{
                                dispatch(limpiarDatos());
                                if (response.headers['content-type'] == 'application/pdf'){
                                    var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
                                    var link = document.createElement('a');
                                    link.href = window.URL.createObjectURL(blob);
                                    link.download = 'cheque.pdf';
                                    window.open(link, '_blank');
                                    return false;
                                }
                                }).catch((error)=>{
                                    NotificationManager.error(
                                        'Ocurrió un error al generar reporte',
                                        'Error',
                                        2000
                                    );
                                }).finally(() => {
                                    dispatch(setLoader(false));
                                });
                        }else {
                            dispatch(limpiarDatos());        
                        }
                    });
                }).catch((error) => {
                    NotificationManager.error(
                        'Ocurrió un error al conciliar',
                        'Error',
                        2000
                    );
                }).finally(() => {
                    dispatch(setLoader(false));
                });
            }
            if (!texto) {
                Swal.showValidationMessage(
                    'Este campo es requerido'
                )
            }
        }
    })
}

export const actualizar = (datos, id, fechaI, fechaF, fechaOp, operado) => (dispatch) => {
    let fechaIn = moment(fechaI);
    let fechaFi = moment(fechaF);
    let fechaOperado = moment(fechaOp);
    if (fechaOperado.isBetween(fechaIn, fechaFi, 'day', '[]')) {
        let ruta = window.location.href;
        let id_emp = ruta.split('/');
        const formData = {
            id: id,
            estado: datos,
            fechaI : fechaI,
            fechaF : fechaF,
            empresa: id_emp[5]
        }
        dispatch(setLoader(false));
        api.get('/conciliacion/estado', formData).then((response) => {
            dispatch({ type: LISTADO_CHEQUES, data: response });
        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al listar cheques',
                'Error',
                2000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }else if (!operado && !fechaOperado.isBetween(fechaIn, fechaFi, 'day', '[]')) {
        let ruta = window.location.href;
        let id_emp = ruta.split('/');
        const formData = {
            id: id,
            estado: datos,
            fechaI : fechaI,
            fechaF : fechaF,
            empresa: id_emp[5]
        }
        dispatch(setLoader(false));
        api.get('/conciliacion/estado', formData).then((response) => {
            dispatch({ type: LISTADO_CHEQUES, data: response });
        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al listar cheques',
                'Error',
                2000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
}

export const limpiarDatos = () => (dispatch) => {
    dispatch({ type: LISTADO_CHEQUES, data: [] });
}

export const actions = {
    listarCuentasBancarias,
    listar,
    getModulo,
    conciliar,
    actualizar,
    limpiarDatos
};


export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_CUENTAS]: (state, { cuenta }) => {
        return {
            ...state,
            cuenta,
        };
    },
    [LISTADO_CHEQUES]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
};

export const initialState = {
    loader: false,
    cuenta: [],
    data: [],
    modulo: [],
};

export default handleActions(reducers, initialState)