import React, { Component } from 'react';
import Formulario from './FacturacionHome';
import LoadMask from "../Utils/LoadMask/LoadMask";

class Facturacion extends Component{
    componentDidMount = () => {
        const { getModulo } = this.props;
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        getModulo(modulo[5],modulo[4]);
    }
    render(){
        const { loader, modulo } = this.props;
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    modulo={modulo}
                />
            </LoadMask>
        );
    }
}

export default Facturacion;
