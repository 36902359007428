import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";

const LOADER = 'LOADER';
const GUARDAR_NIVEL = 'GUARDAR_NIVEL';

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const leer = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    dispatch(setLoader(true));
    api.get('/niveles/leer_nivel', params).then((response) => {
        
        if (response.msj === 'No existe'){
            localStorage.setItem('niveles', false);
            localStorage.setItem('niveles_cuentas', 0);
        }
        if (response.nivel){
            localStorage.setItem('niveles', true);
            localStorage.setItem('niveles_cuentas', response.totCuenta);
        }
        dispatch(initializeForm('FormNivel', response.nivel ));
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar el nivel',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const registroNivel = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormNivel.values;
    const formData = {
        nivel_uno: data.nivel_uno,
        nivel_dos: data.nivel_dos,
        nivel_tres: data.nivel_tres,
        nivel_cuatro: data.nivel_cuatro,
        nivel_cinco: data.nivel_cinco,
        nivel_seis: data.nivel_seis,
        empresa: id_emp[5]
    }
    dispatch(setLoader(true));
    api.post('/niveles/', formData).then((response) => {
        NotificationManager.success(
            'Nivel creado',
            'Exito',
            3000
        );
        let ruta = `/contabilidad/${id_emp[5]}`
        dispatch(push(ruta));
    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            6000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const modificarNivel = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const datos = getStore().form.FormNivel.values;
    const id = datos.id;
    const formData = {
        id: id,
        nivel_uno: parseInt(datos.nivel_uno),
        nivel_dos: parseInt(datos.nivel_dos),
        nivel_tres: parseInt(datos.nivel_tres),
        nivel_cuatro: parseInt(datos.nivel_cuatro),
        nivel_cinco: parseInt(datos.nivel_cinco),
        nivel_seis: parseInt(datos.nivel_seis),
    }
    dispatch(setLoader(true));
    api.put(`/niveles/${id}/`, formData).then((response) => {
        NotificationManager.success(
            'Nivel modificado correctamente',
            'Exito',
            3000
        );
        let ruta = `/contabilidad/${id_emp[5]}`
        dispatch(push(ruta));
    }).catch((error) => {
        console.log("error: ", error)
        NotificationManager.error(
            'Ocurrió un error al modificar el nivel',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const actions = {
    registroNivel,
    modificarNivel,
    leer,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [GUARDAR_NIVEL]: (state, { nivel }) => {
        return {
            ...state,
            nivel,
        };
    }
    
};

export const initialState = {
    loader: false,
    nivel: null,
    lectura: null,
};

export default handleActions(reducers, initialState)