import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/auto_ventas/vendedores/vendedoresVer';
import VendedoresFormVer from './VendedoresFormVer';


const ms2p = (state) => {
  return {
    ...state.auto_ventas_vendedores_ver,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(VendedoresFormVer);
