import { handleActions } from 'redux-actions';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';

const LISTADO_CUENTAS = 'LISTADO_CUENTAS';
const LOADER = 'LOADER';
const MODULO = 'MODULO';
const PERIODO_D = 'PERIODO_D';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listarCuentasPrd = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    dispatch(setLoader(true));
    api.get('/cuenta_contable_prd', params).then((response) => {
        dispatch({ type: LISTADO_CUENTAS, data: response });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error al listar cuentas contables',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const agregarCuenta = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormCuentaContablePRD.values;
    if (data === undefined) {
        Swal.fire({
            title: 'Cuenta contable',
            text: 'Debe de seleccionar una cuenta',
            type: 'error',
        })
    }else{
        if (data.cuenta === undefined || data.cuenta === null) {
            Swal.fire({
                title: 'Cuanta contable',
                text: 'Debe de seleccionar una cuenta',
                type: 'error',
            })
        }else{
            const params = {
                cuenta: data.cuenta.value,
                empresa: id_emp[5]
            };
            dispatch(setLoader(true));
            api.post('/cuenta_contable_prd/', params).then(()=>{
                NotificationManager.success(
                    'Cuenta contable guardada correctamente',
                    'Exito',
                    3000
                );
                dispatch(listarCuentasPrd());
            }).catch((error) => {
                NotificationManager.error(
                    error.msj,
                    'Error',
                    2000
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }
    }
};

export const leerPeriodoD = (id, id_periodo) => (dispatch) => {
    const params = { id, id_periodo };
    api.get('/periodos/periodo', params).then((response) => {
        dispatch({ type: PERIODO_D, periodoD: response.periodo });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar periodo',
            'Error',
            2000
        );
    })
}

const limpiar = () => (dispatch) => {
    dispatch({ type: LISTADO_RECIBOS, recibos: [] });
};

export const listarPolizas = (id, id_periodo) => (dispatch) => {
    const params = { id, id_periodo };
    api.get('/parqueo/listar_polizas', params).then((response) => {
        dispatch({ type: POLIZAS, polizas: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar periodo',
            'Error',
            2000
        );
    })
}

export const listarCuentas = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/cuentas/cuentas_contables_produccion/', params).then(data => {
        const list_cuentas = [];
        if (data) {
            data.forEach(item => {
                if (item.tipo === 'A') {
                    list_cuentas.push({
                        value: item.id,
                        label: item.cuenta + ' ' + item.nombre,
                        isDisabled: true
                    })
                } else {
                    list_cuentas.push({
                        value: item.id,
                        label: item.cuenta + ' ' + item.nombre
                    })
                }
            })
        }
        return list_cuentas;
    }).catch((error) => {
        
        return [];
    })
}

const eliminarCuenta = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`/cuenta_contable_prd/${id}`).then(() => {
        NotificationManager.success(
            'Cuenta contable eliminada correctamente',
            'Exito',
            3000
        );
        dispatch(listarCuentasPrd());
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error al eliminar cuenta contable',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const actions = {
    leerPeriodoD,
    getModulo,
    agregarCuenta,
    limpiar,
    listarCuentasPrd,
    listarCuentas,
    eliminarCuenta
};


export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [PERIODO_D]: (state, { periodoD }) => {
        return {
            ...state,
            periodoD,
        };
    },
    [LISTADO_CUENTAS]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
};

export const initialState = {
    loader: false,
    modulo: [],
    periodoD: [],
    data: [],
};

export default handleActions(reducers, initialState)
