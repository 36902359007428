import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";

const GUARDAR_CUENTA = 'GUARDAR_CUENTA';
const LISTADO_CUENTAS = 'LISTADO_CUENTAS';
const NIVEL = 'NIVEL';
const CUENTAS = 'CUENTAS';
const PAGE = 'PAGE';
const LOADER = 'LOADER';
const CONFIG = 'CONFIG';
const MODULO = 'MODULO';

const setPage = page => ({
    type: PAGE,
    page,
});

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listarCuentas = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    dispatch(setLoader(true));
    api.get('/niveles/nivel_cuenta', params).then((response)=>{
        dispatch({ type: NIVEL, nivel: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar nivel',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/cuentas/${id}`).then((response) => {
        dispatch({type: GUARDAR_CUENTA, lectura: response });
        dispatch(initializeForm('FormCuentas', response ));
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar la cuenta',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const verCuentas = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    dispatch(setLoader(true));
    api.get(`/cuentas/empresa_cuentas/?id=${id}`).then((response)=>{
        dispatch({ type: LISTADO_CUENTAS, cuentas: response });
        dispatch(initializeForm("FormCuentas", response));
    }).catch((error)=>{
        console.log("error: ", error)
        NotificationManager.error(
            'Ocurrió un error al listar las cuentas',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}


const listarCuentasPage = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    //const params = { page, id };
    const params = { id };
    dispatch(setLoader(true));
    api.get('/cuentas', params).then((response)=>{
        dispatch({ type: CUENTAS, data: response });
        api.get(`/niveles/nivel_cuenta/?id=${id}`).then((response)=>{
            dispatch({ type: NIVEL, nivel: response });
        }).catch((error)=>{
            NotificationManager.error(
                'Ocurrió un error al listar nivel',
                'Error',
                2000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar las cuentas',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}


export const registroCuenta = (formato) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormCuentas.values;
    let cuenta = data.cuenta
    let f = formato.split('-');
    let cont = 0;
    let formatoCuenta = '';
    let digito = '';
  
    if (f.length === 3){
        let nivel1 = f[0].length
        digito = cuenta.substr(cont,nivel1);
        formatoCuenta = formatoCuenta + digito + '-';
        
        let nivel2 = f[1].length;
        cont = cont+nivel1; 
        digito = cuenta.substr(cont,nivel2);
        formatoCuenta = formatoCuenta + digito + '-';
        
        let nivel3 = f[2].length;
        cont = cont+nivel2; 
        digito = cuenta.substr(cont,nivel3);
        formatoCuenta = formatoCuenta + digito;
        
    }
  
    if (f.length === 4){
        let nivel1 = f[0].length
        digito = cuenta.substr(cont,nivel1);
        formatoCuenta = formatoCuenta + digito + '-';
        
        let nivel2 = f[1].length;
        cont = cont+nivel1; 
        digito = cuenta.substr(cont,nivel2);
        formatoCuenta = formatoCuenta + digito + '-';
        
        let nivel3 = f[2].length;
        cont = cont+nivel2; 
        digito = cuenta.substr(cont,nivel3);
        formatoCuenta = formatoCuenta + digito + '-';
        
        let nivel4 = f[3].length;
        cont = cont+nivel3;
        digito = cuenta.substr(cont,nivel4);
        formatoCuenta = formatoCuenta + digito;
    }
    
    if (f.length === 5){
        let nivel1 = f[0].length
        digito = cuenta.substr(cont,nivel1);
        formatoCuenta = formatoCuenta + digito + '-';
        
        let nivel2 = f[1].length;
        cont = cont+nivel1; 
        digito = cuenta.substr(cont,nivel2);
        formatoCuenta = formatoCuenta + digito + '-';
        
        let nivel3 = f[2].length;
        cont = cont+nivel2; 
        digito = cuenta.substr(cont,nivel3);
        formatoCuenta = formatoCuenta + digito + '-';
        
        let nivel4 = f[3].length;
        cont = cont+nivel3;
        digito = cuenta.substr(cont,nivel4);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel5 = f[4].length;
        cont = cont+nivel4;
        digito = cuenta.substr(cont,nivel5);
        formatoCuenta = formatoCuenta + digito;
    }

    if (f.length === 6){
        let nivel1 = f[0].length
        digito = cuenta.substr(cont,nivel1);
        formatoCuenta = formatoCuenta + digito + '-';
        
        let nivel2 = f[1].length;
        cont = cont+nivel1; 
        digito = cuenta.substr(cont,nivel2);
        formatoCuenta = formatoCuenta + digito + '-';
        
        let nivel3 = f[2].length;
        cont = cont+nivel2; 
        digito = cuenta.substr(cont,nivel3);
        formatoCuenta = formatoCuenta + digito + '-';
        
        let nivel4 = f[3].length;
        cont = cont+nivel3;
        digito = cuenta.substr(cont,nivel4);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel5 = f[4].length;
        cont = cont+nivel4;
        digito = cuenta.substr(cont,nivel5);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel6 = f[5].length;
        cont = cont+nivel5;
        digito = cuenta.substr(cont,nivel6);
        formatoCuenta = formatoCuenta + digito;
    }
      
    const formData = {
        cuenta: formatoCuenta,
        nombre: data.nombre,
        tipo: data.tipo,
        naturaleza: data.naturaleza,
        empresa: id_emp[5]
    }
    dispatch(setLoader(true));
    api.post('/cuentas/', formData).then((response) => {
        NotificationManager.success(
            'Cuenta creada',
            'Exito',
            3000
        );
        response.cuenta = '';
        response.nombre = '';
        dispatch(initializeForm("FormCuentas", response));
    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const modificarCuenta = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const datos = getStore().form.FormCuentas.values;
    const id = datos.id;
    const formData = {
        id: id,
        cuenta: datos.cuenta,
        nombre: datos.nombre,
        tipo: datos.tipo,
        naturaleza: datos.naturaleza,
    }
    dispatch(setLoader(true));
    api.put(`/cuentas/${id}/`, formData).then((response) => {
        NotificationManager.success(
            'Cuenta modificada correctamente',
            'Exito',
            3000
        );
        let ruta = `/contabilidad/${id_emp[5]}/cuentas_contables`
        dispatch(push(ruta));
    }).catch((error) => {
        
        NotificationManager.error(
            'Ocurrió un error al modificar la cuenta',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}


export const reporteCuentas = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let reporte = 'reporte';
    const params = { id, reporte };
    dispatch(setLoader(true));
    api.getPdf('/cuentas', params).then((response)=>{
        if (response.headers['content-type'] == 'application/pdf'){
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'reporte_cuentas_contables.pdf';
            window.open(link, '_blank');
            return false;
          }
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al generar reporte',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const listarCuentasConfig = (page = 1) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { page, id };
    let cont = 1;
    let ct = localStorage.getItem("cuentaC");
    localStorage.setItem("cuentaC",cont);
    if (page===1 && ct===null){
        dispatch(setLoader(true));
    }
    
    api.get('/config_conta/config_empresa', params).then((response)=>{
        dispatch({ type: CONFIG, config: response });
        dispatch(setPage(page));
    }).catch((error)=>{
        console.log("error: ", error)
        NotificationManager.error(
            'Ocurrió un error al listar las cuentas',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const listarCuentasContables = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id, search}
    return api.get('/cuentas/empresa_cuentas/',params).then(data=>{
        const list_cuentas = [];
        if(data){
            data.forEach(item=>{
                if (item.tipo === 'A'){
                    list_cuentas.push({
                        value: item.id,
                        label: item.cuenta + ' ' + item.nombre,
                        isDisabled: true
                    })
                }else{
                    list_cuentas.push({
                        value: item.id,
                        label: item.cuenta + ' ' + item.nombre
                    })
                }
            })
        }
        return list_cuentas;
    }).catch((error)=>{
        return [];
    })
}

export const registroConfigCuenta = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormConfigCuentas.values;
    const formData = {
        descripcion_cuenta: data.tipo,
        cuenta: data.cuenta.value,
        empresa: id_emp[5],
    }
    dispatch(setLoader(true));
    api.post('/config_conta/', formData).then(() => {
        NotificationManager.success(
            'Cuenta agregada',
            'Exito',
            3000
        );
        let page = 1
        dispatch(listarCuentasConfig(page));
    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const eliminarCuentaConf = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`/config_conta/${id}`).then(() => {
        NotificationManager.success(
            'Cuenta borrada correctamente',
            'Exito',
            3000
        );
        let page = 1
        dispatch(listarCuentasConfig(page));
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};



export const actions = {
    registroCuenta,
    modificarCuenta,
    leer,
    listarCuentas,
    verCuentas,
    listarCuentasPage,
    reporteCuentas,
    listarCuentasConfig,
    listarCuentasContables,
    registroConfigCuenta,
    getModulo,
    eliminarCuentaConf,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [GUARDAR_CUENTA]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
    [LISTADO_CUENTAS]: (state, { cuentas }) => {
        return {
            ...state,
            cuentas,
        };
    },
    [NIVEL]: (state, { nivel }) => {
        return {
            ...state,
            nivel,
        };
    },
    [CONFIG]: (state, { config }) => {
        return {
            ...state,
            config,
        };
    },
    [CUENTAS]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
};

export const initialState = {
    loader: false,
    cuentas: null,
    lectura: [],
    nivel: [],
    config: [],
    modulo: [],
    data: [],
    page: 1,
};

export default handleActions(reducers, initialState)