import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import DetalleFactura from './DetalleFactura';
import DetalleEnvio from './DetalleEnvio';
import NavbarModulo from '../../../components/NavbarModulo';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';

import {
    renderField,
    renderDayPickerB,
    AsyncSelectField,
    renderNumber,
    SelectField,
    renderCurrencyFloat,
    renderFieldCheckB
} from "../../../Utils/renderField/renderField";
import propTypes from 'prop-types';

function FacturaForm(props) {
    const {
        crear,
        cli,
        periodoD,
        modulo,
        listarEnvios,
        registroFactura,
        detalleEnvio,
        detalleProducto,
        detalleEnv,
        editarLinea,
        estable,
        tipos,
        tipoCambio,
        monedas,
        lecturaFac,
        datos,
        detalleCEnvio
    } = props;

    let autoFocus = true;
    
   
    const estilo = {
        overflow: "auto",
        height: "200px",
    };
    let ruta = window.location.href;
    let id_empresa = ruta.split('/');
    
    const editar = window.location.href.includes('editar');
    let titulo = editar ? 'Modificar factura' : 'Crear Factura FEL [ Bienes ]';
    let disabled = false;
    if (crear === false && editar === false) {
        disabled = true;
        titulo = 'Ver Factura FEL [ Bienes ]'
    }

    const [editarT, setEditarT] = useState(editar);
    const [tipoD, setTipoD] = useState(0);
    const [iva, setIva] = useState(true);
    const [tipoCambioD, setTipoCambioD] = useState(1.000000);

    const handleOnChange = value => {
        let tipo = value.value.split('#');
        if (tipo[1] == 'Env'){
            detalleEnvio(tipo[0]);
        }
        if (tipo[1] == 'CEnv'){
            detalleCEnvio(tipo[0]);
        }
        
    };
    const handleChange = (e) => {
        let existe = tipos.find(element => element.value === e.target.value);
        setEditarT(false);
        setTipoD(e.target.value);
        if (existe.search === 'IVA') {
            setIva(true);
        }
        if (existe.search === 'SIVA') {
            setIva(false);
        }

    }
    return (
        <div>
            <NavbarModulo
                periodoD={periodoD}
                modulo={modulo}
                titulo={titulo}
            />
            <br />
            <div className="mb-4 card card-small text-white bg-dark mb-3">
                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                    <div className="d-flex flex-column flex-1 mx-4">
                        {crear === false && editar == false &&
                        <div>
                            <div className='row mb-2'>
                                <div className='col-3'>
                                    <Field
                                        name="codigo"
                                        placeholder="Codigo establecimiento"
                                        className="form-control"
                                        component={renderField}
                                        disabled={true}
                                        msj="Código establecimiento"
                                    />
                                </div>
                                <div className='col-6'>
                                    <Field
                                        name="establecimiento"
                                        placeholder="Nombre establecimiento"
                                        className="form-control"
                                        component={renderField}
                                        disabled={true}
                                        msj="Nombre establecimiento"
                                    />
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="correlativo"
                                        placeholder="Documento interno"
                                        className="form-control"
                                        component={renderField}
                                        disabled={true}
                                        msj="Documento interno"
                                    />
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-3'>
                                    {tipos &&
                                        <div className="form-floating">
                                            <Field
                                                name="tipo_documento"
                                                options={tipos}
                                                crear={crear}
                                                dato={crear === true ? 0 : lecturaFac.tipo_documento}
                                                component={SelectField}
                                                className="form-select"
                                                disabled={disabled}
                                                msj="Tipo de documento"
                                            />
                                        </div>
                                    }
                                </div>
                                <div className='col-3'>
                                    <Field
                                        autoFocus={autoFocus}
                                        disabled={disabled}
                                        name="fechaRegistro"
                                        component={renderDayPickerB}
                                        msj="Fecha Registro"
                                    />
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="serie"
                                        placeholder="Nombre"
                                        className="form-control"
                                        component={renderField}
                                        disabled={disabled}
                                        msj="Serie"
                                    />
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="numero"
                                        placeholder="Dias credito"
                                        className="form-control"
                                        component={renderNumber}
                                        disabled={disabled}
                                        msj="Numero"
                                    />
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-6'>
                                    <Field
                                        name="autorizacion"
                                        disabled={disabled}
                                        placeholder="Numero autorización"
                                        className="form-control"
                                        component={renderField}
                                        msj="Numero autorización"
                                    />
                                    </div>
                                <div className='col-3'>
                                    <Field
                                        name="envio"
                                        placeholder="Envío"
                                        className="form-control"
                                        component={renderField}
                                        disabled={disabled}
                                        msj="Envío"
                                    />
                                </div>
                                <div className='col-3'>
                                    <div className="form-floating">
                                        <Field
                                            name="moneda"
                                            options={monedas}
                                            crear={crear}
                                            dato={crear === true ? 0 : lecturaFac.moneda}
                                            component={SelectField}
                                            className="form-select"
                                            disabled={true}
                                            msj="Moneda"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                        {crear === true &&
                            <div className='row mb-2'>
                                <div className='col-3'>
                                    <div className="form-floating">
                                        <input
                                            placeholder="Codigo establecimiento"
                                            type="text"
                                            autocomplete="off"
                                            name="codigo"
                                            className="form-control"
                                            value={estable.codigo}
                                            disabled={true}
                                        />
                                        <label className='form-label' htmlFor="codigo">
                                            Código establecimiento
                                        </label>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className="form-floating">
                                        <input
                                            placeholder="Nombre establecimiento"
                                            type="text"
                                            autocomplete="off"
                                            name="nombre"
                                            className="form-control"
                                            value={estable.nombre_comercial}
                                            disabled={true}
                                        />
                                        <label className='form-label' htmlFor="nombre">
                                            Nombre establecimiento
                                        </label>
                                    </div>
                                </div>
                            </div>
                        }
                        {crear === true &&
                            <div className='row mb-2'>
                                <div className='col-3'>
                                    <div className="form-floating">
                                        <select className="form-select" id="tipo" name="tipo"
                                            value={editarT === true ? lecturaFac.tipo_documento : tipoD} onChange={handleChange} autofocus>
                                            <option selected>Seleccionar...</option>
                                            {tipos.map(
                                                tip => <option key={tip.value} value={tip.value}
                                                    >{tip.label}
                                                </option>)
                                            }
                                            </select>
                                        <label className='form-label' htmlFor="mes">Tipo de documento</label>
                                    </div>
                                </div>
                                <div className='col-3'>
                                    <Field
                                        disabled={disabled}
                                        name="fechaRegistro"
                                        component={renderDayPickerB}
                                        msj="Fecha Registro"
                                    />
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="envio"
                                        placeholder="Envio de producción..."
                                        className="form-control-find"
                                        loadOptions={listarEnvios}
                                        component={AsyncSelectField}
                                        onChange={handleOnChange}
                                        msj="Envio de producción"
                                    />
                                </div>
                            </div>
                        }
                        {(crear === true) &&
                            <div>
                                {cli.exportacion === true &&
                                    <div className='row mb-2'>
                                        <div className='col-3 mt-3'>
                                            <Field
                                                disabled={true}
                                                checked={true}
                                                name="exportacion"
                                                label="Exportación"
                                                component={renderFieldCheckB}
                                            />
                                        </div>
                                        <div className='col-3'>
                                            <div className="form-floating">
                                                <NumberFormat
                                                    className={classNames('form-control')}
                                                    decimalScale={6}
                                                    fixedDecimalScale
                                                    placeholder="Tipo de cambio"
                                                    value={tipoCambioD}
                                                    thousandSeparator
                                                    onValueChange={(values) => {
                                                        setTipoCambioD(values.value)
                                                    }}
                                                />
                                                <label className='form-label' htmlFor="cuenta">
                                                    Tipo de cambio
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-3 mt-2'>
                                            <button
                                                className='btn mr-2 mb-3 btn-secondary'
                                                onClick={() => {
                                                    let tc = tipoCambio(periodoD, cli.exportacion);
                                                    if (tc === undefined){
                                                        setTipoCambioD('1.000000');
                                                    }else{
                                                        tc.then(d => { 
                                                            setTipoCambioD(d);
                                                        });
                                                    }
                                                }}
                                            >
                                                Buscar tipo de cambio
                                            </button>
                                        </div>
                                        {/*<div className='col-3'>
                                            <Field
                                                name="ref_exportacion"
                                                disabled={disabled}
                                                placeholder="Referencia exportación"
                                                className="form-control"
                                                component={renderField}
                                                msj="Referencia exportación"
                                            />
                                        </div>*/}
                                    </div>
                                }
                                {cli.tipo === 'CE' &&
                                    <div className='row mb-2'>
                                        <div className='col-3'>
                                            <div className="form-floating">
                                                <NumberFormat
                                                    className={classNames('form-control')}
                                                    decimalScale={6}
                                                    fixedDecimalScale
                                                    placeholder="Tipo de cambio"
                                                    value={tipoCambioD}
                                                    thousandSeparator
                                                    onValueChange={(values) => {
                                                        setTipoCambioD(values.value)
                                                    }}
                                                />
                                                <label className='form-label' htmlFor="cuenta">
                                                    Tipo de cambio
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-3 mt-2'>
                                            <button
                                                className='btn mr-2 mb-3 btn-secondary'
                                                onClick={() => {
                                                    let tc = tipoCambio(periodoD, true);
                                                    if (tc === undefined){
                                                        setTipoCambioD('1.000000');
                                                    }else{
                                                        tc.then(d => { 
                                                            setTipoCambioD(d);
                                                        });
                                                    }
                                                }}
                                            >
                                                Buscar tipo de cambio
                                            </button>
                                        </div>
                                    </div>
                                }
                                <div className='row mb-2'>
                                    <div className='col-12'>
                                        <div className="form-floating">
                                            <input
                                                placeholder="Cliente"
                                                type="text"
                                                autocomplete="off"
                                                name="cliente"
                                                className="form-control"
                                                value={detalleEnv.length === 0 ? '' : cli.cliente}
                                                disabled={true}
                                            />
                                            <label className='form-label' htmlFor="cuenta">
                                                Cliente
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <div className='col-12'>
                                        <Field
                                            name="observaciones"
                                            disabled={disabled}
                                            placeholder="Observaciones"
                                            className="form-control"
                                            component={renderField}
                                            msj="Observaciones"
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {(crear === false && editar === false) &&
                            <div>
                                <div className='row mb-2'>
                                    <div className='col-12'>
                                        <Field
                                            name="clienteN"
                                            disabled={disabled}
                                            placeholder="Cliente"
                                            className="form-control"
                                            component={renderField}
                                            msj="Cliente"
                                        />
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <div className='col-12'>
                                        <Field
                                            name="observaciones"
                                            disabled={disabled}
                                            placeholder="Observaciones"
                                            className="form-control"
                                            component={renderField}
                                            msj="Observaciones"
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {(editar === true) &&
                            <div>
                                <div className='row mb-2'>
                                    <div className='col-12'>
                                        <Field
                                            name="clienteN"
                                            disabled={true}
                                            placeholder="Cliente"
                                            className="form-control"
                                            component={renderField}
                                            msj="Cliente"
                                        />
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <div className='col-12'>
                                        <Field
                                            name="observaciones"
                                            disabled={disabled}
                                            placeholder="Observaciones"
                                            className="form-control"
                                            component={renderField}
                                            msj="Observaciones"
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        
                        {(crear === true) &&
                            <div>
                                <h5 className="p-2 bg-secondary title mt-1">Detalle factura</h5>
                                <br/>
                                <div style={estilo}>
                                    <DetalleEnvio
                                        detalle={detalleEnv}
                                        editarLinea={editarLinea}
                                    />
                                </div>
                            </div>
                        }
                        {(crear === false && editar === false) &&
                            <div>
                                <h5 className="p-2 bg-secondary title mt-1">Detalle factura</h5>
                                <br/>
                                <div style={estilo}>
                                    <DetalleFactura
                                        detalle={detalleProducto}
                                    />
                                </div>
                            </div>
                        }
                        <br/>
                        {(crear === false && editar === false) &&
                            <div>
                                <div className='row mb-2'>
                                    <div className='col-3'>

                                    </div>
                                    <div className='col-2'>
                                        <Field
                                            name="base"
                                            disabled={disabled}
                                            placeholder="Base"
                                            className="form-control"
                                            component={renderCurrencyFloat}
                                            msj="Base"
                                        />
                                    </div>
                                    <div className='col-2'>
                                        <Field
                                            name="iva"
                                            disabled={disabled}
                                            placeholder="Iva"
                                            className="form-control"
                                            component={renderCurrencyFloat}
                                            msj="Iva"
                                        />
                                    </div>
                                    <div className='col-2'>
                                        <Field
                                            name="total"
                                            disabled={disabled}
                                            placeholder="Total"
                                            className="form-control"
                                            component={renderCurrencyFloat}
                                            msj="Total"
                                        />
                                    </div>
                                    <div className='col-3'>
                                        
                                    </div>
                                </div>
                            </div>
                        }
                        <br/>
                        {(crear === true) &&
                            <div className='mb-2'>
                                <button
                                    className='btn mr-2 mb-2 btn-primary'
                                    onClick={() => registroFactura(periodoD, cli.idCliente, detalleEnv, tipoD, iva, cli.exportacion, tipoCambioD, datos, cli.tipo)}
                                >
                                Guardar factura
                                </button>
                                <a
                                    href={`/#/facturacion/${id_empresa[5]}/facturas/${id_empresa[7]}`}
                                    className='btn btn-secondary mb-2'
                                >
                                Regresar
                                </a>
                            </div>
                        }
                        {(crear === false && editar === false) &&
                            <div className='mb-2'>
                                <a
                                    href={`/#/facturacion/${id_empresa[5]}/facturas/${id_empresa[7]}`}
                                    className='btn btn-secondary mb-2'
                                >
                                    Regresar
                                </a>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

FacturaForm.propTypes = {
    crear: PropTypes.bool,
    editar: PropTypes.bool,
    bloqueo: PropTypes.bool,
    fechaInicio: PropTypes.string,
    fechaFinal: PropTypes.string,
    datos: PropTypes.object,
    detallePro: PropTypes.object,
    periodoD: PropTypes.object,
    modulo: PropTypes.object,
    detalleIngreso: PropTypes.func,
    detalleProducto: PropTypes.object,
    cli: propTypes.object,
    numeroSerie: propTypes.func,
    detalleEnv: PropTypes.object
};


export default reduxForm({
    form: 'FormFactura',
})(FacturaForm);
