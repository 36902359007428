import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import NavbarModulo from '../../components/NavbarModulo';
import NavbarModuloLF from '../../components/NavbarModuloFage';
import DetalleListado from './DetallePromocionPuesto';
import Formulario from './PromocionPuestoFormDetalle';
const valEmp = __CONFIG__.empresa;

import {
    AsyncSelectField,
    AsyncSelectFieldIn,
} from "../../Utils/renderField/renderField";

function PromocionPuestoForm(props) {
    const { modulo, listarColaborador, listarPaises, emp, guardarPuesto,
        datosColaborador, listaDepartamento, lista,
        listaPuesto, cargo, depto, jefe, horario, lugar, puesto_N, depto_N,
        eliminarLinea } = props;
    let titulo = 'Promoción de puesto';
    
    let ruta = window.location.href;
    let id_emp = ruta.split('/');

    const handleOnChange = value => {
        listarColaborador(value.value);
    };

    const handleOnChangeEmp = value => {
        datosColaborador(value.value);
    };

    const estilo = {
        overflow: "auto",
        height: "300px",

    };
    
    return (
        <div>
            {valEmp === 'fage' &&
                <NavbarModuloLF
                    modulo={modulo}
                    titulo={titulo}
                />
            }
            {valEmp === '' &&
                <NavbarModulo
                    modulo={modulo}
                    titulo={titulo}
                />
            }
            
            <br />
            <div className="mb-4 card card-small text-white bg-dark mb-3">
                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                    <div className="d-flex flex-column flex-1 mx-4">
                        <div className='mb-2'>
                            <div className='row mb-2'>
                                <div className='col-6'>
                                    <Field
                                        name="pais"
                                        placeholder="Pais..."
                                        className="form-control-find"
                                        component={AsyncSelectField}
                                        loadOptions={listarPaises}
                                        onChange={handleOnChange}
                                        msj="Pais"
                                    />
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-6'>
                                    <Field
                                        name="colaborador"
                                        placeholder="Colaborador..."
                                        className="form-control-find"
                                        component={AsyncSelectFieldIn}
                                        loadOptions={emp}
                                        onChange={handleOnChangeEmp}
                                        msj="Colaborador"
                                    />
                                </div>
                            </div>
                            <Formulario
                                cargo = {cargo}
                                depto = {depto}
                                jefe = {jefe}
                                horario = {horario}
                                lugar = {lugar}
                                puesto_N = {puesto_N}
                                depto_N = {depto_N}
                                listaDepartamento = {listaDepartamento}
                                listaPuesto = {listaPuesto}
                            />
                            {lista.length >= 1 &&
                                <div className='mt-3' style={estilo}>
                                    <DetalleListado
                                        detalle={lista}
                                        eliminarLinea={eliminarLinea}
                                    />
                                </div>
                            }
                            <div className='d-flex flex-row mt-3'>
                                
                                <button
                                    className='btn mr-2 mb-3 btn-primary'
                                    onClick={() => guardarPuesto()}
                                >
                                    Guardar puesto
                                </button>
                                {valEmp === 'fage' &&
                                    <a
                                        href={`/#/lfg/rr_hh/${id_emp[6]}`}
                                        className='btn btn-secondary mb-3'
                                    >
                                        Regresar
                                    </a>
                                }
                                {valEmp === '' &&
                                    <a
                                        href={`/#/rr_hh/${id_emp[5]}`}
                                        className='btn btn-secondary mb-3'
                                    >
                                        Regresar
                                    </a>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

PromocionPuestoForm.propTypes = {
    handleSubmit: PropTypes.func,
    crear: PropTypes.bool,
    lectura: PropTypes.object,
    modulo: PropTypes.object
};

export default reduxForm({
    form: 'FormCambioPuestoColaborador',
})(PromocionPuestoForm);
