import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';
const valEmp = __CONFIG__.empresa;

const COLABORADOR = 'COLABORADOR';
const LISTADO = 'LISTADO';
const TIPO = 'TIPO';
const PUESTO = 'PUESTO';
const LOADER = 'LOADER';
const MODULO = 'MODULO';
const LECTURA = 'LECTURA';
const LISTADO_PERIODOS = 'LISTADO_PERIODOS';
const PERIODO_D = 'PERIODO_D';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listarColaborador = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params;
    let id = ''
    if (valEmp === 'fage'){
        id = id_emp[6];
        params = { id };
    }else{
        id = id_emp[5];
        params = { id };
    }
    api.get('/colaborador/empresa_colaborador_pais', params).then(data => {
        const lista = [];
        if (data) {
            data.forEach(item => {
                lista.push({
                    value: item.id,
                    label: item.codigo+' - ' + item.nombre
                })
            })
        }
        dispatch({type: COLABORADOR, emp: lista })
    }).catch((error) => {
        return [];
    })
}

export const datosColaborador = (emp) => (dispatch) => {
    api.get(`/colaborador/${emp}`).then((response) => {
        dispatch({type: PUESTO, cargo: response.cargo })
    }).catch((error) => {
        return [];
    })
}

export const registroFalta = (dias) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormFalta.values;

    let id_empresa = '';
    if (valEmp === 'fage'){
        id_empresa = id_emp[6]
    }else{
        id_empresa = id_emp[5]
    }
    if (data === undefined) {
        Swal.fire({
            title: 'Falta',
            text: 'Debe de seleccionar colaborador',
            type: 'error',
        })
    } else {
        if (data.fecha_inicio === undefined || data.fecha_inicio === '') {
            Swal.fire({
                title: 'Falta',
                text: 'Debe de ingresar fecha de inicio',
                type: 'error',
            })
        } else {
            if (data.fecha_final === undefined || data.fecha_final === '') {
                Swal.fire({
                    title: 'Falta',
                    text: 'Debe de ingresar fecha final',
                    type: 'error',
                })
            } else {
                if (data.motivo === undefined || data.motivo === '') {
                    Swal.fire({
                        title: 'Falta',
                        text: 'Debe de seleccionar motivo',
                        type: 'error',
                    })
                } else {
                    const formData = {
                        colaborador: data.colaborador.value,
                        fecha_inicio: data.fecha_inicio,
                        fecha_final: data.fecha_final,
                        motivo: data.motivo,
                        dias: dias,
                        observacion: data.observaciones,
                        empresa: id_empresa
                    }
                    dispatch(setLoader(true));
                    api.post('/rrhh_falta/', formData).then(() => {
                        NotificationManager.success(
                            'Falta creada correctamente',
                            'Exito',
                            3000
                        );
                        let ruta = `/rr_hh/${id_emp[5]}/faltas/${id_emp[7]}`
                        dispatch(push(ruta));
                    }).catch((error) => {
                    NotificationManager.error(
                        error.msj,
                        'Error',
                        0
                    );
                    }).finally(() => {
                        dispatch(setLoader(false));
                    });
                }
            }
        }
    }
}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    
    let id = id_emp[5];
    let periodo = id_emp[7];
    let params = { id, periodo };
    dispatch(setLoader(true));
    api.get('/rrhh_falta/', params).then((response)=>{
        dispatch({type: LISTADO, data: response })
    }).catch(error=>{
        NotificationManager.error(
            'Ocurrió un error al listar faltas',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/rrhh_falta/${id}`).then((response) => {
        dispatch({type: TIPO, tipo: parseInt(response.motivo)})
        dispatch({type: LECTURA, lectura: response})
        dispatch(initializeForm('FormFalta', response ));
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar falta',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

const limpiarDatos = () => (dispatch) => {
    dispatch({ type: PUESTO, cargo: [] });
};

const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`/rrhh_falta/${id}`).then(() => {
        NotificationManager.success(
            'Falta eliminada correctamente',
            'Exito',
            3000
        );
        dispatch(listar());
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error al eliminar Falta',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const modificarFalta = (dias) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const datos = getStore().form.FormFalta.values;
    const id = datos.id;

    if (datos.fecha_inicio === undefined || datos.fecha_inicio === '') {
        Swal.fire({
            title: 'Falta',
            text: 'Debe de ingresar fecha de inicio',
            type: 'error',
        })
    } else {
        if (datos.fecha_final === undefined || datos.fecha_final === '') {
            Swal.fire({
                title: 'Falta',
                text: 'Debe de ingresar fecha final',
                type: 'error',
            })
        } else {
            if (datos.motivo === undefined || datos.motivo === '') {
                Swal.fire({
                    title: 'Falta',
                    text: 'Debe de seleccionar motivo',
                    type: 'error',
                })
            } else {
                const formData = {
                    id: id,
                    fecha_inicio: datos.fecha_inicio,
                    fecha_final: datos.fecha_final,
                    motivo: datos.motivo,
                    dias: dias,
                    observacion: datos.observaciones,
                }
                dispatch(setLoader(true));
                api.put(`/rrhh_falta/${id}/`, formData).then(() => {
                    NotificationManager.success(
                        'Falta modificada correctamente',
                        'Exito',
                        3000
                    );
                    let ruta = `/rr_hh/${id_emp[5]}/faltas/${id_emp[7]}`
                    dispatch(push(ruta));
                }).catch((error) => {
                    NotificationManager.error(
                        error.msj,
                        'Error',
                        0
                    );
                }).finally(() => {
                    dispatch(setLoader(false));
                });
            }
        }
    }
}

export const listarPeriodos = (year) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let params = { id, year };
    dispatch(setLoader(true));
    api.get('/periodos/periodo_empresa_year', params).then((response)=>{
        let periodo = [];
        response.forEach(item=>{
            periodo.push({
                value: item.id,
                label: item.periodo
            })
        })
        dispatch({ type: LISTADO_PERIODOS, periodos: periodo });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar los periodos',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leerPeriodoD = (id, id_periodo) => (dispatch) => {
    const params = { id, id_periodo };
    api.get('/periodos/periodo', params).then((response) => {
        dispatch({ type: PERIODO_D, periodoD: response.periodo });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar periodo',
            'Error',
            2000
        );
    })
}

export const actions = {
    listarColaborador,
    getModulo,
    registroFalta,
    datosColaborador,
    listar,
    leer,
    limpiarDatos,
    eliminar,
    modificarFalta,
    listarPeriodos,
    leerPeriodoD
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [COLABORADOR]: (state, { emp }) => {
        return {
            ...state,
            emp,
        };
    },
    [PUESTO]: (state, { cargo }) => {
        return {
            ...state,
            cargo,
        };
    },
    [LISTADO]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [TIPO]: (state, { tipo }) => {
        return {
            ...state,
            tipo,
        };
    },
    [LECTURA]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
    [LISTADO_PERIODOS]: (state, { periodos }) => {
        return {
            ...state,
            periodos,
        };
    },
    [PERIODO_D]: (state, { periodoD }) => {
        return {
            ...state,
            periodoD,
        };
    },
};

export const initialState = {
    loader: false,
    modulo: [],
    data: [],
    emp: [],
    tipo: [],
    lectura: [],
    periodos: [],
    periodoD: [],
};

export default handleActions(reducers, initialState)
 