import { handleActions, createAction } from "redux-actions";
import { NotificationManager } from "react-notifications";
import { api } from "api";

export const listarEmpresas = (search) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let id = id_emp[5];
    const params = { search };
    return api
        .get("/empresa/listado_de_empresas_usuario", params)
        .then((response) => {
            let empresasList = [];
            response.forEach((empresa) => {
                empresasList.push({
                    value: empresa.id,
                    label: empresa.razon_social,
                });
            });
            return empresasList;
        })
        .catch((error) => {
            NotificationManager.error(
                "Ocurrió un error al listar las empresas",
                "Error",
                2000
            );
            return [];
        });
};

export const listarEmpresasAgregadas = (search) => (dispatch) => {
    return api
        .get("/finanzas/empresas")
        .then((response) => {
            return response;
        })
        .catch((error) => {
            NotificationManager.error(
                "Ocurrió un error al listar las empresas",
                "Error",
                2000
            );
        });
};

export const listarEmpresasAgregadasComboBox = (search) => (dispatch) => {
    return api
        .get("/finanzas/empresas", { search })
        .then((response) => {
            let optionsList = response.map((item) => ({
                value: item.id,
                label: item.razon_social,
            }));
            return optionsList;
        })
        .catch((error) => {
            NotificationManager.error(
                "Ocurrió un error al listar las empresas",
                "Error",
                2000
            );
        });
};

export const listarPaises = (search) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let id = id_emp[5];
    const params = { search };
    return api
        .get("/paises", params)
        .then((response) => {
            let empresasList = [];
            response.results.forEach((empresa) => {
                empresasList.push({
                    value: empresa.id,
                    label: empresa.pais,
                });
            });
            return empresasList;
        })
        .catch((error) => {
            NotificationManager.error(
                "Ocurrió un error al listar las empresas",
                "Error",
                2000
            );
        });
};

export const obtenerDatosDeEmpresa = (id) => (dispatch) => {
    return api
        .get(`/empresa/${id}`)
        .then((response) => {
            let formData = {
                identificador: response.identificador,
                razon_social: response.razon_social,
                pais: { value: response.pais, label: response.paisNombre },
            };
            return formData;
        })
        .catch((error) => {
            NotificationManager.error(
                "Ocurrió un error al listar las empresas",
                "Error",
                2000
            );
        });
};

export const agregarEmpresa = (data) => (dispatch) => {
    return api
        .post("finanzas/empresas", data)
        .then((response) => {
            NotificationManager.success("Empresa agregada", "Éxito", 2000);
            return true;
        })
        .catch((error) => {
            NotificationManager.error(error.msj, "Error", 2000);
            return false;
        });
};

export const actions = {
    listarEmpresas,
    listarPaises,
    listarEmpresasAgregadas,
    listarEmpresasAgregadasComboBox,
    obtenerDatosDeEmpresa,
    agregarEmpresa,
};

export const reducers = {};

export const initialState = {};

export default handleActions(reducers, initialState);
