import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";

const LISTADO_TIPOS = 'LISTADO_TIPOS';
const GUARDAR_TIPO = 'GUARDAR_TIPO';
const PAGE = 'PAGE';
const LOADER = 'LOADER';
const MODULO = 'MODULO';

const setPage = page => ({
    type: PAGE,
    page,
});

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listar = (page = 1) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { page, id };
    api.get('/tiposFac', params).then((response)=>{
        dispatch({ type: LISTADO_TIPOS, data: response });
        dispatch(setPage(page));
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar tipos de documento',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const registroTipo = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormTipoDocumento.values;
    const formData = {
        tipo: data.tipo,
        descripcion: data.descripcion,
        tipo_documento: data.tipo_documento,
        categoria: data.categoria,
        libro: data.libro,
        modulo: 'FAC',
        empresa: id_emp[5]
    }
    dispatch(setLoader(true));
    api.post('/tiposFac/', formData).then((response) => {
        NotificationManager.success(
            'Tipo documento creado',
            'Exito',
            3000
        );
        let ruta = `/facturacion/${id_emp[5]}/tipos`
        dispatch(push(ruta));
    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const modificarTipo = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const datos = getStore().form.FormTipoDocumento.values;
    const id = datos.id;
    const formData = {
        id: id,
        tipo: datos.tipo,
        descripcion: datos.descripcion,
        tipo_documento: datos.tipo_documento,
        categoria: datos.categoria,
        libro: datos.libro,
        modulo: 'FAC',
        empresa: id_emp[5]
    }
    dispatch(setLoader(true));
    api.put(`/tiposFac/${id}/`, formData).then((response) => {
        NotificationManager.success(
            'Tipo documento modificado correctamente',
            'Exito',
            3000
        );
        let ruta = `/facturacion/${id_emp[5]}/tipos`
        dispatch(push(ruta));
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al modificar tipo de documento',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/tiposFac/${id}`).then((response) => {
        dispatch({type: GUARDAR_TIPO, lectura: response });
        dispatch(initializeForm('FormTipoDocumento', response ));
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar tipo de documento',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const actions = {
    listar,
    registroTipo,
    modificarTipo,
    leer,
    getModulo
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_TIPOS]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [GUARDAR_TIPO]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
};

export const initialState = {
    loader: false,
    lectura: [],
    modulo: [],
    data: {
        results: [],
        count: 0,
    },
    page: 1,
};

export default handleActions(reducers, initialState)