import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";

const LISTADO_PILOTO = 'LISTADO_PILOTO';
const LOADER = 'LOADER';
const MODULO = 'MODULO';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    //const params = { page, id };
    const params = { id };
    api.get('/piloto', params).then((response)=>{
        dispatch({ type: LISTADO_PILOTO, data: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar pilotos',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const registroPiloto = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormPiloto.values;
    const formData = {
        nombre: data.nombre,
        empresa: id_emp[5]
    }
    dispatch(setLoader(true));
    api.post('/piloto/', formData).then(() => {
        NotificationManager.success(
        'Piloto creado',
        'Exito',
        3000
    );
    let ruta = `/produccion/${id_emp[5]}/piloto`
    dispatch(push(ruta));
    }).catch((error) => {
    NotificationManager.error(
        error.msj,
        'Error',
        2000
    );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const modificarPiloto = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const datos = getStore().form.FormPiloto.values;
    const id = datos.id;
    const formData = {
        id: id,
        nombre: datos.nombre,
        activo: datos.activo
    }
    dispatch(setLoader(true));
    api.put(`/piloto/${id}/`, formData).then(() => {
        NotificationManager.success(
            'Piloto modificado correctamente',
            'Exito',
            3000
        );
        let ruta = `/produccion/${id_emp[5]}/piloto`
        dispatch(push(ruta));
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al modificar el piloto',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/piloto/${id}`).then((response) => {
        dispatch(initializeForm('FormPiloto', response ));
    }).catch((error)=>{
        console.log("error: ", error)
        NotificationManager.error(
            'Ocurrió un error al consultar piloto',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const actions = {
    listar,
    registroPiloto,
    modificarPiloto,
    leer,
    getModulo
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_PILOTO]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
};

export const initialState = {
    loader: false,
    modulo: [],
    data: [],
    page: 1,
};

export default handleActions(reducers, initialState)
