import React from "react";
import {
    Button,
    Dialog,
    DialogTrigger,
    OverlayArrow,
    Popover,
} from "react-aria-components";
import "./MenuPopover.css";

function MenuPopover({ children, ...props }) {
    return (
        <Popover className="menu-popover" {...props}>
            <OverlayArrow>
                <svg width={12} height={12} viewBox="0 0 12 12">
                    <path d="M0 0 L6 6 L12 0" />
                </svg>
            </OverlayArrow>
            <Dialog>{children}</Dialog>
        </Popover>
    );
}

export default MenuPopover;

// <DialogTrigger>
//     <Button aria-label="Help">ⓘ</Button>
//     <MyPopover>
//         <Heading slot="title">Help</Heading>
//         <p>For help accessing your account, please contact support.</p>
//     </MyPopover>
// </DialogTrigger>;
