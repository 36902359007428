import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from '../../components/Modal';
import PropTypes from 'prop-types';
import moment from 'moment';
const valEmp = __CONFIG__.empresa;

function FormSeleccionarPeriodoNomina(props) {
    const {
        periodoL,
        listarPeriodos,
        periodos_nomina
    } = props;

    const [id, setId] = useState("");
    const [idPer, setIdPer] = useState("");
    
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    
    const handleChange = e => {
        listarPeriodos(e.target.value);
        setId(e.target.value);
    }

    const handleChangeP = e => {
        setIdPer(e.target.value);
    }
    
    const [estadoModal, cambiarEstadoModal] = useState(true);
    

    return (
        <div className='container'>
            <Modal
                estado={estadoModal}
                cambiarEstado={cambiarEstadoModal}
                titulo="Seleccionar periodo horas extras"
                botonCerrar="false"
            >
                {periodoL &&  
                   <div className="form-floating mb-3">
                        <select className="form-select" id="periodo"
                            value={id} onChange={handleChange} >
                            <option selected>Seleccionar...</option>
                            {periodoL.map(mes =>
                                <option key={mes.value} value={mes.value}>{mes.label}</option>)
                            }
                        </select>
                        <label className='form-label' htmlfor="periodo">Periodo</label>
                    </div>
                }
                {id && periodos_nomina.length > 0 && 
                   <div className="form-floating">
                        <select className="form-select" id="periodoN"
                            value={idPer} onChange={handleChangeP} >
                            <option selected>Seleccionar...</option>
                            {periodos_nomina.map(mes =>
                                <option key={mes.id} value={mes.id}>{mes.periodo_nomina} --- {mes.numero} --- [ {moment(mes.fecha_inicio).format("DD/MM/YYYY")} ] [ {moment(mes.fecha_fin).format("DD/MM/YYYY")} ]</option>)
                            }
                        </select>
                        <label className='form-label' htmlfor="periodo">Periodo nomina</label>
                    </div>
                }
                <br />
                {valEmp === 'fage' &&
                    <Contenido>
                        {periodos_nomina.length > 0 &&
                            (idPer &&
                            <a
                                href={`/#/lfg/nomina/${id_emp[6]}/horas_extras/${idPer}`}
                                className='btn mr-2 mb-3 btn-primary'
                            >
                                Seleccionar
                            </a>
                            )
                        }
                    </Contenido>
                }
                {valEmp === '' &&
                    <Contenido>
                        {periodos_nomina.length > 0 &&
                            (idPer &&
                            <a
                                href={`/#/nomina/${id_emp[5]}/horas_extras/${idPer}`}
                                className='btn mr-2 mb-3 btn-primary'
                            >
                                Seleccionar
                            </a>
                            )
                        }
                    </Contenido>
                }
            </Modal>
        </div>
    );

}
FormSeleccionarPeriodoNomina.propTypes = {
    periodoL: PropTypes.string.isRequired,
};

export default (FormSeleccionarPeriodoNomina);

const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;