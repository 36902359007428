import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import Modal from '../../../components/components/ModalPoliza'
import NavbarModulo from '../../components/NavbarModulo';

import {
    renderField,
    renderDayPickerB,
    SelectField,
    AsyncSelectField,
    renderCurrencyFloat,
    renderFieldCheck,
    renderNumber,
    renderFieldCheckB,
    renderCurrencyFloatTC
} from "../../Utils/renderField/renderField";

function SaldosInicialesForm(props) {
    const {
        handleSubmit,
        crear,
        lectura,
        listarClientes,
        registroCliente,
        obtenerPaises,
        listaEs,
        modulo,
        datos
    } = props;

    let autoFocus = true;
    const editar = window.location.href.includes('editar');
    let titulo = editar ? 'Modificar factura / saldo inicial' : 'Crear factura / saldo inicial';
    let disabled = false;
    if (crear === false && editar === false) {
        disabled = true;
        titulo = 'Ver factura / saldo inicial'
    }

    const [dolares, setDolares] = useState(editar === true ? lectura.en_dolares : false);
    const [estadoModal, cambiarEstadoModal] = useState(false);
    const tiposCL = [
        {
            value: 'L',
            label: 'LOCAL'
        },
        {
            value: 'E',
            label: 'EXTERIOR'
        },
        {
            value: 'P',
            label: 'PERSONA'
        },
    ];

    const onCheckboxClicked = (idx, isChecked) => {
        setDolares(isChecked);
    }
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    return (
        <div>
            <NavbarModulo
                modulo={modulo}
                titulo={titulo}
            />
            <br />
            <div className="mb-4 card card-small text-white bg-dark mb-3">
                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                    <div className="d-flex flex-column flex-1 mx-4">
                        {crear === false && editar == false &&
                            <div className='row mb-2'>
                                <div className='col-3'>
                                    <Field
                                        name="codigo"
                                        placeholder="Codigo establecimiento"
                                        className="form-control"
                                        component={renderField}
                                        disabled={true}
                                        msj="Código establecimiento"
                                    />
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="establecimiento"
                                        placeholder="Nombre establecimiento"
                                        className="form-control"
                                        component={renderField}
                                        disabled={true}
                                        msj="Nombre establecimiento"
                                    />
                                </div>
                            </div>
                        }
                        {editar === true &&
                            <div className='row mb-2'>
                                <div className='col-3'>
                                    <Field
                                        name="codigo"
                                        placeholder="Codigo establecimiento"
                                        className="form-control"
                                        component={renderField}
                                        disabled={true}
                                        msj="Código establecimiento"
                                    />
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="establecimiento"
                                        placeholder="Nombre establecimiento"
                                        className="form-control"
                                        component={renderField}
                                        disabled={true}
                                        msj="Nombre establecimiento"
                                    />
                                </div>
                            </div>
                        }
                        {crear === true &&
                            <div className='row mb-2'>
                                <div className='col-3'>
                                    <div className="form-floating">
                                        <input
                                            placeholder="Codigo establecimiento"
                                            type="text"
                                            autocomplete="off"
                                            name="codigo"
                                            className="form-control"
                                            value={listaEs.codigo}
                                            disabled={true}
                                        />
                                        <label className='form-label' htmlFor="codigo">
                                            Código establecimiento
                                        </label>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className="form-floating">
                                        <input
                                            placeholder="Nombre"
                                            type="text"
                                            autocomplete="off"
                                            name="nombre"
                                            className="form-control"
                                            value={listaEs.nombre_comercial}
                                            disabled={true}
                                        />
                                        <label className='form-label' htmlFor="nombre">
                                            Nombre establecimiento
                                        </label>
                                    </div>
                                </div>
                            </div>
                        }

                        <div className='row mb-2'>
                            <div className='col-3'>
                                <Field
                                    autoFocus={autoFocus}
                                    disabled={disabled}
                                    name="fechaRegistro"
                                    component={renderDayPickerB}
                                    msj="Fecha factura"
                                />
                            </div>
                            {((crear === false && editar == false) || editar === true) &&

                                <div className='col-6'>
                                    <Field
                                        name="nombreCli"
                                        placeholder="Proveedor"
                                        className="form-control"
                                        component={renderField}
                                        disabled={true}
                                        msj="Proveedor"
                                    />
                                </div>
                            }
                            {(crear === true) &&
                                <div className='col-6'>
                                    <Field
                                        name="cliente_documento"
                                        placeholder="Cliente..."
                                        className="form-control-find"
                                        component={AsyncSelectField}
                                        loadOptions={listarClientes}
                                        msj="Clientes"
                                    />
                                </div>
                            }
                            {(crear === true) &&
                                <div className='col-3 mt-2'>
                                    <button
                                        className='btn mr-2 mb-3 btn-secondary'
                                        onClick={() => cambiarEstadoModal(!estadoModal)}
                                    >
                                        Crear nuevo cliente
                                    </button>
                                </div>
                            }
                        </div>
                        {(editar === true) &&
                            <div className='row'>
                                <div className='col-3'>
                                </div>
                                <div className='col-6'>
                                    <Field
                                        name="cliente_documento"
                                        placeholder="Cliente..."
                                        className="form-control-find"
                                        component={AsyncSelectField}
                                        loadOptions={listarClientes}
                                        msj="Clientes"
                                    />
                                </div>
                            </div>

                        }

                        <div className='row mt-1'>
                            {(crear === false && editar == false) ? (
                                <div className='col-3'>
                                    <Field
                                        disabled={true}
                                        name="en_dolares"
                                        label="Dolares"
                                        component={renderFieldCheck}
                                    />
                                </div>
                            ) :
                                <div className='col-3'>
                                    <Field
                                        name="en_dolares"
                                        checked={dolares}
                                        label="Dolares"
                                        component={renderFieldCheckB}
                                        onChange={onCheckboxClicked}
                                    />
                                </div>  
                            }
                        </div>

                        <div className='row mb-2'>
                            <div className='col-3'>
                                <Field
                                    name="serie"
                                    placeholder="Nombre"
                                    className="form-control"
                                    component={renderField}
                                    disabled={disabled}
                                    msj="Serie"
                                />
                            </div>
                            <div className='col-3'>
                                <Field
                                    name="numero"
                                    placeholder="Dias credito"
                                    className="form-control"
                                    component={renderField}
                                    disabled={disabled}
                                    msj="Numero"
                                />
                            </div>
                            <div className='col-3'>
                                <Field
                                    name="total"
                                    placeholder="Saldo"
                                    className="form-control"
                                    component={renderCurrencyFloat}
                                    disabled={disabled}
                                    msj="Saldo"
                                />
                            </div>
                            <div className='col-3'>
                                <Field
                                    name="tipo_cambio"
                                    placeholder="Tipo de cambio"
                                    className="form-control"
                                    component={renderCurrencyFloatTC}
                                    disabled={disabled}
                                    msj="Tipo de cambio"
                                />
                            </div>

                        </div>
                        <div>
                            <Field
                                id="descripcion"
                                name="descripcion"
                                placeholder="Descripcion"
                                component={renderField}
                                className="form-control"
                                disabled={disabled}
                                msj="Descripción"
                            />
                        </div>
                        <div className='d-flex flex-row mt-3'>
                            {disabled == false &&
                                <button
                                    className='btn mr-2 mb-3 btn-primary'
                                    onClick={handleSubmit}
                                >
                                    {editar ? 'Modificar' : 'Guardar'}
                                </button>
                            }
                            <a
                                href={`/#/facturacion/${id_emp[5]}/saldos`}
                                className='btn btn-secondary mb-3'
                            >
                                Regresar
                            </a>
                        </div>
                        <Modal
                            estado={estadoModal}
                            cambiarEstado={cambiarEstadoModal}
                            titulo="Crear cliente"
                            botonCerrar="true"
                        >
                            <div className="d-flex flex-column flex-1 mx-4">

                                <div className='mb-2 w-25'>
                                    <Field
                                        autoFocus={autoFocus}
                                        name="identificador"
                                        placeholder="Identificador"
                                        className="form-control"
                                        component={renderField}
                                        msj="Identificador"
                                    />
                                </div>
                                <div className='row'>
                                    <div className='mb-2 col-8'>
                                        <Field
                                            name="nombre"
                                            placeholder="Nombre"
                                            className="form-control"
                                            component={renderField}
                                            msj="Nombre"
                                        />
                                    </div>
                                    {obtenerPaises &&
                                        <div className='col-4'>
                                            <div className="form-floating">
                                                <Field
                                                    name="pais"
                                                    options={obtenerPaises}
                                                    crear={crear}
                                                    component={SelectField}
                                                    className="form-select"
                                                    msj="Pais"
                                                />
                                            </div>
                                        </div>
                                    }
                                </div>

                                <div className='mb-2 w-75'>
                                    <Field
                                        name="direccion"
                                        placeholder="Direccion"
                                        className="form-control"
                                        component={renderField}
                                        msj="Dirección"
                                    />
                                </div>
                                <div className='row'>
                                    <div className='col-4'>
                                        <Field
                                            name="telefono"
                                            placeholder="Telefono"
                                            className="form-control"
                                            component={renderField}
                                            msj="Telefono"
                                        />
                                    </div>
                                    <div className='col-4'>
                                        <Field
                                            name="correo"
                                            placeholder="Correo electrónico"
                                            className="form-control"
                                            component={renderField}
                                            msj="Correo electronico"
                                        />
                                    </div>
                                    <div className='col-4'>
                                        <Field
                                            name="dias_credito"
                                            placeholder="Dias credito"
                                            className="form-control"
                                            component={renderNumber}
                                            msj="Dias crédito"
                                        />
                                    </div>
                                </div>
                                <div className='row mt-2'>
                                    <div className='col-4'>
                                        <Field
                                            name="limite_credito"
                                            placeholder="Limite credito"
                                            className="form-control"
                                            component={renderNumber}
                                            msj="Limite crédito"
                                        />
                                    </div>
                                    <div className='col-4'>
                                        <div className="form-floating">
                                            <Field
                                                name="tipo_cliente"
                                                options={tiposCL}
                                                component={SelectField}
                                                className="form-select"
                                                msj="Tipo cliente"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex flex-row mt-3'>

                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={() => registroCliente(datos)}
                                    >
                                        Guardar
                                    </button>

                                    <button
                                        className='btn mr-2 mb-3 btn-secondary'
                                        onClick={() => cambiarEstadoModal(false)}
                                    >
                                        Salir
                                    </button>
                                </div>
                            </div>
                        </Modal>
                    </div>
                </div>
            </div>
        </div>
    );
}

SaldosInicialesForm.propTypes = {
    handleSubmit: PropTypes.func,
    listarProveedores: PropTypes.object,
    crear: PropTypes.bool,
    lectura: PropTypes.object,
    tipos: PropTypes.object,
    registroCliente: PropTypes.func,
    obtenerPaises: PropTypes.object,
    modulo: PropTypes.object,
    datos: PropTypes.object,
};

export default reduxForm({
    form: 'FormFacturaSaldo',
})(SaldosInicialesForm);
