import React, { Component } from 'react';
import Grid from '../../Utils/GridFiltro';
import PropTypes from 'prop-types';
import { standardActions } from '../../Utils/GridFiltro/StandardActions';
import { FaPlusCircle } from 'react-icons/fa';

class TipoProductoList extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: true
    };

    componentDidMount = () => {
        const { listar } = this.props;
        listar();
    }
    render() {
        const { data, loader, eliminar } = this.props;
        let ruta = window.location.href;
        let id_emp = ruta.split('/');
        return (
            <React.Fragment>

                <div className="d-flex flex-row justify-content-between align-items-center p-2">
                    <h3 className="p-2 text-md-center title">
                        Tipo de Productos
                        <span>  </span>
                        <a
                            href={`/#/punto_venta/${id_emp[5]}/tipo_producto/crear`}
                            className='btn btn-primary text-lg'>{<FaPlusCircle />}  Nuevo
                        </a>
                    </h3>
                </div>

                {data &&
                    <Grid
                        hover
                        striped
                        data={data}
                        loading={loader}
                        headerStyle={{ background: 'black' }}
                    //onPageChange={onPageChange}
                    //onSortChange={onSortChange} 
                    >
                        <TableHeaderColumn
                            isKey
                            dataField="tipo"
                            headerAlign="center"
                            dataAlign='left'
                            dataSort
                            thStyle={
                                { color: 'white' }}
                            tdStyle={
                                { color: 'white' }}
                        >
                            Tipo de producto
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            thStyle={
                                { color: 'white' }}
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            dataFormat={standardActions({
                                editar: "tipo_producto",
                                ver: "tipo_producto",
                            })}

                        >
                            Acciones
                        </TableHeaderColumn>
                    </Grid>
                }
            </React.Fragment>
        );
    }
}

export default TipoProductoList;
