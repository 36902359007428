import { NotificationManager } from "react-notifications";
import { api } from "api";
import { useDispatch } from "react-redux";
import { getPeriodoId } from "../utils";

const baseurl = "laboratorio/informes_patologia";

export async function convertUrlsToFiles(items) {
    let files = [];
    for (let item of items) {
        let response = await fetch(item.lamina);
        let data = await response.blob();
        let metadata = { type: data.type };
        let fileName = item.lamina.substring(item.lamina.lastIndexOf("/") + 1);
        let file = new File([data], fileName, metadata);
        files.push(file);
    }
    return files;
}

export const getList = () => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let empresa = id_emp[5];
    const periodo = getPeriodoId();
    const params = { empresa, periodo };
    return api
        .get(baseurl, params)
        .then((response) => {
            let data = [];
            Object.keys(response).forEach((key) => {
                response[key].forEach((item) => {
                    data.push(item);
                });
            });
            return data;
        })
        .catch((error) => {
            return [];
        });
};

export const getEntry = (id) => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let empresa = id_emp[5];
    const params = { empresa };
    return api
        .get(baseurl + `/${id}`, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return [];
        });
};

export const crear = (data) => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    const formData = {
        ...data,
        empresa: id_emp[5],
    };
    return api
        .post(baseurl, formData)
        .then(() => {
            NotificationManager.success(writeSuccessMsg, "Exito", 10000);
        })
        .catch((error) => {
            try {
                error.non_field_errors.map((err) => {
                    NotificationManager.error(err, "Error", 10000);
                });
                return;
            } catch (exception) {
                Object.keys(error).forEach((field) => {
                    error[field].forEach((errorMessage) => {
                        NotificationManager.error(
                            `${field}: ${errorMessage}`,
                            "Error",
                            10000
                        );
                    });
                });
            }

            console.log(error);
            NotificationManager.error("", "Error", 10000);
        })
        .catch((error) => {
            NotificationManager.error("Ocurrio un error", "Error", 2000);
            return false;
        });
};

export const editar = (data, id) => {
    let ruta = window.location.href;
    let empresa = ruta.split("/")[5];
    const formData = {
        ...data,
        empresa,
    };
    return api
        .put(baseurl + `/${id}`, formData)
        .then(() => {
            NotificationManager.success(editSuccessMsg, "Exito", 3000);
            return true;
        })
        .catch((error) => {
            NotificationManager.error(error.msj, "Error", 2000);
            return false;
        });
};
export const eliminar = (id) => {
    let ruta = window.location.href;
    let empresa = ruta.split("/")[5];
    const formData = {
        empresa,
    };
    return api
        .eliminar(baseurl + `/${id}`, formData)
        .then(() => {
            NotificationManager.success("Eliminado correctamente", "Exito", 3000);
            return true;
        })
        .catch((error) => {
            NotificationManager.error(error.msj, "Error", 2000);
            return false;
        });
};

export const getInforme = (id, tipo) => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let empresa = id_emp[5];
    let informe = id;
    const params = { empresa, tipo, informe };

    return api
        .getPdf(baseurl + `/informe_patologia`, params)
        .then((response) => {
            const contentType = "application/pdf";
            if (response.headers["content-type"] === contentType) {
                const blob = new Blob([response.text], {
                    type: `${contentType};charset=utf-8`,
                });
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = "informe_patologia.pdf";
                window.open(link, "_blank");
                return false;
            }
        })
        .catch((error) => {
            const infoMessage = "Informacion";
            const errorMessage = "Error";
            if (error.status === 404) {
                NotificationManager.info(error.body.msj, infoMessage, 5000);
            } else if (error.status === 500) {
                NotificationManager.error(
                    "Ocurrió un error al generar informe",
                    errorMessage,
                    2000
                );
            } else {
                NotificationManager.error(
                    "Unexpected error occurred",
                    errorMessage,
                    2000
                );
            }
        });
};

export const getInformeCitologia = (id) => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let empresa = id_emp[5];
    let tipo = "citologia";
    let informe = id;
    const params = { empresa, tipo, informe };

    return api
        .getPdf(
            "laboratorio/informes_patologia_citologia/informe_patologia",
            params
        )
        .then((response) => {
            const contentType = "application/pdf";
            if (response.headers["content-type"] === contentType) {
                const blob = new Blob([response.text], {
                    type: `${contentType};charset=utf-8`,
                });
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = "informe_patologia_citologia.pdf";
                window.open(link, "_blank");
                return false;
            }
        })
        .catch((error) => {
            const infoMessage = "Informacion";
            const errorMessage = "Error";
            if (error.status === 404) {
                NotificationManager.info(error.body.msj, infoMessage, 5000);
            } else if (error.status === 500) {
                NotificationManager.error(
                    "Ocurrió un error al generar informe",
                    errorMessage,
                    2000
                );
            } else {
                NotificationManager.error(
                    "Unexpected error occurred",
                    errorMessage,
                    2000
                );
            }
        });
};
