import React, { Component } from 'react';
import Formulario from './DevolucionProductoForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";

class DevolucionProducto extends Component{
    state = {
        crear: true,
    }

    componentDidMount = () => {
        const { match, leer, getModulo,leerPeriodoD, vaciar,
        leerDetalleProductos, parametrosEmpresa } = this.props;
        const id = match.params.id;
        if (id){
            this.setState({crear: false});
            leer(id);
        }
        let ruta = window.location.href;
        let periodo = ruta.split('/');
        leerPeriodoD(periodo[5], periodo[7]);
        getModulo(periodo[5], periodo[4]);
        parametrosEmpresa();
        vaciar();
        leerDetalleProductos('00000000-0000-0000-0000-000000000000');
    }
    
    render(){
        const { loader, modulo, periodoD, datos, listarEnvios, pro, registroDevolucion,
            detalleProducto, detalleCon, envio, eliminarNota,
            listarProductos, nuevaLineaInventario, eliminarLinea,
            numeroS, listarFases, agregarLinea, listarPilotos,
            listarClientes, listarBodegas, listarSeries, numeroSerie } = this.props;
        const { crear } = this.state;
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    crear = {crear}
                    modulo = {modulo}
                    periodoD = {periodoD}
                    datos = {datos}
                    detalleCon = {detalleCon}
                    registroDevolucion = {registroDevolucion}
                    listarEnvios = {listarEnvios}
                    listarProductos = {listarProductos}
                    detalleProducto = {detalleProducto}
                    pro = {pro}
                    envio = {envio}
                    numeroS = {numeroS}
                    listarFases = {listarFases}
                    agregarLinea = {agregarLinea}
                    listarPilotos = {listarPilotos}
                    nuevaLineaInventario = {nuevaLineaInventario}
                    listarClientes = {listarClientes}
                    listarBodegas = {listarBodegas}
                    listarSeries = {listarSeries}
                    numeroSerie = {numeroSerie}
                    eliminarNota = {eliminarNota}
                    eliminarLinea = {eliminarLinea}
                />
            </LoadMask>
        );
    }
}

export default DevolucionProducto
