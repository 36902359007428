import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/visitador_medico/visitador';
import Visitador from './Visitador';


const ms2p = (state) => {
  return {
    ...state.vm_visitador,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Visitador);
