import React, { useState, useEffect } from "react";
import DataTableBase from "../../components/DataTableWSearch";
import GenBtn from "../../components/GenBtn";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Banner from "../common/Banner";
import { getList, getInforme, getInformeCitologia, eliminar } from "./actions";
import * as Fa from "react-icons/fa";
import LoadMask from "../../Utils/LoadMask/LoadMask";
import {
    MenuComponent,
    MenuItemComp,
    MenuLink,
} from "../../Utils/Components/MenuComponent";
import OptionsBar from "../common/OptionsBar";
import { getPeriodo } from "../utils";
const BannerText = "Informes de patologia";

function Listado({ history, ...props }) {
    const [tableData, setTableData] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const periodo = getPeriodo();

    const columns = [
        {
            name: "Numero de patologia",
            selector: (row) => row.patologia.numero_patologia,
            sortable: false,
        },
        {
            name: "Tipo",
            selector: (row) => row.patologia.tipo,
            sortable: false,
        },
        {
            cell: (row) => (
                <GenBtn
                    textColor="hsl(3, 95%, 36%)"
                    background="hsl(3, 95%, 36%, 0.2)"
                    toolTipText="Generar PDF"
                    onPress={(e) => {
                        if (row.patologia.tipo === "Citologia") {
                            getInformeCitologia(row.id, "citologia");
                            return;
                        }
                        getInforme(row.id, row.patologia.tipo.toLowerCase());
                    }}
                >
                    <Fa.FaFilePdf />
                </GenBtn>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            cell: (row) => (
                <GenBtn
                    buttonColor="rgb(255, 13, 0)"
                    toolTipText="Eliminar"
                    onPress={(e) => {
                        eliminar(row.id);
                        fetchData();
                    }}
                >
                    <Fa.FaTrash />
                </GenBtn>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        // {
        //     cell: (row) => (
        //         <GenBtn
        //             textColor="hsl(0, 0%, 80%)"
        //             background="hsla(0, 100%, 100%, 0.2)"
        //             toolTipText="Editar"
        //             onPress={(e) => {
        //                 history.push(
        //                     `${history.location.pathname}/editar/${row.id}`
        //                 );
        //             }}
        //         >
        //             <Fa.FaPen />
        //         </GenBtn>
        //     ),
        //     ignoreRowClick: true,
        //     allowOverflow: true,
        //     button: true,
        // },
        // {
        //     cell: (row) => (
        //         <GenBtn
        //             textColor="hsl(50, 50%, 80%)"
        //             background="hsla(50, 50%, 80%, 0.2)"
        //             toolTipText="Ver"
        //             onPress={(e) => {
        //                 history.push(
        //                     `${history.location.pathname}/ver/${row.id}`
        //                 );
        //             }}
        //         >
        //             <Fa.FaEye />
        //         </GenBtn>
        //     ),
        //     ignoreRowClick: true,
        //     allowOverflow: true,
        //     button: true,
        // },
    ];

    async function fetchData() {
        setIsLoading(true)
        setTableData([]);
        const data = await getList();
        setTableData(data);
        setIsLoading(false);
    }
    useEffect(() => {
        fetchData();
    }, [periodo]);

    return (
        <main>
            <OptionsBar />
            <Banner>{BannerText}</Banner>
            <MenuComponent
                label="Crear"
                onAction={(key) => (location.href += `/crear/${key}`)}
            >
                <MenuItemComp id="citologia">
                    <div style={{ padding: "0.25rem 0.75rem" }}>Citologia</div>
                </MenuItemComp>
                <MenuItemComp id="afiliada">
                    <div style={{ padding: "0.25rem 0.75rem" }}>Afiliada</div>
                </MenuItemComp>
                <MenuItemComp id="privada">
                    <div style={{ padding: "0.25rem 0.75rem" }}>Privada</div>
                </MenuItemComp>
                <MenuItemComp id="inmunohistoquimica">
                    <div style={{ padding: "0.25rem 0.75rem" }}>
                        Inmunohistoquimica
                    </div>
                </MenuItemComp>
            </MenuComponent>
            <LoadMask loading={isLoading} color="#bd0034" blur={true}>
                <DataTableBase data={tableData} columns={columns} />
            </LoadMask>
        </main>
    );
}

export default withRouter(Listado);
