import { handleActions } from 'redux-actions';
import { NotificationManager } from "react-notifications";
import { initialize as initializeForm } from 'redux-form';
import { api } from "api";
import Swal from 'sweetalert2';

const LOADER = 'LOADER';
const MODULO = 'MODULO';
const CUENTAS = 'CUENTAS';

const setLoader = loader => ({
    type: LOADER,
    loader,
});


export const listarCuentas = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    dispatch(setLoader(true));
    api.get('/visitador_medico/cuenta_contable', params).then((response)=>{
        dispatch({ type: CUENTAS, data: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar las cuentas contables',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const registroCuenta = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormCuentasContablesVM.values;
    if (data.numero_cuenta === undefined || data.numero_cuenta === ''){
        Swal.fire({
            title: 'Cuentas contables',
            text: 'Debe de ingresar número de cuenta',
            type: 'error',
        });
    }else{
        const formData = {
            nombre: data.tipo,
            cuenta: data.numero_cuenta,
            empresa: id_emp[5],
        }
        dispatch(setLoader(true));
        api.post('/visitador_medico/cuenta_contable/', formData).then((response) => {
            NotificationManager.success(
                'Cuenta contable agregada',
                'Exito',
                3000
            );
            dispatch(initializeForm('FormCuentasContablesVM', response ));
            dispatch(listarCuentas());
        }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                2000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        if (error.status === 401){
            NotificationManager.error(
              error.body.msj,
              'Error',
              0
            );
        }
        if (error.status === 400){
            NotificationManager.error(
              'Ocurrió un error en buscar modulo',
              'Error',
              0
            );
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const eliminarCuenta = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`/visitador_medico/cuenta_contable/${id}`).then(() => {
        NotificationManager.success(
            'Cuenta borrada correctamente',
            'Exito',
            3000
        );
        dispatch(listarCuentas());
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const actions = {
    getModulo,
    registroCuenta,
    listarCuentas,
    eliminarCuenta
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [CUENTAS]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
};

export const initialState = {
    loader: false,
    modulo: [],
    data: []
};

export default handleActions(reducers, initialState)
 