import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BootstrapTable } from 'react-bootstrap-table';
import LoadMask from "../LoadMask/LoadMask";


export default class Grid extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false
    };

    render() {
        const {
            loading,
            data,
            page,
            remote = true,
            expandableRow,
            expandComponent,
            cellEditProp,
            afterSave,
            onPageChange,
            onSortChange,
            pagination,
            expanding,
            onExpand,
            footer,
            trClassName,
            ...other
        } = this.props;
        const options = {
            sizePerPage: 10,
            hideSizePerPage: true,
            paginationSize: 5,
            alwaysShowAllBtns: true,
            noDataText: loading ? 'Cargando...' : <span className='title'>No hay datos</span>,
            page,
            onPageChange: onPageChange ? onPageChange : () => { },
            onSortChange: onSortChange ? onSortChange : () => { },
            onExpand: onExpand
        };
        const footerData = [
            [
                {
                    label: <strong className='title'>Total:</strong>,
                    columnIndex: 1,
                    align: 'left',
                },
                {
                    label: 'Total value',
                    columnIndex:2,
                    align: 'right',
                    formatter: (tableData) => {
                        const cantidad = tableData.reduce(function (resultado, elemento) {
                            return parseFloat(resultado) + parseFloat(elemento.cantidad);
                        }, 0.00);
                        return (
                            <strong className='title'>{new Intl.NumberFormat("en-US", { minimumFractionDigits: 6 }).format(cantidad)}</strong>
                        );
                    }
                },
                {
                    label: 'Total value',
                    columnIndex: 3,
                    align: 'right',
                    formatter: (tableData) => {
                        const costo = tableData.reduce(function (resultado, elemento) {
                            return parseFloat(resultado) + parseFloat(elemento.costo_unitario);
                        }, 0.00);
                        return (
                            <strong className='title'>{new Intl.NumberFormat("en-US", { minimumFractionDigits: 6 }).format(costo)}</strong>
                        );
                    }
                },
                {
                    label: 'Total value',
                    columnIndex: 4,
                    align: 'right',
                    formatter: (tableData) => {
                        const total = tableData.reduce(function (resultado, elemento) {
                            return parseFloat(resultado) + parseFloat(elemento.total);
                        }, 0.00);
                        return (
                            <strong className='title'>{new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(total)}</strong>
                        );
                    }
                },
            ]
        ];

        return (
            <div>
                <LoadMask loading={loading} dark blur>
                    <BootstrapTable
                        expandableRow={expandableRow}
                        expandComponent={expandComponent}
                        trClassName={trClassName}
                        cellEdit={cellEditProp}
                        data={loading ? [] : data}
                        afterSaveCell={afterSave}
                        remote={remote}
                        footerData={footerData}
                        footer={footer}
                        fetchInfo={{ dataTotalSize: data.count }}
                        options={options}
                        {...other}
                    />
                </LoadMask>
            </div>

        )
    }
}
