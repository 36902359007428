import React from "react";

export default function BareDialog({
    dialogRef,
    className,
    children,
    id,
    style,
    noClosingOverlay,
}) {
    function dialogOverlay(e) {
        if (noClosingOverlay) return "";
        const dialogDimensions = dialogRef.current.getBoundingClientRect();
        if (
            e.clientX < dialogDimensions.left - 55 ||
            e.clientX > dialogDimensions.right + 55 ||
            e.clientY < dialogDimensions.top - 55 ||
            e.clientY > dialogDimensions.bottom + 55
        ) {
            dialogRef.current.setAttribute("closing", "");
            dialogRef.current.addEventListener(
                "animationend",
                () => {
                    dialogRef.current.removeAttribute("closing");
                    dialogRef.current.close();
                },
                { once: true }
            );
        }
    }

    return (
        <dialog
            className={className}
            id={id}
            ref={dialogRef}
            onClick={(e) => dialogOverlay(e)}
            style={style}
        >
            {children}
        </dialog>
    );
}
