import React, { Component } from 'react';
import Formulario from './SeleccionarPeriodoNominaForm';


class SeleccionarPeriodo extends Component{

    componentDidMount = () => {
        const { periodosList } = this.props;
        periodosList();
    }
    render(){
        const { periodoL, datos } = this.props;
        return(
           
            <Formulario
                periodoL={periodoL}
                datos={datos}
            />
           
        );
    } 
}

export default SeleccionarPeriodo;