import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import { validate, validators } from 'validate-redux-form';
import PropTypes from 'prop-types';
import NavbarModulo from '../../components/NavbarModulo';
import NavbarModuloLF from '../../components/NavbarModuloFage';
const valEmp = __CONFIG__.empresa;

import {
    renderField,
} from "../../Utils/renderField/renderField";

function LineaForm(props) {
    const { handleSubmit, crear, modulo } = props;
    
    const editar = window.location.href.includes('editar');
    let titulo = editar ? 'Modificar concentración' : 'Crear concentración';
    let disabled = false;
    if (crear === false && editar === false) {
        disabled = true;
        titulo = 'Ver concentración'
    }
    let autoFocus = true;
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    return (
        <div>
            {valEmp === 'fage' &&
                <NavbarModuloLF
                    modulo={modulo}
                    titulo={titulo}
                />
            }
            {valEmp === '' &&
                <NavbarModulo
                    modulo={modulo}
                    titulo={titulo}
                />
            }
            <form onSubmit={handleSubmit}>
                <br />
                <div className="mb-4 card card-small text-white bg-dark mb-3">
                    <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                        <div className="d-flex flex-column flex-1 mx-4">
                            <div className='mb-2 row'>
                                <div className='col-6'>
                                    <Field
                                        autoFocus={autoFocus}
                                        name="descripcion"
                                        placeholder="Concentración"
                                        className="form-control"
                                        component={renderField}
                                        disabled={disabled}
                                        msj="Concentración"
                                    />
                                </div>
                            </div>
                            <div className='d-flex flex-row mt-3'>
                                {disabled == false &&
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        type='submit'
                                    >
                                        {editar ? 'Modificar' : 'Guardar'}
                                    </button>
                                }
                                {valEmp === 'fage' &&
                                    <a
                                        href={`/#/lfg/registro_sanitario/${id_emp[6]}/concentracion`}
                                        className='btn btn-secondary mb-3'
                                    >
                                        Regresar
                                    </a>
                                }
                                {valEmp === '' &&
                                    <a
                                        href={`/#/registro_sanitario/${id_emp[5]}/concentracion`}
                                        className='btn btn-secondary mb-3'
                                    >
                                        Regresar
                                    </a>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

LineaForm.propTypes = {
    handleSubmit: PropTypes.func,
    crear: PropTypes.bool,
    lectura: PropTypes.object,
    modulo: PropTypes.object,
};

export default reduxForm({
    form: 'FormConcentracion',
    validate: (data) => {
        return validate(data, {
            descripcion: validators.exists()('Este campo es requerido'),
        });
    },
})(LineaForm);
