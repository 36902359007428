import React from "react";
import {
    Button,
    Dialog,
    DialogTrigger,
    Heading,
    OverlayArrow,
    Popover,
    Switch,
} from "react-aria-components";
import GenBtn from "./GenBtn";
import "./Popover.css";

export default function InfoPopover({ children, ...props }) {
    return (
        <DialogTrigger>
            <GenBtn aria-label="Help" title="Informacion">
                ⓘ
            </GenBtn>
            <Popover className="info-popover" {...props}>
                <OverlayArrow>
                    <svg width={12} height={12} viewBox="0 0 12 12">
                        <path d="M0 0 L6 6 L12 0" />
                    </svg>
                </OverlayArrow>
                <Dialog>{children}</Dialog>
            </Popover>
        </DialogTrigger>
    );
}
