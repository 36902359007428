import React, { Component } from 'react';
import Formulario from './RegistroMarcaForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import NavbarModulo from '../../components/NavbarModulo';

class RegistroMarca extends Component{
    state = {
        crear: true,
    }

    componentDidMount = () => {
        const { match, leerMod, getModulo, listarAgentes, listarStatus,
            listarTitulares, listarComercializada,
            listarPrincipio, listarPaises } = this.props;
        const id = match.params.id;
        if (id){
            this.setState({crear: false});
            leerMod(id);
        }
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        getModulo(modulo[5],modulo[4])
        listarAgentes();
        listarStatus();
        listarTitulares();
        listarComercializada();
        listarPrincipio();
        listarPaises();
    }
    setLogo = (logo) => {
        this.setState({logo});
    };

    registroMarca = (data) => {
        const { registroMarca } = this.props;
        registroMarca( {...data, logo: null},
        [{ "file": this.state.logo, "name": 'logo' },])
    }
    
    modificarMarca = (data) => {
        const { modificarMarca } = this.props;
        modificarMarca( {...data, logo: null},
        [{ "file": this.state.logo, "name": 'logo' },])
    }

    render(){
        const { loader, modulo, agentes, lectura,
            paises, status, titulares,
            comercial, principio_activo, listaPais } = this.props;
        const { crear } = this.state;
        const funcionEnvio = crear ? this.registroMarca : this.modificarMarca;
        const editar = window.location.href.includes('editar');
        let titulo = editar ? 'Modificar marca' : 'Crear marca';
        let disabled = false;
        if (crear === false && editar === false) {
            disabled = true;
            titulo = 'Ver marca'
        }
        return(
            <div>
                <NavbarModulo
                    modulo={modulo}
                    titulo={titulo}
                />
                <br />
                <Tabs
                    tabBarPosition="top"
                    renderTabBar={() => <ScrollableInkTabBar />}
                    renderTabContent={() => <TabContent />}
                    >
                    <TabPane tab="Datos marca" key="PRINCIPAL_TOP">
                        <div className="py-2 px-3">
                            <div className="mb-4 card card-small text-white bg-dark mb-1">
                                <div className="p-0 pt-4 d-flex flex-column flex-md-row">
                                    <div className="d-flex flex-column flex-1 mx-4">
                                        <LoadMask loading={loader} dark>
                                            <Formulario
                                                onSubmit = {funcionEnvio}
                                                crear = {crear}
                                                editar = {editar}
                                                modulo = {modulo}
                                                agentes = {agentes}
                                                paises = {paises}
                                                status = {status}
                                                setLogo = {this.setLogo}
                                                titulares = {titulares}
                                                comercial = {comercial}
                                                principio_activo = {principio_activo}
                                                lectura = {lectura}
                                                listaPais = {listaPais}
                                            />
                                        </LoadMask>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TabPane>
                </Tabs>
            </div>
        );
    }
}


export default RegistroMarca;
