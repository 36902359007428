import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/visitador_medico/tipo';
import Tipo from './Tipo';


const ms2p = (state) => {
  return {
    ...state.vm_tipo,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Tipo);

