import React from 'react';
import * as FaIcons from "react-icons/fa";
import * as BsIcons from "react-icons/bs";

export const SidebarDataLibroContable = [
    {
        title: 'Inicio',
        path: '/libros_contables',
        icon: <BsIcons.BsGrid1X2Fill />,
    },
    {
        title: 'Polizas',
        path: '/libros_contables/seleccionar',
        icon: <FaIcons.FaChalkboard />,
    },
    {
        title: 'Traslado de polizas',
        path: '/libros_contables/traslado',
        icon: <BsIcons.BsArrowRepeat />,
    },
    {
        title: 'Intercambiar cuentas en modulo bancos',
        path: '/libros_contables/intercambiar_cuentas',
        icon: <BsIcons.BsArrowRepeat />,
    },
    {
        title: 'Informes',
        path: '/libros_contables/informes',
        icon: <FaIcons.FaClipboard />,
    }
]

