import { connect } from 'react-redux';
import { actions } from '../../../redux/modules/visitador_medico/informes';
import Informe from './Informes';


const ms2p = (state) => {
  return {
    ...state.informeVM,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Informe);
