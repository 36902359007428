import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";

const LISTADO_INVERNADERO = 'LISTADO_INVERNADERO';
const GUARDAR_INVERNADERO = 'GUARDAR_INVERNADERO';
const DETALLE_FASES = 'DETALLE_FASES';
const LOADER = 'LOADER';
const PARAMETRO = 'PARAMETRO';
const MODULO = 'MODULO';
const LISTADO = 'LISTADO';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    dispatch(setLoader(true));
    api.get('/invernaderos', params).then((response)=>{
        dispatch({ type: LISTADO_INVERNADERO, data: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar invernaderos',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const registroInvernadero = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormInvernaderos.values;
    const detalle = localStorage.getItem('id_detalle_invernadero');
    const formData = {
        nombre: data.nombre,
        fase: data.fase.value,
        empresa: id_emp[5],
        detalle: detalle
    }
    dispatch(setLoader(true));
    api.post('/invernaderos/', formData).then((response) => {
        if (detalle){
            dispatch(initializeForm('FormInvernaderos', response));
            dispatch(leerDetalleFases(response.id));
        }else{
            dispatch(initializeForm('FormInvernaderos', response));
            localStorage.setItem('id_detalle_invernadero', response.id);
            dispatch(leerDetalleFases(response.id));
        }
        NotificationManager.success(
            'LLave ingresada correctamente',
            'Exito',
            3000
        );
        
    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}           

export const modificarInvernadero = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const datos = getStore().form.FormInvernaderos.values;
    const id = datos.id;
    const formData = {
        id: id,
        nombre: datos.nombre,
    }
    dispatch(setLoader(true));
    api.put(`/invernaderos/${id}/`, formData).then(() => {
        NotificationManager.success(
            'Invernadero modificado correctamente',
            'Exito',
            3000
        );
        let ruta = `/produccion/${id_emp[5]}/invernaderos`
        dispatch(push(ruta));
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al modificar invernaderos',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/invernaderos/${id}`).then((response) => {
        dispatch(initializeForm('FormInvernaderos', response));
        localStorage.setItem('id_detalle_invernadero', response.id);
        dispatch(leerDetalleFases(response.id));
    }).catch((error)=>{
        console.log("error: ", error)
        NotificationManager.error(
            'Ocurrió un error al consultar cliente',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}


export const parametrosEmpresa = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let params = {id}
    dispatch(setLoader(true));
    api.get('/empresa/empresa_parametros', params).then((response) => {
        dispatch({type: PARAMETRO, datos: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar parametros',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


export const listarFases = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/fase_prd/buscar_fase', params).then(data => {
        const list_fase = [];
        if (data) {
            data.forEach(item => {
                list_fase.push({
                    value: item.id,
                    label: item.descripcion
                })
            })
        }
        return list_fase;
    }).catch((error) => {
        return [];
    })
}

const leerDetalleFases = id => (dispatch) => {
    api.get(`/invernaderos_detalle/${id}`).then((response)=>{
        dispatch({ type: DETALLE_FASES, detalleFase: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};

const eliminarLineaFase = id => (dispatch) => {
    const detalle = localStorage.getItem('id_detalle_invernadero');
    dispatch(setLoader(true));
    api.eliminar(`/invernaderos_detalle/${id}`).then(() => {
        dispatch(leerDetalleFases(detalle));
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar llave',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


export const actions = {
    listar,
    registroInvernadero,
    modificarInvernadero,
    leer,
    getModulo,
    parametrosEmpresa,
    listarFases,
    leerDetalleFases,
    eliminarLineaFase,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_INVERNADERO]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [DETALLE_FASES]: (state, { detalleFase }) => {
        return {
            ...state,
            detalleFase,
        };
    },
    [GUARDAR_INVERNADERO]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
    [PARAMETRO]: (state, { datos }) => {
        return {
            ...state,
            datos,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [LISTADO]: (state, { lista }) => {
        return {
            ...state,
            lista,
        };
    },
};

export const initialState = {
    loader: false,
    lectura: [],
    listaP: [],
    datos: [],
    modulo: [],
    lista: [],
    detalleFase: [],
    data: [],
    page: 1,
};

export default handleActions(reducers, initialState)