import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import Grid from '../../Utils/GridVDocumento';
import moment from 'moment';
import styled from 'styled-components';
import TablaDetalleMod from './NCDetalleMod';
import TablaDetalle from './NCDetalle';
import TablaVerDetalle from './NCVerDetalle';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import Swal from 'sweetalert2';
import {
    renderDayPickerB,
    renderField,
    renderNumber,
    SelectField,
    AsyncSelectField,
    renderCurrencyFloat,
    AsyncSelectFieldIn,
    renderCurrencyFloatTC,
} from "../../Utils/renderField/renderField";

function NCForm(props) {
    const { crear, lectura, editar, detalleNC, tipoCambio,
        leerDocumentos, doc, listarClientes, list_doc, periodoD,
        loader, bloqueo, eliminarDocumento, eliminarLineaAbonoNuevo,
        agregarLinea, fechaFinal, cuentaBan, detalleDocN,
        nuevaLineaDocumento, fechaRegistro, cambio, clienteD } = props;

    let autoFocus = true;

    const tipos = [
        {
            value: 'PAGO DE CLIENTES',
            label: 'PAGO DE CLIENTES'
        },
        {
            value: 'NO AFECTA A CLIENTES',
            label: 'NO AFECTA A CLIENTES'
        },
        {
            value: 'ANTICIPO DE CLIENTES',
            label: 'ANTICIPO DE CLIENTES'
        },
    ];

  
    const [tipo, setTipo] = useState(false);
    const [pagoD, setPagoD] = useState(false);
    const [editarT, setEditarT] = useState(true);
    const [nombre, setNombre] = useState('');
    const [cliente, setCliente] = useState('');

    const [tipoCambioD, setTipoCambioD] = useState(1.000000);

    let concepto = localStorage.getItem('conceptoNC');

    const handleChange = (e) => {
        if (editar === true ){
            setEditarT(false)
            setTipo(e.target.value);
            if (e.target.value === 'PAGO DE CLIENTES') {
                setPagoD(true);
                localStorage.setItem('conceptoNC', 'PAGO DE CLIENTES');
            }
            if (e.target.value === 'ANTICIPO DE CLIENTES') {
                setPagoD(false);
                localStorage.setItem('conceptoNC', 'ANTICIPO DE CLIENTES');
            }
            if (e.target.value === 'NO AFECTA A CLIENTES') {
                setPagoD(false);
                localStorage.setItem('conceptoNC', 'NO AFECTA A CLIENTES');
            }
        }else{
            setTipo(e.target.value);
            if (e.target.value === 'PAGO DE CLIENTES') {
                setPagoD(true);
                localStorage.setItem('conceptoNC', 'PAGO DE CLIENTES');
            }
            if (e.target.value === 'ANTICIPO DE CLIENTES') {
                setPagoD(false);
                localStorage.setItem('conceptoNC', 'ANTICIPO DE CLIENTES');
            }
            if (e.target.value === 'NO AFECTA A CLIENTES') {
                setPagoD(false);
                localStorage.setItem('conceptoNC', 'NO AFECTA A CLIENTES');
            }
        }

    }

    const estilo = {
        overflow: "auto",
        height: "200px",

    };
    
    const handleOnChange = value => {
        if (editar === true ){
            setCliente(value.value);
            if (localStorage.getItem('conceptoNC') === 'PAGO DE CLIENTES'){
                if (detalleNC.length > 0){
                    if (value.value === clienteD){
                        setNombre(value.label);
                        localStorage.setItem('ClienteNC', value.label);    
                    }else{
                        Swal.fire({
                            title: 'Nota de crédito',
                            text: 'Si cambia de cliente debe de quitar los documentos agregados',
                            type: 'error',
                        })
                    }
                }else{
                    leerDocumentos(value.value, fechaRegistro, cambio);
                    setNombre(value.label);
                    localStorage.setItem('ClienteNC', value.label);
                }
            }
        }else{
            if (localStorage.getItem('conceptoNC') === 'PAGO DE CLIENTES'){
                leerDocumentos(value.value, fechaFinal, cuentaBan.cambio);
            }
            setNombre(value.label);
            localStorage.setItem('ClienteNC', value.label);
        }
    };

    return (
        <div>
            {crear === false && editar == false &&
                <div className='row mb-2'>
                    <div className='col-3'>
                        <Field
                            name="cuenta"
                            placeholder="Cuenta"
                            className="form-control"
                            component={renderField}
                            disabled={bloqueo}
                            msj="Cuenta"
                        />
                    </div>
                    <div className='col-3'>
                        <Field
                            name="banco"
                            placeholder="Banco"
                            className="form-control"
                            component={renderField}
                            disabled={bloqueo}
                            msj="Banco"
                        />
                    </div>
                    <div className='col-3'>
                        <Field
                            name="moneda"
                            placeholder="Moneda"
                            className="form-control"
                            component={renderField}
                            disabled={bloqueo}
                            msj="Moneda"
                        />
                    </div>
                    <div className='col-3'>
                        <Field
                            name="tipo"
                            placeholder="Tipo"
                            className="form-control"
                            component={renderField}
                            disabled={bloqueo}
                            msj="Tipo"
                        />
                    </div>
                </div>

            }
            {(crear === true || editar == true) &&
                <div className='row mb-2'>
                    <div className='col-3'>
                        <div className="form-floating">
                            <input
                                placeholder="Cuenta"
                                type="text"
                                autocomplete="off"
                                name="cuenta"
                                className="form-control"
                                value={editar ? lectura.cuenta : cuentaBan.numero_cuenta}
                                disabled={true}
                            />
                            <label className='form-label' htmlFor="cuenta">
                                Cuenta
                            </label>
                        </div>
                    </div>
                    <div className='col-3'>
                        <div className="form-floating">
                            <input
                                placeholder="Banco"
                                type="text"
                                autocomplete="off"
                                name="banco"
                                className="form-control"
                                value={editar ? lectura.banco : cuentaBan.bancoNombre}
                                disabled={true}
                            />
                            <label className='form-label' htmlFor="banco">
                                Banco
                            </label>
                        </div>
                    </div>
                    <div className='col-3'>
                        <div className="form-floating">
                            <input
                                placeholder="Moneda"
                                type="text"
                                autocomplete="off"
                                name="moneda"
                                className="form-control"
                                value={editar? lectura.moneda : cuentaBan.monedaN}
                                disabled={true}
                            />
                            <label className='form-label' htmlFor="moneda">
                                Moneda
                            </label>
                        </div>
                    </div>
                    <div className='col-3'>
                        <div className="form-floating">
                            <input
                                placeholder="Tipo"
                                type="text"
                                autocomplete="off"
                                name="tipoCB"
                                className="form-control"
                                value={editar ? lectura.tipo : cuentaBan.tipo}
                                disabled={true}
                            />
                            <label className='form-label' htmlFor="tipoCB">
                                Tipo
                            </label>
                        </div>
                    </div>
                </div>
            }
            <div className='row mb-2'>
                <div className='col-3'>
                    <Field
                        autoFocus={autoFocus}
                        disabled={bloqueo}
                        name="fechaRegistro"
                        component={renderDayPickerB}
                        msj="Fecha Registro"
                    />
                </div>
                {crear === false && editar == false &&
                    <div className='col-3'>
                        <Field
                            name="numero"
                            placeholder="Numero nota de crédito"
                            className="form-control"
                            component={renderNumber}
                            disabled={bloqueo}
                            msj="Numero nota de crédito"
                        />
                    </div>
                }

                {(crear === true || editar === true) &&
                    <div className='col-3'>
                        <Field
                            name="numero"
                            placeholder="Numero nota de crédito"
                            className="form-control"
                            component={renderNumber}
                            disabled={bloqueo}
                            msj="Numero nota de crédito"
                        />
                    </div>
                }
                <div className='col-3'>
                    <Field
                        name="total"
                        placeholder="Total"
                        className="form-control"
                        component={renderCurrencyFloat}
                        disabled={bloqueo}
                        msj="Total"
                    />
                </div>
                {(editar === true) && (
                    <div className='col-3'>
                    {lectura.cambio === 'S' &&
                        <Field
                            name="tipo_cambio"
                            placeholder="Tipo de cambio"
                            className="form-control"
                            component={renderCurrencyFloatTC}
                            disabled={bloqueo}
                            msj="Tipo de cambio"
                        />
                    }
                    </div>
                )}
                {crear === false && editar == false &&
                <div className='col-3'>
                    {lectura.cambio === 'S' &&
                        <Field
                            name="tipo_cambio"
                            placeholder="Tipo de cambio"
                            className="form-control"
                            component={renderCurrencyFloatTC}
                            disabled={bloqueo}
                            msj="Tipo de cambio"
                        />
                    }
                    </div>
                }
            </div>
            {(crear === true) && (
                <div>
                {cuentaBan.cambio === 'S' &&
                    <div className='row mb-2'>
                        <div className='col-3'>
                            <div className="form-floating">
                                <NumberFormat
                                    className={classNames('form-control')}
                                    decimalScale={6}
                                    fixedDecimalScale
                                    placeholder="Tipo de cambio"
                                    value={tipoCambioD}
                                    thousandSeparator
                                    onValueChange={(values) => {
                                        setTipoCambioD(values.value)
                                        localStorage.setItem('TC_NC', tipoCambioD)
                                    }}
                                />
                                <label className='form-label' htmlFor="cuenta">
                                    Tipo de cambio
                                </label>
                            </div>
                        </div>
                        <div className='col-3 mt-2'>
                            <button
                                className='btn mr-2 mb-3 btn-secondary'
                                onClick={() => {
                                    let tc = tipoCambio(periodoD, cuentaBan.cambio);
                                    if (tc === undefined){
                                        setTipoCambioD('1.000000');
                                    }else{
                                        tc.then(d => { 
                                            if (d === undefined){
                                                setTipoCambioD('1.000000');
                                            }else{
                                                setTipoCambioD(d);
                                                localStorage.setItem('TC_NC', d)
    
                                            }
                                        });
                                    }
                                }}
                            >
                                Buscar tipo de cambio
                            </button>
                        </div>
                    </div>
                }
                </div>
            )}
            {crear === false && editar == false &&
                <div className='row mb-2'>
                    <div className='col-3'>
                        {tipos &&
                            <div className="form-floating">
                                <Field
                                    name="tipo"
                                    options={tipos}
                                    crear={crear}
                                    dato={crear === true ? 0 : lectura.concepto}
                                    component={SelectField}
                                    className="form-select"
                                    disabled={bloqueo}
                                    msj="Tipo de documento"
                                />
                            </div>
                        }
                    </div>
                    {(lectura.concepto === "PAGO DE CLIENTES" || lectura.concepto === "ANTICIPO DE CLIENTES") &&
                        <div className='row col-9'>
                            <div className='w-75'>
                                <Field
                                    name="clienteNombre"
                                    placeholder="Codigo Cliente"
                                    className="form-control"
                                    component={renderField}
                                    disabled={bloqueo}
                                    msj="Nombre Cliente"
                                />
                            </div>
                        </div>
                    }
                </div>
            }
            {(crear === true) &&
                <div className='row mb-2'>
                    <div className='col-3'>
                        <div className="form-floating">
                            <select className="form-select" id="tipo" name="tipo"
                                value={editar === true ? lectura.concepto : tipo} onChange={handleChange} >
                                <option selected>Seleccionar...</option>
                                {tipos.map(
                                    tip => <option key={tip.value} value={tip.value}
                                    >{tip.label}
                                    </option>)
                                }
                            </select>
                            <label className='form-label' htmlFor="mes">Tipo de documento</label>
                        </div>
                    </div>
                    {(crear === true) && ( concepto === 'PAGO DE CLIENTES' || concepto === 'ANTICIPO DE CLIENTES' ) &&
                        <div className='w-75'>
                            <Field
                                name="cliente_documento"
                                placeholder="Cliente..."
                                className="form-control-find"
                                component={AsyncSelectField}
                                loadOptions={listarClientes}
                                onChange={handleOnChange}
                                msj="Cliente"
                            />
                        </div>
                    }

                </div>
            }
            {(editar === true) &&
                <div className='row mb-2'>
                    <div className='col-3'>
                        <div className="form-floating">
                            <select className="form-select" id="tipo" name="tipo"
                                value={editarT === true ? lectura.concepto : tipo} onChange={handleChange} >
                                <option selected>Seleccionar...</option>
                                {tipos.map(
                                    tip => <option key={tip.value} value={tip.value}
                                    >{tip.label}
                                    </option>)
                                }
                            </select>
                            <label className='form-label' htmlFor="mes">Tipo de documento</label>
                        </div>
                    </div>
                    {editar && (concepto === 'PAGO DE CLIENTES' || concepto === 'ANTICIPO DE CLIENTES') &&
                        <div className='col-9'>
                                <Field
                                    name="clienteNombre"
                                    placeholder="Nombre Cliente"
                                    className="form-control"
                                    component={renderField}
                                    disabled={true}
                                    msj="Nombre Cliente"
                                />
                                {cliente === '' && detalleNC.length === 0 &&
                                    <Field
                                        name="cliente_documento"
                                        placeholder="Cliente..."
                                        className="form-control-find"
                                        component={AsyncSelectField}
                                        loadOptions={listarClientes}
                                        onChange={handleOnChange}
                                        msj="Cliente"
                                    />
                                }
                                {cliente && detalleNC.length === 0 &&
                                    <Field
                                        name="cliente_documento"
                                        placeholder="Cliente..."
                                        className="form-control-find"
                                        component={AsyncSelectField}
                                        loadOptions={listarClientes}
                                        onChange={handleOnChange}
                                        msj="Cliente"
                                    />
                                }
                        </div>
                    }
                </div>
            }
            {(crear === true) &&
                <div className='mb-2'>
                    <div className="form-floating">
                        <input
                            placeholder="Nombre"
                            type="text"
                            autocomplete="off"
                            name="nombre"
                            className="form-control"
                            value={editar && nombre === '' ? lectura.nombre : nombre }
                            onChange={e => { setNombre(e.target.value); localStorage.setItem('ClienteNC', e.target.value); }}
                            disabled={false}
                        />
                        <label className='form-label' htmlFor="proveedor">
                            Nombre
                        </label>
                    </div>
                </div>
            }
            {crear === false && editar == false && 
                <div className='mb-2'>
                    <Field
                        name="nombre"
                        placeholder="Nombre"
                        component={renderField}
                        className="form-control"
                        disabled={bloqueo}
                        msj="Nombre"
                    />
                </div>
            }
            {(editar === true) && 
                <div className='mb-2'>
                    <div className="form-floating">
                        <input
                            placeholder="Nombre"
                            type="text"
                            autocomplete="off"
                            name="nombre"
                            className="form-control"
                            value={editar && nombre === '' ? lectura.nombre : nombre }
                            onChange={e => { setNombre(e.target.value); localStorage.setItem('ClienteNC', e.target.value); }}
                            disabled={false}
                        />
                        <label className='form-label' htmlFor="cliente">
                            Nombre
                        </label>
                    </div>
                </div>
            }
            <div className='mb-2'>
                <Field
                    name="descripcion"
                    placeholder="Descripcion"
                    component={renderField}
                    className="form-control"
                    disabled={bloqueo}
                    msj="Referencia"
                />
            </div>
            {doc && editar && concepto === 'PAGO DE CLIENTES' &&
                <div>
                    <div style={estilo}>
                        <Grid
                            hover
                            striped
                            data={doc}
                            loading={loader}
                            headerStyle={{ background: 'black' }}
                        //onSortChange={onSortChange} 
                        >
                            <TableHeaderColumn
                                isKey
                                dataField="fecha"
                                dataSort
                                dataFormat={(cell, row) => {
                                    return moment(cell).format("DD/MM/YYYY");
                                }}
                                thStyle={
                                    { color: 'white' }}
                                tdStyle={
                                    { color: 'white' }}
                            >
                                Fecha
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="serie"
                                dataSort
                                thStyle={
                                    { color: 'white' }}
                                tdStyle={
                                    { color: 'white' }}
                            >
                                Serie
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="numero"
                                dataSort
                                thStyle={
                                    { color: 'white' }}
                                tdStyle={
                                    { color: 'white' }}
                            >
                                Numero
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="clienteN"
                                width="350"
                                dataSort
                                thStyle={
                                    { color: 'white' }}
                                tdStyle={
                                    { color: 'white' }}
                            >
                                Cliente
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="total"
                                headerAlign="center"
                                dataAlign='right'
                                dataFormat={(cell, row) => {
                                    if (parseFloat(cell) === 0.00) {
                                        return ''
                                    } else {
                                        return new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(cell);
                                    }
                                }}
                                dataSort
                                thStyle={
                                    { color: 'white' }}
                                tdStyle={
                                    { color: 'white' }}
                            >
                                Total
                            </TableHeaderColumn>
                        </Grid>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-8 mt-2'>
                            <Field
                                name="documento"
                                placeholder="Documento..."
                                className="form-control-find"
                                loadOptions={list_doc}
                                component={AsyncSelectFieldIn}
                                msj="Documento"
                            />
                        </div>
                        <div className='col-3 mt-2'>
                            <div className="form-floating">
                                <Field
                                    name="abono"
                                    placeholder="Abono"
                                    className="form-control"
                                    component={renderCurrencyFloat}
                                    disabled={false}
                                    msj="Abono"
                                />
                            </div>
                        </div>
                    </div>
                    <Contenido>
                        <button
                            className='btn mr-2 mb-3 btn-primary'
                            onClick={() => nuevaLineaDocumento(periodoD)}
                        >
                            Agregar documento
                        </button>
                        <div className='row'>
                            <div className='col-6'>
                                <div className="form-floating">
                                    <Field
                                        name="diferencia"
                                        placeholder="Diferencia"
                                        className="form-control"
                                        component={renderCurrencyFloat}
                                        disabled={true}
                                        msj="Diferencia"
                                    />
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className="form-floating">
                                    <Field
                                        name="totalAbono"
                                        placeholder="Total Abono"
                                        className="form-control"
                                        component={renderCurrencyFloat}
                                        disabled={true}
                                        msj="Total Abono"
                                    />
                                </div>
                            </div>
                        </div>
                    </Contenido>

                </div>
            }
            {pagoD && editar === false &&
                <div>
                    <h5 className="p-2 bg-secondary title mt-1">Documentos con saldo</h5>
                    <div style={estilo}>
                        <Grid
                            hover
                            striped
                            data={doc}
                            loading={loader}
                            headerStyle={{ background: 'black' }}
                        //onSortChange={onSortChange} 
                        >
                            <TableHeaderColumn
                                isKey
                                dataField="fecha"
                                dataSort
                                dataFormat={(cell, row) => {
                                    return moment(cell).format("DD/MM/YYYY");
                                }}
                                thStyle={
                                    { color: 'white' }}
                                tdStyle={
                                    { color: 'white' }}
                            >
                                Fecha
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="serie"
                                dataSort
                                thStyle={
                                    { color: 'white' }}
                                tdStyle={
                                    { color: 'white' }}
                            >
                                Serie
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="numero"
                                dataSort
                                thStyle={
                                    { color: 'white' }}
                                tdStyle={
                                    { color: 'white' }}
                            >
                                Numero
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="clienteN"
                                width="350"
                                dataSort
                                thStyle={
                                    { color: 'white' }}
                                tdStyle={
                                    { color: 'white' }}
                            >
                                Cliente
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="total"
                                headerAlign="center"
                                dataAlign='right'
                                dataFormat={(cell, row) => {
                                    if (parseFloat(cell) === 0.00) {
                                        return ''
                                    } else {
                                        return new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(cell);
                                    }
                                }}
                                dataSort
                                thStyle={
                                    { color: 'white' }}
                                tdStyle={
                                    { color: 'white' }}
                            >
                                Total
                            </TableHeaderColumn>
                        </Grid>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-8 mt-2'>
                            <Field
                                name="documento"
                                placeholder="Documento..."
                                className="form-control-find"
                                loadOptions={list_doc}
                                component={AsyncSelectFieldIn}
                                msj="Documento"
                            />
                        </div>
                        <div className='col-3 mt-2'>
                            <div className="form-floating">
                                <Field
                                    name="abono"
                                    placeholder="Abono"
                                    className="form-control"
                                    component={renderCurrencyFloat}
                                    disabled={false}
                                    msj="Abono"
                                />
                            </div>
                        </div>
                    </div>
                    {(crear === true) &&
                        <Contenido>
                            <button
                                className='btn mr-2 mb-3 btn-primary'
                                onClick={() => agregarLinea(periodoD)}
                            >
                                Agregar documento
                            </button>
                            <div className='row mb-2'>
                                <div className='col-6'>
                                    <div className="form-floating">
                                        <Field
                                            name="diferencia"
                                            placeholder="Diferencia"
                                            className="form-control"
                                            component={renderCurrencyFloat}
                                            disabled={true}
                                            msj="Diferencia"
                                        />
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className="form-floating">
                                        <Field
                                            name="totalAbono"
                                            placeholder="Total Abono"
                                            className="form-control"
                                            component={renderCurrencyFloat}
                                            disabled={true}
                                            msj="Total Abono"
                                        />
                                    </div>
                                </div>
                            </div>
                        </Contenido>
                    }

                    {(editar === true) &&
                        <Contenido>
                            <button
                                className='btn mr-2 mb-3 btn-primary'
                                onClick={() => nuevaLineaDocumento(periodoD)}
                            >
                                Agregar documento
                            </button>
                            <div className='row mb-2'>
                                <div className='col-6'>
                                    <div className="form-floating">
                                        <Field
                                            name="diferencia"
                                            placeholder="Diferencia"
                                            className="form-control"
                                            component={renderCurrencyFloat}
                                            disabled={true}
                                            msj="Diferencia"
                                        />
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className="form-floating">
                                        <Field
                                            name="totalAbono"
                                            placeholder="Total Abono"
                                            className="form-control"
                                            component={renderCurrencyFloat}
                                            disabled={true}
                                            msj="Total Abono"
                                        />
                                    </div>
                                </div>
                            </div>
                        </Contenido>
                    }

                    {(crear === true) &&
                        <div>
                            <h5 className="p-2 bg-secondary title mt-1">Documentos agregados</h5>
                            <div style={estilo}>
                                <TablaDetalle
                                    detalle={detalleDocN}
                                    eliminarDocumento={eliminarLineaAbonoNuevo}
                                />
                            </div>
                        </div>
                    }
                </div>
            }
            {crear === false && editar === false &&
                (lectura.concepto === 'PAGO DE CLIENTES' &&
                    <div>
                        <h5 className="p-2 bg-secondary title mt-1">Documentos agregados</h5>
                        <div style={estilo}>
                            <TablaVerDetalle
                                detalle={detalleNC}
                            />
                        </div>
                    </div>
                )
            }
            {(editar === true) && concepto === 'PAGO DE CLIENTES' &&
                <div>
                    <h5 className="p-2 bg-secondary title mt-1">Documentos agregados</h5>
                    <div style={estilo}>
                        <TablaDetalleMod
                            detalle={detalleNC}
                            eliminarDocumento={eliminarDocumento}
                        />
                    </div>

                </div>
            }
        </div>

    );
}

NCForm.propTypes = {
    funcionEnvio: PropTypes.func,
    crear: PropTypes.bool,
    editar: PropTypes.bool,
    lectura: PropTypes.object,
    bancos: PropTypes.object,
    listaM: PropTypes.object,
    listarClientes: PropTypes.object,
    leerDocumentos: PropTypes.func,
    doc: PropTypes.object,
    loader: PropTypes.bool,
    bloqueo: PropTypes.bool,
    detalleDoc: PropTypes.object,
    eliminarDocumento: PropTypes.func,
    cuentasBan: PropTypes.object,
    fechaInicio: PropTypes.string,
    fechaFinal: PropTypes.string,
    detalleNC: PropTypes.object
};

export default reduxForm({
    form: 'FormNC',
})(NCForm);

const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;