import React, { Component } from 'react';
import Grid from '../../Utils/GridFiltro';
import PropTypes from 'prop-types';
import { standardActions } from '../../Utils/GridFiltro/StandardActions';
import NavbarModulo from '../../components/NavbarModulo';
import NavbarModuloLF from '../../components/NavbarModuloFage';
const valEmp = __CONFIG__.empresa;
import moment from 'moment';

class LiquidacionList extends Component{
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: true
    };

    componentDidMount = () => {
        const { listar, getModulo, parametrosEmpresa } = this.props;
        listar();
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        if (valEmp === 'fage'){
            getModulo(modulo[6],modulo[5]);
            parametrosEmpresa();
        }else{
            getModulo(modulo[5],modulo[4]);
            parametrosEmpresa();
        }
    }
    render(){
        const { data, loader, imprimirLiquidacion, modulo, datos } = this.props;
        let ruta = window.location.href;
        let datosRt = ruta.split('/');
        return(
            <React.Fragment>
                {valEmp === 'fage' &&
                    <NavbarModuloLF
                        pais = {datos.paisNombre}
                        modulo={modulo}
                    />
                }
                {valEmp === '' &&
                    <NavbarModulo
                        pais = {datos.paisNombre}
                        modulo={modulo}
                    />
                }
                <br />
                <h3 className="p-2 bg-secondary title">Liquidaciones de colaboradores</h3>
                <br />
                {valEmp === 'fage' &&
                    <div className="d-flex flex-row justify-content-start mb-2">
                        <a
                            href={`/#/lfg/rr_hh/${datosRt[6]}/liquidacion_laboral/crear`}
                            className='btn btn-primary'
                        >
                            Crear liquidación
                        </a>
                    </div>
                }
                {valEmp === '' &&
                    <div className="d-flex flex-row justify-content-start mb-2">
                        <a
                            href={`/#/rr_hh/${datosRt[5]}/liquidacion_laboral/crear`}
                            className='btn btn-primary'
                        >
                            Crear liquidación
                        </a>
                    </div>
                }
                {data &&
                    <Grid 
                        hover 
                        striped 
                        data={data}
                        loading={loader} 
                        headerStyle={ { background: 'black' } } 
                        //onPageChange={onPageChange}
                        //onSortChange={onSortChange} 
                    >
                        <TableHeaderColumn
                            isKey
                            dataField="codigo"
                            width="150"
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Código
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="nombre"
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Nombre
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="fecha_terminacion"
                            dataSort
                            width="150"
                            dataAlign="right"
                            dataFormat={(cell, row)=>{
                                return moment(cell).format("DD/MM/YYYY");
                            }}
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Fecha de baja
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataAlign="center"
                            dataField="activo"
                            width="80"
                            dataFormat={(cell, row)=>{
                                if (cell === true){
                                    return 'A';
                                }else{
                                    return 'I';
                                }
                            }}
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Estado
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataAlign="center"
                            dataField="estado"
                            width="200"
                            dataFormat={(cell, row)=>{
                                if (cell === 'P'){
                                    return 'Pregrabado';
                                }else{
                                    return '';
                                }
                            }}
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Estado liquidación
                        </TableHeaderColumn>
                        {valEmp === 'fage' &&
                            <TableHeaderColumn
                                thStyle={
                                {color: 'white'}}
                                dataField="id"
                                width="150"
                                dataAlign="center"
                                dataSort
                                dataFormat={standardActions({ 
                                    editar_lfg_liq: "liquidacion_laboral", 
                                    ver_lfg_liq: "liquidacion_laboral",
                                    imprimirLiquidacion: imprimirLiquidacion,
                                    empresa: datosRt[6],
                                    periodo: datosRt[8],
                                    modulo: datosRt[5],
                                })} 
                                
                            >
                                Acciones
                            </TableHeaderColumn>
                        }
                        {valEmp === '' &&
                            <TableHeaderColumn
                                thStyle={
                                {color: 'white'}}
                                dataField="id"
                                width="150"
                                dataAlign="center"
                                dataSort
                                dataFormat={standardActions({ 
                                    editar_liq: "liquidacion_laboral", 
                                    ver_liq: "liquidacion_laboral",
                                    imprimirLiquidacion: imprimirLiquidacion,
                                    empresa: datosRt[5],
                                    periodo: datosRt[7],
                                    modulo: datosRt[4],
                                })} 
                                
                            >
                                Acciones
                            </TableHeaderColumn>
                        }
                    </Grid>
                }
            </React.Fragment>
        );
    }
}

export default LiquidacionList;
