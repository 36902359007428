import { handleActions } from 'redux-actions';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';

const LOADER = 'LOADER';
const MODULO = 'MODULO';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listarCuentasBancarias = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id, search}
    return api.get('/cuenta_banco/empresa_cuentas/',params).then(data=>{
        const list_cuentas = [];
        if(data){
          data.forEach(item=>{
            list_cuentas.push({
              value: item.id,
              label: item.numero_cuenta + ' ' + item.bancoNombre+' '+item.monedaN
            })
          })
        }
      return list_cuentas;
    }).catch((error)=>{
        return [];
    })
}

export const listarProveedores = (search) => () => {
  let ruta = window.location.href;
  let id_emp = ruta.split('/');
  let id = id_emp[5];
  const params = {id, search}
  return api.get('/egresos/listar_proveedores',params).then(data=>{
      const list_cuentas = [];
      if(data){
        data.forEach(item=>{
          list_cuentas.push({
            value: item.id,
            label: item.nombre_proveedor
          })
        })
      }
    return list_cuentas;
  }).catch((error)=>{
      return [];
  })
}

export const listarClientes = (search) => () => {
  let ruta = window.location.href;
  let id_emp = ruta.split('/');
  let id = id_emp[5];
  const params = {id, search}
  return api.get('/ingresos/listar_clientes',params).then(data=>{
      const list_cuentas = [];
      if(data){
        data.forEach(item=>{
          list_cuentas.push({
            value: item.id,
            label: item.nombre_cliente
          })
        })
      }
    return list_cuentas;
  }).catch((error)=>{
      return [];
  })
}

export const listarCC = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id, search}
    return api.get('/centrocosto/empresa_centro_costo/',params).then(data=>{
        const list_cc = [];
        if(data){
            data.forEach(item=>{
                list_cc.push({
                    value: item.id,
                    label: item.codigo + ' ' + item.descripcion
                })
            })
        }
        return list_cc;
    }).catch((error)=>{
        return [];
    })
}

export const reporteDiarioBancos = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const data = getStore().form.FormInformesB.values;
    let mes = '';
    if (data === undefined){
        mes = 'TODAS';
    }else{
        mes = data.mes;
    }
    let mesLetras = renderSwitch(data.mes)
    let year =  data.year;
    let reporte = 'diarioBancos';
    let cuenta = data.cuenta_bancaria ? data.cuenta_bancaria.value : '';
    const params = { id, reporte, mesLetras, year, cuenta};
    dispatch(setLoader(true));
    api.getPdf('/egresos', params).then((response)=>{
        if (response.headers['content-type'] == 'application/pdf'){
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'reporte_diario_bancos.pdf';
            window.open(link, '_blank');
            return false;
          }
    }).catch((error)=>{
        if (error.status === 404){
            NotificationManager.info(
              error.body.msj,
              'Informacion',
              5000
            );
        }
        if (error.status === 500){
            NotificationManager.error(
              'Ocurrió un error al generar reporte',
              'Error',
              2000
            );
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const excelDiarioBancos = () => (dispatch, getStore) => {
  let ruta = window.location.href;
  let id_emp = ruta.split('/');
  let id = id_emp[5];
  const data = getStore().form.FormInformesB.values;
  let mes = '';
    if (data === undefined){
        mes = 'TODAS';
    }else{
        mes = data.mes;
    }
  let mesLetras = renderSwitch(data.mes)
  let year =  data.year;
  let reporte = 'diarioBancosExcel';
  let cuenta = '';
  try {
    cuenta = data.cuenta_bancaria.value;
  } catch(error) {
  }
  const params = { id, reporte, mesLetras, year, cuenta};
  dispatch(setLoader(true));
  api.getExcel('/egresos', params).then((response) => {
  
      const blob = response;
      const fileName = 'diario_de_bancos.xlsx';

      // Descargar el archivo utilizando la función saveAs de file-saver
      const file = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(file, fileName);
      
  }).catch(error => {
    if (error === 404) {
      NotificationManager.error(
        "No se encontraron datos",
        'Informacion',
        5000
        );
      }
    if (error === 500) {
        NotificationManager.error(
            'Ocurrió un error al generar reporte',
            'Error',
            0
        );
    }
  })
  .finally(() => {
      dispatch(setLoader(false));
  });
  
}

export const reporteListadoEgresos = () => (dispatch, getStore) => {
  let ruta = window.location.href;
  let id_emp = ruta.split('/');
  let id = id_emp[5];
  const data = getStore().form.FormInformesB.values;
  let fechaI = data.fechaInicial;
  let fechaF = data.fechaFinal;
  let filtro = data.filtro ? data.filtro : '';
  if (filtro == 0) filtro = ''
  let cuenta = data.cuenta_bancaria ? data.cuenta_bancaria.value : '';
  let reporte = 'egresos';
  let proveedor_id = data.proveedor ? data.proveedor.value : '';
  let proveedor_nombre = data.proveedor ? data.proveedor.label : '';
  const params = { 
    id,
    fechaI, 
    fechaF, 
    filtro, 
    cuenta, 
    reporte, 
    proveedor_id,
    proveedor_nombre,
  };
  dispatch(setLoader(true));
  api.getPdf('/egresos', params).then((response) => {
      if (response.headers['content-type'] == 'application/pdf') {
          var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'reporte_ingresos.pdf';
          window.open(link, '_blank');
          return false;
      }
  }).catch((error) => {
      if (error.status === 404) {
          NotificationManager.error(
              "No se encontraron datos",
              "Error",
              2000
          );
      }
      if (error.status === 500) {
          NotificationManager.error(
              'Ocurrió un error al generar reporte',
              'Error',
              0
          );
      }

  }).finally(() => {
      dispatch(setLoader(false));
  });
}

export const reporteListadoIngresos = () => (dispatch, getStore) => {
  let ruta = window.location.href;
  let id_emp = ruta.split('/');
  let id = id_emp[5];
  const data = getStore().form.FormInformesB.values;
  let fechaI = data.fechaInicial;
  let fechaF = data.fechaFinal;
  let filtro = data.filtro ? data.filtro : '';
  if (filtro == 0) filtro = ''
  let cuenta = data.cuenta_bancaria ? data.cuenta_bancaria.value : '';
  let reporte = 'ingresos';
  let cliente_id = data.cliente ? data.cliente.value : '';
  let cliente_nombre = data.cliente ? data.cliente.label : '';
  const params = { 
    id,
    fechaI, 
    fechaF, 
    filtro, 
    cuenta, 
    reporte, 
    cliente_id,
    cliente_nombre,
  };

  dispatch(setLoader(true));
  api.getPdf('/ingresos', params).then((response) => {
      if (response.headers['content-type'] == 'application/pdf') {
          var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'reporte_ingresos.pdf';
          window.open(link, '_blank');
          return false;
      }
  }).catch((error) => {
      if (error.status === 404) {
          NotificationManager.error(
              "No se encontraron datos",
              'Error',
              5000
          );
      }
      if (error.status === 500) {
          NotificationManager.error(
              'Ocurrió un error al generar reporte',
              'Error',
              0
          );
      }

  }).finally(() => {
      dispatch(setLoader(false));
  });
}

function renderSwitch(param) {
    switch (param) {
      case '01':
        return 'ENERO';
      case '02':
        return 'FEBRERO';
      case '03':
        return 'MARZO';
      case '04':
        return 'ABRIL';
      case '05':
        return 'MAYO';
      case '06':
        return 'JUNIO';
      case '07':
        return 'JULIO';
      case '08':
        return 'AGOSTO';
      case '09':
        return 'SEPTIEMBRE';
      case '10':
        return 'OCTUBRE';
      case '11':
        return 'NOVIEMBRE';
      case '12':
        return 'DICIEMBRE';

      default:
        return 'Seleccione un mes';
    }
}

export const getModulo = (id, modulo) => (dispatch) => {
  const params = {id, modulo}
  dispatch(setLoader(true));
  api.get('/modulos/modulo/', params).then((response)=>{
      dispatch({ type: MODULO, modulo: response.modulos });
  }).catch(() => {
      NotificationManager.error(
          'Ocurrió un error en buscar modulo',
          'Error',
          2000
      );
  }).finally(() => {
      dispatch(setLoader(false));
  });
};

export const reporteConciliacion = () => (dispatch, getStore) => {
  let ruta = window.location.href;
  let id_emp = ruta.split('/');
  const data = getStore().form.FormInformesB.values;
  if (data === undefined) {
    Swal.fire({
        title: 'Conciliación bancaria',
        text: 'Debe de seleccionar una cuenta bancaria',
        type: 'error',
    })
  }else{
    if (data.cuenta_bancaria === undefined || data.cuenta_bancaria === null || data.cuenta_bancaria === '') {
        Swal.fire({
            title: 'Conciliación bancaria',
            text: 'Debe de seleccionar una cuenta bancaria',
            type: 'error',
        })
    }else{
      if (data.mes === undefined || data.mes === '') {
        Swal.fire({
            title: 'Conciliación bancaria',
            text: 'Debe de seleccionar mes',
            type: 'error',
        })
      }else{
        if (data.year === undefined || data.year === '') {
          Swal.fire({
              title: 'Conciliación bancaria',
              text: 'Debe de ingresar año',
              type: 'error',
          })
        }else{
          Swal.fire({
            title: 'Revisado por:',
            html:
              '<div class="mx-auto mt-4" style="width: 450px;">' +
              `<input class="form-control" id="mensaje" autocomplete="off" class="mx-auto mb-4" type="text">` +
              '</div',
            background: "black",
            showCancelButton: true,
            confirmButtonColor: "#4040ff",
            confirmButtonText: "Aceptar",
            cancelButtonText: "Cancelar",
            cancelButtonColor: "#646464",
            showCloseButton: true,
            customClass: {
              title: 'sweet_titleImportant',
            },
            preConfirm: () => {
              let texto = document.getElementById('mensaje').value
              if (texto) {
                let imp = 'imprimirConciliacion';
                let empresa = id_emp[5];
                let year = data.year;
                let cuenta = data.cuenta_bancaria.value;
                let mes = renderSwitch(data.mes)
                const params = { empresa, imp, mes, year, cuenta, texto };
                dispatch(setLoader(true));
                api.getPdf('/conciliacion', params).then((response) => {
                  if (response.headers['content-type'] == 'application/pdf') {
                    var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = 'conciliacon_bancaria.pdf';
                    window.open(link, '_blank');
                    return false;
                  }
                }).catch((error) => {
                  NotificationManager.error(
                    'Ocurrió un error al generar reporte',
                    'Error',
                    2000
                  );
                }).finally(() => {
                  dispatch(setLoader(false));
                });
              }
              if (!texto) {
                Swal.showValidationMessage(
                  'Este campo es requerido'
                )
              }
            }
          })
        }
      }
    }
  }
}

export const actions = {
    listarCuentasBancarias,
    listarCC,
    reporteDiarioBancos,
    getModulo,
    listarProveedores,
    reporteListadoEgresos,
    reporteListadoIngresos,
    listarClientes,
    excelDiarioBancos,
    reporteConciliacion
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [MODULO]: (state, { modulo }) => {
      return {
          ...state,
          modulo,
      };
  },
};

export const initialState = {
    loader: false,
    modulo: []
};

export default handleActions(reducers, initialState)