import { NotificationManager } from "react-notifications";
import { api } from "api";
import { handleActions, createAction } from 'redux-actions';

const LISTAR_INGRESOS = createAction('LISTAR_INGRESOS');

export const crearIngreso = (data) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    data.combustible = data.combustible.value
    data.linea = data.linea.value
    data.marca = data.marca.value
    data.tipo_carro = data.tipo_carro.value
    data.empresa = id 
    return api.postAttachments(
        'auto_ventas/ingresos', 
        {...data, foto:  null}, 
        [{ "name": 'foto', "file": data.foto}]
        )
    .then((response) => {
            NotificationManager.success(
                'Vehiculo agregado',
                'Exito',
                3000,
                null,
                true
            );
            return response;
        }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                2000,
                null,
                true
            );
            return false;
        })
}

export const reporteRecepcionDeVehiculos = (carroId) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let rep = 'recepcion_de_vehiculos';
    const params = { id, rep, carroId };
    api.getPdf('/auto_ventas/ingresos/reportes', params).then((response)=>{
        if (response.headers['content-type'] == 'application/pdf'){
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'recepcion_de_vehiculos.pdf';
            window.open(link, '_blank');
            return false;
          }
    }).catch((error)=>{
        if (error.status === 404){
            NotificationManager.info(
              error.body.msj,
              'Informacion',
              5000
            );
        }
        if (error.status === 500){
            NotificationManager.error(
              'Ocurrió un error al generar reporte',
              'Error',
              2000
            );
        }
    })
}

export const listarIngresos = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id }
    return api.get('auto_ventas/ingresos', params).then(response => {
        // dispatch({type: LISTAR_TIPOS, data: response})
        dispatch(LISTAR_INGRESOS(response));
    }).catch((error) => {
        return [];
    })
}

export const listarMarcas = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('auto_ventas/marca', params).then(data => {
        const listaMarcas = [];
        if (data) {
            data.forEach(item => {
                listaMarcas.push({
                    value: item.id,
                    label: item.nombre
                })
            })
        }
        return listaMarcas;
    }).catch((error) => {
        return [];
    })
}
export const listarCombustibles = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('auto_ventas/combustible', params).then(data => {
        const listaCombustibles = [];
        if (data) {
            data.forEach(item => {
                listaCombustibles.push({
                    value: item.id,
                    label: item.nombre
                })
            })
        }
        return listaCombustibles;
    }).catch((error) => {
        return [];
    })
}

export const listarLineas = (search, marca) => () => {
    if (marca === null) {
        marca = '00000000-0000-0000-0000-000000000000'
    }
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search, marca };
    return api.get('auto_ventas/linea', params).then(data => {
        const listaLineas = [];
        if (data) {
            data.forEach(item => {
                listaLineas.push({
                    value: item.id,
                    label: item.nombre
                })
            })
        }
        return listaLineas;
    }).catch((error) => {
        return [];
    })
}

export const listarTipos = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('auto_ventas/tipo', params).then(data => {
        const listaTipos = [];
        if (data) {
            data.forEach(item => {
                listaTipos.push({
                    value: item.id,
                    label: item.nombre
                })
            })
        }
        return listaTipos;
    }).catch((error) => {
        return [];
    })
}


export const actions = {
    listarMarcas,
    listarLineas,
    listarCombustibles,
    listarTipos,
    listarIngresos,
    reporteRecepcionDeVehiculos,
    crearIngreso,
};

export const reducers = {
    [LISTAR_INGRESOS]: (state, action) => {
        return {
            ...state,
            data: action.payload,
        };
    
    },
}

export const initialState = {
    data: [],

};

export default handleActions(reducers, initialState)