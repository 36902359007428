import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";

const LISTADO_INSUMO = 'LISTADO_INSUMO';
const GUARDAR_INSUMO = 'GUARDAR_INSUMO';
const LOADER = 'LOADER';
const MODULO = 'MODULO';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    //const params = { page, id };
    const params = { id };
    api.get('/insumo_prd', params).then((response)=>{
        dispatch({ type: LISTADO_INSUMO, data: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar insumos',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const registroInsumo = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormInsumo.values;
    const formData = {
        descripcion: data.descripcion,
        empresa: id_emp[5]
    }
    dispatch(setLoader(true));
    api.post('/insumo_prd/', formData).then(() => {
        NotificationManager.success(
        'Insumo creado',
        'Exito',
        3000
    );
    let ruta = `/produccion/${id_emp[5]}/insumo`
    dispatch(push(ruta));
    }).catch((error) => {
    NotificationManager.error(
        error.msj,
        'Error',
        2000
    );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const modificarInsumo = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const datos = getStore().form.FormInsumo.values;
    const id = datos.id;
    const formData = {
        id: id,
        descripcion: datos.descripcion
    }
    dispatch(setLoader(true));
    api.put(`/insumo_prd/${id}/`, formData).then(() => {
        NotificationManager.success(
            'Insumo modificado correctamente',
            'Exito',
            3000
        );
        let ruta = `/produccion/${id_emp[5]}/insumo`
        dispatch(push(ruta));
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al modificar el insumo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/insumo_prd/${id}`).then((response) => {
        dispatch({type: GUARDAR_INSUMO, lectura: response });
        dispatch(initializeForm('FormInsumo', response ));
    }).catch((error)=>{
        console.log("error: ", error)
        NotificationManager.error(
            'Ocurrió un error al consultar insumo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const actions = {
    listar,
    registroInsumo,
    modificarInsumo,
    leer,
    getModulo
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_INSUMO]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [GUARDAR_INSUMO]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
};

export const initialState = {
    loader: false,
    lectura: [],
    modulo: [],
    data: [],
    page: 1,
};

export default handleActions(reducers, initialState)
 