import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import { validate, validators } from 'validate-redux-form';
import PropTypes from 'prop-types';
import NavbarModulo from '../../components/NavbarModulo';
import {
    renderField,
    SelectField,
    AsyncSelectField
} from "../../Utils/renderField/renderField";

function CuentaBancariaForm(props) {
    const { handleSubmit, crear, lectura, bancos, listaM, 
        listarCuentas, listarCC, datos, modulo } = props;

    const editar = window.location.href.includes('editar');
    let titulo = editar ? 'Modificar cuenta bancaria' : 'Crear cuenta bancaria';
    let disabled = false;
    if (crear === false && editar === false) {
        disabled = true;
        titulo = 'Ver cuenta bancaria'
    }
    let autoFocus = true;
    const tipos = [
        {
            value: 'MONETARIA',
            label: 'MONETARIA'
        },
        {
            value: 'AHORRO',
            label: 'AHORRO'
        },
        {
            value: 'INVERSIONES',
            label: 'INVERSIONES'
        },
    ];
    let oculto = false;
    let cc = localStorage.getItem('cc');
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    return (
        <div>
            <NavbarModulo
                modulo={modulo}
                titulo={titulo}
            />
            <form onSubmit={handleSubmit}>
                <br />
                <div className="mb-4 card card-small text-white bg-dark mb-3">
                    <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                        <div className="d-flex flex-column flex-1 mx-4">
                            <div className='row mb-2'>
                                <div className='mb-2 col-4'>
                                    <Field
                                        autoFocus={autoFocus}
                                        name="numero_cuenta"
                                        placeholder="numeroCuenta"
                                        className="form-control"
                                        component={renderField}
                                        disabled={disabled}
                                        msj="Numero Cuenta"
                                    />
                                </div>
                                {bancos &&
                                    <div className='mb-2 col-4'>
                                        <div className="form-floating">
                                            <Field
                                                name="banco"
                                                options={bancos}
                                                crear={crear}
                                                dato={crear === true ? 0 : lectura.banco}
                                                component={SelectField}
                                                className="form-select"
                                                disabled={disabled}
                                                msj="Banco"
                                            />
                                        </div>
                                    </div>
                                }
                                <div className='col-4'>
                                    <div className="form-floating">
                                        <Field
                                            name="tipo"
                                            options={tipos}
                                            crear={crear}
                                            dato={crear === true ? 0 : lectura.tipo}
                                            component={SelectField}
                                            className="form-select"
                                            disabled={disabled}
                                            msj="Tipo cuenta"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                {listaM &&
                                    <div className='mb-2 col-3'>
                                        <div className="form-floating">
                                            <Field
                                                name="moneda"
                                                options={listaM}
                                                crear={crear}
                                                dato={crear === true ? 0 : lectura.moneda}
                                                component={SelectField}
                                                className="form-select"
                                                disabled={disabled}
                                                msj="Moneda"
                                            />
                                        </div>
                                    </div>
                                }
                                {crear === false && editar === false &&
                                    <div className='mb-2 col-6'>
                                        <Field
                                            name="cuentaNombre"
                                            placeholder="Cuenta Contable"
                                            className="form-control"
                                            component={renderField}
                                            disabled={disabled}
                                            msj="Cuenta Contable"
                                        />
                                    </div>
                                }
                                {(crear === true) &&
                                    <div className='col-6'>
                                        <Field
                                            name="cuenta"
                                            placeholder="Cuenta contable..."
                                            className="form-control-find"
                                            component={AsyncSelectField}
                                            loadOptions={listarCuentas}
                                            msj="Cuenta"
                                        />
                                    </div>
                                }
                                {(editar === true) &&
                                    <div className='mb-2 col-6'>
                                        <Field
                                            name="cuentaNombre"
                                            placeholder="Cuenta Contable"
                                            className="form-control"
                                            component={renderField}
                                            disabled={true}
                                            msj="Cuenta Contable"
                                        />
                                    </div>
                                }
                                {oculto &&
                                    <Field
                                        name="cuenta_contable"
                                        placeholder="Cuenta Contable"
                                        className="form-control"
                                        component={renderField}
                                        disabled={true}
                                    />
                                }
                                {(datos.centro_costo === 'S') &&
                                    (crear === false && editar === false &&
                                        <div className='mb-2 col-3'>
                                            <Field
                                                name="ccNombre"
                                                placeholder="Centro Costo"
                                                className="form-control"
                                                component={renderField}
                                                disabled={disabled}
                                                msj="Centro Costo"
                                            />
                                        </div>
                                    )
                                }
                                {(datos.centro_costo === 'S') &&
                                    ((crear === true) &&
                                        <div className='col-3'>
                                            <Field
                                                name="cc"
                                                placeholder="Centro Costo..."
                                                className="form-control-find"
                                                component={AsyncSelectField}
                                                loadOptions={listarCC}
                                                msj="Centro Costo"
                                            />
                                        </div>
                                    )
                                }
                                {(datos.centro_costo === 'S') &&
                                    ((editar === true) &&
                                        <div className='mb-2 col-3'>
                                            <Field
                                                name="ccNombre"
                                                placeholder="Centro Costo"
                                                className="form-control"
                                                component={renderField}
                                                disabled={true}
                                                msj="Centro Costo"
                                            />
                                        </div>
                                    )
                                }
                            </div>
                            
                            {(editar == true) &&
                                <div className='row mb-2'>
                                    <div className='col-3'>
                                    </div>
                                    <div className='col-6'>
                                        <Field
                                            name="cuenta"
                                            placeholder="Cuenta contable..."
                                            className="form-control-find"
                                            component={AsyncSelectField}
                                            loadOptions={listarCuentas}
                                            msj="Cuenta"
                                        />
                                    </div>
                                    {(datos.centro_costo === 'S') &&
                                        ((editar === true) &&
                                            <div className='col-3'>
                                                <Field
                                                    name="cc"
                                                    placeholder="Centro Costo..."
                                                    className="form-control-find"
                                                    component={AsyncSelectField}
                                                    loadOptions={listarCC}
                                                    msj="Centro Costo"
                                                />
                                            </div>
                                        )
                                    }
                                </div>
                            }
                            {oculto &&
                                <Field
                                    name="centro_costo"
                                    placeholder="Centro Costo"
                                    className="form-control"
                                    component={renderField}
                                    disabled={true}
                                />
                            }
                            <div className='d-flex flex-row mt-3'>
                                {disabled == false &&
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        type='submit'
                                    >
                                        {editar ? 'Modificar' : 'Guardar'}
                                    </button>
                                }
                                <a
                                    href={`/#/bancos/${id_emp[5]}/cuenta_bancaria`}
                                    className='btn btn-secondary mb-3'
                                >
                                    Regresar
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

CuentaBancariaForm.propTypes = {
    handleSubmit: PropTypes.func,
    crear: PropTypes.bool,
    lectura: PropTypes.object,
    bancos: PropTypes.object,
    listaM: PropTypes.object,
    modulo: PropTypes.object,
    datos: PropTypes.object,
};

export default reduxForm({
    form: 'FormCuentaBancaria',
    validate: (data) => {
        return validate(data, {
            numeroCuenta: validators.exists()('Este campo es requerido'),
            banco: validators.exists()('Este campo es requerido'),
            tipo: validators.exists()('Este campo es requerido'),
        });
    },
})(CuentaBancariaForm);
