
function validarPassword(value) {
    let msj = [];
    if (value.length < 8) {
        msj.push({"id":"1","msj": "Su contraseña debe tener al menos 8 caracteres."});
    }
    if (value.search(/[a-z]/) < 0) {
        msj.push({"id":"2","msj": "Su contraseña debe contener al menos una letra minúscula."});
    }
    if (value.search(/[A-Z]/) < 0) {
        msj.push({"id":"3","msj": "Su contraseña debe contener al menos una letra mayúscula."});
    }
    if (value.search(/[0-9]/) < 0) {
        msj.push({"id":"4","msj": "Su contraseña debe contener al menos un dígito."});
    }
    if (value.search(/[!@#$%^&*]/) < 0) {
        msj.push({"id":"5","msj": "Su contraseña debe contener al menos un carácter especial."});
    }
    if (msj.length > 0) {
        return msj;
    }
    return true;
}

export const validar = { validarPassword };
