import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import NavbarModulo from '../../components/NavbarModulo';
import DetalleListado from './DetalleListadoInvernaderos';
import DetalleListadoVer from './DetalleListadoInvernaderosVer';
import styled from 'styled-components';

import {
    renderField,
    AsyncSelectField,
} from "../../Utils/renderField/renderField";

function InvernaderosForm(props) {
    const {
        crear,
        listarFases,
        modulo,
        periodoD,
        detalleFase,
        registroListado,
        eliminarLineaFase,
        modificarListado
    } = props;

    const estilo = {
        overflow: "auto",
        height: "500px",

    };
    const editar = window.location.href.includes('editar');
    let titulo = editar ? 'Modificar invernadero' : 'Nuevo invernadero';
    let disabled = false;
    if (crear === false && editar === false) {
        disabled = true;
        titulo = 'Ver invernadero'
    }
    let ruta = window.location.href;
    let id_emp = ruta.split('/');

    return (
        <div>
            <NavbarModulo
                periodoD = {periodoD}
                modulo = {modulo}
                titulo = {titulo}
            />
            <div className="mb-4 card card-small text-white bg-dark mb-3">
                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                    <div className="d-flex flex-column flex-1 mx-4">
                        <div className='row mb-2'>
                            <div className='col-8'>
                                <Field
                                    name='nombre'
                                    disabled={disabled}
                                    component={renderField}
                                    className="form-control"
                                    placeholder="Invernadero"
                                    msj="Invernadero"
                                />
                            </div>
                        </div>
                        {(crear === true || editar === true) &&
                            <div>
                                <div className='row mb-2'>
                                    <div className='col-8'>
                                        <Field
                                            name="fase"
                                            placeholder="LLave..."
                                            className="form-control-find"
                                            loadOptions={listarFases}
                                            component={AsyncSelectField}
                                            msj="Producto"
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {(crear === true || editar === true) &&
                            <Contenido>
                                <button
                                    className='btn mr-2 mb-3 btn-primary'
                                    onClick={() => registroListado()}
                                >
                                    Agregar linea
                                </button>
                            </Contenido>
                        }
                        <br/>
                        {(crear === true || editar === true) &&
                            <div style={estilo}>
                                <DetalleListado
                                    detalle={detalleFase}
                                    eliminarLineaFase={eliminarLineaFase}
                                />
                            </div>
                        }
                        {(crear === false && editar === false) &&
                            <div style={estilo}>
                                <DetalleListadoVer
                                    detalle={detalleFase}
                                />
                            </div>
                        }
                        <br />
                        <div className='d-flex flex-row mt-3'>
                            {disabled == false &&
                                <button
                                className='btn mr-2 mb-3 btn-primary'
                                onClick={modificarListado}
                                >
                                {editar ? 'Modificar' : 'Guardar'}
                                </button>
                            }
                            <a
                                href = {`/#/produccion/${id_emp[5]}/invernaderos`}
                                className='btn btn-secondary mb-3'
                            >
                                Regresar
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

InvernaderosForm.propTypes = {
    crear: PropTypes.bool,
    lectura: PropTypes.object,
    editar: PropTypes.bool,
    bloqueo: PropTypes.bool,
    modulo: PropTypes.object,
    periodoD: PropTypes.object,
    obtenerBodegas: PropTypes.array,
    listarProductos: PropTypes.object,
    detalleProducto: PropTypes.object,
    registroListado: PropTypes.func,
    modificarListado: PropTypes.func,
    eliminarLineaProducto: PropTypes.func,
    confirmarEntrada: PropTypes.func,
    periodoD: PropTypes.object,
};

const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;

export default reduxForm({
    form: 'FormInvernaderos',
})(InvernaderosForm);
