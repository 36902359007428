import React from 'react';
import { useButton } from 'react-aria';
import { useRef } from 'react';

import "./X-Btn.css"

export default function XBtn({style, className, ...props}) {
    let ref = useRef();
    let {buttonProps, isPressed} = useButton(props, ref);
    let { children } = props;
    
    return (
      <button 
        {...buttonProps} 
        style={style}
        type="button" 
        ref={ref}
        formMethod='dialog'
        className={
          "x-btn" 
          + " "
          + (className || "") 
          + " "
          + (isPressed ? "x-btn-pressed" : "")
        }
        >
          {children}
        </button>
    )
  }