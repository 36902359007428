import { handleActions, createAction } from 'redux-actions';
import { NotificationManager } from "react-notifications";
import { api } from "api";

const ListarMarcasAction = createAction("LISTAR_MARCAS")

export const crearMarca = (data) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const formData = {
        empresa: id_emp[5],
        nombre: data.marca
    }
    return api.post('auto_ventas/marca', formData).then(() => {
        NotificationManager.success(
            'Marca agregada',
            'Exito',
            3000
        );
    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            2000
        );
    })
}

export const editarMarca = (data, marcaId) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const formData = {
        empresa: id_emp[5],
        nombre: data.marca
    }
    return api.put(`auto_ventas/marca/${marcaId}`, formData).then(() => {
        NotificationManager.success(
            'Marca Editada',
            'Exito',
            3000
        );
    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            2000
        );
    })
}




export const listarMarcas = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id }
    return api.get('auto_ventas/marca', params).then(response => {
        // dispatch({type: LISTAR_TIPOS, data: response})
        dispatch(ListarMarcasAction(response));
    }).catch((error) => {
        return [];
    })
}




export const actions = {
    crearMarca,
    listarMarcas,
    editarMarca
};

export const reducers = {
    [ListarMarcasAction]: (state, action) => {
        return {
            ...state,
            data: action.payload
        }
    },
};

export const initialState = {
    data: [],
};

export default handleActions(reducers, initialState)