import React from "react";
// import { Text } from "react-aria-components";
import { TextField, Label, Input, Button, Text } from "react-aria-components";
import { useController } from "react-hook-form";
import "./FicInput.css";
import "./Tabs.css";

export default function FicInput({
    label,
    description,
    errorMsj,
    nonForm,
    control,
    name,
    style,
    allCaps,
    errorMessage,
    ...props
}) {
    if (nonForm) {
        return (
            <TextField style={{ width: "100%", ...style }} {...props}>
                <Label>{label}</Label>
                <Input />
                {description && <Text slot="description">{description}</Text>}
                {errorMessage && (
                    <Text slot="errorMessage">{errorMessage}</Text>
                )}
            </TextField>
        );
    }
    const { field, fieldState } = useController({ control, name });
    const { onChange: fieldOnChange, ...rest } = field;
    return (
        <TextField
            {...props}
            {...rest}
            isInvalid={fieldState.invalid}
            style={{ width: "auto", ...style }}
            onChange={(e) => {
                props.onChange ? props.onChange(e) : "";
                if (allCaps) {
                    const allCapsStr = e.toUpperCase();
                    fieldOnChange(allCapsStr);
                } else {
                    fieldOnChange(e);
                }
            }}
        >
            <Label>{label}</Label>
            <Input />
            {description && <Text slot="description">{description}</Text>}
            {fieldState.error && (
                <Text slot="errorMessage">{fieldState.error.message}</Text>
            )}
        </TextField>
    );
}
