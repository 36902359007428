import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { FicComboBox } from "../../Utils/FormInputComponents";
import { MenuTrigger } from "react-aria-components";
import GenBtn from "../../components/GenBtn";
import { connect } from "react-redux";
import { setPeriodoLaboratorio } from "../../../../redux/modules/laboratorio/actions";
import { getPeriodos } from "./actions";
import { getPeriodo } from "../utils";
import AgregarPeriodoPopover from "../Periodos/AgregarPeriodoPopover";

const OptionsBarContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 40px;
    background-color: #1e1e1e;
    border-radius: 0px 0px 10px 10px;
    margin-bottom: 10px;
    border-bottom: 2px solid #009c03;

    .divider {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        line-height: 0;
    }

    .divider svg {
        position: relative;
        display: block;
        width: calc(100% + 1.3px);
        height: 15px;
    }

    .divider .shape-fill {
        /* fill: #4e4e4e; */
        fill: #bd00363e;
    }
`;

function OptionsBar({ setPeriodoLaboratorio }) {
    const [periodo, setPeriodo] = useState(getPeriodo());
    const [isPeriodoPopOverOpen, setIsPeriodoPopOverOpen] = useState(false);
    const [periodoSelectKey, setPeriodoSelectKey] = useState(0);
    const firstRender = useRef(null);

    const handlePeriodoChange = (value) => {
        setPeriodo(value);
        setPeriodoLaboratorio(value);
    };

    useEffect(() => {
        setPeriodoSelectKey((prev) => prev + 1);
    }, [isPeriodoPopOverOpen]);

    useEffect(() => {
        firstRender.current = true;
        const storedPeriodo = sessionStorage.getItem("periodoLaboratorio");
        if (storedPeriodo) {
            const parsedPeriodo = JSON.parse(storedPeriodo);
            handlePeriodoChange(parsedPeriodo);
        }
        return () => {
            firstRender.current = false;
        };
    }, []);

    return (
        <OptionsBarContainer>
            <div className="divider">
                <svg
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1200 120"
                    preserveAspectRatio="none"
                >
                    <path
                        d="M1200 0L0 0 598.97 114.72 1200 0z"
                        className="shape-fill"
                    ></path>
                </svg>
            </div>

            <div
                style={{
                    color: "#009c03",
                    fontSize: "1rem",
                    fontWeight: "bold",
                    verticalAlign: "top",
                }}
            >
                PERIODO: &nbsp;
            </div>
            <FicComboBox
                key={periodoSelectKey}
                componentStyle={{ maxWidth: "250px" }}
                nonForm
                value={periodo}
                loadOptions={async (search) => {
                    const periodos = await getPeriodos(search);
                    if (
                        firstRender.current &&
                        !sessionStorage.getItem("periodoLaboratorio")
                    ) {
                        firstRender.current = false;
                        handlePeriodoChange({
                            value: periodos[0].value,
                            label: periodos[0].label,
                        });
                    }
                    return periodos;
                }}
                onChange={handlePeriodoChange}
            />
            <MenuTrigger
                isOpen={isPeriodoPopOverOpen}
                onOpenChange={setIsPeriodoPopOverOpen}
            >
                <GenBtn
                    toolTipText="Agregar periodo"
                    style={{ marginBottom: "0.5rem" }}
                >
                    +
                </GenBtn>
                <AgregarPeriodoPopover />
            </MenuTrigger>
        </OptionsBarContainer>
    );
}

export default connect(null, { setPeriodoLaboratorio })(OptionsBar);

// const OptionsBarContainer = styled.div`
//     position: relative;
//     display: flex;
//     justify-content: flex-end;
//     align-items: center;
//     padding: 10px 40px;
//     background-color: #1e1e1e;
//     border-radius: 0px 0px 10px 10px;
//     margin-bottom: 10px;
//     border-bottom: 2px solid #009c03;

//     .divider {
//         position: absolute;
//         top: 0;
//         left: 0;
//         width: 100%;
//         overflow: hidden;
//         line-height: 0;
//     }

//     .divider svg {
//         position: relative;
//         display: block;
//         width: calc(100% + 1.3px);
//         height: 15px;
//     }

//     .divider .shape-fill {
//         /* fill: #4e4e4e; */
//         fill: #bd00363e;
//     }
// `;

// function OptionsBar({ setPeriodoLaboratorio }) {
//     const [periodo, setPeriodo] = useState(getPeriodo());
//     const [isPeriodoPopOverOpen, setIsPeriodoPopOverOpen] = useState(false);
//     const [periodoSelectKey, setPeriodoSelectKey] = useState(0);
//     const firstRender = useRef(null);

//     const handlePeriodoChange = (value) => {
//         setPeriodo(value);
//         setPeriodoLaboratorio(value);
//     };

//     useEffect(() => {
//         setPeriodoSelectKey((prev) => prev + 1);
//     }, [isPeriodoPopOverOpen]);

//     useEffect(() => {
//         firstRender.current = true;
//     }, []);
//     return (
//         // <React.Fragment>

//         // </React.Fragment>
//         <OptionsBarContainer>
//             <div className="divider">
//                 <svg
//                     data-name="Layer 1"
//                     xmlns="http://www.w3.org/2000/svg"
//                     viewBox="0 0 1200 120"
//                     preserveAspectRatio="none"
//                 >
//                     <path
//                         d="M1200 0L0 0 598.97 114.72 1200 0z"
//                         className="shape-fill"
//                     ></path>
//                 </svg>
//             </div>

//             <div
//                 style={{
//                     color: "#009c03",
//                     fontSize: "1rem",
//                     fontWeight: "bold",
//                     verticalAlign: "top",
//                 }}
//             >
//                 PERIODO: &nbsp;
//             </div>
//             <FicComboBox
//                 key={periodoSelectKey}
//                 componentStyle={{ maxWidth: "250px" }}
//                 nonForm
//                 value={periodo}
//                 loadOptions={(search) => {
//                     const periodos = getPeriodos(search);
//                     if(firstRender) {
//                         firstRender.current = false;
//                         return setPeriodo({value:periodos[0].value, label:periodos[0].label});
//                     }
//                     return periodos
//                 }}
//                 onChange={handlePeriodoChange}
//             />
//             <MenuTrigger
//                 isOpen={isPeriodoPopOverOpen}
//                 onOpenChange={setIsPeriodoPopOverOpen}
//             >
//                 <GenBtn
//                     toolTipText="Agregar periodo"
//                     style={{ marginBottom: "0.5rem" }}
//                 >
//                     +
//                 </GenBtn>
//                 <AgregarPeriodoPopover />
//             </MenuTrigger>
//             {/* <DialogTrigger>
//                 <GenBtn>
//                     <Fa.FaEllipsisV />
//                 </GenBtn>
//                 <MenuPopover>
//                     <FicComboBoxV2 />
//                     <FicRadio nonForm>
//                         <Radio value="1">Opcion 1</Radio>
//                         <Radio value="2">Opcion 2</Radio>
//                         <div>Option2</div>
//                         <Radio value="3">Opcion 3</Radio>
//                         <div>Option3</div>
//                     </FicRadio>
//                 </MenuPopover>
//             </DialogTrigger> */}
//         </OptionsBarContainer>
//     );
// }

// export default connect(null, { setPeriodoLaboratorio })(OptionsBar);
