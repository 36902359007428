import React, { Component } from 'react';
import Grid from '../../Utils/GridFiltro';
import { standardActions } from '../../Utils/GridFiltro/StandardActions';
import NavbarModulo from '../../components/NavbarModulo';

class OtrosGastosList extends Component{
    
    componentDidMount = () => {
        const { listar, leerPeriodoD, getModulo } = this.props;
        const ruta = window.location.href;
        const periodo = ruta.split('/');
        leerPeriodoD(periodo[5], periodo[7]);
        getModulo(periodo[5],periodo[4]);
        listar(periodo[7]);
    }

    render(){
        const { loader, eliminarLiquidacion,  data, periodoD, modulo } = this.props;
        let ruta = window.location.href;
        let id_emp = ruta.split('/');
        return(
            <React.Fragment>
                <NavbarModulo
                    periodoD = {periodoD}
                    modulo = {modulo}
                />
                <br />
                <h3 className="p-2 bg-secondary title">Otros gastos</h3>
                <br />
                <div className="d-flex flex-row justify-content-start mb-2">
                    <a
                        href={`/#/visitador_medico/${id_emp[5]}/otros_gastos_archivo/${id_emp[7]}`}
                        className='btn btn-primary'
                    >
                        Crear gasto
                    </a>
                </div>
                {data &&
                    <Grid 
                        hover 
                        striped 
                        data={data} 
                        loading={loader}
                        headerStyle={ { background: 'black' } } 
                        //onSortChange={onSortChange} 
                    >
                    <TableHeaderColumn
                        isKey
                        dataField="codigo"
                        width="100"
                        dataSort
                        thStyle={ 
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Codigo
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="nombre"
                        width="450"
                        dataSort
                        thStyle={ 
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Nombre
                    </TableHeaderColumn>
                    <TableHeaderColumn
                            headerAlign="center"
                            dataAlign='right'
                            dataField="total"
                            width="150"
                            dataFormat={(cell, row)=>{
                                return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Math.abs(cell));
                            }}
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Total
                        </TableHeaderColumn>
                    <TableHeaderColumn
                        thStyle={
                        {color: 'white'}}
                        dataField="id"
                        width="80"
                        dataAlign="center"
                        dataSort
                        dataFormat={standardActions({
                            ver_fac_fel_especial: 'otros_gastos',
                            eliminar: eliminarLiquidacion,
                            empresa: id_emp[5],
                            periodo: id_emp[7],
                            modulo: id_emp[4],
                        })} 
                    >
                        Acciones
                    </TableHeaderColumn>
                </Grid>
                }
            </React.Fragment>
        );
    }
}

export default OtrosGastosList;
