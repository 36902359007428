import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/compras/importacion';
import Importaciones from './ImportacionFormTabs';


const ms2p = (state) => {
    return {
        ...state.importacion,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Importaciones);
