import React from 'react';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import * as BsIcons from "react-icons/bs";

export const SidebarData = [
    {
        title: 'Inicio',
        path: '/produccion',
        icon: <BsIcons.BsGrid1X2Fill />,
    },
    {
        title: 'Periodos',
        path: '/produccion/periodos',
        icon: <BsIcons.BsFillCalendarDateFill />,
    },
    {
        title: 'Series',
        path: '/produccion/series',
        icon: <FaIcons.FaGripHorizontal />,
    },
    {
        title: 'Catalogos',
        path: '#',
        icon: <FaIcons.FaBook />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav: [
            {
                title: 'Centro de trabajo',
                path: '/produccion',
                path_s: 'centro_trabajo',
                icon: <BsIcons.BsPinFill />,
            },
            {
                title: 'Llave',
                path: '/produccion',
                path_s: 'fase',
                icon: <FaIcons.FaGripHorizontal />,
            },
            {
                title: 'Piloto',
                path: '/produccion',
                path_s: 'piloto',
                icon: <RiIcons.RiTruckFill />,
            },
        ]
    },
    {
        title: 'Registros',
        path: '#',
        icon: <FaIcons.FaDollyFlatbed />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav: [
            {
                title: 'Ingreso de producción',
                path: '/produccion',
                path_s: 'seleccionar_periodo_ingreso',
                icon: <BsIcons.BsBasketFill />,
            },
            {
                title: 'Envío de producción',
                path: '/produccion',
                path_s: 'seleccionar_periodo_envio',
                icon: <BsIcons.BsBasketFill />,
            },
            {
                title: 'Consumo',
                path: '/produccion',
                path_s: 'seleccionar_periodo_consumo',
                icon: <BsIcons.BsBasketFill />,
            },
            {
                title: 'Devolución de productos',
                path: '/produccion',
                path_s: 'seleccionar_periodo_devolucion_producto',
                icon: <FaIcons.FaLongArrowAltUp />,
            },
            {
                title: 'Consolidación de envios',
                path: '/produccion',
                path_s: 'seleccionar_periodo_consolidacion_envio',
                icon: <BsIcons.BsGrid3X2GapFill />,
            },
            {
                title: 'Recibos de caja',
                path: '/produccion',
                path_s: 'seleccionar_recibo_caja',
                icon: <BsIcons.BsCardChecklist />,
            },
            {
                title: 'Corte de caja',
                path: '/produccion',
                path_s: 'seleccionar_corte_caja',
                icon: <BsIcons.BsLockFill />,
            },
        ]
    },
    {
        title: 'Control de cajas',
        path: '#',
        icon: <FaIcons.FaBook />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav: [
            {
                title: 'Invernaderos',
                path: '/produccion',
                path_s: 'invernaderos',
                icon: <BsIcons.BsPinFill />,
            },
            {
                title: 'Insumo',
                path: '/produccion',
                path_s: 'insumo',
                icon: <BsIcons.BsInboxesFill />,
            },
            {
                title: 'Devolución de cajas',
                path: '/produccion',
                path_s: 'seleccionar_devolucion_cajas',
                icon: <BsIcons.BsBoxArrowUp />,
            },
            {
                title: 'Distribución de cajas',
                path: '/produccion',
                path_s: 'seleccionar_distribucion_cajas',
                icon: <BsIcons.BsBoxArrowRight/>,
            },
            {
                title: 'Traslado de cajas',
                path: '/produccion',
                path_s: 'seleccionar_traslado_cajas',
                icon: <FaIcons.FaArrowsAltH/>,
            },
            {
                title: 'Compra de cajas',
                path: '/produccion',
                path_s: 'seleccionar_compra_cajas',
                icon: <BsIcons.BsInboxFill />,
            },
            {
                title: 'Salida de cajas',
                path: '/produccion',
                path_s: 'seleccionar_baja_cajas',
                icon: <BsIcons.BsBoxArrowDown />,
            },
        ]
    },
    {
        title: 'Gastos',
        path: '#',
        icon: <FaIcons.FaBook />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav: [
            /*{
                title: 'Trasplante',
                path: '/produccion',
                path_s: 'seleccionar_siembra',
                icon: <BsIcons.BsFillLayersFill />,
            },
            {
                title: 'Fecha cosecha',
                path: '/produccion',
                path_s: 'fecha_cosecha',
                icon: <BsIcons.BsFillCalendarDateFill />,
            },
            {
                title: 'Fecha final de cosecha',
                path: '/produccion',
                path_s: 'fecha_final',
                icon: <BsIcons.BsFillCalendarDateFill />,
            },*/
            {
                title: 'Tipo de gasto',
                path: '/produccion',
                path_s: 'tipo_gasto',
                icon: <FaIcons.FaGripHorizontal />,
            },
            {
                title: 'Gasto',
                path: '/produccion',
                path_s: 'seleccionar_gasto',
                icon: <BsIcons.BsCashCoin />,
            },
            {
                title: 'Cuentas sin incluir',
                path: '/produccion',
                path_s: 'cuentas_sin_incluir',
                icon: <FaIcons.FaTasks />,
            },
        ]
    },
    /*{
        title: 'Facturas',
        path: '/produccion/seleccionar_periodo_factura',
        icon: <FaIcons.FaCashRegister />,
    },*/
    {
        title: 'Nota de cobro',
        path: '/produccion/seleccionar_periodo_nota',
        icon: <FaIcons.FaListUl />,
    },
    {
        title: 'Informes',
        path: '/produccion/informes',
        icon: <FaIcons.FaClipboard />,
    }
]
