
import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';
import moment from 'moment';

const LISTADO_ENTRADA = 'LISTADO_ENTRADA';
const LOADER = 'LOADER';
const MODULO = 'MODULO';
const PERIODO_D = 'PERIODOD';
const PARAMETRO = 'PARAMETRO';
const NUMERO_ORDEN = 'NUMERO_ORDEN';
const DETALLE = 'DETALLE';
const DETALLE_ORDEN = 'DETALLE_ORDEN';
const LECTURA = 'LECTURA';
const PROVEEDOR = 'PROVEEDOR';
const LISTADO_BODEGAS = 'LISTADO_BODEGAS';
const DETALLE_PRODUCTOS = 'DETALLE_PRODUCTOS';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let periodo = id_emp[7];
    let motivo = 'ING'
    const params = { id, periodo, motivo };
    api.get('/movimiento_inventario', params).then((response)=>{
        dispatch({ type: LISTADO_ENTRADA, data: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar ingresos de inventario',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leerPeriodoD = (id, id_periodo) => (dispatch) => {
    const params = { id, id_periodo };
    api.get('/periodos/periodo', params).then((response) => {
        dispatch({ type: PERIODO_D, periodoD: response.periodo });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error al consultar periodo',
            'Error',
            2000
        );
    })
}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        if (error.status === 401){
            NotificationManager.error(
              error.body.msj,
              'Error',
              0
            );
        }
        if (error.status === 400){
            NotificationManager.error(
              'Ocurrió un error en buscar modulo',
              'Error',
              0
            );
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const parametrosEmpresa = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id }
    dispatch(setLoader(true));
    api.get('/empresa/empresa_parametros', params).then((response) => {
        dispatch({ type: PARAMETRO, datos: response });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error al consultar parametros',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const  registroIngreso = (periodoD,detalleOrd) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormIngresoInventario.values;
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fechaRegistro)
    if (fecha>=fechaI && fecha<=fechaF){
        if (data.bodega === undefined || data.bodega === null){
            Swal.fire({
                title: 'Ingreso de inventario',
                text: 'Debe seleccionar una bodega',
                type: 'error',
            })
            return false;
        }else{
            const formData = {
                fecha: data.fechaRegistro,
                descripcion: data.descripcion,
                oc: data.ordenCompra.value,
                serieFactura: data.serieFactura,
                numeroFactura: data.numeroFactura,
                detalleOrden: detalleOrd,
                bodega: data.bodega,
                motivo: 'ING',
                tipoDoc: 'ING_OC',
                empresa: id_emp[5],
                periodo: id_emp[7]
            }
            dispatch(setLoader(true));
            api.post('/movimiento_inventario/', formData).then(() => {
                NotificationManager.success(
                    'Ingreso de inventario guardado correctamente',
                    'Exito',
                    3000
                );
                ruta = `/inventario/${id_emp[5]}/ingreso_inventario/${id_emp[7]}`
                dispatch(push(ruta));
                /*Swal.fire({
                    title: '¿Imprimir orden de compra?',
                    text: '¡Desea imprimir orden de compra!',
                    type: 'question',
                    showCancelButton: true,
                    confirmButtonText: '¡Sí, imprimir!',
                    cancelButtonText: 'No, cancelar',
                    reverseButtons: true
                }).then((result) => {
                    if (result.value) {
                        let reporte = 'imprimirOrden';
                        let idOrden = response.id;
                        let id = id_emp[5];
                        const params = { id, reporte, idOrden };
                        dispatch(setLoader(true));
                        api.getPdf('/orden_compra', params).then((response)=>{
                            let ruta = `/compras/${id_emp[5]}/orden_compra/${id_emp[7]}`
                            dispatch(push(ruta));
                            if (response.headers['content-type'] == 'application/pdf'){
                                var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
                                var link = document.createElement('a');
                                link.href = window.URL.createObjectURL(blob);
                                link.download = 'cheque.pdf';
                                window.open(link, '_blank');
                                return false;
                            }
                            
                        }).catch((error)=>{
                            NotificationManager.error(
                                'Ocurrió un error al generar reporte',
                                'Error',
                                2000
                            );
                        }).finally(() => {
                            dispatch(setLoader(false));
                        });
                    }else {
                        window.location.reload(true);
                    }
                });*/
            }).catch((error) => {
                NotificationManager.error(
                    error.msj,
                    'Error',
                    0
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }
    }else {
        Swal.fire({
            title: 'Ingreso de inventario',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })    
    }
}

export const numeroOrdenCompra = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let params = {id}
    
    api.get('/orden_compra/numero_orden/', params).then((response) => {
        dispatch({ type: NUMERO_ORDEN, numeroOrden: parseInt(response.numero)+1 });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar numero de orden',
            'Error',
            2000
        );
    })
}

export const imprimirOrden = (idOrden) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let reporte = 'imprimirOrden';
    const params = { id, reporte, idOrden };
    dispatch(setLoader(true));
    api.getPdf('/orden_compra', params).then((response)=>{
        if (response.headers['content-type'] == 'application/pdf'){
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'orden_compra.pdf';
            window.open(link, '_blank');
            return false;
        }
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al generar reporte',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

let detalleOd = [];
const leerDetalleOrden = id => (dispatch) => {
    let params = { id }
    api.get('/orden_compra_detalle/detalle_envio', params).then((response) => {
        dispatch({ type: PROVEEDOR, prov: response.proveedor });
        detalleOd=[]
        response.detalle.forEach(data => {
            detalleOd.push({
                id: data.id,
                cantidad: data.cantidad,
                sku: data.sku,
                producto: data.producto,
                productoN: data.productoN,
                descuento: data.descuento,
                precio_unitario: data.precio_unitario,
                total: data.total,
                tipo: data.tipo,
            })
        });
        dispatch({ type: DETALLE_ORDEN, detalleOrd: detalleOd });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};

const limpiarOrden = () => (dispatch) => {
    detalleOd = [];
    dispatch({ type: DETALLE_ORDEN, detalleOrd: detalleOd });
};

const leerDetalleProductos = id => (dispatch) => {
    api.get(`/movimiento_inventario_detalle/${id}`).then((response)=>{
        dispatch({ type: DETALLE_PRODUCTOS, detalleProducto: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};


export const leer = (id) => (dispatch) => {
    let params = {id};
    dispatch(setLoader(true));
    api.get('/movimiento_inventario/ingreso_inventario', params).then((response) => {
        dispatch(initializeForm('FormIngresoInventario', response ));
        dispatch(leerDetalleProductos(response.id));
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar la entrada',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const listarOrdenes = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/orden_compra/ordenes', params).then(data => {
        const list_ord = [];
        if (data) {
            data.forEach(item => {
                list_ord.push({
                    value: item.id,
                    label: 'OC' + ' -- ' +item.numero
                })
            })
        }
        return list_ord;
    }).catch((error) => {
        return [];
    })
}

export const listarBodegas = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    api.get('/bodega', params).then((response) => {
        const bodega = [];
        response.forEach(item=>{
            bodega.push({
                value: item.id,
                label: item.numero + ' / ' + item.bodega
            })
        })
        dispatch({ type: LISTADO_BODEGAS, listaBodegas: bodega });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar bodegas',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const eliminarIngresoInventario = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`/movimiento_inventario/${id}`).then(() => {
        dispatch(listar());
        NotificationManager.success(
            'Ingreso de inventario borrado correctamente',
            'Exito',
            3000
        );
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar ingreso de inventario',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const actions = {
    listar,
    listarOrdenes,
    leerDetalleOrden,
    registroIngreso,
    leer,
    limpiarOrden,
    leerPeriodoD,
    getModulo,
    parametrosEmpresa,
    numeroOrdenCompra,
    imprimirOrden,
    listarBodegas,
    eliminarIngresoInventario
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_ENTRADA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [PERIODO_D]: (state, { periodoD }) => {
        return {
            ...state,
            periodoD,
        };
    },
    [PARAMETRO]: (state, { datos }) => {
        return {
            ...state,
            datos,
        };
    },
    [DETALLE]: (state, { detalleOrden }) => {
        return {
            ...state,
            detalleOrden,
        };
    },
    [NUMERO_ORDEN]: (state, { numeroOrden }) => {
        return {
            ...state,
            numeroOrden,
        };
    },
    [DETALLE_ORDEN]: (state, { detalleOrd }) => {
        return {
            ...state,
            detalleOrd,
        };
    },
    [LECTURA]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
    [PROVEEDOR]: (state, { prov }) => {
        return {
            ...state,
            prov,
        };
    },
    [LISTADO_BODEGAS]: (state, { listaBodegas }) => {
        return {
            ...state,
            listaBodegas,
        };
    },
    [DETALLE_PRODUCTOS]: (state, { detalleProducto }) => {
        return {
            ...state,
            detalleProducto,
        };
    },
};

export const initialState = {
    loader: false,
    modulo: [],
    periodoD: [],
    data: [],
    detalleOrden: [],
    numeroOrden: '',
    detalleOrd: [],
    lectura: [],
    prov: [],
    listaBodegas: [],
};

export default handleActions(reducers, initialState)