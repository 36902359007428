import React, { Component } from 'react';
import styled from 'styled-components';
import { SidebarDataUserS } from '../Sidebar/SideBarDataUserS';
import MenuItemsUser from './NavbarMenuUser';

const defaultAvatar = require("assets/img/avatar-placeholder.png");

class NavbarModulo extends Component {
    constructor(props) {
        super(props);
        this.state = { dropdownOpen: false };
    }
    render() {
        const { logOut, user, empresaData, } = this.props;
        let empresa = "Modulos"
        return (
            <NavBar>
                <div className="imageBoxLogo d-table m-auto">
                    <Image
                        src={require('assets/img/SIFW-LOGO-Red.png')}
                        alt="Logo" />
                </div>
                <div className="titleNav">
                    <Titulo>{empresa}</Titulo>
                </div>
                <div className="imageBoxLogo d-table m-auto">
                    {empresaData.logo &&
                        <ImageLogo
                            src={empresaData.logo}
                            alt="Logo" />
                    }
                </div>
                <NavBarMenuUser>
                    <ul className="borderUser flex-row navbar-nav">
                        {SidebarDataUserS.map((menu, index) => {
                            return <MenuItemsUser
                                items={menu}
                                key={index}
                                img={(user.avatar) ? __CONFIG__.api_image + user.avatar : defaultAvatar}
                                user={user.username}
                                logOut={logOut}
                            />;
                        })}
                    </ul>
                </NavBarMenuUser>
            </NavBar>

        );
    }
}

export default NavbarModulo;

const NavBar = styled.div`
    background-color: #333;
    height: 61px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const NavBarMenuUser = styled.div`
    background-color: black;
    height: 61px;
    width: 450px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const Titulo = styled.div`
    color: white;
    padding-top: 5px;
    padding-left: 10px;
    font-weight: bold;
    font-size: 1.5rem;
`;

const Image = styled.img`
    max-width: 100%;
    max-height: 100%;
    display: inline-block;
    position: absolute;
    @media (max-width: 402px) {
        display: none;
        
    }
`
const ImageLogo = styled.img`
    max-width: 100%;
    max-height: 100%;
    display: inline-block;
    position: absolute;
    bottom: 0;
    margin: 0 auto;
    left: 0;
    right: 0;
    @media (max-width: 402px) {
        display: none;
    }
`;
