import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import Card from './ModuloCard';
import LoadMask from "../Utils/LoadMask/LoadMask";
import "./style.css";

const renderModulo = (modulo, id_emp) => {
    return modulo.map((value, index) =>
        <Grid item sm={4} xs={12}>
            <Card 
                key={index} 
                logo={value.logo}
                name = {value.modulo}
                ruta = {value.ruta +'/' + id_emp} 
            />
        </Grid>  
    );
}

class Modulos extends Component {
    componentDidMount = () => {
        const { match, getModulos } = this.props;
        const id = match.params.empresa;
        getModulos(id);
    }
    render() {
    const { modulo, loader, empresaData, match, totalE } = this.props;
    const id_emp = match.params.empresa;
    return (
        <LoadMask loading={loader} dark>
            <div className='mt-2 ml-3 title'>
                <h4>{empresaData.razon_social}</h4> 
            </div>
            { totalE > 1 &&
                <a 
                href='/#/empresas'
                className='btn btn-secondary ml-3'
            >
                Empresas
            </a>
            }
            <div className='formato'>
                {modulo && modulo.length>0 ? (
                    ( modulo &&
                        <Grid container spacing={3}>
                            {renderModulo(modulo, id_emp)}
                        </Grid>
                    )
                ) : (
                    <div className="d-flex flex-column align-items-center pt-3">
                        <img className="mt-1"
                            src={require('assets/img/box-sinfondo.png')}
                            alt="Error"
                            height={200}
                        />
                    </div>
                )}
               
            </div>
        </LoadMask>
    );
  }
}

export default Modulos;