import React, { Component } from 'react';
import Formulario from './LineasPolizaForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";

class SubirLineasPoliza extends Component{
    state={
        archivo: null
    }

    setArchivo = (archivo) => {
        this.setState({archivo});
    };
    
    subirLineas = (data) => {
        const { subirLineas } = this.props;
        subirLineas( {...data, archivo: null},
        [{ "file": this.state.archivo, "name": 'archivo' },])
    }

    render(){
        const { loader } = this.props;
    
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    onSubmit = {this.subirLineas}
                    setArchivo={this.setArchivo} 
                />
            </LoadMask>
        );
    }
}

export default SubirLineasPoliza
