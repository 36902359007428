import React, { Component } from 'react';
import Formulario from './SeleccionarEmpresaForm';


class SeleccionarEmpresa extends Component{

    render(){
        const { listarEmpresas } = this.props;
        return(
           
            <Formulario
                listarEmpresas={ listarEmpresas } 
            />
           
        );
    } 
}

export default SeleccionarEmpresa;