import React, { useEffect } from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import { reduxForm } from 'redux-form/immutable';
import NavbarModulo from '../components/NavbarModulo';
import "../../../../style/autoVentas/Cards.css"

const ListaDeCarros = ({ carros }) => {
  return (
    <div className="cars-container">
        {carros.map((carro) => (

            <div key={carro.id} className="car-card">
                {carro.foto ? 
                (
                    <img src={carro.foto} alt={carro.marca.label} className='car-img' />
                )
                    : 
                (
                    <div className="placeholder-img">No hay imagen</div>
                )}
                <div className="car-card-content">
                    <h4 className='car-name'>
                        {`${carro.marca.label} - ${carro.linea.label} - ${carro.modelo}`}
                    </h4>
                    <div class="car-info-container">
                        <div className='car-info'><div className='car-info-title' >Combustible:&nbsp;</div>{carro.combustible.label}</div>
                        <div className='car-info'><div className='car-info-title'>Millas:&nbsp;</div>{carro.millas}</div>
                        <div className='car-info'><div className='car-info-title'>Motor:&nbsp;</div>{carro.motor}</div>
                        <div className='car-info'><div className='car-info-title'>Modalidad:&nbsp;</div>{carro.modalidad}</div>
                    </div>
                </div>
                {/* <button className='view-btn'>VER</button> */}
            </div>
        ))
        }
    </div>
  );
};


function AutoVentas(props) {
    const {
        listarAutos,
        listaDeAutos
    } = props;

    const description = 'Auto Ventas';
    
    useEffect(() => {
        listarAutos()
        
    },[])
    
    return (
        <div>
            <NavbarModulo
                modulo={{modulo: "Modulos"}}
                titulo={description}
            />
            {/* <Helmet>
                <title>{description}</title>
            </Helmet> */}


            <h1>Vehiculos</h1>
            <ListaDeCarros carros={listaDeAutos} />

            <Toolbar >
                <div style={{ color: "white" }}>
                    {/* <Typography variant="h3">
                        {description}
                    </Typography> */}
                </div>
            </Toolbar>
        </div>
    );
}

export default reduxForm({
    form: 'FormAutoVentasHome',

})(AutoVentas);
