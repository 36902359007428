import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/compras/notaCredito';
import NC from './NCProveedorTabs';


const ms2p = (state) => {
  return {
    ...state.nota_credito_cxp,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(NC);
