import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';

import {
    renderField,
    SelectField,
    renderNumber,
    renderFilePickerC,
    renderDayPickerB,
    AsyncSelectField,
    renderFieldCheck,
    renderFilePickerFoto
} from "../../Utils/renderField/renderField";

function ColaboradorForm(props) {
    const { crear, listarMunicipio,
        obtenerPaises, listarOcupacion, listaDepto, listaMuni, datos,
        setFoto, lectura, disabled, editar, listarDepto, documentos } = props;

    let autoFocus = true;
    
    const tipo = [
        { label: "DPI", value: 1 },
        { label: "CERTIFICADO", value: 2 },
        { label: "PASAPORTE", value: 3 },
    ];
    const genero = [
        { label: "HOMBRE", value: 1 },
        { label: "MUJER", value: 2 },
    ];
    const estado_civil = [
        { label: "SOLTERO(A)", value: 1 },
        { label: "CASADO(A)", value: 2 },
        { label: "UNIDO(A)", value: 3 },
    ];
    const licencia = [
        { label: "NINGUNO", value: 'N' },
        { label: "VEHICULO", value: 'V' },
        { label: "MOTO", value: 'M' },
        { label: "PROFESIONAL", value: 'P' },
    ];
    const etnia = [
        { label: "MAYA", value: 1 },
        { label: "GARIFUNA", value: 2 },
        { label: "XINCA", value: 3 },
        { label: "AFRODESCENDIENTE / CREOLE / AFROMESTIZO", value: 4 },
        { label: "LADINO", value: 5 },
        { label: "EXTRANJERO", value: 6 },
    ];
    const nivel_educativo = [
        { label: "NINGUNO", value: 1 },
        { label: "PRIMARIA INCOMPLETA", value: 2 },
        { label: "PRIMARIA COMPLETA", value: 3 },
        { label: "BASICO INCOMPLETO", value: 4 },
        { label: "BASICO COMPLETO", value: 5 },
        { label: "DIVERSIFICADO INCOMPLETO", value: 6 },
        { label: "DIVERSIFICADO COMPLETO", value: 7 },
        { label: "ESTUDIANTE UNIVERSITARIO", value: 8 },
        { label: "TECNICO UNIVERSITARIO", value: 9 },
        { label: "LICENCIATURA", value: 10 },
        { label: "POSTGRADO", value: 11 },
        { label: "MAESTRIA", value: 12 },
        { label: "DOCTORADO", value: 13 },
    ];
    const discapacidad = [
        { label: "NINGUNA", value: 1 },
        { label: "DISCAPACIDAD AUDITIVA", value: 2 },
        { label: "DISCAPACIDAD VISUAL", value: 3 },
        { label: "DISCAPACIDAD MULTIPLE", value: 4 },
        { label: "DISCAPACIDAD FISICA O MOTORA", value: 5 },
        { label: "DISCAPACIDAD INTELECTUAL", value: 6 },
        { label: "OTRA", value: 7 },
    ];
    const temp_contrato = [
        { label: "INDEFINIDO", value: 1 },
        { label: "DEFINIDO", value: 2 },
    ];
    const tipo_contrato = [
        { label: "VERBAL", value: 1 },
        { label: "ESCRITO", value: 2 },
    ];
    const jornada = [
        { label: "DIURNA", value: 1 },
        { label: "MIXTA", value: 2 },
        { label: "NOCTURNA", value: 3 },
        { label: "NINGUNA", value: 4 },
    ];
    const idioma = [
        { label: "Achi´", value: 1 },
        { label: "Akateka", value: 2 },
        { label: "Awakateka", value: 3 },
        { label: "Ch´orti´", value: 4 },
        { label: "Chalchiteka", value: 5 },
        { label: "Chuj", value: 6 },
        { label: "Itza´", value: 7 },
        { label: "Ixil", value: 8 },
        { label: "Jakalteka", value: 9 },
        { label: "K´iche´", value: 10 },
        { label: "Kaqchikel", value: 11 },
        { label: "Mam", value: 12 },
        { label: "Mopan", value: 13 },
        { label: "Poqomam", value: 14 },
        { label: "Poqomchi´", value: 15 },
        { label: "Q´anjob´al", value: 16 },
        { label: "Q´eqchi´", value: 17 },
        { label: "Sakapulteka", value: 18 },
        { label: "Sipakapense", value: 19 },
        { label: "Tektiteka", value: 20 },
        { label: "Tz´utujil", value: 21 },
        { label: "Uspanteka", value: 22 },
        { label: "Español", value: 99 },
    ];

    const handleOnChange = (e, value) => {
        listarMunicipio(value)
    };

    const handleOnChangeNac = (e, value) => {
        listarDepto(value)
    };

    const [jubilado, setJubilado] = useState(false);

    const onCheckboxClicked = (idx, isChecked) => {
        setJubilado(isChecked);
        localStorage.removeItem('jubilado');
    }
    
    return (
        <div>
            <div className='row mb-2'>
                <div className='col-10'>
                    <div className='row'>
                        {crear === true &&
                            <div className='col-3'>
                                <Field
                                    autoFocus={autoFocus}
                                    name="codigo"
                                    placeholder="Codigo"
                                    className="form-control"
                                    component={renderNumber}
                                    disabled={disabled}
                                    msj="Código"
                                />
                            </div>
                        }
                        {editar === true &&
                            <div className='col-3'>
                                <Field
                                    autoFocus={autoFocus}
                                    name="codigo"
                                    placeholder="Codigo"
                                    className="form-control"
                                    component={renderNumber}
                                    disabled={true}
                                    msj="Código"
                                />
                            </div>
                        }
                        {(crear === false && editar === false) &&
                            <div className='col-3'>
                                <Field
                                    autoFocus={autoFocus}
                                    name="codigo"
                                    placeholder="Codigo"
                                    className="form-control"
                                    component={renderNumber}
                                    disabled={true}
                                    msj="Código"
                                />
                            </div>
                        }
                        <div className='col-3'>
                            <div className="form-floating">
                                <Field
                                    name="tipo_documento"
                                    options={documentos}
                                    crear={crear}
                                    dato={crear === true ? 0 : lectura.tipo_documento}
                                    component={SelectField}
                                    className="form-select"
                                    disabled={disabled}
                                    msj="Tipo documento"
                                />
                            </div>
                        </div>
                        <div className='col-3'>
                            <Field
                                name="documento"
                                placeholder="Documento"
                                className="form-control"
                                component={renderField}
                                disabled={disabled}
                                msj="Documento"
                            />
                        </div>
                        <div className='col-3'>
                            <Field
                                name="nitCol"
                                placeholder="Nit"
                                className="form-control"
                                component={renderField}
                                disabled={disabled}
                                msj="Nit"
                            />
                        </div>
                        <div class="w-100 mb-2"></div>
                        <div className='col-3'>
                            <Field
                                name="primer_nombre"
                                placeholder="Primer nombre"
                                className="form-control"
                                component={renderField}
                                disabled={disabled}
                                msj="Primer nombre"
                            />
                        </div>
                        <div className='col-3'>
                            <Field
                                name="segundo_nombre"
                                placeholder="Segundo nombre"
                                className="form-control"
                                component={renderField}
                                disabled={disabled}
                                msj="Segundo nombre"
                            />
                        </div>
                        <div className='col-3'>
                            <Field
                                name="primer_apellido"
                                placeholder="Primer nombre"
                                className="form-control"
                                component={renderField}
                                disabled={disabled}
                                msj="Primer apellido"
                            />
                        </div>
                        <div className='col-3'>
                            <Field
                                name="segundo_apellido"
                                placeholder="Primer nombre"
                                className="form-control"
                                component={renderField}
                                disabled={disabled}
                                msj="Segundo apellido"
                            />
                        </div>
                        <div class="w-100 mb-2"></div>
                        <div className='col-3'>
                            <Field
                                name="apellido_casada"
                                placeholder="Apellido de casada"
                                className="form-control"
                                component={renderField}
                                disabled={disabled}
                                msj="Apellido de casada"
                            />
                        </div>
                        <div className='col-3'>
                            {obtenerPaises &&
                                <div className="form-floating">
                                    <Field
                                        name="nacionalidad"
                                        options={obtenerPaises}
                                        crear={crear}
                                        dato={crear === true ? 0 : lectura.nacionalidad}
                                        component={SelectField}
                                        className="form-select"
                                        onChange={handleOnChangeNac}
                                        disabled={disabled}
                                        msj="Nacionalidad"
                                    />
                                </div>
                            }
                        </div>
                        {datos.codigoPais == 'GTM' &&
                            <div className='col-3'>
                                {listaDepto &&
                                    <div>
                                        {listaDepto.length >= 1 &&
                                            <div className="form-floating">
                                                <Field
                                                    name="departamento"
                                                    options={listaDepto}
                                                    crear={crear}
                                                    dato={crear === true ? 0 : lectura.departamento}
                                                    component={SelectField}
                                                    onChange={handleOnChange}
                                                    className="form-select"
                                                    disabled={disabled}
                                                    msj="Departamento"
                                                />
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        }
                        {crear === true && datos.codigoPais == 'GTM' &&
                            <div className='col-3'>
                                {listaMuni &&
                                    <div>
                                        {listaMuni.length >= 1 &&
                                            <div className="form-floating">
                                                <Field
                                                    name="municipio"
                                                    options={listaMuni}
                                                    crear={crear}
                                                    dato={0}
                                                    component={SelectField}
                                                    className="form-select"
                                                    disabled={disabled}
                                                    msj="Municipio"
                                                />
                                            </div>
                                        }   
                                    </div>
                                }
                            </div>
                        }
                        {editar === true && datos.codigoPais == 'GTM' &&
                            <div className='col-3'>
                                {listaMuni.length >=1 &&
                                    <div className="form-floating">
                                        <Field
                                            name="municipio"
                                            options={listaMuni}
                                            crear={crear}
                                            dato={crear === true ? 0 : lectura.municipio}
                                            component={SelectField}
                                            className="form-select"
                                            disabled={disabled}
                                            msj="Municipio"
                                        />
                                    </div>
                                }
                            </div>
                        }
                        {crear === false && editar === false && datos.codigoPais == 'GTM' &&
                            <div className='col-3'>
                                {listaMuni.length &&
                                    <div className="form-floating">
                                        <Field
                                            name="municipio"
                                            options={listaMuni}
                                            crear={crear}
                                            dato={crear === true ? 0 : lectura.municipio}
                                            component={SelectField}
                                            className="form-select"
                                            disabled={disabled}
                                            msj="Municipio"
                                        />
                                    </div>
                                }
                            </div>
                        }
                        <div class="w-100 mb-2"></div>
                        <div className='col-3'>
                            <Field
                                name="fecha_nacimiento"
                                component={renderDayPickerB}
                                msj="Fecha nacimiento"
                            />
                        </div>
                        <div className='col-3'>
                            <div className="form-floating">
                                <Field
                                    name="genero"
                                    options={genero}
                                    crear={crear}
                                    dato={crear === true ? 0 : lectura.genero}
                                    component={SelectField}
                                    className="form-select"
                                    disabled={disabled}
                                    msj="Género"
                                />
                            </div>
                        </div>
                        <div className='col-3'>
                            <div className="form-floating">
                                <Field
                                    name="estado_civil"
                                    options={estado_civil}
                                    crear={crear}
                                    dato={crear === true ? 0 : lectura.estado_civil}
                                    component={SelectField}
                                    className="form-select"
                                    disabled={disabled}
                                    msj="Estado civil"
                                />
                            </div>
                        </div>
                        <div className='col-3'>
                            <Field
                                name="telefonoCol"
                                placeholder="telefono"
                                className="form-control"
                                component={renderField}
                                disabled={disabled}
                                msj="Telefono"
                            />
                        </div>
                    </div>
                </div>
                {(crear === false && editar == false) && lectura.foto &&
                    <div className="col-2">
                        <label htmlFor="logo">Foto colaborador</label>
                        <Field
                            photo={lectura.foto}
                            setFile={setFoto}
                            name="foto"
                            disabled="true"
                            component={renderFilePickerFoto}
                        />
                    </div>
                }
                {(crear === true) &&
                    <div className="col-2">
                        <label htmlFor="foto">Foto colaborador</label>
                        <Field
                            photo={null}
                            setFile={setFoto}
                            name="foto"
                            component={renderFilePickerC}
                        />
                    </div>
                }
                {(editar === true) &&
                    <div className="col-2">
                        <label htmlFor="logo">Foto colaborador</label>
                        <Field
                            photo={lectura.foto}
                            setFile={setFoto}
                            name="foto"
                            component={renderFilePickerC}
                        />
                    </div>
                }
            </div>
            <div className='row mb-2'>
                <div className='col-3'>
                    <Field
                        name="igss"
                        placeholder="Seguro social"
                        className="form-control"
                        component={renderField}
                        disabled={disabled}
                        msj="Seguro social"
                    />
                </div>
                <div className='col-3'>
                    <div className="form-floating">
                        <Field
                            name="tipo_licencia"
                            options={licencia}
                            crear={crear}
                            dato={crear === true ? 0 : lectura.tipo_licencia}
                            component={SelectField}
                            className="form-select"
                            disabled={disabled}
                            msj="Licencia"
                        />
                    </div>
                </div>
                <div className='col-3'>
                    <Field
                        name="numero_licencia"
                        placeholder="Numero de licencia"
                        className="form-control"
                        component={renderField}
                        disabled={disabled}
                        msj="Número de licencia"
                    />
                </div>
                <div className='col-3'>
                    <Field
                        name="correo"
                        placeholder="Correo"
                        className="form-control"
                        component={renderField}
                        disabled={disabled}
                        msj="Correo"
                    />
                </div>
            </div>
            <div className='row mb-2'>
                {datos.codigoPais === 'GTM' &&
                    <div className='col-3'>
                        <div className="form-floating">
                            <Field
                                name="etnia"
                                options={etnia}
                                crear={crear}
                                dato={crear === true ? 0 : lectura.etnia}
                                component={SelectField}
                                className="form-select"
                                disabled={disabled}
                                msj="Etnia"
                            />
                        </div>
                    </div>
                }
                {datos.codigoPais === 'GTM' &&
                    <div className='col-3'>
                        <div className="form-floating">
                            <Field
                                name="idioma"
                                options={idioma}
                                crear={crear}
                                dato={crear === true ? 0 : lectura.idioma}
                                component={SelectField}
                                className="form-select"
                                disabled={disabled}
                                msj="Idioma"
                            />
                        </div>
                    </div>
                }
                <div className='col-6'>
                    <Field
                        name="direccionCol"
                        placeholder="Direccion"
                        className="form-control"
                        component={renderField}
                        disabled={disabled}
                        msj="Dirección"
                    />
                </div>
            </div>
            <div className='row mb-2'>
                <div className='col-3'>
                    <div className="form-floating">
                        <Field
                            name="discapacidad"
                            options={discapacidad}
                            crear={crear}
                            dato={crear === true ? 0 : lectura.discapacidad}
                            component={SelectField}
                            className="form-select"
                            disabled={disabled}
                            msj="Discapacidad"
                        />
                    </div>
                </div>
                <div className='col-3'>
                    <div className="form-floating">
                        <Field
                            name="temporalidad"
                            options={temp_contrato}
                            crear={crear}
                            dato={crear === true ? 0 : lectura.temporalidad}
                            component={SelectField}
                            className="form-select"
                            disabled={disabled}
                            msj="Temporalidad de contrato"
                        />
                    </div>
                </div>
                <div className='col-3'>
                    <div className="form-floating">
                        <Field
                            name="tipo_contrato"
                            options={tipo_contrato}
                            crear={crear}
                            dato={crear === true ? 0 : lectura.tipo_contrato}
                            component={SelectField}
                            className="form-select"
                            disabled={disabled}
                            msj="Tipo de contrato"
                        />
                    </div>
                </div>
                <div className='col-3'>
                    <div className="form-floating">
                        <Field
                            name="nivel_educativo"
                            options={nivel_educativo}
                            crear={crear}
                            dato={crear === true ? 0 : lectura.nivel_educativo}
                            component={SelectField}
                            className="form-select"
                            disabled={disabled}
                            msj="Nivel educativo"
                        />
                    </div>
                </div>
            </div>
            <div className='row mb-2'>
                <div className='col-3'>
                    <div className="form-floating">
                        <Field
                            name="jornada_laboral"
                            options={jornada}
                            crear={crear}
                            dato={crear === true ? 0 : lectura.jornada_laboral}
                            component={SelectField}
                            className="form-select"
                            disabled={disabled}
                            msj="Jornada de trabajo"
                        />
                    </div>
                </div>
                <div className='col-4'>
                    <Field
                        name="titulo"
                        placeholder="Titulo obtenido"
                        className="form-control"
                        component={renderField}
                        disabled={disabled}
                        msj="Titulo obtenido"
                    />
                </div>
                <div className='col-5'>
                    <Field
                        name="institucion"
                        placeholder="Institución"
                        className="form-control"
                        component={renderField}
                        disabled={disabled}
                        msj="Institución"
                    />
                </div>
            </div>
            <div className='row mb-2'>
                {crear === true && datos.codigoPais == 'GTM' &&
                    <div className='col-6'>
                        <Field
                            name="ocupacion_colaborador"
                            placeholder="Ocupación..."
                            className="form-control-find"
                            component={AsyncSelectField}
                            loadOptions={listarOcupacion}
                            msj="Ocupación"
                        />
                    </div>
                }
                {crear === true &&
                    <div className='col-3 mt-3'>
                        <Field
                            name="jubilado"
                            checked={jubilado}
                            label="Jubilado"
                            component={renderFieldCheck}
                            onChange={onCheckboxClicked}
                        />
                    </div>
                }
                {crear === false && editar === false && datos.codigoPais == 'GTM' &&
                    <div className='col-6'>
                        <Field
                            name="ocupacionDesc"
                            placeholder="Ocupación"
                            className="form-control"
                            component={renderField}
                            disabled={disabled}
                            msj="Ocupación"
                        />
                    </div>

                }
                {editar === true &&
                    <div>
                        <div className='row mb-2'>
                            {datos.codigoPais === 'GTM' &&
                                <div className='col-6 mt-1'>
                                    <Field
                                        name="ocupacionDesc"
                                        placeholder="Ocupación"
                                        className="form-control"
                                        component={renderField}
                                        disabled={true}
                                        msj="Ocupación"
                                    />
                                </div>
                            }
                            <div className='col-3 mt-3'>
                                <Field
                                    name="jubilado"
                                    checked={jubilado}
                                    label="Jubilado"
                                    component={renderFieldCheck}
                                    onChange={onCheckboxClicked}
                                />
                            </div>
                        </div>
                        {datos.codigoPais === 'GTM' &&
                            <div className='row mb-2'>
                                <div className='col-6 mt-1'>
                                    <Field
                                        name="ocupacion_colaborador"
                                        placeholder="Ocupación..."
                                        className="form-control-find"
                                        component={AsyncSelectField}
                                        loadOptions={listarOcupacion}
                                        msj="Ocupación"
                                    />
                                </div>
                            </div>
                        }
                    </div>

                }
                <div className='col-3 mt-3'>
                    {crear === false && editar == false &&
                        <Field
                            disabled={true}
                            name="jubilado"
                            label="Jubilado"
                            component={renderFieldCheck}
                        />
                    }
                </div>
            </div>
        </div>
    );
}

ColaboradorForm.propTypes = {
    handleSubmit: PropTypes.func,
    crear: PropTypes.bool,
    lectura: PropTypes.object,
    modulo: PropTypes.object,
    obtenerPaises: PropTypes.object,
    listaDepto: PropTypes.object,
    listaMuni: PropTypes.object,
    listarDepto: PropTypes.object,
    listarOcupacion: PropTypes.object
};

export default reduxForm({
    form: 'FormColaborador',
})(ColaboradorForm);
