import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/visitador_medico/departamento';
import DepartamentoList from './DepartamentoList';


const ms2p = (state) => {
  return {
    ...state.vm_departamento,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(DepartamentoList);
