import React, { Component } from 'react';
import Formulario from './OtrosGastosDetalleForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";

class OtrosGastosDetalle extends Component{
    
    componentDidMount = () => {
        const { match, leer, getModulo } = this.props;
        const id = match.params.id;
        if (id){
            leer(id);
        }
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        getModulo(modulo[5],modulo[4])
    }
    
    render(){
        const { loader, modulo, detalleDoc } = this.props;
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    modulo = {modulo}
                    detalleDoc = {detalleDoc}
                />
            </LoadMask>
        );
    }
}


export default OtrosGastosDetalle;