import React, { Component } from 'react';
import Grid from '../../../Utils/GridInventarioA';

class DetalleInventarioVerList extends Component{
    
    render(){
        const { loader, detalle } = this.props;
        return(
            <React.Fragment>
                {detalle &&
                <Grid 
                    hover 
                    striped 
                    data={detalle} 
                    loading={loader}
                    footer
                    headerStyle={ { background: 'black' } } 
                >
                    <TableHeaderColumn
                        isKey
                        dataField="numeroBodega"
                        dataAlign='right'
                        width="100"
                        headerAlign="center"
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Bodega
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="productoN"
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                        >
                        Producto
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="cantidad"
                        dataAlign='right'
                        width="200"
                        headerAlign="center"
                        dataSort
                        dataFormat={(cell, row)=>{
                            return new Intl.NumberFormat("en-US",{minimumFractionDigits: 6}).format(cell);
                        }}
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Cantidad
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="costo_unitario"
                        dataAlign='right'
                        width="200"
                        headerAlign="center"
                        dataFormat={(cell, row)=>{
                            return new Intl.NumberFormat("en-US",{minimumFractionDigits: 6}).format(cell);
                        }}
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Precio Unitario
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="total"
                        dataAlign='right'
                        width="200"
                        headerAlign="center"
                        dataFormat={(cell, row)=>{
                            return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(cell);
                        }}
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Total
                    </TableHeaderColumn>
                </Grid>
                }
            </React.Fragment>
        );
    }
}

export default DetalleInventarioVerList;
