import React, { Component } from 'react';
import Formulario from './InformesForm';

class Informes extends Component{
    componentDidMount = () => {
        const { getModulo, parametrosEmpresa } = this.props;
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        getModulo(modulo[5],modulo[4]);
        parametrosEmpresa();
    }
    render(){
        const { loader, modulo, reporteKardexValorizado, listarProductos, 
            listarBodegas,reporteExistencia, reporteInventarioFisico, 
            reporteSalidasDeBodegas, excelSalidasDeBodegas,reporteEntradasABodegas,
            datos, reporteExistenciaReservaOS, reporteMovimiento, excelExistencia,
            excelEntradasBodegas, reporteVentasDetalle, excelVentasDetalle, listarClientes,
            reporteKardex, excelKardex, excelKardexValorizado} = this.props;
        return(
            <div>
                <Formulario
                    loader = {loader}
                    modulo = {modulo}
                    listarProductos = {listarProductos}
                    reporteKardexValorizado = {reporteKardexValorizado}
                    listarBodegas = {listarBodegas}
                    reporteMovimiento = {reporteMovimiento}
                    reporteExistencia = {reporteExistencia}
                    reporteInventarioFisico = {reporteInventarioFisico}
                    reporteSalidasDeBodegas = {reporteSalidasDeBodegas}
                    excelSalidasDeBodegas = {excelSalidasDeBodegas}
                    reporteEntradasABodegas={reporteEntradasABodegas}
                    datos = {datos}
                    reporteExistenciaReservaOS = {reporteExistenciaReservaOS}
                    excelExistencia = {excelExistencia}
                    excelEntradasBodegas = {excelEntradasBodegas}
                    listarClientes = {listarClientes}
                    reporteVentasDetalle = {reporteVentasDetalle}
                    excelVentasDetalle = {excelVentasDetalle}
                    reporteKardex = {reporteKardex}
                    excelKardex = {excelKardex}
                    excelKardexValorizado = {excelKardexValorizado}
                />
            </div>
        );
    }
}

export default Informes;
