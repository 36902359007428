import PropTypes from 'prop-types';
import React from 'react';
import { reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import RegistroForm from './RegistroHomeForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";

function RegistroHome(props) {
    const { onSubmitR, loader } = props;
    return (
        <div className="blue-gradient-bg">
            <div className="d-flex flex-column align-items-center pt-3 bienvenida title">
                <img className="mt-5 imageReg"
                    src={require('assets/img/SIFW-LOGO-Red.png')}
                    alt="Logo" />
            </div>
            <br />
            <div className="login-wrapper">
                <div className="card2 card border-0 px-4 py-5 bg-dark col-lg-6 col-md-4 col-11">
                    <h4 className="text-center pv mb-4 title">Registro de Cuenta</h4>
                    <LoadMask loading={loader} dark>
                        <RegistroForm onSubmit={onSubmitR} />
                    </LoadMask>
                    <span>¿Ya tienes cuenta?&nbsp;<Link to="/login" style={{ textDecoration: 'none', color: '#fff' }}>Ingresa aquí</Link></span>
                </div>
            </div>
        </div>
    );
}
RegistroHome.propTypes = {
    onSubmit: PropTypes.func,
    loader: PropTypes.bool,
};
export default reduxForm({
    form: 'FormRHome', //identificador unico
})(RegistroHome)
