import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import { validate, validators } from 'validate-redux-form';
import PropTypes from 'prop-types';
import NavbarModulo from '../../components/NavbarModulo';
import {
    renderField,
    SelectField,
    renderFieldCheck,
    renderFieldCheckB
} from "../../Utils/renderField/renderField";

function FaseForm(props) {
    const { handleSubmit, crear, modulo, listaCentros, lectura } = props;
    const editar = window.location.href.includes('editar');
    let titulo = editar ? 'Modificar Fase' : 'Crear Fase';
    let disabled = false;
    if (crear === false && editar === false) {
        disabled = true;
        titulo = 'Ver Fase'
    }
    let autoFocus = true;
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const [activo, setActivo] = useState(true);
    const onCheckboxClicked = (idx, isChecked) => {
        setActivo(isChecked);
    }
    return (
        <div>
            <NavbarModulo
                modulo={modulo}
                titulo={titulo}
            />
            <br />
            <div className="mb-4 card card-small text-white bg-dark mb-3">
                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                    <div className="d-flex flex-column flex-1 mx-4">
                        <div className='mb-2'>
                            <div className='row mb-2'>
                                <div className='col-4'>
                                    {listaCentros &&
                                        <div className="form-floating">
                                            <Field
                                                autoFocus={autoFocus}
                                                name="centro_trabajo"
                                                options={listaCentros}
                                                crear={crear}
                                                dato={crear === true ? 0 : lectura.centro_trabajo}
                                                component={SelectField}
                                                className="form-select"
                                                disabled={disabled}
                                                msj="Centro de trabajo"
                                            />
                                        </div>
                                    }
                                </div>
                                <div className='col-4 mt-3'>
                                    {crear === true ? (
                                        <Field
                                            name="activo"
                                            label="Activo"
                                            checked={activo}
                                            onChange={onCheckboxClicked}
                                            component={renderFieldCheckB}
                                        />
                                        ) :
                                        <Field
                                            disabled={disabled}
                                            name="activo"
                                            label="Activo"
                                            component={renderFieldCheck}
                                        />
                                    }
                                </div>
                            </div>
                            <div className='mb-2 w-50'>
                                <Field
                                    name="descripcion"
                                    placeholder="Descripción"
                                    className="form-control"
                                    component={renderField}
                                    disabled={disabled}
                                    msj="Descripción"
                                />
                            </div>
                            <div className='mb-2 w-50'>
                                <Field
                                    name="area"
                                    placeholder="Area"
                                    className="form-control"
                                    component={renderField}
                                    disabled={disabled}
                                    msj="Area"
                                />
                            </div>
                            <div className='d-flex flex-row mt-3'>
                                {disabled == false &&
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={handleSubmit}
                                    >
                                        {editar ? 'Modificar' : 'Guardar'}
                                    </button>
                                }
                                <a
                                    href={`/#/produccion/${id_emp[5]}/fase`}
                                    className='btn btn-secondary mb-3'
                                >
                                    Regresar
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

FaseForm.propTypes = {
    handleSubmit: PropTypes.func,
    crear: PropTypes.bool,
    lectura: PropTypes.object,
    modulo: PropTypes.object,
    listaCentros: PropTypes.object,
    lectura: PropTypes.object,
};

export default reduxForm({
    form: 'FormFasePrd',
    validate: (data) => {
        return validate(data, {
            centro_trabajo: validators.exists()('Este campo es requerido'),
            descripcion: validators.exists()('Este campo es requerido'),
        });
    },
})(FaseForm);
