import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import NavbarModulo from '../../components/NavbarModulo';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import moment from 'moment';

import {
    AsyncSelectFieldIn,
    renderField,
    renderDayPickerB,
    SelectField
} from "../../Utils/renderField/renderField";

function FaltaForm(props) {
    const { modulo, emp, registroFalta, tipo, lectura,
        datosColaborador, cargo, crear, modificarFalta } = props;

    const editar = window.location.href.includes('editar');
    let titulo = editar ? 'Modificar falta' : 'Crear falta';
    let disabled = false;
    if (crear === false && editar === false) {
        disabled = true;
        titulo = 'Ver falta'
    }
    
    let ruta = window.location.href;
    let id_emp = ruta.split('/');

    const handleOnChangeEmp = value => {
        datosColaborador(value.value);
    };

    const [fecha_inicio, setFechaInicio] = React.useState('');
    const [fecha_final, setFechaFinal] = React.useState('');
    const [dias, setDias] = useState(0);
    const [fechaEdInicio, setFechaEdInicio] = useState(false);
    const [fechaEdFinal, setFechaEdFinal] = useState(false);
    const [diasEd, setDiasEd] = useState(false);

    const calcularDias = (value) => {
        if (editar === true){
            const fechaInicial = moment(fecha_inicio);
            const fechaFinal = moment(fecha_final);
            if (fechaFinal.isValid()) {
                const diasC = fechaFinal.diff(fechaInicial, 'days');
                if (diasC >= 0) {
                    setDiasEd(true);
                    setDias(diasC+1);
                }
            }
    
        }else{
            const fechaInicial = moment(fecha_inicio);
            const fechaFinal = moment(value);
            if (fechaFinal.isValid()) {
                const dias = fechaFinal.diff(fechaInicial, 'days');
                if (dias >= 0) {
                    setDias(dias+1);
                }
            }
        }
    }

    const motivoFalta = [
        { label: "Falta injustificada", value: 1 },
        { label: "Falta justificada", value: 2 },
        { label: "Permiso sin goce de salario", value: 3 },
        { label: "Permiso con goce de salario", value: 4 },
    ];

    return (
        <div>
            <NavbarModulo
                modulo={modulo}
                titulo={titulo}
            />
            
            <br />
            <div className="mb-4 card card-small text-white bg-dark mb-3">
                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                    <div className="d-flex flex-column flex-1 mx-4">
                        <div className='mb-2'>
                            {(crear === false && editar === false) &&
                                <div className='row mb-2'>
                                    <div className='col-6'>
                                        <Field
                                            name="nombre"
                                            placeholder="Colaborador"
                                            className="form-control-find"
                                            disabled={true}
                                            component={renderField}
                                            msj="Colaborador"
                                        />
                                    </div>
                                    <div className='col-6'>
                                        <Field
                                            name="puestoNombre"
                                            placeholder="Puesto"
                                            className="form-control-find"
                                            disabled={true}
                                            component={renderField}
                                            msj="Puesto"
                                        />
                                    </div>
                                </div>
                            }
                            {crear === true &&
                                <div className='row mb-2'>
                                    <div className='col-6'>
                                        <Field
                                            name="colaborador"
                                            placeholder="Colaborador..."
                                            className="form-control-find"
                                            component={AsyncSelectFieldIn}
                                            loadOptions={emp}
                                            onChange={handleOnChangeEmp}
                                            msj="Colaborador"
                                        />
                                    </div>
                                    <div className='col-6'>
                                        <div className="form-floating">
                                            <input
                                                className = {classNames('form-control')}
                                                type="text"
                                                value = {cargo}
                                                disabled = {true}
                                            />
                                            <label className='form-label'>Puesto</label>
                                        </div>
                                    </div>
                                </div>
                            }
                            {editar === true &&
                                <div className='row mb-2'>
                                    <div className='col-6'>
                                        <Field
                                            name="nombre"
                                            placeholder="Colaborador"
                                            className="form-control-find"
                                            disabled={true}
                                            component={renderField}
                                            msj="Colaborador"
                                        />
                                    </div>
                                    <div className='col-6'>
                                        <Field
                                            name="puestoNombre"
                                            placeholder="Puesto"
                                            className="form-control-find"
                                            disabled={true}
                                            component={renderField}
                                            msj="Puesto"
                                        />
                                    </div>
                                </div>
                            }
                            {crear === false && editar === false &&
                                <div className='row mb-3'>
                                    <div className='col-2'>
                                        <Field
                                            name="fecha_inicio"
                                            placeholder="Fecha inicio"
                                            className="form-control-find"
                                            disabled={true}
                                            component={renderDayPickerB}
                                            msj="Fecha inicio"
                                        />
                                    </div>
                                    <div className='col-2'>
                                        <Field
                                            name="fecha_final"
                                            placeholder="Fecha final"
                                            className="form-control-find"
                                            disabled={true}
                                            component={renderDayPickerB}
                                            msj="Fecha final"
                                        />
                                    </div>
                                    <div className='col-2'>
                                        <Field
                                            name="dias"
                                            placeholder="Fecha final"
                                            className="form-control-find"
                                            disabled={true}
                                            component={renderField}
                                            msj="Cantidad de dias"
                                        />
                                    </div>
                                </div>
                            }
                            {editar === true &&
                                <div className='row mb-3'>
                                    <div className='col-2'>
                                        <Field
                                            name="fecha_inicio"
                                            placeholder="Fecha inicio"
                                            className="form-control-find"
                                            component={renderDayPickerB}
                                            onChange={(e) => {
                                                const fecha = moment(e.target.value);
                                                if (fecha.isValid()) { 
                                                    setFechaInicio(e.target.value);
                                                    setFechaEdInicio(true);
                                                    if (fechaEdFinal === false){
                                                        setFechaFinal(lectura.fecha_final);
                                                        calcularDias();
                                                    }else{
                                                        calcularDias();
                                                    }
                                                }
                                            }}
                                            msj="Fecha inicio"
                                        />
                                    </div>
                                    <div className='col-2'>
                                        <Field
                                            name="fecha_final"
                                            placeholder="Fecha final"
                                            className="form-control-find"
                                            component={renderDayPickerB}
                                            onChange={(e) => {
                                                const fechaF = moment(e.target.value);
                                                if (fechaF.isValid()){ 
                                                    setFechaFinal(e.target.value);
                                                    setFechaEdFinal(true);
                                                    if (fechaEdInicio === false){
                                                        setFechaInicio(lectura.fecha_inicio);    
                                                        calcularDias();
                                                    }else{
                                                        calcularDias();
                                                    }
                                                }
                                            }}
                                            msj="Fecha final"
                                        />
                                    </div>
                                    <div className='col-2'>
                                        <div className="form-floating">
                                            <NumberFormat
                                                className={classNames('form-control')}
                                                decimalScale={0}
                                                disabled={true}
                                                placeholder="Cantidad de dias"
                                                fixedDecimalScale
                                                thousandSeparator
                                                value={diasEd === false ? lectura.dias : dias}
                                                name="total"
                                            />
                                            <label className='form-label' htmlFor="total">
                                                Cantidad de dias
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            }
                            {crear === true &&
                                <div className='row mb-3'>
                                    <div className='col-2'>
                                        <Field
                                            name="fecha_inicio"
                                            placeholder="Fecha inicial"
                                            className="form-control-find"
                                            component={renderDayPickerB}
                                            onChange={(e) => {
                                                setFechaInicio(e.target.value);
                                                
                                            }}
                                            msj="Fecha inicio"
                                        />
                                    </div>
                                    <div className='col-2'>
                                        <Field
                                            name="fecha_final"
                                            placeholder="Fecha inicial"
                                            className="form-control-find"
                                            component={renderDayPickerB}
                                            onChange={(e) => {
                                                calcularDias(e.target.value);
                                                
                                            }}
                                            msj="Fecha final"
                                        />
                                    </div>
                                    <div className='col-2'>
                                        <div className="form-floating">
                                            <NumberFormat
                                                className={classNames('form-control')}
                                                decimalScale={0}
                                                disabled={true}
                                                placeholder="Cantidad de dias"
                                                fixedDecimalScale
                                                thousandSeparator
                                                value={dias}
                                                name="total"
                                            />
                                            <label className='form-label' htmlFor="total">
                                                Cantidad de dias
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className='row mb-3'>
                                <div className='col-3'>
                                    <div className="form-floating">
                                        <Field
                                            name="motivo"
                                            disabled={disabled}
                                            options={motivoFalta}
                                            crear={crear}
                                            dato={crear === true ? 0 : tipo}
                                            component={SelectField}
                                            className="form-select"
                                            msj="Motivo"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-3'>
                                <div className='col-12'>
                                    <Field
                                        name="observaciones"
                                        placeholder="Observaciones"
                                        component={renderField}
                                        className="form-control"
                                        disabled={disabled}
                                        msj="Observaciones"
                                    />
                                </div>
                            </div>
                            <div className='d-flex flex-row mt-3 ml-2'>
                                {crear === true &&
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={() => registroFalta(dias)}
                                    >
                                        Guardar
                                    </button>
                                }
                                {editar === true &&
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={() => modificarFalta(diasEd === false ? lectura.dias : dias)}
                                    >
                                        Modificar
                                    </button>
                                }
                                <a
                                    href={`/#/rr_hh/${id_emp[5]}/faltas/${id_emp[7]}`}
                                    className='btn btn-secondary mb-3'
                                >
                                    Regresar
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

FaltaForm.propTypes = {
    handleSubmit: PropTypes.func,
    crear: PropTypes.bool,
    lectura: PropTypes.object,
    modulo: PropTypes.object
};

export default reduxForm({
    form: 'FormFalta',
})(FaltaForm);
