import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from '../../components/Modal';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';
import {
    AsyncSelectField,
} from "../../Utils/renderField/renderField";


function FormSeleccionarEmpresa(props) {
    const {
        listarEmpresas
    } = props;
    
    const [id, setId] = useState("");
    
    
    const handleOnChangeEmp = value => {
        setId(value.value);
    };
   
    const [estadoModal, cambiarEstadoModal] = useState(true);
    
    return (
        <div className='container'>
            <Modal
                estado={estadoModal}
                cambiarEstado={cambiarEstadoModal}
                titulo="Seleccionar empresa"
                botonCerrar="false"
            >
                <Field
                    name="empresa"
                        placeholder="Empresa..."
                        className="form-control-find"
                        component={AsyncSelectField}
                        loadOptions={listarEmpresas}
                        onChange={handleOnChangeEmp}
                        msj="Empresa"
                    />
                <br />
                <Contenido>
                    {id &&
                        <a
                            href={`/#/tools/home/consulta_documento/${id}`}
                            className='btn mr-2 mb-3 btn-primary'
                        >
                            Seleccionar
                        </a>
                    }
                </Contenido>
            </Modal>
        </div>
    );

}
FormSeleccionarEmpresa.propTypes = {
    periodoL: PropTypes.string.isRequired,
    listaEs: PropTypes.string.isRequired
};

export default reduxForm({
    form: 'FormSelEmpresas'
})(FormSeleccionarEmpresa);


const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;
