import React, { Component } from 'react';
import Grid from '../../Utils/GridFiltro';
import { standardActions } from '../../Utils/GridFiltro/StandardActions';

class VisitadorList extends Component{
    
    render(){
        const { loader, eliminar,  detalle } = this.props;
        return(
            <React.Fragment>
                {detalle &&
                    <Grid 
                        hover 
                        striped 
                        data={detalle} 
                        loading={loader}
                        headerStyle={ { background: 'black' } } 
                        //onSortChange={onSortChange} 
                    >
                    <TableHeaderColumn
                        isKey
                        dataField="codigo"
                        width="100"
                        dataSort
                        thStyle={ 
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Codigo
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="nombre"
                        width="400"
                        dataSort
                        thStyle={ 
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Nombre
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="puesto"
                        width="250"
                        dataSort
                        thStyle={ 
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Puesto
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        thStyle={
                        {color: 'white'}}
                        dataField="id"
                        width="80"
                        dataAlign="center"
                        dataSort
                        dataFormat={standardActions({
                            eliminar: eliminar })} 
                    >
                        Acciones
                    </TableHeaderColumn>
                </Grid>
                }
            </React.Fragment>
        );
    }
}

export default VisitadorList;
