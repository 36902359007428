import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';
import moment from 'moment';
const valEmp = __CONFIG__.empresa;
import { split } from 'lodash';

const COLABORADOR = 'COLABORADOR';
const LISTADO = 'LISTADO';
const TIPO = 'TIPO';
const PUESTO = 'PUESTO';
const LOADER = 'LOADER';
const MODULO = 'MODULO';
const YEAR = 'YEAR';
const FECHA_INICIAL = 'FECHA_INICIAL';
const FECHA_FIN = 'FECHA_FIN';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listarColaborador = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params;
    let id = ''
    if (valEmp === 'fage'){
        id = id_emp[6];
        params = { id };
    }else{
        id = id_emp[5];
        params = { id };
    }
    api.get('/colaborador/empresa_colaborador_pais', params).then(data => {
        const lista = [];
        if (data) {
            data.forEach(item => {
                lista.push({
                    value: item.id,
                    label: item.codigo+' - ' + item.nombre
                })
            })
        }
        dispatch({type: COLABORADOR, emp: lista })
    }).catch((error) => {
        return [];
    })
}

export const datosColaborador = (emp) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let yearD = '';
    if (valEmp === 'fage'){
        yearD = id_emp[8];
    }else{
        yearD = id_emp[7];
    }
    api.get(`/colaborador/${emp}`).then((response) => {
        let nAnio = parseInt(yearD) + 1 ;
        let firstdate = moment(nAnio.toString()).startOf('year').format('DD/MM/YYYY');
        let lastdate = moment(nAnio.toString()).endOf('year').format('DD/MM/YYYY');
        dispatch({type: FECHA_INICIAL, fechaI: firstdate }) 
        dispatch({type: FECHA_FIN, fechaF: lastdate })
        dispatch({type: YEAR, year: yearD })
        dispatch({type: PUESTO, cargo: response.cargo })
    }).catch((error) => {
        return [];
    })
}

export const registroIsr = (anio, fechaI, fechaF) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormIsr.values;
    let id_empresa = '';
    if (valEmp === 'fage'){
        id_empresa = id_emp[6]
    }else{
        id_empresa = id_emp[5]
    }
    if (data === undefined) {
        Swal.fire({
            title: 'ISR Colaborador',
            text: 'Debe de seleccionar colaborador',
            type: 'error',
        })
    } else {
        if (data.descuento === undefined || data.descuento === '0.00') {
            Swal.fire({
                title: 'ISR Colaborador',
                text: 'Debe de ingresar descuento',
                type: 'error',
            })
        } else {
            if (data.tipo === undefined || data.tipo === '') {
                Swal.fire({
                    title: 'ISR Colaborador',
                    text: 'Debe de seleccionar frecuencia de pago',
                    type: 'error',
                })
            } else {
                let f = split(fechaF, '/')
                let fechaFinal =  f[2]+'-'+f[1]+'-'+f[0]
                const formData = {
                    anio: anio,
                    colaborador: data.colaborador.value,
                    fecha_inicio: moment(fechaI).format('YYYY-MM-DD'),
                    fecha_fin: fechaFinal,
                    forma_descuento: data.tipo ? data.tipo : data.forma_descuento,
                    descuento: data.descuento,
                    empresa: id_empresa
                }
                dispatch(setLoader(true));
                api.post('/rrhh_isr_colaborador/', formData).then((response) => {
                    NotificationManager.success(
                        'ISR colaborador creado correctamente',
                        'Exito',
                        3000
                    );
                    response.descuento = 0.00
                    response.tipo = response.forma_descuento
                    dispatch({type: PUESTO, cargo: '' })
                    dispatch(initializeForm('FormIsr', response));
                }).catch((error) => {
                NotificationManager.error(
                    error.msj,
                    'Error',
                    0
                );
                }).finally(() => {
                    dispatch(setLoader(false));
                });
            }
        }
    }
}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = '';
    let year = '';
    let params;
    if (valEmp === 'fage'){
        id = id_emp[6];
        year = id_emp[8];
        params = { id, year };
    }else{
        id = id_emp[5];
        year = id_emp[7];
        params = { id, year };
    }
    return api.get('/rrhh_isr_colaborador/', params).then((response)=>{
        dispatch({type: LISTADO, data: response })
    }).catch(error=>{
        NotificationManager.error(
            'Ocurrió un error al listar descuento ISR',
            'Error',
            0
        );
    })
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/rrhh_isr_colaborador/${id}`).then((response) => {
        dispatch({type: TIPO, tipo: response.forma_descuento })
        dispatch(initializeForm('FormIsr', response ));
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar puesto',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const modificarIsr = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const datos = getStore().form.FormIsr.values;
    const id = datos.id;
    const formData = {
        id: id,
        descuento: datos.descuento,
        forma_descuento: datos.tipo ? datos.tipo : datos.forma_descuento,
        activo: datos.activo
    }
    dispatch(setLoader(true));
    api.put(`/rrhh_isr_colaborador/${id}/`, formData).then(() => {
        NotificationManager.success(
            'ISR colaborador modificado correctamente',
            'Exito',
            3000
        );
        let ruta = '';
        if (valEmp === 'fage'){
            ruta = `/lfg/rr_hh/${id_emp[6]}/isr/${id_emp[8]}`
            dispatch(push(ruta));
        }else{
            ruta = `/rr_hh/${id_emp[5]}/isr/${id_emp[7]}`
            dispatch(push(ruta));
        }
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al modificar ISR colaborador',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const limpiarDatos = () => (dispatch) => {
    dispatch({ type: PUESTO, cargo: [] });
    dispatch({ type: YEAR, year: '0000' })
    dispatch({ type: FECHA_INICIAL, fechaI: '00/00/0000' });
    dispatch({ type: FECHA_FIN, fechaF: '00/00/0000' });
};

const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`/rrhh_isr_colaborador/${id}`).then(() => {
        NotificationManager.success(
            'ISR colaborador eliminado correctamente',
            'Exito',
            3000
        );
        dispatch(listar());
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error al eliminar ISR colaborador',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}


export const actions = {
    listarColaborador,
    getModulo,
    registroIsr,
    modificarIsr,
    datosColaborador,
    listar,
    leer,
    limpiarDatos,
    eliminar
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [COLABORADOR]: (state, { emp }) => {
        return {
            ...state,
            emp,
        };
    },
    [PUESTO]: (state, { cargo }) => {
        return {
            ...state,
            cargo,
        };
    },
    [LISTADO]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [TIPO]: (state, { tipo }) => {
        return {
            ...state,
            tipo,
        };
    },
    [YEAR]: (state, { year }) => {
        return {
            ...state,
            year,
        };
    },
    [FECHA_INICIAL]: (state, { fechaI }) => {
        return {
            ...state,
            fechaI,
        };
    },
    [FECHA_FIN]: (state, { fechaF }) => {
        return {
            ...state,
            fechaF,
        };
    },
};

export const initialState = {
    loader: false,
    modulo: [],
    data: [],
    emp: [],
    tipo: [],
    year: '0000',
    fechaI: '00/00/0000',
    fechaF: '00/00/0000',
};

export default handleActions(reducers, initialState)
 