import React, { useState } from 'react';
import Modal from '../components/Modal';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LoadMask from "../Utils/LoadMask/LoadMask";
import NavbarModulo from '../components/NavbarModulo';

function InformesForm(props) {
    const {
        modulo,
        loader,
        reporteMarcas,
    } = props;
    
    const [modalListadoMarca, setModalListadoMarca] = useState(false);
    
    let titulo = 'Informes'
    let ruta = window.location.href;
    let id_emp = ruta.split('/');

    return (
        <div>
            <NavbarModulo
                modulo={modulo}
                titulo={titulo}
            />
        
            <div className="container">
                <br/>
                <h4 className='mt-1' style={{ color: "white" }}>Informes</h4>
                <div className="row">
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <a class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => setModalListadoMarca(!modalListadoMarca)}
                                data-bs-toggle="list"
                                role="tab"
                                href={`/#/registro_marca/${id_emp[5]}/informes`}
                                aria-controls="list-home">Listado de marcas</a>
                        </div>
                    </div>
                </div>
                <Modal
                    estado={modalListadoMarca} //Listado de marcas
                    cambiarEstado={setModalListadoMarca}
                    titulo="Listado de marcas"
                    botonCerrar="true"
                    >
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div className='row'>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={reporteMarcas}
                                    >
                                    Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn btn-secondary'
                                        onClick={() => setModalListadoMarca(false)}
                                    >
                                    Cancelar
                                    </button>
                                </div>
                            </Contenido>
                        </LoadMask>
                </Modal>  
            </div>
        </div>
    );
}

InformesForm.propTypes = {
    loader: PropTypes.bool,
    modulo: PropTypes.object,
};

export default reduxForm({
    form: 'FormInformesRegistroMarca',
})(InformesForm);


const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;
