import React, { Component, useState } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import { validate, validators } from 'validate-redux-form';
import {
  renderField,
  SelectField,
  renderFilePicker
} from "../Utils/renderField/renderField";
const valEmp = __CONFIG__.empresa;

class EmpresaForm extends Component {

  render() {
    const { obtenerPaises, handleSubmit, crear, lectura, setLogo } = this.props;
    
    const editar = window.location.href.includes('editar');
    let titulo = editar ? 'Modificar Empresa' : 'Crear Empresa';
    let disabled = false;
    if (crear === false && editar === false) {
      disabled = true;
      titulo = 'Ver Empresa'
    }
    
    let autoFocus = true;
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    return (
      <div>
        <form onSubmit={handleSubmit}>
          <br />
          <h3 className="p-2 bg-secondary title">{titulo}</h3>
          <div className="mb-4 card card-small text-white bg-dark mb-3">
            <div className="p-0 pt-3 d-flex flex-column flex-md-row">
              <div className="d-flex flex-column flex-1 mx-4">
                <div className='mb-2 w-25'>
                  <Field
                    autoFocus={autoFocus}
                    id="identificador"
                    name="identificador"
                    placeholder="Identificador"
                    component={renderField}
                    className="form-control"
                    disabled={disabled}
                    msj="Identificador"
                  />
                </div>
                <div className='row mb-2'>
                  <div className='col-6'>
                    <Field
                      id="razonSocial"
                      name="razon_social"
                      placeholder="Razon Social"
                      className="form-control"
                      component={renderField}
                      disabled={disabled}
                      msj="Razón Social"
                    />
                  </div>

                  <div className='col-6'>
                    <Field
                      id="direccion"
                      name="direccion"
                      placeholder="Dirección"
                      className="form-control"
                      component={renderField}
                      disabled={disabled}
                      msj="Dirección"
                    />
                  </div>
                </div>
                <div className='row mb-2'>
                  <div className='col-4'>
                    <Field
                      id="telefono"
                      name="telefono"
                      placeholder="Telefono"
                      className="form-control"
                      component={renderField}
                      disabled={disabled}
                      msj="Telefono"
                    />
                  </div>
                  <div className='col-4'>
                    {obtenerPaises &&
                      <div className="form-floating">
                        <Field
                          name="pais"
                          options={obtenerPaises}
                          crear={crear}
                          dato={crear === true ? 0 : lectura.pais}
                          component={SelectField}
                          className="form-select"
                          disabled={disabled}
                          msj="Pais"
                        />
                      </div>

                    }
                  </div>
                </div>
                <div className="col-4">
                  <label htmlFor="logo">Logo</label>
                  <Field
                    photo={crear === true ? null : lectura.logo}
                    setFile={setLogo}
                    name="logo"
                    component={renderFilePicker}
                  />
                </div>
                <div className='d-flex flex-row mt-3'>
                  {disabled == false &&
                    <button
                      className={`btn mr-2 mb-3 ${editar ? 'btn-primary' : 'btn-primary'}`}
                      type='submit'
                    >
                      {editar ? 'Modificar' : 'Guardar'}
                    </button>
                  }
                  {valEmp === 'fage' &&
                    <a
                      href={`/#/lfg/ajustes/${id_emp[6]}/empresas`}
                      className='btn btn-secondary mb-3'
                    >
                      Regresar
                    </a>
                  }
                  {valEmp === '' &&
                    <a
                      href={`/#/ajustes/${id_emp[5]}/empresas`}
                      className='btn btn-secondary mb-3'
                    >
                      Regresar
                    </a>
                  }
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default reduxForm({
  form: 'FormEmpresa',
  validate: (data) => {
    return validate(data, {
      identificador: validators.exists()('Este campo es requerido'),
      razonSocial: validators.exists()('Este campo es requerido'),
      moneda: validators.exists()('Seleccione una moneda'),
      pais: validators.exists()('Seleccione un pais'),
    });
  },
})(EmpresaForm);
