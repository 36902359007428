import React, { Component, useReducer } from 'react';
import Grid from '../../components/Utils/Grid';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';
import { standardActions } from '../../components/Utils/Grid/StandardActions';
import {
    SelectField,
    AsyncSelectField,
  } from "../../components/Utils/renderField/renderField";

class ConfigCuentasList extends Component{
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false
    };

    componentDidMount = () => {
        const { listarCuentasConfig, listarCuentasContables, page } = this.props;
        listarCuentasConfig(page);
        listarCuentasContables();
    }
    
    
    render(){
        const { 
            config, 
            loader, 
            eliminarCuentaConf, 
            listarCuentasConfig: onPageChange, 
            listarCuentasContables, 
            registroConfigCuenta
        } = this.props;
        
        const tipo = [
            { label: "IVA CREDITO", value: "IVA CREDITO" },
            { label: "IVA DEBITO", value: "IVA DEBITO" },
            { label: "INVENTARIO", value: "INVENTARIO" },
            { label: "ANTICIPO SOBRE COMPRAS", value: "ANTICIPO SOBRE COMPRAS" },
            { label: "ANTICIPO SOBRE VENTAS", value: "ANTICIPO SOBRE VENTAS" },
            { label: "DIFERENCIAL CAMBIARIO", value: "DIFERENCIAL CAMBIARIO" },
            { label: "PERDIDAS Y GANANCIAS", value: "PERDIDAS Y GANANCIAS" },
            { label: "VENTAS LOCALES", value: "VENTAS LOCALES" },
            { label: "VENTAS EXPORTACION", value: "VENTAS EXPORTACION" },
            { label: "CLIENTES", value: "CLIENTES" },
            { label: "CLIENTES EXTERIOR", value: "CLIENTES EXTERIOR" },
            { label: "PROVEEDORES", value: "PROVEEDORES" },
            { label: "PROVEEDORES EXTERIOR", value: "PROVEEDORES EXTERIOR" },
            { label: "GASTO NO DEDUCIBLE", value: "GASTO NO DEDUCIBLE" },
            { label: "ISR RETENCIONES (FESP)", value: "ISR RETENCIONES (FESP)" },
            { label: "IVA RETENCIONES (FESP)", value: "IVA RETENCIONES (FESP)" },
            
        ];
        let ruta = window.location.href;
        let id_emp = ruta.split('/');
        return(
            <React.Fragment>
                <br />
                <h3 className="p-2 bg-secondary title">Configuración de cuentas</h3>
                <div className="row mb-2">
                    <div className='col-4'>
                        <div className="form-floating">
                            <Field
                                name="tipo"
                                options={tipo}
                                dato={0}
                                component={SelectField}
                                className="form-select"
                                msj="Seleccionar tipo"
                            />
                        </div>
                    </div>
                    <div className='col-6'>
                        <Field
                            name="cuenta"
                            placeholder="Cuenta contable..."
                            className="form-control-find"
                            component={AsyncSelectField}
                            loadOptions={listarCuentasContables}
                            msj="Cuenta"
                        />
                    </div>
                </div>    
                <button
                    className='btn mr-2 mb-3 btn-primary'
                    onClick={registroConfigCuenta}
                >
                    Guardar
                </button>
                <a
                    href = {`/#/contabilidad/${id_emp[5]}`}
                    className='btn btn-secondary mb-3'
                >
                Regresar
                </a>
                {config &&
                    <Grid 
                        hover 
                        striped 
                        data={config} 
                        loading={loader} 
                        headerStyle={ { background: 'black' } } 
                        onPageChange={onPageChange}
                        //onSortChange={onSortChange} 
                    >
                        <TableHeaderColumn
                            isKey
                            dataField="descripcion_cuenta"
                            width="350"
                            dataSort
                            thStyle={ 
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Cuenta
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="nombre_cuenta"
                            dataSort
                            thStyle={ 
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Descripción
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            thStyle={
                            {color: 'white'}}
                            dataField="id"
                            dataAlign="center"
                            width="105"
                            dataSort
                            dataFormat={standardActions({ 
                                
                                eliminar: eliminarCuentaConf })} 
                            
                        >
                            Acciones
                        </TableHeaderColumn>
                    </Grid>
                }
            </React.Fragment>
        );
    }
}
export default reduxForm({
    form: 'FormConfigCuentas',
})(ConfigCuentasList );
