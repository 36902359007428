import * as yup from "yup";
import { today, getLocalTimeZone } from "@internationalized/date";
import { getSettings } from "../../utils";
import { validar } from "../../../components/ValidarNit";

export function getSchema() {
    const schema = yup.object({
        tipo_abono: yup.mixed().required("El tipo de abono es requerido"),
        fecha_registro: yup
            .mixed()
            .required("La fecha de registro es requerida"),
        serie: yup.string().required("La serie es requerida"),
        numero: yup.string().notRequired(),
        monto: yup
            .number()
            .typeError("El monto debe ser un número")
            .required("El monto es requerido")
            .min(1, "El monto debe ser mayor a 0"),
        referencia: yup.string().required("La referencia es requerida"),
        cliente: yup.mixed().required("El cliente es requerido"),
    });
    return schema;
}

export function getDefaultValues() {
    return {
        nombre: "",
        dpi: "",
        afiliacion: "",
    };
}
