import React, { Component } from 'react';
import Grid from '../../Utils/GridFiltro';
import PropTypes from 'prop-types';
import moment from 'moment';
import { standardActions } from '../../Utils/Grid/StandardActions';
import NavbarModulo from '../../components/NavbarModulo';

class AbonosList extends Component{
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: true
    };

    componentDidMount = () => {
        const { listar, leerPeriodoD, getModulo } = this.props;
        let ruta = window.location.href;
        let periodo = ruta.split('/');
        leerPeriodoD(periodo[5], periodo[7]);
        getModulo(periodo[5],periodo[4]);
        listar();
    }
    render(){
        const { data, loader, eliminarAbono, periodoD, modulo, } = this.props;
        let ruta = window.location.href;
        let datos = ruta.split('/');
        return(
            <React.Fragment>
                <NavbarModulo
                    periodoD = {periodoD}
                    modulo = {modulo}
                />
                <br />
                <h3 className="p-2 bg-secondary title">Abonos</h3>
                <br />
                {periodoD.estado == 'A' &&
                    <div className="d-flex flex-row justify-content-start mb-2">
                        <a
                            href={`/#/compras/${datos[5]}/abonos/${datos[7]}/crear`}
                            className='btn btn-primary'
                        >
                            Crear Abono
                        </a>
                    </div>
                }
                {data && periodoD.estado == 'A' &&
                    <Grid 
                        hover 
                        striped 
                        data={data} 
                        loading={loader} 
                        headerStyle={ { background: 'black' } } 
                        //onPageChange={onPageChange}
                        //onSortChange={onSortChange} 
                    >
                        <TableHeaderColumn
                            isKey
                            width="100"
                            dataField="fecha_registro"
                            dataSort
                            dataFormat={(cell, row)=>{
                                return moment(cell).format("DD/MM/YYYY");
                            }}
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Fecha
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="serie"
                            width="150"
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Serie
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            width="150"
                            dataField="numero"
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Numero
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            width="400"
                            dataField="proveedorN"
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Proveedor
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            headerAlign="center"
                            width="200"
                            dataAlign='right'
                            dataField="monto"
                            dataFormat={(cell, row)=>{
                                return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Math.abs(cell));
                            }}
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Total
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="descripcion"
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Descripcion
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            thStyle={
                            {color: 'white'}}
                            dataField="id"
                            width="200"
                            dataAlign="center"
                            dataSort
                            dataFormat={standardActions({ 
                                editar_a: "abonos",
                                ver_a: "abonos",
                                eliminar: eliminarAbono,
                                empresa: datos[5],
                                periodo: datos[7],
                                modulo: datos[4],
                            })} 
                        >
                            Acciones
                        </TableHeaderColumn>
                    </Grid>
                }
                {data && periodoD.estado == 'C' &&
                    <Grid 
                        hover 
                        striped 
                        data={data} 
                        loading={loader} 
                        headerStyle={ { background: 'black' } } 
                        //onPageChange={onPageChange}
                        //onSortChange={onSortChange} 
                    >
                        <TableHeaderColumn
                            isKey
                            width="100"
                            dataField="fecha_registro"
                            dataSort
                            dataFormat={(cell, row)=>{
                                return moment(cell).format("DD/MM/YYYY");
                            }}
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Fecha
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="serie"
                            width="150"
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Serie
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            width="150"
                            dataField="numero"
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Numero
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            width="400"
                            dataField="proveedorN"
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Proveedor
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            headerAlign="center"
                            width="200"
                            dataAlign='right'
                            dataField="monto"
                            dataFormat={(cell, row)=>{
                                return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Math.abs(cell));
                            }}
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Total
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="descripcion"
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Descripcion
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            thStyle={
                            {color: 'white'}}
                            dataField="id"
                            width="200"
                            dataAlign="center"
                            dataSort
                            dataFormat={standardActions({ 
                                ver_a: "abonos",
                                empresa: datos[5],
                                periodo: datos[7],
                                modulo: datos[4],
                            })} 
                        >
                            Acciones
                        </TableHeaderColumn>
                    </Grid>
                }
            </React.Fragment>
        );
    }
}

export default AbonosList;
