import React, { Component } from 'react';
import Formulario from './InvernaderosForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";

class Invernaderos extends Component{
    state = {
        crear: true,
    }

    componentDidMount = () => {
        const { match, leer, getModulo, leerDetalleFases } = this.props;
        const id = match.params.id;
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        
        if (id){
            this.setState({crear: false});
            leer(id);
        }
        getModulo(modulo[5],modulo[4]);
        leerDetalleFases('00000000-0000-0000-0000-000000000000');
        localStorage.removeItem('id_detalle_invernadero');
    }
    
    render(){
        const { registroInvernadero, modificarInvernadero, loader, lectura, modulo,
            listarFases, detalleFase, eliminarLineaFase } = this.props;
        const { crear } = this.state;
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    crear = {crear}
                    lectura = {lectura}
                    modulo = {modulo}
                    listarFases = {listarFases}
                    detalleFase = {detalleFase}
                    registroListado = {registroInvernadero}
                    modificarListado = {modificarInvernadero}
                    eliminarLineaFase = {eliminarLineaFase}
                />
            </LoadMask>
        );
    }
}

export default Invernaderos
