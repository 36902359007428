import React from "react";
import styled from "styled-components";
import {
    FicInput,
    FicComboBox,
    FicDatePicker,
    FicDateRangePicker,
} from "../../Utils/FormInputComponents";
import Banner from "../common/Banner";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { validar } from "../../components/ValidarNit";
import GenBtn from "../../components/GenBtn";
import InputsContainer from "../common/InputsContainer";
import {
    crearSeguro,
    editarSeguro,
    getAgentes,
    getClientes,
    getSeguro,
} from "./actions";
import { useEffect } from "react";
import { withRouter } from "react-router";

const GridContainer = styled.div`
    width: 100%;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, clamp(250px, 35%, 450px));
    /* justify-items: center; */
`;

const SectionTitle = styled.h2`
    margin: 0 auto;
    font-size: large;
    color: #bd0034;
`;

const OPCIONES_TIPO = [
    { value: "vehiculos", label: "VEHICULOS" },
    { value: "gastos_medicos", label: "GASTOS MEDICOS" },
    { value: "incendio", label: "INCENDIO" },
];
const OPCIONES_CLASIFICACION = [
    { value: "casas", label: "CASA" },
    { value: "edificios", label: "EDIFICIO" },
    { value: "apartamentos", label: "APARTAMENTOS" },
];

const schema = yup.object({
    agente: yup.mixed().required("Seleccione un agente"),
    cliente: yup.mixed().required("Seleccione un agente"),
    tipo: yup.mixed().required("Seleccione un agente"),
    // clasificacion: yup.mixed().when("tipo", {
    //     is: { value: "incendio", label: "INCENDIO" },
    //     then: yup.mixed().required("Seleccione una clasificacion"),
    // }),
    poliza: yup.string().required("El nombre es requerido"),
    aseguradora: yup.string().required("El nombre es requerido"),
    suma_asegurada: yup.string().required("La suma asegurada es requerida"),
    vigencia: yup.mixed().required("La vigencia es requerida"),

    marca: yup.mixed().when("tipo", {
        // is: { value: "vehiculos", label: "VEHICULOS" },
        is: (field) => {
            const tipo = field ? field.value : "";
            return tipo === "vehiculos";
        },
        then: () => yup.string().required("La marca es requerida"),
    }),
    linea: yup.mixed().when("tipo", {
        is: (field) => {
            const tipo = field ? field.value : "";
            return tipo === "vehiculos";
        },
        then: () => yup.string().required("La linea es requerida"),
    }),
    modelo: yup.mixed().when("tipo", {
        is: (field) => {
            const tipo = field ? field.value : "";
            return tipo === "vehiculos";
        },
        then: () =>
            yup
                .number()
                .typeError("Debe de ser un numero")
                .positive("Debe de ser un numero positivo")
                .test(
                    "len",
                    "Debe de tener 4 digitos (ej. 2014)",
                    (val) => val.toString().length === 4
                )
                .required("El modelo es requerido"),
    }),
    placa: yup.mixed().when("tipo", {
        is: (field) => {
            const tipo = field ? field.value : "";
            return tipo === "vehiculos";
        },
        then: () => yup.string().required("La placa es requerida"),
    }),
    chasis: yup.mixed().when("tipo", {
        is: (field) => {
            const tipo = field ? field.value : "";
            return tipo === "vehiculos";
        },
        then: () => yup.string().required("Requerido"),
    }),
    motor: yup.mixed().when("tipo", {
        is: (field) => {
            const tipo = field ? field.value : "";
            return tipo === "vehiculos";
        },
        then: () => yup.string().required("Requerido"),
    }),
    deducible_damage: yup.mixed().when("tipo", {
        is: (field) => {
            const tipo = field ? field.value : "";
            return tipo === "vehiculos";
        },
        then: () =>
            yup
                .number()
                .typeError("Debe de ser un numero")
                .required("Requerido"),
    }),
    deducible_robo: yup.mixed().when("tipo", {
        is: (field) => {
            const tipo = field ? field.value : "";
            return tipo === "vehiculos";
        },
        then: () =>
            yup
                .number()
                .typeError("Debe de ser un numero")
                .required("Requerido"),
    }),
});

function Editar({ history, ...props }) {
    const params = props.match.params;
    const seguroId = params.seguroId;
    const { control, reset, handleSubmit, watch, formState } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: async () => {
            let formData = await getSeguro(seguroId);
            formData.agente = {
                value: formData.agente.id,
                label: formData.agente.nombre,
            };
            formData.cliente = {
                value: formData.cliente.id,
                label: formData.cliente.nombre,
            };
            formData.vigencia = {
                start: formData.vigencia_inicio,
                end: formData.vigencia_fin,
            };
            formData.tipo = {
                value: formData.tipo,
                label: formData.tipo.toUpperCase(),
            };
            formData.clasificacion = formData.clasificacion
                ? {
                      value: formData.clasificacion,
                      label: formData.clasificacion.toUpperCase(),
                  }
                : null;

            // setLoadingState(false);
            return formData;
        },
    });

    const onSubmit = (data) => {
        data.vigencia_inicio = data.vigencia.start;
        data.vigencia_fin = data.vigencia.end;
        data.agente = data.agente.value;
        data.cliente = data.cliente.value;
        data.tipo = data.tipo.value;
        data.clasificacion = data.clasificacion
            ? data.clasificacion.value
            : null;
        const shouldGoBack = editarSeguro(data, seguroId);
        if (shouldGoBack) {
            history.goBack();
        }
    };

    let tipo = watch("tipo");
    tipo = tipo ? tipo.value : "";

    return (
        <React.Fragment>
            <Banner>Editar seguro</Banner>
            <form onSubmit={handleSubmit(onSubmit)}>
                <InputsContainer>
                    <FicComboBox
                        componentStyle={{ flexGrow: "1", maxWidth: "400px" }}
                        control={control}
                        label="Agente"
                        name="agente"
                        loadOptions={getAgentes}
                    />
                    <FicComboBox
                        componentStyle={{ flexGrow: "1", maxWidth: "400px" }}
                        control={control}
                        label="Cliente"
                        name="cliente"
                        loadOptions={getClientes}
                    />
                    <FicComboBox
                        componentStyle={{
                            flexGrow: "1",
                            minWidth: "250px",
                            width: "auto",
                        }}
                        control={control}
                        label="Tipo"
                        name="tipo"
                        nonAsync
                        options={OPCIONES_TIPO}
                    />
                    {tipo === "incendio" && (
                        <FicComboBox
                            componentStyle={{
                                flexGrow: "1",
                                width: "auto",
                                minWidth: "250px",
                            }}
                            control={control}
                            label="Clasificacion"
                            name="clasificacion"
                            nonAsync
                            options={OPCIONES_CLASIFICACION}
                            disabled={tipo != "incendio"}
                        />
                    )}
                </InputsContainer>
                <br />
                <InputsContainer>
                    <GridContainer>
                        <FicInput
                            control={control}
                            label="Poliza"
                            name="poliza"
                            inputMode="numeric"
                        />
                        <FicInput
                            control={control}
                            label="Aseguradora"
                            name="aseguradora"
                        />
                        <FicInput
                            control={control}
                            label="Suma asegurada"
                            name="suma_asegurada"
                        />
                        <FicDateRangePicker
                            control={control}
                            label="Vigencia"
                            name="vigencia"
                        />
                    </GridContainer>
                </InputsContainer>
                <br />
                {tipo === "vehiculos" && (
                    <InputsContainer>
                        <SectionTitle>Datos del vehiculo</SectionTitle>
                        <GridContainer>
                            <FicInput
                                control={control}
                                label="Marca"
                                name="marca"
                            />
                            <FicInput
                                control={control}
                                label="Linea"
                                name="linea"
                            />
                            <FicInput
                                control={control}
                                label="Modelo"
                                name="modelo"
                            />
                            <FicInput
                                control={control}
                                label="Placa"
                                name="placa"
                            />
                            <FicInput
                                control={control}
                                label="Chasis"
                                name="chasis"
                            />
                            <FicInput
                                control={control}
                                label="Motor"
                                name="motor"
                            />
                            <FicInput
                                control={control}
                                label="D. Daños"
                                name="deducible_damage"
                            />
                            <FicInput
                                control={control}
                                label="D. Robo"
                                name="deducible_robo"
                            />
                        </GridContainer>
                    </InputsContainer>
                )}

                <GenBtn style={{ margin: "1rem 0" }} type="submit">
                    Guardar
                </GenBtn>
            </form>
        </React.Fragment>
    );
}

export default withRouter(Editar);
