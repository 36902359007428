import { connect } from "react-redux";
import { actions } from "../../../../../redux/modules/finanzas/estados-financieros/balanceDeSaldos";
import CierresParcialesHome from "./CierresParcialesHome";

const ms2p = (state) => {
    return {
        ...state.finanzas,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(CierresParcialesHome);
