import React, { Component } from 'react';
import Grid from '../../Utils/GridVDocumento';
import { standardActions } from '../../Utils/GridVDocumento/StandardActions';

class DetallePromocionPuestoList extends Component{
    
    render(){
        const { loader, eliminarLinea,  detalle } = this.props;
        return(
            <React.Fragment>
                {detalle &&
                <Grid 
                    hover 
                    striped 
                    data={detalle} 
                    loading={loader}
                    headerStyle={ { background: 'black' } } 
                >
                    <TableHeaderColumn
                        isKey
                        dataField="departamentoN"
                        dataAlign='left'
                        headerAlign="center"
                        width="300"
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Departamento
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="puestoN"
                        width="300"
                        dataAlign='left'
                        headerAlign="center"
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Puesto
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="jefe_inmediato"
                        dataAlign='left'
                        headerAlign="center"
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Jefe inmediato
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="lugar_trabajo"
                        dataAlign='left'
                        headerAlign="center"
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Lugar de trabajo
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="horario_laboral"
                        dataAlign='left'
                        headerAlign="center"
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Horario de trabajo
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        thStyle={
                        {color: 'white'}}
                        dataField="id"
                        width="150"
                        dataAlign="center"
                        dataSort
                        dataFormat={standardActions({
                            eliminarCargo: eliminarLinea })} 
                    >
                        Acciones
                    </TableHeaderColumn>
                </Grid>
                }
            </React.Fragment>
        );
    }
}

export default DetallePromocionPuestoList;
