import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";

const LISTADO_UNIDAD = 'LISTADO_UNIDAD';
const GUARDAR_UNIDAD = 'GUARDAR_UNIDAD';
const LOADER = 'LOADER';
const MODULO = 'MODULO';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    api.get('/unidad_medida', params).then((response)=>{
        dispatch({ type: LISTADO_UNIDAD, data: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar unidad de medida',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const registroUnidad = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormUnidad.values;
    const formData = {
        unidad_medida: data.unidad_medida,
        descripcion: data.descripcion,
        empresa: id_emp[5]
    }
    dispatch(setLoader(true));
    api.post('/unidad_medida/', formData).then((response) => {
        NotificationManager.success(
        'Unidad de medida creada',
        'Exito',
        3000
    );
    let ruta = `/inventario/${id_emp[5]}/unidad_medida`
    dispatch(push(ruta));
    }).catch((error) => {
    NotificationManager.error(
        error.msj,
        'Error',
        2000
    );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const modificarUnidad = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const datos = getStore().form.FormUnidad.values;
    const id = datos.id;
    const formData = {
        id: id,
        unidad_medida: datos.unidad_medida,
        descripcion: datos.descripcion,
    }
    dispatch(setLoader(true));
    api.put(`/unidad_medida/${id}/`, formData).then((response) => {
        NotificationManager.success(
            'Unidad de medida modificada correctamente',
            'Exito',
            3000
        );
        let ruta = `/inventario/${id_emp[5]}/unidad_medida`
        dispatch(push(ruta));
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al modificar unidad de medida',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/unidad_medida/${id}`).then((response) => {
        dispatch({type: GUARDAR_UNIDAD, lectura: response });
        dispatch(initializeForm('FormUnidad', response ));
    }).catch((error)=>{
        console.log("error: ", error)
        NotificationManager.error(
            'Ocurrió un error al consultar unidad de medida',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const actions = {
    listar,
    registroUnidad,
    modificarUnidad,
    leer,
    getModulo
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_UNIDAD]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [GUARDAR_UNIDAD]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
};

export const initialState = {
    loader: false,
    lectura: [],
    modulo: [],
    data: [],
};

export default handleActions(reducers, initialState)
