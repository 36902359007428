import React, { Component } from 'react';
import Grid from '../../Utils/GridFiltro';
import PropTypes from 'prop-types';
import { standardActions } from '../../Utils/GridFiltro/StandardActions';
const valEmp = __CONFIG__.empresa;

class LineaList extends Component{
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        loading: true
    };

    componentDidMount = () => {
        const { listar } = this.props;
        listar();
    }
    render(){
        const { data, loader, eliminar } = this.props;
        let ruta = window.location.href;
        let id_emp = ruta.split('/');
        return(
            <React.Fragment>
                <br />
                <h3 className="p-2 bg-secondary title">Principio activo</h3>
                <br />
                {valEmp === 'fage' &&
                    <div className="d-flex flex-row justify-content-start mb-2">
                        <a
                            href={`/#/lfg/registro_sanitario/${id_emp[6]}/principio_activo/crear`}
                            className='btn btn-primary'
                        >
                            Crear principio activo
                        </a>
                    </div>
                }
                {valEmp === '' &&
                    <div className="d-flex flex-row justify-content-start mb-2">
                        <a
                            href={`/#/registro_sanitario/${id_emp[5]}/principio_activo/crear`}
                            className='btn btn-primary'
                        >
                            Crear principio activo
                        </a>
                    </div>
                }
                {data &&
                    <Grid 
                        hover 
                        striped 
                        data={data} 
                        loading={loader} 
                        headerStyle={ { background: 'black' } } 
                        //onPageChange={onPageChange}
                        //onSortChange={onSortChange} 
                    >
                        <TableHeaderColumn
                            isKey
                            dataField="descripcion"
                            headerAlign="center"
                            width="200"
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Principio activo
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            thStyle={
                            {color: 'white'}}
                            dataField="id"
                            width="180"
                            dataAlign="center"
                            dataSort
                            dataFormat={standardActions({ 
                                editar: "principio_activo", 
                                ver: "principio_activo",
                                eliminar: eliminar })} 
                            
                        >
                            Acciones
                        </TableHeaderColumn>
                    </Grid>
                }
            </React.Fragment>
        );
    }
}

export default LineaList;
