import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from '../../components/Modal';
import PropTypes from 'prop-types';
const test = __CONFIG__.test;

function FormSeleccionarCompra(props) {
    const {
        periodoL,
        datos
    } = props;

    const tipos = [
        {
            value: 'LOCAL',
            label: 'LOCAL'
        },
        {
            value: 'LIQ',
            label: 'LIQUIDACION DE GASTOS'
        },
        {
            value: 'IMP',
            label: 'IMPORTACION'
        },
        {
            value: 'NC',
            label: 'NOTA DE CREDITO'
        },
    ];

    const tiposTest = [
        {
            value: 'LOCAL',
            label: 'LOCAL'
        },
        {
            value: 'LIQ',
            label: 'LIQUIDACION DE GASTOS'
        },
        {
            value: 'NC',
            label: 'NOTA DE CREDITO'
        },
    ];

    const tiposFEL = [
        {
            value: 'LOCAL',
            label: 'LOCAL'
        },
        {
            value: 'LIQ',
            label: 'LIQUIDACION DE GASTOS'
        },
        {
            value: 'IMP',
            label: 'IMPORTACION'
        },
        {
            value: 'FACTESPFEL',
            label: 'FACTURA ESPECIAL  [ FEL ]'
        },
        {
            value: 'NC',
            label: 'NOTA DE CREDITO'
        },
    ];


    const [id, setId] = useState("");
    
    const [idTC, setIdTC] = useState("");

    const handleChangeTC = e => {
        setIdTC(e.target.value);
    }
    
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    
    const handleChange = e => {
        setId(e.target.value);
    }
    
    const [estadoModal, cambiarEstadoModal] = useState(true);
    

    return (
        <div className='container'>
            <Modal
                estado={estadoModal}
                cambiarEstado={cambiarEstadoModal}
                titulo="Seleccionar tipo de compra y periodo"
                botonCerrar="false"
            >
                { datos.fel === 'S' && test === false &&
                    <div className="form-floating mb-3">
                        <select className="form-select" id="tipo" name="tipo"
                        value={idTC} onChange={handleChangeTC} autoFocus >
                        <option selected>Seleccionar...</option>
                        {tiposFEL.map(
                            tipo => <option key={tipo.value} value={tipo.value}
                            >{tipo.label}
                            </option>)
                        }
                        </select>
                        <label className='form-label' htmlFor="mes">Tipo de compra</label>
                    </div>
                    
                }
                { datos.fel === 'S' && test === true &&
                    <div className="form-floating mb-3">
                        <select className="form-select" id="tipo" name="tipo"
                        value={idTC} onChange={handleChangeTC} autoFocus >
                        <option selected>Seleccionar...</option>
                        {tiposFEL.map(
                            tipo => <option key={tipo.value} value={tipo.value}
                            >{tipo.label}
                            </option>)
                        }
                        </select>
                        <label className='form-label' htmlFor="mes">Tipo de compra</label>
                    </div>
                    
                }
                { datos.fel === 'N' && test === true &&
                    <div className="form-floating mb-3">
                        <select className="form-select" id="tipo" name="tipo"
                        value={idTC} onChange={handleChangeTC} autoFocus >
                        <option selected>Seleccionar...</option>
                        {tiposTest.map(
                            tipo => <option key={tipo.value} value={tipo.value}
                            >{tipo.label}
                            </option>)
                        }
                        </select>
                        <label className='form-label' htmlFor="mes">Tipo de compra</label>
                    </div>
                    
                }
                { datos.fel === 'N' && test === false &&
                    <div className="form-floating mb-3">
                        <select className="form-select" id="tipo" name="tipo"
                        value={idTC} onChange={handleChangeTC} autoFocus >
                        <option selected>Seleccionar...</option>
                        {tipos.map(
                            tipo => <option key={tipo.value} value={tipo.value}
                            >{tipo.label}
                            </option>)
                        }
                        </select>
                        <label className='form-label' htmlFor="mes">Tipo de compra</label>
                    </div>
                    
                }
                {periodoL && idTC != '' && 
                        <div className="form-floating">
                            <select className="form-select" id="periodo"
                                value={id} onChange={handleChange} >
                                <option selected>Seleccionar...</option>
                                {periodoL.map(mes =>
                                    <option key={mes.value} value={mes.value}>{mes.label}</option>)
                                }

                            </select>
                            <label className='form-label' htmlfor="periodo">Periodo</label>
                        </div>
                    }
                <br />
                <Contenido>
                    {periodoL.length > 0 && idTC === 'LOCAL' &&
                        (id &&
                        <a
                            href={`/#/compras/${id_emp[5]}/compras/${id}`}
                            className='btn mr-2 mb-3 btn-primary'
                        >
                            Seleccionar
                        </a>
                        )
                    }
                    {periodoL.length > 0 && idTC === 'LIQ' &&
                        (id &&
                        <a
                            href={`/#/compras/${id_emp[5]}/liquidacion_gastos/${id}`}
                            className='btn mr-2 mb-3 btn-primary'
                        >
                            Seleccionar
                        </a>
                        )
                        
                    }
                    {periodoL.length > 0 && idTC === 'IMP' &&
                        (id &&
                        <a
                            href={`/#/compras/${id_emp[5]}/importaciones/${id}`}
                            className='btn mr-2 mb-3 btn-primary'
                        >
                            Seleccionar
                        </a>
                        )
                        
                    }
                    {periodoL.length > 0 && idTC === 'NC' &&
                        (id &&
                        <a
                            href={`/#/compras/${id_emp[5]}/nota_credito/${id}`}
                            className='btn mr-2 mb-3 btn-primary'
                        >
                            Seleccionar
                        </a>
                        )
                        
                    }
                    {periodoL.length > 0 && idTC === 'FACTESPFEL' && datos.fel === 'S' &&
                        (id &&
                        <a
                            href={`/#/compras/${id_emp[5]}/factura_especial_fel/${id}`}
                            className='btn mr-2 mb-3 btn-primary'
                        >
                            Seleccionar
                        </a>
                        )
                        
                    }
                </Contenido>
            </Modal>
        </div>
    );

}
FormSeleccionarCompra.propTypes = {
    periodoL: PropTypes.string.isRequired,
    datos: PropTypes.string.isRequired,
};

export default (FormSeleccionarCompra);

const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;