import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";

const LISTADO_MARCAS = 'LISTADO_MARCAS';
const GUARDAR_MARCA = 'GUARDAR_MARCA';
const LISTADO_AGENTES = 'LISTADO_AGENTES';
const LISTADO_PAIS = 'LISTADO_PAIS';
const LISTADO_STATUS = 'LISTADO_STATUS';
const LISTADO_TITULARES = 'LISTADO_TITULARES';
const LISTADO_LIMITACIONES = 'LISTADO_LIMITACIONES';
const LISTADO_COMERCIAL = 'LISTADO_COMERCIAL';
const LISTADO_PRINCIPIO = 'LISTADO_PRINCIPIO';
const LOADER = 'LOADER';
const MODULO = 'MODULO';
const PARAMETRO = 'PARAMETRO';
const DETALLE_HISTORIAL = 'DETALLE_HISTORIAL';
const OBSERVACIONES = 'OBSERVACIONES';
const LISTADO_PAISES = 'LISTADO_PAISES';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let params = { id };
    dispatch(setLoader(true));
    api.get('/registro_marca/registro_marca', params).then((response)=>{
        dispatch({ type: LISTADO_MARCAS, data: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar marcas',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const registroMarca = (data={},attachments=[]) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id_empresa = id_emp[5]
    
    const formData = {
        descripcion_marca: data.descripcion_marca,
        clase: data.clase_marca,
        tipo: data.tipo,
        certificado: data.certificado,
        acta: data.acta,
        agente: data.agente,
        pais: data.pais_marca,
        status: data.status,
        titular: data.titular,
        fecha_vencimiento: data.fecha_vencimiento,
        fecha_concesion: data.fecha_concesion,
        fecha_sometida: data.fecha_sometida,
        fecha_actualizacion: data.fecha_actualizacion,
        limitacion: data.limitacion,
        renueva: data.renueva,
        titulo_archivo: data.titulo_archivo,
        principio_activo: data.principio_activo == '0' ? '' : data.principio_activo,
        comercial: data.comercial == '0' ? '' : data.comercial,
        empresa: id_empresa,
    }
    dispatch(setLoader(true));
    api.postAttachments('/registro_marca/registro_marca/', formData, attachments).then(() => {
        NotificationManager.success(
            'Marca creada',
            'Exito',
            3000
        );
        setTimeout(() => {
            window.location.reload();
        }, 2000);
    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const modificarMarca = (data={},attachments=[]) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = data.id;
    
    let tipo = data.tipo_marca;
    if (tipo === undefined) {
        tipo = data.tipo;
    } else {
        tipo = data.tipo_marca;
    }
    let agente = data.agente;
    if (agente === undefined) {
        agente = data.agente;
    } else {
        agente = data.agente;
    }
    let pais_marca = data.pais_marca;
    if (pais_marca === undefined) {
        pais_marca = data.pais;
    } else {
        pais_marca = data.pais_marca;
    }
    let status = data.status;
    if (status === undefined) {
        status = data.status;
    } else {
        status = data.status;
    }
    let titular = data.titular;
    if (titular === undefined) {
        titular = data.titular;
    } else {
        titular = data.titular;
    }
    let principio_activo = data.principio_activo;
    if (principio_activo === undefined) {
        principio_activo = data.principio_activo;
    } else {
        principio_activo = data.principio_activo;
    }
    let comercial = data.comercializada;
    if (comercial === undefined) {
        comercial = data.comercial;
    } else {
        comercial = data.comercializada;
    }
    const formData = {
        id: id,
        descripcion_marca: data.descripcion_marca,
        clase: data.clase_marca,
        tipo: tipo,
        certificado: data.certificado,
        acta: data.acta,
        agente: agente,
        pais: pais_marca,
        status: status,
        titular: titular,
        fecha_vencimiento: data.fecha_vencimiento,
        fecha_concesion: data.fecha_concesion,
        fecha_sometida: data.fecha_sometida,
        fecha_actualizacion: data.fecha_actualizacion,
        limitacion: data.limitacion,
        renueva: data.renueva,
        titulo_archivo: data.titulo_archivo,
        principio_activo: principio_activo == '0' ? '' : principio_activo,
        comercial: comercial == '0' ? '' : comercial,
    }
    dispatch(setLoader(true));
    api.putAttachments(`/registro_marca/registro_marca/${id}/`, formData, attachments).then(() => {
        NotificationManager.success(
            'Marca modificada correctamente',
            'Exito',
            3000
        );
        let ruta = `/registro_marca/${id_emp[5]}/marca`
        dispatch(push(ruta));
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al modificar marca',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const leerDetalleHistorial = id => (dispatch) => {
    api.get(`/registro_marca/historial/${id}`).then((response)=>{
        dispatch({ type: DETALLE_HISTORIAL, detalleHis: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};


export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/registro_marca/registro_marca/${id}`).then((response) => {
        dispatch({type: GUARDAR_MARCA, lectura: response });
        dispatch(initializeForm('FormRegistroMarcaVer', response ));
        dispatch(leerDetalleHistorial(response.id));
        dispatch(leerObservaciones(response.id))
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar marca',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}


export const leerMod = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/registro_marca/registro_marca/${id}`).then((response) => {
        dispatch({type: GUARDAR_MARCA, lectura: response });
        dispatch(initializeForm('FormRegistroMarca', response ));
        dispatch(leerDetalleHistorial(response.id));
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar marca',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const listarAgentes = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let params = { id };
    dispatch(setLoader(true));
    api.get('/registro_marca/agente', params).then((response) => {
        const agente = [];
        response.forEach(item => {
            agente.push({
                value: item.id,
                label: item.nombre_agente
            })
        })
        dispatch({ type: LISTADO_AGENTES, agentes: agente });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar agentes',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const parametrosEmpresa = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id }
    dispatch(setLoader(true));
    api.get('/empresa/empresa_parametros', params).then((response) => {
        dispatch({ type: PARAMETRO, datos: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar parametros',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const listarStatus = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let params = { id };
    dispatch(setLoader(true));
    api.get('/registro_marca/status', params).then((response) => {
        const statu = [];
        response.forEach(item => {
            statu.push({
                value: item.id,
                label: item.status
            })
        })
        dispatch({ type: LISTADO_STATUS, status: statu });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar status',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const listarTitulares = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let params = { id };
    dispatch(setLoader(true));
    api.get('/registro_marca/titular', params).then((response) => {
        const titular = [];
        response.forEach(item => {
            titular.push({
                value: item.id,
                label: item.titular
            })
        })
        dispatch({ type: LISTADO_TITULARES, titulares: titular });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar titulares',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const listarLimitaciones = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let params = { id };
    
    dispatch(setLoader(true));
    api.get('/registro_marca/limitacion', params).then((response) => {
        const limitacion = [];
        response.forEach(item => {
            limitacion.push({
                value: item.id,
                label: item.limitacion
            })
        })
        dispatch({ type: LISTADO_LIMITACIONES, limitaciones: limitacion });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar limitaciones',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const listarComercializada = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let params = { id };
    
    dispatch(setLoader(true));
    api.get('/registro_marca/comercializada', params).then((response) => {
        const comercializada = [];
        response.forEach(item => {
            comercializada.push({
                value: item.id,
                label: item.descripcion
            })
        })
        dispatch({ type: LISTADO_COMERCIAL, comercial: comercializada });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar comercializadas',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const listarPrincipio = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let params = { id };
    
    dispatch(setLoader(true));
    api.get('/registro_marca/principio_activo', params).then((response) => {
        const principio = [];
        response.forEach(item => {
            principio.push({
                value: item.id,
                label: item.descripcion
            })
        })
        dispatch({ type: LISTADO_PRINCIPIO, principio_activo: principio });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar principios activos',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const listarPaises = () => (dispatch) => {
    return api.get("/paises").then(response => {
        const pais = [];
        response.results.forEach(item => {
            pais.push({
                value: item.id,
                label: item.pais.toUpperCase()
            })
        })
        dispatch({ type: LISTADO_PAISES, listaPais: pais });
    })
}

const leerObservaciones = id => (dispatch) => {
    api.get(`/registro_marca/observaciones/${id}`).then((response)=>{
        dispatch({ type: OBSERVACIONES, detalleObs: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};

export const actions = {
    listar,
    registroMarca,
    modificarMarca,
    listarAgentes,
    listarPaises,
    listarStatus,
    listarTitulares,
    listarLimitaciones,
    listarComercializada,
    listarPrincipio,
    leer,
    leerMod,
    getModulo,
    parametrosEmpresa,
    leerObservaciones
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_MARCAS]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [GUARDAR_MARCA]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
    [LISTADO_AGENTES]: (state, { agentes }) => {
        return {
            ...state,
            agentes,
        };
    },
    [LISTADO_PAIS]: (state, { paises }) => {
        return {
            ...state,
            paises,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [PARAMETRO]: (state, { datos }) => {
        return {
            ...state,
            datos,
        };
    },
    [LISTADO_STATUS]: (state, { status }) => {
        return {
            ...state,
            status,
        };
    },
    [LISTADO_TITULARES]: (state, { titulares }) => {
        return {
            ...state,
            titulares,
        };
    },
    [LISTADO_LIMITACIONES]: (state, { limitaciones }) => {
        return {
            ...state,
            limitaciones,
        };
    },
    [LISTADO_COMERCIAL]: (state, { comercial }) => {
        return {
            ...state,
            comercial,
        };
    },
    [LISTADO_PRINCIPIO]: (state, { principio_activo }) => {
        return {
            ...state,
            principio_activo,
        };
    },
    [DETALLE_HISTORIAL]: (state, { detalleHis }) => {
        return {
            ...state,
            detalleHis,
        };
    },
    [LISTADO_PAISES]: (state, { listaPais }) => {
        return {
            ...state,
            listaPais,
        };
    },
    [OBSERVACIONES]: (state, { detalleObs }) => {
        return {
            ...state,
            detalleObs,
        };
    },
};

export const initialState = {
    loader: false,
    lectura: [],
    modulo: [],
    detalleProducto: [],
    data: [],
    page: 1,
    datos: [],
    agentes: [],
    paises: [],
    status: [],
    titulares: [],
    limitaciones: [],
    comercial: [],
    principio_activo: [],
    detalleHis: [],
    listaPais: [],
    detalleObs: [],
};

export default handleActions(reducers, initialState)
