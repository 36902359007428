import React, { Component } from 'react';
import Formulario from './InformesFormLC';

class Informes extends Component{

    componentDidMount = () => {
        const { listarCuentas, listarCC, getModulo, parametrosEmpresa } = this.props;
        listarCuentas();
        listarCC();
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        getModulo(modulo[5],modulo[4]);
        parametrosEmpresa();
    }

    render(){
        const { 
            listarCuentas, 
            loader, 
            listarCC, 
            libroMayor, 
            balanceGeneral, 
            libroDiario,
            balanceSaldos, 
            reporteCuentas, 
            estadoResultado, 
            modulo, 
            datos,
            excelBalanceSaldos,
            excelLibroMayor
        } = this.props;
        return(
            <div>
                <Formulario
                    listarCuentas = { listarCuentas }
                    listarCC = {listarCC}
                    libroMayor = {libroMayor}
                    balanceG = {balanceGeneral}
                    balanceS = {balanceSaldos}
                    reporteCuentas = {reporteCuentas}
                    estadoResultado = {estadoResultado}
                    loader = {loader}
                    modulo = {modulo}
                    datos = {datos}
                    libroDiario = {libroDiario}
                    excelBalanceSaldos = {excelBalanceSaldos}
                    excelLibroMayor = {excelLibroMayor}
                />
            </div>
        );
    }
}

export default Informes;
