export default function processFormData(data, withSubSerie) {
    if (typeof data.clinica === "object") {
        data.clinica = data.clinica.value;
    }
    data.tipo = data.tipo.value;
    if (data.tipo === "inmunohistoquimica") {
        data.patologia = data.patologia && data.patologia.value;
        // data.paciente = null;
    }
    if (data.tipo !== "inmunohistoquimica") {
        data.patologia = null;
    }
    if (data.igss) {
        data.igss = data.igss.value;
    }
    data.serie = data.serie.value;
    if (!withSubSerie) {
        delete data.sub_serie;
    }
    return data;
}
