import React from 'react';
import {Checkbox} from 'react-aria-components';
import './FicCheckbox.css';
import { useController } from 'react-hook-form';


export default function FicCheckbox({label, style, control, name, nonForm, ...props}){
    // Creado con la intencion de usarse con react-hook-form, (https://react-hook-form.com/api/usecontroller)
    if(nonForm) {
        return(
            <Checkbox style={style} {...props} >
                <div className="checkbox">
                    <svg viewBox="0 0 18 18" aria-hidden="true">
                    <polyline points="1 9 7 14 15 4" />
                    </svg>
                </div>
            {label}
            </Checkbox>
        )
        
    }
    const { field } = useController({control, name});
    return(
        <Checkbox style={style} {...props} {...field} isSelected={field.value}>
            <div className="checkbox">
                <svg viewBox="0 0 18 18" aria-hidden="true">
                <polyline points="1 9 7 14 15 4" />
                </svg>
            </div>
        {label}
        </Checkbox>
        )
}