import React, { Component } from 'react';
import Formulario from './PuestoForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";
const valEmp = __CONFIG__.empresa;

class Puesto extends Component{
    state = {
        crear: true,
    }

    componentDidMount = () => {
        const { match, leer, getModulo } = this.props;
        const id = match.params.id;
        if (id){
            this.setState({crear: false});
            leer(id);
        }
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        if (valEmp === 'fage'){
            getModulo(modulo[6],modulo[5])
        }else{
            getModulo(modulo[5],modulo[4])
        }
    }
    
    render(){
        const { registroPuesto, modificarPuesto, loader, modulo } = this.props;
        const { crear } = this.state;
        const funcionEnvio = crear ? registroPuesto : modificarPuesto;
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    onSubmit = {funcionEnvio}
                    crear = {crear}
                    modulo = {modulo}
                />
            </LoadMask>
        );
    }
}

export default Puesto;
