import React from "react";
import {
    TRow,
    TTableHeader,
    TCheckbox,
    TColumn,
    TTable,
    TTableBody,
    TButton,
    TCell,
    TDropIndicator,
} from "../../../Utils/Components/TableComponents";
import { useDragAndDrop } from "react-aria-components";
import { useSaldo } from "../context/provider";
import { useListData } from "react-stately";
import {
    Table,
    Column,
    Cell,
    TableBody,
    Button,
    isTextDropItem,
    TableHeader,
    Row,
} from "react-aria-components";
import styled from "styled-components";

const DragPreviewContainer = styled.div`
    display: flex;
    justify-content: space-between;
    background-color: #bd0034;
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 1rem;
    gap: 5px;
    width: fit-content;
`;

export default function DocumentosTable({ list, ...props }) {
    const processItems = async (items) => {
        return await Promise.all(
            items.filter(isTextDropItem).map(async (item) => {
                return JSON.parse(await item.getText("custom-type"));
            })
        );
    };

    let { dragAndDropHooks } = useDragAndDrop({
        // Provide drag data in a custom format as well as plain text.
        getItems(keys) {
            return [...keys].map((key) => {
                let item = list.getItem(key);
                return {
                    "custom-type": JSON.stringify(item),
                    "text/plain": item.numero_patologia,
                };
            });
        },
        renderDropIndicator(target) {
            return <TDropIndicator target={target} />;
        },

        renderDragPreview(items) {
            const parsedItems = items.map((item) =>
                JSON.parse(item["custom-type"])
            );
            const totalAbonos = parsedItems.reduce(
                (acc, item) => acc + Number(item.saldo),
                0
            );
            return (
                <DragPreviewContainer>
                    Total de {items.length} documento(s):
                    <span
                        style={{
                            background: "white",
                            color: "#bd0034",
                            borderRadius: "4px",
                            padding: "0 8px",
                            textAlign: "center",
                            verticalAlign: "middle",
                            fontWeight: "bold",
                        }}
                    >
                        {totalAbonos}
                    </span>
                </DragPreviewContainer>
            );
        },

        // Accept drops with the custom format.
        acceptedDragTypes: ["custom-type"],

        // Ensure items are always moved rather than copied.
        getDropOperation: () => "move",

        // Handle drops between items from other lists.
        async onInsert(e) {
            let processedItems = await processItems(e.items);
            if (e.target.dropPosition === "before") {
                list.insertBefore(e.target.key, ...processedItems);
            } else if (e.target.dropPosition === "after") {
                list.insertAfter(e.target.key, ...processedItems);
            }
        },

        // Handle drops on the collection when empty.
        async onRootDrop(e) {
            let processedItems = await processItems(e.items);
            list.append(...processedItems);
        },

        // Handle reordering items within the same list.
        onReorder(e) {
            if (e.target.dropPosition === "before") {
                list.moveBefore(e.target.key, e.keys);
            } else if (e.target.dropPosition === "after") {
                list.moveAfter(e.target.key, e.keys);
            }
        },

        // Remove the items from the source list on drop
        // if they were moved to a different list.
        onDragEnd(e) {
            if (e.dropOperation === "move" && !e.isInternal) {
                list.remove(...e.keys);
            }
        },
    });
    return (
        <React.Fragment>
            <TTable
                aria-label={props["aria-label"]}
                selectionMode="multiple"
                selectedKeys={list.selectedKeys}
                onSelectionChange={list.setSelectedKeys}
                dragAndDropHooks={dragAndDropHooks}
            >
                <TTableHeader>
                    <TColumn />
                    <TColumn>
                        <TCheckbox slot="selection" />
                    </TColumn>
                    <TColumn>Fecha</TColumn>
                    <TColumn>Cliente</TColumn>
                    <TColumn isRowHeader>Patologia</TColumn>
                    <TColumn>Saldo</TColumn>
                </TTableHeader>
                <TTableBody
                    items={list.items}
                    renderEmptyState={() => (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            No hay documentos
                        </div>
                    )}
                >
                    {(item) => (
                        <TRow>
                            <TCell>
                                <TButton slot="drag">≡</TButton>
                            </TCell>
                            <TCell>
                                <TCheckbox slot="selection" />
                            </TCell>
                            <TCell>{item.fecha_recibido}</TCell>
                            <TCell>{item.clinica.nombre}</TCell>
                            <TCell>{item.numero_patologia}</TCell>
                            <TCell>{item.saldo.toLocaleString()}</TCell>
                        </TRow>
                    )}
                </TTableBody>
            </TTable>
        </React.Fragment>
    );
}
