import React, { Component } from 'react';
import Grid from '../../Utils/GridVDocumento';

class DetalleListadoInvernaderosVerList extends Component{
    
    render(){
        const { loader, detalle } = this.props;
        return(
            <React.Fragment>
                {detalle &&
                    <Grid 
                        hover 
                        striped 
                        data={detalle} 
                        loading={loader}
                        headerStyle={ { background: 'black' } } 
                    >
                        <TableHeaderColumn
                            isKey
                            dataField="fase"
                            dataAlign='left'
                            headerAlign="center"
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Llave
                        </TableHeaderColumn>
                    </Grid>
                }
            </React.Fragment>
        );
    }
}

export default DetalleListadoInvernaderosVerList;
