import React, { Component } from 'react';
import Formulario from './NivelesForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";

class Niveles extends Component{
    state = {
        crear: true
    }

    componentDidMount = () => {
        const { leer } = this.props;
        let ruta = window.location.href;
        let id_emp = ruta.split('/');
        if (id_emp[5]){
            leer();
            let n = localStorage.getItem('niveles');
            let tot = localStorage.getItem('niveles_cuentas');
            if (tot === '0' && n === 'true'){
                this.setState({crear: false});
            }
        }
    }
    render(){
        const { registroNivel, modificarNivel, loader } = this.props;
        const { crear } = this.state;
        const funcionEnvio = crear ? registroNivel : modificarNivel;
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    onSubmit={funcionEnvio}
                />
            </LoadMask>
        );
    }
}

export default Niveles;
