import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/recursos_humanos/judicial';
import Judicial from './Judicial';


const ms2p = (state) => {
    return {
        ...state.judicial_rh,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Judicial);
