import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import NavbarModulo from '../../components/NavbarModulo';

import {
  renderDayPickerB,
  AsyncSelectField
} from "../../Utils/renderField/renderField";

function CierreProyectoForm(props) {
    const { cierreProyecto, listarProyectos, modulo } = props;
    let titulo = 'Cierre de proyecto';
    let autoFocus = true;

    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    return (
        <div>
        <NavbarModulo
            modulo={modulo}
            titulo={titulo}
        />
        <br />
        <div className="mb-4 card card-small text-white bg-dark mb-3">
            <div className="p-0 pt-3 d-flex flex-column flex-md-row">
            <div className="d-flex flex-column flex-1 mx-4">
                <div className='row mb-2'>
                    <div className='col-2'>
                        <Field
                            autoFocus={autoFocus}
                            name="fecha"
                            component={renderDayPickerB}
                            msj="Fecha cierre de proyecto"
                        />
                    </div>
                    <div className='col-10'>
                        <Field
                            name="proyecto"
                            placeholder="Orden de servicio..."
                            className="form-control-find"
                            component={AsyncSelectField}
                            loadOptions={listarProyectos}
                            msj="Orden de servicio / proyecto"
                        />
                    </div>
                    <div className='d-flex flex-row mt-3'>
                        <button
                            className='btn mr-2 mb-3 btn-primary'
                            onClick={cierreProyecto}
                            >
                            Cerrar proyecto
                        </button>
                        <a
                            href={`/#/inventario/${id_emp[5]}`}
                            className='btn btn-secondary mb-3'
                        >
                            Regresar
                        </a>
                    </div>
                </div>
            </div>
            </div>
        </div>
        </div>
    );
}

CierreProyectoForm.propTypes = {
    modulo: PropTypes.object,
};

export default reduxForm({
    form: 'FormCierreProyectoINV', // a unique identifier for this form
  
})(CierreProyectoForm);
