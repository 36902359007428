import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';
import moment from 'moment';

const LISTADO_DEP = 'LISTADO_DEP';
const LISTADO_DEP_BUSCAR = 'LISTADO_DEP_BUSCAR';
const GUARDAR_DEP = 'GUARDAR_DEP';
const LISTADO_DOCUMENTOS = 'LISTADO_DOCUMENTOS';
const GUARDAR_POLIZA = 'GUARDAR_POLIZA';
const DETALLE_POLIZA = 'DETALLE_POLIZA';
const DETALLE_DEP = 'DETALLE_DEP';
const DETALLE_DOCUMENTOS = 'DETALLE_DOCUEMENTOS';
const CUENTA_BANCARIA = 'CUENTA_BANCARIA';
const PAGE = 'PAGE';
const LOADER = 'LOADER';
const PERIODO = 'PERIODO';
const NIVEL = 'NIVEL';
const LOADERC = 'LOADERC';
const PERIODO_D = 'PERIODOD';
const MODULO = 'MODULO';
const PARAMETRO = 'PARAMETRO';
const DOCUMENTOS = 'DOCUMENTOS';
const DETALLE = 'DETALLE';
const LISTADO_CUENTAS = 'LISTADO_CUENTAS';
const TOTAL_DEP = 'TOTAL_DEP';

const setPage = page => ({
    type: PAGE,
    page,
});

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setLoaderC = loaderC => ({
    type: LOADERC,
    loaderC,
});


export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let id_period = id_emp[7];
    let tipo = 'DP';
    let modulo = 'AGR';
    const params = { id, id_period, tipo, modulo };
    dispatch(setLoader(true));
    api.get('/ingresos', params).then((response) => {
        dispatch({ type: LISTADO_DEP, data: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar depósitos',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leerCuentaB = (cuenta_bancaria) => (dispatch) => {
    api.get(`/cuenta_banco/${cuenta_bancaria}`).then((response) => {
        dispatch({ type: CUENTA_BANCARIA, cuentaBan: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar Cuenta Bancaria',
            'Error',
            0
        );
    })
}

export const registroDep = (periodoD, cuentaBan, datos, totalDep) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormReciboDep.values;

    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fechaRegistro);
    let poliza = '';

    if (fecha >= fechaI && fecha <= fechaF) {
        if (datos.contabilidad === 'S') {
            poliza = 'banco';
        }
        if (datos.contabilidad === 'N') {
            poliza = '';
        }
        if (cuentaBan.cambio === 'S' && tipoCambio === null) {
            localStorage.setItem('dep_ban_rec', 'F')
            Swal.fire({
                title: 'Depósito',
                text: 'Tiene que ingresar tipo de cambio',
                type: 'error',
            })
        } else {
            const formData = {
                fechaRegistro: data.fechaRegistro,
                numero: data.numero,
                total: totalDep,
                cuenta: id_emp[9],
                concepto: 'ABONO DE CLIENTES',
                descripcion: data.descripcion,
                periodo: id_emp[7],
                modulo: 'BAN',
                empresa: id_emp[5],
                poliza: poliza,
                detalleNC: data.detalleD,
                cliente: '',
                tipo_cambio: '1.000000',
                cambio: cuentaBan.cambio,
                nombre: '',
                tipo: 'DP',
                recibo: 'S',
                idPoliza: localStorage.getItem('idPolizaDep_rec')
            }
            dispatch(setLoader(true));
            api.post('/ingresos/', formData).then((response) => {
                localStorage.setItem('dep_ban_rec', 'T')
                response.fechaRegistro = response.documento.fecha_registro;
                response.fecha = response.documento.fecha;
                response.numero = response.documento.numero;
                response.descripcion = response.documento.descripcion;
                response.total = response.documento.total;
                if (response.idPoliza) {
                    localStorage.setItem('idPolizaDep_rec', response.idPoliza);
                    const poliza = localStorage.getItem('idPolizaDep_rec');
                    localStorage.setItem('detalleID_Dep_rec', response.detalle);
                    dispatch(initializeForm('FormReciboDep', response));
                    dispatch(leerDetalleDocumentos(response.detalle));
                    dispatch(leerDetalle(poliza));
                } else {
                    setTimeout(function () {
                        window.location.reload(true);
                    }, 2000);
                }

                NotificationManager.success(
                    'Documento agregado correctamente',
                    'Exito',
                    3000
                );

            }).catch((error) => {
                localStorage.setItem('dep_ban_rec', 'F')
                NotificationManager.error(
                    error.msj,
                    'Error',
                    0
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }
    } else {
        localStorage.setItem('dep_ban_rec', 'F')
        Swal.fire({
            title: 'Depósito',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })
    }
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/ingresos/${id}`).then((response) => {
        response.fechaRegistro = response.fecha_registro;
        response.total = response.total;
        localStorage.setItem('conceptoDep_rec', response.concepto);
        localStorage.setItem('detalleID_Dep_rec', response.id);
        const editar = window.location.href.includes('editar');
        if (editar) {
            localStorage.setItem('idPolizaDep_rec', response.poliza);
        }
        if (response.poliza){
            dispatch(leerDetalle(response.poliza));
        }
        dispatch({ type: GUARDAR_DEP, lectura: response });
        dispatch(initializeForm('FormReciboDep', response));
        dispatch(leerDetalleDep(response.id));
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar el depósito',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const listarCuentas = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/cuentas/empresa_cuentas/', params).then(data => {
        const list_cuentas = [];
        if (data) {
            data.forEach(item => {
                if (item.tipo === 'A') {
                    list_cuentas.push({
                        value: item.id,
                        label: item.cuenta + ' ' + item.nombre,
                        isDisabled: true
                    })
                } else {
                    list_cuentas.push({
                        value: item.id,
                        label: item.cuenta + ' ' + item.nombre
                    })
                }
            })
        }
        return list_cuentas;
    }).catch((error) => {

        return [];
    })
}

export const listarCC = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5]
    const params = { id, search }
    return api.get('/centrocosto/empresa_centro_costo/', params).then(data => {
        const list_cc = [];
        if (data) {
            data.forEach(item => {
                list_cc.push({
                    value: item.id,
                    label: item.codigo + ' ' + item.descripcion
                })
            })
        }
        return list_cc;
    }).catch((error) => {

        return [];
    })
}

export const listarCortes = (periodo) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let idEmpre = id_emp[5];
    const params = { idEmpre, periodo }
    dispatch(setLoaderC(true));
    api.get('/ingresos/cortes_caja/', params).then((response) => {
        if (response.detalle.length > 0) {
            const saldos = response.detalle.filter((item) => parseFloat(item.total) >= 0.01)
            dispatch({ type: LISTADO_DOCUMENTOS, doc: saldos });
            const documento = [];
            response.detalle.forEach(item => {
                if (parseFloat(item.total) >= 0.01) {
                    documento.push({
                        value: item.id + ' # ' + item.total + ' # ' + item.fecha + ' # ' + 'CORTECAJ' + ' # ' + item.numero,
                        label: moment(item.fecha).format("DD/MM/YYYY") + ' --- ' + item.numero + ' -- ' + ' Saldo: ' + new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(item.total),
                    })
                }
            })
            dispatch({ type: DOCUMENTOS, list_doc: documento })
        } else {
            dispatch({ type: DOCUMENTOS, list_doc: [] })
            dispatch({ type: LISTADO_DOCUMENTOS, doc: [] });
        }
    }).catch((error) => {

        NotificationManager.error(
            'Ocurrió un error al consultar cortes de caja',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoaderC(false));
    });

}

export const listarNiveles = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    dispatch(setLoader(true));
    api.get('/niveles/nivel_cuenta', params).then((response) => {
        dispatch({ type: NIVEL, nivel: response });
    }).catch((error) => {

        NotificationManager.error(
            'Ocurrió un error al listar nivel',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const leerDetalle = id => (dispatch) => {
    api.get(`/poliza_detalle/${id}`).then((response) => {
        dispatch({ type: DETALLE_POLIZA, detalle: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};

const leerDetalleDocumentos = id => (dispatch) => {
    api.get(`/ingresos_detalle/${id}`).then((response) => {
        dispatch({ type: DETALLE_DOCUMENTOS, detalleDoc: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};

const leerDetalleDep = id => (dispatch) => {
    api.get(`/ingresos_detalle/${id}`).then((response) => {
        dispatch({ type: DETALLE_DEP, detalleDep: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};

const eliminarDocumento = id => (dispatch) => {

    const detalle = localStorage.getItem('detalleID_Dep_rec');
    dispatch(setLoader(true));
    api.eliminar(`/ingresos_detalle/${id}`).then((response) => {
        if (detalle) {
            const poliza = localStorage.getItem('idPolizaDep_rec');
            response.fechaRegistro = response.documento.fecha_registro;
            response.descripcion = response.documento.descripcion;
            response.clienteNombre = response.documento.clienteN;
            response.numero = response.documento.numero;
            response.total = response.documento.total;
            response.id = response.documento.id;
            response.cliente = response.documento.cliente
            response.diferencia = parseFloat(response.documento.total) - parseFloat(response.totalA)
            response.totalAbono = response.totalA;
            response.poliza = response.documento.poliza;
            dispatch(leerDetalleDep(detalle));
            dispatch(leerDetalle(poliza));
            dispatch(initializeForm('FormReciboDep', response));
        }
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar el documento',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const confirmarPoliza = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let dif = localStorage.getItem('dif');
    if (parseFloat(dif) === 0.00) {
        NotificationManager.success(
            'Póliza guardada correctamente',
            'Exito',
            3000
        );
        localStorage.removeItem('idPolizaDep');
        localStorage.removeItem('detalleID_Dep');
        const editar = window.location.href.includes('editar');
        if (editar) {
            let ruta = `/bancos/${id_emp[5]}/deposito/${id_emp[7]}`
            dispatch(push(ruta));
        } else {
            window.location.reload(true);
        }
    } else {
        Swal.fire({
            title: 'Modificar poliza',
            text: `Poliza con diferencia: ${new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(dif)}`,
            type: 'error',
        })
    }
}

export const registroPolizaDetalleSCC = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.DepFormTabs.values;
    const poliza = localStorage.getItem('idPolizaDep');
    const formData = {
        empresa: id_emp[5],
        periodo: id_emp[7],
        cc: 0,
        cuenta: data.cuenta.value,
        debe: data.debe,
        haber: data.haber,
        poliza: poliza
    }
    if (formData.debe === undefined && formData.haber === undefined) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'Debe de ingresar valor en debe o haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) === 0.00 && parseFloat(formData.haber) === 0.00) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'No puede llevar valores a 0.00 en debe o haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) >= 0.01 && parseFloat(formData.haber) >= 0.01) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'No puede llevar valores en debe y haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) >= 0.01) {
        formData.haber = 0.00;
        dispatch(setLoader(true));
        api.post('/polizas/encabezado_polizas/', formData).then((response) => {
            const poliza = localStorage.getItem('idPolizaDep')
            if (poliza === null) {
                response.debe = 0.00;
                response.haber = 0.00;
                dispatch(initializeForm('DepFormTabs', response));
                dispatch(leerDetalle(response.detalle.encabezado));
            } else {
                const editar = window.location.href.includes('editar');
                if (editar) {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('DepFormTabs', response));
                    dispatch(leerDetalleMod(poliza));
                } else {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('DepFormTabs', response));
                    dispatch(leerDetalle(poliza));
                }
            }

        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al registrar la poliza',
                'Error',
                2000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    } else if (parseFloat(formData.haber) >= 0.01) {
        formData.debe = 0.00;
        api.post('/polizas/encabezado_polizas/', formData).then((response) => {
            localStorage.removeItem('tipo_poliza');
            const poliza = localStorage.getItem('idPolizaDep')
            if (poliza === null) {
                response.debe = 0.00;
                response.haber = 0.00;
                dispatch(initializeForm('DepFormTabs', response));
                dispatch(leerDetalle(response.detalle.encabezado));
            } else {
                const editar = window.location.href.includes('editar');
                if (editar) {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('DepFormTabs', response));
                    dispatch(leerDetalleMod(poliza));
                } else {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('DepFormTabs', response));
                    dispatch(leerDetalle(poliza));
                }
            }

        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al registrar la poliza',
                'Error',
                2000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
}

export const registroPolizaDetalle = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.NCFormTabs.values;
    const poliza = localStorage.getItem('idPolizaDep');
    const formData = {
        empresa: id_emp[5],
        periodo: id_emp[7],
        cc: data.cc.value,
        cuenta: data.cuenta.value,
        debe: data.debe,
        haber: data.haber,
        poliza: poliza
    }
    if (formData.debe === undefined && formData.haber === undefined) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'Debe de ingresar valor en debe o haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) === 0.00 && parseFloat(formData.haber) === 0.00) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'No puede llevar valores a 0.00 en debe o haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) >= 0.01 && parseFloat(formData.haber) >= 0.01) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'No puede llevar valores en debe y haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) >= 0.01) {
        formData.haber = 0.00;
        dispatch(setLoader(true));
        api.post('/polizas/encabezado_polizas/', formData).then((response) => {
            const poliza = localStorage.getItem('idPolizaDep')
            if (poliza === null) {
                response.debe = 0.00;
                response.haber = 0.00;
                dispatch(initializeForm('DepFormTabs', response));
                dispatch(leerDetalle(response.detalle.encabezado));
            } else {
                const editar = window.location.href.includes('editar');
                if (editar) {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('DepFormTabs', response));
                    dispatch(leerDetalleMod(poliza));
                } else {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('DepFormTabs', response));
                    dispatch(leerDetalle(poliza));
                }
            }
        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al registrar la poliza',
                'Error',
                2000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    } else if (parseFloat(formData.haber) >= 0.01) {
        formData.debe = 0.00;
        dispatch(setLoader(true));
        api.post('/polizas/encabezado_polizas/', formData).then((response) => {
            localStorage.removeItem('tipo_poliza');
            const poliza = localStorage.getItem('idPolizaDep')
            if (poliza === null) {
                response.debe = 0.00;
                response.haber = 0.00;
                dispatch(initializeForm('DepFormTabs', response));
                dispatch(leerDetalle(response.encabezado));
            } else {
                const editar = window.location.href.includes('editar');
                if (editar) {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('DepFormTabs', response));
                    dispatch(leerDetalleMod(poliza));
                } else {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('DepFormTabs', response));
                    dispatch(leerDetalle(poliza));
                }
            }

        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al registrar la poliza',
                'Error',
                2000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
}

const eliminarLinea = id => (dispatch) => {
    const poliza = localStorage.getItem('idPolizaDep');
    dispatch(setLoader(true));
    api.eliminar(`poliza_detalle/${id}`).then(() => {
        const editar = window.location.href.includes('editar');
        if (editar) {
            dispatch(leerDetalleMod(poliza));
        } else {
            dispatch(leerDetalle(poliza));
        }
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar linea de poliza',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leerDetalleMod = id => (dispatch) => {
    api.get(`/poliza_detalle/${id}`).then((response) => {
        response.polizaDetalle_polizaEncabezado = response
        dispatch({ type: GUARDAR_POLIZA, lectura: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};

export const registroCuenta = (formato) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.DepFormTabs.values;
    let cuenta = data.cuenta
    let f = formato.split('-');
    let cont = 0;
    let formatoCuenta = '';
    let digito = '';

    if (f.length === 3) {
        let nivel1 = f[0].length
        digito = cuenta.substr(cont, nivel1);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel2 = f[1].length;
        cont = cont + nivel1;
        digito = cuenta.substr(cont, nivel2);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel3 = f[2].length;
        cont = cont + nivel2;
        digito = cuenta.substr(cont, nivel3);
        formatoCuenta = formatoCuenta + digito;

    }

    if (f.length === 4) {
        let nivel1 = f[0].length
        digito = cuenta.substr(cont, nivel1);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel2 = f[1].length;
        cont = cont + nivel1;
        digito = cuenta.substr(cont, nivel2);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel3 = f[2].length;
        cont = cont + nivel2;
        digito = cuenta.substr(cont, nivel3);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel4 = f[3].length;
        cont = cont + nivel3;
        digito = cuenta.substr(cont, nivel4);
        formatoCuenta = formatoCuenta + digito;
    }

    if (f.length === 5) {
        let nivel1 = f[0].length
        digito = cuenta.substr(cont, nivel1);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel2 = f[1].length;
        cont = cont + nivel1;
        digito = cuenta.substr(cont, nivel2);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel3 = f[2].length;
        cont = cont + nivel2;
        digito = cuenta.substr(cont, nivel3);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel4 = f[3].length;
        cont = cont + nivel3;
        digito = cuenta.substr(cont, nivel4);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel5 = f[4].length;
        cont = cont + nivel4;
        digito = cuenta.substr(cont, nivel5);
        formatoCuenta = formatoCuenta + digito;
    }

    if (f.length === 6) {
        let nivel1 = f[0].length
        digito = cuenta.substr(cont, nivel1);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel2 = f[1].length;
        cont = cont + nivel1;
        digito = cuenta.substr(cont, nivel2);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel3 = f[2].length;
        cont = cont + nivel2;
        digito = cuenta.substr(cont, nivel3);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel4 = f[3].length;
        cont = cont + nivel3;
        digito = cuenta.substr(cont, nivel4);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel5 = f[4].length;
        cont = cont + nivel4;
        digito = cuenta.substr(cont, nivel5);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel6 = f[5].length;
        cont = cont + nivel5;
        digito = cuenta.substr(cont, nivel6);
        formatoCuenta = formatoCuenta + digito;
    }
    let idPoliza = localStorage.getItem('idPolizaDep')
    const formData = {
        cuenta: formatoCuenta,
        nombre: data.nombre,
        tipo: data.tipoC,
        naturaleza: data.naturaleza,
        empresa: id_emp[5],
        poliza: idPoliza
    }
    dispatch(setLoaderC(true));
    api.post('/cuentas/', formData).then((response) => {
        if (idPoliza) {
            NotificationManager.success(
                'Cuenta creada' + ' ' + response.cuenta.cuenta + ' ' + response.cuenta.nombre,
                'Exito',
                0
            );
            response.cuenta = '';
            response.nombre = '';
            response.fecha = response.poliza.fecha;
            response.tipo = response.poliza.tipo;
            response.numero_poliza = response.poliza.numero_poliza;
            response.descripcion = response.poliza.descripcion;
            listarCuentas();

        } else {
            NotificationManager.success(
                'Cuenta creada' + ' ' + response.cuenta + ' ' + response.nombre,
                'Exito',
                0
            );
            response.cuenta = '';
            response.nombre = '';
            listarCuentas();
        }

    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoaderC(false));
    });
}

export const reporteCuentas = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let reporte = 'reporte';
    const params = { id, reporte };
    dispatch(setLoaderC(true));;
    api.getPdf('/cuentas', params).then((response) => {
        if (response.headers['content-type'] == 'application/pdf') {
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'reporte_cuentas_contables.pdf';
            window.open(link, '_blank');
            return false;
        }
    }).catch((error) => {

        NotificationManager.error(
            'Ocurrió un error al generar reporte',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoaderC(false));
    });
}

export const leerPeriodoD = (id, id_periodo) => (dispatch) => {
    const params = { id, id_periodo };
    api.get('/periodos/periodo', params).then((response) => {
        dispatch({ type: PERIODO_D, periodoD: response.periodo });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar periodo',
            'Error',
            2000
        );
    })
}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = { id, modulo }
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response) => {
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const parametrosEmpresa = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id }
    dispatch(setLoader(true));
    api.get('/empresa/empresa_parametros', params).then((response) => {
        dispatch({ type: PARAMETRO, datos: response });
        api.get('/empresa/empresa_configuracion_poliza', params).then((datos) => {
            if (datos.validar === 'false'){
                Swal.fire({
                    title: 'Depósito',
                    text: datos.msj,
                    type: 'info',
                    allowOutsideClick: false,
                    confirmButtonText: "Continuar",
                }).then((result) => {
                    if (result.value) {
                        let ruta = `/contabilidad/${id_emp[5]}/config`
                        dispatch(push(ruta));
                    }
                });
            }
            
        }).catch((error)=>{
            
            NotificationManager.error(
                'Ocurrió un error al consultar impuesto',
                'Error',
                8000
            );
        })
    }).catch((error) => {

        NotificationManager.error(
            'Ocurrió un error al consultar parametros',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

let detalle = [];
export const agregarLinea = (periodoD, docu) => (dispatch, getStore) => {
    const data = getStore().form.FormReciboDep.values;
    if (data === undefined) {
        Swal.fire({
            title: 'Depósito',
            text: 'Debe de ingresar fecha de depósito',
            type: 'error',
        })
    }else{
        let fechaI = moment(periodoD.fecha_inicio);
        let fechaF = moment(periodoD.fecha_fin);
        let fecha = moment(data.fechaRegistro);

        if (fecha >= fechaI && fecha <= fechaF) {
            let documento = docu.split('#');
            if (detalle.length > 0) {
                detalle = [];
                detalle.push({
                    id: documento[0].trim(),
                    fecha: documento[2].trim(),
                    numero: documento[4].trim(),
                    monto: documento[1].trim(),
                    abono: documento[1].trim(),
                    td: documento[3].trim(),
                    saldo: parseFloat(documento[1] - parseFloat(documento[1])).toFixed(2)
                })
                data.detalleD = detalle;
                dispatch({ type: TOTAL_DEP, totalDep: documento[1].trim() });
                NotificationManager.success(
                    'Corte de caja ingresado correctamente',
                    'Exito',
                    3000
                );
                dispatch(actualizar());
            } else {
                detalle.push({
                    id: documento[0].trim(),
                    fecha: documento[2].trim(),
                    numero: documento[4].trim(),
                    monto: documento[1].trim(),
                    abono: documento[1].trim(),
                    td: documento[3].trim(),
                    saldo: parseFloat(documento[1] - parseFloat(documento[1])).toFixed(2)
                })
                data.detalleD = detalle;
                dispatch({ type: TOTAL_DEP, totalDep: documento[1].trim() });
                NotificationManager.success(
                    'Corte de caja ingresado correctamente',
                    'Exito',
                    3000
                );
                dispatch(actualizar());
            }
        } else {
            Swal.fire({
                title: 'Depósito',
                text: 'Fecha fuera del periodo',
                type: 'error',
            })
        }
    }
}

const actualizar = () => (dispatch, getStore) => {
    const data = getStore().form.FormReciboDep.values;
    dispatch(initializeForm('FormReciboDep', data));
    dispatch({ type: DETALLE, detalleDocN: data.detalleD });
};

const eliminarLineaAbonoNuevo = (id) => (dispatch, getStore) => {
    const data = getStore().form.FormReciboDep.values;
    let totAbono = parseFloat(data.monto);
    let totDoc = 0.00;

    detalle.forEach((element, index) => {
        if (element.id === id) {
            detalle.splice(index, 1);
        }
    })
    detalle.forEach(item => totDoc += parseFloat(item.abono));
    data.totalAbono = parseFloat(totDoc);
    data.diferencia = parseFloat(totAbono - parseFloat(totDoc));
    data.detalleD = detalle;
    dispatch(actualizar());
};

export const nuevaLineaDocumento = (periodoD) => (dispatch, getStore) => {
    const data = getStore().form.FormReciboDep.values;
    let ruta = window.location.href;
    let id_emp = ruta.split('/');

    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fechaRegistro);
    
    if (fecha >= fechaI && fecha <= fechaF) {
        let docu = data.documento.value;
        let documento = docu.split('#');

        let saldo = parseFloat(documento[1].trim());
        let abono = parseFloat(data.abono);
        let GtotalAbono = parseFloat(data.totalDetalleAbono) + parseFloat(data.abono);
        let totAbono = parseFloat(data.monto);

        let cli = data.cliente_documento;
        if (cli === undefined) {
            cli = data.cliente;
        } else {
            cli = data.cliente_documento.value;
        }
        if (GtotalAbono > totAbono) {
            Swal.fire({
                title: 'Depósito',
                text: 'El abono no puede ser mayor al monto del documento',
                type: 'error',
            })
        } else {
            if (abono === 0 || data.abono === undefined) {
                Swal.fire({
                    title: 'Depósito',
                    text: 'El abono no puede ser 0.00',
                    type: 'error',
                })
            } else {
                if (abono > saldo) {
                    Swal.fire({
                        title: 'Depósito',
                        text: 'El abono es mayor al saldo del documento',
                        type: 'error',
                    })
                } else {
                    const formData = {
                        fecha_registro: data.fechaRegistro,
                        descripcion: data.descripcion,
                        tipo: 'DP',
                        td: documento[2].trim(),
                        idDoc: documento[0].trim(),
                        abono: parseFloat(data.abono),
                        saldo: parseFloat(documento[1].trim()) - parseFloat(data.abono),
                        empresa: id_emp[5],
                        cliente: cli,
                        detalle: data.id,
                        total: data.total,
                        cuenta: data.cuenta_bancaria,
                        idPoliza: data.poliza ? data.poliza : ''
                    }

                    dispatch(setLoader(true));
                    api.post('/ingresos/', formData).then((response) => {

                        response.fechaRegistro = response.documento.fecha_registro;
                        response.numero = response.documento.numero;
                        response.descripcion = response.documento.descripcion;
                        response.cliente = response.documento.cliente
                        response.clienteNombre = response.documento.clienteN
                        response.total = response.documento.total;
                        response.totalAbono = response.totalA;
                        response.id = response.documento.id
                        response.diferencia = parseFloat(response.documento.total) - parseFloat(response.totalA)
                        if (response.documento.poliza){
                            dispatch(leerDetalleMod(response.documento.poliza));
                        }
                        dispatch(leerDetalleDep(response.documento.id));

                        NotificationManager.success(
                            'Documento agregado correctamente',
                            'Exito',
                            3000
                        );
                    }).catch((error) => {
                        NotificationManager.error(
                            error.msj,
                            'Error',
                            0
                        );
                    }).finally(() => {
                        dispatch(setLoader(false));
                    });
                }
            }
        }
    } else {
        Swal.fire({
            title: 'Depósito',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })
    }
}

const eliminarDep = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`ingresos/${id}`).then(() => {
        dispatch(listar(1));
        NotificationManager.success(
            'Depósito borrado correctamente',
            'Exito',
            3000
        );
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar documento',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const tipoCambio = (periodoD, dolares) => (dispatch, getStore) => {
    const data = getStore().form.FormDep.values;
    if (data === undefined) {
        Swal.fire({
            title: 'Depósito',
            text: 'Debe de ingresar datos del depósito',
            type: 'error',
        })
    } else {
        let fechaI = moment(periodoD.fecha_inicio);
        let fechaF = moment(periodoD.fecha_fin);
        let fecha = moment(data.fechaRegistro)
        if (fecha >= fechaI && fecha <= fechaF) {
            if (dolares === 'S') {
                if (data.fechaRegistro === undefined) {
                    Swal.fire({
                        title: 'Depósito',
                        text: 'Debe de ingresar fecha del depósito',
                        type: 'error',
                    })
                } else {
                    let fecha = data.fechaRegistro;
                    let ruta = window.location.href;
                    let id_emp = ruta.split('/');
                    let idEmpre = id_emp[5];
                    const params = { fecha, idEmpre }
                    dispatch(setLoaderC(true));
                    return api.get('/tipo_cambio/tipo_cambio_fecha/', params).then((response) => {
                        if (parseFloat(response) === 0) {
                            Swal.fire({
                                title: 'No hay tipo de cambio del dia' + ' ' + moment(fecha).format("DD/MM/YYYY"),
                                html:
                                    '<div class="col-sm-12 mt-4">' +
                                    '<h4 style="color: white;">¿Desea ingresarlo?</h4>' +
                                    '</div>',
                                type: 'info',
                                background: "black",
                                showCancelButton: true,
                                confirmButtonText: 'Si',
                                cancelButtonText: 'No',
                                confirmButtonColor: "#4040ff",
                                cancelButtonColor: "#646464",
                                reverseButtons: true,
                                customClass: {
                                    title: 'sweet_titleImportant',
                                },
                            }).then((result) => {
                                if (result.value) {
                                    Swal.fire({
                                        title: 'Ingrese tipo de cambio',
                                        html:
                                            '<div class="col-sm-12 mt-3">' +
                                            '<h4 style="color: white">' + moment(fecha).format("DD/MM/YYYY") + '</h4>' +
                                            '<input type="number" min="1" id="cambio" class="swal2-input form-control">' +
                                            '</div>',
                                        type: 'info',
                                        background: "black",
                                        showCancelButton: true,
                                        confirmButtonText: 'Si',
                                        cancelButtonText: 'No',
                                        confirmButtonColor: "#4040ff",
                                        cancelButtonColor: "#646464",
                                        reverseButtons: true,
                                        customClass: {
                                            title: 'sweet_titleImportant',
                                        },
                                    }).then((result) => {
                                        if (result.value) {
                                            let tcambio = document.getElementById('cambio').value
                                            const formData = {
                                                fechaT: fecha,
                                                tipoCambio: tcambio,
                                                empresa: id_emp[5],
                                                tipo: 'manual'
                                            }
                                            api.post('/tipo_cambio/', formData).then(response => {
                                                NotificationManager.success(
                                                    'Tipo de cambio guardado',
                                                    'Exito',
                                                    4000
                                                );

                                            }).catch((error) => {
                                                NotificationManager.error(
                                                    error.msj,
                                                    'Error',
                                                    0
                                                );
                                            })
                                        }
                                    });
                                }
                            });
                        } else {
                            return response;
                        }
                    }).catch((error) => {
                        NotificationManager.error(
                            'Ocurrió un error al consultar tipo de cambio',
                            'Error',
                            2000
                        );
                    }).finally(() => {
                        dispatch(setLoaderC(false));
                    });
                }
            }
        } else {
            Swal.fire({
                title: 'Depósito',
                text: 'Fecha fuera del periodo',
                type: 'error',
            })
        }
    }
}

export const buscar = (page = 1) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let idEmpre = id_emp[5];
    const data = getStore().form.FormDepositosList.values;
    let fechaI = data.fechaInicial;
    let fechaF = data.fechaFinal;
    let numero = data.numero;
    let tipo = 'DP';
    let tipoB = 'buscar';
    let cuenta = data.cuenta_bancaria ? data.cuenta_bancaria.value : '';
    const params = { page, idEmpre, fechaI, fechaF, cuenta, numero, tipo, tipoB };
    let cont = 1;
    let pl = localStorage.getItem("listDP");
    localStorage.setItem("listDP", cont);
    if (page === 1 && pl === null) {
        dispatch(setLoader(true));
    }
    api.get('/ingresos', params).then((response) => {
        dispatch({ type: LISTADO_DEP, data: '' });
        dispatch({ type: LISTADO_DEP_BUSCAR, busD: response });
        dispatch(setPage(page));
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar depósitos',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const vaciar = () => (dispatch) => {
    detalle = [];
    dispatch(initializeForm('FormDep'));
    dispatch({ type: DETALLE, detalleDocN: detalle });
};

export const listarCuentasBancarias = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    let idCuenta = '';
    api.get('/cuenta_banco', params).then((response) => {
        if (response.length === 1){
            response.forEach(item => {
                idCuenta = item.id
            })
            dispatch({ type: LISTADO_CUENTAS, cuenta: idCuenta });
        }else{
            dispatch({ type: LISTADO_CUENTAS, cuenta: '' });
        }
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar cuentas bancarias',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const actions = {
    listar,
    registroDep,
    listarCuentas,
    listarCC,
    leer,
    listarCortes,
    listarNiveles,
    leerDetalle,
    leerDetalleMod,
    leerDetalleDocumentos,
    eliminarDocumento,
    confirmarPoliza,
    registroCuenta,
    reporteCuentas,
    registroPolizaDetalleSCC,
    registroPolizaDetalle,
    leerCuentaB,
    eliminarLinea,
    getModulo,
    leerPeriodoD,
    parametrosEmpresa,
    agregarLinea,
    eliminarLineaAbonoNuevo,
    nuevaLineaDocumento,
    eliminarDep,
    tipoCambio,
    listarCuentasBancarias,
    buscar,
    vaciar
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_DEP]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [LISTADO_DEP_BUSCAR]: (state, { busD }) => {
        return {
            ...state,
            busD,
        };
    },
    [GUARDAR_DEP]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
    [DETALLE_DOCUMENTOS]: (state, { detalleDoc }) => {
        return {
            ...state,
            detalleDoc,
        };
    },
    [LISTADO_DOCUMENTOS]: (state, { doc }) => {
        return {
            ...state,
            doc,
        };
    },
    [DETALLE_POLIZA]: (state, { detalle }) => {
        return {
            ...state,
            detalle,
        };
    },
    [PERIODO]: (state, { periodoS }) => {
        return {
            ...state,
            periodoS,
        };
    },
    [NIVEL]: (state, { nivel }) => {
        return {
            ...state,
            nivel,
        };
    },
    [LOADERC]: (state, { loaderC }) => {
        return {
            ...state,
            loaderC,
        };
    },
    [GUARDAR_POLIZA]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
    [CUENTA_BANCARIA]: (state, { cuentaBan }) => {
        return {
            ...state,
            cuentaBan,
        };
    },
    [DETALLE_DEP]: (state, { detalleDep }) => {
        return {
            ...state,
            detalleDep,
        };
    },
    [PERIODO_D]: (state, { periodoD }) => {
        return {
            ...state,
            periodoD,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [PARAMETRO]: (state, { datos }) => {
        return {
            ...state,
            datos,
        };
    },
    [DOCUMENTOS]: (state, { list_doc }) => {
        return {
            ...state,
            list_doc,
        };
    },
    [DETALLE]: (state, { detalleDocN }) => {
        return {
            ...state,
            detalleDocN,
        };
    },
    [LISTADO_CUENTAS]: (state, { cuenta }) => {
        return {
            ...state,
            cuenta,
        };
    },
    [TOTAL_DEP]: (state, { totalDep }) => {
        return {
            ...state,
            totalDep,
        };
    },
};

export const initialState = {
    loader: false,
    doc: [],
    nivel: [],
    detalle: [],
    lectura: {
        polizaDetalle_polizaEncabezado: []
    },
    cuentaBan: [],
    detalleDep: [],
    loaderC: false,
    modulo: [],
    list_doc: [],
    detalleDocN: [],
    periodoD: [],
    datos: [],
    data: [],
    periodoS: null,
    page: 1,
    cuenta: [],
    totalDep: 0.00,
};

export default handleActions(reducers, initialState)