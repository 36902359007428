import React, { Component, useState } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import { validate, validators } from 'validate-redux-form';
import {
  renderField,
} from "../Utils/renderField/renderField";
const valEmp = __CONFIG__.empresa;

class EstablecimientoForm extends Component {

  render() {
    const { handleSubmit, crear } = this.props;
    const editar = window.location.href.includes('editar');
    let titulo = editar ? 'Modificar Establecimiento' : 'Crear Establecimiento';
    let disabled = false;
    if (crear === false && editar === false) {
      disabled = true;
      titulo = 'Ver Establecimiento'
    }
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let autoFocus = true;
    return (
      <div>
        <form onSubmit={handleSubmit}>
          <br />
          <h3 className="p-2 bg-secondary title">{titulo}</h3>
          <div className="mb-4 card card-small text-white bg-dark mb-3">
            <div className="p-0 pt-3 d-flex flex-column flex-md-row">
              <div className="d-flex flex-column flex-1 mx-4">
                  <div className='row mb-2'>
                    <div className='col-3'>
                      <Field
                        name="identificador"
                        placeholder="Identificador"
                        className="form-control"
                        component={renderField}
                        disabled={true}
                        msj="Identificador"
                      />
                    </div>
                    <div className='col-6'>
                      <Field
                        name="razon_social"
                        placeholder="Razón social"
                        className="form-control"
                        component={renderField}
                        disabled={true}
                        msj="Razón social"
                      />
                    </div>
                  </div>
                <div className='row mb-2'>
                  <div className='col-3'>
                    <Field
                      autoFocus={autoFocus}
                      name="codigo"
                      placeholder="Codigo"
                      className="form-control"
                      component={renderField}
                      disabled={disabled}
                      msj="Codigo"
                    />
                  </div>
                </div>
                <div className='row mb-2'>
                  <div className='col-6'>
                    <Field
                      name="nombre_comercial"
                      placeholder="Nombre Comercial"
                      className="form-control"
                      component={renderField}
                      disabled={disabled}
                      msj="Nombre Comercial"
                    />
                  </div>
                </div>
                <div className='row mb-2'>
                  <div className='col-6'>
                    <Field
                      name="direccion"
                      placeholder="Dirección"
                      className="form-control"
                      component={renderField}
                      disabled={disabled}
                      msj="Direccion"
                    />
                  </div>
                </div>
                <div className='row mb-2'>
                  <div className='col-3'>
                    <Field
                      id="telefono"
                      name="telefono"
                      placeholder="Telefono"
                      className="form-control"
                      component={renderField}
                      disabled={disabled}
                      msj="Telefono"
                    />
                  </div>
                </div>
                <div className='d-flex flex-row mt-3'>
                  {disabled == false &&
                    <button
                      className={`btn mr-2 mb-3 ${editar ? 'btn-primary' : 'btn-primary'}`}
                      type='submit'
                    >
                      {editar ? 'Modificar' : 'Guardar'}
                    </button>
                  }
                  {valEmp === 'fage' &&
                    <a
                      href={`/#/lfg/ajustes/${id_emp[6]}/establecimientos`}
                      className='btn btn-secondary mb-3'
                    >
                      Regresar
                    </a>
                  }
                  {valEmp === '' &&
                    <a
                      href={`/#/ajustes/${id_emp[5]}/establecimientos`}
                      className='btn btn-secondary mb-3'
                    >
                      Regresar
                    </a>
                  }
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default reduxForm({
  form: 'FormEstablecimiento',
  validate: (data) => {
    return validate(data, {
      empresa: validators.exists()('Seleccione una empresa'),
      codigo: validators.exists()('Este campo es requerido'),
      nombreComercial: validators.exists()('Este campo es requerido'),
    });
  },
})(EstablecimientoForm);
