import React, { useEffect, useState } from 'react';
import Formulario from './OrdenCompraForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";

function OrdenCompra(props) {
    const { loader, listarProductos, periodoD, modulo,
        listarProveedores, detalleProducto, monedas, tipoCambio,
        detalleOrden, registroOrden, agregarLinea, numeroOrden, detalleOrd,
        lectura, eliminarLineaProductoNuevo,
    } = props;        
        
    useEffect(() => {
        const { match, leer, getModulo, leerPeriodoD, 
            parametrosEmpresa, listarMonedas, limpiarOrden, numeroOrdenCompra } = props;
        const id = match.params.id;
        if (id) {
            setCrear(false);
            leer(id);
        }
        let ruta = window.location.href;
        let periodo = ruta.split('/');
        leerPeriodoD(periodo[5], periodo[7]);
        getModulo(periodo[5], periodo[4]);
        parametrosEmpresa();
        listarMonedas();
        limpiarOrden();
        numeroOrdenCompra();
    }, [])

    const [crear, setCrear] = useState(true);

    const editar = window.location.href.includes('editar');
    let titulo = 'Crear orden de compra';
    let disabled = false;
    if (crear === false && editar === false) {
        disabled = true;
        titulo = 'Ver orden de compra'
    }

    return (
        <LoadMask loading={loader} dark>
            <Formulario
                registroOrden = {registroOrden}
                listarProductos = {listarProductos}
                crear = {crear}
                titulo = {titulo}
                editar = {editar}
                periodoD = {periodoD}
                modulo = {modulo}
                bloqueo = {disabled}
                listarProveedores = {listarProveedores}
                detalleProducto = {detalleProducto}
                monedas = {monedas}
                tipoCambio = {tipoCambio}
                detalleOrden = {detalleOrden}
                agregarLinea = {agregarLinea}
                numeroOrden = {numeroOrden}
                detalleOrd = {detalleOrd}
                lectura = {lectura}
                eliminarLineaProductoNuevo = {eliminarLineaProductoNuevo}
            />
        </LoadMask>
    );
}

export default OrdenCompra;