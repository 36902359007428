import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/registro_sanitario/pais';
import Pais from './PaisList';


const ms2p = (state) => {
  return {
    ...state.rs_pais,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Pais);
