import React, { Component } from 'react';
import Grid from '../../Utils/GridVDocumento';
import { standardActions } from '../../Utils/GridVDocumento/StandardActions';

class DetalleListadoPreciosList extends Component{
    
    render(){
        const { loader, eliminarLineaProducto,  detalle } = this.props;
        return(
            <React.Fragment>
                {detalle &&
                <Grid 
                    hover 
                    striped 
                    data={detalle} 
                    loading={loader}
                    headerStyle={ { background: 'black' } } 
                >
                    <TableHeaderColumn
                        isKey
                        dataField="sku"
                        dataAlign='left'
                        width="300"
                        headerAlign="center"
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        SKU
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="productoN"
                        dataAlign='left'
                        headerAlign="center"
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Producto
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="precio"
                        dataAlign='right'
                        width="200"
                        headerAlign="center"
                        dataFormat={(cell, row)=>{
                            return new Intl.NumberFormat("en-US",{minimumFractionDigits: 6}).format(cell);
                        }}
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Precio
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        thStyle={
                        {color: 'white'}}
                        dataField="id"
                        width="150"
                        dataAlign="center"
                        dataSort
                        dataFormat={standardActions({
                            eliminar: eliminarLineaProducto })} 
                    >
                        Acciones
                    </TableHeaderColumn>
                </Grid>
                }
            </React.Fragment>
        );
    }
}

export default DetalleListadoPreciosList;
