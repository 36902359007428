import React from 'react';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import * as BsIcons from "react-icons/bs";

export const SidebarData = [
    {
        title: 'Inicio',
        path: '/nomina',
        icon: <BsIcons.BsGrid1X2Fill />,
    },
    {
        title: 'Parámetros',
        path: '#',
        icon: <FaIcons.FaTasks />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav: [
            {
                title: 'Periodos',
                path: '/nomina',
                path_s: 'periodos',
                icon: <BsIcons.BsFillCalendarDateFill />,
            },
            {
                title: 'Tipo de descuento',
                path: '/nomina',
                path_s: 'tipo_descuento',
                icon: <FaIcons.FaList />,
            },
            {
                title: 'Frecuencia de pago',
                path: '/nomina',
                path_s: 'frecuencia_pago',
                icon: <FaIcons.FaListUl />,
            },
        ]
    },
    {
        title: 'Transacciones',
        path: '#',
        icon: <FaIcons.FaBook />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav: [
            {
                title: 'Periodo nomina',
                path: '/nomina',
                path_s: 'seleccionar_periodo_nomina',
                icon: <BsIcons.BsFillCalendarDateFill />,
            },
            {
                title: 'Nomina de sueldo',
                path: '/nomina',
                path_s: 'seleccionar_nomina_periodo',
                icon: <FaIcons.FaIdBadge />,
            },
            {
                title: 'Otros ingresos',
                path: '/nomina',
                path_s: 'seleccionar_nomina_otros_periodo',
                icon: <FaIcons.FaIdBadge />,
            },
            {
                title: 'Horas extras',
                path: '/nomina',
                path_s: 'seleccionar_periodo_horas_extras',
                icon: <FaIcons.FaStopwatch />,
            },
            {
                title: 'Descuentos',
                path: '/nomina',
                path_s: 'seleccionar_periodo_descuentos',
                icon: <FaIcons.FaMinus />,
            },
            {
                title: 'Cierre de mes',
                path: '/nomina',
                path_s: 'seleccionar_cierre_mes',
                icon: <FaIcons.FaLock  />,
            },
        ]
    },
    {
        title: 'Prestaciones',
        path: '#',
        icon: <FaIcons.FaBook />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav: [
            {
                title: 'Calculo Bono 14',
                path: '/nomina',
                path_s: 'calcular_bono',
                icon: <BsIcons.BsFillCalendarDateFill />,
            },
        ]
    },
    {
        title: 'Informes',
        path: '/nomina/informes',
        icon: <FaIcons.FaClipboard />,
    }
]
