import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/produccion/ingreso';
import Ingreso from './IngresoList';


const ms2p = (state) => {
  return {
    ...state.ingreso_prd,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Ingreso);
