import React from "react";
import "../../../../../style/Finanzas/FinanzasHome.css";

function EstadosFinancierosHome(props) {
    const {} = props;

    return (
        <React.Fragment>
            <h3 style={{ color: "#bd0034" }}>Estados financieros</h3>
        </React.Fragment>
    );
}

export default EstadosFinancierosHome;
