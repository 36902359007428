import React, { useState } from "react";
import styled from "styled-components";
import {
    FicComboBox,
    FicDatePicker,
    FicInput,
    FicTextArea,
    FicImageDropzone,
    FicNumber,
} from "../../../Utils/FormInputComponents";
import Banner from "../../common/Banner";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { validar } from "../../../components/ValidarNit";
import GenBtn from "../../../components/GenBtn";
import InputsContainer from "../../common/InputsContainer";
import { crear, getPatologia, getPatologias, getInforme } from "./actions";
import { getPeriodoDates } from "../../utils";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import { withRouter } from "react-router";
import { useEffect } from "react";
import useUpdateEffect from "../../../../../hooks/useUpdateEffect";
import Overlay from "../../common/Overlay";
import { today, getLocalTimeZone, isEqualMonth } from "@internationalized/date";
import OptionsBar from "../../common/OptionsBar";

const schema = yup.object({
    patologia: yup.mixed().required("Campo requerido"),
    fecha_entrega: yup.mixed().required("Campo requerido"),
    diagnostico: yup.string().required("Campo requerido"),
});

function Crear({ history, ...props }) {
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingSavedData, setIsLoadingSavedData] = useState(false);
    const { startOfMonthPeriodo, endOfMonthPeriodo } = getPeriodoDates();
    const { control, reset, handleSubmit, watch, setValue } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: {
            patologia: null,
            fecha_entrega: today(getLocalTimeZone()).toString(),
            informacion:
                "SE RECIBE UNA LAMINILLA FIJADA EN ALCOHOL LA CUAL SE SOMETE A TINCIÓN CON EL MÉTODO DE PAPANICOLAU.",
            calidad_muestra: "ADECUADO",
            indice_maduracion: "",
            efecto_hormonal: "CELULAS BASALES Y PARABASALES",
            inflamatorio: "NEGATIVO",
            microorganismos: "NEGATIVO",
            reaccion_reparativa: "",
            diagnostico: "TELEATROFIA",
        },
    });
    const { control: dataControl, reset: dataReset } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: {
            nombre: "",
        },
    });

    async function onSubmit(data) {
        setIsLoading(true);
        data.patologia = patologia.value;
        let response = await crear(data);
        await getInforme(response.id);
        setIsLoading(false);
        reset();
    }

    const patologia = watch("patologia");
    async function getPatologiaData() {
        setIsLoadingSavedData(true);
        const formData = await getPatologia(patologia.value);
        if (!formData || formData.length === 0) {
            setIsLoadingSavedData(false);
            return;
        }
        formData.afiliacion = formData.paciente.afiliacion;
        formData.paciente = formData.paciente.nombre;
        formData.igss && (formData.igss = formData.igss.nombre);
        formData.clinica = formData.clinica.nombre;
        setIsLoadingSavedData(false);
        return formData;
    }

    useUpdateEffect(() => {
        if (isEqualMonth(startOfMonthPeriodo, today(getLocalTimeZone()))) {
            setValue("fecha_entrega", today(getLocalTimeZone()).toString());
        } else {
            setValue("fecha_entrega", null);
        }
    }, [startOfMonthPeriodo]);

    useUpdateEffect(() => {
        if (patologia) {
            getPatologiaData().then((data) => {
                dataReset(data);
            });
        }
    }, [patologia]);

    return (
        <React.Fragment>
            <OptionsBar />
            <Banner>Informe de patologia (citologia)</Banner>
            <GenBtn
                onPress={() => history.goBack()}
                style={{ margin: "5px 0" }}
            >
                Regresar
            </GenBtn>
            <LoadMask loading={isLoadingSavedData} color="#bd0034" blur={true}>
                <form style={{ position: "relative" }} onSubmit={() => {}}>
                    <Overlay
                        isActive={!patologia}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            minWidth: "460px",
                            textAlign: "center",
                        }}
                    >
                        <h1 style={{ color: "#bd0034" }}>
                            Informacion de la patologia
                        </h1>
                        <small>(Seleccione una patologia para mostrar)</small>
                    </Overlay>
                    <InputsContainer>
                        <FicInput
                            control={dataControl}
                            name="paciente"
                            label="Paciente"
                            isDisabled
                        />
                        <FicInput
                            control={dataControl}
                            name="edad"
                            label="Edad"
                            isDisabled
                        />
                        <FicInput
                            control={dataControl}
                            name="clinica"
                            label="Clinica"
                            isDisabled
                        />
                        <FicInput
                            control={dataControl}
                            name="medico"
                            label="Medico"
                            isDisabled
                        />
                        <FicDatePicker
                            control={dataControl}
                            name="fecha_recibido"
                            label="Recibido"
                            isDisabled
                        />
                    </InputsContainer>
                    {/* <FicImageDropzone
                        control={control}
                        name="laminas"
                        label="Laminas"
                        isDisabled
                    /> */}
                </form>
            </LoadMask>
            <LoadMask loading={isLoading} color="#bd0034" blur={true}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FicComboBox
                        control={control}
                        name="patologia"
                        label="Patologia"
                        loadOptions={getPatologias}
                        autoFocus
                    />
                    <InputsContainer>
                        <FicNumber
                            control={control}
                            label="Costo del examen"
                            name="costo"
                            formatOptions={{ minimumFractionDigits: 2 }}
                        />
                        <FicDatePicker
                            control={control}
                            name="fecha_entrega"
                            label="Fecha de entrega"
                            minValue={startOfMonthPeriodo}
                            maxValue={endOfMonthPeriodo}
                        />
                        <FicNumber
                            control={control}
                            label="Costo del examen"
                            name="costo"
                            formatOptions={{ minimumFractionDigits: 2 }}
                        />
                        <FicTextArea
                            control={control}
                            name="informacion"
                            label="Informacion"
                        />
                        <FicInput
                            control={control}
                            name="calidad_muestra"
                            label="Calidad de la muestra"
                        />
                        <FicInput
                            control={control}
                            name="indice_maduracion"
                            label="Indice de maduracion"
                        />
                        <FicInput
                            control={control}
                            name="efecto_hormonal"
                            label="Efecto hormonal predominante"
                        />
                        <FicInput
                            control={control}
                            name="inflamatorio"
                            label="Inflamatorio"
                        />
                        <FicInput
                            control={control}
                            name="microorganismos"
                            label="Microorganismos"
                        />
                        <FicInput
                            control={control}
                            name="reaccion_reparativa"
                            label="Reaccion reparativa"
                        />
                        <FicInput
                            control={control}
                            name="diagnostico"
                            label="Diagnostico"
                        />
                    </InputsContainer>
                    <GenBtn style={{ margin: "1rem 0" }} type="submit">
                        Guardar
                    </GenBtn>
                </form>
            </LoadMask>
        </React.Fragment>
    );
}
export default withRouter(Crear);
