import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { renderField } from '../Utils/renderField';
const valEmp = __CONFIG__.empresa;

const emailValidation = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
  'Dirección de correo electrónico no válido' : undefined

const UsuarioForm = (props) => {
    const { handleSubmit, crear } = props;
    const editar = window.location.href.includes('editar');
    let titulo = editar ? 'Modificar Usuario' : 'Crear Usuario';
    let disabled = false;
    if (crear === false && editar === false) {
      disabled = true;
      titulo = 'Ver Usuario'
    }
    let autoFocus = true;
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    return (
        <div>
        <form onSubmit={handleSubmit}>
            <br />
            <h3 className="p-2 bg-secondary title">{titulo}</h3>
            <div className="mb-4 card card-small text-white bg-dark mb-3">
                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                    <div className="d-flex flex-column flex-1 mx-4">

                        <div className='row mb-2 w-75'>
                            <div className='col-6'>
                                <Field
                                    autoFocus={autoFocus}
                                    name="first_name"
                                    component={renderField}
                                    placeholder="Nombre"
                                    type="text"
                                    disabled={disabled}
                                    className="form-control"
                                    msj="Nombre"
                                />
                            </div>
                            <div className='col-6'>
                                <Field
                                    name="last_name"
                                    placeholder="Apellido"
                                    component={renderField}
                                    type="text"
                                    disabled={disabled}
                                    className="form-control"
                                    msj="Apellido"
                                />
                            </div>

                        </div>
                        <div className="row mb-2  w-75">
                            <div className='col-6'>
                                <Field
                                    name="username"
                                    placeholder="Correo electrónico"
                                    component={renderField}
                                    type="email"
                                    disabled={disabled}
                                    validate={emailValidation}
                                    className="form-control"
                                    msj="Correo electrónico"
                                />
                            </div>
                        </div>
                        <div className='d-flex flex-row mt-3'>
                            {disabled == false &&
                                <button
                                className={`btn mr-2 mb-3 ${editar ? 'btn-primary' : 'btn-primary'}`}
                                type='submit'
                                >
                                {editar ? 'Modificar' : 'Guardar'}
                                </button>
                            }
                            {valEmp === 'fage' &&
                                <a
                                    href={`/#/lfg/ajustes/${id_emp[6]}/usuarios`}
                                    className='btn btn-secondary mb-3'
                                >
                                    Regresar
                                </a>
                            }
                            {valEmp === '' &&
                                <a
                                    href={`/#/ajustes/${id_emp[5]}/usuarios`}
                                    className='btn btn-secondary mb-3'
                                >
                                    Regresar
                                </a>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    );
};

export const matchPassword = (pass, confirm) => validatorFromFunction(value => {
    return pass === confirm;
});

export default reduxForm({
    form: 'FormUsuario', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            confirmPassword: combine(
                validators.exists()('Este campo es requerido'),
                matchPassword(data.password, data.confirmPassword)()('Las contraseñas no coinciden')
            ),
            username: validators.exists()('Este campo es requerido'),
            first_name: validators.exists()('Este campo es requerido'),
            last_name: validators.exists()('Este campo es requerido'),
            password: validators.exists()('Este campo es requerido'),
        });
    },
})(UsuarioForm);
