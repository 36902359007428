import React, { Component } from 'react';
import ProfileForm from "./ProfileForm";
import LoadMask from "../../Utils/LoadMask/LoadMask";

class Profile extends Component {

    constructor(props) {
        super(props);
        this.state = {avatar: null};
    }
    
    componentDidMount = () => {
        const { getMe } = this.props;
        getMe();
    }

    setAvatar = (avatar) => {
        this.setState({avatar});
    };
    
    update = (data) => {
        const { update } = this.props;
        update({...data, avatar: null}, [{"file": this.state.avatar, "name": "avatar"}]);
    };

    render() {
        const { me, loader } = this.props;
        
        return (
            <LoadMask loading={loader} dark>
                <ProfileForm 
                    onSubmit={this.update} 
                    me={me} 
                    setAvatar={this.setAvatar} 
                />
            </LoadMask>
            
        );
    }
}

export default Profile;

