import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import NavbarModulo from '../../components/NavbarModulo';
import CuentasList from './CuentaContableList';

import {
    AsyncSelectField
} from "../../Utils/renderField/renderField";

function CuentaContableForm(props) {
    const {
        modulo,
        listarCuentas,
        data,
        agregarCuenta,
        eliminarCuenta
    } = props;

    let ruta = window.location.href;
    let id_empresa = ruta.split('/');
    
    let titulo = 'Agregar cuentas contables sin incluir';
    
    return (
        <div>
            <NavbarModulo
                modulo={modulo}
                titulo={titulo}
            />
            <br />
            <div className="mb-4 card card-small text-white bg-dark mb-3">
                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                    <div className="d-flex flex-column flex-1 mx-4">
                        <div className='row mb-3'>
                            <div className='col-6'>
                                <Field
                                    autoFocus={true}
                                    name="cuenta"
                                    placeholder="Cuenta contable..."
                                    className="form-control-find"
                                    component={AsyncSelectField}
                                    loadOptions={listarCuentas}
                                    msj="Cuenta contable"
                                />
                            </div>
                        </div>
                        <div className='d-flex flex-row mt-2'>
                            <button
                                className='btn mr-2 mb-3 btn-primary'
                                onClick={() => agregarCuenta()}
                            >
                                Agregar cuenta
                            </button>
                            <a
                                href = {`/#/produccion/${id_empresa[5]}`}
                                className='btn btn-secondary mb-3'
                            >
                                Regresar
                            </a>
                        </div>
                        <br/>
                        <CuentasList
                            data = {data}
                            eliminarLinea = {eliminarCuenta}
                        />
                        <br/>
                        <br/>
                    </div>
                </div>
            </div>
        </div>
    );
}
    
CuentaContableForm.propTypes = {
    periodoD: PropTypes.object,
    modulo: PropTypes.object,
};
    
    
export default reduxForm({
    form: 'FormCuentaContablePRD',
})(CuentaContableForm);

