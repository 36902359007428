import { handleActions } from 'redux-actions';
import { NotificationManager } from "react-notifications";
import { api } from "api";

const LOADER = 'LOADER';
const MODULO = 'MODULO';
const PERIODO_D = 'PERIODO_D';
const LISTADO = 'LISTADO';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const subirCompras = (data={},attachments=[]) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    
    const formData = {
        empresa: id_emp[5],
        periodo: id_emp[7],
    }
    dispatch(setLoader(true));
    api.postAttachments('/visitador_medico/compras/compras_excel/', formData, attachments).then(() => {
        NotificationManager.success(
            'Compras creadas',
            'Exito',
            8000
        );
    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            6000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
    
}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        if (error.status === 401){
            NotificationManager.error(
              error.body.msj,
              'Error',
              0
            );
        }
        if (error.status === 400){
            NotificationManager.error(
              'Ocurrió un error en buscar modulo',
              'Error',
              0
            );
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const leerPeriodoD = (id, id_periodo) => (dispatch) => {
    const params = { id, id_periodo };
    api.get('/periodos/periodo', params).then((response) => {
        dispatch({ type: PERIODO_D, periodoD: response.periodo });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar periodo',
            'Error',
            2000
        );
    })
}

export const listar = (periodo) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let params = { id, periodo };
    dispatch(setLoader(true));
    api.get('/visitador_medico/compras', params).then((response)=>{
        dispatch({type: LISTADO, data: response })
    }).catch(error=>{
        NotificationManager.error(
            'Ocurrió un error al listar compras',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const actions = {
    subirCompras,
    getModulo,
    leerPeriodoD,
    listar
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [PERIODO_D]: (state, { periodoD }) => {
        return {
            ...state,
            periodoD,
        };
    },
    [LISTADO]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
};

export const initialState = {
    loader: false,
    modulo: [],
    periodoD: [],
    data: [],
};

export default handleActions(reducers, initialState)