import { MESES } from "../../../../../constants/date";
import { parseDate } from "@internationalized/date";

export function processFormData(data) {
    data.estado = data.estado ? "A" : "C";
    const mes = data.fecha_inicio.month;
    data.periodo = `${MESES[mes]} ${data.fecha_inicio.year}`;
    data.fecha_inicio = data.fecha_inicio.toString();
    data.fecha_fin = data.fecha_fin.toString();
    return data;
}
