import React, { useState, useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import Tabs, { TabPane } from 'rc-tabs';
import PropTypes from 'prop-types';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import Documento from './ImportacionForm';
import Gastos from './ImportacionFormGastosLocales';
import LoadMask from "../../Utils/LoadMask/LoadMask";
import Poliza from "../../components/PolizaFormA";
import Modal from '../../components/ModalPoliza';
import ModalCuenta from '../../components/Modal';
import styled from 'styled-components';
import TablaSCC from './ImportacionGastosDetalle';
import TablaSCCVer from './ImportacionGastosVerDetalle';
import NavbarModulo from '../../components/NavbarModulo';
import Inventario from './InventarioForm';
import DetalleInventario from './DetalleInventario';
import DetalleInventarioVer from './DetalleInventarioVer';
import DetalleInventarioFarmVer from './DetalleInventarioFarmVer';
import DetalleInventarioFarm from './DetalleInventarioFarm';

import {
    renderField,
    SelectField,
    AsyncSelectField,
    renderCurrencyPoliza,
    renderNumber
} from "../../Utils/renderField/renderField";

function ImportacionFormTab(props) {
    const {
        crear, lectura, tipos, datos, listarProductos, registroProductoInventario, periodoD,
        listarProveedores, loader, detalle, eliminarLinea, eliminarDocumento, listaBodegas,
        listarCuentas, listarCC, nivel, registroCuenta, listarProveedoresGastos, detalleProducto,
        reporteCuentas, loaderC, prov, registroImportacion, registroProveedor, impuesto, modulo,
        registroPolizaDetalle, confirmarPoliza, registroPolizaDetalleSCC, lectura_data,
        detalleLiq, detalleImp, registroCompra, modificarImportacion, registroProveedorExt,
        eliminarDocumentoImportacion, tipoCambio, eliminarLineaProducto, listaP } = props;

    useEffect(() => {
        const { match, leer, listarTiposDocu, 
            leerDetalle, listarNiveles, leerDetalleLiquidacion,
            leerDetalleImportacion, parametrosEmpresa, leerDetalleInventario,
            listarBodegas, listarImpuestos, leerPeriodoD, getModulo, listarPaises } = props;
        const id = match.params.id;
        if (id) {
            setCrear(false);
            leer(id);
        }
        let ruta = window.location.href;
        let periodo = ruta.split('/');
        leerPeriodoD(periodo[5], periodo[7]);
        getModulo(periodo[5], periodo[4]);
        listarTiposDocu();
        listarNiveles();
        parametrosEmpresa();
        listarImpuestos();
        listarBodegas();
        leerDetalle('00000000-0000-0000-0000-000000000000');
        leerDetalleImportacion('00000000-0000-0000-0000-000000000000');
        leerDetalleLiquidacion('00000000-0000-0000-0000-000000000000');
        leerDetalleInventario('00000000-0000-0000-0000-000000000000');
        listarPaises();
        localStorage.removeItem('idPolizaImp');
        localStorage.removeItem('detalleIDIMP');
        localStorage.removeItem('detalleIMPGasto');
        localStorage.removeItem('dataI');
        localStorage.removeItem('TcambioImp');
        localStorage.removeItem('id_detalle_inventario_IMP');
        
    }, [])
    const [crearC, setCrear] = useState(true);
    const [estadoModal, cambiarEstadoModal] = useState(false);
    const [estadoModalCC, cambiarEstadoModalCC] = useState(false);
    const [estadoModalCuenta, cambiarEstadoModalCuenta] = useState(false);

    const editar = window.location.href.includes('editar');
    let titulo = editar ? 'Modificar importación' : 'Crear importación';
    let disabled = false;
    if (crearC === false && editar === false) {
        disabled = true;
        titulo = 'Ver importación';
    }
    
    /* crear cuenta contable*/
    const getNivel = () => {
        let formato = ''
        if (nivel.length > 0) {
            for (let a = 0; a < nivel[0].nivel_uno; a++) {
                formato = formato + '#'
            }
            for (let b = 0; b < nivel[0].nivel_dos; b++) {
                if (b === 0) {
                    formato = formato + '-' + '#'
                } else {
                    formato = formato + '#'
                }
            }
            for (let c = 0; c < nivel[0].nivel_tres; c++) {
                if (c === 0) {
                    formato = formato + '-' + '#'
                } else {
                    formato = formato + '#'
                }
            }
            for (let d = 0; d < nivel[0].nivel_cuatro; d++) {
                if (d === 0) {
                    formato = formato + '-' + '#';
                } else {
                    formato = formato + '#';
                }
            }
            for (let e = 0; e < nivel[0].nivel_cinco; e++) {
                if (e === 0) {
                    formato = formato + '-' + '#';
                } else {
                    formato = formato + '#';
                }
            }
            for (let f = 0; f < nivel[0].nivel_seis; f++) {
                formato = formato + '#';
            }
            localStorage.setItem('formato', formato.toString());
        }

        return formato
    };

    const tipo = [
        {
            value: 'A',
            label: 'Acumulativa'
        },
        {
            value: 'D',
            label: 'Detalle'
        },
    ];

    const naturaleza = [
        {
            value: 'ACTIVO',
            label: 'Activo'
        },
        {
            value: 'PASIVO',
            label: 'Pasivo'
        },
        {
            value: 'CAPITAL',
            label: 'Capital'
        },
        {
            value: 'INGRESOS',
            label: 'Ingresos'
        },
        {
            value: 'COSTOS',
            label: 'Costos'
        },
        {
            value: 'GASTOS',
            label: 'Gastos'
        },
    ];

    let autoFocus = true;
    
    const [key, setKey] = useState('PRINCIPAL_TOP');
    let poliza = localStorage.getItem('idPolizaImp');
    const estilo = {
        overflow: "auto",
        height: "200px",

    };
    const estiloFarm = {
        overflow: "auto",
        height: "200px",
    };
    
    let ruta = window.location.href;
    let id_empresa = ruta.split('/');
    let dif_poliza = localStorage.getItem('dif');
    return (
        <React.Fragment>
            <NavbarModulo
                periodoD={periodoD}
                modulo={modulo}
                titulo={titulo}
            />
            <div className="py-1">
                <div className="mb-1">
                    <div className="p-0 px-3 pt-0">
                        {datos.inventario === 'S' && datos.contabilidad === 'S' &&
                            <Tabs
                                activeKey={key}
                                onChange={(k) => setKey(k)}
                                tabBarPosition="top"
                                renderTabBar={() => <ScrollableInkTabBar />}
                                renderTabContent={() => <TabContent />}
                            >
                                <TabPane tab="Importación" key="PRINCIPAL_TOP">
                                    <div className="py-2 px-3">
                                        <div className="mb-4 card card-small text-white bg-dark mb-1">
                                            <div className="p-0 pt-4 d-flex flex-column flex-md-row">
                                                <div className="d-flex flex-column flex-1 mx-4">
                                                    <LoadMask loading={loader} dark>
                                                        <Documento
                                                            crear={crearC}
                                                            editar={editar}
                                                            lectura={lectura_data}
                                                            bloqueo={disabled}
                                                            tipos={tipos}
                                                            fechaInicio={periodoD.fecha_inicio}
                                                            fechaFinal={periodoD.fecha_fin}
                                                            listarProveedores={listarProveedores}
                                                            registroProveedorExt={registroProveedorExt}
                                                            listarCuentas={listarCuentas}
                                                            tipoCambio={tipoCambio}
                                                            impuesto = {impuesto}
                                                            obtenerPaises = { listaP }
                                                            registroImportacion = {registroImportacion}
                                                            periodoD = {periodoD}
                                                            eliminarDocumentoImportacion = {eliminarDocumentoImportacion}
                                                            detalleImp = {detalleImp}
                                                        />
                                                    </LoadMask>
                                                    <br />
                                                    {(editar == true) &&
                                                        <div>
                                                            <button
                                                                className='btn mr-2 btn-primary'
                                                                onClick={() => modificarImportacion(periodoD)}
                                                            >
                                                                Modificar importación
                                                            </button>
                                                            <a
                                                                href={`/#/compras/${id_empresa[5]}/importaciones/${id_empresa[7]}`}
                                                                className='btn btn-secondary'
                                                            >
                                                                Regresar
                                                            </a>
                                                        </div>
                                                    }
                                                    <br />
                                                    {(crear === false || editar === false) &&
                                                        <div>
                                                            <a
                                                                href={`/#/compras/${id_empresa[5]}/importaciones/${id_empresa[7]}`}
                                                                className='btn btn-secondary mb-3'
                                                            >
                                                                Regresar
                                                            </a>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane tab="Gastos locales" key="SEGUNDO_TOP">
                                    <div className="py-2 px-3">
                                        <div className="mb-4 card card-small text-white bg-dark mb-1">
                                            <div className="p-0 pt-4 d-flex flex-column flex-md-row">
                                                <div className="d-flex flex-column flex-1 mx-4">
                                                    <LoadMask loading={loader} dark>
                                                        <Gastos
                                                            crear={crearC}
                                                            editar={editar}
                                                            lectura={lectura_data}
                                                            bloqueo={disabled}
                                                            tipos={tipos}
                                                            listarProveedores={listarProveedoresGastos}
                                                            listarProveedoresD={prov}
                                                            registroProveedor={registroProveedor}
                                                            listarCuentas={listarCuentas}
                                                            impuesto = {impuesto}
                                                            periodoD = {periodoD}
                                                            datos = {datos}
                                                            detalleLiq = {detalleLiq}
                                                            obtenerPaises = { listaP }
                                                        />
                                                        {(crearC === true || editar === true) &&
                                                            <Contenido>
                                                                <button
                                                                    className='btn mr-2 mb-3 btn-primary'
                                                                    onClick={() => registroCompra(impuesto, periodoD, datos)}
                                                                >
                                                                    Agregar documento
                                                                </button>
                                                            </Contenido>
                                                        }
                                                        {crearC === false && editar === false &&
                                                            <div style={estilo}>
                                                                <TablaSCCVer
                                                                    detalle={detalleLiq}
                                                                    eliminarLinea={eliminarDocumento}
                                                                />
                                                            </div>
                                                        }
                                                        {(crearC === true || editar === true) &&
                                                            <div style={estilo}>
                                                                <TablaSCC
                                                                    detalle={detalleLiq}
                                                                    eliminarDocumento={eliminarDocumento}
                                                                />
                                                            </div>
                                                        }
                                                    </LoadMask>
                                                    <br />
                                                    {(crearC === false || editar === false) &&
                                                        <div>
                                                            <a
                                                                href={`/#/compras/${id_empresa[5]}/importaciones/${id_empresa[7]}`}
                                                                className='btn btn-secondary mb-3'
                                                            >
                                                                Regresar
                                                            </a>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane tab="Inventario" key="TERCER_TOP">
                                    <div className="py-2 px-3">
                                        <div className="mb-4 card card-small text-white bg-dark mb-1">
                                            <div className="p-0 pt-4 d-flex flex-column flex-md-row">
                                                <div className="d-flex flex-column flex-1 mx-4">
                                                    <LoadMask loading={loader} dark>
                                                        <Inventario
                                                            crear={crearC}
                                                            lectura={lectura}
                                                            bloqueo={disabled}
                                                            editar={editar}
                                                            obtenerBodegas={listaBodegas}
                                                            listarProductos={listarProductos}
                                                            datos = {datos}
                                                        />
                                                        {(crearC === true || editar === true) &&
                                                            <Contenido>
                                                                <button
                                                                    className='btn mr-2 mb-3 btn-primary'
                                                                    onClick={() => registroProductoInventario(impuesto)}
                                                                >
                                                                    Agregar linea
                                                                </button>
                                                            </Contenido>
                                                        }
                                                        {(crearC === true || editar === true) && datos.farmaceutica === 'N' &&
                                                            <div style={estilo}>
                                                                <DetalleInventario
                                                                    detalle={detalleProducto}
                                                                    eliminarLineaProducto={eliminarLineaProducto}
                                                                />
                                                            </div>
                                                        }
                                                        {(crearC === true || editar === true) && datos.farmaceutica === 'S' &&
                                                            <div style={estiloFarm}>
                                                                <DetalleInventarioFarm
                                                                    detalle={detalleProducto}
                                                                    eliminarLineaProducto={eliminarLineaProducto}
                                                                />
                                                            </div>
                                                        }
                                                        {(crearC === false && editar === false) && datos.farmaceutica === 'N' &&
                                                            <div style={estilo}>
                                                                <DetalleInventarioVer
                                                                    detalle={detalleProducto}
                                                                />
                                                            </div>
                                                        }
                                                        {(crearC === false && editar === false) && datos.farmaceutica === 'S' &&
                                                            <div style={estiloFarm}>
                                                                <DetalleInventarioFarmVer
                                                                    detalle={detalleProducto}
                                                                />
                                                            </div>
                                                        }
                                                        <br />
                                                        <br />
                                                        <br />
                                                    </LoadMask>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane tab="Póliza" key="CUARTO_TOP">
                                    <div className="py-2 px-3">
                                        <div className="mb-4 card card-small bg-dark mb-3">
                                            <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                                                <div className="d-flex flex-column flex-1 mx-3">
                                                    {disabled == false && datos.centro_costo === 'N' &&
                                                        <Contenido>
                                                            <button
                                                                className='btn mr-2 mb-3 btn-primary'
                                                                onClick={() => cambiarEstadoModal(!estadoModal)}
                                                            >
                                                                Agregar Linea
                                                            </button>
                                                        </Contenido>
                                                    }

                                                    {disabled == false && datos.centro_costo === 'S' &&
                                                        <Contenido>
                                                            <button
                                                                className='btn mr-2 mb-3 btn-primary'
                                                                onClick={() => cambiarEstadoModalCC(!estadoModalCC)}
                                                            >
                                                                Agregar Linea
                                                            </button>
                                                        </Contenido>
                                                    }
                                                    <Poliza
                                                        listarCuentas={listarCuentas}
                                                        lectura={lectura}
                                                        editar={editar}
                                                        crear={crearC}
                                                        listarCC={listarCC}
                                                        detallePoliza={detalle}
                                                        eliminarLinea={eliminarLinea}
                                                        niveles={nivel}
                                                        registroCuenta={registroCuenta}
                                                        reporteCuentas={reporteCuentas}
                                                        loaderC={loaderC}
                                                    />
                                                    <br />
                                                    <div className='d-flex flex-row mt-3'>
                                                        {disabled == false && poliza &&
                                                            <button
                                                                className='btn mr-2 mb-3 btn-primary'
                                                                onClick={confirmarPoliza}
                                                            >
                                                                Confirmar poliza
                                                            </button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPane>
                            </Tabs>
                        }
                        {datos.inventario === 'N' && datos.contabilidad === 'S' &&
                            <Tabs
                                activeKey={key}
                                onChange={(k) => setKey(k)}
                                tabBarPosition="top"
                                renderTabBar={() => <ScrollableInkTabBar />}
                                renderTabContent={() => <TabContent />}
                            >
                                <TabPane tab="Importación" key="PRINCIPAL_TOP">
                                    <div className="py-2 px-3">
                                        <div className="mb-4 card card-small text-white bg-dark mb-1">
                                            <div className="p-0 pt-4 d-flex flex-column flex-md-row">
                                                <div className="d-flex flex-column flex-1 mx-4">
                                                    <LoadMask loading={loader} dark>
                                                        <Documento
                                                            crear={crearC}
                                                            editar={editar}
                                                            lectura={lectura_data}
                                                            bloqueo={disabled}
                                                            tipos={tipos}
                                                            fechaInicio={periodoD.fecha_inicio}
                                                            fechaFinal={periodoD.fecha_fin}
                                                            listarProveedores={listarProveedores}
                                                            listarProveedoresD={prov}
                                                            registroProveedorExt={registroProveedorExt}
                                                            listarCuentas={listarCuentas}
                                                            tipoCambio={tipoCambio}
                                                            impuesto = {impuesto}
                                                            registroImportacion = {registroImportacion}
                                                            periodoD = {periodoD}
                                                            eliminarDocumentoImportacion = {eliminarDocumentoImportacion}
                                                            detalleImp = {detalleImp}
                                                        />
                                                    </LoadMask>
                                                    <br />
                                                    {(editar == true) &&
                                                        <div>
                                                            <button
                                                                className='btn mr-2 btn-primary'
                                                                onClick={() => modificarImportacion(periodoD)}
                                                            >
                                                                Modificar importación
                                                            </button>
                                                            <a
                                                                href={`/#/compras/${id_empresa[5]}/importaciones/${id_empresa[7]}`}
                                                                className='btn btn-secondary'
                                                            >
                                                                Regresar
                                                            </a>
                                                        </div>
                                                    }
                                                    <br />
                                                    {(crear === false || editar === false) &&
                                                        <div>
                                                            <a
                                                                href={`/#/compras/${id_empresa[5]}/importaciones/${id_empresa[7]}`}
                                                                className='btn btn-secondary mb-3'
                                                            >
                                                                Regresar
                                                            </a>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane tab="Gastos locales" key="SEGUNDO_TOP">
                                    <div className="py-2 px-3">
                                        <div className="mb-4 card card-small text-white bg-dark mb-1">
                                            <div className="p-0 pt-4 d-flex flex-column flex-md-row">
                                                <div className="d-flex flex-column flex-1 mx-4">
                                                    <LoadMask loading={loader} dark>
                                                        <Gastos
                                                            crear={crearC}
                                                            editar={editar}
                                                            lectura={lectura_data}
                                                            bloqueo={disabled}
                                                            tipos={tipos}
                                                            listarProveedores={listarProveedoresGastos}
                                                            registroProveedor={registroProveedor}
                                                            listarCuentas={listarCuentas}
                                                            impuesto = {impuesto}
                                                            periodoD = {periodoD}
                                                        />
                                                        {(crearC === true || editar === true) &&
                                                            <Contenido>
                                                                <button
                                                                    className='btn mr-2 mb-3 btn-primary'
                                                                    onClick={() => registroCompra(impuesto, periodoD, datos)}
                                                                >
                                                                    Agregar documento
                                                                </button>
                                                            </Contenido>
                                                        }
                                                        {crearC === false && editar === false &&
                                                            <div style={estilo}>
                                                                <TablaSCCVer
                                                                    detalle={detalleLiq}
                                                                    eliminarLinea={eliminarDocumento}
                                                                />
                                                            </div>
                                                        }
                                                        {(crearC === true || editar === true) &&
                                                            <div style={estilo}>
                                                                <TablaSCC
                                                                    detalle={detalleLiq}
                                                                    eliminarDocumento={eliminarDocumento}
                                                                />
                                                            </div>
                                                        }
                                                    </LoadMask>
                                                    <br />
                                                    {(crearC === false || editar === false) &&
                                                        <div>
                                                            <a
                                                                href={`/#/compras/${id_empresa[5]}/importaciones/${id_empresa[7]}`}
                                                                className='btn btn-secondary mb-3'
                                                            >
                                                                Regresar
                                                            </a>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane tab="Póliza" key="TERCERO_TOP">
                                    <div className="py-2 px-3">
                                        <div className="mb-4 card card-small bg-dark mb-3">
                                            <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                                                <div className="d-flex flex-column flex-1 mx-3">
                                                    {disabled == false && datos.centro_costo === 'N' &&
                                                        <Contenido>
                                                            <button
                                                                className='btn mr-2 mb-3 btn-primary'
                                                                onClick={() => cambiarEstadoModal(!estadoModal)}
                                                            >
                                                                Agregar Linea
                                                            </button>
                                                        </Contenido>
                                                    }

                                                    {disabled == false && datos.centro_costo === 'S' &&
                                                        <Contenido>
                                                            <button
                                                                className='btn mr-2 mb-3 btn-primary'
                                                                onClick={() => cambiarEstadoModalCC(!estadoModalCC)}
                                                            >
                                                                Agregar Linea
                                                            </button>
                                                        </Contenido>
                                                    }
                                                    <Poliza
                                                        listarCuentas={listarCuentas}
                                                        lectura={lectura}
                                                        editar={editar}
                                                        crear={crearC}
                                                        listarCC={listarCC}
                                                        detallePoliza={detalle}
                                                        eliminarLinea={eliminarLinea}
                                                        niveles={nivel}
                                                        registroCuenta={registroCuenta}
                                                        reporteCuentas={reporteCuentas}
                                                        loaderC={loaderC}
                                                    />
                                                    <br />
                                                    <div className='d-flex flex-row mt-3'>
                                                        {disabled == false && poliza &&
                                                            <button
                                                                className='btn mr-2 mb-3 btn-primary'
                                                                onClick={confirmarPoliza}
                                                            >
                                                                Confirmar poliza
                                                            </button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPane>
                            </Tabs>
                        }
                    </div>
                </div>
            </div>
            <Modal
                estado={estadoModal}
                cambiarEstado={cambiarEstadoModal}
                titulo="Ingresar Detalle poliza"
                botonCerrar="true"
            >
                <div className='row mb-3'>
                    <div className='col-6'>
                        <Field
                            name="cuenta"
                            placeholder="Cuenta contable..."
                            className="form-control-find"
                            component={AsyncSelectField}
                            loadOptions={listarCuentas}
                            msj="Cuenta"
                        />
                        <button
                            className='btn ml-2 btn-dark'
                            onClick={() => cambiarEstadoModalCuenta(!estadoModalCuenta)}
                        >
                            Crear cuenta
                        </button>
                    </div>
                    <div className='col-3 mt-1 w-25'>
                        <Field
                            name='debe'
                            component={renderCurrencyPoliza}
                            className="form-control"
                            placeholder="Debe"
                            msj="Debe"
                        />
                    </div>
                    <div className='col-3 mt-1'>
                        <Field
                            name='haber'
                            component={renderCurrencyPoliza}
                            className="form-control"
                            placeholder="Haber"
                            msj="Haber"
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-9'>
                    </div>
                    <div className='col-3 title'>
                        Diferencia: {new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(dif_poliza)}
                    </div>
                </div>
                <Contenido>
                    <div className='row'>
                        <div className='col-6'>
                            <button
                                className='btn ml-3 mb-3 btn-primary'
                                onClick={registroPolizaDetalleSCC}
                            >
                                Agregar
                            </button>
                        </div>
                        <div className='col-6'>
                            <button
                                className='btn mb-3 btn btn-secondary'
                                onClick={() => cambiarEstadoModal(false)}
                            >
                                Cancelar
                            </button>
                        </div>
                    </div>
                </Contenido>
            </Modal>
            <Modal
                estado={estadoModalCC}
                cambiarEstado={cambiarEstadoModalCC}
                titulo="Ingresar Detalle poliza"
                botonCerrar="true"
            >
                <div className='row mb-3'>
                    <div className='col-6'>
                        <Field
                            autoFocus={true}
                            name="cuenta"
                            placeholder="Cuenta contable..."
                            className="form-control-find"
                            component={AsyncSelectField}
                            loadOptions={listarCuentas}
                            msj="Cuenta"
                        />
                        <button
                            className='btn ml-2 btn-dark'
                            onClick={() => cambiarEstadoModalCuenta(!estadoModalCuenta)}
                        >
                            Crear cuenta
                        </button>
                    </div>

                    <div className='col-2'>
                        <Field
                            name="cc"
                            placeholder="C.C..."
                            className="form-control-find"
                            component={AsyncSelectField}
                            loadOptions={listarCC}
                            msj="Centro Costo"
                        />
                    </div>
                    <div className='col-2 mt-1 w-25'>
                        <Field
                            name='debe'
                            component={renderCurrencyPoliza}
                            className="form-control"
                            placeholder="Debe"
                            msj="Debe"
                        />
                    </div>
                    <div className='col-2 mt-1'>
                        <Field
                            name='haber'
                            component={renderCurrencyPoliza}
                            className="form-control"
                            placeholder="Haber"
                            msj="Haber"
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-9'>
                    </div>
                    <div className='col-3 title'>
                        Diferencia: {new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(dif_poliza)}
                    </div>
                </div>
                <Contenido>
                    <div className='row'>
                        <div className='col-6'>
                            <button
                                className='btn ml-3 mb-3 btn-primary'
                                onClick={registroPolizaDetalle}
                            >
                                Agregar
                            </button>
                        </div>
                        <div className='col-6'>
                            <button
                                className='btn mr-2 mb-3 btn btn-secondary'
                                onClick={() => cambiarEstadoModalCC(false)}
                            >
                                Cancelar
                            </button>
                        </div>
                    </div>
                </Contenido>
            </Modal>
            <ModalCuenta
                estado={estadoModalCuenta}
                cambiarEstado={cambiarEstadoModalCuenta}
                titulo="Crear cuenta contable"
                botonCerrar="false"
            >
                <div className="d-flex flex-column flex-1 mx-4">
                    <div className='mb-2 w-50'>
                        <Field
                            autoFocus={autoFocus}
                            type="number"
                            id="cuenta"
                            name="cuenta"
                            placeholder="Cuenta"
                            className="form-control"
                            numberFormat={getNivel()}
                            component={renderNumber}
                            msj="Cuenta"
                        />
                    </div>
                    <div className='mb-2 w-100'>
                        <Field
                            id="nombre"
                            name="nombre"
                            placeholder="Nombre Cuenta"
                            className="form-control"
                            component={renderField}
                            msj="Nombre Cuenta"
                        />
                    </div>
                    <div className='mb-2 w-50'>
                        <div className="form-floating">
                            <Field
                                name="tipoC"
                                options={tipo}
                                crear={crear}
                                dato={crear === true ? 0 : lectura.tipo}
                                component={SelectField}
                                className="form-select"
                                msj="Tipo"
                            />
                        </div>
                    </div>
                    <div className='mb-3 w-50'>
                        <div className="form-floating">
                            <Field
                                id="naturaleza"
                                name="naturaleza"
                                options={naturaleza}
                                crear={crear}
                                dato={crear === true ? 0 : lectura.naturaleza}
                                component={SelectField}
                                className="form-select"
                                msj="Naturaleza"
                            />
                        </div>
                    </div>
                    <LoadMask loading={loaderC} dark>
                        <Contenido>
                            <div className='row'>
                                <button
                                    className='btn mr-2 mb-3 btn-primary'
                                    onClick={registroCuenta}
                                >
                                    Guardar
                                </button>
                                <button
                                    className='btn mr-2 mb-3 btn-dark'
                                    onClick={reporteCuentas}
                                >
                                    Generar reporte
                                </button>
                                <button
                                    className='btn mr-2 mb-3 btn-secondary'
                                    onClick={() => cambiarEstadoModalCuenta(false)}
                                >
                                    Cancelar
                                </button>
                            </div>
                        </Contenido>
                    </LoadMask>
                </div>
            </ModalCuenta>
        </React.Fragment>
    )

}
ImportacionFormTab.propTypes = {
    handleSubmit: PropTypes.func,
    crear: PropTypes.bool,
    lectura: PropTypes.object,
    tipos: PropTypes.object,
    listarProveedores: PropTypes.func,
    loader: PropTypes.bool,
    detalle: PropTypes.array,
    eliminarLinea: PropTypes.func,
    listarCuentas: PropTypes.func,
    listarCC: PropTypes.func,
    nivel: PropTypes.object,
    registroCuenta: PropTypes.func,
    reporteCuentas: PropTypes.func,
    loaderC: PropTypes.bool,
    prov: PropTypes.array,
    registroImportacion: PropTypes.func,
    modificarImportacion: PropTypes.func,
    registroCompra: PropTypes.func,
    registroPolizaDetalle: PropTypes.func,
    confirmarPoliza: PropTypes.func,
    registroPolizaDetalleSCC: PropTypes.func,
    leerDetalleLiquidacion: PropTypes.func,
    eliminarDocumento: PropTypes.func,
    listarProveedoresD: PropTypes.func,
    registroProveedor: PropTypes.func,
    leerDetalleImportacion: PropTypes.func,
    listarProveedoresGastos: PropTypes.func,
    registroProveedorExt: PropTypes.func,
    eliminarDocumentoImportacion: PropTypes.func
};

export default reduxForm({
    form: 'ImportacionFormTabs', //identificador unico
})(ImportacionFormTab)

const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;