import React, { Component } from 'react';
import Formulario from './TrasladoForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";

class Traslado extends Component{

    componentDidMount = () => {
        const { getModulo } = this.props;
        let ruta = window.location.href;
        let periodo = ruta.split('/');
        getModulo(periodo[5],periodo[4]);
    }

    render(){
        const { loader, traslado, modulo, listarPeriodos, periodos } = this.props;
        return(
            <div>
                <LoadMask loading={loader} dark>
                    <Formulario
                        traslado = {traslado}
                        modulo = {modulo}
                        listarPeriodos = {listarPeriodos}
                        periodos = {periodos}
                    />
                </LoadMask>
            </div>
        );
    }
}

export default Traslado;
