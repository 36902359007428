import React, { Component } from 'react';
import Formulario from './CuentaContableForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";

class CuentaContable extends Component{

    componentDidMount() {
        const { getModulo, listarCuentasPrd } = this.props;
        let ruta = window.location.href;
        let periodo = ruta.split('/');
        getModulo(periodo[5],periodo[4]);
        listarCuentasPrd();
    }
    
    render(){
        const { loader, modulo, data, eliminarCuenta,
            listarCuentas, agregarCuenta } = this.props;
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    modulo={modulo}
                    listarCuentas = {listarCuentas}
                    data = {data}
                    agregarCuenta = {agregarCuenta}
                    eliminarCuenta = {eliminarCuenta}
                />
           </LoadMask>
        );
    }
}

export default CuentaContable;
