import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';
const valEmp = __CONFIG__.empresa;

const LISTADO_PRODUCTO = 'LISTADO_PRODUCTO';
const GUARDAR_PRODUCTO = 'GUARDAR_PRODUCTO';
const LOADER = 'LOADER';
const MODULO = 'MODULO';
const LISTADO_FORMA = 'LISTADO_FORMA';
const LISTADO_GRUPO = 'LISTADO_GRUPO';
const LISTADO_PRINCIPIOS = 'LISTADO_PRINCIPIOS';
const LISTADO_LINEA = 'LISTADO_LINEA';
const LISTADO_FAMILIA = 'LISTADO_FAMILIA';
const LISTADO_CONCENTRACION = 'LISTADO_CONCENTRACION';
const DETALLE = 'DETALLE';
const DETALLE_PRODUCTOS = 'DETALLE_PRODUCTOS';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let params = { id };
    dispatch(setLoader(true));
    api.get('/rs_producto', params).then((response) => {
        dispatch({ type: LISTADO_PRODUCTO, data: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar productos',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const registroProducto = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormProductoRS.values;
    let id_empresa = id_emp[5]
    if (data === undefined) {
        Swal.fire({
            title: 'Producto',
            text: 'Debe de ingresar datos del producto',
            type: 'error',
        })
    } else {
        if (data.descripcion === undefined || data.descripcion === '') {
            Swal.fire({
                title: 'Producto',
                text: 'Debe de ingresar nombre del producto',
                type: 'error',
            })
        } else {
            if (data.linea === undefined || data.linea === '') {
                Swal.fire({
                    title: 'Producto',
                    text: 'Debe de seleccionar linea',
                    type: 'error',
                })
            } else {
                if (data.familia === undefined || data.familia === '') {
                    Swal.fire({
                        title: 'Producto',
                        text: 'Debe de seleccionar familia',
                        type: 'error',
                    })
                } else {
                    if (data.forma === undefined || data.forma === '') {
                        Swal.fire({
                            title: 'Producto',
                            text: 'Debe de seleccionar forma farmacéutica',
                            type: 'error',
                        })
                    } else {
                        if (data.grupo === undefined || data.grupo === '') {
                            Swal.fire({
                                title: 'Producto',
                                text: 'Debe de seleccionar grupo terapéutico',
                                type: 'error',
                            })
                        } else {
                            if (data.detalleD === undefined || data.detalleD === '') {
                                Swal.fire({
                                    title: 'Producto',
                                    text: 'Debe de ingresar detalle del producto',
                                    type: 'error',
                                })
                            } else {
                                const formData = {
                                    descripcion: data.descripcion,
                                    principio_activo: data.principio_activo,
                                    forma: data.forma,
                                    grupo: data.grupo,
                                    detallePro: data.detalleD,
                                    linea: data.linea,
                                    familia: data.familia,
                                    empresa: id_empresa
                                }
                                dispatch(setLoader(true));
                                api.post('/rs_producto/', formData).then(() => {
                                    NotificationManager.success(
                                        'Producto creado',
                                        'Exito',
                                        3000
                                    );
                                    let ruta = `/registro_sanitario/${id_emp[5]}/producto`
                                    dispatch(push(ruta));
                                }).catch((error) => {
                                    NotificationManager.error(
                                        error.msj,
                                        'Error',
                                        2000
                                    );
                                }).finally(() => {
                                    dispatch(setLoader(false));
                                });
                            }
                        }
                    }
                }
            }
        }
    }
}

export const modificarProducto = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const datos = getStore().form.FormProductoRS.values;
    const id = id_emp[7];
    
    if (datos === undefined) {
        Swal.fire({
            title: 'Producto',
            text: 'Debe de ingresar datos del producto',
            type: 'error',
        })
    } else {
        if (datos.descripcion === undefined || datos.descripcion === '') {
            Swal.fire({
                title: 'Producto',
                text: 'Debe de ingresar nombre del producto',
                type: 'error',
            })
        } else {
            if (datos.linea === undefined || datos.linea === '') {
                Swal.fire({
                    title: 'Producto',
                    text: 'Debe de seleccionar linea',
                    type: 'error',
                })
            } else {
                if (datos.familia === undefined || datos.familia === '') {
                    Swal.fire({
                        title: 'Producto',
                        text: 'Debe de seleccionar familia',
                        type: 'error',
                    })
                } else {
                    if (datos.forma_farmaceutica === undefined || datos.forma_farmaceutica === '') {
                        Swal.fire({
                            title: 'Producto',
                            text: 'Debe de seleccionar forma farmacéutica',
                            type: 'error',
                        })
                    } else {
                        if (datos.grupo_terapeutico === undefined || datos.grupo_terapeutico === '') {
                            Swal.fire({
                                title: 'Producto',
                                text: 'Debe de seleccionar grupo terapéutico',
                                type: 'error',
                            })
                        } else {
                            const formData = {
                                id: id,
                                descripcion: datos.descripcion,
                                linea: datos.linea,
                                familia: datos.familia,
                                farmaceutico: datos.farmaceutico,
                                forma: datos.forma_farmaceutica,
                                grupo: datos.grupo_terapeutico,
                            }
                            dispatch(setLoader(true));
                            api.put(`/rs_producto/${id}/`, formData).then(() => {
                                NotificationManager.success(
                                    'Producto modificado correctamente',
                                    'Exito',
                                    3000
                                );
                                let ruta = `/registro_sanitario/${id_emp[5]}/producto`
                                dispatch(push(ruta));
                            }).catch((error) => {
                                NotificationManager.error(
                                    'Ocurrió un error al modificar producto',
                                    'Error',
                                    2000
                                );
                            }).finally(() => {
                                dispatch(setLoader(false));
                            });
                        }
                    }
                }
            }
        }
    }
}

const leerDetalleProductos = id => (dispatch) => {
    api.get(`/rs_producto_detalle/${id}`).then((response) => {
        dispatch({ type: DETALLE_PRODUCTOS, detalleProducto: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/rs_producto/${id}`).then((response) => {
        response.representante = response.representante_pais
        response.farmaceutico = response.farmaceutico_responsable
        localStorage.setItem('id_detalle_producto_rs', response.id);
        dispatch(initializeForm('FormProductoRS', response));
        dispatch({ type: GUARDAR_PRODUCTO, lectura: response });
        dispatch(leerDetalleProductos(response.id));
    }).catch((error) => {

        NotificationManager.error(
            'Ocurrió un error al consultar producto',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = { id, modulo }
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response) => {
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const listarPrincipios = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let params = { id };
    return api.get("/principio_activo", params).then(response => {
        const principios = [];
        response.forEach(item => {
            principios.push({
                value: item.id + '/' + item.descripcion,
                label: item.descripcion
            })
        })
        dispatch({ type: LISTADO_PRINCIPIOS, listaPrincipio: principios });
    }).catch(error => {
        NotificationManager.error(
            'Ocurrió un error al listar principios activos',
            'Error',
            8000
        );
    })
}

const listarForma = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let params = { id };
    
    return api.get("/forma", params).then(response => {
        const forma = [];
        response.forEach(item => {
            forma.push({
                value: item.id,
                label: item.descripcion
            })
        })
        dispatch({ type: LISTADO_FORMA, listaForm: forma });
    }).catch(error => {
        NotificationManager.error(
            'Ocurrió un error al listar formas farmacéuticas',
            'Error',
            8000
        );
    })
}

const listarGrupos = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let params = { id };
    
    return api.get("/grupo", params).then(response => {
        const grupo = [];
        response.forEach(item => {
            grupo.push({
                value: item.id,
                label: item.descripcion
            })
        })
        dispatch({ type: LISTADO_GRUPO, listaGrupo: grupo });
    }).catch(error => {
        NotificationManager.error(
            'Ocurrió un error al listar grupos terapéuticos',
            'Error',
            8000
        );
    })
}

const listarLineas = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params;
    let id = ''
    if (valEmp === 'fage') {
        id = id_emp[6];
        params = { id };
    } else {
        id = id_emp[5];
        params = { id };
    }
    return api.get("/linea", params).then(response => {
        const linea = [];
        response.forEach(item => {
            linea.push({
                value: item.id,
                label: item.descripcion
            })
        })
        dispatch({ type: LISTADO_LINEA, listaLinea: linea });
    }).catch(error => {
        NotificationManager.error(
            'Ocurrió un error al listar lineas',
            'Error',
            8000
        );
    })
}

const listarFamilias = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params;
    let id = ''
    if (valEmp === 'fage') {
        id = id_emp[6];
        params = { id };
    } else {
        id = id_emp[5];
        params = { id };
    }
    return api.get("/familia", params).then(response => {
        const familia = [];
        response.forEach(item => {
            familia.push({
                value: item.id,
                label: item.descripcion
            })
        })
        dispatch({ type: LISTADO_FAMILIA, listaFamilia: familia });
    }).catch(error => {
        NotificationManager.error(
            'Ocurrió un error al listar familias',
            'Error',
            8000
        );
    })
}

const listarPresentacion = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params;
    let id = ''
    if (valEmp === 'fage') {
        id = id_emp[6];
        params = { id };
    } else {
        id = id_emp[5];
        params = { id };
    }
    return api.get("/presentacion", params).then(response => {
        const presentacion = [];
        response.forEach(item => {
            presentacion.push({
                value: item.id,
                label: item.descripcion
            })
        })
        dispatch({ type: LISTADO_PRESENTACION, listaPresentacion: presentacion });
    }).catch(error => {
        NotificationManager.error(
            'Ocurrió un error al listar presentaciones',
            'Error',
            8000
        );
    })
}

const listarConcentracion = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params;
    let id = ''
    if (valEmp === 'fage') {
        id = id_emp[6];
        params = { id };
    } else {
        id = id_emp[5];
        params = { id };
    }
    return api.get("/concentracion", params).then(response => {
        const concentracion = [];
        response.forEach(item => {
            concentracion.push({
                value: item.id + '/' + item.descripcion,
                label: item.descripcion
            })
        })
        dispatch({ type: LISTADO_CONCENTRACION, listaConcentracion: concentracion });
    }).catch(error => {
        NotificationManager.error(
            'Ocurrió un error al listar concentraciones',
            'Error',
            8000
        );
    })
}


const actualizar = () => (dispatch, getStore) => {
    const data = getStore().form.FormProductoRS.values;
    dispatch(initializeForm('FormProductoRS', data));
    dispatch({ type: DETALLE, detallePro: data.detalleD })
};


let detalle = [];
export const agregarLinea = () => (dispatch, getStore) => {
    const data = getStore().form.FormProductoRS.values;
    if (data.principio === undefined || data.principio === '') {
        Swal.fire({
            title: 'Producto',
            text: 'Debe de seleccionar principio activo',
            type: 'error',
        })
    } else {
        if (data.concentracion === undefined || data.concentracion === '') {
            Swal.fire({
                title: 'Producto',
                text: 'Debe de seleccionar concentración',
                type: 'error',
            })
        } else {
            if (data.unidad === undefined || data.unidad === '') {
                Swal.fire({
                    title: 'Producto',
                    text: 'Debe de ingresar unidad de dosis',
                    type: 'error',
                })
            } else {
                let datosPrincipio = data.principio.split('/');
                let datosConcentracion = data.concentracion.split('/');
                detalle.push({
                    id: datosPrincipio[0] + '/' + datosConcentracion[0],
                    idPrincipio: datosPrincipio[0],
                    principio: datosPrincipio[1],
                    idConcentracion: datosConcentracion[0],
                    concentracion: datosConcentracion[1],
                    unidad: data.unidad,
                })
                data.detalleD = detalle;
                NotificationManager.success(
                    'Producto ingresado correctamente',
                    'Exito',
                    3000
                );

                dispatch(actualizar());
            }
        }

    }
}

const eliminarLinea = (id) => (dispatch) => {
    let d = id.split('/');
    detalle.forEach((element, index) => {
        if (element.idPrincipio === d[0] && element.idConcentracion === d[1]) {
            detalle.splice(index, 1);
        }
    })
    dispatch(actualizar());
};

export const agregarLineaProducto = () => (dispatch, getStore) => {
    const data = getStore().form.FormProductoRS.values;
    const detalle = localStorage.getItem('id_detalle_producto_rs');
    if (data.principio === undefined || data.principio === '') {
        Swal.fire({
            title: 'Producto',
            text: 'Debe de seleccionar principio activo',
            type: 'error',
        })
    } else {
        if (data.concentracion === undefined || data.concentracion === '') {
            Swal.fire({
                title: 'Producto',
                text: 'Debe de seleccionar concentración',
                type: 'error',
            })
        } else {
            let datosPrincipio = data.principio.split('/');
            let datosConcentracion = data.concentracion.split('/');

            const formData = {
                idPrincipio: datosPrincipio[0],
                idConcentracion: datosConcentracion[0],
                unidad: data.unidad,
                detalle: detalle,
            }
            dispatch(setLoader(true));
            api.post('/rs_producto/', formData).then((response) => {
                NotificationManager.success(
                    'Producto ingresado correctamente',
                    'Exito',
                    3000
                );
                dispatch(leerDetalleProductos(response.detalle));
            }).catch((error) => {
                NotificationManager.error(
                    error.msj,
                    'Error',
                    2000
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }
    }
}


const eliminarLineaProducto = id => (dispatch) => {
    const detalle = localStorage.getItem('id_detalle_producto_rs');
    dispatch(setLoader(true));
    api.eliminar(`/rs_producto_detalle/${id}`).then(() => {
        dispatch(leerDetalleProductos(detalle));
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar el producto',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const limpiarDatos = () => (dispatch) => {
    detalle = [];
    dispatch(initializeForm('FormProductoRS'));
    dispatch({ type: DETALLE, detallePro: detalle })
};

const eliminarProducto = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`/rs_producto/${id}`).then(() => {
        NotificationManager.success(
            'Producto eliminado correctamente',
            'Exito',
            3000
        );
        dispatch(listar());
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar el producto',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const actions = {
    listar,
    registroProducto,
    modificarProducto,
    leer,
    getModulo,
    listarLineas,
    listarForma,
    listarGrupos,
    listarPrincipios,
    listarFamilias,
    listarPresentacion,
    listarConcentracion,
    agregarLinea,
    eliminarLinea,
    agregarLineaProducto,
    eliminarLineaProducto,
    limpiarDatos,
    eliminarProducto
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_PRODUCTO]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [LISTADO_FORMA]: (state, { listaForm }) => {
        return {
            ...state,
            listaForm,
        };
    },
    [LISTADO_GRUPO]: (state, { listaGrupo }) => {
        return {
            ...state,
            listaGrupo,
        };
    },
    [GUARDAR_PRODUCTO]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
    [LISTADO_PRINCIPIOS]: (state, { listaPrincipio }) => {
        return {
            ...state,
            listaPrincipio,
        };
    },
    [LISTADO_LINEA]: (state, { listaLinea }) => {
        return {
            ...state,
            listaLinea,
        };
    },
    [LISTADO_FAMILIA]: (state, { listaFamilia }) => {
        return {
            ...state,
            listaFamilia,
        };
    },
    [LISTADO_CONCENTRACION]: (state, { listaConcentracion }) => {
        return {
            ...state,
            listaConcentracion,
        };
    },
    [DETALLE]: (state, { detallePro }) => {
        return {
            ...state,
            detallePro,
        };
    },
    [DETALLE_PRODUCTOS]: (state, { detalleProducto }) => {
        return {
            ...state,
            detalleProducto,
        };
    },
};

export const initialState = {
    loader: false,
    modulo: [],
    lectura: [],
    listaForm: [],
    listaVia: [],
    listaGrupo: [],
    data: [],
    listaPrincipio: [],
    listaLinea: [],
    listaFamilia: [],
    listaConcentracion: [],
    detallePro: [],
    detalleProducto: [],
};

export default handleActions(reducers, initialState)
