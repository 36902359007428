import React from "react";
import styled from "styled-components";
import { FicInput, FicDatePicker } from "../../Utils/FormInputComponents";
import Banner from "../common/Banner";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { validar } from "../../components/ValidarNit";
import GenBtn from "../../components/GenBtn";
import InputsContainer from "../common/InputsContainer";
import { crearAgente } from "./actions";

const schema = yup.object({
    dpi: yup
        .number()
        .typeError("Debe de ser un numero")
        .test("dpi-validation", "El dpi no es valido", function (value) {
            return validar.valCui(value.toString())[0].validar;
        })
        .required("El numero de dpi es requerido"),
    nit: yup
        .string()
        .test("nit-validation", "El NIT no es valido", function (value) {
            return validar.valNit(value);
        })
        .required("El nit es requerido"),
    nombre: yup.string().required("El nombre es requerido"),
    telefono: yup
        .number()
        .typeError("Debe de ser un numero (sin separadores)")
        .required("El celular es requerido"),
    direccion: yup.string().required("La direccion es requerida"),
    email: yup
        .string()
        .email("Ingrese un email valido")
        .required("El correo es requerido"),
    fecha_de_alta: yup.mixed().required("La fecha de alta es requerida"),
});

const onSubmit = (data) => {
    crearAgente(data);
};

export default function Crear() {
    const { control, reset, handleSubmit } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),

        defaultValues: {
            dpi: "",
            nit: "",
            nombre: "",
            telefono: "",
            direccion: "",
            email: "",
            fecha_de_alta: "",
            fecha_de_baja: "",
        },
    });

    return (
        <React.Fragment>
            <Banner>Agregar agente</Banner>
            <form onSubmit={handleSubmit(onSubmit)}>
                <InputsContainer>
                    <FicInput
                        style={{ flexGrow: "1", maxWidth: "400px" }}
                        control={control}
                        label="DPI"
                        name="dpi"
                    />
                    <FicInput
                        style={{ flexGrow: "1", maxWidth: "400px" }}
                        control={control}
                        label="Nit"
                        name="nit"
                    />
                    <FicInput
                        control={control}
                        label="Nombre"
                        name="nombre"
                        style={{ minWidth: "400px" }}
                    />
                    <FicInput
                        style={{ flexGrow: "1", maxWidth: "400px" }}
                        control={control}
                        label="Telefono"
                        name="telefono"
                        inputMode="numeric"
                    />
                    <FicInput
                        control={control}
                        label="Direccion"
                        name="direccion"
                        style={{ minWidth: "400px" }}
                    />
                    <FicInput
                        style={{ flexGrow: "1", maxWidth: "400px" }}
                        control={control}
                        label="E-mail"
                        name="email"
                    />
                    <FicDatePicker
                        control={control}
                        label="Fecha de alta"
                        name="fecha_de_alta"
                    />
                    <FicDatePicker
                        control={control}
                        label="Fecha de baja"
                        name="fecha_de_baja"
                    />
                </InputsContainer>
                <GenBtn style={{ margin: "1rem 0" }} type="submit">
                    Guardar
                </GenBtn>
            </form>
        </React.Fragment>
    );
}
