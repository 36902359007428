import React, { Component } from 'react';
import Grid from '../../Utils/GridPolizaFage';

class DetallePoliza extends Component{
    
    render(){
        const { loader,  detalle } = this.props;
        return(
            <React.Fragment>
                {detalle &&
                    <Grid 
                        hover 
                        striped 
                        data={detalle} 
                        loading={loader}
                        footer
                        headerStyle={ { background: 'black' } } 
                        //onSortChange={onSortChange} 
                    >
                    <TableHeaderColumn
                        isKey
                        dataField="centro_costo"
                        width="150"
                        dataSort
                        thStyle={ 
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Centro costo
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="cuenta_contable"
                        dataSort
                        width="150"
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Cuenta contable
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="total_debe"
                        width="150"
                        headerAlign="center"
                        dataAlign='right'
                        dataSort
                        dataFormat={(cell, row)=>{
                            if (parseFloat(cell) === 0.00){
                                return ''
                            }else{
                                return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(cell);    
                            }
                        }}
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Debe
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="total_haber"
                        width="150"
                        headerAlign="center"
                        dataAlign='right'
                        dataSort
                        dataFormat={(cell, row)=>{
                            if (parseFloat(cell) === 0.00){
                                return ''
                            }else{
                                return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(cell);    
                            }
                        }}
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Haber
                    </TableHeaderColumn>
                </Grid>
                }
            </React.Fragment>
        );
    }
}

export default DetallePoliza;
