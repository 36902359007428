import * as yup from "yup";
import { today, getLocalTimeZone } from "@internationalized/date";
import { getSettings } from "../../utils";
import { validar } from "../../../components/ValidarNit";

export function getSchema() {
    const settings = getSettings();
    const schema = yup.object({
        nit: yup
            .number()
            .typeError("Debe de ser un numero (sin guiones)")
            .test("nit-validation", "El nit no es valido", function (value) {
                return settings.empresaSettings.validar_nit === "S"
                    ? validar.valNit(value.toString())
                    : true;
            })
            .notRequired(),
        nombre: yup.string().required("El nombre es requerido"),
        direccion: yup.string().notRequired(),
        email: yup.string().notRequired(),
        telefono: yup.string().notRequired(),
        afiliada: yup.boolean().required("La afiliacion es requerida"),
    });
    return schema;
}

export function getDefaultValues() {
    return {
        nit: "",
        nombre: "",
        direccion: "",
        email: "",
        telefono: "",
        afiliada: false,
    };
}
