import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import LoadMask from "../../Utils/LoadMask/LoadMask";
import NavbarModulo from '../../components/NavbarModulo';
import NavbarModuloLF from '../../components/NavbarModuloFage';
import DatosColaborador from './ColaboradorForm';
import DatosNominaColaborador from './ColaboradorNominaForm';
import DatosAdicionalesColaborador from './ColaboradorFormDatosAd';
import DocumentosColaborador from './ColaboradorDocumentosForm';
import DatosFamiliaresColaborador from './ColaboradorDatosFamiliares';
const valEmp = __CONFIG__.empresa;

class ColaboradorTab extends Component {
    state = {
        crear: true,
        foto: null,
        cv: null,
        dpi: null,
        contrato: null,
        ant_penal: null,
        ant_pol: null,
        const_laboral: null,
        const_estudio: null,
        tarj_ali: null,
        tarj_salud: null,
        carnet_vac: null,
        prueba_psi: null,
        prueba_conf: null,
        poli: null,
        estudio_socioE: null
    }

    componentDidMount = () => {
        const { match, leer, getModulo, listarPaises, vaciar, parametrosEmpresa,
            listarDepartamentos, listarPuestos, listarDocumentos } = this.props;
        const id = match.params.id;
        if (id){
            this.setState({crear: false});
            leer(id);
        }else{
            parametrosEmpresa();
        }
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        if (valEmp === 'fage'){
            getModulo(modulo[6],modulo[5])
        }else{
            getModulo(modulo[5],modulo[4])
        }
        listarPaises();
        listarDepartamentos();
        listarPuestos();
        listarDocumentos();
        vaciar();
        localStorage.removeItem('id_detalle_familia');
    }
    
    setFoto = (foto) => {
        this.setState({foto});
    };

    setCV = (cv) => {
        this.setState({cv});
    };

    setDPI = (dpi) => {
        this.setState({dpi});
    };

    setContrato = (contrato) => {
        this.setState({contrato});
    };

    setAnt_Penales = (ant_penal) => {
        this.setState({ant_penal});
    };

    setAnt_Policiacos = (ant_pol) => {
        this.setState({ant_pol});
    };

    setConst_Laboral = (const_laboral) => {
        this.setState({const_laboral});
    };

    setConst_Estudio = (const_estudio) => {
        this.setState({const_estudio});
    };

    setTarj_Alimentos = (tarj_ali) => {
        this.setState({tarj_ali});
    };

    setTarj_Salud = (tarj_salud) => {
        this.setState({tarj_salud});
    };

    setCarnet_Vac = (carnet_vac) => {
        this.setState({carnet_vac});
    };

    setPrueba_Psi = (prueba_psi) => {
        this.setState({prueba_psi});
    };

    setPrueba_Conf = (prueba_conf) => {
        this.setState({prueba_conf});
    };

    setPoligrafo = (poli) => {
        this.setState({poli});
    };

    setEstudio_SocioE = (estudio_socioE) => {
        this.setState({estudio_socioE});
    };


    registroColaborador = (data) => {
        const { registroColaborador } = this.props;
        registroColaborador( {...data, foto: null},
        [{ "file": this.state.foto, "name": 'foto' }, { "file": this.state.cv, "name": 'cv' }, { "file": this.state.dpi, "name": 'dpi' },
            { "file": this.state.contrato, "name": 'contrato' }, { "file": this.state.ant_penal, "name": 'antecedentes_penales' },
            { "file": this.state.ant_pol, "name": 'antecedentes_policiacos' }, { "file": this.state.const_laboral, "name": 'constancia_laboral' },
            { "file": this.state.const_estudio, "name": 'constancia_estudio' }, { "file": this.state.tarj_ali, "name": 'tarjeta_alimentos' },
            { "file": this.state.tarj_salud, "name": 'tarjeta_salud' }, { "file": this.state.carnet_vac, "name": 'carnet_vacunacion' },
            { "file": this.state.prueba_psi, "name": 'prueba_psico' }, { "file": this.state.prueba_conf, "name": 'prueba_confiabilidad' },
            { "file": this.state.poli, "name": 'poligrafo' }, { "file": this.state.estudio_socioE, "name": 'estudio_socioeconomico' } 
        ])
    }

    modificarColaborador = (data) => {
        const { modificarColaborador } = this.props;
        modificarColaborador( {...data, foto: null},
        [{ "file": this.state.foto, "name": 'foto' }, { "file": this.state.cv, "name": 'cv' }, { "file": this.state.dpi, "name": 'dpi'},
            { "file": this.state.contrato, "name": 'contrato' }, { "file": this.state.ant_penal, "name": 'antecedentes_penales' },
            { "file": this.state.ant_pol, "name": 'antecedentes_policiacos' }, { "file": this.state.const_laboral, "name": 'constancia_laboral' },
            { "file": this.state.const_estudio, "name": 'constancia_estudio' }, { "file": this.state.tarj_ali, "name": 'tarjeta_alimentos' },
            { "file": this.state.tarj_salud, "name": 'tarjeta_salud' }, { "file": this.state.carnet_vac, "name": 'carnet_vacunacion' },
            { "file": this.state.prueba_psi, "name": 'prueba_psico' }, { "file": this.state.prueba_conf, "name": 'prueba_confiabilidad' },
            { "file": this.state.poli, "name": 'poligrafo' }, { "file": this.state.estudio_socioE, "name": 'estudio_socioeconomico' }
        ])
    }


    render() {
        const { listaMuni, loader, modulo, listaP, agregarLinea, datos,
            listarOcupacion, listaDepto, lectura, detalle, detalleFa,
            listarMunicipio, listaDepartamento, listaPuesto, listarDepto,
            eliminarLineaFamilia, agregarNuevaLineaFamilia, eliminarLinea, documentos } = this.props;
        const { crear } = this.state;
        const funcionEnvio = crear ? this.registroColaborador : this.modificarColaborador;
        const editar = window.location.href.includes('editar');
        let titulo = editar ? 'Modificar colaborador' : 'Crear colaborador';
        let disabled = false;
        if (crear === false && editar === false) {
            disabled = true;
            titulo = 'Ver colaborador'
        }
        let ruta = window.location.href;
        let id_emp = ruta.split('/');
        
        return (
            <React.Fragment>
                {valEmp === 'fage' &&
                    <NavbarModuloLF
                        pais = {datos.paisNombre}
                        modulo={modulo}
                        titulo={titulo}
                    />
                }
                {valEmp === '' &&
                    <NavbarModulo
                        pais = {datos.paisNombre}
                        modulo={modulo}
                        titulo={titulo}
                    />
                }
                <div className="py-1">
                    <div className="mb-1">
                        <div className="p-0 px-3 pt-0">
                            <div className='d-flex flex-row mt-3'>
                                {disabled == false &&
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={funcionEnvio}
                                    >
                                        {editar ? 'Modificar' : 'Guardar'}
                                    </button>
                                }
                                {valEmp === 'fage' && id_emp[7] === 'ingreso' &&
                                    <a
                                        href={`/#/lfg/rr_hh/${id_emp[6]}/ingreso/${id_emp[8]}`}
                                        className='btn btn-secondary mb-3'
                                    >
                                        Regresar
                                    </a>
                                }
                                {valEmp === '' && id_emp[6] === 'ingreso' &&
                                    <a
                                        href={`/#/rr_hh/${id_emp[5]}/ingreso/${id_emp[7]}`}
                                        className='btn btn-secondary mb-3'
                                    >
                                        Regresar
                                    </a>
                                }
                                {valEmp === 'fage' && id_emp[7] === 'baja' &&
                                    <a
                                        href={`/#/lfg/rr_hh/${id_emp[6]}/baja/${id_emp[8]}`}
                                        className='btn btn-secondary mb-3'
                                    >
                                        Regresar
                                    </a>
                                }
                                {valEmp === '' && id_emp[6] === 'baja' &&
                                    <a
                                        href={`/#/rr_hh/${id_emp[5]}/baja/${id_emp[7]}`}
                                        className='btn btn-secondary mb-3'
                                    >
                                        Regresar
                                    </a>
                                }
                            </div>
                            {datos.documentos_rrhh === 'S' && 
                                <Tabs
                                    defaultActiveKey="PRINCIPAL_TOP"
                                    tabBarPosition="top"
                                    onChange={this.callback}
                                    renderTabBar={() => <ScrollableInkTabBar />}
                                    renderTabContent={() => <TabContent />}
                                >
                                    <TabPane tab="Generales" key="PRINCIPAL_TOP">
                                        <div className="py-4 px-3">
                                            <div className="mb-4 card card-small text-white bg-dark mb-3">
                                                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                                                    <div className="d-flex flex-column flex-1 mx-4">
                                                        <LoadMask loading={loader} dark>
                                                            <DatosColaborador 
                                                                crear = {crear}
                                                                editar = {editar}
                                                                modulo = {modulo}
                                                                lectura = {lectura}
                                                                obtenerPaises = {listaP}
                                                                listarDepto = {listarDepto}
                                                                listarOcupacion = {listarOcupacion}
                                                                listaDepto = {listaDepto}
                                                                listarMunicipio = {listarMunicipio}
                                                                listaMuni = {listaMuni}
                                                                setFoto = {this.setFoto}
                                                                disabled = {disabled}
                                                                documentos = {documentos}
                                                                datos = {datos}
                                                            />
                                                        </LoadMask>
                                                        <br />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                    <TabPane tab="Nómina" key="SEGUNDO_TOP">
                                        <div className="py-4 px-3">
                                            <div className="mb-4 card card-small text-white bg-dark mb-3">
                                                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                                                    <div className="d-flex flex-column flex-1 mx-4">
                                                        <LoadMask loading={loader} dark>
                                                            <DatosNominaColaborador
                                                                crear = {crear}
                                                                editar = {editar}
                                                                modulo = {modulo}
                                                                lectura = {lectura}
                                                                listaDepartamento = {listaDepartamento}
                                                                listaPuesto = {listaPuesto}
                                                                disabled = {disabled}
                                                            />
                                                        </LoadMask>
                                                        <br />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                    <TabPane tab="Datos adicionales" key="TERCER_TOP">
                                        <div className="py-4 px-3">
                                            <div className="mb-4 card card-small text-white bg-dark mb-3">
                                                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                                                    <div className="d-flex flex-column flex-1 mx-4">
                                                        <LoadMask loading={loader} dark>
                                                            <DatosAdicionalesColaborador
                                                                crear = {crear}
                                                                editar = {editar}
                                                                modulo = {modulo}
                                                                lectura = {lectura}
                                                                disabled = {disabled}
                                                                datos = {datos}
                                                            />
                                                        </LoadMask>
                                                        <br />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                    <TabPane tab="Documentos" key="CUARTO_TOP">
                                        <div className="py-4 px-3">
                                            <div className="mb-4 card card-small text-white bg-dark mb-3">
                                                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                                                    <div className="d-flex flex-column flex-1 mx-4">
                                                        <LoadMask loading={loader} dark>
                                                            <DocumentosColaborador
                                                                crear = {crear}
                                                                editar = {editar}
                                                                setCV = {this.setCV}
                                                                setDPI = {this.setDPI}
                                                                setContrato = {this.setContrato}
                                                                setAntPen = {this.setAnt_Penales}
                                                                setAntPol = {this.setAnt_Policiacos}
                                                                setConstLab = {this.setConst_Laboral}
                                                                setConstEst = {this.setConst_Estudio}
                                                                setTarjAli = {this.setTarj_Alimentos}
                                                                setTarjSalud = {this.setTarj_Salud}
                                                                setCarnetVac = {this.setCarnet_Vac}
                                                                setPruebaP = {this.setPrueba_Psi}
                                                                setPruebaC = {this.setPrueba_Conf}
                                                                setPoligrafo = {this.setPoligrafo}
                                                                setEstudioS = {this.setEstudio_SocioE}
                                                                lectura = {lectura}
                                                            />
                                                        </LoadMask>
                                                        <br />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                    <TabPane tab="Datos familiares" key="QUINTO_TOP">
                                        <div className="py-4 px-3">
                                            <div className="mb-4 card card-small text-white bg-dark mb-3">
                                                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                                                    <div className="d-flex flex-column flex-1 mx-4">
                                                        <LoadMask loading={loader} dark>
                                                            <DatosFamiliaresColaborador
                                                                crear = {crear}
                                                                editar = {editar}
                                                                detalle = {detalle}
                                                                agregarLinea = {agregarLinea}
                                                                disabled = {disabled}
                                                                detalleFa = {detalleFa}
                                                                eliminarLineaFamilia = {eliminarLineaFamilia}
                                                                eliminarLinea={eliminarLinea}
                                                                agregarNuevaLineaFamilia = {agregarNuevaLineaFamilia} 
                                                            />
                                                        </LoadMask>
                                                        <br />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                </Tabs>
                            }
                            {datos.documentos_rrhh === 'N' && 
                                <Tabs
                                    defaultActiveKey="PRINCIPAL_TOP"
                                    tabBarPosition="top"
                                    onChange={this.callback}
                                    renderTabBar={() => <ScrollableInkTabBar />}
                                    renderTabContent={() => <TabContent />}
                                >
                                    <TabPane tab="Generales" key="PRINCIPAL_TOP">
                                        <div className="py-4 px-3">
                                            <div className="mb-4 card card-small text-white bg-dark mb-3">
                                                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                                                    <div className="d-flex flex-column flex-1 mx-4">
                                                        <LoadMask loading={loader} dark>
                                                            <DatosColaborador 
                                                                crear = {crear}
                                                                editar = {editar}
                                                                modulo = {modulo}
                                                                lectura = {lectura}
                                                                obtenerPaises = {listaP}
                                                                listarDepto = {listarDepto}
                                                                listarOcupacion = {listarOcupacion}
                                                                listaDepto = {listaDepto}
                                                                listarMunicipio = {listarMunicipio}
                                                                listaMuni = {listaMuni}
                                                                setFoto = {this.setFoto}
                                                                disabled = {disabled}
                                                                documentos = {documentos}
                                                                datos = {datos}
                                                            />
                                                        </LoadMask>
                                                        <br />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                    <TabPane tab="Nómina" key="SEGUNDO_TOP">
                                        <div className="py-4 px-3">
                                            <div className="mb-4 card card-small text-white bg-dark mb-3">
                                                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                                                    <div className="d-flex flex-column flex-1 mx-4">
                                                        <LoadMask loading={loader} dark>
                                                            <DatosNominaColaborador
                                                                crear = {crear}
                                                                editar = {editar}
                                                                modulo = {modulo}
                                                                lectura = {lectura}
                                                                listaDepartamento = {listaDepartamento}
                                                                listaPuesto = {listaPuesto}
                                                                disabled = {disabled}
                                                            />
                                                        </LoadMask>
                                                        <br />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                    <TabPane tab="Datos adicionales" key="TERCER_TOP">
                                        <div className="py-4 px-3">
                                            <div className="mb-4 card card-small text-white bg-dark mb-3">
                                                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                                                    <div className="d-flex flex-column flex-1 mx-4">
                                                        <LoadMask loading={loader} dark>
                                                            <DatosAdicionalesColaborador
                                                                crear = {crear}
                                                                editar = {editar}
                                                                modulo = {modulo}
                                                                lectura = {lectura}
                                                                disabled = {disabled}
                                                                datos = {datos}
                                                            />
                                                        </LoadMask>
                                                        <br />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                    <TabPane tab="Datos familiares" key="QUINTO_TOP">
                                        <div className="py-4 px-3">
                                            <div className="mb-4 card card-small text-white bg-dark mb-3">
                                                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                                                    <div className="d-flex flex-column flex-1 mx-4">
                                                        <LoadMask loading={loader} dark>
                                                            <DatosFamiliaresColaborador
                                                                crear = {crear}
                                                                editar = {editar}
                                                                detalle = {detalle}
                                                                agregarLinea = {agregarLinea}
                                                                disabled = {disabled}
                                                                detalleFa = {detalleFa}
                                                                eliminarLineaFamilia = {eliminarLineaFamilia}
                                                                eliminarLinea={eliminarLinea}
                                                                agregarNuevaLineaFamilia = {agregarNuevaLineaFamilia} 
                                                            />
                                                        </LoadMask>
                                                        <br />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                </Tabs>
                            }
                        </div>
                    </div>
                </div>
           </React.Fragment>
        )
    }
}

export default reduxForm({
    form: 'ColaboradorTab', //identificador unico
})(ColaboradorTab)
