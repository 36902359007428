import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';
const valEmp = __CONFIG__.empresa;

const LISTADO_PAISES = 'LISTADO_PAISES';
const LISTADO_PAIS = 'LISTADO_PAIS';
const PAIS = 'PAIS';
const LOADER = 'LOADER';
const MODULO = 'MODULO';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params;
    let id;
    if (valEmp === 'fage'){
        id = id_emp[6];
        params = { id };
    }else{
        id = id_emp[5];
        params = { id };
    }
    dispatch(setLoader(true));
    api.get('/pais_registro', params).then((response)=>{
        dispatch({ type: LISTADO_PAISES, data: response });
        
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar paises',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const registroPais = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormPaisRS.values;
    let id_empresa = '';
    if (valEmp === 'fage'){
        id_empresa = id_emp[6]
    }else{
        id_empresa = id_emp[5]
    }
    if (data === undefined){
        Swal.fire({
            title: 'Pais',
            text: 'Debe de seleccionar pais',
            type: 'error',
        })
    }else{
        if (data.tiempo === undefined || parseInt(data.tiempo) === 0){
            Swal.fire({
                title: 'Pais',
                text: 'Debe de ingresar tiempo registro',
                type: 'error',
            })
        }else{
            if (data.tiempo_renovacion === undefined || parseInt(data.tiempo_renovacion) === 0){
                Swal.fire({
                    title: 'Pais',
                    text: 'Debe de ingresar tiempo de renovación',
                    type: 'error',
                })
            }else{
                const formData = {
                    pais_registro_sanitario: data.pais,
                    empresa: id_empresa,
                    tiempo: data.tiempo,
                    tiempo_renovacion: data.tiempo_renovacion
                }
                dispatch(setLoader(true));
                api.post('/pais_registro/', formData).then(() => {
                    NotificationManager.success(
                    'Pais creado',
                    'Exito',
                    3000
                );
                let ruta = '';
                if (valEmp === 'fage'){
                    ruta = `/lfg/registro_sanitario/${id_emp[6]}/pais`
                    dispatch(push(ruta));
                }else{
                    ruta = `/registro_sanitario/${id_emp[5]}/pais`
                    dispatch(push(ruta));
                }
                }).catch((error) => {
                NotificationManager.error(
                    error.msj,
                    'Error',
                    2000
                );
                }).finally(() => {
                    dispatch(setLoader(false));
                });
            }
        }
    }
}

export const modificarPais = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const datos = getStore().form.FormPaisRS.values;
    let pais = datos.pais;
    if (pais === undefined){
        pais = datos.pais_registro_sanitario;
    }else{
        pais = datos.pais;
    }
    const id = datos.id;
    if (datos.tiempo === undefined || parseInt(datos.tiempo) === 0){
        Swal.fire({
            title: 'Pais',
            text: 'Debe de ingresar tiempo registro',
            type: 'error',
        })
    }else{
        if (datos.tiempo_renovacion === undefined || parseInt(datos.tiempo_renovacion) === 0){
            Swal.fire({
                title: 'Pais',
                text: 'Debe de ingresar tiempo de renovación',
                type: 'error',
            })
        }else{
            const formData = {
                id: id,
                pais_registro_sanitario: pais,
                tiempo: datos.tiempo,
                tiempo_renovacion: datos.tiempo_renovacion

            }
            dispatch(setLoader(true));
            api.put(`/pais_registro/${id}/`, formData).then(() => {
                NotificationManager.success(
                    'Pais modificado correctamente',
                    'Exito',
                    3000
                );
                let ruta = '';
                if (valEmp === 'fage'){
                    ruta = `/lfg/registro_sanitario/${id_emp[6]}/pais`
                    dispatch(push(ruta));
                }else{
                    ruta = `/registro_sanitario/${id_emp[5]}/pais`
                    dispatch(push(ruta));
                }
            }).catch((error) => {
                NotificationManager.error(
                    'Ocurrió un error al modificar pais',
                    'Error',
                    2000
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }
    }
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/pais_registro/${id}`).then((response) => {
        dispatch(initializeForm('FormPaisRS', response ));
        dispatch({ type: PAIS, lectura: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar pais',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const listarPaises = () => (dispatch) => {
    return api.get("/paises").then(response => {
        const pais = [];
        response.results.forEach(item => {
            pais.push({
                value: item.id,
                label: item.pais.toUpperCase()
            })
        })
        dispatch({ type: LISTADO_PAIS, listaP: pais });
    }).catch(error => {
        NotificationManager.error(
            'Ocurrió un error al listar paises',
            'Error',
            8000
        );
    })
}


export const actions = {
    listar,
    registroPais,
    modificarPais,
    leer,
    getModulo,
    listarPaises,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_PAISES]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [LISTADO_PAIS]: (state, { listaP }) => {
        return {
            ...state,
            listaP,
        };
    },
    [PAIS]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
};

export const initialState = {
    loader: false,
    modulo: [],
    pais: [],
    lectura: [],
    listaP: [],
    data: [],
};

export default handleActions(reducers, initialState)
 