
import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';
import moment from 'moment';

const LISTADO_ORDEN = 'LISTADO_ORDEN';
const LOADER = 'LOADER';
const MODULO = 'MODULO';
const PERIODO_D = 'PERIODOD';
const PARAMETRO = 'PARAMETRO';
const DETALLE = 'DETALLE';
const DETALLE_ORDEN = 'DETALLE_ORDEN';
const PRODUCTOS = 'PRODUCTOS';

const setLoader = loader => ({
    type: LOADER,
    loader,
});


export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let id_period = id_emp[7];
    const params = { id, id_period };
    dispatch(setLoader(true));
    api.get('/inv_consumo_producto', params).then((response) => {
        dispatch({ type: LISTADO_ORDEN, data: response });
    
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar consumo de material',
            'Error',
            3000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}


export const leerPeriodoD = (id, id_periodo) => (dispatch) => {
    const params = { id, id_periodo };
    api.get('/periodos/periodo', params).then((response) => {
        dispatch({ type: PERIODO_D, periodoD: response.periodo });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar periodo',
            'Error',
            2000
        );
    })
}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        if (error.status === 401){
            NotificationManager.error(
              error.body.msj,
              'Error',
              0
            );
        }
        if (error.status === 400){
            NotificationManager.error(
              'Ocurrió un error en buscar modulo',
              'Error',
              0
            );
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


export const parametrosEmpresa = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id }
    dispatch(setLoader(true));
    api.get('/empresa/empresa_parametros', params).then((response) => {
        dispatch({ type: PARAMETRO, datos: response });
    }).catch((error) => {

        NotificationManager.error(
            'Ocurrió un error al consultar parametros',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const listarClientes = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id, search}
    return api.get('/cliente/empresa_clientes',params).then(data=>{
        const list_cliente = [];
        if(data){
            data.forEach(item=>{
                list_cliente.push({
                    value: item.id,
                    label: item.identificador + ' ' + item.nombre
                })
            })
        }
        return list_cliente ;
    }).catch((error)=>{
        
        return [];
    })
}


let detalle = [];
export const agregarLinea = (periodoD, datos) => (dispatch, getStore) => {
    const data = getStore().form.FormConsumoProductoINV.values;

    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fecha);

    if (fecha >= fechaI && fecha <= fechaF) {
        if (data.cantidad === 0.00 || data.cantidad === undefined) {
            Swal.fire({
                title: 'Consumo de material',
                text: 'Debe de ingresar cantidad',
                type: 'error',
            })
        }else{
            if (data.bodega === undefined || data.bodega === null) {
                Swal.fire({
                    title: 'Consumo de material',
                    text: 'Debe de selecionar una bodega',
                    type: 'error',
                })
            } else {
                if (data.producto === undefined || data.producto === null) {
                    Swal.fire({
                        title: 'Consumo de material',
                        text: 'Debe de selecionar una bodega',
                        type: 'error',
                    })
                } else {
                    let prod = data.producto.value.split("#");
                    let exis = parseFloat(prod[1].trim())
                    if (datos.movimiento_s_existencia === 'N'){
                        if (parseFloat(data.cantidad)<=exis){
                            if (detalle.length > 0) {
                                const dato = detalle.find(item => item.id === prod[0].trim());
                                if (dato) {
                                    NotificationManager.error(
                                        'Producto ya fue ingresado',
                                        'Error',
                                        3000
                                    );
                                } else {
                                    detalle.push({
                                        id: prod[0].trim(),
                                        cantidad: data.cantidad,
                                        idPro: prod[0].trim(),
                                        sku: prod[2].trim(),
                                        idBodega: data.bodega.value,
                                        productoN: prod[3].trim(),
                                    })
                                    data.cantidad = 0.00;
                                    data.detalleD = detalle;
                                    NotificationManager.success(
                                        'Producto ingresado correctamente',
                                        'Exito',
                                        3000
                                    );
                                    dispatch(actualizar());
                                }
                            } else {
                                detalle.push({
                                    id: prod[0].trim(),
                                    cantidad: data.cantidad,
                                    idPro: prod[0].trim(),
                                    sku: prod[2].trim(),
                                    idBodega: data.bodega.value,
                                    productoN: prod[3].trim(),
                                })
                                data.cantidad = 0.00;
                                data.detalleD = detalle;
                                NotificationManager.success(
                                    'Producto ingresado correctamente',
                                    'Exito',
                                    3000
                                );
                                dispatch(actualizar());
                            }
                        }else {
                            Swal.fire({
                                title: 'Consumo de material',
                                text: 'La cantidad ingresada es mayor a la existencia',
                                type: 'error',
                            })      
                        }
                    }
                }
            }
        }
    }else {
        Swal.fire({
            title: 'Consumo de material',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })
    }
}

const actualizar = () => (dispatch, getStore) => {
    const data = getStore().form.FormConsumoProductoINV.values;
    dispatch(initializeForm('FormConsumoProductoINV', data));
    dispatch({ type: DETALLE, detalleOrden: data.detalleD })
};

const limpiarOrden = () => (dispatch) => {
    detalle = [];
    dispatch({ type: DETALLE, detalleOrden: detalle })
    dispatch({type: PRODUCTOS, pro: [] })
};


export const registroOrden = (periodoD, detalleO) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormConsumoProductoINV.values;
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fecha)
    if (fecha>=fechaI && fecha<=fechaF){
        if (data.proyecto === undefined || data.proyecto === null) {
            Swal.fire({
                title: 'Consumo de material',
                text: 'Debe de seleccionar proyecto',
                type: 'error',
            })
        } else {
            if (detalleO.length === 0) {
                Swal.fire({
                    title: 'Consumo de material',
                    text: 'Debe de ingresar productos',
                    type: 'error',
                })
            } else {
                const formData = {
                    fecha: data.fecha,
                    proyecto: data.proyecto.value,
                    observaciones: data.observaciones,
                    detalleOrdenServicio: data.detalleD,
                    empresa: id_emp[5],
                    periodo: id_emp[7]
                }
                dispatch(setLoader(true));
                api.post('/inv_consumo_producto/', formData).then((response) => {
                    NotificationManager.success(
                        'Orden de servicio guardada correctamente',
                        'Exito',
                        3000
                    );
                    let ruta = `/inventario/${id_emp[5]}/consumo_producto/${id_emp[7]}`
                    dispatch(push(ruta));
                    /*Swal.fire({
                        title: '¿Imprimir orden de compra?',
                        text: '¡Desea imprimir orden de compra!',
                        type: 'question',
                        showCancelButton: true,
                        confirmButtonText: '¡Sí, imprimir!',
                        cancelButtonText: 'No, cancelar',
                        reverseButtons: true
                    }).then((result) => {
                        if (result.value) {
                            let reporte = 'imprimirOrden';
                            let idOrden = response.id;
                            let id = id_emp[5];
                            const params = { id, reporte, idOrden };
                            dispatch(setLoader(true));
                            api.getPdf('/orden_compra', params).then((response)=>{
                                let ruta = `/compras/${id_emp[5]}/orden_compra/${id_emp[7]}`
                                dispatch(push(ruta));
                                if (response.headers['content-type'] == 'application/pdf'){
                                    var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
                                    var link = document.createElement('a');
                                    link.href = window.URL.createObjectURL(blob);
                                    link.download = 'cheque.pdf';
                                    window.open(link, '_blank');
                                    return false;
                                }
                                
                            }).catch((error)=>{
                                NotificationManager.error(
                                    'Ocurrió un error al generar reporte',
                                    'Error',
                                    2000
                                );
                            }).finally(() => {
                                dispatch(setLoader(false));
                            });
                        }else {
                            window.location.reload(true);
                        }
                    });*/
                }).catch((error) => {
                    NotificationManager.error(
                        error.msj,
                        'Error',
                        0
                    );
                }).finally(() => {
                    dispatch(setLoader(false));
                });
            }   
        }
    }else {
        Swal.fire({
            title: 'Orden de compra',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })    
    }
}

export const imprimirOrden = (idOrden) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let reporte = 'imprimirConsumoProducto';
    const params = { id, reporte, idOrden };
    dispatch(setLoader(true));
    api.getPdf('/inv_consumo_producto', params).then((response)=>{
        if (response.headers['content-type'] == 'application/pdf'){
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'consumo_material.pdf';
            window.open(link, '_blank');
            return false;
        }
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al generar reporte',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

const leerDetalleOrden = (proyecto, id) => (dispatch) => {
    let params = { proyecto, id }
    api.get('/orden_servicio_detalle/consumo_producto/', params).then((response) => {
        dispatch({ type: DETALLE_ORDEN, detalleOrd: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/inv_consumo_producto/${id}`).then((response) => {
        dispatch(leerDetalleOrden(response.proyecto, response.id));
        dispatch(initializeForm('FormConsumoProductoINV', response));
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar consumo de material',
            'Error',
            3000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const eliminarLineaProductoNuevo = (id) => (dispatch, getStore) => {
    const data = getStore().form.FormConsumoProductoINV.values;
    
    detalle.forEach((element, index) => {
        if (element.id === id) {
            detalle.splice(index, 1);
        }
    })
    data.detalleD = detalle;
    dispatch(actualizar());
};

export const leerProductos = (id, datos) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let idEmpre = id_emp[5];
    
    if (datos.agricola === 'S'){
        const params = {id, idEmpre }
        api.get('/producto/empresa_productos_cod_inventario_bodega_prd/',params).then((response) => {
            const producto = [];
            response.forEach(item=>{
                if (item.tipoPro === 'P'){
                    producto.push({
                        value: item.id+' # '+ item.saldo + '#' + item.sku + '#' + item.productoN,
                        label: item.sku + ' / ' + item.productoN + ' -- ' + ' Existencia: ' + item.saldo
                    })
                }
            })
            dispatch({type: PRODUCTOS, pro: producto })
        })    
    
    }
    if (datos.agricola === 'N'){
        let form = 'F'
        const params = {id, idEmpre, form }
        api.get('/producto/empresa_productos_cod_inventario_bodega/',params).then((response) => {
            const producto = [];
            response.forEach(item=>{
                if (item.tipoPro === 'P'){
                    producto.push({
                        value: item.id+' # '+ item.saldo + '#' + item.sku + '#' + item.productoN,
                        label: item.sku + ' / ' + item.productoN + ' -- ' + ' Existencia: ' + item.saldo
                    })
                }
            })
            dispatch({type: PRODUCTOS, pro: producto })
        })    
    }
    
}

export const listarBodegas = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search };
    return api.get('/bodega', params).then((response) => {
        const bodega = [];
        if (response){
            response.forEach(item=>{
                bodega.push({
                    value: item.id,
                    label: item.numero + ' / ' + item.bodega
                })
            })
        }
        return bodega;
    }).catch((error) => {
        return [];
    })
}

//Ordenes de servicio
export const listarProyectos = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id, search}
    return api.get('/orden_servicio/proyectos',params).then(data=>{
        const list_proyectos = [];
        if(data){
            data.forEach(item=>{
                list_proyectos.push({
                    value: item.id,
                    label: 'IOS' + ' -- ' + item.numero + ' / ' + item.nombre_proyecto
                })
            })
        }
        return list_proyectos;
    }).catch((error)=>{
        
        return [];
    })
}

export const anularConsumo = (id) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let idE = id_emp[5];
    let idOrden = id;
    const params = { idE, idOrden };
    dispatch(setLoader(true));
    api.put('/inv_consumo_producto/anular/', params).then(() => {
        NotificationManager.success(
            'Consumo de material anulado correctamente',
            'Exito',
            3000
        );
        dispatch(listar());
    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            3000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const actions = {
    listar,
    registroOrden,
    leer,
    limpiarOrden,
    leerProductos,
    listarClientes,
    leerPeriodoD,
    getModulo,
    parametrosEmpresa,
    agregarLinea,
    imprimirOrden,
    eliminarLineaProductoNuevo,
    listarBodegas,
    listarProyectos,
    anularConsumo
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_ORDEN]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [PERIODO_D]: (state, { periodoD }) => {
        return {
            ...state,
            periodoD,
        };
    },
    [PARAMETRO]: (state, { datos }) => {
        return {
            ...state,
            datos,
        };
    },
    [DETALLE]: (state, { detalleOrden }) => {
        return {
            ...state,
            detalleOrden,
        };
    },
    [DETALLE_ORDEN]: (state, { detalleOrd }) => {
        return {
            ...state,
            detalleOrd,
        };
    },
    [PRODUCTOS]: (state, { pro }) => {
        return {
            ...state,
            pro,
        };
    },
};

export const initialState = {
    loader: false,
    tipos: [],
    periodoS: null,
    monedas: [],
    modulo: [],
    periodoD: [],
    data: [],
    detalleOrden: [],
    detalleOrd: [],
    pro: []
};

export default handleActions(reducers, initialState)