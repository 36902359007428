import React from "react";
import styled from "styled-components";
import { FicInput } from "../../Utils/FormInputComponents";
import Banner from "../common/Banner";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { validar } from "../../components/ValidarNit";
import GenBtn from "../../components/GenBtn";
import InputsContainer from "../common/InputsContainer";
import {getVehiculo,  } from "./actions";
import { withRouter } from "react-router";

const schema = yup.object({
    nit: yup
        .string()
        .test("nit-validation", "El NIT no es valido", function (value) {
            return validar.valNit(value);
        })
        .required("El nit es requerido"),
    nombre: yup.string().required("El nombre es requerido"),
    celular: yup
        .number()
        .typeError("Debe de ser un numero (sin separadores)")
        .required("El celular es requerido"),
    direccion: yup.string().required("La direccion es requerida"),
    email: yup
        .string()
        .email("Ingrese un email valido")
        .required("El correo es requerido"),
    telefono: yup
        .number()
        .typeError("Debe de ser un numero (sin separadores)")
        .required("El telefono es requerido"),
});


function Ver({ history, ...props }) {
    const params = props.match.params;
    const vehiculoId = params.vehiculoId;
    
    const { control } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: async () => {
            const formData = await getVehiculo(vehiculoId);
            // setLoadingState(false);
            return formData;
        },
    });


    return (
        <React.Fragment>
            <Banner>Ver</Banner>
                <GenBtn style={{ margin: "1rem 0" }} onPress={() => history.goBack()}>
                    Regresar
                </GenBtn>
            <form onSubmit={() => {}}>
            <InputsContainer>
                    <FicInput
                        control={control}
                        label="Placa"
                        name="placa"
                        isDisabled
                    />
                    <FicInput
                        control={control}
                        label="Marca"
                        name="marca"
                        isDisabled
                    />
                    <FicInput
                        control={control}
                        label="Linea"
                        name="linea"
                        inputMode="numeric"
                        isDisabled
                    />
                    <FicInput
                        control={control}
                        label="Modelo"
                        name="modelo"
                        isDisabled
                    />
                    <FicInput
                        control={control}
                        label="Chasis"
                        name="chasis"
                        isDisabled
                    />
                    <FicInput
                        control={control}
                        label="Motor"
                        name="motor"
                        isDisabled
                    />
                </InputsContainer>
            </form>
        </React.Fragment>
    );
}

export default withRouter(Ver);
