import React, { Component } from 'react';
import Formulario from './SeleccionarPaisForm';


class SeleccionarPais extends Component{

    componentDidMount = () => {
        const { listarPaises } = this.props;
        listarPaises();
    }
    render(){
        const { pais } = this.props;
        return(
           
            <Formulario
                pais = {pais}
            />
           
        );
    } 
}

export default SeleccionarPais;