import React, { Component } from 'react';
import moment from 'moment';

class Footer extends Component {
        
    render() {
        let per = localStorage.getItem('info_periodo');
        let fechaI = localStorage.getItem('fechaI');
        let fechaF = localStorage.getItem('fechaF');
        let periodo = '';
        if (per && fechaI && fechaF){
            periodo = per+' ['+' '+moment(fechaI).format("DD/MM/YYYY")+' - '+moment(fechaF).format("DD/MM/YYYY")+' '+']'
        }
        return (
            <footer className="bg-foo p-3 px-3 bg-white border-top">
                <div className="row px-3"> 
                    <div className="col-md-4">
                        <a className="link-aconsi" href="https://aconsi.com/" target="_blank"><strong>ACONSI</strong></a>
                    </div>
                    <div className="col-md-4 offset-md-4">
                        <span className=''>{periodo}</span>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
