import React from 'react';
import Select, { Creatable, Async } from 'react-select';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import Switch from 'react-switch';
import DayPicker from '../DayPicker';
import FileUploader from '../FileUploader/FileUploader';
import FileUploaderB from '../FileUploader/FileUploaderB';
import FileUploaderC from '../FileUploader/FileUploaderC';
import FileUploaderRH from '../FileUploader/FileUploaderRH';
import FileUploaderFotoRH from '../FileUploader/FileUploaderFotoRH';
import DatePicker from "react-date-picker";
import _ from "lodash";


export const renderField = ({
    input, placeholder, type, meta: { touched, error }, msj, disabled = false, autoFocus=false, onKeyDown
}) => {
    const invalid = touched && error;
    return (
        <div className="form-floating">
            <input
                {...input}
                placeholder={placeholder}
                type={type}
                autocomplete="off"
                disabled={disabled}
                autoFocus={autoFocus}
                onKeyDown={onKeyDown}
                className={classNames('form-control', { 'is-invalid': invalid })}

            />
            <label className='form-label' htmlfor={msj}>{msj}</label>
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderFieldPoliza = ({
    input, placeholder, type, meta: { touched, error }, msj
}) => {
    const invalid = touched && error;
    return (
        <div>
            <input
                {...input}
                placeholder={placeholder}
                type={type}
                autocomplete="off"
                className={classNames('form-control', { 'is-invalid': invalid })}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderTextArea = ({
    input, placeholder, rows, msj, disabled, meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <div className="form-floating">
            <textarea
                {...input}
                placeholder={placeholder}
                disabled={disabled}
                style={{ resize: 'none',height: '100px' }}
                rows={rows || 3}
                className={classNames('form-control', { 'is-invalid': invalid })}
            />
            <label className='form-label' htmlfor={msj}>{msj}</label>
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderNumber = ({
    input, decimalScale, placeholder, meta: { touched, error }, prefix = "", suffix = "", numberFormat, msj, 
    disabled,  autoFocus=false
}) => {
    const invalid = touched && error;
    return (
        <div className="form-floating">
            <NumberFormat
                autoFocus={autoFocus}
                placeholder={placeholder}
                className={classNames('form-control', { 'is-invalid': invalid })}
                decimalScale={decimalScale || 0}
                format={numberFormat}
                fixedDecimalScale
                value={input.value}
                thousandSeparator
                prefix={prefix}
                suffix={suffix}
                disabled={disabled}
                onValueChange={(values) => {
                    input.onChange(values.value);
                }}
            />
            <label className='form-label' htmlfor={msj}>{msj}</label>
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderCurrency = ({
    input, meta: { touched, error }, prefix = "Q ", placeholder,
}) => {
    const invalid = touched && error;
    return (
        <div>
            <NumberFormat
                className={classNames('form-control', { 'is-invalid': invalid })}
                decimalScale={2}
                fixedDecimalScale
                placeholder={placeholder}
                value={input.value}
                thousandSeparator
                prefix={prefix}
                onValueChange={(values) => {
                    input.onChange(values.value);
                }}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderCurrencyFloat = ({
    input, meta: { touched, error }, prefix = "", placeholder, msj, disabled
}) => {
    const invalid = touched && error;
    return (
        <div className="form-floating">
            <NumberFormat
                className={classNames('form-control', { 'is-invalid': invalid })}
                decimalScale={2}
                fixedDecimalScale
                placeholder={placeholder}
                value={input.value}
                disabled={disabled}
                thousandSeparator
                prefix={prefix}
                onValueChange={(values) => {
                    input.onChange(values.value);
                }}
            />
            <label className='form-label' htmlfor={msj}>{msj}</label>
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderCurrencyFloatA = ({
    input, meta: { touched, error }, prefix = "", placeholder, msj, dato, detail, disabled
}) => {
    const invalid = touched && error;
    let val = detail === 'false' ? dato : 0.00
    return (
        <div className="form-floating">
            <NumberFormat
                className={classNames('form-control', { 'is-invalid': invalid })}
                decimalScale={2}
                fixedDecimalScale
                placeholder={placeholder}
                value={val}
                thousandSeparator
                disabled={disabled}
                prefix={prefix}
                onValueChange={(values) => {
                    input.onChange(values.value);
                }}
            />
            <label className='form-label' htmlfor={msj}>{msj}</label>
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderFieldA = ({
    input, placeholder, type, meta: { touched, error }, msj, disabled = false, autoFocus=false, onKeyDown, dato, detail
}) => {
    const invalid = touched && error;
    let val = detail === 'false' ? dato : ''
    return (
        <div className="form-floating">
            <input
                placeholder={placeholder}
                type={type}
                autocomplete="off"
                value={val}
                disabled={disabled}
                autoFocus={autoFocus}
                onKeyDown={onKeyDown}
                className={classNames('form-control', { 'is-invalid': invalid })}

            />
            <label className='form-label' htmlfor={msj}>{msj}</label>
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderCurrencyFloatG = ({
    input, meta: { touched, error }, prefix = "", placeholder, msj, disabled
}) => {
    const invalid = touched && error;
    return (
        <div className="form-floating">
            <NumberFormat
                className={classNames('form-control', { 'is-invalid': invalid })}
                decimalScale={3}
                fixedDecimalScale
                placeholder={placeholder}
                value={input.value}
                thousandSeparator
                prefix={prefix}
                disabled={disabled}
                onValueChange={(values) => {
                    input.onChange(values.value);
                }}
            />
            <label className='form-label' htmlfor={msj}>{msj}</label>
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderCurrencyFloatTC = ({
    input, meta: { touched, error }, prefix = "", placeholder, msj, disabled
}) => {
    const invalid = touched && error;
    return (
        <div className="form-floating">
            <NumberFormat
                className={classNames('form-control', { 'is-invalid': invalid })}
                decimalScale={6}
                fixedDecimalScale
                placeholder={placeholder}
                value={input.value}
                thousandSeparator
                prefix={prefix}
                disabled={disabled}
                onValueChange={(values) => {
                    input.onChange(values.value);
                }}
            />
            <label className='form-label' htmlfor={msj}>{msj}</label>
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderCurrencyFloatPrecioVenta = ({
    input, meta: { touched, error }, prefix = "", placeholder, msj, disabled
}) => {
    const invalid = touched && error;
    return (
        <div className="form-floating">
            <NumberFormat
                className={classNames('form-control', { 'is-invalid': invalid })}
                decimalScale={5}
                fixedDecimalScale
                placeholder={placeholder}
                value={input.value}
                thousandSeparator
                prefix={prefix}
                disabled={disabled}
                onValueChange={(values) => {
                    input.onChange(values.value);
                }}
            />
            <label className='form-label' htmlfor={msj}>{msj}</label>
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderCurrencyPoliza = ({
    input, meta: { touched, error }, placeholder,
}) => {
    const invalid = touched && error;
    return (
        <div>
            <NumberFormat
                className={classNames('form-control', { 'is-invalid': invalid })}
                decimalScale={2}
                fixedDecimalScale
                placeholder={placeholder}
                value={input.value}
                thousandSeparator
                onValueChange={(values) => {
                    input.onChange(values.value);
                }}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};


export const renderSwitch = ({
    input, meta: { touched, error }, label, disabled,
}) => {
    const invalid = touched && error;
    return (
        <div className="d-flex align-items-center">
            <Switch
                onColor="#007bff"
                height={18}
                width={36}
                disabled={disabled}
                onChange={(value) => {
                    input.onChange(value);
                }}
                checked={input.value ? input.value : false}
            // id="normal-switch"
            />
            &nbsp;{label}
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderFieldCheck = ({ input, label, value, disabled, type, crear, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <div className="checkbox c-checkbox">
                <label className="needsclick">
                    <input
                        type="checkbox"
                        disabled={disabled}
                        {...input}
                        checked={input.value} 
                        className={classNames('', { 'is-invalid': invalid })}
                    />
                    <span className="fa fa-check" />
                    &nbsp;{label}
                </label>
            </div>
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    )
};

export const renderFieldCheckB = ({ input, label, value, disabled, type, checked, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <div className="checkbox c-checkbox">
                <label className="needsclick">
                    <input
                        type="checkbox"
                        disabled={disabled}
                        {...input}
                        checked={checked} 
                        className={classNames('', { 'is-invalid': invalid })}
                    />
                    <span className="fa fa-check" />
                    &nbsp;{label}
                </label>
            </div>
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    )
};


export const renderFieldRadio = ({ input, label, value, checked, disabled, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <div className="radio c-radio c-radio-nofont d-flex">
                <label className="negro font-weight-normal">
                    <input
                        type="radio"
                        disabled={disabled}
                        {...input}
                        checked={checked}
                        className={classNames('', { 'is-invalid': invalid })}
                    />
                    <span />
                    &nbsp;{label}
                </label>
            </div>
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    )
};

export const SelectField = (
    {
        input,
        disabled = false,
        options,
        crear,
        dato,
        placeholder,
        autoFocus = false,
        msj,
        meta: { touched, error }
    }) => {
    const invalid = touched && error;
    const _options = [];
    /*options.map(option => {
        _options.push({ ...option, value: option[valueKey], label: option[labelKey] });
    });*/
    let value = input.value;
    if (value !== null && value !== undefined) {
        value = _.find(_options, { value });
    }
    
    return (
        <React.Fragment>
            <div className="form-floating">
                <select
                    //isClearable={isClearable}
                    className={classNames('form-select', { 'is-invalid': invalid })}
                    //backspaceRemovesValue={false}
                    //isMulti={isMulti}
                    //isSearchable={isSearchable}
                    autoFocus={autoFocus}
                    options={options}
                    crear={crear}
                    dato={dato}
                    placeholder={placeholder}
                    onChange={(e) => { 
                        input.onChange(
                        e.target.value ? e.target.value : null); 
                    }}
                    value={value}
                    disabled={disabled}
                >
                    <option value={0}>Seleccionar...</option>
                        {options.map(ele => 
                            <option key={ele.value} value={ele.value} 
                                selected={crear === true ? 0 :
                                ele.value === dato ? dato : 0}>{ele.label}</option>  
                        )}
                </select>
                <label className='form-label' htmlfor={msj}>{msj}</label>
                {invalid && (
                    <div className="invalid-feedback">
                        {error}
                    </div>
                )}
            </div>
        </React.Fragment>
    )
};

export const SelectFieldReporte = (
    {
        input,
        disabled = false,
        options,
        crear,
        dato,
        placeholder,
        autoFocus = false,
        msj,
        meta: { touched, error }
    }) => {
    const invalid = touched && error;
    const _options = [];
    /*options.map(option => {
        _options.push({ ...option, value: option[valueKey], label: option[labelKey] });
    });*/
    let value = input.value;
    if (value !== null && value !== undefined) {
        value = _.find(_options, { value });
    }
    
    return (
        <React.Fragment>
            <div className="form-floating">
                <select
                    //isClearable={isClearable}
                    className={classNames('form-select', { 'is-invalid': invalid })}
                    //backspaceRemovesValue={false}
                    //isMulti={isMulti}
                    //isSearchable={isSearchable}
                    autoFocus={autoFocus}
                    options={options}
                    crear={crear}
                    dato={dato}
                    placeholder={placeholder}
                    onChange={(e) => { 
                        input.onChange(
                        e.target.value ? e.target.value : null); 
                    }}
                    value={value}
                    disabled={disabled}
                >
                    <option value={0}>Todos</option>
                        {options.map(ele => 
                            <option key={ele.value} value={ele.value} 
                                selected={crear === true ? 0 :
                                ele.value === dato ? dato : 0}>{ele.label}</option>  
                        )}
                </select>
                <label className='form-label' htmlfor={msj}>{msj}</label>
                {invalid && (
                    <div className="invalid-feedback">
                        {error}
                    </div>
                )}
            </div>
        </React.Fragment>
    )
};

export const SelectFieldReporteBusqueda = (
    {
        input,
        disabled = false,
        options,
        crear,
        dato,
        placeholder,
        autoFocus = false,
        msj,
        meta: { touched, error }
    }) => {
    const invalid = touched && error;
    const _options = [];
    /*options.map(option => {
        _options.push({ ...option, value: option[valueKey], label: option[labelKey] });
    });*/
    let value = input.value;
    if (value !== null && value !== undefined) {
        value = _.find(_options, { value });
    }
    
    return (
        <React.Fragment>
            <div className="form-floating">
                <select
                    //isClearable={isClearable}
                    className={classNames('form-select', { 'is-invalid': invalid })}
                    //backspaceRemovesValue={false}
                    //isMulti={isMulti}
                    //isSearchable={isSearchable}
                    autoFocus={autoFocus}
                    options={options}
                    crear={crear}
                    dato={dato}
                    placeholder={placeholder}
                    onChange={(e) => { 
                        input.onChange(
                        e.target.value ? e.target.value : null); 
                    }}
                    value={value}
                    disabled={disabled}
                >
                    <option value={0}></option>
                        {options.map(ele => 
                            <option key={ele.value} value={ele.value} 
                                selected={crear === true ? 0 :
                                ele.value === dato ? dato : 0}>{ele.label}</option>  
                        )}
                </select>
                <label className='form-label' htmlfor={msj}>{msj}</label>
                {invalid && (
                    <div className="invalid-feedback">
                        {error}
                    </div>
                )}
            </div>
        </React.Fragment>
    )
};


export const AsyncSelectField = (
    {
        input,
        disabled,
        //isClearable,
        isSearchable,
        loadOptions,
        placeholder,
        autoFocus=false,
        meta: { touched, error }
    }) => {

    const invalid = touched && error;

    const customStyles = {
        menu: (provided, state) => ({
          ...provided,
          color: 'white',
        }),
        option: (provided, state) => ({
            ...provided,
            color: 'white',
        }),
      }

    return (
        <React.Fragment>
            <Async
                autoFocus={autoFocus}
                //isClearable={isClearable}
                isClearable={true}
                cacheOptions
                className={classNames('form-control-find', { 'is-invalid': invalid })}
                backspaceRemovesValue={false}
                isSearchable={isSearchable}
                defaultOptions
                loadOptions={loadOptions}
                placeholder={placeholder}
                onChange={(e) => { input.onChange(e ? e : null); }}
                value={input.value}
                isDisabled={disabled}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      neutral0: '#333',
                      primary25: 'black',
                      primary: 'black',
                    },
                })}
                styles={customStyles}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    )
};

export const AsyncSelectFieldIn = (
    {
        input,
        disabled,
        //isClearable,
        isSearchable,
        loadOptions,
        placeholder,
        autoFocus=false,
        meta: { touched, error }
    }) => {

    const invalid = touched && error;

    const customStyles = {
        menu: (provided, state) => ({
          ...provided,
          color: 'white',
        }),
        option: (provided, state) => ({
            ...provided,
            color: 'white',
        }),
      }

    return (
        <React.Fragment>
            <Select
                autoFocus={autoFocus}
                //isClearable={isClearable}
                isClearable={true}
                cacheOptions
                className={classNames('form-control-find', { 'is-invalid': invalid })}
                backspaceRemovesValue={false}
                isSearchable={isSearchable}
                defaultOptions
                options={loadOptions}
                placeholder={placeholder}
                onChange={(e) => { input.onChange(e ? e : null); }}
                value={input.value}
                isDisabled={disabled}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        neutral0: '#333',
                        primary25: 'black',
                        primary: 'black',
                    },
                })}
                styles={customStyles}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    )
};

export const AsyncSelectFieldA = (
    {
        input,
        disabled,
        isClearable,
        isSearchable,
        loadOptions,
        placeholder,
        autoFocus=false,
        meta: { touched, error }
    }) => {

    const invalid = touched && error;

    const customStyles = {
        menu: (provided, state) => ({
          ...provided,
          color: 'white',
        }),
        option: (provided, state) => ({
            ...provided,
            color: 'white',
        }),
      }

    return (
        <React.Fragment>
            <Async
                autoFocus={autoFocus}
                isClearable={isClearable}
                cacheOptions
                className={classNames('form-control-find', { 'is-invalid': invalid })}
                backspaceRemovesValue={false}
                isSearchable={isSearchable}
                defaultOptions
                loadOptions={loadOptions}
                placeholder={placeholder}
                onChange={(e) => { input.onChange(e ? e : null); }}
                value={input.value}
                isDisabled={disabled}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      neutral0: '#333',
                      primary25: 'black',
                      primary: 'black',
                    },
                })}
                styles={customStyles}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    )
};


export const CreatableSelectField = (
    {
        input,
        disabled,
        isClearable,
        isSearchable,
        options,
        placeholder,
        labelKey = "label",
        valueKey = "value",
        meta: { touched, error }
    }) => {

    const invalid = touched && error;
    const _options = [];
    options.forEach(option => {
        _options.push({ ...option, label: option[labelKey], value: option[valueKey] });
    });

    return (
        <React.Fragment>
            <Creatable
                isClearable={isClearable}
                className={classNames('react-select-container', { 'is-invalid': invalid })}
                backspaceRemovesValue={false}
                isSearchable={isSearchable}
                options={_options}
                placeholder={placeholder}
                onChange={(e) => { input.onChange(e ? e : null); }}
                value={input.value}
                isDisabled={disabled}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    )
};


/**
 * @param photo: este parametro se usa para tener la imagen previa de una imagen en dado caso el formulario es
 * usado para una actualizacion, se espera que sea la ruta donde se encuentra la imagen
 * @param setFile
 * @param className
 * @param disabled
 * @param input
 * @param touched
 * @param error
 * */
export const renderFilePicker = ({ photo, setFile, className, disabled, input, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div className={classNames(`${className}`, { 'is-invalid': invalid })}>
            <FileUploader
                disabled={disabled}
                img={!!photo ? photo : null}
                onFileChange={(e, file) => {
                    file = file || e.target.files[0];
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        input.onChange(reader.result);
                        if (!!setFile) {
                            setFile(file);
                        }
                    };
                    reader.readAsDataURL(file);
                }} />
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
};

export const renderFilePickerB = ({ photo, setFile, className, disabled, input, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div className={classNames(`${className}`, { 'is-invalid': invalid })}>
            <FileUploaderB
                disabled={disabled}
                img={!!photo ? photo : null}
                onFileChange={(e, file) => {
                    file = file || e.target.files[0];
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        input.onChange(reader.result);
                        if (!!setFile) {
                            setFile(file);
                        }
                    };
                    reader.readAsDataURL(file);
                }} />
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
};


export const renderDayPicker = ({ className, disabled, maxDate, minDate, input, msj, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div className={classNames(`${className}`, { 'is-invalid': invalid })}>
            <DayPicker
                disabled={disabled}
                maxDate={maxDate}
                minDate={minDate}
                onChange={e => input.onChange(e)}
                value={input.value}
            />
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
};

export const renderDayPickerB = ({ className, disabled, maxDate, minDate, input, msj, autoFocus=false, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div className="form-floating">
            <input
                autoFocus={autoFocus}
                className={classNames('form-control', { 'is-invalid': invalid })}
                type="date"
                disabled={disabled}
                max={maxDate}
                min={minDate}
                onChange={e => input.onChange(e)}
                value={input.value}
            />
            <label className='form-label' htmlfor={msj}>{msj}</label>
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
};

export const renderDayPickerC = ({ className, 
    disabled, 
    maxDate, 
    minDate, 
    input, 
    msj, 
    autoFocus=false, 
    readOnly,
    meta: { touched, error } }) => {
const invalid = touched && error;
return (
    <div className="form-floating">
        <input
            autoFocus={autoFocus}
            className={classNames('form-control', { 'is-invalid': invalid })}
            type="date"
            disabled={disabled}
            max={maxDate}
            min={minDate}
            onKeyDown={e => { if (readOnly) e.preventDefault(); }}
            onChange={e => input.onChange(e)}
            value={input.value}
        />
        <label className='form-label' htmlfor={msj}>{msj}</label>
        {invalid && <div className="invalid-feedback">
            {error}
        </div>}
    </div>
)
};

export const renderFilePickerC = ({ photo, setFile, className, disabled, input, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div className={classNames(`${className}`, { 'is-invalid': invalid })}>
            <FileUploaderC
                disabled={disabled}
                img={!!photo ? photo : null}
                onFileChange={(e, file) => {
                    file = file || e.target.files[0];
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        input.onChange(reader.result);
                        if (!!setFile) {
                            setFile(file);
                        }
                    };
                    reader.readAsDataURL(file);
                }} />
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
};

export const renderFilePickerFoto = ({ photo, setFile, className, disabled, input, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div className={classNames(`${className}`, { 'is-invalid': invalid })}>
            <FileUploaderFotoRH
                disabled={disabled}
                img={!!photo ? photo : null}
                onFileChange={(e, file) => {
                    file = file || e.target.files[0];
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        input.onChange(reader.result);
                        if (!!setFile) {
                            setFile(file);
                        }
                    };
                    reader.readAsDataURL(file);
                }} />
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
};

export const renderFilePickerRH = ({ photo, setFile, className, disabled, input, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div className={classNames(`${className}`, { 'is-invalid': invalid })}>
            <FileUploaderRH
                disabled={disabled}
                img={!!photo ? photo : null}
                onFileChange={(e, file) => {
                    file = file || e.target.files[0];
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        input.onChange(reader.result);
                        if (!!setFile) {
                            setFile(file);
                        }
                    };
                    reader.readAsDataURL(file);
                }} />
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
};


export const renderDatePicker = ({ className, disabled, maxDate, minDate, input, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div className={classNames(`${className}`, { 'is-invalid': invalid })}>
            <DatePicker
                onChange={e => input.onChange(e)}
                disabled={disabled}
                maxDate={maxDate}
                minDate={minDate}
                value={input.value}
            />
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
};

export const RenderField = {
    renderField,
    renderTextArea,
    renderNumber,
    renderCurrency,
    renderSwitch,
    renderFieldCheck,
    renderFieldRadio,
};
