import React from "react";
import styled from "styled-components";
import { FicInput, FicDatePicker } from "../../Utils/FormInputComponents";
import Banner from "../common/Banner";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { validar } from "../../components/ValidarNit";
import GenBtn from "../../components/GenBtn";
import InputsContainer from "../common/InputsContainer";
import { getAgente, editarAgente } from "./actions";
import { withRouter } from "react-router";


function Ver({ history, ...props }) {
    const params = props.match.params;
    const agenteId = params.agenteId;

    const { control } = useForm({
        defaultValues: async () => {
            const formData = await getAgente(agenteId);
            // setLoadingState(false);
            return formData;
        },
    });



    return (
        <React.Fragment>
            <Banner>Ver agente</Banner>
                <GenBtn style={{ margin: "1rem 0" }} onPress={() => history.goBack()}>
                    Regresar
                </GenBtn>
            <form onSubmit={() => {}}>
                <InputsContainer>
                    <FicInput
                        style={{ flexGrow: "1", maxWidth: "400px" }}
                        control={control}
                        label="DPI"
                        name="dpi"
                        isDisabled
                    />
                    <FicInput
                        style={{ flexGrow: "1", maxWidth: "400px" }}
                        control={control}
                        label="Nit"
                        name="nit"
                        isDisabled
                    />
                    <FicInput
                        control={control}
                        label="Nombre"
                        name="nombre"
                        style={{ minWidth: "400px" }}
                        isDisabled
                    />
                    <FicInput
                        style={{ flexGrow: "1", maxWidth: "400px" }}
                        control={control}
                        label="Telefono"
                        name="telefono"
                        inputMode="numeric"
                        isDisabled
                    />
                    <FicInput
                        control={control}
                        label="Direccion"
                        name="direccion"
                        style={{ minWidth: "400px" }}
                        isDisabled
                    />
                    <FicInput
                        style={{ flexGrow: "1", maxWidth: "400px" }}
                        control={control}
                        label="E-mail"
                        name="email"
                        isDisabled
                    />
                    <FicDatePicker
                        control={control}
                        label="Fecha de alta"
                        name="fecha_de_alta"
                        isDisabled
                    />
                    <FicDatePicker
                        control={control}
                        label="Fecha de baja"
                        name="fecha_de_baja"
                        isDisabled
                    />
                </InputsContainer>
            </form>
        </React.Fragment>
    );
}

export default withRouter(Ver);
