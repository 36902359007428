import { NotificationManager } from "react-notifications";
import { api } from "api";

const baseurl = "seguros/seguro";

export const getClientes = (search) => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let empresa = id_emp[5];
    const params = { empresa, search };
    return api
        .get("seguros/clientes", params)
        .then((response) => {
            return response.map((item) => ({
                value: item.id,
                label: item.nombre,
            }));
        })
        .catch((error) => {
            return [];
        });
};

export const getAgentes = (search) => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let empresa = id_emp[5];
    const params = { empresa, search };
    return api
        .get("seguros/agentes", params)
        .then((response) => {
            return response.map((item) => ({
                value: item.id,
                label: item.nombre,
            }));
        })
        .catch((error) => {
            return [];
        });
};

export const getSeguros = () => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let empresa = id_emp[5];
    const params = { empresa };
    return api
        .get(baseurl, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return [];
        });
};

export const getSeguro = (id) => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let empresa = id_emp[5];
    const params = { empresa };
    return api
        .get(baseurl + `/${id}`, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return [];
        });
};

export const getVehiculos = () => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let empresa = id_emp[5];
    const params = { empresa };
    return api
        .get("seguros/vehiculos", params)
        .then((response) => {
            return response.map((item) => ({
                id: item.id,
                label: `${item.placa} | ${item.marca} ${item.linea} ${item.modelo}`,
            }));
        })
        .catch((error) => {
            return [];
        });
};

export const crearSeguro = (data) => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    const formData = {
        ...data,
        empresa: id_emp[5],
    };
    return api
        .post(baseurl, formData)
        .then((response) => {
            NotificationManager.success("Seguro agregado", "Exito", 3000);
        })
        .catch((error) => {
            NotificationManager.error("", "Error", 2000);
        });
};

export const editarSeguro = (data, id) => {
    let ruta = window.location.href;
    let empresa = ruta.split("/")[5];
    const formData = {
        ...data,
        empresa,
    };
    return api
        .put(baseurl + `/${id}`, formData)
        .then(() => {
            NotificationManager.success("Seguro editado", "Exito", 3000);
            return true;
        })
        .catch((error) => {
            NotificationManager.error(error.msj, "Error", 2000);
            return false;
        });
};
