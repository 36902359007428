import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';
import moment from 'moment';
import { validar } from "../../../common/components/components/ValidarNit";

const LISTADO_IMPORTACION = 'LISTADO_IMPORTACION';
const LISTADO_TIPOS_DOCU = 'LISTADO_TIPOS_DOCU';
const GUARDAR_IMPORTACION = 'GUARDAR_IMPORTACION';
const GUARDAR_POLIZA = 'GUARDAR_POLIZA';
const DETALLE_POLIZA = 'DETALLE_POLIZA';
const DETALLE_LIQUIDACION = 'DETALLE_LIQUIDACION';
const DETALLE_IMPORTACION = 'DETALLE_IMPORTACION';
const LOADER = 'LOADER';
const LOADERC = 'LOADERC';
const NIVEL = 'NIVEL';
const PERIODO = 'PERIODO';
const MODULO = 'MODULO';
const PERIODO_D = 'PERIODOD';
const PARAMETRO = 'PARAMETRO';
const LISTADO_BODEGAS = 'LISTADO_BODEGAS';
const LISTADO_PAISES = 'LISTADO_PAISES';
const LISTADO_IMPUESTO = 'LISTADO_IMPUESTO';
const DETALLE_PRODUCTOS = 'DETALLE_PRODUCTOS';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setLoaderC = loaderC => ({
    type: LOADERC,
    loaderC,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let id_period = id_emp[7];
    //const params = { page, id, id_period };
    const params = { id, id_period };
    dispatch(setLoader(true));
    api.get('/importacion', params).then((response) => {
        dispatch({ type: LISTADO_IMPORTACION, data: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar importaciones',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const listarTiposDocu = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    api.get('/tipos', params).then((response) => {
        const tiposD = [];
        response.results.forEach(item => {
            if (item.tipo_documento === 'CARGO'){
                tiposD.push({
                    value: item.id,
                    label: item.tipo + ' - ' + item.descripcion,
                    search: item.categoria
                })
            }
        })
        dispatch({ type: LISTADO_TIPOS_DOCU, tipos: tiposD });
    }).catch((error) => {
        
        NotificationManager.error(
            'Ocurrió un error al listar tipos de documento',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const listarProveedores = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let tipo = 'importacion';
    const params = {id, search, tipo}
    return api.get('/proveedor/empresa_proveedores',params).then(data=>{
        const list_proveedor = [];
        if(data){
            data.forEach(item=>{
                list_proveedor.push({
                    value: item.id,
                    label: item.identificador + ' ' + item.nombre
                })
            })
        }
        return list_proveedor ;
    }).catch((error)=>{
        
        return [];
    })
}

export const listarProveedoresGastos = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let tipo = 'liquidacion';
    const params = {id, search, tipo}
    return api.get('/proveedor/empresa_proveedores',params).then(data=>{
        const list_proveedor = [];
        if(data){
            data.forEach(item=>{
                list_proveedor.push({
                    value: item.id,
                    label: item.identificador + ' ' + item.nombre
                })
            })
        }
        return list_proveedor ;
    }).catch((error)=>{
        
        return [];
    })
}

export const listarCuentas = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/cuentas/empresa_cuentas/', params).then(data => {
        const list_cuentas = [];
        if (data) {
            data.forEach(item => {
                if (item.tipo === 'A') {
                    list_cuentas.push({
                        value: item.id,
                        label: item.cuenta + ' ' + item.nombre,
                        isDisabled: true
                    })
                } else {
                    list_cuentas.push({
                        value: item.id,
                        label: item.cuenta + ' ' + item.nombre
                    })
                }
            })
        }
        return list_cuentas;
    }).catch((error) => {
        
        return [];
    })
}

export const listarCC = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/centrocosto/empresa_centro_costo/', params).then(data => {
        const list_cc = [];
        if (data) {
            data.forEach(item => {
                list_cc.push({
                    value: item.id,
                    label: item.codigo + ' ' + item.descripcion
                })
            })
        }
        return list_cc;
    }).catch((error) => {
        
        return [];
    })
}

const leerDetalle = id => (dispatch) => {
    api.get(`/poliza_detalle/${id}`).then((response) => {
        dispatch({ type: DETALLE_POLIZA, detalle: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};

export const listarNiveles = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    dispatch(setLoader(true));
    api.get('/niveles/nivel_cuenta', params).then((response) => {
        dispatch({ type: NIVEL, nivel: response });
    }).catch((error) => {
        
        NotificationManager.error(
            'Ocurrió un error al listar nivel',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const eliminarLinea = id => (dispatch) => {
    const poliza = localStorage.getItem('idPolizaImp');
    dispatch(setLoader(true));
    api.eliminar(`poliza_detalle/${id}`).then(() => {
        const editar = window.location.href.includes('editar');
        if (editar) {
            dispatch(leerDetalleMod(poliza));
        } else {
            dispatch(leerDetalle(poliza));
        }
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar linea de poliza',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const registroCuenta = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.ImportacionFormTabs.values;
    let cuenta = data.cuenta
    let formato = localStorage.getItem('formato')
    let f = formato.split('-');
    let cont = 0;
    let formatoCuenta = '';
    let digito = '';

    if (f.length === 3) {
        let nivel1 = f[0].length
        digito = cuenta.substr(cont, nivel1);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel2 = f[1].length;
        cont = cont + nivel1;
        digito = cuenta.substr(cont, nivel2);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel3 = f[2].length;
        cont = cont + nivel2;
        digito = cuenta.substr(cont, nivel3);
        formatoCuenta = formatoCuenta + digito;

    }

    if (f.length === 4) {
        let nivel1 = f[0].length
        digito = cuenta.substr(cont, nivel1);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel2 = f[1].length;
        cont = cont + nivel1;
        digito = cuenta.substr(cont, nivel2);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel3 = f[2].length;
        cont = cont + nivel2;
        digito = cuenta.substr(cont, nivel3);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel4 = f[3].length;
        cont = cont + nivel3;
        digito = cuenta.substr(cont, nivel4);
        formatoCuenta = formatoCuenta + digito;
    }

    if (f.length === 5) {
        let nivel1 = f[0].length
        digito = cuenta.substr(cont, nivel1);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel2 = f[1].length;
        cont = cont + nivel1;
        digito = cuenta.substr(cont, nivel2);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel3 = f[2].length;
        cont = cont + nivel2;
        digito = cuenta.substr(cont, nivel3);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel4 = f[3].length;
        cont = cont + nivel3;
        digito = cuenta.substr(cont, nivel4);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel5 = f[4].length;
        cont = cont + nivel4;
        digito = cuenta.substr(cont, nivel5);
        formatoCuenta = formatoCuenta + digito;
    }

    if (f.length === 6) {
        let nivel1 = f[0].length
        digito = cuenta.substr(cont, nivel1);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel2 = f[1].length;
        cont = cont + nivel1;
        digito = cuenta.substr(cont, nivel2);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel3 = f[2].length;
        cont = cont + nivel2;
        digito = cuenta.substr(cont, nivel3);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel4 = f[3].length;
        cont = cont + nivel3;
        digito = cuenta.substr(cont, nivel4);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel5 = f[4].length;
        cont = cont + nivel4;
        digito = cuenta.substr(cont, nivel5);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel6 = f[5].length;
        cont = cont + nivel5;
        digito = cuenta.substr(cont, nivel6);
        formatoCuenta = formatoCuenta + digito;
    }
    let idPoliza = localStorage.getItem('idPolizaImp')
    const formData = {
        cuenta: formatoCuenta,
        nombre: data.nombre,
        tipo: data.tipoC,
        naturaleza: data.naturaleza,
        empresa: id_emp[5],
        poliza: idPoliza
    }
    dispatch(setLoaderC(true));
    api.post('/cuentas/', formData).then((response) => {
        if (idPoliza) {
            NotificationManager.success(
                'Cuenta creada' + ' ' + response.cuenta.cuenta + ' ' + response.cuenta.nombre,
                'Exito',
                0
            );
            response.cuenta = '';
            response.nombre = '';
            response.fecha = response.poliza.fecha;
            response.tipo = response.poliza.tipo;
            response.numero_poliza = response.poliza.numero_poliza;
            response.descripcion = response.poliza.descripcion;
            listarCuentas();

        }else{
            NotificationManager.success(
                'Cuenta creada' + ' ' + response.cuenta + ' ' + response.nombre,
                'Exito',
                0
            );
            response.cuenta = '';
            response.nombre = '';
            listarCuentas();
        }

    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoaderC(false));
    });
}

export const reporteCuentas = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let reporte = 'reporte';
    const params = { id, reporte };
    dispatch(setLoaderC(true));;
    api.getPdf('/cuentas/', params).then((response) => {
        if (response.headers['content-type'] == 'application/pdf') {
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'reporte_cuentas_contables.pdf';
            window.open(link, '_blank');
            return false;
        }
    }).catch((error) => {
        
        NotificationManager.error(
            'Ocurrió un error al generar reporte',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoaderC(false));
    });
}

export const registroImportacion = (periodoD) => (dispatch, getStore) => {
    const data = getStore().form.FormImportacion.values;
    
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fechaImportacion)
    if (fecha>=fechaI && fecha<=fechaF){
        const detalle = localStorage.getItem('detalleIDIMP');
        const formData = {
            fechaRegistro: data.fechaRegistro,
            declaracion: data.declaracion,
            fecha: data.fechaImportacion,
            afecta_iva: data.afecta_iva,
            fob: data.fob,
            flete: data.flete,
            seguro: data.seguro,
            otros: data.otros,
            total_gasto: localStorage.getItem('total_gastoImp'),
            total_quetzales: localStorage.getItem('totalQ'),
            tipo_cambio: data.tipo_cambio,
            dai: localStorage.getItem('dai') === null ? 0.00 : localStorage.getItem('dai'),
            descripcion: data.observaciones,
            iva: localStorage.getItem('ivaImport'),
            empresa: id_emp[5],
            fechaD: data.fechaD,
            serie: data.serie ? data.serie.toUpperCase() : '',
            numero: data.numero,
            pedido: data.pedido,
            detalle: detalle,
            modulo: 'CMP',
            proveedor: data.proveedor_documento.value,
            montoD: localStorage.getItem('montoD'),
            periodo: id_emp[7],
            idPoliza: localStorage.getItem('idPolizaImp'),
            poliza: 'importacion'
        }
        dispatch(setLoader(true));
        api.post('/importacion/', formData).then((response) => {
            
            if (detalle){
                response.fechaRegistro = response.importacion.fecha_registro;
                response.declaracion = response.importacion.declaracion;
                response.observaciones = response.importacion.descripcion;
                response.fob = response.importacion.total_fob;
                response.flete = response.importacion.flete;
                response.seguro = response.importacion.seguro;
                response.otros = response.importacion.otros;
                response.dai = response.importacion.dai;
                response.totalLiq = response.total;
                response.tipo_cambio = response.importacion.tipo_cambio;
                response.totalQ = 0.00;
                response.fechaImportacion = response.importacion.fecha;
                localStorage.setItem('TcambioImp',response.importacion.tipo_cambio);
                const poliza = localStorage.getItem('idPolizaImp');
                dispatch(initializeForm('FormImportacion', response));
                dispatch(leerDetalleImportacion(response.detalle));
                const editar = window.location.href.includes('editar');
                if (editar) {
                    dispatch(leerDetalleMod(poliza));
                } else {
                    dispatch(leerDetalle(poliza));
                }
            }else{
                response.fechaRegistro = response.importacion.fecha_registro;
                response.declaracion = response.importacion.declaracion;
                response.observaciones = response.importacion.descripcion;
                response.tipo_documento = response.tipoD;
                response.fob = response.importacion.total_fob;
                response.flete = response.importacion.flete;
                response.seguro = response.importacion.seguro;
                response.otros = response.importacion.otros;
                response.dai = response.importacion.dai;
                response.totalLiq = response.total;
                response.tipo_cambio = response.importacion.tipo_cambio;
                response.fechaImportacion = response.importacion.fecha;
                localStorage.setItem('idPolizaImp', response.idPoliza);
                const poliza = localStorage.getItem('idPolizaImp');
                localStorage.setItem('detalleIDIMP', response.detalle);
                dispatch(initializeForm('FormImportacion', response));
                dispatch(leerDetalleImportacion(response.detalle));
                const editar = window.location.href.includes('editar');
                if (editar) {
                    dispatch(leerDetalleMod(poliza));
                } else {
                    dispatch(leerDetalle(poliza));
                }
            }
        
            NotificationManager.success(
                'Documento creado correctamente',
                'Exito',
                3000
            );
        
        }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                0
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }else{
        Swal.fire({
            title: 'Importación',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })      
    }

}

export const modificarImportacion = (periodoD) => (dispatch, getStore) => {
    const data = getStore().form.FormImportacion.values;
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const detalle = localStorage.getItem('detalleIDIMP');
    const id = data.id;
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fechaImportacion)
    if (fecha>=fechaI && fecha<=fechaF){
        const formData = {
            id: id,
            detalle: detalle,
            fechaRegistro: data.fechaRegistro,
            declaracion: data.declaracion,
            fecha: data.fechaImportacion,
            afecta_iva: data.afecta_iva,
            fob: data.fob,
            flete: data.flete,
            seguro: data.seguro,
            otros: data.otros,
            total_gasto: localStorage.getItem('total_gastoImp'),
            total_quetzales: localStorage.getItem('totalQ'),
            tipo_cambio: data.tipo_cambio,
            dai: data.dai,
            descripcion: data.observaciones,
            iva: localStorage.getItem('ivaImport'),
            idPoliza: localStorage.getItem('idPolizaImp'),
            empresa: id_emp[5],
        }
        dispatch(setLoader(true));
        api.put(`/importacion/${id}/`, formData).then((response) => {
            if (detalle){
                response.fechaRegistro = response.importacion.fecha_registro;
                response.declaracion = response.importacion.declaracion;
                response.observaciones = response.importacion.descripcion;
                response.fob = response.importacion.total_fob;
                response.flete = response.importacion.flete;
                response.seguro = response.importacion.seguro;
                response.otros = response.importacion.otros;
                response.dai = response.importacion.dai;
                response.totalLiq = response.total;
                response.tipo_cambio = response.importacion.tipo_cambio;
                response.fechaImportacion = response.importacion.fecha;
                localStorage.setItem('idPolizaImp', response.importacion.poliza);
                const poliza = localStorage.getItem('idPolizaImp');
                dispatch(initializeForm('FormImportacion', response));
                dispatch(leerDetalleMod(poliza));
                dispatch(leerDetalleImportacion(response.detalle));
                dispatch({ type: GUARDAR_IMPORTACION, lectura_data: response })
            
            }else{
                response.fechaRegistro = response.importacion.fecha_registro;
                response.declaracion = response.importacion.declaracion;
                response.observaciones = response.importacion.descripcion;
                response.tipo_documento = response.tipoD;
                response.fob = response.importacion.total_fob;
                response.flete = response.importacion.flete;
                response.seguro = response.importacion.seguro;
                response.otros = response.importacion.otros;
                response.dai = response.importacion.dai;
                response.totalLiq = response.total;
                response.tipo_cambio = response.importacion.tipo_cambio;
                response.fechaImportacion = response.importacion.fecha;
                localStorage.setItem('idPolizaImp', response.importacion.poliza);
                const poliza = localStorage.getItem('idPolizaImp');
                localStorage.setItem('detalleIMP', response.detalle);
                dispatch(initializeForm('FormImportacion', response));
                dispatch(leerDetalleMod(poliza));
                dispatch(leerDetalleImportacion(response.detalle));
                dispatch({ type: GUARDAR_IMPORTACION, lectura_data: response })
            }
        
            NotificationManager.success(
                'Importación modificada',
                'Exito',
                3000
            );

            let ruta = `/compras/${id_emp[5]}/importaciones/${id_emp[7]}`
            dispatch(push(ruta));

        }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                0
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }else{
        Swal.fire({
            title: 'Importación',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })    
    }
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/importacion/${id}`).then((response) => {
        response.observaciones = response.descripcion;
        response.fechaRegistro = response.fecha_registro;
        response.totalLiq =  response.total;
        response.fob =  response.total_fob;
        response.fechaImportacion = response.fecha;
        response.tipoCambio = response.tipo_cambio;
        response.totalLiq = response.total_quetzales;
        response.totalGasto = response.total_gasto;
        response.iva = response.iva;
        localStorage.setItem('TcambioImp', response.tipo_cambio);
        dispatch({ type: GUARDAR_IMPORTACION, lectura_data: response });
        dispatch(initializeForm('FormImportacion', response));
        dispatch(leerDetalleGastos(response.id));
        dispatch(leerDetalleImportacion(response.id));
        localStorage.setItem('detalleIDIMP', response.id);
        if (response.poliza){
            localStorage.setItem('idPolizaImp', response.poliza);
            dispatch(leerDetalleMod(response.poliza));
            if (response.inventario){
                localStorage.setItem('tipoCambio', response.tipo_cambio);
                localStorage.setItem('id_detalle_inventario_IMP', response.inventario);
                dispatch(leerDetalleInventario(response.inventario))
            }
        }else{
            if (response.inventario){
                localStorage.setItem('tipoCambio', response.tipo_cambio);
                localStorage.setItem('id_detalle_inventario_IMP', response.inventario);
                dispatch(leerDetalleInventario(response.inventario))
            }
        }
        
    }).catch((error) => {
        
        NotificationManager.error(
            'Ocurrió un error al importación',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const registroPolizaDetalle = () => (dispatch, getStore) => {
    const data = getStore().form.ImportacionFormTabs.values;
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const poliza = localStorage.getItem('idPolizaImp');
    const formData = {
        empresa: id_emp[5],
        periodo: id_emp[7],
        cc: data.cc.value,
        cuenta: data.cuenta.value,
        debe: data.debe,
        haber: data.haber,
        poliza: poliza
    }
    if (formData.debe === undefined && formData.haber === undefined) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'Debe de ingresar valor en debe o haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) === 0.00 && parseFloat(formData.haber) === 0.00) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'No puede llevar valores a 0.00 en debe o haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) >= 0.01 && parseFloat(formData.haber) >= 0.01) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'No puede llevar valores en debe y haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) >= 0.01) {
        formData.haber = 0.00;
        dispatch(setLoader(true));
        api.post('/polizas/encabezado_polizas/', formData).then((response) => {
            const poliza = localStorage.getItem('idPolizaImp')
            if (poliza === null) {
                response.debe = 0.00;
                response.haber = 0.00;
                dispatch(initializeForm('ImportacionFormTabs', response));
                dispatch(leerDetalle(response.detalle.encabezado));
            } else {
                const editar = window.location.href.includes('editar');
                if (editar) {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('ImportacionFormTabs', response));
                    dispatch(leerDetalleMod(poliza));
                } else {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('ImportacionFormTabs', response));
                    dispatch(leerDetalle(poliza));
                }
            }
        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al registrar la poliza',
                'Error',
                0
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    } else if (parseFloat(formData.haber) >= 0.01) {
        formData.debe = 0.00;
        dispatch(setLoader(true));
        api.post('/polizas/encabezado_polizas/', formData).then((response) => {
            localStorage.removeItem('tipo_poliza');
            const poliza = localStorage.getItem('idPolizaImp')
            if (poliza === null) {
                response.debe = 0.00;
                response.haber = 0.00;
                dispatch(initializeForm('ImportacionFormTabs', response));
                dispatch(leerDetalle(response.encabezado));
            } else {
                const editar = window.location.href.includes('editar');
                if (editar) {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('ImportacionFormTabs', response));
                    dispatch(leerDetalleMod(poliza));
                } else {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('ImportacionFormTabs', response));
                    dispatch(leerDetalle(poliza));
                }
            }

        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al registrar la poliza',
                'Error',
                0
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
}

export const registroPolizaDetalleSCC = () => (dispatch, getStore) => {
    const data = getStore().form.ImportacionFormTabs.values;
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const poliza = localStorage.getItem('idPolizaImp');
    const formData = {
        empresa: id_emp[5],
        periodo: id_emp[7],
        cc: 0,
        cuenta: data.cuenta.value,
        debe: data.debe,
        haber: data.haber,
        poliza: poliza
    }
    if (formData.debe === undefined && formData.haber === undefined) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'Debe de ingresar valor en debe o haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) === 0.00 && parseFloat(formData.haber) === 0.00) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'No puede llevar valores a 0.00 en debe o haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) >= 0.01 && parseFloat(formData.haber) >= 0.01) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'No puede llevar valores en debe y haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) >= 0.01) {
        formData.haber = 0.00;
        dispatch(setLoader(true));
        api.post('/polizas/encabezado_polizas/', formData).then((response) => {
            const poliza = localStorage.getItem('idPolizaImp')
            if (poliza === null) {
                response.debe = 0.00;
                response.haber = 0.00;
                dispatch(initializeForm('ImportacionFormTabs', response));
                dispatch(leerDetalle(response.detalle.encabezado));
            } else {
                const editar = window.location.href.includes('editar');
                if (editar) {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('ImportacionFormTabs', response));
                    dispatch(leerDetalleMod(poliza));
                } else {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('ImportacionFormTabs', response));
                    dispatch(leerDetalle(poliza));
                }
            }

        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al registrar la poliza',
                'Error',
                0
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    } else if (parseFloat(formData.haber) >= 0.01) {
        formData.debe = 0.00;
        api.post('/polizas/encabezado_polizas/', formData).then((response) => {
            localStorage.removeItem('tipo_poliza');
            const poliza = localStorage.getItem('idPolizaImp')
            if (poliza === null) {
                response.debe = 0.00;
                response.haber = 0.00;
                dispatch(initializeForm('ImportacionFormTabs', response));
                dispatch(leerDetalle(response.detalle.encabezado));
            } else {
                const editar = window.location.href.includes('editar');
                if (editar) {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('ImportacionFormTabs', response));
                    dispatch(leerDetalleMod(poliza));
                } else {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('ImportacionFormTabs', response));
                    dispatch(leerDetalle(poliza));
                }
            }

        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al registrar la poliza',
                'Error',
                0
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
}

export const confirmarPoliza = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let idE = id_emp[5]
    let dif = localStorage.getItem('dif');
    if (parseFloat(dif) === 0.00) {
        NotificationManager.success(
            'Póliza guardada correctamente',
            'Exito',
            3000
        );
        Swal.fire({
            title: '¿Imprimir prorrateo de importación?',
            text: '¡Desea imprimir prorrateo!',
            type: 'question',
            showCancelButton: true,
            confirmButtonText: '¡Sí, imprimir!',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                let reporte = 'imprimirProrrateo';
                let id_imp = localStorage.getItem('detalleIDIMP');
                const params = { idE, reporte, id_imp };
                dispatch(setLoader(true));
                api.getPdf('/movimiento_inventario', params).then((response)=>{
                    localStorage.removeItem('idPolizaImp');
                    localStorage.removeItem('dif');
                    localStorage.removeItem('detalleIDIMP');
                    localStorage.removeItem('dataI');
                    localStorage.setItem('LIQ', false);
                    let ruta = `/compras/${id_emp[5]}/importaciones/${id_emp[7]}`
                    dispatch(push(ruta));
                    if (response.headers['content-type'] == 'application/pdf'){
                        var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
                        var link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = 'prorrateo.pdf';
                        window.open(link, '_blank');
                        return false;
                    }
                }).catch((error)=>{
                    NotificationManager.error(
                        error.body.msj,
                        'Error',
                        0
                    );
                    localStorage.removeItem('idPolizaImp');
                    localStorage.removeItem('detalleIDIMP');
                    localStorage.removeItem('detalleIMPGasto');
                    localStorage.removeItem('dataI');
                    localStorage.removeItem('TcambioImp');
                    localStorage.removeItem('id_detalle_inventario_IMP');
                    localStorage.setItem('LIQ_Imp', false);
                    const editar = window.location.href.includes('editar');
                    if (editar){
                        let ruta = `/compras/${id_emp[5]}/importaciones/${id_emp[7]}`
                        dispatch(push(ruta));
                    }else{
                        setTimeout(() => {
                            window.location.reload(true);
                        }, 3000);
                    }
                    }).finally(() => {
                        dispatch(setLoader(false));
                    });
            }else {
                localStorage.removeItem('idPolizaImp');
                localStorage.removeItem('detalleIDIMP');
                localStorage.removeItem('detalleIMPGasto');
                localStorage.removeItem('dataI');
                localStorage.removeItem('TcambioImp');
                localStorage.removeItem('id_detalle_inventario_IMP');
                localStorage.setItem('LIQ', false);
                const editar = window.location.href.includes('editar');
                if (editar){
                    let ruta = `/compras/${id_emp[5]}/importaciones/${id_emp[7]}`
                    dispatch(push(ruta));
                }else{
                    setTimeout(() => {
                        window.location.reload(true);
                    }, 2000);
                }
            }
            
        });
    } else {
        Swal.fire({
            title: 'Modificar poliza',
            text: `Poliza con diferencia: ${new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(dif)}`,
            type: 'error',
        })
    }
}

const leerDetalleMod = id => (dispatch) => {
    api.get(`/poliza_detalle/${id}`).then((response)=>{
        response.polizaDetalle_polizaEncabezado = response
        dispatch({ type: GUARDAR_POLIZA, lectura: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};


export const leerPeriodoD = (id, id_periodo) => (dispatch) => {
    const params = { id, id_periodo };
    api.get('/periodos/periodo', params).then((response) => {
        dispatch({ type: PERIODO_D, periodoD: response.periodo });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar periodo',
            'Error',
            2000
        );
    })
}

export const leerPeriodo = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let id_periodo = id_emp[7];
    const params = { id, id_periodo };
    api.get('/periodos/periodo', params).then((response) => {
        localStorage.setItem('info_periodo', response.periodo.periodo);
        localStorage.setItem('fechaI', response.periodo.fecha_inicio);
        localStorage.setItem('fechaF', response.periodo.fecha_fin);
        dispatch({ type: PERIODO, periodoS: response.periodo });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar periodo',
            'Error',
            0
        );
    })
}

const leerDetalleLiquidacion = id => (dispatch) => {
    api.get(`/liquidacion_gasto_detalle/${id}`).then((response)=>{
        dispatch({ type: DETALLE_LIQUIDACION, detalleLiq: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};

const leerDetalleGastos = (id) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id_empresa = id_emp[5];
    const params = { id_empresa, id };
    api.get('/liquidacion_gasto/importacion_liq_gasto', params).then((response)=>{
        const editar = window.location.href.includes('editar');
        if (editar){
            localStorage.setItem('detalleIMPGasto',response.encabezado.id);
        }
        response.serie  = response.encabezado.serie;
        response.numeroL  = response.encabezado.numero;
        response.proveedor = response.encabezado.proveedor;
        response.totalLiq = response.encabezado.total;
        response.proveedorN = response.encabezado.proveedorN;
        response.observaciones = response.encabezado.descripcion;
        dispatch(initializeForm('FormImportacionGastos', response));
        dispatch({ type: DETALLE_LIQUIDACION, detalleLiq: response.detalle });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};

const eliminarDocumento = id => (dispatch) => {
    
    const detalle = localStorage.getItem('detalleIMPGasto');
    dispatch(setLoader(true));
    api.eliminar(`/liquidacion_gasto_detalle/${id}`).then((response) => {
        
        if (detalle){
            const editar = window.location.href.includes('editar');
            response.fechaRegistro = response.compra.fecha_registro;
            response.numeroL = response.compra.numero;
            response.observaciones = response.compra.descripcion;
            response.identificador = response.compra.nit_proveedor;
            response.totalLiq = response.total;
            response.tipo_documento = response.tipoD;
            if (editar) {
                dispatch(leerDetalleLiquidacion(response.compra.id));
                dispatch(leerDetalleMod(response.compra.poliza));
                dispatch(initializeForm('FormImportacionGastos', response));
            }else{
                dispatch(leerDetalleLiquidacion(response.compra.id));
                dispatch(leerDetalle(response.compra.poliza));
                dispatch(initializeForm('FormImportacionGastos', response));
            }
        }
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar el documento',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const eliminarDocumentoImportacion = id => (dispatch) => {
    
    const detalle = localStorage.getItem('detalleIDIMP');
    dispatch(setLoader(true));
    api.eliminar(`/importacion_detalle/${id}`).then((response) => {
        if (detalle){
            const poliza = localStorage.getItem('idPolizaImp');
            const editar = window.location.href.includes('editar');
            response.fechaRegistro = response.importacion.fecha_registro;
            response.declaracion = response.importacion.declaracion;
            response.observaciones = response.importacion.descripcion;
            response.fob = response.importacion.total_fob;
            response.flete = response.importacion.flete;
            response.seguro = response.importacion.seguro;
            response.otros = response.importacion.otros;
            response.dai = response.importacion.dai;
            response.totalLiq = response.total;
            response.tipo_cambio = response.importacion.tipo_cambio;
            localStorage.setItem('TcambioImp',response.importacion.tipo_cambio);
            if (editar) {
                dispatch(initializeForm('FormImportacion', response));
                dispatch(leerDetalleMod(poliza));
                dispatch(leerDetalleImportacion(response.importacion.id));
            }else{
                dispatch(initializeForm('FormImportacion', response));
                dispatch(leerDetalleImportacion(response.detalle));
                dispatch(leerDetalleGastos());
            }
        }
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar el documento',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const eliminarImportacion = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`liquidacion_gasto/${id}`).then(() => {
        dispatch(listar(1));
        NotificationManager.success(
            'Liquidacion borrada correctamente',
            'Exito',
            3000
        );
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar liquidacion',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const registroProveedor = (datos) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormImportacionGastos.values;
    
    if (datos.validar_nit === 'S'){
        if (data.identificadorPro === 'cf' || data.identificadorPro === 'CF' || data.identificadorPro === 'c/f' || data.identificadorPro === 'C/F'){
            const formData = {
                identificador: data.identificadorPro,
                nombre: data.nombre,
                direccion: data.direccion,
                telefono: data.telefono,
                correo: data.correo,
                limite: data.limite_credito,
                tipo_proveedor: data.tipo_proveedor,
                dias_credito: data.dias_credito,
                pais: data.pais,
                modulo: 'CMP',
                empresa: id_emp[5]
            }
            dispatch(setLoader(true));
            api.post('/proveedor/', formData).then((response) => {
                NotificationManager.success(
                    'Proveedor creado',
                    'Exito',
                    3000
                );
                
            }).catch((error) => {
                NotificationManager.error(
                    error.msj,
                    'Error',
                    0
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }else{
            let validarPro = validar.valNit(data.identificadorPro);
            if (validarPro === false){
                Swal.fire({
                    title: 'Proveedor',
                    text: 'Ingrese Nit válido',
                    type: 'error',
                })
            }else{
                const formData = {
                    identificador: data.identificadorPro,
                    nombre: data.nombre,
                    direccion: data.direccion,
                    telefono: data.telefono,
                    correo: data.correo,
                    limite: data.limite_credito,
                    tipo_proveedor: data.tipo_proveedor,
                    dias_credito: data.dias_credito,
                    pais: data.pais,
                    modulo: 'CMP',
                    empresa: id_emp[5]
                }
                dispatch(setLoader(true));
                api.post('/proveedor/', formData).then((response) => {
                    NotificationManager.success(
                        'Proveedor creado',
                        'Exito',
                        3000
                    );
                }).catch((error) => {
                    NotificationManager.error(
                        error.msj,
                        'Error',
                        0
                    );
                }).finally(() => {
                    dispatch(setLoader(false));
                });
            }
        }
    }else{
        const formData = {
            identificador: data.identificadorPro,
            nombre: data.nombre,
            direccion: data.direccion,
            telefono: data.telefono,
            correo: data.correo,
            limite: data.limite_credito,
            tipo_proveedor: data.tipo_proveedor,
            dias_credito: data.dias_credito,
            pais: data.pais,
            modulo: 'CMP',
            empresa: id_emp[5]
        }
        dispatch(setLoader(true));
        api.post('/proveedor/', formData).then((response) => {
            NotificationManager.success(
                'Proveedor creado',
                'Exito',
                3000
            );
            
        }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                0
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
}

export const registroProveedorExt = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormImportacion.values;
    const formData = {
        identificador: data.identificadorPro,
        nombre: data.nombre,
        direccion: data.direccion,
        telefono: data.telefono,
        correo: data.correo,
        limite: data.limite_credito,
        tipo_proveedor: data.tipo_proveedor,
        dias_credito: data.dias_credito,
        pais: data.pais,
        modulo: 'CMP',
        empresa: id_emp[5]
    }
    dispatch(setLoader(true));
    api.post('/proveedor/', formData).then((response) => {
        NotificationManager.success(
            'Proveedor creado',
            'Exito',
            3000
        );
           
    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}


const leerDetalleImportacion = id => (dispatch) => {
    api.get(`/importacion_detalle/${id}`).then((response)=>{
        dispatch({ type: DETALLE_IMPORTACION, detalleImp: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};

export const registroCompra = (impuesto, periodoD, datoParam) => (dispatch, getStore) => {
    const data = getStore().form.FormImportacionGastos.values;
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    const datos = getStore().form.FormImportacion.values;
    let fecha = moment(datos.fechaRegistro);
    let poliza = '';
    let fechaFinalD = moment(data.fecha);
    if (fecha >= fechaI && fecha <= fechaF) {
        if (fechaFinalD.isAfter(periodoD.fecha_fin)) {
            Swal.fire({
                title: 'Gastos locales',
                text: 'Fecha es posterior al periodo: ' + moment(periodoD.fecha_fin).format("DD/MM/YYYY"),
                type: 'error',
            })
        } else {
            let doc = localStorage.getItem('tipoDocumentoLiqImp');
            if (doc === 'Seleccionar...' || doc === null) {
                Swal.fire({
                    title: 'Gastos locales',
                    text: 'Debe de seleccionar tipo de documento',
                    type: 'error',
                })
            } else {
                if (data.fecha === undefined || data.fecha === null) {
                    Swal.fire({
                        title: 'Gastos locales',
                        text: 'Debe de ingresar fecha de documento',
                        type: 'error',
                    })
                } else {
                    if (data.proveedor_gasto === undefined || data.proveedor_gasto === null) {
                        Swal.fire({
                            title: 'Gastos locales',
                            text: 'Debe de seleccionar proveedor',
                            type: 'error',
                        })
                    } else {
                        if (datoParam.contabilidad === 'S') {
                            if (data.cuenta === undefined || data.cuenta === null) {
                                Swal.fire({
                                    title: 'Gastos locales',
                                    text: 'Debe de seleccionar cuenta',
                                    type: 'error',
                                })
                            } else {
                                let ruta = window.location.href;
                                let id_emp = ruta.split('/');
                                let fec = datos.fechaRegistro
                                let fecR = fec.split('-')
                                let serieL = fecR[0] + '-' + fecR[1]
                                const detalle = localStorage.getItem('detalleIMPGasto');
                                const detalleImp = localStorage.getItem('detalleIDIMP');
                                
                                let PIva = parseFloat(parseFloat(parseFloat(impuesto.porcentaje_iva)/100)+1).toFixed(2);
                                let CGSuper = parseFloat(impuesto.costo_galon_super).toFixed(2);
                                let CGRegular = parseFloat(impuesto.costo_galon_regular).toFixed(2);
                                let CGDiesel = parseFloat(impuesto.costo_galon_diesel).toFixed(2);
                                
                                let comb = data.combustible;
                                let SumIDP = 0.00;
                                let SumBase = 0.00;
                                let IdpSuper = 0.00;
                                let IdpRegular = 0.00;
                                let IdpDiesel = 0.00;
                                let BaseSuper = 0.00;
                                let BaseRegular = 0.00;
                                let BaseDiesel = 0.00;
                                let BaseOtros = 0.00;
                                let IvaSup = 0.00;
                                let IvaReg = 0.00;
                                let IvaDie = 0.00;
                                let IvaOtros = 0.00;
                                let SumIva = 0.00;
                                let SumTotalC = 0.00;
                            
                                let gaSuper = data.gsuper;
                                let gaRegular = data.gregular;
                                let gaDiesel = data.gdiesel;

                                let costoSuper = data.csuper;
                                let costoRegular = data.cregular;
                                let costoDiesel = data.cdiesel;
                                let otros = data.otros;
                                if (gaSuper === undefined){
                                    gaSuper = 0.00
                                }
                                if (gaRegular === undefined){
                                    gaRegular = 0.00
                                }
                                if (gaDiesel === undefined){
                                    gaDiesel = 0.00
                                }

                                if (costoSuper === undefined){
                                    costoSuper = 0.00
                                }
                                if (costoRegular === undefined){
                                    costoRegular = 0.00
                                }
                                if (costoDiesel === undefined){
                                    costoDiesel = 0.00
                                }
                                if (otros === undefined){
                                    otros = 0.00
                                }

                                let baseB = 0.00;
                                let ivaB = 0.00;
                                let SumBaD = 0.00;
                                let baseS = 0.00;
                                let ivaS = 0.00;
                                let SumIvD = 0.00;
                                let SumTotalD = 0.00;

                                let pro = data.proveedor_liquidacion;
                                if (pro === undefined) {
                                    pro = data.proveedor;
                                } else {
                                    pro = data.proveedor_liquidacion.value;
                                }
                                if (datoParam.contabilidad === 'S') {
                                    poliza = 'compras';
                                }
                                if (datoParam.contabilidad === 'N') {
                                    poliza = '';
                                }

                                let bienes = data.bienes;
                                let servicios = data.servicios;
                                let exento = data.exento;

                                if (bienes === undefined) {
                                    bienes = 0.00
                                }
                                if (servicios === undefined) {
                                    servicios = 0.00
                                }
                                if (exento === undefined) {
                                    exento = 0.00
                                }
                                let iva = JSON.parse(localStorage.getItem('A_ivaLiqImp'));

                                //Verificar si la factura es mas de dos meses
                                let fechaI = moment(periodoD.fecha_inicio)
                                let date_f = moment(fechaI).subtract(2, 'months').format();
                                let date_fin = moment(date_f).subtract(1, 'days').format();
                                let fecha = moment(data.fecha).isBetween(date_fin, fechaI, 'days');
                                let dif = fechaI.diff(data.fecha, 'days')
                                if (fecha === false && dif >= 1) {
                                    if (comb === true) {
                                        iva = false;
                                        SumIDP = 0.00;
                                        BaseSuper = parseFloat(costoSuper).toFixed(2);
                                        BaseRegular = parseFloat(costoRegular).toFixed(2);
                                        BaseDiesel = parseFloat(costoDiesel).toFixed(2);
                                        BaseOtros = parseFloat(otros).toFixed(2);
                                        SumBase = parseFloat(parseFloat(BaseSuper) + parseFloat(BaseRegular) + parseFloat(BaseDiesel) + parseFloat(BaseOtros)).toFixed(2);
                                        SumIva = 0.00;
                                        SumTotalC = parseFloat(parseFloat(costoSuper) + parseFloat(costoRegular) + parseFloat(costoDiesel) + parseFloat(otros)).toFixed(2);
                                    }else{
                                        iva = false;
                                        baseB = parseFloat(bienes).toFixed(2);
                                        ivaB = 0.00;

                                        baseS = parseFloat(servicios).toFixed(2);
                                        ivaS = 0.00;

                                        SumBaD = parseFloat(parseFloat(baseB) + parseFloat(baseS)).toFixed(2);

                                        SumIvD = parseFloat(parseFloat(ivaB) + parseFloat(ivaS)).toFixed(2);
                                        SumTotalD = parseFloat(parseFloat(bienes) + parseFloat(servicios) + parseFloat(exento)).toFixed(2);
                                    }
                                } else {
                                    if (comb === true){
                                    
                                        IdpSuper = parseFloat(parseFloat(gaSuper).toFixed(3) * CGSuper).toFixed(2);
                                        IdpRegular = parseFloat(parseFloat(gaRegular).toFixed(3) * CGRegular).toFixed(2);
                                        IdpDiesel =  parseFloat(parseFloat(gaDiesel).toFixed(3) * CGDiesel).toFixed(2)
                                    
                                        SumIDP = parseFloat(parseFloat(IdpSuper) + parseFloat(IdpRegular) + parseFloat(IdpDiesel)).toFixed(2);
                                    
                                        BaseSuper = parseFloat((parseFloat(costoSuper)-parseFloat(IdpSuper))/PIva).toFixed(2);
                                        BaseRegular = parseFloat((parseFloat(costoRegular)-parseFloat(IdpRegular))/PIva).toFixed(2);
                                        BaseDiesel = parseFloat((parseFloat(costoDiesel)-parseFloat(IdpDiesel))/PIva).toFixed(2);
                                        BaseOtros = parseFloat(parseFloat(otros)/PIva).toFixed(2);
                                    
                                        SumBase = parseFloat(parseFloat(BaseSuper) + parseFloat(BaseRegular) + parseFloat(BaseDiesel) + parseFloat(BaseOtros)).toFixed(2);
                                    
                                        IvaSup = parseFloat((parseFloat(costoSuper)-parseFloat(IdpSuper))-parseFloat(BaseSuper)).toFixed(2);
                                        IvaReg = parseFloat((parseFloat(costoRegular)-parseFloat(IdpRegular))-parseFloat(BaseRegular)).toFixed(2);
                                        IvaDie = parseFloat((parseFloat(costoDiesel)-parseFloat(IdpDiesel))-parseFloat(BaseDiesel)).toFixed(2);
                                        IvaOtros = parseFloat(parseFloat(otros) - parseFloat(BaseOtros)).toFixed(2);
                                        
                                        SumIva = parseFloat(parseFloat(IvaSup)+parseFloat(IvaReg)+parseFloat(IvaDie)+parseFloat(IvaOtros)).toFixed(2);
                                        
                                        SumTotalC = parseFloat(parseFloat(costoSuper)+parseFloat(costoRegular)+parseFloat(costoDiesel)+parseFloat(otros)).toFixed(2);

                                    }
                                    if (comb === undefined) {
                                        comb = false;
                                    }
                                    if (iva === true && comb === false) {
                                        baseB = parseFloat(parseFloat(bienes) / PIva).toFixed(2);
                                        ivaB = parseFloat(parseFloat(bienes) - parseFloat(baseB)).toFixed(2);

                                        baseS = parseFloat(parseFloat(servicios) / PIva).toFixed(2);
                                        ivaS = parseFloat(parseFloat(servicios) - parseFloat(baseS)).toFixed(2);

                                        SumBaD = parseFloat(parseFloat(baseB) + parseFloat(baseS)).toFixed(2);

                                        SumIvD = parseFloat(parseFloat(ivaB) + parseFloat(ivaS)).toFixed(2);
                                        SumTotalD = parseFloat(parseFloat(bienes) + parseFloat(servicios) + parseFloat(exento)).toFixed(2);

                                    }
                                    if (iva === false) {
                                        SumBaD = parseFloat(parseFloat(bienes) + parseFloat(servicios) + parseFloat(exento)).toFixed(2);
                                        SumIvD = 0.00;
                                        SumTotalD = parseFloat(parseFloat(bienes) + parseFloat(servicios) + parseFloat(exento)).toFixed(2);
                                    }
                                }

                                const formData = {
                                    fecha_registro: datos.fechaRegistro,
                                    serie: serieL,
                                    numero: 1,
                                    descripcionL: data.observaciones,
                                    fecha: data.fecha,
                                    serieD: data.serieD ? data.serieD.toUpperCase() : '',
                                    numeroD: data.numeroD.toUpperCase(),
                                    afectaIva: iva,
                                    combustible: data.combustible,
                                    gSuper: data.gsuper,
                                    cSuper: data.csuper,
                                    gRegular: data.gregular,
                                    cRegular: data.cregular,
                                    gDiesel: data.gdiesel,
                                    cDiesel: data.cdiesel,
                                    otros: data.otros,
                                    idp: SumIDP,
                                    bienes: data.bienes,
                                    servicios: data.servicios,
                                    exento: data.exento,
                                    tipo: localStorage.getItem('tipoDocumentoLiqImp'),
                                    periodo: id_emp[7],
                                    identificador: data.identificador,
                                    proveedor_liq: pro,
                                    proveedor: data.proveedor_gasto.value,
                                    descripcion: data.descripcion,
                                    iva: comb ? SumIva : SumIvD,
                                    base: comb ? SumBase : SumBaD,
                                    total: comb ? SumTotalC : SumTotalD,
                                    modulo: 'CMP',
                                    empresa: id_emp[5],
                                    poliza: poliza,
                                    cuenta: datoParam.contabilidad === 'S' ? data.cuenta.value : '',
                                    detalle: detalle,
                                    detalleImp: detalleImp,
                                    idPoliza: localStorage.getItem('idPolizaImp')
                                }
                                dispatch(setLoader(true));
                                api.post('/liquidacion_gasto/importacion_gasto/', formData).then((response) => {
                                    localStorage.removeItem('base');
                                    localStorage.removeItem('iva');
                                    localStorage.removeItem('total');
                                    if ( detalle === undefined ){
                                        localStorage.setItem('detalleIMPGasto', response.compra.id);
                                    }
                                    if (detalle){
                                        response.fechaRegistro = response.compra.fecha_registro;
                                        response.numeroL = response.compra.numero;
                                        response.serie = response.compra.serie;
                                        response.proveedorN = response.compra.proveedorN
                                        response.observaciones = response.compra.descripcion;
                                        response.identificador = response.compra.nit_proveedor;
                                        response.totalLiq = response.total;
                                        
                                        localStorage.setItem('LIQ_Imp', true);
                                        const poliza = localStorage.getItem('idPolizaImp');
                                        dispatch(initializeForm('FormImportacionGastos', response));
                                        dispatch(leerDetalleLiquidacion(response.detalle));
                                        const editar = window.location.href.includes('editar');
                                        if (editar) {
                                            dispatch(leerDetalleMod(poliza));
                                        } else {
                                            dispatch(leerDetalle(poliza));
                                        }
                                    }else{
                                        response.fechaRegistro = response.compra.fecha_registro;
                                        response.numeroL = response.compra.numero;
                                        response.serie = response.compra.serie;
                                        response.proveedorN = response.compra.proveedorN
                                        
                                        response.observaciones = response.compra.descripcion;
                                        response.identificador = response.compra.nit_proveedor;
                                        response.totalLiq = response.total;
                                        
                                        localStorage.setItem('LIQ_Imp', true);
                                        localStorage.setItem('idPolizaImp', response.idPoliza);
                                        const poliza = localStorage.getItem('idPolizaImp');
                                        localStorage.setItem('detalleIMPGasto', response.detalle);
                                        dispatch(initializeForm('FormImportacionGastos', response));
                                        dispatch(leerDetalleLiquidacion(response.detalle));
                                        const editar = window.location.href.includes('editar');
                                        if (editar) {
                                            dispatch(leerDetalleMod(poliza));
                                        } else {
                                            dispatch(leerDetalle(poliza));
                                        }

                                    }
                                
                                    NotificationManager.success(
                                        'Documento creado correctamente',
                                        'Exito',
                                        3000
                                    );
                                
                                }).catch((error) => {
                                    NotificationManager.error(
                                        error.msj,
                                        'Error',
                                        0
                                    );
                                }).finally(() => {
                                    dispatch(setLoader(false));
                                });
                            }
                        }else {
                            const detalleLiq = localStorage.getItem('detalleIMPGasto');
                            let ruta = window.location.href;
                            let id_emp = ruta.split('/');
                            let fec = data.fechaRegistro
                            let fecR = fec.split('-')
                            let serieL = fecR[0] + '-' + fecR[1]

                            let PIva = parseFloat(parseFloat(parseFloat(impuesto.porcentaje_iva) / 100) + 1).toFixed(2);
                            let CGSuper = parseFloat(impuesto.costo_galon_super).toFixed(2);
                            let CGRegular = parseFloat(impuesto.costo_galon_regular).toFixed(2);
                            let CGDiesel = parseFloat(impuesto.costo_galon_diesel).toFixed(2);

                            let comb = data.combustible;
                            let SumIDP = 0.00;
                            let SumBase = 0.00;
                            let IdpSuper = 0.00;
                            let IdpRegular = 0.00;
                            let IdpDiesel = 0.00;
                            let BaseSuper = 0.00;
                            let BaseRegular = 0.00;
                            let BaseDiesel = 0.00;
                            let BaseOtros = 0.00;
                            let IvaSup = 0.00;
                            let IvaReg = 0.00;
                            let IvaDie = 0.00;
                            let IvaOtros = 0.00;
                            let SumIva = 0.00;
                            let SumTotalC = 0.00;

                            let gaSuper = data.gsuper;
                            let gaRegular = data.gregular;
                            let gaDiesel = data.gdiesel;

                            let costoSuper = data.csuper;
                            let costoRegular = data.cregular;
                            let costoDiesel = data.cdiesel;
                            let otros = data.otros;
                            if (gaSuper === undefined) {
                                gaSuper = 0.00
                            }
                            if (gaRegular === undefined) {
                                gaRegular = 0.00
                            }
                            if (gaDiesel === undefined) {
                                gaDiesel = 0.00
                            }

                            if (costoSuper === undefined) {
                                costoSuper = 0.00
                            }
                            if (costoRegular === undefined) {
                                costoRegular = 0.00
                            }
                            if (costoDiesel === undefined) {
                                costoDiesel = 0.00
                            }
                            if (otros === undefined) {
                                otros = 0.00
                            }

                            let baseB = 0.00;
                            let ivaB = 0.00;
                            let SumBaD = 0.00;
                            let baseS = 0.00;
                            let ivaS = 0.00;
                            let SumIvD = 0.00;
                            let SumTotalD = 0.00;

                            let pro = data.proveedor_liquidacion;
                            if (pro === undefined) {
                                pro = data.proveedor;
                            } else {
                                pro = data.proveedor_liquidacion.value;
                            }
                            if (datos.contabilidad === 'S') {
                                poliza = 'compras';
                            }
                            if (datos.contabilidad === 'N') {
                                poliza = '';
                            }

                            let bienes = data.bienes;
                            let servicios = data.servicios;
                            let exento = data.exento;

                            if (bienes === undefined) {
                                bienes = 0.00
                            }
                            if (servicios === undefined) {
                                servicios = 0.00
                            }
                            if (exento === undefined) {
                                exento = 0.00
                            }
                            let iva = JSON.parse(localStorage.getItem('A_iva'));

                            //Verificar si la factura es mas de dos meses
                            let fechaI = moment(periodoD.fecha_inicio)
                            let date_f = moment(fechaI).subtract(2, 'months').format();
                            let date_fin = moment(date_f).subtract(1, 'days').format();
                            let fecha = moment(data.fecha).isBetween(date_fin, fechaI, 'days');
                            let dif = fechaI.diff(data.fecha, 'days')
                            if (fecha === false && dif >= 1) {
                                if (comb === true) {
                                    iva = false;
                                    SumIDP = 0.00;
                                    BaseSuper = parseFloat(costoSuper).toFixed(2);
                                    BaseRegular = parseFloat(costoRegular).toFixed(2);
                                    BaseDiesel = parseFloat(costoDiesel).toFixed(2);
                                    BaseOtros = parseFloat(otros).toFixed(2);
                                    SumBase = parseFloat(parseFloat(BaseSuper) + parseFloat(BaseRegular) + parseFloat(BaseDiesel) + parseFloat(BaseOtros)).toFixed(2);
                                    SumIva = 0.00;
                                    SumTotalC = parseFloat(parseFloat(costoSuper) + parseFloat(costoRegular) + parseFloat(costoDiesel) + parseFloat(otros)).toFixed(2);
                                }else{
                                    iva = false;
                                    baseB = parseFloat(bienes).toFixed(2);
                                    ivaB = 0.00;

                                    baseS = parseFloat(servicios).toFixed(2);
                                    ivaS = 0.00;

                                    SumBaD = parseFloat(parseFloat(baseB) + parseFloat(baseS)).toFixed(2);

                                    SumIvD = parseFloat(parseFloat(ivaB) + parseFloat(ivaS)).toFixed(2);
                                    SumTotalD = parseFloat(parseFloat(bienes) + parseFloat(servicios) + parseFloat(exento)).toFixed(2);
                                }
                            } else {
                                if (comb === true) {

                                    IdpSuper = parseFloat(parseFloat(gaSuper).toFixed(3) * CGSuper).toFixed(2);
                                    IdpRegular = parseFloat(parseFloat(gaRegular).toFixed(3) * CGRegular).toFixed(2);
                                    IdpDiesel = parseFloat(parseFloat(gaDiesel).toFixed(3) * CGDiesel).toFixed(2)

                                    SumIDP = parseFloat(parseFloat(IdpSuper) + parseFloat(IdpRegular) + parseFloat(IdpDiesel)).toFixed(2);

                                    BaseSuper = parseFloat((parseFloat(costoSuper) - parseFloat(IdpSuper)) / PIva).toFixed(2);
                                    BaseRegular = parseFloat((parseFloat(costoRegular) - parseFloat(IdpRegular)) / PIva).toFixed(2);
                                    BaseDiesel = parseFloat((parseFloat(costoDiesel) - parseFloat(IdpDiesel)) / PIva).toFixed(2);
                                    BaseOtros = parseFloat(parseFloat(otros) / PIva).toFixed(2);

                                    SumBase = parseFloat(parseFloat(BaseSuper) + parseFloat(BaseRegular) + parseFloat(BaseDiesel) + parseFloat(BaseOtros)).toFixed(2);

                                    IvaSup = parseFloat((parseFloat(costoSuper) - parseFloat(IdpSuper)) - parseFloat(BaseSuper)).toFixed(2);
                                    IvaReg = parseFloat((parseFloat(costoRegular) - parseFloat(IdpRegular)) - parseFloat(BaseRegular)).toFixed(2);
                                    IvaDie = parseFloat((parseFloat(costoDiesel) - parseFloat(IdpDiesel)) - parseFloat(BaseDiesel)).toFixed(2);
                                    IvaOtros = parseFloat(parseFloat(otros) - parseFloat(BaseOtros)).toFixed(2);

                                    SumIva = parseFloat(parseFloat(IvaSup) + parseFloat(IvaReg) + parseFloat(IvaDie) + parseFloat(IvaOtros)).toFixed(2);

                                    SumTotalC = parseFloat(parseFloat(costoSuper) + parseFloat(costoRegular) + parseFloat(costoDiesel) + parseFloat(otros)).toFixed(2);

                                }

                                if (comb === undefined) {
                                    comb = false;
                                }
                                if (iva === true && comb === false) {
                                    baseB = parseFloat(parseFloat(bienes) / PIva).toFixed(2);
                                    ivaB = parseFloat(parseFloat(bienes) - parseFloat(baseB)).toFixed(2);

                                    baseS = parseFloat(parseFloat(servicios) / PIva).toFixed(2);
                                    ivaS = parseFloat(parseFloat(servicios) - parseFloat(baseS)).toFixed(2);

                                    SumBaD = parseFloat(parseFloat(baseB) + parseFloat(baseS)).toFixed(2);

                                    SumIvD = parseFloat(parseFloat(ivaB) + parseFloat(ivaS)).toFixed(2);
                                    SumTotalD = parseFloat(parseFloat(bienes) + parseFloat(servicios) + parseFloat(exento)).toFixed(2);

                                }
                                if (iva === false) {
                                    SumBaD = parseFloat(parseFloat(bienes) + parseFloat(servicios) + parseFloat(exento)).toFixed(2);
                                    SumIvD = 0.00;
                                    SumTotalD = parseFloat(parseFloat(bienes) + parseFloat(servicios) + parseFloat(exento)).toFixed(2);
                                }
                            }

                            const formData = {
                                fecha_registro: data.fechaRegistro,
                                serie: serieL,
                                numero: 1,
                                descripcionL: data.observaciones,
                                fecha: data.fecha,
                                serieD: data.serieD ? data.serieD.toUpperCase() : '',
                                numeroD: data.numeroD.toUpperCase(),
                                afectaIva: iva,
                                combustible: data.combustible,
                                gSuper: data.gsuper,
                                cSuper: data.csuper,
                                gRegular: data.gregular,
                                cRegular: data.cregular,
                                gDiesel: data.gdiesel,
                                cDiesel: data.cdiesel,
                                otros: data.otros,
                                idp: SumIDP,
                                bienes: data.bienes,
                                servicios: data.servicios,
                                exento: data.exento,
                                tipo: localStorage.getItem('tipoDocumentoLiqImp'),
                                periodo: id_emp[7],
                                proveedor_liq: pro,
                                proveedor: data.proveedor_documento.value,
                                descripcion: data.descripcion,
                                iva: comb ? SumIva : SumIvD,
                                base: comb ? SumBase : SumBaD,
                                total: comb ? SumTotalC : SumTotalD,
                                modulo: 'CMP',
                                empresa: id_emp[5],
                                poliza: poliza,
                                cuenta: '',
                                detalle: detalleLiq,
                                idPoliza: localStorage.getItem('idPolizaImp')
                            }

                            dispatch(setLoader(true));
                            api.post('/liquidacion_gasto/importacion_gasto/', formData).then((response) => {
                                if ( detalle === undefined ){
                                    localStorage.setItem('detalleIMPGasto', response.compra.id);
                                }
                                if (detalleLiq) {
                                    response.fechaRegistro = response.compra.fecha_registro;
                                    response.numeroL = response.compra.numero;
                                    response.serie = response.compra.serie;
                                    response.proveedorN = response.compra.proveedorN
                                    response.observaciones = response.compra.descripcion;
                                    response.identificador = response.compra.nit_proveedor;
                                    response.totalLiq = response.total;
                                
                                    localStorage.setItem('LIQ_Imp', true);
                                    dispatch(initializeForm('FormImportacionGastos', response));
                                    dispatch(leerDetalleLiquidacion(response.detalle));

                                } else {
                                    response.fechaRegistro = response.compra.fecha_registro;
                                    response.numeroL = response.compra.numero;
                                    response.serie = response.compra.serie;
                                    response.proveedorN = response.compra.proveedorN;

                                    response.observaciones = response.compra.descripcion;
                                    response.identificador = response.compra.nit_proveedor;
                                    response.totalLiq = response.total;
                                    
                                    localStorage.setItem('LIQ_Imp', true);
                                    localStorage.setItem('detalleIMPGasto', response.detalle);
                                    dispatch(initializeForm('FormImportacionGastos', response));
                                    dispatch(leerDetalleLiquidacion(response.detalle));
                                }

                                NotificationManager.success(
                                    'Documento creado correctamente',
                                    'Exito',
                                    3000
                                );

                            }).catch((error) => {
                                NotificationManager.error(
                                    error.msj,
                                    'Error',
                                    0
                                );
                            }).finally(() => {
                                dispatch(setLoader(false));
                            });
                        }
                    }
                }
            }
        }
    }
}


export const revisarTipoCambio = (params, fecha) => (dispatch) => {

    api.get('/tipo_cambio/tipo_cambio_empresa/', params).then(response => {
        if (response.tipoCambio === false){
            Swal.fire({
                title: 'No hay tipo de cambio del dia'+' '+moment(fecha).format("DD/MM/YYYY"),
                html: 
                '<div class="col-sm-12 mt-4">' +
                '<h4 style="color: white;">¿Desea ingresarlo?</h4>' +
                '</div>',
                type: 'info',
                background: "black",
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No',
                confirmButtonColor: "#4040ff",
                cancelButtonColor: "#646464",
                reverseButtons: true,
                customClass: {
                    title: 'sweet_titleImportant',
                },
            }).then((result) => {
                if (result.value) {
                    Swal.fire({
                        title: 'Ingrese tipo de cambio',
                        html: 
                        '<div class="col-sm-12 mt-3">' +
                        '<h4 style="color: white">'+moment(fecha).format("DD/MM/YYYY")+'</h4>' +
                        '<input type="number" min="1" id="cambio" class="swal2-input form-control">' +
                        '</div>',
                        type: 'info',
                        background: "black",
                        showCancelButton: true,
                        confirmButtonText: 'Si',
                        cancelButtonText: 'No',
                        confirmButtonColor: "#4040ff",
                        cancelButtonColor: "#646464",
                        reverseButtons: true,
                        customClass: {
                            title: 'sweet_titleImportant',
                        },
                    }).then((result) => {
                        if (result.value) {
                            let tcambio = document.getElementById('cambio').value
                            const formData = {
                                fechaT: fecha,
                                tipoCambio: tcambio,
                                empresa: localStorage.getItem('id'),
                                tipo: 'manual'    
                            }
                            api.post('/tipo_cambio/', formData).then(response => {
                                response.tipo_cambio = response.tipo_cambio;
                                dispatch(initializeForm('FormImportacion', response));
                                localStorage.setItem('tipoCambio', response.tipo_cambio)
                                NotificationManager.success(
                                    'Tipo de cambio guardado',
                                    'Exito',
                                    4000
                                );
                                dispatch(push("/compras/importaciones/crear"));
                            }).catch((error) => {
                                NotificationManager.error(
                                    error.msj,
                                    'Error',
                                    0
                                );
                            })
                        }
                    });
                }else{
                    dispatch(push("/compras/importaciones/crear"));
                }
            });

        }else{
            response.tipo_cambio = response.tCambio
            dispatch(initializeForm('FormImportacion', response));
            localStorage.setItem('tipoCambio', response.tCambio)
            dispatch(push("/compras/importaciones/crear"));
        }
    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            0
        );
    })
}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = { id, modulo }
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response) => {
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const tipoCambio = (fechaInicio, fechaFinal) => (dispatch, getStore) => {
    const data = getStore().form.FormImportacion.values;
    
    if (data === undefined){
        Swal.fire({
            title: 'Importación',
            text: 'Ingrese fecha de importación',
            type: 'error',
        })    
    }else{
        let fechaI = moment(fechaInicio);
        let fechaF = moment(fechaFinal);
        let fecha = moment(data.fechaRegistro)
        
        if (fecha>=fechaI && fecha<=fechaF){
            let fecha = data.fechaImportacion;
            let ruta = window.location.href;
            let id_emp = ruta.split('/');
            let idEmpre = id_emp[5];
            const params = {fecha, idEmpre }
            dispatch(setLoaderC(true));
            return api.get('/tipo_cambio/tipo_cambio_fecha/',params).then((response) => {
                if (parseFloat(response) === 0.000000 ){
                    Swal.fire({
                        title: 'No hay tipo de cambio del dia'+' '+moment(fecha).format("DD/MM/YYYY"),
                        html: 
                        '<div class="col-sm-12 mt-4">' +
                        '<h4 style="color: white;">¿Desea ingresarlo?</h4>' +
                        '</div>',
                        type: 'info',
                        background: "black",
                        showCancelButton: true,
                        confirmButtonText: 'Si',
                        cancelButtonText: 'No',
                        confirmButtonColor: "#4040ff",
                        cancelButtonColor: "#646464",
                        reverseButtons: true,
                        customClass: {
                            title: 'sweet_titleImportant',
                        },
                    }).then((result) => {
                        if (result.value) {
                            Swal.fire({
                                title: 'Ingrese tipo de cambio',
                                html: 
                                '<div class="col-sm-12 mt-3">' +
                                '<h4 style="color: white">'+moment(fecha).format("DD/MM/YYYY")+'</h4>' +
                                '<input type="number" min="1" id="cambio" class="swal2-input form-control">' +
                                '</div>',
                                type: 'info',
                                background: "black",
                                showCancelButton: true,
                                confirmButtonText: 'Si',
                                cancelButtonText: 'No',
                                confirmButtonColor: "#4040ff",
                                cancelButtonColor: "#646464",
                                reverseButtons: true,
                                customClass: {
                                    title: 'sweet_titleImportant',
                                },
                            }).then((result) => {
                                if (result.value) {
                                    let tcambio = document.getElementById('cambio').value
                                    const formData = {
                                        fechaT: fecha,
                                        tipoCambio: tcambio,
                                        empresa: id_emp[5],
                                        tipo: 'manual'    
                                    }
                                    api.post('/tipo_cambio/', formData).then(response => {
                                        NotificationManager.success(
                                            'Tipo de cambio guardado',
                                            'Exito',
                                            4000
                                        );
                                        localStorage.setItem('tipoCambio', response.tipo_cambio)
                                        return response.tipo_cambio;
                                        
                                    }).catch((error) => {
                                        NotificationManager.error(
                                            error.msj,
                                            'Error',
                                            0
                                        );
                                    })
                                }
                            });
                        }
                    });
        
                }else{
                    return response;
                }
                
            }).catch((error)=>{
                NotificationManager.error(
                    'Ocurrió un error al consultar tipo de cambio',
                    'Error',
                    2000
                );
            }).finally(() => {
                dispatch(setLoaderC(false));
            });
        }else{
            Swal.fire({
                title: 'Importación',
                text: 'Fecha fuera del periodo',
                type: 'error',
            })        
        }
    }
}

export const parametrosEmpresa = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id}
    dispatch(setLoader(true));
    api.get('/empresa/empresa_parametros', params).then((response) => {
        dispatch({type: PARAMETRO, datos: response });
        api.get('/empresa/empresa_impuesto', params).then((imp) => {
            if (imp.validar === 'false'){
                Swal.fire({
                    title: 'Importación',
                    text: imp.msj,
                    type: 'info',
                    allowOutsideClick: false,
                    confirmButtonText: "Continuar",
                }).then((result) => {
                    if (result.value) {
                        let ruta = `/ajustes/${id_emp[5]}/impuestos`
                        dispatch(push(ruta));
                    }
                });
            }
            if (imp.validar === 'true'){
                api.get('/empresa/empresa_configuracion_poliza', params).then((datos) => {
                    if (datos.validar === 'false'){
                        Swal.fire({
                            title: 'Importación',
                            text: datos.msj,
                            type: 'info',
                            allowOutsideClick: false,
                            confirmButtonText: "Continuar",
                        }).then((result) => {
                            if (result.value) {
                                let ruta = `/contabilidad/${id_emp[5]}/config`
                                dispatch(push(ruta));
                            }
                        });
                    }
                    
                }).catch((error)=>{
                    
                    NotificationManager.error(
                        'Ocurrió un error al consultar impuesto',
                        'Error',
                        8000
                    );
                })
            }
            
        }).catch((error)=>{
            
            NotificationManager.error(
                'Ocurrió un error al consultar parametros',
                'Error',
                8000
            );
        })
    }).catch((error)=>{
        
        NotificationManager.error(
            'Ocurrió un error al consultar parametros',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const listarBodegas = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    api.get('/bodega', params).then((response) => {
        if (response.length>=1){
            const bodega = [];
            response.forEach(item=>{
                bodega.push({
                    value: item.id,
                    label: item.numero + ' / ' + item.bodega
                })
            })
            dispatch({ type: LISTADO_BODEGAS, listaBodegas: bodega });
        }
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar bodegas',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const listarProductos = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id, search}
    return api.get('/producto/empresa_productos_cod',params).then(data=>{
        const list_producto = [];
        if(data){
            data.forEach(item=>{
                list_producto.push({
                    value: item.id,
                    label: item.SKU + ' / ' + item.producto
                })
            })
        }
        return list_producto ;
    }).catch((error)=>{
        return [];
    })
}

export const registroProductoInventario = (impuesto) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormImportacionInventario.values;
    const datos = getStore().form.FormImportacion.values;
    const detalle = localStorage.getItem('id_detalle_inventario_IMP');
    let PIva = parseFloat(parseFloat(parseFloat(impuesto.porcentaje_iva)/100)+1).toFixed(2);
    let totalPro = parseFloat(parseFloat(data.cantidad)*parseFloat(data.precio)).toFixed(2);
    
    const formData = {
        bodega: data.bodega,
        producto: data.producto.value,
        cantidad: data.cantidad,
        precio_unitario: data.precio,
        total: totalPro,
        id_importacion: localStorage.getItem('detalleIDIMP'),
        fecha: datos.fechaRegistro,
        costo_unitario_s_iva: parseFloat(parseFloat(data.precio)/PIva).toFixed(6),
        total_s_iva: parseFloat(parseFloat(totalPro)/PIva).toFixed(2),
        costo_unitario_Q: parseFloat(parseFloat(data.precio)*parseFloat(localStorage.getItem('tipoCambio'))).toFixed(6),
        total_Q: parseFloat(parseFloat(totalPro)*parseFloat(localStorage.getItem('tipoCambio'))).toFixed(2),
        motivo: 'IMP',
        descripcion: datos.observaciones,
        empresa: id_emp[5],
        tipoDoc: 'IMP',
        periodo: id_emp[7],
        tipo_cambio: localStorage.getItem('tipoCambio'),
        detalle: detalle,
        lote: data.lote ? data.lote : '',
        fecha_vencimiento: data.fecha_vencimiento ? data.fecha_vencimiento : ''
    }
    dispatch(setLoader(true));
    api.post('/movimiento_inventario/', formData).then((response) => {
        response.bodega = response.detalle.bodega
        localStorage.setItem('id_detalle_inventario_IMP', response.encabezado.id);
        if (detalle){
            dispatch(initializeForm('FormImportacionInventario', response));
            dispatch(leerDetalleProductos(response.encabezado.id));
        }else{
            dispatch(initializeForm('FormImportacionInventario', response));
            localStorage.setItem('id_detalle_inventario_IMP', response.encabezado.id);
            dispatch(leerDetalleProductos(response.encabezado.id));
        }
        NotificationManager.success(
            'Producto creado correctamente',
            'Exito',
            3000
        );
    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const leerDetalleProductos = id => (dispatch) => {
    api.get(`/movimiento_inventario_detalle/${id}`).then((response)=>{
        dispatch({ type: DETALLE_PRODUCTOS, detalleProducto: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};

export const listarImpuestos = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5]
    const params = { id };
    dispatch(setLoader(true));
    api.get('/impuesto/impuesto_empresa', params).then((response) => {
        dispatch({ type: LISTADO_IMPUESTO, impuesto: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar impuesto',
            'Error',
            3000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const eliminarLineaProducto = id => (dispatch) => {
    const detalle = localStorage.getItem('id_detalle_inventario_IMP');
    dispatch(setLoader(true));
    api.eliminar(`/movimiento_inventario_detalle/${id}`).then(() => {
        dispatch(leerDetalleProductos(detalle));
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leerDetalleInventario = id => (dispatch) => {
    api.get(`/movimiento_inventario_detalle/${id}`).then((response)=>{
        dispatch({ type: DETALLE_PRODUCTOS, detalleProducto: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar detalle del inventario',
            'Error',
            4000
        );
    })
};

export const imprimirProrrateo = id => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let idE = id_emp[5];
    let id_imp = id;
    let reporte = 'imprimirProrrateo';
    const params = { idE, reporte, id_imp };
    dispatch(setLoader(true));
    api.getPdf('/movimiento_inventario', params).then((response) => {
        if (response.headers['content-type'] == 'application/pdf') {
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'prorrateo.pdf';
            window.open(link, '_blank');
            return false;
        }
    }).catch((error) => {
        NotificationManager.error(
            error.body.msj,
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const listarPaises = () => (dispatch) => {
    return api.get("/paises").then(response=>{
        const pais = [];
        response.results.forEach(item=>{
            pais.push({
                value: item.id,
                label: item.pais.toUpperCase()
            })
        })
        dispatch({ type: LISTADO_PAISES, listaP: pais });
    }).catch(error=>{
        
        NotificationManager.error(
            'Ocurrió un error al listar paises',
            'Error',
            8000
        );
    })
}

export const actions = {
    listar,
    registroImportacion,
    modificarImportacion,
    registroCompra,
    leer,
    listarTiposDocu,
    listarProveedores,
    leerDetalle,
    eliminarLinea,
    listarCuentas,
    listarCC,
    listarNiveles,
    registroCuenta,
    reporteCuentas,
    registroPolizaDetalle,
    confirmarPoliza,
    leerPeriodo,
    registroPolizaDetalleSCC,
    leerDetalleLiquidacion,
    eliminarDocumento,
    eliminarImportacion,
    registroProveedor,
    leerDetalleImportacion,
    listarProveedoresGastos,
    registroProveedorExt,
    eliminarDocumentoImportacion,
    revisarTipoCambio,
    leerPeriodoD,
    getModulo,
    tipoCambio,
    parametrosEmpresa,
    listarImpuestos,
    listarBodegas,
    listarProductos,
    registroProductoInventario,
    eliminarLineaProducto,
    leerDetalleInventario,
    imprimirProrrateo,
    listarPaises
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_IMPORTACION]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [DETALLE_POLIZA]: (state, { detalle }) => {
        return {
            ...state,
            detalle,
        };
    },
    [LISTADO_TIPOS_DOCU]: (state, { tipos }) => {
        return {
            ...state,
            tipos,
        };
    },
    [DETALLE_LIQUIDACION]: (state, { detalleLiq }) => {
        return {
            ...state,
            detalleLiq,
        };
    },
    [DETALLE_IMPORTACION]: (state, { detalleImp }) => {
        return {
            ...state,
            detalleImp,
        };
    },
    [NIVEL]: (state, { nivel }) => {
        return {
            ...state,
            nivel,
        };
    },
    [LOADERC]: (state, { loaderC }) => {
        return {
            ...state,
            loaderC,
        };
    },
    [PERIODO]: (state, { periodoS }) => {
        return {
            ...state,
            periodoS,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [PERIODO_D]: (state, { periodoD }) => {
        return {
            ...state,
            periodoD,
        };
    },
    [GUARDAR_IMPORTACION]: (state, { lectura_data }) => {
        return {
            ...state,
            lectura_data,
        };
    },
    [GUARDAR_POLIZA]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
    [PARAMETRO]: (state, { datos }) => {
        return {
            ...state,
            datos,
        };
    },
    [DETALLE_PRODUCTOS]: (state, { detalleProducto }) => {
        return {
            ...state,
            detalleProducto,
        };
    },
    [LISTADO_BODEGAS]: (state, { listaBodegas }) => {
        return {
            ...state,
            listaBodegas,
        };
    },
    [LISTADO_IMPUESTO]: (state, { impuesto }) => {
        return {
            ...state,
            impuesto,
        };
    },
    [LISTADO_PAISES]: (state, { listaP }) => {
        return {
            ...state,
            listaP,
        };
    },
};

export const initialState = {
    loader: false,
    tipos: [],
    lectura: {
        polizaDetalle_polizaEncabezado: []
    },
    periodoS: null,
    periodoD: [],
    modulo: [],
    nivel: [],
    datos: [],
    loaderC: false,
    detalle: [],
    detalleLiq: [],
    detalleImp: [],
    lectura_data: [],
    detalleProducto: [],
    listaBodegas: [],
    listaP: [],
    impuesto: [],
    data: [],
    page: 1,
};

export default handleActions(reducers, initialState)