import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import NavbarModulo from '../../components/NavbarModulo';
import DetalleListado from './DetalleSerieLIst';
import styled from 'styled-components';

import {
    renderField,
    SelectField,
} from "../../Utils/renderField/renderField";

function SerieForm(props) {
    const {
        crear,
        modulo,
        periodoD,
        listar,
        registroSerie,
        listaBodegas,
        eliminarSerie
    } = props;

    const estilo = {
        overflow: "auto",
        height: "400px",

    };

    const tipo = [
        { label: "INGRESO PRODUCCION", value: "INGRESO PRODUCCION" },
        { label: "ENVIO", value: "ENVIO" },
        { label: "ORDEN PRODUCCION", value: "ORDEN PRODUCCION" },
        { label: "CONSUMO MATERIA PRIMA", value: "CONSUMO MATERIA PRIMA" },
        { label: "NOTA DE COBRO", value: "NOTA DE COBRO" },
        { label: "NOTA DE DEVOLUCION", value: "NOTA DE DEVOLUCION" },
        { label: "RECIBO DE CAJA", value: "RECIBO DE CAJA" }
    ];
    const mov = [
        { label: "INVENTARIO", value: "INV" },
        { label: "AGRICOLA", value: "AGR" },
    ];
    let titulo = 'Series';
    let ruta = window.location.href;
    let id_emp = ruta.split('/');

    return (
        <div>
            <NavbarModulo
                periodoD = {periodoD}
                modulo = {modulo}
                titulo = {titulo}
            />
            <br />
            <div className="mb-4 card card-small text-white bg-dark mb-3">
                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                    <div className="d-flex flex-column flex-1 mx-4">
                        <div className='row mb-2'>
                            <div className='col-4'>
                                <div className="form-floating">
                                    <Field
                                        name="tipo"
                                        options={tipo}
                                        crear={crear}
                                        component={SelectField}
                                        className="form-select"
                                        msj="Tipo registro"
                                    />
                                </div>
                            </div>
                            <div className='mb-2 col-2'>
                                <Field
                                    name="serie"
                                    placeholder="Serie"
                                    className="form-control"
                                    component={renderField}
                                    msj="Serie"
                                />
                            </div>
                            <div className='mb-2 col-2'>
                                <Field
                                    name="movimiento"
                                    options={mov}
                                    crear={crear}
                                    component={SelectField}
                                    className="form-select"
                                    msj="Movimiento"
                                    />
                            </div>
                            <div className='col-4'>
                                <div className="form-floating">
                                    <Field
                                        name="bodega"
                                        options={listaBodegas}
                                        component={SelectField}
                                        className="form-select"
                                        msj="Bodega"
                                    />
                                </div>
                            </div>
                        </div>
                        <Contenido>
                            <button
                                className='btn mr-2 mb-3 btn-primary'
                                onClick={() => registroSerie()}
                            >
                                Guardar serie
                            </button>
                        </Contenido>
                        <br/>
                        <div style={estilo}>
                            <DetalleListado
                                detalle={listar}
                                eliminarLinea={eliminarSerie}
                            />
                        </div>
                        <br />
                        <div className='d-flex flex-row mt-3'>
                            <a
                                href = {`/#/produccion/${id_emp[5]}`}
                                className='btn btn-secondary mb-3'
                            >
                                Regresar
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

SerieForm.propTypes = {
    crear: PropTypes.bool,
    lectura: PropTypes.object,
    editar: PropTypes.bool,
    bloqueo: PropTypes.bool,
    modulo: PropTypes.object,
    periodoD: PropTypes.object,
    listar: PropTypes.array,
    registroSerie: PropTypes.func,
    listaBodegas: PropTypes.array,
    eliminarSerie: PropTypes.func
};

const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;

export default reduxForm({
    form: 'FormSeries',
})(SerieForm);
