import React from "react";
import styled from "styled-components";
const InputsContainerElement = styled.div`
    background-color: #2b2b2b;
    padding: 2rem;
    /* display: flex;
    flex-wrap: wrap;
    justify-content: stretch; */
    /* margin-bottom: 1rem; */
    border-radius: 5px;
    /* justify-content: center; */
    gap: 12px;
    /* justify-items: center; */
    /* gap: 0.325rem; */
    /* grid-template-columns: 1fr 1fr 1fr; */
    display: grid;
    /* overflow-x: scroll; */
    overflow: visible;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    min-width: 460px;
`;

export default function InputsContainer({ children, ...props }) {
    return (
        <InputsContainerElement {...props}>{children}</InputsContainerElement>
    );
}
