import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';

import {
    renderFilePickerB,
} from "../../Utils/renderField/renderField";

function SubirOcupacionesForm(props) {
    const { handleSubmit, setArchivo } = props;

    return (
        <div>
            <form onSubmit={handleSubmit}>
            <br />
            <div className="mb-4 card card-small text-white bg-dark mb-3">
                <div className="border-bottom card-header"><h6 className="m-0">Subir ocupaciones</h6></div>
                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                    <div className="d-flex flex-column flex-1 mx-4">
                        <div className='mb-2 w-25'>
                            <Field
                                photo={''}
                                setFile={setArchivo}
                                name="archivo"
                                component={renderFilePickerB}
                            />
                        </div>
                        <div className='d-flex flex-row mt-3'>

                            <button
                                className='btn mr-2 mb-3 btn-primary'
                                type='submit'
                            >
                                Subir ocupaciones
                            </button>

                            <a
                                href={'/#/tools/home'}
                                className='btn btn-secondary mb-3'
                            >
                                Regresar
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            </form>
        </div>
    );
}

SubirOcupacionesForm.propTypes = {
    subirProductos: PropTypes.func,
    listarEmpresas: PropTypes.func,
};

export default reduxForm({
    form: 'FormSubirOcupacion'
})(SubirOcupacionesForm);
