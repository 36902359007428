import React, { useState } from "react";
import styled from "styled-components";
import { FicInput, FicCheckbox } from "../../Utils/FormInputComponents";
import Banner from "../common/Banner";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { validar } from "../../components/ValidarNit";
import GenBtn from "../../components/GenBtn";
import InputsContainer from "../common/InputsContainer";
import { crear } from "./actions";
import LoadMask from "../../Utils/LoadMask/LoadMask";
import { withRouter } from "react-router";
import { useEffect } from "react";
import { getSchema, getDefaultValues } from "./helpers/getFormSettings";

const BANNER_TEXT = "Agregar cliente";

function Crear({ history, ...props }) {
    const [isLoading, setIsLoading] = useState(false);
    const schema = getSchema();
    const { control, reset, handleSubmit } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: getDefaultValues(),
    });

    async function onSubmit(data) {
        setIsLoading(true);
        await crear(data);
        setIsLoading(false);
    }

    return (
        <React.Fragment>
            <Banner>{BANNER_TEXT}</Banner>
            <GenBtn
                onPress={() => history.goBack()}
                style={{ margin: "5px 0" }}
            >
                Regresar
            </GenBtn>
            <LoadMask loading={isLoading} color="#bd0034" blur={true}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <InputsContainer>
                        <FicInput control={control} label="Nit" name="nit" />
                        <FicInput
                            control={control}
                            label="Nombre"
                            name="nombre"
                        />
                        <FicInput
                            control={control}
                            label="Direccion"
                            name="direccion"
                        />
                        <FicInput
                            control={control}
                            label="Email"
                            name="email"
                        />
                        <FicInput
                            control={control}
                            label="Telefono"
                            name="telefono"
                        />
                        <FicCheckbox
                            control={control}
                            label="Afiliada?"
                            name="afiliada"
                        />
                    </InputsContainer>
                    <GenBtn style={{ margin: "1rem 0" }} type="submit">
                        Guardar
                    </GenBtn>
                </form>
            </LoadMask>
        </React.Fragment>
    );
}
export default withRouter(Crear);
