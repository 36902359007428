import { handleActions, createAction } from 'redux-actions';
import { NotificationManager } from "react-notifications";
import { api } from "api";

const ListarTiposAction = createAction("LISTARTIPO")

export const listarTipos = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id }
    return api.get('auto_ventas/tipo', params).then(response => {
        // dispatch({type: LISTAR_TIPOS, data: response})
        dispatch(ListarTiposAction(response));
    }).catch((error) => {
        return [];
    })
}

export const crearTipo = (data) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const formData = {
        nombre: data.tipo,
        empresa: id_emp[5],
    }
    return api.post('auto_ventas/tipo', formData).then(() => {
        NotificationManager.success(
            'Tipo agregado',
            'Exito',
            3000
        );
    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            2000
        );
    })
}

export const editarTipo = (data, marcaId) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const formData = {
        empresa: id_emp[5],
        nombre: data.tipo
    }
    return api.put(`auto_ventas/tipo/${marcaId}`, formData).then(() => {
        NotificationManager.success(
            'Tipo Editado',
            'Exito',
            3000
        );
    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            2000
        );
    })
}

export const actions = {
    crearTipo,
    editarTipo,
    listarTipos
};

export const reducers = {
    [ListarTiposAction]: (state, action) => {
        return {
            ...state,
            data: action.payload
        }
    },
};

export const initialState = {
    modulo:[],
    data: [],
};

export default handleActions(reducers, initialState)