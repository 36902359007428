import React, { Component } from 'react';
import Formulario from './AumentoSalarioForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";
const valEmp = __CONFIG__.empresa;

class AumentoSalario extends Component{
    
    componentDidMount = () => {
        const { getModulo, limpiarDatos } = this.props;
        
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        if (valEmp === 'fage'){
            getModulo(modulo[6],modulo[5])
        }else{
            getModulo(modulo[5],modulo[4]);
        }
        limpiarDatos();
    }
    
    render(){
        const { listarColaborador, loader, modulo, listarPaises, emp, guardarSalario,
            datosColaborador, cargo, historialSal, eliminarLinea } = this.props;
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    modulo = {modulo}
                    listarColaborador = {listarColaborador}
                    listarPaises = {listarPaises}
                    emp = {emp}
                    guardarSalario = {guardarSalario}
                    cargo = {cargo}
                    historialSal = {historialSal}
                    datosColaborador = {datosColaborador}
                    eliminarLinea = {eliminarLinea}
                />
            </LoadMask>
        );
    }
}

export default AumentoSalario;