import React, { Component } from 'react';
import Formulario from './FacturacionPosForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";

class FacturacionPos extends Component {
    //deshabilita el boton crear
    state = {
        crear: true,
    }

    componentDidMount = () => {
        const { match, listadoProductos, getModulo } = this.props;
        const id = match.params.id;
        if (id) {
            this.setState({ crear: false });
            leer(id);
        }
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        getModulo(modulo[5], modulo[4]);
    }

    render() {
        const { loader, modulo, listarProductos, data, listarClientes } = this.props;
        const { crear } = this.state;
        // Si crear esta habilitado ejecute registro bodega
        // const funcionEnvio = crear ? registroBodega : modificarBodega;
        return (
            <LoadMask loading={loader} dark>
                <Formulario
                    // onSubmit={funcionEnvio}
                    // crear={crear}
                    modulo={modulo}
                    listarProductos={listarProductos}
                    // productosSelect={productosSelect}
                    listarClientes={listarClientes}
                />
            </LoadMask>
        );
    }
}

export default FacturacionPos;
