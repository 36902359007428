import {
    Header,
    ListBox,
    ListBoxItem,
    Section,
    Text,
} from "react-aria-components";
import React from "react";
import styled from "styled-components";
import { useEffect } from "react";

const StyledListBox = styled(ListBox)`
    background-color: #212121;
    &[data-orientation="horizontal"],
    &[data-layout="grid"] {
        display: flex;
        width: fit-content;
        max-width: 100%;
        overflow-x: auto;
        padding: 0.5rem;
        border-radius: 0.5rem;
    }

    .my-item {
        display: flex;
        align-items: center;
        outline: none;
        position: relative;
        background-color: #212121;
        color: #fff;
        border: 2px solid #505050;
        border-radius: 0.5rem;
        padding: 0.5rem;
        margin: 0.5rem;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &[data-hovered] {
            border-color: #656565;
            background-color: hsl(0, 0%, 100%, 0.5);
            color: #212121;
        }

        &[data-selected] {
            border-color: #bd0034;
            backdrop-filter: blur(5px);
            background-color: #bd003644;
            color: #fff;
        }

        &[data-focused] {
            border-color: #bababa;
            box-shadow: 0 0 0 1px #bababa;
        }
    }
`;

const StyledListBoxItem = styled(ListBoxItem)``;

export function ICListBox({ children, ...props }) {
    return (
        <StyledListBox {...props} className="my-listbox">
            {children}
        </StyledListBox>
    );
}

export function ICItem(props) {
    return (
        <ListBoxItem
            {...props}
            className={({ isFocusVisible, isSelected }) =>
                `my-item ${isFocusVisible ? "focused" : ""} ${
                    isSelected ? "selected" : ""
                }`
            }
        />
    );
}
