import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import NavbarModulo from '../../components/NavbarModulo';
import {
    AsyncSelectField,
    renderDayPickerB
} from "../../Utils/renderField/renderField";

function FechaFinalCosechaForm(props) {
    const { handleSubmit, modulo, listarSiembra } = props;
    const editar = window.location.href.includes('editar');
    let titulo = editar ? 'Modificar fecha finl cosecha' : 'Fecha final cosecha';
    let disabled = false;
    
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    
    return (
        <div>
            <NavbarModulo
                modulo={modulo}
                titulo={titulo}
            />
            <br />
            <div className="mb-4 card card-small text-white bg-dark mb-3">
                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                    <div className="d-flex flex-column flex-1 mx-4">
                        <div className='mb-2'>
                            <div className='row mb-2'>
                                <div className='col-6'>
                                    <Field
                                        name="siembra"
                                        placeholder="Siembra..."
                                        className="form-control-find"
                                        loadOptions={listarSiembra}
                                        component={AsyncSelectField}
                                        msj="Siembra"
                                    />
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="fecha_cosecha"
                                        component={renderDayPickerB}
                                        msj="Fecha final cosecha"
                                    />
                                </div>
                            </div>
                            <div className='d-flex flex-row mt-3 ml-2'>
                                {disabled == false &&
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={handleSubmit}
                                    >
                                        {editar ? 'Modificar' : 'Guardar'}
                                    </button>
                                }
                                <a
                                    href={`/#/produccion/${id_emp[5]}`}
                                    className='btn btn-secondary mb-3'
                                >
                                    Regresar
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

FechaFinalCosechaForm.propTypes = {
    handleSubmit: PropTypes.func,
    crear: PropTypes.bool,
    modulo: PropTypes.object,
};

export default reduxForm({
    form: 'FechaFechaFinalCosechaForm',
})(FechaFinalCosechaForm);
