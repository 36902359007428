import { handleActions } from 'redux-actions';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';
const valEmp = __CONFIG__.empresa;

const LISTADO_FREC = 'LISTADO_FREC';
const LOADER = 'LOADER';
const MODULO = 'MODULO';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params;
    let id = ''
    if (valEmp === 'fage'){
        id = id_emp[6];
        params = { id };
    }else{
        id = id_emp[5];
        params = { id };
    }
    dispatch(setLoader(true));
    api.get('/frecuencia_pago', params).then((response)=>{
        dispatch({ type: LISTADO_FREC, data: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar frecuencia de pago',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const registroFrecuencia = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id_empresa = '';
    if (valEmp === 'fage'){
        id_empresa = id_emp[6]
    }else{
        id_empresa = id_emp[5]
    }
    const data = getStore().form.FormFrecuenciaPago.values;
    if (data === undefined) {
        Swal.fire({
            title: 'Frecuencia de pago',
            text: 'Debe de seleccionar frecuencia de pago',
            type: 'error',
        })
    } else {
        const formData = {
            frecuencia_pago: data.frecuencia_pago,
            empresa: id_empresa
        }
        dispatch(setLoader(true));
        api.post('/frecuencia_pago/', formData).then(() => {
            NotificationManager.success(
                'Frecuencia de pago creado',
                'Exito',
                3000
            );
            dispatch(listar());
        }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                2000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        if (error.status === 401){
            NotificationManager.error(
              error.body.msj,
              'Error',
              0
            );
        }
        if (error.status === 400){
            NotificationManager.error(
              'Ocurrió un error en buscar modulo',
              'Error',
              0
            );
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const eliminarFrecuencia = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`/frecuencia_pago/${id}`).then(() => {
        NotificationManager.success(
            'Frecuencia de pago eliminado correctamente',
            'Exito',
            3000
        );
        dispatch(listar());
    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const actions = {
    listar,
    getModulo,
    registroFrecuencia,
    eliminarFrecuencia
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_FREC]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
};

export const initialState = {
    loader: false,
    modulo: [],
    data: [],
};

export default handleActions(reducers, initialState)