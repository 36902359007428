import React, { Component } from 'react';
import Formulario from './SeleccionarFormPeriodo';


class SeleccionarPeriodo extends Component{

    render(){
        
        return(
           
            <Formulario/>
           
        );
    } 
}

export default SeleccionarPeriodo;