import React, { useState, useMemo, useEffect } from "react";
import {
    Button,
    CalendarCell,
    CalendarGrid,
    CalendarGridBody,
    CalendarGridHeader,
    CalendarHeaderCell,
    DateInput,
    DateRangePicker,
    DateSegment,
    Dialog,
    FieldError,
    Group,
    Heading,
    Label,
    Popover,
    RangeCalendar,
    Text,
} from "react-aria-components";
import { I18nProvider } from "@react-aria/i18n";
import { parseDate, today, getLocalTimeZone } from "@internationalized/date";
import { useController } from "react-hook-form";
import "./FicDateRangePicker.css";

function FicDateRangePicker({
    label,
    description,
    errorMsj,
    control,
    name,
    ...props
}) {
    const { field, fieldState } = useController({ control, name });
    const { onChange: fieldOnChange, value, ...rest } = field;
    const errorMessage = fieldState.error ? fieldState.error.message : "";
    const [componentValue, setComponentValue] = useState(null);

    let processedValue = useMemo(() => {
        let parsedValue;
        try {
            parsedValue = value
                ? { start: parseDate(value.start), end: parseDate(value.end) }
                : null;
        } catch (error) {
            parsedValue = null;
        }
        return parsedValue;
    }, [value]);

    useEffect(() => {
        setComponentValue(processedValue);
    }, [processedValue]);

    return (
        <DateRangePicker
            {...props}
            {...rest}
            value={componentValue}
            isInvalid={fieldState.invalid}
            onChange={(e) => {
                const date = e
                    ? { start: e.start.toString(), end: e.end.toString() }
                    : {};
                props.onChange ? props.onChange(e) : "";
                fieldOnChange(date);
            }}
            maxValue={today(getLocalTimeZone())}
        >
            <Label>{label}</Label>
            <Group>
                <DateInput slot="start">
                    {(segment) => <DateSegment segment={segment} />}
                </DateInput>
                <span aria-hidden="true">–</span>
                <DateInput slot="end">
                    {(segment) => <DateSegment segment={segment} />}
                </DateInput>
                <Button className="date-range-picker-btn">▼</Button>
            </Group>
            {description && <Text slot="description">{description}</Text>}
            <FieldError>{errorMessage}</FieldError>
            <Popover>
                <Dialog>
                    <RangeCalendar
                        visibleDuration={{ months: 2 }}
                        pageBehavior="single"
                    >
                        <header>
                            <Heading />
                            <Button slot="previous">&#8249;</Button>
                            <Button slot="next">&#8250;</Button>
                        </header>
                        <div
                            style={{
                                display: "flex",
                                gap: 30,
                                overflow: "auto",
                            }}
                        >
                            <CalendarGrid>
                                {(date) => <CalendarCell date={date} />}
                            </CalendarGrid>
                            <CalendarGrid offset={{ months: 1 }}>
                                {(date) => <CalendarCell date={date} />}
                            </CalendarGrid>
                        </div>
                    </RangeCalendar>
                </Dialog>
            </Popover>
        </DateRangePicker>
    );
}

export default function FicDatePickeri18n(props) {
    const browserLanguage = window.navigator.language;
    return (
        <I18nProvider locale={browserLanguage}>
            <FicDateRangePicker {...props} />
        </I18nProvider>
    );
}

// export default FicDateRangePicker;
