import { handleActions } from "redux-actions";
import { api } from "api";
import { NotificationManager } from "react-notifications";

export const guardarContribuyente = (data) => (dispatch) => {
    // data.empresa = localStorage.getItem("fnzEmpresaId");
    return api
        .post("finanzas/libro_compras/contribuyentes", data)
        .then((response) => {
            NotificationManager.success(
                response.msj,
                "Exito",
                6000,
                null,
                true
            );
        })
        .catch((error) => {
            NotificationManager.error(error.msj, "Error", 2000, null, true);
        });
};

export const listarContribuyentes = () => (dispatch) => {
    const params = { empresa: localStorage.getItem("fnzEmpresaId") };
    return api
        .get("/finanzas/libro_compras/contribuyentes", params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            NotificationManager.error(
                "Ocurrió un error al listar las empresas",
                "Error",
                2000
            );
            return [];
        });
};

const subirArchivoContribuyentes = (data) => (dispatch) => {
    data.empresa = localStorage.getItem("fnzEmpresaId");
    return api
        .postAttachments(
            "finanzas/libro_compras/contribuyentes/subir_excel",
            { ...data, contribuyente_excel: null },
            [{ name: "contribuyentes", file: data.contribuyente_excel }]
        )
        .then((response) => {
            NotificationManager.success(
                "Archivo de contribuyentes guardado",
                "Exito",
                3000,
                null,
                true
            );
            return response;
        })
        .catch((error) => {
            if (!error) error = {};

            NotificationManager.error(
                error.msj
                    ? error.msj
                    : "Ocurrio un error al guardar los contribuyentes",
                "Error",
                2000,
                null,
                true
            );
            return false;
        });
};

export const actions = {
    guardarContribuyente,
    listarContribuyentes,
    subirArchivoContribuyentes,
};

export const reducers = {};

export const initialState = {};

export default handleActions(reducers, initialState);
