import React from 'react';
import { Helmet } from 'react-helmet';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import Toolbar from '@material-ui/core/Toolbar';
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
import { NavLink } from 'react-router-dom';
import NavbarModuloFage from '../components/NavbarModuloFage';
import NavbarModulo from '../components/NavbarModulo';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form/immutable';
import BuildIcon from '@material-ui/icons/Build';;
const valEmp = __CONFIG__.empresa;

const LinkBtn = React.forwardRef(function LinkBtn(props, ref) { // eslint-disable-line
  return <NavLink to={props.to} {...props} innerRef={ref} />; // eslint-disable-line
});

function AjustesHome(props) {
  const {
    modulo,
    total
  } = props;

  const description = 'Ajustes';
  let ruta = window.location.href;
  let id_emp = ruta.split('/');

  return (
    <div>
      {valEmp === 'fage' &&
        <NavbarModuloFage
          modulo={modulo}
          titulo={''}
        />
      }
      {valEmp === '' &&
        <NavbarModulo
          modulo={modulo}
          titulo={''}
        />
      }
      <Helmet>
        <title>{description}</title>
      </Helmet>
      <Toolbar >
        <div style={{ color: "white" }}>
          <Typography variant="h3">
            {description}
          </Typography>
        </div>
      </Toolbar>
      <div style={{ marginTop: "3%", marginBottom: "2%", marginLeft: "3%" }}>
        <Grid container spacing={3} >
          <Grid item xs={12} sm={6}>
            <div style={{ marginTop: "0%", marginBottom: "4%", marginLeft: "3%", color: "white" }}>
              {total &&
                <Typography variant="h6">
                  <Icon>business</Icon> Empresa: {total.totEmpresas}
                </Typography>
              }
            </div>
            {valEmp === 'fage' &&
              <Button size="small" color="primary" startIcon={<BuildIcon />} variant="contained"
                component={LinkBtn} to={`/lfg/ajustes/${id_emp[6]}/empresas`} style={{ backgroundColor: '#12824C', color: '#FFFFFF' }}>
                Administrar Empresa
              </Button>
            }
            {valEmp === '' &&
              <Button size="small" color="primary" startIcon={<BuildIcon />} variant="contained"
                component={LinkBtn} to={`/ajustes/${id_emp[5]}/empresas`} style={{ backgroundColor: '#12824C', color: '#FFFFFF' }}>
                Administrar Empresa
              </Button>
            }
          </Grid>
          <Grid item xs={12} sm={6}>
            <div style={{ marginTop: "0%", marginBottom: "4%", marginLeft: "3%", color: "white" }}>
              {total &&
                <Typography variant="h6">
                  <Icon >business</Icon> Establecimientos: {total.totSucursal}
                </Typography>
              }
            </div>
            {valEmp === 'fage' &&
              <Button size="small" color="primary" startIcon={<BuildIcon />} variant="contained"
                component={LinkBtn} to={`/lfg/ajustes/${id_emp[6]}/establecimientos`} style={{ backgroundColor: '#12824C', color: '#FFFFFF' }}>
                Administrar Establecimientos
              </Button>
            }
            {valEmp === '' &&
              <Button size="small" color="primary" startIcon={<BuildIcon />} variant="contained"
                component={LinkBtn} to={`/ajustes/${id_emp[5]}/establecimientos`} style={{ backgroundColor: '#12824C', color: '#FFFFFF' }}>
                Administrar Establecimientos
              </Button>
            }
          </Grid>
        </Grid>
      </div>
      <div style={{ marginTop: "3%", marginBottom: "1%", marginLeft: "3%" }}>
        <Grid container spacing={3} >
          <Grid item xs={12} sm={6}>
            <div style={{ marginTop: "0%", marginBottom: "4%", marginLeft: "3%", color: "white" }}>
              {total &&
                <Typography variant="h6">
                  <Icon >account_circle</Icon> Usuarios: {total.totUsuarios}
                </Typography>
              }
            </div>
            {valEmp === 'fage' &&
              <Button size="small" color="primary" startIcon={<BuildIcon />} variant="contained"
                component={Link} to={`/lfg/ajustes/${id_emp[6]}/usuarios`} style={{ backgroundColor: '#12824C', color: '#FFFFFF' }}>
                Administrar Usuarios
              </Button>
            }
            {valEmp === '' &&
              <Button size="small" color="primary" startIcon={<BuildIcon />} variant="contained"
                component={Link} to={`/ajustes/${id_emp[5]}/usuarios`} style={{ backgroundColor: '#12824C', color: '#FFFFFF' }}>
                Administrar Usuarios
              </Button>
            }
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

AjustesHome.propTypes = {
  modulo: PropTypes.object,
  total: PropTypes.object,
};

export default reduxForm({
  form: 'FormAjustesHome',

})(AjustesHome);
