import React, { Component } from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { NavLink } from "react-router-dom";
import { withRouter } from "react-router-dom";
import "./notFound.css";
// const LinkBtn = React.forwardRef(function LinkBtn(props, ref) {
//     // eslint-disable-line
//     return <NavLink to={props.to} {...props} innerRef={ref} />; // eslint-disable-line
// });

class NotFound extends Component {
    constructor(props) {
        super(props);
    }

    handleGoBack = () => {
        const { history } = this.props;
        if (history.length > 1) {
            history.goBack();
        } else {
            history.push("/");
        }
    };

    render() {
        return (
            <div className="d-flex flex-column align-items-center pt-3">
                <div className="error-template">
                    <img
                        className="mt-1 image"
                        src={require("assets/img/error404.png")}
                        alt="Error"
                    />
                    <h2 className="title mb-4">Pagina no encontrada</h2>
                    <Button
                        size="small"
                        color="primary"
                        // component={LinkBtn}
                        onClick={this.handleGoBack}
                        style={{ backgroundColor: "#000000", color: "#FFFFFF" }}
                    >
                        Regresar
                    </Button>
                </div>
            </div>
        );
    }
}

export default withRouter(NotFound);
