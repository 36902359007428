import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from '../components/Modal';
import PropTypes from 'prop-types';

function FormSeleccionarPeriodo(props) {
    const {
        periodoL,
    } = props;

   
    const [id, setId] = useState("");
    
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    
    const handleChange = e => {
        setId(e.target.value);
    }
    
    const [estadoModal, cambiarEstadoModal] = useState(true);
    
    return (
        <div className='container'>
            <Modal
                estado={estadoModal}
                cambiarEstado={cambiarEstadoModal}
                titulo="Seleccionar periodo"
                botonCerrar="false"
            >
                {periodoL &&
                    <div className="form-floating">
                        <select className="form-select" id="periodo"
                            value={id} onChange={handleChange} >
                            <option selected>Seleccionar...</option>
                            {periodoL.map(mes =>
                                <option key={mes.value} value={mes.value}>{mes.label}</option>)
                            }

                        </select>
                        <label className='form-label' htmlfor="periodo">Periodo</label>
                    </div>
                }
                <br />
                { id_emp[4] === 'compras' &&
                    <Contenido>
                        {periodoL.length > 0 &&
                            (id &&
                            <a
                                href={`/#/compras/${id_emp[5]}/tipo_cambio/${id}`}
                                className='btn mr-2 mb-3 btn-primary'
                            >
                                Seleccionar
                            </a>
                            )
                        }
                    </Contenido>
                }    
                { id_emp[4] === 'facturacion' &&
                    <Contenido>
                        {periodoL.length > 0 &&
                            (id &&
                            <a
                                href={`/#/facturacion/${id_emp[5]}/tipo_cambio/${id}`}
                                className='btn mr-2 mb-3 btn-primary'
                            >
                                Seleccionar
                            </a>
                            )
                        }
                    </Contenido>
                }
            </Modal>
        </div>
    );

}
FormSeleccionarPeriodo.propTypes = {
    periodoL: PropTypes.string.isRequired,
};

export default (FormSeleccionarPeriodo);

const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;