import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import DetalleFactura from './DetalleFactura';
import DetalleEnvio from './DetalleEnvio';
import NavbarModulo from '../../components/NavbarModulo';


import {
    renderField,
    renderDayPickerB,
    AsyncSelectField,
    renderNumber,
    SelectField,
    renderCurrencyFloat
} from "../../Utils/renderField/renderField";
import propTypes from 'prop-types';

function FacturaForm(props) {
    const {
        crear,
        cli,
        lectura,
        periodoD,
        modulo,
        listarEnvios,
        registroFactura,
        detalleEnvio,
        detalleProducto,
        detalleEnv,
        editarLinea,
        estable,
        tipos
    } = props;

    let autoFocus = true;
    
   
    const estilo = {
        overflow: "auto",
        height: "200px",
    };
    let ruta = window.location.href;
    let id_empresa = ruta.split('/');
    
    const editar = window.location.href.includes('editar');
    let titulo = editar ? 'Modificar factura' : 'Crear factura';
    let disabled = false;
    if (crear === false && editar === false) {
        disabled = true;
        titulo = 'Ver factura'
    }

    const [editarD, setEditarD] = useState(editar);
    const [editarT, setEditarT] = useState(editar);
    const [tipoD, setTipoD] = useState(0);
    const [iva, setIva] = useState(editarD === true ? lectura.afecta_iva : crear === true ? true : iva);

    const handleOnChange = value => {
        detalleEnvio(value.value);
    };
    const handleChange = (e) => {
        let existe = tipos.find(element => element.value === e.target.value);
        setEditarT(false);
        setTipoD(e.target.value);
        if (existe.search === 'IVA') {
            setIva(true);
        }
        if (existe.search === 'SIVA') {
            setIva(false);
        }

    }
    return (
        <div>
            <NavbarModulo
                periodoD={periodoD}
                modulo={modulo}
                titulo={titulo}
            />
            <br />
            <div className="mb-4 card card-small text-white bg-dark mb-3">
                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                    <div className="d-flex flex-column flex-1 mx-4">
                        {crear === false && editar == false &&
                            <div className='row mb-2'>
                                <div className='col-3'>
                                    <Field
                                        name="codigo"
                                        placeholder="Codigo establecimiento"
                                        className="form-control"
                                        component={renderField}
                                        disabled={true}
                                        msj="Código establecimiento"
                                    />
                                </div>
                                <div className='col-6'>
                                    <Field
                                        name="establecimiento"
                                        placeholder="Nombre establecimiento"
                                        className="form-control"
                                        component={renderField}
                                        disabled={true}
                                        msj="Nombre establecimiento"
                                    />
                                </div>
                                <div className='col-3'>
                                    {tipos &&
                                        <div className="form-floating">
                                            <Field
                                                name="tipo_documento"
                                                options={tipos}
                                                crear={crear}
                                                dato={crear === true ? 0 : lectura.tipo_documento}
                                                component={SelectField}
                                                className="form-select"
                                                disabled={disabled}
                                                msj="Tipo de documento"
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                        {(crear === false && editar === false) &&
                            <div className='row mb-2'>
                                <div className='col-3'>
                                    <Field
                                        autoFocus={autoFocus}
                                        disabled={disabled}
                                        name="fechaRegistro"
                                        component={renderDayPickerB}
                                        msj="Fecha Registro"
                                    />
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="serie"
                                        placeholder="Nombre"
                                        className="form-control"
                                        component={renderField}
                                        disabled={disabled}
                                        msj="Serie"
                                    />
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="numero"
                                        placeholder="Dias credito"
                                        className="form-control"
                                        component={renderNumber}
                                        disabled={disabled}
                                        msj="Numero"
                                    />
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="envio"
                                        placeholder="Envío"
                                        className="form-control"
                                        component={renderField}
                                        disabled={disabled}
                                        msj="Envío"
                                    />
                                </div>
                            </div>
                        }
                        {crear === true &&
                            <div className='row mb-2'>
                                <div className='col-3'>
                                    <div className="form-floating">
                                        <input
                                            placeholder="Codigo establecimiento"
                                            type="text"
                                            autocomplete="off"
                                            name="codigo"
                                            className="form-control"
                                            value={estable.codigo}
                                            disabled={true}
                                        />
                                        <label className='form-label' htmlFor="codigo">
                                            Código establecimiento
                                        </label>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className="form-floating">
                                        <input
                                            placeholder="Nombre establecimiento"
                                            type="text"
                                            autocomplete="off"
                                            name="nombre"
                                            className="form-control"
                                            value={estable.nombre_comercial}
                                            disabled={true}
                                        />
                                        <label className='form-label' htmlFor="nombre">
                                            Nombre establecimiento
                                        </label>
                                    </div>
                                </div>
                               
                                    <div className='col-3'>
                                        <div className="form-floating">
                                            <select className="form-select" id="tipo" name="tipo"
                                                value={editarT === true ? lectura.tipo_documento : tipoD} onChange={handleChange} >
                                                <option selected>Seleccionar...</option>
                                                {tipos.map(
                                                    tip => <option key={tip.value} value={tip.value}
                                                    >{tip.label}
                                                    </option>)
                                                }
                                            </select>
                                            <label className='form-label' htmlFor="mes">Tipo de documento</label>
                                        </div>
                                    </div>
                                
                            </div>
                        }
                        {crear === true &&
                            <div className='row mb-2'>
                                <div className='col-3'>
                                    <Field
                                        autoFocus={autoFocus}
                                        disabled={disabled}
                                        name="fechaRegistro"
                                        component={renderDayPickerB}
                                        msj="Fecha Registro"
                                    />
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="serie"
                                        placeholder="Nombre"
                                        className="form-control"
                                        component={renderField}
                                        disabled={disabled}
                                        msj="Serie"
                                    />
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="numero"
                                        placeholder="Dias credito"
                                        className="form-control"
                                        component={renderNumber}
                                        disabled={disabled}
                                        msj="Numero"
                                    />
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="envio"
                                        placeholder="Envio de producción..."
                                        className="form-control-find"
                                        loadOptions={listarEnvios}
                                        component={AsyncSelectField}
                                        onChange={handleOnChange}
                                        msj="Envio de producción"
                                    />
                                </div>
                                
                            </div>
                        }
                        {(editar === true) &&
                            <div className='row mb-2'>
                                <div className='col-3'>
                                    <Field
                                        autoFocus={autoFocus}
                                        disabled={disabled}
                                        name="fechaRegistro"
                                        component={renderDayPickerB}
                                        msj="Fecha Registro"
                                    />
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="serie"
                                        placeholder="Nombre"
                                        className="form-control"
                                        component={renderField}
                                        disabled={true}
                                        msj="Serie"
                                    />
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="numero"
                                        placeholder="Dias credito"
                                        className="form-control"
                                        component={renderNumber}
                                        disabled={true}
                                        msj="Numero"
                                    />
                                </div>
                            </div>
                        }

                        {(crear === true) &&
                        <div>
                            <div className='row mb-2'>
                                <div className='col-12'>
                                    <div className="form-floating">
                                        <input
                                            placeholder="Cliente"
                                            type="text"
                                            autocomplete="off"
                                            name="cliente"
                                            className="form-control"
                                            value={detalleEnv.length === 0 ? '' : cli.cliente}
                                            disabled={true}
                                        />
                                        <label className='form-label' htmlFor="cuenta">
                                            Cliente
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-12'>
                                    <Field
                                        name="observaciones"
                                        disabled={disabled}
                                        placeholder="Observaciones"
                                        className="form-control"
                                        component={renderField}
                                        msj="Observaciones"
                                    />
                                </div>
                            </div>
                        </div>
                        }
                        {(crear === false && editar === false) &&
                            <div>
                                <div className='row mb-2'>
                                    <div className='col-12'>
                                        <Field
                                            name="clienteN"
                                            disabled={disabled}
                                            placeholder="Cliente"
                                            className="form-control"
                                            component={renderField}
                                            msj="Cliente"
                                        />
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <div className='col-12'>
                                        <Field
                                            name="observaciones"
                                            disabled={disabled}
                                            placeholder="Observaciones"
                                            className="form-control"
                                            component={renderField}
                                            msj="Observaciones"
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {(editar === true) &&
                            <div>
                                <div className='row mb-2'>
                                    <div className='col-12'>
                                        <Field
                                            name="clienteN"
                                            disabled={true}
                                            placeholder="Cliente"
                                            className="form-control"
                                            component={renderField}
                                            msj="Cliente"
                                        />
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <div className='col-12'>
                                        <Field
                                            name="observaciones"
                                            disabled={disabled}
                                            placeholder="Observaciones"
                                            className="form-control"
                                            component={renderField}
                                            msj="Observaciones"
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        
                        {(crear === true) &&
                            <div>
                                <h5 className="p-2 bg-secondary title mt-1">Detalle factura</h5>
                                <br/>
                                <div style={estilo}>
                                    <DetalleEnvio
                                        detalle={detalleEnv}
                                        editarLinea={editarLinea}
                                    />
                                </div>
                            </div>
                        }

                        {(editar === true) &&
                            <div>
                                <h5 className="p-2 bg-secondary title mt-1">Detalle factura</h5>
                                <br/>
                                <div style={estilo}>
                                    <DetalleEnvio
                                        detalle={detalleEnv}
                                    />
                                </div>
                            </div>
                        }
                        {(crear === false && editar === false) &&
                            <div>
                                <h5 className="p-2 bg-secondary title mt-1">Detalle factura</h5>
                                <br/>
                                <div style={estilo}>
                                    <DetalleFactura
                                        detalle={detalleProducto}
                                    />
                                </div>
                            </div>
                        }
                        <br/>
                        {(crear === false && editar === false) &&
                            <div>
                                <div className='row mb-2'>
                                    <div className='col-3'>

                                    </div>
                                    <div className='col-2'>
                                        <Field
                                            name="base"
                                            disabled={disabled}
                                            placeholder="Base"
                                            className="form-control"
                                            component={renderCurrencyFloat}
                                            msj="Base"
                                        />
                                    </div>
                                    <div className='col-2'>
                                        <Field
                                            name="iva"
                                            disabled={disabled}
                                            placeholder="Iva"
                                            className="form-control"
                                            component={renderCurrencyFloat}
                                            msj="Iva"
                                        />
                                    </div>
                                    <div className='col-2'>
                                        <Field
                                            name="total"
                                            disabled={disabled}
                                            placeholder="Total"
                                            className="form-control"
                                            component={renderCurrencyFloat}
                                            msj="Total"
                                        />
                                    </div>
                                    <div className='col-3'>
                                        
                                    </div>
                                </div>
                            </div>
                        }
                        <br/>
                        {(crear === true || editar === true) &&
                            <div className='mb-2'>
                                <button
                                    className='btn mr-2 mb-2 btn-primary'
                                    onClick={() => registroFactura(periodoD, cli.idCliente, detalleEnv, tipoD, iva)}
                                >
                                {editar ? 'Modificar' : 'Guardar factura'}
                                </button>
                                <a
                                    href={`/#/produccion/${id_empresa[5]}/factura/${id_empresa[7]}`}
                                    className='btn btn-secondary mb-2'
                                >
                                Regresar
                                </a>
                            </div>
                        }
                        {(crear === false && editar === false) &&
                            <div className='mb-2'>
                                <a
                                    href={`/#/produccion/${id_empresa[5]}/factura/${id_empresa[7]}`}
                                    className='btn btn-secondary mb-2'
                                >
                                    Regresar
                                </a>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

FacturaForm.propTypes = {
    crear: PropTypes.bool,
    editar: PropTypes.bool,
    bloqueo: PropTypes.bool,
    fechaInicio: PropTypes.string,
    fechaFinal: PropTypes.string,
    datos: PropTypes.object,
    detallePro: PropTypes.object,
    periodoD: PropTypes.object,
    modulo: PropTypes.object,
    detalleIngreso: PropTypes.func,
    detalleProducto: PropTypes.object,
    cli: propTypes.object,
    numeroSerie: propTypes.func,
    detalleEnv: PropTypes.object
};


export default reduxForm({
    form: 'FormFactura',
})(FacturaForm);
