import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import Grid from '../../Utils/GridVDocumento';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import styled from 'styled-components';
import TablaDetalleMod from './NDDetalleMod';
import TablaDetalle from './NDDetalle';
import TablaVerDetalle from './NDVerDetalle';
import {
    renderDayPickerB,
    renderField,
    renderNumber,
    SelectField,
    AsyncSelectField,
    renderCurrencyFloat,
    renderCurrencyFloatTC,
    AsyncSelectFieldIn

} from "../../Utils/renderField/renderField";

function NDForm(props) {
    const { crear, lectura, editar, detalleND, nuevaLineaDocumento,
        leerDocumentos, doc, listarProveedores, cuentaBan, list_doc,
        loader, bloqueo, eliminarDocumento, tipoCambio, detalleDocN,
        fechaFinal, agregarLinea, periodoD, eliminarLineaAbonoNuevo,
        idMoneda, cambio, proveedorD } = props;

    let autoFocus = true;
    const [nombre, setNombre] = useState('');

    const tipos = [
        {
            value: 'AFECTA A PROVEEDORES',
            label: 'AFECTA A PROVEEDORES'
        },
        {
            value: 'NO AFECTA A PROVEEDORES',
            label: 'NO AFECTA A PROVEEDORES'
        },
        {
            value: 'ANTICIPO A PROVEEDORES',
            label: 'ANTICIPO A PROVEEDORES'
        },
    ];

    const [tipo, setTipo] = useState(false);
    const [pagoD, setPagoD] = useState(false);
    const [editarT, setEditarT] = useState(true);
    const [proveedor, setProveedor] = useState('');
    
    const [afecta, setAfecta] = useState(false);
    const [tipoCambioD, setTipoCambioD] = useState(1.000000);
    const [tipoCambioED, setTipoCambioED] = useState(1.000000);
    
    let concepto = localStorage.getItem('conceptoND');

    const handleChange = (e) => {
        if (editar === true ){
            setEditarT(false)
            setTipo(e.target.value);
            if (e.target.value === 'AFECTA A PROVEEDORES') {
                setPagoD(true);
                setAfecta(true);
                localStorage.setItem('conceptoND', 'AFECTA A PROVEEDORES');
            }
            if (e.target.value === 'NO AFECTA A PROVEEDORES') {
                setPagoD(false);
                setAfecta(true);
                localStorage.setItem('conceptoND', 'NO AFECTA A PROVEEDORES');
            }
            if (e.target.value === 'ANTICIPO A PROVEEDORES') {
                setPagoD(false);
                setAfecta(true);
                localStorage.setItem('conceptoND', 'ANTICIPO A PROVEEDORES');
            }
        }else{
            setTipo(e.target.value);
            if (e.target.value === 'AFECTA A PROVEEDORES') {
                setPagoD(true);
                setAfecta(true);
                localStorage.setItem('conceptoND', 'AFECTA A PROVEEDORES');
            }
            if (e.target.value === 'NO AFECTA A PROVEEDORES') {
                setPagoD(false);
                setAfecta(true);
                localStorage.setItem('conceptoND', 'NO AFECTA A PROVEEDORES');
            }
            if (e.target.value === 'ANTICIPO A PROVEEDORES') {
                setPagoD(false);
                setAfecta(true);
                localStorage.setItem('conceptoND', 'ANTICIPO A PROVEEDORES');
            }
        }
    }

    const estilo = {
        overflow: "auto",
        height: "200px",

    };

    const handleOnChange = value => {
        if (editar === true ){
            setProveedor(value.value);
            if (localStorage.getItem('conceptoND') === 'AFECTA A PROVEEDORES' || localStorage.getItem('conceptoND') === 'ANTICIPO A PROVEEDORES') {
                if (detalleND.length > 0){
                    if (value.value === proveedorD){
                        let nombreCK = value.label.split('-');
                        setNombre(nombreCK[1].trim());
                        localStorage.setItem('ProveedorND', nombreCK[1].trim());
                    }else{
                        Swal.fire({
                            title: 'Cheques',
                            text: 'Si cambia de cliente debe de quitar los documentos agregados',
                            type: 'error',
                        })
                    }
                }else{
                    leerDocumentos(value.value, fechaFinal, cambio, idMoneda);
                    let nombreCK = value.label.split('-');
                    setNombre(nombreCK[1].trim());
                    localStorage.setItem('ProveedorND', nombreCK[1].trim());
                }
            }
        }else{
            if (localStorage.getItem('conceptoND') === 'AFECTA A PROVEEDORES' || localStorage.getItem('conceptoND') === 'ANTICIPO A PROVEEDORES'){
                leerDocumentos(value.value, fechaFinal, cuentaBan.cambio, cuentaBan.moneda);
            }
            let nombreCK = value.label.split('-');
            setNombre(nombreCK[1].trim());
            localStorage.setItem('ProveedorND', nombreCK[1].trim());
        }
    };
    
    return (
        <div>
            {crear === false && editar == false &&
                <div className='row mb-2'>
                    <div className='col-3'>
                        <Field
                            name="cuenta"
                            placeholder="Cuenta"
                            className="form-control"
                            component={renderField}
                            disabled={bloqueo}
                            msj="Cuenta"
                        />
                    </div>
                    <div className='col-3'>
                        <Field
                            name="banco"
                            placeholder="Banco"
                            className="form-control"
                            component={renderField}
                            disabled={bloqueo}
                            msj="Banco"
                        />
                    </div>
                    <div className='col-3'>
                        <Field
                            name="moneda"
                            placeholder="Moneda"
                            className="form-control"
                            component={renderField}
                            disabled={bloqueo}
                            msj="Moneda"
                        />
                    </div>
                    <div className='col-3'>
                        <Field
                            name="tipo"
                            placeholder="Tipo"
                            className="form-control"
                            component={renderField}
                            disabled={bloqueo}
                            msj="Tipo"
                        />
                    </div>
                </div>

            }
            {(crear === true || editar == true) &&
                <div className='row mb-2'>
                    <div className='col-3'>
                        <div className="form-floating">
                            <input
                                placeholder="Cuenta"
                                type="text"
                                autocomplete="off"
                                name="cuenta"
                                className="form-control"
                                value={editar ? lectura.cuenta : cuentaBan.numero_cuenta}
                                disabled={true}
                            />
                            <label className='form-label' htmlFor="cuenta">
                                Cuenta
                            </label>
                        </div>
                    </div>
                    <div className='col-3'>
                        <div className="form-floating">
                            <input
                                placeholder="Banco"
                                type="text"
                                autocomplete="off"
                                name="banco"
                                className="form-control"
                                value={editar ? lectura.banco : cuentaBan.bancoNombre}
                                disabled={true}
                            />
                            <label className='form-label' htmlFor="banco">
                                Banco
                            </label>
                        </div>
                    </div>
                    <div className='col-3'>
                        <div className="form-floating">
                            <input
                                placeholder="Moneda"
                                type="text"
                                autocomplete="off"
                                name="moneda"
                                className="form-control"
                                value={editar? lectura.moneda : cuentaBan.monedaN}
                                disabled={true}
                            />
                            <label className='form-label' htmlFor="moneda">
                                Moneda
                            </label>
                        </div>
                    </div>
                    <div className='col-3'>
                        <div className="form-floating">
                            <input
                                placeholder="Tipo"
                                type="text"
                                autocomplete="off"
                                name="tipoCB"
                                className="form-control"
                                value={editar ? lectura.tipo : cuentaBan.tipo}
                                disabled={true}
                            />
                            <label className='form-label' htmlFor="tipoCB">
                                Tipo
                            </label>
                        </div>
                    </div>
                </div>
            }
            <div className='row mb-2'>
                <div className='col-3'>
                    <Field
                        autoFocus={autoFocus}
                        disabled={bloqueo}
                        name="fechaRegistro"
                        component={renderDayPickerB}
                        msj="Fecha Registro"
                    />
                </div>
                {crear === false && editar == false &&
                    <div className='col-3'>
                        <Field
                            name="numero_cheque"
                            placeholder="Numero nota de débito"
                            className="form-control"
                            component={renderNumber}
                            disabled={bloqueo}
                            msj="Numero nota de débito"
                        />
                    </div>
                }

                {(crear === true || editar === true) &&
                    <div className='col-3'>
                        <Field
                            name="numero_cheque"
                            placeholder="Numero nota de débito"
                            className="form-control"
                            component={renderNumber}
                            disabled={bloqueo}
                            msj="Numero nota de débito"
                        />
                    </div>
                }
                <div className='col-3'>
                    <Field
                        name="total"
                        placeholder="Total"
                        className="form-control"
                        component={renderCurrencyFloat}
                        disabled={bloqueo}
                        msj="Total"
                    />
                </div>
                {(editar === true) && (
                    <div className='col-3'>
                    {lectura.cambio === 'S' &&
                        <Field
                            name="tipo_cambio"
                            placeholder="Tipo de cambio"
                            className="form-control"
                            component={renderCurrencyFloatTC}
                            disabled={bloqueo}
                            msj="Tipo de cambio"
                        />
                    }
                    </div>
                )}
                {crear === false && editar == false &&
                <div className='col-3'>
                    {lectura.cambio === 'S' &&
                        <Field
                            name="tipo_cambio"
                            placeholder="Tipo de cambio"
                            className="form-control"
                            component={renderCurrencyFloatTC}
                            disabled={bloqueo}
                            msj="Tipo de cambio"
                        />
                    }
                    </div>
            }
            </div>
            {(crear === true) && (
                <div>
                    {cuentaBan.cambio === 'S' &&
                        <div className='row mb-2'>
                            <div className='col-3'>
                                <div className="form-floating">
                                    <NumberFormat
                                        className={classNames('form-control')}
                                        decimalScale={6}
                                        fixedDecimalScale
                                        placeholder="Tipo de cambio"
                                        value={tipoCambioD}
                                        thousandSeparator
                                        onValueChange={(values) => {
                                            setTipoCambioD(values.value)
                                            localStorage.setItem('TC_ND', tipoCambioD)
                                        }}
                                    />
                                    <label className='form-label' htmlFor="cuenta">
                                        Tipo de cambio
                                    </label>
                                </div>
                            </div>
                            <div className='col-3 mt-2'>
                                <button
                                    className='btn mr-2 mb-3 btn-secondary'
                                    onClick={() => {
                                        let tc = tipoCambio(periodoD, cuentaBan.cambio);
                                        if (tc === undefined){
                                            setTipoCambioD('1.000000');
                                        }else{
                                            tc.then(d => { 
                                                if (d === undefined){
                                                    setTipoCambioD('1.000000');
                                                }else{
                                                    setTipoCambioD(d);
                                                    localStorage.setItem('TC_ND', d)
        
                                                }
                                            });
                                        }
                                    }}
                                >
                                    Buscar tipo de cambio
                                </button>
                            </div>
                        </div>
                    }
                </div>
            )}
            {(editar === true) && (
            <div>
                {lectura.cambio === 'S' &&
                    <div className='row mt-2 mb-2'>
                        <div className='col-3'>
                            <div className="form-floating">
                                <NumberFormat
                                    className={classNames('form-control')}
                                    decimalScale={6}
                                    fixedDecimalScale
                                    placeholder="Tipo de cambio"
                                    value={tipoCambioED}
                                    thousandSeparator
                                    onValueChange={(values) => {
                                        setTipoCambioED(values.value)
                                        localStorage.setItem('TC_ND', tipoCambioED)
                                    }}
                                />
                                <label className='form-label' htmlFor="cuenta">
                                    Tipo de cambio
                                </label>
                            </div>
                        </div>
                        <div className='col-3 mt-2'>
                            <button
                                className='btn mr-2 mb-3 btn-secondary'
                                onClick={() => {
                                    let tc = tipoCambio(periodoD, lectura.cambio);
                                    if (tc === undefined){
                                        setTipoCambioED('1.000000');
                                    }else{
                                        tc.then(d => { 
                                            if (d === undefined){
                                                setTipoCambioED('1.000000');
                                            }else{
                                                setTipoCambioED(d);
                                                localStorage.setItem('TC_ND', d)

                                            }
                                        });
                                    }
                                }}
                            >
                                Buscar tipo de cambio
                            </button>
                        </div>
                    </div>
                }
            </div>
            )}
            {crear === false && editar == false &&
                <div className='row mb-2'>
                    <div className='col-3'>
                        {tipos &&
                            <div className="form-floating">
                                <Field
                                    name="tipo"
                                    options={tipos}
                                    crear={crear}
                                    dato={crear === true ? 0 : lectura.concepto}
                                    component={SelectField}
                                    className="form-select"
                                    disabled={bloqueo}
                                    msj="Tipo de documento"
                                />
                            </div>
                        }
                    </div>
                    {(crear === false && editar == false) && ( concepto === 'AFECTA A PROVEEDORES' || concepto === 'ANTICIPO A PROVEEDORES' ) &&
                        <div className='w-75'>
                            <Field
                                name="proveedorNombre"
                                placeholder="Nombre Proveedor"
                                className="form-control"
                                component={renderField}
                                disabled={bloqueo}
                                msj="Nombre Proveedor"
                            />
                        </div>
                    }
                </div>
            }
            {(crear === true) &&
                <div className='row mb-2'>
                    <div className='col-3'>
                        <div className="form-floating">
                            <select className="form-select" id="tipo" name="tipo"
                                value={tipo} onChange={handleChange} >
                                <option selected>Seleccionar...</option>
                                {tipos.map(
                                    tip => <option key={tip.value} value={tip.value}
                                    >{tip.label}
                                    </option>)
                                }
                            </select>
                            <label className='form-label' htmlFor="mes">Tipo de documento</label>
                        </div>
                    </div>
                    <div className='w-75'>
                        <Field
                            name="proveedor_documento"
                            placeholder="Proveedor..."
                            className="form-control-find"
                            component={AsyncSelectField}
                            loadOptions={listarProveedores}
                            onChange={handleOnChange}
                            msj="Proveedor"
                        />
                    </div>
                </div>
            }
            {editar == true &&
                <div className='row mb-2'>
                    <div className='col-3'>
                        <div className="form-floating">
                            <select className="form-select" id="tipo" name="tipo"
                                value={editarT === true ? lectura.concepto : tipo} onChange={handleChange} >
                                <option selected>Seleccionar...</option>
                                {tipos.map(
                                    tip => <option key={tip.value} value={tip.value}
                                    >{tip.label}
                                    </option>)
                                }
                            </select>
                            <label className='form-label' htmlFor="mes">Tipo de documento</label>
                        </div>
                    </div>
                    {editar && ( concepto === 'AFECTA A PROVEEDORES' || concepto === 'ANTICIPO A PROVEEDORES' ) &&
                        <div className='row col-9'>
                            <div className='w-75'>
                                <Field
                                    name="proveedorNombre"
                                    placeholder="Codigo Proveedor"
                                    className="form-control"
                                    component={renderField}
                                    disabled={true}
                                    msj="Nombre Proveedor"
                                />
                            </div>
                            {proveedor === '' && detalleND.length === 0 &&
                                <div className='w-75'>
                                    <Field
                                        name="proveedor_documento"
                                        placeholder="Proveedor..."
                                        className="form-control-find"
                                        component={AsyncSelectField}
                                        loadOptions={listarProveedores}
                                        onChange={handleOnChange}
                                        msj="Proveedor"
                                    />
                                </div>
                            }
                            {proveedor  && detalleND.length === 0 &&
                                <div className='w-75'>
                                    <Field
                                        name="proveedor_documento"
                                        placeholder="Proveedor..."
                                        className="form-control-find"
                                        component={AsyncSelectField}
                                        loadOptions={listarProveedores}
                                        onChange={handleOnChange}
                                        msj="Proveedor"
                                    />
                                </div>
                            }
                        </div>
                    }
                </div>
            }
            {crear === false && editar == false && 
                <div className='mb-2'>
                    <Field
                        name="nombre"
                        placeholder="Nombre"
                        component={renderField}
                        className="form-control"
                        disabled={bloqueo}
                        msj="Nombre"
                    />
                </div>
            }
            {(crear === true) && afecta && 
                <div className='mb-2'>
                    <div className="form-floating">
                        <input
                            placeholder="Nombre"
                            type="text"
                            autocomplete="off"
                            name="nombre"
                            className="form-control"
                            value={editar && nombre === '' ? lectura.nombre : nombre }
                            onChange={e => { setNombre(e.target.value); localStorage.setItem('ProveedorND', e.target.value); }}
                            disabled={false}
                            />
                            <label className='form-label' htmlFor="proveedor">
                                Nombre
                            </label>
                        </div>
                    </div>
            }
            
            {(editar === true) && 
                <div className='mb-2'>
                    <div className="form-floating">
                        <input
                            placeholder="Nombre"
                            type="text"
                            autocomplete="off"
                            name="nombre"
                            className="form-control"
                            value={editar && nombre === '' ? lectura.nombre : nombre }
                            onChange={e => { setNombre(e.target.value); localStorage.setItem('ProveedorND', e.target.value); }}
                            disabled={false}
                        />
                        <label className='form-label' htmlFor="proveedor">
                            Nombre
                        </label>
                    </div>
                </div>
            }
            
            <div className='mb-2'>
                <Field
                    name="descripcion"
                    placeholder="Descripcion"
                    component={renderField}
                    className="form-control"
                    disabled={bloqueo}
                    msj="Referencia"
                />
            </div>
            {doc && editar && concepto === 'AFECTA A PROVEEDORES' &&
                <div>
                    <h5 className="p-2 bg-secondary title mt-1">Documentos con saldo</h5>
                    <div style={estilo}>
                        <Grid
                            hover
                            striped
                            data={doc}
                            loading={loader}
                            headerStyle={{ background: 'black' }}

                        >
                            <TableHeaderColumn
                                isKey
                                dataField="fecha"
                                dataSort
                                dataFormat={(cell, row) => {
                                    return moment(cell).format("DD/MM/YYYY");
                                }}
                                thStyle={
                                    { color: 'white' }}
                                tdStyle={
                                    { color: 'white' }}
                            >
                                Fecha
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="serie"
                                dataSort
                                thStyle={
                                    { color: 'white' }}
                                tdStyle={
                                    { color: 'white' }}
                            >
                                Serie
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="numero"
                                dataSort
                                thStyle={
                                    { color: 'white' }}
                                tdStyle={
                                    { color: 'white' }}
                            >
                                Numero
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="proveedorN"
                                width="350"
                                dataSort
                                thStyle={
                                    { color: 'white' }}
                                tdStyle={
                                    { color: 'white' }}
                            >
                                Proveedor
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="total"
                                headerAlign="center"
                                dataAlign='right'
                                dataFormat={(cell, row) => {
                                    if (parseFloat(cell) === 0.00) {
                                        return ''
                                    } else {
                                        return new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(cell);
                                    }
                                }}
                                dataSort
                                thStyle={
                                    { color: 'white' }}
                                tdStyle={
                                    { color: 'white' }}
                            >
                                Total
                            </TableHeaderColumn>
                        </Grid>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-8 mt-2'>
                            <Field
                                name="documento"
                                placeholder="Documento..."
                                className="form-control-find"
                                loadOptions={list_doc}
                                component={AsyncSelectFieldIn}
                                msj="Documento"
                            />
                        </div>
                        <div className='col-3 mt-2'>
                            <div className="form-floating">
                                <Field
                                    name="abono"
                                    placeholder="Abono"
                                    className="form-control"
                                    component={renderCurrencyFloat}
                                    disabled={false}
                                    msj="Abono"
                                />
                            </div>
                        </div>
                    </div>
                    <Contenido>
                        <button
                            className='btn mr-2 mb-3 btn-primary'
                            onClick={() => nuevaLineaDocumento(periodoD)}
                        >
                            Agregar documento
                        </button>
                        <div className='row mb-2'>
                            <div className='col-6'>
                                <div className="form-floating">
                                    <Field
                                        name="diferencia"
                                        placeholder="Diferencia"
                                        className="form-control"
                                        component={renderCurrencyFloat}
                                        disabled={true}
                                        msj="Diferencia"
                                    />
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className="form-floating">
                                    <Field
                                        name="totalAbono"
                                        placeholder="Total Abono"
                                        className="form-control"
                                        component={renderCurrencyFloat}
                                        disabled={true}
                                        msj="Total Abono"
                                    />
                                </div>
                            </div>
                        </div>
                    </Contenido>

                </div>
            }
            {pagoD && editar === false &&
                <div>
                    <h5 className="p-2 bg-secondary title mt-1">Documentos con saldo</h5>
                    <div style={estilo}>
                        <Grid
                            hover
                            striped
                            data={doc}
                            loading={loader}
                            headerStyle={{ background: 'black' }}

                        >
                            <TableHeaderColumn
                                isKey
                                dataField="fecha"
                                dataSort
                                dataFormat={(cell, row) => {
                                    return moment(cell).format("DD/MM/YYYY");
                                }}
                                thStyle={
                                    { color: 'white' }}
                                tdStyle={
                                    { color: 'white' }}
                            >
                                Fecha
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="serie"
                                dataSort
                                thStyle={
                                    { color: 'white' }}
                                tdStyle={
                                    { color: 'white' }}
                            >
                                Serie
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="numero"
                                dataSort
                                thStyle={
                                    { color: 'white' }}
                                tdStyle={
                                    { color: 'white' }}
                            >
                                Numero
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="proveedorN"
                                width="350"
                                dataSort
                                thStyle={
                                    { color: 'white' }}
                                tdStyle={
                                    { color: 'white' }}
                            >
                                Proveedor
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="total"
                                headerAlign="center"
                                dataAlign='right'
                                dataFormat={(cell, row) => {
                                    if (parseFloat(cell) === 0.00) {
                                        return ''
                                    } else {
                                        return new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(cell);
                                    }
                                }}
                                dataSort
                                thStyle={
                                    { color: 'white' }}
                                tdStyle={
                                    { color: 'white' }}
                            >
                                Total
                            </TableHeaderColumn>
                        </Grid>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-8 mt-2'>
                            <Field
                                name="documento"
                                placeholder="Documento..."
                                className="form-control-find"
                                loadOptions={list_doc}
                                component={AsyncSelectFieldIn}
                                msj="Documento"
                            />
                        </div>
                        <div className='col-3 mt-2'>
                            <div className="form-floating">
                                <Field
                                    name="abono"
                                    placeholder="Abono"
                                    className="form-control"
                                    component={renderCurrencyFloat}
                                    disabled={false}
                                    msj="Abono"
                                />
                            </div>
                        </div>
                    </div>
                    {(crear === true) &&
                        <Contenido>
                            <button
                                className='btn mr-2 mb-3 btn-primary'
                                onClick={() => agregarLinea(periodoD)}
                            >
                                Agregar documento
                            </button>
                            <div className='row mb-2'>
                                <div className='col-6'>
                                    <div className="form-floating">
                                        <Field
                                            name="diferencia"
                                            placeholder="Diferencia"
                                            className="form-control"
                                            component={renderCurrencyFloat}
                                            disabled={true}
                                            msj="Diferencia"
                                        />
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className="form-floating">
                                        <Field
                                            name="totalAbono"
                                            placeholder="Total Abono"
                                            className="form-control"
                                            component={renderCurrencyFloat}
                                            disabled={true}
                                            msj="Total Abono"
                                        />
                                    </div>
                                </div>
                            </div>
                        </Contenido>
                    }
                    {(crear === true) &&
                        <div>
                            <h5 className="p-2 bg-secondary title mt-1">Documentos agregados</h5>
                            <div style={estilo}>
                                <TablaDetalle
                                    detalle={detalleDocN}
                                    eliminarDocumento={eliminarLineaAbonoNuevo}
                                />
                            </div>
                        </div>
                    }
                </div>
            }
            {crear === false && editar === false &&
                (lectura.concepto === 'AFECTA A PROVEEDORES' &&
                    <div>
                        <h5 className="p-2 bg-secondary title mt-1">Documentos agregados</h5>
                        <div style={estilo}>
                            <TablaVerDetalle
                                detalle={detalleND}
                            />
                        </div>
                   </div>
                )
            }
            {(editar === true) && concepto === 'AFECTA A PROVEEDORES' &&
                <div>
                    <h5 className="p-2 bg-secondary title mt-1">Documentos agregados</h5>
                    <div style={estilo}>
                        <TablaDetalleMod
                            detalle={detalleND}
                            eliminarDocumento={eliminarDocumento}
                        />
                    </div>
                     <br />
                </div>
            }
        </div>
    );
}

NDForm.propTypes = {
    funcionEnvio: PropTypes.func,
    crear: PropTypes.bool,
    editar: PropTypes.bool,
    lectura: PropTypes.object,
    bancos: PropTypes.object,
    listaM: PropTypes.object,
    listarProveedores: PropTypes.object,
    leerDocumentos: PropTypes.func,
    doc: PropTypes.object,
    loader: PropTypes.bool,
    bloqueo: PropTypes.bool,
    detalleDoc: PropTypes.object,
    eliminarDocumento: PropTypes.func,
    cuentaBan: PropTypes.object,
    ck: PropTypes.int,
    detalleND: PropTypes.object,
    fechaInicio: PropTypes.string,
    fechaFinal: PropTypes.string,
};

export default reduxForm({
    form: 'FormND',
})(NDForm);

const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;