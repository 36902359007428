import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/produccion/invernaderos';
import Invernadero from './InvernaderosList';


const ms2p = (state) => {
  return {
    ...state.invernaderos,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Invernadero);
