import React, { Component } from 'react';
import Grid from '../../Utils/GridFiltro';
import PropTypes from 'prop-types';
import { standardActions } from '../../Utils/GridProducto/StandardActions';
import { GrDocumentPdf } from 'react-icons/gr';
import { IconContext } from "react-icons";
import { FaFacebook, FaPlus, FaPlusCircle } from 'react-icons/fa';

class ProductoList extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: true
    };

    componentDidMount = () => {
        const { listar, page } = this.props;
        listar();
    }
    render() {
        const { data, loader, eliminar, reporteProductos } = this.props;
        console.log(data);
        const estilo1 = {
            backgroundColor: "#ff413d", maxHeight: "35px", maxWidth: "35px"
        };
        const styleImg = {
            width:"50px",
            height:"50px",
            objectfit: "cover",
            transition: "transform .2s",
        };
        let ruta = window.location.href;
        let id_emp = ruta.split('/');
        return (
            <React.Fragment>
                {/* <br /> */}
                <div className="d-flex flex-row justify-content-between align-items-center p-2">
                    <h3 className="p-2 text-md-center title">
                        Productos
                        <span>  </span>
                        <a
                            href={`/#/punto_venta/${id_emp[5]}/producto/crear`}
                            className='btn btn-primary text-lg'>
                            {<FaPlusCircle />}  Nuevo
                        </a>
                    </h3>
                    <button className='btn btn-dark float-right d-flex justify-content-center' onClick={reporteProductos} style={estilo1}
                        data-bs-toggle="tooltip" data-bs-placement="left" title="Generar reporte">
                        <i>
                            <IconContext.Provider value={{ size: "1.25rem" }}>
                                <GrDocumentPdf />
                            </IconContext.Provider>
                        </i>
                    </button>
                </div>
                {data &&
                    <Grid
                        hover
                        striped
                        data={data}
                        loading={loader}
                        headerStyle={{ background: 'black' }}
                    //onPageChange={onPageChange}
                    //onSortChange={onSortChange} 
                    >
                        <TableHeaderColumn
                            isKey
                            dataField="SKU"
                            width="100"
                            dataSort
                            thStyle={
                                { color: 'white' }}
                            tdStyle={
                                { color: 'white' }}
                        >
                            SKU
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="producto"
                            width="250"
                            dataSort
                            thStyle={
                                { color: 'white' }}
                            tdStyle={
                                { color: 'white' }}
                        >
                            Producto
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="tipo"
                            width="100"
                            dataAlign='center'
                            dataSort
                            dataFormat={(cell, row) => {
                                if (cell === 'P') {
                                    return 'PRODUCTO'
                                }
                                if (cell === 'C') {
                                    return 'COMBO'
                                }
                                if (cell === 'S') {
                                    return 'SERVICIO'
                                }
                            }}
                            thStyle={
                                { color: 'white' }}
                            tdStyle={
                                { color: 'white' }}
                        >
                            Tipo
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="imagen"
                            dataAlign='center'
                            width="120"
                            dataSort
                            dataFormat={(cell, row) => {
                                if (cell === null) {
                                    return ''
                                } else {
                                    return (<div><img style={styleImg}
                                        className='rounded-pill border text-center border-secondary zoom'
                                        src={cell}
                                        /></div>);
                                }
                            }}
                            thStyle={
                                {
                                    color: 'white',
                                }}
                            tdStyle={
                                { color: 'white' }}
                        >
                            Imagen
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            thStyle={
                                { color: 'white' }}
                            dataField="id"
                            width="180"
                            dataAlign="center"
                            dataSort
                            dataFormat={standardActions({
                                editar: "producto",
                                ver: "producto",
                                combo: 'combo'
                            })}

                        >
                            Acciones
                        </TableHeaderColumn>
                    </Grid>
                }
            </React.Fragment>
        );
    }
}

export default ProductoList;
