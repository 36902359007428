import React, { useState } from 'react';
import Modal from '../components/Modal';
import ModalRegistro from '../components/ModalInf_registro_sanitario';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LoadMask from "../Utils/LoadMask/LoadMask";
import NavbarModulo from '../components/NavbarModulo';
import NavbarModuloLF from '../components/NavbarModuloFage';
const valEmp = __CONFIG__.empresa;

import {
    SelectField,
    SelectFieldReporte,
    renderNumber,
    AsyncSelectFieldIn,
    SelectFieldReporteBusqueda
} from "../Utils/renderField/renderField";


function InformesForm(props) {
    const {
        modulo,
        loader,
        obtenerPaises,
        listaPro,
        reporteRegistrosSanitariosVigentes,
        reporteRegistrosSanitariosPorVencer,
        listaFamilia,
        listaLinea,
        listaGrupo,
        listaForma,
        reporteProductos,
        listaPrincipio,
        reporteRegistrosExcel
    } = props;
    
    const [modalListadoProducto, setModalListadoProducto] = useState(false);
    const [modalRegistrosVigentes, setModalRegistrosVigentes] = useState(false);
    const [modalRegistrosVigentesExcel, setModalRegistrosVigentesExcel] = useState(false);
    const [modalRegistrosPorVencer, setModalRegistrosPorVencer] = useState(false);
    
    let titulo = 'Informes'
    let ruta = window.location.href;
    let id_emp = ruta.split('/');

    const meses = [
        {
            label: 'ENERO',
            value: '01'
          
        },
        {
            label: 'FEBRERO',
            value: '02'
        },
        {
            label: 'MARZO',
            value: '03',
        },
        {
            label: 'ABRIL',
            value: '04',
        },
        {
            label: 'MAYO',
            value: '05',
        },
        {
            label: 'JUNIO',
            value: '06',
        },
        {
            label: 'JULIO',
            value: '07',
        },
        {
            label: 'AGOSTO',
            value: '08',
        },
        {
            label: 'SEPTIEMBRE',
            value: '09',
        },
        {
            label: 'OCTUBRE',
            value: '10'
        },
        {
            label: 'NOVIEMBRE',
            value: '11',
        },
        {
            label: 'DICIEMBRE',
            value: '12',
        },
    ];
    
    const busqueda = [
        {
            label: 'LINEA',
            value: '1'
          
        },
        {
            label: 'FAMILIA',
            value: '2'
        },
        {
            label: 'FORMA FARMACEUTICA',
            value: '3',
        },
        {
            label: 'PRINCIPIO ACTIVO',
            value: '4',
        },
        {
            label: 'GRUPO TERAPÉUTICO',
            value: '5',
        },
    ];

    let rutaInforme = '';
    if (valEmp === 'fage'){
        rutaInforme = `/#/lfg/registro_sanitario/${id_emp[6]}/informes`
        
    }else{
        rutaInforme = `/#/registro_sanitario/${id_emp[5]}/informes`
    }

    const [tipoBusqueda, setBusqueda] = useState('');

    const seleccionarBusqueda = value => {
        if (parseInt(value) === 1) {
            setBusqueda('linea');
        }
        if (parseInt(value) === 2) {
            setBusqueda('familia');
        }
        if (parseInt(value) === 3) {
            setBusqueda('forma');
        }
        if (parseInt(value) === 4) {
            setBusqueda('principio');
        }
        if (parseInt(value) === 5) {
            setBusqueda('grupo');
        }
    };

    return (
        <div>
            {valEmp === 'fage' &&
                <NavbarModuloLF
                    modulo={modulo}
                    titulo={titulo}
                />
            }
            {valEmp === '' &&
                <NavbarModulo
                    modulo={modulo}
                    titulo={titulo}
                />
            }
        
            <div className="container">
                <br/>
                <h4 className='mt-1' style={{ color: "white" }}>Informes</h4>
                <div className="row">
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <a class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => setModalListadoProducto(!modalListadoProducto)}
                                data-bs-toggle="list"
                                role="tab"
                                href={rutaInforme}
                                aria-controls="list-home">Listado de productos</a>
                        </div>
                    </div>
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <a class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => setModalRegistrosVigentes(!modalRegistrosVigentes)}
                                data-bs-toggle="list"
                                role="tab"
                                href={rutaInforme}
                                aria-controls="list-home">Registros sanitarios vigentes</a>
                        </div>
                    </div>
                    <div className="col-xxl-4">
                        <div className="list-group" id="list-tab" role="tablist">
                            <a class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => setModalRegistrosPorVencer(!modalRegistrosPorVencer)}
                                data-bs-toggle="list"
                                role="tab"
                                href={rutaInforme}
                                aria-controls="list-home">Registros sanitarios por vencer</a>
                        </div>
                    </div>
                </div>
                <div className="row mt-1">
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <a class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => setModalRegistrosVigentesExcel(!modalRegistrosVigentesExcel)}
                                data-bs-toggle="list"
                                role="tab"
                                href={rutaInforme}
                                aria-controls="list-home">Registros sanitarios a excel</a>
                        </div>
                    </div>
                </div>
                <Modal
                    estado={modalListadoProducto} //Listado de productos
                    cambiarEstado={setModalListadoProducto}
                    titulo="Listado de productos"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className='col-8 mb-2'>
                                {listaFamilia &&
                                    <div className='col-12 mb-2'>
                                        <Field
                                            name="familia"
                                            options={listaFamilia}
                                            component={SelectFieldReporte}
                                            className="form-select"
                                            msj="Familia"
                                        />
                                    </div>
                                }
                            </div>
                            <div className='col-8 mb-2'>
                                {listaLinea &&
                                    <div className='col-12 mb-2'>
                                        <Field
                                            name="linea"
                                            options={listaLinea}
                                            component={SelectFieldReporte}
                                            className="form-select"
                                            msj="Linea"
                                        />
                                    </div>
                                }
                            </div>
                            <div className='col-12 mb-2'>
                                {listaGrupo &&
                                    <div className='col-12 mb-2'>
                                        <Field
                                            name="grupo"
                                            options={listaGrupo}
                                            component={SelectFieldReporte}
                                            className="form-select"
                                            msj="Grupo"
                                        />
                                    </div>
                                }
                            </div>
                            <div className='col-12 mb-2'>
                                {listaForma &&
                                    <div className='col-12 mb-2'>
                                        <Field
                                            name="forma"
                                            options={listaForma}
                                            component={SelectFieldReporte}
                                            className="form-select"
                                            msj="Forma Farmaceutica"
                                        />
                                    </div>
                                }
                            </div>
                        </Contenido>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div className='row'>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={reporteProductos}
                                    >
                                    Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn btn-secondary'
                                        onClick={() => setModalListadoProducto(false)}
                                    >
                                    Cancelar
                                    </button>
                                </div>
                            </Contenido>
                        </LoadMask>
                </Modal>  
                <ModalRegistro
                    estado={modalRegistrosVigentes} //Registros sanitarios vigentes
                    cambiarEstado={setModalRegistrosVigentes}
                    titulo="Registros sanitarios vigentes"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className='col-12 mb-2'>
                                <Field
                                    name="producto"
                                    placeholder="Producto..."
                                    className="form-control-find"
                                    component={AsyncSelectFieldIn}
                                    loadOptions={listaPro}
                                    msj="Producto"
                                />
                            </div>
                            <div className='col-8 mb-2'>
                                {obtenerPaises &&
                                    <div className='col-12 mb-3'>
                                        <Field
                                            name="pais"
                                            options={obtenerPaises}
                                            component={SelectFieldReporte}
                                            className="form-select"
                                            msj="Pais"
                                        />
                                    </div>
                                }
                            </div>
                            <div className='row col-12 mb-2'>
                                <div className='col-6 mb-2'>
                                    <Field
                                        name="busqueda"
                                        options={busqueda}
                                        component={SelectFieldReporte}
                                        className="form-select"
                                        msj="Criterio de busqueda"
                                        onChange={(e) => {
                                            seleccionarBusqueda(e[0])
                                        }}
                                    />
                                </div>
                                {tipoBusqueda === 'linea' &&
                                    <div className='col-6 mb-3'>
                                        {listaLinea &&
                                            <Field
                                                name="linea"
                                                options={listaLinea}
                                                component={SelectFieldReporteBusqueda}
                                                className="form-select"
                                                msj="Linea"
                                            />
                                        }
                                    </div>
                                }
                                {tipoBusqueda === 'familia' &&
                                <div className='col-6 mb-3'>
                                    {listaFamilia &&
                                        <Field
                                            name="familia"
                                            options={listaFamilia}
                                            component={SelectFieldReporteBusqueda}
                                            className="form-select"
                                            msj="Familia"
                                        />
                                    }
                                </div>
                            }
                            {tipoBusqueda === 'forma' &&
                                <div className='col-6 mb-3'>
                                    {listaForma &&
                                        <Field
                                            name="forma"
                                            options={listaForma}
                                            component={SelectFieldReporteBusqueda}
                                            className="form-select"
                                            msj="Forma Farmaceutica"
                                        />
                                    }
                                </div>
                            }
                            {tipoBusqueda === 'principio' &&
                                <div className='col-6 mb-3'>
                                    <div className="form-floating">
                                        <Field
                                            name="principio"
                                            options={listaPrincipio}
                                            component={SelectFieldReporteBusqueda}
                                            className="form-select"
                                            msj="Principio Activo"
                                        />
                                    </div>
                                </div>
                            }
                            {tipoBusqueda === 'grupo' &&
                                <div className='col-6 mb-3'>
                                    {listaGrupo &&
                                        <Field
                                            name="grupo"
                                            options={listaGrupo}
                                            component={SelectFieldReporteBusqueda}
                                            className="form-select"
                                            msj="Grupo"
                                            />
                                    }
                                </div>
                            }
                            </div>
                            
                            
                        </Contenido>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div className='row'>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={() => reporteRegistrosSanitariosVigentes(tipoBusqueda)}
                                    >
                                    Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn btn-secondary'
                                        onClick={() => setModalRegistrosVigentes(false)}
                                    >
                                    Cancelar
                                    </button>
                                </div>
                            </Contenido>
                        </LoadMask>
                </ModalRegistro>  
                <Modal
                    estado={modalRegistrosPorVencer} //Registros sanitarios por vencer
                    cambiarEstado={setModalRegistrosPorVencer}
                    titulo="Registros sanitarios por vencer"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className='col-6 mb-2'>
                                <Field
                                    name="mes"
                                    options={meses}
                                    component={SelectField}
                                    className="form-select"
                                    msj="Mes"
                                />
                            </div>
                            <div className='col-6 mb-3'>
                                <div className="form-floating">
                                    <Field
                                        name="year"
                                        component={renderNumber}
                                        className="form-control"
                                        msj="Año"
                                    />
                                </div>
                            </div>
                        </Contenido>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div className='row'>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={reporteRegistrosSanitariosPorVencer}
                                    >
                                        Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn btn-secondary'
                                        onClick={() => setModalRegistrosPorVencer(false)}
                                    >
                                        Cancelar
                                    </button>
                                </div>
                            </Contenido>
                        </LoadMask>
                </Modal>  
                <Modal
                    estado={modalRegistrosVigentesExcel} //Registros vigentes a excel
                    cambiarEstado={setModalRegistrosVigentesExcel}
                    titulo="Registros sanitarios vigentes a excel"
                    botonCerrar="true"
                    >
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div className='row'>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={reporteRegistrosExcel}
                                    >
                                    Generar excel
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn btn-secondary'
                                        onClick={() => setModalRegistrosVigentesExcel(false)}
                                    >
                                    Cancelar
                                    </button>
                                </div>
                            </Contenido>
                        </LoadMask>
                </Modal>  
            </div>
        </div>
    );
}

InformesForm.propTypes = {
    loader: PropTypes.bool,
    modulo: PropTypes.object,
    listarProyectos: PropTypes.func,
    reporteDetalleGastosProyecto: PropTypes.func,
};

export default reduxForm({
    form: 'FormInformesRegistroSanitario',
})(InformesForm);


const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;
