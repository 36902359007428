import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import {
    FicComboBox,
    FicFileDrop,
    FicInput,
    FicCheckbox,
} from "../../../Utils/FormInputComponents";
import GenBtn from "../../../components/GenBtn";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as XLSX from "sheetjs-style";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { validar } from "../../../components/ValidarNit";
import LoadMask from "../../../Utils/LoadMask/LoadMask";

const FormContainer = styled.form`
    display: flex;
    flex-direction: column;
    margin-bottom: 2%;
    gap: 2%;
`;
const DialogContent = styled.div`
    color: #e1e1e1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2%;
    margin-bottom: 2%;
    gap: 2%;
`;

const GridContainer = styled.div`
    /* flex-grow: 1; */
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    gap: 0.5rem;
`;

const CuentaDataContainer = styled.div`
    display: flex;
    gap: 0.25rem;
    align-items: center;
    opacity: 0;
    transition: opacity 300ms ease-in;
    opacity: 1;
`;

const Modal = styled.dialog`
    /* min-width: 385px; */
    width: clamp(286px, 100%, 500px);
    padding: 1%;
    border-radius: 7px;
    background: #1e1e1e;
    border: 1px dashed #bd0034;
    overflow: visible;

    &::backdrop {
        background: hsla(0, 0%, 0%, 0.8);
    }
`;

const DialogFooter = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.5rem;
`;

const DialogBtnContainer = styled.div`
    display: flex;
    gap: 0.45rem;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 2rem;
    width: 100%;
    margin-top: 2%;
`;

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 2%;
    margin-bottom: 2%;
    gap: 2%;
`;

const IndicadorContainer = styled.div`
    display: flex;
    padding: 0.325rem 0.325rem 1.325rem 0.325rem;
`;
const IndicadorDeArchivosIngresados = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props) => {
        return props.hasData ? "hsl(121, 91%, 27%)" : "hsl(9, 100%, 40%)";
    }};
    border-radius: 5px;
    border: 1px solid
        ${(props) => {
            return props.hasData ? "hsl(121, 91%, 27%)" : "hsl(9, 100%, 40%)";
        }};

    background-color: ${(props) =>
        props.hasData ? "hsl(121, 91%, 27%, 0.2)" : "hsl(9, 100%, 40%, 0.2)"};
    width: 1.75rem;
    height: 1.75rem;
`;

const getCurrentYear = () => new Date().getFullYear();

const generateYearList = () => {
    const currentYear = getCurrentYear();
    const previousYear = currentYear - 1;

    const yearList = [
        { value: currentYear, label: currentYear.toString() },
        { value: previousYear, label: previousYear.toString() },
    ];

    return yearList;
};

const mes = [
    { value: 1, label: "Enero" },
    { value: 2, label: "Febrero" },
    { value: 3, label: "Marzo" },
    { value: 4, label: "Abril" },
    { value: 5, label: "Mayo" },
    { value: 6, label: "Junio" },
    { value: 7, label: "Julio" },
    { value: 8, label: "Agosto" },
    { value: 9, label: "Septiembre" },
    { value: 10, label: "Octubre" },
    { value: 11, label: "Noviembre" },
    { value: 12, label: "Diciembre" },
];

const schema = yup.object().shape({
    year: yup.mixed().required("Seleccione un año"),
    mes: yup.mixed().required("Seleccione un mes"),
});

const yearOptions = generateYearList();

function BalanceDeSaldos(props) {
    const {
        guardarLibroCompras,
        guardarContribuyente,
        chequearMesesIngresados,
    } = props;
    const [tableData, setTableData] = useState([]);
    const [fileExists, setFileExists] = useState(false);
    const [contribuyentesFaltantes, setContribuyentesFaltantes] = useState();
    const [dataCuentas, setDataCuentas] = useState([]);
    const [paginaActual, setPaginaActual] = useState(1);
    const [loadingState, setLoadingState] = useState(false);
    const [mesesIngresados, setMesesIngresados] = useState([
        { mes: 1, hasData: false },
        { mes: 2, hasData: false },
        { mes: 3, hasData: false },
        { mes: 4, hasData: false },
        { mes: 5, hasData: false },
        { mes: 6, hasData: false },
        { mes: 7, hasData: false },
        { mes: 8, hasData: false },
        { mes: 9, hasData: false },
        { mes: 10, hasData: false },
        { mes: 11, hasData: false },
        { mes: 12, hasData: false },
    ]);
    const cuentasPorPagina = 9;
    let numPaginas = 0;
    if (contribuyentesFaltantes) {
        numPaginas = Math.ceil(
            contribuyentesFaltantes.length / cuentasPorPagina
        );
    }

    const paginarCuentas = () => {
        const indexInicial = (paginaActual - 1) * cuentasPorPagina;
        const indexFinal = indexInicial + cuentasPorPagina;
        return dataCuentas.slice(indexInicial, indexFinal);
    };

    const dialogRef = useRef(null);

    const TableContainer = styled.div`
        width: fit-content;
        overflow: scroll;
        height: 75vh;
        border: ${fileExists ? "2px solid #dd0000" : ""};
        resize: vertical;

        & ::-webkit-resizer {
            display: ${fileExists ? "none" : ""};
            color: #00ff00;
        }
    `;

    const {
        handleSubmit,
        formState: { errors },
        control,
        reset,
        watch,
    } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: { year: null, mes: null },
    });

    const onSubmit = async (data) => {
        setTableData([]);
        reset();
        setLoadingState(true);
        await guardarLibroCompras(data)
            .then((response) => {
                if (response) {
                    let dataArray = response.map((item) => ({
                        // key: index,
                        nit: item.nit,
                        razon_social: { value: item.nombre, error: "" },
                        exterior: item.exterior,
                    }));
                    dataArray = dataArray.sort((a, b) => {
                        if (a.nit < b.nit) return -1;
                        if (a.nit > b.nit) return 1;
                    });
                    setContribuyentesFaltantes(dataArray);
                    setDataCuentas(dataArray);
                    if (contribuyentesFaltantes) {
                        dialogRef.current.showModal();
                    }
                }
            })
            .then(() => {
                setFileExists(false);
                setLoadingState(false);
                chequearMeses(data.year.value);
            });
    };

    const chequearMeses = async (year) => {
        if (year) {
            let meses = await chequearMesesIngresados(year);
            let mesesIngresadosActualizados = meses.map((mesObj, index) => {
                return { mes: mesObj.month, hasData: mesObj.hasData };
            });
            setMesesIngresados(mesesIngresadosActualizados);
        }
    };

    const year = watch("year");
    useEffect(() => {
        if (year) {
            setTableData([]);
            chequearMeses(year.value);
        }
    }, [year]);

    useEffect(() => {
        if (contribuyentesFaltantes) {
            dialogRef.current.showModal();
        }
    }, [contribuyentesFaltantes]);

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onload = (event) => {
            const data = event.target.result;
            const workbook = XLSX.read(data, { type: "binary" });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(sheet);

            setTableData(jsonData);
        };

        reader.readAsBinaryString(file);
        setFileExists(true);
    };

    return (
        <React.Fragment>
            <h4 style={{ margin: "2% 0", borderBottom: "1px dashed #bd0034" }}>
                Libro compras
            </h4>
            {/* <FicFileDrop nonForm type="file" /> */}
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                }}
            >
                <FormContainer
                    encType="multipart/form-data"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <div style={{ display: "flex", gap: "2rem" }}>
                        <FicComboBox
                            control={control}
                            name="year"
                            label="Año"
                            options={yearOptions}
                            nonAsync
                            componentStyle={{ width: "15%", minWidth: "206px" }}
                        />
                        <FicComboBox
                            control={control}
                            name="mes"
                            label="Mes"
                            options={mes}
                            nonAsync
                            componentStyle={{ width: "15%", minWidth: "206px" }}
                        />
                    </div>
                    <ButtonContainer>
                        <FicFileDrop
                            control={control}
                            name="libro_compras"
                            labelText="Subir libro de compras"
                            type="file"
                            style={{ margin: "5px" }}
                            onChange={handleFileUpload}
                            accept=".xlsx, .xls, .csv"
                        />
                        {/* <GenBtn style={{margin: "0"}}>Guardar</GenBtn> */}

                        <LoadMask
                            loading={loadingState}
                            color="#bd0034"
                            blur={true}
                        >
                            {fileExists && (
                                <GenBtn type="submit">Guardar</GenBtn>
                            )}
                        </LoadMask>
                    </ButtonContainer>
                </FormContainer>
                <div>
                    <div>Meses con datos: &nbsp;</div>
                    <IndicadorContainer>
                        {mesesIngresados.map((item, index) => (
                            <IndicadorDeArchivosIngresados
                                key={index}
                                hasData={item.hasData}
                            >
                                {item.mes}
                            </IndicadorDeArchivosIngresados>
                        ))}
                    </IndicadorContainer>
                </div>
            </div>
            <TableContainer>
                <table style={{ padding: "2%" }}>
                    <thead
                        style={{ backgroundColor: "#2e2e2e", color: "#fff" }}
                    >
                        <tr>
                            {tableData.length > 0 &&
                                Object.keys(tableData[4]).map(
                                    (header, index) => (
                                        <th key={index}>{header}</th>
                                    )
                                )}
                        </tr>
                    </thead>
                    <tbody
                        style={{ backgroundColor: "#1e1e1e", color: "#fff" }}
                    >
                        {tableData.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                {Object.values(row).map((cell, cellIndex) => {
                                    return (
                                        <td
                                            key={cellIndex}
                                            style={{
                                                border: "1px solid #bd0034",
                                                padding: "0rem 1rem",
                                                background: `${
                                                    cellIndex == 4 &&
                                                    !validar.valNit(cell)
                                                        ? "hsla(0, 100%, 37%, 0.4)"
                                                        : ""
                                                }`,
                                            }}
                                        >
                                            {cellIndex === 2 || cellIndex === 3
                                                ? cell
                                                : cell.toLocaleString()}
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </TableContainer>

            <Modal ref={dialogRef}>
                <DialogContent>
                    <h5 style={{ color: "#bd0034", fontWeight: "bold" }}>
                        FALTAN LOS SIGUIENTES CONTRIBUYENTES
                    </h5>
                    <GridContainer>
                        <div
                            style={{
                                display: "grid",
                                gridTemplateColumns: "0.25fr 1fr  0.115fr",
                                gridTemplateRows: "1fr",
                                gap: "1rem",
                                color: "#009c03",
                            }}
                        >
                            <div>NIT</div>
                            <div>Nombre</div>
                            <div>Exterior?</div>
                        </div>
                        {/* whoever mantains this... sorry!*/}
                        {dataCuentas &&
                            paginarCuentas().map((item, index) => {
                                const itemIndex =
                                    (paginaActual - 1) * cuentasPorPagina +
                                    index;
                                return (
                                    <CuentaDataContainer key={index}>
                                        <div style={{ minWidth: "11ch" }}>
                                            {item.nit}
                                        </div>
                                        <FicInput
                                            style={{
                                                minWidth: "100px",
                                            }}
                                            nonForm
                                            placeHolder="nombre"
                                            value={item.razon_social.value}
                                            onChange={(e) => {
                                                setDataCuentas((prev) => {
                                                    const updatedPrev = [
                                                        ...prev,
                                                    ];
                                                    const updatedItem = {
                                                        ...updatedPrev[
                                                            itemIndex
                                                        ],
                                                    };

                                                    updatedItem.razon_social = {
                                                        ...updatedItem.razon_social,
                                                        value: e,
                                                    };

                                                    updatedPrev[itemIndex] =
                                                        updatedItem;

                                                    return updatedPrev;
                                                });
                                            }}
                                            errorMsj={item.razon_social.error}
                                        />
                                        <FicCheckbox
                                            style={{
                                                margin: "0rem 1rem",
                                            }}
                                            nonForm
                                            placeHolder="exterior"
                                            isSelected={item.exterior}
                                            onChange={(e) => {
                                                setDataCuentas((prev) => {
                                                    const updatedPrev = [
                                                        ...prev,
                                                    ];
                                                    const updatedItem = {
                                                        ...updatedPrev[
                                                            itemIndex
                                                        ],
                                                    };

                                                    updatedItem.exterior =
                                                        !updatedItem.exterior;

                                                    updatedPrev[itemIndex] =
                                                        updatedItem;

                                                    return updatedPrev;
                                                });
                                            }}
                                        />
                                    </CuentaDataContainer>
                                );
                            })}
                    </GridContainer>
                    <DialogFooter>
                        <GenBtn
                            textColor="hsl(0, 100%, 50%)"
                            background="hsla(0, 100%, 50%, 0.2)"
                            onPress={() => dialogRef.current.close()}
                        >
                            Cerrar
                        </GenBtn>
                        <DialogBtnContainer>
                            <GenBtn
                                textColor={`hsla(360, 0%, 80%, ${
                                    paginaActual === 1 ? "0.2" : "1"
                                })`}
                                background="hsla(360, 0%, 80%, 0.2)"
                                onPress={() =>
                                    setPaginaActual(paginaActual - 1)
                                }
                                isDisabled={paginaActual === 1}
                            >
                                <FaArrowLeft />
                            </GenBtn>
                            <small>
                                {paginaActual} de {numPaginas} paginas
                            </small>
                            <GenBtn
                                textColor={`hsla(360, 0%, 80%, ${
                                    paginaActual === numPaginas ? "0.2" : "1"
                                })`}
                                background="hsla(360, 0%, 80%, 0.2)"
                                onPress={() =>
                                    setPaginaActual(paginaActual + 1)
                                }
                                isDisabled={paginaActual === numPaginas}
                            >
                                <FaArrowRight />
                            </GenBtn>
                        </DialogBtnContainer>
                        <GenBtn
                            onPress={() => {
                                let isValid = true;
                                let newDataCuentas = dataCuentas.map((item) => {
                                    if (!item.razon_social.value) {
                                        isValid = false;
                                        item.razon_social.error = "Requerido";
                                    } else item.razon_social.error = "";
                                    return item;
                                });
                                setDataCuentas(newDataCuentas);
                                if (isValid) {
                                    for (
                                        let index = 0;
                                        index < dataCuentas.length;
                                        index++
                                    ) {
                                        const element = dataCuentas[index];
                                        element.razon_social =
                                            element.razon_social.value;
                                        guardarContribuyente(element);
                                    }
                                    setDataCuentas([]);
                                    dialogRef.current.close();
                                }
                            }}
                        >
                            Guardar
                        </GenBtn>
                    </DialogFooter>
                </DialogContent>
            </Modal>
        </React.Fragment>
    );
}

export default BalanceDeSaldos;
