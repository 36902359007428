import React from 'react';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import * as BsIcons from "react-icons/bs";

export const SidebarData = [
    {
        title: 'Inicio',
        path: '/inmobiliaria',
        icon: <BsIcons.BsGrid1X2Fill />,
    },
    {
        title: 'Catalogos',
        path: '#',
        icon: <FaIcons.FaBook />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav: [
            {
                title: 'Tipos de obra',
                path: '/inmobiliaria',
                path_s: 'tipo_obra',
                icon: <BsIcons.BsPinFill />,
            },
            {
                title: 'Proyecto',
                path: '/inmobiliaria',
                path_s: 'proyectos',
                icon: <BsIcons.BsInboxFill />,
            },
            {
                title: 'Subproyecto',
                path: '/inmobiliaria',
                path_s: 'subproyectos',
                icon: <BsIcons.BsInboxesFill />,
            },
            {
                title: 'Fases',
                path: '/inmobiliaria',
                path_s: 'fases',
                icon: <FaIcons.FaBuffer />,
            },
            {
                title: 'Materiales',
                path: '/inmobiliaria',
                path_s: 'materiales',
                icon: <FaIcons.FaTools />,
            }
        ]
    },    
    {
        title: 'Informes',
        path: '/facturacion/informes',
        icon: <FaIcons.FaClipboard />,
    }
]
