import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";

const LISTADO_FASE = 'LISTADO_FASE';
const GUARDAR_FASE = 'GUARDAR_FASE';
const LOADER = 'LOADER';
const MODULO = 'MODULO';
const LISTADO_CENTROS = 'LISTADO_CENTROS'

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    //const params = { page, id };
    const params = { id };
    api.get('/fase_prd', params).then((response)=>{
        dispatch({ type: LISTADO_FASE, data: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar fases',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const registroFase = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormFasePrd.values;
    const formData = {
        centro_trabajo: data.centro_trabajo,
        descripcion: data.descripcion,
        activo: data.activo === false ? 'False' : 'True',
        area: data.area,
        empresa: id_emp[5]
    }
    dispatch(setLoader(true));
    api.post('/fase_prd/', formData).then(() => {
        NotificationManager.success(
        'Fase creado',
        'Exito',
        3000
    );
    let ruta = `/produccion/${id_emp[5]}/fase`
    dispatch(push(ruta));
    }).catch((error) => {
    NotificationManager.error(
        error.msj,
        'Error',
        2000
    );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const modificarFase = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const datos = getStore().form.FormFasePrd.values;
    const id = datos.id;
    const formData = {
        id: id,
        centro_trabajo: datos.centro_trabajo,
        activo: datos.activo === false ? 'False' : 'True',
        descripcion: datos.descripcion,
        area: datos.area
    }
    dispatch(setLoader(true));
    api.put(`/fase_prd/${id}/`, formData).then(() => {
        NotificationManager.success(
            'Fase modificado correctamente',
            'Exito',
            3000
        );
        let ruta = `/produccion/${id_emp[5]}/fase`
        dispatch(push(ruta));
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al modificar fase',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/fase_prd/${id}`).then((response) => {
        dispatch({type: GUARDAR_FASE, lectura: response });
        dispatch(initializeForm('FormFasePrd', response ));
    }).catch((error)=>{
        console.log("error: ", error)
        NotificationManager.error(
            'Ocurrió un error al consultar fase',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const listarCentroTrabajo = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    return api.get('/centro_trabajo', params).then((response)=>{
        const tipos = [];
        response.forEach(item=>{
            tipos.push({
                value: item.id,
                label: item.descripcion
            })
        })
        dispatch({ type: LISTADO_CENTROS, listaCentros: tipos });
    }).catch(error=>{
        NotificationManager.error(
            'Ocurrió un error al listar centro de trabajo',
            'Error',
            0
        );
    })
}

export const actions = {
    listar,
    registroFase,
    modificarFase,
    leer,
    getModulo,
    listarCentroTrabajo
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_FASE]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [GUARDAR_FASE]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [LISTADO_CENTROS]: (state, { listaCentros }) => {
        return {
            ...state,
            listaCentros,
        };
    },
};

export const initialState = {
    loader: false,
    lectura: [],
    modulo: [],
    listaCentros: [],
    data: [],
    page: 1,
};

export default handleActions(reducers, initialState)
 