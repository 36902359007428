import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";

const PAGE = 'PAGE';
const LOADER = 'LOADER';
const IMPUESTO = 'IMPUESTO';
const MODULO = 'MODULO';

const setPage = page => ({
    type: PAGE,
    page,
});

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/impuesto/${id}`).then((response) => {
        dispatch(initializeForm('FormImportacionMod', response ));
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar el impuesto',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const modificarImpuesto = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const datos = getStore().form.FormImportacionMod.values;
    const id = datos.id;
    const formData = {
        id: id,
        porcentaje_iva: datos.porcentaje_iva,
        gSuper: datos.costo_galon_super,
        gRegular: datos.costo_galon_regular,
        gDiesel: datos.costo_galon_diesel,
    }
    dispatch(setLoader(true));
    api.put(`/impuesto/${id}/`, formData).then(() => {
        NotificationManager.success(
            'Impuesto modificado correctamente',
            'Exito',
            3000
        );
        let ruta = `/ajustes/${id_emp[5]}/impuestos`
        dispatch(push(ruta));
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al modificar el impuesto',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const listarImpuestos = (page = 1) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { page, id };
    let cont = 1;
    let ct = localStorage.getItem("impuestoList");
    localStorage.setItem("impuestolist",cont);
    if (page===1 && ct===null){
        dispatch(setLoader(true));
    }
    dispatch(setPage(page));
    api.get('/impuesto', params).then((response)=>{
        dispatch({ type: IMPUESTO, impuesto: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar impuestos',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const registroImpuesto = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormImpuestoEmpresa.values;
    const formData = {
        porcentaje_iva: data.porcentaje_iva,
        gSuper: data.costo_galon_super,
        gRegular: data.costo_galon_regular,
        gDiesel: data.costo_galon_diesel,
        empresa: id_emp[5],
    }
    dispatch(setLoader(true));
    api.post('/impuesto/', formData).then(() => {
        NotificationManager.success(
            'Impuesto agregado correctamente',
            'Exito',
            3000
        );
        let page = 1
        dispatch(listarImpuestos(page));
    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


export const actions = {
    registroImpuesto,
    modificarImpuesto,
    leer,
    listarImpuestos,
    getModulo,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [IMPUESTO]: (state, { impuesto }) => {
        return {
            ...state,
            impuesto,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
};

export const initialState = {
    loader: false,
    lectura: [],
    impuesto: [],
    modulo: [],
    data: {
        results: [],
        count: 0,
    },
    page: 1,
};

export default handleActions(reducers, initialState)