import React, { Component } from 'react';
import Formulario from './TipoDocumentoForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";
const valEmp = __CONFIG__.empresa;

class TipoDocumento extends Component{
    state = {
        crear: true,
    }

    componentDidMount = () => {
        const { match, leerPais, getModulo, listarDocumentos } = this.props;
        const id = match.params.id;
        if (id){
            this.setState({crear: false});
            leerPais(id);
            listarDocumentos();
        }
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        
        if (valEmp === 'fage'){
            getModulo(modulo[6],modulo[5])
        }else{
            getModulo(modulo[5],modulo[4])
        }
    }
    
    render(){
        const { registroPais, modificarPais, loader, modulo, listaP, documentos, 
            registroDocumento, eliminarDocumento } = this.props;
        const { crear } = this.state;
        const funcionEnvio = crear ? registroPais : modificarPais;
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    onSubmit = {funcionEnvio}
                    crear = {crear}
                    modulo = {modulo}
                    obtenerPaises = {listaP}
                    documentos = {documentos}
                    registroDocumento = {registroDocumento}
                    eliminarDocumento = {eliminarDocumento}
                />
            </LoadMask>
        );
    }
}

export default TipoDocumento;