
import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";

const LOADER = 'LOADER';
const LISTADO_CC = 'LISTADO_CC';
const GUARDAR_CENTRO = 'GUARDAR_CENTRO';
const PAGE = 'PAGE';
const MODULO = 'MODULO';

const setPage = page => ({
    type: PAGE,
    page,
});

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

const listarCentroC = (page = 1) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { page, id };
    dispatch(setLoader(true));
    api.get('/centrocosto', params).then((response)=>{
        dispatch({ type: LISTADO_CC, data: response });
        dispatch(setPage(page));
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar centro costo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}


export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/centrocosto/${id}`).then((response) => {
        dispatch({type: GUARDAR_CENTRO, lectura: response });
        dispatch(initializeForm('FormCentroCosto', response ));
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar centro costo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}


export const registroCentroCosto = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormCentroCosto.values;
    const formData = {
        codigo: data.codigo,
        descripcion: data.descripcion,
        empresa: id_emp[5],
    }
    dispatch(setLoader(true));
    api.post('/centrocosto/', formData).then((response) => {
        NotificationManager.success(
            'Centro costo creado',
            'Exito',
            3000
        );
        let ruta = `/contabilidad/${id_emp[5]}/centro_costo`
        dispatch(push(ruta));
    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const modificarCentroCosto = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const datos = getStore().form.FormCentroCosto.values;
    const id = datos.id;
    const formData = {
        id: id,
        descripcion: datos.descripcion,
    }
    dispatch(setLoader(true));
    api.put(`/centrocosto/${id}/`, formData).then((response) => {
        NotificationManager.success(
            'Centro costo modificado correctamente',
            'Exito',
            3000
        );
        let ruta = `/contabilidad/${id_emp[5]}/centro_costo`
        dispatch(push(ruta));
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al modificar centro costo',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const actions = {
    listarCentroC,
    leer,
    registroCentroCosto,
    modificarCentroCosto,
    getModulo
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_CC]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [GUARDAR_CENTRO]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
};

export const initialState = {
    loader: false,
    nivel: [],
    modulo: [],
    data: {
        results: [],
        count: 0,
    },
    page: 1,
};

export default handleActions(reducers, initialState)