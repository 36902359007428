import React, { useRef, useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { renderField, renderFieldCheckB } from '../../Utils/renderField/renderField';
import ReCAPTCHA from "react-google-recaptcha";
import PropTypes from 'prop-types';
import { api } from "api";
import { NotificationManager } from "react-notifications";
import styled from 'styled-components';
import { validar } from "../../../components/components/ValidarPass";
import Lista from "../../../components/components/ListaErrores";
import Modal from '../../../components/components/Modal'

const emailValidation = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
  'Dirección de correo electrónico no válido' : undefined

const estilo = {
    color: "white",
};
const estilo2 = {
    fontSize: "14px",
};

function RegisterForm(props) {
    const { onSubmit } = props;
    const [error, setError] = useState('');
    const captchaRef = useRef(null);
    const key = __CONFIG__.site_key;
    const secrect = __CONFIG__.secrect_key;
    
    const handle = (e) =>{
        e.preventDefault();
        if (estadoModal === false){
            const token = captchaRef.current.getValue();
            captchaRef.current.reset();
            const data = {
                token: token,
                se: secrect
            }
            api.post('/user/verificar_captcha/', data).then(response => {
                if (response.validar === true){
                    onSubmit(term)
                }
            }).catch((error) => {
                NotificationManager.error(
                    error.msj,
                    'Error',
                    8000
                );
            })
        }
    }
    const divError = {
        color: 'red',
    };

    const [term, setTerm] = useState(true);
    const [estadoModal, cambiarEstadoModal] = useState(false);

    const onCheckboxClickedTerm = (idx, isChecked) => {
        setTerm(isChecked);
    }

    return (
        <form name="loginForm" className="form-validate mb-lg" onSubmit={handle}>

            <div className='row mb-2'>
                <div className='col-6'>
                    <Field
                        name="first_name"
                        component={renderField}
                        placeholder="Nombre"
                        type="text"
                        className="form-control"
                        msj="Nombre"
                    />
                </div>
                <div className='col-6'>
                    <Field
                        name="last_name"
                        placeholder="Apellido"
                        component={renderField}
                        type="text"
                        className="form-control"
                        msj="Apellido"
                    />
                </div>

            </div>
            <div className="row mb-2">
                <div className='col-6'>
                    <Field
                        name="username"
                        placeholder="Correo electrónico"
                        component={renderField}
                        type="email"
                        validate={emailValidation}
                        className="form-control"
                        msj="Correo electrónico"
                    />
                </div>
            </div>
            <div className='row mb-2'>
                <div className='col-6'>
                    <Field
                        name="password"
                        placeholder="Contraseña"
                        component={renderField}
                        type="password"
                        onChange={(e, value) => {
                            if (value) {
                                let valor = validar.validarPassword(value);
                                if (valor.length > 0) {
                                    setError(valor);
                                }
                                if (valor === true){
                                    setError('');
                                }
                            }
                        }}
                        className="form-control"
                        msj="Contraseña"
                    />
                </div>
                <div className='col-6'>
                    <Field
                        name="confirmPassword"
                        placeholder="Confirmar Contraseña"
                        component={renderField}
                        type="password"
                        className="form-control"
                        msj="Confirmar Contraseña"
                    />
                </div>
            </div>
            {error &&
                <div className='col-12'>
                    <div style={divError}>
                        <Lista 
                            items = {error}
                        />
                    </div>
                </div>
            }
            <div className='mb-2'>
                <h5 style={estilo}>La contraseña debe cumplir los siguientes requerimientos:</h5>
                <div className="row">
                    <div className="col-6">
                        <ul>
                            <li style={estilo}>Al menos <strong>una letra minúscula</strong></li>
                            <li style={estilo}>Al menos <strong>una letra mayúscula</strong></li>
                            <li style={estilo}>Al menos <strong>un número</strong></li>
                        </ul>
                    </div>
                    <div className="col-6">
                        <ul>
                            <li style={estilo}>Al menos <strong>un carácter especial</strong></li>
                            <li style={estilo}>Mínimo <strong>8 carácteres</strong></li>
                        </ul>
                    </div>
                </div>
                
            </div>
            <div className='mb-2'>
                <Contenido>
                    <Field
                        name="terminos"
                        checked={term}
                        label="Aceptar términos y condiciones"
                        component={renderFieldCheckB}
                        onChange={onCheckboxClickedTerm}
                    />
                </Contenido>
            </div>
            <div className='mb-2'>
                <Contenido>
                    <a style={{ color: '#808080' }}
                        className="mr-2 mb-3"
                        onClick={() => cambiarEstadoModal(!estadoModal)}
                    >
                        Términos y condiciones
                    </a>
                </Contenido>
            </div>
            <div className='mb-2'>
                <Contenido>
                    <ReCAPTCHA 
                        theme={'dark'}
                        sitekey={key}
                        ref={captchaRef}
                    />
                </Contenido>
            </div>
            <div className="buttons-box">
                <button type="submit" style={estilo2} className="btn btn-primary m-1 align-self-center">Registrarse</button>
            </div>
            <Modal
                estado={estadoModal}
                cambiarEstado={cambiarEstadoModal}
                titulo="Términos y condiciones"
                botonCerrar="true"
            >
                <p style={{ color: '#fff', fontWeight: 'bold' }}>Aceptación de los Términos</p>
                <p style={{ color: '#fff' }}>Al acceder y utilizar nuestro sistema, aceptas estos términos y condiciones en su totalidad. Si no estás de acuerdo con alguno de los términos, te pedimos que no utilices el sistema.</p>

                <p style={{ color: '#fff', fontWeight: 'bold' }}>Uso Autorizado</p>
                <p style={{ color: '#fff' }}>Nuestro sistema se proporciona para fines contables y financieros. Está prohibido utilizar el sistema con fines ilegales o fraudulentos.</p>

                <p style={{ color: '#fff', fontWeight: 'bold' }}>Acceso y Seguridad</p>
                <p style={{ color: '#fff' }}>Debes mantener la confidencialidad de tus credenciales de acceso y no compartirlas con terceros. Eres responsable de cualquier actividad realizada en tu cuenta. Si sospechas un acceso no autorizado, debes notificarnos de inmediato.</p>
            
            </Modal>
        </form>
    );
};
RegisterForm.propTypes = {
    handleSubmit: PropTypes.func
};
export const matchPassword = (pass, confirm) => validatorFromFunction(value => {
    return pass === confirm;
});

export default reduxForm({
    form: 'register', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            confirmPassword: combine(
                validators.exists()('Este campo es requerido'),
                matchPassword(data.password, data.confirmPassword)()('Las contraseñas no coinciden')
            ),
            username: validators.exists()('Este campo es requerido'),
            first_name: validators.exists()('Este campo es requerido'),
            last_name: validators.exists()('Este campo es requerido'),
            password: validators.exists()('Este campo es requerido'),
        });
    },
})(RegisterForm);

const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;