import React from 'react';

const Lista = ({ items }) => {
    return (
      <ul >
        {items.map((linea) => (
            <li key={linea.id}>
                {linea.msj}
            </li>
        ))}
      </ul>
    );
};

export default Lista;