import { connect } from 'react-redux';
import { actions } from '../../../redux/modules/libros_contables/informes';
import Informe from './InformesLC';


const ms2p = (state) => {
  return {
    ...state.lc_informe,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Informe);
