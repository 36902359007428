import React from "react";
import styled from "styled-components";
import {
    FicInput,
    FicComboBox,
    FicDatePicker,
    FicDateRangePicker,
    FicNumber,
} from "../../Utils/FormInputComponents";
import Banner from "../common/Banner";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { validar } from "../../components/ValidarNit";
import GenBtn from "../../components/GenBtn";
import InputsContainer from "../common/InputsContainer";
import { crearSeguro, getAgentes, getClientes, getVehiculos } from "./actions";
import { useEffect, useMemo, useState } from "react";
import EditableTable from "../Table";
import { useListData, useAsyncList } from "react-stately";
import { useFilter } from "react-aria";

const GridContainer = styled.div`
    width: 100%;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, clamp(250px, 35%, 450px));
    /* justify-items: center; */
`;

const DatosDelVehiculoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #2b2b2b;
    padding: 2rem;
    border-radius: 5px;
    gap: 12px;
    overflow: visible;
    min-width: 640px;

    .--inputs-datos-vehiculo {
        margin: 1rem;
        margin-right: 2.5rem;
        min-width: 320px;
        @media (min-width: 1115px) {
            margin-top: 2rem;
        }
    }
`;

const TableContainer = styled.div`
    //breakpoint 1115
    display: flex;

    @media (max-width: 1115px) {
        flex-direction: column;
    }
`;

const SectionTitle = styled.h2`
    margin: 0 auto;
    font-size: large;
    color: #bd0034;
`;

const OPCIONES_TIPO = [
    { value: "vehiculos", label: "VEHICULOS" },
    { value: "gastos_medicos", label: "GASTOS MEDICOS" },
    { value: "incendio", label: "INCENDIO" },
];
const OPCIONES_CLASIFICACION = [
    { value: "casas", label: "CASA" },
    { value: "edificios", label: "EDIFICIO" },
    { value: "apartamentos", label: "APARTAMENTOS" },
];

const schema = yup.object({
    agente: yup.mixed().required("Seleccione un agente"),
    cliente: yup.mixed().required("Seleccione un agente"),
    tipo: yup.mixed().required("Seleccione un agente"),
    // clasificacion: yup.mixed().when("tipo", {
    //     is: { value: "incendio", label: "INCENDIO" },
    //     then: yup.mixed().required("Seleccione una clasificacion"),
    // }),
    poliza: yup.string().required("El nombre es requerido"),
    aseguradora: yup.string().required("El nombre es requerido"),
    suma_asegurada: yup.string().required("La suma asegurada es requerida"),
    vigencia: yup.mixed().required("La vigencia es requerida"),

    // // carro: yup.mixed().when("tipo", {
    // //     // is: { value: "vehiculos", label: "VEHICULOS" },
    // //     is: (field) => {
    // //         const tipo = field ? field.value : "";
    // //         return tipo === "vehiculos";
    // //     },
    // //     then: () => yup.mixed().required("El carro es requerido"),
    // // }),
    deducible_damage: yup.mixed().when("tipo", {
        is: (field) => {
            const tipo = field ? field.value : "";
            return tipo === "vehiculos";
        },
        then: () =>
            yup
                .number()
                .typeError("Debe de ser un numero")
                .required("Requerido"),
    }),
    deducible_robo: yup.mixed().when("tipo", {
        is: (field) => {
            const tipo = field ? field.value : "";
            return tipo === "vehiculos";
        },
        then: () =>
            yup
                .number()
                .typeError("Debe de ser un numero")
                .required("Requerido"),
    }),
});

export default function Crear() {
    const { control, reset, handleSubmit, watch, formState } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: {
            agente: null,
            cliente: null,
            // tipo: null,
            tipo: { value: "vehiculos", label: "VEHICULOS" },
            clasificacion: null,

            poliza: "",
            aseguradora: "",
            suma_asegurada: "",
            vigencia: "",

            deducible_damage: 0,
            deducible_robo: 0,
        },
    });

    let tipo = watch("tipo");
    tipo = tipo ? tipo.value : "";

    const onSubmit = (data) => {
        data.vigencia_inicio = data.vigencia.start;
        data.vigencia_fin = data.vigencia.end;
        data.agente = data.agente.value;
        data.cliente = data.cliente.value;
        data.tipo = data.tipo.value;
        data.clasificacion = data.clasificacion
            ? data.clasificacion.value
            : null;

        if (tipo === "vehiculos") {
            // Filter out blank data
            let vehiculosFormData = vehiculosData.items.filter(
                (item) => item.monto && item.vehiculo
            );

            // Re-structure the array
            vehiculosFormData = vehiculosFormData.map((item) => ({
                monto: item.monto,
                vehiculo: item.vehiculo.id,
            }));

            data.vehiculos = vehiculosFormData;
            // reset();
        }
        crearSeguro(data);
    };

    let vehiculosData = useListData({
        initialItems: [],
        getKey: (item) => item.key,
    });

    let columns = [
        {
            name: "Vehiculo",
            id: "vehiculo",
            isRowHeader: true,
            input: "select",
        },
        { name: "Monto", id: "monto", input: "number" },
        // { name: "Monto", id: "2", input: "select" },
        // { name: "Monto", id: "3", input: "select" },
    ];

    // let [filterValue, setFilterValue] = useState("");
    let { contains } = useFilter({ sensitivity: "base" });

    let comboBoxData = useListData({
        initialItems: [],

        getKey: (item) => item.id,
        filter: (item, filterText) => contains(item.label, filterText),
    });
    // let filteredItems = useMemo(
    //     () => comboBoxData.filter((item) => startsWith(item.label, filterValue)),
    //     [options, filterValue]
    // );

    async function fetchVehiculos() {
        let ruta = window.location.href;
        let id_emp = ruta.split("/");
        let empresa = id_emp[5];
        const params = { empresa };
        let res = await getVehiculos();
        comboBoxData.append(...res);
    }

    useEffect(() => {
        fetchVehiculos();
    }, []);
    // useEffect(() => {
    //     console.log("items", comboBoxData.items);
    // }, [comboBoxData]);
    // useEffect(() => {
    //     console.log("data", data.items);
    // }, [data]);

    return (
        <React.Fragment>
            <Banner>Agregar seguro</Banner>
            <form onSubmit={handleSubmit(onSubmit)}>
                <InputsContainer style={{ minWidth: "640px" }}>
                    <FicComboBox
                        componentStyle={{ flexGrow: "1", maxWidth: "400px" }}
                        control={control}
                        label="Agente"
                        name="agente"
                        loadOptions={getAgentes}
                    />
                    <FicComboBox
                        componentStyle={{ flexGrow: "1", maxWidth: "400px" }}
                        control={control}
                        label="Cliente"
                        name="cliente"
                        loadOptions={getClientes}
                    />
                    <FicComboBox
                        componentStyle={{
                            flexGrow: "1",
                            minWidth: "250px",
                            width: "auto",
                        }}
                        control={control}
                        label="Tipo"
                        name="tipo"
                        nonAsync
                        options={OPCIONES_TIPO}
                    />
                    {tipo === "incendio" && (
                        <FicComboBox
                            componentStyle={{
                                flexGrow: "1",
                                width: "auto",
                                minWidth: "250px",
                            }}
                            control={control}
                            label="Clasificacion"
                            name="clasificacion"
                            nonAsync
                            options={OPCIONES_CLASIFICACION}
                            disabled={tipo != "incendio"}
                        />
                    )}
                </InputsContainer>
                <br />
                <InputsContainer style={{ minWidth: "640px" }}>
                    <GridContainer>
                        <FicInput
                            control={control}
                            label="Poliza"
                            name="poliza"
                            inputMode="numeric"
                            style={{ width: "auto" }}
                        />
                        <FicInput
                            control={control}
                            label="Aseguradora"
                            name="aseguradora"
                        />
                        <FicNumber
                            control={control}
                            label="Suma asegurada"
                            name="suma_asegurada"
                        />
                        <FicDateRangePicker
                            control={control}
                            label="Vigencia"
                            name="vigencia"
                        />
                    </GridContainer>
                </InputsContainer>
                <br />
                {tipo === "vehiculos" && (
                    <DatosDelVehiculoContainer>
                        <SectionTitle>Vehiculos</SectionTitle>
                        {/* <div className="d-flex"> */}
                        <TableContainer>
                            {/* <FicComboBox
                                componentStyle={{
                                    flexGrow: "1",
                                    maxWidth: "400px",
                                }}
                                control={control}
                                label="Carro"
                                name="carro"
                                loadOptions={getVehiculos}
                            /> */}
                            <div className="--inputs-datos-vehiculo">
                                <FicNumber
                                    control={control}
                                    label="D. Daños"
                                    name="deducible_damage"
                                />
                                <FicNumber
                                    control={control}
                                    label="D. Robo"
                                    name="deducible_robo"
                                />
                            </div>
                            <EditableTable
                                style={{ alignSelf: "center" }}
                                data={vehiculosData}
                                columns={columns}
                                options={comboBoxData}
                            />
                        </TableContainer>
                        {/* </div> */}
                    </DatosDelVehiculoContainer>
                )}

                <GenBtn style={{ margin: "1rem 0" }} type="submit">
                    Guardar
                </GenBtn>
            </form>
        </React.Fragment>
    );
}
