import React from 'react';
import styled from 'styled-components';
import * as AiIcons from "react-icons/ai";

const Modal = ({children, estado, cambiarEstado, titulo, botonCerrar}) => {
    return(
        <React.Fragment>
            {estado &&
            <Overlay>
                <ContenedorModal>
                    <EncabezadoModal>
                        <h3>{titulo}</h3>
                        {botonCerrar &&
                            <BotonCerrar
                                onClick={() => cambiarEstado(false)}
                            >
                            <AiIcons.AiOutlineClose />
                         </BotonCerrar>
                        }
                    </EncabezadoModal>

                    {children}
                </ContenedorModal>
            </Overlay>  
            }
        </React.Fragment>
    );
}

export default Modal;

const Overlay = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0,0,0,.5);
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ContenedorModal = styled.div`
    width: 500px;
    min-height: 100px;
    background: #333;
    position: relative:
    border-radius: 5px;
    padding: 20px;
`;

const EncabezadoModal = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #E8E8E8;

    h3 {
        font-weight: 500;
        font-size: 16px;
        color: #1766DC;
    }
`;

const BotonCerrar = styled.button`
    top: 15px;
    right: 20px;
    left: 100px;
    width: 30px;
    height: 30px;
    border: none;
    background: #333;
    cursor: pointer;
    transition: .3s ease all;
    border-radius: 5px;
    color: #1766DC;

    &:hover {
        background: #000000
    }
`;