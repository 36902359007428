import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/contabilidad/centroCosto';
import CentroC from './CentroCostoList';


const ms2p = (state) => {
  return {
    ...state.centroCosto,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(CentroC);
