import React, { Component } from 'react';
import Grid from '../../Utils/GridProyecto';
import { standardActions } from '../../Utils/Grid/StandardActions';
import moment from 'moment';

class ConsultaFactura extends Component {

    render() {
        const { loader, detalle } = this.props;
        return (
            <React.Fragment>
                {detalle &&
                    <Grid
                        hover
                        striped
                        data={detalle}
                        loading={loader}
                        headerStyle={{ background: 'black' }}
                    >
                        <TableHeaderColumn
                            isKey
                            dataField="fecha_registro"
                            dataSort
                            dataFormat={(cell, row) => {
                                return moment(cell).format("DD/MM/YYYY");
                            }}
                            thStyle={
                                { color: 'white' }}
                            tdStyle={
                                { color: 'white' }}
                        >
                            Fecha
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="serie"
                            dataSort
                            thStyle={
                                { color: 'white' }}
                            tdStyle={
                                { color: 'white' }}
                        >
                            Serie
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="numero"
                            dataSort
                            thStyle={
                                { color: 'white' }}
                            tdStyle={
                                { color: 'white' }}
                        >
                            Numero
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            width="400"
                            dataField="proveedor"
                            dataSort
                            thStyle={
                                { color: 'white' }}
                            tdStyle={
                                { color: 'white' }}
                        >
                            Proveedor
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            headerAlign="center"
                            dataAlign='right'
                            dataField="total"
                            dataFormat={(cell, row) => {
                                return new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(Math.abs(cell));
                            }}
                            dataSort
                            thStyle={
                                { color: 'white' }}
                            tdStyle={
                                { color: 'white' }}
                        >
                            Total
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="descripcion"
                            dataSort
                            width="200"
                            thStyle={
                                { color: 'white' }}
                            tdStyle={
                                { color: 'white' }}
                        >
                            Descripción
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            thStyle={
                                { color: 'white' }}
                            dataField="id"
                            width="180"
                            dataAlign="center"
                            dataSort
                            dataFormat={standardActions({
                                editar: "asignar_proyecto",
                            })}

                        >
                            Acciones
                        </TableHeaderColumn>
                    </Grid>
                }
            </React.Fragment>
        );
    }
}

export default ConsultaFactura;
