import { handleActions } from "redux-actions";
import { api } from "api";
import { NotificationManager } from "react-notifications";
import { guardarNomenclatura } from "./nomenclatura";

const guardarBalanceDeSaldos = (data) => (dispatch) => {
    data.empresa = localStorage.getItem("fnzEmpresaId");
    data.mes = data.mes.value;
    data.year = data.year.value;
    return api
        .postAttachments(
            "finanzas/estados_financieros/balance_de_saldos",
            { ...data, balance_de_saldos: null },
            [{ name: "balance_de_saldos", file: data.balance_de_saldos }]
        )
        .then((response) => {
            NotificationManager.success(
                "Balance de saldos guardado",
                "Exito",
                3000,
                null
            );
            return false;
        })
        .catch((error) => {
            if (error.cuentas_faltantes) {
                return error.cuentas_faltantes;
            }
            NotificationManager.error(
                "Ocurrio un error al subir el balance de saldos",
                "Error",
                3000,
                null
            );
        });
};

export const obtenerResumen = (data) => (dispatch) => {
    data.empresa = localStorage.getItem("fnzEmpresaId");
    data.year = data.year.value;
    data.mes_inicial = data.mes_inicial.value;
    data.mes_final = data.mes_final.value;
    return api
        .get("/finanzas/estados_financieros/balance_de_saldos/resumen", data)
        .then((response) => {
            const excelBinaryData = atob(response.excel);
            const blob = new Blob(
                [
                    new Uint8Array(excelBinaryData.length).map((_, i) =>
                        excelBinaryData.charCodeAt(i)
                    ),
                ],
                {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                }
            );
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = "Balance de saldos_resumen.xlsx";
            document.body.appendChild(a);
            let resumen = JSON.parse(response.resumen);
            let excelLinkElement = a;
            return { resumen, excelLinkElement };
        })
        .catch((error) => {
            NotificationManager.error(error.body.msj, "Error", 2000);
        });
};

export const obtenerCierresParciales = (data) => (dispatch) => {
    data.empresa = localStorage.getItem("fnzEmpresaId");
    data.year = data.year.value;
    data.mes_inicial = data.mes_inicial.value;
    data.mes_final = data.mes_final.value;
    return api
        .get("/finanzas/estados_financieros/balance_de_saldos/cierres_parciales", data)
        .then((response) => {
            const excelBinaryData = atob(response.excel);
            const blob = new Blob(
                [
                    new Uint8Array(excelBinaryData.length).map((_, i) =>
                        excelBinaryData.charCodeAt(i)
                    ),
                ],
                {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                }
            );
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = "Cierres parciales";
            document.body.appendChild(a);
            let resumen = JSON.parse(response.resumen);
            let excelLinkElement = a;
            return { resumen, excelLinkElement };
        })
        .catch((error) => {
            NotificationManager.error(
                "Ocurrió un error al listar las empresas",
                "Error",
                2000
            );
        });
};

export const listarEmpresasAgregadas = (search) => (dispatch) => {
    return api
        .get("/finanzas/empresas", { search })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            NotificationManager.error(
                "Ocurrió un error al listar las empresas",
                "Error",
                2000
            );
        });
};

const chequearMesesIngresados = (year) => (dispatch) => {
    let data = {};
    data.empresa = localStorage.getItem("fnzEmpresaId");
    year ? (data.year = year) : (data.year = "0");

    return api
        .get("/finanzas/estados_financieros/balance_de_saldos/meses_ingresados/", data)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            NotificationManager.error(
                "Ocurrió un error al chequear los meses ingresados",
                "Error",
                2000
            );
        });
};
export const actions = {
    guardarBalanceDeSaldos,
    obtenerResumen,
    listarEmpresasAgregadas,
    guardarNomenclatura,
    obtenerCierresParciales,
    chequearMesesIngresados,
};

export const reducers = {};

export const initialState = {};

export default handleActions(reducers, initialState);
