import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import DetalleDatos from './DetalleDatosFamiliares';
import DetalleDatosVer from './DetalleDatosFamiliaVer';
import DetalleDatosMod from './DetalleDatosFamiliaMod';
import styled from 'styled-components';

import {
    renderField,
    SelectField,
    renderDayPickerB,
    renderFieldCheck
} from "../../Utils/renderField/renderField";

function ColaboradorDatosFamiliaresForm(props) {
    const { crear, detalleFa, eliminarLineaFamilia,
        disabled, editar, detalle, agregarLinea,
        agregarNuevaLineaFamilia, eliminarLinea } = props;

    let autoFocus = true;
    const [nietos, setNietos] = useState(false);

    const onCheckboxClickedN = (idx, isChecked) => {
        setNietos(isChecked);
    }

    const genero = [
        { label: "HOMBRE", value: 'H' },
        { label: "MUJER", value: 'M' },
    ];
    return (
        <div>
            {crear === true &&
                <div>
                    <div className='row mb-2'>
                        <div className='col-6'>
                            <Field
                                autoFocus={autoFocus}
                                name="conyuge"
                                placeholder="Cónyuge"
                                className="form-control"
                                component={renderField}
                                disabled={disabled}
                                msj="Cónyuge"
                            />
                        </div>
                        <div className='col-3 mt-3'>
                            <Field
                                name="nietos"
                                checked={nietos}
                                label="Tiene nietos"
                                component={renderFieldCheck}
                                onChange={onCheckboxClickedN}
                            />
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <strong className="d-block mb-2 title bg-secondary mt-2">Hijos</strong>
                        <div className='col-6'>
                            <Field
                                autoFocus={autoFocus}
                                name="nombre_hijo"
                                placeholder="Nombre"
                                className="form-control"
                                component={renderField}
                                disabled={disabled}
                                msj="Nombre"
                            />
                        </div>
                        <div className='col-3'>
                            <div className="form-floating">
                                <Field
                                    name="genero_hijo"
                                    options={genero}
                                    crear={crear}
                                    dato={0}
                                    component={SelectField}
                                    className="form-select"
                                    disabled={disabled}
                                    msj="Género"
                                />
                            </div>
                        </div>
                        <div className='col-3'>
                            <Field
                                name="fecha_nacimiento_hijo"
                                component={renderDayPickerB}
                                msj="Fecha nacimiento"
                                disabled={disabled}
                            />
                        </div>
                    </div>
                    <Contenido>
                        <button
                            className='btn mr-2 mb-3 btn-primary'
                            onClick={() => agregarLinea()}
                        >
                            Agregar
                        </button>
                    </Contenido>
                    <div >
                        <DetalleDatos
                            detalle={detalle}
                            eliminarLinea={eliminarLinea}
                        />
                    </div>
                </div>
            }
            {editar === true &&
                <div>
                    <div className='row mb-2'>
                        <div className='col-6'>
                            <Field
                                autoFocus={autoFocus}
                                name="conyuge"
                                placeholder="Cónyuge"
                                className="form-control"
                                component={renderField}
                                disabled={disabled}
                                msj="Cónyuge"
                            />
                        </div>
                        <div className='col-3 mt-3'>
                            <Field
                                name="nietos"
                                checked={nietos}
                                label="Tiene nietos"
                                component={renderFieldCheck}
                                onChange={onCheckboxClickedN}
                            />
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <strong className="d-block mb-2 title bg-secondary mt-2">Hijos</strong>
                        <div className='col-6'>
                            <Field
                                autoFocus={autoFocus}
                                name="nombre_hijo"
                                placeholder="Nombre"
                                className="form-control"
                                component={renderField}
                                disabled={disabled}
                                msj="Nombre"
                            />
                        </div>
                        <div className='col-3'>
                            <div className="form-floating">
                                <Field
                                    name="genero_hijo"
                                    options={genero}
                                    crear={crear}
                                    dato={0}
                                    component={SelectField}
                                    className="form-select"
                                    disabled={disabled}
                                    msj="Género"
                                />
                            </div>
                        </div>
                        <div className='col-3'>
                            <Field
                                name="fecha_nacimiento_hijo"
                                component={renderDayPickerB}
                                msj="Fecha nacimiento"
                                disabled={disabled}
                            />
                        </div>
                    </div>
                    <Contenido>
                        <button
                            className='btn mr-2 mb-3 btn-primary'
                            onClick={() => agregarNuevaLineaFamilia()}
                        >
                            Agregar
                        </button>
                    </Contenido>
                    <div >
                        <DetalleDatosMod
                            detalle={detalleFa}
                            eliminarLinea={eliminarLineaFamilia}
                        />
                    </div>
                </div>
            }
            {(editar === false && crear === false ) &&
                <div>
                    <div className='row mb-2'>
                        <div className='col-6'>
                            <Field
                                autoFocus={autoFocus}
                                name="conyuge"
                                placeholder="Cónyuge"
                                className="form-control"
                                component={renderField}
                                disabled={disabled}
                                msj="Cónyuge"
                            />
                        </div>
                        <div className='col-3 mt-3'>
                            <Field
                                name="nietos"
                                checked={nietos}
                                disabled={true}
                                label="Tiene nietos"
                                component={renderFieldCheck}
                                onChange={onCheckboxClickedN}
                            />
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <strong className="d-block mb-2 title bg-secondary mt-2">Hijos</strong>
                        <DetalleDatosVer
                            detalle={detalleFa}
                        />
                    </div>
                </div>
            }
        </div>
    );
}

ColaboradorDatosFamiliaresForm.propTypes = {
    handleSubmit: PropTypes.func,
    crear: PropTypes.bool,
    lectura: PropTypes.object,
    modulo: PropTypes.object,
    listaDepartamento: PropTypes.object,
    listaPuesto: PropTypes.object,
};

const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;

export default reduxForm({
    form: 'FormColaborador',
})(ColaboradorDatosFamiliaresForm);

