import React, { Component } from 'react';
import Grid from '../../Utils/GridEnvioProduccion';

class DetalleIngresoVer extends Component{
    
    render(){
        const { loader, detalle } = this.props;
        return(
            <React.Fragment>
                {detalle &&
                <Grid 
                    hover 
                    striped 
                    data={detalle} 
                    loading={loader}
                    footer
                    headerStyle={ { background: 'black' } } 
                >
                <TableHeaderColumn
                    isKey
                    dataField="tCantidad"
                    dataAlign='right'
                    width="200"
                    headerAlign="center"
                    dataSort
                    dataFormat={(cell, row)=>{
                        return new Intl.NumberFormat("en-US",{minimumFractionDigits: 6}).format(cell);
                    }}
                    thStyle={
                        {color: 'white'}}
                    tdStyle={
                        {color: 'white'}}
                >
                    Cantidad
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="sku"
                    width="200"
                    dataSort
                    thStyle={
                        {color: 'white'}}
                    tdStyle={
                        {color: 'white'}}
                    >
                    SKU
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="productoN"
                    dataSort
                    thStyle={
                        {color: 'white'}}
                    tdStyle={
                        {color: 'white'}}
                    >
                    Producto
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="tUnidad"
                    dataAlign='right'
                    width="200"
                    headerAlign="center"
                    dataSort
                    dataFormat={(cell, row)=>{
                        return new Intl.NumberFormat("en-US",{minimumFractionDigits: 0}).format(cell);
                    }}
                    thStyle={
                        {color: 'white'}}
                    tdStyle={
                        {color: 'white'}}
                >
                    Unidad
                </TableHeaderColumn>
                <TableHeaderColumn
                        dataField="tInsumo"
                        dataAlign='right'
                        width="200"
                        headerAlign="center"
                        dataSort
                        dataFormat={(cell, row)=>{
                            return new Intl.NumberFormat("en-US",{minimumFractionDigits: 0}).format(cell);
                        }}
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Cajas
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="precio"
                    dataAlign='right'
                    width="200"
                    headerAlign="center"
                    dataSort
                    dataFormat={(cell, row)=>{
                        return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(cell);
                    }}
                    thStyle={
                        {color: 'white'}}
                    tdStyle={
                        {color: 'white'}}
                >
                    Precio
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="total"
                    dataAlign='right'
                    width="200"
                    headerAlign="center"
                    dataSort
                    dataFormat={(cell, row)=>{
                        return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(cell);
                    }}
                    thStyle={
                        {color: 'white'}}
                    tdStyle={
                        {color: 'white'}}
                >
                    Total
                </TableHeaderColumn>
                </Grid>
                }
            </React.Fragment>
        );
    }
}

export default DetalleIngresoVer;
