import React, { Component } from "react";
import Grid from "../../Utils/GridDevolucionPRD";

class DetalleInventarioVerList extends Component {
    render() {
        const { loader, detalle } = this.props;
        return (
            <React.Fragment>
                {detalle && (
                    <Grid
                        hover
                        striped
                        data={detalle}
                        loading={loader}
                        footer
                        headerStyle={{ background: "black" }}
                    >
                        <TableHeaderColumn
                            isKey
                            dataField="bodega"
                            width="180"
                            headerAlign="center"
                            dataSort
                            thStyle={{ color: "white" }}
                            tdStyle={{ color: "white" }}
                        >
                            Bodega
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="sku"
                            width="180"
                            headerAlign="center"
                            dataSort
                            thStyle={{ color: "white" }}
                            tdStyle={{ color: "white" }}
                        >
                            SKU
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="productoN"
                            width="200"
                            dataSort
                            thStyle={{ color: "white" }}
                            tdStyle={{ color: "white" }}
                        >
                            Producto
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="cantidad"
                            dataAlign="right"
                            width="200"
                            headerAlign="center"
                            dataSort
                            dataFormat={(cell, row) => {
                                return new Intl.NumberFormat("en-US", {
                                    minimumFractionDigits: 6,
                                }).format(cell);
                            }}
                            thStyle={{ color: "white" }}
                            tdStyle={{ color: "white" }}
                        >
                            Cantidad
                        </TableHeaderColumn>
                    </Grid>
                )}
            </React.Fragment>
        );
    }
}

export default DetalleInventarioVerList;
