import React, { Component } from 'react';
import Formulario from './FormSeleccionarPais';
import LoadMask from "../../Utils/LoadMask/LoadMask";

class SeleccionarPais extends Component{

    render(){
        const { listarPaises, loader, generarCorte } = this.props;
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    listarPaises = {listarPaises}
                    generarCorte = {generarCorte}
                />
            </LoadMask>
        );
    } 
}

export default SeleccionarPais;