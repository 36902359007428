import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import NavbarModulo from '../../components/NavbarModulo';
import NavbarModuloLF from '../../components/NavbarModuloFage';
import {
    renderDayPickerB,
    SelectField,
    renderFieldCheckB,
    renderFieldCheck,
    renderField
} from "../../Utils/renderField/renderField";
const valEmp = __CONFIG__.empresa;

function PeriodosForm(props) {
    const { registroPeriodo, crear, modulo, frec, periodoN, numeroPer,
        periodoD, lectura, modificarPeriodo } = props;
    
    const editar = window.location.href.includes('editar');
    let titulo = editar ? 'Modificar Periodo' : 'Crear Periodo';
    let disabled = false;
    if (crear === false && editar === false) {
        disabled = true;
        titulo = 'Ver Periodo'
    }
    if (editar === true) {
        disabled = false;
    }
    let ruta = window.location.href;
    let id_emp = ruta.split('/');

    const [activo, setActivo] = useState(false);
    
    const onCheckboxClicked = (idx, isChecked) => {
        setActivo(isChecked);
    }

    return (
        <div>
            {valEmp === 'fage' &&
                <NavbarModuloLF
                    periodoD={periodoD}
                    modulo={modulo}
                    titulo={titulo}
                />
            }
            {valEmp === '' &&
                <NavbarModulo
                    periodoD={periodoD}
                    modulo={modulo}
                    titulo={titulo}
                />
            }
            <div>
                <br />
                <div className="mb-4 card card-small text-white bg-dark mb-3">
                    <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                        <div className="d-flex flex-column flex-1 mx-4">
                            {crear === true &&
                                <div className='mb-2 row'>
                                    <div className='col-3'>
                                        <div className="form-floating">
                                            <input
                                                placeholder="Periodo"
                                                type="text"
                                                autocomplete="off"
                                                name="periodo"
                                                className="form-control"
                                                value={periodoN}
                                                disabled={true}
                                            />
                                            <label className='form-label' htmlFor="periodo">
                                                Periodo
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-3'>
                                        <div className="form-floating">
                                            <input
                                                placeholder="Numero"
                                                type="text"
                                                autocomplete="off"
                                                name="numero"
                                                className="form-control"
                                                value={numeroPer}
                                                disabled={true}
                                            />
                                            <label className='form-label' htmlFor="numero">
                                                Numero
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-3'>
                                        <div className="form-floating">
                                            <Field
                                                name="frecuencia"
                                                options={frec}
                                                crear={crear}
                                                dato={crear === true ? 0 : 0}
                                                component={SelectField}
                                                className="form-select"
                                                msj="Frecuencia"
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                            {crear === false && editar === false &&
                                <div className='row mb-2'>
                                    <div className='col-3'>
                                        <Field
                                            name="periodo_nomina"
                                            placeholder="Periodo"
                                            className="form-control"
                                            component={renderField}
                                            disabled={true}
                                            msj="Periodo"
                                        />
                                    </div>
                                    <div className='col-3'>
                                        <Field
                                            name="numero"
                                            placeholder="Numero"
                                            className="form-control"
                                            component={renderField}
                                            disabled={true}
                                            msj="Numero"
                                        />
                                    </div>
                                    <div className='col-3'>
                                        <div className="form-floating">
                                            <Field
                                                name="frecuencia"
                                                options={frec}
                                                crear={crear}
                                                dato={lectura.frecuencia_pago}
                                                component={SelectField}
                                                className="form-select"
                                                disabled={true}
                                                msj="Frecuencia"
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                            {editar === true &&
                                <div className='row mb-2'>
                                    <div className='col-3'>
                                        <Field
                                            name="periodo_nomina"
                                            disabled={true}
                                            placeholder="Periodo"
                                            className="form-control"
                                            component={renderField}
                                            msj="Periodo"
                                        />
                                    </div>
                                    <div className='col-3'>
                                        <Field
                                            name="numero"
                                            disabled={true}
                                            placeholder="Numero"
                                            className="form-control"
                                            component={renderField}
                                            msj="Numero"
                                        />
                                    </div>
                                    <div className='col-3'>
                                        <div className="form-floating">
                                            <Field
                                                name="frecuencia"
                                                options={frec}
                                                crear={crear}
                                                dato={lectura.frecuencia_pago}
                                                component={SelectField}
                                                className="form-select"
                                                msj="Frecuencia"
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className='row mb-2'>
                                <div className='col-3'>
                                    <Field
                                        name="fecha_inicial"
                                        placeholder="Fecha Inicial"
                                        component={renderDayPickerB}
                                        disabled={disabled}
                                        msj="Fecha Inicial"
                                    />
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="fecha_fin"
                                        placeholder="Fecha Final"
                                        component={renderDayPickerB}
                                        className="form-control"
                                        disabled={disabled}
                                        msj="Fecha Final"
                                    />
                                </div>
                                <div className='col-6'>
                                    <div className='col-4 mt-3'>
                                        {crear === true ? (
                                        <Field
                                            name="bonificacion_comision"
                                            label="Bonificación y/o comisión"
                                            checked={activo}
                                            onChange={onCheckboxClicked}
                                            component={renderFieldCheckB}
                                        />
                                        ) :
                                        (crear === false && editar === false ? (
                                            <Field
                                                disabled={disabled}
                                                name="bonficacion_comision"
                                                label="Bonificación y/o comisión"
                                                component={renderFieldCheck}
                                            />
                                        ):
                                            <Field
                                                name="bonficacion_comision"
                                                label="Bonificación y/o comisión"
                                                component={renderFieldCheck}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex flex-row mt-3'>
                                {(crear === true) &&
                                    <button
                                        className={`btn mr-2 mb-3 ${editar ? 'btn-primary' : 'btn-primary'}`}
                                        type='submit'
                                        onClick={() => registroPeriodo(periodoN, numeroPer)}
                                    >
                                        Guardar
                                    </button>
                                }
                                {(editar === true) &&
                                    <button
                                        className={`btn mr-2 mb-3 ${editar ? 'btn-primary' : 'btn-primary'}`}
                                        type='submit'
                                        onClick={() => modificarPeriodo()}
                                    >
                                        Modificar
                                    </button>
                                }
                                {valEmp === 'fage' && crear === true &&
                                    <a
                                        href={`/#/lfg/nomina/${id_emp[6]}/periodo_nomina/${id_emp[9]}`}
                                        className='btn btn-secondary mb-3'
                                    >
                                        Regresar
                                    </a>
                                }
                                {valEmp === '' && crear === true &&
                                    <a
                                        href={`/#/nomina/${id_emp[5]}/periodo_nomina/${id_emp[8]}`}
                                        className='btn btn-secondary mb-3'
                                    >
                                        Regresar
                                    </a>
                                }
                                {valEmp === 'fage' && crear === false &&
                                    <a
                                        href={`/#/lfg/nomina/${id_emp[6]}/periodo_nomina/${id_emp[8]}`}
                                        className='btn btn-secondary mb-3'
                                    >
                                        Regresar
                                    </a>
                                }
                                {valEmp === '' && crear === false &&
                                    <a
                                        href={`/#/nomina/${id_emp[5]}/periodo_nomina/${id_emp[7]}`}
                                        className='btn btn-secondary mb-3'
                                    >
                                        Regresar
                                    </a>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default reduxForm({
    form: 'FormPeriodosNomina',
})(PeriodosForm);
