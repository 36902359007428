import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import Card from './EstableCard';
//css
//import "./style.css";

const renderEstablecimiento = (empresa) => {
    return empresa.map((value, index) =>
        <Grid item sm={4} xs={12}>
            <Card 
                key={index} 
                logo={value.logo}
                name = {value.nombre_comercial}
                
            />
        </Grid>  
    );
}
class EstablecimientoList extends Component {
  render() {
    const { match, empresa } = this.props;
    const id = match.params.id;
    let resultado = empresa.find( item => parseInt(item.id) === parseInt(id) );
    return (
        <div className='formato'>
            <Grid container spacing={3}>
                {renderEstablecimiento(resultado.empresa_sucursal)}
            </Grid>
        </div>
     
    );
  }
}

export default EstablecimientoList;