import React, { Component } from 'react';
import Formulario from './ConsumoForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";

class Consumo extends Component{
    state = {
        crear: true,
    }

    componentDidMount = () => {
        const { match, leer, getModulo,leerPeriodoD, vaciar,
        leerDetalleProductos, parametrosEmpresa, listarSeries } = this.props;
        const id = match.params.id;
        if (id){
            this.setState({crear: false});
            leer(id);
        }
        let ruta = window.location.href;
        let periodo = ruta.split('/');
        leerPeriodoD(periodo[5], periodo[7]);
        getModulo(periodo[5], periodo[4]);
        parametrosEmpresa();
        listarSeries();
        vaciar();
        leerDetalleProductos('00000000-0000-0000-0000-000000000000');
        localStorage.removeItem('id_detalle_consumo_prd');
    }
    
    render(){
        const { loader, modulo, periodoD, datos, listarBodegas, pro, registroConsumo,
            detalleProducto, registroProductoInventario, detalleCon,
            eliminarLineaProducto, leerProductos, listarSeries, nuevaLineaInventario,
            numeroSerie, numeroS, listarFases, agregarLinea, listarPilotos, modificarConsumo } = this.props;
        const { crear } = this.state;
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    crear = {crear}
                    modulo = {modulo}
                    periodoD = {periodoD}
                    datos = {datos}
                    detalleCon = {detalleCon}
                    registroProductoInventario = {registroProductoInventario}
                    eliminarLineaProducto = {eliminarLineaProducto}
                    registroConsumo = {registroConsumo}
                    modificarConsumo = {modificarConsumo}
                    listarBodegas = {listarBodegas}
                    leerProductos = {leerProductos}
                    detalleProducto = {detalleProducto}
                    pro = {pro}
                    listarSeries={listarSeries}
                    numeroSerie = {numeroSerie}
                    numeroS = {numeroS}
                    listarFases = {listarFases}
                    agregarLinea = {agregarLinea}
                    listarPilotos = {listarPilotos}
                    nuevaLineaInventario = {nuevaLineaInventario}
                />
            </LoadMask>
        );
    }
}

export default Consumo
