import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/facturacion/polizaParqueo';
import PolizaList from './PolizaList';


const ms2p = (state) => {
  return {
    ...state.fac_generar_poliza,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(PolizaList);
