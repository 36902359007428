import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import NavbarModulo from '../../components/NavbarModulo';
import DetalleEnvio from './DetalleEnvio';
import DetalleEnvioVer from './DetalleEnvioVer';
import styled from 'styled-components';

import {
    SelectField,
    AsyncSelectField,
    renderDayPickerB,
    AsyncSelectFieldIn,
    renderNumber,
    renderField
} from "../../Utils/renderField/renderField";

function ConsolidacionForm(props) {
    const {
        crear, 
        modulo, 
        listarClientes, 
        periodoD, 
        listarEnvios,
        envios,
        numeroDev,
        registroConsolidacion,
        agregarLinea,
        detalleEnv,
        eliminarLineaEnvio,
        detalleCEnv,
        validarEnvio
    } = props;

    const editar = window.location.href.includes('editar');
    let titulo = editar ? 'Modificar consolidacion de envios' : 'Crear consolidacion de envios';
    let disabled = false;
    if (crear === false && editar === false) {
        disabled = true;
        titulo = 'Ver consolidacion de envios'
    }
    let autoFocus = true;
    let ruta = window.location.href;
    let id_emp = ruta.split('/');

    const [cajas, setCajas] = useState(0);
    const [libras, setLibras] = useState(0);
    const [producto, setProducto] = useState(0);

    const handleOnChange = value => {
        listarEnvios(value.value);
    };

    const handleOnChangeEnvio = value => {
        let datos = value.value.split('#');
        let validar = validarEnvio(datos[0]);
        validar.then(d => {
            if (d === false){
                setCajas(datos[1]);
                setLibras(datos[2]);
                setProducto(datos[6])
            }
        });
    };

    const estilo = {
        overflow: "auto",
        height: "300px",

    };

    const status = [
        {
            value: 'A',
            label: 'ABIERTO'
        },
        {
            value: 'C',
            label: 'CERRADO'
        },
    ];

    return (
        <div>
            <NavbarModulo
                periodoD = {periodoD}
                modulo={modulo}
                titulo={titulo}
            />
            <br />
            <div className="mb-4 card card-small text-white bg-dark mb-2">
                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                    <div className="d-flex flex-column flex-1 mx-4">
                        {crear === true &&
                            <div className='row mb-2'>
                                <div className='col-2'>
                                    <Field
                                        autoFocus={autoFocus}
                                        name="fechaRegistro"
                                        component={renderDayPickerB}
                                        msj="Fecha Registro"
                                    />
                                </div>
                                <div className='col-2'>
                                    <div className="form-floating">
                                        <input
                                            placeholder="Serie"
                                            type="text"
                                            autocomplete="off"
                                            name="serie"
                                            className="form-control"
                                            value={'CE'}
                                            disabled={true}
                                        />
                                        <label className='form-label' htmlFor="serie">
                                            Serie
                                        </label>
                                    </div>
                                </div>
                                <div className='col-2'>
                                    <div className="form-floating">
                                        <input
                                            placeholder="Numero"
                                            type="number"
                                            autocomplete="off"
                                            name="numeroCheque"
                                            className="form-control"
                                            value={numeroDev}
                                            disabled={true}
                                        />
                                        <label className='form-label' htmlFor="numero">
                                            Numero
                                        </label>
                                    </div>
                                </div>
                                <div className='col-3'>
                                    <div className="form-floating">
                                        <input
                                            placeholder="Moneda"
                                            type="text"
                                            autocomplete="off"
                                            name="moneda"
                                            className="form-control"
                                            value={'Dolares'}
                                            disabled={true}
                                        />
                                        <label className='form-label' htmlFor="numero">
                                            Moneda
                                        </label>
                                    </div>
                                </div>
                            </div>
                        }
                        {crear === false && editar == false &&
                            <div className='row mb-2'>
                                <div className='col-2'>
                                    <Field
                                        name="fechaRegistro"
                                        component={renderDayPickerB}
                                        disabled={true}
                                        msj="Fecha Registro"
                                    />
                                </div>
                                <div className='col-2'>
                                    <Field
                                        name="serie"
                                        component={renderField}
                                        disabled={true}
                                        msj="Fecha Registro"
                                    />
                                </div>
                                <div className='col-2'>
                                    <Field
                                        name="numero"
                                        component={renderField}
                                        disabled={true}
                                        msj="Fecha Registro"
                                    />
                                </div>
                                <div className='col-3'>
                                    <div className="form-floating">
                                        <input
                                            placeholder="Moneda"
                                            type="text"
                                            autocomplete="off"
                                            name="moneda"
                                            className="form-control"
                                            value={'Dolares'}
                                            disabled={true}
                                        />
                                        <label className='form-label' htmlFor="numero">
                                            Moneda
                                        </label>
                                    </div>
                                </div>
                            </div>
                        }
                        {crear === true &&
                            <div className='row'>
                                <div className='col-10'>
                                    <Field
                                        name="cliente"
                                        placeholder="Cliente..."
                                        className="form-control-find"
                                        component={AsyncSelectField}
                                        loadOptions={listarClientes}
                                        onChange={handleOnChange}
                                        msj="Cliente"
                                    />
                                </div>
                            </div>
                        }
                        {crear === false && editar == false &&
                            <div className='row mb-2'>
                                <div className='col-12'>
                                    <Field
                                        name="clienteN"
                                        component={renderField}
                                        disabled={true}
                                        msj="Cliente"
                                    />
                                </div>
                            </div>
                        }
                        {crear === true &&
                        <div>
                            <div class='row'>
                                <h5 className="p-2 bg-secondary title mt-1">Detalle consolidación</h5>
                                <div className='col-3'>
                                    <Field
                                        name="envio"
                                        placeholder="Envio de producción..."
                                        className="form-control-find"
                                        loadOptions={envios}
                                        component={AsyncSelectFieldIn}
                                        onChange={handleOnChangeEnvio}
                                        msj="Envios"
                                    />
                                </div>
                                <div className='col-2'>
                                    <div className="form-floating">
                                        <input
                                            placeholder="Cajas"
                                            type="number"
                                            autocomplete="off"
                                            name="numeroCheque"
                                            className="form-control"
                                            value={cajas}
                                            disabled={true}
                                        />
                                        <label className='form-label' htmlFor="numero">
                                            Cajas
                                        </label>
                                    </div>
                                </div>
                                <div className='col-2'>
                                    <div className="form-floating"> 
                                        <input
                                            placeholder="Libras"
                                            type="number"
                                            autocomplete="off"
                                            name="numeroCheque"
                                            className="form-control"
                                            value={libras}
                                            disabled={true}
                                        />
                                        <label className='form-label' htmlFor="numero">
                                            Libras
                                        </label>
                                    </div>
                                </div>
                                <div className='col-2'>
                                    <div className="form-floating">
                                        <Field
                                            name="grape15"
                                            placeholder="Grape 15*1"
                                            className="form-control"
                                            component={renderNumber}
                                            msj="Grape 15*1"
                                        />
                                    </div>
                                </div>
                                <div className='col-2'>
                                    <div className="form-floating">
                                        <Field
                                            name="grape12"
                                            placeholder="Grape 12*15"
                                            className="form-control"
                                            component={renderNumber}
                                            msj="Grape 12*15"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class='row mt-2'>
                                <div className='col-2'>
                                    <div className="form-floating">
                                        <Field
                                            name="status"
                                            options={status}
                                            dato={1}
                                            component={SelectField}
                                            className="form-select"
                                            msj="Status"
                                        />
                                    </div>
                                </div>
                                <div className='col-10'>
                                    <div className="form-floating"> 
                                        <input
                                            placeholder="Producto"
                                            type="text"
                                            autocomplete="off"
                                            name="producto"
                                            className="form-control"
                                            value={producto}
                                            disabled={true}
                                        />
                                        <label className='form-label' htmlFor="producto">
                                            Producto
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                        {(crear === true) &&
                            <Contenido>
                                <button
                                    className='btn mr-2 mb-3 btn-primary mt-3'
                                    onClick={() => {
                                        agregarLinea(periodoD);
                                    }}
                                >
                                    Agregar envio
                                </button>
                            </Contenido>
                        }
                        {(crear === true) &&
                            <div style={estilo} className='mt-3'>
                                <DetalleEnvio
                                    detalle={detalleEnv}
                                    eliminarEnvio = {eliminarLineaEnvio}
                                />
                            </div>
                        }
                        {crear === false && editar == false &&
                            <div style={estilo} className='mt-3'>
                                <h5 className="p-2 bg-secondary title mt-1">Detalle consolidación</h5>
                                <DetalleEnvioVer
                                    detalle={detalleCEnv}
                                />
                            </div>
                        }
                        <div className='d-flex flex-row mt-3'>
                            {disabled == false &&
                                <button
                                    className='btn mr-2 mb-3 btn-primary'
                                    onClick={() => { registroConsolidacion(periodoD, numeroDev) }}
                                >
                                    {editar ? 'Modificar' : 'Guardar'}
                                </button>
                            }
                            <a
                                href={`/#/produccion/${id_emp[5]}/consolidacion_envio/${id_emp[7]}`}
                                className='btn btn-secondary mb-3'
                            >
                                Regresar
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

ConsolidacionForm.propTypes = {
    crear: PropTypes.bool,
    lectura: PropTypes.object,
    modulo: PropTypes.object,
    lectura: PropTypes.object,
};


export default reduxForm({
    form: 'FormConsolidacionEnvio',
})(ConsolidacionForm);

const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;