import React, { useState } from 'react';
import Modal from '../components/Modal';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LoadMask from "../Utils/LoadMask/LoadMask";
import NavbarModulo from '../components/NavbarModulo';

import {
    renderDayPickerB,
    AsyncSelectField
} from "../Utils/renderField/renderField";

function InformesForm(props) {
    const {
        modulo,
        loader,
        listarProyectos,
        reporteDetalleGastosProyecto,
    } = props;

    const [estadoDetalleGastosProyecto, cambiarEstadoDetalleGastosProyecto] = useState(false);
    
    let autoFocus = true;
    let titulo = 'Informes'
    let ruta = window.location.href;
    let id_emp = ruta.split('/');

    return (
        <div>
            <NavbarModulo
                modulo={modulo}
                titulo={titulo}
            />
        
            <div className="container">
                <br/>
                <h4 className='mt-1' style={{ color: "white" }}>Informes</h4>
                <div className="row">
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <a class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => cambiarEstadoDetalleGastosProyecto(!estadoDetalleGastosProyecto)}
                                data-bs-toggle="list"
                                role="tab"
                                href={`/#/inmobiliaria/${id_emp[5]}/informes`}
                                aria-controls="list-home">Detalle de gastos por proyecto</a>
                        </div>
                    </div>
                </div>
                <Modal
                    estado={estadoDetalleGastosProyecto} //Gastos por proyecto
                    cambiarEstado={cambiarEstadoDetalleGastosProyecto}
                    titulo="Detalle de gastos por proyecto"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className='row'>
                                <div className='col-6 mb-2'>
                                    <Field
                                        autoFocus={autoFocus}
                                        name="fechaInicial"
                                        component={renderDayPickerB}
                                        msj="Fecha del"
                                    />
                                </div>
                                <div className='col-6 mb-2'>
                                    <Field
                                        name="fechaFinal"
                                        component={renderDayPickerB}
                                        msj="Fecha al"
                                    />
                                </div>
                            </div>
                            
                            <div className='col-12 mb-2'>
                                <Field
                                    name="proyecto"
                                    placeholder="Proyecto..."
                                    className="form-control-find"
                                    component={AsyncSelectField}
                                    loadOptions={listarProyectos}
                                    msj="Producto"
                                />
                            </div>
                        </Contenido>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div className='row'>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={reporteDetalleGastosProyecto}
                                    >
                                    Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn btn-secondary'
                                        onClick={() => cambiarEstadoDetalleGastosProyecto(false)}
                                    >
                                    Cancelar
                                    </button>
                                </div>
                            </Contenido>
                        </LoadMask>
                </Modal>  
            </div>
        </div>
    );
}

InformesForm.propTypes = {
    loader: PropTypes.bool,
    modulo: PropTypes.object,
    listarProyectos: PropTypes.func,
    reporteDetalleGastosProyecto: PropTypes.func,
};

export default reduxForm({
    form: 'FormInformesI',

})(InformesForm);


const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;
