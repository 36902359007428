import React, { Component } from 'react';
import { Link } from 'react-router-dom'
const valEmp = __CONFIG__.empresa;

const estilo = {
    fontSize: "14px",
};
class ConfirmacionEnvio extends Component{
    render() {
        return(
            <div className="blue-gradient-bg">
                <div className="d-flex flex-column align-items-center pt-3 bienvenida title">
                    <img className="mt-5 imageReg"
                        src={require('assets/img/SIFW-LOGO-Red.png')}
                        alt="Logo" />
                </div>
                <br />
                <div className="login-wrapper">
                    <div className="card2 card border-0 px-4 py-5 bg-dark col-lg-6 col-md-4 col-11">
                        <h4 className="text-center pv mb-4 title">Cambiar Contraseña</h4>
                        <h5 className="text-justify title">Se ha enviado un correo electrónico para realizar el cambio de contraseña,
                            verifique su correo electrónico y siga las instrucciones proporcionadas.
                        </h5>
                        <br/>
                        {valEmp === 'fage' &&
                            <Link to="/lfg" style={estilo}
                                className="btn btn-secondary btn-sm mr-2 align-self-center"
                            >
                                Continuar
                            </Link>
                        }
                        {valEmp === '' &&
                            <Link to="/login" style={estilo}
                                className="btn btn-secondary btn-sm mr-2 align-self-center"
                            >
                                Continuar
                            </Link>
                        }

                    </div>
                </div>
            </div>
        )
    } 
    
}

export default ConfirmacionEnvio;