
import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';
import moment from 'moment';

const LOADER = 'LOADER';
const LISTADO_TC = 'LISTADO_TC';
const MODULO = 'MODULO';
const PERIODO = 'PERIODO';

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let id_period = id_emp[7];
    let lista = 'LISTA'
    //const params = { page, id, id_period, lista };
    const params = { id, id_period, lista };
    api.get('/tipo_cambio', params).then((response) => {
        dispatch({ type: LISTADO_TC, data: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar tipo de cambio',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}


export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/tipo_cambio/${id}`).then((response) => {
        dispatch(initializeForm('FormTipoCambio', response ));
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar tipo de cambio',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const registroTC = (periodoD) => (dispatch, getStore) => {
    const data = getStore().form.FormTipoCambio.values;
    if (data === undefined){
        Swal.fire({
            title: 'Tipo de cambio',
            text: 'Debe de ingresar datos',
            type: 'error',
        })    
    }else{
        if (data.fecha === undefined){
            Swal.fire({
                title: 'Tipo de cambio',
                text: 'Debe de ingresar fecha',
                type: 'error',
            })    
        }else{
            if (data.tipo_cambio === undefined || data.tipo_cambio === '0.000000'){
                Swal.fire({
                    title: 'Tipo de cambio',
                    text: 'Debe de ingresar tipo de cambio',
                    type: 'error',
                })    
            }
            else{
                let fechaI = moment(periodoD.fecha_inicio);
                let fechaF = moment(periodoD.fecha_fin);
                let fecha = moment(data.fecha)
                if (fecha>=fechaI && fecha<=fechaF){
                    let ruta = window.location.href;
                    let id_emp = ruta.split('/');
                    const formData = {
                        fechaT: data.fecha,
                        tipoCambio: data.tipo_cambio,
                        empresa: id_emp[5],
                        tipo: 'manual'
                    }
                    dispatch(setLoader(true));
                    api.post('/tipo_cambio/', formData).then(() => {
                        NotificationManager.success(
                            'Tipo de cambio creado',
                            'Exito',
                            3000
                        );
                        if (id_emp[4] === 'facturacion'){
                            let ruta = `/facturacion/${id_emp[5]}/tipo_cambio/${id_emp[7]}`;
                            dispatch(push(ruta));
                        }
                        if (id_emp[4] === 'compras'){
                            let ruta = `/compras/${id_emp[5]}/tipo_cambio/${id_emp[7]}`;
                            dispatch(push(ruta));
                        }
                        
                    }).catch((error) => {
                        NotificationManager.error(
                            error.msj,
                            'Error',
                            2000
                        );
                    }).finally(() => {
                        dispatch(setLoader(false));
                    });
                }else {
                    Swal.fire({
                        title: 'Tipo de cambio',
                        text: 'Fecha fuera del periodo',
                        type: 'error',
                    })        
                }
            }
        }
    }
}

export const modificarTC = () => (dispatch, getStore) => {
    const datos = getStore().form.FormTipoCambio.values;
    if (datos.tipo_cambio === undefined || datos.tipo_cambio === '0.000000'){
        Swal.fire({
            title: 'Tipo de cambio',
            text: 'Debe de ingresar tipo de cambio',
            type: 'error',
        })    
    }else{
        let id = datos.id;
        const formData = {
            id: id,
            fechaT: datos.fecha,
            tipoCambio: datos.tipo_cambio
        }
        let ruta = window.location.href;
        let id_emp = ruta.split('/');
        dispatch(setLoader(true));
        api.put(`/tipo_cambio/${id}/`, formData).then(() => {
            NotificationManager.success(
                'Tipo de cambio modificado correctamente',
                'Exito',
                3000
            );
            let ruta = `/compras/${id_emp[5]}/tipo_cambio/${id_emp[7]}`;
            dispatch(push(ruta));
        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al modificar tipo de cambio',
                'Error',
                0
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const leerPeriodo = (id, id_periodo) => (dispatch) => {
    const params = { id, id_periodo };
    api.get('/periodos/periodo', params).then((response) => {
        dispatch({ type: PERIODO, periodo: response.periodo });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar periodo',
            'Error',
            2000
        );
    })
}

export const actions = {
    registroTC,
    modificarTC,
    listar,
    leer,
    getModulo,
    leerPeriodo
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_TC]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [PERIODO]: (state, { periodo }) => {
        return {
            ...state,
            periodo,
        };
    },
};

export const initialState = {
    loader: false,
    periodos: null,
    data: [],
    modulo: [],
    periodo: []
};

export default handleActions(reducers, initialState)