import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import NavbarModulo from '../../components/NavbarModulo';
import DetalleInventario from './DetalleInventario';
import DetalleInventarioVer from './DetalleInventarioVer';
import DetalleInventarioFarm from './DetalleInventarioFarm';
import DetalleInventarioVerFarm from './DetalleInventarioVerFarm';
import styled from 'styled-components';

import {
    renderField,
    AsyncSelectField,
    renderDayPickerB,
    AsyncSelectFieldIn,
    renderCurrencyFloatTC,
} from "../../Utils/renderField/renderField";

function SalidasForm(props) {
    const {
        crear,
        bloqueo,
        modulo,
        periodoD,
        detalleProducto,
        registroProductoInventario,
        eliminarLineaProducto,
        confirmarSalida,
        datos,
        listarBodegas,
        leerProductos,
        pro,
        leerLotes,
        lotes,
        leerProductosFarm,
        proFarm
    } = props;

    const estilo = {
        overflow: "auto",
        height: "300px",

    };
    const [bodega, setBodega] = useState('');    
    const editar = window.location.href.includes('editar');
    let titulo = editar ? 'Modificar Salida' : 'Nueva Salida';
    let disabled = false;
    if (crear === false && editar === false) {
        disabled = true;
        titulo = 'Ver Salida'
    }
    const handleOnChange = value => {
        leerProductos(value.value, datos);
        localStorage.setItem('bodegaFactura', value.value)
    };
    
    const handleOnChangeFarm = value => {
        setBodega(value.value);
        leerProductosFarm(value.value);
    };

    const handleOnChangeProducto = value => {
        let idPro = value.value.split('#');
        leerLotes(idPro[0].trim(), bodega);
    };

    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let autoFocus = true;
    return (
        <div>
            <NavbarModulo
                periodoD = {periodoD}
                modulo = {modulo}
                titulo = {titulo}
            />
            <div className="mb-4 card card-small text-white bg-dark mb-3">
                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                    <div className="d-flex flex-column flex-1 mx-4">
                        <div className='row mb-2'>
                            <div className='col-3'>
                                <Field
                                    autoFocus={autoFocus}
                                    name='serie'
                                    component={renderField}
                                    className="form-control"
                                    placeholder="Serie"
                                    disabled={disabled}
                                    msj="Serie"
                                />
                            </div>
                            <div className='col-3'>
                                <Field
                                    name='numero'
                                    component={renderField}
                                    className="form-control"
                                    placeholder="Numero"
                                    disabled={disabled}
                                    msj="Numero"
                                />
                            </div>
                            <div className='col-3'>
                                <Field
                                    name="fecha"
                                    component={renderDayPickerB}
                                    msj="Fecha"
                                    disabled={disabled}
                                />
                            </div>
                        </div>
                        <div className='mb-2'>
                            <Field
                                id="descripcion"
                                name="descripcion"
                                placeholder="Descripcion"
                                component={renderField}
                                className="form-control"
                                msj="Referencia"
                                disabled={disabled}
                            />
                        </div>
                        {(crear === true || editar === true) &&
                            <div>
                                <h5 className="p-2 bg-secondary title mt-1">Detalle salida</h5>
                                {datos.farmaceutica === 'N' &&
                                    <div className='row mb-2'>
                                        <div className='col-3'>
                                            <Field
                                                name="bodega"
                                                placeholder="Bodega..."
                                                className="form-control-find"
                                                loadOptions={listarBodegas}
                                                component={AsyncSelectField}
                                                onChange={handleOnChange}
                                                msj="Bodega"
                                            />
                                        </div>
                                        <div className='col-7'>
                                            <Field
                                                name="producto"
                                                placeholder="Producto..."
                                                className="form-control-find"
                                                loadOptions={pro}
                                                component={AsyncSelectFieldIn}
                                                msj="Producto"
                                            />
                                        </div>
                                        <div className='col-2'>
                                            <Field
                                                name="cantidad"
                                                placeholder="Cantidad"
                                                className="form-control"
                                                component={renderCurrencyFloatTC}
                                                disabled={bloqueo}
                                                msj="Cantidad"
                                            />
                                        </div>
                                    </div>
                                }
                                {datos.farmaceutica === 'S' &&
                                    <div>
                                        <div className='row mb-2'>
                                            <div className='col-3'>
                                                <Field
                                                    name="bodega"
                                                    placeholder="Bodega..."
                                                    className="form-control-find"
                                                    loadOptions={listarBodegas}
                                                    component={AsyncSelectField}
                                                    onChange={handleOnChangeFarm}
                                                    msj="Bodega"
                                                />
                                            </div>
                                            <div className='col-9'>
                                                <Field
                                                    name="producto"
                                                    placeholder="Producto..."
                                                    className="form-control-find"
                                                    loadOptions={proFarm}
                                                    onChange={handleOnChangeProducto}
                                                    component={AsyncSelectFieldIn}
                                                    msj="Producto"
                                                />
                                            </div>
                                        </div>
                                        <div className='row mb-2'>
                                            <div className='col-4'>
                                                <Field
                                                    name="lote"
                                                    placeholder="Lotes..."
                                                    className="form-control-find"
                                                    loadOptions={lotes}
                                                    component={AsyncSelectFieldIn}
                                                    msj="Lotes"
                                                />
                                            </div>
                                        <div className='col-2'>
                                                <Field
                                                    name="cantidad"
                                                    placeholder="Cantidad"
                                                    className="form-control"
                                                    component={renderCurrencyFloatTC}
                                                    disabled={bloqueo}
                                                    msj="Cantidad"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                        {(crear === true || editar === true) &&
                            <Contenido>
                                <button
                                    className='btn mr-2 mb-3 btn-primary'
                                    onClick={() => registroProductoInventario(periodoD, datos)}
                                >
                                    Agregar linea
                                </button>
                            </Contenido>
                        }
                        <br/>
                        {datos.farmaceutica === 'N' &&
                            <div>
                                {(crear === true || editar === true) &&
                                    <div style={estilo}>
                                        <DetalleInventario
                                            detalle={detalleProducto}
                                            eliminarLineaProducto={eliminarLineaProducto}
                                        />
                                    </div>
                                }
                            </div>
                        }
                        {datos.farmaceutica === 'N' &&
                            <div>
                                {(crear === false && editar === false) &&
                                    <div style={estilo}>
                                        <DetalleInventarioVer
                                            detalle={detalleProducto}
                                            eliminarLineaProducto={eliminarLineaProducto}
                                        />
                                    </div>
                                }
                            </div>
                        }
                        {datos.farmaceutica === 'S' &&
                            <div>
                                {(crear === true || editar === true) &&
                                    <div style={estilo}>
                                        <DetalleInventarioFarm
                                            detalle={detalleProducto}
                                            eliminarLineaProducto={eliminarLineaProducto}
                                        />
                                    </div>
                                }
                            </div>
                        }
                        {datos.farmaceutica === 'S' &&
                            <div>
                                {(crear === false && editar === false) &&
                                    <div style={estilo}>
                                        <h5 className="p-2 bg-secondary title mt-1">Detalle salida</h5>
                                        <DetalleInventarioVerFarm
                                            detalle={detalleProducto}
                                            eliminarLineaProducto={eliminarLineaProducto}
                                        />
                                    </div>
                                }
                            </div>
                        }
                        <br />
                        <div className='d-flex flex-row mt-3'>
                            {disabled == false &&
                                <button
                                className='btn mr-2 mb-3 btn-primary'
                                onClick={confirmarSalida}
                                >
                                {editar ? 'Modificar' : 'Guardar'}
                                </button>
                            }
                            <a
                                href = {`/#/inventario/${id_emp[5]}/salidas/${id_emp[7]}`}
                                className='btn btn-secondary mb-3'
                            >
                                Regresar
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

SalidasForm.propTypes = {
    crear: PropTypes.bool,
    lectura: PropTypes.object,
    editar: PropTypes.bool,
    bloqueo: PropTypes.bool,
    modulo: PropTypes.object,
    periodoD: PropTypes.object,
    obtenerBodegas: PropTypes.array,
    listarProductos: PropTypes.object,
    detalleProducto: PropTypes.object,
    registroProductoInventario: PropTypes.func,
    eliminarLineaProducto: PropTypes.func,
    confirmarEntrada: PropTypes.func,
    periodoD: PropTypes.object,
    datos: PropTypes.object,
    listarBodegas: PropTypes.func,
    leerProductos: PropTypes.func,
    pro: PropTypes.object,
};

const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;

export default reduxForm({
    form: 'FormSalidasInventario',
})(SalidasForm);
