import React, { Component } from 'react';
import Formulario from './VisitadorForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";

class Visitador extends Component{
    
    componentDidMount = () => {
        const { getModulo, listarColaborador, listar, limpiarDatos } = this.props;
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        getModulo(modulo[5],modulo[4]);
        listarColaborador(modulo[7]);
        listar();
        limpiarDatos();
    }
    
    render(){
        const { listarColaborador, loader, modulo, agregarVisitador, emp, data,
            eliminar, datosColaborador, cargo } = this.props;
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    modulo = {modulo}
                    listarColaborador = {listarColaborador}
                    agregarVisitador = {agregarVisitador}
                    emp = {emp}
                    data = {data}
                    eliminar = {eliminar}
                    datosColaborador = {datosColaborador}
                    cargo = {cargo}
                />
            </LoadMask>
        );
    }
}

export default Visitador;