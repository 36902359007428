import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';

import {
    renderField,
    renderFieldCheck,
    renderCurrencyFloat,
    renderNumber,
    SelectField
} from "../../Utils/renderField/renderField";

function DatosAdicionalesForm(props) {
    const { crear, disabled, editar, lectura, datos } = props;

    let autoFocus = true;

    const [medico, setMedico] = useState(false);
    const [accidente, setAccidente] = useState(false);
    const [vehiculo, setVehiculo] = useState(false);
    const [enf, setEnf] = useState(false);
    const [vacuna, setVacuna] = useState(false);
    const [tatuaje, setTatuaje] = useState(false);
    const [pasaporte, setPasaporte] = useState(false);
    const [visa, setVisa] = useState(false);

    const onCheckboxClickedM = (idx, isChecked) => {
        setMedico(isChecked);
    }

    const onCheckboxClickedA = (idx, isChecked) => {
        setAccidente(isChecked);
    }

    const onCheckboxClickedV = (idx, isChecked) => {
        setVehiculo(isChecked);
    }

    const onCheckboxClickedEF = (idx, isChecked) => {
        setEnf(isChecked);
    }

    const onCheckboxClickedVa = (idx, isChecked) => {
        setVacuna(isChecked);
    }

    const onCheckboxClickedTa = (idx, isChecked) => {
        setTatuaje(isChecked);
    }

    const onCheckboxClickedPas = (idx, isChecked) => {
        setPasaporte(isChecked);
    }

    const onCheckboxClickedVi = (idx, isChecked) => {
        setVisa(isChecked);
    }

    const casa = [
        {
            value: 1,
            label: 'PROPIA'
        },
        {
            value: 2,
            label: 'ALQUILADA'
        },
        {
            value: 3,
            label: 'PAGANDO'
        },
    ];
    
    return (
        <div>
            {crear === true &&
                <div>
                    <div className='row mb-2'>
                        {datos.codigoPais == 'GTM' &&
                            <div className='col-3'>
                                <Field
                                    autoFocus={autoFocus}
                                    name="irtra"
                                    placeholder="Afiliación irtra"
                                    className="form-control"
                                    component={renderField}
                                    disabled={disabled}
                                    msj="Afiliación irtra"
                                />
                            </div>
                        }
                        <div className='col-3'>
                            <Field
                                name="incentivo_mensual"
                                placeholder="Incentivo mensual"
                                className="form-control"
                                component={renderCurrencyFloat}
                                disabled={disabled}
                                msj="Incentivo mensual"
                            />
                        </div>
                        <div className='col-3'>
                            <Field
                                name="depreciacion_vehiculo"
                                placeholder="Depreciación de vehiculo"
                                className="form-control"
                                component={renderCurrencyFloat}
                                disabled={disabled}
                                msj="Depreciación de vehiculo"
                            />
                        </div>
                        <div className='col-3'>
                            <Field
                                name="gasolina"
                                placeholder="Gasolina"
                                className="form-control"
                                component={renderCurrencyFloat}
                                disabled={disabled}
                                msj="Gasolina"
                            />
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-3'>
                            <Field
                                name="peso"
                                placeholder="Peso"
                                className="form-control"
                                component={renderField}
                                disabled={disabled}
                                msj="Peso"
                            />
                        </div>
                        <div className='col-3'>
                            <Field
                                name="altura"
                                placeholder="Altura"
                                className="form-control"
                                component={renderField}
                                disabled={disabled}
                                msj="Altura"
                            />
                        </div>
                        <div className='col-3'>
                            <Field
                                name="grupo_sanguineo"
                                placeholder="Grupo sanguíneo"
                                className="form-control"
                                component={renderField}
                                disabled={disabled}
                                msj="Grupo sanguíneo"
                            />
                        </div>
                        <div className='col-3'>
                            <Field
                                name="religion"
                                placeholder="Religión"
                                className="form-control"
                                component={renderField}
                                disabled={disabled}
                                msj="Religión"
                            />
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-3'>
                            <div className="form-floating">
                                <Field
                                    name="vivienda"
                                    options={casa}
                                    crear={crear}
                                    dato={0}
                                    component={SelectField}
                                    className="form-select"
                                    disabled={disabled}
                                    msj="Vivienda"
                                />
                            </div>
                        </div>
                        <div className='col-3 mt-3'>
                            <Field
                                name="padece_enf"
                                checked={enf}
                                label="Padece alguna enfermedad"
                                component={renderFieldCheck}
                                onChange={onCheckboxClickedEF}
                            />
                        </div>
                        <div className='col-3'>
                            <Field
                                name="enfermedad"
                                placeholder="Enfermedad"
                                className="form-control"
                                component={renderField}
                                disabled={disabled}
                                msj="Enfermedad"
                            />
                        </div>
                        <div className='col-3 mt-3'>
                            <Field
                                name="vacuna"
                                checked={vacuna}
                                label="Vacuna covid"
                                component={renderFieldCheck}
                                onChange={onCheckboxClickedVa}
                            />
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-3 mt-3'>
                            <Field
                                name="tatuajes"
                                checked={tatuaje}
                                label="Posee tatuajes"
                                component={renderFieldCheck}
                                onChange={onCheckboxClickedTa}
                            />
                        </div>
                        <div className='col-3 mt-3'>
                            <Field
                                name="pasaporte"
                                checked={pasaporte}
                                label="Pasaporte"
                                component={renderFieldCheck}
                                onChange={onCheckboxClickedPas}
                            />
                        </div>
                        <div className='col-3 mt-3'>
                            <Field
                                name="visa"
                                checked={visa}
                                label="Visa"
                                component={renderFieldCheck}
                                onChange={onCheckboxClickedVi}
                            />
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <strong className="d-block mb-2 title bg-secondary mt-2">Datos vehículo</strong>
                        <div className='col-3'>
                            <Field
                                name="marca"
                                placeholder="Marca"
                                className="form-control"
                                component={renderField}
                                disabled={disabled}
                                msj="Marca"
                            />
                        </div>
                        <div className='col-3'>
                            <Field
                                name="placa"
                                placeholder="Placa"
                                className="form-control"
                                component={renderField}
                                disabled={disabled}
                                msj="Placa"
                            />
                        </div>
                        <div className='col-3'>
                            <Field
                                name="modelo"
                                placeholder="Modelo"
                                className="form-control"
                                component={renderNumber}
                                disabled={disabled}
                                msj="Modelo"
                            />
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <strong className="d-block mb-2 title bg-secondary mt-2">En caso de emergencia</strong>
                        <div className='col-3'>
                            <Field
                                name="nombre_emergencia"
                                placeholder="Nombre"
                                className="form-control"
                                component={renderField}
                                disabled={disabled}
                                msj="Nombre"
                            />
                        </div>
                        <div className='col-3'>
                            <Field
                                name="telefono_emergencia"
                                placeholder="Marca"
                                className="form-control"
                                component={renderField}
                                disabled={disabled}
                                msj="Telefono"
                            />
                        </div>
                        <div className='col-3'>
                            <Field
                                name="parentesco"
                                placeholder="Parentesco"
                                className="form-control"
                                component={renderField}
                                disabled={disabled}
                                msj="Parentesco"
                            />
                        </div>
                    </div>
                    <div className="row mb-2">
                        <strong className="d-block mb-2 title bg-secondary mt-2">Seguro</strong>

                        <div className='row'>
                            <div className='col-3 ml-3'>
                                <Field
                                    name="medico"
                                    checked={medico}
                                    label="Medico"
                                    component={renderFieldCheck}
                                    onChange={onCheckboxClickedM}
                                />
                            </div>
                            <div className='col-3 ml-3'>
                                <Field
                                    name="accidente"
                                    checked={accidente}
                                    label="Accidentes"
                                    component={renderFieldCheck}
                                    onChange={onCheckboxClickedA}
                                />
                            </div>
                            <div className='col-3 ml-3'>
                                <Field
                                    name="vehiculo"
                                    checked={vehiculo}
                                    label="Vehiculo"
                                    component={renderFieldCheck}
                                    onChange={onCheckboxClickedV}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }
            {editar === true &&
                <div>
                    <div className='row mb-2'>
                        {datos.codigoPais == 'GTM' &&
                            <div className='col-3'>
                                <Field
                                    autoFocus={autoFocus}
                                    name="irtra"
                                    placeholder="Afiliación irtra"
                                    className="form-control"
                                    component={renderField}
                                    disabled={disabled}
                                    msj="Afiliación irtra"
                                />
                            </div>
                        }
                        <div className='col-3'>
                            <Field
                                name="incentivo_mensual"
                                placeholder="Incentivo mensual"
                                className="form-control"
                                component={renderCurrencyFloat}
                                disabled={disabled}
                                msj="Incentivo mensual"
                            />
                        </div>
                        <div className='col-3'>
                            <Field
                                name="depreciacion_vehiculo"
                                placeholder="Depreciación de vehiculo"
                                className="form-control"
                                component={renderCurrencyFloat}
                                disabled={disabled}
                                msj="Depreciación de vehiculo"
                            />
                        </div>
                        <div className='col-3'>
                            <Field
                                name="gasolina"
                                placeholder="Gasolina"
                                className="form-control"
                                component={renderCurrencyFloat}
                                disabled={disabled}
                                msj="Gasolina"
                            />
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-3'>
                            <Field
                                name="peso"
                                placeholder="Peso"
                                className="form-control"
                                component={renderField}
                                disabled={disabled}
                                msj="Peso"
                            />
                        </div>
                        <div className='col-3'>
                            <Field
                                name="altura"
                                placeholder="Altura"
                                className="form-control"
                                component={renderField}
                                disabled={disabled}
                                msj="Altura"
                            />
                        </div>
                        <div className='col-3'>
                            <Field
                                name="grupo_sanguineo"
                                placeholder="Grupo sanguíneo"
                                className="form-control"
                                component={renderField}
                                disabled={disabled}
                                msj="Grupo sanguíneo"
                            />
                        </div>
                        <div className='col-3'>
                            <Field
                                name="religion"
                                placeholder="Religión"
                                className="form-control"
                                component={renderField}
                                disabled={disabled}
                                msj="Religión"
                            />
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-3'>
                            <div className="form-floating">
                                <Field
                                    name="vivienda"
                                    options={casa}
                                    crear={crear}
                                    dato={lectura.vivienda}
                                    component={SelectField}
                                    className="form-select"
                                    disabled={disabled}
                                    msj="Vivienda"
                                />
                            </div>
                        </div>
                        <div className='col-3 mt-3'>
                            <Field
                                name="padece_enf"
                                checked={enf}
                                label="Padece alguna enfermedad"
                                component={renderFieldCheck}
                                onChange={onCheckboxClickedEF}
                            />
                        </div>
                        <div className='col-3'>
                            <Field
                                name="enfermedad"
                                placeholder="Enfermedad"
                                className="form-control"
                                component={renderField}
                                disabled={disabled}
                                msj="Enfermedad"
                            />
                        </div>
                        <div className='col-3 mt-3'>
                            <Field
                                name="vacuna"
                                checked={vacuna}
                                label="Vacuna covid"
                                component={renderFieldCheck}
                                onChange={onCheckboxClickedVa}
                            />
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-3 mt-3'>
                            <Field
                                name="tatuajes"
                                checked={tatuaje}
                                label="Posee tatuajes"
                                component={renderFieldCheck}
                                onChange={onCheckboxClickedTa}
                            />
                        </div>
                        <div className='col-3 mt-3'>
                            <Field
                                name="pasaporte"
                                checked={pasaporte}
                                label="Pasaporte"
                                component={renderFieldCheck}
                                onChange={onCheckboxClickedPas}
                            />
                        </div>
                        <div className='col-3 mt-3'>
                            <Field
                                name="visa"
                                checked={visa}
                                label="Visa"
                                component={renderFieldCheck}
                                onChange={onCheckboxClickedVi}
                            />
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <strong className="d-block mb-2 title bg-secondary mt-2">Datos vehículo</strong>
                        <div className='col-3'>
                            <Field
                                name="marca"
                                placeholder="Marca"
                                className="form-control"
                                component={renderField}
                                disabled={disabled}
                                msj="Marca"
                            />
                        </div>
                        <div className='col-3'>
                            <Field
                                name="placa"
                                placeholder="Placa"
                                className="form-control"
                                component={renderField}
                                disabled={disabled}
                                msj="Placa"
                            />
                        </div>
                        <div className='col-3'>
                            <Field
                                name="modelo"
                                placeholder="Modelo"
                                className="form-control"
                                component={renderNumber}
                                disabled={disabled}
                                msj="Modelo"
                            />
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <strong className="d-block mb-2 title bg-secondary mt-2">En caso de emergencia</strong>
                        <div className='col-3'>
                            <Field
                                name="nombre_emergencia"
                                placeholder="Nombre"
                                className="form-control"
                                component={renderField}
                                disabled={disabled}
                                msj="Nombre"
                            />
                        </div>
                        <div className='col-3'>
                            <Field
                                name="telefono_emergencia"
                                placeholder="Marca"
                                className="form-control"
                                component={renderField}
                                disabled={disabled}
                                msj="Telefono"
                            />
                        </div>
                        <div className='col-3'>
                            <Field
                                name="parentesco"
                                placeholder="Parentesco"
                                className="form-control"
                                component={renderField}
                                disabled={disabled}
                                msj="Parentesco"
                            />
                        </div>
                    </div>
                    <div className="row mb-2">
                        <strong className="d-block mb-2 title bg-secondary mt-2">Seguro</strong>

                        <div className='row'>
                            <div className='col-3 ml-3'>
                                <Field
                                    name="medico"
                                    checked={medico}
                                    label="Medico"
                                    component={renderFieldCheck}
                                    onChange={onCheckboxClickedM}
                                />
                            </div>
                            <div className='col-3 ml-3'>
                                <Field
                                    name="accidente"
                                    checked={accidente}
                                    label="Accidentes"
                                    component={renderFieldCheck}
                                    onChange={onCheckboxClickedA}
                                />
                            </div>
                            <div className='col-3 ml-3'>
                                <Field
                                    name="vehiculo"
                                    checked={vehiculo}
                                    label="Vehiculo"
                                    component={renderFieldCheck}
                                    onChange={onCheckboxClickedV}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }
            {(editar === false && crear === false) &&
                <div>
                    <div className='row mb-2'>
                        {datos.codigoPais == 'GTM' &&
                            <div className='col-3'>
                                <Field
                                    autoFocus={autoFocus}
                                    name="irtra"
                                    placeholder="Afiliación irtra"
                                    className="form-control"
                                    component={renderField}
                                    disabled={disabled}
                                    msj="Afiliación irtra"
                                />
                            </div>
                        }
                        <div className='col-3'>
                            <Field
                                name="incentivo_mensual"
                                placeholder="Incentivo mensual"
                                className="form-control"
                                component={renderCurrencyFloat}
                                disabled={disabled}
                                msj="Incentivo mensual"
                            />
                        </div>
                        <div className='col-3'>
                            <Field
                                name="depreciacion_vehiculo"
                                placeholder="Depreciación de vehiculo"
                                className="form-control"
                                component={renderCurrencyFloat}
                                disabled={disabled}
                                msj="Depreciación de vehiculo"
                            />
                        </div>
                        <div className='col-3'>
                            <Field
                                name="gasolina"
                                placeholder="Gasolina"
                                className="form-control"
                                component={renderCurrencyFloat}
                                disabled={disabled}
                                msj="Gasolina"
                            />
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-3'>
                            <Field
                                name="peso"
                                placeholder="Peso"
                                className="form-control"
                                component={renderField}
                                disabled={disabled}
                                msj="Peso"
                            />
                        </div>
                        <div className='col-3'>
                            <Field
                                name="altura"
                                placeholder="Altura"
                                className="form-control"
                                component={renderField}
                                disabled={disabled}
                                msj="Altura"
                            />
                        </div>
                        <div className='col-3'>
                            <Field
                                name="grupo_sanguineo"
                                placeholder="Grupo sanguíneo"
                                className="form-control"
                                component={renderField}
                                disabled={disabled}
                                msj="Grupo sanguíneo"
                            />
                        </div>
                        <div className='col-3'>
                            <Field
                                name="religion"
                                placeholder="Religión"
                                className="form-control"
                                component={renderField}
                                disabled={disabled}
                                msj="Religión"
                            />
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-3'>
                            <div className="form-floating">
                                <Field
                                    name="vivienda"
                                    options={casa}
                                    crear={crear}
                                    dato={lectura.vivienda}
                                    component={SelectField}
                                    className="form-select"
                                    disabled={disabled}
                                    msj="Vivienda"
                                />
                            </div>
                        </div>
                        <div className='col-3 mt-3'>
                            <Field
                                name="padece_enf"
                                checked={enf}
                                disabled={true}
                                label="Padece alguna enfermedad"
                                component={renderFieldCheck}
                                onChange={onCheckboxClickedEF}
                            />
                        </div>
                        <div className='col-3'>
                            <Field
                                name="enfermedad"
                                placeholder="Enfermedad"
                                className="form-control"
                                component={renderField}
                                disabled={disabled}
                                msj="Enfermedad"
                            />
                        </div>
                        <div className='col-3 mt-3'>
                            <Field
                                name="vacuna"
                                checked={vacuna}
                                label="Vacuna covid"
                                disabled={true}
                                component={renderFieldCheck}
                                onChange={onCheckboxClickedVa}
                            />
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-3 mt-3'>
                            <Field
                                name="tatuajes"
                                checked={tatuaje}
                                disabled={true}
                                label="Posee tatuajes"
                                component={renderFieldCheck}
                                onChange={onCheckboxClickedTa}
                            />
                        </div>
                        <div className='col-3 mt-3'>
                            <Field
                                name="pasaporte"
                                checked={pasaporte}
                                label="Pasaporte"
                                disabled={true}
                                component={renderFieldCheck}
                                onChange={onCheckboxClickedPas}
                            />
                        </div>
                        <div className='col-3 mt-3'>
                            <Field
                                name="visa"
                                checked={visa}
                                label="Visa"
                                disabled={true}
                                component={renderFieldCheck}
                                onChange={onCheckboxClickedVi}
                            />
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <strong className="d-block mb-2 title bg-secondary mt-2">Datos vehículo</strong>
                        <div className='col-3'>
                            <Field
                                name="marca"
                                placeholder="Marca"
                                className="form-control"
                                component={renderField}
                                disabled={disabled}
                                msj="Marca"
                            />
                        </div>
                        <div className='col-3'>
                            <Field
                                name="placa"
                                placeholder="Placa"
                                className="form-control"
                                component={renderField}
                                disabled={disabled}
                                msj="Placa"
                            />
                        </div>
                        <div className='col-3'>
                            <Field
                                name="modelo"
                                placeholder="Modelo"
                                className="form-control"
                                component={renderNumber}
                                disabled={disabled}
                                msj="Modelo"
                            />
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <strong className="d-block mb-2 title bg-secondary mt-2">En caso de emergencia</strong>
                        <div className='col-3'>
                            <Field
                                name="nombre_emergencia"
                                placeholder="Nombre"
                                className="form-control"
                                component={renderField}
                                disabled={disabled}
                                msj="Nombre"
                            />
                        </div>
                        <div className='col-3'>
                            <Field
                                name="telefono_emergencia"
                                placeholder="Marca"
                                className="form-control"
                                component={renderField}
                                disabled={disabled}
                                msj="Telefono"
                            />
                        </div>
                        <div className='col-3'>
                            <Field
                                name="parentesco"
                                placeholder="Parentesco"
                                className="form-control"
                                component={renderField}
                                disabled={disabled}
                                msj="Parentesco"
                            />
                        </div>
                    </div>
                    <div className="row mb-2">
                        <strong className="d-block mb-2 title bg-secondary mt-2">Seguro</strong>

                        <div className='row'>
                            <div className='col-3 ml-3'>
                                <Field
                                    name="medico"
                                    checked={medico}
                                    label="Medico"
                                    disabled={true}
                                    component={renderFieldCheck}
                                    onChange={onCheckboxClickedM}
                                />
                            </div>
                            <div className='col-3 ml-3'>
                                <Field
                                    name="accidente"
                                    checked={accidente}
                                    label="Accidentes"
                                    disabled={true}
                                    component={renderFieldCheck}
                                    onChange={onCheckboxClickedA}
                                />
                            </div>
                            <div className='col-3 ml-3'>
                                <Field
                                    name="vehiculo"
                                    checked={vehiculo}
                                    label="Vehiculo"
                                    disabled={true}
                                    component={renderFieldCheck}
                                    onChange={onCheckboxClickedV}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

DatosAdicionalesForm.propTypes = {
    handleSubmit: PropTypes.func,
    crear: PropTypes.bool,
};

export default reduxForm({
    form: 'FormColaborador',
})(DatosAdicionalesForm);
