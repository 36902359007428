import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import {
  SelectField,
  renderNumber
} from "../../Utils/renderField/renderField";
import NavbarModulo from '../../components/NavbarModulo';
import TablaVerDetalle from './ConciliacionDetalle';
import LoadMask from "../../Utils/LoadMask/LoadMask";

const meses = [
  {
    value: 'ENERO',
    label: 'ENERO'
  },
  {
    value: 'FEBRERO',
    label: 'FEBRERO'
  },
  {
    value: 'MARZO',
    label: 'MARZO'
  },
  {
    value: 'ABRIL',
    label: 'ABRIL'
  },
  {
    value: 'MAYO',
    label: 'MAYO'
  },
  {
    value: 'JUNIO',
    label: 'JUNIO'
  },
  {
    value: 'JULIO',
    label: 'JULIO'
  },
  {
    value: 'AGOSTO',
    label: 'AGOSTO'
  },
  {
    value: 'SEPTIEMBRE',
    label: 'SEPTIEMBRE'
  },
  {
    value: 'OCTUBRE',
    label: 'OCTUBRE'
  },
  {
    value: 'NOVIEMBRE',
    label: 'NOVIEMBRE'
  },
  {
    value: 'DICIEMBRE',
    label: 'DICIEMBRE'
  },
];


function ConciliacionForm(props) {
  const { cuenta, listar, modulo, data, conciliar, actualizar, loader, loaderC } = props;
  
  let titulo = 'Conciliación de bancaria';
  let ruta = window.location.href;
  let id_emp = ruta.split('/');
  return (
    <div>
      <NavbarModulo
        modulo={modulo}
        titulo={titulo}
      />
      <div className="mb-4 card card-small text-white bg-dark mb-3">
        <div className="p-0 pt-3 d-flex flex-column flex-md-row">
          <div className="d-flex flex-column flex-1 mx-4">
            <div className='row mb-2'>
              <div className='col-md-6'>
                {cuenta &&
                  <Field
                    name="cuenta"
                    options={cuenta}
                    dato={0}
                    component={SelectField}
                    className="form-select"
                    msj="Cuenta bancaria"
                  />
                }
              </div>
            </div>
            <div className='row mb-2'>
              <div className='col-3'>
                <Field
                  name="mes"
                  options={meses}
                  component={SelectField}
                  className="form-select"
                  msj="Mes"
                />
              </div>
              <div className='col-3'>
                <Field
                  name="year"
                  placeholder="Año"
                  component={renderNumber}
                  msj="Año"
                />
              </div>
                <div className='col-3'>
                  <button
                    className='btn mr-2 mt-2 btn-primary'
                    type='submit'
                    onClick={listar}
                  >
                    Mostrar
                  </button>
                </div>
            </div>
            {data.length >= 1 &&
              <div className='d-flex flex-row mt-3'>
                  <button
                    className='btn mr-2 mb-3 btn-primary'
                    type='submit'
                    onClick={() => { conciliar(data) }}
                  >
                    Conciliar
                  </button>
                
              <a
                href={`/#/bancos/${id_emp[5]}`}
                className='btn btn-secondary mb-3'
              >
                Regresar
              </a>
              </div>
            }
            {data.length === 0 &&
              <div className='d-flex flex-row mt-3'>
                <a
                  href={`/#/bancos/${id_emp[5]}`}
                  className='btn btn-secondary mb-3'
                >
                  Regresar
                </a>
              </div>
            }
            <div>
              <div className='mt-2'>
                <TablaVerDetalle
                  loader = {loader}
                  detalle = {data}
                  actualizar = {actualizar}
                />
              </div>
            </div>
            <br/>
            <br/>
          </div>
        </div>
      </div>

    </div>
  );
}

export default reduxForm({
  form: 'FormConciliacionCheques',
})(ConciliacionForm);
