import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';
const valEmp = __CONFIG__.empresa;

const COLABORADOR = 'COLABORADOR';
const LISTADO = 'LISTADO';
const TIPO = 'TIPO';
const PUESTO = 'PUESTO';
const LOADER = 'LOADER';
const MODULO = 'MODULO';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listarColaborador = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params;
    let id = ''
    if (valEmp === 'fage') {
        id = id_emp[6];
        params = { id };
    } else {
        id = id_emp[5];
        params = { id };
    }
    api.get('/colaborador/empresa_colaborador_pais', params).then(data => {
        const lista = [];
        if (data) {
            data.forEach(item => {
                lista.push({
                    value: item.id,
                    label: item.codigo + ' - ' + item.nombre
                })
            })
        }
        dispatch({ type: COLABORADOR, emp: lista })
    }).catch((error) => {
        return [];
    })
}

export const datosColaborador = (emp) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let yearD = '';
    if (valEmp === 'fage') {
        yearD = id_emp[8];
    } else {
        yearD = id_emp[7];
    }
    api.get(`/colaborador/${emp}`).then((response) => {
        dispatch({ type: PUESTO, cargo: response.cargo })
    }).catch((error) => {
        return [];
    })
}

export const registroPrestamo = (descuento) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormPrestamo.values;
    
    let id_empresa = '';
    if (valEmp === 'fage') {
        id_empresa = id_emp[6]
    } else {
        id_empresa = id_emp[5]
    }
    if (data === undefined) {
        Swal.fire({
            title: 'Préstamo Colaborador',
            text: 'Debe de seleccionar colaborador',
            type: 'error',
        })
    } else {
        if (data.fecha_inicio === undefined || data.fecha_inicio === '') {
            Swal.fire({
                title: 'Préstamo Colaborador',
                text: 'Debe de ingresar fecha de inicio',
                type: 'error',
            })
        } else {
            if (data.tipo === undefined || data.tipo === '') {
                Swal.fire({
                    title: 'Préstamo Colaborador',
                    text: 'Debe de seleccionar frecuencia de pago',
                    type: 'error',
                })
            } else {
                if (data.total === undefined || data.total === '0.00') {
                    Swal.fire({
                        title: 'Préstamo Colaborador',
                        text: 'Debe de ingresar total de préstamo',
                        type: 'error',
                    })
                } else {
                    if ((data.meses === undefined || data.meses === 0) && (parseFloat(descuento) === 0)) {
                        Swal.fire({
                            title: 'Préstamo Colaborador',
                            text: 'Debe de ingresar meses o cuota',
                            type: 'error',
                        })
                    } else {
                        if (parseFloat(descuento) === 0) {
                            Swal.fire({
                                title: 'Préstamo Colaborador',
                                text: 'Debe de ingresar cuota',
                                type: 'error',
                            })
                        } else {
                            if (parseFloat(descuento) > parseFloat(data.total)) {
                                Swal.fire({
                                    title: 'Préstamo Colaborador',
                                    text: 'Cuota no puede ser mayor a total de préstamo',
                                    type: 'error',
                                })
                            } else {
                                const formData = {
                                    colaborador: data.colaborador.value,
                                    fecha_inicio: data.fecha_inicio,
                                    forma_descuento: data.tipo ? data.tipo : data.forma_descuento,
                                    total: data.total,
                                    meses: data.meses ? data.meses : 0,
                                    cuota: descuento ? descuento : 0.00,
                                    empresa: id_empresa
                                }
                                dispatch(setLoader(true));
                                api.post('/rrhh_prestamo/', formData).then(() => {
                                    NotificationManager.success(
                                        'Préstamo colaborador creado correctamente',
                                        'Exito',
                                        3000
                                    );
                                    if (valEmp === 'fage') {
                                        ruta = `/lfg/rr_hh/${id_emp[6]}/prestamos`
                                        dispatch(push(ruta));
                                    } else {
                                        ruta = `/rr_hh/${id_emp[5]}/prestamos`
                                        dispatch(push(ruta));
                                    }
                                }).catch((error) => {
                                    NotificationManager.error(
                                        error.msj,
                                        'Error',
                                        0
                                    );
                                }).finally(() => {
                                    dispatch(setLoader(false));
                                });
                            }
                        }
                    }
                }
            }
        }
    }
}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = { id, modulo }
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response) => {
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = '';
    let year = '';
    let params;
    if (valEmp === 'fage') {
        id = id_emp[6];
        year = id_emp[8];
        params = { id, year };
    } else {
        id = id_emp[5];
        year = id_emp[7];
        params = { id, year };
    }
    return api.get('/rrhh_prestamo/', params).then((response) => {
        dispatch({ type: LISTADO, data: response })
    }).catch(error => {
        NotificationManager.error(
            'Ocurrió un error al listar descuento ISR',
            'Error',
            0
        );
    })
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/rrhh_prestamo/${id}`).then((response) => {
        dispatch({ type: TIPO, tipo: response.forma_descuento })
        dispatch(initializeForm('FormPrestamo', response));
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar préstamo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const modificarPrestamo = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const datos = getStore().form.FormPrestamo.values;
    const id = datos.id;
    const formData = {
        id: id,
        fecha_inicio: datos.fecha_inicio,
        total: datos.total,
        meses: datos.meses ? datos.meses : 0,
        cuota: datos.cuota ? datos.cuota : 0.00,
        forma_descuento: datos.tipo ? datos.tipo : datos.forma_descuento,
    }
    dispatch(setLoader(true));
    api.put(`/rrhh_prestamo/${id}/`, formData).then(() => {
        NotificationManager.success(
            'Préstamo colaborador modificado correctamente',
            'Exito',
            3000
        );
        let ruta = '';
        if (valEmp === 'fage') {
            ruta = `/lfg/rr_hh/${id_emp[6]}/prestamos`
            dispatch(push(ruta));
        } else {
            ruta = `/rr_hh/${id_emp[5]}/prestamos`
            dispatch(push(ruta));
        }
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al modificar préstamo colaborador',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const limpiarDatos = () => (dispatch) => {
    dispatch({ type: PUESTO, cargo: [] });
};

const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`/rrhh_prestamo/${id}`).then(() => {
        NotificationManager.success(
            'Préstamo eliminado correctamente',
            'Exito',
            3000
        );
        dispatch(listar());
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error al eliminar préstamo',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}


export const actions = {
    listarColaborador,
    getModulo,
    registroPrestamo,
    modificarPrestamo,
    datosColaborador,
    listar,
    leer,
    limpiarDatos,
    eliminar
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [COLABORADOR]: (state, { emp }) => {
        return {
            ...state,
            emp,
        };
    },
    [PUESTO]: (state, { cargo }) => {
        return {
            ...state,
            cargo,
        };
    },
    [LISTADO]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [TIPO]: (state, { tipo }) => {
        return {
            ...state,
            tipo,
        };
    },
};

export const initialState = {
    loader: false,
    modulo: [],
    data: [],
    emp: [],
    tipo: [],
};

export default handleActions(reducers, initialState)
