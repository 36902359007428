import React, { useEffect, useRef, useState } from "react";
import Toolbar from "@material-ui/core/Toolbar";
import NavbarModulo from "../../components/NavbarModulo";
import "../../../../../style/Finanzas/FinanzasHome.css";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import { FicComboBox } from "../../Utils/FormInputComponents";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "react-router-dom";
import DataTableWSearch from "../../components/DataTableWSearch";
import { FicInput } from "../../Utils/FormInputComponents";
import { FicCheckbox } from "../../Utils/FormInputComponents";
import DialogModal from "../../components/DialogModal";
import GenBtn from "../../components/GenBtn";
import { validar } from "../../components/ValidarNit";

const AgregarEmpLink = styled.button`
    color: hsla(206, 100%, 50%);
    background-color: hsla(206, 100%, 50%, 0.1);
    border: 1px solid hsla(206, 100%, 50%);
    border-radius: 5px;
    padding: 0.5rem;
    text-decoration: none;
    margin: 1rem 0 1rem 0;
    min-width: 77.5px;
    width: 15%;
    text-align: center;
    transition: border 300ms ease-in;

    &:hover {
        text-decoration: none;
        border: 1px solid hsla(206, 100%, 50%, 0.1);
        transition: border 300ms ease-out;
    }
`;

const Container = styled.div`
    margin-top: 2rem;
    height: 50vh;
    width: 100%;
    // background-color: #1e1e1e;
`;

const StyledFormContainer = styled.form`
    margin: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
`;
const StyledContainer = styled.form`
    margin: 10% 10% 0 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
`;

const schema = yup.object().shape({
    identificador: yup
        .string()
        .test("dpi-validation", "El NIT no es valido", function (value) {
            return validar.valNit(value);
        }),
    razon_social: yup.string().required("El nombre es requerido"),
    pais: yup.mixed().required("El pais es requerido"),
    moneda: yup.string().required("La moneda es requerida"),
});

function FinanzasHome(props) {
    const {
        listarEmpresas,
        listarPaises,
        listarEmpresasAgregadas,
        obtenerDatosDeEmpresa,
        agregarEmpresa,
    } = props;

    const defaultValues = {
        identificador: "",
        razon_social: "",
        pais: null,
        moneda: "",
    };

    const dialogRef = useRef(null);
    const [empresaExistente, setEmpresaExistente] = useState(false);
    const [empresa, setEmpresa] = useState();
    const [tableData, setTableData] = useState([]);

    const {
        handleSubmit,
        formState: { errors },
        control,
        reset,
        setValue,
    } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: defaultValues,
    });

    async function setFormData() {
        if (empresa) {
            await obtenerDatosDeEmpresa(empresa.value).then((data) => {
                reset(data);
            });
        }
    }

    async function fetchData() {
        await listarEmpresasAgregadas().then((data) => {
            setTableData(data);
        });
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        reset(defaultValues);
        setEmpresa(null);
    }, [empresaExistente]);

    useEffect(() => {
        setFormData();
        reset(defaultValues);
    }, [empresa]);

    async function onSubmit(data) {
        if (empresa) {
            data.empresa = empresa.value;
        }
        data.pais = data.pais.value;
        const shouldReset = await agregarEmpresa(data);
        if (shouldReset) {
            reset();
        }
        setValue("pais", null);
        fetchData();
    }

    const columns = [
        {
            name: "Razon social",
            selector: (row) => row.razon_social,
            sortable: true,
        },
        {
            name: "Nit",
            selector: (row) => row.identificador,
            sortable: true,
        },
        {
            name: "Pais",
            selector: (row) => row.pais,
            sortable: true,
        },
        {
            name: "Moneda",
            selector: (row) => row.moneda,
            sortable: true,
        },
    ];

    return (
        <Container>
            <AgregarEmpLink onClick={() => dialogRef.current.showModal()}>
                Agregar empresa
            </AgregarEmpLink>
            {/* <AgregarEmpLink onClick={() => fetchData()}>Refresh</AgregarEmpLink> */}

            <DialogModal
                dialogRef={dialogRef}
                title="Agregar empresa"
                style={{
                    width: "30rem",
                    minWidth: "255px",
                    overflow: "visible",
                }}
            >
                <StyledContainer>
                    <FicCheckbox
                        nonForm
                        isSelected={empresaExistente}
                        onChange={setEmpresaExistente}
                        label="Empresa existente"
                    />
                    {empresaExistente && (
                        <FicComboBox
                            nonForm
                            name="empresa"
                            label="Empresa"
                            loadOptions={listarEmpresas}
                            placeholder="Seleccione una empresa"
                            value={empresa}
                            onChange={setEmpresa}
                        />
                    )}
                </StyledContainer>
                <StyledFormContainer onSubmit={handleSubmit(onSubmit)}>
                    <FicInput
                        name="identificador"
                        label="NIT"
                        placeholder="NIT"
                        control={control}
                        isReadOnly={empresaExistente}
                    />
                    <FicInput
                        name="razon_social"
                        label="Nombre"
                        placeholder="Nombre"
                        control={control}
                        isReadOnly={empresaExistente}
                    />
                    {/* <FicInput
                            name="pais"
                            label="Pais"
                            placeholder="Pais"
                            control={control}
                            isReadOnly={empresaExistente}
                        /> */}
                    <FicComboBox
                        name="pais"
                        label="Pais"
                        loadOptions={listarPaises}
                        placeholder="Seleccione un pais"
                        control={control}
                        disabled={empresaExistente}
                    />
                    <FicInput
                        name="moneda"
                        label="Moneda"
                        placeholder="Moneda"
                        control={control}
                    />
                    <GenBtn type="submit">Agregar</GenBtn>
                </StyledFormContainer>
            </DialogModal>

            <DataTableWSearch
                title="Listado de empresas"
                data={tableData}
                columns={columns}
                fieldToFilter="empresa"
                filterPlaceHolder="Buscar por codigo"
            />
        </Container>
    );
}

export default FinanzasHome;
