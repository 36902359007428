import React from 'react';
import { reduxForm } from 'redux-form/immutable';

import TablaVerCC from '../Contabilidad/Polizas/TablaPolizaVerCentroCosto';
import TablaVerSCC from '../Contabilidad/Polizas/TablaPolizaVerSCentroCosto';
import PropTypes from 'prop-types';
import Tabla from '../Contabilidad/Polizas/TablaPolizaSCentroCosto';
import TablaCC from '../Contabilidad/Polizas/TablaPolizaCentroCosto';


function PolizasForm(props) {
    const {
        crear, lectura, editar,
        detallePoliza,
        eliminarLinea,
    } = props;

    let cc = localStorage.getItem('cc');

    return (
        <div>
            <br />
            {crear === false && editar === false ? (
                lectura.polizaDetalle_polizaEncabezado.length > 0 &&
                (cc === 'S' ? (
                    <TablaVerCC
                        lectura={lectura}
                    />
                ) : (
                    <TablaVerSCC
                        lectura={lectura}
                    />
                ))
            ) : (crear === false && editar === true ? (
                lectura.polizaDetalle_polizaEncabezado &&
                (cc === 'S' ? (
                    <TablaCC
                        detallePoliza={lectura.polizaDetalle_polizaEncabezado}
                        eliminarLinea={eliminarLinea}
                    />

                ) : (
                    <Tabla
                        detallePoliza={lectura.polizaDetalle_polizaEncabezado}
                        eliminarLinea={eliminarLinea}
                    />
                ))
            ) : (cc === 'S' ? (
                <TablaCC
                    detallePoliza={detallePoliza}
                    eliminarLinea={eliminarLinea}
                />
            ) : (
                <Tabla
                    detallePoliza={detallePoliza}
                    eliminarLinea={eliminarLinea}
                />
            )
            ))
            }
        </div>
    );

}

PolizasForm.propTypes = {
    crear: PropTypes.bool,
    editar: PropTypes.bool,
    lectura: PropTypes.object,
    detallePoliza: PropTypes.object,
    eliminarLinea: PropTypes.func,
};

export default reduxForm({
    form: 'PolizaFormA',
})(PolizasForm);

