import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { NotificationManager } from "react-notifications";
import { api } from "api";
import {setMe} from "./login";
import { initialize as initializeForm } from 'redux-form';

const LOADER = 'LOGIN_LOADER';
const ME = 'ME';

export const constants = {
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const setUser = me => ({
    type: ME,
    me,
});
// ------------------------------------
// Actions
// ------------------------------------

export const update = (data = {}, attachments=[]) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.putAttachments('user/update_me', data, attachments).then((response) => {
        dispatch(setMe(response));
        NotificationManager.success('Datos actualizados exitosamente', 'ERROR', 1000);
    }).catch(() => {
        NotificationManager.error('Ocurrió un error al actualizar los datos', 'ERROR', 5000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const getMe = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get('/user/me').then(response => {
        dispatch({type: ME, me: response });
        dispatch(initializeForm('FormProfile', response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const actions = {
    update,
    getMe
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [ME]: (state, { me }) => {
        return {
            ...state,
            me,
        };
    },
};

export const initialState = {
    loader: false,
    me: [],
};

export default handleActions(reducers, initialState);
