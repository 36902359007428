import React, { Component } from 'react';
import Grid from '../Utils/GridFiltro';
import PropTypes from 'prop-types';
import { standardActions } from '../Utils/Grid/StandardActions';
import moment from 'moment';
import NavbarModulo from '../components/NavbarModulo';

class TipoCambioList extends Component{
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: true
    };

    componentDidMount = () => {
        const { listar, leerPeriodo, getModulo } = this.props;
        let ruta = window.location.href;
        let periodo = ruta.split('/');
        leerPeriodo(periodo[5], periodo[7]);
        getModulo(periodo[5],periodo[4]);
        listar();
    }
    render(){
        const { data, loader, periodo, modulo } = this.props;
        let ruta = window.location.href;
        let datos = ruta.split('/');
        return(
            <React.Fragment>
                <NavbarModulo
                    periodoD = {periodo}
                    modulo = {modulo}
                />
                <br />
                <h3 className="p-2 bg-secondary title">Tipo de cambio</h3>
                <br />
                { datos[4] === 'compras' &&
                    <div className="d-flex flex-row justify-content-start mb-2">
                        <a
                            href={`/#/compras/${datos[5]}/tipo_cambio/${datos[7]}/crear`}
                            className='btn btn-primary'
                        >
                            Crear tipo de cambio
                        </a>
                        <a
                            href={`/#/compras/${datos[5]}`}
                            className='btn btn-secondary ml-2'
                        >
                            Regresar
                        </a>
                    </div>
                }
                { datos[4] === 'facturacion' &&
                    <div className="d-flex flex-row justify-content-start mb-2">
                        <a
                            href={`/#/facturacion/${datos[5]}/tipo_cambio/${datos[7]}/crear`}
                            className='btn btn-primary'
                        >
                            Crear tipo de cambio
                        </a>
                        <a
                            href={`/#/facturacion/${datos[5]}`}
                            className='btn btn-secondary ml-2'
                        >
                            Regresar
                        </a>
                    </div>
                }
                {data &&
                    <Grid 
                        hover 
                        striped 
                        data={data}
                        loading={loader} 
                        headerStyle={ { background: 'black' } } 
                        //onPageChange={onPageChange}
                        //onSortChange={onSortChange} 
                    >
                        <TableHeaderColumn
                            isKey
                            dataField="fecha"
                            width="200"
                            dataFormat={(cell, row)=>{
                                return moment(cell).format("DD/MM/YYYY");
                            }}
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Fecha
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="tipo_cambio"
                            headerAlign="center"
                            dataAlign='right'
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Tipo de cambio
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            thStyle={
                            {color: 'white'}}
                            dataField="id"
                            width="150"
                            dataAlign="center"
                            dataSort
                            dataFormat={standardActions({ 
                                editar_a: "tipo_cambio",
                                empresa: datos[5],
                                periodo: datos[7],
                                modulo: datos[4],
                            })} 
                        >
                            Acciones
                        </TableHeaderColumn>
                    </Grid>
                }
            </React.Fragment>
        );
    }
}

export default TipoCambioList;
