import React from 'react';
import { Helmet } from 'react-helmet';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import { reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import NavbarModulo from '../components/NavbarModulo';
import NavbarModuloLF from '../components/NavbarModuloFage';
const valEmp = __CONFIG__.empresa;

function NominaHome(props) {
    const {
        modulo,
    } = props;

    const description = 'Nomina';
    let titulo = ''

    return (
        <div>
            {valEmp === 'fage' &&
                <NavbarModuloLF
                    modulo={modulo}
                    titulo={titulo}
                />
            }
            {valEmp === '' &&
                <NavbarModulo
                    modulo={modulo}
                    titulo={titulo}
                />
            }
            <Helmet>
                <title>{description}</title>
            </Helmet>
            <Toolbar >
                <div style={{ color: "white" }}>
                    <Typography variant="h3">
                        {description}
                    </Typography>
                </div>
            </Toolbar>
        </div>
    );
}

NominaHome.propTypes = {
    modulo: PropTypes.object
};

export default reduxForm({
    form: 'FormNominaHome',

})(NominaHome);
