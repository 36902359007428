import React, { Component } from 'react';
import Grid from '../../Utils/GridFiltro';
import PropTypes from 'prop-types';
import { standardActions } from '../../Utils/Grid/StandardActions';
import { GrDocumentPdf } from 'react-icons/gr';
import { IconContext } from "react-icons";
import { FaPlusCircle } from 'react-icons/fa';

class BodegasList extends Component {
    static propTypes = {
        data: PropTypes.array.isRequired,
        // data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        reporteBodegas: PropTypes.func
    };

    static defaultProps = {
        loading: true
    };

    componentDidMount = () => {
        const { listar, modulo } = this.props;
        listar();
    }
    render() {
        const { data, loader, eliminar, modulo, reporteBodegas } = this.props;
        const estilo1 = {
            backgroundColor: "#ff413d", maxHeight: "35px", maxWidth: "35px"
        };
        let ruta = window.location.href;
        let id_emp = ruta.split('/');
        return (
            <React.Fragment>
                <div className="d-flex flex-row justify-content-between p-2">
                    <h3 className="p-2 text-md-center title">
                        Bodegas
                        <span>  </span>
                        <a
                            href={`/#/punto_venta/${id_emp[5]}/bodegas/crear`}
                            className='btn btn-primary text-lg'>{<FaPlusCircle />} Nuevo
                        </a>
                    </h3>
                    <button className='btn btn-dark float-right d-flex justify-content-center' onClick={reporteBodegas} style={estilo1}
                        data-bs-toggle="tooltip" data-bs-placement="left" title="Generar reporte">
                        <i>
                            <IconContext.Provider value={{ size: "1.25rem" }}>
                                <GrDocumentPdf />
                            </IconContext.Provider>
                        </i>
                    </button>
                </div>

                {data &&
                    <Grid
                        hover
                        striped
                        data={data}
                        loading={loader}
                        headerStyle={{ background: 'black' }}
                    //onPageChange={onPageChange}
                    //onSortChange={onSortChange} 
                    >
                        <TableHeaderColumn
                            thStyle={
                                { color: 'white' }}
                            dataField="id"
                            width="100"
                            dataAlign="center"
                            dataSort
                            dataFormat={standardActions({
                                editar: "bodegas",
                                ver: "bodegas",
                            })}

                        >
                            Acciones
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            isKey
                            dataField="numero"
                            width="150"
                            dataAlign='center'
                            dataSort
                            // dataFormat={(cell, row) => {
                            //     if (cell === null) {
                            //         return ''
                            //     } else {
                            //         return (<div><input value={cell} /></div>);
                            //     }
                            // }}
                            thStyle={
                                { color: 'white' }}
                            tdStyle={
                                { color: 'white' }}
                        >
                            Numero Bodega
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="bodega"
                            // minWidth="40%"
                            width="250"
                            headerAlign="center"
                            dataAlign='left'
                            dataSort
                            thStyle={
                                { color: 'white' }}
                            tdStyle={
                                { color: 'white' }}
                        >
                            Bodega
                        </TableHeaderColumn>
                    </Grid>
                }
            </React.Fragment>
        );
    }
}

export default BodegasList;
