//Recuperar Contraseña
import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { NotificationManager } from "react-notifications";
import { api } from "api";
const LOADER = 'LOADER';
const valEmp = __CONFIG__.empresa;

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const revisarCorreo = () => (dispatch, getStore) => {
    const formData = getStore().form.correoForm.values;
    let ruta = window.location.href;
    let url = ruta.split('/');
    let urlToken = url[0]+'/'+url[1]+'/'+url[2]+'/'+url[3];
    
    const data = {
        correo: formData.username,
        url: urlToken
    }
    dispatch(setLoader(true));
    api.get('/user/verificar_correo/', data).then((response) => {
        NotificationManager.success(
            'Correo enviado',
            'Exito',
            3000
        );
        dispatch(push('/confirmacion-envio'));
    }).catch((error) => {
        if (error.status === 400){
            NotificationManager.info(
              error.body.msj,
              'Informacion',
              5000
            );
        }
        if (error.status === 404){
            NotificationManager.error(
              'Ocurrió un error al enviar correo',
              'Error',
              0
            );
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const cambiarPassToken = () => (dispatch, getStore) => {
    const formData = getStore().form.cambiarPassForm.values;
    let ruta = window.location.href;
    let url = ruta.split('/');
    let id = url[5];
    let token = url[6].split('=');
    const data = {
        password: formData.password,
        token: token[1],
        id: id
    }
    dispatch(setLoader(true));
    api.put('/user/cambiar_pass_token/', data).then(() => {
        NotificationManager.success(
            'Contraseña modificada',
            'Exito',
            3000
        );
        if (valEmp === 'fage'){
            dispatch(push('/lfg'));
        }else{
            dispatch(push('/login'));
        }
    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            10000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const cambiarPass = () => (dispatch, getStore) => {
    const formData = getStore().form.CambiarPassFormInt.values;
    const data = {
        password: formData.password,
    }
    dispatch(setLoader(true));
    api.put('/user/cambiar_pass/', data).then((response) => {
        NotificationManager.success(
            'Contraseña modificada',
            'Exito',
            3000
        );
    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            10000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}


export const actions = {
    revisarCorreo,
    cambiarPassToken,
    cambiarPass,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
};

export const initialState = {
    loader: false,
};

export default handleActions(reducers, initialState)