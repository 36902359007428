import React, { Component } from 'react';
import Grid from '../../Utils/GridFiltro';
import { standardActions } from '../../Utils/GridFiltro/StandardActions';
import NavbarModulo from '../../components/NavbarModulo';
import NavbarModuloLF from '../../components/NavbarModuloFage';
const valEmp = __CONFIG__.empresa;

class PeriodosList extends Component{
    componentDidMount = () => {
        const { listar, leerPeriodoD, getModulo } = this.props;
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        if (valEmp === 'fage'){
            getModulo(modulo[6], modulo[5])
            leerPeriodoD(modulo[6], modulo[8]);
        }else{
            getModulo(modulo[5],modulo[4]);
            leerPeriodoD(modulo[5],modulo[7]);
        }
        listar();
    }
    render(){
        const { data, loader, modulo, periodoD } = this.props;
        let titulo = '';
        let ruta = window.location.href;
        let id_emp = ruta.split('/');
        return(
            <React.Fragment>
                {valEmp === 'fage' &&
                    <NavbarModuloLF
                        periodoD={periodoD}
                        modulo={modulo}
                        titulo={titulo}
                    />
                }
                {valEmp === '' &&
                    <NavbarModulo
                        periodoD={periodoD}
                        modulo={modulo}
                        titulo={titulo}
                    />
                }
                <br />
                <h3 className="p-2 bg-secondary title">Periodos</h3>
                <br />
                {valEmp === 'fage' &&
                    <div className="d-flex flex-row justify-content-start mb-2">
                        <a
                            href={`/#/lfg/nomina/${id_emp[6]}/periodo_nomina/crear/${id_emp[8]}`}
                            className='btn btn-primary'
                        >
                            Crear Periodo
                        </a>
                        <a
                            href={`/#/lfg/nomina/${id_emp[6]}`}
                            className='btn btn-secondary ml-2'
                        >
                            Regresar
                        </a>
                    </div>
                }
                {valEmp === '' &&
                    <div className="d-flex flex-row justify-content-start mb-2">
                        <a
                            href={`/#/nomina/${id_emp[5]}/periodo_nomina/crear/${id_emp[7]}`}
                            className='btn btn-primary'
                        >
                            Crear Periodo
                        </a>
                        <a
                            href={`/#/nomina/${id_emp[5]}`}
                            className='btn btn-secondary ml-2'
                        >
                            Regresar
                        </a>
                    </div>
                }
                {data &&
                    <Grid 
                        hover 
                        striped 
                        data={data} 
                        loading={loader} 
                        headerStyle={ { background: 'black' } } 
                        //onPageChange={listar} 
                        //onSortChange={onSortChange} 
                    >
                        <TableHeaderColumn
                            isKey
                            dataField="periodo_nomina"
                            headerAlign="center"
                            width="100"
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Periodo
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="numero"
                            dataAlign='right'
                            width="100"
                            headerAlign="center"
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Correlativo
                        </TableHeaderColumn>
                        {valEmp === 'fage' &&
                            <TableHeaderColumn
                                thStyle={
                                {color: 'white'}}
                                dataField="id"
                                width="100"
                                dataAlign="center"
                                dataSort
                                dataFormat={standardActions({ 
                                    editar_periodo_nomina_lfg: "periodo_nomina", 
                                    ver_lfg: "periodo_nomina",
                                    empresa: id_emp[6],
                                    periodo: id_emp[8],
                                    modulo: id_emp[5],
                                })} 
                                
                            >
                                Acciones
                            </TableHeaderColumn>
                        }
                        {valEmp === '' &&
                            <TableHeaderColumn
                                thStyle={
                                {color: 'white'}}
                                dataField="id"
                                width="100"
                                dataAlign="center"
                                dataSort
                                dataFormat={standardActions({ 
                                    editar_periodo_nomina: "periodo_nomina", 
                                    ver_a: "periodo_nomina",
                                    empresa: id_emp[5],
                                    periodo: id_emp[7],
                                    modulo: id_emp[4],
                                })} 
                                
                            >
                                Acciones
                            </TableHeaderColumn>
                        }
                    </Grid>
                }
            </React.Fragment>
        );
    }
}

export default PeriodosList;
