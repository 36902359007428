import React, { Component } from 'react';
import Formulario from './FormSeleccionarPais';


class SeleccionarPais extends Component{

    componentDidMount = () => {
        const { paisList } = this.props;
        paisList();
    }
    render(){
        const { pais } = this.props;
        return(
           
            <Formulario
                pais={pais}
            />
           
        );
    } 
}

export default SeleccionarPais;