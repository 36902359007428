import React, { useEffect, useRef, useState } from "react";
import FormDialogModal from "../../components/FormDialogModal";
import DataTableWSearch from "../../components/DataTableWSearch";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import GenBtn from "../../components/GenBtn";
import { FicInput } from "../../Utils/FormInputComponents";
import * as Fa from "react-icons/fa";

const schema = yup.object({
    tipo: yup.string().required("El tipo es requerido"),
});

function TipoList(props) {
    const {
        handleSubmit,
        reset,
        control,
        formState: { errors },
    } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: {
            tipo: "",
        },
    });

    const {
        handleSubmit: editSubmit,
        reset: editReset,
        control: editControl,
        formState: { errors: editErrors },
    } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: {
            tipo: "",
        },
    });

    const { crearTipo, listarTipos, editarTipo, data } = props;
    const dialogRef = useRef(null);
    const editDialogRef = useRef(null);
    const [tipoFieldData, setTipoFieldData] = useState();
    const [tipoId, setTipoId] = useState();

    useEffect(() => {
        listarTipos();
    }, []);

    async function onSubmit(formData) {
        await crearTipo(formData);
        reset();
        listarTipos();
    }

    async function onEditSubmit(formData) {
        await editarTipo(formData, tipoId);
        listarTipos();
        editDialogRef.current.close();
        editReset();
    }

    const columns = [
        {
            name: "Nombre",
            selector: (row) => row.nombre,
            sortable: true,
        },
        {
            cell: (row) => (
                <GenBtn
                    key={row.nombre}
                    textColor="hsl(0, 0%, 80%)"
                    background="hsla(0, 100%, 100%, 0.2)"
                    onPress={(e) => {
                        editDialogRef.current.showModal();
                        setTipoFieldData(row.nombre);
                        setTipoId(row.id);
                    }}
                >
                    <Fa.FaPen />
                </GenBtn>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    return (
        <React.Fragment>
            <br />
            <h3
                className="p-2  title rounded shadow"
                style={{
                    background:
                        "linear-gradient(to top right, #790021, #2d2d2d)",
                }}
            >
                Tipo
            </h3>
            <br />
            <div className="d-flex flex-row justify-content-start mb-2">
                <GenBtn
                    onClick={() => {
                        dialogRef.current.showModal();
                    }}
                >
                    Agregar
                </GenBtn>
            </div>

            <DataTableWSearch
                columns={columns}
                data={data}
                fieldToFilter="nombre"
                filterPlaceHolder="Buscar por nombre"
            />

            <FormDialogModal
                dialogRef={dialogRef}
                onSubmit={handleSubmit(onSubmit)}
                title={"Agregar Tipo"}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "15px",
                        width: "25rem",
                        height: "10rem",
                        padding: "15px",
                    }}
                >
                    <FicInput
                        name="tipo"
                        placeholder="Tipo"
                        control={control}
                    />
                    <div className="d-flex justify-content-end mt-auto">
                        <GenBtn
                            type="submit"
                            style={{
                                width: "90px",
                                height: "40px",
                                marginBottom: "8px",
                            }}
                        >
                            Agregar
                        </GenBtn>
                    </div>
                </div>
            </FormDialogModal>

            <FormDialogModal
                dialogRef={editDialogRef}
                onSubmit={editSubmit(onEditSubmit)}
                title={"Modificar tipo"}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "15px",
                        width: "25rem",
                        height: "10rem",
                        padding: "15px",
                    }}
                >
                    <FicInput
                        name="tipo"
                        placeholder={`${tipoFieldData}`}
                        control={editControl}
                    />
                    <div className="d-flex justify-content-end mt-auto">
                        <GenBtn
                            type="submit"
                            style={{
                                width: "90px",
                                height: "40px",
                                marginBottom: "8px",
                            }}
                        >
                            Guardar
                        </GenBtn>
                    </div>
                </div>
            </FormDialogModal>
        </React.Fragment>
    );
}

export default TipoList;
