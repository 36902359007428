import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/produccion/centroTrabajo';
import Centros from './CentroTrabajoList';


const ms2p = (state) => {
    return {
        ...state.centro_trabajo,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Centros);