import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import LoginForm from './LoginRegistroForm';
import LoadMask from "Utils/LoadMask/LoadMask";

class LoginRegistro extends Component {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
    };

    render() {
        const { loginRegistro, loader } = this.props;
        
        if (localStorage.getItem('token')) {
            return (<Redirect to="/pro/registro" />);
        }
        
        return (
            <div className="blue-gradient-bg">
                <div className="d-flex flex-column align-items-center pt-3 bienvenida title">
                    <img className="mt-5 imageReg"
                        src={require('assets/img/SIFW-LOGO-Red.png')}
                        alt="Logo" /> 
                </div>
                <br />
                <div className="login-wrapper">
                    <div className="card2 card border-0 px-4 py-5 bg-dark col-lg-6 col-md-4 col-11">
                        <h5 className="text-center pv mb-4 title">Iniciar sesión</h5>
                        <LoadMask loading={loader} dark>
                            <LoginForm onSubmit={loginRegistro} />
                        </LoadMask>
                    </div>
                </div>
            </div>
        );
    }
}

export default LoginRegistro;
