import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import ModalG from '../../../components/components/ModalC';

import {
    renderField,
    renderDayPickerB,
    SelectField,
    AsyncSelectField,
    renderCurrencyFloat,
    renderCurrencyFloatG,
    renderNumber,
    renderFieldCheckB,
    renderCurrencyFloatA
} from "../../Utils/renderField/renderField";

function ImportacionFormGastosLocales(props) {
    const {
        crear,
        lectura,
        bloqueo,
        editar,
        tipos,
        listarProveedores,
        listarCuentas,
        registroProveedor,
        impuesto,
        periodoD,
        obtenerPaises,
        datos,
        detalleLiq
    } = props;

    let autoFocus = true;
        
    const [combustible, setCombustible] = useState(false);
    const [iva, setIva] = useState(true);
    const [base, setBase] = useState(0.00);
    const [tIva, setTIva] = useState(0.00);
    const [total, setTotal] = useState(0.0);

    const [bienes, setBienes] = useState(0.00);
    const [servicios, setServicios] = useState(0.00);
    const [exento, setExento] = useState(0.00);

    const [ivaBienes, setIvaBienes] = useState(0.00);
    const [ivaServicios, setIvaServicios] = useState(0.00);

    const [baseBienes, setBaseBienes] = useState(0.00);
    const [baseServicios, setBaseServicios] = useState(0.00);

    const [gSuper, setGsuper] = useState(0.00);
    const [gRegular, setGregular] = useState(0.00);
    const [gDiesel, setGdiesel] = useState(0.00);

    const [cSuper, setCsuper] = useState(0.00);
    const [cRegular, setCregular] = useState(0.00);
    const [cDiesel, setCdiesel] = useState(0.00);
    const [cOtros, setCotros] = useState(0.00);

    const [baseSuper, setBaseSuper] = useState(0.00);
    const [baseRegular, setBaseRegular] = useState(0.00);
    const [baseDiesel, setBaseDiesel] = useState(0.00);

    const [ivaSuper, setIvaSuper] = useState(0.00);
    const [ivaRegular, setIvaRegular] = useState(0.00);
    const [ivaDiesel, setIvaDiesel] = useState(0.00);

    const [idpSuper, setIdpSuper] = useState(0.00);
    const [idpRegular, setIdpRegular] = useState(0.00);
    const [idpDiesel, setIdpDiesel] = useState(0.00);
    const [ivaOtros, setIvaOtros] = useState(0.00);

    const [tComb, setTComb] = useState(0.00);

    const [idp, setIdp] = useState(0.00);
    const [tipoD, setTipoD] = useState(0);

    const [estadoModalG, cambiarEstadoModalG] = useState(false);

    const onCheckboxClickedIva = (idx, isChecked) => {
        setIva(isChecked);
    }

    const onCheckboxClickedCombustible = (idx, isChecked) => {
        setCombustible(isChecked);
    }
    
    const handleChange = (e) => {
        let existe = tipos.find(element => element.value === e.target.value);
        localStorage.setItem('tipoDocumentoLiqImp', e.target.value);

        setTipoD(e.target.value);
        if (existe.search === 'IVA') {
            setIva(true);
            localStorage.setItem('A_ivaLiqImp', true);
        }
        if (existe.search === 'COMP') {
            setIva(false);
            localStorage.setItem('A_ivaLiqImp', false);
        }
        if (existe.search === 'PEQ') {
            setIva(false);
            localStorage.setItem('A_ivaLiqImp', false);
        }

    }
    const tipoProveedor = [
        {
            value: 'L',
            label: 'LOCAL'
        },
        {
            value: 'E',
            label: 'EXTERIOR'
        },
    ];
   
    let PIva = parseFloat(parseFloat(parseFloat(impuesto.porcentaje_iva)/100)+1).toFixed(2);
    let CGSuper = parseFloat(impuesto.costo_galon_super).toFixed(2);
    let CGRegular = parseFloat(impuesto.costo_galon_regular).toFixed(2);
    let CGDiesel = parseFloat(impuesto.costo_galon_diesel).toFixed(2);

    let detalle = localStorage.getItem('LIQ_Imp');
    
    return (
        <div>
            <div className='row mb-2'>
                <div className='col-2'>
                    <div className="form-floating">
                        <Field
                            name="serie"
                            placeholder="Serie"
                            className="form-control"
                            component={renderField}
                            disabled={true}
                            msj="Serie"
                        />
                    </div>
                </div>
                <div className='col-2'>
                    <div className="form-floating">
                        <Field
                            name="numeroL"
                            placeholder="Numero"
                            className="form-control"
                            component={renderField}
                            disabled={true}
                            msj="Numero"
                        />
                    </div>
                </div>
                {crear === true &&
                    <div className='mt-2'>
                        <div>
                            {detalleLiq.length === 0 &&
                                <div className='row'>
                                    <div className='col-9'>
                                        <Field
                                            name="proveedor_liquidacion"
                                            placeholder="Proveedor..."
                                            className="form-control-find"
                                            component={AsyncSelectField}
                                            loadOptions={listarProveedores}
                                            msj="Proveedor"
                                        />
                                    </div>
                                    <div className='col-3 mt-2'>
                                        <button
                                            className='btn mr-2 mb-3 btn-secondary'
                                            onClick={() => cambiarEstadoModalG(!estadoModalG)}
                                        >
                                            Crear nuevo proveedor
                                        </button>
                                    </div>
                                </div>
                            }
                        </div>
                        {detalleLiq.length >= 1 &&
                            <Field
                                name="proveedorN"
                                disabled={true}
                                placeholder="Proveedor"
                                className="form-control"
                                component={renderField}
                                msj="Proveedor"
                            />
                        }
                        
                    </div>
                }
                {editar === true &&
                    <div className='col-8'>
                        <div className="form-floating">
                            <Field
                                name="proveedorN"
                                disabled={true}
                                placeholder="Proveedor"
                                className="form-control"
                                component={renderField}
                                msj="Proveedor"
                            />
                        </div>
                    </div>
                }
            </div>
            <div className='row mb-2'>
                {editar === true &&
                    <div className='col-9'>
                        <Field
                            name="proveedor_liquidacion"
                            placeholder="Proveedor..."
                            className="form-control-find"
                            component={AsyncSelectField}
                            loadOptions={listarProveedores}
                            msj="Proveedor"
                        />
                    </div>
                }
                <div className='col-9'>
                    {crear === false && editar == false &&
                        <Field
                            name="proveedorN"
                            placeholder="Identificador"
                            className="form-control"
                            component={renderField}
                            disabled={bloqueo}
                            msj="Proveedor"
                        />
                    }
                    
                </div>
                {crear === false && editar == false &&
                    <div className='col-3'>
                        <div className="form-floating">
                            <Field
                                name="totalLiq"
                                placeholder="Total Gastos"
                                className="form-control"
                                component={renderCurrencyFloat}
                                disabled={true}
                                msj="Total Gastos"
                            />
                        </div>
                    </div>
                }
            </div>
            <div className='mb-2'>
                <Field
                    name="observaciones"
                    placeholder="Descripcion"
                    component={renderField}
                    className="form-control"
                    disabled={bloqueo}
                    msj="Observaciones"
                />
            </div>
            <h5 className="p-2 bg-secondary title mt-1">Documentos</h5>
            {(crear === true || editar == true) &&
                <div>
                    <div className='row mb-2'>
                        <div className='col-3'>
                            {tipos &&
                                <div className="form-floating">
                                    <select className="form-select" id="tipo" name="tipo"
                                        value={tipoD} onChange={handleChange} >
                                        <option selected>Seleccionar...</option>
                                        {tipos.map(
                                            tip => <option key={tip.value} value={tip.value}
                                            >{tip.label}
                                            </option>)
                                        }
                                    </select>
                                    <label className='form-label' htmlFor="mes">Tipo de documento</label>
                                </div>
                            }
                        </div>
                        <div className='col-3'>
                            <Field
                                disabled={bloqueo}
                                name="fecha"
                                component={renderDayPickerB}
                                msj="Fecha"
                                maxDate={periodoD.fecha_fin}
                                
                            />
                        </div>
                        <div className='col-3'>
                            <Field
                                name="serieD"
                                placeholder="Serie"
                                className="form-control"
                                component={renderField}
                                disabled={bloqueo}
                                msj="Serie"
                            />
                        </div>
                        <div className='col-3'>
                            <Field
                                name="numeroD"
                                placeholder="Numero"
                                className="form-control"
                                component={renderNumber}
                                disabled={bloqueo}
                                onChange={(e, value) => {
                                    if (value) {
                                        localStorage.setItem('LIQ_Imp', false);
                                        detalle = localStorage.getItem('LIQ_Imp');
                                        setBase(0.00);
                                        setTIva(0.00);
                                        setTotal(0.00);
                                        setBienes(0.00);
                                        setServicios(0.00);
                                        setExento(0.00);
                                        setIdp(0.00);
                                        setBaseBienes(0.00);
                                        setBaseServicios(0.00);
                                        setIvaBienes(0.00);
                                        setIvaServicios(0.00);

                                        setBaseSuper(0.00);
                                        setBaseRegular(0.00);
                                        setBaseDiesel(0.00);
                                        setIvaSuper(0.00);
                                        setIvaRegular(0.00);
                                        setIvaDiesel(0.00);
                                        setIvaOtros(0.00);
                                        setIdpSuper(0.00);
                                        setIdpRegular(0.00);
                                        setIdpDiesel(0.00);
                                        setGsuper(0.00);
                                        setGregular(0.00);
                                        setGdiesel(0.00);
                                        setCsuper(0.00);
                                        setCregular(0.00);
                                        setCdiesel(0.00)
                                        setCotros(0.00);
                                        setTComb(0.00);
                                        setTotal(0.00);
                                    }
                                }}
                                msj="Numero"
                            />
                        </div>
                    </div>
                    
                    <fieldset>
                        <div className='row'>
                            <div className='w-50'>
                                <Field
                                    name="proveedor_gasto"
                                    placeholder="Proveedor..."
                                    className="form-control-find"
                                    component={AsyncSelectField}
                                    loadOptions={listarProveedores}
                                    msj="Proveedor"
                                />
                            </div>
                            <div className='col-3 mt-2'>
                                <button
                                    className='btn mr-2 mb-3 btn-secondary'
                                    onClick={() => cambiarEstadoModalG(!estadoModalG)}
                                >
                                    Crear nuevo proveedor
                                </button>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <div className='row mb-2'>
                            <div className='col-3 mt-3'>
                                <Field
                                    name="afecta_iva"
                                    checked={iva}
                                    iva={true}
                                    label="Afecta iva"
                                    component={renderFieldCheckB}
                                    onChange={onCheckboxClickedIva}
                                />
                            </div>
                            <div className='col-3 mt-3'>
                                <Field
                                    name="combustible"
                                    checked={combustible}
                                    label="Combustible"
                                    component={renderFieldCheckB}
                                    onChange={onCheckboxClickedCombustible}
                                />
                            </div>
                            <div className='col-6'>
                                <Field
                                    name="cuenta"
                                    placeholder="Cuenta contable..."
                                    className="form-control-find"
                                    component={AsyncSelectField}
                                    loadOptions={listarCuentas}
                                    msj="Cuenta"
                                />
                            </div>
                        </div>
                    </fieldset>

                    {combustible &&
                        <div>
                            <div className='row mb-2'>
                                <div className='col-3'>
                                    <div className="form-floating">
                                        <Field
                                            name="gsuper"
                                            placeholder="Nombre"
                                            className="form-control"
                                            component={renderCurrencyFloatG}
                                            disabled={bloqueo}
                                            onChange={(e, value) => {
                                                if (value) {
                                                    let totSuper = parseFloat(parseFloat(value) * CGSuper).toFixed(2);
                                                    let Tidp = parseFloat(parseFloat(totSuper) + parseFloat(idpRegular) + parseFloat(idpDiesel)).toFixed(2);
                                                    setIdpSuper(totSuper);
                                                    setIdp(Tidp);
                                                    setGsuper(totSuper);
                                                } else {
                                                    setGsuper(0.00);
                                                    setIdpSuper(0.00);
                                                }
                                            }}
                                            msj="Galones super"
                                        />
                                    </div>
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="csuper"
                                        placeholder="Nombre"
                                        className="form-control"
                                        component={renderCurrencyFloat}
                                        disabled={bloqueo}
                                        onChange={(e, value) => {
                                            if (value) {
                                                let baseS = (parseFloat(value) - parseFloat(gSuper)) / PIva;
                                                let ivaSup = (parseFloat(value) - parseFloat(idpSuper)) - parseFloat(baseS);
                                                let totalB = parseFloat(parseFloat(baseS) + parseFloat(baseRegular) + parseFloat(baseDiesel)).toFixed(2);
                                                let totI = parseFloat(parseFloat(ivaSup) + parseFloat(ivaRegular) + parseFloat(ivaDiesel) + parseFloat(ivaOtros)).toFixed(2);
                                                let totCombus = parseFloat(parseFloat(value) + parseFloat(cRegular) + parseFloat(cDiesel) + parseFloat(cOtros)).toFixed(2);
                                                setBase(totalB);
                                                setBaseSuper(baseS);
                                                setIvaSuper(ivaSup);
                                                setTIva(totI);
                                                setTComb(totCombus);
                                                setTotal(totCombus);
                                                setCsuper(value);


                                            } else {
                                                setTIva(0.00);
                                                setBase(0.00);
                                                setBaseSuper(0.00);
                                                setTotal(0.00);
                                                setTComb(0.00);
                                                setCsuper(0.00);
                                            }
                                        }}
                                        msj="Total factura"
                                    />
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-3'>
                                    <div className="form-floating">
                                        <Field
                                            name="gregular"
                                            placeholder="Nombre"
                                            className="form-control"
                                            component={renderCurrencyFloatG}
                                            disabled={bloqueo}
                                            onChange={(e, value) => {
                                                if (value) {
                                                    let totRegular = parseFloat(parseFloat(value) * CGRegular).toFixed(2);
                                                    let Tidp = parseFloat(parseFloat(totRegular) + parseFloat(idpSuper) + parseFloat(idpDiesel)).toFixed(2);
                                                    setIdpRegular(totRegular);
                                                    setIdp(Tidp);
                                                    setGregular(totRegular);
                                                } else {
                                                    setGregular(0.00);
                                                    setIdpRegular(0.00);
                                                }

                                            }}
                                            msj="Galones regular"
                                        />
                                    </div>
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="cregular"
                                        placeholder="Nombre"
                                        className="form-control"
                                        component={renderCurrencyFloat}
                                        disabled={bloqueo}
                                        onChange={(e, value) => {
                                            if (value) {
                                                let baseR = (parseFloat(value) - parseFloat(gRegular)) / PIva;
                                                let ivaReg = (parseFloat(value) - parseFloat(idpRegular)) - parseFloat(baseR);
                                                let totalB = parseFloat(parseFloat(baseR) + parseFloat(baseSuper) + parseFloat(baseDiesel)).toFixed(2);
                                                let totI = parseFloat(parseFloat(ivaReg) + parseFloat(ivaSuper) + parseFloat(ivaDiesel) + parseFloat(ivaOtros)).toFixed(2);
                                                let totCombus = parseFloat(parseFloat(value) + parseFloat(cSuper) + parseFloat(cDiesel) + parseFloat(cOtros)).toFixed(2);
                                                setBase(totalB);
                                                setBaseRegular(baseR);
                                                setIvaRegular(ivaReg);
                                                setTIva(totI);
                                                setTComb(totCombus);
                                                setTotal(totCombus);
                                                setCregular(value);


                                            } else {
                                                setTIva(0.00);
                                                setBase(0.00);
                                                setBaseRegular(0.00);
                                                setTotal(0.00);
                                                setTComb(0.00);
                                                setCregular(0.00);
                                            }
                                        }}
                                        msj="Total factura"
                                    />
                                </div>
                                <div className='col-3'>
                                    <div className="form-floating">
                                        <Field
                                            name="otros"
                                            placeholder="Otros"
                                            className="form-control"
                                            component={renderCurrencyFloat}
                                            disabled={bloqueo}
                                            onChange={(e, value) => {
                                                if (value) {
                                                    let baseO = parseFloat(parseFloat(value) / PIva).toFixed(2);
                                                    let ivaO = parseFloat(parseFloat(parseFloat(value) - parseFloat(baseO)).toFixed(2)).toFixed(2);
                                                    let totI = parseFloat(parseFloat(ivaRegular) + parseFloat(ivaSuper) + parseFloat(ivaDiesel) + parseFloat(ivaO)).toFixed(2);
                                                    let totalB = parseFloat(parseFloat(baseO) + parseFloat(baseRegular) + parseFloat(baseDiesel) + parseFloat(baseSuper)).toFixed(2);
                                                    let totCombus = parseFloat(parseFloat(value) + parseFloat(cRegular) + parseFloat(cDiesel) + parseFloat(cSuper)).toFixed(2);
                                                    setBase(totalB);
                                                    setIvaOtros(ivaO);
                                                    setCotros(value);
                                                    setTIva(totI);
                                                    setTComb(totCombus);
                                                    setTotal(totCombus);

                                                } else {
                                                    setTIva(0.00);
                                                    setCotros(0.00);
                                                }
                                            }}
                                            msj="Otros"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-3'>
                                    <div className="form-floating">
                                        <Field
                                            name="gdiesel"
                                            placeholder="Nombre"
                                            className="form-control"
                                            component={renderCurrencyFloatG}
                                            disabled={bloqueo}
                                            onChange={(e, value) => {
                                                if (value) {
                                                    let totDiesel = parseFloat(parseFloat(value) * CGDiesel).toFixed(2);
                                                    let Tidp = parseFloat(parseFloat(totDiesel) + parseFloat(idpSuper) + parseFloat(idpRegular)).toFixed(2);
                                                    setIdpDiesel(totDiesel);
                                                    setGdiesel(totDiesel);
                                                    setIdp(Tidp)
                                                } else {
                                                    setGdiesel(0.00);
                                                    setIdpDiesel(0.00);
                                                }

                                            }}
                                            msj="Galones diesel"
                                        />
                                    </div>
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="cdiesel"
                                        placeholder="Nombre"
                                        className="form-control"
                                        component={renderCurrencyFloat}
                                        disabled={bloqueo}
                                        onChange={(e, value) => {
                                            if (value) {
                                                let baseD = parseFloat((parseFloat(value) - parseFloat(gDiesel)) / PIva).toFixed(2);
                                                let ivaDie = parseFloat((parseFloat(value) - parseFloat(idpDiesel)) - parseFloat(baseD)).toFixed(2);
                                                let totalB = parseFloat(parseFloat(baseD) + parseFloat(baseRegular) + parseFloat(baseSuper)).toFixed(2);
                                                let totI = parseFloat(parseFloat(ivaDie) + parseFloat(ivaSuper) + parseFloat(ivaRegular) + parseFloat(ivaOtros)).toFixed(2);
                                                let totCombus = parseFloat(parseFloat(value) + parseFloat(cRegular) + parseFloat(cSuper)).toFixed(2);
                                                setBase(totalB);
                                                setBaseDiesel(baseD);
                                                setIvaDiesel(ivaDie);
                                                setTIva(totI);
                                                setTComb(totCombus);
                                                setTotal(totCombus);
                                                setCdiesel(value);


                                            } else {
                                                setTIva(0.00);
                                                setBase(0.00);
                                                setTotal(0.00);
                                                setTComb(0.00);
                                            }
                                        }}
                                        msj="Total factura"
                                    />
                                </div>
                                <div className='col-3'>
                                    <div className="form-floating">
                                        <Field
                                            name="idp"
                                            placeholder="Numero"
                                            className="form-control"
                                            component={renderCurrencyFloatA}
                                            disabled={true}
                                            dato={idp}
                                            detail={detalle}
                                            msj="IDP"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {combustible === false &&
                        <div className='row mb-2'>
                            <div className='col-4'>
                                <Field
                                    name="bienes"
                                    placeholder="Bienes"
                                    className="form-control"
                                    component={renderCurrencyFloat}
                                    disabled={bloqueo}
                                    onChange={(e, value) => {
                                        if (iva) {
                                            if (value) {
                                                localStorage.setItem('LIQ_Imp', false);
                                                detalle = localStorage.getItem('LIQ_Imp');
                                                let baseB = parseFloat(value) / PIva;
                                                setBaseBienes(baseB);
                                                let ivaB = parseFloat(value) - parseFloat(baseB);
                                                setIvaBienes(ivaB);
                                                let TotalB = parseFloat(baseB) + parseFloat(baseServicios);
                                                let TotalIva = parseFloat(ivaB) + parseFloat(ivaServicios);
                                                let TotalF = parseFloat(value) + parseFloat(servicios) + parseFloat(exento);
                                                setBase(TotalB);
                                                setBienes(value);
                                                setTIva(TotalIva);
                                                setTotal(TotalF);

                                            } else {
                                                setBase(0.00)
                                                setBienes(0.00);
                                                setTIva(0.00)
                                            }
                                        }
                                        if (iva === false) {

                                            if (value) {
                                                setTotal(0.00);
                                                setBase(0.00);
                                                setTIva(0.00);
                                                setBienes(0.00);
                                                let TotalFSI = parseFloat(value) + parseFloat(servicios) + parseFloat(exento);
                                                setBienes(TotalFSI);
                                                setBase(TotalFSI);
                                                setTIva(0.00);
                                                setTotal(TotalFSI);
                                            } else {
                                                setBase(0.00)
                                                setBienes(0.00);
                                                setTIva(0.00)

                                            }
                                        }
                                    }}
                                    msj="Bienes"
                                />
                            </div>
                            <div className='col-4'>
                                <Field
                                    name="servicios"
                                    placeholder="Servicios"
                                    className="form-control"
                                    component={renderCurrencyFloat}
                                    disabled={bloqueo}
                                    onChange={(e, value) => {
                                        if (iva) {
                                            if (value) {
                                                localStorage.setItem('LIQ_Imp', false);
                                                detalle = localStorage.getItem('LIQ_Imp');
                                                let baseSe = parseFloat(value) / PIva;
                                                setBaseServicios(baseSe);
                                                let ivaS = parseFloat(value) - parseFloat(baseSe);
                                                setIvaServicios(ivaS);
                                                let TotalSe = parseFloat(baseSe) + parseFloat(baseBienes);
                                                let TotalIva = parseFloat(ivaS) + parseFloat(ivaBienes);
                                                let TotalF = parseFloat(value) + parseFloat(bienes) + parseFloat(exento);
                                                setServicios(value);
                                                setBase(TotalSe);
                                                setTIva(TotalIva);
                                                setTotal(TotalF);

                                            } else {
                                                setServicios(0.00);
                                                setBase(0.00)
                                                setTIva(0.00)
                                            }
                                        }
                                        if (iva === false) {
                                            if (value) {
                                                setTotal(0.00);
                                                setBase(0.00);
                                                setTIva(0.00);
                                                setServicios(0.00);
                                                let TotalFSI = parseFloat(value) + parseFloat(bienes) + parseFloat(exento);
                                                setServicios(TotalFSI);
                                                setBase(TotalFSI);
                                                setTIva(0.00);
                                                setTotal(TotalFSI);
                                            } else {
                                                setServicios(0.00);
                                                setBase(0.00)
                                                setTIva(0.00)
                                            }
                                        }
                                    }}
                                    msj="Servicios"
                                />
                            </div>
                            <div className='col-4'>
                                <Field
                                    name="exento"
                                    placeholder="No afecto"
                                    className="form-control"
                                    component={renderCurrencyFloat}
                                    disabled={bloqueo}
                                    onChange={(e, value) => {
                                        if (value) {
                                            let exento = parseFloat(value) + parseFloat(servicios) + parseFloat(bienes)
                                            setExento(value)
                                            setTotal(exento);
                                            if (bienes === 0.00 && servicios === 0.00) {
                                                setBase(0.00);
                                                setTIva(0.00);
                                            }

                                        } else {
                                            let TotalFSI = parseFloat(value) + parseFloat(bienes) + parseFloat(servicios);
                                            setExento(TotalFSI);
                                            setTIva(0.00);
                                            setTotal(TotalFSI);
                                        }
                                    }}
                                    msj="No afecto"
                                />
                            </div>
                        </div>
                    }

                    <div className='row mb-2'>
                        <div className='col-4'>
                            <div className="form-floating">
                                <Field
                                    name="base"
                                    placeholder="Numero"
                                    className="form-control"
                                    component={renderCurrencyFloatA}
                                    disabled={true}
                                    dato={base}
                                    detail={detalle}
                                    msj="Base"
                                />
                            </div>
                        </div>
                        <div className='col-4'>
                            <div className="form-floating">
                                <Field
                                    name="iva"
                                    placeholder="Numero"
                                    className="form-control"
                                    component={renderCurrencyFloatA}
                                    disabled={true}
                                    dato={tIva}
                                    detail={detalle}
                                    msj="Iva"
                                />
                            </div>
                        </div>
                        <div className='col-4'>
                            <div className="form-floating">
                                <Field
                                    name="total"
                                    placeholder="Numero"
                                    className="form-control"
                                    component={renderCurrencyFloatA}
                                    disabled={true}
                                    dato={total}
                                    detail={detalle}
                                    msj="Total"
                                />
                            </div>
                        </div>
                    </div>

                    <div className='row mb-2'>
                        <div className='col-8'>
                            <div className='mb-2'>
                                <Field
                                    name="descripcion"
                                    placeholder="Descripcion"
                                    component={renderField}
                                    className="form-control"
                                    disabled={bloqueo}
                                    msj="Descripcion"
                                />
                            </div>
                        </div>
                        <div className='col-4'>
                            <div className="form-floating">
                                <Field
                                    name="totalLiq"
                                    placeholder="Total Gastos"
                                    className="form-control"
                                    component={renderCurrencyFloat}
                                    disabled={true}
                                    msj="Total Gastos"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }
            <ModalG
                estado={estadoModalG}
                cambiarEstado={cambiarEstadoModalG}
                titulo="Crear proveedor"
                botonCerrar="true"
            >
                <div className="d-flex flex-column flex-1 mx-4">
                    <div className='mb-2 w-25'>
                        <Field
                            autoFocus={autoFocus}
                            name="identificadorPro"
                            placeholder="Identificador"
                            className="form-control"
                            component={renderField}
                            msj="Identificador"
                        />
                    </div>
                    <div className='mb-2 w-50'>
                        <Field
                            name="nombre"
                            placeholder="Nombre"
                            className="form-control"
                            component={renderField}
                            msj="Nombre"
                        />
                    </div>
                    <div className='mb-2 w-75'>
                        <Field
                            name="direccion"
                            placeholder="Direccion"
                            className="form-control"
                            component={renderField}
                            msj="Dirección"
                        />
                    </div>
                    <div className='row'>
                        <div className='col-4'>
                            <Field
                                name="telefono"
                                placeholder="Telefono"
                                className="form-control"
                                component={renderField}
                                msj="Telefono"
                            />
                        </div>
                        <div className='col-4'>
                            <Field
                                name="correo"
                                placeholder="Correo electrónico"
                                className="form-control"
                                component={renderField}
                                msj="Correo electronico"
                            />
                        </div>
                        <div className='col-4'>
                            <Field
                                name="dias_credito"
                                placeholder="Dias credito"
                                className="form-control"
                                component={renderNumber}
                                msj="Dias crédito"
                            />
                        </div>
                    </div>
                    <div className='row mt-2'>
                        <div className='col-4'>
                            <Field
                                name="limite_credito"
                                placeholder="Limite credito"
                                className="form-control"
                                component={renderNumber}
                                msj="Limite crédito"
                            />
                        </div>
                        <div className='col-4'>
                            <div className="form-floating">
                                <Field
                                    name="tipo_proveedor"
                                    options={tipoProveedor}
                                    dato={crear === true ? 0 : lectura.tipo_proveedor}
                                    component={SelectField}
                                    className="form-select"
                                    msj="Tipo proveedor"
                                />
                            </div>
                        </div>
                        <div className='col-4'>
                            {obtenerPaises &&
                                <div className="form-floating">
                                    <Field
                                        name="pais"
                                        options={obtenerPaises}
                                        crear={crear}
                                        dato={0}
                                        component={SelectField}
                                        className="form-select"
                                        msj="Pais"
                                    />
                                </div>
                            }
                        </div>
                    </div>
                    <div className='d-flex flex-row mt-3'>
                        <button
                            className='btn mr-2 mb-3 btn-primary'
                            onClick={() => registroProveedor(datos)}
                        >
                            Guardar
                        </button>

                        <button
                            className='btn mr-2 mb-3 btn-secondary'
                            onClick={() => cambiarEstadoModalG(false)}
                        >
                            Salir
                        </button>
                    </div>
                </div>
            </ModalG>
        </div>
    );
}

ImportacionFormGastosLocales.propTypes = {
    listarProveedores: PropTypes.object,
    crear: PropTypes.bool,
    lectura: PropTypes.object,
    tipos: PropTypes.object,
    editar: PropTypes.bool,
    bloqueo: PropTypes.bool,
    listarCuentas: PropTypes.object,
    listarProveedoresD: PropTypes.object,
    registroProveedor: PropTypes.func
};

export default reduxForm({
    form: 'FormImportacionGastos',
})(ImportacionFormGastosLocales);
