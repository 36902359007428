import React, { Component } from 'react';
import Formulario from './productoComboForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";

class ProductoCombo extends Component{
    state = {
        crear: true,
    }

    componentDidMount = () => {
        const { match, leerCombo, getModulo, leerDetalleCombo } = this.props;
        const id = match.params.id;
        if (id){
            this.setState({crear: false});
            leerCombo(id);
        }
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        getModulo(modulo[5],modulo[4]);
        leerDetalleCombo(modulo[5], modulo[7]);
    }
    
    render(){
        const { registroCombo, loader, modulo, listarProductos, 
            detalleProducto, confirmarCombo, eliminarLineaProducto } = this.props;
        const { crear } = this.state;
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    registroCombo = {registroCombo}
                    crear = {crear}
                    modulo = {modulo}
                    listarProductos = {listarProductos}
                    detalleProducto = {detalleProducto}
                    confirmarCombo = {confirmarCombo}
                    eliminarLineaProducto = {eliminarLineaProducto}
                />
            </LoadMask>
        );
    }
}

export default ProductoCombo
