import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/recursos_humanos/altaIgss';
import ListAlta from './AltaList';


const ms2p = (state) => {
  return {
    ...state.alta_igss_rh,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ListAlta);