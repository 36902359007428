import React, { Component } from 'react';
import Formulario from './ObservacionesForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";

class Observaciones extends Component{
    state = {
        crear: true,
    }

    componentDidMount = () => {
        const { match, leer, getModulo, limpiarDatos } = this.props;
        const id = match.params.id;
        if (id){
            this.setState({crear: false});
            leer(id);
        }
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        getModulo(modulo[5],modulo[4]);
        limpiarDatos();
    }
    
    render(){
        const { registroObservacion, loader, modulo, listarMarcas,
            datosMarca, detalleObs
        } = this.props;
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    registroObservacion = {registroObservacion}
                    modulo = {modulo}
                    listarMarcas = {listarMarcas}
                    datosMarca = {datosMarca}
                    detalleObs = {detalleObs}
                />
            </LoadMask>
        );
    }
}


export default Observaciones;
