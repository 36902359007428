import { handleActions } from 'redux-actions';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import moment from 'moment';
import Swal from 'sweetalert2';
const valEmp = __CONFIG__.empresa;

const LOADER = 'LOADER';
const MODULO = 'MODULO';
const LISTADO_PAISES = 'LISTADO_PAISES';
const LISTADO_PERIODOS = 'LISTADO_PERIODOS';
const LISTADO_PRODUCTOS = 'LISTADO_PRODUCTOS';
const LISTADO_FAMILIA = 'LISTADO_FAMILIA';
const LISTADO_LINEA = 'LISTADO_LINEA';
const LISTADO_GRUPO = 'LISTADO_GRUPO';
const LISTADO_FORMA = 'LISTADO_FORMA';
const LISTADO_PRINCIPIO = 'LISTADO_PRINCIPIO';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const getModulo = (id, modulo) => (dispatch) => {
    const params = { id, modulo }
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response) => {
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


export const listarPaises = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let empresa = '';
    if (valEmp === 'fage'){
        empresa = id_emp[6];
    }else{
        empresa = id_emp[5];
    }
    api.get(`/pais_registro/?id=${empresa}`).then((data)=>{
        const pais = [];
        data.forEach(item=>{
            pais.push({
                value: item.id,
                label: item.paisNombre
            })
        })
        dispatch({ type: LISTADO_PAISES, listaP: pais });
    }).catch(()=>{
        NotificationManager.error(
            'Ocurrió un error al listar paises',
            'Error',
            0
        );
    })
}

export const listarProductos = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params;
    let id = ''
    if (valEmp === 'fage'){
        id = id_emp[6];
        params = { id};
    }else{
        id = id_emp[5];
        params = { id };
    }
    api.get('/rs_producto', params).then(data => {
        const productos = [];
        if (data) {
            data.forEach(item => {
                productos.push({
                    value: item.id,
                    label: item.descripcion
                })
            })
        }
        dispatch({ type: LISTADO_PRODUCTOS, listaPro: productos });
    }).catch((error) => {
        return [];
    })
}

export const reporteRegistrosSanitariosVigentes = (tipoBusqueda) => (dispatch, getStore) => {
    const data = getStore().form.FormInformesRegistroSanitario.values;
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    
    let id = '';
    let params = {};
    let producto = '';
    let pais = '';
    let linea = '';
    let familia = '';
    let forma = '';
    let principio = '';
    let grupo = '';

    if (data === undefined){
        let reporte = 'reporteRegistrosSanitariosVigentes';
        id = id_emp[5];
        params = { id, reporte, producto, pais, tipoBusqueda, linea, familia, forma, principio, grupo};
        dispatch(setLoader(true));
        api.getPdf('/rs_registro_sanitario', params).then((response) => {
            if (response.headers['content-type'] == 'application/pdf') {
                var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = 'registros_sanitarios_vigentes.pdf';
                window.open(link, '_blank');
                return false;
            }
        }).catch((error) => {
            if (error.status === 404) {
                NotificationManager.error(
                    error.body.msj,
                    'Error',
                    3000
                );
            }
            if (error.status === 500) {
                NotificationManager.error(
                    'Ocurrió un error al generar reporte',
                    'Error',
                    0
                );
            }
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }else{
        if (data.producto) {
            producto = data.producto.value;
        }else{
            producto = '';
        }
        if (data.pais) {
            if (data.pais === 0) {
                pais = '';
            }else{
                pais = data.pais;
            }
        }else{
            pais = '';
        }
        if (data.busqueda === '0'){
            linea = '';
            familia = '';
            forma = '';
            principio = '';
            grupo = '';
            tipoBusqueda = '';
        }else{
            linea = data.linea;
            familia =  data.familia;
            forma = data.forma;
            principio = data.principio;
            grupo = data.grupo;
        }
        let reporte = 'reporteRegistrosSanitariosVigentes';
        id = id_emp[5];
        params = { id, reporte, producto, pais, tipoBusqueda, linea, familia, forma, principio, grupo};
        dispatch(setLoader(true));
        api.getPdf('/rs_registro_sanitario', params).then((response) => {
            if (response.headers['content-type'] == 'application/pdf') {
                var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = 'registros_sanitarios_vigentes.pdf';
                window.open(link, '_blank');
                return false;
            }
        }).catch((error) => {
            if (error.status === 404) {
                NotificationManager.error(
                    error.body.msj,
                    'Error',
                    3000
                );
            }
            if (error.status === 500) {
                NotificationManager.error(
                    'Ocurrió un error al generar reporte',
                    'Error',
                    0
                );
            }
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
}

export const reporteRegistrosSanitariosPorVencer = () => (dispatch, getStore) => {
    const data = getStore().form.FormInformesRegistroSanitario.values;
    
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    
    let id = '';
    let params = {};
    let mes = data.mes;
    let mesLetras = moment().month(mes - 1).format('MMMM');
    let year = data.year;
    
    let reporte = 'reporteRegistrosSanitariosVencer';
    if (valEmp === 'fage') {
        id = id_emp[6];
        params = { id, reporte, mes, year, mesLetras };
    } else {
        id = id_emp[5];
        params = { id, reporte, mes, year, mesLetras};
    }
    dispatch(setLoader(true));
    api.getPdf('/rs_registro_sanitario', params).then((response) => {
        if (response.headers['content-type'] == 'application/pdf') {
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'registros_sanitarios_por_vencer.pdf';
            window.open(link, '_blank');
            return false;
        }
    }).catch((error) => {
        if (error.status === 404) {
            NotificationManager.info(
                error.body.msj,
                'Información',
                0
            );
        }
        if (error.status === 500) {
            NotificationManager.error(
                'Ocurrió un error al generar reporte',
                'Error',
                0
            );
        }

    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const listarFamilia = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let empresa = '';
    if (valEmp === 'fage'){
        empresa = id_emp[6];
    }else{
        empresa = id_emp[5];
    }
    api.get(`/familia/?id=${empresa}`).then((data)=>{
        const familia = [];
        data.forEach(item=>{
            familia.push({
                value: item.id,
                label: item.descripcion
            })
        })
        dispatch({ type: LISTADO_FAMILIA, listaFamilia: familia });
    }).catch(()=>{
        NotificationManager.error(
            'Ocurrió un error al listar familias',
            'Error',
            0
        );
    })
}

export const listarLinea = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let empresa = '';
    if (valEmp === 'fage'){
        empresa = id_emp[6];
    }else{
        empresa = id_emp[5];
    }
    api.get(`/linea/?id=${empresa}`).then((data)=>{
        const linea = [];
        data.forEach(item=>{
            linea.push({
                value: item.id,
                label: item.descripcion
            })
        })
        dispatch({ type: LISTADO_LINEA, listaLinea: linea });
    }).catch(()=>{
        NotificationManager.error(
            'Ocurrió un error al listar lineas',
            'Error',
            0
        );
    })
}

export const listarGrupo = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let empresa = '';
    if (valEmp === 'fage'){
        empresa = id_emp[6];
    }else{
        empresa = id_emp[5];
    }
    api.get(`/grupo/?id=${empresa}`).then((data)=>{
        const grupo = [];
        data.forEach(item=>{
            grupo.push({
                value: item.id,
                label: item.descripcion
            })
        })
        dispatch({ type: LISTADO_GRUPO, listaGrupo: grupo });
    }).catch(()=>{
        NotificationManager.error(
            'Ocurrió un error al listar grupos',
            'Error',
            0
        );
    })
}

export const listarForma = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let empresa = '';
    if (valEmp === 'fage'){
        empresa = id_emp[6];
    }else{
        empresa = id_emp[5];
    }
    api.get(`/forma/?id=${empresa}`).then((data)=>{
        const forma = [];
        data.forEach(item=>{
            forma.push({
                value: item.id,
                label: item.descripcion
            })
        })
        dispatch({ type: LISTADO_FORMA, listaForma: forma });
    }).catch(()=>{
        NotificationManager.error(
            'Ocurrió un error al listar formas',
            'Error',
            0
        );
    })
}

export const reporteProductos = () => (dispatch, getStore) => {
    const data = getStore().form.FormInformesRegistroSanitario.values;

    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    
    let id = '';
    let params = {};
    let familia = '';
    let linea = '';
    let grupo = '';
    let forma = '';
    if (data != undefined) {
        if (data.familia) {
            if (data.familia === 0) {
                familia = '';
            }else{
                familia = data.familia;
            }
        }
        if (data.linea) {
            if (data.linea === 0) {
                linea = '';
            }else{
                linea = data.linea;
            }
        }
        if (data.grupo) {
            if (data.grupo === 0) {
                grupo = '';
            }else{
                grupo = data.grupo;
            }
        }
        if (data.forma) {
            if (data.forma === 0) {
                forma = '';
            }else{
                forma = data.forma;
            }
        }
    }else{
        familia = '';
        linea = '';
        grupo = '';
        forma = '';
    }

    let reporte = 'reporteProductos';
    if (valEmp === 'fage') {
        id = id_emp[6];
        params = { id, reporte, familia, linea, grupo, forma};
    } else {
        id = id_emp[5];
        params = { id, reporte, familia, linea, grupo, forma};
    }
    dispatch(setLoader(true));
    api.getPdf('/rs_producto', params).then((response) => {
        if (response.headers['content-type'] == 'application/pdf') {
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'productos.pdf';
            window.open(link, '_blank');
            return false;
        }
    }).catch((error) => {
        if (error.status === 404) {
            NotificationManager.error(
                error.body.msj,
                'Error',
                3000
            );
        }
        if (error.status === 500) {
            NotificationManager.error(
                'Ocurrió un error al generar reporte',
                'Error',
                0
            );
        }

    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const listarPrincipioActivo = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let empresa = '';
    if (valEmp === 'fage'){
        empresa = id_emp[6];
    }else{
        empresa = id_emp[5];
    }
    api.get(`/principio_activo/?id=${empresa}`).then((data)=>{
        const principio = [];
        data.forEach(item=>{
            principio.push({
                value: item.id,
                label: item.descripcion
            })
        })
        dispatch({ type: LISTADO_PRINCIPIO, listaPrincipio: principio });
    }).catch(()=>{
        NotificationManager.error(
            'Ocurrió un error al listar principios activo',
            'Error',
            0
        );
    })
}

export const reporteRegistrosExcel = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let reporte = 'registrosSanitariosExcel';
    let params = { id, reporte };
    dispatch(setLoader(true));
    api.getExcel('/rs_registro_sanitario', params).then((response) => {
        const blob = response;
        const fileName = 'registros_sanitarios.xlsx';

        // Descargar el archivo utilizando la función saveAs de file-saver
        const file = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(file, fileName);
                
    }).catch((error) => {
        if (error.status === 404){
            NotificationManager.info(
                error.body.msj,
                'Informacion',
                5000
                );
        }
        if (error.status === 500){
            NotificationManager.error(
                'Ocurrió un error al generar reporte',
                'Error',
                0
            );
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
}


export const actions = {
    getModulo,
    listarPaises,
    listarProductos,
    listarFamilia,
    listarLinea,
    listarGrupo,
    listarForma,
    listarPrincipioActivo,
    reporteRegistrosSanitariosVigentes,
    reporteRegistrosSanitariosPorVencer,
    reporteProductos,
    reporteRegistrosExcel
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [LISTADO_PAISES]: (state, { listaP }) => {
        return {
            ...state,
            listaP,
        };
    },
    [LISTADO_PERIODOS]: (state, { listaPeriodo }) => {
        return {
            ...state,
            listaPeriodo,
        };
    },
    [LISTADO_PRODUCTOS]: (state, { listaPro }) => {
        return {
            ...state,
            listaPro,
        };
    },
    [LISTADO_FAMILIA]: (state, { listaFamilia }) => {
        return {
            ...state,
            listaFamilia,
        };
    },
    [LISTADO_LINEA]: (state, { listaLinea }) => {
        return {
            ...state,
            listaLinea,
        };
    },
    [LISTADO_GRUPO]: (state, { listaGrupo }) => {
        return {
            ...state,
            listaGrupo,
        };
    },
    [LISTADO_FORMA]: (state, { listaForma }) => {
        return {
            ...state,
            listaForma,
        };
    },
    [LISTADO_PRINCIPIO]: (state, { listaPrincipio }) => {
        return {
            ...state,
            listaPrincipio,
        };
    },
};

export const initialState = {
    loader: false,
    monedas: [],
    modulo: [],
    listaP: [],
    listaPeriodo: [],
    listaPro: [],
    listaFamilia: [],
    listaLinea: [],
    listaGrupo: [],
    listaForma: [],
    listaPrincipio: [],
};

export default handleActions(reducers, initialState)