import React from 'react';
import PropTypes from 'prop-types';
const defaultA = require("assets/img/box-sinfondo.png");

function ModuloCard(props) {
  const {
    logo,
    name,
    ruta,
  } = props;

  const img = __CONFIG__.api_image+logo;

  return (
    <div className="card company" style={{ width: "18rem" }}>
      <div className='text-center imageBox'>
      <img className='imageLogo' src={logo ? img : defaultA} alt="Card image"/>
      </div>
      
      <div className="card-body">
        <h6 className="titleCompany">{name}</h6>
          <a href={`/#${ruta}`} className="btn-outline-dark botonSeleccionar">Seleccionar</a>
      </div>
    </div>
  );
}

ModuloCard.propTypes = {
  logo: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default (ModuloCard);
