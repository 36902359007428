import React, { Component } from 'react';
import Formulario from './NominaHome';
import LoadMask from "../Utils/LoadMask/LoadMask";
const valEmp = __CONFIG__.empresa;

class Nomina extends Component{
    componentDidMount = () => {
        const { getModulo } = this.props;
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        if (valEmp === 'fage'){
            getModulo(modulo[6],modulo[5])
        }else{
            getModulo(modulo[5],modulo[4]);
        }
    }
    render(){
        const { loader, modulo } = this.props;
        
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    modulo={modulo}
                />
            </LoadMask>
        );
    }
}

export default Nomina;
