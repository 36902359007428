import React, { Component } from 'react';
import Formulario from './GenerarPolizaForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";

class GenerarPoliza extends Component{
    
    componentDidMount = () => {
        const { getModulo, limpiarBusqueda } = this.props;
        
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        getModulo(modulo[5],modulo[4]);
        limpiarBusqueda();
    }

    render(){
        const { loader, modulo, listarPeriodos, listaPeriodo,
            generarPoliza, detallePoliza } = this.props;
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    modulo = {modulo}
                    listarPeriodos = {listarPeriodos}
                    listaPeriodo = {listaPeriodo}
                    generarPoliza = {generarPoliza}
                    detallePoliza = {detallePoliza}
                />
            </LoadMask>
        );
    }
}

export default GenerarPoliza;