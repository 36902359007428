import React, { Component } from 'react';
import Formulario from './FrecuenciaPagoForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";
const valEmp = __CONFIG__.empresa;

class FrecuenciaPago extends Component{
    componentDidMount = () => {
        const { getModulo, listar } = this.props;
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        if (valEmp === 'fage'){
            getModulo(modulo[6],modulo[5])
        }else{
            getModulo(modulo[5],modulo[4]);
        }
        listar();
    }
    render(){
        const { loader, modulo, data, registroFrecuencia, eliminarFrecuencia } = this.props;
        
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    modulo = {modulo}
                    data = {data}
                    registroFrecuencia = {registroFrecuencia}
                    eliminarFrecuencia = {eliminarFrecuencia}
                />
            </LoadMask>
        );
    }
}

export default FrecuenciaPago;