import { NotificationManager } from "react-notifications";
import { api } from "api";
import { handleActions, createAction } from 'redux-actions';
import { listarIngresos } from "./ingresos";
import { listarMarcas } from "./ingresos";
import { listarCombustibles } from "./ingresos";
import { listarLineas } from "./ingresos";
import { listarTipos } from "./ingresos";


export const datosDelIngreso = (ingresoId) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let action = "editar"
    const params = { id, action }
    return api.get(`auto_ventas/ingresos/${ingresoId}`, params).then(response => {
        return response;
    }).catch((error) => {
        NotificationManager.error(
            "Ocurrio un error al obtener los datos",
            'Error',
            2000,
            null,
            true
        );
        return [];
    })
}

export const actualizarIngreso  = (data, ingresoId) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    data.combustible = data.combustible.value
    data.linea = data.linea.value
    data.marca = data.marca.value
    data.tipo_carro = data.tipo_carro.value
    data.empresa = id 
    if (typeof data.foto === 'string') {
        data.foto = undefined;
    }
    
    return api.putAttachments(`auto_ventas/ingresos/${ingresoId}`, {...data, foto: null}, [{ "name": 'foto', "file": data.foto}]).then(() => {
            NotificationManager.success(
                'Vehiculo Modificado',
                'Exito',
                3000,
                null,
                true
            );
            return true;
        }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                2000,
                null,
                true
            );
            return false;
        })


}



export const actions = {
    listarMarcas,
    listarLineas,
    listarCombustibles,
    listarTipos,
    listarIngresos,
    datosDelIngreso,
    actualizarIngreso
};

export const reducers = {

}

export const initialState = {

};

export default handleActions(reducers, initialState)