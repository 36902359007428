import React, { Component } from 'react';
import Formulario from './InformesForm';
const valEmp = __CONFIG__.empresa;

class Informes extends Component{
    componentDidMount = () => {
        const { getModulo, listarPaises, listarProductos, listarFamilia,
            listarLinea, listarGrupo, listarForma, listarPrincipioActivo } = this.props;
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        if (valEmp === 'fage'){
            getModulo(modulo[6],modulo[5]);
            listarProductos();
            listarPaises();
            listarFamilia();
            listarLinea();
            listarGrupo();
            listarForma();
            listarPrincipioActivo();
        }else{
            getModulo(modulo[5],modulo[4]);
            listarProductos();
            listarPaises();
            listarFamilia();
            listarLinea();
            listarGrupo();
            listarForma();
            listarPrincipioActivo();
        }
    }
    render(){
        const { loader, modulo, listaP, listaPro, listaFamilia, reporteRegistrosExcel,
            reporteRegistrosSanitariosVigentes, reporteRegistrosSanitariosPorVencer,
            listaLinea, listaGrupo, listaForma, reporteProductos, listaPrincipio } = this.props;
        return(
            <div>
                <Formulario
                    loader = {loader}
                    modulo = {modulo}
                    obtenerPaises = {listaP}
                    listaPro = {listaPro}
                    reporteRegistrosSanitariosVigentes = {reporteRegistrosSanitariosVigentes}
                    reporteRegistrosSanitariosPorVencer = {reporteRegistrosSanitariosPorVencer}
                    listaFamilia = {listaFamilia}
                    listaLinea = {listaLinea}
                    listaGrupo = {listaGrupo}
                    listaForma = {listaForma}
                    reporteProductos = {reporteProductos}
                    listaPrincipio = {listaPrincipio}
                    reporteRegistrosExcel = {reporteRegistrosExcel}
                />
            </div>
        );
    }
}

export default Informes;
