import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/nomina/nomina_otros_ingresos';
import Nomina from './ListNominaOtros';

const ms2p = (state) => {
  return {
    ...state.otros_gastos_nomina,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Nomina);