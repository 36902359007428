import React, { Component } from 'react';
import Formulario from './TipoDescuentoForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";
const valEmp = __CONFIG__.empresa;

class TipoDescuento extends Component{
    state = {
        crear: true,
    }

    componentDidMount = () => {
        const { match, getModulo, leer } = this.props;
        const id = match.params.id;
        if (id){
            this.setState({crear: false});
            leer(id);
        }

        let ruta = window.location.href;
        let modulo = ruta.split('/');
        if (valEmp === 'fage'){
            getModulo(modulo[6], modulo[5])
        }else{
            getModulo(modulo[5],modulo[4]);
        }
    }

    render(){
        const { loader, modulo, registroTipo, modificarTipo } = this.props;
        const { crear } = this.state;
        const funcionEnvio = crear ? registroTipo : modificarTipo;
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    onSubmit = {funcionEnvio}
                    modulo = {modulo}
                    crear = {crear}
                />
            </LoadMask>
        );
    }
}

export default TipoDescuento;