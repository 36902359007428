import React from 'react';
import { reduxForm } from 'redux-form/immutable';
import DetalleListado from './NominaList';
import PropTypes from 'prop-types';
import NavbarModulo from '../../components/NavbarModulo';
import NavbarModuloLF from '../../components/NavbarModuloFage';
import { FaPrint } from 'react-icons/fa';
import { FaRedo } from 'react-icons/fa';

const valEmp = __CONFIG__.empresa;

function NominaForm(props) {
    const { modulo, data, periodoN, correlativo,
        fecha_inicio, fecha_fin, frecuencia, periodoD, periodoG, loader,
        periodoId, reporteNomina, mostrarDescuentos,
        confirmarNomina, revisarNomina, reporteDescuentos,
        actualizarNomina, estado  } = props;
    let titulo = 'Nómina';
    
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const estilo = {
        overflow: "auto",
        width: "100%",

    };
    const editar = window.location.href.includes('editar');
    const crear = window.location.href.includes('crear');
    
    return (
        <div>
            {valEmp === 'fage' &&
                <NavbarModuloLF
                    periodoD={periodoD}    
                    modulo={modulo}
                    titulo={titulo}
                />
            }
            {valEmp === '' &&
                <NavbarModulo
                    periodoD={periodoD}
                    modulo={modulo}
                    titulo={titulo}
                />
            }

            <br />
            <div className="mb-4 card card-small text-white bg-dark mb-3" style={{ overflowX: "auto" }}>
                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                    <div className="d-flex flex-column flex-1 mx-4" style={{ minWidth: "1500px" }}>
                        <div className='row mb-2'>
                            <div className='col-2'>
                                <div className="form-floating">
                                    <input
                                        placeholder="Periodo"
                                        type="text"
                                        autocomplete="off"
                                        name="periodo"
                                        className="form-control"
                                        value={periodoN}
                                        disabled={true}
                                    />
                                    <label className='form-label' htmlFor="periodo">
                                        Periodo
                                    </label>
                                </div>
                            </div>
                            <div className='col-1'>
                                <div className="form-floating">
                                    <input
                                        placeholder="Numero"
                                        type="text"
                                        autocomplete="off"
                                        name="numero"
                                        className="form-control"
                                        value={correlativo}
                                        disabled={true}
                                    />
                                    <label className='form-label' htmlFor="numero">
                                        Numero
                                    </label>
                                </div>
                            </div>
                            <div className='col-2'>
                                <div className="form-floating">
                                    <input
                                        placeholder="Fecha inicio"
                                        type="text"
                                        autocomplete="off"
                                        name="numero"
                                        className="form-control"
                                        value={fecha_inicio}
                                        disabled={true}
                                    />
                                    <label className='form-label' htmlFor="numero">
                                        Fecha inicio
                                    </label>
                                </div>
                            </div>
                            <div className='col-2'>
                                <div className="form-floating">
                                    <input
                                        placeholder="Fecha final"
                                        type="text"
                                        autocomplete="off"
                                        name="numero"
                                        className="form-control"
                                        value={fecha_fin}
                                        disabled={true}
                                    />
                                    <label className='form-label' htmlFor="numero">
                                        Fecha final
                                    </label>
                                </div>
                            </div>
                            <div className='col-2'>
                                <div className="form-floating">
                                    <input
                                        placeholder="Frecuencia de pago"
                                        type="text"
                                        autocomplete="off"
                                        name="numero"
                                        className="form-control"
                                        value={frecuencia}
                                        disabled={true}
                                    />
                                    <label className='form-label' htmlFor="numero">
                                        Frecuencia de pago
                                    </label>
                                </div>
                            </div>
                        </div>
                        
                        <div style={estilo} className='mt-2 mb-3'>
                            <DetalleListado
                                loader={loader}
                                detalle={data}
                                mostrarDescuentos={mostrarDescuentos}
                            />
                        </div>
                        
                        <div className='d-flex flex-row mt-3'>
                            {(crear === true || editar === true) &&
                                <div>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={() => confirmarNomina(periodoId,periodoG)}
                                    >
                                        Guardar nómina
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={() => revisarNomina(periodoId,periodoG)}
                                    >
                                        Revisión de nómina
                                    </button>
                                </div>
                            }
                            {valEmp === 'fage' &&
                                <a
                                    href={`/#/lfg/nomina/${id_emp[6]}/listar_nomina/${periodoG}`}
                                    className='btn btn-secondary mb-3'
                                >
                                    Regresar
                                </a>
                            }
                            {valEmp === '' &&
                                <a
                                    href={`/#/nomina/${id_emp[5]}/listar_nomina/${periodoG}`}
                                    className='btn btn-secondary mb-3'
                                >
                                    Regresar
                                </a>
                            }
                            {estado !== 'F' &&
                                <button
                                    className='btn btn-secondary mb-3 ml-5'
                                    onClick={() => actualizarNomina(periodoId)}
                                >
                                    <span><FaRedo /></span> Actualizar nómina
                                </button>
                            }
                            <button
                                className='btn btn-secondary mb-3 ml-2'
                                onClick={() => reporteNomina(periodoId)}
                            >
                                <span><FaPrint /></span> Ver nómina
                            </button>
                            <button
                                className='btn btn-secondary mb-3 ml-2'
                                onClick={() => reporteDescuentos(periodoId)}
                            >
                                <span><FaPrint /></span> Mostrar descuentos
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

NominaForm.propTypes = {
    handleSubmit: PropTypes.func,
    modulo: PropTypes.object
};

export default reduxForm({
    form: 'FormNomina',
})(NominaForm);
