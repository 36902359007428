import React, { useState, useEffect } from "react";
import DataTableBase from "../../components/DataTableWSearch";
import GenBtn from "../../components/GenBtn";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Banner from "../common/Banner";
import { getList, getPacientes } from "./actions";
import * as Fa from "react-icons/fa";
import LoadMask from "../../Utils/LoadMask/LoadMask";
import OptionsBar from "../common/OptionsBar";
import { getPeriodo } from "../utils";

const BannerText = "Ingresos de patologia";

function Listado({ history, ...props }) {
    const [tableData, setTableData] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const periodo = getPeriodo();

    const columns = [
        {
            name: "Numero de patologia",
            selector: (row) => row.numero_patologia,
            sortable: false,
        },
        {
            name: "Estado",
            sortable: false,
            cell: (row) => <div>{row.entregado ? "Entregado" : "No entregado"}</div>,
            sortFunction: (a, b) => a.entregado - b.entregado,
            conditionalCellStyles: [
                {
                    when: (row) => row.entregado,
                    style: {
                        color: "green",
                    },
                },
                {
                    when: (row) => !row.entregado,
                    style: {
                        color: "red",
                    }
                }
            ],
        },
        {
            cell: (row) => (
                <GenBtn
                    key={row.id}
                    textColor="hsl(0, 0%, 80%)"
                    background="hsla(0, 100%, 100%, 0.2)"
                    toolTipText="Editar"
                    onPress={(e) => {
                        history.push(
                            `${history.location.pathname}/editar/${row.id}`
                        );
                    }}
                >
                    <Fa.FaPen />
                </GenBtn>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            cell: (row) => (
                <GenBtn
                    textColor="hsl(50, 50%, 80%)"
                    background="hsla(50, 50%, 80%, 0.2)"
                    toolTipText="Ver"
                    onPress={(e) => {
                        history.push(
                            `${history.location.pathname}/ver/${row.id}`
                        );
                    }}
                >
                    <Fa.FaEye />
                </GenBtn>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    async function fetchData() {
        setIsLoading(true);
        const data = await getList();
        console.log({data})
        setTableData(data);
        setIsLoading(false);
    }
    useEffect(() => {
        fetchData();
    }, [periodo]);

    return (
        <main>
            <OptionsBar />
            <Banner>{BannerText}</Banner>
            <GenBtn onPress={() => (location.href += "/crear")}>Crear</GenBtn>
            <LoadMask loading={isLoading} color="#bd0034" blur={true}>
                <DataTableBase
                    data={tableData}
                    columns={columns}
                    fieldsToFilter={["numero_patologia"]}
                />
            </LoadMask>
        </main>
    );
}

export default withRouter(Listado);
