import React from 'react';
import {reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import LoadMask from "../../Utils/LoadMask/LoadMask";
import Formulario from './CambiarPassIntForm';

function CambiarPassword(props) {
    const { cambiarPass, loader } = props;

    return (
        <div className="blue-gradient-bg">
            <br/>
            <div className="login-wrapper">
                <div className="card2 card border-0 px-4 py-5 bg-dark col-lg-6 col-md-4 col-11">
                    <h5 className="text-center pv mb-4 title">Cambiar contraseña</h5>
                    <LoadMask loading={loader} dark>
                        <Formulario
                            onSubmit = {cambiarPass}
                        />
                    </LoadMask>
                </div>
            </div>
        </div>
    )

}
CambiarPassword.propTypes = {
    cambiarPass: PropTypes.func,
    loader: PropTypes.bool,
};

export default reduxForm({
    form: 'CambiarPassInt', //identificador unico
})(CambiarPassword)
