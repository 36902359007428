import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';
import moment from 'moment';

const LISTADO_FACTURA = 'LISTADO_FACTURA';
const GUARDAR_FACTURA = 'GUARDAR_FACTURA';
const PAGE = 'PAGE';
const LOADER = 'LOADER';
const MODULO = 'MODULO';
const PERIODO_D = 'PERIODO_D';
const DETALLE_PRODUCTOS = 'DETALLE_PRODUCTOS';
const PARAMETRO = 'PARAMETRO';
const CLIENTE = 'CLIENTE';
const DETALLE_ENVIO = 'DETALLE_ENVIO';
const ESTABLECIMIENTO = 'ESTABLECIMIENTO';
const LISTADO_ESTABLECIMIENTOS = 'LISTADO_ESTABLECIMIENTOS';
const LISTADO_TIPOS_DOCU = 'LISTADO_TIPOS_DOCU';

const setPage = page => ({
    type: PAGE,
    page,
});

const setLoader = loader => ({
    type: LOADER,
    loader,
});


export const listar = (page = 1) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let id_period = id_emp[7];
    const params = { page, id, id_period };
    api.get('/factura', params).then((response)=>{
        dispatch({ type: LISTADO_FACTURA, data: response });
        dispatch(setPage(page));
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar facturas',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/factura/${id}`).then((response) => {
        response.fechaRegistro = response.fecha;
        response.observaciones = response.descripcion
        response.clienteN = response.nombreCli
        dispatch({ type: GUARDAR_FACTURA, lectura: response });
        dispatch(initializeForm('FormFactura', response ));
        dispatch(leerDetalleProductos(response.id));
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar factura',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const leerPeriodoD = (id, id_periodo) => (dispatch) => {
    const params = { id, id_periodo };
    api.get('/periodos/periodo', params).then((response) => {
        dispatch({ type: PERIODO_D, periodoD: response.periodo });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar periodo',
            'Error',
            2000
        );
    })
}

export const listarEnvios = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id, search}
    return api.get('/envio_produccion/empresa_envio_produccion',params).then(data=>{
        const list_envios = [];
        if(data){
            data.forEach(item=>{
                list_envios.push({
                    value: item.id,
                    label: item.serie + ' - ' + item.numero
                })
            })
        }
        return list_envios ;
    }).catch((error)=>{
        return [];
    })
}

export const registroFactura = (periodoD, idCliente, detalleN, tipoD, iva) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormFactura.values;
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fechaRegistro)
    if (fecha>=fechaI && fecha<=fechaF){
        let total = 0.00;
        detalleN.forEach( (e) => { 
            if (parseFloat(e.unidad) === 0.00) {
                total += parseFloat(parseFloat(e.cantidad)*parseFloat(e.precio));
            }else{
                total += parseFloat(parseFloat(e.unidad)*parseFloat(e.precio));
            }
        });
        
        const formData = {
            fechaRegistro: data.fechaRegistro,
            serie: data.serie,
            numero: data.numero,
            tipoD: tipoD,
            iva: iva,
            observaciones: data.observaciones,
            envio: data.envio.value,
            idCliente: idCliente,
            detalleFactura: detalleN,
            establecimiento: id_emp[9],
            total: total,
            motivo: 'FACT',
            empresa: id_emp[5],
            periodo: id_emp[7]
        }
        
        dispatch(setLoader(true));
        api.post('/factura/factura_inventario_prd/', formData).then(() => {
            NotificationManager.success(
                'Factura guardada correctamente',
                'Exito',
                3000
            );
            let ruta = `/produccion/${id_emp[5]}/factura/${id_emp[7]}`
            dispatch(push(ruta))
        }).catch((error)=>{
            NotificationManager.error(
                'Ocurrió un error al guardar la factura',
                'Error',
                4000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }else {
        Swal.fire({
            title: 'Factura',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })        
    }
}

const leerDetalleProductos = id => (dispatch) => {
    api.get(`factura_detalle_prd/${id}`).then((response)=>{
        dispatch({ type: DETALLE_PRODUCTOS, detalleProducto: response });
    }).catch((error)=>{
        NotificationManager.error(
            error.body.msj,
            'Error',
            6000
        );
        dispatch({ type: DETALLE_PRODUCTOS, detalleProducto: [] });;
    })
};

export const parametrosEmpresa = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id}
    dispatch(setLoader(true));
    api.get('/empresa/empresa_parametros', params).then((response) => {
        dispatch({type: PARAMETRO, datos: response });
    }).catch((error)=>{
        
        NotificationManager.error(
            'Ocurrió un error al consultar parametros',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

let detalle = [];
export const detalleEnvio = (id) => (dispatch) => {
    let params = {id}
    return api.get('/envio_produccion_detalle/detalle_envio', params).then((response)=>{
        dispatch({type: CLIENTE, cli: response });
        detalle=[]
        response.detalle.forEach(data => {
            detalle.push({
                id: data.id,
                cantidad: data.cantidad,
                sku: data.sku,
                producto: data.producto,
                productoN: data.productoN,
                unidad: data.unidad,
                insumo: data.insumo,
                cantidad_insumo: data.cantidad_insumo,
                precio: data.precio,
                total: data.total
            })
        });
        dispatch({ type: DETALLE_ENVIO, detalleEnv: detalle });
        
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
}

export const eliminarFactura = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`/factura/${id}`).then(() => {
        NotificationManager.success(
            'Factura borrada correctamente',
            'Exito',
            3000
        );
        let page = 1;
        dispatch(listar(page));
    }).catch((error) => {
        if (error.msj) {
            Swal.fire({
                title: 'Factura',
                text: error.msj,
                type: 'error',
            })    
        }else{
            NotificationManager.error(
                'Ocurrió un error en eliminar factura',
                'Error',
                6000
            );
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const vaciar = () => (dispatch) => {
    detalle = [];
    dispatch(initializeForm('FormFactura'));
    dispatch({ type: DETALLE_ENVIO, detalleEnv: detalle });
};

export const leerEstablecimiento = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/sucursal/${id}`).then((response) => {
        dispatch({type: ESTABLECIMIENTO, estable: response });
        
    }).catch((error)=>{
        
        NotificationManager.error(
            'Ocurrió un error al consultar el establecimiento',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const listarEstablecimientos = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    return api.get('/sucursal', params).then((data)=>{
        const estable = [];
        data.results.forEach(item=>{
            estable.push({
                value: item.id,
                label: item.codigo + ' - ' + item.nombre_comercial
            })
        })
        if (estable.length === 1 ){
            estable.forEach(item => {
                dispatch({ type: LISTADO_ESTABLECIMIENTOS, listaEs: item.value });
            })
            dispatch({ type: LISTADO_ESTABLECIMIENTOS, listaEs: data.results[0] });
        }else{
            dispatch({ type: LISTADO_ESTABLECIMIENTOS, listaEs: estable });
        }
        
    }).catch(error=>{
        
        NotificationManager.error(
            'Ocurrió un error al listar establecimientos',
            'Error',
            0
        );
    })
}

export const listarTiposDocu = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    api.get('/tiposFac', params).then((response) => {
        const tiposD = [];
        response.results.forEach(item => {
            if (item.tipo_documento === 'CARGO'){
                tiposD.push({
                    value: item.id,
                    label: item.tipo + ' - ' + item.descripcion,
                    search: item.categoria
                })
            }
        })
        dispatch({ type: LISTADO_TIPOS_DOCU, tipos: tiposD });
    }).catch((error) => {
        
        NotificationManager.error(
            'Ocurrió un error al listar tipos de documento',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}


export const actions = {
    listar,
    leer,
    getModulo,
    leerPeriodoD,
    registroFactura,
    listarEnvios,
    parametrosEmpresa,
    detalleEnvio,
    leerDetalleProductos,
    eliminarFactura,
    vaciar,
    leerEstablecimiento,
    listarEstablecimientos,
    listarTiposDocu 
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_FACTURA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [PERIODO_D]: (state, { periodoD }) => {
        return {
            ...state,
            periodoD,
        };
    },
    [PARAMETRO]: (state, { datos }) => {
        return {
            ...state,
            datos,
        };
    },
    [DETALLE_PRODUCTOS]: (state, { detalleProducto }) => {
        return {
            ...state,
            detalleProducto,
        };
    },
    [CLIENTE]: (state, { cli }) => {
        return {
            ...state,
            cli,
        };
    },
    [DETALLE_ENVIO]: (state, { detalleEnv }) => {
        return {
            ...state,
            detalleEnv,
        };
    },
    [ESTABLECIMIENTO]: (state, { estable }) => {
        return {
            ...state,
            estable,
        };
    },
    [LISTADO_ESTABLECIMIENTOS]: (state, { listaEs }) => {
        return {
            ...state,
            listaEs,
        };
    },
    [LISTADO_TIPOS_DOCU]: (state, { tipos }) => {
        return {
            ...state,
            tipos,
        };
    },
    [GUARDAR_FACTURA]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
};

export const initialState = {
    loader: false,
    lectura: [],
    modulo: [],
    periodoD: [],
    cli: [],
    detalleProducto: [],
    detalleEnv: [],
    estable: [],
    listaEs: [],
    tipos: [],
    datos: [],
    lectura: [],
    data: {
        results: [],
        count: 0,
    },
    page: 1,
};

export default handleActions(reducers, initialState)

    