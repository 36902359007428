import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';

import {
    renderField,
    SelectField,
    renderCurrencyFloat,
    renderDayPickerB,
} from "../../Utils/renderField/renderField";

function ColaboradorNominaForm(props) {
    const { crear, listaDepartamento,  listaPuesto,
        lectura, disabled, editar } = props;

    let autoFocus = true;
    const frecuencia = [
        { label: "SEMANAL", value: "S" },
        { label: "QUINCENAL", value: "Q" },
        { label: "CATORCENAL", value: "C" },
        { label: "MENSUAL", value: "M" },
    ];
    const tipo_cuenta = [
        {
            value: 'MONETARIA',
            label: 'MONETARIA'
        },
        {
            value: 'AHORRO',
            label: 'AHORRO'
        },
    ];
    return (
        <div>
            {crear === true &&
                <div>
                    <div className='row mb-2'>
                        <div className='col-3'>
                            <Field
                                autoFocus={autoFocus}
                                name="sueldo_base"
                                placeholder="Sueldo base"
                                className="form-control"
                                component={renderCurrencyFloat}
                                disabled={disabled}
                                msj="Sueldo base"
                            />
                        </div>
                        <div className='col-3'>
                            <Field
                                name="bonificacion"
                                placeholder="Bonificacion"
                                className="form-control"
                                component={renderCurrencyFloat}
                                disabled={disabled}
                                msj="Bonificacion"
                            />
                        </div>
                        <div className='col-3'>
                            <Field
                                name="fecha_alta"
                                component={renderDayPickerB}
                                msj="Fecha alta"
                            />
                        </div>
                        <div className='col-3'>
                            <Field
                                name="fecha_baja"
                                disabled={true}
                                component={renderDayPickerB}
                                msj="Fecha baja"
                            />
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-3'>
                            <Field
                                name="departamento_trabajo"
                                options={listaDepartamento}
                                crear={crear}
                                dato={crear === true ? 0 : lectura.departamentoTrabajo}
                                component={SelectField}
                                className="form-select"
                                disabled={disabled}
                                msj="Departamento"
                            />
                        </div>
                        <div className='col-3'>
                            <div className="form-floating">
                                <Field
                                    name="puesto"
                                    options={listaPuesto}
                                    crear={crear}
                                    dato={crear === true ? 0 : lectura.puestoTrabajo}
                                    component={SelectField}
                                    className="form-select"
                                    disabled={disabled}
                                    msj="Puesto"
                                />
                            </div>
                        </div>
                        <div className='col-3'>
                            <Field
                                name="jefe_inmediato"
                                placeholder="Jefe inmediato"
                                className="form-control"
                                component={renderField}
                                disabled={disabled}
                                msj="Jefe inmediato"
                            />
                        </div>
                        <div className='col-3'>
                            <Field
                                name="lugar_trabajo"
                                placeholder="Lugar de trabajo"
                                className="form-control"
                                component={renderField}
                                disabled={disabled}
                                msj="Lugar de trabajo"
                            />
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-3'>
                            <Field
                                name="horario_trabajo"
                                placeholder="Jornada de trabajo"
                                className="form-control"
                                component={renderField}
                                disabled={disabled}
                                msj="Horario de trabajo"
                            />
                        </div>
                        <div className='col-3'>
                            <div className="form-floating">
                                <Field
                                    name="frecuencia_pago"
                                    options={frecuencia}
                                    crear={crear}
                                    dato={0}
                                    component={SelectField}
                                    className="form-select"
                                    disabled={disabled}
                                    msj="Frecuencia de pago"
                                />
                            </div>
                        </div>
                        <div className='col-3'>
                            <Field
                                name="numero_cuenta"
                                placeholder="Numero de cuenta"
                                className="form-control"
                                component={renderField}
                                disabled={disabled}
                                msj="Numero de cuenta"
                            />
                        </div>
                        <div className='col-3'>
                            <Field
                                name="banco"
                                placeholder="Banco"
                                className="form-control"
                                component={renderField}
                                disabled={disabled}
                                msj="Banco"
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-3'>
                            <div className="form-floating">
                                <Field
                                    name="tipo_cuenta"
                                    options={tipo_cuenta}
                                    crear={crear}
                                    dato={0}
                                    component={SelectField}
                                    className="form-select"
                                    disabled={disabled}
                                    msj="Tipo cuenta"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }
            {editar === true &&
                <div>
                    <div className='row mb-2'>
                        <div className='col-3'>
                            <Field
                                name="sueldo_base"
                                placeholder="Sueldo base"
                                className="form-control"
                                component={renderCurrencyFloat}
                                disabled={disabled}
                                msj="Sueldo base"
                            />
                        </div>
                        <div className='col-3'>
                            <Field
                                name="bonificacion"
                                placeholder="Bonificacion"
                                className="form-control"
                                component={renderCurrencyFloat}
                                disabled={disabled}
                                msj="Bonificacion"
                            />
                        </div>
                        <div className='col-3'>
                            <Field
                                name="fecha_alta"
                                component={renderDayPickerB}
                                msj="Fecha alta"
                                disabled={disabled}
                            />
                        </div>
                        <div className='col-3'>
                            <Field
                                name="fecha_baja"
                                disabled={true}
                                component={renderDayPickerB}
                                msj="Fecha baja"
                            />
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-3'>
                            <Field
                                name="departamento_trabajo"
                                options={listaDepartamento}
                                crear={crear}
                                dato={crear === true ? 0 : lectura.departamentoTrabajo}
                                component={SelectField}
                                className="form-select"
                                msj="Departamento"
                            />
                        </div>
                        <div className='col-3'>
                            <div className="form-floating">
                                <Field
                                    name="puesto"
                                    options={listaPuesto}
                                    crear={crear}
                                    dato={crear === true ? 0 : lectura.puestoTrabajo}
                                    component={SelectField}
                                    className="form-select"
                                    msj="Puesto"
                                />
                            </div>
                        </div>
                        <div className='col-3'>
                            <Field
                                name="jefe_inmediato"
                                placeholder="Jefe inmediato"
                                className="form-control"
                                component={renderField}
                                msj="Jefe inmediato"
                            />
                        </div>
                        <div className='col-3'>
                            <Field
                                name="lugar_trabajo"
                                placeholder="Lugar de trabajo"
                                className="form-control"
                                component={renderField}
                                msj="Lugar de trabajo"
                            />
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-3'>
                            <Field
                                name="horario_trabajo"
                                placeholder="Jornada de trabajo"
                                className="form-control"
                                component={renderField}
                                msj="Horario de trabajo"
                            />
                        </div>
                        <div className='col-3'>
                            <div className="form-floating">
                                <Field
                                    name="frecuencia_pago"
                                    options={frecuencia}
                                    crear={crear}
                                    dato={lectura.frecuencia_pago}
                                    component={SelectField}
                                    className="form-select"
                                    msj="Frecuencia de pago"
                                />
                            </div>
                        </div>
                        <div className='col-3'>
                            <Field
                                name="numero_cuenta"
                                placeholder="Numero de cuenta"
                                className="form-control"
                                component={renderField}
                                msj="Numero de cuenta"
                            />
                        </div>
                        <div className='col-3'>
                            <Field
                                name="banco"
                                placeholder="Banco"
                                className="form-control"
                                component={renderField}
                                msj="Banco"
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-3'>
                            <div className="form-floating">
                                <Field
                                    name="tipo_cuenta"
                                    options={tipo_cuenta}
                                    crear={crear}
                                    dato={lectura.tipo_cuenta}
                                    component={SelectField}
                                    className="form-select"
                                    msj="Tipo cuenta"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }
            {(editar === false && crear === false ) &&
                <div>
                    <div className='row mb-2'>
                        <div className='col-3'>
                            <Field
                                name="sueldo_base"
                                placeholder="Sueldo base"
                                className="form-control"
                                component={renderCurrencyFloat}
                                disabled={true}
                                msj="Sueldo base"
                            />
                        </div>
                        <div className='col-3'>
                            <Field
                                name="bonificacion"
                                placeholder="Bonificacion"
                                className="form-control"
                                component={renderCurrencyFloat}
                                disabled={true}
                                msj="Bonificacion"
                            />
                        </div>
                        <div className='col-3'>
                            <Field
                                name="fecha_alta"
                                component={renderDayPickerB}
                                msj="Fecha alta"
                                disabled={true}
                            />
                        </div>
                        <div className='col-3'>
                            <Field
                                name="fecha_baja"
                                disabled={true}
                                component={renderDayPickerB}
                                msj="Fecha baja"
                            />
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-3'>
                            <Field
                                name="departamento_trabajo"
                                options={listaDepartamento}
                                crear={crear}
                                dato={crear === true ? 0 : lectura.departamentoTrabajo}
                                component={SelectField}
                                className="form-select"
                                disabled={true}
                                msj="Departamento"
                            />
                        </div>
                        <div className='col-3'>
                            <div className="form-floating">
                                <Field
                                    name="puesto"
                                    options={listaPuesto}
                                    crear={crear}
                                    dato={crear === true ? 0 : lectura.puestoTrabajo}
                                    component={SelectField}
                                    className="form-select"
                                    disabled={true}
                                    msj="Puesto"
                                />
                            </div>
                        </div>
                        <div className='col-3'>
                            <Field
                                name="jefe_inmediato"
                                placeholder="Jefe inmediato"
                                className="form-control"
                                component={renderField}
                                disabled={true}
                                msj="Jefe inmediato"
                            />
                        </div>
                        <div className='col-3'>
                            <Field
                                name="lugar_trabajo"
                                placeholder="Lugar de trabajo"
                                className="form-control"
                                component={renderField}
                                disabled={true}
                                msj="Lugar de trabajo"
                            />
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-3'>
                            <Field
                                name="horario_trabajo"
                                placeholder="Jornada de trabajo"
                                className="form-control"
                                component={renderField}
                                disabled={true}
                                msj="Horario de trabajo"
                            />
                        </div>
                        <div className='col-3'>
                            <div className="form-floating">
                                <Field
                                    name="frecuencia_pago"
                                    options={frecuencia}
                                    crear={crear}
                                    dato={lectura.frecuencia_pago}
                                    component={SelectField}
                                    className="form-select"
                                    disabled={true}
                                    msj="Frecuencia de pago"
                                />
                            </div>
                        </div>
                        <div className='col-3'>
                            <Field
                                name="numero_cuenta"
                                placeholder="Numero de cuenta"
                                className="form-control"
                                component={renderField}
                                disabled={true}
                                msj="Numero de cuenta"
                            />
                        </div>
                        <div className='col-3'>
                            <Field
                                name="banco"
                                placeholder="Banco"
                                className="form-control"
                                component={renderField}
                                disabled={true}
                                msj="Banco"
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-3'>
                            <div className="form-floating">
                                <Field
                                    name="tipo_cuenta"
                                    options={tipo_cuenta}
                                    crear={crear}
                                    dato={lectura.tipo_cuenta}
                                    component={SelectField}
                                    className="form-select"
                                    disabled={disabled}
                                    msj="Tipo cuenta"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

ColaboradorNominaForm.propTypes = {
    handleSubmit: PropTypes.func,
    crear: PropTypes.bool,
    lectura: PropTypes.object,
    modulo: PropTypes.object,
    listaDepartamento: PropTypes.object,
    listaPuesto: PropTypes.object,
};

export default reduxForm({
    form: 'FormColaborador',
})(ColaboradorNominaForm);
