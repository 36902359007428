import React, { useState } from "react";
import styled from "styled-components";
import { FicInput } from "../../Utils/FormInputComponents";
import Banner from "../common/Banner";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { validar } from "../../components/ValidarNit";
import GenBtn from "../../components/GenBtn";
import InputsContainer from "../common/InputsContainer";
import { getEntry, editar } from "./actions";
import { withRouter } from "react-router";
import LoadMask from "../../Utils/LoadMask/LoadMask";

const idName = "unidadId";

const schema = yup.object({
    nombre: yup.string().required("El nombre es requerido"),
});

function Editar({ history, ...props }) {
    const [isLoading, setIsLoading] = useState(false);
    const params = props.match.params;
    const entryId = params[idName];

    const { control, reset, handleSubmit } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: async () => {
            setIsLoading(true);
            const formData = await getEntry(entryId);
            setIsLoading(false);
            return formData;
        },
    });

    const onSubmit = (data) => {
        const shouldGoBack = editar(data, entryId);
        if (shouldGoBack) {
            history.goBack();
        }
    };

    return (
        <React.Fragment>
            <Banner>Editar</Banner>
            <GenBtn
                onPress={() => history.goBack()}
                style={{ margin: "5px 0" }}
            >
                Regresar
            </GenBtn>
            <LoadMask loading={isLoading} color="#bd0034" blur={true}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <InputsContainer>
                        <FicInput
                            control={control}
                            label="Nombre"
                            name="nombre"
                        />
                    </InputsContainer>
                    <GenBtn style={{ margin: "1rem 0" }} type="submit">
                        Guardar
                    </GenBtn>
                </form>
            </LoadMask>
        </React.Fragment>
    );
}

export default withRouter(Editar);
