import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from '../../components/Modal';
import PropTypes from 'prop-types';
import moment from 'moment';
const valEmp = __CONFIG__.empresa;

function SeleccionarFormPeriodo(props) {
    const {
        periodoL
        
    } = props;
    
    const [id, setId] = useState("");

    const handleChange = e => {
        setId(e.target.value);
    }
   
    const [estadoModal, cambiarEstadoModal] = useState(true);
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    return (
        <div className='container'>
            <Modal
                estado={estadoModal}
                cambiarEstado={cambiarEstadoModal}
                titulo="Seleccionar periodo nomina"
                botonCerrar="false"
            >
                {periodoL && 
                    <div className="form-floating">
                        <select className="form-select" id="periodo"
                            value={id} onChange={handleChange} >
                            <option selected>Seleccionar...</option>
                            {periodoL.map(mes =>
                                <option key={mes.id} value={mes.id}>{mes.periodo_nomina} --- {mes.numero} --- [ {moment(mes.fecha_inicio).format("DD/MM/YYYY")} ] [ {moment(mes.fecha_fin).format("DD/MM/YYYY")} ]</option>)
                            }

                        </select>
                        <label className='form-label' htmlfor="periodo">Periodo</label>
                    </div>
                }
                <br />
                {valEmp === 'fage' &&
                    <Contenido>
                        {periodoL.length > 0 &&
                            (id &&
                                <a
                                    href={`/#/lfg/nomina/${id_emp[6]}/nomina/${id}/crear`}
                                    className='btn mr-2 mb-3 btn-primary'
                                >
                                    Seleccionar
                                </a>
                            )
                        }
                    </Contenido>
                }
                {valEmp === '' &&
                    <Contenido>
                        {periodoL.length > 0 &&
                            (id &&
                                <a
                                    href={`/#/nomina/${id_emp[5]}/nomina/${id}/crear`}
                                    className='btn mr-2 mb-3 btn-primary'
                                >
                                    Seleccionar
                                </a>
                            )
                        }
                    </Contenido>
                }
            </Modal>
        </div>
    );

}
SeleccionarFormPeriodo.propTypes = {
    periodoL: PropTypes.string.isRequired,
    listaEs: PropTypes.string.isRequired
};

export default (SeleccionarFormPeriodo);

const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;