import React, { useState } from 'react';
import Modal from '../components/Modal';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LoadMask from "../Utils/LoadMask/LoadMask";
import NavbarModulo from '../components/NavbarModulo';

import {
    renderDayPickerB,
    renderNumber,
    SelectField,
    AsyncSelectField,
} from "../Utils/renderField/renderField";

const meses = [
    {
        label: 'ENERO',
        value: '01'
      
    },
    {
        label: 'FEBRERO',
        value: '02'
    },
    {
        label: 'MARZO',
        value: '03',
    },
    {
        label: 'ABRIL',
        value: '04',
    },
    {
        label: 'MAYO',
        value: '05',
    },
    {
        label: 'JUNIO',
        value: '06',
    },
    {
        label: 'JULIO',
        value: '07',
    },
    {
        label: 'AGOSTO',
        value: '08',
    },
    {
        label: 'SEPTIEMBRE',
        value: '09',
    },
    {
        label: 'OCTUBRE',
        value: '10'
    },
    {
        label: 'NOVIEMBRE',
        value: '11',
    },
    {
        label: 'DICIEMBRE',
        value: '12',
    },
];

function InformesForm(props) {
    const {
        loader,
        modulo,
        listarCuentasBancarias,
        listarProveedores,
        listarClientes,
        //listarCC,
        reporteDiarioBancos,
        reporteListadoEgresos,
        reporteListadoIngresos,
        excelDiarioBancos,
        reporteConciliacion
    } = props;

    const [estadoModalDiario, cambiarEstadoModalDiario] = useState(false);
    const [modalListadoDeEgresos, cambiarModalListadoDeEgresos] = useState(false);
    const [modalListadoDeIngresos, cambiarModalListadoDeIngresos] = useState(false);
    const [modalConciliacion, cambiarModalConciliacion] = useState(false);
    
    let autoFocus = true;
    let titulo = 'Informes';

    return (
        <div>
            <NavbarModulo
                modulo={modulo}
                titulo={titulo}
            />
        
            <div className="container">
                <br/>
                <h4 className='mt-1' style={{ color: "white" }}>Informes</h4>
                <div className="row">
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <button class="list-group-item list-group-item-action"
                                id="list-home-list"
                                data-bs-toggle="list"
                                role="tab"
                                onClick={() => cambiarEstadoModalDiario(!estadoModalDiario)}
                                aria-controls="list-home">Diario de bancos
                            </button>
                        </div>
                    </div>
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <button class="list-group-item list-group-item-action"
                                id="list-home-list"
                                data-bs-toggle="list"
                                role="tab"
                                onClick={() => cambiarModalListadoDeEgresos(!modalListadoDeEgresos)}
                                aria-controls="list-home">Listado de egresos
                            </button>
                        </div>
                    </div>
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <button class="list-group-item list-group-item-action"
                                id="list-home-list"
                                data-bs-toggle="list"
                                role="tab"
                                onClick={() => cambiarModalListadoDeIngresos(!modalListadoDeIngresos)}
                                aria-controls="list-home">Listado de ingresos
                            </button>
                        </div>
                    </div>
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <button class="list-group-item list-group-item-action"
                                id="list-home-list"
                                data-bs-toggle="list"
                                role="tab"
                                onClick={() => cambiarModalConciliacion(!modalConciliacion)}
                                aria-controls="list-home">Conciliación bancaria
                            </button>
                        </div>
                    </div>
                </div>
                <Modal
                    estado={estadoModalDiario} //diario de bancos
                    cambiarEstado={cambiarEstadoModalDiario}
                    titulo="Diario de bancos"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className='col-6 mb-2'>
                                <div className="form-floating">
                                    <Field
                                        autoFocus={autoFocus}
                                        name="mes"
                                        options={meses}
                                        component={SelectField}
                                        className="form-select"
                                        msj="Mes"
                                    />
                                </div>
                            </div>
                            <div className='col-6 mb-2'>
                                <div className="form-floating">
                                    <Field
                                        name="year"
                                        component={renderNumber}
                                        className="form-control"
                                        msj="Año"
                                    />
                                </div>
                            </div>
                        </Contenido>
                        <div className="col-12 mb-2">
                            <Field
                                autoFocus={autoFocus}
                                name="cuenta_bancaria"
                                placeholder="Cuenta bancaria..."
                                className="form-control-find"
                                component={AsyncSelectField}
                                loadOptions={listarCuentasBancarias}
                                msj="Cuenta Bancaria"
                            />
                        </div>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div >
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={reporteDiarioBancos}
                                    >
                                    Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={excelDiarioBancos}
                                    >
                                    Generar Excel
                                    </button>
                                </div>
                                    
                                    <button
                                        className='btn mr-2 mb-3 btn btn-secondary'
                                        onClick={() => cambiarEstadoModalDiario(false)}
                                    >
                                    Cancelar
                                    </button>
                            </Contenido>
                        </LoadMask>
                </Modal>

                <Modal
                    estado={modalListadoDeEgresos} //Listado de egresos
                    cambiarEstado={cambiarModalListadoDeEgresos}
                    titulo="Listado de egresos"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className='row'>
                                <div className='col-6 mb-2'>
                                    <Field
                                        autoFocus={autoFocus}
                                        name="fechaInicial"
                                        component={renderDayPickerB}
                                        msj="Fecha del"
                                    />
                                </div>
                                <div className='col-6 mb-2'>
                                    <Field
                                        name="fechaFinal"
                                        component={renderDayPickerB}
                                        msj="Fecha al"
                                    />
                                </div>
                            </div>
                            
                            <div className="col-12 mb-2">
                                <Field
                                    name="cuenta_bancaria"
                                    placeholder="Cuenta bancaria..."
                                    className="form-control-find"
                                    component={AsyncSelectField}
                                    loadOptions={listarCuentasBancarias}
                                    msj="Cuenta Bancaria"
                                />
                            </div>

                            <div className='col-12 mb-2'>
                                <Field
                                    name="proveedor"
                                    placeholder="Proveedor..."
                                    className="form-control-find"
                                    component={AsyncSelectField}
                                    loadOptions={listarProveedores}
                                    msj="Producto"
                                />
                            </div>
 
                            <div className='col-12 mb-2'>
                                    <Field
                                        autoFocus={autoFocus}
                                        name="filtro"
                                        options={
                                            [
                                                {label: "CHEQUE", value: "CK"},
                                                {label: "NOTA DE DEBITO", value: "ND"}
                                            ]
                                        }
                                        component={SelectField}
                                        className="form-select"
                                        msj="TIPO"
                                    />
                             </div>
                            <div className="mb-3 col-sm-12 col-md-4">
                            </div>
                        </Contenido>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div className='row'>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={reporteListadoEgresos}
                                        >
                                    Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn btn-secondary'
                                        onClick={() => cambiarModalListadoDeEgresos(false)}
                                        >
                                        Cancelar
                                    </button>
                                </div>
                            </Contenido>
                        </LoadMask>
                </Modal> 
                <Modal
                    estado={modalListadoDeIngresos} //Listado de ingresos
                    cambiarEstado={cambiarModalListadoDeIngresos}
                    titulo="Listado de ingresos"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className='row'>
                                <div className='col-6 mb-2'>
                                    <Field
                                        autoFocus={autoFocus}
                                        name="fechaInicial"
                                        component={renderDayPickerB}
                                        msj="Fecha del"
                                    />
                                </div>
                                <div className='col-6 mb-2'>
                                    <Field
                                        name="fechaFinal"
                                        component={renderDayPickerB}
                                        msj="Fecha al"
                                    />
                                </div>
                            </div>
                            
                            <div className="col-12 mb-2">
                                <Field
                                    name="cuenta_bancaria"
                                    placeholder="Cuenta bancaria..."
                                    className="form-control-find"
                                    component={AsyncSelectField}
                                    loadOptions={listarCuentasBancarias}
                                    msj="Cuenta Bancaria"
                                />
                            </div>

                            <div className='col-12 mb-2'>
                                <Field
                                    name="cliente"
                                    placeholder="Cliente..."
                                    className="form-control-find"
                                    component={AsyncSelectField}
                                    loadOptions={listarClientes}
                                    msj="Cliente"
                                />
                            </div>
 
                            <div className='col-12 mb-2'>
                                    <Field
                                        autoFocus={autoFocus}
                                        name="filtro"
                                        options={
                                            [
                                                {label: "DEPOSITO", value: "DP"},
                                                {label: "NOTA DE CREDITO", value: "NC"}
                                            ]
                                        }
                                        component={SelectField}
                                        className="form-select"
                                        msj="TIPO"
                                    />
                             </div>
                            <div className="mb-3 col-sm-12 col-md-4">
                            </div>
                        </Contenido>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div className='row'>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={reporteListadoIngresos}
                                    >
                                    Generar Reporte
                                    </button>
                                <button
                                    className='btn mr-2 mb-3 btn btn-secondary'
                                    onClick={() => cambiarModalListadoDeIngresos(false)}
                                    >
                                    Cancelar
                                </button>
                            </div>
                            </Contenido>
                        </LoadMask>
                </Modal> 
                <Modal
                    estado={modalConciliacion} //Conciliacion bancaria
                    cambiarEstado={cambiarModalConciliacion}
                    titulo="Conciliación bancaria"
                    botonCerrar="true"
                    >
                    <Contenido>
                        <div className="col-12 mb-2">
                            <Field
                                name="cuenta_bancaria"
                                placeholder="Cuenta bancaria..."
                                className="form-control-find"
                                component={AsyncSelectField}
                                loadOptions={listarCuentasBancarias}
                                msj="Cuenta Bancaria"
                            />
                        </div>
                        <div className='col-6 mb-2'>
                            <div className="form-floating">
                                <Field
                                    name="mes"
                                    options={meses}
                                    component={SelectField}
                                    className="form-select"
                                    msj="Mes"
                                />
                            </div>
                        </div>
                        <div className='col-6 mb-2'>
                            <div className="form-floating">
                                <Field
                                    name="year"
                                    component={renderNumber}
                                    className="form-control"
                                    msj="Año"
                                />
                            </div>
                        </div>
                    </Contenido>
                    <LoadMask loading={loader} dark>
                        <Contenido>
                            <div className='row mt-1'>
                                <button
                                    className='btn mr-2 mb-3 btn-primary'
                                    onClick={reporteConciliacion}
                                >
                                    Generar Reporte
                                </button>
                                <button
                                    className='btn mr-2 mb-3 btn btn-secondary'
                                    onClick={() => cambiarModalListadoDeEgresos(false)}
                                >
                                    Cancelar
                                </button>
                            </div>
                        </Contenido>
                    </LoadMask>
                </Modal> 
            </div>
        </div>
    );

}

InformesForm.propTypes = {
    listarProveedores: PropTypes.func,
    listarClientes: PropTypes.func,
    listarCuentasBancarias: PropTypes.func,
    reporteDiarioBancos: PropTypes.func,
    reporteListadoEgresos: PropTypes.func,
    reporteListadoIngresos: PropTypes.func,
    loader: PropTypes.bool,
    modulo: PropTypes.object
};

export default reduxForm({
    form: 'FormInformesB',

})(InformesForm);


const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;
