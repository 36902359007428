import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';
import moment from 'moment';
import { validar } from "../../../common/components/components/ValidarNit";

const LISTADO_FACTURA = 'LISTADO_FACTURA';
const GUARDAR_FACTURA = 'GUARDAR_FACTURA';
const LOADER = 'LOADER';
const MODULO = 'MODULO';
const PERIODO_D = 'PERIODO_D';
const DETALLE_PRODUCTOS = 'DETALLE_PRODUCTOS';
const PARAMETRO = 'PARAMETRO';
const CLIENTE = 'CLIENTE';
const DETALLE_ENVIO = 'DETALLE_ENVIO';
const ESTABLECIMIENTO = 'ESTABLECIMIENTO';
const LISTADO_ESTABLECIMIENTOS = 'LISTADO_ESTABLECIMIENTOS';
const LISTADO_TIPOS_DOCU = 'LISTADO_TIPOS_DOCU';
const PRODUCTOS = 'PRODUCTOS';
const DETALLE = 'DETALLE';
const LISTADO_PAISES = 'LISTADO_PAISES';
const LOADERC = 'LOADERC';
const LISTADO_MONEDAS = 'LISTADO_MONEDAS';
const NIVEL = 'NIVEL';
const DETALLE_POLIZA = 'DETALLE_POLIZA';
const LISTADO_MONEDAS_NUEVO = 'LISTADO_MONEDAS_NUEVO';
const LOTES = 'LOTES';
const GUARDAR_POLIZA = 'GUARDAR_POLIZA';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setLoaderC = loaderC => ({
    type: LOADERC,
    loaderC,
});

export const leerB = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/factura/${id}`).then((response) => {
        response.fechaRegistro = response.fecha;
        response.observaciones = response.descripcion;
        response.clienteN = response.nombreCli;
        response.correlativo = response.serie + ' - ' + response.numero;
        response.serie = response.serie_FEL;
        response.numero = response.numero_FEL;
        response.autorizacion = response.numero_autorizacion;
        dispatch({ type: GUARDAR_FACTURA, lecturaFac: response });
        dispatch(initializeForm('FormFacturaBienesFarmFel', response ));
        dispatch(leerDetalleProductos(response.id, response.empresa));
        if (response.poliza){
            dispatch(leerDetalleMod(response.poliza));
        }else{
            response.polizaDetalle_polizaEncabezado = [];
            dispatch({ type: GUARDAR_POLIZA, lectura: response });
        }
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar factura',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

const leerDetalleMod = id => (dispatch) => {
    api.get(`/poliza_detalle/${id}`).then((response)=>{
        response.polizaDetalle_polizaEncabezado = response
        dispatch({ type: GUARDAR_POLIZA, lectura: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const leerPeriodoD = (id, id_periodo) => (dispatch) => {
    const params = { id, id_periodo };
    api.get('/periodos/periodo', params).then((response) => {
        dispatch({ type: PERIODO_D, periodoD: response.periodo });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar periodo',
            'Error',
            2000
        );
    })
}

const leerDetalleProductos = ( id ) => (dispatch) => {
    const params = {'id': id}
    api.get('/factura_detalle/detalle_factura_inventario/',params).then((response)=>{
        dispatch({ type: DETALLE_PRODUCTOS, detalleProducto: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};

export const parametrosEmpresa = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id}
    dispatch(setLoader(true));
    api.get('/empresa/empresa_parametros', params).then((response) => {
        dispatch({type: PARAMETRO, datos: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar parametros',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const leerEstablecimiento = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/sucursal/${id}`).then((response) => {
        dispatch({type: ESTABLECIMIENTO, estable: response });
        
    }).catch((error)=>{
        
        NotificationManager.error(
            'Ocurrió un error al consultar el establecimiento',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const listarEstablecimientos = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    return api.get('/sucursal', params).then((data)=>{
        const estable = [];
        data.results.forEach(item=>{
            estable.push({
                value: item.id,
                label: item.codigo + ' - ' + item.nombre_comercial
            })
        })
        if (estable.length === 1 ){
            estable.forEach(item => {
                dispatch({ type: LISTADO_ESTABLECIMIENTOS, listaEs: item.value });
            })
            dispatch({ type: LISTADO_ESTABLECIMIENTOS, listaEs: data.results[0] });
        }else{
            dispatch({ type: LISTADO_ESTABLECIMIENTOS, listaEs: estable });
        }
        
    }).catch(error=>{
        
        NotificationManager.error(
            'Ocurrió un error al listar establecimientos',
            'Error',
            0
        );
    })
}

export const listarTiposDocu = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    api.get('/tiposFac', params).then((response) => {
        const tiposD = [];
        response.results.forEach(item => {
            if (item.tipo_documento === 'CARGO'){
                tiposD.push({
                    value: item.id,
                    label: item.tipo + ' - ' + item.descripcion,
                    search: item.categoria
                })
            }
        })
        dispatch({ type: LISTADO_TIPOS_DOCU, tipos: tiposD });
    }).catch((error) => {
        
        NotificationManager.error(
            'Ocurrió un error al listar tipos de documento',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const listarBodegas = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search };
    return api.get('/bodega', params).then((response) => {
        const bodega = [];
        if (response){
            response.forEach(item=>{
                bodega.push({
                    value: item.id,
                    label: item.numero + ' / ' + item.bodega
                })
            })
        }
        return bodega;
    }).catch((error) => {
        return [];
    })
}

export const leerProductos = (id) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let idEmpre = id_emp[5];
    const params = {id, idEmpre }
    api.get('/producto/empresa_productos_cod_inventario_bodega_farm/',params).then((response) => {
        const producto = [];
        response.forEach(item=>{
            if (item.tipoPro === 'P'){
                producto.push({
                    value: item.id+' # '+ item.saldo + '#' + item.tipoPro + '#' + item.productoN,
                    label: item.sku + ' / ' + item.productoN + ' -- ' + ' Existencia: ' + new Intl.NumberFormat("en-US", { minimumFractionDigits: 6 }).format(item.saldo)
                })
            }
        })
        dispatch({type: PRODUCTOS, pro: producto })
    })
}

export const leerLotes = (id, bodega) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let idEmpre = id_emp[5];
    const params = {id, idEmpre, bodega }
    api.get('/producto/empresa_productos_cod_inventario_lotes_farm/',params).then((response) => {
        const lote = [];
        response.forEach(item=>{
            lote.push({
                value: item.id + '#' + item.saldo + '#' + moment(item.vencimiento).format("MM/YY") + '#' + moment(item.vencimiento).format("YYYY-MM-DD"),
                label: item.lote + ' / ' + moment(item.vencimiento).format("MM/YYYY") + ' -- ' + ' Existencia: ' + new Intl.NumberFormat("en-US", { minimumFractionDigits: 6 }).format(item.saldo)
            })
        })
        dispatch({type: LOTES, lotes: lote })
    })
}


let detalleFac = [];
export const agregarLinea = (periodoD, precioVta) => (dispatch, getStore) => {
    const data = getStore().form.FormFacturaBienesFarmFel.values;

    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fechaRegistro);
    let precioVenta = precioVta ? precioVta : 0.00;
    if (fecha >= fechaI && fecha <= fechaF) {
        if (data.bodega === undefined || data.bodega === 0.00) {
            Swal.fire({
                title: 'Factura',
                text: 'Debe de seleccionar bodega',
                type: 'error',
            })
        } else {
            if (data.producto === undefined || data.producto === null) {
                Swal.fire({
                    title: 'Factura',
                    text: 'Debe de seleccionar producto',
                    type: 'error',
                })
            } else {
                if (data.cantidad === undefined || data.cantidad === 0.000000) {
                    Swal.fire({
                        title: 'Factura',
                        text: 'Debe de ingresar cantidad',
                        type: 'error',
                    })
                } else {
                    if (precioVenta === undefined || precioVenta === 0.00) {
                        Swal.fire({
                            title: 'Factura',
                            text: 'Debe de ingresar precio venta',
                            type: 'error',
                        })
                    } else {
                        let proN = data.producto.label.split("--");
                        let proID = proN[0].split("/");
                        let pro = data.producto.value.split('#');
                        let numero_bodega = data.bodega.label.split('/');
                        let lote = data.lote.value.split('#');
                        let loteDes = data.lote.label.split('/');
                        let descuento = data.descuento ? data.descuento : 0.00;
                        if (parseFloat(data.cantidad) > parseFloat(lote[1].trim())){
                            Swal.fire({
                                title: 'Factura',
                                text: 'Cantidad ingresada es mayor a la existencia',
                                type: 'error',
                            })
                        }else {
                            let totalFactura = parseFloat(parseFloat(data.cantidad) * parseFloat(precioVenta)).toFixed(2)
                            let totalDescuento = parseFloat(parseFloat(descuento) * parseFloat(data.cantidad)).toFixed(2)
                            detalleFac.push({
                                id: pro[0].trim(),
                                cantidad: data.cantidad,
                                idPro: pro[0].trim(),
                                sku: proID[0].trim(),
                                productoN: pro[3].trim(),
                                idBodega: data.bodega.value, 
                                bodega: numero_bodega[0],
                                precio: precioVenta,
                                lote: loteDes[0].trim(),
                                fecha_vencimiento: lote[2].trim(),
                                fecha_ven: lote[3].trim(),
                                descuento: parseFloat(descuento) == 0 ? '0.00' : descuento,
                                total: parseFloat(parseFloat(totalFactura) - parseFloat(totalDescuento)).toFixed(2)
                            })
                            data.cantidad = 0;
                            data.descripcion = '';
                            data.descuento = 0.00;
                            data.detalleFac = detalleFac;
                            NotificationManager.success(
                                'Producto ingresado correctamente',
                                'Exito',
                                3000
                            );
                            dispatch(actualizar());
                        }
                    }
                }
            }
        }
    } else {
        Swal.fire({
            title: 'Factura',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })
    }
}

const actualizar = () => (dispatch, getStore) => {
    const data = getStore().form.FormFacturaBienesFarmFel.values;
    dispatch(initializeForm('FormFacturaBienesFarmFel', data));
    dispatch({ type: DETALLE, detalleCon: data.detalleFac })
};

const vaciarBienes = () => (dispatch) => {
    detalleFac = [];
    dispatch(initializeForm('FormFacturaBienesFarmFel'));
    dispatch({ type: DETALLE, detalleCon: detalleFac });
};

const eliminarLinea = (id) => (dispatch) => {
    detalleFac.forEach((element, index) => {
        if (element.idPro === id) {
            detalleFac.splice(index, 1);
        }
    })
    dispatch(actualizar());
};

const listarPaises = () => (dispatch) => {
    return api.get("/paises").then(response=>{
        const pais = [];
        response.results.forEach(item=>{
            pais.push({
                value: item.id,
                label: item.pais
            })
        })
        dispatch({ type: LISTADO_PAISES, listaP: pais });
    }).catch(error=>{
        NotificationManager.error(
            'Ocurrió un error al listar paises',
            'Error',
            2000
        );
    })
}

export const listarClientes = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id, search}
    return api.get('/cliente/empresa_clientes',params).then(data=>{
        const list_cliente = [];
        if(data){
            data.forEach(item=>{
                list_cliente.push({
                    value: item.id,
                    label: item.identificador + ' ' + item.nombre
                })
            })
        }
        return list_cliente ;
    }).catch((error)=>{
        
        return [];
    })
}

export const registroFacturaBienes = (periodoD) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormFacturaBienesFarmFel.values;

    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fechaRegistro)
    if (fecha >= fechaI && fecha <= fechaF) {
        let total = 0.00;
        data.detalleFac.forEach((e) => {
            total += parseFloat(e.total);
        });
        if (data.tipo_factura === 'EXP' && data.tipo_cambio === 1.000000) {
            localStorage.setItem('conf_conf_FacturaBienesFarmFel', 'F');
            Swal.fire({
                title: 'Factura',
                text: 'Debe de ingresar tipo de cambio',
                type: 'error',
            })
        } else {
            const formData = {
                fecha_registro: data.fechaRegistro,
                cliente: data.cliente_documento.value,
                referencia: data.observaciones,
                tipo: localStorage.getItem('tipoFacBFarm'),
                moneda: localStorage.getItem('fact_bien_farm_TC'),
                establecimiento: id_emp[9],
                tipo_documento: localStorage.getItem('tipoDocumentoFacBFarm'),
                total: total,
                detalleFactura: data.detalleFac,
                empresa: id_emp[5],
                periodo: id_emp[7],
                tipo_cambio: localStorage.getItem('TC_facturaBienesFarm'),
                cuenta: data.cuenta ? data.cuenta.value : '',
            }
            dispatch(setLoader(true));
            api.post('/factura/factura_inventario_bienes_farm_fel/', formData).then((response) => {
                NotificationManager.success(
                    'Factura guardada correctamente',
                    'Exito',
                    3000
                );
                if (response.idPoliza){
                    localStorage.setItem('conf_conf_FacturaBienesFarmFel', 'T');
                    localStorage.setItem('id_poliza_bienes_factura_farm', response.idPoliza);
                    dispatch(leerDetalle(response.idPoliza));
                    Swal.fire({
                        title: '¿Ver factura?',
                        text: '¡Desea ver la factura!',
                        type: 'question',
                        showCancelButton: true,
                        confirmButtonText: '¡Sí, aceptar!',
                        cancelButtonText: 'No, cancelar',
                        reverseButtons: true
                    }).then((result) => {
                        if (result.value) {
                            window.open(`https://report.feel.com.gt/ingfacereport/ingfacereport_documento?uuid=${response.encabezado.numero_autorizacion}`, "_blank");
                            setTimeout(function () {
                                window.location.reload(true);
                            }, 3000);
                        }else {
                            setTimeout(function () {
                                window.location.reload(true);
                            }, 1000);
                        }
                    });
                }else{
                    Swal.fire({
                        title: '¿Ver factura?',
                        text: '¡Desea ver la factura!',
                        type: 'question',
                        showCancelButton: true,
                        confirmButtonText: '¡Sí, aceptar!',
                        cancelButtonText: 'No, cancelar',
                        reverseButtons: true
                    }).then((result) => {
                        if (result.value) {
                            window.open(`https://report.feel.com.gt/ingfacereport/ingfacereport_documento?uuid=${response.encabezado.numero_autorizacion}`, "_blank");
                            setTimeout(function () {
                                window.location.reload(true);
                            }, 3000);
                        }else {
                            setTimeout(function () {
                                window.location.reload(true);
                            }, 1000);
                        }
                    });
                }
            }).catch((error) => {
                localStorage.setItem('conf_conf_FacturaBienesFarmFel', 'F');
                NotificationManager.error(
                    error.msj,
                    'Error',
                    4000
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }
    }else {
        localStorage.setItem('conf_conf_FacturaBienesFarmFel', 'F');
        Swal.fire({
            title: 'Factura',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })        
    }
}

const leerDetalle = id => (dispatch) => {
    api.get(`/poliza_detalle/${id}`).then((response) => {
        dispatch({ type: DETALLE_POLIZA, detalle: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};


export const tipoCambio = (periodoD, exportacion) => (dispatch, getStore) => {
    const data = getStore().form.FormFactura.values;
    if (data === undefined) {
        Swal.fire({
            title: 'Factura',
            text: 'Debe de ingresar datos de la factura',
            type: 'error',
        })
    } else {   
        if (exportacion){
            let fechaI = moment(periodoD.fecha_inicio);
            let fechaF = moment(periodoD.fecha_fin);
            let fecha = moment(data.fechaRegistro)
            if (fecha>=fechaI && fecha<=fechaF){
                if (data.fechaRegistro === undefined) {
                    Swal.fire({
                        title: 'Factura',
                        text: 'Debe de ingresar fecha de la factura',
                        type: 'error',
                    })
                } else {
                    let fecha = data.fechaRegistro;
                    let ruta = window.location.href;
                    let id_emp = ruta.split('/');
                    let idEmpre = id_emp[5];
                    const params = { fecha, idEmpre }
                    dispatch(setLoaderC(true));
                    return api.get('/tipo_cambio/tipo_cambio_fecha/', params).then((response) => {
                        if (parseFloat(response) === 0) {
                            Swal.fire({
                                title: 'No hay tipo de cambio del dia' + ' ' + moment(fecha).format("DD/MM/YYYY"),
                                html:
                                    '<div class="col-sm-12 mt-4">' +
                                    '<h4 style="color: white;">¿Desea ingresarlo?</h4>' +
                                    '</div>',
                                type: 'info',
                                background: "black",
                                showCancelButton: true,
                                confirmButtonText: 'Si',
                                cancelButtonText: 'No',
                                confirmButtonColor: "#4040ff",
                                cancelButtonColor: "#646464",
                                reverseButtons: true,
                                customClass: {
                                    title: 'sweet_titleImportant',
                                },
                            }).then((result) => {
                                if (result.value) {
                                    Swal.fire({
                                        title: 'Ingrese tipo de cambio',
                                        html:
                                            '<div class="col-sm-12 mt-3">' +
                                            '<h4 style="color: white">' + moment(fecha).format("DD/MM/YYYY") + '</h4>' +
                                            '<input type="number" min="1" id="cambio" class="swal2-input form-control">' +
                                            '</div>',
                                        type: 'info',
                                        background: "black",
                                        showCancelButton: true,
                                        confirmButtonText: 'Si',
                                        cancelButtonText: 'No',
                                        confirmButtonColor: "#4040ff",
                                        cancelButtonColor: "#646464",
                                        reverseButtons: true,
                                        customClass: {
                                            title: 'sweet_titleImportant',
                                        },
                                    }).then((result) => {
                                        if (result.value) {
                                            let tcambio = document.getElementById('cambio').value
                                            const formData = {
                                                fechaT: fecha,
                                                tipoCambio: tcambio,
                                                empresa: id_emp[5],
                                                tipo: 'manual'
                                            }
                                            api.post('/tipo_cambio/', formData).then(response => {
                                                NotificationManager.success(
                                                    'Tipo de cambio guardado',
                                                    'Exito',
                                                    4000
                                                );

                                            }).catch((error) => {
                                                NotificationManager.error(
                                                    error.msj,
                                                    'Error',
                                                    0
                                                );
                                            })
                                        }
                                    });
                                }
                            });
                        } else {
                            return response;
                        }
                    }).catch((error) => {
                        NotificationManager.error(
                            'Ocurrió un error al consultar tipo de cambio',
                            'Error',
                            2000
                        );
                    }).finally(() => {
                        dispatch(setLoaderC(false));
                    });
                }    
            }else{
                Swal.fire({
                    title: 'Factura',
                    text: 'Fecha fuera del periodo',
                    type: 'error',
                })        
            }
        }
    }
}

export const tipoCambioB = (periodoD) => (dispatch, getStore) => {
    const data = getStore().form.FormFacturaBienesFarmFel.values;
    
    if (data === undefined) {
        Swal.fire({
            title: 'Factura',
            text: 'Debe de ingresar datos de la factura',
            type: 'error',
        })
    } else {   
        let fechaI = moment(periodoD.fecha_inicio);
        let fechaF = moment(periodoD.fecha_fin);
        let fecha = moment(data.fechaRegistro)
        if (fecha >= fechaI && fecha <= fechaF) {
            if (data.fechaRegistro === undefined) {
                Swal.fire({
                    title: 'Factura',
                    text: 'Debe de ingresar fecha de la factura',
                    type: 'error',
                })
            } else {
                let fecha = data.fechaRegistro;
                let ruta = window.location.href;
                let id_emp = ruta.split('/');
                let idEmpre = id_emp[5];
                const params = { fecha, idEmpre }
                dispatch(setLoaderC(true));
                return api.get('/tipo_cambio/tipo_cambio_fecha/', params).then((response) => {
                    if (parseFloat(response) === 0) {
                        Swal.fire({
                            title: 'No hay tipo de cambio del dia' + ' ' + moment(fecha).format("DD/MM/YYYY"),
                            html:
                                '<div class="col-sm-12 mt-4">' +
                                '<h4 style="color: white;">¿Desea ingresarlo?</h4>' +
                                '</div>',
                            type: 'info',
                            background: "black",
                            showCancelButton: true,
                            confirmButtonText: 'Si',
                            cancelButtonText: 'No',
                            confirmButtonColor: "#4040ff",
                            cancelButtonColor: "#646464",
                            reverseButtons: true,
                            customClass: {
                                title: 'sweet_titleImportant',
                            },
                        }).then((result) => {
                            if (result.value) {
                                Swal.fire({
                                    title: 'Ingrese tipo de cambio',
                                    html:
                                        '<div class="col-sm-12 mt-3">' +
                                        '<h4 style="color: white">' + moment(fecha).format("DD/MM/YYYY") + '</h4>' +
                                        '<input type="number" min="1" id="cambio" class="swal2-input form-control">' +
                                        '</div>',
                                    type: 'info',
                                    background: "black",
                                    showCancelButton: true,
                                    confirmButtonText: 'Si',
                                    cancelButtonText: 'No',
                                    confirmButtonColor: "#4040ff",
                                    cancelButtonColor: "#646464",
                                    reverseButtons: true,
                                    customClass: {
                                        title: 'sweet_titleImportant',
                                    },
                                }).then((result) => {
                                    if (result.value) {
                                        let tcambio = document.getElementById('cambio').value
                                        const formData = {
                                            fechaT: fecha,
                                            tipoCambio: tcambio,
                                            empresa: id_emp[5],
                                            tipo: 'manual'
                                        }
                                        api.post('/tipo_cambio/', formData).then(response => {
                                            NotificationManager.success(
                                                'Tipo de cambio guardado',
                                                'Exito',
                                                4000
                                            );

                                        }).catch((error) => {
                                            NotificationManager.error(
                                                error.msj,
                                                'Error',
                                                0
                                            );
                                        })
                                    }
                                });
                            }
                        });
                    } else {
                        return response;
                    }
                }).catch((error) => {
                    NotificationManager.error(
                        'Ocurrió un error al consultar tipo de cambio',
                        'Error',
                        2000
                    );
                }).finally(() => {
                    dispatch(setLoaderC(false));
                });
            }
        } else {
            Swal.fire({
                title: 'Factura',
                text: 'Fecha fuera del periodo',
                type: 'error',
            })
        }
        
    }
}


let detalleFacServ = [];
export const agregarLineaServ = (periodoD) => (dispatch, getStore) => {
    const data = getStore().form.FormFacturaServiciosFarmFel.values;
    
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fecha);

    if (fecha >= fechaI && fecha <= fechaF) {
        if (data.descripcion_serv === undefined || data.descripcion_serv === 0.00) {
            Swal.fire({
                title: 'Factura',
                text: 'Debe de ingresar descripción',
                type: 'error',
            })
        } else {
            if (data.cantidad === undefined || data.cantidad === 0.000000) {
                Swal.fire({
                    title: 'Factura',
                    text: 'Debe de ingresar cantidad',
                    type: 'error',
                })
            } else {
                if (data.precio === undefined || data.precio === 0.00) {
                    Swal.fire({
                        title: 'Factura',
                        text: 'Debe de ingresar precio venta',
                        type: 'error',
                    })
                } else {
                    detalleFacServ.push({
                        id: data.cantidad,
                        desc: data.descripcion_serv,
                        cantidad: data.cantidad,
                        costo: data.precio,
                        total: parseFloat(parseFloat(data.cantidad) * parseFloat(data.precio)).toFixed(2)
                    })
                    data.cantidad = 0;
                    data.descripcion_serv = '';
                    data.precio = 0.00;
                    data.detalleFacServ = detalleFacServ;
                    NotificationManager.success(
                        'Producto ingresado correctamente',
                        'Exito',
                        3000
                    );
                    dispatch(actualizarServ());
                }
            }
        }
    } else {
        Swal.fire({
            title: 'Factura',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })
    }
}

const actualizarServ = () => (dispatch, getStore) => {
    const data = getStore().form.FormFacturaServiciosFarmFel.values;
    dispatch(initializeForm('FormFacturaServiciosFarmFel', data));
    dispatch({ type: DETALLE, detalleCon: data.detalleFacServ })
};

const vaciarServicios = () => (dispatch) => {
    detalleFacServ = [];
    dispatch(initializeForm('FormFacturaServiciosFarmFel'));
    dispatch({ type: DETALLE, detalleCon: detalleFacServ });
};

const eliminarLineaServ = (id) => (dispatch) => {
    detalleFacServ.forEach((element, index) => {
        if (element.idPro === id) {
            detalleFacServ.splice(index, 1);
        }
    })
    dispatch(actualizar());
};

export const registroFacturaServicio = (periodoD) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormFacturaServiciosFarmFel.values;
    
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fecha)
    if (fecha>=fechaI && fecha<=fechaF){
        let total = 0.00;
        data.detalleFacServ.forEach( (e) => { 
            total += parseFloat(e.total);
        });
            const formData = {
                fechaRegistro: data.fecha,
                idCliente: data.cliente_documento.value,
                observaciones: data.descripcion,
                tipo: localStorage.getItem('tipoFacSFarm'),
                moneda: localStorage.getItem('fact_serv_farm_TC'),
                establecimiento: id_emp[9],
                detalleFactura: data.detalleFacServ,
                tipo_documento: localStorage.getItem('tipoDocumentoFacSFarm'),
                total: total,
                motivo: 'FACT',
                empresa: id_emp[5],
                periodo: id_emp[7],
                tipo_cambio: localStorage.getItem('TC_facturaServicioFarm'),
                cuenta: data.cuenta ? data.cuenta.value : '',
            }

            dispatch(setLoader(true));
            api.post('/factura/factura_servicio_farm_fel/', formData).then((response) => {
                NotificationManager.success(
                    'Factura guardada correctamente',
                    'Exito',
                    3000
                );
                if (response.idPoliza){
                    localStorage.setItem('conf_conf_FacturaServiciosFarmFel', 'T');
                    localStorage.setItem('id_poliza_servicios_factura_farm', response.idPoliza);
                    dispatch(leerDetalle(response.idPoliza));
                    Swal.fire({
                        title: '¿Ver factura?',
                        text: '¡Desea ver la factura!',
                        type: 'question',
                        showCancelButton: true,
                        confirmButtonText: '¡Sí, aceptar!',
                        cancelButtonText: 'No, cancelar',
                        reverseButtons: true
                    }).then((result) => {
                        if (result.value) {
                            window.open(`https://report.feel.com.gt/ingfacereport/ingfacereport_documento?uuid=${response.encabezado.numero_autorizacion}`, "_blank");
                            setTimeout(function () {
                                window.location.reload(true);
                            }, 3000);
                        }else {
                            setTimeout(function () {
                                window.location.reload(true);
                            }, 1000);
                        }
                    });
                }else{
                    Swal.fire({
                        title: '¿Ver factura?',
                        text: '¡Desea ver la factura!',
                        type: 'question',
                        showCancelButton: true,
                        confirmButtonText: '¡Sí, aceptar!',
                        cancelButtonText: 'No, cancelar',
                        reverseButtons: true
                    }).then((result) => {
                        if (result.value) {
                            window.open(`https://report.feel.com.gt/ingfacereport/ingfacereport_documento?uuid=${response.encabezado.numero_autorizacion}`, "_blank");
                            setTimeout(function () {
                                window.location.reload(true);
                            }, 3000);
                        }else {
                            setTimeout(function () {
                                window.location.reload(true);
                            }, 1000);
                        }
                    });
                }
            }).catch((error) => {
                localStorage.setItem('conf_conf_FacturaServiciosFarmFel', 'F');
                NotificationManager.error(
                    error.msj,
                    'Error',
                    4000
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        
    }else {
        localStorage.setItem('conf_conf_FacturaServiciosFarmFel', 'F');
        Swal.fire({
            title: 'Factura',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })        
    }
}

export const tipoCambioServ = (periodoD) => (dispatch, getStore) => {
    const data = getStore().form.FormFacturaServiciosFel.values;
    
    if (data === undefined) {
        Swal.fire({
            title: 'Factura',
            text: 'Debe de ingresar datos de la factura',
            type: 'error',
        })
    } else {   
        let fechaI = moment(periodoD.fecha_inicio);
        let fechaF = moment(periodoD.fecha_fin);
        let fecha = moment(data.fecha)
        if (fecha >= fechaI && fecha <= fechaF) {
            if (data.fecha === undefined) {
                Swal.fire({
                    title: 'Factura',
                    text: 'Debe de ingresar fecha de la factura',
                    type: 'error',
                })
            } else {
                let fecha = data.fecha;
                let ruta = window.location.href;
                let id_emp = ruta.split('/');
                let idEmpre = id_emp[5];
                const params = { fecha, idEmpre }
                dispatch(setLoaderC(true));
                return api.get('/tipo_cambio/tipo_cambio_fecha/', params).then((response) => {
                    if (parseFloat(response) === 0) {
                        Swal.fire({
                            title: 'No hay tipo de cambio del dia' + ' ' + moment(fecha).format("DD/MM/YYYY"),
                            html:
                                '<div class="col-sm-12 mt-4">' +
                                '<h4 style="color: white;">¿Desea ingresarlo?</h4>' +
                                '</div>',
                            type: 'info',
                            background: "black",
                            showCancelButton: true,
                            confirmButtonText: 'Si',
                            cancelButtonText: 'No',
                            confirmButtonColor: "#4040ff",
                            cancelButtonColor: "#646464",
                            reverseButtons: true,
                            customClass: {
                                title: 'sweet_titleImportant',
                            },
                        }).then((result) => {
                            if (result.value) {
                                Swal.fire({
                                    title: 'Ingrese tipo de cambio',
                                    html:
                                        '<div class="col-sm-12 mt-3">' +
                                        '<h4 style="color: white">' + moment(fecha).format("DD/MM/YYYY") + '</h4>' +
                                        '<input type="number" min="1" id="cambio" class="swal2-input form-control">' +
                                        '</div>',
                                    type: 'info',
                                    background: "black",
                                    showCancelButton: true,
                                    confirmButtonText: 'Si',
                                    cancelButtonText: 'No',
                                    confirmButtonColor: "#4040ff",
                                    cancelButtonColor: "#646464",
                                    reverseButtons: true,
                                    customClass: {
                                        title: 'sweet_titleImportant',
                                    },
                                }).then((result) => {
                                    if (result.value) {
                                        let tcambio = document.getElementById('cambio').value
                                        const formData = {
                                            fechaT: fecha,
                                            tipoCambio: tcambio,
                                            empresa: id_emp[5],
                                            tipo: 'manual'
                                        }
                                        api.post('/tipo_cambio/', formData).then(response => {
                                            NotificationManager.success(
                                                'Tipo de cambio guardado',
                                                'Exito',
                                                4000
                                            );

                                        }).catch((error) => {
                                            NotificationManager.error(
                                                error.msj,
                                                'Error',
                                                0
                                            );
                                        })
                                    }
                                });
                            }
                        });
                    } else {
                        return response;
                    }
                }).catch((error) => {
                    NotificationManager.error(
                        'Ocurrió un error al consultar tipo de cambio',
                        'Error',
                        2000
                    );
                }).finally(() => {
                    dispatch(setLoaderC(false));
                });
            }
        } else {
            Swal.fire({
                title: 'Factura',
                text: 'Fecha fuera del periodo',
                type: 'error',
            })
        }
        
    }
}


export const leerS = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/factura/${id}`).then((response) => {
        response.fechaRegistro = response.fecha;
        response.observaciones = response.descripcion;
        response.clienteN = response.nombreCli;
        response.correlativo = response.serie + ' - ' + response.numero;
        response.serie = response.serie_FEL;
        response.numero = response.numero_FEL;
        response.autorizacion = response.numero_autorizacion;
        dispatch({ type: GUARDAR_FACTURA, lecturaFac: response });
        dispatch(initializeForm('FormFacturaServiciosFarmFel', response ));
        dispatch(leerDetalleServicios(response.id));
        if (response.poliza){
            dispatch(leerDetalleMod(response.poliza));
        }else{
            response.polizaDetalle_polizaEncabezado = [];
            dispatch({ type: GUARDAR_POLIZA, lectura: response });
        }
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar factura',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

const leerDetalleServicios = (id) => (dispatch) => {
    api.get(`/factura_detalle/${id}`).then((response)=>{
        dispatch({ type: DETALLE_PRODUCTOS, detalleProducto: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};


export const listarMonedas = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    api.get('/monedas/moneda_empresa', params).then((response) => {
        const moneda = [];
        response.forEach(item => {
            moneda.push({
                value: item.id,
                label: item.moneda + ' - ' + item.simbolo
            })
        })
        /*const monedaNuevo = [];
        response.forEach(item => {
            monedaNuevo.push({
                value: item.id + '#' + item.simbolo,
                label: item.moneda + ' - ' + item.simbolo
            })
        })*/
        dispatch({ type: LISTADO_MONEDAS, monedas: moneda });
        //dispatch({ type: LISTADO_MONEDAS_NUEVO, monedasNuevo: monedaNuevo });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar monedas',
            'Error',
            3000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const registroCliente = (datos) => (dispatch, getStore) => {
    const data = getStore().form.FormFacturaBienesFel.values;
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    if (datos.validar_nit === 'S'){
        if (data.identificador === 'CF'){
            const formData = {
                identificador: data.identificador,
                nombre: data.nombre,
                direccion: data.direccion,
                telefono: data.telefono,
                correo: data.correo,
                limite: data.limite_credito,
                tipo_cliente: data.tipo_cliente,
                dias_credito: data.dias_credito,
                pais: data.pais,
                modulo: 'FAC',
                empresa: id_emp[5]
            }
            dispatch(setLoader(true));
            api.post('/cliente/', formData).then(() => {
                NotificationManager.success(
                    'Cliente creado',
                    'Exito',
                    3000
                );
            }).catch((error) => {
                NotificationManager.error(
                    error.msj,
                    'Error',
                    0
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }else{
            let valid = validar.valNit(data.identificador);
            if (valid === false){
                Swal.fire({
                    title: 'Cliente',
                    text: 'Ingrese Nit válido',
                    type: 'error',
                })
            }else{
                const formData = {
                    identificador: data.identificador,
                    nombre: data.nombre,
                    direccion: data.direccion,
                    telefono: data.telefono,
                    correo: data.correo,
                    limite: data.limite_credito,
                    tipo_cliente: data.tipo_cliente,
                    dias_credito: data.dias_credito,
                    pais: data.pais,
                    modulo: 'FAC',
                    empresa: id_emp[5]
                }
                dispatch(setLoader(true));
                api.post('/cliente/', formData).then(() => {
                    NotificationManager.success(
                        'Cliente creado',
                        'Exito',
                        3000
                    );
                }).catch((error) => {
                    NotificationManager.error(
                        error.msj,
                        'Error',
                        2000
                    );
                }).finally(() => {
                    dispatch(setLoader(false));
                });
            }
        }
    }else{
        const formData = {
            identificador: data.identificador,
            nombre: data.nombre,
            direccion: data.direccion,
            telefono: data.telefono,
            correo: data.correo,
            limite: data.limite_credito,
            tipo_cliente: data.tipo_cliente,
            dias_credito: data.dias_credito,
            pais: data.pais,
            modulo: 'FAC',
            empresa: id_emp[5]
        }
        dispatch(setLoader(true));
        api.post('/cliente/', formData).then(() => {
            NotificationManager.success(
                'Cliente creado',
                'Exito',
                3000
            );
        }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                2000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
}

export const listarNiveles = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    dispatch(setLoader(true));
    api.get('/niveles/nivel_cuenta', params).then((response) => {
        dispatch({ type: NIVEL, nivel: response });
    }).catch((error) => {
        
        NotificationManager.error(
            'Ocurrió un error al listar nivel',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const listarCuentas = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/cuentas/empresa_cuentas/', params).then(data => {
        const list_cuentas = [];
        if (data) {
            data.forEach(item => {
                if (item.tipo === 'A') {
                    list_cuentas.push({
                        value: item.id,
                        label: item.cuenta + ' ' + item.nombre,
                        isDisabled: true
                    })
                } else {
                    list_cuentas.push({
                        value: item.id,
                        label: item.cuenta + ' ' + item.nombre
                    })
                }
            })
        }
        return list_cuentas;
    }).catch((error) => {
        
        return [];
    })
}

export const listarCC = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/centrocosto/empresa_centro_costo/', params).then(data => {
        const list_cc = [];
        if (data) {
            data.forEach(item => {
                list_cc.push({
                    value: item.id,
                    label: item.codigo + ' ' + item.descripcion
                })
            })
        }
        return list_cc;
    }).catch((error) => {
        
        return [];
    })
}

export const registroCuenta = (formato) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.BienesFarmFormTabs.values;
    let cuenta = data.cuenta
    let f = formato.split('-');
    let cont = 0;
    let formatoCuenta = '';
    let digito = '';

    if (f.length === 3) {
        let nivel1 = f[0].length
        digito = cuenta.substr(cont, nivel1);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel2 = f[1].length;
        cont = cont + nivel1;
        digito = cuenta.substr(cont, nivel2);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel3 = f[2].length;
        cont = cont + nivel2;
        digito = cuenta.substr(cont, nivel3);
        formatoCuenta = formatoCuenta + digito;

    }

    if (f.length === 4) {
        let nivel1 = f[0].length
        digito = cuenta.substr(cont, nivel1);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel2 = f[1].length;
        cont = cont + nivel1;
        digito = cuenta.substr(cont, nivel2);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel3 = f[2].length;
        cont = cont + nivel2;
        digito = cuenta.substr(cont, nivel3);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel4 = f[3].length;
        cont = cont + nivel3;
        digito = cuenta.substr(cont, nivel4);
        formatoCuenta = formatoCuenta + digito;
    }

    if (f.length === 5) {
        let nivel1 = f[0].length
        digito = cuenta.substr(cont, nivel1);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel2 = f[1].length;
        cont = cont + nivel1;
        digito = cuenta.substr(cont, nivel2);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel3 = f[2].length;
        cont = cont + nivel2;
        digito = cuenta.substr(cont, nivel3);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel4 = f[3].length;
        cont = cont + nivel3;
        digito = cuenta.substr(cont, nivel4);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel5 = f[4].length;
        cont = cont + nivel4;
        digito = cuenta.substr(cont, nivel5);
        formatoCuenta = formatoCuenta + digito;
    }

    if (f.length === 6) {
        let nivel1 = f[0].length
        digito = cuenta.substr(cont, nivel1);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel2 = f[1].length;
        cont = cont + nivel1;
        digito = cuenta.substr(cont, nivel2);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel3 = f[2].length;
        cont = cont + nivel2;
        digito = cuenta.substr(cont, nivel3);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel4 = f[3].length;
        cont = cont + nivel3;
        digito = cuenta.substr(cont, nivel4);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel5 = f[4].length;
        cont = cont + nivel4;
        digito = cuenta.substr(cont, nivel5);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel6 = f[5].length;
        cont = cont + nivel5;
        digito = cuenta.substr(cont, nivel6);
        formatoCuenta = formatoCuenta + digito;
    }
    let idPoliza = localStorage.getItem('idPoliza')
    const formData = {
        cuenta: formatoCuenta,
        nombre: data.nombre,
        tipo: data.tipoC,
        naturaleza: data.naturaleza,
        empresa: id_emp[5],
        poliza: idPoliza
    }
    dispatch(setLoaderC(true));
    api.post('/cuentas/', formData).then((response) => {
        if (idPoliza) {
            NotificationManager.success(
                'Cuenta creada' + ' ' + response.cuenta.cuenta + ' ' + response.cuenta.nombre,
                'Exito',
                0
            );
            response.cuenta = '';
            response.nombre = '';
            response.fecha = response.poliza.fecha;
            response.tipo = response.poliza.tipo;
            response.numero_poliza = response.poliza.numero_poliza;
            response.descripcion = response.poliza.descripcion;
            listarCuentas();

        }else{
            NotificationManager.success(
                'Cuenta creada' + ' ' + response.cuenta + ' ' + response.nombre,
                'Exito',
                0
            );
            response.cuenta = '';
            response.nombre = '';
            listarCuentas();
        }

    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoaderC(false));
    });
}

export const confirmarPoliza = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let dif = localStorage.getItem('dif');
    if (parseFloat(dif) === 0.00) {
        localStorage.removeItem('id_poliza_bienes_factura_farm');
        localStorage.removeItem('id_poliza_servicios_factura_farm');
        localStorage.removeItem('dif');
        NotificationManager.success(
            'Póliza guardada correctamente',
            'Exito',
            3000
        );
        const editar = window.location.href.includes('editar');
        if (editar){
            let ruta = `/facturacion/${id_emp[5]}/facturas/${id_emp[7]}`
            dispatch(push(ruta));
        }else{
            window.location.reload(true);
        }
        
    } else {
        Swal.fire({
            title: 'Modificar poliza',
            text: `Poliza con diferencia: ${new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(dif)}`,
            type: 'error',
        })
    }
}

export const registroPolizaDetalleSCCBienes = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.BienesFarmFormTabs.values;
    const poliza = localStorage.getItem('id_poliza_bienes_factura_farm');
    const formData = {
        empresa: id_emp[5],
        periodo: id_emp[5],
        cc: 0,
        cuenta: data.cuenta.value,
        debe: data.debe,
        haber: data.haber,
        poliza: poliza
    }
    if (formData.debe === undefined && formData.haber === undefined) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'Debe de ingresar valor en debe o haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) === 0.00 && parseFloat(formData.haber) === 0.00) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'No puede llevar valores a 0.00 en debe o haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) >= 0.01 && parseFloat(formData.haber) >= 0.01) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'No puede llevar valores en debe y haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) >= 0.01) {
        formData.haber = 0.00;
        dispatch(setLoader(true));
        api.post('/polizas/encabezado_polizas/', formData).then((response) => {
            const poliza = localStorage.getItem('id_poliza_bienes_factura_farm')
            if (poliza === null) {
                response.debe = 0.00;
                response.haber = 0.00;
                dispatch(initializeForm('BienesFarmFormTabs', response));
                dispatch(leerDetalle(response.detalle.encabezado));
            } else {
                const editar = window.location.href.includes('editar');
                if (editar) {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('BienesFarmFormTabs', response));
                    dispatch(leerDetalleMod(poliza));
                } else {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('BienesFarmFormTabs', response));
                    dispatch(leerDetalle(poliza));
                }
            }

        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al registrar la poliza',
                'Error',
                2000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    } else if (parseFloat(formData.haber) >= 0.01) {
        formData.debe = 0.00;
        api.post('/polizas/encabezado_polizas/', formData).then((response) => {
            localStorage.removeItem('tipo_poliza');
            const poliza = localStorage.getItem('id_poliza_bienes_factura_farm')
            if (poliza === null) {
                response.debe = 0.00;
                response.haber = 0.00;
                dispatch(initializeForm('BienesFarmFormTabs', response));
                dispatch(leerDetalle(response.detalle.encabezado));
            } else {
                const editar = window.location.href.includes('editar');
                if (editar) {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('BienesFarmFormTabs', response));
                    dispatch(leerDetalleMod(poliza));
                } else {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('BienesFarmFormTabs', response));
                    dispatch(leerDetalle(poliza));
                }
            }

        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al registrar la poliza',
                'Error',
                2000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
}

export const registroPolizaDetalleSCCServicio = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.ServiciosFarmFormTabs.values;
    const poliza = localStorage.getItem('id_poliza_servicios_factura_farm');
    const formData = {
        empresa: id_emp[5],
        periodo: id_emp[5],
        cc: 0,
        cuenta: data.cuenta.value,
        debe: data.debe,
        haber: data.haber,
        poliza: poliza
    }
    if (formData.debe === undefined && formData.haber === undefined) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'Debe de ingresar valor en debe o haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) === 0.00 && parseFloat(formData.haber) === 0.00) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'No puede llevar valores a 0.00 en debe o haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) >= 0.01 && parseFloat(formData.haber) >= 0.01) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'No puede llevar valores en debe y haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) >= 0.01) {
        formData.haber = 0.00;
        dispatch(setLoader(true));
        api.post('/polizas/encabezado_polizas/', formData).then((response) => {
            const poliza = localStorage.getItem('id_poliza_servicios_factura_farm')
            if (poliza === null) {
                response.debe = 0.00;
                response.haber = 0.00;
                dispatch(initializeForm('ServiciosFarmFormTabs', response));
                dispatch(leerDetalle(response.detalle.encabezado));
            } else {
                const editar = window.location.href.includes('editar');
                if (editar) {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('ServiciosFarmFormTabs', response));
                    dispatch(leerDetalleMod(poliza));
                } else {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('ServiciosFarmFormTabs', response));
                    dispatch(leerDetalle(poliza));
                }
            }

        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al registrar la poliza',
                'Error',
                2000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    } else if (parseFloat(formData.haber) >= 0.01) {
        formData.debe = 0.00;
        api.post('/polizas/encabezado_polizas/', formData).then((response) => {
            localStorage.removeItem('tipo_poliza');
            const poliza = localStorage.getItem('id_poliza_servicios_factura_farm')
            if (poliza === null) {
                response.debe = 0.00;
                response.haber = 0.00;
                dispatch(initializeForm('ServiciosFarmFormTabs', response));
                dispatch(leerDetalle(response.detalle.encabezado));
            } else {
                const editar = window.location.href.includes('editar');
                if (editar) {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('ServiciosFarmFormTabs', response));
                    dispatch(leerDetalleMod(poliza));
                } else {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('ServiciosFarmFormTabs', response));
                    dispatch(leerDetalle(poliza));
                }
            }

        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al registrar la poliza',
                'Error',
                2000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
}

const eliminarLineaPoliza = id => (dispatch) => {
    const poliza = localStorage.getItem('id_poliza_bienes_factura_farm');
    dispatch(setLoader(true));
    api.eliminar(`poliza_detalle/${id}`).then(() => {
        const editar = window.location.href.includes('editar');
        if (editar) {
            dispatch(leerDetalleMod(poliza));
        } else {
            dispatch(leerDetalle(poliza));
        }
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar linea de poliza',
            'Error',
            3000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const eliminarLineaPolizaServicio = id => (dispatch) => {
    const poliza = localStorage.getItem('id_poliza_servicios_factura_farm');
    dispatch(setLoader(true));
    api.eliminar(`poliza_detalle/${id}`).then(() => {
        const editar = window.location.href.includes('editar');
        if (editar) {
            dispatch(leerDetalleMod(poliza));
        } else {
            dispatch(leerDetalle(poliza));
        }
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar linea de poliza',
            'Error',
            3000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const precioVenta = (id) => (dispatch, getStore) => {
    const data = getStore().form.FormFacturaBienesFarmFel.values;
    if (data === undefined){
        Swal.fire({
            title: 'Facturación',
            text: 'Debe de seleccionar producto',
            type: 'error',
        })
    }else{
        if (data.cliente_documento === undefined){
            Swal.fire({
                title: 'Facturación',
                text: 'Debe de seleccionar cliente',
                type: 'error',
            })
        }else{
            let ruta = window.location.href;
            let id_emp = ruta.split('/');
            let idEmpre = id_emp[5];
            let cliente = data.cliente_documento.value;
            const params = {id, idEmpre, cliente }
            dispatch(setLoader(true));
            return api.get('/producto/precio_venta_producto/',params).then((response) => {
                return response.precio;
            }).catch((error) => {
                NotificationManager.error(
                    error.msj,
                    'Error',
                    0
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });

        }
    }
}

export const actions = {
    leerB,
    leerS,
    getModulo,
    leerPeriodoD,
    parametrosEmpresa,
    leerDetalleProductos,
    leerEstablecimiento,
    listarEstablecimientos,
    listarTiposDocu,
    listarBodegas,
    leerProductos,
    agregarLinea,
    actualizar,
    vaciarBienes,
    eliminarLinea,
    listarPaises,
    listarClientes,
    registroFacturaBienes,
    tipoCambio,
    tipoCambioB,
    agregarLineaServ,
    vaciarServicios,
    eliminarLineaServ,
    registroFacturaServicio,
    tipoCambioServ,
    listarMonedas,
    registroCliente,
    listarNiveles,
    leerLotes,
    listarCuentas,
    listarCC,
    confirmarPoliza,
    registroCuenta,
    registroPolizaDetalleSCCBienes,
    registroPolizaDetalleSCCServicio,
    eliminarLineaPoliza,
    eliminarLineaPolizaServicio,
    precioVenta
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_FACTURA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [PERIODO_D]: (state, { periodoD }) => {
        return {
            ...state,
            periodoD,
        };
    },
    [PARAMETRO]: (state, { datos }) => {
        return {
            ...state,
            datos,
        };
    },
    [DETALLE_PRODUCTOS]: (state, { detalleProducto }) => {
        return {
            ...state,
            detalleProducto,
        };
    },
    [CLIENTE]: (state, { cli }) => {
        return {
            ...state,
            cli,
        };
    },
    [DETALLE_ENVIO]: (state, { detalleEnv }) => {
        return {
            ...state,
            detalleEnv,
        };
    },
    [ESTABLECIMIENTO]: (state, { estable }) => {
        return {
            ...state,
            estable,
        };
    },
    [LISTADO_ESTABLECIMIENTOS]: (state, { listaEs }) => {
        return {
            ...state,
            listaEs,
        };
    },
    [LISTADO_TIPOS_DOCU]: (state, { tipos }) => {
        return {
            ...state,
            tipos,
        };
    },
    [GUARDAR_FACTURA]: (state, { lecturaFac }) => {
        return {
            ...state,
            lecturaFac,
        };
    },
    [PRODUCTOS]: (state, { pro }) => {
        return {
            ...state,
            pro,
        };
    },
    [DETALLE]: (state, { detalleCon }) => {
        return {
            ...state,
            detalleCon,
        };
    },
    [LISTADO_PAISES]: (state, { listaP }) => {
        return {
            ...state,
            listaP,
        };
    },
    [LOADERC]: (state, { loaderC }) => {
        return {
            ...state,
            loaderC,
        };
    },
    [LISTADO_MONEDAS]: (state, { monedas }) => {
        return {
            ...state,
            monedas,
        };
    },
    [NIVEL]: (state, { nivel }) => {
        return {
            ...state,
            nivel,
        };
    },
    [LISTADO_MONEDAS_NUEVO]: (state, { monedasNuevo }) => {
        return {
            ...state,
            monedasNuevo,
        };
    },
    [LOTES]: (state, { lotes }) => {
        return {
            ...state,
            lotes,
        };
    },
    [DETALLE_POLIZA]: (state, { detalle }) => {
        return {
            ...state,
            detalle,
        };
    },
    [GUARDAR_POLIZA]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    }
};

export const initialState = {
    loader: false,
    lectura: {
        polizaDetalle_polizaEncabezado: []
    },
    modulo: [],
    periodoD: [],
    cli: [],
    detalleProducto: [],
    detalleEnv: [],
    estable: [],
    listaEs: [],
    tipos: [],
    datos: [],
    pro: [],
    loaderC: false,
    listaP: [],
    monedas: [],
    detalleCon: [],
    data: {
        results: [],
        count: 0,
    },
    page: 1,
    nivel: [],
    monedasNuevo: [],
    lotes: [],
    detalle: [],
    lecturaFac: []
};

export default handleActions(reducers, initialState)

    