import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import MenuPopover from "../../components/MenuPopover";
import LoadMask from "../../Utils/LoadMask/LoadMask";
import { FicDatePicker } from "../../Utils/FormInputComponents";
import GenBtn from "../../components/GenBtn";
import { crear } from "./actions";
import { processFormData } from "./helpers";
import { startOfMonth, endOfMonth, parseDate } from "@internationalized/date";

const schema = yup.object({
    fecha_periodo: yup.string().required("El mes y año es requerido"),
});

export default function AgregarPeriodoPopover(props) {
    const [isLoading, setIsLoading] = useState(false);
    const { control, reset, handleSubmit, watch } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: {
            fecha_periodo: null,
        },
    });

    async function onSubmit(data) {
        setIsLoading(true);
        data.estado = "A"
        const date = parseDate(data.fecha_periodo);
        const firstDay = startOfMonth(date);
        const lastDay = endOfMonth(date);
        data.fecha_inicio = firstDay;
        data.fecha_fin = lastDay;
        await crear(processFormData(data));
        setIsLoading(false);
        reset();
    }

    return (
        <MenuPopover {...props}>
            <form
                onSubmit={handleSubmit(onSubmit)}
                style={{ display: "flex", flexDirection: "column" }}
            >
                <div
                    style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        color: "#bd0034",
                    }}
                >
                    Agregar periodo
                </div>
                <div>
                    <FicDatePicker
                        control={control}
                        label="Mes y año"
                        name="fecha_periodo"
                        granularity="month"
                        noBtn
                    />
                </div>
                <GenBtn
                    style={{ margin: "1rem 0" }}
                    type="submit"
                    isDisabled={isLoading}
                >
                    Guardar
                </GenBtn>
            </form>
        </MenuPopover>
    );
}
