import React from "react";
import {
    Button,
    Menu,
    MenuItem,
    MenuTrigger,
    Popover,
    Separator,
} from "react-aria-components";
import {
    FaBars,
    FaAccusoft,
    FaAddressCard,
    FaArrowDown,
    FaArrowLeft,
    FaChevronLeft,
} from "react-icons/fa";
import { Link } from "react-router-dom";

import styled, { keyframes } from "styled-components";
const popoverSlide = keyframes`
    from {
        transform: translateY(-10px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
`;
const StyledButton = styled(Button)`
    padding: 7px 12px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: blue; */
    box-shadow: inset 0 0 0px #000000;
    transition: box-shadow 250ms, border 250ms;
    font-weight: 700;
    color: #009c03;
    border: 1px solid #009c03;
    background: hsla(121, 100%, 31%, 0.2);
    transition: box-shadow 400ms ease;

    &[data-hovered] {
        border-color: #969696;
        box-shadow: 0 0 0 1px #969696;
    }
    &[data-focused] {
        border-color: #bababa;
        box-shadow: 0 0 0 2px #bababa;
    }
    &[data-pressed] {
        border-color: #464646;
        box-shadow: inset 0 0 10px #000000;
        transition: box-shadow 20ms, border 15ms, text-shadow 15ms;
    }
`;
// const StyledButton = styled(Button)
//     position: relative;
//     display: flex;
//     align-items: center;
//     font-size: large;
//     gap: 0.15rem;
//     border: none;
//     color: #fff !important;
//     background-color: #121212;
//     padding: 0.571rem 1.143rem;
//     ::after {
//         content: "";
//         position: absolute;
//         left: 0;
//         right: 0;
//         bottom: 0;
//         background-color: #bd0034;
//         height: 2px;
//         width: 0;
//         transition: width 150ms ease-in-out;
//         margin: 0 auto;
//     }

//     .open-indicator {
//         font-size: 1rem;
//         transition: transform 150ms;
//         transform: rotate(0);
//         transform-origin: center;
//     }

//     &[data-hovered] {
//         color: #bd0034;
//         ::after {
//             width: 35%;
//         }
//     }
//     &[data-pressed] {
//         ::after {
//             width: 100%;
//         }

//         color: #bd0034 !important;
//         .open-indicator {
//             transform: rotate(-90deg);
//         }
//     }
// `;
const StyledMenu = styled(Menu)`
    padding: 0.5rem;
    min-width: 150px;
    box-sizing: border-box;
    max-height: inherit;
    overflow: auto;
    outline: none;
`;
export const MenuLink = styled(Link)`
    padding: 0.286rem 0.571rem;
    /* border-radius: 6px; */
    outline: none;
    cursor: default;
    color: #fff !important;
    font-size: 1.072rem;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    /* column-gap: 20px; */
    forced-color-adjust: none;
    width: 100%;
    height: 100%;
    cursor: pointer;
    &.active {
        color: #bd0034 !important;
    }
`;
export const MenuButton = styled(Link)`
    padding: 0.286rem 0.571rem;
    /* border-radius: 6px; */
    outline: none;
    cursor: default;
    color: #fff !important;
    font-size: 1.072rem;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    /* column-gap: 20px; */
    forced-color-adjust: none;
    width: 100%;
    height: 100%;
    cursor: pointer;
    &.active {
        color: #bd0034 !important;
    }
`;
export const MenuItemComp = styled(MenuItem)`
    margin: 2px;
    /* padding: 0.286rem 0.571rem; */
    border-radius: 6px;
    outline: none;
    cursor: default;
    color: #fff;
    font-size: 1.072rem;
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    /* column-gap: 20px; */
    forced-color-adjust: none;
    /* backdrop-filter: blur(10px); */

    &[data-hovered] {
        background-color: hsl(0, 0%, 0%, 0.75);
        /* background-color: hsla(0, 0%, 0%, 0.95); */
        color: #bd0034;
        a {
            color: #bd0034 !important;
        }
    }
`;
const StyledPopover = styled(Popover)`
    background-color: hsl(0, 1%, 12%, 0.87);
    backdrop-filter: blur(10px);
    border: 1px solid hsl(121, 100%, 31%, 0.5);
    border-radius: 6px;
    max-width: 250px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    color: #fff;

    &[data-entering] {
        animation: ${popoverSlide} 100ms ease-out;
    }

    &[data-exiting] {
        animation: ${popoverSlide} 100ms reverse ease-in;
    }
`;

export const SeparatorComponent = styled(Separator)`
    align-self: stretch;
    background-color: #707070;

    &[aria-orientation="vertical"] {
        width: 1px;
        margin: 0px 10px;
    }
`;

export function MenuComponent({
    label,
    children,
    withOpenIndicator,
    ...props
}) {
    return (
        <MenuTrigger>
            <StyledButton aria-label="Menu">
                <React.Fragment>
                    <FaBars />
                    <SeparatorComponent orientation="vertical" />
                    {label}
                    {withOpenIndicator && (
                        <FaChevronLeft className="open-indicator" />
                    )}
                </React.Fragment>
            </StyledButton>
            <StyledPopover>
                <StyledMenu {...props}>{children}</StyledMenu>
            </StyledPopover>
        </MenuTrigger>
    );
}
