import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import NavbarModulo from '../../components/NavbarModulo';
import NavbarModuloLF from '../../components/NavbarModuloFage';
import {
    renderField,
    renderCurrencyFloat,
    AsyncSelectFieldIn,
    SelectField
} from "../../Utils/renderField/renderField";

const valEmp = __CONFIG__.empresa;

function DescuentosForm(props) {
    const { crear, modulo, periodoN, correlativo,
        periodoId, emp, datosColaborador, registroDescuento,
        lectura, modificarDescuento, tipos } = props;

    const editar = window.location.href.includes('editar');
    let titulo = editar ? 'Modificar descuento' : 'Crear descuento';
    let disabled = false;
    if (crear === false && editar === false) {
        disabled = true;
        titulo = 'Ver descuento'
    }
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    
    const handleOnChangeEmp = value => {
        datosColaborador(value.value);
    };

    return (
        <div>
            {valEmp === 'fage' &&
                <NavbarModuloLF
                    modulo={modulo}
                    titulo={titulo}
                />
            }
            {valEmp === '' &&
                <NavbarModulo
                    modulo={modulo}
                    titulo={titulo}
                />
            }
            <br />
            <div className="mb-4 card card-small text-white bg-dark mb-3">
                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                    <div className="d-flex flex-column flex-1 mx-4">
                        <div className='row mb-2'>
                            <div className='col-3'>
                                <div className="form-floating">
                                    <input
                                        placeholder="Periodo"
                                        type="text"
                                        autocomplete="off"
                                        name="periodo"
                                        className="form-control"
                                        value={periodoN}
                                        disabled={true}
                                    />
                                    <label className='form-label' htmlFor="periodo">
                                        Periodo
                                    </label>
                                </div>
                            </div>
                            <div className='col-3'>
                                <div className="form-floating">
                                    <input
                                        placeholder="Numero"
                                        type="text"
                                        autocomplete="off"
                                        name="numero"
                                        className="form-control"
                                        value={correlativo}
                                        disabled={true}
                                    />
                                    <label className='form-label' htmlFor="numero">
                                        Numero
                                    </label>
                                </div>
                            </div>
                        </div>
                        {crear === true &&
                            <div className='row mb-2'>
                                <div className='col-6'>
                                    <div className="form-floating">
                                        <Field
                                            name="tipo"
                                            options={tipos}
                                            dato={0}
                                            component={SelectField}
                                            className="form-select"
                                            msj="Tipo de descuento"
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {crear === false && editar === false && 
                            <div className='row mb-2'>
                                <div className='col-6'>
                                    <div className="form-floating">
                                        <Field
                                            name="tipo_descuento"
                                            options={tipos}
                                            disabled={true}
                                            dato={lectura.tipo_descuento}
                                            component={SelectField}
                                            className="form-select"
                                            msj="Tipo de descuento"
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {editar === true &&
                            <div className='row mb-2'>
                                <div className='col-6'>
                                    <div className="form-floating">
                                        <Field
                                            name="tipo_descuento"
                                            options={tipos}
                                            dato={lectura.tipo_descuento}
                                            component={SelectField}
                                            className="form-select"
                                            msj="Tipo de descuento"
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {crear === true &&
                            <div className='row mb-2'>
                                <div>
                                    <Field
                                        name="colaborador_descuento"
                                        placeholder="Colaborador..."
                                        className="form-control-find"
                                        component={AsyncSelectFieldIn}
                                        loadOptions={emp}
                                        onChange={handleOnChangeEmp}
                                        msj="Colaborador"
                                    />
                                </div>
                            </div>
                        }
                        {crear === false && editar === false &&
                            <div className='row mb-2'>
                                <div className='col-12'>
                                    <Field
                                        name="nombre"
                                        disabled={true}
                                        placeholder="Descripcion"
                                        component={renderField}
                                        className="form-control"
                                        msj="Colaborador"
                                    />
                                </div>
                            </div>
                        }
                        {editar === true &&
                            <div className='row mb-2'>
                                <div className='col-12'>
                                    <Field
                                        name="nombre"
                                        disabled={true}
                                        placeholder="Descripcion"
                                        component={renderField}
                                        className="form-control"
                                        msj="Colaborador"
                                    />
                                </div>
                            </div>
                        }
                        {crear === true &&
                            <div className='row mb-2'>
                                <div className='col-3'>
                                    <div className="form-floating">
                                        <Field
                                            name="monto"
                                            placeholder="Monto"
                                            className="form-control"
                                            component={renderCurrencyFloat}
                                            msj="Monto"
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {editar === true &&
                            <div className='row mb-2'>
                                <div className='col-3'>
                                    <div className="form-floating">
                                        <Field
                                            name="monto"
                                            placeholder="Monto"
                                            className="form-control"
                                            component={renderCurrencyFloat}
                                            msj="Monto"
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {crear === false && editar === false &&
                            <div className='row mb-2'>
                                <div className='col-md-3'>
                                    <div className="form-floating">
                                        <Field
                                            name="monto"
                                            disabled={true}
                                            placeholder="Monto"
                                            className="form-control"
                                            component={renderCurrencyFloat}
                                            msj="Monto"
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        <div className='row mb-2'>
                            <div className='col-md-12'>
                                <Field
                                    name="descripcion"
                                    disabled={disabled}
                                    placeholder="Descripcion"
                                    component={renderField}
                                    className="form-control"
                                    msj="Descripcion"
                                />
                            </div>
                        </div>
                        <div className='d-flex flex-row mt-3'>
                            {crear == true && editar == false &&
                                <button
                                    className='btn mr-2 mb-3 btn-primary'
                                    type='submit'
                                    onClick={() => registroDescuento(periodoId)}
                                >
                                    Guardar
                                </button>
                            }
                            {editar == true && crear == false &&
                                <button
                                    className='btn mr-2 mb-3 btn-primary'
                                    type='submit'
                                    onClick={() => modificarDescuento()}
                                >
                                    Modificar
                                </button>
                            }
                            {valEmp === 'fage' &&
                                <a
                                    href={`/#/lfg/nomina/${id_emp[6]}/descuentos/${id_emp[8]}`}
                                    className='btn btn-secondary mb-3'
                                >
                                    Regresar
                                </a>
                            }
                            {valEmp === '' &&
                                <a
                                    href={`/#/nomina/${id_emp[5]}/descuentos/${id_emp[7]}`}
                                    className='btn btn-secondary mb-3'
                                >
                                    Regresar
                                </a>
                            }
                        </div>
                    </div>
                </div>
            </div>

        </div >
    );
}

DescuentosForm.propTypes = {
    handleSubmit: PropTypes.func,
    crear: PropTypes.bool,
    modulo: PropTypes.object,
};

export default reduxForm({
    form: 'FormDescuentosNomina'
    
})(DescuentosForm);
