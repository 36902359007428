import React, { Component } from 'react';
import Formulario from './IngresoProduccionForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";

class IngresoProduccion extends Component{
    state = {
        crear: true,
    }

    componentDidMount() {
        const { match, leer, getModulo,leerPeriodoD, listarClientes, 
            listarProductos, listarFases, vaciar, parametrosEmpresa,
            listarPaises, listarInsumos, listarSeries } = this.props;
        const id = match.params.id;
        if (id){
            this.setState({ crear: false });
            leer(id);
        }
        localStorage.removeItem('id_detalle_ingreso_produccion');
        let ruta = window.location.href;
        let periodo = ruta.split('/');
        leerPeriodoD(periodo[5], periodo[7]);
        getModulo(periodo[5],periodo[4]);
        listarClientes();
        listarProductos();
        listarFases();
        vaciar();
        parametrosEmpresa();
        listarPaises();
        listarInsumos();
        listarSeries();
    }
    render(){
        const { registroProduccion, modificarProduccion, loader, lectura, modulo, 
            listarClientes, periodoD, agregarLinea, detalleProducto, listarProductos,
            listarFases, detallePro, eliminarLineaProducto, nuevaLineaProduccion,
            eliminarLinea, datos, listaP, registroCliente, listarInsumos, listarSeries,
            numeroSerie } = this.props;
        const { crear } = this.state;
        const funcionEnvio = crear ? registroProduccion : modificarProduccion;
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    funcionEnvio = {funcionEnvio}
                    crear = {crear}
                    lectura = {lectura}
                    modulo = {modulo}
                    periodoD = {periodoD}
                    listarClientes = {listarClientes}
                    agregarLinea = {agregarLinea}
                    detalleProducto = {detalleProducto}
                    listarProductos = {listarProductos}
                    listarFases = {listarFases}
                    detallePro = {detallePro}
                    eliminarLineaProducto = {eliminarLineaProducto}
                    nuevaLineaProduccion = {nuevaLineaProduccion}
                    eliminarLinea = {eliminarLinea}
                    datos = {datos}
                    obtenerPaises={listaP}
                    registroCliente={registroCliente}
                    listarInsumos={listarInsumos}
                    listarSeries={listarSeries}
                    numeroSerie = {numeroSerie}
                />
           </LoadMask>
        );
    }
}

export default IngresoProduccion;