import { handleActions } from 'redux-actions';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import moment from 'moment';
const valEmp = __CONFIG__.empresa;
import Swal from 'sweetalert2';

const LOADER = 'LOADER';
const MODULO = 'MODULO';
const LISTADO_PAISES = 'LISTADO_PAISES';
const LISTADO_PERIODOS = 'LISTADO_PERIODOS';
const LISTADO_DEPARTAMENTOS = 'LISTADO_DEPARTAMENTOS';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const getModulo = (id, modulo) => (dispatch) => {
    const params = { id, modulo }
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response) => {
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const reportePlanilla = () => (dispatch, getStore) => {
    const data = getStore().form.FormInformesNomina.values;
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    
    let id = '';
    let params = {};
    
    let per = data.periodo;
    let periodo = per.split('#')[0];
    let tipo = per.split('#')[1];
    
    if (tipo === 'false'){
        let reporte = 'reporteNomina';
        
        if (valEmp === 'fage') {
            id = id_emp[6];
            params = { id, reporte, periodo };
        } else {
            id = id_emp[5];
            params = { id, reporte, periodo };
        }

        dispatch(setLoader(true));
        api.getPdf('/nomina', params).then((response) => {
            if (response.headers['content-type'] == 'application/pdf') {
                var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = 'planilla.pdf';
                window.open(link, '_blank');
                return false;
            }
        }).catch((error) => {
            if (error.status === 404) {
                NotificationManager.error(
                    "No se encontraron datos",
                    'Error',
                    2000
                );
            }
            if (error.status === 500) {
                NotificationManager.error(
                    'Ocurrió un error al generar reporte',
                    'Error',
                    0
                );
            }

        }).finally(() => {
            dispatch(setLoader(false));
        });
    }else{
        let reporte = 'reporteNomina';
        
        if (valEmp === 'fage') {
            id = id_emp[6];
            params = { id, reporte, periodo };
        } else {
            id = id_emp[5];
            params = { id, reporte, periodo };
        }
        dispatch(setLoader(true));
        api.getPdf('/nomina_otros_ingresos', params).then((response) => {
            if (response.headers['content-type'] == 'application/pdf') {
                var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = 'planilla_otros_ingresos.pdf';
                window.open(link, '_blank');
                return false;
            }
        }).catch((error) => {
            if (error.status === 404) {
                NotificationManager.error(
                    "No se encontraron datos",
                    'Error',
                    2000
                );
            }
            if (error.status === 500) {
                NotificationManager.error(
                    'Ocurrió un error al generar reporte',
                    'Error',
                    0
                );
            }
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
}


export const listarPaises = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let empresa = '';
    if (valEmp === 'fage'){
        empresa = id_emp[6];
    }else{
        empresa = id_emp[5];
    }
    api.get(`/pais_colaborador/pais_empresa/?id=${empresa}`).then((data)=>{
        const pais = [];
        data.results.forEach(item=>{
            pais.push({
                value: item.id,
                label: item.pais
            })
        })
        dispatch({ type: LISTADO_PAISES, listaP: pais });
    }).catch(()=>{
        NotificationManager.error(
            'Ocurrió un error al listar paises',
            'Error',
            0
        );
    })
}

export const listarPlanillas = (year) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = '';
    let parametros = {};
    if (valEmp === 'fage'){
        id = id_emp[6];
        parametros = { id, year };
    }else{
        id = id_emp[5];
        parametros = { id, year };
    }
    api.get('/periodos_nomina/periodo_empresa_reporte', parametros).then((data)=>{
        const periodos = [];
        data.forEach(item=>{
            periodos.push({
                value: item.id + '#' + item.bonficacion_comision,
                label: item.periodo_nomina + ' - ' + item.numero + ' - ' + '[ ' + moment(item.fecha_inicio).format('DD/MM/YYYY') + ' - ' + moment(item.fecha_fin).format('DD/MM/YYYY') + ' ]' 
            })
        })
        dispatch({ type: LISTADO_PERIODOS, listaPeriodo: periodos });
    }).catch(() =>{
        NotificationManager.error(
            'Ocurrió un error al listar periodos',
            'Error',
            0
        );
    })
}

export const reportePlanillaMensual = () => (dispatch, getStore) => {
    const data = getStore().form.FormInformesNomina.values;
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    
    let id = '';
    let params = {};
    let mes = data.mes;
    let year = data.year;

    let reporte = 'reporteNominaMensual';
    if (valEmp === 'fage') {
        id = id_emp[6];
        params = { id, reporte, mes, year };
    } else {
        id = id_emp[5];
        params = { id, reporte, mes, year };
    }
    dispatch(setLoader(true));
    api.getPdf('/nomina', params).then((response) => {
        if (response.headers['content-type'] == 'application/pdf') {
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'planilla_mensual.pdf';
            window.open(link, '_blank');
            return false;
        }
    }).catch((error) => {
        if (error.status === 404) {
            NotificationManager.error(
                error.body.msj,
                'Error',
                3000
            );
        }
        if (error.status === 500) {
            NotificationManager.error(
                'Ocurrió un error al generar reporte',
                'Error',
                0
            );
        }

    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const reporteCuotasIgss = () => (dispatch, getStore) => {
    const data = getStore().form.FormInformesNomina.values;
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    
    let id = '';
    let params = {};
    let mes = data.mes;
    let year = data.year;

    let reporte = 'reporteCuotaIgss';
    if (valEmp === 'fage') {
        id = id_emp[6];
        params = { id, reporte, mes, year };
    } else {
        id = id_emp[5];
        params = { id, reporte, mes, year };
    }
    dispatch(setLoader(true));
    api.getPdf('/nomina', params).then((response) => {
        if (response.headers['content-type'] == 'application/pdf') {
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'Cuotas_igss.pdf';
            window.open(link, '_blank');
            return false;
        }
    }).catch((error) => {
        if (error.status === 404) {
            NotificationManager.error(
                error.body.msj,
                'Error',
                3000
            );
        }
        if (error.status === 500) {
            NotificationManager.error(
                'Ocurrió un error al generar reporte',
                'Error',
                0
            );
        }

    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const excelCuotasIgss = () => (dispatch, getStore) => {
    const data = getStore().form.FormInformesNomina.values;
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    
    let id = '';
    let params = {};
    let mes = data.mes;
    let year = data.year;

    let reporte = 'excelReporteCuotaIgss';
    if (valEmp === 'fage') {
        id = id_emp[6];
        params = { id, reporte, mes, year };
    } else {
        id = id_emp[5];
        params = { id, reporte, mes, year };
    }
    dispatch(setLoader(true));
    api.getExcel('/nomina', params).then((response) => {
        const blob = response;
        const fileName = 'cuotas_igss.xlsx';

        // Descargar el archivo utilizando la función saveAs de file-saver
        const file = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(file, fileName);
            
    }).catch(error => {
        if (error === 404) {
            NotificationManager.error(
                "No se encontraron datos",
                'Informacion',
                5000
            );
        }
        if (error === 500) {
            NotificationManager.error(
                'Ocurrió un error al generar reporte',
                'Error',
                0
            );
        }
    })
    .finally(() => {
        dispatch(setLoader(false));
    });
}

export const reporteRecibosPeriodo = () => (dispatch, getStore) => {
    const data = getStore().form.FormInformesNomina.values;
    
    let ruta = window.location.href;
    let id_emp = ruta.split('/');

    if (data === undefined) {
        Swal.fire({
            title: 'Recibos de nomina',
            text: 'Debe de seleccionar el periodo',
            type: 'error',
        })
    }else{
        if (data.periodo === undefined || data.periodo === null) {
            Swal.fire({
                title: 'Recibos de nomina',
                text: 'Debe de seleccionar el periodo',
                type: 'error',
            })
        }else {
            let emp = data.colaborador ? data.colaborador.value : '';
            let year = data.year;
            let periodo = data.periodo;
            let depto = '';

            if (emp){
                depto = '';
            }else{
                depto = data.departamento ? data.departamento : '';
            }

            let reporte = 'reporteRecibosPeriodo';
            let id = id_emp[5];
            let params = { id, reporte, emp, year, periodo, depto };

            dispatch(setLoader(true));
            api.getPdf('/nomina', params).then((response) => {
                if (response.headers['content-type'] == 'application/pdf') {
                    var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = 'recibos_nomina_mes.pdf';
                    window.open(link, '_blank');
                    return false;
                }
            }).catch((error) => {
                if (error.status === 404) {
                    NotificationManager.info(
                        "No hay datos para generar reporte",
                        'Informacion',
                        3000
                    );
                }
                if (error.status === 500) {
                    NotificationManager.error(
                        'Ocurrió un error al generar reporte',
                        'Error',
                        0
                    );
                }
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }
    }
}

export const listarColaborador = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params;
    let id = ''
    if (valEmp === 'fage'){
        id = id_emp[6];
        params = { id, search };
    }else{
        id = id_emp[5];
        params = { id, search };
    }
    return api.get('/colaborador/buscar_colaborador_nomina',params).then(data=>{
        const list_emp = [];
        if(data){
            data.forEach(item=>{
                list_emp.push({
                    value: item.id,
                    label:  item.codigo+' - ' + item.nombre
                })
            })
        }
        return list_emp;
    }).catch((error)=>{
        return [];
    })
}

export const listarPlanillasRecibos = (year) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = '';
    let parametros = {};
    if (valEmp === 'fage'){
        id = id_emp[6];
        parametros = { id, year };
    }else{
        id = id_emp[5];
        parametros = { id, year };
    }
    api.get('/periodos_nomina/periodo_empresa_reporte', parametros).then((data)=>{
        const periodos = [];
        data.forEach(item=>{
            if (item.bonficacion_comision === false) {
                periodos.push({
                    value: item.id,
                    label: item.periodo_nomina + ' - ' + item.numero + ' - ' + '[ ' + moment(item.fecha_inicio).format('DD/MM/YYYY') + ' - ' + moment(item.fecha_fin).format('DD/MM/YYYY') + ' ]' 
                })
            }
        })
        dispatch({ type: LISTADO_PERIODOS, listaPeriodo: periodos });
    }).catch(() =>{
        NotificationManager.error(
            'Ocurrió un error al listar periodos',
            'Error',
            0
        );
    })
}

export const reportePromedioIngresos = () => (dispatch, getStore) => {
    const data = getStore().form.FormInformesNomina.values;
    
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    
    let id = '';
    let params = {};
    if (data === undefined) {
        Swal.fire({
            title: 'Promedio de ingresos',
            text: 'Debe de seleccionar colaborador',
            type: 'error',
        })
    }else{
        if (data.colaborador === undefined || data.colaborador === null) {
            Swal.fire({
                title: 'Promedio de ingresos',
                text: 'Debe de seleccionar colaborador',
                type: 'error',
            })
        }else {
            if (data.mes_inicial === undefined || data.mes_inicial === null) {
                Swal.fire({
                    title: 'Promedio de ingresos',
                    text: 'Debe de seleccionar mes inicial',
                    type: 'error',
                })
            }else {
                if (data.mes_final === undefined || data.mes_final === null) {
                    Swal.fire({
                        title: 'Promedio de ingresos',
                        text: 'Debe de seleccionar mes final',
                        type: 'error',
                    })
                }else {
                    if (data.year_inicial === undefined || data.year_inicial === null) {
                        Swal.fire({
                            title: 'Promedio de ingresos',
                            text: 'Debe de ingresar año inicial',
                            type: 'error',
                        })
                    }else {
                        if (data.year_final === undefined || data.year_final === null) {
                            Swal.fire({
                                title: 'Promedio de ingresos',
                                text: 'Debe de ingresar año final',
                                type: 'error',
                            })
                        }else {
                            let emp = data.colaborador ? data.colaborador.value : '';;
                            let year_inicial = data.year_inicial;
                            let year_final = data.year_final;
                            let mes_inicial = data.mes_inicial;
                            let mes_final = data.mes_final;
                            let reporte = 'reportePromedioIngresos';
                            if (valEmp === 'fage') {
                                id = id_emp[6];
                                params = { id, reporte, emp, year_inicial, year_final, mes_inicial, mes_final };
                            } else {
                                id = id_emp[5];
                                params = { id, reporte, emp, year_inicial, year_final, mes_inicial, mes_final };
                            }

                            dispatch(setLoader(true));
                            api.getPdf('/nomina', params).then((response) => {
                                if (response.headers['content-type'] == 'application/pdf') {
                                    var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
                                    var link = document.createElement('a');
                                    link.href = window.URL.createObjectURL(blob);
                                    link.download = 'recibos_nomina_mes.pdf';
                                    window.open(link, '_blank');
                                    return false;
                                }
                            }).catch((error) => {
                                if (error.status === 404) {
                                    NotificationManager.info(
                                        "No hay datos para generar reporte",
                                        'Informacion',
                                        3000
                                    );
                                }
                                if (error.status === 500) {
                                    NotificationManager.error(
                                        'Ocurrió un error al generar reporte',
                                        'Error',
                                        0
                                    );
                                }
                            }).finally(() => {
                                dispatch(setLoader(false));
                            });
                        }
                    }
                }
            }
        }
    }
}

export const listarDepartamentos = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let params = { id };
    
    api.get('/departamento', params).then((response) => {
        const depto = [];
        response.forEach(item => {
            depto.push({
                value: item.id,
                label: item.descripcion,
            })
        })
        dispatch({ type: LISTADO_DEPARTAMENTOS, deptos: depto });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar tipos de descuento',
            'Error',
            0
        );
    });
}


export const actions = {
    getModulo,
    reportePlanilla,
    listarPaises,
    listarPlanillas,
    reportePlanillaMensual,
    reporteCuotasIgss,
    excelCuotasIgss,
    reporteRecibosPeriodo,
    listarColaborador,
    listarPlanillasRecibos,
    reportePromedioIngresos,
    listarDepartamentos,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [LISTADO_PAISES]: (state, { listaP }) => {
        return {
            ...state,
            listaP,
        };
    },
    [LISTADO_PERIODOS]: (state, { listaPeriodo }) => {
        return {
            ...state,
            listaPeriodo,
        };
    },
    [LISTADO_DEPARTAMENTOS]: (state, { deptos }) => {
        return {
            ...state,
            deptos,
        };
    },
};

export const initialState = {
    loader: false,
    monedas: [],
    modulo: [],
    listaP: [],
    listaPeriodo: [],
    deptos: []
};

export default handleActions(reducers, initialState)