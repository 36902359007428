import React, {useState} from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import { validate, validators } from 'validate-redux-form';
import PropTypes from 'prop-types';
import NavbarModulo from '../../components/NavbarModulo';
import {
    renderField,
    renderFieldCheck,
    renderFieldCheckB
} from "../../Utils/renderField/renderField";

function CentroTrabajoForm(props) {
    const { handleSubmit, crear, modulo } = props;

    const editar = window.location.href.includes('editar');
    let titulo = editar ? 'Modificar centro de trabajo' : 'Crear centro de trabajo';
    let disabled = false;
    if (crear === false && editar === false) {
        disabled = true;
        titulo = 'Ver centro de trabajo'
    }
    let autoFocus = true;
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const [activo, setActivo] = useState(true);
    const onCheckboxClicked = (idx, isChecked) => {
        setActivo(isChecked);
    }
    return (
        <div>
            <NavbarModulo
                modulo={modulo}
                titulo={titulo}
            />
            <form onSubmit={handleSubmit}>
                <br />
                <div className="mb-4 card card-small text-white bg-dark mb-3">
                    <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                        <div className="d-flex flex-column flex-1 mx-4">
                            <div className='row mb-2'>
                                {crear === true &&
                                    <div className='col-3'>
                                        <Field
                                            autoFocus={autoFocus}
                                            name="codigo"
                                            placeholder="Codigo"
                                            className="form-control"
                                            component={renderField}
                                            disabled={disabled}
                                            msj="Codigo"
                                        />
                                    </div>
                                }
                                {editar === true &&
                                    <div className='col-3'>
                                        <Field
                                            name="codigo"
                                            placeholder="Codigo"
                                            className="form-control"
                                            component={renderField}
                                            disabled={true}
                                            msj="Codigo"
                                        />
                                    </div>
                                }
                                {crear === false && editar === false &&
                                    <div className='col-3'>
                                        <Field
                                            name="codigo"
                                            placeholder="Codigo"
                                            className="form-control"
                                            component={renderField}
                                            disabled={true}
                                            msj="Codigo"
                                        />
                                    </div>
                                }
                                <div className='col-4 mt-3'>
                                    {crear === true ? (
                                        <Field
                                            name="activo"
                                            label="Activo"
                                            checked={activo}
                                            onChange={onCheckboxClicked}
                                            component={renderFieldCheckB}
                                        />
                                        ) :
                                        <Field
                                            disabled={disabled}
                                            name="activo"
                                            label="Activo"
                                            component={renderFieldCheck}
                                        />
                                    }
                                </div>
                            </div>
                            <div className='mb-2 w-50'>
                                <Field
                                    name="descripcion"
                                    placeholder="Descripción"
                                    className="form-control"
                                    component={renderField}
                                    disabled={disabled}
                                    msj="Descripción"
                                />
                            </div>
                            <div className='d-flex flex-row mt-3'>
                                {disabled == false &&
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        type='submit'
                                    >
                                        {editar ? 'Modificar' : 'Guardar'}
                                    </button>
                                }
                                <a
                                    href={`/#/produccion/${id_emp[5]}/centro_trabajo`}
                                    className='btn btn-secondary mb-3'
                                >
                                    Regresar
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

CentroTrabajoForm.propTypes = {
    handleSubmit: PropTypes.func,
    crear: PropTypes.bool,
    lectura: PropTypes.object,
    modulo: PropTypes.object,
};

export default reduxForm({
    form: 'FormCentroTrabajo',
    validate: (data) => {
        return validate(data, {
            codigo: validators.exists()('Este campo es requerido'),
            descripcion: validators.exists()('Este campo es requerido'),
        });
    },
})(CentroTrabajoForm);
