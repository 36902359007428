import React, { Component } from 'react';
import Grid from '../Utils/GridFiltro';
import { standardActions } from '../Utils/Grid/StandardActions';
import NavbarModulo from '../components/NavbarModulo';

class PeriodosList extends Component{
    componentDidMount = () => {
        const { listarPeriodos, getModulo } = this.props;
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        listarPeriodos();
        getModulo(modulo[5],modulo[4])
    }
    render(){
        const { periodos, loader, modulo } = this.props;
        let ruta = window.location.href;
        let id_emp = ruta.split('/');
        let titulo = '';
        return(
            <React.Fragment>
                <NavbarModulo
                    modulo={modulo}
                    titulo={titulo}
                />
                <br />
                <h3 className="p-2 bg-secondary title">Periodos</h3>
                <br />
                { id_emp[4] === 'produccion' &&
                    <div className="d-flex flex-row justify-content-start mb-2">
                        <a
                            href={`/#/produccion/${id_emp[5]}/periodos/crear`}
                            className='btn btn-primary'
                        >
                            Crear Periodo
                        </a>
                        <a
                            href={`/#/produccion/${id_emp[5]}`}
                            className='btn btn-secondary ml-2'
                        >
                            Regresar
                        </a>
                    </div>
                }
                { id_emp[4] === 'inventario' &&
                    <div className="d-flex flex-row justify-content-start mb-2">
                        <a
                            href={`/#/inventario/${id_emp[5]}/periodos/crear`}
                            className='btn btn-primary'
                        >
                            Crear Periodo
                        </a>
                        <a
                            href={`/#/inventario/${id_emp[5]}`}
                            className='btn btn-secondary ml-2'
                        >
                            Regresar
                        </a>
                    </div>
                }
                { id_emp[4] === 'facturacion' &&
                    <div className="d-flex flex-row justify-content-start mb-2">
                        <a
                            href={`/#/facturacion/${id_emp[5]}/periodos/crear`}
                            className='btn btn-primary'
                        >
                            Crear Periodo
                        </a>
                        <a
                            href={`/#/facturacion/${id_emp[5]}`}
                            className='btn btn-secondary ml-2'
                        >
                            Regresar
                        </a>
                    </div>
                }
                { id_emp[4] === 'bancos' &&
                    <div className="d-flex flex-row justify-content-start mb-2">
                        <a
                            href={`/#/bancos/${id_emp[5]}/periodos/crear`}
                            className='btn btn-primary'
                        >
                            Crear Periodo
                        </a>
                        <a
                            href={`/#/bancos/${id_emp[5]}`}
                            className='btn btn-secondary ml-2'
                        >
                            Regresar
                        </a>
                    </div>
                }
                { id_emp[4] === 'compras' &&
                    <div className="d-flex flex-row justify-content-start mb-2">
                        <a
                            href={`/#/compras/${id_emp[5]}/periodos/crear`}
                            className='btn btn-primary'
                        >
                            Crear Periodo
                        </a>
                        <a
                            href={`/#/compras/${id_emp[5]}`}
                            className='btn btn-secondary ml-2'
                        >
                            Regresar
                        </a>
                    </div>
                }
                { id_emp[4] === 'contabilidad' &&
                    <div className="d-flex flex-row justify-content-start mb-2">
                        <a
                            href={`/#/contabilidad/${id_emp[5]}/periodos/crear`}
                            className='btn btn-primary'
                        >
                            Crear Periodo
                        </a>
                        <a
                            href={`/#/contabilidad/${id_emp[5]}`}
                            className='btn btn-secondary ml-2'
                        >
                            Regresar
                        </a>
                    </div>
                }
                { id_emp[4] === 'ajustes' &&
                    <div className="d-flex flex-row justify-content-start mb-2">
                        <a
                            href={`/#/ajustes/${id_emp[5]}/periodos/crear`}
                            className='btn btn-primary'
                        >
                            Crear Periodo
                        </a>
                        <a
                            href={`/#/ajustes/${id_emp[5]}`}
                            className='btn btn-secondary ml-2'
                        >
                            Regresar
                        </a>
                    </div>
                }
                { id_emp[4] === 'nomina' &&
                    <div className="d-flex flex-row justify-content-start mb-2">
                        <a
                            href={`/#/nomina/${id_emp[5]}/periodos/crear`}
                            className='btn btn-primary'
                        >
                            Crear Periodo
                        </a>
                        <a
                            href={`/#/nomina/${id_emp[5]}`}
                            className='btn btn-secondary ml-2'
                        >
                            Regresar
                        </a>
                    </div>
                }
                { id_emp[4] === 'visitador_medico' &&
                    <div className="d-flex flex-row justify-content-start mb-2">
                        <a
                            href={`/#/visitador_medico/${id_emp[5]}/periodos/crear`}
                            className='btn btn-primary'
                        >
                            Crear Periodo
                        </a>
                        <a
                            href={`/#/visitador_medico/${id_emp[5]}`}
                            className='btn btn-secondary ml-2'
                        >
                            Regresar
                        </a>
                    </div>
                }
                { id_emp[4] === 'punto_venta' &&
                    <div className="d-flex flex-row justify-content-start mb-2">
                        <a
                            href={`/#/punto_venta/${id_emp[5]}/periodos/crear`}
                            className='btn btn-primary'
                        >
                            Crear Periodo
                        </a>
                        <a
                            href={`/#/punto_venta/${id_emp[5]}`}
                            className='btn btn-secondary ml-2'
                        >
                            Regresar
                        </a>
                    </div>
                }
                {periodos &&
                    <Grid 
                        hover 
                        striped 
                        data={periodos} 
                        loading={loader} 
                        headerStyle={ { background: 'black' } } 
                        //onPageChange={listar} 
                        //onSortChange={onSortChange} 
                    >
                        <TableHeaderColumn
                            isKey
                            dataField="periodo"
                            headerAlign="center"
                            width="100"
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Periodo
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="estado"
                            dataAlign='center'
                            width="100"
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Estado
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            thStyle={
                            {color: 'white'}}
                            dataField="id"
                            width="100"
                            dataAlign="center"
                            dataSort
                            dataFormat={standardActions({ 
                                editar: "periodos", 
                                ver: "periodos",
                            })} 
                            
                        >
                            Acciones
                        </TableHeaderColumn>
                    </Grid>
                }
            </React.Fragment>
        );
    }
}

export default PeriodosList;
