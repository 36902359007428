import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { reduxForm } from 'redux-form';
import Formulario from './UsuarioFormPass';
import LoadMask from "../Utils/LoadMask/LoadMask";

function UsuarioPass(props) {
    const { activarUsuario, loader } = props;

    useEffect(() => {
        const { validar } = props;
        validar();
    }, [])
    return (
        <div className="blue-gradient-bg">
            <div className="d-flex flex-column align-items-center pt-3 bienvenida title">
                <img className="mt-5 imageReg"
                    src={require('assets/img/SIFW-LOGO-Red.png')}
                    alt="Logo" />
            </div>
            <br />
            <div className="login-wrapper">
                <div className="card2 card border-0 px-4 py-5 bg-dark col-lg-6 col-md-4 col-11">
                    <h4 className="text-center pv mb-4 title">Activar cuenta</h4>
                    <LoadMask loading={loader} dark>
                        <Formulario
                            onSubmit={activarUsuario}
                        />
                    </LoadMask>
                </div>
            </div>
        </div>
    );

}
UsuarioPass.propTypes = {
    validar: PropTypes.func,
};
export default reduxForm({
    form: 'UsuarioPass', //identificador unico
})(UsuarioPass)

