import React, { useState } from "react";
import styled from "styled-components";
import {
    FicInput,
    FicComboBox,
    FicNumber,
    FicDatePicker,
} from "../../Utils/FormInputComponents";
import Banner from "../common/Banner";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { validar } from "../../components/ValidarNit";
import GenBtn from "../../components/GenBtn";
import { getEntry, editar, getPatologias } from "./actions";
import { withRouter } from "react-router";
import LoadMask from "../../Utils/LoadMask/LoadMask";
import { FicImageDropzone } from "../../Utils/FormInputComponents";
import { getPeriodoDates } from "../utils";

const idName = "laminaId";

const InputsContainer = styled.div`
    background-color: #2b2b2b;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 12px;
    border-radius: 5px;
    min-width: 400px;
    max-width: 100%;

    @media (min-width: 768px) {
        display: grid;
        grid-template-areas:
            "patologia patologia  fecha"
            "laminas laminas laminas ";
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
`;

const schema = yup.object({
    patologia: yup.mixed().required("La patologia es requerida"),
    fecha_ingreso: yup.mixed().required("La fecha de ingreso es requerida"),
    laminas: yup
        .array()
        .when("patologia", {
            is: (val) => val && val.tipo !== "Citologia",
            then: () => yup.mixed().notRequired(),
        })
        .when("patologia", {
            is: (val) => val && val.tipo === "Inmunohistoquimica",
            then: () =>
                yup
                    .array()
                    .min(1, "Debe subir al menos una lamina")
                    .max(
                        1,
                        "Solo puede subir una lamina para inmunohistoquimica"
                    )
                    .required("Las laminas son requeridas"),

            otherwise: (y) =>
                yup
                    .array()
                    .min(1, "Debe subir al menos una lamina")
                    .required("Las laminas son requeridas"),
        }),
});
function Editar({ history, ...props }) {
    const [isLoading, setIsLoading] = useState(false);
    const params = props.match.params;
    const entryId = params[idName];
    const { startOfMonthPeriodo, endOfMonthPeriodo } = getPeriodoDates();


    const { control, reset, handleSubmit } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: async () => {
            setIsLoading(true);
            const formData = await getEntry(entryId);
            formData.patologia = formData.patologia.numero_patologia
            setIsLoading(false);

            setIsLoading(false);
            return formData;
        },
    });

    const onSubmit = (data) => {
        const shouldGoBack = editar(data, entryId);
        if (shouldGoBack) {
            history.goBack();
        }
    };

    return (
        <React.Fragment>
            <Banner>Editar</Banner>
            <GenBtn
                onPress={() => history.goBack()}
                style={{ margin: "5px 0" }}
            >
                Regresar
            </GenBtn>
            <LoadMask loading={isLoading} color="#bd0034" blur={true}>
                <form
                    encType="multipart/form-data"
                    onSubmit={handleSubmit(onSubmit)}
                    style={{ display: "flex", flexDirection: "column" }}
                >
                    <InputsContainer style={{}}>
                        <FicInput
                            control={control}
                            label="Patologia"
                            name="patologia"
                            style={{ gridArea: "patologia" }}
                            isDisabled
                        />
                        {/* <FicComboBox
                        control={control}
                        label="Paciente"
                        name="paciente"
                        loadOptions={getPacientes}
                    /> */}

                        <FicDatePicker
                            control={control}
                            label="Fecha de ingreso"
                            name="fecha_ingreso"
                            componentStyle={{ gridArea: "fecha" }}
                            minValue={startOfMonthPeriodo}
                            maxValue={endOfMonthPeriodo}
                        />
                        <FicImageDropzone
                            control={control}
                            label="Laminas"
                            name="laminas"
                            style={{ gridArea: "laminas" }}
                        />
                    </InputsContainer>
                    <GenBtn style={{ margin: "1rem 0" }} type="submit">
                        Guardar
                    </GenBtn>
                </form>
            </LoadMask>
        </React.Fragment>
    );
}

export default withRouter(Editar);
