import React, { useEffect, useRef, useState } from 'react';
import FormDialogModal from '../../components/FormDialogModal';
import { FicInput } from '../../Utils/FormInputComponents';
import DataTableWSearch from '../../components/DataTableWSearch';
import * as yup from "yup"
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import GenBtn from '../../components/GenBtn';
import * as Fa from 'react-icons/fa';

const schema = yup.object({
    marca: yup.string().required('La marca es requerida'),
})


function MarcasList(props) {

    const { handleSubmit, reset, control, formState: { errors }} = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: {
            marca: '',
        }    
    })
    const { handleSubmit: editSubmit, reset: editReset, control: editControl, formState: { errors: editErrors }} = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: {
            marca: "",
        }    
    })

    const {crearMarca, listarMarcas, editarMarca, data } = props;
    const [ marcaFieldData, setMarcaFieldData ] = useState()
    const [ marcaId, setMarcaId ] = useState()

    const dialogRef = useRef(null)
    const editDialogRef = useRef(null)
    
    async function onSubmit(formData) {
        await crearMarca(formData);
        reset();
        listarMarcas();
    }

    async function onEditSubmit(formData) {
        await editarMarca(formData, marcaId);
        listarMarcas();
        editDialogRef.current.close()
        editReset();
    }

    useEffect(() => {
        listarMarcas();
    }, [])

    const columns = [
        {
            name: 'Nombre',
            selector: row => row.nombre,
            sortable: true,
        },
        {
            cell: (row) => <GenBtn key={row.nombre} textColor="hsl(0, 0%, 80%)" background="hsla(0, 100%, 100%, 0.2)" 
                                onPress={
                                (e) => {
                                    editDialogRef.current.showModal() 
                                    setMarcaFieldData(row.nombre)
                                    setMarcaId(row.id)
                                }}>
                                <Fa.FaPen/>
                            </GenBtn>
                ,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    return(
        <React.Fragment>

            <br />
            <h3 className="p-2  title rounded shadow"
                style={{border: "1px solid #790021", background: "linear-gradient(to top right, #790021, #2d2d2d)"}}
            >
                Marcas
            </h3>
            <br />
            <div className="d-flex flex-row justify-content-start mb-2">
            <GenBtn
                // BtnColor="#00cb03"
                // style={{width: "150px", height:"40px"}}
                onClick={() => {
                    dialogRef.current.showModal()
                    }}>
                Agregar
            </GenBtn>

            </div>

            <DataTableWSearch 
                columns={columns} 
                data={data} 
                fieldToFilter="nombre" 
                filterPlaceHolder="Buscar por nombre"
            />

            <FormDialogModal dialogRef={dialogRef} onSubmit={handleSubmit(onSubmit)} title={"Agregar marca"}>
                <div style={{display:"flex", flexDirection: "column", gap: "15px", width:"25rem", height:"10rem", padding: "15px"}}>
                    <FicInput
                        name="marca"
                        placeholder="Marca"
                        control={control}
                        error={errors && errors.marca && errors.marca.message}                   
                    />
                    <div className='d-flex justify-content-end mt-auto'>
                        <GenBtn 
                            type="submit"
                            style={{
                                width:"90px", 
                                height:"40px", 
                                marginBottom:"8px"}}>
                            Agregar
                        </GenBtn>
                    </div>
                </div>

            </FormDialogModal>

            <FormDialogModal dialogRef={editDialogRef} onSubmit={editSubmit(onEditSubmit)} title={"Modificar marca"}>
                <div style={{display:"flex", flexDirection: "column", gap: "15px", width:"25rem", height:"10rem", padding: "15px"}}>
                    <FicInput
                        name="marca"
                        placeholder={`${marcaFieldData}`}
                        control={editControl}
                        error={editErrors && editErrors.marca && editErrors.marca.message}
                    />
                    <div className='d-flex justify-content-end mt-auto'>
                        <GenBtn 
                            type="submit"
                            style={{
                                width:"90px", 
                                height:"40px", 
                                marginBottom:"8px"}}>
                            Guardar
                        </GenBtn>
                    </div>
                </div>

            </FormDialogModal>
            
        </React.Fragment>
    );

}

export default MarcasList;

