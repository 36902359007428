import React from "react";
import styled from "styled-components";
import { FicInput } from "../../Utils/FormInputComponents";
import Banner from "../common/Banner";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { validar } from "../../components/ValidarNit";
import GenBtn from "../../components/GenBtn";
import InputsContainer from "../common/InputsContainer";
import { crear } from "./actions";

const schema = yup.object({
    nombre: yup.string().required("El nombre es requerido"),
});

const onSubmit = (data) => {
    crear(data);
};

export default function Crear() {
    const { control, reset, handleSubmit } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: {
            nombre: "",
        },
    });

    return (
        <React.Fragment>
            <Banner>Crear vehiculo</Banner>
            <form onSubmit={handleSubmit(onSubmit)}>
                <InputsContainer>
                    <FicInput control={control} label="Nombre" name="nombre" />
                </InputsContainer>
                <GenBtn style={{ margin: "1rem 0" }} type="submit">
                    Guardar
                </GenBtn>
            </form>
        </React.Fragment>
    );
}
