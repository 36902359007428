import React, { Component } from 'react';
import Formulario from './ReciboCajaForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";

class ReciboCaja extends Component{
    state = {
        crear: true,
    }

    componentDidMount() {
        const { match, leer, getModulo, leerPeriodoD, parametrosEmpresa, vaciar,
            listarMonedas  } = this.props;
        const id = match.params.id;
        if (id){
            this.setState({ crear: false });
            leer(id);
        }
        let ruta = window.location.href;
        let periodo = ruta.split('/');
        leerPeriodoD(periodo[5], periodo[7]);
        getModulo(periodo[5],periodo[4]);
        parametrosEmpresa();
        listarMonedas();
        vaciar();
    }
    render(){
        const { registroRecibo, numeroSerie, listarClientes,
            loader, lectura, modulo, periodoD, detalleDocN,
            listarSeries, doc, datos, leerDocumentos, moneda, list_doc, 
            agregarLinea, detalleDocumentos, eliminarLineaAbonoNuevo } = this.props;
        const { crear } = this.state;
               
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    registroRecibo = {registroRecibo}
                    crear = {crear}
                    lectura = {lectura}
                    modulo = {modulo}
                    periodoD = {periodoD}
                    datos = {datos}
                    numeroSerie={numeroSerie}
                    listarSeries={listarSeries}
                    listarClientes = {listarClientes}
                    leerDocumentos = {leerDocumentos}
                    fechaFinal={periodoD.fecha_fin}
                    moneda = {moneda}
                    list_doc={list_doc}
                    doc = {doc}
                    agregarLinea = {agregarLinea}
                    detalleDocN = {detalleDocN}
                    detalleDocumentos = {detalleDocumentos}
                    eliminarLineaAbonoNuevo = {eliminarLineaAbonoNuevo}
                />
           </LoadMask>
        );
    }
}

export default ReciboCaja;

