import React, { Component } from 'react';
import Formulario from './ConsolidacionForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";

class Consolidacion extends Component{
    state = {
        crear: true,
    }

    componentDidMount() {
        const { match, leer, getModulo, leerPeriodoD, numeroDevolucion, limpiar } = this.props;
        const id = match.params.id;
        if (id){
            this.setState({ crear: false });
            leer(id);
        }
        let ruta = window.location.href;
        let periodo = ruta.split('/');
        leerPeriodoD(periodo[5], periodo[7]);
        getModulo(periodo[5],periodo[4]);
        numeroDevolucion();
        limpiar();
    }
    render(){
        const { registroConsolidacion, listarPilotos,  
            loader, lectura, modulo, periodoD, detalleProducto, cli,
            listarIngresos, listarSeries, numeroSerie, numeroS,
            datos, detalleIngreso, detalleEnv, listarClientes,
            listarEnvios, envios, numeroDev, agregarLinea, eliminarLineaEnvio,
            detalleCEnv, validarEnvio } = this.props;
        const { crear } = this.state;
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    crear = {crear}
                    lectura = {lectura}
                    modulo = {modulo}
                    periodoD = {periodoD}
                    detalleProducto = {detalleProducto}
                    listarIngresos = {listarIngresos}
                    datos = {datos}
                    detalleIngreso = {detalleIngreso}
                    cli = {cli}
                    listarSeries={listarSeries}
                    numeroSerie = {numeroSerie}
                    numeroS = {numeroS}
                    detalleEnv = {detalleEnv}
                    listarPilotos = {listarPilotos}
                    listarClientes = {listarClientes}
                    listarEnvios = {listarEnvios}
                    envios = {envios}
                    numeroDev = {numeroDev}
                    registroConsolidacion = {registroConsolidacion}
                    agregarLinea = {agregarLinea}
                    eliminarLineaEnvio = {eliminarLineaEnvio}
                    detalleCEnv = {detalleCEnv}
                    validarEnvio = {validarEnvio}
                />
           </LoadMask>
        );
    }
}

export default Consolidacion;