import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { logOut, getMe, getModulo } from "./redux/modules/cuenta/login";

import Navbar from "./common/components/layout/Navbar/NavbarBancoV2";
import { VerifyLogin } from "./common/components/layout";


class PrivateRouteBancos extends Component {

    componentDidMount = () => {
        const { getModulo } = this.props;
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        getModulo(modulo[5],modulo[4]);
    }
    
    isAuthenticated = () => {
        const token = localStorage.getItem("token");
        const { getMe, login: { me } } = this.props;
        if (!!token && !!me.username) {
            return true;
        } else if(token) {
            getMe();
            return "Verifying"
        }
        return false;
    };

    render() {
        const { component: Component, logOut, login: { me, empresaData }, ...rest } = this.props;
        const isAuthenticated = this.isAuthenticated();
        return (
            <Route
                {...rest}
                render={props =>
                    isAuthenticated ? (
                        (isAuthenticated === true) ? (<div>
                            <main className="main-content p-0 col-sm-12 col-md-9 col-lg-12">
                                <div className="main-navbar fondo sticky-top">
                                    <div className="p-0">
                                        <Navbar 
                                            logOut={logOut} 
                                            user={me}
                                            empresaData={empresaData}
                                        />
                                    </div>
                                </div>
                                <div className="main-content-container px-4 container-fluid">
                                    <Component {...props} />
                                </div>
                            </main>
                        </div>) : (
                            <VerifyLogin />
                        )
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: props.location }
                            }}
                        />
                    )
                }
            />
        );
    }
}

const mstp = state => ({ ...state });

const mdtp = { logOut, getMe, getModulo };

const ProtectedRoute = connect(
    mstp,
    mdtp
)(PrivateRouteBancos);

export default ProtectedRoute;

