import { NotificationManager } from "react-notifications";
import { api } from "api";
import { useDispatch } from "react-redux";

const baseurl = "periodos";
const writeSuccessMsg = "Periodo agregado";
const editSuccessMsg = "Periodo editado";

export const getList = () => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let empresa = id_emp[5];
    const params = { id: empresa };
    return api
        .get(baseurl + "/periodo_empresa", params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return [];
        });
};

export const getEntry = (id) => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let empresa = id_emp[5];
    const params = { empresa };
    return api
        .get(baseurl + `/${id}`, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return [];
        });
};

export const crear = (data) => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    const formData = {
        ...data,
        empresa: id_emp[5],
    };
    return api
        .post(baseurl, formData)
        .then(() => {
            NotificationManager.success(writeSuccessMsg, "Exito", 10000);
        })
        .catch((error) => {
            try {
                error.non_field_errors.map((err) => {
                    NotificationManager.error(err, "Error", 10000);
                });
                return;
            } catch (exception) {
                if (typeof error === "object") {
                    Object.keys(error).forEach((field) => {
                        error[field].forEach((errorMessage) => {
                            NotificationManager.error(
                                `${field}: ${errorMessage}`,
                                "Error",
                                10000
                            );
                        });
                    });
                } else {
                    NotificationManager.error("", "Error", 10000);
                }
            }
        })
        .catch((error) => {
            NotificationManager.error("", "Error", 2000);
        });
};

export const editar = (data, id) => {
    let ruta = window.location.href;
    let empresa = ruta.split("/")[5];
    const formData = {
        ...data,
        empresa,
        id
    };
    return api
        .put(baseurl + `/${id}`, formData)
        .then(() => {
            NotificationManager.success(editSuccessMsg, "Exito", 3000);
            return true;
        })
        .catch((error) => {
            NotificationManager.error(error.msj, "Error", 2000);
            return false;
        });
};
