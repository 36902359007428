import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from '../../components/Modal';
import { Field, reduxForm } from 'redux-form/immutable';
import {
    renderNumber,
} from "../../Utils/renderField/renderField";

function FormSeleccionarPeriodo(props) {
    const {
        listarPeriodos,
        periodos,
    } = props;

    const [idPer, setIdPer] = useState("");
    
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    
    const handleChangeP = e => {
        setIdPer(e.target.value);
    }
    
    const [estadoModal, cambiarEstadoModal] = useState(true);
    
    const handleOnChange = (e,value) => {
        if (value.length == 4) {
            listarPeriodos(value)
        }
        
    }
    return (
        <div className='container'>
            <Modal
                estado={estadoModal}
                cambiarEstado={cambiarEstadoModal}
                titulo="Ingresar año y seleccionar periodo"
                botonCerrar="false"
            >
                <Contenido>
                    <div className='col-6'>
                        <div className="form-floating">
                            <Field
                                name="anio"
                                placeholder="Año"
                                className="form-control"
                                component={renderNumber}
                                onChange={handleOnChange}
                                msj="Año"
                            />
                        </div>
                        <div className='mt-2'>
                        {periodos &&
                            <div className="form-floating mb-3">
                                <select className="form-select" id="periodo"
                                    value={idPer} onChange={handleChangeP} >
                                    <option selected>Seleccionar...</option>
                                    {periodos.map(mes =>
                                        <option key={mes.value} value={mes.value}>{mes.label}</option>)
                                    }
                                </select>
                                <label className='form-label' htmlfor="periodo">Periodo</label>
                            </div>
                        }
                        </div>
                    </div>
                    <div>
                        {idPer &&
                            <a
                                href={`/#/rr_hh/${id_emp[5]}/faltas/${idPer}`}
                                className='btn mr-2 mb-3 btn-primary'
                            >
                                Seleccionar
                            </a>
                        }
                    </div>
                </Contenido>
            </Modal>
        </div>
    );

}

export default reduxForm({
    form: 'FormSeleccionarAnioFalta',
})(FormSeleccionarPeriodo);


const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;