import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/produccion/fechaCosecha';
import Fecha from './FechaCosecha';


const ms2p = (state) => {
    return {
        ...state.fecha_siembra_prd,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Fecha);
