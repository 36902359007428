import React, { Component } from 'react';
import Formulario from './FacturaForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";

class Factura extends Component{
    state = {
        crear: true,
    }

    componentDidMount() {
        const { match, leer, getModulo, leerPeriodoD, parametrosEmpresa, 
            vaciar, leerEstablecimiento, listarTiposDocu } = this.props;
        const id = match.params.id;
        const id_est = match.params.estable;
        if (id){
            this.setState({ crear: false });
            leer(id);
        }
        if (id === undefined){
            leerEstablecimiento(id_est);
        }
        let ruta = window.location.href;
        let periodo = ruta.split('/');
        leerPeriodoD(periodo[5], periodo[7]);
        getModulo(periodo[5],periodo[4]);
        parametrosEmpresa();
        listarTiposDocu();
        vaciar();
    }
    render(){
        const { registroFactura, estable,
            loader, lectura, modulo, periodoD, detalleProducto, cli,
            listarEnvios, editarLinea, actualizar, 
            datos, detalleEnvio, detalleEnv, tipos} = this.props;
        const { crear } = this.state;
               
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    registroFactura = {registroFactura}
                    crear = {crear}
                    lectura = {lectura}
                    modulo = {modulo}
                    periodoD = {periodoD}
                    detalleProducto = {detalleProducto}
                    listarEnvios = {listarEnvios}
                    datos = {datos}
                    detalleEnvio = {detalleEnvio}
                    cli = {cli}
                    detalleEnv = {detalleEnv}
                    editarLinea = {editarLinea}
                    actualizar = {actualizar}
                    estable = {estable}
                    tipos = {tipos}
                />
           </LoadMask>
        );
    }
}

export default Factura;