import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from '../../components/Modal';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';
import {
    AsyncSelectField,
    renderDayPickerB
} from "../../Utils/renderField/renderField";

function FormSeleccionarPais(props) {
    
    const {
        listarPaises,
        generarCorte
    } = props;
    
    const [estadoModal, cambiarEstadoModal] = useState(true);
    let autoFocus = true;
    return (
        <div className='container'>
            <Modal
                estado={estadoModal}
                cambiarEstado={cambiarEstadoModal}
                titulo="Corte de vacaciones"
                botonCerrar="false"
            >
                <div className='row mb-2'>
                    <div className='col-12'>
                        <Field
                            name="pais"
                            placeholder="Pais..."
                            className="form-control-find"
                            component={AsyncSelectField}
                            loadOptions={listarPaises}
                            msj="Pais"
                        />
                    </div>
                </div>
                <Contenido>
                <div className='row'>
                    <div className='col-12 mb-2'>
                        <Field
                            autoFocus={autoFocus}
                            name="fechaCorte"
                            component={renderDayPickerB}
                            msj="Fecha corte"
                        />
                    </div>
                </div>
                </Contenido>
                <br />
                <Contenido>
                    <div className='row'>
                        <button
                            className='btn mr-2 mb-3 btn-primary'
                            onClick={generarCorte}
                        >
                            Generar corte
                        </button>
                        <button
                            className='btn mr-2 mb-3 btn btn-secondary'
                            onClick={() => cambiarEstadoModal(false)}
                        >
                            Cancelar
                        </button>
                    </div>
                </Contenido>
            </Modal>
        </div>
    );

}
FormSeleccionarPais.propTypes = {
    periodoL: PropTypes.string.isRequired,
    listaEs: PropTypes.string.isRequired
};

export default reduxForm({
    form: 'FormCorteVacaciones',
})(FormSeleccionarPais);

const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;