import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/facturacion/notaCreditoFEL';
import Notas from './NCClienteTabs';


const ms2p = (state) => {
  return {
    ...state.nota_credito_fel,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Notas);
