import React, { useState, useEffect }  from 'react'
import "./FicImageDrop.css"

export default function FicImageDrop({errorMsj, handleFileChange, fieldStatus, className, style, description, value, fileSize, isDisabled, isReadOnly, ...props}) {
  // Solo es para imagenes
  // "fileSize" tiene que ser en Bytes
  // Para obtener el archivo seleccionado se debe de pasar la funcion "handleFileChange" y se llamara con la imagen
  // Pasar el prop dropText para mostrar un mensaje de texto en caso de que no se haya seleccionado ninguna imagen
  // Para usarse con react-hook-form usarse con <Controller/> y pasar 'onChange' como "handleFileChange"
  // por ejemplo:
  // <Controller
    //   defaultValue={null}
    //   name="foto"
    //   control={control}
    //   render={({ 
      //   field: { onChange, value },
      // }) => 
      //     <FicFileDrop 
    //       handleFileChange={onChange}
    //       valuu={value}
    //     />
    //   https://react-hook-form.com/docs/usecontroller/controller
    
    const MAX_FILE_SIZE = fileSize? fileSize: 5 * 1024 * 1024; // 5MB 
    const [selectedFile, setSelectedFile] = useState(null)
    const [error, setError] = useState('');

    const disabled = (isDisabled | isReadOnly)? true: false

    useEffect(() => {
      value? setSelectedFile(value) : null
    },[value])
    
    const handleDrop = (event) => {
      event.preventDefault();
      if (!isDisabled) {
        const file = event.dataTransfer.files[0];
        if (file && file.size <= MAX_FILE_SIZE) {
          setSelectedFile(file);
          setError('');
          handleFileChange(file); // Invocar la función de callback con el archivo seleccionado
        } else {
          setError(`La imagen excede el tamaño permitido`);
        }
      }
    };
  
    const handleFileInputChange = (event) => {
      const file = event.target.files[0];
      if (file && file.size <= MAX_FILE_SIZE) {
        setSelectedFile(file);
        setError('');
        handleFileChange(file); // Invocar la función de callback con el archivo seleccionado
      } else {
        setError(`La imagen excede el tamaño permitido`);
      }
    };
  
    const handleImageClick = () => {
      if(!isDisabled) {
        document.getElementById('fileInput').click();
      }
    };

    return (
      <div
        className={`${disabled? "file-drop-input-disabled" : "file-drop"} ${selectedFile ? 'has-image' : ''} ${className || ''} ${fieldStatus ? "file-drop-input-invalid" : ""}`}
        style={{ width: "200px", height: "200px", ...style }}
        onDragOver={(event) => event.preventDefault()}
        onDrop={handleDrop}
        onClick={handleImageClick}
      >
        {selectedFile ? (
          typeof selectedFile === 'string' ? (
            <img className="preview-image" src={selectedFile} alt="Selected" />
          ) : (
            <img className="preview-image" src={URL.createObjectURL(selectedFile)} alt="Selected" />
          )
        ) : (
          <span className="drop-text">{description || 'Arrastra una imagen aquí o haz click (max. 5MB)'}</span>
        )}
        {error && <span className={`${selectedFile ? 'error-text-with-img' : 'error-text-without-img'}`}>{error}</span>}
        <input
          id="fileInput"
          type="file"
          accept="image/*"
          onChange={handleFileInputChange}
          style={{ display: 'none' }}
        />
      </div>
    );
  };
  