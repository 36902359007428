import React from "react";
import FormDialogModal from "../../../components/FormDialogModal";
import GenBtn from "../../../components/GenBtn";
import {
    FicComboBox,
    FicInput,
    FicRadio,
    FicCheckbox,
} from "../../../Utils/FormInputComponents/index";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import styled from "styled-components";

const FormContent = styled.div`
    display: flex;
    overflow: visible;
    flex-direction: column;
    gap: 15px;
    min-width: 25rem;
    width: 45rem;
    height: 18rem;
    padding: 10px;
`;

const schema = yup
    .object({
        marca: yup.mixed().required("La marca es requerida"),
        // linea: yup
        //     .string()
        //     .required('La linea es requerida'),
    })
    .required();

function RecepcionDeVehiculos({
    dialogRef,
    onSubmit,
    listadoDeIngresos,
    ...props
}) {
    const defaultValues = {};

    const {
        handleSubmit,
        reset,
        control,
        formState: { errors },
        setFocus,
    } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
        defaultValues: defaultValues,
    });

    return (
        <FormDialogModal
            style={{ overflow: "visible" }}
            dialogRef={dialogRef}
            onSubmit={handleSubmit(onSubmit)}
            title={"Agregar linea"}
        >
            <FormContent>
                <div className="pr-3 pl-3 pb-2 pt-3 g-3">
                    <FicComboBox
                        componentStyle={{ marginBottom: "3rem" }}
                        name="marca"
                        loadOptions={listadoDeIngresos}
                        control={control}
                    />

                    {/* <FicInput
                            name="linea"
                            placeholder="Linea"
                            control={control}
                        /> */}
                </div>
                <div className="d-flex justify-content-end mt-auto">
                    <GenBtn
                        type="submit"
                        style={{
                            width: "90px",
                            height: "40px",
                            marginBottom: "8px",
                        }}
                    >
                        Agregar
                    </GenBtn>
                </div>
            </FormContent>
        </FormDialogModal>
    );
}

export default RecepcionDeVehiculos;
