import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BootstrapTable } from 'react-bootstrap-table';
import LoadMask from "../LoadMask/LoadMask";


export default class Grid extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false
    };

    createCustomClearButton = (onClick) => {
        return (
          <button className='btn btn-dark btn-sm ml-2' onClick={ onClick }>Limpiar</button>
        );
    }

    createCustomSearchField = () => {
        return (
            <SearchField
                className='form-floating'
                placeholder='Buscar...'
                style={{"height": "35px","font-size": "100%"}}
            />
        );
    }

    render() {
        const {
            loading,
            data,
            page,
            remote=true,
            expandableRow,
            expandComponent,
            cellEditProp,
            afterSave,
            onPageChange,
            onSortChange,
            pagination,
            expanding,
            onExpand,
            trClassName,
            mostrarDescuentos,
            ...other
        } = this.props;

        const options = {
            sizePerPage: 10,
            hideSizePerPage: false,
            alwaysShowAllBtns: true,
            clearSearch: true,
            noDataText: loading ? 'Cargando...' : <span className='title'>No hay datos</span>,
            page,
            clearSearchBtn: this.createCustomClearButton,
            searchField: this.createCustomSearchField,
            onPageChange: onPageChange ? onPageChange : () => {},
            onSortChange: onSortChange ? onSortChange : () => {},
            onExpand:onExpand,
            onRowDoubleClick: function(row) {
                mostrarDescuentos(row.colaborador, row.periodo)
            }
        };

        const footerData = [
            [
                {
                    label: 'Total value',
                    columnIndex: 0,
                    align: 'right',
                    formatter: (tableData) => {
                        const cant = tableData.reduce(function (resultado, elemento) {
                            return parseInt(resultado) + 1;
                        }, 0);
                        return (
                            <strong className='title'>{cant}</strong>
                        );
                    }
                },
                {
                    label: <strong className='title'>Totales:</strong>,
                    columnIndex: 1,
                    align: 'center',
                },
                {
                    label: 'Total value',
                    columnIndex: 4,
                    align: 'right',
                    formatter: (tableData) => {
                        const julio = tableData.reduce(function (resultado, elemento) {
                            return parseFloat(resultado) + parseFloat(elemento.julio);
                        }, 0.00);
                        return (
                            <strong className='title'>{new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(julio)}</strong>
                        );
                    }
                },
                {
                    label: 'Total value',
                    columnIndex: 5,
                    align: 'right',
                    formatter: (tableData) => {
                        const agosto = tableData.reduce(function (resultado, elemento) {
                            return parseFloat(resultado) + parseFloat(elemento.agosto);
                        }, 0.00);
                        return (
                            <strong className='title'>{new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(agosto)}</strong>
                        );
                    }
                },
                {
                    label: 'Total value',
                    columnIndex: 6,
                    align: 'right',
                    formatter: (tableData) => {
                        const septiembre = tableData.reduce(function (resultado, elemento) {
                            return parseFloat(resultado) + parseFloat(elemento.septiembre);
                        }, 0.00);
                        return (
                            <strong className='title'>{new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(septiembre)}</strong>
                        );
                    }
                },
                {
                    label: 'Total value',
                    columnIndex: 7,
                    align: 'right',
                    formatter: (tableData) => {
                        const octubre = tableData.reduce(function (resultado, elemento) {
                            return parseFloat(resultado) + parseFloat(elemento.octubre);
                        }, 0.00);
                        return (
                            <strong className='title'>{new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(octubre)}</strong>
                        );
                    }
                },
                {
                    label: 'Total value',
                    columnIndex: 8,
                    align: 'right',
                    formatter: (tableData) => {
                        const noviembre = tableData.reduce(function (resultado, elemento) {
                            return parseFloat(resultado) + parseFloat(elemento.noviembre);
                        }, 0.00);
                        return (
                            <strong className='title'>{new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(noviembre)}</strong>
                        );
                    }
                },
                {
                    label: 'Total value',
                    columnIndex: 9,
                    align: 'right',
                    formatter: (tableData) => {
                        const diciembre = tableData.reduce(function (resultado, elemento) {
                            return parseFloat(resultado) + parseFloat(elemento.diciembre);
                        }, 0.00);
                        return (
                            <strong className='title'>{new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(diciembre)}</strong>
                        );
                    }
                },
                {
                    label: 'Total value',
                    columnIndex: 10,
                    align: 'right',
                    formatter: (tableData) => {
                        const enero = tableData.reduce(function (resultado, elemento) {
                            return parseFloat(resultado) + parseFloat(elemento.enero);
                        }, 0.00);
                        return (
                            <strong className='title'>{new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(enero)}</strong>
                        );
                    }
                },
                {
                    label: 'Total value',
                    columnIndex: 11,
                    align: 'right',
                    formatter: (tableData) => {
                        const febrero = tableData.reduce(function (resultado, elemento) {
                            return parseFloat(resultado) + parseFloat(elemento.febrero);
                        }, 0.00);
                        return (
                            <strong className='title'>{new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(febrero)}</strong>
                        );
                    }
                },
                {
                    label: 'Total value',
                    columnIndex: 12,
                    align: 'right',
                    formatter: (tableData) => {
                        const marzo = tableData.reduce(function (resultado, elemento) {
                            return parseFloat(resultado) + parseFloat(elemento.marzo);
                        }, 0.00);
                        return (
                            <strong className='title'>{new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(marzo)}</strong>
                        );
                    }
                },
                {
                    label: 'Total value',
                    columnIndex: 13,
                    align: 'right',
                    formatter: (tableData) => {
                        const abril = tableData.reduce(function (resultado, elemento) {
                            return parseFloat(resultado) + parseFloat(elemento.abril);
                        }, 0.00);
                        return (
                            <strong className='title'>{new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(abril)}</strong>
                        );
                    }
                },
                {
                    label: 'Total value',
                    columnIndex: 14,
                    align: 'right',
                    formatter: (tableData) => {
                        const mayo = tableData.reduce(function (resultado, elemento) {
                            return parseFloat(resultado) + parseFloat(elemento.mayo);
                        }, 0.00);
                        return (
                            <strong className='title'>{new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(mayo)}</strong>
                        );
                    }
                },
                {
                    label: 'Total value',
                    columnIndex: 15,
                    align: 'right',
                    formatter: (tableData) => {
                        const junio = tableData.reduce(function (resultado, elemento) {
                            return parseFloat(resultado) + parseFloat(elemento.junio);
                        }, 0.00);
                        return (
                            <strong className='title'>{new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(junio)}</strong>
                        );
                    }
                },
                {
                    label: 'Total value',
                    columnIndex: 16,
                    align: 'right',
                    formatter: (tableData) => {
                        const promedio = tableData.reduce(function (resultado, elemento) {
                            return parseFloat(resultado) + parseFloat(elemento.promedio);
                        }, 0.00);
                        return (
                            <strong className='title'>{new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(promedio)}</strong>
                        );
                    }
                },
                {
                    label: 'Total value',
                    columnIndex: 17,
                    align: 'right',
                    formatter: (tableData) => {
                        const total = tableData.reduce(function (resultado, elemento) {
                            return parseFloat(resultado) + parseFloat(elemento.total);
                        }, 0.00);
                        return (
                            <strong className='title'>{new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(total)}</strong>
                        );
                    }
                },
                {
                    label: 'Total value',
                    columnIndex: 18,
                    align: 'right',
                    formatter: (tableData) => {
                        const prestamo = tableData.reduce(function (resultado, elemento) {
                            return parseFloat(resultado) + parseFloat(elemento.prestamo);
                        }, 0.00);
                        return (
                            <strong className='title'>{new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(prestamo)}</strong>
                        );
                    }
                },
                {
                    label: 'Total value',
                    columnIndex: 19,
                    align: 'right',
                    formatter: (tableData) => {
                        const liquido = tableData.reduce(function (resultado, elemento) {
                            return parseFloat(resultado) + parseFloat(elemento.liquido);
                        }, 0.00);
                        return (
                            <strong className='title'>{new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(liquido)}</strong>
                        );
                    }
                },
            ]
        ];


        return (
            <div>
                <LoadMask loading={loading} dark blur>
                    <BootstrapTable
                        expandableRow={expandableRow}
                        expandComponent={expandComponent}
                        trClassName={trClassName}
                        cellEdit={cellEditProp}
                        data={loading ? [] : data}
                        afterSaveCell={afterSave}
                        searchPlaceholder="Buscar..."
                        search={ true }
                        multiColumnSearch={ true }
                        footerData={footerData}
                        fetchInfo={{ dataTotalSize: data.count }}
                        options={options}
                        searchTotal={this.props.data.count}
                        {...other}
                    />
                </LoadMask>
            </div>

        )
    }
}
