import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/registro_sanitario/licencia';
import Licencia from './LicenciaList';


const ms2p = (state) => {
  return {
    ...state.rs_licencia,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Licencia);
