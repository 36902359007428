import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/punto_venta/bodegas';
import Bodegas from './BodegasList';


const ms2p = (state) => {
   return {
      ...state.bodegas_pv,
   };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Bodegas);
