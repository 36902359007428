import { handleActions } from 'redux-actions';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';
const valEmp = __CONFIG__.empresa;

const LISTADO_PERIODOS = 'LISTADO_PERIODOS';
const LOADER = 'LOADER';
const MODULO = 'MODULO';

const setLoader = loader => ({
    type: LOADER,
    loader,
});


export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const listarPeriodos = (year) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = '';
    let params = {};
    if (valEmp === 'fage'){
        id = id_emp[6]
        params = { id, year };
    }else{
        id = id_emp[5]
        params = { id, year };
    }
    dispatch(setLoader(true));
    api.get('/periodos/periodo_empresa_year', params).then((response)=>{
        let periodo = [];
        response.forEach(item=>{
            periodo.push({
                value: item.id,
                label: item.periodo
            })
        })
        dispatch({ type: LISTADO_PERIODOS, listaPeriodo: periodo });
    }).catch(()=>{
        NotificationManager.error(
            'Ocurrió un error al listar los periodos',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const generarCierre = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id_empresa = '';
    if (valEmp === 'fage') {
        id_empresa = id_emp[6]
    } else {
        id_empresa = id_emp[5]
    }
    const data = getStore().form.FormCierreMesNomina.values;
    if (data === undefined){
        Swal.fire({
            title: 'Cierre de mes',
            text: 'Debe de ingresar el año',
            type: 'error',
        })
    }else{
        const formData = {
            year: data.anio,
            periodo: data.periodo,
            empresa: id_empresa,
            recalcular: 'N'
        }
        dispatch(setLoader(true));
        api.get('/nomina/cerrar_mes_periodo', formData).then((response)=>{
            if (response.result === 'S'){
                Swal.fire({
                    title: 'Cierre de mes',
                    text: 'Ya fue cerrado el mes, ¿Desea volver a calcular datos?',
                    type: 'info',
                    allowOutsideClick: false,
                    confirmButtonText: "Sí",
                    cancelButtonText: "No",
                    showCancelButton: true,
                }).then((result) => {
                    if (result.value) {
                        const formDataR = {
                            year: data.anio,
                            periodo: data.periodo,
                            empresa: id_empresa,
                            recalcular: 'S'
                        }
                        dispatch(setLoader(true));
                        api.get('/nomina/cerrar_mes_periodo', formDataR).then(()=>{
                            NotificationManager.success(
                                'Cierre de mes realizado correctamente',
                                'Éxito',
                                3000
                            );
                        }).catch((error)=>{
                            if (error.status === 404){
                                NotificationManager.error(
                                    error.body.msj,
                                    'Error',
                                    0
                                );
                                let tableRows = '';
                                error.body.result.forEach(item => {
                                    tableRows += `<tr><td style="text-align: left;border: 1px solid white; padding: 10px;">${item.periodo}</td><td style="text-align: right;border: 1px solid white; padding: 10px;">${item.numero}</td></tr>`;
                                })
                                Swal.fire({
                                    title: 'Periodos no cerrados',
                                    html: `
                                        <table style="width: 455px; color: white; border-collapse: collapse;">
                                            <thead>
                                                <tr>
                                                    <th style="border: 1px solid white; padding: 10px;">Periodo</th>
                                                    <th style="border: 1px solid white; padding: 10px;">Numero</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                ${tableRows}
                                            </tbody>
                                        </table>
                                    `,
                                    icon: 'warning',
                                    background: "black",
                                    showCloseButton: true,
                                    confirmButtonColor: '#6c757d',
                                    confirmButtonText: 'Cerrar',
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        
                                    }
                                });
                            }
                            if (error.status === 500) {
                                NotificationManager.error(
                                    'Ocurrió un error al generar cierre de mes',
                                    'Error',
                                    0
                                );
                            }
                        }).finally(() => {
                            dispatch(setLoader(false));
                        });
                    }
                });
            }else{
                NotificationManager.success(
                    'Cierre de mes realizado correctamente',
                    'Éxito',
                    3000
                );
            }
        }).catch((error)=>{
            if (error.status === 404){
                NotificationManager.error(
                    error.body.msj,
                    'Error',
                    0
                );
                let tableRows = '';
                error.body.result.forEach(item => {
                    tableRows += `<tr><td style="text-align: left;border: 1px solid white; padding: 10px;">${item.periodo}</td><td style="text-align: right;border: 1px solid white; padding: 10px;">${item.numero}</td></tr>`;
                })
                Swal.fire({
                    title: 'Periodos no cerrados',
                    html: `
                        <table style="width: 455px; color: white; border-collapse: collapse;">
                            <thead>
                                <tr>
                                    <th style="border: 1px solid white; padding: 10px;">Periodo</th>
                                    <th style="border: 1px solid white; padding: 10px;">Numero</th>
                                </tr>
                            </thead>
                            <tbody>
                                ${tableRows}
                            </tbody>
                        </table>
                    `,
                    icon: 'warning',
                    background: "black",
                    showCloseButton: true,
                    confirmButtonColor: '#6c757d',
                    confirmButtonText: 'Cerrar',
                }).then((result) => {
                    if (result.isConfirmed) {
                        
                    }
                });
            }
            if (error.status === 500) {
                NotificationManager.error(
                    'Ocurrió un error al generar cierre de mes',
                    'Error',
                    0
                );
            }
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
}

export const actions = {
    getModulo,
    listarPeriodos,
    generarCierre
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [LISTADO_PERIODOS]: (state, { listaPeriodo }) => {
        return {
            ...state,
            listaPeriodo,
        };
    },
};

export const initialState = {
    loader: false,
    modulo: [],
    listaPeriodo: [],
};

export default handleActions(reducers, initialState)