import React, { Component } from 'react';
import Grid from '../../Utils/GridFiltro';
import { standardActions } from '../../Utils/Grid/StandardActions';
import moment from 'moment';
import NavbarModulo from '../../components/NavbarModulo';
import PropTypes from 'prop-types';

class DepositoReciboList extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: true
    };

    componentDidMount = () => {
        const { listar, leerPeriodoD, getModulo, listarCuentasBancarias } = this.props;
        let ruta = window.location.href;
        let periodo = ruta.split('/');
        leerPeriodoD(periodo[5], periodo[7]);
        getModulo(periodo[5], periodo[4]);
        listarCuentasBancarias();
        listar();
    }

    render() {
        const { data, loader, eliminarDep, periodoD, modulo, cuenta } = this.props;
        let ruta = window.location.href;
        let datos = ruta.split('/');
        return (
            <React.Fragment>
                <NavbarModulo
                    periodoD={periodoD}
                    modulo={modulo}
                />
                <br />
                <h3 className="p-2 bg-secondary title">Depósitos de recibos</h3>
                <br />
                {periodoD.estado == 'A' &&
                <div>
                    {cuenta &&
                        <div className="d-flex flex-row justify-content-start mb-2">
                            <a
                                href={`/#/bancos/${datos[5]}/deposito_recibo/${datos[7]}/crear/${cuenta}`}
                                className='btn btn-primary'
                            >
                                Crear depósito
                            </a>

                        </div>
                    }
                    {cuenta === '' &&
                        <div className="d-flex flex-row justify-content-start mb-2">
                            <a
                                href={`/#/bancos/${datos[5]}/deposito_recibo/${datos[7]}/seleccionar_cuenta`}
                                className='btn btn-primary'
                            >
                                Crear depósito
                            </a>

                        </div>
                    }
                </div>
                }
                {data && periodoD.estado == 'A' &&
                    <Grid
                        hover
                        striped
                        data={data}
                        loading={loader}
                        headerStyle={{ background: 'black' }}
                    //onPageChange={onPageChange}
                    //onSortChange={onSortChange} 
                    >
                        <TableHeaderColumn
                            isKey
                            dataField="fecha_registro"
                            width="115"
                            dataFormat={(cell, row) => {
                                return moment(cell).format("DD/MM/YYYY");
                            }}
                            dataSort
                            thStyle={
                                { color: 'white' }}
                            tdStyle={
                                { color: 'white' }}
                        >
                            Fecha
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="cuenta"
                            dataSort
                            thStyle={
                                { color: 'white' }}
                            tdStyle={
                                { color: 'white' }}
                        >
                            Cuenta
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="numero"
                            width="200"
                            headerAlign="center"
                            dataAlign='right'
                            dataSort
                            thStyle={
                                { color: 'white' }}
                            tdStyle={
                                { color: 'white' }}
                        >
                            Numero
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="total"
                            headerAlign="center"
                            dataAlign='right'
                            dataFormat={(cell, row) => {
                                if (parseFloat(cell) === 0.00) {
                                    return ''
                                } else {
                                    return new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(cell);
                                }
                            }}
                            dataSort
                            thStyle={
                                { color: 'white' }}
                            tdStyle={
                                { color: 'white' }}
                        >
                            Total
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="concepto"
                            dataSort
                            thStyle={
                                { color: 'white' }}
                            tdStyle={
                                { color: 'white' }}
                        >
                            Concepto
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            thStyle={
                                { color: 'white' }}
                            dataField="id"
                            width="180"
                            dataAlign="center"
                            dataSort
                            dataFormat={standardActions({
                                ver_a: "deposito_recibo",
                                eliminarDep: eliminarDep,
                                empresa: datos[5],
                                periodo: datos[7],
                                modulo: datos[4],
                            })}

                        >
                            Acciones
                        </TableHeaderColumn>
                    </Grid>
                }
                {data && periodoD.estado == 'C' &&
                    <Grid
                        hover
                        striped
                        data={data}
                        loading={loader}
                        headerStyle={{ background: 'black' }}
                    //onPageChange={onPageChange}
                    //onSortChange={onSortChange} 
                    >
                        <TableHeaderColumn
                            isKey
                            dataField="fecha_registro"
                            width="115"
                            dataFormat={(cell, row) => {
                                return moment(cell).format("DD/MM/YYYY");
                            }}
                            dataSort
                            thStyle={
                                { color: 'white' }}
                            tdStyle={
                                { color: 'white' }}
                        >
                            Fecha
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="cuenta"
                            dataSort
                            thStyle={
                                { color: 'white' }}
                            tdStyle={
                                { color: 'white' }}
                        >
                            Cuenta
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="numero"
                            width="200"
                            headerAlign="center"
                            dataAlign='right'
                            dataSort
                            thStyle={
                                { color: 'white' }}
                            tdStyle={
                                { color: 'white' }}
                        >
                            Numero
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="total"
                            headerAlign="center"
                            dataAlign='right'
                            dataFormat={(cell, row) => {
                                if (parseFloat(cell) === 0.00) {
                                    return ''
                                } else {
                                    return new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(cell);
                                }
                            }}
                            dataSort
                            thStyle={
                                { color: 'white' }}
                            tdStyle={
                                { color: 'white' }}
                        >
                            Total
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="concepto"
                            dataSort
                            thStyle={
                                { color: 'white' }}
                            tdStyle={
                                { color: 'white' }}
                        >
                            Concepto
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            thStyle={
                                { color: 'white' }}
                            dataField="id"
                            width="180"
                            dataAlign="center"
                            dataSort
                            dataFormat={standardActions({
                                ver_a: "deposito_recibo",
                                empresa: datos[5],
                                periodo: datos[7],
                                modulo: datos[4],
                            })}

                        >
                            Acciones
                        </TableHeaderColumn>
                    </Grid>
                }
            </React.Fragment>
        );

    }
}

export default DepositoReciboList;
