import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { SidebarData } from "../Sidebar/SidebarDataFinanzasComprasVentas";
import { SidebarDataUserFinanzas } from "../Sidebar/SideBarDataUserFinanzas";
import { IconContext } from "react-icons";
import styled from "styled-components";
import SubMenu from "../Sidebar/SubmenuInventario";
import PropTypes from "prop-types";
const defaultAvatar = require("assets/img/avatar-placeholder.png");
import MenuItems from "./NavbarMenu";
import MenuItemsUser from "./NavbarMenuUser";
import NavbarComponent from "./NavBarSubModulo";

function NavbarLibroCompras(props) {
    const { logOut, user, empresaData } = props;

    return (
        <React.Fragment>
            <IconContext.Provider value={{ color: "#fff" }}>
                <NavBar>
                    <div className="imageBoxLogo d-table m-auto">
                        <img
                            className="ml-3 imageLogoAC"
                            src={require("assets/img/SIFW-LOGO-Red.png")}
                            alt="Logo"
                        />
                    </div>
                    <div className="titleNav">
                        <h4 className="title mt-2 ml-4">
                            {localStorage.getItem("fnzEmpresaName")}
                        </h4>
                    </div>
                    <div className="imageBoxLogo">
                        {empresaData.logo && (
                            <img
                                className="imageLogoAC mr-1"
                                src={__CONFIG__.api + empresaData.logo}
                                alt="Logo"
                            />
                        )}
                    </div>
                    <NavBarMenuUser>
                        <ul className="borderUser flex-row navbar-nav">
                            {SidebarDataUserFinanzas.map((menu, index) => {
                                return (
                                    <MenuItemsUser
                                        items={menu}
                                        key={index}
                                        img={
                                            user.avatar
                                                ? __CONFIG__.api + user.avatar
                                                : defaultAvatar
                                        }
                                        user={user.username}
                                        logOut={logOut}
                                    />
                                );
                            })}
                        </ul>
                    </NavBarMenuUser>
                </NavBar>
                <NavbarComponent links={SidebarData} />
            </IconContext.Provider>
        </React.Fragment>
    );
}

NavbarLibroCompras.propTypes = {
    logOut: PropTypes.func,
    user: PropTypes.object,
};

export default NavbarLibroCompras;

const NavBar = styled.div`
    // background: linear-gradient(to right, #333333, #595959);
    background-color: #1e1e1e;
    height: 61px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const NavBarMenuUser = styled.div`
    background-color: #121212;
    height: 61px;
    width: 450px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;
