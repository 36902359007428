import React, { useState, useEffect } from "react";
import { useController } from "react-hook-form";
import { Label } from "react-aria-components";
import "./FicDatePickerSI.css";

export default function FicDatePickerSI({
    name,
    control,
    label,
    description,
    errorMsj,
    ...props
}) {
    const { field, fieldState } = useController({ control, name });

    return (
        <div className="fic-input-container">
            <Label className="fic-input-label">{label}</Label>
            <input
                {...field}
                type="date"
                className={`${
                    props.isDisabled ? "fic-input-disabled" : "fic-input"
                } ${fieldState.invalid ? "input-invalid " : ""}`}
            />
            {description && <span className="description">{description}</span>}
            {errorMsj && (
                <span className="input-invalid-error-msj">{errorMsj}</span>
            )}
        </div>
    );
}
