import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import Modal from '../../../components/components/ModalA'

import {
    renderField,
    renderDayPickerB,
    SelectField,
    AsyncSelectField,
    renderCurrencyFloat,
    renderCurrencyFloatG,
    renderFieldCheck,
    renderCurrencyFloatA,
    renderNumber,
    renderCurrencyFloatTC,
    renderFieldCheckB
} from "../../Utils/renderField/renderField";

function ComprasForm(props) {
    const {
        crear,
        bloqueo,
        editar,
        tipos,
        listarProveedores,
        registroProveedor,
        fechaInicio,
        fechaFinal,
        datos,
        obtenerPaises,
        impuesto,
        tipoCambio,
        lectura_data,
    } = props;

    let autoFocus = true;
    const [error, setError] = useState('');
    const [dolares, setDolares] = useState(false);
    const [combustible, setCombustible] = useState(false);

    const [base, setBase] = useState(0.00);
    const [tIva, setTIva] = useState(0.00);
    const [total, setTotal] = useState(0.0);

    const [bienes, setBienes] = useState(0.00);
    const [servicios, setServicios] = useState(0.00);
    const [exento, setExento] = useState(0.00);
   
    const [ivaBienes, setIvaBienes] = useState(0.00);
    const [ivaServicios, setIvaServicios] = useState(0.00);

    const [baseBienes, setBaseBienes] = useState(0.00);
    const [baseServicios, setBaseServicios] = useState(0.00);

    const [gSuper, setGsuper] = useState(0.00);
    const [gRegular, setGregular] = useState(0.00);
    const [gDiesel, setGdiesel] = useState(0.00);

    const [cSuper, setCsuper] = useState(0.00);
    const [cRegular, setCregular] = useState(0.00);
    const [cDiesel, setCdiesel] = useState(0.00);
    const [cOtros, setCotros] = useState(0.00);

    const [baseSuper, setBaseSuper] = useState(0.00);
    const [baseRegular, setBaseRegular] = useState(0.00);
    const [baseDiesel, setBaseDiesel] = useState(0.00);

    const [ivaSuper, setIvaSuper] = useState(0.00);
    const [ivaRegular, setIvaRegular] = useState(0.00);
    const [ivaDiesel, setIvaDiesel] = useState(0.00);

    const [idpSuper, setIdpSuper] = useState(0.00);
    const [idpRegular, setIdpRegular] = useState(0.00);
    const [idpDiesel, setIdpDiesel] = useState(0.00);
    const [ivaOtros, setIvaOtros] = useState(0.00);

    const [tComb, setTComb] = useState(0.00);

    const [idp, setIdp] = useState(0.00);

    const [tipoD, setTipoD] = useState(0);

    const [editarD, setEditarD] = useState(editar);
    const [editarT, setEditarT] = useState(editar);

    const [estadoModal, cambiarEstadoModal] = useState(false);

    let dolar = localStorage.getItem('dolaresCompra');
    let comb = localStorage.getItem('combCompra');

    const onCheckboxClicked = (idx, isChecked) => {
        setDolares(isChecked);
        localStorage.removeItem('dolaresCompra');
    }

    const onCheckboxClickedIva = (idx, isChecked) => {
        setIva(isChecked);
    }

    const onCheckboxClickedCombustible = (idx, isChecked) => {
        setCombustible(isChecked);
        localStorage.removeItem('combCompra');
    }
    
    const tipoProveedor = [
        {
            value: 'L',
            label: 'LOCAL'
        },
        {
            value: 'E',
            label: 'EXTERIOR'
        },
    ];
    
    const handleChange = (e) => {
        let existe = tipos.find(element => element.value === e);
        setEditarT(false);
        setTipoD(e);
        if (existe.search === 'IVA') {
            setIva(true);
            localStorage.setItem('A_iva', true);
        }
        if (existe.search === 'COMP') {
            setIva(false);
            localStorage.setItem('A_iva', false);
        }
        if (existe.search === 'PEQ') {
            setIva(false);
            localStorage.setItem('A_iva', false);
        }

    }
    const [tipoCambioD, setTipoCambioD] = useState('');
    const [iva, setIva] = useState(true);
    const [totalQ, setTotalQ] = useState(0.00);
    
    let PIva = parseFloat(parseFloat(parseFloat(impuesto.porcentaje_iva)/100)+1).toFixed(2);
    let CGSuper = parseFloat(impuesto.costo_galon_super).toFixed(2);
    let CGRegular = parseFloat(impuesto.costo_galon_regular).toFixed(2);
    let CGDiesel = parseFloat(impuesto.costo_galon_diesel).toFixed(2);
    
    let detalle = localStorage.getItem('LIQ');
     
    return (
        <div>
            <div className='row mb-2'>
                <div className='col-3'>
                    <Field
                        autoFocus={autoFocus}
                        disabled={bloqueo}
                        name="fechaRegistro"
                        component={renderDayPickerB}
                        msj="Fecha Registro"
                        maxDate={fechaFinal}
                    />
                </div>
                {((crear === false && editar == false) || editar === true) &&
                    <div className='col-3'>
                        <Field
                            name="identificador"
                            placeholder="Identificador"
                            className="form-control"
                            component={renderField}
                            disabled={true}
                            msj="Identificador"
                        />
                    </div>
                }
                {((crear === false && editar == false) || editar === true) &&

                    <div className='col-6'>
                        <Field
                            name="proveedorN"
                            placeholder="Proveedor"
                            className="form-control"
                            component={renderField}
                            disabled={true}
                            msj="Proveedor"
                        />
                    </div>
                }
                {(crear === true) &&
                    <div className='col-6'>
                        <Field
                            name="proveedor_documento"
                            placeholder="Proveedor..."
                            className="form-control-find"
                            component={AsyncSelectField}
                            loadOptions={listarProveedores}
                            msj="Proveedor"
                        />
                    </div>
                }
                {(crear === true) &&
                    <div className='col-3 mt-2'>
                        <button
                            className='btn mr-2 mb-3 btn-secondary'
                            onClick={() => cambiarEstadoModal(!estadoModal)}
                        >
                            Crear nuevo proveedor
                        </button>
                    </div>
                }
            </div>
            {(editar === true) &&
                <div className='row'>
                    <div className='col-6'>
                    </div>
                    <div className='col-6'>
                        <Field
                            name="proveedor_documento"
                            placeholder="Proveedor..."
                            className="form-control-find"
                            component={AsyncSelectField}
                            loadOptions={listarProveedores}
                            msj="Proveedor"
                        />
                    </div>
                </div>

            }
            <fieldset>
                <div className='row mb-2'>
                    {(crear === false && editar == false) ? (
                        <div className='col-3'>
                            <Field
                                disabled={true}
                                name="en_dolares"
                                label="Compra en dolares"
                                component={renderFieldCheck}
                            />
                        </div>
                    ) :
                        <div className='col-3'>
                            <Field
                                name="en_dolares"
                                checked={dolares}
                                label="Compra en dolares"
                                component={renderFieldCheck}
                                onChange={onCheckboxClicked}
                            />
                        </div>
                    }
                    {(crear === false && editar == false) ? (
                        <div className='col-3'>
                            <Field
                                disabled={true}
                                name="afecta_iva"
                                label="Afecta iva"
                                component={renderFieldCheck}
                            />
                        </div>
                    ) :
                        (crear === true && editar == false) ? (
                            <div className='col-3'>
                                <Field
                                    name="afecta_iva"
                                    checked={iva}
                                    label="Afecta iva"
                                    component={renderFieldCheckB}
                                    onChange={onCheckboxClickedIva}
                                />
                            </div>
                        ):
                            <div className='col-3'>
                                <Field
                                    name="afecta_iva"
                                    checked={iva}
                                    label="Afecta iva"
                                    component={renderFieldCheck}
                                    onChange={onCheckboxClickedIva}
                                />
                            </div>
                        
                    }
                    {(crear === false && editar == false) ? (
                        <div className='col-3'>
                            <Field
                                name="afecta_combustible"
                                disabled={true}
                                label="Combustible"
                                component={renderFieldCheck}
                                onChange={onCheckboxClickedCombustible}
                            />
                        </div>
                    ) :
                        <div className='col-3'>
                            <Field
                                name="afecta_combustible"
                                checked={combustible}
                                label="Combustible"
                                component={renderFieldCheck}
                                onChange={onCheckboxClickedCombustible}
                            />
                        </div>
                    }

                </div>
            </fieldset>
            {dolares && crear === true &&
                <div className='row mb-2'>
                    <div className='col-3'>
                        <div className="form-floating">
                            <NumberFormat
                                className={classNames('form-control')}
                                decimalScale={6}
                                fixedDecimalScale
                                placeholder="Tipo de cambio"
                                value={tipoCambioD}
                                thousandSeparator
                                onValueChange={(values) => {
                                    setTipoCambioD(values.value)
                                    localStorage.setItem('TC_compra', values.value)
                                }}
                            />
                            <label className='form-label' htmlFor="cuenta">
                                Tipo de cambio
                            </label>
                        </div>
                    </div>
                    <div className='col-3 mt-2'>
                        <button
                            className='btn mr-2 mb-3 btn-secondary'
                            onClick={() => {
                                let tc = tipoCambio(fechaInicio, fechaFinal);
                                tc.then(d => { 
                                    setTipoCambioD(d);
                                    localStorage.setItem('TC_compra', d)
                                });
                                
                            }}
                        >
                            Buscar tipo de cambio
                        </button>
                    </div>
                    <div className='col-2'>

                    </div>
                    <div className='col-4'>
                        <div className="form-floating">
                            <NumberFormat
                                className={classNames('form-control')}
                                decimalScale={2}
                                disabled={true}
                                placeholder="Total Quetzales"
                                fixedDecimalScale
                                thousandSeparator
                                value={editarD === true ? lectura_data.total_quetzales : totalQ}
                                name="totalQ"
                            />
                            <label className='form-label' htmlFor="total">
                                Total Quetzales
                            </label>
                        </div>
                    </div>
                </div>
            }
            {(crear === false && editar === false && dolar === 'true') &&
                <div className='row mb-2'>
                    <div className='col-3'>
                        <Field
                            name="tipo_cambio"
                            placeholder="Tipo de cambio"
                            className="form-control"
                            component={renderField}
                            disabled={bloqueo}
                            msj="Tipo de cambio"
                        />
                    </div>
                    <div className='col-3'>
                    </div>
                    <div className='col-2'>
                    </div>
                    <div className='col-4'>
                        <Field
                            name="total_quetzales"
                            placeholder="Tipo de cambio"
                            className="form-control"
                            component={renderCurrencyFloat}
                            disabled={bloqueo}
                            msj="Total Quetzales"
                        />
                    </div>
                </div>
            }
            {(dolar === 'true' && editar === true ) &&
                <div className='row mb-2'>
                    <div className='col-3'>
                        <Field
                            name="tipo_cambio"
                            placeholder="Tipo de cambio"
                            className="form-control"
                            component={renderCurrencyFloatTC}
                            disabled={bloqueo}
                            msj="Tipo de cambio"
                            onChange={(e, value) => {
                                if (value) {
                                    setEditarD(false);
                                    let totQ = parseFloat(parseFloat(lectura_data.total) * parseFloat(value)).toFixed(2)
                                    setTotalQ(totQ)
                                    localStorage.setItem('TC_compra', value)
                                }
                            }}
                        />
                    </div>
                    <div className='col-3'>
                    </div>
                    <div className='col-2'>
                    </div>
                    <div className='col-4'>
                        <div className="form-floating">
                            <NumberFormat
                                className={classNames('form-control')}
                                decimalScale={2}
                                disabled={true}
                                placeholder="Total Quetzales"
                                fixedDecimalScale
                                thousandSeparator
                                value={editarD === true ? lectura_data.total_quetzales : totalQ}
                                name="Total Quetzales"
                            />
                            <label className='form-label' htmlFor="base">
                            Total Quetzales
                            </label>
                        </div>
                    </div>
                </div>
            }
            {(dolares === true && editar === true) &&
                <div className='row mb-2'>
                    <div className='col-3'>
                        <Field
                            name="tipo_cambio"
                            placeholder="Tipo de cambio"
                            className="form-control"
                            component={renderCurrencyFloatTC}
                            disabled={bloqueo}
                            msj="Tipo de cambio"
                            onChange={(e, value) => {
                                if (value) {
                                    setEditarD(false);
                                    let totQ = parseFloat(parseFloat(lectura_data.total) * parseFloat(value)).toFixed(2)
                                    setTotalQ(totQ)
                                    localStorage.setItem('TC_compra', value)
                                }
                            }}
                        />
                    </div>
                    <div className='col-3'>
                    </div>
                    <div className='col-2'>
                    </div>
                    <div className='col-4'>
                        <div className="form-floating">
                            <NumberFormat
                                className={classNames('form-control')}
                                decimalScale={2}
                                disabled={true}
                                placeholder="Total Quetzales"
                                fixedDecimalScale
                                thousandSeparator
                                value={editarD === true ? lectura_data.total_quetzales : totalQ}
                                name="Total Quetzales"
                            />
                            <label className='form-label' htmlFor="base">
                            Total Quetzales
                            </label>
                        </div>
                    </div>
                </div>
            }
            <div className='row mb-2'>
                {crear === false && editar == false &&
                    <div className='col-3'>
                        {tipos &&
                            <div className="form-floating">
                                <Field
                                    name="tipo"
                                    options={tipos}
                                    crear={crear}
                                    dato={crear === true ? 0 : lectura_data.tipo}
                                    component={SelectField}
                                    className="form-select"
                                    disabled={bloqueo}
                                    msj="Tipo de documento"
                                />
                            </div>
                        }
                    </div>
                }
                {(crear === true || editar == true) &&
                    <div className='col-3'>
                        <div className="form-floating">
                            <Field
                                name="tipo"
                                options={tipos}
                                crear={crear}
                                dato={crear === true ? 0 : lectura_data.tipo}
                                component={SelectField}
                                className="form-select"
                                onChange={(e, value) => {
                                    handleChange(value)
                                }}
                                msj="Tipo de documento"
                            />
                        </div>
                    </div>
                }
                <div className='col-3'>
                    <Field
                        disabled={bloqueo}
                        name="fecha"
                        component={renderDayPickerB}
                        msj="Fecha"
                        maxDate={fechaFinal}
                        minDate={'2000-01-01'}
                    />
                </div>
                <div className='col-3'>
                    <Field
                        name="serie"
                        placeholder="Serie"
                        className="form-control"
                        component={renderField}
                        disabled={bloqueo}
                        msj="Serie"
                    />
                </div>
                <div className='col-3'>
                    <Field
                        name="numero"
                        placeholder="Numero"
                        className="form-control"
                        component={renderNumber}
                        disabled={bloqueo}
                        msj="Numero"
                    />
                </div>
            </div>
            {(crear === false && editar === false && comb === 'true') &&
                <div>
                    <div className='row mb-2'>
                        <div className='col-3'>
                            <div className="form-floating">
                                <Field
                                    name="gsuper"
                                    placeholder="Galones super"
                                    className="form-control"
                                    component={renderCurrencyFloatG}
                                    disabled={true}
                                    msj="Galones super"
                                />
                            </div>
                        </div>
                        <div className='col-3'>
                            <div className="form-floating">
                                <Field
                                    name="csuper"
                                    placeholder="Total factura"
                                    className="form-control"
                                    component={renderCurrencyFloatG}
                                    disabled={true}
                                    msj="Total factura"
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-3'>
                            <div className="form-floating">
                                <Field
                                    name="gregular"
                                    placeholder="Galones regular"
                                    className="form-control"
                                    component={renderCurrencyFloatG}
                                    disabled={true}
                                    msj="Galones Regular"
                                />
                            </div>
                        </div>
                        <div className='col-3'>
                            <div className="form-floating">
                                <Field
                                    name="cregular"
                                    placeholder="Total factura"
                                    className="form-control"
                                    component={renderCurrencyFloatG}
                                    disabled={true}
                                    msj="Total factura"
                                />
                            </div>
                        </div>
                        <div className='col-3'>
                            <div className="form-floating">
                                <Field
                                    name="otros"
                                    placeholder="Otros"
                                    className="form-control"
                                    component={renderCurrencyFloat}
                                    disabled={true}
                                    msj="Otros"
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-3'>
                            <div className="form-floating">
                                <Field
                                    name="cdiesel"
                                    placeholder="Galones diesel"
                                    className="form-control"
                                    component={renderCurrencyFloatG}
                                    disabled={true}
                                    msj="Galones diesel"
                                />
                            </div>
                        </div>
                        <div className='col-3'>
                            <div className="form-floating">
                                <Field
                                    name="cdiesel"
                                    placeholder="Total factura"
                                    className="form-control"
                                    component={renderCurrencyFloatG}
                                    disabled={true}
                                    msj="Total factura"
                                />
                            </div>
                        </div>
                        <div className='col-3'>
                            <div className="form-floating">
                                <Field
                                    name="idp"
                                    placeholder="IDP"
                                    className="form-control"
                                    component={renderCurrencyFloat}
                                    disabled={true}
                                    msj="IDP"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }
            {combustible && crear === true &&
                <div>
                    <div className='row mb-2'>
                        <div className='col-3'>
                            <div className="form-floating">
                                <Field
                                    name="gsuper"
                                    placeholder="Galones super"
                                    className="form-control"
                                    component={renderCurrencyFloatG}
                                    disabled={bloqueo}
                                    onChange={(e, value) => {
                                        if (value) {
                                            let totSuper = parseFloat(parseFloat(value) * CGSuper).toFixed(2);
                                            let Tidp = parseFloat(parseFloat(totSuper) + parseFloat(idpRegular) + parseFloat(idpDiesel)).toFixed(2);
                                            setIdpSuper(totSuper);
                                            setIdp(Tidp);
                                            setGsuper(totSuper);

                                        } else {
                                            setGsuper(0.00);
                                            setIdpSuper(0.00);
                                        }
                                    }}
                                    msj="Galones super"
                                />
                            </div>
                        </div>
                        <div className='col-3'>
                            <Field
                                name="csuper"
                                placeholder="Total factura"
                                className="form-control"
                                component={renderCurrencyFloat}
                                disabled={bloqueo}
                                onChange={(e, value) => {
                                    if (value) {
                                        let baseS = (parseFloat(value) - parseFloat(gSuper)) / PIva;
                                        let ivaSup = (parseFloat(value) - parseFloat(idpSuper)) - parseFloat(baseS);
                                        let totalB = parseFloat(parseFloat(baseS) + parseFloat(baseRegular) + parseFloat(baseDiesel)).toFixed(2);
                                        let totI = parseFloat(parseFloat(ivaSup) + parseFloat(ivaRegular) + parseFloat(ivaDiesel) + parseFloat(ivaOtros)).toFixed(2);
                                        let totCombus = parseFloat(parseFloat(value) + parseFloat(cRegular) + parseFloat(cDiesel) + parseFloat(cOtros)).toFixed(2);
                                        setBase(totalB);
                                        setBaseSuper(baseS);
                                        setIvaSuper(ivaSup);
                                        setTIva(totI);
                                        setTComb(totCombus);
                                        setTotal(totCombus);
                                        setCsuper(value);

                                        localStorage.setItem('dataD', totI);
                                        localStorage.setItem('base', totalB);
                                        localStorage.setItem('iva', totI);
                                        localStorage.setItem('total', totCombus);
                                    } else {
                                        setTIva(0.00);
                                        setBase(0.00);
                                        setBaseSuper(0.00);
                                        setTotal(0.00);
                                        setTComb(0.00);
                                        setCsuper(0.00);
                                    }
                                }}
                                msj="Total factura"
                            />
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-3'>
                            <div className="form-floating">
                                <Field
                                    name="gregular"
                                    placeholder="Galones regular"
                                    className="form-control"
                                    component={renderCurrencyFloatG}
                                    disabled={bloqueo}
                                    onChange={(e, value) => {
                                        if (value) {
                                            let totRegular = parseFloat(parseFloat(value) * CGRegular).toFixed(2);
                                            let Tidp = parseFloat(parseFloat(totRegular) + parseFloat(idpSuper) + parseFloat(idpDiesel)).toFixed(2);
                                            setIdpRegular(totRegular);
                                            setIdp(Tidp);
                                            setGregular(totRegular);

                                        } else {
                                            setGregular(0.00);
                                            setIdpRegular(0.00);
                                        }

                                    }}
                                    msj="Galones regular"
                                />
                            </div>
                        </div>
                        <div className='col-3'>
                            <Field
                                name="cregular"
                                placeholder="Total factura"
                                className="form-control"
                                component={renderCurrencyFloat}
                                disabled={bloqueo}
                                onChange={(e, value) => {
                                    if (value) {
                                        let baseR = (parseFloat(value) - parseFloat(gRegular)) / PIva;
                                        let ivaReg = (parseFloat(value) - parseFloat(idpRegular)) - parseFloat(baseR);
                                        let totalB = parseFloat(parseFloat(baseR) + parseFloat(baseSuper) + parseFloat(baseDiesel)).toFixed(2);
                                        let totI = parseFloat(parseFloat(ivaReg) + parseFloat(ivaSuper) + parseFloat(ivaDiesel) + parseFloat(ivaOtros)).toFixed(2);
                                        let totCombus = parseFloat(parseFloat(value) + parseFloat(cSuper) + parseFloat(cDiesel) + parseFloat(cOtros)).toFixed(2);
                                        setBase(totalB);
                                        setBaseRegular(baseR);
                                        setIvaRegular(ivaReg);
                                        setTIva(totI);
                                        setTComb(totCombus);
                                        setTotal(totCombus);
                                        setCregular(value);

                                        localStorage.setItem('dataD', totI);
                                        localStorage.setItem('base', totalB);
                                        localStorage.setItem('iva', totI);
                                        localStorage.setItem('total', totCombus);
                                    } else {
                                        setTIva(0.00);
                                        setBase(0.00);
                                        setBaseRegular(0.00);
                                        setTotal(0.00);
                                        setTComb(0.00);
                                        setCregular(0.00);
                                    }
                                }}
                                msj="Total factura"
                            />
                        </div>
                        <div className='col-3'>
                            <div className="form-floating">
                                <Field
                                    name="otros"
                                    placeholder="Otros"
                                    className="form-control"
                                    component={renderCurrencyFloat}
                                    disabled={bloqueo}
                                    onChange={(e, value) => {
                                        if (value) {
                                            let baseO = parseFloat(parseFloat(value) / PIva).toFixed(2);
                                            let ivaO = parseFloat(parseFloat(parseFloat(value) - parseFloat(baseO)).toFixed(2)).toFixed(2);
                                            let totI = parseFloat(parseFloat(ivaRegular) + parseFloat(ivaSuper) + parseFloat(ivaDiesel) + parseFloat(ivaO)).toFixed(2);
                                            let totalB = parseFloat(parseFloat(baseO) + parseFloat(baseRegular) + parseFloat(baseDiesel) + parseFloat(baseSuper)).toFixed(2);
                                            let totCombus = parseFloat(parseFloat(value) + parseFloat(cRegular) + parseFloat(cDiesel) + parseFloat(cSuper)).toFixed(2);
                                            setBase(totalB);
                                            setIvaOtros(ivaO);
                                            setCotros(value);
                                            setTIva(totI);
                                            setTComb(totCombus);
                                            setTotal(totCombus);

                                            localStorage.setItem('base', totalB);
                                            localStorage.setItem('iva', totI);
                                            localStorage.setItem('total', totCombus)

                                        } else {
                                            setTIva(0.00);
                                            setCotros(0.00);
                                        }
                                    }}
                                    msj="Otros"
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-3'>
                            <div className="form-floating">
                                <Field
                                    name="gdiesel"
                                    placeholder="Galones diesel"
                                    className="form-control"
                                    component={renderCurrencyFloatG}
                                    disabled={bloqueo}
                                    onChange={(e, value) => {
                                        if (value) {
                                            let totDiesel = parseFloat(parseFloat(value) * CGDiesel).toFixed(2);
                                            let Tidp = parseFloat(parseFloat(totDiesel) + parseFloat(idpSuper) + parseFloat(idpRegular)).toFixed(2);
                                            setIdpDiesel(totDiesel);
                                            setGdiesel(totDiesel);
                                            setIdp(Tidp)

                                        } else {
                                            setGdiesel(0.00);
                                            setIdpDiesel(0.00);
                                        }

                                    }}
                                    msj="Galones diesel"
                                />
                            </div>
                        </div>
                        <div className='col-3'>
                            <Field
                                name="cdiesel"
                                placeholder="Total factura"
                                className="form-control"
                                component={renderCurrencyFloat}
                                disabled={bloqueo}
                                onChange={(e, value) => {
                                    if (value) {
                                        let baseD = parseFloat((parseFloat(value) - parseFloat(gDiesel)) / PIva).toFixed(2);
                                        let ivaDie = parseFloat((parseFloat(value) - parseFloat(idpDiesel)) - parseFloat(baseD)).toFixed(2);
                                        let totalB = parseFloat(parseFloat(baseD) + parseFloat(baseRegular) + parseFloat(baseSuper)).toFixed(2);
                                        let totI = parseFloat(parseFloat(ivaDie) + parseFloat(ivaSuper) + parseFloat(ivaRegular) + parseFloat(ivaOtros)).toFixed(2);
                                        let totCombus = parseFloat(parseFloat(value) + parseFloat(cRegular) + parseFloat(cSuper)).toFixed(2);
                                        setBase(totalB);
                                        setBaseDiesel(baseD);
                                        setIvaDiesel(ivaDie);
                                        setTIva(totI);
                                        setTComb(totCombus);
                                        setTotal(totCombus);
                                        setCdiesel(value);

                                        localStorage.setItem('base', totalB);
                                        localStorage.setItem('iva', totI);
                                        localStorage.setItem('total', totCombus)
                                    } else {
                                        setTIva(0.00);
                                        setBase(0.00);
                                        setTotal(0.00);
                                        setTComb(0.00);
                                    }
                                }}
                                msj="Total factura"
                            />
                        </div>
                        <div className='col-3'>
                            <div className="form-floating">
                                <Field
                                    name="idp"
                                    placeholder="Numero"
                                    className="form-control"
                                    component={renderCurrencyFloatA}
                                    disabled={true}
                                    dato={idp}
                                    detail={detalle}
                                    msj="IDP"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }
            {(comb === 'true' && editar === true) && 
                <div>
                    <div className='row mb-2'>
                        <div className='col-3'>
                            <div className="form-floating">
                                <Field
                                    name="gsuper"
                                    placeholder="Galones super"
                                    className="form-control"
                                    component={renderCurrencyFloatG}
                                    disabled={bloqueo}
                                    onChange={(e, value) => {
                                        if (value) {
                                            let totSuper = parseFloat(parseFloat(value) * CGSuper).toFixed(2);
                                            let Tidp = parseFloat(parseFloat(totSuper) + parseFloat(idpRegular) + parseFloat(idpDiesel)).toFixed(2);
                                            setIdpSuper(totSuper);
                                            setIdp(Tidp);
                                            setGsuper(totSuper);

                                        } else {
                                            setGsuper(0.00);
                                            setIdpSuper(0.00);
                                        }
                                    }}
                                    msj="Galones super"
                                />
                            </div>
                        </div>
                        <div className='col-3'>
                            <Field
                                name="csuper"
                                placeholder="Total factura"
                                className="form-control"
                                component={renderCurrencyFloat}
                                disabled={bloqueo}
                                onChange={(e, value) => {
                                    if (value) {
                                        let baseS = (parseFloat(value) - parseFloat(gSuper)) / PIva;
                                        let ivaSup = (parseFloat(value) - parseFloat(idpSuper)) - parseFloat(baseS);
                                        let totalB = parseFloat(parseFloat(baseS) + parseFloat(baseRegular) + parseFloat(baseDiesel)).toFixed(2);
                                        let totI = parseFloat(parseFloat(ivaSup) + parseFloat(ivaRegular) + parseFloat(ivaDiesel) + parseFloat(ivaOtros)).toFixed(2);
                                        let totCombus = parseFloat(parseFloat(value) + parseFloat(cRegular) + parseFloat(cDiesel) + parseFloat(cOtros)).toFixed(2);
                                        setBase(totalB);
                                        setBaseSuper(baseS);
                                        setIvaSuper(ivaSup);
                                        setTIva(totI);
                                        setTComb(totCombus);
                                        setTotal(totCombus);
                                        setCsuper(value);

                                        localStorage.setItem('dataD', totI);
                                        localStorage.setItem('base', totalB);
                                        localStorage.setItem('iva', totI);
                                        localStorage.setItem('total', totCombus);
                                    } else {
                                        setTIva(0.00);
                                        setBase(0.00);
                                        setBaseSuper(0.00);
                                        setTotal(0.00);
                                        setTComb(0.00);
                                        setCsuper(0.00);
                                    }
                                }}
                                msj="Total factura"
                            />
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-3'>
                            <div className="form-floating">
                                <Field
                                    name="gregular"
                                    placeholder="Galones regular"
                                    className="form-control"
                                    component={renderCurrencyFloatG}
                                    disabled={bloqueo}
                                    onChange={(e, value) => {
                                        if (value) {
                                            let totRegular = parseFloat(parseFloat(value) * CGRegular).toFixed(2);
                                            let Tidp = parseFloat(parseFloat(totRegular) + parseFloat(idpSuper) + parseFloat(idpDiesel)).toFixed(2);
                                            setIdpRegular(totRegular);
                                            setIdp(Tidp);
                                            setGregular(totRegular);

                                        } else {
                                            setGregular(0.00);
                                            setIdpRegular(0.00);
                                        }

                                    }}
                                    msj="Galones regular"
                                />
                            </div>
                        </div>
                        <div className='col-3'>
                            <Field
                                name="cregular"
                                placeholder="Total factura"
                                className="form-control"
                                component={renderCurrencyFloat}
                                disabled={bloqueo}
                                onChange={(e, value) => {
                                    if (value) {
                                        let baseR = (parseFloat(value) - parseFloat(gRegular)) / PIva;
                                        let ivaReg = (parseFloat(value) - parseFloat(idpRegular)) - parseFloat(baseR);
                                        let totalB = parseFloat(parseFloat(baseR) + parseFloat(baseSuper) + parseFloat(baseDiesel)).toFixed(2);
                                        let totI = parseFloat(parseFloat(ivaReg) + parseFloat(ivaSuper) + parseFloat(ivaDiesel) + parseFloat(ivaOtros)).toFixed(2);
                                        let totCombus = parseFloat(parseFloat(value) + parseFloat(cSuper) + parseFloat(cDiesel) + parseFloat(cOtros)).toFixed(2);
                                        setBase(totalB);
                                        setBaseRegular(baseR);
                                        setIvaRegular(ivaReg);
                                        setTIva(totI);
                                        setTComb(totCombus);
                                        setTotal(totCombus);
                                        setCregular(value);

                                        localStorage.setItem('dataD', totI);
                                        localStorage.setItem('base', totalB);
                                        localStorage.setItem('iva', totI);
                                        localStorage.setItem('total', totCombus);
                                    } else {
                                        setTIva(0.00);
                                        setBase(0.00);
                                        setBaseRegular(0.00);
                                        setTotal(0.00);
                                        setTComb(0.00);
                                        setCregular(0.00);
                                    }
                                }}
                                msj="Total factura"
                            />
                        </div>
                        <div className='col-3'>
                            <div className="form-floating">
                                <Field
                                    name="otros"
                                    placeholder="Otros"
                                    className="form-control"
                                    component={renderCurrencyFloat}
                                    disabled={bloqueo}
                                    onChange={(e, value) => {
                                        if (value) {
                                            let baseO = parseFloat(parseFloat(value) / PIva).toFixed(2);
                                            let ivaO = parseFloat(parseFloat(parseFloat(value) - parseFloat(baseO)).toFixed(2)).toFixed(2);
                                            let totI = parseFloat(parseFloat(ivaRegular) + parseFloat(ivaSuper) + parseFloat(ivaDiesel) + parseFloat(ivaO)).toFixed(2);
                                            let totalB = parseFloat(parseFloat(baseO) + parseFloat(baseRegular) + parseFloat(baseDiesel) + parseFloat(baseSuper)).toFixed(2);
                                            let totCombus = parseFloat(parseFloat(value) + parseFloat(cRegular) + parseFloat(cDiesel) + parseFloat(cSuper)).toFixed(2);
                                            setBase(totalB);
                                            setIvaOtros(ivaO);
                                            setCotros(value);
                                            setTIva(totI);
                                            setTComb(totCombus);
                                            setTotal(totCombus);

                                            localStorage.setItem('base', totalB);
                                            localStorage.setItem('iva', totI);
                                            localStorage.setItem('total', totCombus)

                                        } else {
                                            setTIva(0.00);
                                            setCotros(0.00);
                                        }
                                    }}
                                    msj="Otros"
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-3'>
                            <div className="form-floating">
                                <Field
                                    name="gdiesel"
                                    placeholder="Galones diesel"
                                    className="form-control"
                                    component={renderCurrencyFloatG}
                                    disabled={bloqueo}
                                    onChange={(e, value) => {
                                        if (value) {
                                            let totDiesel = parseFloat(parseFloat(value) * CGDiesel).toFixed(2);
                                            let Tidp = parseFloat(parseFloat(totDiesel) + parseFloat(idpSuper) + parseFloat(idpRegular)).toFixed(2);
                                            setIdpDiesel(totDiesel);
                                            setGdiesel(totDiesel);
                                            setIdp(Tidp)

                                        } else {
                                            setGdiesel(0.00);
                                            setIdpDiesel(0.00);
                                        }

                                    }}
                                    msj="Galones diesel"
                                />
                            </div>
                        </div>
                        <div className='col-3'>
                            <Field
                                name="cdiesel"
                                placeholder="Total factura"
                                className="form-control"
                                component={renderCurrencyFloat}
                                disabled={bloqueo}
                                onChange={(e, value) => {
                                    if (value) {
                                        let baseD = parseFloat((parseFloat(value) - parseFloat(gDiesel)) / PIva).toFixed(2);
                                        let ivaDie = parseFloat((parseFloat(value) - parseFloat(idpDiesel)) - parseFloat(baseD)).toFixed(2);
                                        let totalB = parseFloat(parseFloat(baseD) + parseFloat(baseRegular) + parseFloat(baseSuper)).toFixed(2);
                                        let totI = parseFloat(parseFloat(ivaDie) + parseFloat(ivaSuper) + parseFloat(ivaRegular) + parseFloat(ivaOtros)).toFixed(2);
                                        let totCombus = parseFloat(parseFloat(value) + parseFloat(cRegular) + parseFloat(cSuper)).toFixed(2);
                                        setBase(totalB);
                                        setBaseDiesel(baseD);
                                        setIvaDiesel(ivaDie);
                                        setTIva(totI);
                                        setTComb(totCombus);
                                        setTotal(totCombus);
                                        setCdiesel(value);

                                        localStorage.setItem('base', totalB);
                                        localStorage.setItem('iva', totI);
                                        localStorage.setItem('total', totCombus)
                                    } else {
                                        setTIva(0.00);
                                        setBase(0.00);
                                        setTotal(0.00);
                                        setTComb(0.00);
                                    }
                                }}
                                msj="Total factura"
                            />
                        </div>
                        <div className='col-3'>
                            <div className="form-floating">
                                <Field
                                    name="idp"
                                    placeholder="Numero"
                                    className="form-control"
                                    component={renderCurrencyFloatA}
                                    disabled={true}
                                    dato={idp}
                                    detail={detalle}
                                    msj="IDP"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }
            {(combustible === true && editar === true) &&
                <div>
                    <div className='row mb-2'>
                        <div className='col-3'>
                            <div className="form-floating">
                                <Field
                                    name="gsuper"
                                    placeholder="Galones super"
                                    className="form-control"
                                    component={renderCurrencyFloatG}
                                    disabled={bloqueo}
                                    onChange={(e, value) => {
                                        if (value) {
                                            let totSuper = parseFloat(parseFloat(value) * CGSuper).toFixed(2);
                                            let Tidp = parseFloat(parseFloat(totSuper) + parseFloat(idpRegular) + parseFloat(idpDiesel)).toFixed(2);
                                            setIdpSuper(totSuper);
                                            setIdp(Tidp);
                                            setGsuper(totSuper);

                                        } else {
                                            setGsuper(0.00);
                                            setIdpSuper(0.00);
                                        }
                                    }}
                                    msj="Galones super"
                                />
                            </div>
                        </div>
                        <div className='col-3'>
                            <Field
                                name="csuper"
                                placeholder="Total factura"
                                className="form-control"
                                component={renderCurrencyFloat}
                                disabled={bloqueo}
                                onChange={(e, value) => {
                                    if (value) {
                                        let baseS = (parseFloat(value) - parseFloat(gSuper)) / PIva;
                                        let ivaSup = (parseFloat(value) - parseFloat(idpSuper)) - parseFloat(baseS);
                                        let totalB = parseFloat(parseFloat(baseS) + parseFloat(baseRegular) + parseFloat(baseDiesel)).toFixed(2);
                                        let totI = parseFloat(parseFloat(ivaSup) + parseFloat(ivaRegular) + parseFloat(ivaDiesel) + parseFloat(ivaOtros)).toFixed(2);
                                        let totCombus = parseFloat(parseFloat(value) + parseFloat(cRegular) + parseFloat(cDiesel) + parseFloat(cOtros)).toFixed(2);
                                        setBase(totalB);
                                        setBaseSuper(baseS);
                                        setIvaSuper(ivaSup);
                                        setTIva(totI);
                                        setTComb(totCombus);
                                        setTotal(totCombus);
                                        setCsuper(value);

                                        localStorage.setItem('dataD', totI);
                                        localStorage.setItem('base', totalB);
                                        localStorage.setItem('iva', totI);
                                        localStorage.setItem('total', totCombus);
                                    } else {
                                        setTIva(0.00);
                                        setBase(0.00);
                                        setBaseSuper(0.00);
                                        setTotal(0.00);
                                        setTComb(0.00);
                                        setCsuper(0.00);
                                    }
                                }}
                                msj="Total factura"
                            />
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-3'>
                            <div className="form-floating">
                                <Field
                                    name="gregular"
                                    placeholder="Galones regular"
                                    className="form-control"
                                    component={renderCurrencyFloatG}
                                    disabled={bloqueo}
                                    onChange={(e, value) => {
                                        if (value) {
                                            let totRegular = parseFloat(parseFloat(value) * CGRegular).toFixed(2);
                                            let Tidp = parseFloat(parseFloat(totRegular) + parseFloat(idpSuper) + parseFloat(idpDiesel)).toFixed(2);
                                            setIdpRegular(totRegular);
                                            setIdp(Tidp);
                                            setGregular(totRegular);

                                        } else {
                                            setGregular(0.00);
                                            setIdpRegular(0.00);
                                        }

                                    }}
                                    msj="Galones regular"
                                />
                            </div>
                        </div>
                        <div className='col-3'>
                            <Field
                                name="cregular"
                                placeholder="Total factura"
                                className="form-control"
                                component={renderCurrencyFloat}
                                disabled={bloqueo}
                                onChange={(e, value) => {
                                    if (value) {
                                        let baseR = (parseFloat(value) - parseFloat(gRegular)) / PIva;
                                        let ivaReg = (parseFloat(value) - parseFloat(idpRegular)) - parseFloat(baseR);
                                        let totalB = parseFloat(parseFloat(baseR) + parseFloat(baseSuper) + parseFloat(baseDiesel)).toFixed(2);
                                        let totI = parseFloat(parseFloat(ivaReg) + parseFloat(ivaSuper) + parseFloat(ivaDiesel) + parseFloat(ivaOtros)).toFixed(2);
                                        let totCombus = parseFloat(parseFloat(value) + parseFloat(cSuper) + parseFloat(cDiesel) + parseFloat(cOtros)).toFixed(2);
                                        setBase(totalB);
                                        setBaseRegular(baseR);
                                        setIvaRegular(ivaReg);
                                        setTIva(totI);
                                        setTComb(totCombus);
                                        setTotal(totCombus);
                                        setCregular(value);

                                        localStorage.setItem('dataD', totI);
                                        localStorage.setItem('base', totalB);
                                        localStorage.setItem('iva', totI);
                                        localStorage.setItem('total', totCombus);
                                    } else {
                                        setTIva(0.00);
                                        setBase(0.00);
                                        setBaseRegular(0.00);
                                        setTotal(0.00);
                                        setTComb(0.00);
                                        setCregular(0.00);
                                    }
                                }}
                                msj="Total factura"
                            />
                        </div>
                        <div className='col-3'>
                            <div className="form-floating">
                                <Field
                                    name="otros"
                                    placeholder="Otros"
                                    className="form-control"
                                    component={renderCurrencyFloat}
                                    disabled={bloqueo}
                                    onChange={(e, value) => {
                                        if (value) {
                                            let baseO = parseFloat(parseFloat(value) / PIva).toFixed(2);
                                            let ivaO = parseFloat(parseFloat(parseFloat(value) - parseFloat(baseO)).toFixed(2)).toFixed(2);
                                            let totI = parseFloat(parseFloat(ivaRegular) + parseFloat(ivaSuper) + parseFloat(ivaDiesel) + parseFloat(ivaO)).toFixed(2);
                                            let totalB = parseFloat(parseFloat(baseO) + parseFloat(baseRegular) + parseFloat(baseDiesel) + parseFloat(baseSuper)).toFixed(2);
                                            let totCombus = parseFloat(parseFloat(value) + parseFloat(cRegular) + parseFloat(cDiesel) + parseFloat(cSuper)).toFixed(2);
                                            setBase(totalB);
                                            setIvaOtros(ivaO);
                                            setCotros(value);
                                            setTIva(totI);
                                            setTComb(totCombus);
                                            setTotal(totCombus);

                                            localStorage.setItem('base', totalB);
                                            localStorage.setItem('iva', totI);
                                            localStorage.setItem('total', totCombus)

                                        } else {
                                            setTIva(0.00);
                                            setCotros(0.00);
                                        }
                                    }}
                                    msj="Otros"
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-3'>
                            <div className="form-floating">
                                <Field
                                    name="gdiesel"
                                    placeholder="Galones diesel"
                                    className="form-control"
                                    component={renderCurrencyFloatG}
                                    disabled={bloqueo}
                                    onChange={(e, value) => {
                                        if (value) {
                                            let totDiesel = parseFloat(parseFloat(value) * CGDiesel).toFixed(2);
                                            let Tidp = parseFloat(parseFloat(totDiesel) + parseFloat(idpSuper) + parseFloat(idpRegular)).toFixed(2);
                                            setIdpDiesel(totDiesel);
                                            setGdiesel(totDiesel);
                                            setIdp(Tidp)

                                        } else {
                                            setGdiesel(0.00);
                                            setIdpDiesel(0.00);
                                        }

                                    }}
                                    msj="Galones diesel"
                                />
                            </div>
                        </div>
                        <div className='col-3'>
                            <Field
                                name="cdiesel"
                                placeholder="Total factura"
                                className="form-control"
                                component={renderCurrencyFloat}
                                disabled={bloqueo}
                                onChange={(e, value) => {
                                    if (value) {
                                        let baseD = parseFloat((parseFloat(value) - parseFloat(gDiesel)) / PIva).toFixed(2);
                                        let ivaDie = parseFloat((parseFloat(value) - parseFloat(idpDiesel)) - parseFloat(baseD)).toFixed(2);
                                        let totalB = parseFloat(parseFloat(baseD) + parseFloat(baseRegular) + parseFloat(baseSuper)).toFixed(2);
                                        let totI = parseFloat(parseFloat(ivaDie) + parseFloat(ivaSuper) + parseFloat(ivaRegular) + parseFloat(ivaOtros)).toFixed(2);
                                        let totCombus = parseFloat(parseFloat(value) + parseFloat(cRegular) + parseFloat(cSuper)).toFixed(2);
                                        setBase(totalB);
                                        setBaseDiesel(baseD);
                                        setIvaDiesel(ivaDie);
                                        setTIva(totI);
                                        setTComb(totCombus);
                                        setTotal(totCombus);
                                        setCdiesel(value);

                                        localStorage.setItem('base', totalB);
                                        localStorage.setItem('iva', totI);
                                        localStorage.setItem('total', totCombus)
                                    } else {
                                        setTIva(0.00);
                                        setBase(0.00);
                                        setTotal(0.00);
                                        setTComb(0.00);
                                    }
                                }}
                                msj="Total factura"
                            />
                        </div>
                        <div className='col-3'>
                            <div className="form-floating">
                                <Field
                                    name="idp"
                                    placeholder="Numero"
                                    className="form-control"
                                    component={renderCurrencyFloatA}
                                    disabled={true}
                                    dato={idp}
                                    detail={detalle}
                                    msj="IDP"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }
            {combustible === false &&
                <div className='row mb-2'>
                    <div className='col-4'>
                        <Field
                            name="bienes"
                            placeholder="Bienes"
                            className="form-control"
                            component={renderCurrencyFloat}
                            disabled={bloqueo}
                            onChange={(e, value) => {
                                if (iva) {
                                    if (value) {
                                        setEditarD(false);
                                        localStorage.setItem('LIQ', false);
                                        detalle = localStorage.getItem('LIQ');
                                        let baseB = parseFloat(value) / PIva;
                                        setBaseBienes(baseB);
                                        let ivaB = parseFloat(value) - parseFloat(baseB);
                                        setIvaBienes(ivaB);
                                        let TotalB = parseFloat(baseB) + parseFloat(baseServicios);
                                        let TotalIva = parseFloat(ivaB) + parseFloat(ivaServicios);
                                        let TotalF = parseFloat(value) + parseFloat(servicios) + parseFloat(exento);
                                        setBase(TotalB);
                                        setBienes(value);
                                        setTIva(TotalIva);
                                        setTotal(TotalF);
                                        localStorage.setItem('base', TotalB)
                                        localStorage.setItem('iva', TotalIva)
                                        localStorage.setItem('total', TotalF)
                                        if (dolares){
                                            let totQ = parseFloat(parseFloat(TotalF) * parseFloat(tipoCambioD)).toFixed(2)
                                            setTotalQ(totQ)
                                        }
                                    } else {
                                        setBase(0.00)
                                        setBienes(0.00);
                                        setTIva(0.00)

                                    }
                                }
                                if (iva === false) {

                                    if (value) {
                                        setEditarD(false);
                                        setTotal(0.00);
                                        setBase(0.00);
                                        setTIva(0.00);
                                        setBienes(0.00);
                                        let TotalFSI = parseFloat(value) + parseFloat(servicios) + parseFloat(exento);
                                        setBienes(TotalFSI);
                                        setBase(TotalFSI);
                                        setTIva(0.00);
                                        setTotal(TotalFSI);
                                        if (dolares){
                                            let totQ = parseFloat(parseFloat(TotalFSI) * parseFloat(tipoCambioD)).toFixed(2)
                                            setTotalQ(totQ)
                                        }
                                    } else {
                                        setBase(0.00)
                                        setBienes(0.00);
                                        setTIva(0.00)

                                    }
                                }
                            }}
                            msj="Bienes"
                        />
                    </div>
                    <div className='col-4'>
                        <Field
                            name="servicios"
                            placeholder="Servicios"
                            className="form-control"
                            component={renderCurrencyFloat}
                            disabled={bloqueo}
                            onChange={(e, value) => {
                                if (iva) {
                                    if (value) {
                                        setEditarD(false);
                                        localStorage.setItem('LIQ', false);
                                        detalle = localStorage.getItem('LIQ');
                                        let baseSe = parseFloat(value) / PIva;
                                        setBaseServicios(baseSe);
                                        let ivaS = parseFloat(value) - parseFloat(baseSe);
                                        setIvaServicios(ivaS);
                                        let TotalSe = parseFloat(baseSe) + parseFloat(baseBienes);
                                        let TotalIva = parseFloat(ivaS) + parseFloat(ivaBienes);
                                        let TotalF = parseFloat(value) + parseFloat(bienes) + parseFloat(exento);
                                        setServicios(value);
                                        setBase(TotalSe);
                                        setTIva(TotalIva);
                                        setTotal(TotalF);
                                        localStorage.setItem('base', TotalSe)
                                        localStorage.setItem('iva', TotalIva)
                                        localStorage.setItem('total', TotalF)
                                        if (dolares){
                                            let totQ = parseFloat(parseFloat(TotalF) * parseFloat(tipoCambioD)).toFixed(2)
                                            setTotalQ(totQ)
                                        }
                                    } else {
                                        setServicios(0.00);
                                        setBase(0.00)
                                        setTIva(0.00)
                                    }

                                }
                                if (iva === false) {
                                    if (value) {
                                        setEditarD(false);
                                        setTotal(0.00);
                                        setBase(0.00);
                                        setTIva(0.00);
                                        setServicios(0.00);
                                        let TotalFSI = parseFloat(value) + parseFloat(bienes) + parseFloat(exento);
                                        setServicios(TotalFSI);
                                        setBase(TotalFSI);
                                        setTIva(0.00);
                                        setTotal(TotalFSI);
                                        if (dolares){
                                            let totQ = parseFloat(parseFloat(TotalFSI) * parseFloat(tipoCambioD)).toFixed(2)
                                            setTotalQ(totQ)
                                        }
                                    } else {
                                        setServicios(0.00);
                                        setBase(0.00)
                                        setTIva(0.00)
                                    }
                                }
                            }}
                            msj="Servicios"
                        />
                    </div>
                    <div className='col-4'>
                        <Field
                            name="exento"
                            placeholder="No afecto"
                            className="form-control"
                            component={renderCurrencyFloat}
                            disabled={bloqueo}
                            onChange={(e, value) => {
                                if (value) {
                                    setEditarD(false);
                                    let exento = parseFloat(value) + parseFloat(servicios) + parseFloat(bienes)
                                    setExento(value)
                                    setTotal(exento);
                                    if (bienes === 0.00 && servicios === 0.00) {
                                        setBase(0.00);
                                        setTIva(0.00);
                                    }
                                    localStorage.setItem('total', exento)
                                    localStorage.setItem('dataD', base)
                                    if (dolares){
                                        let totQ = parseFloat(parseFloat(exento) * parseFloat(tipoCambioD)).toFixed(2)
                                        setTotalQ(totQ)
                                    }
                                } else {
                                    setEditarD(false);
                                    let TotalFSI = parseFloat(value) + parseFloat(bienes) + parseFloat(servicios);
                                    setExento(TotalFSI);
                                    setTIva(0.00);
                                    setTotal(TotalFSI);
                                    if (dolares){
                                        let totQ = parseFloat(parseFloat(TotalFSI) * parseFloat(tipoCambioD)).toFixed(2)
                                        setTotalQ(totQ)
                                    }
                                }
                            }}
                            msj="No afecto"
                        />
                    </div>
                </div>
            }
            {crear === false && editar === false &&
                <div className='row mb-2'>
                    <div className='col-4'>
                        <Field
                            name="base"
                            placeholder="Base"
                            className="form-control"
                            component={renderCurrencyFloat}
                            disabled={true}
                            msj="Base"
                        />
                    </div>
                    <div className='col-4'>
                        <Field
                            name="iva"
                            placeholder="Iva"
                            className="form-control"
                            component={renderCurrencyFloat}
                            disabled={true}
                            msj="Iva"
                        />
                    </div>
                    <div className='col-4'>
                        <Field
                            name="total"
                            placeholder="Total"
                            className="form-control"
                            component={renderCurrencyFloat}
                            disabled={true}
                            msj="Total"
                        />
                    </div>
                </div>
            }
            {(crear === true || editar == true) &&
                <div className='row mb-2'>
                    <div className='col-4'>
                        <div className="form-floating">
                            <NumberFormat
                                className={classNames('form-control')}
                                decimalScale={2}
                                disabled={true}
                                placeholder="Base"
                                fixedDecimalScale
                                thousandSeparator
                                value={editarD === true ? lectura_data.base : base}
                                name="base"
                            />
                            <label className='form-label' htmlFor="base">
                                Base
                            </label>
                        </div>
                    </div>
                    <div className='col-4'>
                        <div className="form-floating">
                            <NumberFormat
                                className={classNames('form-control')}
                                decimalScale={2}
                                disabled={true}
                                placeholder="Iva"
                                fixedDecimalScale
                                thousandSeparator
                                value={editarD === true ? lectura_data.iva : tIva}
                                name="iva"
                            />
                            <label className='form-label' htmlFor="iva">
                                Iva
                            </label>
                        </div>
                    </div>
                    <div className='col-4'>
                        <div className="form-floating">
                            <NumberFormat
                                className={classNames('form-control')}
                                decimalScale={2}
                                disabled={true}
                                placeholder="Total"
                                fixedDecimalScale
                                thousandSeparator
                                value={editarD === true ? lectura_data.total : total}
                                name="total"
                            />
                            <label className='form-label' htmlFor="total">
                                Total
                            </label>
                        </div>
                    </div>
                </div>
            }
            <div className='mb-2'>
                <Field
                    id="descripcion"
                    name="descripcion"
                    placeholder="Descripcion"
                    component={renderField}
                    className="form-control"
                    disabled={bloqueo}
                    msj="Descripcion"
                />
            </div>
            <Modal
                estado={estadoModal}
                cambiarEstado={cambiarEstadoModal}
                titulo="Crear proveedor"
                botonCerrar="true"
            >
                <div className="d-flex flex-column flex-1 mx-4">
                    <div className='mb-2 w-25'>
                        {(datos.validar_nit === 'S') &&
                            <Field
                                type="text"
                                autoFocus={autoFocus}
                                name="identificador"
                                placeholder="Identificador"
                                className="form-control"
                                component={renderField}
                                msj="Identificador"
                            />
                        }
                        {error &&

                            <div className='col-12'>
                                <div style={divError}>
                                    {error}
                                </div>
                            </div>

                        }
                        {(datos.validar_nit === 'N') &&
                            <Field
                                autoFocus={autoFocus}
                                name="identificador"
                                placeholder="Identificador"
                                className="form-control"
                                component={renderField}
                                msj="Identificador"
                            />
                        }
                    </div>
                    <div className='mb-2 w-50'>
                        <Field
                            name="nombre"
                            placeholder="Nombre"
                            className="form-control"
                            component={renderField}
                            msj="Nombre"
                        />
                    </div>
                    <div className='mb-2 w-75'>
                        <Field
                            name="direccion"
                            placeholder="Direccion"
                            className="form-control"
                            component={renderField}
                            msj="Dirección"
                        />
                    </div>
                    <div className='row'>
                        <div className='col-4'>
                            <Field
                                name="telefono"
                                placeholder="Telefono"
                                className="form-control"
                                component={renderField}
                                msj="Telefono"
                            />
                        </div>
                        <div className='col-4'>
                            <Field
                                name="correo"
                                placeholder="Correo electrónico"
                                className="form-control"
                                component={renderField}
                                msj="Correo electronico"
                            />
                        </div>
                        <div className='col-4'>
                            <Field
                                name="dias_credito"
                                placeholder="Dias credito"
                                className="form-control"
                                component={renderNumber}
                                msj="Dias crédito"
                            />
                        </div>
                    </div>
                    <div className='row mt-2'>
                        <div className='col-4'>
                            <Field
                                name="limite_credito"
                                placeholder="Limite credito"
                                className="form-control"
                                component={renderNumber}
                                msj="Limite crédito"
                            />
                        </div>
                        <div className='col-4'>
                            <div className="form-floating">
                                <Field
                                    name="tipo_proveedor"
                                    options={tipoProveedor}
                                    dato={crear === true ? 0 : lectura_data.tipo_proveedor}
                                    component={SelectField}
                                    className="form-select"
                                    msj="Tipo proveedor"
                                />
                            </div>
                        </div>
                        <div className='col-4'>
                            {obtenerPaises &&
                                <div className="form-floating">
                                    <Field
                                        name="pais"
                                        options={obtenerPaises}
                                        crear={crear}
                                        dato={crear === true ? 0 : lectura_data.pais}
                                        component={SelectField}
                                        className="form-select"
                                        msj="Pais"
                                    />
                                </div>
                            }
                        </div>
                    </div>
                    <div className='d-flex flex-row mt-3'>

                        <button
                            className='btn mr-2 mb-3 btn-primary'
                            onClick={() => registroProveedor(datos)}
                        >
                            Guardar
                        </button>

                        <button
                            className='btn mr-2 mb-3 btn-secondary'
                            onClick={() => cambiarEstadoModal(false)}
                        >
                            Salir
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

ComprasForm.propTypes = {
    listarProveedores: PropTypes.object,
    crear: PropTypes.bool,
    lectura: PropTypes.object,
    tipos: PropTypes.object,
    editar: PropTypes.bool,
    bloqueo: PropTypes.bool,
    listarProveedoresD: PropTypes.object,
    registroProveedor: PropTypes.func,
    fechaInicio: PropTypes.string,
    fechaFinal: PropTypes.string,
    datos: PropTypes.object,
    obtenerPaises: PropTypes.object,
    impuesto: PropTypes.object,
    tipoCambio: PropTypes.func,
    lectura_data: PropTypes.object
};

export default reduxForm({
    form: 'FormCompras',
})(ComprasForm);
