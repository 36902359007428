import React, { Component } from 'react';
import Formulario from './ParametrosForm';
import LoadMask from "../Utils/LoadMask/LoadMask";

class Parametros extends Component{

    componentDidMount() {
        const { getModulosParametros } = this.props;
        getModulosParametros();
    }

    render(){
        const { modificarParametro, leerParametro, loader, 
            leerParametroCompras, modificarParametroCompras,
            leerParametroFacturacion, modificarParametroFacturacion,
            cantidadMod, contaPr, compraPr, factPr } = this.props;
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    lectura = {cantidadMod}
                    leer = {leerParametro}
                    onSubmit = {modificarParametro}
                    compras = {leerParametroCompras}
                    funcionC = {modificarParametroCompras}
                    facturacion = {leerParametroFacturacion}
                    funcionF = {modificarParametroFacturacion}
                    conta = {contaPr}
                    compra = {compraPr}
                    factura = {factPr}
                />
            </LoadMask>
        );
    }
}

export default Parametros;
