import store from "../../../router";
import {
    today,
    getLocalTimeZone,
    parseDate,
    startOfMonth,
    endOfMonth,
    isEqualMonth,
} from "@internationalized/date";
import { useEmpresaSettings } from "../Utils/useEmpresaSettings";

export function getPeriodo() {
    const state = store.getState();
    return state.laboratorio && state.laboratorio.periodo;
}

export function getPeriodoId() {
    try {
        const periodo = getPeriodo();
        return periodo && periodo.value ? periodo.value : JSON.parse(sessionStorage.getItem("periodoLaboratorio")).value;
    }
    catch (e) {
        return null;
    }
}

export function getPeriodoIso() {
    const periodo = getPeriodo();
    return periodo && periodo.iso ? periodo.iso : null;
}

export function getPeriodoDates() {
    const periodo = getPeriodoIso() ? parseDate(getPeriodoIso()) : null;
    const startOfMonthPeriodo = periodo
        ? startOfMonth(periodo)
        : today(getLocalTimeZone());
    const endOfMonthPeriodo = periodo
        ? endOfMonth(periodo)
        : today(getLocalTimeZone());
    return { startOfMonthPeriodo, endOfMonthPeriodo };
}

export function getSettings() {
    const state = store.getState();
    let settings = state.laboratorio && state.laboratorio.settings;
    let empresaSettings = useEmpresaSettings();
    return { ...settings, empresaSettings };
}
