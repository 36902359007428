import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
const valEmp = __CONFIG__.empresa;

const LISTADO_TITULAR = 'LISTADO_TITULAR';
const LOADER = 'LOADER';
const MODULO = 'MODULO';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params;
    let id = ''
    if (valEmp === 'fage'){
        id = id_emp[6];
        params = { id };
    }else{
        id = id_emp[5];
        params = { id };
    }
    dispatch(setLoader(true));
    api.get('/registro_marca/titular', params).then((response)=>{
        dispatch({ type: LISTADO_TITULAR, data: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar titular',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const registroTitular = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormTitularRM.values;
    let id_empresa = '';
    if (valEmp === 'fage'){
        id_empresa = id_emp[6]
    }else{
        id_empresa = id_emp[5]
    }
    const formData = {
        titular: data.titular,
        empresa: id_empresa
    }
    dispatch(setLoader(true));
    api.post('/registro_marca/titular/', formData).then(() => {
        NotificationManager.success(
        'Titular creado',
        'Exito',
        3000
    );
    let ruta = '';
    if (valEmp === 'fage'){
        ruta = `/lfg/registro_marca/${id_emp[6]}/titular`
        dispatch(push(ruta));
    }else{
        ruta = `/registro_marca/${id_emp[5]}/titular`
        dispatch(push(ruta));
    }
    }).catch((error) => {
    NotificationManager.error(
        error.msj,
        'Error',
        2000
    );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const modificarTitular = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const datos = getStore().form.FormTitularRM.values;
    const id = datos.id;
    const formData = {
        id: id,
        titular: datos.titular,
    }
    dispatch(setLoader(true));
    api.put(`/registro_marca/titular/${id}/`, formData).then(() => {
        NotificationManager.success(
            'Titular modificado correctamente',
            'Exito',
            3000
        );
        let ruta = '';
        if (valEmp === 'fage'){
            ruta = `/lfg/registro_marca/${id_emp[6]}/titular`
            dispatch(push(ruta));
        }else{
            ruta = `/registro_marca/${id_emp[5]}/titular`
            dispatch(push(ruta));
        }
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al modificar titular',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/registro_marca/titular/${id}`).then((response) => {
        dispatch(initializeForm('FormTitularRM', response ));
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar titular',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        if (error.status === 401){
            NotificationManager.error(
              error.body.msj,
              'Error',
              0
            );
        }
        if (error.status === 400){
            NotificationManager.error(
              'Ocurrió un error en buscar modulo',
              'Error',
              0
            );
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const actions = {
    listar,
    registroTitular,
    modificarTitular,
    leer,
    getModulo,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_TITULAR]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
};

export const initialState = {
    loader: false,
    modulo: [],
    data: [],
};

export default handleActions(reducers, initialState)
 