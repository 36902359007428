import { handleActions } from 'redux-actions';
import { NotificationManager } from "react-notifications";
import { api } from "api";

const LOADER = 'LOADER';
const MODULO = 'MODULO';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listarMonedas = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    api.get('/monedas/moneda_empresa', params).then((response) => {
        const moneda = [];
        response.forEach(item => {
            moneda.push({
                value: item.id,
                label: item.moneda + ' - ' + item.simbolo
            })
        })
        dispatch({ type: LISTADO_MONEDAS, monedas: moneda });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar monedas',
            'Error',
            3000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}


export const getModulo = (id, modulo) => (dispatch) => {
    const params = { id, modulo }
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response) => {
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const listarProyectos = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id, search}
    return api.get('/proyecto/listar_proyectos',params).then(data=>{
        const list_producto = [];
        if(data){
            data.forEach(item=>{
                list_producto.push({
                    value: item.id,
                    label: item.proyecto
                })
                
            })
        }
        return list_producto ;
    }).catch((error)=>{
        return [];
    })
} 

export const reporteDetalleGastosProyecto = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const data = getStore().form.FormInformesI.values;
    let fechaI = data.fechaInicial;
    let fechaF = data.fechaFinal;
    let proyecto_id = data.proyecto ? data.proyecto.value : '';
    let proyecto = data.proyecto ? data.proyecto.label : '';
    let reporte = 'detalle_gastos_por_proyecto';
    const params = { id, reporte, fechaI, fechaF, proyecto_id, proyecto };
    
    dispatch(setLoader(true));
    api.getPdf('/inmobiliaria_reportes', params).then((response) => {
        if (response.headers['content-type'] == 'application/pdf') {
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'detalle_gastos_por_proyecto.pdf';
            window.open(link, '_blank');
            return false;
        }
    }).catch((error) => {
        if (error.status === 404) {
            NotificationManager.error(
                "No se encontraron datos",
                'Error',
                2000
            );
        }
        if (error.status === 500) {
            NotificationManager.error(
                'Ocurrió un error al generar reporte',
                'Error',
                0
            );
        }

    }).finally(() => {
        dispatch(setLoader(false));
    });
}


export const actions = {
    listarMonedas,
    getModulo,
    listarProyectos,
    reporteDetalleGastosProyecto,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },

    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
};

export const initialState = {
    loader: false,
    monedas: [],
    modulo: []
};

export default handleActions(reducers, initialState)