import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { withRouter } from "react-router";
import LoadMask from "../../Utils/LoadMask/LoadMask";
import GenBtn from "../../components/GenBtn";
import InputsContainer from "../common/InputsContainer";
import { getEntry } from "./actions";
import { FicDatePicker, FicInput, FicCheckbox } from "../../Utils/FormInputComponents";
import Banner from "../common/Banner";
import { startOfMonth, endOfMonth, parseDate } from "@internationalized/date";
import useUpdateEffect from "../../../../hooks/useUpdateEffect";

const idName = "periodoId";

const schema = yup.object({
    nombre: yup.string().required("El nombre es requerido"),
});

function Ver({ history, ...props }) {
    const [isLoading, setIsLoading] = useState(false);
    const [fechaInicio, setFechaInicio] = useState(null);
    const [fechaFin, setFechaFin] = useState(null);
    const params = props.match.params;
    const entryId = params[idName];

    const { control, reset, handleSubmit, watch } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: async () => {
            setIsLoading(true);
            const formData = await getEntry(entryId);
            formData.estado = formData.estado === "A";
            setIsLoading(false);
            return formData;
        },
    });

    const onSubmit = (data) => {};

    const selectedDate = watch("fecha_periodo");

    useUpdateEffect(() => {
        if (selectedDate) {
            const date = parseDate(selectedDate);
            const firstDay = startOfMonth(date);
            const lastDay = endOfMonth(date);
            setFechaInicio(firstDay);
            setFechaFin(lastDay);
        }
    }, [selectedDate]);

    return (
        <React.Fragment>
            <Banner>Editar</Banner>
            <GenBtn
                onPress={() => history.goBack()}
                style={{ margin: "5px 0" }}
            >
                Regresar
            </GenBtn>
            <LoadMask loading={isLoading} color="#bd0034" blur={true}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <InputsContainer>
                        <FicInput
                            control={control}
                            label="Mes y año"
                            name="periodo"
                            granularity="month"
                            isDisabled
                        />
                        <FicInput
                            label="Fecha de inicio"
                            name="fecha_inicio"
                            value={fechaInicio}
                            control={control}
                            noBtn
                            isDisabled
                            />
                        <FicInput
                            control={control}
                            label="Fecha final"
                            name="fecha_fin"
                            value={fechaFin}
                            noBtn
                            isDisabled
                            />
                        <FicCheckbox
                            control={control}
                            label="Activo"
                            name="estado"
                            isDisabled
                        />
                    </InputsContainer>
                    <GenBtn style={{ margin: "1rem 0" }} type="submit">
                        Guardar
                    </GenBtn>
                </form>
            </LoadMask>
        </React.Fragment>
    );
}

export default withRouter(Ver);
