import React from 'react';
import * as FaIcons from "react-icons/fa";
import * as BsIcons from "react-icons/bs";
import * as RiIcons from "react-icons/ri";

export const SidebarData = [
    {
        title: 'Inicio',
        label: 'inicio',
        path: '/compras',
        icon: <BsIcons.BsGrid1X2Fill />,
    },
    {
        title: 'Parámetros',
        label: 'parametros',
        path: '#',
        icon: <FaIcons.FaTasks />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav: [
            {
                title: 'Periodos',
                path: '/compras',
                path_s: 'periodos',
                icon: <BsIcons.BsFillCalendarDateFill />,
            },
            {
                title: 'Tipo de Documento',
                path: '/compras',
                path_s: 'tipos',
                icon: <FaIcons.FaList />,
            },
            {
                title: 'Tipo de Cambio',
                path: '/compras',
                path_s: 'tipo_cambio',
                icon: <BsIcons.BsCoin />,
            },
        ]
    },
    {
        title: 'Proveedores',
        label: 'proveedores',
        path: '/compras/proveedores',
        icon: <FaIcons.FaBuilding />,
    },
    {
        title: 'Saldos iniciales',
        label: 'saldos_iniciales',
        path: '/compras/saldos',
        icon: <FaIcons.FaBook  />,
    },
    {
        title: 'Agregar proyecto',
        label: 'agregar_proyecto',
        path: '/compras/buscar_factura',
        icon: <BsIcons.BsBricks />,
    },
    {
        title: 'Compras',
        label: 'compras',
        path: '/compras/seleccionar',
        icon: <BsIcons.BsCartCheckFill />,
    },
    {
        title: 'Abonos',
        label: 'abonos',
        path: '#',
        icon: <FaIcons.FaTasks />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav: [
            {
                title: 'Tipo abono',
                path: '/compras',
                path_s: 'tipo_abono',
                icon: <FaIcons.FaList />,
            },
            {
                title: 'Abonos',
                path: '/compras',
                path_s: 'abonos',
                icon: <FaIcons.FaTasks />,
            },
        ]
    },
    {
        title: 'Anticipos',
        label: 'anticipos',
        path: '/compras/anticipos',
        icon: <FaIcons.FaTasks />,
    },
    {
        title: 'Orden de Compra',
        label: 'orden_compra',
        path: '/compras/seleccionar_orden',
        icon: <FaIcons.FaClipboardCheck />,
    },
    {
        title: 'Informes',
        label: 'informes',
        path: '/compras/informes',
        icon: <FaIcons.FaClipboard />,
    }
]

