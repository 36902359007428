import React, { Component } from 'react';
import Formulario from './FormSeleccionarPais';
import { Redirect } from 'react-router-dom';
const valEmp = __CONFIG__.empresa;

class SeleccionarPais extends Component{

    componentDidMount = () => {
        const { paisList } = this.props;
        paisList();
    }
    render(){
        const { pais } = this.props;
        let ruta = window.location.href;
        let id_emp = ruta.split('/');
        return(
            pais.length >= 2 ? (
                <Formulario
                    pais={pais}
                />
            ) : (
                <React.Fragment>
                    {valEmp === 'fage' &&
                        pais.map((p) => 
                            <Redirect to={`/lfg/rr_hh/${id_emp[6]}/ingreso/${p.value}`} />
                        )
                    }
                    {valEmp === '' &&
                        pais.map((p) => 
                            <Redirect to={`/rr_hh/${id_emp[5]}/ingreso/${p.value}`} />
                        )
                    }
                </React.Fragment>
            )
        );
    } 
}

export default SeleccionarPais;