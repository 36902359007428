import { handleActions } from 'redux-actions';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';
const valEmp = __CONFIG__.empresa;

const LOADER = 'LOADER';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listarPaises = () => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let empresa = '';
    if (valEmp === 'fage'){
        empresa = id_emp[6];
    }else{
        empresa = id_emp[5];
    }
    return api.get(`/pais_colaborador/pais_empresa/?id=${empresa}`).then((data)=>{
        const pais = [];
        data.results.forEach(item=>{
            pais.push({
                value: item.id,
                label: item.pais
            })
        })
        return pais;
    }).catch(error=>{
        NotificationManager.error(
            'Ocurrió un error al listar paises',
            'Error',
            0
        );
    })
}

export const generarCorte = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const datos = getStore().form.FormCorteVacaciones.values;
    
    let id_empresa = '';
    if (valEmp === 'fage') {
        id_empresa = id_emp[6]
    } else {
        id_empresa = id_emp[5]
    }
    if (datos.pais === undefined || datos.pais === null) {
        Swal.fire({
            title: 'Corte de vacaciones',
            text: 'Debe de seleccionar pais',
            type: 'error',
        })
    } else {
        const formData = {
            pais: datos.pais.value,
            fecha: datos.fechaCorte,
            empresa: id_empresa
        }
        dispatch(setLoader(true));
        api.post('/rrhh_vacaciones/corte_vacaciones/', formData).then(() => {

            NotificationManager.success(
                'Corte de vacaciones generado',
                'Exito',
                2000
            );
        }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                2000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
}


export const actions = {
    listarPaises,
    generarCorte,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    
};

export const initialState = {
    loader: false,
    modulo: [],
};

export default handleActions(reducers, initialState)
