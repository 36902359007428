import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/produccion/factura';
import Factura from './Factura';


const ms2p = (state) => {
  return {
    ...state.factura_prd,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Factura);
