import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/nomina/periodo';
import Periodo from './Periodo';

const ms2p = (state) => {
  return {
    ...state.periodo_nomina,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Periodo);