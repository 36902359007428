import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/facturacion/tipoAbono';
import Tipos from './TipoAbonoList';


const ms2p = (state) => {
  return {
    ...state.tipo_abono_fac,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Tipos);
