import * as yup from "yup";
import { today, getLocalTimeZone } from "@internationalized/date";
import { getSettings } from "../../utils";
import { validar } from "../../../components/ValidarNit";

export function getSchema() {
    const settings = getSettings();
    const schema = yup.object({
        nombre: yup.string().required("El nombre es requerido"),
        dpi: yup
            .number()
            .typeError("Debe de ser un numero (sin guiones)")
            .test("dpi-validation", "El dpi no es valido", function (value) {
                return settings.empresaSettings.validar_dpi === "S"
                    ? validar.valCui(value.toString())[0].validar
                    : true;
            })
            .notRequired("El numero de dpi es requerido"),
    });
    return schema;
}

export function getDefaultValues() {
    return {
        nombre: "",
        dpi: "",
        afiliacion: "",
    };
}
