import { handleActions } from 'redux-actions';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';

const LISTADO_PERIODOS = 'LISTADO_PERIODOS';
const LOADER = 'LOADER';
const MODULO = 'MODULO';
const DETALLE_POLIZA = 'DETALLE_POLIZA';

const setLoader = loader => ({
    type: LOADER,
    loader,
});


export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const listarPeriodos = (year) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5]
    let params = { id, year };
    dispatch(setLoader(true));
    api.get('/periodos/periodo_empresa_year', params).then((response)=>{
        let periodo = [];
        response.forEach(item=>{
            periodo.push({
                value: item.id,
                label: item.periodo
            })
        })
        dispatch({ type: LISTADO_PERIODOS, listaPeriodo: periodo });
    }).catch(()=>{
        NotificationManager.error(
            'Ocurrió un error al listar los periodos',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const generarPoliza = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id_empresa = id_emp[5]
    
    const data = getStore().form.FormGenerarPolizaVM.values;
    if (data === undefined){
        Swal.fire({
            title: 'Genera póliza',
            text: 'Debe de ingresar el año',
            type: 'error',
        })
    }else{
        if (data.periodo === undefined || data.periodo === null){
            Swal.fire({
                title: 'Genera póliza',
                text: 'Debe de seleccionar el periodo',
                type: 'error',
            })
        }else{
            const formData = {
                year: data.anio,
                periodo: data.periodo,
                empresa: id_empresa,
            }
            dispatch(setLoader(true));
            api.get('/visitador_medico/liquidacion_detalle/generar_poliza', formData).then((response)=>{
                console.log(response)
                dispatch({ type: DETALLE_POLIZA, detallePoliza: response });
                
                NotificationManager.success(
                    'Póliza generada correctamente',
                    'Éxito',
                    3000
                );
            
            }).catch(()=>{
                NotificationManager.error(
                    'Ocurrió un error al generar póliza',
                    'Error',
                    0
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }
    }
}

const limpiarBusqueda = () => (dispatch) => {
    dispatch({ type: LISTADO_PERIODOS, listaPeriodo: [] });
    dispatch({ type: DETALLE_POLIZA, detallePoliza: [] });
};

export const actions = {
    getModulo,
    listarPeriodos,
    generarPoliza,
    limpiarBusqueda
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [LISTADO_PERIODOS]: (state, { listaPeriodo }) => {
        return {
            ...state,
            listaPeriodo,
        };
    },
    [DETALLE_POLIZA]: (state, { detallePoliza }) => {
        return {
            ...state,
            detallePoliza,
        };
    },
};

export const initialState = {
    loader: false,
    modulo: [],
    listaPeriodo: [],
    detallePoliza: [],
};

export default handleActions(reducers, initialState)