import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/recursos_humanos/liquidacion';
import Liquidacion from './LiquidacionList';


const ms2p = (state) => {
  return {
    ...state.liquidacion_rh,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Liquidacion);