import React from 'react';
import { Helmet } from 'react-helmet';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import { reduxForm } from 'redux-form/immutable';
import NavbarModulo from '../components/NavbarModulo';
import PropTypes from 'prop-types';

function RecursosHumanosHome(props) {
    const {
        modulo,
    } = props;

    const description = 'Recursos Humanos';
    let titulo = ''

    return (
        <div>
            <NavbarModulo
                modulo={modulo}
                titulo={titulo}
            />
            <Helmet>
                <title>{description}</title>
            </Helmet>
            <Toolbar >
                <div style={{ color: "white" }}>
                    <Typography variant="h3">
                        {description}
                    </Typography>
                </div>
            </Toolbar>
        </div>
    );
}

RecursosHumanosHome.propTypes = {
    modulo: PropTypes.object
};

export default reduxForm({
    form: 'FormRecursosHumanosHome',

})(RecursosHumanosHome);
