import React, { useEffect, useRef, useState } from "react";
import DataTableWSearch from "../../components/DataTableWSearch";
import GenBtn from "../../components/GenBtn";
import * as Fa from "react-icons/fa";
import { withRouter } from "react-router-dom";
import LoadMask from "../../Utils/LoadMask/LoadMask";

function VentasList(props) {
    const { listarVentas, reporteVentaDeVehiculos, history } = props;
    const [loadingState, setLoadingState] = useState(true);
    const [data, setData] = useState([]);

    async function fetchData() {
        setData(await listarVentas());
        setLoadingState(false);
    }

    useEffect(() => {
        fetchData();
    }, []);

    const columns = [
        {
            name: "Fecha de venta",
            selector: (row) => {
                let date = new Date(row.fecha_venta);
                const userLocale = navigator.language || navigator.userLanguage;
                const formattedDate = new Intl.DateTimeFormat(
                    userLocale
                ).format(date);
                return formattedDate;
            },
            sortable: true,
        },
        {
            name: "Vendedor",
            selector: (row) => row.vendedor,
            sortable: true,
        },
        {
            name: "Carro",
            selector: (row) => row.carro,
            sortable: true,
        },
        {
            name: "Comprador",
            selector: (row) => row.comprador_nombre,
            sortable: true,
        },
        {
            cell: (row) => (
                <GenBtn
                    title="Generar finiquito"
                    textColor="hsl(3, 95%, 36%)"
                    background="hsl(3, 95%, 36%, 0.2)"
                    onPress={(e) => {
                        reporteVentaDeVehiculos(row.id);
                    }}
                >
                    <Fa.FaFilePdf />
                </GenBtn>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            cell: (row) => (
                <GenBtn
                    title="Editar venta"
                    textColor="hsl(0, 0%, 80%)"
                    background="hsla(0, 100%, 100%, 0.2)"
                    onPress={(e) => {
                        history.push(
                            `${history.location.pathname}/editar/${row.id}`
                        );
                    }}
                >
                    <Fa.FaPencilAlt />
                </GenBtn>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            cell: (row) => (
                <GenBtn
                    title="Ver detalles"
                    textColor="hsl(50, 50%, 80%)"
                    background="hsla(50, 50%, 80%, 0.2)"
                    onPress={(e) => {
                        history.push(
                            `${history.location.pathname}/ver/${row.id}`
                        );
                    }}
                >
                    <Fa.FaEye />
                </GenBtn>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    return (
        <React.Fragment>
            <br />
            <h3
                className="p-2  title rounded shadow"
                style={{
                    background:
                        "linear-gradient(to top right, #790021, #2d2d2d)",
                }}
            >
                Ventas
            </h3>
            <br />
            <div className="d-flex flex-row justify-content-start mb-2">
                <GenBtn
                    // BtnColor="#00cb03"
                    style={{ width: "150px", height: "40px" }}
                    onPress={() => {
                        history.push(`${history.location.pathname}/crear`);
                    }}
                >
                    Crear venta
                </GenBtn>
            </div>
            <LoadMask loading={loadingState} color="#bd0034" blur={true}>
                <DataTableWSearch
                    columns={columns}
                    data={data}
                    filterPlaceHolder="Buscar por vendedor"
                />
            </LoadMask>
        </React.Fragment>
    );
}

export default withRouter(VentasList);
