import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/recursos_humanos/aumentoSalario';
import Aumento from './AumentoSalario';


const ms2p = (state) => {
  return {
    ...state.aumento_salario_rh,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Aumento);