import React, { Component } from 'react';
import Formulario from './GastoForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";

class Gastos extends Component{
    state = {
        crear: true,
    }

    componentDidMount = () => {
        const { match, leer, getModulo,leerPeriodoD, 
        vaciar } = this.props;
        const id = match.params.id;
        if (id){
            this.setState({crear: false});
            leer(id);
        }
        let ruta = window.location.href;
        let periodo = ruta.split('/');
        leerPeriodoD(periodo[5], periodo[7]);
        getModulo(periodo[5], periodo[4]);
        vaciar();
        
        localStorage.removeItem('id_detalle_gasto_indirecto');
    }
    
    render(){
        const { loader, modulo, periodoD, registroGasto, detalleS,
            detallePro, agregarLinea, modificarGasto, eliminarLinea, listarGastos,
            listarFases, nuevaLineaGasto, eliminarLineaGasto, lectura } = this.props;
        const { crear } = this.state;
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    crear = {crear}
                    modulo = {modulo}
                    periodoD = {periodoD}
                    detalleCon = {detallePro}
                    registroGasto = {registroGasto}
                    modificarGasto = {modificarGasto}
                    agregarLinea = {agregarLinea}
                    eliminarLinea = {eliminarLinea}
                    listarFases = {listarFases}
                    detalleS = {detalleS}
                    nuevaLineaGasto = {nuevaLineaGasto}
                    eliminarLineaGasto = {eliminarLineaGasto}
                    listarGastos = {listarGastos}
                    lectura = {lectura}
                />
            </LoadMask>
        );
    }
}

export default Gastos
