import React from "react";
import { api } from "api";
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import * as BsIcons from "react-icons/bs";
import * as IoIcons from "react-icons/io";
import * as AiIcons from "react-icons/ai";
import * as GiIcons from "react-icons/gi";

export const getPermisos = () => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let empresa = id_emp[5];
    const params = { empresa };
    return api
        .get(`laboratorio/permisos`, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return [];
        });
};

async function filterByPermisos(links) {
    let data = await getPermisos();
    let permisosArray = data.permisos.map((item) => item.nombre);

    function linksFilterFunction(links) {
        let filteredLinks = [];
        links.forEach(async (obj) => {
            if (obj.required_permission) {
                if (permisosArray.includes(obj.required_permission)) {
                    filteredLinks.push(obj);
                }
            } else if (obj.subNav) {
                let filteredSubNav = linksFilterFunction(obj.subNav);
                let newObj = { ...obj };
                if (filteredSubNav.length > 0) {
                    newObj.subNav = filteredSubNav;
                    filteredLinks.push(newObj);
                }
            } else {
                filteredLinks.push(obj);
            }
        });
        return filteredLinks;
    }

    return linksFilterFunction(links);
}

const baseurl = "/laboratorio";
const SidebarDataLaboratorio = [
    {
        title: "Inicio",
        path: baseurl,
        icon: <BsIcons.BsGrid1X2Fill />,
    },
    {
        title: "Catalogos",
        path: "#",
        icon: <AiIcons.AiFillBook />,
        subNav: [
            {
                title: "Ajustes",
                path: baseurl,
                path_s: "ajustes",
                icon: <FaIcons.FaWrench />,
                required_permission: "ajustes",
            },
            {
                title: "Permisos",
                path: baseurl,
                path_s: "permisos",
                icon: <FaIcons.FaIdBadge />,
                required_permission: "permisos",
            },
            {
                title: "Series",
                path: baseurl,
                path_s: "series",
                icon: <FaIcons.FaCircle />,
                required_permission: "series",
            },
            {
                title: "Clientes",
                path: baseurl,
                path_s: "clientes",
                icon: <FaIcons.FaCircle />,
                required_permission: "clientes",
            },
            {
                title: "Pacientes",
                path: baseurl,
                path_s: "pacientes",
                icon: <FaIcons.FaCircle />,
                required_permission: "pacientes",
            },
            {
                title: "Unidad IGSS",
                path: baseurl,
                path_s: "unidad-igss",
                icon: <FaIcons.FaCircle />,
                required_permission: "unidad_igss",
            },
            {
                title: "Periodos",
                path: baseurl,
                path_s: "periodos",
                icon: <FaIcons.FaCircle />,
                required_permission: "periodos",
            },
        ],
    },
    {
        title: "Patologias",
        path: "#",
        icon: <FaIcons.FaMicroscope />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav: [
            {
                title: "Ingreso de patologia",
                path: baseurl,
                path_s: "ingreso-de-patologia",
                icon: <FaIcons.FaCircle />,
                required_permission: "ingreso_patologias",
            },
            {
                title: "Laminas",
                path: baseurl,
                path_s: "laminas",
                icon: <FaIcons.FaImage />,
                required_permission: "laminas",
            },
            {
                title: "Informes Patologicos",
                path: baseurl,
                path_s: "informes-patologicos",
                icon: <FaIcons.FaClipboard />,
                required_permission: "informes",
            },
            // {
            //     title: "Entrega de laminas",
            //     path: baseurl,
            //     path_s: "entrega-laminas",
            //     icon: <FaIcons.FaImage />,
            // },
        ],
    },
    {
        title: "Abonos",
        path: "#",
        icon: <FaIcons.FaCoins />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav: [
            {
                title: "Tipo de abonos",
                path: baseurl,
                path_s: "tipo-abono",
                icon: <FaIcons.FaCircle />,
                required_permission: "tipos_abono",
            },
            {
                title: "Abonos",
                path: baseurl,
                path_s: "abonos",
                icon: <FaIcons.FaCircle />,
                required_permission: "abonos",
            },
        ],
    },
    {
        title: "Informes",
        path: baseurl + "/informes",
        icon: <FaIcons.FaClipboard />,
        required_permission: "informes",
    },
];

export default function getSidebarDataLaboratorio() {
    return filterByPermisos(SidebarDataLaboratorio);
}
