import React, { Component } from 'react';
import Grid from '../Utils/GridPoliza';
import { standardActions } from '../Utils/Grid/StandardActions';
const valEmp = __CONFIG__.empresa;

const filas = [];
let codEmp = "";
class EstablecimientoList extends Component{
    componentDidMount = () => {
        const { listar } = this.props;
        listar();
    }
    render(){
        const { empresa, loader } = this.props;
        filas.length=0;
        if (empresa.results){
            empresa.results.forEach(emp=>{
                codEmp = emp.id
                emp.empresa_sucursal.forEach(es=>{
                  filas.push({
                    id : es.id,
                    codigo : es.codigo,
                    nombre : es.nombre_comercial,
                    direccion: es.direccion,
                    razonS : emp.razon_social,
                    idEmp: codEmp
                  })
                })
            })
        }
        let ruta = window.location.href;
        let id_emp = ruta.split('/');
        return(
            <React.Fragment>
                <br />
                <h3 className="p-2 bg-secondary title">Establecimientos</h3>
                <br />
                {valEmp === 'fage' &&
                    <div className="d-flex flex-row justify-content-start mb-2">
                        <a
                            href={`/#/lfg/ajustes/${id_emp[6]}/establecimientos/crear`}
                            className='btn btn-primary'
                        >
                            Crear Establecimiento
                        </a>
                        <a
                            href={`/#/lfg/ajustes/${id_emp[6]}`}
                            className='btn btn-secondary ml-2'
                        >
                            Regresar
                        </a>
                    </div>
                }
                {valEmp === '' &&
                    <div className="d-flex flex-row justify-content-start mb-2">
                        <a
                            href={`/#/ajustes/${id_emp[5]}/establecimientos/crear`}
                            className='btn btn-primary'
                        >
                            Crear Establecimiento
                        </a>
                        <a
                            href={`/#/ajustes/${id_emp[5]}`}
                            className='btn btn-secondary ml-2'
                        >
                            Regresar
                        </a>
                    </div>
                }
                {empresa &&
                    <Grid 
                        hover 
                        striped 
                        data={filas} 
                        loading={loader} 
                        headerStyle={ { background: 'black' } } 
                        //onPageChange={listar} 
                        //onSortChange={onSortChange} 
                    >
                        <TableHeaderColumn
                            width="100"
                            dataField="codigo"
                            dataSort
                            thStyle={ 
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Codigo
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="razonS"
                            width="300"
                            dataSort
                            thStyle={ 
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Razon Social
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="nombre"
                            width="300"
                            dataSort
                            thStyle={ 
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Nombre Comercial
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="direccion"
                            width="300"
                            dataSort
                            thStyle={ 
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Dirección
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            isKey
                            dataField="id"
                            thStyle={
                            {color: 'white'}}
                            width="100"
                            dataAlign="center"
                            dataSort
                            dataFormat={standardActions({ 
                                editar: "establecimientos", 
                                ver: "establecimientos",
                            })} 
                            
                        >
                            Acciones
                        </TableHeaderColumn>
                    </Grid>
                }
            </React.Fragment>
        );
    }
}

export default EstablecimientoList;
