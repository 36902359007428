import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import NavbarModulo from '../../components/NavbarModulo';
import styled from 'styled-components';
import DetalleRegistro from './DetalleRegistros';
import DetalleRegistroMod from './DetalleRegistrosMod';
import DetalleRegistroVer from './DetalleRegistrosVer';

import {
    SelectField,
    renderDayPickerB,
    renderField,
    renderTextArea,
    AsyncSelectField,
    renderNumber
} from "../../Utils/renderField/renderField";

function RegistroCoempaqueForm(props) {
    const { registroRS, modificarRS, crear, modulo, paisN, obtenerVias, 
        buscarProductos, obtenerProductos, obtenerFabricantes, 
        obtenerAcondicionadores, listarRegistrosSanitarios,
        lectura, obtenerPaises, obtenerLicencias, obtenerTipoRegistro,
        codigoPais, datoRegistro, listRegistroProducto, descripcionProducto, buscarFabricante,
        buscarAcondicionador, registroProducto, listaELZM, agregarLinea,
        detallePro, eliminarLinea, detalleRegistro, eliminarLineaRegistro,
        agregarLineaRegistro } = props;
    
    const editar = window.location.href.includes('editar');
    let titulo = editar ? 'Modificar coempaque' : 'Crear coempaque';
    let disabled = false;
    if (crear === false && editar === false) {
        disabled = true;
        titulo = 'Ver registro sanitario'
    }
    let autoFocus = true;
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    
    const condicion = [
        { label: "RECETA MEDICA", value: "RM" },
        { label: "VENTA LIBRE", value: "VL" },
    ];

    const lote = [
        { label: "A", value: "S" },
        { label: "N/A", value: "N" },
    ];

    const estados = [
        { label: "SI", value: "S" },
        { label: "NO", value: "N" },
    ];


    const [productoPais, setProductoPais] = useState('')
    
    const handleOnChangeProducto = (e) => {
        let producto = descripcionProducto(e.value);
        producto.then(d => {
            setProductoPais(d);
        });
        setProductoPais(producto);
    };

    const handleOnChangeFabricante = (e) => {
        datoRegistro(e.value);
    };
    const estilo = {
        overflow: "auto",
        height: "300px",
    };
    return (
        <div>
            <NavbarModulo
                pais = {paisN}
                modulo={modulo}
                titulo={titulo}
            />
            <div>
                <br />
                <div className="mb-4 card card-small text-white bg-dark mb-3">
                    <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                        <div className="d-flex flex-column flex-1 mx-4">
                            {codigoPais === 'GTM' ? (
                                <div className='mb-2 row'>
                                    <div className='col-12'>
                                        {crear === false && editar === false ? (
                                            <div>
                                                {obtenerProductos &&
                                                    <Field
                                                        autoFocus={autoFocus}
                                                        name="producto"
                                                        options={obtenerProductos}
                                                        crear={crear}
                                                        dato={crear === true ? 0 : lectura.producto}
                                                        component={SelectField}
                                                        className="form-select"
                                                        disabled={disabled}
                                                        msj="Producto"
                                                    />
                                                }
                                            </div>
                                        ) : (
                                            crear === true ? (
                                                <Field
                                                    autoFocus={autoFocus}
                                                    placeholder="Producto..."
                                                    name="producto"
                                                    loadOptions={buscarProductos}
                                                    component={AsyncSelectField}
                                                    disabled={disabled}
                                                    msj="Producto"
                                                />
                                            ) : (
                                                <div>
                                                    {obtenerProductos &&
                                                        <Field
                                                            autoFocus={autoFocus}
                                                            name="producto"
                                                            options={obtenerProductos}
                                                            crear={crear}
                                                            dato={crear === true ? 0 : lectura.producto}
                                                            component={SelectField}
                                                            className="form-select"
                                                            disabled={disabled}
                                                            msj="Producto"
                                                        />
                                                    }
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <div className='mb-2 row'>
                                        <div className='col-12'>
                                            {crear === false && editar === false ? (
                                                <div>
                                                    {obtenerProductos &&
                                                        <Field
                                                            autoFocus={autoFocus}
                                                            name="producto"
                                                            options={obtenerProductos}
                                                            crear={crear}
                                                            dato={crear === true ? 0 : lectura.producto}
                                                            component={SelectField}
                                                            className="form-select"
                                                            disabled={disabled}
                                                            msj="Producto"
                                                        />
                                                    }
                                                </div>
                                            ) : (
                                                crear === true ? (
                                                    <Field
                                                        autoFocus={autoFocus}
                                                        placeholder="Producto..."
                                                        name="producto"
                                                        loadOptions={buscarProductos}
                                                        component={AsyncSelectField}
                                                        onChange={(e, value) => {
                                                            handleOnChangeProducto(value)
                                                        }}
                                                        msj="Producto"
                                                    />
                                                ) : (
                                                    <div>
                                                        {obtenerProductos &&
                                                            <Field
                                                                autoFocus={autoFocus}
                                                                name="producto"
                                                                options={obtenerProductos}
                                                                crear={crear}
                                                                dato={crear === true ? 0 : lectura.producto}
                                                                component={SelectField}
                                                                className="form-select"
                                                                disabled={disabled}
                                                                msj="Producto"
                                                            />
                                                        }
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </div>
                                    {(crear === false && editar == false) ? (
                                        <div className='mb-2 row'>
                                            <div className='col-12'>
                                                <Field
                                                    name="descripcion_producto"
                                                    placeholder="Descripción producto"
                                                    className="form-control"
                                                    component={renderField}
                                                    disabled={true}
                                                    msj="Descripción producto"
                                                />    
                                            </div>
                                        </div>
                                    ) : (
                                        (crear === false && editar == true) ? (
                                            <div className='mb-2 row'>
                                                <div className='col-12'>
                                                    <Field
                                                        name="descripcion_producto"
                                                        placeholder="Descripción producto"
                                                        className="form-control"
                                                        component={renderField}
                                                        disabled={false}
                                                        msj="Descripción producto"
                                                    />    
                                                </div>
                                            </div>
                                        ) : (
                                            <div>
                                                <div className='mb-2 row'>
                                                    <div className='col-6'>
                                                        <Field
                                                            name="fabricante_producto"
                                                            placeholder="Fabricante (consulta registro Guatemala)..."
                                                            loadOptions={buscarFabricante}
                                                            component={AsyncSelectField}
                                                            onChange={(e, value) => {
                                                                handleOnChangeFabricante(value)
                                                            }}
                                                            msj="Fabricante (consulta registro Guatemala)"
                                                        />
                                                    </div>

                                                    <div className='col-6'>
                                                        {listRegistroProducto &&
                                                            <div className="form-floating">
                                                                <input
                                                                    placeholder="Cuenta"
                                                                    type="text"
                                                                    autocomplete="off"
                                                                    name="cuenta"
                                                                    className="form-control"
                                                                    value={listRegistroProducto}
                                                                    disabled={true}
                                                                />
                                                                <label className='form-label' htmlFor="registro">
                                                                    Registro coempaque Guatemala
                                                                </label>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                <div className='mb-2 row'>
                                                    <div className='col-12'>
                                                        <div className="form-floating">
                                                            <input
                                                                placeholder="Descripcion producto"
                                                                type="text"
                                                                autocomplete="off"
                                                                name="cuenta"
                                                                className="form-control"
                                                                value={productoPais}
                                                                disabled={false}
                                                                onChange={(e) => {
                                                                    setProductoPais(e.target.value);
                                                                }}
                                                                />
                                                                <label className='form-label' htmlFor="registro">
                                                                    Descripción producto
                                                                </label>
                                                            </div>
                                                        </div>
                                                </div>
                                            </div>
                                        )
                                    )}
                                </div>
                            )}
                            {codigoPais !== 'GTM' &&
                                <div className='mb-2 row'>
                                    <div className='col-6'>
                                        <div className="form-floating">
                                            <input
                                                placeholder="Cuenta"
                                                type="text"
                                                autocomplete="off"
                                                name="cuenta"
                                                className="form-control"
                                                value={registroProducto}
                                                disabled={true}
                                            />
                                            <label className='form-label' htmlFor="registro">
                                                Registro coempaque Guatemala
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className='mb-2 row'>
                                <div className='col-6'>
                                    {crear === false && editar === false ? (
                                        <div>
                                            {obtenerFabricantes &&
                                                <Field
                                                    name="fabricante"
                                                    options={obtenerFabricantes}
                                                    crear={crear}
                                                    dato={crear === true ? 0 : lectura.fabricante}
                                                    component={SelectField}
                                                    className="form-select"
                                                    disabled={disabled}
                                                    msj="Fabricante"
                                                />
                                            }
                                        </div>
                                    ) : (
                                        crear === true ? (
                                            <Field
                                                name="fabricante"
                                                placeholder="Fabricante..."
                                                loadOptions={buscarFabricante}
                                                component={AsyncSelectField}
                                                disabled={disabled}
                                                msj="Fabricante"
                                            />
                                        ) : (
                                            <div>
                                                {obtenerFabricantes &&
                                                    <Field
                                                        name="fabricante"
                                                        options={obtenerFabricantes}
                                                        crear={crear}
                                                        dato={crear === true ? 0 : lectura.fabricante}
                                                        component={SelectField}
                                                        className="form-select"
                                                        disabled={disabled}
                                                        msj="Fabricante"
                                                    />
                                                }
                                            </div>
                                        )
                                    )}
                                </div>
                                {obtenerPaises &&
                                    <div className='col-6'>
                                        {obtenerPaises &&
                                            <Field
                                                name="pais_fabricacion"
                                                options={obtenerPaises}
                                                crear={crear}
                                                dato={crear === true ? 0 : lectura.pais_fabricante}
                                                component={SelectField}
                                                className="form-select"
                                                disabled={disabled}
                                                msj="Pais fabricación"
                                            />
                                        }
                                    </div>
                                }
                            </div>
                            <div className='mb-2 row'>
                                <div className='col-6'>
                                    {crear === false && editar === false ? (
                                        <div>
                                            {obtenerAcondicionadores &&
                                                <Field
                                                    name="acondicionador"
                                                    options={obtenerAcondicionadores}
                                                    crear={crear}
                                                    dato={crear === true ? 0 : lectura.acondicionador}
                                                    component={SelectField}
                                                    className="form-select"
                                                    disabled={disabled}
                                                    msj="Acondicionador"
                                                />
                                            }   
                                        </div>                                                       
                                    ) : (
                                        crear === true ? (
                                            <Field
                                                name="acondicionador"
                                                placeholder="Acondicionador..."
                                                loadOptions={buscarAcondicionador}
                                                component={AsyncSelectField}
                                                disabled={disabled}
                                                msj="Acondicionador"
                                            />
                                        ) : (
                                            <div>
                                                {obtenerAcondicionadores &&
                                                    <Field
                                                        name="acondicionador"
                                                        options={obtenerAcondicionadores}
                                                        crear={crear}
                                                        dato={crear === true ? 0 : lectura.acondicionador}
                                                        component={SelectField}
                                                        className="form-select"
                                                        disabled={disabled}
                                                        msj="Acondicionador"
                                                    />
                                                }
                                            </div>
                                        )
                                    )}
                                </div>
                                <div className='col-6'>
                                    <Field
                                        name="titular"
                                        placeholder="Titular"
                                        className="form-control"
                                        component={renderField}
                                        disabled={disabled}
                                        msj="Titular del registro"
                                    />
                                </div>
                            </div>
                            <div className='mb-2 row'>
                                <div className='col-3'>
                                    <Field
                                        name="fecha_vencimiento"
                                        component={renderDayPickerB}
                                        msj="Fecha vencimiento"  
                                        disabled={disabled}
                                    />
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="fecha_autorizacion"
                                        component={renderDayPickerB}
                                        msj="Fecha autorización"  
                                        disabled={disabled}
                                    />
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="numero_autorizacion"
                                        placeholder="Registros sanitarios"
                                        className="form-control"
                                        component={renderField}
                                        disabled={disabled}
                                        msj="Registros sanitarios"
                                    />
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="vida_util"
                                        placeholder="Vida útil coempaque (meses)"
                                        className="form-control"
                                        component={renderNumber}
                                        disabled={disabled}
                                        msj="Vida útil coempaque (meses)"
                                    />
                                </div>
                            </div>
                            
                            <div className='mb-2 row'>
                                <div className='col-6'>
                                    <Field
                                        name="condicion"
                                        options={condicion}
                                        crear={crear}
                                        dato={crear === true ? 0 : lectura.condicion_venta}
                                        component={SelectField}
                                        className="form-select"
                                        disabled={disabled}
                                        msj="Condición de venta"
                                    />
                                </div>
                                <div className='col-6'>
                                    <div className="form-floating">
                                        {obtenerPaises &&
                                            <Field
                                                name="licencia"
                                                options={obtenerLicencias}
                                                crear={crear}
                                                dato={crear === true ? 0 : lectura.licencia}
                                                component={SelectField}
                                                className="form-select"
                                                disabled={disabled}
                                                msj="Bajo licencia"
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='mb-2 row'>
                                <div className='col-6'>
                                    <div className="form-floating">
                                        {obtenerVias &&
                                            <Field
                                                name="via"
                                                options={obtenerVias}
                                                crear={crear}
                                                dato={crear === true ? 0 : lectura.via_administracion}
                                                component={SelectField}
                                                className="form-select"
                                                disabled={disabled}
                                                msj="Via administración"
                                            />
                                        }
                                    </div>
                                </div>
                                {obtenerTipoRegistro &&
                                    <div className='col-6'>
                                        <Field
                                            name="tipo_registro"
                                            options={obtenerTipoRegistro}
                                            crear={crear}
                                            dato={crear === true ? 0 : lectura.tipo_registro}
                                            component={SelectField}
                                            className="form-select"
                                            disabled={disabled}
                                            msj="Tipo registro"
                                        />
                                    </div>
                                }
                            </div>
                            <div className='mb-2 row'>
                                <div className='col-3'>
                                    <Field
                                        name="autorizacion"
                                        options={lote}
                                        crear={crear}
                                        dato={crear === true ? 0 : lectura.autorizacion}
                                        component={SelectField}
                                        className="form-select"
                                        disabled={disabled}
                                        msj="Autorización de primer lote"
                                    />
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="analisis"
                                        options={estados}
                                        crear={crear}
                                        dato={crear === true ? 0 : lectura.analisis}
                                        component={SelectField}
                                        className="form-select"
                                        disabled={disabled}
                                        msj="Ya se realizó o no él análisis de primer lote"
                                    />
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="producto_comercializado"
                                        options={estados}
                                        crear={crear}
                                        dato={crear === true ? 0 : lectura.producto_comercializado}
                                        component={SelectField}
                                        className="form-select"
                                        disabled={disabled}
                                        msj="Producto comercializado"
                                    />
                                </div>
                            </div>
                            <div className='mb-2 row'>
                                <div className='col-3'>
                                    <Field
                                        name="elzm"
                                        options={listaELZM}
                                        crear={crear}
                                        dato={crear === true ? 0 : lectura.elzm}
                                        component={SelectField}
                                        className="form-select"
                                        disabled={disabled}
                                        msj="ELZM"
                                    />
                                </div>
                                <div className='col-3'>
                                    <Field
                                        disabled={disabled}
                                        name="fecha"
                                        component={renderDayPickerB}
                                        msj="Fecha"
                                    />
                                </div>
                                <div className='col-6'>
                                    <Field
                                        name="control_estatal"
                                        placeholder="Control estatal"
                                        className="form-control"
                                        component={renderField}
                                        disabled={disabled}
                                        msj="Control estatal"
                                    />
                                </div>
                            </div>
                            <div className='mb-2 row'>
                                <div className='col-12'>
                                    <Field
                                        name="observacion"
                                        placeholder="Observación"
                                        className="form-control"
                                        component={renderField}
                                        disabled={disabled}
                                        msj="Observación"
                                    />
                                </div>
                            </div>
                            <div className='mb-2 row'>
                                <div className='col-12'>
                                    <Field
                                        name="presentacion"
                                        placeholder="Presentación"
                                        className="form-control"
                                        component={renderTextArea}
                                        disabled={disabled}
                                        msj="Presentación"
                                    />
                                </div>
                            </div>
                            {(crear === true || editar === true) &&
                                <div className='mb-2 row'>
                                    <h5 className="p-2 bg-secondary title ">Detalle registros sanitarios</h5>
                                    <div className='col-12'>
                                        <Field
                                            placeholder="Registro sanitario..."
                                            name="registro_sanitario"
                                            loadOptions={listarRegistrosSanitarios}
                                            component={AsyncSelectField}
                                            disabled={disabled}
                                            msj="Registro sanitario"
                                        />
                                    </div>
                                </div>
                            }
                            {(crear === true) &&
                                <Contenido>
                                    <button
                                        className='btn mr-2 mt-3 btn-primary'
                                        onClick={() => agregarLinea()}
                                    >
                                        Agregar linea
                                    </button>
                                </Contenido>
                            }
                            {(editar === true) &&
                                <Contenido>
                                    <button
                                        className='btn mr-2 mt-3 btn-primary'
                                        onClick={() => agregarLineaRegistro()}
                                    >
                                        Agregar linea
                                    </button>
                                </Contenido>
                            }
                            <br />
                            {crear === true &&
                                <div style={estilo}>
                                    <DetalleRegistro
                                        detalle={detallePro}
                                        eliminarLinea={eliminarLinea}
                                    />
                                </div>
                            }
                            {editar === true &&
                                <div style={estilo}>
                                    <DetalleRegistroMod
                                        detalle={detalleRegistro}
                                        eliminarLinea={eliminarLineaRegistro}
                                    />
                                </div>
                            }
                            {(crear === false && editar === false) &&
                                <div style={estilo}>
                                    <h5 className="p-2 bg-secondary title ">Detalle registros sanitarios</h5>
                                    <DetalleRegistroVer
                                        detalle={detalleRegistro}
                                    />
                                </div>
                            }
                            <div className='d-flex flex-row mt-3'>
                                {crear == true &&
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        type='submit'
                                        onClick={() => registroRS(productoPais, codigoPais)}
                                    >
                                        Guardar
                                    </button>
                                }
                                {editar == true &&
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        type='submit'
                                        onClick={() => modificarRS(codigoPais)}
                                    >
                                        Modificar
                                    </button>
                                }
                                <a
                                    href={`/#/registro_sanitario/${id_emp[5]}/registro_coempaque/${id_emp[7]}`}
                                    className='btn btn-secondary mb-3'
                                >
                                    Regresar
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

RegistroCoempaqueForm.propTypes = {
    handleSubmit: PropTypes.func,
    crear: PropTypes.bool,
    lectura: PropTypes.object,
    modulo: PropTypes.object,
};

const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;

export default reduxForm({
    form: 'FormRegistroCoempaque',
    
})(RegistroCoempaqueForm);
