import React, { Component } from 'react';
import Formulario from './FormSeleccionarCuenta';


class SeleccionarCuenta extends Component{

    componentDidMount = () => {
        const { listarCuentasBancarias } = this.props;
        listarCuentasBancarias();
    }
    render(){
        const { cuenta, leerCuentaB } = this.props;
        return(
           
            <Formulario
                cuenta={cuenta}
                leerCuentaB={leerCuentaB}
            />
           
        );
    } 
}

export default SeleccionarCuenta;