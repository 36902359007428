import React, { Component } from 'react';
import Formulario from './ListadoPreciosForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";

class ListadoPrecios extends Component{
    state = {
        crear: true,
    }

    componentDidMount = () => {
        const { match, leer, getModulo, leerDetalleProductos } = this.props;
        const id = match.params.id;
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        
        if (id){
            this.setState({crear: false});
            leer(id);
        }
        getModulo(modulo[5],modulo[4]);
        leerDetalleProductos('00000000-0000-0000-0000-000000000000');
        localStorage.removeItem('id_detalle_listado_precios');
    }
    
    render(){
        const { registroListado, modificarListado, loader, lectura, modulo,
            listarProductos, detalleProducto, eliminarLineaProducto } = this.props;
        const { crear } = this.state;
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    crear = {crear}
                    lectura = {lectura}
                    modulo = {modulo}
                    listarProductos = {listarProductos}
                    detalleProducto = {detalleProducto}
                    registroListado = {registroListado}
                    modificarListado = {modificarListado}
                    eliminarLineaProducto = {eliminarLineaProducto}
                />
            </LoadMask>
        );
    }
}

export default ListadoPrecios
