import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/inventario/cierreProyecto';
import CierreProyecto from './cierreProyectoForm';

const ms2p = (state) => {
  return {
    ...state.inv_cierre_proyecto,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(CierreProyecto);
