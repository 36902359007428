import React, { Component } from 'react';
import Grid from '../../Utils/GridFiltro';
import PropTypes from 'prop-types';
import { standardActions } from '../../Utils/Grid/StandardActions';
import moment from 'moment';
import NavbarModulo from '../../components/NavbarModulo';

class EnvioList extends Component{
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: true
    };

    componentDidMount = () => {
        const { listar, getModulo, leerPeriodoD } = this.props;
        let ruta = window.location.href;
        let periodo = ruta.split('/');
        leerPeriodoD(periodo[5], periodo[7]);
        getModulo(periodo[5],periodo[4]);
        listar();
    }
    render(){
        const { data, loader, imprimirEnvio, modulo, periodoD, anular, eliminarEnvio } = this.props;
        let ruta = window.location.href;
        let id_emp = ruta.split('/');
        return(
            <React.Fragment>
                <NavbarModulo
                    periodoD={periodoD}
                    modulo={modulo}
                />
                <br />
                <h3 className="p-2 bg-secondary title">Ingreso de envíos</h3>
                <br />
                {periodoD.estado == 'A' &&
                    <div className="d-flex flex-row justify-content-start mb-2">
                        <a
                            href={`/#/produccion/${id_emp[5]}/envio/${id_emp[7]}/crear`}
                            className='btn btn-primary'
                        >
                            Crear envío
                        </a>
                    </div>
                }
                {data && periodoD.estado == 'A' &&
                    <Grid 
                        hover 
                        striped 
                        data={data}
                        loading={loader} 
                        headerStyle={ { background: 'black' } } 
                        //onPageChange={onPageChange}
                        //onSortChange={onSortChange} 
                    >
                        <TableHeaderColumn
                            isKey
                            dataField="fecha"
                            dataFormat={(cell, row)=>{
                                return moment(cell).format("DD/MM/YYYY");
                            }}
                            width="150"
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Fecha
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="serie"
                            width="200"
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Serie
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="numero"
                            headerAlign="left"
                            dataAlign="right"
                            dataSort
                            width="200"
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Numero
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="clienteN"
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Cliente
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="observaciones"
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Observaciones
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="estatus"
                            width="150"
                            dataAlign="center"
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Estado
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            thStyle={
                            {color: 'white'}}
                            dataField="id"
                            width="150"
                            dataAlign="center"
                            dataSort
                            dataFormat={standardActions({ 
                                ver_a: "envio",
                                editar_e: "envio",
                                imprimir_e: imprimirEnvio,
                                eliminar_e: eliminarEnvio,
                                empresa: id_emp[5],
                                periodo: id_emp[7],
                                modulo: id_emp[4],
                            })}
                            
                        >
                            Acciones
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            thStyle={
                            {color: 'white'}}
                            dataField="id"
                            width="100"
                            dataAlign="center"
                            dataSort
                            dataFormat={standardActions({
                                anularEnvio: anular,
                            })} 
                        >
                            Anular
                        </TableHeaderColumn>
                    </Grid>
                }
                {data && periodoD.estado == 'C' &&
                    <Grid 
                        hover 
                        striped 
                        data={data}
                        loading={loader} 
                        headerStyle={ { background: 'black' } } 
                        //onPageChange={onPageChange}
                        //onSortChange={onSortChange} 
                    >
                        <TableHeaderColumn
                            isKey
                            dataField="fecha"
                            dataFormat={(cell, row)=>{
                                return moment(cell).format("DD/MM/YYYY");
                            }}
                            width="150"
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Fecha
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="serie"
                            width="200"
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Serie
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="numero"
                            headerAlign="left"
                            dataAlign="right"
                            dataSort
                            width="200"
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Numero
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="clienteN"
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Cliente
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="observaciones"
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Observaciones
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="estatus"
                            width="150"
                            dataAlign="center"
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Estado
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            thStyle={
                            {color: 'white'}}
                            dataField="id"
                            width="150"
                            dataAlign="center"
                            dataSort
                            dataFormat={standardActions({ 
                                ver_a: "envio",
                                imprimir_e: imprimirEnvio,
                                empresa: id_emp[5],
                                periodo: id_emp[7],
                                modulo: id_emp[4],
                            })}
                            
                        >
                            Acciones
                        </TableHeaderColumn>
                    </Grid>
                }
            </React.Fragment>
        );
    }
}

export default EnvioList;
