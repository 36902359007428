import React, { useState, useRef, useEffect } from "react";
import { NavLink } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { IconContext } from "react-icons";
import styled, { keyframes } from "styled-components";

function DropDownIcon() {
    return (
        <IconContext.Provider value={{ color: "#bd0034" }}>
            <FaIcons.FaAngleDown />
        </IconContext.Provider>
    );
}

const Navbar = styled.nav`
    display: flex;
    // overflow-x: scroll;
    // overflow-y: visible;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    // background: linear-gradient(to top, #000000, #121212);
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5);
    border-bottom: 1px solid hsla(343, 100%, 37%, 0.3);
    background: #121212;
    min-height: 3rem;
    min-width: 402px;
    padding: 0rem 4rem;
`;

const DropdownContainer = styled.div`
    position: relative;
    display: inline-block;
    white-space: nowrap;
`;

const DropdownButton = styled.button`
    background-color: transparent;
    color: white;
    font-size: 16px;
    border: none;
    cursor: pointer;
    align-self: center;
    transition: color 100ms ease-in;

    &:hover {
        text-decoration: none;
        color: #bd0034;
        transition: color 100ms ease-in;
    }
`;

const DropdownContent = styled.div`
    display: ${({ open }) => (open ? "block" : "none")};
    border-radius: 7px;
    position: absolute;
    background-color: black;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    overflow: hidden;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const DropdownItem = styled(NavLink)`
    color: white;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    animation: ${fadeIn} 0.3s ease-in;
    cursor: pointer;
    transition: color 100ms ease-in, background-color 100ms ease-in;
    text-decoration: none;
    white-space: nowrap;

    &:hover {
        text-decoration: none;
        color: #bd0034;
        background-color: #333;
        transition: color 100ms ease-in, background-color 100ms ease-in;
    }

    &.active {
        color: #bd0034;

        &:hover {
            background-color: hsla(0, 0%, 0%, 0);
        }
    }
`;

const IconWrapper = styled.span`
    margin-right: 3px;

    &:hover {
        color: #bd0034;
        transition: color 100ms ease-in;
    }
`;

const VerticalRectangle = styled.span`
    width: 0px;
    height: 100%;
    background-color: #bd0034;
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    transition: width 100ms ease-in;
`;

const DropdownItemWrapper = styled.div`
    position: relative;

    &:hover ${VerticalRectangle} {
        width: 6px;
        transition: width 50ms ease-in;
    }
`;

const NavBarSubModulo = ({ links }) => {
    const [openDropdown, setOpenDropdown] = useState(null);
    const dropdownRefs = useRef([]);

    const toggleDropdown = (index) => {
        setOpenDropdown((prevOpenDropdown) =>
            prevOpenDropdown === index ? null : index
        );
    };

    const handleClickOutside = (event) => {
        if (
            !dropdownRefs.current.some(
                (ref) => ref.current && ref.current.contains(event.target)
            )
        ) {
            setOpenDropdown(null);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () =>
            document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    const renderDropdown = (dropdown, index) => (
        <DropdownContent
            open={openDropdown === index}
            key={index}
            ref={dropdownRefs.current[index]}
        >
            {dropdown.map((item, subIndex) => (
                <DropdownItemWrapper key={subIndex}>
                    <VerticalRectangle />
                    <DropdownItem to={item.path} key={subIndex}>
                        {item.icon && <IconWrapper>{item.icon}</IconWrapper>}
                        {item.title}
                    </DropdownItem>
                </DropdownItemWrapper>
            ))}
        </DropdownContent>
    );

    const renderNavItem = (item, index) => {
        const href = item.path;
        if (item.dropdown) {
            const dropdownRef = useRef(null);
            dropdownRefs.current[index] = dropdownRef;
            return (
                <DropdownContainer key={index}>
                    {item.icon && <IconWrapper>{item.icon}</IconWrapper>}
                    <DropdownButton onClick={() => toggleDropdown(index)}>
                        {item.title}
                        &nbsp;
                        <IconWrapper>
                            <DropDownIcon />
                        </IconWrapper>
                        {/* <IconWrapper><FaIcons.FaCaretDown/></IconWrapper> */}
                    </DropdownButton>
                    {renderDropdown(item.dropdown, index)}
                </DropdownContainer>
            );
        } else {
            return (
                <DropdownItem
                    exact
                    to={href}
                    key={index}
                    activeClassName="active"
                >
                    {item.icon && <IconWrapper>{item.icon}</IconWrapper>}
                    {item.title}
                </DropdownItem>
            );
        }
    };

    return <Navbar>{links.map(renderNavItem)}</Navbar>;
};

export default NavBarSubModulo;
