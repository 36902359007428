import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
const valEmp = __CONFIG__.empresa;

const LOADER = 'LOADER';
const LISTADO_ESTABLECIMIENTO = 'LISTADO_ESTABLECIMIENTO';
const GUARDAR_ESTABLECIMIENTO = 'GUARDAR_ESTABLECIMIENTO';
const LISTADO_EMPRESAS = 'LISTADO_EMPRESAS';

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params;
    let id = ''
    if (valEmp === 'fage'){
        id = id_emp[6];
        params = { id };
    }else{
        id = id_emp[5];
        params = { id };
    }
    dispatch(setLoader(true));
    api.get('/empresa/empresa_sucursal/', params).then((response)=>{
        dispatch({ type: LISTADO_ESTABLECIMIENTO, empresa: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar las empresas',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/sucursal/${id}`).then((response) => {
        dispatch(initializeForm('FormEstablecimiento', response ));
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar el establecimiento',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const registroEstablecimiento = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormEstablecimiento.values;
    let idEmpresa = '';
    if (valEmp === 'fage'){
        idEmpresa = id_emp[6];
    }else{
        idEmpresa = id_emp[5];
    }
    const formData = {
        codigo: data.codigo,
        nombre_comercial: data.nombre_comercial,
        direccion: data.direccion,
        telefono: data.telefono,
        empresa: id_emp[5],
    }
    dispatch(setLoader(true));
    api.post('/sucursal/', formData).then(() => {
        NotificationManager.success(
            'Establecimiento creado',
            'Exito',
            3000
        );
        let ruta = '';
        if (valEmp === 'fage'){
            ruta = `/lfg/ajustes/${id_emp[6]}/establecimientos`
            dispatch(push(ruta));
        }
        if (valEmp === ''){
            ruta = `/ajustes/${id_emp[5]}/establecimientos`
            dispatch(push(ruta));
        }
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al registrar el establecimiento',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const modificarEstablecimiento = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const datos = getStore().form.FormEstablecimiento.values;
    const id = datos.id;
    const formData = {
        idE: id,
        codigo: datos.codigo,
        nombre_comercial: datos.nombre_comercial,
        direccion: datos.direccion,
        telefono: datos.telefono,
        empresa: datos.empresa,
    }
    dispatch(setLoader(true));
    api.put(`/sucursal/${id}/`, formData).then(() => {
        NotificationManager.success(
            'Establecimiento modificado correctamente',
            'Exito',
            3000
        );
        let ruta = '';
        if (valEmp === 'fage'){
            ruta = `/lfg/ajustes/${id_emp[6]}/establecimientos`
            dispatch(push(ruta));
        }
        if (valEmp === ''){
            ruta = `/ajustes/${id_emp[5]}/establecimientos`
            dispatch(push(ruta));
        }
    }).catch((error) => {
        console.log("error: ", error)
        NotificationManager.error(
            'Ocurrió un error al modificar el establecimiento',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const mostrarEmpresa = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id_Empresa = '';
    if (valEmp === 'fage'){
        id_Empresa = id_emp[6];
    }else{
        id_Empresa = id_emp[5];
    }
    api.get(`/empresa/${id_Empresa}`).then((response) => {
        dispatch(initializeForm('FormEstablecimiento', response ));
    }).catch(error=>{
        NotificationManager.error(
            'Ocurrió un error al listar las empresas',
            'Error',
            2000
        );
    })
}

export const actions = {
    registroEstablecimiento,
    modificarEstablecimiento,
    listar,
    leer,
    mostrarEmpresa,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_ESTABLECIMIENTO]: (state, { empresa }) => {
        return {
            ...state,
            empresa,
        };
    },
    [GUARDAR_ESTABLECIMIENTO]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
    [LISTADO_EMPRESAS]: (state, { listaE }) => {
        return {
            ...state,
            listaE,
        };
    },
};

export const initialState = {
    loader: false,
    empresa: [],
    lectura: [],
    listaE: [],
};

export default handleActions(reducers, initialState)