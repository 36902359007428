import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/inventario/consumoProducto';
import ConsumoProductoList from './ConsumoProductoList';

const ms2p = (state) => {
  return {
    ...state.inv_consumo_producto,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ConsumoProductoList);
