import React, { Component } from 'react';
import Grid from '../../Utils/GridFiltro';
import { standardActions } from '../../Utils/GridFiltro/StandardActions';
import NavbarModulo from '../../components/NavbarModulo';
import NavbarModuloLF from '../../components/NavbarModuloFage';

const valEmp = __CONFIG__.empresa;

class DescuentosList extends Component {

    componentDidMount = () => {
        const { listar, getModulo, leerPeriodoN } = this.props;
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        if (valEmp === 'fage') {
            getModulo(modulo[6], modulo[5])
            leerPeriodoN(modulo[6], modulo[8]);
        } else {
            getModulo(modulo[5], modulo[4]);
            leerPeriodoN(modulo[5], modulo[7]);
        }
        listar();
    }

    render() {
        const { loader, data, periodoD, modulo, eliminarDescuento, estado_periodo, datos_per } = this.props;
        let titulo = '';
        let ruta = window.location.href;
        let id_emp = ruta.split('/');
        return (
            <div>
                {valEmp === 'fage' &&
                    <NavbarModuloLF
                        periodoD={periodoD}
                        modulo={modulo}
                        titulo={titulo}
                    />
                }
                {valEmp === '' &&
                    <NavbarModulo
                        periodoD={periodoD}
                        modulo={modulo}
                        titulo={titulo}
                    />
                }
                <br />
                <h3 className="p-2 bg-secondary title">Descuentos / {datos_per}</h3>
                {valEmp === 'fage' &&
                    <div className="d-flex flex-row justify-content-start mb-2">
                        {estado_periodo != 'F' &&
                            <a
                                href={`/#/lfg/nomina/${id_emp[6]}/descuentos/${id_emp[8]}/crear`}
                                className='btn btn-primary'
                            >
                                Crear descuento
                            </a>
                        }
                        <a
                            href={`/#/lfg/nomina/${id_emp[6]}`}
                            className='btn btn-secondary ml-2'
                        >
                            Regresar
                        </a>
                    </div>
                }
                {valEmp === '' &&
                    <div className="d-flex flex-row justify-content-start mb-2">
                        {estado_periodo != 'F' &&
                            <a
                                href={`/#/nomina/${id_emp[5]}/descuentos/${id_emp[7]}/crear`}
                                className='btn btn-primary'
                            >
                                Crear hora extra
                            </a>
                        }
                        <a
                            href={`/#/nomina/${id_emp[5]}`}
                            className='btn btn-secondary ml-2'
                        >
                            Regresar
                        </a>
                    </div>
                }
                <br />
                {data &&
                    <Grid
                        hover
                        striped
                        data={data}
                        loading={loader}
                        headerStyle={{ background: 'black' }}
                    >
                        <TableHeaderColumn
                            isKey
                            dataField="codigo"
                            width="100"
                            dataAlign='left'
                            dataSort
                            thStyle={
                                { color: 'white' }}
                            tdStyle={
                                { color: 'white' }}
                        >
                            Codigo
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="nombre"
                            dataAlign='left'
                            width="450"
                            dataSort
                            thStyle={
                                { color: 'white' }}
                            tdStyle={
                                { color: 'white' }}
                        >
                            Nombre
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="tipoDescuento"
                            dataAlign='left'
                            width="200"
                            dataSort
                            thStyle={
                                { color: 'white' }}
                            tdStyle={
                                { color: 'white' }}
                        >
                            Tipo descuento
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            headerAlign="center"
                            dataAlign="right"
                            dataField="monto"
                            width="200"
                            dataSort
                            dataFormat={(cell, row) => {
                                return new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(Math.abs(cell));
                            }}
                            thStyle={
                                { color: 'white' }}
                            tdStyle={
                                { color: 'white' }}
                        >
                            Monto
                        </TableHeaderColumn>
                        {valEmp === 'fage' &&
                            <TableHeaderColumn
                                thStyle={
                                    { color: 'white' }}
                                dataField="id"
                                width="150"
                                dataAlign="center"
                                dataSort
                                dataFormat={standardActions({
                                    editar_nom_desc_lfg: "descuentos",
                                    ver_lfg: "descuentos",
                                    eliminarDescuento: eliminarDescuento,
                                    empresa: id_emp[6],
                                    periodo: id_emp[8],
                                    modulo: id_emp[5],
                                })}

                            >
                                Acciones
                            </TableHeaderColumn>
                        }
                        {valEmp === '' &&
                            <TableHeaderColumn
                                thStyle={
                                    { color: 'white' }}
                                dataField="id"
                                width="150"
                                dataAlign="center"
                                dataSort
                                dataFormat={standardActions({
                                    editar_nom_desc: "descuentos",
                                    ver_a: "descuentos",
                                    eliminarDescuento: eliminarDescuento,
                                    empresa: id_emp[5],
                                    periodo: id_emp[7],
                                    modulo: id_emp[4],
                                })}

                            >
                                Acciones
                            </TableHeaderColumn>
                        }
                    </Grid>
                }
            </div>
        );
    }
}

export default DescuentosList;
