import { NotificationManager } from "react-notifications";
import { api } from "api";
import OptionsBar from "../common/OptionsBar";
import { getPeriodoId } from "../utils";

const baseurl = "laboratorio/relacion_laminas";
const writeSuccessMsg = "Relacion creada";
const editSuccessMsg = "Relacion editada";

async function convertUrlsToFiles(items) {
    let files = [];
    for (let item of items) {
        let response = await fetch(item.lamina);
        let data = await response.blob();
        let metadata = { type: data.type };
        let fileName = item.lamina.substring(item.lamina.lastIndexOf("/") + 1);
        let file = new File([data], fileName, metadata);
        files.push(file);
    }
    return files;
}

export const getList = () => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let empresa = id_emp[5];
    const periodo = getPeriodoId();
    const params = { empresa, periodo };
    return api
        .get(baseurl, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return [];
        });
};

export const getEntry = (id) => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let empresa = id_emp[5];
    const params = { empresa };
    return api
        .get(baseurl + `/${id}`, params)
        .then(async (response) => {
            let image_files = await convertUrlsToFiles(response.imagenes);
            delete response.imagenes;
            let data = { ...response, laminas: image_files };
            return data;
        })
        .catch((error) => {
            return [];
        });
};

export const crear = (data) => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    const attachments = data.laminas.map((item, index) => ({
        name: index,
        file: item,
    }));
    delete data.laminas;
    const periodo = getPeriodoId();
    if (!periodo) {
        NotificationManager.error(
            "No hay un periodo seleccionado",
            "Error",
            10000
        );
        return;
    }
    const formData = {
        ...data,
        empresa: id_emp[5],
        periodo,
    };
    return api
        .postAttachments(baseurl, formData, attachments)
        .then(() => {
            NotificationManager.success(writeSuccessMsg, "Exito", 10000);
        })
        .catch((error) => {
            try {
                error.non_field_errors.map((err) => {
                    NotificationManager.error(err, "Error", 10000);
                });
                return;
            } catch (err) {
                Object.keys(error).forEach((field) => {
                    error[field].forEach((errorMessage) => {
                        NotificationManager.error(
                            `${field}: ${errorMessage}`,
                            "Error",
                            10000
                        );
                    });
                });
            }
        })
        .catch((error) => {
            NotificationManager.error("", "Error", 2000);
        });
};

export const editar = (data, id) => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    const attachments = data.laminas.map((item, index) => ({
        name: index,
        file: item,
    }));
    delete data.laminas;
    const formData = {
        ...data,
        empresa: id_emp[5],
    };
    return api
        .putAttachments(baseurl + `/${id}`, formData, attachments)
        .then(() => {
            NotificationManager.success(editSuccessMsg, "Exito", 3000);
            return true;
        })
        .catch((error) => {
            NotificationManager.error(error.msj, "Error", 2000);
            return false;
        });
};
// export const editar = (data, id) => {
//     let ruta = window.location.href;
//     let empresa = ruta.split("/")[5];
//     const formData = {
//         ...data,
//         empresa,
//     };
//     return api
//         .put(baseurl + `/${id}`, formData)
//         .then(() => {
//             NotificationManager.success(editSuccessMsg, "Exito", 3000);
//             return true;
//         })
//         .catch((error) => {
//             NotificationManager.error(error.msj, "Error", 2000);
//             return false;
//         });
// };

export const getPatologias = (search) => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let empresa = id_emp[5];
    const params = { empresa, search };
    return api
        .get("laboratorio/ingreso_de_patologia", params)
        .then((response) => {
            return response
                .filter(
                    (item) => item.tipo !== "Citologia" && item.laminas === null
                )
                .map((item) => {
                    return {
                        value: item.id,
                        label: `${item.numero_patologia} | ${item.tipo} `,
                        tipo: item.tipo,
                    };
                });
        })
        .catch((error) => {
            return [];
        });
};
