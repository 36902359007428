import React, { Component } from 'react';
import Formulario from './SiembraForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";

class Siembras extends Component{
    state = {
        crear: true,
    }

    componentDidMount = () => {
        const { match, leer, getModulo,leerPeriodoD, 
        vaciar } = this.props;
        const id = match.params.id;
        if (id){
            this.setState({crear: false});
            leer(id);
        }
        let ruta = window.location.href;
        let periodo = ruta.split('/');
        leerPeriodoD(periodo[5], periodo[7]);
        getModulo(periodo[5], periodo[4]);
        vaciar();
        
        localStorage.removeItem('id_detalle_siembra');
    }
    
    render(){
        const { loader, modulo, periodoD, registroSiembra, detalleS,
            detallePro, agregarLinea, modificarSiembra, eliminarLinea,
            listarFases, nuevaLineaSiembra, eliminarLineaSiembra } = this.props;
        const { crear } = this.state;
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    crear = {crear}
                    modulo = {modulo}
                    periodoD = {periodoD}
                    detalleCon = {detallePro}
                    registroSiembra = {registroSiembra}
                    modificarSiembra = {modificarSiembra}
                    agregarLinea = {agregarLinea}
                    eliminarLinea = {eliminarLinea}
                    listarFases = {listarFases}
                    detalleS = {detalleS}
                    nuevaLineaSiembra = {nuevaLineaSiembra}
                    eliminarLineaSiembra = {eliminarLineaSiembra}
                />
            </LoadMask>
        );
    }
}

export default Siembras
