import { NotificationManager } from "react-notifications";
import { api } from "api";
import { handleActions, createAction } from 'redux-actions';
import { listarIngresos } from "./vendedores";
import { listarMarcas } from "./vendedores";
import { listarCombustibles } from "./vendedores";
import { listarLineas } from "./vendedores";
import { listarTipos } from "./vendedores";


export const datosDelVendedor = (vendedorId) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const action = "editar"
    const params = { id, action }
    return api.get(`auto_ventas/vendedores/${vendedorId}`, params).then(response => {
        return response;
    }).catch((error) => {
        NotificationManager.error(
            "Ocurrio un error al obtener los datos",
            'Error',
            2000,
            null,
            true
        );
        return [];
    })
}

export const actualizarVendedor  = (data, vendedorId) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    data.empresa = id
    const action = "editar"
    const params = { data, action }
    return api.put(`auto_ventas/vendedores/${vendedorId}`, params).then(() => {
            NotificationManager.success(
                'Datos del vendedor modificados',
                'Exito',
                3000,
                null,
                true
            );
            return true;
        }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                2000,
                null,
                true
            );
            return false;
        })


}



export const actions = {
    datosDelVendedor,
    actualizarVendedor
};

export const reducers = {

}

export const initialState = {

};

export default handleActions(reducers, initialState)