import React from 'react';
import * as FaIcons from "react-icons/fa";
import * as BsIcons from "react-icons/bs";
import * as RiIcons from "react-icons/ri";

export const SidebarData = [
    {
        title: 'Inicio',
        label: 'inicio',
        path: '/facturacion',
        icon: <BsIcons.BsGrid1X2Fill />,
    },
    {
        title: 'Parámetros',
        label: 'parametros',
        path: '#',
        icon: <FaIcons.FaTasks />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav: [
            {
                title: 'Periodos',
                path: '/facturacion',
                path_s: 'periodos',
                icon: <BsIcons.BsFillCalendarDateFill />,
            },
            {
                title: 'Tipo de Documento',
                path: '/facturacion',
                path_s: 'tipos',
                icon: <FaIcons.FaList />,
            },
            {
                title: 'Tipo de Cambio',
                path: '/facturacion',
                path_s: 'tipo_cambio',
                icon: <BsIcons.BsCoin />,
            },
        ]
    },
    {
        title: 'Clientes',
        label: 'clientes',
        path: '/facturacion/clientes',
        icon: <FaIcons.FaBuilding />,
    },
    {
        title: 'Saldos iniciales',
        label: 'saldos_iniciales',
        path: '/facturacion/saldos',
        icon: <FaIcons.FaBook  />,
    },
    {
        title: 'Listado de precios',
        label: 'listado_precios',
        path: '/facturacion/listado_precios',
        icon: <FaIcons.FaBook />,
    },
    {
        title: 'Registros',
        label: 'registros',
        path: '#',
        icon: <FaIcons.FaBook  />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav: [
            {
                title: 'Facturas',
                path: '/facturacion',
                path_s: 'facturas',
                icon: <FaIcons.FaCashRegister  />,
            },
            {
                title: 'Nota de crédito',
                path: `/facturacion`,
                path_s: 'notas_credito',
                icon: <FaIcons.FaList />,
            },
        ]
    },
    {
        title: 'Registros [ FEL ]',
        label: 'registros_fel',
        path: '#',
        icon: <FaIcons.FaBook  />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav: [
            {
                title: 'Facturas',
                path: '/facturacion',
                path_s: 'facturas',
                icon: <FaIcons.FaCashRegister  />,
            },
            {
                title: 'Nota de crédito',
                path: `/facturacion`,
                path_s: 'nota_credito',
                icon: <FaIcons.FaList />,
            },
            {
                title: 'Nota de débito',
                path: `/facturacion`,
                path_s: 'nota_debito',
                icon: <FaIcons.FaList />,
            }
        ]
    },
    {
        title: 'Abonos',
        label: 'abonos',
        path: '#',
        icon: <FaIcons.FaTasks />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav: [
            {
                title: 'Tipo abono',
                path: '/facturacion',
                path_s: 'tipo_abono',
                icon: <FaIcons.FaList />,
            },
            {
                title: 'Abonos',
                path: '/facturacion',
                path_s: 'abonos',
                icon: <FaIcons.FaTasks />,
            },
        ]
    },
    {
        title: 'Anticipos',
        label: 'anticipos',
        path: '/facturacion/anticipos_cxc',
        icon: <FaIcons.FaTasks />,
    },
    {
        title: 'Parqueo',
        label: 'poliza_parqueo',
        path: '#',
        icon: <FaIcons.FaTasks />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav: [
            {
                title: 'Poliza de parqueo',
                path: '/facturacion',
                path_s: 'sel_poliza_parqueo',
                icon: <FaIcons.FaChalkboard />,
            },
        ]
    },
    {
        title: 'Informes',
        label: 'informes',
        path: '/facturacion/informes',
        icon: <FaIcons.FaClipboard />,
    }
]

