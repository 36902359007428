import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";

const LISTADO_CUENTAS = 'LISTADO_CUENTAS';
const GUARDAR_CUENTA = 'GUARDAR_CUENTA';
const LISTADO_BANCOS = 'LISTADO_BANCOS';
const LISTADO_MONEDAS = 'LISTADO_MONEDAS';
const LOADER = 'LOADER';
const MODULO = 'MODULO';
const PARAMETRO = 'PARAMETRO';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    //const params = { page, id };
    const params = { id };
    dispatch(setLoader(true));
    api.get('/cuenta_banco', params).then((response)=>{
        dispatch({ type: LISTADO_CUENTAS, data: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar bancos',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const listarBancos = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    dispatch(setLoader(true));
    api.get('/banco', params).then((response) => {
        const bancos = [];
        response.forEach(item => {
            bancos.push({
                value: item.id,
                label: item.banco
            })
        })
        dispatch({ type: LISTADO_BANCOS, banco: bancos });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar bancos',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const listarMonedas = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    api.get('/monedas/moneda_empresa', params).then((response)=>{
        const moneda = [];
        response.forEach(item=>{
            moneda.push({
                value: item.id,
                label: item.moneda + ' - ' + item.simbolo
            })
        })
        dispatch({ type: LISTADO_MONEDAS, listaM: moneda });
       
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar monedas',
            'Error',
            2000
        );
    })
}

export const registroCuenta = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormCuentaBancaria.values;
    const formData = {
        numero_cuenta: data.numero_cuenta,
        banco: data.banco,
        tipo: data.tipo,
        moneda: data.moneda,
        cuentaC:  data.cuenta ? data.cuenta.value : '',
        cc: data.cc ? data.cc.value : '',
        empresa: id_emp[5]
    }
    dispatch(setLoader(true));
    api.post('/cuenta_banco/', formData).then((response) => {
        NotificationManager.success(
            'Cuenta bancaria creada',
            'Exito',
            3000
        );
        let ruta = `/bancos/${id_emp[5]}/cuenta_bancaria`
        dispatch(push(ruta));
    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const modificarCuenta = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const datos = getStore().form.FormCuentaBancaria.values;
    const id = datos.id;
    let cuenta;
    let centro;
    if (datos.cuenta){
        cuenta = datos.cuenta.value;
        if (cuenta === undefined){
            cuenta = datos.cuenta_contable;
        }else{
            cuenta = datos.cuenta.value;
        }
    }

    if (datos.cc){
        centro = datos.cc.value;
        if (centro === undefined){
            centro = datos.centro_costo;
        }else{
            centro = datos.cc.value;
        }
    }
    
    const formData = {
        id: id,
        numero_cuenta: datos.numero_cuenta,
        banco: datos.banco,
        tipo: datos.tipo,
        moneda: datos.moneda,
        cuentaC:  cuenta ? cuenta : '',
        cc: centro ? centro : '' ,
        empresa: id_emp[5]
    }
    dispatch(setLoader(true));
    api.put(`/cuenta_banco/${id}/`, formData).then((response) => {
        NotificationManager.success(
            'Cuenta bancaria modificada correctamente',
            'Exito',
            3000
        );
        let ruta = `/bancos/${id_emp[5]}/cuenta_bancaria`
        dispatch(push(ruta));
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al modificar cuenta bancaria',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/cuenta_banco/${id}`).then((response) => {
        dispatch({type: GUARDAR_CUENTA, lectura: response });
        response.cuenta_contable = response.cuenta;
        response.centro_costo = response.cc;
        dispatch(initializeForm('FormCuentaBancaria', response ));
    }).catch((error)=>{
        console.log("error: ", error)
        NotificationManager.error(
            'Ocurrió un error al consultar el banco',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}


export const listarCuentas = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/cuentas/empresa_cuentas/', params).then(data => {
        const list_cuentas = [];
        if (data) {
            data.forEach(item => {
                if (item.tipo === 'A') {
                    list_cuentas.push({
                        value: item.id,
                        label: item.cuenta + ' ' + item.nombre,
                        isDisabled: true
                    })
                } else {
                    list_cuentas.push({
                        value: item.id,
                        label: item.cuenta + ' ' + item.nombre
                    })
                }
            })
        }
        return list_cuentas;
    }).catch((error) => {
        console.log("error: ", error);
        return [];
    })
}

export const listarCC = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id, search}
    return api.get('/centrocosto/empresa_centro_costo/',params).then(data=>{
        const list_cc = [];
        if(data){
            data.forEach(item=>{
                list_cc.push({
                    value: item.id,
                    label: item.codigo + ' ' + item.descripcion
                })
            })
        }
        return list_cc;
    }).catch((error)=>{
        return [];
    })
}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const parametrosEmpresa = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id}
    dispatch(setLoader(true));
    api.get('/empresa/empresa_parametros', params).then((response) => {
        dispatch({type: PARAMETRO, datos: response });
    }).catch((error)=>{
        console.log("error: ", error)
        NotificationManager.error(
            'Ocurrió un error al consultar parametros',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const actions = {
    listar,
    registroCuenta,
    modificarCuenta,
    listarBancos,
    listarMonedas,
    listarCuentas,
    listarCC,
    leer,
    getModulo,
    parametrosEmpresa
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_CUENTAS]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [LISTADO_BANCOS]: (state, { banco }) => {
        return {
            ...state,
            banco,
        };
    },
    [LISTADO_MONEDAS]: (state, { listaM }) => {
        return {
            ...state,
            listaM,
        };
    },
    [GUARDAR_CUENTA]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [PARAMETRO]: (state, { datos }) => {
        return {
            ...state,
            datos,
        };
    },
   
};

export const initialState = {
    loader: false,
    lectura: [],
    modulo: [],
    datos: [],
    data: [],
    page: 1,
};

export default handleActions(reducers, initialState)