import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";

const LISTADO_PRODUCTO = 'LISTADO_PRODUCTO';
const DETALLE_PRODUCTOS = 'DETALLE_PRODUCTOS';
const PRODUCTOS = 'PRODUCTOS';
const LOADER = 'LOADER';
const MODULO = 'MODULO';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const getModulo = (id, modulo) => (dispatch) => {
    const params = { id, modulo }
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response) => {
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const leerProductos = (id) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let idEmpre = id_emp[5];
    const params = { id, idEmpre }
    api.get('/producto/empresa_productos_cod_inventario_bodega_prd/', params).then((response) => {
        const producto = [];
        response.forEach(item => {
            if (item.tipoPro === 'P') {
                producto.push({
                    value: item.id + ' # ' + item.saldo + '#' + item.tipoPro,
                    label: item.sku + ' / ' + item.productoN + ' -- ' + ' Existencia: ' + new Intl.NumberFormat("en-US", { minimumFractionDigits: 6 }).format(item.saldo)
                })
            }
        })
        dispatch({ type: PRODUCTOS, productosSelect: producto })
    })
}

export const listadoProductos = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    //const params = { page, id };
    const params = { id };
    api.get('/producto', params).then((response)=>{
        dispatch({ type: LISTADO_PRODUCTO, data: response });

    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar productos',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const listarProductos = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id, search};
    return api.get('/producto/empresa_productos_cod_inventario',params).then(data=>{
        const list_producto = [];
        if(data){
            data.forEach(item=>{
                list_producto.push({
                    value: item.id+' # '+item.bodega+' # '+item.saldo,
                    label: item.sku + ' / ' + item.productoN + ' -- ' + ' Bodega: ' +item.numeroBodega + ' Existencia: ' + item.saldo
                })
            })
        }
        return list_producto;
    }).catch((error)=>{
        return [];
    })
}

export const listarClientes = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id, search}
    return api.get('/cliente/empresa_clientes',params).then(data=>{
        const list_cliente = [];
        if(data){
            data.forEach(item=>{
                list_cliente.push({
                    value: item.id,
                    label: item.identificador + ' ' + item.nombre
                })
            })
        }
        return list_cliente ;
    }).catch((error)=>{
        
        return [];
    })
}

const leerDetalleFactura = id => (dispatch) => {
    api.get(`factura_detalle/${id}`).then((response)=>{
        dispatch({ type: DETALLE_PRODUCTOS, detalleProducto: response });
    }).catch((error)=>{
        NotificationManager.error(
            error.body.msj,
            'Error',
            6000
        );
        dispatch({ type: DETALLE_PRODUCTOS, detalleProducto: [] });;
    })
};


export const actions = {
    getModulo,
    listarProductos,
    leerProductos,
    listarClientes,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [LISTADO_PRODUCTO]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
};

export const initialState = {
    loader: false,
    modulo: [],
    productosSelect: [],
    data:[],
};

export default handleActions(reducers, initialState);
