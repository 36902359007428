import React, { Component } from 'react';
import Formulario from './TrasladoCajasForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";

class DistribucionCajas extends Component{
    state = {
        crear: true,
    }

    componentDidMount = () => {
        const { match, leer, getModulo,leerPeriodoD, 
        vaciar, numeroTraslado } = this.props;
        const id = match.params.id;
        if (id){
            this.setState({crear: false});
            leer(id);
        }
        let ruta = window.location.href;
        let periodo = ruta.split('/');
        leerPeriodoD(periodo[5], periodo[7]);
        getModulo(periodo[5], periodo[4]);
        vaciar();
        numeroTraslado();
        localStorage.removeItem('id_detalle_traslado_inv');
    }
    
    render(){
        const { loader, modulo, periodoD, listarInsumos, registroTraslado, 
            detalleInsumo, detallePro, eliminarLineaInsumo, numeroDist,
            nuevaLineaInsumo, numeroS, listarInvernaderos, agregarLinea,
            modificarTraslado, eliminarLinea, existencia, existenciaInvernadero,
            detalleExistencia } = this.props;
        const { crear } = this.state;
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    crear = {crear}
                    modulo = {modulo}
                    periodoD = {periodoD}
                    detalleCon = {detallePro}
                    registroTraslado = {registroTraslado}
                    modificarTraslado = {modificarTraslado}
                    listarInsumos = {listarInsumos}
                    detalleInsumo = {detalleInsumo}
                    numeroS = {numeroS}
                    listarInvernaderos = {listarInvernaderos}
                    agregarLinea = {agregarLinea}
                    eliminarLinea = {eliminarLinea}
                    nuevaLineaInsumo = {nuevaLineaInsumo}
                    eliminarLineaInsumo = {eliminarLineaInsumo}
                    existencia = {existencia}
                    numeroDist = {numeroDist}
                    existenciaInvernadero = {existenciaInvernadero}
                    detalleExistencia = {detalleExistencia}
                />
            </LoadMask>
        );
    }
}

export default DistribucionCajas
