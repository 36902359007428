import React, { Component } from 'react';
import Grid from '../../Utils/GridFiltro';
import moment from 'moment';
import { standardActions } from '../../Utils/GridFiltro/StandardActions';

class PolizasListLC extends Component{
    
    render(){
        const { data, loader, eliminar } = this.props;
        let ruta = window.location.href;
        let datos = ruta.split('/');
        
        return(
            <React.Fragment>
            {data &&
                <Grid 
                    hover 
                    striped 
                    data={data} 
                    loading={loader} 
                    headerStyle={ { background: 'black' } } 
                >
                    <TableHeaderColumn
                        isKey
                        dataField="fecha"
                        width="100"
                        dataFormat={(cell, row)=>{
                            return moment(cell).format("DD/MM/YYYY");
                        }}
                        dataSort
                        thStyle={ 
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Fecha
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="tipo"
                        dataAlign='center'
                        width="100"
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Tipo
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="numero_poliza"
                        dataAlign='center'
                        width="170"
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        No. Poliza
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="descripcion"
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Descripcion
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataAlign='right'
                        headerAlign="center"
                        dataField="diferencia"
                        width="150"
                        dataSort
                        dataFormat={(cell, row)=>{
                        if (parseFloat(cell) === 0.00){
                                return ''
                            }else{
                                return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Math.abs(cell));
                            }
                        }}
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Diferencia
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        thStyle={
                        {color: 'white'}}
                        dataField="id"
                        width="180"
                        dataAlign="center"
                        dataSort
                        dataFormat={standardActions({ 
                            editar_pol: "polizas", 
                            ver_a: "polizas",
                            empresa: datos[5],
                            periodo: datos[7],
                            modulo: datos[4],
                            eliminar_pol_a: eliminar })} 
                            
                    >
                        Acciones
                    </TableHeaderColumn>
                </Grid>
            }
            </React.Fragment>
        );
    }
}

export default PolizasListLC;
