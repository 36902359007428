import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import moment from 'moment';
import Swal from 'sweetalert2';
import { validar } from "../../../common/components/components/ValidarNit";

const LISTADO_COMPRA = 'LISTADO_COMPRA';
const LISTADO_TIPOS_DOCU = 'LISTADO_TIPOS_DOCU';
const LISTADO_PROVEEDOR = 'LISTADO_PROVEEDOR';
const LISTADO_PROYECTOS = 'LISTADO_PROYECTOS';
const LISTADO_SUBPROYECTOS = 'LISTADO_SUBPROYECTOS';
const LISTADO_FASES = 'LISTADO_FASES';
const DETALLE_MATERIALES = 'DETALLE_MATERIALES';
const DETALLE_PRODUCTOS = 'DETALLE_PRODUCTOS';
const GUARDAR_COMPRA = 'GUARDAR_COMPRA';
const GUARDAR_POLIZA = 'GUARDAR_POLIZA';
const DETALLE_POLIZA = 'DETALLE_POLIZA';
const ENCABEZADO_MATERIALES = 'ENCABEZADO_MATERIALES';
const LOADER = 'LOADER';
const LOADERC = 'LOADERC';
const NIVEL = 'NIVEL';
const PERIODO_D = 'PERIODOD';
const MODULO = 'MODULO';
const PARAMETRO = 'PARAMETRO';
const LISTADO_BODEGAS = 'LISTADO_BODEGAS';
const LISTADO_PAISES = 'LISTADO_PAISES';
const LISTADO_IMPUESTO = 'LISTADO_IMPUESTO';
const DATOS_COMPRA = 'DATOS_COMPRA';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setLoaderC = loaderC => ({
    type: LOADERC,
    loaderC,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let id_period = id_emp[7];
    const params = { id, id_period };
    dispatch(setLoader(true));
    api.get('/compra', params).then((response) => {
        dispatch({ type: LISTADO_COMPRA, data: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar compras',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const listarFacSaldos = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let saldo = 'true';
    //const params = { page, id, saldo };
    const params = { id, saldo };
    dispatch(setLoader(true));
    api.get('/compra', params).then((response) => {
        dispatch({ type: LISTADO_COMPRA, data: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar facturas',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}


export const listarTiposDocu = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    api.get('/tipos', params).then((response) => {
        const tiposD = [];
        response.results.forEach(item => {
            if (item.tipo_documento === 'CARGO'){
                tiposD.push({
                    value: item.id,
                    label: item.tipo + ' - ' + item.descripcion,
                    search: item.categoria
                })
            }
        })
        dispatch({ type: LISTADO_TIPOS_DOCU, tipos: tiposD });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar tipos de documento',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const listarProveedores = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let tipo = 'compras';
    const params = {id, search, tipo}
    return api.get('/proveedor/empresa_proveedores',params).then(data=>{
        const list_proveedor = [];
        if(data){
            data.forEach(item=>{
                list_proveedor.push({
                    value: item.id,
                    label: item.identificador + ' ' + item.nombre
                })
            })
        }
        return list_proveedor ;
    }).catch((error)=>{
        
        return [];
    })
}

export const listarCuentas = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/cuentas/empresa_cuentas/', params).then(data => {
        const list_cuentas = [];
        if (data) {
            data.forEach(item => {
                if (item.tipo === 'A') {
                    list_cuentas.push({
                        value: item.id,
                        label: item.cuenta + ' ' + item.nombre,
                        isDisabled: true
                    })
                } else {
                    list_cuentas.push({
                        value: item.id,
                        label: item.cuenta + ' ' + item.nombre
                    })
                }
            })
        }
        return list_cuentas;
    }).catch((error) => {
        
        return [];
    })
}

export const listarCC = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/centrocosto/empresa_centro_costo/', params).then(data => {
        const list_cc = [];
        if (data) {
            data.forEach(item => {
                list_cc.push({
                    value: item.id,
                    label: item.codigo + ' ' + item.descripcion
                })
            })
        }
        return list_cc;
    }).catch((error) => {
        
        return [];
    })
}

const leerDetalle = id => (dispatch) => {
    api.get(`/poliza_detalle/${id}`).then((response) => {
        dispatch({ type: DETALLE_POLIZA, detalle: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};

const leerDetalleMod = id => (dispatch) => {
    api.get(`/poliza_detalle/${id}`).then((response)=>{
        response.polizaDetalle_polizaEncabezado = response
        dispatch({ type: GUARDAR_POLIZA, lectura: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};


export const listarNiveles = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    dispatch(setLoader(true));
    api.get('/niveles/nivel_cuenta', params).then((response) => {
        dispatch({ type: NIVEL, nivel: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar nivel',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const eliminarLinea = id => (dispatch) => {
    const poliza = localStorage.getItem('idPolizaCompra');
    dispatch(setLoader(true));
    api.eliminar(`poliza_detalle/${id}`).then(() => {
        const editar = window.location.href.includes('editar');
        if (editar) {
            dispatch(leerDetalleMod(poliza));
        } else {
            dispatch(leerDetalle(poliza));
        }
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar linea de poliza',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const registroCuenta = (formato) => (dispatch, getStore) => {
    const data = getStore().form.ComprasFormTabs.values;
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let cuenta = data.cuenta
    let f = formato.split('-');
    let cont = 0;
    let formatoCuenta = '';
    let digito = '';

    if (f.length === 3) {
        let nivel1 = f[0].length
        digito = cuenta.substr(cont, nivel1);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel2 = f[1].length;
        cont = cont + nivel1;
        digito = cuenta.substr(cont, nivel2);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel3 = f[2].length;
        cont = cont + nivel2;
        digito = cuenta.substr(cont, nivel3);
        formatoCuenta = formatoCuenta + digito;

    }

    if (f.length === 4) {
        let nivel1 = f[0].length
        digito = cuenta.substr(cont, nivel1);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel2 = f[1].length;
        cont = cont + nivel1;
        digito = cuenta.substr(cont, nivel2);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel3 = f[2].length;
        cont = cont + nivel2;
        digito = cuenta.substr(cont, nivel3);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel4 = f[3].length;
        cont = cont + nivel3;
        digito = cuenta.substr(cont, nivel4);
        formatoCuenta = formatoCuenta + digito;
    }

    if (f.length === 5) {
        let nivel1 = f[0].length
        digito = cuenta.substr(cont, nivel1);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel2 = f[1].length;
        cont = cont + nivel1;
        digito = cuenta.substr(cont, nivel2);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel3 = f[2].length;
        cont = cont + nivel2;
        digito = cuenta.substr(cont, nivel3);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel4 = f[3].length;
        cont = cont + nivel3;
        digito = cuenta.substr(cont, nivel4);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel5 = f[4].length;
        cont = cont + nivel4;
        digito = cuenta.substr(cont, nivel5);
        formatoCuenta = formatoCuenta + digito;
    }

    if (f.length === 6) {
        let nivel1 = f[0].length
        digito = cuenta.substr(cont, nivel1);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel2 = f[1].length;
        cont = cont + nivel1;
        digito = cuenta.substr(cont, nivel2);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel3 = f[2].length;
        cont = cont + nivel2;
        digito = cuenta.substr(cont, nivel3);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel4 = f[3].length;
        cont = cont + nivel3;
        digito = cuenta.substr(cont, nivel4);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel5 = f[4].length;
        cont = cont + nivel4;
        digito = cuenta.substr(cont, nivel5);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel6 = f[5].length;
        cont = cont + nivel5;
        digito = cuenta.substr(cont, nivel6);
        formatoCuenta = formatoCuenta + digito;
    }
    let idPoliza = localStorage.getItem('idPoliza')
    const formData = {
        cuenta: formatoCuenta,
        nombre: data.nombre,
        tipo: data.tipoC,
        naturaleza: data.naturaleza,
        empresa: id_emp[5],
        poliza: idPoliza
    }
    dispatch(setLoaderC(true));
    api.post('/cuentas/', formData).then((response) => {
        if (idPoliza) {
            NotificationManager.success(
                'Cuenta creada' + ' ' + response.cuenta.cuenta + ' ' + response.cuenta.nombre,
                'Exito',
                0
            );
            response.cuenta = '';
            response.nombre = '';
            response.fecha = response.poliza.fecha;
            response.tipo = response.poliza.tipo;
            response.numero_poliza = response.poliza.numero_poliza;
            response.descripcion = response.poliza.descripcion;
            listarCuentas();

        }else{
            NotificationManager.success(
                'Cuenta creada' + ' ' + response.cuenta + ' ' + response.nombre,
                'Exito',
                0
            );
            response.cuenta = '';
            response.nombre = '';
            listarCuentas();
        }

    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoaderC(false));
    });
}

export const reporteCuentas = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let reporte = 'reporte';
    const params = { id, reporte };
    dispatch(setLoaderC(true));;
    api.getPdf('/cuentas', params).then((response) => {
        if (response.headers['content-type'] == 'application/pdf') {
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'reporte_cuentas_contables.pdf';
            window.open(link, '_blank');
            return false;
        }
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al generar reporte',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoaderC(false));
    });
}

export const registroCompra = (impuesto, periodoD, datos) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormCompras.values;
    
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fechaRegistro)
    let poliza = '';
    let fechaFinalD = moment(data.fecha);
    if (fecha>=fechaI && fecha<=fechaF){
        if (fechaFinalD.isAfter(periodoD.fecha_fin)) {
            localStorage.setItem('valCompra','F')
            Swal.fire({
                title: 'Compra local',
                text: 'Fecha es posterior al periodo: ' + moment(periodoD.fecha_fin).format("DD/MM/YYYY"),
                type: 'error',
            })   
        }else{
            localStorage.setItem('valCompra','T');
            let PIva = parseFloat(parseFloat(parseFloat(impuesto.porcentaje_iva)/100)+1).toFixed(2);
            let CGSuper = parseFloat(impuesto.costo_galon_super).toFixed(2);
            let CGRegular = parseFloat(impuesto.costo_galon_regular).toFixed(2);
            let CGDiesel = parseFloat(impuesto.costo_galon_diesel).toFixed(2);

            let comb = data.afecta_combustible;
            let SumIDP = 0.00;
            let SumBase = 0.00;
            let IdpSuper = 0.00;
            let IdpRegular = 0.00;
            let IdpDiesel = 0.00;
            let BaseSuper = 0.00;
            let BaseRegular = 0.00;
            let BaseDiesel = 0.00;
            let BaseOtros = 0.00;
            let IvaSup = 0.00;
            let IvaReg = 0.00;
            let IvaDie = 0.00;
            let IvaOtros = 0.00;
            let SumIva = 0.00;
            let SumTotalC = 0.00;
        
            let gaSuper = data.gsuper;
            let gaRegular = data.gregular;
            let gaDiesel = data.gdiesel;

            let costoSuper = data.csuper;
            let costoRegular = data.cregular;
            let costoDiesel = data.cdiesel;
            let otros = data.otros;
            if (gaSuper === undefined){
                gaSuper = 0.00
            }
            if (gaRegular === undefined){
                gaRegular = 0.00
            }
            if (gaDiesel === undefined){
                gaDiesel = 0.00
            }

            if (costoSuper === undefined){
                costoSuper = 0.00
            }
            if (costoRegular === undefined){
                costoRegular = 0.00
            }
            if (costoDiesel === undefined){
                costoDiesel = 0.00
            }
            if (otros === undefined){
                otros = 0.00
            }

            if (comb === true){
            
                IdpSuper = parseFloat(parseFloat(gaSuper).toFixed(3) * CGSuper).toFixed(2);
                IdpRegular = parseFloat(parseFloat(gaRegular).toFixed(3) * CGRegular).toFixed(2);
                IdpDiesel =  parseFloat(parseFloat(gaDiesel).toFixed(3) * CGDiesel).toFixed(2)
            
                SumIDP = parseFloat(parseFloat(IdpSuper) + parseFloat(IdpRegular) + parseFloat(IdpDiesel)).toFixed(2);
            
                BaseSuper = parseFloat((parseFloat(costoSuper)-parseFloat(IdpSuper))/PIva).toFixed(2);
                BaseRegular = parseFloat((parseFloat(costoRegular)-parseFloat(IdpRegular))/PIva).toFixed(2);
                BaseDiesel = parseFloat((parseFloat(costoDiesel)-parseFloat(IdpDiesel))/PIva).toFixed(2);
                BaseOtros = parseFloat(parseFloat(otros)/PIva).toFixed(2);
            
                SumBase = parseFloat(parseFloat(BaseSuper) + parseFloat(BaseRegular) + parseFloat(BaseDiesel) + parseFloat(BaseOtros)).toFixed(2);
            
                IvaSup = parseFloat((parseFloat(costoSuper)-parseFloat(IdpSuper))-parseFloat(BaseSuper)).toFixed(2);
                IvaReg = parseFloat((parseFloat(costoRegular)-parseFloat(IdpRegular))-parseFloat(BaseRegular)).toFixed(2);
                IvaDie = parseFloat((parseFloat(costoDiesel)-parseFloat(IdpDiesel))-parseFloat(BaseDiesel)).toFixed(2);
                IvaOtros = parseFloat(parseFloat(otros) - parseFloat(BaseOtros)).toFixed(2);
                
                SumIva = parseFloat(parseFloat(IvaSup)+parseFloat(IvaReg)+parseFloat(IvaDie)+parseFloat(IvaOtros)).toFixed(2);
                
                SumTotalC = parseFloat(parseFloat(costoSuper)+parseFloat(costoRegular)+parseFloat(costoDiesel)+parseFloat(otros)).toFixed(2);

            }

            let iva = JSON.parse(localStorage.getItem('A_iva'));
            let bienes = data.bienes;
            let servicios = data.servicios;
            let exento = data.exento;
            
            if (bienes === undefined){
                bienes = 0.00
            }
            if (servicios === undefined){
                servicios = 0.00
            }
            if (exento === undefined){
                exento = 0.00
            }
            let baseB = 0.00;
            let ivaB = 0.00;
            let SumBaD = 0.00;
            let baseS = 0.00;
            let ivaS = 0.00;
            let SumIvD = 0.00;
            let SumTotalD = 0.00;
            
            if (comb === undefined){
                comb = false;
            }
            if (iva === true && comb === false){
                baseB = parseFloat(parseFloat(bienes) / PIva).toFixed(2);
                ivaB = parseFloat(parseFloat(bienes) - parseFloat(baseB)).toFixed(2);
                
                baseS = parseFloat(parseFloat(servicios) / PIva).toFixed(2);
                ivaS = parseFloat(parseFloat(servicios) - parseFloat(baseS)).toFixed(2);
            
                SumBaD = parseFloat(parseFloat(baseB)+parseFloat(baseS)).toFixed(2);

                SumIvD = parseFloat(parseFloat(ivaB)+parseFloat(ivaS)).toFixed(2);
                SumTotalD = parseFloat(parseFloat(bienes)+parseFloat(servicios)+parseFloat(exento)).toFixed(2);

                //Verificar si la factura es mas de dos meses
                let fechaI = moment(periodoD.fecha_inicio)
                let date_f = moment(fechaI).subtract(2, 'months').format();
                let date_fin = moment(date_f).subtract(1, 'days').format();
                let fecha = moment(data.fecha).isBetween(date_fin, fechaI, 'days');
                let dif = fechaI.diff(data.fecha, 'days')
                if (fecha === false && dif >= 1){
                    localStorage.setItem('A_iva', false);
                    baseB = parseFloat(bienes).toFixed(2);
                    ivaB = 0.00;
                    
                    baseS = parseFloat(servicios).toFixed(2);
                    ivaS = 0.00;
                
                    SumBaD = parseFloat(parseFloat(baseB)+parseFloat(baseS)).toFixed(2);

                    SumIvD = parseFloat(parseFloat(ivaB)+parseFloat(ivaS)).toFixed(2);
                    SumTotalD = parseFloat(parseFloat(bienes)+parseFloat(servicios)+parseFloat(exento)).toFixed(2);
                }

            }
            if (iva === false){
                SumBaD = parseFloat(parseFloat(bienes)+parseFloat(servicios)+parseFloat(exento)).toFixed(2);
                SumIvD = 0.00;
                SumTotalD = parseFloat(parseFloat(bienes)+parseFloat(servicios)+parseFloat(exento)).toFixed(2);
            }
            if (datos.contabilidad === 'S') {
                poliza = 'compras';
            }
            if (datos.contabilidad === 'N') {
                poliza = '';
            }
            const formData = {
                fecha_registro: data.fechaRegistro,
                fecha: data.fecha,
                serie: data.serie.toUpperCase(),
                numero: data.numero.toUpperCase(),
                afectaIva: JSON.parse(localStorage.getItem('A_iva')),
                compraDolares: data.en_dolares,
                combustible: comb,
                gSuper: data.gsuper,
                cSuper: data.csuper,
                gRegular: data.gregular,
                cRegular: data.cregular,
                gDiesel: data.gdiesel,
                cDiesel: data.cdiesel,
                otros: data.otros,
                idp: SumIDP,
                bienes: data.bienes,
                servicios: data.servicios,
                exento: data.exento,
                tipo: data.tipo,
                periodo: id_emp[7],
                tipo_cambio: localStorage.getItem('TC_compra'),
                proveedor: data.proveedor_documento.value,
                descripcion: data.descripcion,
                iva: comb ? SumIva : SumIvD,
                base: comb ? SumBase : SumBaD,
                total: comb ? SumTotalC : SumTotalD,
                modulo: 'CMP',
                empresa: id_emp[5],
                poliza: poliza
            }
            
            dispatch(setLoader(true));
            api.post('/compra/', formData).then((response) => {
                localStorage.setItem('valCompra','T');
                if (response.idPoliza){
                    localStorage.setItem('valCompra','T');
                    localStorage.setItem('idPolizaCompra', response.idPoliza);
                    localStorage.setItem('compra_id', response.idCompra);
                    localStorage.setItem('fecha_compra', response.fechaRegistro);
                    const poliza = localStorage.getItem('idPolizaCompra');
                    dispatch({ type: DATOS_COMPRA, datosCmp: response });
                    dispatch(leerDetalle(poliza));
                }else{
                    if (datos.inmobiliaria === 'N' && datos.inventario === 'S' && datos.contabilidad === 'N'){
                        localStorage.setItem('valCompra','T');
                        localStorage.setItem('compra_id', response.idCompra);
                        localStorage.setItem('idPolizaCompra', response.id);
                        localStorage.setItem('fecha_compra', response.fechaRegistro);
                        dispatch({ type: DATOS_COMPRA, datosCmp: response });
                    }else{
                        window.location.reload(true);
                    }
                }            

                NotificationManager.success(
                    'Compra creada correctamente',
                    'Exito',
                    3000
                );
                
            }).catch((error) => {
                localStorage.setItem('valCompra','F')
                NotificationManager.error(
                    error.msj,
                    'Error',
                    0
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }
    }else {
        localStorage.setItem('valCompra','F')
        Swal.fire({
            title: 'Compra local',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })        
    }
}

export const modificarCompra = (impuesto, periodoD, datos) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormCompras.values;
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fechaRegistro)
    let poliza = '';
    let fechaFinalD = moment(data.fecha);
    if (fecha>=fechaI && fecha<=fechaF){
        if (fechaFinalD.isAfter(periodoD.fecha_fin)) {
            localStorage.setItem('valCompra','F')
            Swal.fire({
                title: 'Compra local',
                text: 'Fecha es posterior al periodo: ' + moment(periodoD.fecha_fin).format("DD/MM/YYYY"),
                type: 'error',
            })   
        }else{
        
            const id = data.id;
            let PIva = parseFloat(parseFloat(parseFloat(impuesto.porcentaje_iva)/100)+1).toFixed(2);
            let CGSuper = parseFloat(impuesto.costo_galon_super).toFixed(2);
            let CGRegular = parseFloat(impuesto.costo_galon_regular).toFixed(2);
            let CGDiesel = parseFloat(impuesto.costo_galon_diesel).toFixed(2);
            let comb = data.afecta_combustible;
            let SumIDP = 0.00;
            let SumBase = 0.00;
            let IdpSuper = 0.00;
            let IdpRegular = 0.00;
            let IdpDiesel = 0.00;
            let BaseSuper = 0.00;
            let BaseRegular = 0.00;
            let BaseDiesel = 0.00;
            let BaseOtros = 0.00;
            let IvaSup = 0.00;
            let IvaReg = 0.00;
            let IvaDie = 0.00;
            let IvaOtros = 0.00;
            let SumIva = 0.00;
            let SumTotalC = 0.00;
        
            let gaSuper = data.gsuper;
            let gaRegular = data.gregular;
            let gaDiesel = data.gdiesel;

            let costoSuper = data.csuper;
            let costoRegular = data.cregular;
            let costoDiesel = data.cdiesel;
            let otros = data.otros;
            if (gaSuper === undefined){
                gaSuper = 0.00
            }
            if (gaRegular === undefined){
                gaRegular = 0.00
            }
            if (gaDiesel === undefined){
                gaDiesel = 0.00
            }

            if (costoSuper === undefined){
                costoSuper = 0.00
            }
            if (costoRegular === undefined){
                costoRegular = 0.00
            }
            if (costoDiesel === undefined){
                costoDiesel = 0.00
            }
            if (otros === undefined){
                otros = 0.00
            }

            if (comb === true){
            
                IdpSuper = parseFloat(parseFloat(gaSuper).toFixed(3) * CGSuper).toFixed(2);
                IdpRegular = parseFloat(parseFloat(gaRegular).toFixed(3) * CGRegular).toFixed(2);
                IdpDiesel =  parseFloat(parseFloat(gaDiesel).toFixed(3) * CGDiesel).toFixed(2)
            
                SumIDP = parseFloat(parseFloat(IdpSuper) + parseFloat(IdpRegular) + parseFloat(IdpDiesel)).toFixed(2);
            
                BaseSuper = parseFloat((parseFloat(costoSuper)-parseFloat(IdpSuper))/PIva).toFixed(2);
                BaseRegular = parseFloat((parseFloat(costoRegular)-parseFloat(IdpRegular))/PIva).toFixed(2);
                BaseDiesel = parseFloat((parseFloat(costoDiesel)-parseFloat(IdpDiesel))/PIva).toFixed(2);
                BaseOtros = parseFloat(parseFloat(otros)/PIva).toFixed(2);
            
                SumBase = parseFloat(parseFloat(BaseSuper) + parseFloat(BaseRegular) + parseFloat(BaseDiesel) + parseFloat(BaseOtros)).toFixed(2);
            
                IvaSup = parseFloat((parseFloat(costoSuper)-parseFloat(IdpSuper))-parseFloat(BaseSuper)).toFixed(2);
                IvaReg = parseFloat((parseFloat(costoRegular)-parseFloat(IdpRegular))-parseFloat(BaseRegular)).toFixed(2);
                IvaDie = parseFloat((parseFloat(costoDiesel)-parseFloat(IdpDiesel))-parseFloat(BaseDiesel)).toFixed(2);
                IvaOtros = parseFloat(parseFloat(otros) - parseFloat(BaseOtros)).toFixed(2);
                
                SumIva = parseFloat(parseFloat(IvaSup)+parseFloat(IvaReg)+parseFloat(IvaDie)+parseFloat(IvaOtros)).toFixed(2);
                
                SumTotalC = parseFloat(parseFloat(costoSuper)+parseFloat(costoRegular)+parseFloat(costoDiesel)+parseFloat(otros)).toFixed(2);

            }

            let iva = data.afecta_iva;
            let bienes = data.bienes;
            let servicios = data.servicios;
            let exento = data.exento;
            
            if (bienes === undefined){
                bienes = 0.00
            }
            if (servicios === undefined){
                servicios = 0.00
            }
            if (exento === undefined){
                exento = 0.00
            }
            let baseB = 0.00;
            let ivaB = 0.00;
            let SumBaD = 0.00;
            let baseS = 0.00;
            let ivaS = 0.00;
            let SumIvD = 0.00;
            let SumTotalD = 0.00;
            /*if (iva === undefined){
                iva = true;
            }*/
            if (comb === undefined){
                comb = false;
            }
            if (iva === true && comb === false){
                baseB = parseFloat(parseFloat(bienes) / PIva).toFixed(2);
                ivaB = parseFloat(parseFloat(bienes) - parseFloat(baseB)).toFixed(2);
                
                baseS = parseFloat(parseFloat(servicios) / PIva).toFixed(2);
                ivaS = parseFloat(parseFloat(servicios) - parseFloat(baseS)).toFixed(2);
            
                SumBaD = parseFloat(parseFloat(baseB)+parseFloat(baseS)).toFixed(2);

                SumIvD = parseFloat(parseFloat(ivaB)+parseFloat(ivaS)).toFixed(2);
                SumTotalD = parseFloat(parseFloat(bienes)+parseFloat(servicios)+parseFloat(exento)).toFixed(2);

                //Verificar si la factura es mas de dos meses
                let fechaI = moment(periodoD.fecha_inicio)
                let date_f = moment(fechaI).subtract(2, 'months').format();
                let date_fin = moment(date_f).subtract(1, 'days').format();
                let fecha = moment(data.fecha).isBetween(date_fin, fechaI, 'days');
                let dif = fechaI.diff(data.fecha, 'days')
                if (fecha === false && dif >= 1){
                    localStorage.setItem('A_iva', false);
                    baseB = parseFloat(bienes).toFixed(2);
                    ivaB = 0.00;
                    
                    baseS = parseFloat(servicios).toFixed(2);
                    ivaS = 0.00;
                
                    SumBaD = parseFloat(parseFloat(baseB)+parseFloat(baseS)).toFixed(2);

                    SumIvD = parseFloat(parseFloat(ivaB)+parseFloat(ivaS)).toFixed(2);
                    SumTotalD = parseFloat(parseFloat(bienes)+parseFloat(servicios)+parseFloat(exento)).toFixed(2);
                }
            }
            if (iva === false){
                SumBaD = parseFloat(parseFloat(bienes)+parseFloat(servicios)+parseFloat(exento)).toFixed(2);
                SumIvD = 0.00;
                SumTotalD = parseFloat(parseFloat(bienes)+parseFloat(servicios)+parseFloat(exento)).toFixed(2);
            }
            //proveedor: data.proveedor_documento.value
            let prov = data.proveedor_documento;
            if (prov === undefined){
                prov = data.proveedor;
            }else{
                prov = data.proveedor_documento.value;
            }
            if (datos.contabilidad === 'S') {
                poliza = 'compras';
            }
            if (datos.contabilidad === 'N') {
                poliza = '';
            }
            const formData = {
                id: id,
                fecha_registro: data.fechaRegistro,
                fecha: data.fecha,
                serie: data.serie.toUpperCase(),
                numero: data.numero.toUpperCase(),
                afectaIva: data.afecta_iva,
                compraDolares: data.en_dolares,
                combustible: comb,
                gSuper: data.gsuper,
                cSuper: data.csuper,
                gRegular: data.gregular,
                cRegular: data.cregular,
                gDiesel: data.gdiesel,
                cDiesel: data.cdiesel,
                otros: data.otros,
                idp: SumIDP,
                bienes: data.bienes,
                servicios: data.servicios,
                exento: data.exento,
                tipo: data.tipo,
                periodo: id_emp[7],
                tipo_cambio: localStorage.getItem('TC_compra'),
                proveedor: prov,
                descripcion: data.descripcion,
                iva: comb ? SumIva : SumIvD,
                base: comb ? SumBase : SumBaD,
                total: comb ? SumTotalC : SumTotalD,
                modulo: 'CMP',
                empresa: id_emp[5],
                poliza: poliza,
                idPoliza: data.poliza,
            }
            dispatch(setLoader(true));
            api.put(`/compra/${id}/`, formData).then((response) => {
                if (response.idPoliza){
                    const poliza = localStorage.getItem('idPolizaCompra')
                    dispatch(leerDetalleMod(poliza));
                    dispatch({ type: GUARDAR_COMPRA, lectura_data: response });
                }else{
                    ruta = `/compras/${id_emp[5]}/compras/${id_emp[7]}`
                    dispatch(push(ruta));
                }
                
                NotificationManager.success(
                    'Compra modificada correctamente',
                    'Exito',
                    3000
                );
            }).catch((error) => {
                localStorage.setItem('valCompra','F')
                NotificationManager.error(
                    'Ocurrió un error al modificar compra',
                    'Error',
                    0
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }
    }else {
        localStorage.setItem('valCompra','F')
        Swal.fire({
            title: 'Compra local',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })        
    }
}

export const registroCompraSaldo = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormCompraSaldo.values;
    const formData = {
        fecha_registro: data.fechaRegistro,
        fecha: data.fechaRegistro,
        serie: data.serie.toUpperCase(),
        numero: data.numero.toUpperCase(),
        proveedor: data.anulado === true ? null :  data.proveedor_documento.value,
        descripcion: data.descripcion,
        total: data.total,
        tipo_cambio: data.tipo_cambio,
        en_dolares: data.en_dolares,
        modulo: 'CMP',
        tipo_factura: 'SAL',
        empresa: id_emp[5],
        tipoFactura: 'saldo'
    }
    
    dispatch(setLoader(true));
    api.post('/compra/', formData).then((response) => {
        dispatch(initializeForm('FormCompraSaldo', response));
        const editar = window.location.href.includes('editar');
        NotificationManager.success(
            'Compra creada correctamente',
            'Exito',
            3000
        );
        if (editar){
            let ruta = `/compras/${id_emp[5]}/saldos`
            dispatch(push(ruta));
        }
        
    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const modificarCompraSaldo = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormCompraSaldo.values;
    
    const id = data.id;
    let proveedor = data.proveedor_documento;
    if (proveedor === undefined){
        proveedor = data.proveedor;
    }else{
        proveedor = data.proveedor_documento.value;
    }
    
    const formData = {
        id: id,
        fecha_registro: data.fechaRegistro,
        fecha: data.fechaRegistro,
        serie: data.serie.toUpperCase(),
        numero: data.numero,
        proveedor: proveedor,
        total: data.total,
        descripcion: data.descripcion,
        tipo_cambio: data.tipo_cambio,
        en_dolares: data.en_dolares,
        tipoFactura: 'saldo',
        tipo_factura: 'SAL',
    }
    dispatch(setLoader(true));
    api.put(`/compra/${id}/`, formData).then(() => {
        let ruta = `/compras/${id_emp[5]}/saldos`
        dispatch(push(ruta));
        NotificationManager.success(
            'Compra modificada correctamente',
            'Exito',
            3000
        );
        
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al modificar compra',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/compra/${id}`).then((response) => {
        response.fechaRegistro = response.fecha_registro;
        response.identificador = response.nit_proveedor;
        response.tipo = response.tipo_documento;
        response.gsuper = response.g_super;
        response.csuper = response.c_super;
        response.gregular = response.g_regular;
        response.cregular = response.c_regular;
        response.gdiesel = response.g_diesel;
        response.cdiesel = response.c_diesel;
        response.total_quetzales = parseFloat(parseFloat(response.total) * parseFloat(response.tipo_cambio)).toFixed(2);
        localStorage.setItem('combCompra', response.afecta_combustible);
        localStorage.setItem('dolaresCompra', response.en_dolares);
        localStorage.setItem('compra_id', id);
        localStorage.setItem('TC_compra', response.tipo_cambio);
        localStorage.setItem('fecha_compra', response.fecha_registro)
        localStorage.setItem('id_detalle_inventario', response.inventario);
        dispatch({ type: GUARDAR_COMPRA, lectura_data: response });
        dispatch(initializeForm('FormCompras', response));
        const editar = window.location.href.includes('editar');
        if (editar){
            if (response.poliza){
                localStorage.setItem('idPolizaCompra', response.poliza);
                dispatch(leerDetalleMod(response.poliza));
            
            }
            if (response.inventario){
                localStorage.setItem('TC_compra', response.tipo_cambio);
                localStorage.setItem('fecha_compra', response.fecha_registro)
                localStorage.setItem('id_detalle_inventario', response.inventario);
                dispatch(leerDetalleInventario(response.inventario));
            }
            if (response.proyecto){
                localStorage.setItem('id_detalle_material_cmp', response.proyecto);
                dispatch(leerDetalleMateriales(response.proyecto))
            }
        }else{
            if (response.poliza){
                dispatch(leerDetalleMod(response.poliza));
            }
            if (response.inventario){
                localStorage.setItem('TC_compra', response.tipo_cambio);
                localStorage.setItem('fecha_compra', response.fecha_registro)
                localStorage.setItem('id_detalle_inventario', response.inventario);
                dispatch(leerDetalleInventario(response.inventario))
            }
            if (response.proyecto){
                localStorage.setItem('id_detalle_material_cmp', response.proyecto);
                dispatch(leerDetalleMateriales(response.proyecto))
            }
        }
        
    }).catch((error) => {
        
        NotificationManager.error(
            'Ocurrió un error al consultar compra',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const listarProveedoresD = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let search = '';
    let tipo = 'compras'
    const params = { id, search, tipo };
    dispatch(setLoader(true));
    return api.get('/proveedor/empresa_proveedores', params).then(data => {
        const list_proveedor = [];
        if (data) {
            data.forEach(item => {
                list_proveedor.push({
                    value: item.id,
                    label: item.nombre,
                    search: item.identificador
                })
            })
        }
        dispatch({ type: LISTADO_PROVEEDOR, prov: list_proveedor });
    }).catch((error) => {
        
        NotificationManager.error(
            'Ocurrió un error al listar proveedores',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const registroPolizaDetalle = () => (dispatch, getStore) => {
    const data = getStore().form.ComprasFormTabs.values;
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const poliza = localStorage.getItem('idPolizaCompra');
    const formData = {
        empresa: id_emp[5],
        periodo: id_emp[7],
        cc: data.cc.value,
        cuenta: data.cuenta.value,
        debe: data.debe,
        haber: data.haber,
        poliza: poliza
    }
    if (formData.debe === undefined && formData.haber === undefined) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'Debe de ingresar valor en debe o haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) === 0.00 && parseFloat(formData.haber) === 0.00) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'No puede llevar valores a 0.00 en debe o haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) >= 0.01 && parseFloat(formData.haber) >= 0.01) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'No puede llevar valores en debe y haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) >= 0.01) {
        formData.haber = 0.00;
        dispatch(setLoader(true));
        api.post('/polizas/encabezado_polizas/', formData).then((response) => {
            const poliza = localStorage.getItem('idPolizaCompra')
            if (poliza === null) {
                response.debe = 0.00;
                response.haber = 0.00;
                dispatch(initializeForm('ComprasFormTabs', response));
                dispatch(leerDetalle(response.detalle.encabezado));
            } else {
                const editar = window.location.href.includes('editar');
                if (editar) {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('ComprasFormTabs', response));
                    dispatch(leerDetalleMod(poliza));
                } else {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('ComprasFormTabs', response));
                    dispatch(leerDetalle(poliza));
                }
            }
        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al registrar la poliza',
                'Error',
                0
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    } else if (parseFloat(formData.haber) >= 0.01) {
        formData.debe = 0.00;
        dispatch(setLoader(true));
        api.post('/polizas/encabezado_polizas/', formData).then((response) => {
            localStorage.removeItem('tipo_poliza');
            const poliza = localStorage.getItem('idPolizaCompra')
            if (poliza === null) {
                response.debe = 0.00;
                response.haber = 0.00;
                dispatch(initializeForm('ComprasFormTabs', response));
                dispatch(leerDetalle(response.encabezado));
            } else {
                const editar = window.location.href.includes('editar');
                if (editar) {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('ComprasFormTabs', response));
                    dispatch(leerDetalleMod(poliza));
                } else {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('ComprasFormTabs', response));
                    dispatch(leerDetalle(poliza));
                }
            }

        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al registrar la poliza',
                'Error',
                0
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
}

export const registroPolizaDetalleSCC = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.ComprasFormTabs.values;
    const poliza = localStorage.getItem('idPolizaCompra');
    const formData = {
        empresa: id_emp[5],
        periodo: id_emp[7],
        cc: 0,
        cuenta: data.cuenta.value,
        debe: data.debe,
        haber: data.haber,
        poliza: poliza
    }
    if (formData.debe === undefined && formData.haber === undefined) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'Debe de ingresar valor en debe o haber',
            type: 'error',
        })
    }else if (parseFloat(formData.debe) === 0.00 && parseFloat(formData.haber) === 0.00) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'No puede llevar valores a 0.00 en debe o haber',
            type: 'error',
        })
    }else if (parseFloat(formData.debe) >= 0.01 && parseFloat(formData.haber) >= 0.01) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'No puede llevar valores en debe y haber',
            type: 'error',
        })
    }else if (parseFloat(formData.debe) >= 0.01) {
        formData.haber = 0.00;
        dispatch(setLoader(true));
        api.post('/polizas/encabezado_polizas/', formData).then((response) => {
            const poliza = localStorage.getItem('idPolizaCompra')
            if (poliza === null) {
                response.debe = 0.00;
                response.haber = 0.00;
                dispatch(initializeForm('ComprasFormTabs', response));
                dispatch(leerDetalle(response.detalle.encabezado));
            } else {
                const editar = window.location.href.includes('editar');
                if (editar) {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('ComprasFormTabs', response));
                    dispatch(leerDetalleMod(poliza));
                } else {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('ComprasFormTabs', response));
                    dispatch(leerDetalle(poliza));
                }
            }

        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al registrar la poliza',
                'Error',
                0
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }else if (parseFloat(formData.haber) >= 0.01) {
        formData.debe = 0.00;
        api.post('/polizas/encabezado_polizas/', formData).then((response) => {
            localStorage.removeItem('tipo_poliza');
            const poliza = localStorage.getItem('idPoliza')
            if (poliza === null) {
                response.debe = 0.00;
                response.haber = 0.00;
                dispatch(initializeForm('ComprasFormTabs', response));
                dispatch(leerDetalle(response.detalle.encabezado));
            } else {
                const editar = window.location.href.includes('editar');
                if (editar) {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('ComprasFormTabs', response));
                    dispatch(leerDetalleMod(poliza));
                } else {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('ComprasFormTabs', response));
                    dispatch(leerDetalle(poliza));
                }
            }

        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al registrar la poliza',
                'Error',
                0
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
}

export const confirmarPoliza = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let dif = localStorage.getItem('dif');
    if (parseFloat(dif) === 0.00) {
        localStorage.removeItem('idPolizaCompra');
        localStorage.removeItem('dif');
        NotificationManager.success(
            'Póliza guardada correctamente',
            'Exito',
            3000
        );
        const editar = window.location.href.includes('editar');
        if (editar){
            ruta = `/compras/${id_emp[5]}/compras/${id_emp[7]}`
            dispatch(push(ruta));
        }else{
            window.location.reload(true);
        }
        
    } else {
        Swal.fire({
            title: 'Modificar poliza',
            text: `Poliza con diferencia: ${new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(dif)}`,
            type: 'error',
        })
    }
}

export const leerPeriodo = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let id_periodo = id_emp[7];
    const params = { id, id_periodo };
    api.get('/periodos/periodo', params).then((response) => {
        localStorage.setItem('info_periodo', response.periodo.periodo);
        localStorage.setItem('fechaI', response.periodo.fecha_inicio);
        localStorage.setItem('fechaF', response.periodo.fecha_fin);
        dispatch({ type: PERIODO_D, periodoS: response.periodo });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar periodo',
            'Error',
            0
        );
    })
}

const eliminarDocumento = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`compra/${id}`).then(() => {
        dispatch(listar());
        NotificationManager.success(
            'Documento borrado correctamente',
            'Exito',
            3000
        );
    }).catch((error) => {
        
        let tableRows = '';
        error.msj.forEach(item => {
            tableRows += `<tr><td>${item.formato_fecha}</td><td>${item.serie}</td><td>${item.numero}</td><td>${item.tipo}</td></tr>`;
        })
        Swal.fire({
            title: 'Abonos aplicados',
            html: `
                <table>
                    <thead>
                        <tr>
                            <th style="width: 100px">Fecha</th>
                            <th style="width: 100px">Serie</th>
                            <th style="width: 100px">Número</th>
                            <th style="width: 100px">Tipo</th>
                        </tr>
                    </thead>
                    <tbody>
                        ${tableRows}
                    </tbody>
                </table>
            `,
            icon: 'warning',
            showCloseButton: true,
            confirmButtonColor: '#d33',
            confirmButtonText: 'Cerrar',
        }).then((result) => {
            if (result.isConfirmed) {
                
            }
        });
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const registroProveedor = (datos) => (dispatch, getStore) => {
    const data = getStore().form.FormCompras.values;
    console.log(data)
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    if (datos.validar_nit === 'S' && data.tipo_proveedor === 'L'){
        if (data.identificador === 'CF'){
            const formData = {
                identificador: data.identificador,
                nombre: data.nombre,
                direccion: data.direccion,
                telefono: data.telefono,
                correo: data.correo,
                limite: data.limite_credito,
                tipo_proveedor: data.tipo_proveedor,
                dias_credito: data.dias_credito,
                pais: data.pais,
                modulo: 'CMP',
                empresa: id_emp[5]
            }
            dispatch(setLoader(true));
            api.post('/proveedor/', formData).then(() => {
                NotificationManager.success(
                    'Proveedor creado',
                    'Exito',
                    3000
                );
                
            }).catch((error) => {
                NotificationManager.error(
                    error.msj,
                    'Error',
                    0
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }else{
            let valid = validar.valNit(data.identificador);
            if (valid === false){
                Swal.fire({
                    title: 'Proveedor',
                    text: 'Ingrese Nit válido',
                    type: 'error',
                })
            }else{
                const formData = {
                    identificador: data.identificador,
                    nombre: data.nombre,
                    direccion: data.direccion,
                    telefono: data.telefono,
                    correo: data.correo,
                    limite: data.limite_credito,
                    tipo_proveedor: data.tipo_proveedor,
                    dias_credito: data.dias_credito,
                    modulo: 'CMP',
                    pais: data.pais,
                    empresa: id_emp[5]
                }
                dispatch(setLoader(true));
                api.post('/proveedor/', formData).then(() => {
                    NotificationManager.success(
                        'Proveedor creado',
                        'Exito',
                        3000
                    );
                }).catch((error) => {
                    NotificationManager.error(
                        error.msj,
                        'Error',
                        0
                    );
                }).finally(() => {
                    dispatch(setLoader(false));
                });
            }
        }
    }else{
        if (datos.validar_nit === 'S' && data.tipo_proveedor === 'E'){
            const formData = {
                identificador: data.identificador,
                nombre: data.nombre,
                direccion: data.direccion,
                telefono: data.telefono,
                correo: data.correo,
                limite: data.limite_credito,
                tipo_proveedor: data.tipo_proveedor,
                dias_credito: data.dias_credito,
                modulo: 'CMP',
                pais: data.pais,
                empresa: id_emp[5]
            }
            dispatch(setLoader(true));
            api.post('/proveedor/', formData).then(() => {
                NotificationManager.success(
                    'Proveedor creado',
                    'Exito',
                    3000
                );
                
            }).catch((error) => {
                NotificationManager.error(
                    error.msj,
                    'Error',
                    0
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }
        if (datos.validar_nit === 'N' && data.tipo_proveedor === 'L'){
            const formData = {
                identificador: data.identificador,
                nombre: data.nombre,
                direccion: data.direccion,
                telefono: data.telefono,
                correo: data.correo,
                limite: data.limite_credito,
                tipo_proveedor: data.tipo_proveedor,
                dias_credito: data.dias_credito,
                modulo: 'CMP',
                pais: data.pais,
                empresa: id_emp[5]
            }
            dispatch(setLoader(true));
            api.post('/proveedor/', formData).then(() => {
                NotificationManager.success(
                    'Proveedor creado',
                    'Exito',
                    3000
                );
                
            }).catch((error) => {
                NotificationManager.error(
                    error.msj,
                    'Error',
                    0
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }
        if (datos.validar_nit === 'N' && data.tipo_proveedor === 'E'){
            const formData = {
                identificador: data.identificador,
                nombre: data.nombre,
                direccion: data.direccion,
                telefono: data.telefono,
                correo: data.correo,
                limite: data.limite_credito,
                tipo_proveedor: data.tipo_proveedor,
                dias_credito: data.dias_credito,
                modulo: 'CMP',
                pais: data.pais,
                empresa: id_emp[5]
            }
            dispatch(setLoader(true));
            api.post('/proveedor/', formData).then(() => {
                NotificationManager.success(
                    'Proveedor creado',
                    'Exito',
                    3000
                );
                
            }).catch((error) => {
                NotificationManager.error(
                    error.msj,
                    'Error',
                    0
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }
    }
}

export const registroProveedorSaldo = (datos) => (dispatch, getStore) => {
    const data = getStore().form.FormCompraSaldo.values;
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    if (datos.validar_nit === 'S'){
        if (data.identificador === 'CF'){
            const formData = {
                identificador: data.identificador,
                nombre: data.nombre,
                direccion: data.direccion,
                telefono: data.telefono,
                correo: data.correo,
                limite: data.limite_credito,
                tipo_proveedor: data.tipo_proveedor,
                dias_credito: data.dias_credito,
                pais: data.pais,
                modulo: 'CMP',
                empresa: id_emp[5]
            }
            dispatch(setLoader(true));
            api.post('/proveedor/', formData).then(() => {
                NotificationManager.success(
                    'Proveedor creado',
                    'Exito',
                    3000
                );
                
            }).catch((error) => {
                NotificationManager.error(
                    error.msj,
                    'Error',
                    0
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }else{
            let valid = validar.valNit(data.identificador);
            if (valid === false){
                Swal.fire({
                    title: 'Proveedor',
                    text: 'Ingrese Nit válido',
                    type: 'error',
                })
            }else{
                const formData = {
                    identificador: data.identificador,
                    nombre: data.nombre,
                    direccion: data.direccion,
                    telefono: data.telefono,
                    correo: data.correo,
                    limite: data.limite_credito,
                    tipo_proveedor: data.tipo_proveedor,
                    dias_credito: data.dias_credito,
                    modulo: 'CMP',
                    pais: data.pais,
                    empresa: id_emp[5]
                }
                dispatch(setLoader(true));
                api.post('/proveedor/', formData).then(() => {
                    NotificationManager.success(
                        'Proveedor creado',
                        'Exito',
                        3000
                    );
                }).catch((error) => {
                    NotificationManager.error(
                        error.msj,
                        'Error',
                        0
                    );
                }).finally(() => {
                    dispatch(setLoader(false));
                });
            }
        }
    }else{
        const formData = {
            identificador: data.identificador,
            nombre: data.nombre,
            direccion: data.direccion,
            telefono: data.telefono,
            correo: data.correo,
            limite: data.limite_credito,
            tipo_proveedor: data.tipo_proveedor,
            dias_credito: data.dias_credito,
            modulo: 'CMP',
            pais: data.pais,
            empresa: id_emp[5]
        }
        dispatch(setLoader(true));
        api.post('/proveedor/', formData).then(() => {
            NotificationManager.success(
                'Proveedor creado',
                'Exito',
                3000
            );
            
        }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                0
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
}


export const listarProyectos = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    api.get('/proyecto', params).then((response) => {
        const pro = [];
        response.forEach(item=>{
            pro.push({
                value: item.id,
                label: item.proyecto
            })
        })
        dispatch({ type: LISTADO_PROYECTOS, listaProyectos: pro });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar los proyectos',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const listarSubProyectos = (id) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let idE = id_emp[5]
    let idPro = id;
    let detPro = 'proyecto'
    const params = { idE, idPro, detPro };
    api.get('/subproyecto', params).then((response) => {
        const subpro = [];
        response.forEach(item=>{
            subpro.push({
                value: item.id,
                label: item.subproyecto
            })
        })
        dispatch({ type: LISTADO_SUBPROYECTOS, listaSubProyectos: subpro });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar los subproyectos',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const listarFases = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    api.get('/fase', params).then((response) => {
        const fase = [];
        response.forEach(item=>{
            fase.push({
                value: item.id,
                label: item.numero + ' - ' + item.descripcion
            })
        })
        dispatch({ type: LISTADO_FASES, listaFases: fase });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar los proyectos',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const listarMateriales = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id, search}
    return api.get('/material/detalle_materiales',params).then(data=>{
        const list_material = [];
        if(data){
            data.forEach(item=>{
                list_material.push({
                    value: item.id,
                    label: item.descripcion
                })
            })
        }
        return list_material ;
    }).catch((error)=>{
        return [];
    })
}

const leerDetalleProductos = id => (dispatch) => {
    api.get(`/movimiento_inventario_detalle/${id}`).then((response)=>{
        dispatch({ type: DETALLE_PRODUCTOS, detalleProducto: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};

const leerDetalleMateriales = id => (dispatch) => {
    api.get(`/compra_material_detalle/${id}`).then((response)=>{
        dispatch({ type: DETALLE_MATERIALES, detalleMaterial: response.detalle });
        dispatch({ type: ENCABEZADO_MATERIALES, encabezado: response.encabezado });
        if (response.encabezado.proyecto){
            dispatch(listarSubProyectos(response.encabezado.proyecto))
        }
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};

export const registroDetalleMaterial = (impuesto) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormDetallePro.values;
    const detalle = localStorage.getItem('id_detalle_material_cmp');
    let PIva = parseFloat(parseFloat(parseFloat(impuesto.porcentaje_iva)/100)+1).toFixed(2);
    if (data.cantidad === undefined || data.cantidad === ''){
        Swal.fire({
            title: 'Detalle proyecto',
            text: 'Ingrese cantidad',
            type: 'error',
        })
    }else{
        if (data.precio === undefined || data.precio === ''){
            Swal.fire({
                title: 'Detalle proyecto',
                text: 'Ingrese precio',
                type: 'error',
            })
        }else{
            const formData = {
                proyecto: localStorage.getItem('proyecto_compra'),
                subproyecto: data.subproyecto,
                fase: data.fase,
                material: data.material.value,
                cantidad: data.cantidad,
                precio_unitario: data.precio,
                total: localStorage.getItem('total_material'),
                id_compra: localStorage.getItem('compra_id'),
                tipoDoc: 'COMP',
                empresa: id_emp[5],
                base: parseFloat(parseFloat(localStorage.getItem('total_material'))/PIva).toFixed(2),
                detalle: detalle
            }
            dispatch(setLoader(true));
            api.post('/compra_material/', formData).then((response) => {
                
                if (detalle){
                    dispatch(initializeForm('FormDetallePro', response));
                    dispatch(leerDetalleMateriales(response.id_detalle));
                }else{
                    dispatch(initializeForm('FormDetallePro', response));
                    localStorage.setItem('id_detalle_material_cmp', response.id_detalle);
                    dispatch(leerDetalleMateriales(response.id_detalle));
                }
                NotificationManager.success(
                    'Producto creado correctamente',
                    'Exito',
                    3000
                );
            }).catch((error) => {
                NotificationManager.error(
                    error.msj,
                    'Error',
                    0
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }
    }
}

const eliminarLineaProducto = id => (dispatch) => {
    const detalle = localStorage.getItem('id_detalle_inventario');
    dispatch(setLoader(true));
    api.eliminar(`/movimiento_inventario_detalle/${id}`).then(() => {
        dispatch(leerDetalleProductos(detalle));
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


const eliminarLineaMaterial = id => (dispatch) => {
    const detalle = localStorage.getItem('id_detalle_material_cmp');
    dispatch(setLoader(true));
    api.eliminar(`/compra_material_detalle/${id}`).then(() => {
        dispatch(leerDetalleMateriales(detalle));
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


export const leerPeriodoD = (id, id_periodo) => (dispatch) => {
    const params = { id, id_periodo };
    api.get('/periodos/periodo', params).then((response) => {
        dispatch({ type: PERIODO_D, periodoD: response.periodo });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar periodo',
            'Error',
            2000
        );
    })
}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        if (error.status === 401){
            NotificationManager.error(
              error.body.msj,
              'Error',
              0
            );
        }
        if (error.status === 400){
            NotificationManager.error(
              'Ocurrió un error en buscar modulo',
              'Error',
              0
            );
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const parametrosEmpresa = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id}
    dispatch(setLoader(true));
    api.get('/empresa/empresa_parametros', params).then((response) => {
        dispatch({type: PARAMETRO, datos: response });
        api.get('/empresa/empresa_impuesto', params).then((imp) => {
            if (imp.validar === 'false'){
                Swal.fire({
                    title: 'Compra local',
                    text: imp.msj,
                    type: 'info',
                    allowOutsideClick: false,
                    confirmButtonText: "Continuar",
                }).then((result) => {
                    if (result.value) {
                        let ruta = `/ajustes/${id_emp[5]}/impuestos`
                        dispatch(push(ruta));
                    }
                });
            }
            if (imp.validar === 'true'){
                api.get('/empresa/empresa_configuracion_poliza', params).then((datos) => {
                    if (datos.validar === 'false'){
                        Swal.fire({
                            title: 'Compra local',
                            text: datos.msj,
                            type: 'info',
                            allowOutsideClick: false,
                            confirmButtonText: "Continuar",
                        }).then((result) => {
                            if (result.value) {
                                let ruta = `/contabilidad/${id_emp[5]}/config`
                                dispatch(push(ruta));
                            }
                        });
                    }
                    
                }).catch((error)=>{
                    
                    NotificationManager.error(
                        'Ocurrió un error al consultar impuesto',
                        'Error',
                        8000
                    );
                })
            }
            
        }).catch((error)=>{
            
            NotificationManager.error(
                'Ocurrió un error al consultar parametros',
                'Error',
                8000
            );
        })
    }).catch((error)=>{
        
        NotificationManager.error(
            'Ocurrió un error al consultar parametros',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const listarBodegas = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    api.get('/bodega', params).then((response) => {
        const bodega = [];
        response.forEach(item=>{
            bodega.push({
                value: item.id,
                label: item.numero + ' / ' + item.bodega
            })
        })
        dispatch({ type: LISTADO_BODEGAS, listaBodegas: bodega });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar los proyectos',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const listarProductos = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id, search}
    return api.get('/producto/empresa_productos_cod',params).then(data=>{
        const list_producto = [];
        if(data){
            data.forEach(item=>{
                list_producto.push({
                    value: item.id,
                    label: item.SKU + ' / ' + item.producto
                })
            })
        }
        return list_producto ;
    }).catch((error)=>{
        return [];
    })
}

export const registroProductoInventario = (impuesto, datos, parametro) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormInventario.values;
    const detalle = localStorage.getItem('id_detalle_inventario');
    let PIva = parseFloat(parseFloat(parseFloat(impuesto.porcentaje_iva)/100)+1).toFixed(2);
    let totalPro = parseFloat(parseFloat(data.cantidad)*parseFloat(data.precio)).toFixed(2);
    
    const formData = {
        bodega: data.bodega,
        producto: data.producto.value,
        cantidad: data.cantidad,
        precio_unitario: data.precio,
        total: totalPro,
        id_compra: detalle ? '' : parametro === 'N' ? datos.idCompra : datos.id,
        fecha: detalle ? '' : parametro === 'N' ? datos.compra.fecha_registro : datos.fecha_registro,
        costo_unitario_s_iva: parseFloat(parseFloat(data.precio)/PIva).toFixed(6),
        total_s_iva: parseFloat(parseFloat(totalPro)/PIva).toFixed(2),
        motivo: 'CMP',
        descripcion: '',
        empresa: id_emp[5],
        tipoDoc: 'COMP',
        periodo: id_emp[7],
        tipo_cambio: localStorage.getItem('TC_compra'),
        detalle: detalle,
        lote: data.lote ? data.lote : '',
        fecha_vencimiento: data.fecha_vencimiento ? data.fecha_vencimiento : ''
    }
    dispatch(setLoader(true));
    api.post('/movimiento_inventario/', formData).then((response) => {
        response.bodega = response.detalle.bodega
        if (detalle){
            dispatch(initializeForm('FormInventario', response));
            dispatch(leerDetalleProductos(response.encabezado.id));
        }else{
            dispatch(initializeForm('FormInventario', response));
            localStorage.setItem('id_detalle_inventario', response.encabezado.id);
            dispatch(leerDetalleProductos(response.encabezado.id));
        }
        NotificationManager.success(
            'Producto creado correctamente',
            'Exito',
            3000
        );
    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const listarPaises = () => (dispatch) => {
    return api.get("/paises").then(response=>{
        const pais = [];
        response.results.forEach(item=>{
            pais.push({
                value: item.id,
                label: item.pais.toUpperCase()
            })
        })
        dispatch({ type: LISTADO_PAISES, listaP: pais });
    }).catch(error=>{
        
        NotificationManager.error(
            'Ocurrió un error al listar paises',
            'Error',
            8000
        );
    })
}

export const listarImpuestos = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5]
    const params = { id };
    dispatch(setLoader(true));
    api.get('/impuesto/impuesto_empresa', params).then((response) => {
        dispatch({ type: LISTADO_IMPUESTO, impuesto: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar impuesto',
            'Error',
            3000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const tipoCambio = (fechaInicio, fechaFinal) => (dispatch, getStore) => {
    const data = getStore().form.FormCompras.values;
    let fechaI = moment(fechaInicio);
    let fechaF = moment(fechaFinal);
    let fecha = moment(data.fechaRegistro)
    if (fecha>=fechaI && fecha<=fechaF){
        if (data.en_dolares){
            let fecha = data.fechaRegistro;
            let ruta = window.location.href;
            let id_emp = ruta.split('/');
            let idEmpre = id_emp[5];
            const params = {fecha, idEmpre }
            dispatch(setLoaderC(true));
            return api.get('/tipo_cambio/tipo_cambio_fecha/',params).then((response) => {
                return response;
            }).catch((error)=>{
                NotificationManager.error(
                    'Ocurrió un error al consultar tipo de cambio',
                    'Error',
                    2000
                );
            }).finally(() => {
                dispatch(setLoaderC(false));
            });
        }
    }else{
        Swal.fire({
            title: 'Compra local',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })        
    }
}

const leerDetalleInventario = id => (dispatch) => {
    api.get(`/movimiento_inventario_detalle/${id}`).then((response)=>{
        dispatch({ type: DETALLE_PRODUCTOS, detalleProducto: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar detalle del inventario',
            'Error',
            4000
        );
    })
};

export const leerSaldo = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/compra/${id}`).then((response) => {
        response.fechaRegistro = response.fecha_registro;
        response.proveedorN = response.proveedorN;
        dispatch(initializeForm('FormCompraSaldo', response));
        
    }).catch((error) => {
        
        NotificationManager.error(
            'Ocurrió un error al consultar compra',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

const eliminarFacturaSaldo = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`compra/${id}`).then(() => {
        dispatch(listarFacSaldos());
        NotificationManager.success(
            'Documento borrado correctamente',
            'Exito',
            3000
        );
    }).catch((error) => {
        
        let tableRows = '';
        error.msj.forEach(item => {
            tableRows += `<tr><td>${item.formato_fecha}</td><td>${item.serie}</td><td>${item.numero}</td><td>${item.tipo}</td></tr>`;
        })
        Swal.fire({
            title: 'Abonos aplicados',
            html: `
                <table>
                    <thead>
                        <tr>
                            <th style="width: 100px">Fecha</th>
                            <th style="width: 100px">Serie</th>
                            <th style="width: 100px">Número</th>
                            <th style="width: 100px">Tipo</th>
                        </tr>
                    </thead>
                    <tbody>
                        ${tableRows}
                    </tbody>
                </table>
            `,
            icon: 'warning',
            showCloseButton: true,
            confirmButtonColor: '#d33',
            confirmButtonText: 'Cerrar',
        }).then((result) => {
            if (result.isConfirmed) {
                
            }
        });
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const actions = {
    listar,
    registroCompra,
    modificarCompra,
    leer,
    listarTiposDocu,
    listarProveedores,
    leerDetalle,
    eliminarLinea,
    listarCuentas,
    listarCC,
    listarNiveles,
    registroCuenta,
    reporteCuentas,
    registroPolizaDetalle,
    confirmarPoliza,
    leerPeriodo,
    registroPolizaDetalleSCC,
    listarProveedoresD,
    eliminarDocumento,
    registroProveedor,
    listarProyectos,
    listarSubProyectos,
    listarFases,
    listarMateriales,
    eliminarLineaProducto,
    leerDetalleMateriales,
    registroDetalleMaterial,
    eliminarLineaMaterial,
    leerPeriodoD,
    getModulo,
    parametrosEmpresa,
    listarBodegas,
    listarProductos,
    registroProductoInventario,
    leerDetalleProductos,
    listarPaises,
    listarImpuestos,
    tipoCambio,
    leerDetalleInventario,
    listarFacSaldos,
    registroCompraSaldo,
    registroProveedorSaldo,
    modificarCompraSaldo,
    leerSaldo,
    eliminarFacturaSaldo
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_COMPRA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [DETALLE_POLIZA]: (state, { detalle }) => {
        return {
            ...state,
            detalle,
        };
    },
    [LISTADO_TIPOS_DOCU]: (state, { tipos }) => {
        return {
            ...state,
            tipos,
        };
    },
    [LISTADO_PROVEEDOR]: (state, { prov }) => {
        return {
            ...state,
            prov,
        };
    },
    [NIVEL]: (state, { nivel }) => {
        return {
            ...state,
            nivel,
        };
    },
    [LOADERC]: (state, { loaderC }) => {
        return {
            ...state,
            loaderC,
        };
    },
    [GUARDAR_POLIZA]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
    [GUARDAR_COMPRA]: (state, { lectura_data }) => {
        return {
            ...state,
            lectura_data,
        };
    },
    [LISTADO_PROYECTOS]: (state, { listaProyectos }) => {
        return {
            ...state,
            listaProyectos,
        };
    },
    [LISTADO_SUBPROYECTOS]: (state, { listaSubProyectos }) => {
        return {
            ...state,
            listaSubProyectos,
        };
    },
    [LISTADO_FASES]: (state, { listaFases }) => {
        return {
            ...state,
            listaFases,
        };
    },
    [DETALLE_MATERIALES]: (state, { detalleMaterial }) => {
        return {
            ...state,
            detalleMaterial,
        };
    },
    [PERIODO_D]: (state, { periodoD }) => {
        return {
            ...state,
            periodoD,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [PARAMETRO]: (state, { datos }) => {
        return {
            ...state,
            datos,
        };
    },
    [LISTADO_BODEGAS]: (state, { listaBodegas }) => {
        return {
            ...state,
            listaBodegas,
        };
    },
    [DETALLE_PRODUCTOS]: (state, { detalleProducto }) => {
        return {
            ...state,
            detalleProducto,
        };
    },
    [LISTADO_PAISES]: (state, { listaP }) => {
        return {
            ...state,
            listaP,
        };
    },
    [LISTADO_IMPUESTO]: (state, { impuesto }) => {
        return {
            ...state,
            impuesto,
        };
    },
    [ENCABEZADO_MATERIALES]: (state, { encabezado }) => {
        return {
            ...state,
            encabezado,
        };
    },
    [DATOS_COMPRA]: (state, { datosCmp }) => {
        return {
            ...state,
            datosCmp,
        };
    },
};

export const initialState = {
    loader: false,
    tipos: [],
    prov: [],
    lectura: {
        polizaDetalle_polizaEncabezado: []
    },
    lectura_data: [],
    listaProyectos: [],
    listaSubProyectos: [],
    listaFases: [],
    periodoD: [],
    listaBodegas: [],
    modulo: [],
    nivel: [],
    datos: [],
    listaP: [],
    loaderC: false,
    detalle: [],
    impuesto: [],
    encabezado: [],
    detalleMaterial: [],
    detalleProducto: [],
    data: [],
    datosCmp: [],
};

export default handleActions(reducers, initialState)