import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validatorFromFunction, validators } from 'validate-redux-form';
import { renderField } from '../../Utils/renderField';

const emailValidation = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
  'Dirección de correo electrónico no válido' : undefined

const estilo = {
    fontSize: "14px",
};

const CorreoForm = (props) => {
    const { handleSubmit } = props;
    return (
        <form className="form-validate mb-lg" onSubmit={handleSubmit}>

            <div className="mb-2 d-flex flex-column align-items-center">
                <div className='col-6'>
                    <Field
                        name="username"
                        placeholder="Correo electrónico"
                        component={renderField}
                        type="email"
                        validate={emailValidation}
                        className="form-control"
                        msj="Correo electrónico"
                    />
                </div>
            </div>
            
            <div className="buttons-box">
                <button type="submit" style={estilo}
                    className="btn btn-primary m-1 align-self-center">
                        Enviar correo electrónico
                </button>
                <a
                    href='/#/login' style={estilo}
                    className='btn btn-secondary m-1 align-self-center'
                >
                    Cancelar
                </a>
            </div>
        </form>
    );
};

export const matchPassword = (pass, confirm) => validatorFromFunction(value => {
    return pass === confirm;
});

export default reduxForm({
    form: 'correoForm', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            username: validators.exists()('Este campo es requerido'),
        });
    },
})(CorreoForm);
