import React from 'react';
import {reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import LoadMask from "../../Utils/LoadMask/LoadMask";
import FormularioCorreo from './CorreoForm';

function EnviarCorreo(props) {
    const { revisarCorreo, loader } = props;

    return (
        <div className="blue-gradient-bg">
            <div className="d-flex flex-column align-items-center pt-3 bienvenida title">
                <img className="mt-5 imageReg"
                    src={require('assets/img/SIFW-LOGO-Red.png')}
                    alt="Logo" /> 
            </div>
            <br />
            <div className="login-wrapper">
                <div className="card2 card border-0 px-4 py-5 bg-dark col-lg-6 col-md-4 col-11">
                    <h5 className="text-center pv mb-4 title">Cambiar contraseña</h5>
                    <LoadMask loading={loader} dark>
                        <FormularioCorreo
                            onSubmit = {revisarCorreo}
                        />
                    </LoadMask>
                </div>
            </div>
        </div>
    )

}
EnviarCorreo.propTypes = {
    revisarCorreo: PropTypes.func,
    loader: PropTypes.bool,
};

export default reduxForm({
    form: 'EnvrioCorreoForm', //identificador unico
})(EnviarCorreo)

