import React, { Component } from 'react';
import Grid from '../../Utils/GridFiltro';
import PropTypes from 'prop-types';
import { standardActions } from '../../Utils/Grid/StandardActions';

class CuentasContablesList extends Component{
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false
    };

    componentDidMount = () => {
        const { listarCuentasPage } = this.props;
        listarCuentasPage();
    }
    
    render(){
        const { data, loader, eliminar, nivel, reporteCuentas} = this.props;
        const estilo1 = {
            fontSize: "15px",
        };
        const estilo2 = {
            fontSize: "14px",
        };
        
        let ruta = window.location.href;
        let datos = ruta.split('/');
        return(
            <React.Fragment>
                <br />
                <h3 className="p-2 bg-secondary title">Cuentas Contables</h3>
                <div className="row mb-2">
                    <div className="col d-flex justify-content-between">
                        {nivel.length > 0 &&
                            <a
                                href={`/#/contabilidad/${datos[5]}/cuentas_contables/crear`}
                                className='btn btn-primary' style={estilo2}
                            >
                                Crear Cuenta
                            </a>
                        }
                        <button className='btn btn-dark float-right' onClick={reporteCuentas}
                            data-bs-toggle="tooltip" data-bs-placement="left" title="Generar reporte">
                                <i className="material-icons" style={estilo1}>insert_drive_file</i>
                        </button>
                    </div>
                </div>    
                {data &&
                    <Grid 
                        hover 
                        striped 
                        data={data} 
                        loading={loader} 
                        headerStyle={ { background: 'black' } } 
                        //onPageChange={onPageChange}
                        //onSortChange={onSortChange} 
                    >
                        <TableHeaderColumn
                            isKey
                            dataField="cuenta"
                            width="200"
                            dataSort
                            thStyle={ 
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Cuenta
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="nombre"
                            dataSort
                            thStyle={ 
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Descripción
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            thStyle={
                            {color: 'white'}}
                            width="200"
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            dataFormat={standardActions({ 
                                editar: "cuentas_contables", 
                                ver: "cuentas_contables",
                                eliminar: eliminar })} 
                            
                        >
                            Acciones
                        </TableHeaderColumn>
                    </Grid>
                }
            </React.Fragment>
        );
    }
}

export default CuentasContablesList;
