import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import LoginForm from './LoginFageForm';
import './login.css';
import LoadMask from "Utils/LoadMask/LoadMask";

class LoginFage extends Component {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
    };

    render() {
        const { loginFage, loader } = this.props;
        
        if (localStorage.getItem('tokenF')) {
            return (<Redirect to="/home" />);
        }
        
        return (
            <section className="vh-100">
                <div className="container-fluid h-custom blue-gradient-bg-Fage">
                    <div className="d-flex justify-content-center align-items-center h-100">
                        <div className="py-4">
                            <div className="login-wrapper">
                            <div className="card company" style={{ width: "30rem" }}>
                                <div className="card-body">
                                    <div div className='text-center imageBox'>
                                        <img className='imageLogo' src={require('assets/img/LAFAGE-logo.jpg')} alt="Card image"/>
                                    </div>
                                    <h5 className="text-center pv mb-3 mt-1 bienvenida">Iniciar sesión</h5>
                                    <LoadMask loading={loader}>
                                        <LoginForm onSubmit={loginFage} />
                                    </LoadMask>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="d-flex flex-column flex-md-row text-center text-md-start justify-content-between py-4 px-4 px-xl-5 bg-foo">
                    <div className="text-white mb-3 mb-md-0">
                        Copyright&copy; 2021 ACONSI - Todos los derechos reservados.
                    </div>
                    <div>
                        <Link className="mr-3" to="/cambiar-password" style={{ textDecoration: 'none', color: 'blue' }}>¿Olvidó su contraseña?</Link>
                    </div>
                </div>
            </section>
        );
    }
}

export default LoginFage;
