import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/auto_ventas/tipo';
import TipoList from './TipoList';


const ms2p = (state) => {
  return {
    ...state.auto_ventas_tipo,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(TipoList);
