import React, { useState } from "react";
import {
    FicInput,
    FicComboBox,
    FicNumber,
    FicDatePicker,
    FicTextArea,
    FicCheckbox,
} from "../../Utils/FormInputComponents";
import Banner from "../common/Banner";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { validar } from "../../components/ValidarNit";
import GenBtn from "../../components/GenBtn";
import InputsContainer from "../common/InputsContainer";
import {
    crear,
    getPacientes,
    getPatologias,
    getUnidadesIgss,
    getClientes,
    getNumeroPatologia,
    getSeriePatologia,
    getSeriePatologiaComboBox,
    getSiguienteSubSeriePatologia,
} from "./actions";
import LoadMask from "../../Utils/LoadMask/LoadMask";
import { withRouter } from "react-router";
import { useEffect, useRef } from "react";
import {
    today,
    getLocalTimeZone,
    parseDate,
    startOfMonth,
    endOfMonth,
    isEqualMonth,
} from "@internationalized/date";
import OptionsBar from "../common/OptionsBar";
import { getPeriodo, getPeriodoDates, getPeriodoIso } from "../utils";
import useUpdateEffect from "../../../../hooks/useUpdateEffect";
import { getSettings } from "../utils";
import { getSchema, getDefaultValues, processFormData } from "./helpers";

const BANNER_TEXT = "Crear ingreso de patologia";

const TIPO_OPTIONS = [
    { value: "privada", label: "Privada" },
    { value: "afiliada", label: "Afiliada" },
    { value: "citologia", label: "Citologia" },
    { value: "inmunohistoquimica", label: "Inmunohistoquimica" },
];

function Crear({ history, ...props }) {
    const [isLoading, setIsLoading] = useState(false);
    const { startOfMonthPeriodo, endOfMonthPeriodo } = getPeriodoDates();
    const periodoData = getPeriodo();
    const [withSubSerie, setWithSubSerie] = useState(false);
    const firstRender = useRef(true);
    const settings = getSettings();

    const schema = getSchema();

    const { control, reset, handleSubmit, setValue, watch } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: getDefaultValues(),
    });

    const tipo = watch("tipo") ? watch("tipo").value : null;
    const numero_patologia = watch("numero_patologia");
    const serie = watch("serie") ? watch("serie").value : null;

    async function setSeriePatologia() {
        let serie = await getSeriePatologia();
        if (settings.manual_series) return;
        serie = serie[0];
        setValue("serie", { value: serie.id, label: serie.nombre });
    }

    async function setNumeroPatologia() {
        let response = await getNumeroPatologia(serie);
        let numero_patologia_value = response.numero_patologia;

        setValue("numero_patologia", numero_patologia_value);
    }

    async function fetchSubSerie() {
        let response = await getSiguienteSubSeriePatologia(
            numero_patologia,
            serie
        );
        if (!response) return;
        setValue("sub_serie", response.sub_serie);
    }

    useEffect(() => {
        setNumeroPatologia();
    }, [serie, withSubSerie]);

    useEffect(() => {
        function setSerie() {
            if (settings.manual_series) return;
            if (firstRender.current) {
                firstRender.current = false;
                setSeriePatologia();
            }
            if (numero_patologia.length === 6) {
                setSeriePatologia();
            }
        }
        setSerie();
    }, [numero_patologia]);

    useEffect(() => {
        setValue("clinica", null);
    }, [tipo]);

    useEffect(() => {
        if (withSubSerie && numero_patologia.length === 6) {
            fetchSubSerie();
        }
    }, [numero_patologia, serie, withSubSerie]);

    useUpdateEffect(() => {
        if (isEqualMonth(startOfMonthPeriodo, today(getLocalTimeZone()))) {
            setValue("fecha_recibido", today(getLocalTimeZone()).toString());
        } else {
            setValue("fecha_recibido", null);
        }
    }, [startOfMonthPeriodo]);

    async function onSubmit(data) {
        setIsLoading(true);
        const processedData = processFormData(data, withSubSerie);
        const shouldReset = await crear(processedData);
        setIsLoading(false);
        if (shouldReset) reset();
        setSeriePatologia(); // Volver a generar la serie porque el valor se vuelve null despues de "guardar"
        getNumeroPatologia();
    }

    return (
        <React.Fragment>
            <OptionsBar />
            <Banner>{BANNER_TEXT}</Banner>
            <GenBtn
                onPress={() => history.goBack()}
                style={{ margin: "5px 0" }}
            >
                Regresar
            </GenBtn>
            <LoadMask loading={isLoading} color="#bd0034" blur={true}>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    style={{ display: "flex", flexDirection: "column" }}
                >
                    {/* <div style={{display: "flex", flexDirection: }}> */}

                    <div
                        style={{
                            display: "flex",
                            alignSelf: "center",
                            gap: "0.5rem",
                            // backgroundColor: "#1e1e1e",
                        }}
                    >
                        <FicCheckbox
                            name=""
                            label="Con sub serie?"
                            onChange={setWithSubSerie}
                            value={withSubSerie}
                            style={{ alignSelf: "center" }}
                            nonForm
                        />
                        <FicComboBox
                            control={control}
                            label="Serie"
                            name="serie"
                            componentStyle={{ width: "140px" }}
                            disabled={!settings.manual_series}
                            loadOptions={getSeriePatologiaComboBox}
                        />

                        <FicInput
                            control={control}
                            label="Numero de patologia"
                            name="numero_patologia"
                            style={{ width: "155px" }}
                        />
                        {withSubSerie && (
                            <FicInput
                                control={control}
                                label="Sub serie"
                                name="sub_serie"
                                style={{ width: "65px" }}
                                isDisabled
                            />
                        )}
                    </div>
                    {/* </div> */}
                    <div style={{ backgroundColor: "#2b2b2b" }}>
                        <InputsContainer>
                            <FicComboBox
                                control={control}
                                label="Tipo"
                                name="tipo"
                                nonAsync
                                options={TIPO_OPTIONS}
                            />
                            {tipo === "inmunohistoquimica" && (
                                <FicComboBox
                                    control={control}
                                    label="Patologia"
                                    name="patologia"
                                    loadOptions={getPatologias}
                                />
                            )}
                            <FicInput
                                control={control}
                                label="Paciente"
                                name="paciente"
                            />
                            {tipo === "afiliada" && (
                                <FicInput
                                    control={control}
                                    label="Afiliacion"
                                    name="afiliacion"
                                />
                            )}
                            <FicNumber
                                control={control}
                                label="Edad"
                                name="edad"
                            />
                            <FicComboBox
                                control={control}
                                label="Clinica"
                                name="clinica"
                                loadOptions={(arg) => getClientes(arg, tipo)}
                            />

                            {tipo === "afiliada" && (
                                <FicComboBox
                                    control={control}
                                    label="IGSS"
                                    name="igss"
                                    loadOptions={getUnidadesIgss}
                                />
                            )}
                            <FicInput
                                control={control}
                                label="Medico"
                                name="medico"
                            />
                            <FicDatePicker
                                control={control}
                                label="Fecha recibido"
                                name="fecha_recibido"
                                minValue={startOfMonthPeriodo}
                                maxValue={endOfMonthPeriodo}
                            />
                            {/* <FicNumber
                                control={control}
                                label="Costo del examen"
                                name="costo"
                                formatOptions={{ minimumFractionDigits: 2 }}
                            /> */}
                        </InputsContainer>
                        <FicTextArea
                            style={{ padding: "0 2rem 1rem 2rem" }}
                            control={control}
                            label="Descripcion macroscopica"
                            name="descripcion_macroscopica"
                        />
                        <FicTextArea
                            style={{ padding: "0 2rem 1rem 2rem" }}
                            control={control}
                            label="Especimen"
                            name="especimen"
                        />
                    </div>
                    <GenBtn style={{ margin: "1rem 0" }} type="submit">
                        Guardar
                    </GenBtn>
                </form>
            </LoadMask>
        </React.Fragment>
    );
}
export default withRouter(Crear);
