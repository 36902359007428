import { func } from "prop-types";
import React from "react";
import { useEffect } from "react";
import useUpdateEffect from "../../../../hooks/useUpdateEffect";
import {
    Checkbox,
    TableHeader,
    Button,
    Cell,
    Column,
    Row,
    Table,
    TableBody,
    DropIndicator,
    FieldError,
    Input,
    Label,
    Text,
    TextField,
    NumberField,
    Group,
} from "react-aria-components";
import styled, { keyframes } from "styled-components";
import { useState } from "react";

const StyledTextField = styled(TextField)`
    display: flex;
    flex-direction: column;
    width: auto;
    height: auto;
    color: var(--text-color);

    .react-aria-Input,
    .react-aria-TextArea {
        padding: 0 0.286rem;
        border: 1px solid var(--border-color);
        border-radius: 6px;
        background: #1e1e1e;
        font-size: 1.143rem;
        color: var(--field-text-color);
        transition: box-shadow 400ms ease, border 250ms ease;

        &[data-focused] {
            outline: 1px solid var(--focus-ring-color);
            border-color: var(--focus-ring-color);
            box-shadow: 0 0 0 1px var(--focus-ring-color);
        }
    }
`;

const StyledNumberField = styled(NumberField)`
    --focus-ring-color: #bababa;
    --border-color: #bababa;
    margin-bottom: 8px;
    color: var(--text-color);

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &[data-focus-within] {
        outline: 1px solid var(--focus-ring-color);
        .react-aria-Input,
        .react-aria-Button {
            border-color: #bd0034;
        }
    }

    .react-aria-Group {
        display: inline-block;
    }

    .react-aria-Button {
        font-size: 1.4rem;
        width: 2.3rem;
        padding: 0;
        background: #1e1e1e;
        border: none;
        color: var(--text-color);

        &[slot="decrement"] {
            border-radius: 4px 0 0 4px;
            border-right: 1px solid var(--border-color);
        }

        &[slot="increment"] {
            border-radius: 0 4px 4px 0;
            border-left: 1px solid var(--border-color);
        }
    }

    .react-aria-Input {
        width: auto;
        background: #1e1e1e;
        color: var(--text-color);
        border: 1px solid #bd0034;
        border-radius: 4px;
        margin: 0 -1px;
        z-index: 5;
        font-size: 1rem;
        padding: 0.429rem 0.571rem;
        outline: none;
        /* width: 6rem; */
        flex: 1;
        transition: box-shadow 400ms ease, border 250ms ease;

        &[data-focused] {
            outline: 1px solid var(--focus-ring-color);
            border-color: var(--focus-ring-color);
            box-shadow: 0 0 0 1px var(--focus-ring-color);
        }
    }
`;

const TableContainer = styled.div`
    padding: 1rem;
    border: 1px solid #bd0034;
    border-radius: 7px;
    /* background-color: #1e1e1e; */
    background-color: #262626;
    display: flex;
    flex-direction: column;
    min-width: fit-content;
`;

const StyledTable = styled(Table)`
    margin: 0;
    padding: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: whitesmoke;
    text-align: left;
    border-radius: 7px;
    padding: 0.286rem;
    outline: none;
    border-spacing: 0;
    min-height: 100px;
    align-self: start;
    max-width: 100%;
    width: 100%;
    forced-color-adjust: none;
`;

const StyledCheckbox = styled(Checkbox)`
    --label-color: rgb(236, 236, 236);
    --deselected-color: gray;
    --deselected-color-pressed: #981c35;
    --selected-color: rgb(202, 21, 57, 0.5);
    --selected-color-border: rgb(202, 21, 57);
    --selected-color-pressed: lch(from rgb(202, 21, 57) calc(l - 10%) c h);
    --checkmark-color: white;
    --invalid-color: red;
    --invalid-color-pressed: red;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.571rem;
    font-size: 0.95rem;
    color: var(--label-color);
    border-radius: 4px;
    margin: 0;
    padding: 0;
    width: fit-content;

    .checkbox {
        width: 1.143rem;
        height: 1.143rem;
        border: 2px solid var(--deselected-color);
        border-radius: 4px;
        transition: all 200ms;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    & svg {
        width: 1rem;
        height: 1rem;
        fill: none;
        stroke: var(--checkmark-color);
        stroke-width: 3px;
        stroke-dasharray: 22px;
        stroke-dashoffset: 66;
        transition: all 200ms;
    }

    &[data-pressed] .checkbox {
        border-color: var(--deselected-color-pressed);
    }

    &[data-selected],
    &[data-indeterminate] {
        .checkbox {
            border-color: var(--selected-color-border);
            background: var(--selected-color);
        }

        &[data-pressed] .checkbox {
            border-color: var(--selected-color-pressed);
            background: var(--selected-color-pressed);
        }

        & svg {
            stroke-dashoffset: 44;
        }
    }

    &[data-indeterminate] {
        & svg {
            stroke: none;
            fill: var(--checkmark-color);
        }
    }

    &[data-focused] {
        outline: 2px solid var(--focus-ring-color);
        outline-offset: -1px;
    }

    &[data-focus-visible] {
        outline: 2px solid var(--focus-ring-color);
        outline-offset: -1px;
    }

    &[data-disabled] {
        opacity: 0.4;
    }
`;

const StyledRow = styled(Row)`
    --row-border-color: hsl(0, 0%, 31%, 0.5);
    --focus-ring-color: #bababa;
    --radius: 7px;
    --radius-top: 6px;
    --radius-bottom: 6px;
    outline: none;
    position: relative;
    border-radius: var(--radius);
    /* border: 1px solid var(--row-border-color); */
    border-top: 1px solid var(--row-border-color);
    border-bottom: 1px solid var(--row-border-color);
    clip-path: inset(0 round var(--radius)); /* firefox */
    :first-child {
        border-radius: 7px 7px 0 0;
    }
    transform: scale(1);

    :last-child {
        border-radius: 0 0 7px 7px;
    }

    &[data-focused] {
        outline: 2px solid var(--focus-ring-color);
        outline-offset: -3px;
    }

    &[data-focus-visible] {
        outline: 2px solid var(--focus-ring-color);
        outline-offset: -3px;
    }

    &[data-selected] {
        /* border: 1px solid #bd0034; */
        background-color: hsl(343, 100%, 37%, 0.25);
        /* color: var(--highlight-foreground); */
    }

    @supports selector(:has(.foo)) {
        .react-aria-Row[data-selected]:has(+ [data-selected]),
        .react-aria-Row[data-selected]:has(
                + .react-aria-DropIndicator + [data-selected]
            ) {
            --radius-bottom: 0px;
        }

        .react-aria-Row[data-selected] + [data-selected],
        .react-aria-Row[data-selected]
            + .react-aria-DropIndicator
            + [data-selected] {
            --radius-top: 0px;
        }
    }
`;

const StyledCell = styled(Cell)`
    padding: 0.271rem 0.5rem;
    /* background-color: #1e1e1e; */
    color: whitesmoke;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    vertical-align: middle;

    &[data-focus-visible] {
        outline: 2px solid var(--focus-ring-color);
        outline-offset: -2px;
    }

    &:first-child {
        border-radius: var(--radius-top) 0 0 var(--radius-bottom);
    }

    &:last-child {
        border-radius: 0 var(--radius-top) var(--radius-bottom) 0;
    }
`;

const StyledButton = styled(Button)`
    --focus-ring-color: #bababa;
    border: none;
    color: #bd0034;
    background: none;
    font-size: 2rem;
    border-radius: 7px;
    transition: box-shadow 150ms ease, border 250ms ease;

    &[data-focused] {
        outline: 1px solid var(--focus-ring-color);
        border-color: var(--focus-ring-color);
        box-shadow: 0 0 0 1px var(--focus-ring-color);
    }

    &[data-focus-visible] {
        outline: 2px solid var(--focus-ring-color);
        outline-offset: -1px;
    }
`;

const StyledTableBody = styled(TableBody)`
    &::before {
        content: "@";
        display: block;
        line-height: 10px;
        text-indent: -9999px;
    }
`;

const StyledTableHeader = styled(TableHeader)`
    border-bottom: 1px solid #bd0034;
    border-radius: 7px;
    line-height: 40px;
    background-color: #1e1e1e;
`;

const StyledDropIndicator = styled(DropIndicator)`
    background-color: #bd0034;
    &[data-drop-target] {
        outline: 1px solid #bd0034;
        transform: translateZ(0);
        animation: ${fadeIn} 200ms ease-in-out;
    }
`;

const StyledColumn = styled(Column)`
    padding: 0.5rem;
    min-width: 100px;
    &[data-focus-visible] {
        outline: 2px solid var(--focus-ring-color);
        outline-offset: -2px;
    }
`;

const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

export function TCell(props) {
    return <StyledCell {...props} />;
}

export function TEditableCell({
    children,
    isEditing,
    onEdit,
    InputComponent,
    initialValue,
    ...props
}) {
    const [state, setState] = useState(initialValue);

    useEffect(() => {
        setState(initialValue);
    }, [initialValue, isEditing]);

    useUpdateEffect(() => {
        if (!isEditing) {
            onEdit(state);
        }
    }, [isEditing]);
    return (
        <StyledCell>
            {isEditing ? (
                <InputComponent
                    {...props}
                    value={state}
                    onChange={setState}
                    onClick={() => {
                        console.log("input clicked");
                    }}
                    autoFocus
                />
            ) : (
                children
            )}
        </StyledCell>
    );
}

export function TRow(props) {
    return <StyledRow {...props} />;
}

export function TTableHeader(props) {
    return <StyledTableHeader {...props} />;
}

export function TCheckbox(props) {
    return (
        <StyledCheckbox {...props}>
            {({ isIndeterminate }) => (
                <React.Fragment>
                    <div className="checkbox">
                        <svg viewBox="0 0 18 18" aria-hidden="true">
                            {isIndeterminate ? (
                                <rect x={1} y={7.5} width={15} height={3} />
                            ) : (
                                <polyline points="1 9 7 14 15 4" />
                            )}
                        </svg>
                    </div>
                </React.Fragment>
            )}
        </StyledCheckbox>
    );
}

export function TButton(props) {
    return <StyledButton {...props} />;
}

export function TColumn(props) {
    return <StyledColumn {...props} />;
}

export function TTable({ outsideTableChildren, ...props }) {
    return (
        <TableContainer>
            <StyledTable {...props} />
            {outsideTableChildren}
        </TableContainer>
    );
}

export function TTableBody(props) {
    return <StyledTableBody {...props} />;
}

export function TDropIndicator(props) {
    return <StyledDropIndicator {...props} />;
}

export function TTextField({ label, ...props }) {
    return (
        <StyledTextField {...props}>
            <Label>{label ? label : ""}</Label>
            <Input />
        </StyledTextField>
    );
}

export function TNumberField({ label, ...props }) {
    return (
        <StyledNumberField {...props}>
            <Label>{label ? label : ""}</Label>
            <Group>
                <Input />
            </Group>
            {/* <Button slot="decrement">-</Button> */}
            {/* <Button slot="increment">+</Button> */}
        </StyledNumberField>
    );
}
