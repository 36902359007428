import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import NavbarModulo from '../../components/NavbarModulo';
import NavbarModuloLF from '../../components/NavbarModuloFage';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import DetalleListado from './DetalleVacaciones';
import moment from 'moment';
const valEmp = __CONFIG__.empresa;

import {
    AsyncSelectField,
    AsyncSelectFieldIn,
    renderDayPickerB,
    renderCurrencyFloat,
    renderField
} from "../../Utils/renderField/renderField";

function VacacionesForm(props) {
    const { modulo, listarColaborador, listarPaises, emp, guardarDatos,
        datosColaborador, cargo, fechaAlta, diasV, lista, listaVacaciones,
        diasT, eliminarLinea } = props;
    let titulo = 'Ingreso de vacaciones';
    
    let ruta = window.location.href;
    let id_emp = ruta.split('/');

    const handleOnChange = value => {
        listarColaborador(value.value);
    };

    const handleOnChangeEmp = value => {
        datosColaborador(value.value);
    };

    const [diasVac, setDiasVac] = useState(0);

    const calcularDiasVac = (value) => {
        if (fechaAlta) {
            const fechaInicial = moment(fechaAlta);
            const fechaFinal = moment(value);
            
            if (fechaFinal.isValid() && fechaFinal.isAfter(fechaInicial)) {
                let cantidadDias = fechaFinal.diff(fechaInicial, 'days');
                const totCantidadDias = cantidadDias + 1
                if (cantidadDias >= 0) {
                    let diasVacc = parseFloat((parseFloat(totCantidadDias)*parseFloat(diasV))/365).toFixed(2);
                    let totalDias = diasVacc - diasT
                    setDiasVac(totalDias);
                }
            }
        }
    };

    const estilo = {
        overflow: "auto",
        height: "300px",

    };

    return (
        <div>
            {valEmp === 'fage' &&
                <NavbarModuloLF
                    modulo={modulo}
                    titulo={titulo}
                />
            }
            {valEmp === '' &&
                <NavbarModulo
                    modulo={modulo}
                    titulo={titulo}
                />
            }
           
            <br />
            <div className="mb-4 card card-small text-white bg-dark mb-3">
                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                    <div className="d-flex flex-column flex-1 mx-4">
                        <div className='mb-2'>
                            <div className='row mb-2'>
                                <div className='col-6'>
                                    <Field
                                        name="pais"
                                        placeholder="Pais..."
                                        className="form-control-find"
                                        component={AsyncSelectField}
                                        loadOptions={listarPaises}
                                        onChange={handleOnChange}
                                        msj="Pais"
                                    />
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-6'>
                                    <Field
                                        name="colaborador"
                                        placeholder="Colaborador..."
                                        className="form-control-find"
                                        component={AsyncSelectFieldIn}
                                        loadOptions={emp}
                                        onChange={handleOnChangeEmp}
                                        msj="Colaborador"
                                    />
                                </div>
                                <div className='col-3 mt-2'>
                                    <button
                                        className='btn mr-2 mb-3 btn-secondary'
                                        onClick={listaVacaciones}
                                    >
                                        Ver detalle
                                    </button>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-3'>
                                    <div className="form-floating">
                                        <input
                                            className={classNames('form-control')}
                                            type="date"
                                            disabled={true}
                                            value={fechaAlta}
                                        />
                                        <label className='form-label'>Fecha ingreso</label>
                                    </div>
                                </div>
                                <div className='col-9'>
                                    <div className="form-floating">
                                        <input
                                            className = {classNames('form-control')}
                                            type="text"
                                            value = {cargo}
                                            disabled = {true}
                                        />
                                        <label className='form-label'>Puesto</label>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-3'>
                                    <Field
                                        name="fecha_corte"
                                        component={renderDayPickerB}
                                        msj="Fecha corte"
                                        onChange={(e) => calcularDiasVac(e.target.value)}
                                    />
                                </div>
                                <div className='col-2'>
                                    <div className="form-floating">
                                        <NumberFormat
                                            className={classNames('form-control')}
                                            decimalScale={2}
                                            disabled={true}
                                            placeholder="Dias de vacaciones"
                                            fixedDecimalScale
                                            thousandSeparator
                                            value={diasVac}
                                            name="dias_vacaciones"
                                            onChange={(e) => setDiasVac(e.target.value)}
                                        />
                                        <label className='form-label' htmlFor="total">
                                            Dias de vacaciones
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-3'>
                                    <Field
                                        name="fecha_inicio"
                                        component={renderDayPickerB}
                                        msj="Fecha inicio"
                                    />
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="fecha_fin"
                                        component={renderDayPickerB}
                                        msj="Fecha fin"
                                    />
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="dias"
                                        placeholder="Dias tomados"
                                        className="form-control"
                                        component={renderCurrencyFloat}
                                        msj="Dias tomados"
                                    />
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-12'>
                                    <Field
                                        name="descripcion"
                                        placeholder="Descripcion"
                                        component={renderField}
                                        className="form-control"
                                        msj="Observaciones"
                                    />
                                </div>
                            </div>
                            {lista.length >= 1 &&
                                <div className='mt-3' style={estilo}>
                                    <DetalleListado
                                        detalle={lista}
                                        eliminarRegistro={eliminarLinea}
                                    />
                                </div>
                            }
                            <div className='d-flex flex-row mt-3'>
                                
                                <button
                                    className='btn mr-2 mb-3 btn-primary'
                                    onClick={guardarDatos}
                                >
                                    Guardar
                                </button>
                                {valEmp === 'fage' &&
                                    <a
                                        href={`/#/lfg/rr_hh/${id_emp[6]}`}
                                        className='btn btn-secondary mb-3'
                                    >
                                        Regresar
                                    </a>
                                }
                                {valEmp === '' &&
                                    <a
                                        href={`/#/rr_hh/${id_emp[5]}`}
                                        className='btn btn-secondary mb-3'
                                    >
                                        Regresar
                                    </a>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

VacacionesForm.propTypes = {
    handleSubmit: PropTypes.func,
    crear: PropTypes.bool,
    lectura: PropTypes.object,
    modulo: PropTypes.object
};

export default reduxForm({
    form: 'FormVacacionesColaborador',
})(VacacionesForm);
