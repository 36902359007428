import React from 'react';
import { Link } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as BsIcons from "react-icons/bs";
import { IconContext } from 'react-icons';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';

function NavbarModulo(props) {
    const {
        periodoD,
        pais,
        modulo,
        titulo
    } = props;
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    return (
        <React.Fragment>
            <IconContext.Provider value={{ color: '#fff' }}>
                <NavBarModulo>
                    <SidebarLinkModulo to={`/empresas/${id_emp[5]}/modulos`}>
                        <div>
                            <FaIcons.FaBox />
                            <SidebarLabel>
                                {modulo.modulo}
                            </SidebarLabel>
                        </div>
                    </SidebarLinkModulo>
                        {periodoD ? (
                            <SidebarLinkEstatico to="#">
                                {periodoD &&
                                    <div>
                                        <BsIcons.BsCalendarCheckFill />
                                        {periodoD.estado === 'A' &&
                                            <SidebarLabel>
                                                {periodoD.periodo + ' [' + ' ' + moment(periodoD.fecha_inicio).format("DD/MM/YYYY") + ' - ' + moment(periodoD.fecha_fin).format("DD/MM/YYYY") + ' ' + ']'}
                                            </SidebarLabel>
                                        }
                                        {periodoD.estado === 'C' &&
                                            <SidebarLabel>
                                                {periodoD.periodo + ' [' + ' ' + moment(periodoD.fecha_inicio).format("DD/MM/YYYY") + ' - ' + moment(periodoD.fecha_fin).format("DD/MM/YYYY") + ' ' + ']' + ' ' + '[ CERRADO ]'}
                                            </SidebarLabel>
                                        }
                                    </div>
                                }
                            </SidebarLinkEstatico>
                        ): (
                            <SidebarLinkEstatico to="#">
                                {pais &&
                                    <div>
                                        <BsIcons.BsFillFlagFill />
                                        <SidebarLabel>
                                            {pais.toUpperCase()}
                                        </SidebarLabel>
                                    </div>
                                }
                            </SidebarLinkEstatico>
                        )}
                    <SidebarLinkForm to="#">
                        {titulo &&
                            <div>
                                <BsIcons.BsFillBookmarkFill />
                                <SidebarLabel>
                                    {titulo}
                                </SidebarLabel>
                            </div>
                        }
                    </SidebarLinkForm>
                </NavBarModulo>
            </IconContext.Provider>
        </React.Fragment>
    )
}

NavbarModulo.propTypes = {
    periodoD: PropTypes.object,
    modulo: PropTypes.object,
    titulo: PropTypes.string
};

export default NavbarModulo;

const NavBarModulo = styled.div`
    background-color: #333;
    height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,.2);
`;

const SidebarLinkModulo = styled(Link)`
    display: flex;
    color: #fff;
    width: 550px;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    list-style: none;
    height: 50px;
    text-decoration: none;
    font-size: 17px;

    &:hover {
        background: #333;
        cursor: pointer;
        color: #007bff;
        text-decoration: none;
    }
`;

const SidebarLinkEstatico = styled(Link)`
    display: flex;
    color: #fff;
    width: 550px;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    list-style: none;
    height: 50px;
    text-decoration: none;
    font-size: 17px;

    &:hover {
        background: #333;
        cursor: auto;
        color: #fff;
        text-decoration: none;
    }
`;

const SidebarLabel = styled.span`
    margin-left: 16px;
`;

const SidebarLinkForm = styled(Link)`
    display: flex;
    color: #fff;
    width: 400px;
    justify-content: end;
    align-items: center;
    padding: 20px;
    list-style: none;
    height: 50px;
    text-decoration: none;
    font-size: 17px;

    &:hover {
        background: #333;
        cursor: auto;
        color: #fff;
        text-decoration: none;
    }
`;