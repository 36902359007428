import { handleActions } from 'redux-actions';
import { NotificationManager } from "react-notifications";
import { push } from "react-router-redux";
import { api } from "api";
import { initialize as initializeForm } from 'redux-form';
import Swal from 'sweetalert2';
const valEmp = __CONFIG__.empresa;

const COLABORADOR = 'COLABORADOR';
const FECHA_ALTA = 'FECHA_ALTA';
const SALARIO = 'SALARIO';
const BONIFICACION = 'BONIFICACION';
const PRESTACIONES = 'PRESTACIONES';
const LISTADO_EMP_LQ = "LISTADO_EMP_LQ";
const PUESTO = 'PUESTO';
const LOADER = 'LOADER';
const MODULO = 'MODULO';
const PARAMETRO = 'PARAMETRO';
const LIQUIDACION = 'LIQUIDACION';
const DETALLE_LIQUIDACION = 'DETALLE_LIQUIDACION';
const ID_COLABORADOR = 'ID_COLABORADOR';
const DATOS_NOMINA = 'DATOS_NOMINA';
const PROMEDIO = 'PROMEDIO';
const PROMEDIO_HORAS = 'PROMEDIO_HORAS';
const PROMEDIO_COMISION = 'PROMEDIO_COMISION';
const NOMINA_SUELDO = 'NOMINA_SUELDO';
const NOMINA_SUELDO_MOD = 'NOMINA_SUELDO_MOD';
const SUELDO_LIQUIDO = 'SUELDO_LIQUIDO';
const SALDO_PRESTAMO = 'SALDO_PRESTAMO';
const DETALLE_NOMINA_SUELDO = 'DETALLE_NOMINA_SUELDO';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params;
    let id = '';
    let id_pais = '';
    if (valEmp === 'fage') {
        id = id_emp[6];
        id_pais = id_emp[8];
        //params = { page, id, id_pais };
        params = { id, id_pais };
    } else {
        id = id_emp[5];
        id_pais = id_emp[7];
        //params = { page, id, id_pais };
        params = { id, id_pais };
    }
    api.get('/rrhh_liquidacion', params).then((response) => {
        dispatch({ type: LISTADO_EMP_LQ, data: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar liquidaciones',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const listarColaborador = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params;
    let id = ''
    if (valEmp === 'fage'){
        id = id_emp[6];
        params = { id};
    }else{
        id = id_emp[5];
        params = { id };
    }
    api.get('/colaborador/empresa_colaborador_pais', params).then(data => {
        const lista = [];
        if (data) {
            data.forEach(item => {
                lista.push({
                    value: item.id,
                    label: item.codigo+' - ' + item.nombre
                })
            })
        }
        dispatch({type: COLABORADOR, emp: lista })
    }).catch((error) => {
        return [];
    })
}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = { id, modulo }
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response) => {
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const listarPaises = () => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let empresa = '';
    if (valEmp === 'fage'){
        empresa = id_emp[6];
    }else{
        empresa = id_emp[5];
    }
    return api.get(`/pais_colaborador/pais_empresa/?id=${empresa}`).then((data)=>{
        const pais = [];
        data.results.forEach(item=>{
            pais.push({
                value: item.id,
                label: item.pais
            })
        })
        return pais;
    }).catch(error=>{
        NotificationManager.error(
            'Ocurrió un error al listar paises',
            'Error',
            0
        );
    })
}

export const datosColaborador = (emp) => (dispatch) => {
    api.get(`/colaborador/${emp}`).then((response) => {
        dispatch({type: FECHA_ALTA, fechaAlta: response.fecha_alta })
        dispatch({type: SALARIO, salario: response.sueldoBase })
        dispatch({type: BONIFICACION, boni: response.bonificacion })
        dispatch({type: PUESTO, cargo: response.cargo })
        dispatch({type: ID_COLABORADOR, idColaborador: response.id })
    }).catch(() => {
        return [];
    })
}

export const datosNomina = (emp, id_emp, fecha) => (dispatch) => {
    let empresa = '';
    if (valEmp === 'fage'){
        empresa = id_emp[6];
    }else{
        empresa = id_emp[5];
    }
    const params = { empresa, emp, fecha }
        
    api.get('/nomina/datos_nomina_emp', params).then((response) => {
        if (parseFloat(response.sinDatos) === 1){
            dispatch({type: DATOS_NOMINA, datosNom: response.datos })
            dispatch({type: PROMEDIO, promedio: response.promedio })
            dispatch({type: PROMEDIO_HORAS, promedioHora: response.horas })
            dispatch({type: PROMEDIO_COMISION, promedioComision: response.comision })
            dispatch({type: SALDO_PRESTAMO, totalPrestamo: response.saldoPrestamo })
            NotificationManager.info(
                'No se encontraron datos para calulo de promedio',
                'Información',
                6000
            );
        }else{
            dispatch({type: DATOS_NOMINA, datosNom: response.datos })
            dispatch({type: PROMEDIO, promedio: response.promedio })
            dispatch({type: PROMEDIO_HORAS, promedioHora: response.horas })
            dispatch({type: PROMEDIO_COMISION, promedioComision: response.comision })
            dispatch({type: SALDO_PRESTAMO, totalPrestamo: response.saldoPrestamo })
        }
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error al consultar datos de nomina',
            'Error',
            0
        );
    })
}



export const calcularDatos = (dias, diasAg, diasBono, diasVac, promedioSalario, promedioHora, promedioComision, salarioLiquido, totalPrestamo) => (dispatch, getStore) => {
    const data = getStore().form.FormLiquidacionColaborador.values;
    let salarioExt = 0.00;
    let comision = 0.00;
    if (promedioSalario === undefined || promedioSalario === '' || parseFloat(promedioSalario) === 0.00) {
        Swal.fire({
            title: 'Liquidación laboral',
            text: 'Debe de tener salario ordinario promedio',
            type: 'error',
        })
    } else {
        if (data.motivo === undefined || data.motivo === '') {
            Swal.fire({
                title: 'Liquidación laboral',
                text: 'Debe de seleccionar motivo para la liquidación',
                type: 'error',
            })
        } else {
            if (promedioHora === undefined || promedioHora === '' || parseFloat(promedioHora) === 0.00) {
                salarioExt = 0;
            }else{
                salarioExt = promedioHora;
            }
            if (promedioComision === undefined || promedioComision === '' || parseFloat(promedioComision) === 0.00) {
                comision = 0;
            }else{
                comision = promedioComision;
            }
            let totalSalario = parseFloat(promedioSalario) + parseFloat(salarioExt) + parseFloat(comision);
            if (parseInt(data.motivo) === 1 || parseInt(data.motivo) === 4){
                let indemn = parseFloat((parseFloat(totalSalario) /6 + parseFloat(totalSalario)) /365 * parseFloat(dias)).toFixed(2);
                let aguinaldo = parseFloat((parseFloat(totalSalario) /365) * parseFloat(diasAg)).toFixed(2);
                let bono = parseFloat((parseFloat(totalSalario) / 365) * parseFloat(diasBono)).toFixed(2);
                let vacaciones = parseFloat((parseFloat(totalSalario)/30) * parseFloat(diasVac)).toFixed(2);
                dispatch(cargarPrestaciones(indemn, vacaciones, aguinaldo, bono, salarioLiquido, totalPrestamo));
            }
            if (parseInt(data.motivo) === 2 || parseInt(data.motivo) === 3){
                let indemn = 0.00;
                let aguinaldo = parseFloat((parseFloat(totalSalario) / 365) * parseFloat(diasAg)).toFixed(2);
                let bono = parseFloat((parseFloat(totalSalario) / 365) * parseFloat(diasBono)).toFixed(2);
                let vacaciones = parseFloat((parseFloat(totalSalario) /30) * parseFloat(diasVac)).toFixed(2);
                dispatch(cargarPrestaciones(indemn, vacaciones, aguinaldo, bono, salarioLiquido, totalPrestamo));
            }
            if (parseInt(data.motivo) === 5){
                let indemn = 0.00;
                let aguinaldo = 0.00;
                let bono = 0.00;
                let vacaciones = 0.00;
                dispatch(cargarPrestaciones(indemn, vacaciones, aguinaldo, bono, salarioLiquido, totalPrestamo));
            }
        }
    }
}

export const preGrabar = (
    dias, 
    diasAg, 
    diasBono, 
    diasVac, 
    prestaciones, 
    fecha_aguinaldo, 
    fecha_bono, 
    promedioSalario, 
    promedioHora, 
    promedioComision, 
    diasSueldo, 
    nominaSueldo ) => (dispatch, getStore) => {
    const data = getStore().form.FormLiquidacionColaborador.values;

    let ruta = window.location.href;
    let id_emp = ruta.split('/');

    let id_empresa = '';
    if (valEmp === 'fage') {
        id_empresa = id_emp[6]
    } else {
        id_empresa = id_emp[5]
    }
    if (data.motivo === undefined || data.motivo === '') {
        Swal.fire({
            title: 'Liquidación laboral',
            text: 'Debe de seleccionar motivo para la liquidación',
            type: 'error',
        })
    } else {
        if (data.fecha_vacaciones === undefined || data.fecha_vacaciones === '' || data.fecha_vacaciones === null) {
            Swal.fire({
                title: 'Liquidación laboral',
                text: 'Debe de ingresar fecha de vacaciones',
                type: 'error',
            })
        } else {
            const formData = {
                motivo: data.motivo,
                idEmp: data.colaborador.value,
                fecha_terminacion: data.fecha_baja,
                fecha_aguinaldo: fecha_aguinaldo,
                fecha_bono: fecha_bono,
                fecha_vac: data.fecha_vacaciones,
                dias_laborados: dias,
                dias_aguinaldo: diasAg,
                dias_bono: diasBono,
                dias_vacaciones: diasVac,
                detallePres: prestaciones,
                salario_promedio: promedioSalario,
                salario_ext_promedio: promedioHora ? promedioHora : 0.00,
                comision: promedioComision ? promedioComision : 0.00,
                fecha_sueldo: data.fecha_sueldo,
                dias_sueldo: diasSueldo,
                empresa: id_empresa,
                nominaSueldo: nominaSueldo,
                estado: 'P'
            }
            dispatch(setLoader(true));
            api.post('/rrhh_liquidacion/', formData).then(() => {
                NotificationManager.success(
                    'Liquidación creada',
                    'Exito',
                    3000
                );
                let ruta = '';
                if (valEmp === 'fage') {
                    ruta = `/lfg/rr_hh/${id_emp[6]}/liquidacion_laboral`
                    dispatch(push(ruta));
                } else {
                    ruta = `/rr_hh/${id_emp[5]}/liquidacion_laboral`
                    dispatch(push(ruta));
                }
            }).catch((error) => {
                NotificationManager.error(
                    error.msj,
                    'Error',
                    2000
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }
    }
}

export const confirmar = (
    dias, 
    diasAg, 
    diasBono, 
    diasVac, 
    prestaciones, 
    fecha_aguinaldo, 
    fecha_bono, 
    promedioSalario, 
    promedioHora, 
    promedioComision,
    diasSueldo,
    nominaSueldo
    ) => (dispatch, getStore) => {
    const data = getStore().form.FormLiquidacionColaborador.values;

    let ruta = window.location.href;
    let id_emp = ruta.split('/');

    let id_empresa = '';
    if (valEmp === 'fage') {
        id_empresa = id_emp[6]
    } else {
        id_empresa = id_emp[5]
    }
    if (data.motivo === undefined || data.motivo === '') {
        Swal.fire({
            title: 'Liquidación laboral',
            text: 'Debe de seleccionar motivo para la liquidación',
            type: 'error',
        })
    } else {
        if (data.fecha_vacaciones === undefined || data.fecha_vacaciones === '' || data.fecha_vacaciones === null) {
            Swal.fire({
                title: 'Liquidación laboral',
                text: 'Debe de ingresar fecha de vacaciones',
                type: 'error',
            })
        } else {
            const formData = {
                motivo: data.motivo,
                idEmp: data.colaborador.value,
                fecha_terminacion: data.fecha_baja,
                fecha_aguinaldo: fecha_aguinaldo,
                fecha_bono: fecha_bono,
                fecha_vac: data.fecha_vacaciones,
                dias_laborados: dias,
                dias_aguinaldo: diasAg,
                dias_bono: diasBono,
                dias_vacaciones: diasVac,
                detallePres: prestaciones,
                salario_promedio: promedioSalario,
                salario_ext_promedio: promedioHora,
                comision: promedioComision,
                fecha_sueldo: data.fecha_sueldo,
                dias_sueldo: diasSueldo,
                nominaSueldo: nominaSueldo,
                empresa: id_empresa,
                estado: 'C'
            }
            Swal.fire({
                title: data.colaborador.label,
                html:
                    '<div class="col-sm-12 mt-4">' +
                    '<h4 style="color: white;">¿Esta seguro en confirmar la liquidación?</h4>' +
                    '</div>',
                type: 'info',
                background: "black",
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No',
                confirmButtonColor: "#4040ff",
                cancelButtonColor: "#646464",
                reverseButtons: true,
                customClass: {
                    title: 'sweet_titleImportant',
                },
            }).then((result) => {
                if (result.value) {
                    dispatch(setLoader(true));
                    api.post('/rrhh_liquidacion/', formData).then(() => {
                        NotificationManager.success(
                            'Liquidación creada',
                            'Exito',
                            3000
                        );
                        let ruta = '';
                        if (valEmp === 'fage') {
                            ruta = `/lfg/rr_hh/${id_emp[6]}/liquidacion_laboral`
                            dispatch(push(ruta));
                        } else {
                            ruta = `/rr_hh/${id_emp[5]}/liquidacion_laboral`
                            dispatch(push(ruta));
                        }
                    }).catch((error) => {
                        NotificationManager.error(
                            error.msj,
                            'Error',
                            2000
                        );
                    }).finally(() => {
                        dispatch(setLoader(false));
                    });
                }
            });
        }
    }
}


export const cargarPrestaciones = (indem, vac, agui, bono, salarioLiquido, totalPrestamo) => (dispatch) => {

    const datos = [
        {
            id: 1,
            prestacion: 'Indemnización',
            total: indem === undefined ? '0.00' : indem,
        },
        {
            id: 2,
            prestacion: 'Vacaciones',
            total: vac === undefined ? '0.00' : vac,
        },
        {
            id: 3,
            prestacion: 'Aguinaldo',
            total: agui === undefined ? '0.00' : agui,
        },
        {
            id: 4,
            prestacion: 'Bonificación anual (Bono 14)',
            total: bono === undefined ? '0.00' : bono,
        },
        {
            id: 5,
            prestacion: 'Salarios pendientes',
            total: salarioLiquido === undefined ? '0.00' : salarioLiquido,
        },
        {
            id: 6,
            prestacion: 'Saldo prestamo',
            total: totalPrestamo === undefined ? '0.00' : totalPrestamo === 0.00 ? 0.00 : parseFloat(totalPrestamo) * -1,
        },
        
    ];

    dispatch({type: PRESTACIONES, prest: datos });
    
}

export const agregarPrestacion = (datos, prestacion, montoPrestacion) => (dispatch, getStore) => {
    const data = getStore().form.FormLiquidacionColaborador.values;
    let monto = 0;
    if (data.tipo === 'ING'){
        monto = parseFloat(montoPrestacion.replace(/,/g, ''));
    }
    if (data.tipo === 'DES'){
        monto = parseFloat(montoPrestacion.replace(/,/g, '')) * -1; 
    }
    datos.push({
        id: 7,
        prestacion: prestacion,
        total: monto
    })
    dispatch({type: PRESTACIONES, prest: datos });
}

export const parametrosEmpresa = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params = '';
    let id = '';
    let pais = '';
    
    if (valEmp === 'fage') {
        id = id_emp[6];
        pais = id_emp[8];
        params = { id, pais }
    } else {
        id = id_emp[5];
        pais = id_emp[7];
        params = { id, pais }
    }
    dispatch(setLoader(true));
    api.get('/empresa/empresa_parametrosRH', params).then((response) => {
        dispatch({ type: PARAMETRO, datos: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar parametros',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const leer = (id) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    dispatch(setLoader(true));
    api.get(`/rrhh_liquidacion/${id}`).then((response) => {
        response.nombre = response.codigo + ' - ' + response.nombre;
        dispatch(datosNomina(response.colaborador, id_emp, response.fecha_terminacion));
        dispatch(leerDetalleLiquidacion(response.id));
        dispatch(leerDetalleSueldo(response.id));
        dispatch(initializeForm('FormLiquidacionColaborador', response));
        dispatch({ type: LIQUIDACION, lectura: response });
    }).catch((error) => {

        NotificationManager.error(
            'Ocurrió un error al consultar liquidación',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

const leerDetalleLiquidacion = id => (dispatch) => {
    api.get(`/rrhh_liquidacion_detalle/${id}`).then((response) => {
        dispatch({ type: DETALLE_LIQUIDACION, detalleLiq: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};

const leerDetalleSueldo = id => (dispatch) => {
    api.get(`/nomina_liquidacion/${id}`).then((response) => {
        dispatch({ type: DETALLE_NOMINA_SUELDO, detalleNominaSueldo: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};

export const recalcularDatos = (diasLaborados, diasAguinaldo, diasBon, diasVacaciones, promedioSal, promedioHora, promedioComision, salarioLiquido, totalPrestamo, editarAg, editarBo, editarVa, editarSuel, editarDias) => (dispatch, getStore) => {
    const data = getStore().form.FormLiquidacionColaborador.values;

    let salarioExt = 0.00;
    let comision = 0.00;
    let promedioSalario = 0.00;
    let dias = 0;
    let diasAg = 0;
    let diasBono = 0;
    let diasVac = 0;
    if (editarAg === true && editarBo === true && editarVa === true){
        promedioSalario = parseFloat(data.salario_promedio);
        dias = parseFloat(data.dias_laborados);
        diasAg = parseFloat(data.dias_aguinaldo);
        diasBono = parseFloat(data.dias_bono);
        diasVac = parseFloat(data.dias_vacaciones);
    }else{
        if (editarAg === false && editarBo === false && editarVa === false){
            promedioSalario = promedioSal;
            dias = parseFloat(diasLaborados);
            diasAg = parseFloat(diasAguinaldo);
            diasBono = parseFloat(diasBon);
            diasVac = parseFloat(diasVacaciones);
        }else{
            let diasAgVar = 0;
            let diasBonoVar = 0;
            let diasVacVar = 0;
            let diasVar = 0;

            if (editarDias === true){
                diasVar = parseFloat(data.dias_laborados);
            }else{
                diasVar = parseFloat(diasLaborados);
            }

            if (editarAg === true){
                diasAgVar = parseFloat(data.dias_aguinaldo);
            }else{
                diasAgVar = parseFloat(diasAguinaldo);
            }

            if (editarBo === true){
                diasBonoVar = parseFloat(data.dias_bono);
            }else{
                diasBonoVar = parseFloat(diasBon);
            }
            
            if (editarVa === true){
                diasVacVar = parseFloat(data.dias_vacaciones);
            }else{
                diasVacVar = parseFloat(diasVacaciones);
            }

            promedioSalario = parseFloat(data.salario_promedio);
            dias = parseFloat(diasVar);
            diasAg = parseFloat(diasAgVar);
            diasBono = parseFloat(diasBonoVar);
            diasVac = parseFloat(diasVacVar);
        }
    }
    
    if (data.salario_ordinario === undefined || promedioSalario === '') {
        Swal.fire({
            title: 'Liquidación laboral',
            text: 'Debe de tener salario ordinario promedio',
            type: 'error',
        })
    } else {
        if (data.motivo === undefined || data.motivo === '') {
            Swal.fire({
                title: 'Liquidación laboral',
                text: 'Debe de seleccionar motivo para la liquidación',
                type: 'error',
            })
        } else {
            if (promedioHora === undefined || promedioHora === '') {
                salarioExt = 0;
            }else{
                if (editarAg === true && editarBo === true && editarVa === true){
                    salarioExt = parseFloat(data.salario_ext_promedio);
                }else{
                    salarioExt = promedioHora;
                }
            }
            if (promedioComision === undefined || promedioComision === '') {
                comision = 0;
            }else{
                if (editarAg === true && editarBo === true && editarVa === true){
                    comision = parseFloat(data.comision_promedio);
                }else{
                    comision = promedioComision;
                }
            }
            if (parseInt(data.motivo) === 1 || parseInt(data.motivo) === 4){
                let totalSalario = parseFloat(promedioSalario) + parseFloat(salarioExt) + parseFloat(comision);
                let indemn = parseFloat((parseFloat(totalSalario) /6 + parseFloat(totalSalario)) /365 * parseFloat(dias)).toFixed(2);
                let aguinaldo = parseFloat((parseFloat(totalSalario) / 365) * parseFloat(diasAg)).toFixed(2);
                let bono = parseFloat((parseFloat(totalSalario)/365) * parseFloat(diasBono)).toFixed(2);
                let vacaciones = parseFloat((parseFloat(totalSalario) / 30) * diasVac).toFixed(2);
                const formData = {
                    id: data.id,
                    tipo: '2',
                    lista: [
                        {
                            id: 1,
                            total: indemn
                        },
                        {
                            id: 2,
                            total: vacaciones
                        },
                        {
                            id: 3,
                            total: aguinaldo
                        },
                        {
                            id: 4,
                            total: bono
                        },
                        {
                            id: 5,
                            total: salarioLiquido
                        },
                        {
                            id: 6,
                            total: totalPrestamo
                        }

                    ]
                }
                api.post('/rrhh_liquidacion_detalle/', formData).then((response) => {
                    NotificationManager.success(
                        'Datos actualizados',
                        'Exito',
                        3000
                    );
                    dispatch(leerDetalleLiquidacion(response))
                }).catch((error) => {
                    NotificationManager.error(
                        error.msj,
                        'Error',
                        2000
                    );
                });
            }
            if (parseInt(data.motivo) === 2 || parseInt(data.motivo) === 3){
                let totalSalario = parseFloat(promedioSalario) + parseFloat(salarioExt) + parseFloat(comision);
                let indemn = 0.00;
                let aguinaldo = parseFloat((parseFloat(totalSalario) / 365) * parseFloat(diasAg)).toFixed(2);
                let bono = parseFloat((parseFloat(totalSalario)/365) * parseFloat(diasBono)).toFixed(2);
                let vacaciones = parseFloat((parseFloat(totalSalario) / 30) * diasVac).toFixed(2);
                const formData = {
                    id: data.id,
                    tipo: '2',
                    lista: [
                        {
                            id: 1,
                            total: indemn
                        },
                        {
                            id: 2,
                            total: vacaciones
                        },
                        {
                            id: 3,
                            total: aguinaldo
                        },
                        {
                            id: 4,
                            total: bono
                        },
                        {
                            id: 5,
                            total: salarioLiquido
                        },
                        {
                            id: 6,
                            total: totalPrestamo
                        }
                    ]
                }
                
                api.post('/rrhh_liquidacion_detalle/', formData).then((response) => {
                    NotificationManager.success(
                        'Datos actualizados',
                        'Exito',
                        3000
                    );
                    dispatch(leerDetalleLiquidacion(response))
                }).catch((error) => {
                    NotificationManager.error(
                        error.msj,
                        'Error',
                        2000
                    );
                });
            }
            if (parseInt(data.motivo) === 5){
                let indemn = 0.00;
                let aguinaldo = 0.00;
                let bono = 0.00;
                let vacaciones = 0.00;
                const formData = {
                    id: data.id,
                    tipo: '2',
                    lista: [
                        {
                            id: 1,
                            total: indemn
                        },
                        {
                            id: 2,
                            total: vacaciones
                        },
                        {
                            id: 3,
                            total: aguinaldo
                        },
                        {
                            id: 4,
                            total: bono
                        },
                        {
                            id: 5,
                            total: salarioLiquido
                        },
                        {
                            id: 6,
                            total: totalPrestamo
                        }
                    ]
                }
                
                api.post('/rrhh_liquidacion_detalle/', formData).then((response) => {
                    NotificationManager.success(
                        'Datos actualizados',
                        'Exito',
                        3000
                    );
                    dispatch(leerDetalleLiquidacion(response))
                }).catch((error) => {
                    NotificationManager.error(
                        error.msj,
                        'Error',
                        2000
                    );
                });
            }
        }
    }
}

const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`/rrhh_liquidacion_detalle/${id}`).then((response) => {
        dispatch(leerDetalleLiquidacion(response));
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar prestación',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const guardarPrestacion = (prestacion, montoPrestacion) => (dispatch, getStore) => {
    const data = getStore().form.FormLiquidacionColaborador.values;
    let monto = 0;
    if (data.tipo === 'ING'){
        monto = parseFloat(montoPrestacion.replace(/,/g, ''));
    }
    if (data.tipo === 'DES'){
        monto = parseFloat(montoPrestacion.replace(/,/g, '')) * -1; 
    }
    const formdata = {
        id: data.id,
        codigo: 7 ,
        prestacion: prestacion,
        total: monto,
        tipo: '1'
    }
    api.post('/rrhh_liquidacion_detalle/', formdata).then((response) => {
        
        NotificationManager.success(
            'Prestación guardada',
            'Exito',
            3000
        );
        dispatch(leerDetalleLiquidacion(response));
    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            2000
        );
    });
}

export const preGrabarMod = (
        dias, 
        diasAg, 
        diasBono, 
        diasVac, 
        editarD, 
        editarAg, 
        editarBo, 
        editarVa,
        editarSuel,
        diasSueldo,
        nominaSueldo
        ) => (dispatch, getStore) => {
    const data = getStore().form.FormLiquidacionColaborador.values;
    
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    
    if (data.salario_ordinario === undefined || data.salario_ordinario === '') {
        Swal.fire({
            title: 'Liquidación laboral',
            text: 'Debe de tener salario ordinario promedio',
            type: 'error',
        })
    } else {
        if (data.motivo === undefined || data.motivo === '') {
            Swal.fire({
                title: 'Liquidación laboral',
                text: 'Debe de seleccionar motivo para la liquidación',
                type: 'error',
            })
        } else {
            let id = data.id;
            const formData = {
                id: data.id,
                motivo: data.motivo,
                fecha_terminacion: data.fecha_terminacion,
                fecha_aguinaldo: data.fecha_aguinaldo,
                fecha_bono: data.fecha_bono,
                fecha_vac: data.fecha_vacaciones,
                dias_laborados: editarD === false ? dias : data.dias_laborados,
                dias_aguinaldo: editarAg === false ? diasAg : data.dias_aguinaldo,
                dias_bono: editarBo === false ? diasBono: data.dias_bono,
                dias_vacaciones: editarVa === false ? diasVac: data.dias_vacaciones,
                salario_promedio: data.salario_promedio,
                salario_ext_promedio: data.salario_ext_promedio,
                comision: data.comision_promedio,
                fecha_sueldo: data.fecha_salario_pendiente ? data.fecha_salario_pendiente : '',
                dias_sueldo: editarSuel === false ? diasSueldo: data.dias_salario_pendiente,
                nominaSueldo: nominaSueldo,
                estado: 'P'
            }
            dispatch(setLoader(true));
            api.put(`/rrhh_liquidacion/${id}/`, formData).then(() => {
                NotificationManager.success(
                'Liquidación modificada',
                'Exito',
                3000
            );
            let ruta = '';
            if (valEmp === 'fage'){
                ruta = `/lfg/rr_hh/${id_emp[6]}/liquidacion_laboral`
                dispatch(push(ruta));
            }else{
                ruta = `/rr_hh/${id_emp[5]}/liquidacion_laboral`
                dispatch(push(ruta));
            }
            }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                2000
            );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }
    }
            
}

export const confirmarMod = (
    dias,
    diasAg,
    diasBono,
    diasVac,
    editarD,
    editarAg,
    editarBo,
    editarVa,
    editarSuel,
    diasSueldo,
    nominaSueldo
) => (dispatch, getStore) => {
    const data = getStore().form.FormLiquidacionColaborador.values;

    let ruta = window.location.href;
    let id_emp = ruta.split('/');

    if (data.motivo === undefined || data.motivo === '') {
        Swal.fire({
            title: 'Liquidación laboral',
            text: 'Debe de seleccionar motivo para la liquidación',
            type: 'error',
        })
    } else {
        let id = data.id;
        const formData = {
            id: data.id,
            motivo: data.motivo,
            fecha_terminacion: data.fecha_terminacion,
            fecha_aguinaldo: data.fecha_aguinaldo,
            fecha_bono: data.fecha_bono,
            fecha_vac: data.fecha_vacaciones,
            dias_laborados: editarD === false ? dias : data.dias_laborados,
            dias_aguinaldo: editarAg === false ? diasAg : data.dias_aguinaldo,
            dias_bono: editarBo === false ? diasBono : data.dias_bono,
            dias_vacaciones: editarVa === false ? diasVac : data.dias_vacaciones,
            salario_promedio: data.salario_promedio,
            salario_ext_promedio: data.salario_ext_promedio,
            comision: data.comision_promedio,
            fecha_sueldo: data.fecha_salario_pendiente,
            dias_sueldo: editarSuel === false ? diasSueldo : data.dias_salario_pendiente,
            nominaSueldo: nominaSueldo,
            estado: 'C'
        }
        Swal.fire({
            title: data.colaborador.label,
            html:
                '<div class="col-sm-12 mt-4">' +
                '<h4 style="color: white;">¿Esta seguro en confirmar la liquidación?</h4>' +
                '</div>',
            type: 'info',
            background: "black",
            showCancelButton: true,
            confirmButtonText: 'Si',
            cancelButtonText: 'No',
            confirmButtonColor: "#4040ff",
            cancelButtonColor: "#646464",
            reverseButtons: true,
            customClass: {
                title: 'sweet_titleImportant',
            },
        }).then((result) => {
            if (result.value) {
                dispatch(setLoader(true));
                api.put(`/rrhh_liquidacion/${id}/`, formData).then(() => {
                    NotificationManager.success(
                        'Liquidación modificada',
                        'Exito',
                        3000
                    );
                    let ruta = '';
                    if (valEmp === 'fage') {
                        ruta = `/lfg/rr_hh/${id_emp[6]}/liquidacion_laboral`
                        dispatch(push(ruta));
                    } else {
                        ruta = `/rr_hh/${id_emp[5]}/liquidacion_laboral`
                        dispatch(push(ruta));
                    }
                }).catch((error) => {
                    NotificationManager.error(
                        error.msj,
                        'Error',
                        2000
                    );
                }).finally(() => {
                    dispatch(setLoader(false));
                });
            }
        });
    }
}

export const limpiarDatos = () => (dispatch) => {
    dispatch({ type: FECHA_ALTA, fechaAlta: [] });
    dispatch({ type: SALARIO, salario: 0.00 });
    dispatch({ type: BONIFICACION, boni: 0.00 });
    dispatch({ type: PUESTO, cargo: [] });
    dispatch({ type: ID_COLABORADOR, idColaborador: '' });
    dispatch({ type: DATOS_NOMINA, datosNom: [] });
    dispatch({ type: PROMEDIO, promedio: 0.00 });
    dispatch({ type: PROMEDIO_HORAS, promedioHora: 0.00 })
    dispatch({ type: PROMEDIO_COMISION, promedioComision: 0.00 })
    dispatch({ type: NOMINA_SUELDO, nominaSueldo: [] })
    dispatch({ type: SUELDO_LIQUIDO, salarioLiquido: 0.00 })
    dispatch({ type: NOMINA_SUELDO_MOD, nominaSueldoMod: [] })
}

export const imprimirLiquidacion = id => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params = '';
    let idE = '';
    let idCol = '';
    let reporte = '';
    if (valEmp === 'fage') {
        idE = id_emp[6];
        idCol = id
        reporte = 'liquidacion';
        params = { idE, reporte, idCol };
    } else {
        idE = id_emp[5];
        idCol = id
        reporte = 'liquidacion';
        params = { idE, reporte, idCol };
    }
    dispatch(setLoader(true));
    api.getPdf('/rrhh_liquidacion', params).then((response) => {
        if (response.headers['content-type'] == 'application/pdf') {
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'reporte_alta_colaborador.pdf';
            window.open(link, '_blank');
            return false;
        }
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al generar reporte',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const calcularSueldo = (emp, id_emp, fecha, dias) => (dispatch) => {
    let empresa = '';
    if (valEmp === 'fage'){
        empresa = id_emp[6];
    }else{
        empresa = id_emp[5];
    }
    const params = { empresa, emp, fecha, dias }
    api.get('/nomina/datos_sueldo_emp', params).then((response) => {
        dispatch({type: NOMINA_SUELDO, nominaSueldo: response.datos })
        dispatch({type: SUELDO_LIQUIDO, salarioLiquido: response.salarioLiquido })
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error al generar datos de sueldo',
            'Error',
            0
        );
    })
}


export const calcularSueldoMod = (emp, id_emp, fecha, dias) => (dispatch) => {
    let empresa = '';
    if (valEmp === 'fage'){
        empresa = id_emp[6];
    }else{
        empresa = id_emp[5];
    }
    const params = { empresa, emp, fecha, dias }
    api.get('/nomina/datos_sueldo_emp', params).then((response) => {
        dispatch({type: NOMINA_SUELDO_MOD, nominaSueldoMod: response.datos })
        dispatch({type: SUELDO_LIQUIDO, salarioLiquido: response.salarioLiquido })
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error al generar datos de sueldo',
            'Error',
            0
        );
    })
}

export const actions = {
    leer,
    listarColaborador,
    getModulo,
    listarPaises,
    preGrabar,
    datosColaborador,
    calcularDatos,
    agregarPrestacion,
    cargarPrestaciones,
    listar,
    parametrosEmpresa,
    confirmar,
    recalcularDatos,
    eliminar,
    guardarPrestacion,
    preGrabarMod,
    confirmarMod,
    limpiarDatos,
    datosNomina,
    imprimirLiquidacion,
    calcularSueldo,
    calcularSueldoMod
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [COLABORADOR]: (state, { emp }) => {
        return {
            ...state,
            emp,
        };
    },
    [FECHA_ALTA]: (state, { fechaAlta }) => {
        return {
            ...state,
            fechaAlta,
        };
    },
    [SALARIO]: (state, { salario }) => {
        return {
            ...state,
            salario,
        };
    },
    [BONIFICACION]: (state, { boni }) => {
        return {
            ...state,
            boni,
        };
    },
    [PRESTACIONES]: (state, { prest }) => {
        return {
            ...state,
            prest,
        };
    },
    [PUESTO]: (state, { cargo }) => {
        return {
            ...state,
            cargo,
        };
    },
    [LISTADO_EMP_LQ]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [PARAMETRO]: (state, { datos }) => {
        return {
            ...state,
            datos,
        };
    },
    [LIQUIDACION]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
    [DETALLE_LIQUIDACION]: (state, { detalleLiq }) => {
        return {
            ...state,
            detalleLiq,
        };
    },
    [ID_COLABORADOR]: (state, { idColaborador }) => {
        return {
            ...state,
            idColaborador,
        };
    },
    [DATOS_NOMINA]: (state, { datosNom }) => {
        return {
            ...state,
            datosNom,
        };
    },
    [PROMEDIO]: (state, { promedio }) => {
        return {
            ...state,
            promedio,
        };
    },
    [PROMEDIO_HORAS]: (state, { promedioHora }) => {
        return {
            ...state,
            promedioHora,
        };
    },
    [PROMEDIO_COMISION]: (state, { promedioComision }) => {
        return {
            ...state,
            promedioComision,
        };
    },
    [NOMINA_SUELDO]: (state, { nominaSueldo }) => {
        return {
            ...state,
            nominaSueldo,
        };
    },
    [SUELDO_LIQUIDO]: (state, { salarioLiquido }) => {
        return {
            ...state,
            salarioLiquido,
        };
    },
    [SALDO_PRESTAMO]: (state, { totalPrestamo }) => {
        return {
            ...state,
            totalPrestamo,
        };
    },
    [DETALLE_NOMINA_SUELDO]: (state, { detalleNominaSueldo }) => {
        return {
            ...state,
            detalleNominaSueldo,
        };
    },
    [NOMINA_SUELDO_MOD]: (state, { nominaSueldoMod }) => {
        return {
            ...state,
            nominaSueldoMod,
        };
    },
};

export const initialState = {
    loader: false,
    modulo: [],
    emp: [],
    fechaAlta: '',
    salario: '0.00',
    boni: '0.00',
    prest: [],
    cargo: '',
    datos: [],
    data: [],
    page: 1,
    lectura: [],
    detalleLiq: [],
    idColaborador: '',
    datosNom: [],
    promedio: 0.00,
    promedioHora: 0.00,
    promedioComision: 0.00,
    nominaSueldo: [],
    salarioLiquido: 0.00,
    totalPrestamo: 0.00,
    detalleSueldo: [],
    detalleNominaSueldo: [],
    nominaSueldoMod: [],
};

export default handleActions(reducers, initialState)
