import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import NavbarModulo from '../../components/NavbarModulo';
import NavbarModuloLF from '../../components/NavbarModuloFage';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import {
    renderField,
    renderCurrencyFloat,
    AsyncSelectFieldIn
} from "../../Utils/renderField/renderField";

const valEmp = __CONFIG__.empresa;

function HorasExtrasForm(props) {
    const { crear, modulo, periodoN, correlativo,
        periodoId, emp, hora_extra, datosColaborador, registroHoraExtra,
        lectura, modificarHoraExtra, sueldo } = props;

    const editar = window.location.href.includes('editar');
    let titulo = editar ? 'Modificar hora extra' : 'Crear hora extra';
    let disabled = false;
    if (crear === false && editar === false) {
        disabled = true;
        titulo = 'Ver hora extra'
    }
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const [total, setTotal] = useState(0.0);
    const [editarD, setEditarD] = useState(editar);

    const handleOnChangeEmp = value => {
        datosColaborador(value.value);
    };

    return (
        <div>
            {valEmp === 'fage' &&
                <NavbarModuloLF
                    modulo={modulo}
                    titulo={titulo}
                />
            }
            {valEmp === '' &&
                <NavbarModulo
                    modulo={modulo}
                    titulo={titulo}
                />
            }
            <br />
            <div className="mb-4 card card-small text-white bg-dark mb-3">
                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                    <div className="d-flex flex-column flex-1 mx-4">
                        <div className='row mb-2'>
                            <div className='col-3'>
                                <div className="form-floating">
                                    <input
                                        placeholder="Periodo"
                                        type="text"
                                        autocomplete="off"
                                        name="periodo"
                                        className="form-control"
                                        value={periodoN}
                                        disabled={true}
                                    />
                                    <label className='form-label' htmlFor="periodo">
                                        Periodo
                                    </label>
                                </div>
                            </div>
                            <div className='col-3'>
                                <div className="form-floating">
                                    <input
                                        placeholder="Numero"
                                        type="text"
                                        autocomplete="off"
                                        name="numero"
                                        className="form-control"
                                        value={correlativo}
                                        disabled={true}
                                    />
                                    <label className='form-label' htmlFor="numero">
                                        Numero
                                    </label>
                                </div>
                            </div>
                        </div>
                        {crear === true &&
                            <div className='row mb-2'>
                                <div className='col-10'>
                                    <Field
                                        name="colaborador_hora"
                                        placeholder="Colaborador..."
                                        className="form-control-find"
                                        component={AsyncSelectFieldIn}
                                        loadOptions={emp}
                                        onChange={handleOnChangeEmp}
                                        msj="Colaborador"
                                    />
                                </div>
                                <div className='col-2'>
                                    <div className="form-floating">
                                        <NumberFormat
                                            className={classNames('form-control')}
                                            decimalScale={2}
                                            disabled={true}
                                            placeholder="Total"
                                            fixedDecimalScale
                                            thousandSeparator
                                            value={sueldo}
                                            name="sueldo"
                                        />
                                        <label className='form-label' htmlFor="sueldo">
                                            Sueldo
                                        </label>
                                    </div>
                                </div>
                            </div>
                        }
                        {crear === false && editar === false &&
                            <div className='row mb-2'>
                                <div className='col-12'>
                                    <Field
                                        name="nombre"
                                        disabled={true}
                                        placeholder="Descripcion"
                                        component={renderField}
                                        className="form-control"
                                        msj="Colaborador"
                                    />
                                </div>
                            </div>
                        }
                        {editar === true &&
                            <div className='row mb-2'>
                                <div className='col-12'>
                                    <Field
                                        name="nombre"
                                        disabled={true}
                                        placeholder="Descripcion"
                                        component={renderField}
                                        className="form-control"
                                        msj="Colaborador"
                                    />
                                </div>
                            </div>
                        }
                        {crear === true &&
                            <div className='row mb-2'>
                                <div className='col-3'>
                                    <div className="form-floating">
                                        <Field
                                            name="horas"
                                            placeholder="Horas extras"
                                            className="form-control"
                                            component={renderCurrencyFloat}
                                            onChange={(e, value) => {
                                                if (value) {
                                                    let total = parseFloat(value) * parseFloat(hora_extra)
                                                    setTotal(total);
                                                }
                                            }}
                                            msj="Horas extras"
                                        />
                                    </div>
                                </div>
                                <div className='col-3'>
                                    <div className="form-floating">
                                        <NumberFormat
                                            className={classNames('form-control')}
                                            decimalScale={2}
                                            disabled={true}
                                            placeholder="Total"
                                            fixedDecimalScale
                                            thousandSeparator
                                            value={total}
                                            name="total"
                                        />
                                        <label className='form-label' htmlFor="total">
                                            Total
                                        </label>
                                    </div>
                                </div>
                            </div>
                        }
                        {editar === true &&
                            <div className='row mb-2'>
                                <div className='col-3'>
                                    <div className="form-floating">
                                        <Field
                                            name="horas"
                                            placeholder="Horas extras"
                                            className="form-control"
                                            component={renderCurrencyFloat}
                                            onChange={(e, value) => {
                                                if (value) {
                                                    setEditarD(false);
                                                    let total = parseFloat(value) * parseFloat(hora_extra)
                                                    setTotal(total);
                                                }
                                            }}
                                            msj="Horas extras"
                                        />
                                    </div>
                                </div>
                                <div className='col-3'>
                                    <div className="form-floating">
                                        <NumberFormat
                                            className={classNames('form-control')}
                                            decimalScale={2}
                                            disabled={true}
                                            placeholder="Total"
                                            fixedDecimalScale
                                            thousandSeparator
                                            value={editarD === true ? lectura.total : total}
                                            name="total"
                                        />
                                        <label className='form-label' htmlFor="total">
                                            Total
                                        </label>
                                    </div>
                                </div>
                            </div>
                        }
                        {crear === false && editar === false &&
                            <div className='row mb-2'>
                                <div className='col-md-3'>
                                    <div className="form-floating">
                                        <Field
                                            name="horas"
                                            disabled={true}
                                            placeholder="Horas extras"
                                            className="form-control"
                                            component={renderCurrencyFloat}
                                            msj="Horas extras"
                                        />
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className="form-floating">
                                        <Field
                                            name="total"
                                            disabled={true}
                                            placeholder="Total"
                                            className="form-control"
                                            component={renderCurrencyFloat}
                                            msj="Total"
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        <div className='row mb-2'>
                            <div className='col-md-12'>
                                <Field
                                    name="descripcion"
                                    disabled={disabled}
                                    placeholder="Descripcion"
                                    component={renderField}
                                    className="form-control"
                                    msj="Descripcion"
                                />
                            </div>
                        </div>
                        <div className='d-flex flex-row mt-3'>
                            {crear == true && editar == false &&
                                <button
                                    className='btn mr-2 mb-3 btn-primary'
                                    type='submit'
                                    onClick={() => {
                                        registroHoraExtra(total, periodoId);
                                        setTotal(0.0);
                                    }}
                                >
                                    Guardar
                                </button>
                            }
                            {editar == true && crear == false &&
                                <button
                                    className='btn mr-2 mb-3 btn-primary'
                                    type='submit'
                                    onClick={() => {
                                        modificarHoraExtra(total);
                                        setTotal(0.0);
                                    }}
                                >
                                    Modificar
                                </button>
                            }
                            {valEmp === 'fage' &&
                                <a
                                    href={`/#/lfg/nomina/${id_emp[6]}/horas_extras/${id_emp[8]}`}
                                    className='btn btn-secondary mb-3'
                                >
                                    Regresar
                                </a>
                            }
                            {valEmp === '' &&
                                <a
                                    href={`/#/nomina/${id_emp[5]}/horas_extras/${id_emp[7]}`}
                                    className='btn btn-secondary mb-3'
                                >
                                    Regresar
                                </a>
                            }
                        </div>
                    </div>
                </div>
            </div>

        </div >
    );
}

HorasExtrasForm.propTypes = {
    handleSubmit: PropTypes.func,
    crear: PropTypes.bool,
    modulo: PropTypes.object,
};

export default reduxForm({
    form: 'FormHorasExtrasNomina',
})(HorasExtrasForm);
