import React, { Component } from 'react';
import Grid from '../../Utils/GridVDocumento';
import { standardActions } from '../../Utils/GridVDocumento/StandardActions';
import moment from 'moment';

class DetalleAumentoList extends Component{
    
    render(){
        const { loader, eliminarRegistro,  detalle } = this.props;
        return(
            <React.Fragment>
                {detalle &&
                <Grid 
                    hover 
                    striped 
                    data={detalle} 
                    loading={loader}
                    headerStyle={ { background: 'black' } } 
                >
                    <TableHeaderColumn
                        isKey
                        dataField="fecha_corte"
                        dataAlign='left'
                        headerAlign="center"
                        dataFormat={(cell, row)=>{
                            return moment(cell).format("DD/MM/YYYY");
                        }}
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Fecha corte
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="fecha_inicio"
                        dataAlign='left'
                        headerAlign="center"
                        dataFormat={(cell, row)=>{
                            return moment(cell).format("DD/MM/YYYY");
                        }}
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Fecha inicio
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="fecha_fin"
                        dataAlign='left'
                        headerAlign="center"
                        dataFormat={(cell, row)=>{
                            if (cell === null){
                                return ''
                            }else{
                                return moment(cell).format("DD/MM/YYYY");
                            }
                        }}
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Fecha fin
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="dias"
                        dataAlign='right'
                        headerAlign="center"
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Dias
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        thStyle={
                        {color: 'white'}}
                        dataField="id"
                        width="150"
                        dataAlign="center"
                        dataSort
                        dataFormat={standardActions({
                            eliminarRegistro: eliminarRegistro })} 
                    >
                        Acciones
                    </TableHeaderColumn>
                </Grid>
                }
            </React.Fragment>
        );
    }
}

export default DetalleAumentoList;
