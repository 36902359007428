import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import {
    renderField,
} from "../../Utils/renderField/renderField";

const emailValidation = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
  'Dirección de correo electrónico no válido' : undefined

const estilo = {
    fontSize: "14px",
};
const LoginForm = (props) => {
    const { handleSubmit } = props;
    return (
        <form name="loginForm" onSubmit={handleSubmit}>
            <div className="form-outline mb-4">
                <Field
                    type="text"
                    name="username"
                    placeholder="Usuario"
                    className="form-control"
                    component={renderField}
                    validate={emailValidation}
                    msj="Usuario"
                />
            </div>
            <div className="form-outline mb-3">
                <Field
                    type="password"
                    name="password"
                    placeholder="Usuario"
                    className="form-control"
                    component={renderField}
                    msj="Contraseña"
                />
            </div>
            <div className="buttons-box">
                <button type="submit" style={estilo} className="btn btn-primary m-1 align-self-center">Ingresar</button>
            </div>
        </form>
    );
};

export default reduxForm({
    form: 'login', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            username: validators.exists()('Este campo es requerido'),
            password: validators.exists()('Este campo es requerido'),
        });
    },
})(LoginForm);
