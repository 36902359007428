import React, { Component } from 'react';
import Formulario from './SaldosFacturasForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";

class SaldosFacturas extends Component{
    state={
        archivo: null
    }

    componentDidMount = () => {
        const { listarEmpresas } = this.props;
        listarEmpresas();
    }
    
    render(){
        const { loader, listarEmpresas, saldos } = this.props;
    
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    onSubmit = {saldos}
                    listarEmpresas = {listarEmpresas}
                />
            </LoadMask>
        );
    }
}

export default SaldosFacturas
