import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BootstrapTable } from 'react-bootstrap-table';
import LoadMask from "../LoadMask/LoadMask";


export default class Grid extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false
    };

    createCustomClearButton = (onClick) => {
        return (
          <button className='btn btn-dark btn-sm ml-2' onClick={ onClick }>Limpiar</button>
        );
    }

    createCustomSearchField = () => {
        return (
            <SearchField
                className='form-floating'
                placeholder='Buscar...'
                style={{"height": "35px","font-size": "100%"}}
            />
        );
    }

    render() {
        const {
            loading,
            data,
            page,
            remote=true,
            expandableRow,
            expandComponent,
            cellEditProp,
            afterSave,
            onPageChange,
            onSortChange,
            pagination,
            expanding,
            onExpand,
            trClassName,
            mostrarDescuentos,
            ...other
        } = this.props;

        const options = {
            sizePerPage: 10,
            hideSizePerPage: false,
            alwaysShowAllBtns: true,
            clearSearch: true,
            noDataText: loading ? 'Cargando...' : <span className='title'>No hay datos</span>,
            page,
            clearSearchBtn: this.createCustomClearButton,
            searchField: this.createCustomSearchField,
            onPageChange: onPageChange ? onPageChange : () => {},
            onSortChange: onSortChange ? onSortChange : () => {},
            onExpand:onExpand,
            onRowDoubleClick: function(row) {
                mostrarDescuentos(row.colaborador, row.periodo)
            }
        };

        const footerData = [
            [
                {
                    label: 'Total value',
                    columnIndex: 0,
                    align: 'right',
                    formatter: (tableData) => {
                        const cant = tableData.reduce(function (resultado, elemento) {
                            return parseInt(resultado) + 1;
                        }, 0);
                        return (
                            <strong className='title'>{cant}</strong>
                        );
                    }
                },
                {
                    label: <strong className='title'>Totales:</strong>,
                    columnIndex: 1,
                    align: 'center',
                },
                {
                    label: 'Total value',
                    columnIndex: 2,
                    align: 'right',
                    formatter: (tableData) => {
                        const sueldo = tableData.reduce(function (resultado, elemento) {
                            return parseFloat(resultado) + parseFloat(elemento.sueldo_base);
                        }, 0.00);
                        return (
                            <strong className='title'>{new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(sueldo)}</strong>
                        );
                    }
                },
                {
                    label: 'Total value',
                    columnIndex: 3,
                    align: 'right',
                    formatter: (tableData) => {
                        const bon = tableData.reduce(function (resultado, elemento) {
                            return parseFloat(resultado) + parseFloat(elemento.bonificacion_decreto);
                        }, 0.00);
                        return (
                            <strong className='title'>{new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(bon)}</strong>
                        );
                    }
                },
                {
                    label: 'Total value',
                    columnIndex: 4,
                    align: 'right',
                    formatter: (tableData) => {
                        const total = tableData.reduce(function (resultado, elemento) {
                            return parseFloat(resultado) + parseFloat(elemento.totalSueldo);
                        }, 0.00);
                        return (
                            <strong className='title'>{new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(total)}</strong>
                        );
                    }
                },
                {
                    label: 'Total value',
                    columnIndex: 6,
                    align: 'right',
                    formatter: (tableData) => {
                        const base = tableData.reduce(function (resultado, elemento) {
                            return parseFloat(resultado) + parseFloat(elemento.base);
                        }, 0.00);
                        return (
                            <strong className='title'>{new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(base)}</strong>
                        );
                    }
                },
                {
                    label: 'Total value',
                    columnIndex: 7,
                    align: 'right',
                    formatter: (tableData) => {
                        const bono = tableData.reduce(function (resultado, elemento) {
                            return parseFloat(resultado) + parseFloat(elemento.bonificacion);
                        }, 0.00);
                        return (
                            <strong className='title'>{new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(bono)}</strong>
                        );
                    }
                },
                {
                    label: 'Total value',
                    columnIndex: 8,
                    align: 'right',
                    formatter: (tableData) => {
                        const horas = tableData.reduce(function (resultado, elemento) {
                            return parseFloat(resultado) + parseFloat(elemento.horas_extras);
                        }, 0.00);
                        return (
                            <strong className='title'>{new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(horas)}</strong>
                        );
                    }
                },
                {
                    label: 'Total value',
                    columnIndex: 9,
                    align: 'right',
                    formatter: (tableData) => {
                        const totalNomina = tableData.reduce(function (resultado, elemento) {
                            return parseFloat(resultado) + parseFloat(elemento.total_devengado);
                        }, 0.00);
                        return (
                            <strong className='title'>{new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(totalNomina)}</strong>
                        );
                    }
                },
                {
                    label: 'Total value',
                    columnIndex: 10,
                    align: 'right',
                    formatter: (tableData) => {
                        const totalIgss = tableData.reduce(function (resultado, elemento) {
                            return parseFloat(resultado) + (parseFloat(elemento.igss)*-1);
                        }, 0.00);
                        return (
                            <strong className='title'>{new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(totalIgss)}</strong>
                        );
                    }
                },
                {
                    label: 'Total value',
                    columnIndex: 11,
                    align: 'right',
                    formatter: (tableData) => {
                        const totalISR = tableData.reduce(function (resultado, elemento) {
                            return parseFloat(resultado) + (parseFloat(elemento.isr)*-1);
                        }, 0.00);
                        return (
                            <strong className='title'>{new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(totalISR)}</strong>
                        );
                    }
                },
                {
                    label: 'Total value',
                    columnIndex: 12,
                    align: 'right',
                    formatter: (tableData) => {
                        const totalOtros = tableData.reduce(function (resultado, elemento) {
                            return parseFloat(resultado) + (parseFloat(elemento.descuentos)*-1);
                        }, 0.00);
                        return (
                            <strong className='title'>{new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(totalOtros)}</strong>
                        );
                    }
                },
                {
                    label: 'Total value',
                    columnIndex: 13,
                    align: 'right',
                    formatter: (tableData) => {
                        const totalJudicial = tableData.reduce(function (resultado, elemento) {
                            return parseFloat(resultado) + (parseFloat(elemento.judicial)*-1);
                        }, 0.00);
                        return (
                            <strong className='title'>{new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(totalJudicial)}</strong>
                        );
                    }
                },
                {
                    label: 'Total value',
                    columnIndex: 14,
                    align: 'right',
                    formatter: (tableData) => {
                        const totalJudicial = tableData.reduce(function (resultado, elemento) {
                            return parseFloat(resultado) + parseFloat(elemento.total_descuentos);
                        }, 0.00);
                        return (
                            <strong className='title'>{new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(totalJudicial)}</strong>
                        );
                    }
                },
                {
                    label: 'Total value',
                    columnIndex: 15,
                    align: 'right',
                    formatter: (tableData) => {
                        const totalJudicial = tableData.reduce(function (resultado, elemento) {
                            return parseFloat(resultado) + parseFloat(elemento.liquido);
                        }, 0.00);
                        return (
                            <strong className='title'>{new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(totalJudicial)}</strong>
                        );
                    }
                }
            ]
        ];


        return (
            <div>
                <LoadMask loading={loading} dark blur>
                    <BootstrapTable
                        expandableRow={expandableRow}
                        expandComponent={expandComponent}
                        trClassName={trClassName}
                        cellEdit={cellEditProp}
                        data={loading ? [] : data}
                        afterSaveCell={afterSave}
                        searchPlaceholder="Buscar..."
                        search={ true }
                        multiColumnSearch={ true }
                        footerData={footerData}
                        fetchInfo={{ dataTotalSize: data.count }}
                        options={options}
                        searchTotal={this.props.data.count}
                        {...other}
                    />
                </LoadMask>
            </div>

        )
    }
}
