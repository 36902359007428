import { connect } from 'react-redux';
import { actions } from '../../../redux/modules/registro_sanitario/fabricante';
import RegistroSanitario from './RegistroSanitario';


const ms2p = (state) => {
  return {
    ...state.rs_fabricante,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(RegistroSanitario);
