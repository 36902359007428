import React, { Component } from 'react';
import Formulario from './SubirProductoForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";

class SubirProducto extends Component{
    state={
        archivo: null
    }

    componentDidMount = () => {
        const { listarEmpresas } = this.props;
        listarEmpresas();
    }
    
    setArchivo = (archivo) => {
        this.setState({archivo});
    };
    
    subirProductos = (data) => {
        const { subirProductos } = this.props;
        subirProductos( {...data, archivo: null},
        [{ "file": this.state.archivo, "name": 'archivo' },])
    }

    render(){
        const { loader, listarEmpresas } = this.props;
    
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    onSubmit = {this.subirProductos}
                    listarEmpresas = {listarEmpresas}
                    setArchivo={this.setArchivo} 
                />
            </LoadMask>
        );
    }
}

export default SubirProducto
