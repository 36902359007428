import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/registro_sanitario/familia';
import Familia from './FamiliaList';


const ms2p = (state) => {
  return {
    ...state.rs_familia,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Familia);