import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";

const LISTADO_TIPO = 'LISTADO_TIPO';
const LOADER = 'LOADER';
const MODULO = 'MODULO';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let params = { id };
    dispatch(setLoader(true));
    api.get('/visitador_medico/tipo', params).then((response)=>{
        dispatch({ type: LISTADO_TIPO, data: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar tipos',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const registroTipo = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormTipoVM.values;
    let id_empresa = id_emp[5]
    const formData = {
        descripcion: data.descripcion,
        empresa: id_empresa
    }
    dispatch(setLoader(true));
    api.post('/visitador_medico/tipo/', formData).then(() => {
        NotificationManager.success(
            'Tipo creado',
            'Exito',
            3000
        );
        let ruta = `/visitador_medico/${id_emp[5]}/tipo`
        dispatch(push(ruta));
    }).catch((error) => {
    NotificationManager.error(
        error.msj,
        'Error',
        2000
    );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const modificarTipo = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const datos = getStore().form.FormTipoVM.values;
    const id = datos.id;
    const formData = {
        id: id,
        descripcion: datos.descripcion,
    }
    dispatch(setLoader(true));
    api.put(`/visitador_medico/tipo/${id}/`, formData).then(() => {
        NotificationManager.success(
            'Tipo modificado correctamente',
            'Exito',
            3000
        );
        let ruta = `/visitador_medico/${id_emp[5]}/tipo`
        dispatch(push(ruta));
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al modificar tipo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/visitador_medico/tipo/${id}`).then((response) => {
        dispatch(initializeForm('FormTipoVM', response ));
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar tipo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        if (error.status === 401){
            NotificationManager.error(
              error.body.msj,
              'Error',
              0
            );
        }
        if (error.status === 400){
            NotificationManager.error(
              'Ocurrió un error en buscar modulo',
              'Error',
              0
            );
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const actions = {
    listar,
    registroTipo,
    modificarTipo,
    leer,
    getModulo
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_TIPO]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
};

export const initialState = {
    loader: false,
    modulo: [],
    data: []
};

export default handleActions(reducers, initialState)
 