import React, { Component } from 'react';
import Formulario from './AjustesHome';
import LoadMask from "../Utils/LoadMask/LoadMask";
const valEmp = __CONFIG__.empresa;

class Ajustes extends Component{

    componentDidMount = () => {
        const { getModulo, getTotal } = this.props;
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        if (valEmp === 'fage'){
            getModulo(modulo[6],modulo[5]);
            getTotal();
        }else{
            getModulo(modulo[5],modulo[4]);
            getTotal();
        }
        
    }
    render(){
        const { loader, moduloData, total } = this.props;
        
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    modulo={moduloData}
                    total={total}
                />
            </LoadMask>
        );
    }
}

export default Ajustes;
