import React, { useState } from "react";
import {
    TRow,
    TTableHeader,
    TCheckbox,
    TColumn,
    TTable,
    TTableBody,
    TButton,
    TCell,
    TDropIndicator,
    TEditableCell,
    TTextField,
    TNumberField,
} from "../../../Utils/Components/TableComponents";
import GenBtn from "../../../components/GenBtn";
import { useDragAndDrop } from "react-aria-components";
import { useSaldo } from "../context/provider";
import { useListData } from "react-stately";
import * as Fa from "react-icons/fa";
import { isTextDropItem } from "react-aria-components";
import styled, { keyframes } from "styled-components";

const dynamicBorder = (props) => {
    const saldoDisponible = props.saldoDisponible;

    if (saldoDisponible > 0) return "1px dashed #D7C100";
    if (saldoDisponible < 0) return "2px solid red";
    return "1px solid #35CD00";
};

const CounterContainer = styled.div`
    display: flex;
    justify-content: center;
    align-self: center;
    justify-self: center;
    font-size: large;
    font-weight: bold;
    display: flex;
    flex-wrap: nowrap;
    margin: 0.5rem 0;
    width: 100%;
    height: 100%;
    background-color: #1e1e1e;
    padding: 0.5rem;
    border-radius: 7px;
    border: ${(props) => dynamicBorder(props)};
    transition: border 350ms;
`;

const moveLeft = keyframes`
  0% { transform: translateX(0); }
  85% { transform: translateX(-10px); }
  100% { transform: translateX(0); }
`;
const moveRight = keyframes`
  0% { transform: translateX(0); }
  85% { transform: translateX(10px); }
  100% { transform: translateX(0); }
`;

const MovingIconRight = styled(Fa.FaArrowRight)`
    animation: ${moveLeft} 1s linear infinite;
    color: #35cd00;
`;
const MovingIconLeft = styled(Fa.FaArrowLeft)`
    animation: ${moveRight} 1s linear infinite;
    color: #35cd00;
`;

export default function AbonosTable({ list, ...props }) {
    const { saldoDisponible, setSaldoDisponible } = useSaldo();
    const [disabledKeys, setDisabledKeys] = useState([]);

    const CounterComponent = (
        <CounterContainer saldoDisponible={saldoDisponible}>
            <div style={{ color: "#d8d8d8" }}>Saldo disponible:&nbsp;</div>
            <div
                style={{
                    color: `${saldoDisponible > 0 ? "#D7C100" : "#35CD00"}`,
                }}
            >
                {saldoDisponible}
            </div>
        </CounterContainer>
    );

    const handleAbonoEdit = (abono, item) => {
        const newAbono = Math.abs(abono ? abono : 0);
        let finalAbono = null;
        let currentSaldoDisponible = saldoDisponible;

        if (newAbono >= item.saldo) {
            currentSaldoDisponible =
                currentSaldoDisponible - (item.saldo - item.abono);
            finalAbono = item.saldo;
        }
        if ((finalAbono || newAbono) >= saldoDisponible) {
            currentSaldoDisponible = 0;
            finalAbono = saldoDisponible == 0 ? item.abono : saldoDisponible;
        }
        if (finalAbono === null) {
            const abonoDifference = newAbono - item.abono;
            currentSaldoDisponible = currentSaldoDisponible - abonoDifference;
            finalAbono = newAbono;
        }
        if (currentSaldoDisponible < 0) {
            currentSaldoDisponible = 0;
        }
        setSaldoDisponible(currentSaldoDisponible);
        list.update(item.id, { ...item, abono: finalAbono });
    };

    const processItems = async (items) => {
        let currentSaldoDisponible = saldoDisponible;
        let processedItems = [];
        const newitems = items.filter(isTextDropItem);

        const parsedItems = await Promise.all(
            newitems.map(async (item) => {
                return JSON.parse(await item.getText("custom-type"));
            })
        );

        const totalAbonos = parsedItems.reduce((acc, item) => {
            return acc + Number(item.saldo);
        }, 0);

        if (totalAbonos > currentSaldoDisponible) {
            processedItems = parsedItems.map((item) => {
                const itemSaldo = Number(item.saldo);
                if (currentSaldoDisponible === 0) {
                    return { ...item, abono: 0, isEditing: false };
                }
                if (itemSaldo >= currentSaldoDisponible) {
                    let returnObj = {
                        ...item,
                        abono: currentSaldoDisponible,
                        isEditing: false,
                    };
                    currentSaldoDisponible = 0; // Update the local variable
                    return returnObj;
                } else {
                    currentSaldoDisponible -= itemSaldo; // Update the local variable
                    return {
                        ...item,
                        abono: itemSaldo,
                        isEditing: false,
                    };
                }
            });
            setSaldoDisponible(currentSaldoDisponible);
            return processedItems;
        }

        setSaldoDisponible(saldoDisponible - totalAbonos);
        return parsedItems.map((item) => ({
            ...item,
            abono: Number(item.saldo),
            isEditing: false,
        }));
    };

    function handleEditStateChange(item) {
        // const keyIndex = disabledKeys.indexOf(item.id);
        // item.isEditing
        //     ? setDisabledKeys((prev) => prev.toSpliced(keyIndex, 1))
        //     : setDisabledKeys([...disabledKeys, item.id]);

        list.update(item.id, {
            ...item,
            isEditing: !item.isEditing,
        });
    }

    let { dragAndDropHooks } = useDragAndDrop({
        // Provide drag data in a custom format as well as plain text.
        getItems(keys) {
            return [...keys].map((key) => {
                let item = list.getItem(key);
                return {
                    "custom-type": JSON.stringify(item),
                    "text/plain": item.numero_patologia,
                };
            });
        },
        renderDropIndicator(target) {
            return <TDropIndicator target={target} />;
        },

        // Accept drops with the custom format.
        acceptedDragTypes: ["custom-type"],

        // Ensure items are always moved rather than copied.
        getDropOperation: () => "move",

        // Handle drops between items from other lists.
        async onInsert(e) {
            let processedItems = await processItems(e.items);
            if (e.target.dropPosition === "before") {
                list.insertBefore(e.target.key, ...processedItems);
            } else if (e.target.dropPosition === "after") {
                list.insertAfter(e.target.key, ...processedItems);
            }
        },

        // Handle drops on the collection when empty.
        async onRootDrop(e) {
            let processedItems = await processItems(e.items);
            list.append(...processedItems);
        },

        // Handle reordering items within the same list.
        onReorder(e) {
            if (e.target.dropPosition === "before") {
                list.moveBefore(e.target.key, e.keys);
            } else if (e.target.dropPosition === "after") {
                list.moveAfter(e.target.key, e.keys);
            }
        },

        // Remove the items from the source list on drop
        // if they were moved to a different list.
        onDragEnd(e) {
            if (e.dropOperation === "move" && !e.isInternal) {
                let saldoRegresado = 0;
                e.keys.forEach((key) => {
                    saldoRegresado += Number(list.getItem(key).abono);
                });
                setSaldoDisponible(saldoDisponible + saldoRegresado);
                list.remove(...e.keys);
            }
        },
    });
    return (
        <React.Fragment>
            <TTable
                aria-label="tabla de abonos"
                selectionMode="multiple"
                selectedKeys={list.selectedKeys}
                onSelectionChange={list.setSelectedKeys}
                dragAndDropHooks={dragAndDropHooks}
                outsideTableChildren={CounterComponent}
                disabledBehavior="all"
                disabledKeys={disabledKeys}
            >
                <TTableHeader>
                    <TColumn />
                    <TColumn>
                        <TCheckbox slot="selection" />
                    </TColumn>
                    <TColumn>Fecha</TColumn>
                    <TColumn>Cliente</TColumn>
                    <TColumn isRowHeader>Patologia</TColumn>
                    <TColumn>Abono</TColumn>
                    <TColumn />
                </TTableHeader>
                <TTableBody
                    items={list.items}
                    renderEmptyState={() => (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontWeight: "bolder",
                            }}
                        >
                            <MovingIconRight />
                            Arrastre documentos aqui
                            <MovingIconLeft />
                        </div>
                    )}
                >
                    {(item) => (
                        <TRow allowsDragging={item.isEditing}>
                            <TCell>
                                <TButton slot="drag">≡</TButton>
                            </TCell>
                            <TCell>
                                <TCheckbox slot="selection" />
                            </TCell>
                            <TCell>{item.fecha_recibido}</TCell>
                            <TCell>{item.clinica.nombre}</TCell>
                            <TCell>{item.numero_patologia}</TCell>
                            <TEditableCell
                                isEditing={item.isEditing}
                                onEdit={(value) => {
                                    handleAbonoEdit(value, item);
                                }}
                                InputComponent={TNumberField}
                                label="Abono"
                                initialValue={item.abono}
                                formatOptions={{
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }}
                            >
                                {String(item.abono).toLocaleString()}
                            </TEditableCell>
                            <TCell>
                                <GenBtn
                                    buttonColor={
                                        item.isEditing
                                            ? null
                                            : "rgba(204, 204, 204)"
                                    }
                                    onPress={() => {
                                        handleEditStateChange(item);
                                    }}
                                >
                                    {item.isEditing ? (
                                        <Fa.FaCheck />
                                    ) : (
                                        <Fa.FaPen />
                                    )}
                                </GenBtn>
                            </TCell>
                        </TRow>
                    )}
                </TTableBody>
            </TTable>
        </React.Fragment>
    );
}
