import React, { Component } from 'react';
import Formulario from './VacacionesForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";
const valEmp = __CONFIG__.empresa;

class Vacaciones extends Component{
    
    componentDidMount = () => {
        const { getModulo, limpiarLista } = this.props;
        
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        if (valEmp === 'fage'){
            getModulo(modulo[6],modulo[5])
        }else{
            getModulo(modulo[5],modulo[4]);
        }
        limpiarLista();
    }
    
    render(){
        const { listarColaborador, loader, modulo, listarPaises, emp, guardarDatos,
            datosColaborador, cargo, fechaAlta, diasV, lista, listaVacaciones, diasT,
            eliminarLinea } = this.props;
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    modulo = {modulo}
                    listarColaborador = {listarColaborador}
                    listarPaises = {listarPaises}
                    emp = {emp}
                    guardarDatos = {guardarDatos}
                    cargo = {cargo}
                    datosColaborador = {datosColaborador}
                    fechaAlta = {fechaAlta}
                    diasV = {diasV}
                    lista = {lista}
                    listaVacaciones = {listaVacaciones}
                    diasT = {diasT}
                    eliminarLinea = {eliminarLinea}
                />
            </LoadMask>
        );
    }
}

export default Vacaciones;
