import React, { Component } from 'react';
import Grid from '../../Utils/GridFiltro';
import PropTypes from 'prop-types';
import { standardActions } from '../../Utils/GridFiltro/StandardActions';
import moment from 'moment';
import NavbarModulo from '../../components/NavbarModulo';

class CorteCajaList extends Component{
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: true
    };

    componentDidMount = () => {
        const { listar, getModulo, leerPeriodoD } = this.props;
        let ruta = window.location.href;
        let periodo = ruta.split('/');
        leerPeriodoD(periodo[5], periodo[7]);
        getModulo(periodo[5],periodo[4]);
        listar();
    }
    render(){
        const { data, loader, modulo, periodoD, imprimirCorte  } = this.props;
        let ruta = window.location.href;
        let id_emp = ruta.split('/');
        return(
            <React.Fragment>
                <NavbarModulo
                    periodoD={periodoD}
                    modulo={modulo}
                />
                <br />
                <h3 className="p-2 bg-secondary title">Cortes de caja</h3>
                <br />
                {periodoD.estado == 'A' &&
                    <div className="d-flex flex-row justify-content-start mb-2">
                        <a
                            href={`/#/produccion/${id_emp[5]}/corte_caja/${id_emp[7]}/crear`}
                            className='btn btn-primary'
                        >
                            Corte de caja
                        </a>
                    </div>
                }
                {data && periodoD.estado == 'A' &&
                    <Grid 
                        hover 
                        striped 
                        data={data}
                        loading={loader} 
                        headerStyle={ { background: 'black' } } 
                        //onPageChange={onPageChange}
                        //onSortChange={onSortChange} 
                    >
                        <TableHeaderColumn
                            isKey
                            dataField="fecha"
                            dataFormat={(cell, row)=>{
                                return moment(cell).format("DD/MM/YYYY");
                            }}
                            width="100"
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Fecha
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="numero"
                            dataSort
                            dataAlign="right"
                            headerAlign="left"
                            width="130"
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Numero
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            thStyle={
                            {color: 'white'}}
                            dataField="id"
                            width="130"
                            dataAlign="center"
                            dataSort
                            dataFormat={standardActions({ 
                                ver_a: "corte_caja",
                                imprimir: imprimirCorte,
                                empresa: id_emp[5],
                                periodo: id_emp[7],
                                modulo: id_emp[4],
                            })} 
                            
                        >
                            Acciones
                        </TableHeaderColumn>
                    </Grid>
                }
                {data && periodoD.estado == 'C' &&
                    <Grid 
                        hover 
                        striped 
                        data={data}
                        loading={loader} 
                        headerStyle={ { background: 'black' } } 
                        //onPageChange={onPageChange}
                        //onSortChange={onSortChange} 
                    >
                        <TableHeaderColumn
                            isKey
                            dataField="fecha"
                            dataFormat={(cell, row)=>{
                                return moment(cell).format("DD/MM/YYYY");
                            }}
                            width="100"
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Fecha
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="numero"
                            dataSort
                            dataAlign="right"
                            headerAlign="left"
                            width="130"
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Numero
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            thStyle={
                            {color: 'white'}}
                            dataField="id"
                            width="130"
                            dataAlign="center"
                            dataSort
                            dataFormat={standardActions({ 
                                ver_a: "corte_caja",
                                imprimir: imprimirCorte,
                                empresa: id_emp[5],
                                periodo: id_emp[7],
                                modulo: id_emp[4],
                            })} 
                        >
                            Acciones
                        </TableHeaderColumn>
                    </Grid>
                }
            </React.Fragment>
        );
    }
}

export default CorteCajaList;
