import React from "react";
import styled from "styled-components";
import { FicInput } from "../../Utils/FormInputComponents";
import Banner from "../common/Banner";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { validar } from "../../components/ValidarNit";
import GenBtn from "../../components/GenBtn";
import InputsContainer from "../common/InputsContainer";
import { getVehiculo, editar } from "./actions";
import { withRouter } from "react-router";

const schema = yup.object({
    placa: yup.string().required("La placa es requerida"),
    marca: yup.mixed().required("La marca es requerida"),
    linea: yup.mixed().required("La linea es requerida"),
    modelo: yup
        .number()
        .typeError("Debe de ser un numero")
        .positive("Debe de ser un numero positivo")
        .test(
            "len",
            "Debe de tener 4 digitos (ej. 2014)",
            (val) => val.toString().length === 4
        )
        .required("El modelo es requerido"),
        chasis: yup.string().required("El numero de chasis es requerido"),
    motor: yup.string().required("El motor es requerido"),
});

function Editar({ history, ...props }) {
    const params = props.match.params;
    const vehiculoId = params.vehiculoId;

    const { control, reset, handleSubmit } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: async () => {
            const formData = await getVehiculo(vehiculoId);
            // setLoadingState(false);
            return formData;
        },
    });

    const onSubmit = (data) => {
        const shouldGoBack = editar(data, vehiculoId);
        if (shouldGoBack) {
            history.goBack();
        }
    };

    return (
        <React.Fragment>
            <Banner>Editar</Banner>
            <form onSubmit={handleSubmit(onSubmit)}>
            <InputsContainer>
                    <FicInput
                        control={control}
                        label="Placa"
                        name="placa"
                    />
                    <FicInput
                        control={control}
                        label="Marca"
                        name="marca"
                    />
                    <FicInput
                        control={control}
                        label="Linea"
                        name="linea"
                        inputMode="numeric"
                    />
                    <FicInput
                        control={control}
                        label="Modelo"
                        name="modelo"
                    />
                    <FicInput
                        control={control}
                        label="Chasis"
                        name="chasis"
                    />
                    <FicInput
                        control={control}
                        label="Motor"
                        name="motor"
                    />
                </InputsContainer>
                <GenBtn style={{ margin: "1rem 0" }} type="submit">
                    Guardar
                </GenBtn>
            </form>
        </React.Fragment>
    );
}

export default withRouter(Editar);
