import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

const SubmenuCompras = ({ item }) => {
    const [subnav, setSubnav] = useState(false);
    const showSubnav = () => {
        setSubnav(!subnav);
    }
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let path = item.path.split('/')
    let u = path[2] === undefined ? '' : path[2]
    let nueva_ruta = '/'+id_emp[4]+'/' + id_emp[5] + '/'+ u
    
    return (
        <div>
            <SidebarLink to={nueva_ruta} onClick={item.subNav && showSubnav}>
                <div>
                    {item.icon}
                    <SidebarLabel>
                        {item.title}
                    </SidebarLabel>
                </div>
                <div>
                    {item.subNav && subnav
                        ? item.iconOpened
                        : item.subNav
                        ? item.iconClosed
                        : null
                    }
                </div>
            </SidebarLink>
            {subnav && item.subNav.map((item, index) => {
                let nueva_rutaS = '/'+id_emp[4]+'/' + id_emp[5] + '/'+ item.path_s
                return (
                    
                    <DropdownLink to={nueva_rutaS} key={index}>
                        <div>
                            {item.icon}
                            <SidebarLabel>
                                {item.title}
                            </SidebarLabel>
                        </div>
                    </DropdownLink>
                )
            })}
        </div>
    )
}

export default SubmenuCompras

const SidebarLink = styled(Link)`
    display: flex;
    color: #fff;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    list-style: none;
    height: 50px;
    text-decoration: none;
    font-size: 17px;

    &:hover {
        background: black;
        border-left: 4px solid #632ce4;
        cursor: pointer;
        color: #007bff;
        text-decoration: none;
    }
`;

const SidebarLabel = styled.span`
    margin-left: 16px;
`;

const DropdownLink = styled(Link)`
    display: flex;
    color: #fff;
    background: #414757;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    list-style: none;
    height: 50px;
    text-decoration: none;
    font-size: 17px;

    &:hover {
        background: black;
        border-left: 4px solid #632ce4;
        cursor: pointer;
        color: #007bff;
        text-decoration: none;
    }
`;