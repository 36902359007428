export const SET_PERIODO = "SET_PERIODO";
export const SET_SETTINGS = "SET_SETTINGS";

export const setPeriodoLaboratorio = (value) => {
    if (value) {
        sessionStorage.setItem("periodoLaboratorio", JSON.stringify(value));
    } else {
        sessionStorage.removeItem("periodoLaboratorio");
    }
    return {
        type: SET_PERIODO,
        payload: value,
    };
};

export const setSettingsLaboratorio = (settings) => ({
    type: SET_SETTINGS,
    payload: settings,
});
