import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import NavbarModulo from '../../components/NavbarModulo';

import {
    AsyncSelectField,
    renderFilePickerB,
    renderField
} from "../../Utils/renderField/renderField";

function LiquidacionForm(props) {
    const { handleSubmit, modulo, listarColaborador, periodoD,
        setArchivo
    } = props;
    let titulo = 'Subir liquidaciones';
    
    let ruta = window.location.href;
    let id_emp = ruta.split('/');

    return (
        <div>
            <NavbarModulo
                periodoD={periodoD}
                modulo={modulo}
                titulo={titulo}
            />
            <form onSubmit={handleSubmit}>
            <br />
            <div className="mb-4 card card-small text-white bg-dark mb-3">
                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                    <div className="d-flex flex-column flex-1 mx-4">
                        <div className='mb-2'>
                            <div className='col-4'>
                                <Field
                                    photo={''}
                                    setFile={setArchivo}
                                    name="archivo"
                                    component={renderFilePickerB}
                                />
                            </div>
                            <div className='row mb-2'>
                                <div className='col-8'>
                                    <Field
                                        name="colaborador"
                                        placeholder="Colaborador..."
                                        className="form-control-find"
                                        component={AsyncSelectField}
                                        loadOptions={listarColaborador}
                                        msj="Colaborador"
                                    />
                                </div>
                            </div>
                            <div className='mb-2 col-12'>
                                <Field
                                    name="descripcion"
                                    placeholder="Descripcion"
                                    component={renderField}
                                    className="form-control"
                                    msj="Descripción"
                                />
                            </div>
                        </div>
                        <div className='d-flex flex-row mt-3 ml-3'>
                            <button
                                className='btn mr-2 mb-3 btn-primary'
                                type='submit'
                            >
                                Subir liquidación
                            </button>
                            <a
                                href={`/#/visitador_medico/${id_emp[5]}/liquidaciones/${id_emp[7]}`}
                                className='btn btn-secondary mb-3'
                            >
                                Regresar
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            </form>
        </div>
    );
}

LiquidacionForm.propTypes = {
    handleSubmit: PropTypes.func,
    crear: PropTypes.bool,
    modulo: PropTypes.object
};

export default reduxForm({
    form: 'FormLiquidacionVM',
})(LiquidacionForm);

