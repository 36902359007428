import React, { Component } from 'react';
import Grid from '../../../Utils/GridFacturaServicio';
import { standardActions } from '../../../Utils/GridFacturaServicio/StandardActions';

class DetalleFacturaList extends Component{
    
    render(){
        const { loader, eliminarLineaServicio, detalle } = this.props;
        return(
            <React.Fragment>
                {detalle &&
                <Grid 
                    hover 
                    striped 
                    data={detalle} 
                    loading={loader}
                    footer
                    headerStyle={ { background: 'black' } } 
                >
                    <TableHeaderColumn
                        isKey
                        dataField="desc"
                        width="400"
                        headerAlign="center"
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Descripción
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="cantidad"
                        dataAlign='right'
                        width="150"
                        headerAlign="center"
                        dataSort
                        dataFormat={(cell, row)=>{
                            return new Intl.NumberFormat("en-US",{minimumFractionDigits: 6}).format(cell);
                        }}
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Cantidad
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="costo"
                        dataAlign='right'
                        width="150"
                        headerAlign="center"
                        dataFormat={(cell, row)=>{
                            return new Intl.NumberFormat("en-US",{minimumFractionDigits: 5}).format(cell);
                        }}
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Precio Unitario
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="total"
                        dataAlign='right'
                        width="150"
                        headerAlign="center"
                        dataFormat={(cell, row)=>{
                            return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(cell);
                        }}
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Total
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        thStyle={
                        {color: 'white'}}
                        dataField="id"
                        width="150"
                        dataAlign="center"
                        dataSort
                        dataFormat={standardActions({
                            eliminar: eliminarLineaServicio })} 
                    >
                        Acciones
                    </TableHeaderColumn>
                </Grid>
                }
            </React.Fragment>
        );
    }
}

export default DetalleFacturaList;
