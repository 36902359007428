import React, { useState, useEffect, useRef } from "react";
import Toolbar from "@material-ui/core/Toolbar";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import { FicComboBox, FicFileDrop } from "../../../Utils/FormInputComponents";
import GenBtn from "../../../components/GenBtn";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const FormContainer = styled.form`
    display: flex;
    margin-top: 2%;
    margin-bottom: 2%;
    gap: 2%;
    align-items: center;
    /* justify-content: space-between; */
`;

const getCurrentYear = () => new Date().getFullYear();

const generateYearList = () => {
    const currentYear = getCurrentYear();
    const previousYear = currentYear - 1;

    const yearList = [
        { value: currentYear, label: currentYear.toString() },
        { value: previousYear, label: previousYear.toString() },
    ];

    return yearList;
};

const mes = [
    { value: 1, label: "Enero" },
    { value: 2, label: "Febrero" },
    { value: 3, label: "Marzo" },
    { value: 4, label: "Abril" },
    { value: 5, label: "Mayo" },
    { value: 6, label: "Junio" },
    { value: 7, label: "Julio" },
    { value: 8, label: "Agosto" },
    { value: 9, label: "Septiembre" },
    { value: 10, label: "Octubre" },
    { value: 11, label: "Noviembre" },
    { value: 12, label: "Diciembre" },
];

const schema = yup.object().shape({
    year: yup.mixed().required("Seleccione un año"),
    mes_inicial: yup.mixed().required("Seleccione un mes"),
    mes_final: yup.mixed().required("Seleccione un mes"),
});

const yearOptions = generateYearList();

function BalanceDeSaldosHome(props) {
    const { obtenerCierresParciales } = props;
    const [tableData, setTableData] = useState();
    const [excelDownloadElement, setExcelDownloadElement] = useState();
    const [fileExists, setFileExists] = useState(false);
    const [numValues, setNumValues] = useState();
    const [mesesIngresados, setMesesIngresados] = useState([
        { mes: 1, hasData: false },
        { mes: 2, hasData: false },
        { mes: 3, hasData: false },
        { mes: 4, hasData: false },
        { mes: 5, hasData: false },
        { mes: 6, hasData: false },
        { mes: 7, hasData: false },
        { mes: 8, hasData: false },
        { mes: 9, hasData: false },
        { mes: 10, hasData: false },
        { mes: 11, hasData: false },
        { mes: 12, hasData: false },
    ]);

    const TableContainer = styled.div`
        overflow: auto;
        height: 80vh;
        border: ${fileExists ? "2px solid #dd0000" : ""};
        resize: vertical;
        margin-bottom: 100px

        & ::-webkit-resizer {
            display: ${fileExists ? "none" : ""};
            color: #00ff00;
        }

    `;

    const tdStyle = {
        paddingLeft: "calc(2% + 6px)",
        // paddingRight: "calc(2% + 10px)",
        borderLeft: "2px solid #121212",
        borderRight: "2px solid #121212",
        // whiteSpace: "nowrap",
        // overflow: "hidden",
        // textOverflow: "ellipsis",
    };

    const {
        handleSubmit,
        formState: { errors },
        control,
        reset,
        watch,
    } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: {
            year: null,
            mes_inicial: null,
            mes_final: null,
        },
    });

    useEffect(() => {
        if (tableData) {
            console.table(tableData[0]);
            setNumValues(
                Math.max(
                    ...Object.values(tableData).map(
                        (obj) => Object.values(obj).length
                    )
                )
            );
        }
    }, [tableData]);

    useEffect(() => {
        return () => {
            if (excelDownloadElement) {
                window.URL.revokeObjectURL(excelDownloadElement);
            }
        };
    }, []);

    const year = watch("year");
    useEffect(() => {
        if (year) {
            chequearMeses(year.value);
        }
    }, [year]);

    const onSubmit = async (data) => {
        await obtenerCierresParciales(data).then((response) => {
            if (response != undefined) {
                reset();
                setTableData(response.resumen);
                setExcelDownloadElement(response.excelLinkElement);
            }
        });
    };

    const chequearMeses = async (year) => {
        if (year) {
            let meses = await chequearMesesIngresados(year);
            let mesesIngresadosActualizados = meses.map((mesObj, index) => {
                return { mes: mesObj.month, hasData: mesObj.hasData };
            });
            setMesesIngresados(mesesIngresadosActualizados);
        }
    };

    return (
        <React.Fragment>
            <h4 style={{ margin: "2% 0", borderBottom: "1px dashed #bd0034" }}>
                Cierres parciales
            </h4>

            <FormContainer
                encType="multipart/form-data"
                onSubmit={handleSubmit(onSubmit)}
            >
                <FicComboBox
                    control={control}
                    name="year"
                    label="Año"
                    options={yearOptions}
                    nonAsync
                    componentStyle={{ width: "15%", minWidth: "206px" }}
                />
                <FicComboBox
                    control={control}
                    name="mes_inicial"
                    label="Mes"
                    options={mes}
                    nonAsync
                    componentStyle={{ width: "15%", minWidth: "206px" }}
                />
                <FicComboBox
                    control={control}
                    name="mes_final"
                    label="Mes"
                    options={mes}
                    nonAsync
                    componentStyle={{ width: "15%", minWidth: "206px" }}
                />

                <GenBtn type="submit">Buscar</GenBtn>
            </FormContainer>
            {excelDownloadElement && (
                <GenBtn
                    onPress={() => {
                        excelDownloadElement.click();
                    }}
                >
                    Exportar a excel
                </GenBtn>
            )}
            <TableContainer>
                <table
                    style={{
                        padding: "2%",
                        tableLayout: "fixed",
                        minWidth: "100%",
                    }}
                >
                    <thead>
                        <tr>
                            {tableData &&
                                Object.keys(tableData).map((header, index) => {
                                    if (header == "0") {
                                        header = "Cuenta";
                                    }

                                    return (
                                        <th
                                            key={index}
                                            style={{
                                                minWidth: `${
                                                    index == 0 ? "70ch" : "20ch"
                                                }`,
                                                textAlign: "center",
                                                position: "sticky",
                                                top: "0",
                                                backgroundColor: "#2e2e2e",
                                                color: "#fff",
                                            }}
                                        >
                                            {header}
                                        </th>
                                    );
                                })}
                        </tr>
                    </thead>
                    <tbody
                        style={{ backgroundColor: "#1e1e1e", color: "#fff" }}
                    >
                        {Array.from({ length: numValues }).map(
                            (_, innerIndex) => (
                                <tr
                                    key={innerIndex}
                                    style={{
                                        background: "#1e1e1e",
                                        paddingLeft: "calc(2% + 18px)",
                                        paddingRight: "calc(2% + 18px)",
                                        borderBottom: `1px solid rgb(189, 0, 53, ${
                                            innerIndex % 2 != 0 ? "0.4" : "0.65"
                                        })`,
                                    }}
                                >
                                    {tableData != undefined &&
                                        Object.values(tableData).map(
                                            (obj, index) => {
                                                try {
                                                    if (obj[innerIndex]) {
                                                        return (
                                                            <td
                                                                key={index}
                                                                style={{
                                                                    color: `${
                                                                        obj[
                                                                            innerIndex
                                                                        ] < 0
                                                                            ? "red"
                                                                            : ""
                                                                    }`,
                                                                    ...tdStyle,
                                                                }}
                                                            >
                                                                {index == 0
                                                                    ? obj[
                                                                          innerIndex
                                                                      ]
                                                                    : obj[
                                                                          innerIndex
                                                                      ].toLocaleString()}
                                                            </td>
                                                        );
                                                    } else
                                                        return (
                                                            <td
                                                                style={tdStyle}
                                                                key={index}
                                                            >
                                                                0.00
                                                            </td>
                                                        );
                                                } catch (error) {
                                                    console.log(error);
                                                }
                                            }
                                        )}
                                </tr>
                            )
                        )}
                    </tbody>
                </table>
            </TableContainer>
        </React.Fragment>
    );
}

export default BalanceDeSaldosHome;
