import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { FicComboBox } from "../../../Utils/FormInputComponents";
import GenBtn from "../../../components/GenBtn";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import DialogModal from "../../../components/DialogModal";
import DataTableBase from "../../../components/DataTableWSearch";
import { FicNumber } from "../../../Utils/FormInputComponents";
import LoadMask from "../../../Utils/LoadMask/LoadMask";

const FormContainer = styled.form`
    display: flex;
    gap: 2%;
    align-items: center;
    /* justify-content: space-between; */
`;

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-top: 2%;
    margin-bottom: 2%;
    /* overflow: visible; */
`;

const DialogContent = styled.div`
    padding: 1.375rem;
    /* min-width: 300px; */
    width: clamp(300px, 100%, 1300px);
    min-height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.755rem;
    /* overflow: visible; */
    transition: height 200ms ease-in;
`;

const DataTableContainer = styled.div`
    width: 100%;
    /* min-height: 592px; */
    max-height: 610px;
    overflow-y: scroll;
`;

const getCurrentYear = () => new Date().getFullYear();

const generateYearList = () => {
    const currentYear = getCurrentYear();
    const previousYear = currentYear - 1;

    const yearList = [
        { value: currentYear, label: currentYear.toString() },
        { value: previousYear, label: previousYear.toString() },
    ];

    return yearList;
};

const mes = [
    { value: 1, label: "Enero" },
    { value: 2, label: "Febrero" },
    { value: 3, label: "Marzo" },
    { value: 4, label: "Abril" },
    { value: 5, label: "Mayo" },
    { value: 6, label: "Junio" },
    { value: 7, label: "Julio" },
    { value: 8, label: "Agosto" },
    { value: 9, label: "Septiembre" },
    { value: 10, label: "Octubre" },
    { value: 11, label: "Noviembre" },
    { value: 12, label: "Diciembre" },
];

const schema = yup.object().shape({
    year: yup.mixed().required("Seleccione un año"),
    mes: yup.mixed().required("Seleccione un mes"),
    porcentaje: yup.string().required("Ingrese un porcentaje"),
});
const modalSchema = yup.object().shape({
    contribuyente: yup.mixed().required("Seleccione un contribuyente"),
});

const yearOptions = generateYearList();

const columns = [
    {
        name: "NIT",
        selector: (row) => row.nit,
        sortable: true,
    },
    {
        name: "Nombre",
        selector: (row) => row.razon_social,
        sortable: true,
    },
];

function LibroCompras(props) {
    const {
        obtenerResumen,
        agregarProveedor,
        listarProveedores,
        listarContribuyentesComboBox,
    } = props;
    const [tableData, setTableData] = useState();
    const [proveedoresTableData, setProveedoresTableData] = useState([]);
    const [excelDownloadElement, setExcelDownloadElement] = useState();
    const [fileExists, setFileExists] = useState(false);
    const dialogRef = useRef(null);
    const [numValues, setNumValues] = useState();
    const [loadingState, setLoadingState] = useState(false);

    const TableContainer = styled.div`
        overflow: auto;
        height: 80vh;
        /* border: ${fileExists ? "2px solid #dd0000" : ""}; */
        border: ${fileExists ? "2px dashed #bd0034" : ""};
        resize: vertical;
        margin-bottom: 100px

        & ::-webkit-resizer {
            display: ${fileExists ? "none" : ""};
            color: #00ff00;
        }

    `;

    const tdStyle = {
        paddingLeft: "calc(2% + 6px)",
        // paddingRight: "calc(2% + 10px)",
        borderLeft: "2px solid #121212",
        borderRight: "2px solid #121212",
        // whiteSpace: "nowrap",
        // overflow: "hidden",
        // textOverflow: "ellipsis",
    };

    const {
        handleSubmit,
        formState: { errors },
        control,
        reset,
        watch,
    } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: {
            year: null,
            mes: null,
            porcentaje: "",
        },
    });

    const {
        handleSubmit: modalHandleSubmit,
        control: modalControl,
        reset: modalReset,
    } = useForm({
        mode: "onSubmit",
        resolver: yupResolver(modalSchema),
        defaultValues: { contribuyente: null },
    });

    const fetchData = async () => {
        const data = await listarProveedores();
        if (data) {
            setProveedoresTableData(data);
        }
    };

    useEffect(() => {
        if (tableData) {
            setNumValues(
                Math.max(
                    ...Object.values(tableData).map(
                        (obj) => Object.values(obj).length
                    )
                )
            );
        }
    }, [tableData]);

    useEffect(() => {
        fetchData();
        return () => {
            if (excelDownloadElement) {
                window.URL.revokeObjectURL(excelDownloadElement);
            }
        };
    }, []);

    const onSubmit = async (data) => {
        reset();
        setLoadingState(true);
        await obtenerResumen(data).then((response) => {
            if (response != undefined) {
                setFileExists(true);
                setTableData(response.resumen);
                setExcelDownloadElement(response.excelLinkElement);
            }
            setLoadingState(false);
        });
    };

    const modalOnSubmit = async (data) => {
        data.contribuyente = data.contribuyente.value;
        reset();
        await agregarProveedor(data);
        fetchData();
    };

    return (
        <React.Fragment>
            <h4 style={{ margin: "2% 0", borderBottom: "1px dashed #bd0034" }}>
                Libro de compras
            </h4>
            <Container>
                <FormContainer
                    encType="multipart/form-data"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <FicComboBox
                        control={control}
                        name="year"
                        label="Año"
                        options={yearOptions}
                        nonAsync
                        componentStyle={{ width: "15%", minWidth: "206px" }}
                    />
                    <FicComboBox
                        control={control}
                        name="mes"
                        label="Mes"
                        options={mes}
                        nonAsync
                        componentStyle={{ width: "15%", minWidth: "206px" }}
                    />
                    <FicNumber
                        control={control}
                        formatOptions={{
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2,
                            style: "percent",
                        }}
                        style={{ maxWidth: "200px" }}
                        minValue={0}
                        name="porcentaje"
                        label="Porcentaje"
                    />

                    <LoadMask
                        loading={loadingState}
                        color="#bd0034"
                        blur={true}
                    >
                        <GenBtn type="submit">Generar</GenBtn>
                    </LoadMask>
                </FormContainer>
                <GenBtn
                    textColor="hsla(206, 100%, 50%)"
                    background="hsla(206, 100%, 50%, .2)"
                    style={{ padding: "0.8rem" }}
                    onPress={() => {
                        fetchData();
                        dialogRef.current.showModal();
                    }}
                >
                    Proveedores
                </GenBtn>
            </Container>
            {excelDownloadElement && (
                <GenBtn
                    style={{ margin: "1.225rem" }}
                    onPress={() => {
                        excelDownloadElement.click();
                    }}
                >
                    Exportar a excel
                </GenBtn>
            )}
            <TableContainer>
                <table
                    style={{
                        padding: "2%",
                        tableLayout: "fixed",
                        minWidth: "100%",
                    }}
                >
                    <thead>
                        <tr>
                            {tableData &&
                                Object.keys(tableData).map((header, index) => {
                                    return (
                                        <th
                                            key={index}
                                            style={{
                                                // minWidth: "20ch",
                                                minWidth: `${
                                                    index == 4 ? "40ch" : "20ch"
                                                }`,
                                                textAlign: "center",
                                                position: "sticky",
                                                top: "0",
                                                backgroundColor: "#2e2e2e",
                                                color: "#fff",
                                            }}
                                        >
                                            {header}
                                        </th>
                                    );
                                })}
                        </tr>
                    </thead>
                    <tbody
                        style={{ backgroundColor: "#1e1e1e", color: "#fff" }}
                    >
                        {Array.from({ length: numValues }).map(
                            (_, innerIndex) => (
                                <tr
                                    key={innerIndex}
                                    style={{
                                        background: "#1e1e1e",
                                        paddingLeft: "calc(2% + 18px)",
                                        paddingRight: "calc(2% + 18px)",
                                        borderBottom: `1px solid rgb(189, 0, 53, ${
                                            innerIndex % 2 != 0 ? "0.4" : "0.65"
                                        })`,
                                    }}
                                >
                                    {tableData != undefined &&
                                        Object.values(tableData).map(
                                            (obj, index) => {
                                                try {
                                                    if (obj[innerIndex]) {
                                                        return (
                                                            <td
                                                                key={index}
                                                                style={{
                                                                    color: `${
                                                                        obj[
                                                                            innerIndex
                                                                        ] < 0
                                                                            ? "red"
                                                                            : ""
                                                                    }`,
                                                                    textAlign: `${
                                                                        index >
                                                                        4
                                                                            ? "right"
                                                                            : ""
                                                                    }`,
                                                                    paddingRight:
                                                                        "calc(1.7% + 2px)",
                                                                    ...tdStyle,
                                                                }}
                                                            >
                                                                {index == 0
                                                                    ? obj[
                                                                          innerIndex
                                                                      ]
                                                                    : obj[
                                                                          innerIndex
                                                                      ].toLocaleString()}
                                                            </td>
                                                        );
                                                    } else
                                                        return (
                                                            <td
                                                                style={{
                                                                    textAlign: `${
                                                                        index >
                                                                        4
                                                                            ? "right"
                                                                            : ""
                                                                    }`,
                                                                    paddingRight:
                                                                        "calc(1.7% + 2px)",
                                                                    ...tdStyle,
                                                                }}
                                                                key={index}
                                                            >
                                                                0.00
                                                            </td>
                                                        );
                                                } catch (error) {
                                                    console.log(error);
                                                }
                                            }
                                        )}
                                </tr>
                            )
                        )}
                    </tbody>
                </table>
            </TableContainer>

            <DialogModal
                dialogRef={dialogRef}
                title="Proveedores"
                style={{
                    overflow: "visible",
                    minWidth: "307px",
                    width: "clamp(307px, 100%, 1100px)",
                }}
                noClosingOverlay
            >
                <DialogContent>
                    <form
                        onSubmit={modalHandleSubmit(modalOnSubmit)}
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                            width: "100%",
                            gap: "6%",
                        }}
                    >
                        <FicComboBox
                            loadOptions={listarContribuyentesComboBox}
                            control={modalControl}
                            name="contribuyente"
                            label="Contribuyente"
                        />
                        <GenBtn style={{ minWidth: "150px" }} type="submit">
                            Agregar
                        </GenBtn>
                    </form>
                    <DataTableContainer>
                        <DataTableBase
                            data={proveedoresTableData}
                            paginationPerPage={10}
                            noSearch
                            columns={columns}
                            paginationComponentOptions={{
                                rowsPerPageText: "Filas por página",
                                rangeSeparatorText: "de",
                                selectAllRowsItem: true,
                                selectAllRowsItemText: "Todos",
                            }}
                        />
                    </DataTableContainer>
                </DialogContent>
            </DialogModal>
        </React.Fragment>
    );
}

export default LibroCompras;
