import { handleActions } from "redux-actions";
import { SET_PERIODO, SET_SETTINGS } from "./actions";

const initialState = {
    periodo: null,
    settings: {},
};

export const periodoReducer = (state = initialState, action) => {
    return {
        ...state,
        periodo: action.payload,
    };
};

export const settingsReducer = (state = initialState, action) => {
    return {
        ...state,
        settings: action.payload,
    };
};

const reducers = {
    [SET_PERIODO]: periodoReducer,
    [SET_SETTINGS]: settingsReducer,
};
export default handleActions(reducers, initialState);
