import React, { useRef } from "react";
// import { Text } from "react-aria-components";
import { TextField, Label, TextArea, Text } from "react-aria-components";
import { useController } from "react-hook-form";
import "./FicTextArea.css";
import { useEffect } from "react";

export default function FicTextArea({
    label,
    description,
    errorMessage,
    nonForm,
    control,
    name,
    style,
    allCaps,
    ...props
}) {
    const inputRef = useRef(null);

    useEffect(() => {
        const input = inputRef.current;
        if (input) {
            input.style.height = input.scrollHeight + "px";
        }
    }, [inputRef.current && inputRef.current.value]);

    if (nonForm) {
        return (
            <TextField {...props}>
                <Label>{label}</Label>
                <TextArea ref={inputRef} />
                {description && <Text slot="description">{description}</Text>}
                {errorMessage && (
                    <Text slot="errorMessage">{errorMessage}</Text>
                )}
            </TextField>
        );
    }
    const { field, fieldState } = useController({ control, name });
    return (
        <TextField
            {...props}
            {...field}
            isInvalid={fieldState.invalid}
            style={{ width: "100%", ...style }}
        >
            <Label>{label}</Label>
            <TextArea ref={inputRef} />
            {description && <Text slot="description">{description}</Text>}
            {fieldState.error && (
                <Text slot="errorMessage">{fieldState.error.message}</Text>
            )}
        </TextField>
    );
}
