import React, { Component } from 'react';
import Formulario from './CentroCostoForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";

class CentroCosto extends Component{
    state = {
        crear: true,
    }

    componentDidMount() {
        const { match, leer, getModulo } = this.props;
        const id = match.params.id;
        if (id){
            this.setState({ crear: false });
            leer(id);
        }
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        getModulo(modulo[5],modulo[4]);
    }
    render(){
        const { registroCentroCosto, modificarCentroCosto, loader, modulo } = this.props;
        const { crear } = this.state;
        const funcionEnvio = crear ? registroCentroCosto : modificarCentroCosto;
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    onSubmit={funcionEnvio}
                    crear={crear}
                    modulo={modulo}
                />
           </LoadMask>
        );
    } 
}

export default CentroCosto;