import React, { Component } from 'react';
import Grid from '../../Utils/GridConciliacion';
import moment from 'moment';

class ConciliacionDetalle extends Component{
    
    render(){
        const { loader, detalle, actualizar} = this.props;

        const handleChange = (e) => {
            const item = e.target.name;
            const fechaI = e.target.getAttribute('fechaIn');
            const fechaF = e.target.getAttribute('fechaFi');
            const fechaOp = e.target.getAttribute('fechaOp');
            const operado = e.target.getAttribute('operado');
            const isChecked = e.target.checked;
            actualizar(isChecked, item, fechaI, fechaF, fechaOp, operado);
        };

        // Función para definir el estilo de la fila
        const rowStyle = (row, rowIndex) => {
            if (row === undefined) { 
                return {}; // Estilo por defecto
            }
            if (!row.fecha_registro || !row.fecha_operado) {
                return {};
            }else{
                const fechaI = moment(row.fechaInicio);
                const fechaF = moment(row.fechaFinal);
                const fechaOperado = moment(row.fecha_operado);
                
                if (fechaOperado.isBetween(fechaI, fechaF, 'day', '[]')) {
                    return {}
                } else {
                    return { background: '#000000' };
                }
            }
        };

        return(
            <React.Fragment>
                {detalle &&
                    <Grid 
                        hover 
                        striped 
                        data={detalle} 
                        loading={loader}
                        headerStyle={ { background: 'black' } } 
                        trStyle={rowStyle} // Aplica el estilo de la fila aquí
                        //onSortChange={onSortChange} 
                    >
                    <TableHeaderColumn
                        isKey
                        dataField="tipo"
                        width="100"
                        dataSort
                        thStyle={ 
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Tipo
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="fecha_registro"
                        width="115"
                        dataFormat={(cell, row)=>{
                            return moment(cell).format("DD/MM/YYYY");
                        }}
                        dataSort
                        thStyle={ 
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Fecha
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="numero"
                        width="150"
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Numero
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="nombre"
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Nombre
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="debitos"
                        headerAlign="center"
                        dataAlign='right'
                        dataSort
                        dataFormat={(cell, row)=>{
                            if (parseFloat(cell) === 0.00){
                                return ''
                            }else{
                                return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(cell);
                            }
                        }}
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Debitos
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="creditos"
                        headerAlign="center"
                        dataAlign='right'
                        dataSort
                        dataFormat={(cell, row)=>{
                            if (parseFloat(cell) === 0.00){
                                return ''
                            }else{
                                return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(cell);
                            }
                        }}
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Creditos
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="operado"
                        width="50"
                        dataFormat={(cell, row)=>{
                            return <input 
                                type="checkbox" 
                                name={row.id} 
                                fechaIn={row.fechaInicio}
                                fechaFi={row.fechaFinal}
                                fechaOp={row.fecha_operado}
                                operado={row.operado}
                                checked={cell} 
                                onChange={handleChange} 
                            />;
                        }}
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                    </TableHeaderColumn>
                </Grid>
                }
            </React.Fragment>
        );
    }
}

export default ConciliacionDetalle;
