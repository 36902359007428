import React, { Component, useState } from 'react';
import styled from 'styled-components';
import Modal from '../../components/Modal';
import PropTypes from 'prop-types';

function FormSeleccionarCuenta(props) {
    const {
        cuenta,
        leerCuentaB
    } = props;


    const [cuentaB, setCuenta] = useState("");

    const handleChangeCuenta = e => {
        setCuenta(e.target.value);
        leerCuentaB(e.target.value);
    }

    const [estadoModal, cambiarEstadoModal] = useState(true);
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    return (
        <div className='container'>
            <Modal
                estado={estadoModal}
                cambiarEstado={cambiarEstadoModal}
                titulo="Seleccionar Cuenta Bancaria"
                botonCerrar="false"
            >
                {cuenta &&
                    <div className="form-floating mb-3">
                        <select className="form-select" id="cuenta"
                            value={cuentaB} onChange={handleChangeCuenta} >
                            <option selected>Seleccionar...</option>
                            {cuenta.map(cu =>
                                <option key={cu.value} value={cu.value}>{cu.label}</option>)
                            }

                        </select>
                        <label className='form-label' htmlfor="cuenta">Cuenta Bancaria</label>
                    </div>
                }
                <br />
                <Contenido>
                    {cuenta &&
                        <a
                            href={`/#/bancos/${id_emp[5]}/cheques/${id_emp[7]}/crear/${cuentaB}`}
                            className='btn mr-2 mb-3 btn-primary'
                        >
                            Seleccionar
                        </a>
                    }
                </Contenido>
            </Modal>
        </div>
    );

}
FormSeleccionarCuenta.propTypes = {
    cuenta: PropTypes.string.isRequired,
    leerCuentaB: PropTypes.func,
};

export default (FormSeleccionarCuenta);

const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;