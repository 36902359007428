import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import Modal from '../../../components/components/ModalA'

import {
    renderField,
    renderDayPickerB,
    SelectField,
    AsyncSelectField,
    renderCurrencyFloat,
    renderFieldCheck,
    renderNumber,
    renderCurrencyFloatTC
} from "../../Utils/renderField/renderField";

function FacturacionForm(props) {
    const {
        crear,
        bloqueo,
        editar,
        tipos,
        listarClientes,
        registroCliente,
        obtenerPaises,
        impuesto,
        datos,
        estable,
        tipoCambio,
        periodo,
        listarCuentas,
        polizaFactura,
        lecturaFac
    } = props;

    let autoFocus = true;
    
    const [anulado, setAnulado] = useState(false);

    const [bienes, setBienes] = useState(0.00);
    const [servicios, setServicios] = useState(0.00);
    const [exento, setExento] = useState(0.00); 
    const [tIva, setTIva] = useState(0.00);

    const [base, setBase] = useState(0.00);
    const [total, setTotal] = useState(0.0);
    const [iva, setIva] = useState(editarD === true ? lecturaFac.afecta_iva : crear === true ? true : iva);

    const [ivaBienes, setIvaBienes] = useState(0.00);
    const [ivaServicios, setIvaServicios] = useState(0.00);
    const [baseBienes, setBaseBienes] = useState(0.00);
    const [baseServicios, setBaseServicios] = useState(0.00);

    const [tipoD, setTipoD] = useState(0);
    const [tipoFac, setTipoFac] = useState(0);

    const [editarD, setEditarD] = useState(editar);
    const [editarT, setEditarT] = useState(editar);
    const [editarTF, setEditarTF] = useState(editar);

    const [tipoCambioD, setTipoCambioD] = useState(1.000000);
    const [estadoModal, cambiarEstadoModal] = useState(false);
    const [dolares, setDolares] = useState(false);

    const onCheckboxClickedAnulado = (idx, isChecked) => {
        setAnulado(isChecked);
    }

    const handleChange = (e) => {
        let existe = tipos.find(element => element.value === e.target.value);
        localStorage.setItem('tipoDocumentoFac', e.target.value);
        setEditarT(false);
        setTipoD(e.target.value);
        if (existe.search === 'IVA') {
            setIva(true);
            localStorage.setItem('A_ivaF', true);
        }
        if (existe.search === 'SIVA') {
            setIva(false);
            localStorage.setItem('A_ivaF', false);
        }

    }

    const tipoF = [
        { label: "LOCAL", value: "LOC" },
        { label: "EXPORTACION", value: "EXP" },
    ];

    const tiposCL = [
        {
            value: 'L',
            label: 'LOCAL'
        },
        {
            value: 'E',
            label: 'EXTERIOR'
        },
        {
            value: 'P',
            label: 'PERSONA'
        },
    ];
    const onCheckboxClicked = (idx, isChecked) => {
        setDolares(isChecked);
    }
    const handleChangeFac = (e) => {
        if (e.target.value === 'EXP') {
            setTipoFac(e.target.value);
            setDolares(true);
            setEditarTF(false);
            localStorage.setItem('tipo_factura_venta', e.target.value);
            localStorage.setItem('dolares_fact', 'True');
        }
        if (e.target.value === 'LOC') {
            setTipoFac(e.target.value);
            setDolares(false);
            setEditarTF(false);
            localStorage.setItem('tipo_factura_venta', e.target.value);
            localStorage.setItem('dolares_fact', 'False');
        }   
    }
    let PIva = parseFloat(parseFloat(parseFloat(impuesto.porcentaje_iva)/100)+1).toFixed(2);
    return (
        <div>
            {crear === false && editar == false &&
                <div className='row mb-2'>
                    <div className='col-3'>
                        <Field
                            name="codigo"
                            placeholder="Codigo establecimiento"
                            className="form-control"
                            component={renderField}
                            disabled={true}
                            msj="Código establecimiento"
                        />
                    </div>
                    <div className='col-3'>
                        <Field
                            name="establecimiento"
                            placeholder="Nombre establecimiento"
                            className="form-control"
                            component={renderField}
                            disabled={true}
                            msj="Nombre establecimiento"
                        />
                    </div>
                </div>
            }
            {editar === true &&
                <div className='row mb-2'>
                    <div className='col-3'>
                        <Field
                            name="codigo"
                            placeholder="Codigo establecimiento"
                            className="form-control"
                            component={renderField}
                            disabled={true}
                            msj="Código establecimiento"
                        />
                    </div>
                    <div className='col-3'>
                        <Field
                            name="establecimiento"
                            placeholder="Nombre establecimiento"
                            className="form-control"
                            component={renderField}
                            disabled={true}
                            msj="Nombre establecimiento"
                        />
                    </div>
                </div>
            }
            {crear === true &&
                <div className='row mb-2'>
                    <div className='col-3'>
                        <div className="form-floating">
                            <input
                                placeholder="Codigo establecimiento"
                                type="text"
                                autocomplete="off"
                                name="codigo"
                                className="form-control"
                                value={estable.codigo}
                                disabled={true}
                            />
                            <label className='form-label' htmlFor="codigo">
                                Código establecimiento
                            </label>
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className="form-floating">
                            <input
                                placeholder="Nombre establecimiento"
                                type="text"
                                autocomplete="off"
                                name="nombre"
                                className="form-control"
                                value={estable.nombre_comercial}
                                disabled={true}
                            />
                            <label className='form-label' htmlFor="nombre">
                                Nombre establecimiento
                            </label>
                        </div>
                    </div>
                </div>
            }
            <div className='row mb-2'>
                <div className='col-3'>
                    <Field
                        autoFocus={autoFocus}
                        disabled={bloqueo}
                        name="fechaRegistro"
                        component={renderDayPickerB}
                        msj="Fecha Registro"
                        onChange={(e, value) => {
                            if (value) {
                                if (crear === true) {
                                    setBase(0.00);
                                    setTIva(0.00);
                                    setTotal(0.00);
                                }
                            }
                        }}
                    />
                </div>
                {((crear === false && editar == false) || editar === true) &&

                    <div className='col-6'>
                        <Field
                            name="nombreCli"
                            placeholder="Cliente"
                            className="form-control"
                            component={renderField}
                            disabled={true}
                            msj="Cliente"
                        />
                    </div>
                }
                {(crear === true) &&
                    <div className='col-6'>
                        <Field
                            name="cliente_documento"
                            placeholder="Cliente..."
                            className="form-control-find"
                            component={AsyncSelectField}
                            loadOptions={listarClientes}
                            msj="Cliente"
                        />
                    </div>
                }
                {(crear === true) &&
                    <div className='col-3 mt-2'>
                        <button
                            className='btn mr-2 mb-3 btn-secondary'
                            onClick={() => cambiarEstadoModal(!estadoModal)}
                        >
                            Crear nuevo cliente
                        </button>
                    </div>
                }
            </div>
            {(editar === true) &&
                <div className='row mb-2'>
                    <div className='col-3'>
                    </div>
                    <div className='col-6'>
                        <Field
                            name="cliente_documento"
                            placeholder="Cliente..."
                            className="form-control-find"
                            component={AsyncSelectField}
                            loadOptions={listarClientes}
                            msj="Clientes"
                        />
                    </div>
                </div>
            }
            <div className='row mb-2'>
                {crear === false && editar == false &&
                    <div className='col-3'>
                        {tipos &&
                            <div className="form-floating">
                                <Field
                                    name="tipo_documento"
                                    options={tipos}
                                    crear={crear}
                                    dato={crear === true ? 0 : lecturaFac.tipo_documento}
                                    component={SelectField}
                                    className="form-select"
                                    disabled={bloqueo}
                                    msj="Tipo de documento"
                                />
                            </div>
                        }
                    </div>
                }
                {(crear === true) &&
                    <div className='col-3'>
                        <div className="form-floating">
                            <select className="form-select" id="tipo" name="tipo"
                                value={tipoD} onChange={handleChange} >
                                <option selected>Seleccionar...</option>
                                {tipos.map(
                                    tip => <option key={tip.value} value={tip.value}
                                    >{tip.label}
                                    </option>)
                                }
                            </select>
                            <label className='form-label' htmlFor="mes">Tipo de documento</label>
                        </div>
                    </div>
                }
                {(editar == true) &&
                    <div className='col-3'>
                        <div className="form-floating">
                            <Field
                                name="tipo_doc"
                                options={tipos}
                                crear={crear}
                                dato={crear === true ? 0 : lecturaFac.tipo_documento}
                                component={SelectField}
                                className="form-select"
                                msj="Tipo de documento"
                            />
                        </div>
                    </div>
                }
                {(crear === false && editar === false) &&
                    <div className='col-3'>
                        <div className="form-floating">
                            <Field
                                name="tipo_factura"
                                options={tipoF}
                                crear={crear}
                                dato={crear === true ? 0 : lecturaFac.tipo_factura}
                                component={SelectField}
                                className="form-select"
                                disabled={bloqueo}
                                msj="Tipo Factura"
                            />
                        </div>
                    </div>
                }
                {(crear === true) &&
                    <div className='col-3'>
                        <div className="form-floating">
                            <select className="form-select" id="tipo" name="tipoFactura"
                                value={tipoFac} onChange={handleChangeFac} >
                                <option selected>Seleccionar...</option>
                                {tipoF.map(
                                    tip => <option key={tip.value} value={tip.value}
                                    >{tip.label}
                                    </option>)
                                }
                            </select>
                            <label className='form-label' htmlFor="mes">Tipo de factura</label>
                        </div>
                    </div>
                }
                {(editar === true) &&
                    <div className='col-3'>
                        <div className="form-floating">
                            <Field
                                name="tipo_fac"
                                options={tipoF}
                                crear={crear}
                                dato={crear === true ? 0 : lecturaFac.tipo_factura}
                                component={SelectField}
                                className="form-select"
                                msj="Tipo de factura"
                            />
                        </div>
                    </div>
                }
                <div className='col-3'>
                    <Field
                        name="serie"
                        placeholder="Nombre"
                        className="form-control"
                        component={renderField}
                        disabled={bloqueo}
                        msj="Serie"
                    />
                </div>
                <div className='col-3'>
                    <Field
                        name="numero"
                        placeholder="Dias credito"
                        className="form-control"
                        component={renderNumber}
                        disabled={bloqueo}
                        msj="Numero"
                    />
                </div>
                {(crear === false && editar == false) ? (
                    <div className='row'>
                        <div className='col-3 mt-3 ml-3'>
                            <Field
                                name="en_dolares"
                                disabled={true}
                                label="Dolares"
                                component={renderFieldCheck}
                            />
                        </div>
                        <div className='col-3 mt-3'>
                            <Field
                                name="anulado"
                                disabled={true}
                                label="Anulado"
                                component={renderFieldCheck}
                            />
                        </div>
                    </div>
                ) :
                    <div className='row'>
                        <div className='col-3 mt-3 ml-3'>
                            <Field
                                name="en_dolares"
                                checked={dolares}
                                label="Dolares"
                                component={renderFieldCheck}
                                onChange={onCheckboxClicked}
                            />
                        </div>  
                        <div className='col-3 mt-3'>
                            <Field
                                name="anulado"
                                checked={anulado}
                                label="Anulado"
                                component={renderFieldCheck}
                                onChange={onCheckboxClickedAnulado}
                            />
                        </div>
                    </div>
                }
            </div>
            {polizaFactura === 'S' && crear === true &&
                <div className='row mb-3'>
                    <div className='col-6'>
                        <Field
                            autoFocus={true}
                            name="cuenta"
                            placeholder="Cuenta contable..."
                            className="form-control-find"
                            component={AsyncSelectField}
                            loadOptions={listarCuentas}
                            msj="Cuenta"
                        />
                    </div>
                </div>
            }           
            {(dolares && crear === true) &&
                <div className='row mb-2'>
                    <div className='col-3'>
                        <div className="form-floating">
                            <NumberFormat
                                className={classNames('form-control')}
                                decimalScale={6}
                                fixedDecimalScale
                                placeholder="Tipo de cambio"
                                value={tipoCambioD}
                                thousandSeparator
                                onValueChange={(values) => {
                                    setTipoCambioD(values.value)
                                    localStorage.setItem('TC_facturaVent', tipoCambioD)
                                }}
                            />
                            <label className='form-label' htmlFor="tipo">
                                Tipo de cambio
                            </label>
                        </div>
                    </div>
                    <div className='col-3 mt-2'>
                        <button
                            className='btn mr-2 mb-3 btn-secondary'
                            onClick={() => {
                                let tc = tipoCambio(periodo);
                                if (tc === undefined) {
                                    setTipoCambioD('1.000000');
                                } else {
                                    tc.then(d => {
                                        if (d === undefined) {
                                            setTipoCambioD('1.000000');
                                        } else {
                                            setTipoCambioD(d);
                                            localStorage.setItem('TC_facturaVent', d)

                                        }
                                    });
                                }
                            }}
                        >
                            Buscar tipo de cambio
                        </button>
                    </div>
                </div>
            }
            {(lecturaFac.en_dolares && (crear === false && editar == false)) &&
                <div className='row mb-2'>
                    <div className='col-3'>
                        <Field
                            name="tipo_cambio"
                            placeholder="Tipo de cambio"
                            className="form-control"
                            component={renderField}
                            disabled={bloqueo}
                            msj="Tipo de cambio"
                        />
                    </div>
                </div>
            }
            {(editar === true ) &&
                <div className='row mb-2'>
                    <div className='col-3'>
                        <Field
                            name="tipo_cambio"
                            placeholder="Tipo de cambio"
                            className="form-control"
                            component={renderCurrencyFloatTC}
                            disabled={bloqueo}
                            msj="Tipo de cambio"
                        />
                    </div>
                </div>
            }
            <div className='row mb-2'>
                <div className='col-4'>
                    <Field
                        name="bienes"
                        placeholder="Bienes"
                        className="form-control"
                        component={renderCurrencyFloat}
                        disabled={bloqueo}
                        onChange={(e, value) => {
                            if (iva) {
                                if (value) {
                                    let tipo = tipoFac ? tipoFac : lecturaFac.tipo_factura;
                                    if (tipo === 'EXP') {
                                        setEditarD(false);
                                        setBaseBienes(value);
                                        setIvaBienes(0.00);
                                        let TotalB = parseFloat(value) + parseFloat(baseServicios);
                                        let TotalF = parseFloat(value) + parseFloat(servicios) + parseFloat(exento);
                                        setBase(TotalB);
                                        setBienes(value);
                                        setTIva(0.00);
                                        setTotal(TotalF);
                                    }else{
                                        setEditarD(false);
                                        let baseB = parseFloat(value) / PIva;
                                        setBaseBienes(baseB);
                                        let ivaB = parseFloat(value) - parseFloat(baseB);
                                        setIvaBienes(ivaB);
                                        let TotalB = parseFloat(baseB) + parseFloat(baseServicios);
                                        let TotalIva = parseFloat(ivaB) + parseFloat(ivaServicios);
                                        let TotalF = parseFloat(value) + parseFloat(servicios) + parseFloat(exento);
                                        setBase(TotalB);
                                        setBienes(value);
                                        setTIva(TotalIva);
                                        setTotal(TotalF);
                                    }
                                } else {
                                    setBase(0.00)
                                    setBienes(0.00);
                                    setTIva(0.00)

                                }
                            }
                            if (iva === false) {
                                if (value) {
                                    setEditarD(false);
                                    setTotal(0.00);
                                    setBase(0.00);
                                    setTIva(0.00);
                                    setBienes(0.00);
                                    let TotalFSI = parseFloat(value) + parseFloat(servicios) + parseFloat(exento);
                                    setBienes(TotalFSI);
                                    setBase(TotalFSI);
                                    setTIva(0.00);
                                    setTotal(TotalFSI);
                                } else {
                                    setBase(0.00)
                                    setBienes(0.00);
                                    setTIva(0.00)

                                }
                            }
                        }}
                        msj="Bienes"
                    />
                </div>
                <div className='col-4'>
                    <Field
                        name="servicios"
                        placeholder="Servicios"
                        className="form-control"
                        component={renderCurrencyFloat}
                        disabled={bloqueo}
                        onChange={(e, value) => {
                            if (iva) {
                                if (value) {
                                    let tipo = tipoFac ? tipoFac : lecturaFac.tipo_factura;
                                    if (tipo === 'EXP') {
                                        setEditarD(false);
                                        setBaseServicios(value);
                                        setIvaServicios(0.00);
                                        let TotalSe = parseFloat(value) + parseFloat(baseBienes);
                                        let TotalF = parseFloat(value) + parseFloat(bienes) + parseFloat(exento);
                                        setServicios(value);
                                        setBase(TotalSe);
                                        setTIva(0.00);
                                        setTotal(TotalF);
                                    }else{
                                        setEditarD(false);
                                        let baseSe = parseFloat(value) / PIva;
                                        setBaseServicios(baseSe);
                                        let ivaS = parseFloat(value) - parseFloat(baseSe);
                                        setIvaServicios(ivaS);
                                        let TotalSe = parseFloat(baseSe) + parseFloat(baseBienes);
                                        let TotalIva = parseFloat(ivaS) + parseFloat(ivaBienes);
                                        let TotalF = parseFloat(value) + parseFloat(bienes) + parseFloat(exento);
                                        setServicios(value);
                                        setBase(TotalSe);
                                        setTIva(TotalIva);
                                        setTotal(TotalF);
                                    }
                                } else {
                                    setServicios(0.00);
                                    setBase(0.00)
                                    setTIva(0.00)
                                }

                            }
                            if (iva === false) {
                                if (value) {
                                    setEditarD(false);
                                    setTotal(0.00);
                                    setBase(0.00);
                                    setTIva(0.00);
                                    setServicios(0.00);
                                    let TotalFSI = parseFloat(value) + parseFloat(bienes) + parseFloat(exento);
                                    setServicios(TotalFSI);
                                    setBase(TotalFSI);
                                    setTIva(0.00);
                                    setTotal(TotalFSI);
                                } else {
                                    setServicios(0.00);
                                    setBase(0.00)
                                    setTIva(0.00)
                                }
                            }
                        }}
                        msj="Servicios"
                    />
                </div>
                <div className='col-4'>
                    <Field
                        name="exento"
                        placeholder="No afecto"
                        className="form-control"
                        component={renderCurrencyFloat}
                        disabled={bloqueo}
                        onChange={(e, value) => {
                            if (value) {
                                setEditarD(false);
                                let exento = parseFloat(value) + parseFloat(servicios) + parseFloat(bienes)
                                setExento(value)
                                setTotal(exento);
                                if (bienes === 0.00 || servicios === 0.00) {
                                    setBase(0.00);
                                    setTIva(0.00);
                                }

                            } else {
                                setEditarD(false);
                                let TotalFSI = parseFloat(value) + parseFloat(bienes) + parseFloat(servicios);
                                setExento(TotalFSI);
                                setTIva(0.00);
                                setTotal(TotalFSI);
                            }
                        }}
                        msj="No afecto"
                    />
                </div>
            </div>

            {crear === false && editar === false &&
                <div className='row mb-2'>
                    <div className='col-4'>
                        <Field
                            name="base"
                            placeholder="Base"
                            className="form-control"
                            component={renderCurrencyFloat}
                            disabled={true}
                            msj="Base"
                        />
                    </div>
                    <div className='col-4'>
                        <Field
                            name="iva"
                            placeholder="Iva"
                            className="form-control"
                            component={renderCurrencyFloat}
                            disabled={true}
                            msj="Iva"
                        />
                    </div>
                    <div className='col-4'>
                        <Field
                            name="total"
                            placeholder="Total"
                            className="form-control"
                            component={renderCurrencyFloat}
                            disabled={true}
                            msj="Total"
                        />
                    </div>
                </div>
            }
            {(crear === true || editar == true) &&
                <div className='row mb-2'>
                    <div className='col-4'>
                        <div className="form-floating">
                            <NumberFormat
                                className={classNames('form-control')}
                                decimalScale={2}
                                disabled={true}
                                placeholder="Base"
                                fixedDecimalScale
                                thousandSeparator
                                value={editarD === true ? lecturaFac.base : base}
                                name="base"
                            />
                            <label className='form-label' htmlFor="base">
                                Base
                            </label>
                        </div>
                    </div>
                    <div className='col-4'>
                        <div className="form-floating">
                            <NumberFormat
                                className={classNames('form-control')}
                                decimalScale={2}
                                disabled={true}
                                placeholder="Iva"
                                fixedDecimalScale
                                thousandSeparator
                                value={editarD === true ? lecturaFac.iva : tIva}
                                name="iva"
                            />
                            <label className='form-label' htmlFor="iva">
                                Iva
                            </label>
                        </div>
                    </div>
                    <div className='col-4'>
                        <div className="form-floating">
                            <NumberFormat
                                className={classNames('form-control')}
                                decimalScale={2}
                                disabled={true}
                                placeholder="Total"
                                fixedDecimalScale
                                thousandSeparator
                                value={editarD === true ? lecturaFac.total : total}
                                name="total"
                            />
                            <label className='form-label' htmlFor="total">
                                Total
                            </label>
                        </div>
                    </div>
                </div>
            }
            <div>
                <Field
                    id="descripcion"
                    name="descripcion"
                    placeholder="Descripcion"
                    component={renderField}
                    className="form-control"
                    disabled={bloqueo}
                    msj="Descripcion"
                />
            </div>

            <Modal
                estado={estadoModal}
                cambiarEstado={cambiarEstadoModal}
                titulo="Crear cliente"
                botonCerrar="true"
            >
                <div className="d-flex flex-column flex-1 mx-4">

                    <div className='mb-2 w-25'>
                        <Field
                            autoFocus={autoFocus}
                            name="identificador"
                            placeholder="Identificador"
                            className="form-control"
                            component={renderField}
                            msj="Identificador"
                        />
                    </div>
                    <div className='row'>
                        <div className='mb-2 col-8'>
                            <Field
                                name="nombre"
                                placeholder="Nombre"
                                className="form-control"
                                component={renderField}
                                msj="Nombre"
                            />
                        </div>
                        {obtenerPaises &&
                            <div className='col-4'>
                                <div className="form-floating">
                                    <Field
                                        name="pais"
                                        options={obtenerPaises}
                                        crear={crear}
                                        component={SelectField}
                                        className="form-select"
                                        msj="Pais"
                                    />
                                </div>
                            </div>
                        }
                    </div>

                    <div className='mb-2 w-75'>
                        <Field
                            name="direccion"
                            placeholder="Direccion"
                            className="form-control"
                            component={renderField}
                            msj="Dirección"
                        />
                    </div>
                    <div className='row'>
                        <div className='col-4'>
                            <Field
                                name="telefono"
                                placeholder="Telefono"
                                className="form-control"
                                component={renderField}
                                msj="Telefono"
                            />
                        </div>
                        <div className='col-4'>
                            <Field
                                name="correo"
                                placeholder="Correo electrónico"
                                className="form-control"
                                component={renderField}
                                msj="Correo electronico"
                            />
                        </div>
                        <div className='col-4'>
                            <Field
                                name="dias_credito"
                                placeholder="Dias credito"
                                className="form-control"
                                component={renderNumber}
                                msj="Dias crédito"
                            />
                        </div>
                    </div>
                    <div className='row mt-2'>
                        <div className='col-4'>
                            <Field
                                name="limite_credito"
                                placeholder="Limite credito"
                                className="form-control"
                                component={renderNumber}
                                msj="Limite crédito"
                            />
                        </div>
                        <div className='col-4'>
                            <div className="form-floating">
                                <Field
                                    name="tipo_cliente"
                                    options={tiposCL}
                                    component={SelectField}
                                    className="form-select"
                                    msj="Tipo cliente"
                                />
                            </div>
                        </div>
                    </div>
                    <div className='d-flex flex-row mt-3'>

                        <button
                            className='btn mr-2 mb-3 btn-primary'
                            onClick={() => registroCliente(datos)}
                        >
                            Guardar
                        </button>

                        <button
                            className='btn mr-2 mb-3 btn-secondary'
                            onClick={() => cambiarEstadoModal(false)}
                        >
                            Salir
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

FacturacionForm.propTypes = {
    crear: PropTypes.bool,
    lectura: PropTypes.object,
    tipos: PropTypes.object,
    editar: PropTypes.bool,
    bloqueo: PropTypes.bool,
    registroCliente: PropTypes.func,
    obtenerPaises: PropTypes.object,
    fechaInicio: PropTypes.string,
    fechaFinal: PropTypes.string,
    impuesto: PropTypes.object,
    datos: PropTypes.object,
    estable: PropTypes.object
};

export default reduxForm({
    form: 'FormFacturas',
})(FacturacionForm);
