import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";

const LISTADO_PROYECTO = 'LISTADO_PROYECTO';
const GUARDAR_PROYECTO = 'GUARDAR_PROYECTO';
const GUARDAR_TIPO = 'GUARDAR_TIPO';
const LISTADO_TIPO = 'LISTADO_TIPO';
const LISTADO_TIPOS = 'LISTADO_TIPOS';
const LOADER = 'LOADER';
const MODULO = 'MODULO';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    //const params = { page, id };
    const params = { id };
    dispatch(setLoader(true));
    api.get('/proyecto', params).then((response)=>{
        dispatch({ type: LISTADO_PROYECTO, data: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar proyectos',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const registroProyecto = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormProyecto.values;
    const formData = {
        codigo: data.codigo,
        proyecto: data.proyecto,
        ubicacion: data.ubicacion,
        responsable: data.responsable,
        tipo: data.tipo,
        empresa: id_emp[5]
    }
    dispatch(setLoader(true));
    api.post('/proyecto/', formData).then((response) => {
        NotificationManager.success(
        'Proyecto creado',
        'Exito',
        3000
    );
    let ruta = `/inmobiliaria/${id_emp[5]}/proyectos`
    dispatch(push(ruta));
    }).catch((error) => {
    NotificationManager.error(
        error.msj,
        'Error',
        2000
    );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const modificarProyecto = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const datos = getStore().form.FormProyecto.values;
    const id = datos.id;
    const formData = {
        id: id,
        proyecto: datos.proyecto,
        ubicacion: datos.ubicacion,
        responsable: datos.responsable,
        tipo: datos.tipo,
    }
    dispatch(setLoader(true));
    api.put(`/proyecto/${id}/`, formData).then((response) => {
        NotificationManager.success(
            'Proyecto modificado correctamente',
            'Exito',
            3000
        );
        let ruta = `/inmobiliaria/${id_emp[5]}/proyectos`
        dispatch(push(ruta));
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al modificar proyecto',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/proyecto/${id}`).then((response) => {
        dispatch({type: GUARDAR_PROYECTO, lectura: response });
        dispatch(initializeForm('FormProyecto', response ));
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar proyecto',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const listarTipoObra = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    //const params = { page, id };
    const params = { id };
    dispatch(setLoader(true));
    api.get('/proyecto/tipo_obra', params).then((response)=>{
        dispatch({ type: LISTADO_TIPO, data: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar proyectos',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const registroTipoObra = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormTipoObra.values;
    const formData = {
        descripcion: data.descripcion,
        empresa: id_emp[5]
    }
    dispatch(setLoader(true));
    api.post('/proyecto/tipo_obra_crear/', formData).then(() => {
        NotificationManager.success(
        'Tipo de obra creado',
        'Exito',
        3000
    );
    let ruta = `/inmobiliaria/${id_emp[5]}/tipo_obra`
    dispatch(push(ruta));
    }).catch((error) => {
    NotificationManager.error(
        error.msj,
        'Error',
        2000
    );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const modificarTipoObra = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const datos = getStore().form.FormTipoObra.values;
    const id = datos.id;
    const formData = {
        id: id,
        descripcion: datos.descripcion,
    }
    dispatch(setLoader(true));
    api.post('/proyecto/tipo_obra_modificar/', formData).then((response) => {
        NotificationManager.success(
            'Tipo de obra modificado correctamente',
            'Exito',
            3000
        );
        let ruta = `/inmobiliaria/${id_emp[5]}/tipo_obra`
        dispatch(push(ruta));
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al modificar tipo de obra',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leerTipoObra = (id) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let empresaId = id_emp[5];
    let idTipo = id;
    const params = { empresaId, idTipo };
    dispatch(setLoader(true));
    api.get('/proyecto/tipo_obra_retrieve', params).then((response) => {
        dispatch({type: GUARDAR_TIPO, lectura: response });
        dispatch(initializeForm('FormTipoObra', response ));
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar tipo de obra',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

const listarTipos = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    return api.get('/proyecto/tipo_obra', params).then((response)=>{
        const tipos = [];
        response.forEach(item=>{
            tipos.push({
                value: item.id,
                label: item.descripcion
            })
        })
        dispatch({ type: LISTADO_TIPOS, listaTipos: tipos });
    }).catch(error=>{
        NotificationManager.error(
            'Ocurrió un error al listar tipo de obra',
            'Error',
            0
        );
    })
}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        if (error.status === 401){
            NotificationManager.error(
              error.body.msj,
              'Error',
              0
            );
        }
        if (error.status === 400){
            NotificationManager.error(
              'Ocurrió un error en buscar modulo',
              'Error',
              0
            );
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const actions = {
    listar,
    registroProyecto,
    modificarProyecto,
    leer,
    listarTipoObra,
    registroTipoObra,
    modificarTipoObra,
    leerTipoObra,
    listarTipos,
    getModulo
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_PROYECTO]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [GUARDAR_PROYECTO]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
    [LISTADO_TIPO]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [GUARDAR_TIPO]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
    [LISTADO_TIPOS]: (state, { listaTipos }) => {
        return {
            ...state,
            listaTipos,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
};

export const initialState = {
    loader: false,
    lectura: [],
    modulo: [],
    data: [],
    page: 1,
};

export default handleActions(reducers, initialState)
