import React, { Component } from 'react';
import Formulario from './proyectosForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";

class Proyectos extends Component{
    state = {
        crear: true,
    }

    componentDidMount = () => {
        const { match, leer, listarTipos, getModulo } = this.props;
        const id = match.params.id;
        if (id){
            this.setState({crear: false});
            leer(id);
        }
        listarTipos();
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        getModulo(modulo[5],modulo[4])
    }
    
    render(){
        const { registroProyecto, modificarProyecto, loader, listaTipos, lectura, modulo } = this.props;
        const { crear } = this.state;
        const funcionEnvio = crear ? registroProyecto : modificarProyecto;
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    obtenerTipos = {listaTipos}
                    onSubmit = {funcionEnvio}
                    crear = {crear}
                    lectura={lectura}
                    modulo = {modulo}
                />
            </LoadMask>
        );
    }
}

export default Proyectos;
