import { connect } from 'react-redux';
import { actions } from '../../../redux/modules/auto_ventas/auto_ventas';
import AutoVentas from './AutoVentas';

const ms2p = (state) => {
  return {
    ...state.auto_ventas,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(AutoVentas);
