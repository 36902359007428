import { handleActions } from 'redux-actions';
import { NotificationManager } from "react-notifications";
import { api } from "api";

const MODULO = 'MODULO';
const LOADER = 'LOADER';

const setLoader = loader => ({
  type: LOADER,
  loader,
});

function renderSwitch(param) {
  switch (param) {
    case '01':
      return 'ENERO';
    case '02':
      return 'FEBRERO';
    case '03':
      return 'MARZO';
    case '04':
      return 'ABRIL';
    case '05':
      return 'MAYO';
    case '06':
      return 'JUNIO';
    case '07':
      return 'JULIO';
    case '08':
      return 'AGOSTO';
    case '09':
      return 'SEPTIEMBRE';
    case '10':
      return 'OCTUBRE';
    case '11':
      return 'NOVIEMBRE';
    case '12':
      return 'DICIEMBRE';

    default:
      return 'Seleccione un mes';
  }
}

export const reporteLibroCompras = () => (dispatch, getStore) => {
  let ruta = window.location.href;
  let id_emp = ruta.split('/');
  let id = id_emp[5];
  const data = getStore().form.FormInformesVM.values;
  let mesLetras = renderSwitch(data.mes)
  let folio = localStorage.getItem('folioLC');
  let year = data.year;
  let reporte = 'libroCompras';
  const params = { id, reporte, year, mesLetras, folio };
  dispatch(setLoader(true));
  api.getPdf('/visitador_medico/compras', params).then((response) => {
    if (response.headers['content-type'] == 'application/pdf') {
      var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'reporte_listado_compras.pdf';
      window.open(link, '_blank');
      return false;
    }
  }).catch((error) => {
    if (error.status === 404) {
      NotificationManager.info(
        error.body.msj,
        'Informacion',
        5000
      );
    }
    if (error.status === 500) {
      NotificationManager.error(
        'Ocurrió un error al generar reporte',
        'Error',
        0
      );
    }
  }).finally(() => {
    dispatch(setLoader(false));
  });
}

export const excelLibroCompras = () => (dispatch, getStore) => {
  let ruta = window.location.href;
  let id_emp = ruta.split('/');
  let id = id_emp[5];
  const data = getStore().form.FormInformesVM.values;
  let mesLetras = renderSwitch(data.mes)
  let folio = localStorage.getItem('folioLVM');
  let year = data.year;
  let reporte = 'excelLibroCompras';
  const params = { id, reporte, year, mesLetras, folio };
  dispatch(setLoader(true));
  api.getExcel('/visitador_medico/compras', params).then((response) => {

    const blob = response;
    const fileName = 'libro_de_compras.xlsx';

    // Descargar el archivo utilizando la función saveAs de file-saver
    const file = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(file, fileName);

  }).catch(error => {
    if (error === 404) {
      NotificationManager.error(
        "No se encontraron datos",
        'Informacion',
        5000
      );
    }
    if (error === 500) {
      NotificationManager.error(
        'Ocurrió un error al generar reporte',
        'Error',
        0
      );
    }
  })
    .finally(() => {
      dispatch(setLoader(false));
    });
}

export const getModulo = (id, modulo) => (dispatch) => {
  const params = { id, modulo }
  dispatch(setLoader(true));
  api.get('/modulos/modulo/', params).then((response) => {
    dispatch({ type: MODULO, modulo: response.modulos });
  }).catch(() => {
    NotificationManager.error(
      'Ocurrió un error en buscar modulo',
      'Error',
      2000
    );
  }).finally(() => {
    dispatch(setLoader(false));
  });
};

export const excelDetallePoliza = () => (dispatch, getStore) => {
  let ruta = window.location.href;
  let id_emp = ruta.split('/');
  let id = id_emp[5];
  const data = getStore().form.FormInformesVM.values;
  let mesLetras = renderSwitch(data.mes)
  let year = data.year;
  let reporte = 'excelDetallePoliza';
  const params = { id, reporte, year, mesLetras };
  dispatch(setLoader(true));
  api.getExcel('/visitador_medico/compras', params).then((response) => {

    const blob = response;
    const fileName = 'Detalle_de_poliza.xlsx';

    // Descargar el archivo utilizando la función saveAs de file-saver
    const file = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(file, fileName);

  }).catch(error => {
    if (error === 404) {
      NotificationManager.error(
        "No se encontraron datos",
        'Informacion',
        5000
      );
    }
    if (error === 500) {
      NotificationManager.error(
        'Ocurrió un error al generar reporte',
        'Error',
        0
      );
    }
  })
    .finally(() => {
      dispatch(setLoader(false));
    });
}

export const reporteListadoLiquidaciones = () => (dispatch, getStore) => {
  let ruta = window.location.href;
  let id_emp = ruta.split('/');
  let id = id_emp[5];
  const data = getStore().form.FormInformesVM.values;
  let mesLetras = renderSwitch(data.mes)
  let year = data.year;
  let reporte = 'listadoLiquidaciones';
  const params = { id, reporte, year, mesLetras };
  dispatch(setLoader(true));
  api.getPdf('/visitador_medico/liquidacion', params).then((response) => {
    if (response.headers['content-type'] == 'application/pdf') {
      var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'reporte_listado_liquidaciones.pdf';
      window.open(link, '_blank');
      return false;
    }
  }).catch((error) => {
    if (error.status === 404) {
      NotificationManager.info(
        error.body.msj,
        'Informacion',
        5000
      );
    }
    if (error.status === 500) {
      NotificationManager.error(
        'Ocurrió un error al generar reporte',
        'Error',
        0
      );
    }
  }).finally(() => {
    dispatch(setLoader(false));
  });
}


export const actions = {
  reporteLibroCompras,
  excelLibroCompras,
  getModulo,
  excelDetallePoliza,
  reporteListadoLiquidaciones
};

export const reducers = {
  [LOADER]: (state, { loader }) => {
    return {
      ...state,
      loader,
    };
  },
  [MODULO]: (state, { modulo }) => {
    return {
      ...state,
      modulo,
    };
  },
};

export const initialState = {
  loader: false,
  modulo: [],
};

export default handleActions(reducers, initialState)