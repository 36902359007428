import React, { useState } from "react";
import styled from "styled-components";
import { FicInput, FicTextArea } from "../../Utils/FormInputComponents";
import Banner from "../common/Banner";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { validar } from "../../components/ValidarNit";
import GenBtn from "../../components/GenBtn";
import InputsContainer from "../common/InputsContainer";
import { getEntry, editar, crear } from "./actions";
import { withRouter } from "react-router";
import LoadMask from "../../Utils/LoadMask/LoadMask";
import { useEffect } from "react";


const schema = yup.object({
    // nombre: yup.string().required("El nombre es requerido"),
    // descripcion: yup.string().required("La descripcion es requerida"),
    // email: yup
    //     .string()
    //     .email("El email es invalido")
    //     .required("El email es requerido"),
    // telefono: yup.string().required("El telefono es requerido"),
    // telefono_muestras: yup.string().required("El telefono para muestras es requerido"),
    // doctor: yup.string().required("El doctor es requerido"),
    // fax: yup.string().required("El fax es requerido"),
    // direccion: yup.string().required("La direccion es requerida"),
});

function Editar({ history, ...props }) {
    const [isLoading, setIsLoading] = useState(false);
    const [entryId, setEntryId] = useState(null);

    const { control, reset, handleSubmit, formState } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: async () => {
            setIsLoading(true);
            const formData = await getEntry();
            if (formData) {
                setEntryId(formData.id);
            }
            setIsLoading(false);
            return formData;
        },
    });


    const onSubmit = (data) => {
        const resetData = async () => {
            return await getEntry();
        }
        if (entryId) {
            editar(data, entryId);
        }
        else {
            crear(data);
            reset(resetData());
        }
    };

    return (
        <React.Fragment>
            <Banner>Datos de la empresa</Banner>
            <LoadMask loading={isLoading} color="#bd0034" blur={true}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <InputsContainer>
                        <FicInput
                            control={control}
                            label="Nombre"
                            name="nombre"
                        />
                        <FicInput
                            control={control}
                            label="Descripcion"
                            name="descripcion"
                        />

                        <FicInput
                            control={control}
                            label="Email"
                            name="email"
                        />
                        <FicInput
                            control={control}
                            label="Telefono"
                            name="telefono"
                        />
                            <FicInput
                                control={control}
                                label="Telefono para recepcion de muestras"
                                name="telefono_muestras"
                            />
                        <FicInput
                            control={control}
                            label="Doctor"
                            name="doctor"
                        />
                        <FicInput
                            control={control}
                            label="Fax"
                            name="fax"
                        />
                        <FicTextArea
                            control={control}
                            label="Direccion"
                            name="direccion"
                         />
                    </InputsContainer>
                    <GenBtn style={{ margin: "1rem 0" }} type="submit">
                        Guardar
                    </GenBtn>
                </form>
            </LoadMask>
        </React.Fragment>
    );
}

export default withRouter(Editar);
