import React, { Component } from 'react';
import Grid from '../../Utils/GridAbono';
import { standardActions } from '../../Utils/GridAbono/StandardActions';
import moment from 'moment';

class DepositoReciboDetalle extends Component{
    
    render(){
        const { loader, eliminarDocumento,  detalle } = this.props;
        return(
            <React.Fragment>
                {detalle &&
                    <Grid 
                        hover 
                        striped 
                        data={detalle} 
                        loading={loader}
                        footer
                        headerStyle={ { background: 'black' } } 
                        //onSortChange={onSortChange} 
                    >
                    <TableHeaderColumn
                        isKey
                        dataField="fecha"
                        width="115"
                        dataFormat={(cell, row)=>{
                            return moment(cell).format("DD/MM/YYYY");
                        }}
                        dataSort
                        thStyle={ 
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Fecha
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="numero"
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Numero
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="monto"
                        headerAlign="center"
                        dataAlign='right'
                        dataSort
                        dataFormat={(cell, row)=>{
                            return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(cell);    
                        }}
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Monto
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="abono"
                        headerAlign="center"
                        dataAlign='right'
                        dataFormat={(cell, row)=>{
                            return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(cell);    
                        }}
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Abono
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataAlign='right'
                        headerAlign="center"
                        dataField="saldo"
                        dataFormat={(cell, row)=>{
                            return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(cell);    
                        }}
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Saldo
                    </TableHeaderColumn>
                </Grid>
                }
            </React.Fragment>
        );
    }
}

export default DepositoReciboDetalle;
