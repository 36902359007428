import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import DetalleListado from './FrecuenciaPagoList';
import PropTypes from 'prop-types';
import NavbarModulo from '../../components/NavbarModulo';
import NavbarModuloLF from '../../components/NavbarModuloFage';
import {
    SelectField
} from "../../Utils/renderField/renderField";
const valEmp = __CONFIG__.empresa;

function FrecuenciaPagoForm(props) {
    const { registroFrecuencia , crear, modulo, data, eliminarFrecuencia } = props;
    let titulo = 'Frecuencia de pago';
    let autoFocus = true;

    const frecuencia = [
        { label: "SEMANAL", value: "S" },
        { label: "QUINCENAL", value: "Q" },
        { label: "CATORCENAL", value: "C" },
        { label: "MENSUAL", value: "M" },
    ];
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const estilo = {
        overflow: "auto",
        height: "200px",

    };
    return (
        <div>
            {valEmp === 'fage' &&
                <NavbarModuloLF
                    modulo={modulo}
                    titulo={titulo}
                />
            }
            {valEmp === '' &&
                <NavbarModulo
                    modulo={modulo}
                    titulo={titulo}
                />
            }
            
            <br />
            <div className="mb-4 card card-small text-white bg-dark mb-3">
                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                    <div className="d-flex flex-column flex-1 mx-4">
                        <div className='mb-2'>
                            <div className='mb-2 w-25'>
                                <div className="form-floating">
                                <Field
                                    autoFocus={autoFocus}
                                    name="frecuencia_pago"
                                    options={frecuencia}
                                    crear={crear}
                                    dato={0}
                                    component={SelectField}
                                    className="form-select"
                                    msj="Frecuencia de pago"
                                />
                                </div>
                            </div>
                            <div className='d-flex flex-row mt-3'>
                                <button
                                    className='btn mr-2 mb-3 btn-primary'
                                    onClick={registroFrecuencia}
                                >
                                    Guardar
                                </button>
                                {valEmp === 'fage' &&
                                    <a
                                        href={`/#/lfg/nomina/${id_emp[6]}`}
                                        className='btn btn-secondary mb-3'
                                    >
                                        Regresar
                                    </a>
                                }
                                {valEmp === '' &&
                                    <a
                                        href={`/#/nomina/${id_emp[5]}`}
                                        className='btn btn-secondary mb-3'
                                    >
                                        Regresar
                                    </a>
                                }
                            </div>
                        </div>
                        <div style={estilo}>
                            <DetalleListado
                                detalle={data}
                                eliminarDocumento={eliminarFrecuencia}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

FrecuenciaPagoForm.propTypes = {
    handleSubmit: PropTypes.func,
    modulo: PropTypes.object
};

export default reduxForm({
    form: 'FormFrecuenciaPago',
})(FrecuenciaPagoForm);
