import React from 'react';
import * as FaIcons from "react-icons/fa";
import * as BsIcons from "react-icons/bs";

export const SidebarData = [
    {
        title: 'Inicio',
        path: '/contabilidad',
        icon: <BsIcons.BsGrid1X2Fill />,
    },
    {
        title: 'Periodos',
        path: '/contabilidad/periodos',
        icon: <BsIcons.BsFillCalendarDateFill />,
    },
    {
        title: 'Parametros',
        path: '/contabilidad/config',
        icon: <BsIcons.BsTools />,
    },
    {
        title: 'Niveles',
        path: '/contabilidad/niveles',
        icon: <FaIcons.FaListOl />,
    },
    {
        title: 'Cuentas Contables',
        path: '/contabilidad/cuentas_contables',
        icon: <FaIcons.FaTasks />,
    },
    {
        title: 'Centro Costo',
        path: '/contabilidad/centro_costo',
        icon: <FaIcons.FaTasks />,
    },
    {
        title: 'Polizas',
        path: '/contabilidad/seleccionar',
        icon: <FaIcons.FaChalkboard />,
    },
    {
        title: 'Informes',
        path: '/contabilidad/informes',
        icon: <FaIcons.FaClipboard />,
    }
]

