import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/facturacion/notaDebitoFEL';
import Notas from './NDClienteTabs';


const ms2p = (state) => {
  return {
    ...state.nota_debito_fel,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Notas);
