import { handleActions } from 'redux-actions';
import { api } from "api";


export const datosDelVendedor = (vendedorId) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const action = "ver"
    const params = { id, action }
    return api.get(`auto_ventas/vendedores/${vendedorId}`, params).then(response => {
        return response;
    }).catch((error) => {
        NotificationManager.error(
            "Ocurrio un error al obtener los datos",
            'Error',
            2000,
            null,
            true
        );
        return [];
    })
}



export const actions = {
    datosDelVendedor
};

export const reducers = {

};

export const initialState = {


};

export default handleActions(reducers, initialState)