import React from "react";
import {
    Button,
    Menu,
    MenuItem,
    MenuTrigger,
    Popover,
    Separator,
} from "react-aria-components";
import {
    FaBars,
    FaAccusoft,
    FaAddressCard,
    FaArrowDown,
    FaArrowLeft,
    FaChevronLeft,
} from "react-icons/fa";
import styled, { keyframes } from "styled-components";
const popoverSlide = keyframes`
    from {
        transform: translateY(-10px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
`;
const StyledButton = styled(Button)`
    position: relative;
    display: flex;
    align-items: center;
    font-size: large;
    gap: 0.15rem;
    border: none;
    color: #fff !important;
    background-color: #121212;
    padding: 0.571rem 1.143rem;
    ::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #bd0034;
        height: 2px;
        width: 0;
        transition: width 150ms ease-in-out;
        margin: 0 auto;
    }

    .open-indicator {
        font-size: 1rem;
        transition: transform 150ms;
        transform: rotate(0);
        transform-origin: center;
    }

    &[data-hovered] {
        color: #bd0034;
        ::after {
            width: 35%;
        }
    }
    &[data-pressed] {
        ::after {
            width: 100%;
        }

        color: #bd0034 !important;
        .open-indicator {
            transform: rotate(-90deg);
        }
    }
`;
const StyledMenu = styled(Menu)`
    padding: 0.5rem;
    min-width: 150px;
    box-sizing: border-box;
    max-height: inherit;
    overflow: auto;
    outline: none;
`;
export const NavBarMenuItemComp = styled(MenuItem)`
    margin: 2px;
    /* padding: 0.286rem 0.571rem; */
    border-radius: 6px;
    outline: none;
    cursor: default;
    color: #fff;
    font-size: 1.072rem;
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    /* column-gap: 20px; */
    forced-color-adjust: none;

    &[data-hovered] {
        background-color: #1f1f1f;
        color: #bd0034;
        a {
            color: #bd0034 !important;
        }
    }
`;
const StyledPopover = styled(Popover)`
    background-color: #0c0809;
    border-radius: 6px;
    max-width: 250px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    color: #fff;

    &[data-entering] {
        animation: ${popoverSlide} 100ms ease-out;
    }

    &[data-exiting] {
        animation: ${popoverSlide} 100ms reverse ease-in;
    }
`;

export const SeparatorComponent = styled(Separator)`
    align-self: stretch;
    background-color: #707070;

    &[aria-orientation="vertical"] {
        width: 1px;
        margin: 0px 10px;
    }
`;

export function NavBarMenuComponent({
    label,
    children,
    withOpenIndicator,
    ...props
}) {
    return (
        <MenuTrigger>
            <StyledButton aria-label="Menu">
                <React.Fragment>
                    {label}
                    {withOpenIndicator && (
                        <FaChevronLeft className="open-indicator" />
                    )}
                </React.Fragment>
            </StyledButton>
            <StyledPopover>
                <StyledMenu {...props}>{children}</StyledMenu>
            </StyledPopover>
        </MenuTrigger>
    );
}
