import React from 'react';
import { reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import TablaObs from './TablaObservaciones';

function ObservacionesForm(props) {
  const { detalle } = props;
  
  return (
    <div>
      <div>
        <TablaObs
          detalle={detalle}
        />
      </div>
      <br />
      <br />
    </div>
  );
}

ObservacionesForm.propTypes = {
  crear: PropTypes.bool,
};

export default reduxForm({
  form: 'FormObservacionesRM', // a unique identifier for this form
})(ObservacionesForm);
