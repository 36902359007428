import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import DetalleInventario from './DetalleFactura';
import DetalleInventarioVer from './DetalleFacturaVer';
import Modal from '../../../components/ModalA'
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';

import {
    renderField,
    AsyncSelectField,
    renderDayPickerB,
    renderCurrencyFloatTC,
    SelectField,
    renderNumber,
    renderCurrencyFloat,
    renderCurrencyFloatPrecioVenta
} from "../../../Utils/renderField/renderField";

function FacturaSeviciosForm(props) {
    const {
        crear,
        editar,
        periodoD,
        detalle,
        eliminarLineaProducto,
        datos,
        agregarLinea,
        detalleCon,
        estable,
        tipos,
        obtenerPaises,
        listarClientes,
        lecturaFac,
        tipoCambio,
        monedas,
        polizaFactura,
        listarCuentas
    } = props;

    
    const estilo = {
        overflow: "auto",
        height: "200px",

    };
        
    const [editarT, setEditarT] = useState(editar);
    const [tipoD, setTipoD] = useState(0);
    const [iva, setIva] = useState(true);
    const [estadoModal, cambiarEstadoModal] = useState(false);
    const [tipoCambioD, setTipoCambioD] = useState(1.000000);

    const tipoF = [
        { label: "LOCAL", value: "LOC" },
        { label: "EXPORTACION", value: "EXP" },
    ];

    const tiposCL = [
        {
            value: 'L',
            label: 'LOCAL'
        },
        {
            value: 'E',
            label: 'EXTERIOR'
        },
        {
            value: 'P',
            label: 'PERSONA'
        },
    ];

    let autoFocus = true;
    const handleChangeT = (e) => {
        let existe = tipos.find(element => element.value === e.target.value);
        setEditarT(false);
        setTipoD(e.target.value);
        localStorage.setItem('tipoDocumentoFacSFarm', e.target.value);
        if (existe.search === 'IVA') {
            setIva(true);
        }
        if (existe.search === 'SIVA') {
            setIva(false);
        }

    }
    const [total, setTotal] = useState(0.0);
    const [cantidad, setCantidad] = useState(0.00);

    const [tipoM, setTipoM] = useState(0);
    const [tipoFac, setTipoFac] = useState(0);

    const handleChangeM = e => {
        setTipoM(e.target.value)
        localStorage.setItem('fact_serv_farm_TC', e.target.value)
    };

    const handleChangeTipo = e => {
        setTipoFac(e.target.value)
        localStorage.setItem('tipoFacSFarm', e.target.value);
    };

    return (
        <div>
            {crear === false && editar == false &&
                <div className='row mb-2'>
                    <div className='col-3'>
                        <Field
                            name="codigo"
                            placeholder="Codigo establecimiento"
                            className="form-control"
                            component={renderField}
                            disabled={true}
                            msj="Código establecimiento"
                        />
                    </div>
                    <div className='col-6'>
                        <Field
                            name="establecimiento"
                            placeholder="Nombre establecimiento"
                            className="form-control"
                            component={renderField}
                            disabled={true}
                            msj="Nombre establecimiento"
                        />
                    </div>
                    <div className='col-3'>
                        <Field
                            name="correlativo"
                            placeholder="Documento interno"
                            className="form-control"
                            component={renderField}
                            disabled={true}
                            msj="Documento interno"
                        />
                    </div>
                </div>
            }
            {crear === true &&
                <div>
                    <div className='row mb-2'>
                        <div className='col-3'>
                            <div className="form-floating">
                                <input
                                    placeholder="Codigo establecimiento"
                                    type="text"
                                    autocomplete="off"
                                    name="codigo"
                                    className="form-control"
                                    value={estable.codigo}
                                    disabled={true}
                                />
                                <label className='form-label' htmlFor="codigo">
                                    Código establecimiento
                                </label>
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className="form-floating">
                                <input
                                    placeholder="Nombre establecimiento"
                                    type="text"
                                    autocomplete="off"
                                    name="nombre"
                                    className="form-control"
                                    value={estable.nombre_comercial}
                                    disabled={true}
                                />
                                <label className='form-label' htmlFor="nombre">
                                    Nombre establecimiento
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-3'>
                            <div className="form-floating">
                                <select className="form-select" id="tipo" name="tipo"
                                    value={editarT === true ? lecturaFac.tipo_documento : tipoD} onChange={handleChangeT} autofocus>
                                    <option selected>Seleccionar...</option>
                                    {tipos.map(
                                        tip => <option key={tip.value} value={tip.value}
                                        >{tip.label}
                                        </option>)
                                    }
                                </select>
                                <label className='form-label' htmlFor="mes">Tipo de documento</label>
                            </div>
                        </div>
                        <div className='col-3'>
                            <div className="form-floating">
                                <select className="form-select" id="tipo" name="tipo"
                                    value={tipoFac} onChange={handleChangeTipo}>
                                    <option selected>Seleccionar...</option>
                                    {tipoF.map(
                                        tip => <option key={tip.value} value={tip.value}
                                        >{tip.label}
                                        </option>)
                                    }
                                </select>
                                <label className='form-label' htmlFor="mes">Tipo Factura</label>
                            </div>
                        </div>
                        <div className='col-3'>
                            <Field
                                name="fecha"
                                component={renderDayPickerB}
                                msj="Fecha"
                            />
                        </div>
                        <div className='col-3'>
                            <div className="form-floating">
                                <select className="form-select" id="moneda" name="moneda"
                                    value={tipoM} onChange={handleChangeM} >
                                    <option selected>Seleccionar...</option>
                                    {monedas.map(
                                        tip => <option key={tip.value} value={tip.value}
                                        >{tip.label}
                                        </option>)
                                    }
                                </select>
                                <label className='form-label' htmlFor="moneda">Moneda</label>
                            </div>
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-3'>
                            <div className="form-floating">
                                <NumberFormat
                                    className={classNames('form-control')}
                                    decimalScale={6}
                                    fixedDecimalScale
                                    placeholder="Tipo de cambio"
                                    value={tipoCambioD}
                                    thousandSeparator
                                    onValueChange={(values) => {
                                        setTipoCambioD(values.value)
                                        localStorage.setItem('TC_compra', values.value)
                                    }}
                                />
                                <label className='form-label' htmlFor="cuenta">
                                    Tipo de cambio
                                </label>
                            </div>
                        </div>
                        <div className='col-3 mt-2'>
                            <button
                                className='btn mr-2 mb-3 btn-secondary'
                                onClick={() => {
                                    let tc = tipoCambio(periodoD);
                                    if (tc === undefined) {
                                        setTipoCambioD('1.000000');
                                    } else {
                                        tc.then(d => {
                                            setTipoCambioD(d);
                                        });
                                    }
                                }}
                            >
                                Buscar tipo de cambio
                            </button>
                        </div>
                        {polizaFactura === 'S' &&
                            <div className='col-6'>
                                <Field
                                    autoFocus={true}
                                    name="cuenta"
                                    placeholder="Cuenta contable..."
                                    className="form-control-find"
                                    component={AsyncSelectField}
                                    loadOptions={listarCuentas}
                                    msj="Cuenta"
                                />
                            </div>
                        }
                    </div>
                    <div className='row mb-2'>
                        <div className='col-9'>
                            <Field
                                name="cliente_documento"
                                placeholder="Cliente..."
                                className="form-control-find"
                                component={AsyncSelectField}
                                loadOptions={listarClientes}
                                msj="Cliente"
                            />
                        </div>
                        <div className='col-3 mt-2'>
                            <button
                                className='btn mr-2 mb-3 btn-secondary'
                                onClick={() => cambiarEstadoModal(!estadoModal)}
                            >
                                Crear nuevo cliente
                            </button>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 mb-2'>
                            <Field
                                name="descripcion"
                                placeholder="Descripcion"
                                component={renderField}
                                className="form-control"
                                msj="Observaciones"
                            />
                        </div>
                    </div>
                </div>
            }
            {(crear === false && editar === false) &&
                <div>
                    <div className='row mb-2'>
                        <div className='col-3'>
                            {tipos &&
                                <div className="form-floating">
                                    <Field
                                        name="tipo_documento"
                                        options={tipos}
                                        crear={crear}
                                        dato={crear === true ? 0 : lecturaFac.tipo_documento}
                                        component={SelectField}
                                        className="form-select"
                                        disabled={true}
                                        msj="Tipo de documento"
                                    />
                                </div>
                            }
                        </div>
                        <div className='col-3'>
                            <Field
                                name="fecha"
                                component={renderDayPickerB}
                                msj="Fecha"
                                disabled={true}
                            />
                        </div>
                        <div className='col-3'>
                            <div className='mb-2'>
                                <Field
                                    name="serie"
                                    placeholder="Serie"
                                    component={renderField}
                                    className="form-control"
                                    msj="Serie"
                                    disabled={true}
                                />
                            </div>
                        </div>
                        <div className='col-3'>
                            <div className='mb-2'>
                                <Field
                                    name="numero"
                                    placeholder="Número"
                                    component={renderField}
                                    className="form-control"
                                    msj="Número"
                                    disabled={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-6'>
                            <Field
                                name="autorizacion"
                                disabled={true}
                                placeholder="Numero autorización"
                                className="form-control"
                                component={renderField}
                                msj="Numero autorización"
                            />
                        </div>
                        <div className='col-3'>
                            <div className="form-floating">
                                <Field
                                    name="moneda"
                                    options={monedas}
                                    crear={crear}
                                    dato={crear === true ? 0 : lecturaFac.moneda}
                                    component={SelectField}
                                    className="form-select"
                                    disabled={true}
                                    msj="Moneda"
                                />
                            </div>
                        </div>
                        <div className='col-3'>
                            <div className="form-floating">
                                <Field
                                    name="tipo_cambio"
                                    component={renderCurrencyFloatTC}
                                    disabled={true}
                                    msj="Tipo de cambio"
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-12'>
                            <Field
                                name="clienteN"
                                disabled={true}
                                placeholder="Cliente"
                                className="form-control"
                                component={renderField}
                                msj="Cliente"
                            />
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-12'>
                            <Field
                                name="observaciones"
                                disabled={true}
                                placeholder="Observaciones"
                                className="form-control"
                                component={renderField}
                                msj="Observaciones"
                            />
                        </div>
                    </div>
                </div>
            }
            {(crear === true) &&
                <div>
                    <h5 className="p-2 bg-secondary title mt-1">Detalle factura</h5>
                    <div className='mb-2'>
                        <Field
                            name="descripcion_serv"
                            placeholder="Descripcion"
                            component={renderField}
                            className="form-control"
                            msj="Descripción"
                        />
                    </div>

                    <div className='row mb-2'>
                        <div className='col-2'>
                            <Field
                                name="cantidad"
                                placeholder="Cantidad"
                                className="form-control"
                                component={renderCurrencyFloatTC}
                                onChange={(e, value) => {
                                    if (value) {
                                        setCantidad(value);
                                    }
                                }}
                                msj="Cantidad"
                            />
                        </div>
                        <div className='col-2'>
                            <Field
                                name="precio"
                                placeholder="Precio Unitario"
                                className="form-control"
                                component={renderCurrencyFloatPrecioVenta}
                                onChange={(e, value) => {
                                    if (value) {
                                        let total = parseFloat(parseFloat(value) * parseFloat(cantidad)).toFixed(2);
                                        setTotal(total);
                                        localStorage.setItem('total_linea_factura', total)
                                    }
                                }}
                                msj="Precio Unitario"
                            />
                        </div>
                        <div className='col-2'>
                            <div className="form-floating">
                                <NumberFormat
                                    className={classNames('form-control')}
                                    decimalScale={2}
                                    disabled={true}
                                    placeholder="Base"
                                    fixedDecimalScale
                                    thousandSeparator
                                    value={total}
                                    name="total"
                                />
                                <label className='form-label' htmlFor="total">
                                    Total
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {(crear === true) &&
                <Contenido>
                    <button
                        className='btn mr-2 mb-3 btn-primary'
                        onClick={() => agregarLinea(periodoD)}
                    >
                        Agregar linea
                    </button>
                </Contenido>
            }
            <br />
            {(crear === true) &&
                <div style={estilo}>
                    <DetalleInventario
                        detalle={detalleCon}
                        eliminarLineaProducto={eliminarLineaProducto}
                    />
                </div>
            }
            {(crear === false && editar === false) &&
                <div style={estilo}>
                    <DetalleInventarioVer
                        detalle={detalle}
                    />
                </div>
            }
            <br />
            {(crear === false && editar === false) &&
                <div>
                    <div className='row mb-2'>
                        <div className='col-3'>

                        </div>
                        <div className='col-2'>
                            <Field
                                name="base"
                                disabled={true}
                                placeholder="Base"
                                className="form-control"
                                component={renderCurrencyFloat}
                                msj="Base"
                            />
                        </div>
                        <div className='col-2'>
                            <Field
                                name="iva"
                                disabled={true}
                                placeholder="Iva"
                                className="form-control"
                                component={renderCurrencyFloat}
                                msj="Iva"
                            />
                        </div>
                        <div className='col-2'>
                            <Field
                                name="total"
                                disabled={true}
                                placeholder="Total"
                                className="form-control"
                                component={renderCurrencyFloat}
                                msj="Total"
                            />
                        </div>
                        <div className='col-3'>

                        </div>
                    </div>
                </div>
            }
            <Modal
                estado={estadoModal}
                cambiarEstado={cambiarEstadoModal}
                titulo="Crear cliente"
                botonCerrar="true"
            >
                <div className="d-flex flex-column flex-1 mx-4">

                    <div className='mb-2 w-25'>
                        <Field
                            autoFocus={autoFocus}
                            name="identificador"
                            placeholder="Identificador"
                            className="form-control"
                            component={renderField}
                            msj="Identificador"
                        />
                    </div>
                    <div className='row'>
                        <div className='mb-2 col-8'>
                            <Field
                                name="nombre"
                                placeholder="Nombre"
                                className="form-control"
                                component={renderField}
                                msj="Nombre"
                            />
                        </div>
                        {obtenerPaises &&
                            <div className='col-4'>
                                <div className="form-floating">
                                    <Field
                                        name="pais"
                                        options={obtenerPaises}
                                        crear={crear}
                                        component={SelectField}
                                        className="form-select"
                                        msj="Pais"
                                    />
                                </div>
                            </div>
                        }
                    </div>
                    <div className='mb-2 w-75'>
                        <Field
                            name="direccion"
                            placeholder="Direccion"
                            className="form-control"
                            component={renderField}
                            msj="Dirección"
                        />
                    </div>
                    <div className='row'>
                        <div className='col-4'>
                            <Field
                                name="telefono"
                                placeholder="Telefono"
                                className="form-control"
                                component={renderField}
                                msj="Telefono"
                            />
                        </div>
                        <div className='col-4'>
                            <Field
                                name="correo"
                                placeholder="Correo electrónico"
                                className="form-control"
                                component={renderField}
                                msj="Correo electronico"
                            />
                        </div>
                        <div className='col-4'>
                            <Field
                                name="dias_credito"
                                placeholder="Dias credito"
                                className="form-control"
                                component={renderNumber}
                                msj="Dias crédito"
                            />
                        </div>
                    </div>
                    <div className='row mt-2'>
                        <div className='col-4'>
                            <Field
                                name="limite_credito"
                                placeholder="Limite credito"
                                className="form-control"
                                component={renderNumber}
                                msj="Limite crédito"
                            />
                        </div>
                        <div className='col-4'>
                            <div className="form-floating">
                                <Field
                                    name="tipo_cliente"
                                    options={tiposCL}
                                    component={SelectField}
                                    className="form-select"
                                    msj="Tipo cliente"
                                />
                            </div>
                        </div>
                    </div>
                    <div className='d-flex flex-row mt-3'>

                        <button
                            className='btn mr-2 mb-3 btn-primary'
                            onClick={() => registroCliente(datos)}
                        >
                            Guardar
                        </button>

                        <button
                            className='btn mr-2 mb-3 btn-secondary'
                            onClick={() => cambiarEstadoModal(false)}
                        >
                            Salir
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

FacturaSeviciosForm.propTypes = {
    crear: PropTypes.bool,
    editar: PropTypes.bool,
    bloqueo: PropTypes.bool,
    modulo: PropTypes.object,
    periodoD: PropTypes.object,
    obtenerBodegas: PropTypes.array,
    listarProductos: PropTypes.object,
    detalleProducto: PropTypes.object,
    registroProductoInventario: PropTypes.func,
    eliminarLineaProducto: PropTypes.func,
    confirmarEntrada: PropTypes.func,
    periodoD: PropTypes.object,
    datos: PropTypes.object,
    listarBodegas: PropTypes.func,
    leerProductos: PropTypes.func,
    pro: PropTypes.object,
};

const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;

export default reduxForm({
    form: 'FormFacturaServiciosFarmFel',
})(FacturaSeviciosForm);

