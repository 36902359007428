import React from 'react';
import VendedoresForm from './VendedoresForm';

function Vendedores(props) {
    
    const { 
        crearVendedor,
    } = props;


    return(
        <VendedoresForm
            crearVendedor = {crearVendedor}
        />
    )
}


export default Vendedores;