import React from 'react';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import * as BsIcons from "react-icons/bs";
import * as IoIcons from "react-icons/io";
import * as AiIcons from "react-icons/ai";
import * as GiIcons from "react-icons/gi";


export const SidebarData = [
    {
        title: 'Inicio',
        path: '/auto_ventas',
        icon: <BsIcons.BsGrid1X2Fill />,
    },
    {
        title: 'Vehiculos',
        path: '#',
        icon: <AiIcons.AiFillCar />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav: [
            {
                title: 'Marcas',
                path: '/auto_ventas',
                path_s: 'marcas',
                icon: <IoIcons.IoMdCube />,
            },
            {
                title: 'Lineas',
                path: '/auto_ventas',
                path_s: 'lineas',
                icon: <GiIcons.GiCarWheel />,
            },
            {
                title: 'Tipo',
                path: '/auto_ventas',
                path_s: 'tipo',
                icon: <GiIcons.GiJeep />,
            },
            {
                title: 'Combustible',
                path: '/auto_ventas',
                path_s: 'combustible',
                icon: <GiIcons.GiGasPump />,
            }
        ]
    },
    {
        title: 'Ingresos',
        path: '/auto_ventas/ingresos',
        icon: <RiIcons.RiArrowLeftDownFill />,
    },  
    // {
    //     title: 'Salidas',
    //     path: '/auto_ventas',
    //     path_s: 'salidas',
    //     icon: <RiIcons.RiArrowRightUpFill />,
    // },  
    {
        title: 'Vendedores',
        path: '/auto_ventas/vendedores',
        icon: <FaIcons.FaHandshake />,
    },  
    {
        title: 'Ventas',
        path: '/auto_ventas/ventas',
        icon: <RiIcons.RiPriceTag3Fill />,
    },  
    {
        title: 'Informes',
        path: '/auto_ventas/informes',
        icon: <FaIcons.FaClipboard />,
    }
]
