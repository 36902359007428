import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
const valEmp = __CONFIG__.empresa;

const LISTADO_TIPOS = 'LISTADO_TIPOS';
const LOADER = 'LOADER';
const MODULO = 'MODULO';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params;
    let id = ''
    if (valEmp === 'fage'){
        id = id_emp[6];
        params = { id };
    }else{
        id = id_emp[5];
        params = { id };
    }
    dispatch(setLoader(true));
    api.get('/rrhh_tipo_descuento', params).then((response)=>{
        dispatch({ type: LISTADO_TIPOS, data: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar tipos de descuento',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const registroTipo = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id_empresa = '';
    if (valEmp === 'fage'){
        id_empresa = id_emp[6]
    }else{
        id_empresa = id_emp[5]
    }
    const data = getStore().form.FormTipoDescuentoRRHH.values;
    const formData = {
        descripcion: data.descripcion,
        empresa: id_empresa
    }
    dispatch(setLoader(true));
    api.post('/rrhh_tipo_descuento/', formData).then(() => {
        NotificationManager.success(
            'Tipo de descuento creado',
            'Exito',
            3000
        );
        if (valEmp === 'fage'){
            let ruta = `/lfg/rr_hh/${id_empresa}/tipo_descuento_rr_hh`
            dispatch(push(ruta));
        }
        if (valEmp === ''){
            let ruta = `/rr_hh/${id_empresa}/tipo_descuento_rr_hh`
            dispatch(push(ruta));
        }
    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const modificarTipo = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const datos = getStore().form.FormTipoDescuentoRRHH.values;
    const id = datos.id;
    const formData = {
        id: id,
        descripcion: datos.descripcion,
    }
    dispatch(setLoader(true));
    api.put(`/rrhh_tipo_descuento/${id}/`, formData).then(() => {
        NotificationManager.success(
            'Tipo de descuento modificado correctamente',
            'Exito',
            3000
        );
        if (valEmp === 'fage'){
            let ruta = `/lfg/rr_hh/${id_emp[6]}/tipo_descuento_rr_hh`
            dispatch(push(ruta));
        }
        if (valEmp === ''){
            let ruta = `/rr_hh/${id_emp[5]}/tipo_descuento_rr_hh`
            dispatch(push(ruta));
        }
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al modificar tipo de descuento',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/rrhh_tipo_descuento/${id}`).then((response) => {
        dispatch(initializeForm('FormTipoDescuentoRRHH', response ));
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar tipo de descuento',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`/rrhh_tipo_descuento/${id}`).then(() => {
        NotificationManager.success(
            'Tipo de descuento colaborador eliminado correctamente',
            'Exito',
            3000
        );
        dispatch(listar());
    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const actions = {
    listar,
    registroTipo,
    modificarTipo,
    getModulo,
    leer,
    eliminar
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_TIPOS]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
};

export const initialState = {
    loader: false,
    modulo: [],
    data: [],
};

export default handleActions(reducers, initialState)