import React from 'react';
import * as FaIcons from "react-icons/fa";
import * as BsIcons from "react-icons/bs";

export const SidebarData = [
    {
        title: 'Inicio',
        path: '/bancos',
        icon: <BsIcons.BsGrid1X2Fill />,
    },
    {
        title: 'Periodos',
        path: '/bancos/periodos',
        icon: <BsIcons.BsFillCalendarDateFill />,
    },
    {
        title: 'Cuentas bancarias',
        path: '/bancos/cuenta_bancaria',
        icon: <BsIcons.BsCashStack />,
    },
    {
        title: 'Egresos',
        path: '/bancos/seleccionar_egreso',
        icon: <BsIcons.BsGraphDown />,
    },
    {
        title: 'Ingresos',
        path: '/bancos/seleccionar_ingreso',
        icon: <BsIcons.BsGraphUp />,
    },
    {
        title: 'Conciliación',
        path: '/bancos/conciliacion',
        icon: <BsIcons.BsGrid3X2GapFill />,
    },
    {
        title: 'Informes',
        path: '/bancos/informes',
        icon: <FaIcons.FaClipboard />,
    }
]

