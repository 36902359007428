import React from "react";
import styled from "styled-components";
import {
    FicInput,
    FicComboBox,
    FicDatePicker,
    FicDateRangePicker,
} from "../../Utils/FormInputComponents";
import Banner from "../common/Banner";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { validar } from "../../components/ValidarNit";
import GenBtn from "../../components/GenBtn";
import InputsContainer from "../common/InputsContainer";
import {
    crearSeguro,
    editarSeguro,
    getAgentes,
    getClientes,
    getSeguro,
} from "./actions";
import { useEffect } from "react";
import { withRouter } from "react-router";

const GridContainer = styled.div`
    width: 100%;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, clamp(250px, 35%, 450px));
    /* justify-items: center; */
`;

const SectionTitle = styled.h2`
    margin: 0 auto;
    font-size: large;
    color: #bd0034;
`;

const OPCIONES_TIPO = [
    { value: "vehiculos", label: "VEHICULOS" },
    { value: "gastos_medicos", label: "GASTOS MEDICOS" },
    { value: "incendio", label: "INCENDIO" },
];
const OPCIONES_CLASIFICACION = [
    { value: "casas", label: "CASA" },
    { value: "edificios", label: "EDIFICIO" },
    { value: "apartamentos", label: "APARTAMENTOS" },
];

function Editar({ history, ...props }) {
    const params = props.match.params;
    const seguroId = params.seguroId;
    const { control, reset, handleSubmit, watch, formState } = useForm({
        mode: "onChange",
        defaultValues: async () => {
            let formData = await getSeguro(seguroId);
            formData.agente = {
                value: formData.agente.id,
                label: formData.agente.nombre,
            };
            formData.cliente = {
                value: formData.cliente.id,
                label: formData.cliente.nombre,
            };
            formData.vigencia = {
                start: formData.vigencia_inicio,
                end: formData.vigencia_fin,
            };
            formData.tipo = {
                value: formData.tipo,
                label: formData.tipo.toUpperCase(),
            };
            formData.clasificacion = formData.clasificacion
                ? {
                      value: formData.clasificacion,
                      label: formData.clasificacion.toUpperCase(),
                  }
                : null;

            // setLoadingState(false);
            return formData;
        },
    });

    let tipo = watch("tipo");
    tipo = tipo ? tipo.value : "";

    return (
        <React.Fragment>
            <Banner>Ver seguro</Banner>
            <GenBtn
                style={{ margin: "1rem 0" }}
                onPress={() => history.goBack()}
            >
                Regresar
            </GenBtn>
            <form onSubmit={() => {}}>
                <InputsContainer>
                    <FicComboBox
                        componentStyle={{ flexGrow: "1", maxWidth: "400px" }}
                        control={control}
                        label="Agente"
                        name="agente"
                        loadOptions={getAgentes}
                        disabled
                    />
                    <FicComboBox
                        componentStyle={{ flexGrow: "1", maxWidth: "400px" }}
                        control={control}
                        label="Cliente"
                        name="cliente"
                        loadOptions={getClientes}
                        disabled
                    />
                    <FicComboBox
                        componentStyle={{
                            flexGrow: "1",
                            minWidth: "250px",
                            width: "auto",
                        }}
                        control={control}
                        label="Tipo"
                        name="tipo"
                        nonAsync
                        options={OPCIONES_TIPO}
                        disabled
                    />
                    {tipo === "incendio" && (
                        <FicComboBox
                            componentStyle={{
                                flexGrow: "1",
                                width: "auto",
                                minWidth: "250px",
                            }}
                            control={control}
                            label="Clasificacion"
                            name="clasificacion"
                            nonAsync
                            options={OPCIONES_CLASIFICACION}
                            // disabled={tipo != "incendio"}
                            isDisabled
                        />
                    )}
                </InputsContainer>
                <br />
                <InputsContainer>
                    <GridContainer>
                        <FicInput
                            control={control}
                            label="Poliza"
                            name="poliza"
                            inputMode="numeric"
                            isDisabled
                        />
                        <FicInput
                            control={control}
                            label="Aseguradora"
                            name="aseguradora"
                            isDisabled
                        />
                        <FicInput
                            control={control}
                            label="Suma asegurada"
                            isDisabled
                            name="suma_asegurada"
                        />
                        <FicDateRangePicker
                            control={control}
                            label="Vigencia"
                            name="vigencia"
                            isDisabled
                        />
                    </GridContainer>
                </InputsContainer>
                <br />
                {tipo === "vehiculos" && (
                    <InputsContainer>
                        <SectionTitle>Datos del vehiculo</SectionTitle>
                        <GridContainer>
                            <FicInput
                                control={control}
                                label="Marca"
                                name="marca"
                                isDisabled
                            />
                            <FicInput
                                control={control}
                                label="Linea"
                                name="linea"
                                isDisabled
                            />
                            <FicInput
                                control={control}
                                label="Modelo"
                                name="modelo"
                                isDisabled
                            />
                            <FicInput
                                control={control}
                                label="Placa"
                                name="placa"
                                isDisabled
                            />
                            <FicInput
                                control={control}
                                label="Chasis"
                                name="chasis"
                                isDisabled
                            />
                            <FicInput
                                control={control}
                                label="Motor"
                                name="motor"
                                isDisabled
                            />
                            <FicInput
                                control={control}
                                label="D. Daños"
                                name="deducible_damage"
                                isDisabled
                            />
                            <FicInput
                                control={control}
                                label="D. Robo"
                                name="deducible_robo"
                                isDisabled
                            />
                        </GridContainer>
                    </InputsContainer>
                )}
            </form>
        </React.Fragment>
    );
}

export default withRouter(Editar);
