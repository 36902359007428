import { handleActions } from "redux-actions";
import { api } from "api";
import { NotificationManager } from "react-notifications";
import { listarVendedores } from "./ventas";

export const datosDeVenta = (ventaId) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let id = id_emp[5];
    let action = "ver";
    const params = { id, action };
    return api
        .get(`auto_ventas/ventas/${ventaId}`, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            NotificationManager.error(
                "Ocurrio un error al obtener los datos",
                "Error",
                2000,
                null,
                true
            );
            return [];
        });
};

export const actions = {
    datosDeVenta,
    listarVendedores,
};

export const reducers = {};

export const initialState = {};

export default handleActions(reducers, initialState);
