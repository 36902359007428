import React from "react";
import FicModal from "../../../Utils/FormInputComponents/FicModal";
import {
    FicDateRangePicker,
    FicComboBox,
} from "../../../Utils/FormInputComponents";
import GenBtn from "../../../components/GenBtn";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { reporteListadoDeSeguros } from "./actions";
import { getAgentes } from "./actions";
import InfoPopover from "../../../components/Popover";

const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const ButtonContainer = styled.div`
    display: flex;
    width: 100%;
    gap: 2rem;

    & > :nth-child(1) {
        flex-grow: 4;
    }
`;

const schema = yup.object({});

export default function Seguros() {
    const { control, reset, handleSubmit } = useForm({
        mode: "onSubmit",
        resolver: yupResolver(schema),
        defaultValues: {
            rangoFechas: null,
            agente: null,
        },
    });

    const onSubmit = (formData) => {
        let data = {};
        console.log(formData);
        if (
            formData.rangoFechas &&
            Object.keys(formData.rangoFechas).length !== 0
        ) {
            const fecha_inicial = formData.rangoFechas.start;
            const fecha_final = formData.rangoFechas.end;
            data = { fecha_inicial, fecha_final };
        }
        if (formData.agente) {
            data.agente = formData.agente.value;
        }
        reporteListadoDeSeguros(data);
    };

    return (
        <FicModal title="Listado de seguros" triggerText="Listado de seguros">
            <Form onSubmit={handleSubmit(onSubmit)}>
                <FicComboBox
                    componentStyle={{ flexGrow: "1", maxWidth: "400px" }}
                    control={control}
                    label="Agente"
                    name="agente"
                    loadOptions={getAgentes}
                />
                <FicDateRangePicker
                    label="Rango de fechas"
                    control={control}
                    name="rangoFechas"
                />
                <ButtonContainer>
                    <GenBtn type="submit">Generar</GenBtn>
                    <InfoPopover>
                        <span>
                            <strong>Agente (opcional):</strong> Seleccionar un
                            agente para filtrar por un solo agente, dejar en
                            blanco para mostrar todos.
                            <hr />
                            <strong>Rango de fechas (opcional):</strong>{" "}
                            Seleccionar un rango de fechas o dejar en blanco
                            para mostrar todos.
                        </span>
                        {/* <span>Rango de fechas (opcional): Seleccionar el rango de fechas</span> */}
                    </InfoPopover>
                </ButtonContainer>
            </Form>
        </FicModal>
    );
}
