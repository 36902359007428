import React, { Component } from 'react';
import Formulario from './TipoAbonoForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";

class TipoAbono extends Component{
    state = {
        crear: true,
    }

    componentDidMount = () => {
        const { match, leer, getModulo } = this.props;
        const id = match.params.id;
        if (id){
            this.setState({crear: false});
            leer(id);
        }
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        getModulo(modulo[5],modulo[4]);
    }
    
    render(){
        const { registroTipo, modificarTipo, loader, lectura, modulo } = this.props;
        const { crear } = this.state;
        const funcionEnvio = crear ? registroTipo : modificarTipo;
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    onSubmit = {funcionEnvio}
                    crear = {crear}
                    lectura = {lectura}
                    modulo = {modulo}
                />
            </LoadMask>
        );
    }
}

export default TipoAbono
