import React, { useEffect, useState } from 'react';
import Formulario from './IngresoInventarioForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";

function IngresoInventario(props) {
    const { loader, periodoD, modulo,
        detalleOrden, registroIngreso, detalleOrd,
        lectura, listarOrdenes, leerDetalleOrden,
        prov, listaBodegas, detalleProducto
    } = props;        
        
    useEffect(() => {
        const { match, leer, getModulo, leerPeriodoD, 
            parametrosEmpresa, listarBodegas, limpiarOrden } = props;
        const id = match.params.id;
        if (id) {
            setCrear(false);
            leer(id);
        }
        let ruta = window.location.href;
        let periodo = ruta.split('/');
        leerPeriodoD(periodo[5], periodo[7]);
        getModulo(periodo[5], periodo[4]);
        parametrosEmpresa();
        listarBodegas();
        limpiarOrden();
    }, [])

    const [crear, setCrear] = useState(true);

    const editar = window.location.href.includes('editar');
    let titulo = 'Crear ingreso de Inventario';
    let disabled = false;
    if (crear === false && editar === false) {
        disabled = true;
        titulo = 'Ver ingreso de inventario';
    }

    return (
        <LoadMask loading={loader} dark>
            <Formulario
                registroIngreso = {registroIngreso}
                crear = {crear}
                titulo = {titulo}
                editar = {editar}
                periodoD = {periodoD}
                modulo = {modulo}
                bloqueo = {disabled}
                detalleOrden = {detalleOrden}
                detalleOrd = {detalleOrd}
                lectura = {lectura}
                listarOrdenes = {listarOrdenes}
                leerDetalleOrden = {leerDetalleOrden}
                prov = {prov}
                listaBodegas = {listaBodegas}
                detalleProducto = {detalleProducto}
            />
        </LoadMask>
    );
}

export default IngresoInventario;