import React, { Component } from 'react';
import Formulario from './EnvioForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";

class EnvioProduccion extends Component{
    state = {
        crear: true,
    }

    componentDidMount() {
        const { match, leer, getModulo, leerPeriodoD, parametrosEmpresa, listarSeries, leerDetalleProductos } = this.props;
        const id = match.params.id;
        if (id){
            this.setState({ crear: false });
            leer(id);
        }
        let ruta = window.location.href;
        let periodo = ruta.split('/');
        leerPeriodoD(periodo[5], periodo[7]);
        getModulo(periodo[5],periodo[4]);
        parametrosEmpresa();
        listarSeries();
        leerDetalleProductos('00000000-0000-0000-0000-000000000000');
    }
    render(){
        const { registroEnvio, modificarEnvio, listarPilotos,  
            loader, lectura, modulo, periodoD, detalleProducto, cli,
            listarIngresos, listarSeries, numeroSerie, numeroS,
            datos, detalleIngreso, detalleEnv} = this.props;
        const { crear } = this.state;
        const funcionEnvio = crear ? registroEnvio : modificarEnvio;
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    funcionEnvio = {funcionEnvio}
                    crear = {crear}
                    lectura = {lectura}
                    modulo = {modulo}
                    periodoD = {periodoD}
                    detalleProducto = {detalleProducto}
                    listarIngresos = {listarIngresos}
                    datos = {datos}
                    detalleIngreso = {detalleIngreso}
                    cli = {cli}
                    listarSeries={listarSeries}
                    numeroSerie = {numeroSerie}
                    numeroS = {numeroS}
                    detalleEnv = {detalleEnv}
                    listarPilotos = {listarPilotos}
                />
           </LoadMask>
        );
    }
}

export default EnvioProduccion;