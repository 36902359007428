import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {renderField, renderFilePicker} from '../../Utils/renderField/renderField';

const ProfileForm = (props) => {
    const { handleSubmit, me, setAvatar } = props;
    localStorage.setItem('tituloForm', "Perfil de usuario");
    return (
            <form onSubmit={handleSubmit} className="py-4">
                <div className="mb-4 card card-small text-white bg-dark">
                    <div className="border-bottom card-header"><h6 className="m-0">{me.first_name} {me.last_name}</h6></div>
                    <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                        <div className="col-4 ml-5">
                            <label htmlFor="avatar">Avatar</label>
                            <Field 
                                photo={me.avatar ? __CONFIG__.api_image + me.avatar : null} 
                                setFile={setAvatar} 
                                name="avatar" 
                                component={renderFilePicker} 
                            />
                        </div>
                        <div className="col-8">
                            <div className="mb-2 col-6">
                                <Field 
                                    name="username"
                                    placeholder="Username" 
                                    className="form-control" 
                                    component={renderField} 
                                    disabled={true}
                                    msj="Username"
                                />
                            </div>
                            <div className="mb-2 col-6">
                                <Field 
                                    name="first_name" 
                                    type="text"
                                    disabled={true}
                                    placeholder="Nombre" 
                                    component={renderField} 
                                    className="form-control" 
                                    msj="Nombre"
                                />
                            </div>
                            <div className="mb-2 col-6">
                                <Field 
                                    name="last_name" 
                                    type="text"
                                    disabled={true}
                                    placeholder="Apellido" 
                                    component={renderField} 
                                    className="form-control" 
                                    msj="Apellido"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex">
                        <button className="btn btn-primary mx-auto mb-3">Guardar</button>
                    </div>
                </div>
            </form>
        );
};

export default reduxForm({
    form: 'FormProfile', // a unique identifier for this form
})(ProfileForm);
