import { NotificationManager } from "react-notifications";
import { api } from "api";

const baseurl = "seguros/aseguradoras";

export const getList = () => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let empresa = id_emp[5];
    const params = { empresa };
    return api
        .get(baseurl, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return [];
        });
};

export const getEntry = (id) => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let empresa = id_emp[5];
    const params = { empresa };
    return api
        .get(baseurl + `/${id}`, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return [];
        });
};

export const crear = (data) => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    const formData = {
        ...data,
        empresa: id_emp[5],
    };
    return api
        .post(baseurl, formData)
        .then(() => {
            NotificationManager.success("Aseguradora agregada", "Exito", 3000);
        })
        .catch((error) => {
            NotificationManager.error("", "Error", 2000);
        });
};

export const editar = (data, id) => {
    let ruta = window.location.href;
    let empresa = ruta.split("/")[5];
    const formData = {
        ...data,
        empresa,
    };
    return api
        .put(baseurl + `/${id}`, formData)
        .then(() => {
            NotificationManager.success("Aseguradora editada", "Exito", 3000);
            return true;
        })
        .catch((error) => {
            NotificationManager.error(error.msj, "Error", 2000);
            return false;
        });
};
