import React, { Component } from 'react';
import Grid from '../../Utils/GridFiltro';
import PropTypes from 'prop-types';
import { standardActions } from '../../Utils/Grid/StandardActions';
import { FaPlusCircle } from 'react-icons/fa';

class ClientesList extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: true
    };

    componentDidMount = () => {
        const { listar } = this.props;
        listar();
    }
    render() {
        const { data, loader } = this.props;
        let ruta = window.location.href;
        let id_emp = ruta.split('/');
        return (
            <React.Fragment>
                <div className="d-flex flex-row justify-content-between p-2">
                    <h3 className="p-2 text-md-center title">
                        Clientes
                        <span>  </span>
                        <a
                            href={`/#/punto_venta/${id_emp[5]}/clientes/crear`}
                            className='btn btn-primary text-lg'>{<FaPlusCircle />}  Nuevo
                        </a>
                    </h3>
                </div>

                {data &&
                    <Grid
                        hover
                        striped
                        data={data}
                        loading={loader}
                        headerStyle={{ background: 'black' }}
                    // onPageChange={onPageChange}
                    // onSortChange={onSortChange} 
                    >
                        <TableHeaderColumn
                            thStyle={
                                { color: 'white' }}
                            dataField="id"
                            width="100"
                            dataAlign="center"
                            dataSort
                            dataFormat={standardActions({
                                editar: "clientes",
                                ver: "clientes",
                            })}

                        >
                            Acciones
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            isKey
                            dataField="identificador"
                            width="100"
                            dataSort
                            thStyle={
                                { color: 'white' }}
                            tdStyle={
                                { color: 'white' }}
                        >
                            Identificador
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="nombre"
                            width="250"
                            dataSort
                            thStyle={
                                { color: 'white' }}
                            tdStyle={
                                { color: 'white' }}
                        >
                            Nombre
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="telefono"
                            width="100"
                            dataSort
                            thStyle={
                                { color: 'white' }}
                            tdStyle={
                                { color: 'white' }}
                        >
                            Telefono
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="limite_credito"
                            width="100"
                            dataSort
                            thStyle={
                                { color: 'white' }}
                            tdStyle={
                                { color: 'white' }}
                        >
                            Crédito
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="tipo_cliente"
                            width="100"
                            dataFormat={(cell, row) => {
                                if (cell === 'L') {
                                    return 'LOCAL';
                                } else if (cell === 'E') {
                                    return 'EXTERIOR';
                                } else {
                                    return 'PERSONA'
                                }
                            }}
                            dataSort
                            thStyle={
                                { color: 'white' }}
                            tdStyle={
                                { color: 'white' }}
                        >
                            Tipo
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="activo"
                            width="100"
                            dataAlign='center'
                            dataFormat={(cell, row) => {
                                if (cell === true) {
                                    return 'ACTIVO';
                                } else {
                                    return 'INACTIVO';
                                }
                            }}
                            dataSort
                            thStyle={
                                { color: 'white' }}
                            tdStyle={
                                { color: 'white' }}
                        >
                            Estado
                        </TableHeaderColumn>
                    </Grid>
                }
            </React.Fragment>
        );
    }
}

export default ClientesList;
