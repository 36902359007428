import React, { useState, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Radio } from "react-aria-components";
import {
    FicFileDrop,
    FicComboBox,
    FicInput,
    FicRadio,
    FicCheckbox,
    FicTextArea,
    FicDatePicker,
} from "../../Utils/FormInputComponents/index";
import { useEffect } from "react";
import "../../../../../style/autoVentas/VendedoresForm.css";
import GenBtn from "../../components/GenBtn";
import { validar } from "../../components/ValidarNit";
import { withRouter } from "react-router";

const schema = yup.object({
    nombre: yup.string().required("El Nombre es requerido"),
    dpi: yup
        .number()
        .typeError("Debe de ser un numero")
        .test("dpi-validation", "El dpi no es valido", function (value) {
            return validar.valCui(value.toString())[0].validar;
        })
        .required("El numero de dpi es requerido"),
    num_telefono: yup.string(),
    direccion: yup.string(),
    fecha_de_ingreso: yup.string().required("La fecha de ingreso es requerida"),
});

function VendedoresForm(props) {
    const { crearVendedor, history } = props;

    const urlParams = props.match.params;
    const empresa = urlParams.empresa;

    const {
        handleSubmit,
        reset,
        control,
        formState: { errors },
        watch,
        setValue,
    } = useForm({
        resolver: yupResolver(schema),
        mode: "onSubmit",
        defaultValues: {
            nombre: "",
            dpi: "",
            num_telefono: "",
            direccion: "",
            fecha_de_ingreso: "",
        },
    });

    async function onSubmit(data) {
        let shouldReset = await crearVendedor(data);
        if (shouldReset) {
            reset();
        }
    }

    return (
        <React.Fragment>
            <br />
            <h3
                className="p-2  title rounded shadow"
                style={{
                    background:
                        "linear-gradient(to top right, #790021, #2d2d2d)",
                }}
            >
                Agregar Vendedor
            </h3>
            <br />
            <div className="d-flex flex-row justify-content-start mb-2">
                <GenBtn
                    BtnColor="#787878"
                    style={{ width: "100px", height: "40px" }}
                    onPress={(e) => {
                        history.push(`/auto_ventas/${empresa}/vendedores`);
                    }}
                >
                    Regresar
                </GenBtn>
            </div>
            <form
                encType="multipart/form-data"
                onSubmit={handleSubmit(onSubmit)}
                className="vendedores-form__form-container"
            >
                <div className="vendedores-form__inputs-container">
                    <FicInput
                        label="Nombre Completo"
                        name="nombre"
                        control={control}
                    />
                    <FicInput
                        label="Numero de DPI"
                        name="dpi"
                        control={control}
                    />
                    <FicInput
                        label="Numero de Telefono"
                        name="num_telefono"
                        control={control}
                    />
                    <FicInput
                        label="Direccion"
                        name="direccion"
                        control={control}
                    />
                    <FicDatePicker
                        label="Fecha de ingreso"
                        name="fecha_de_ingreso"
                        control={control}
                    />
                    <div className="vendedores-form__inputs-container"></div>
                </div>
                <GenBtn
                    type="submit"
                    className="vendedores-form__guardar-ingreso-btn"
                    style={{ marginTop: "15px" }}
                >
                    Guardar
                </GenBtn>
            </form>
        </React.Fragment>
    );
}

export default withRouter(VendedoresForm);
