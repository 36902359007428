import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Dropdown from "./SubMenuNavbarUser";
import styled from "styled-components";
import {
    NavBarMenuComponent,
    NavBarMenuItemComp,
    SeparatorComponent,
} from "../../Utils/Components/NavBarMenu";
import placeholderImage from "assets/img/avatar-placeholder.png";

const SidebarLink = styled(NavLink)`
    padding: 0.286rem 0.571rem;
    position: relative;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    border: none;
    /* border-radius: 6px; */
    background-color: #121212;
    padding: 0.571rem 1.143rem;
    ::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #bd0034;
        height: 2px;
        width: 0;
        transition: width 150ms ease-in-out;
        margin: 0 auto;
    }

    &.active {
        color: #bd0034 !important;
    }

    &:hover {
        ::after {
            width: 100%;
        }

        color: #bd0034;
    }
`;

const StyledLink = styled(NavLink)`
    padding: 0.286rem 0.571rem;
    /* border-radius: 6px; */
    outline: none;
    cursor: default;
    color: #fff !important;
    font-size: 1.072rem;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    /* column-gap: 20px; */
    forced-color-adjust: none;
    width: 100%;
    height: 100%;

    &.active {
        color: #bd0034 !important;
    }
`;

const StyledDiv = styled.div`
    padding: 0.286rem 0.571rem;
    /* border-radius: 6px; */
    outline: none;
    cursor: default;
    color: #fff !important;
    font-size: 1.072rem;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    /* column-gap: 20px; */
    forced-color-adjust: none;
    width: 100%;
    height: 100%;
    cursor: pointer;
`;
const MenuItems = ({ pitems, pimg, puser, plogOut }) => {
    // Define the props as variables to avoid build errors (idk why this happens, but it does)
    const items = pitems;
    const img = pimg;
    const user = puser;
    const logOut = plogOut;

    const renderSubMenuItems = (items, id_emp) => {
        return items.subNav.map((submenu, index) => {
            if (submenu.title === "Salir") {
                return (
                    <NavBarMenuItemComp key={index}>
                        <StyledDiv
                            style={{
                                display: "flex",
                                width: "100%",
                                alignItems: "center",
                            }}
                            onClick={logOut}
                        >
                            {submenu.icon}
                            <SeparatorComponent orientation="vertical" />
                            {submenu.title}
                        </StyledDiv>
                    </NavBarMenuItemComp>
                );
            }
            return (
                <NavBarMenuItemComp
                    key={index}
                    // href={`/#${submenu.path}/${id_emp[5]}/${submenu.path_s}`}
                >
                    <StyledLink
                        to={`/ajustes/${id_emp[5]}/${submenu.path_s}`}
                    >
                        {submenu.icon}
                        <SeparatorComponent orientation="vertical" />
                        {submenu.title}
                    </StyledLink>
                </NavBarMenuItemComp>
            );
        });
    };

    const renderMenuItem = () => {
        const ruta = window.location.href;
        const id_emp = ruta.split("/");
        const path = items.path.split("/");
        const u = path[2] === undefined ? "" : path[2];
        const nueva_ruta = `/${id_emp[4]}/${id_emp[5]}/${u}`;

        if (items.subNav) {
            const ButtonLabel = (
                <React.Fragment>
                    <Imagen
                        className="user-avatar rounded-circle mr-3"
                        src={img ? img : placeholderImage}
                        alt="User Avatar"
                    />
                    <span className="d-none d-md-inline-block">
                        <Titulo>{user}</Titulo>
                    </span>

                    <SidebarLabel>{items.title}</SidebarLabel>
                </React.Fragment>
            );
            return (
                <NavBarMenuComponent label={ButtonLabel} withOpenIndicator>
                    {renderSubMenuItems(items, id_emp)}
                </NavBarMenuComponent>
            );
        } else {
            return (
                <SidebarLink to={nueva_ruta}>
                    <React.Fragment>
                        {items.icon}
                        <SeparatorComponent orientation="vertical" />
                        {items.title}
                    </React.Fragment>
                </SidebarLink>
            );
        }
    };

    return renderMenuItem(items);
};

export default MenuItems;

const Titulo = styled.span`
    font-size: 14px;
`;
const SidebarLabel = styled.span`
    margin-left: 16px;
`;

const Imagen = styled.img`
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50% !important;
`;
