import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import NavbarModulo from '../../components/NavbarModulo';
import DetalleListado from './TipoDocumentoList';
import styled from 'styled-components';
const valEmp = __CONFIG__.empresa;

import {
    renderField,
} from "../../Utils/renderField/renderField";

function TipoDocumentoForm(props) {
    const {
        modulo,
        registroDocumento,
        eliminarDocumento,
        documentos
    } = props;

    const estilo = {
        overflow: "auto",
        height: "200px",

    };

    let titulo = 'Documentos';
    let ruta = window.location.href;
    let id_emp = ruta.split('/');

    return (
        <div>
            <NavbarModulo
                modulo = {modulo}
                titulo = {titulo}
            />
            <br />
            <div className="mb-4 card card-small text-white bg-dark mb-3">
                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                    <div className="d-flex flex-column flex-1 mx-4">
                        <div className='row mb-2'>
                            <div className='col-6'>
                                <Field
                                    name="pais"
                                    placeholder="Pais"
                                    className="form-control"
                                    component={renderField}
                                    disabled={true}
                                    msj="Pais"
                                />
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <div className='col-4'>
                                <Field
                                    name="tipo_documento"
                                    placeholder="Documento"
                                    className="form-control"
                                    component={renderField}
                                    msj="Documento"
                                />
                            </div>
                        </div>
                        <Contenido>
                            <button
                                className='btn mr-2 mb-3 btn-primary'
                                onClick={() => registroDocumento()}
                            >
                                Guardar documento
                            </button>
                        </Contenido>
                        <br/>
                        <div style={estilo}>
                            <DetalleListado
                                detalle={documentos}
                                eliminarDocumento={eliminarDocumento}
                            />
                        </div>
                        <br />
                        <div className='d-flex flex-row mt-3'>
                            {valEmp === 'fage' &&
                                <a
                                    href={`/#/lfg/rr_hh/${id_emp[6]}/pais`}
                                    className='btn btn-secondary mb-3'
                                >
                                    Regresar
                                </a>
                            }
                            {valEmp === '' &&
                                <a
                                    href={`/#/rr_hh/${id_emp[5]}/pais`}
                                    className='btn btn-secondary mb-3'
                                >
                                    Regresar
                                </a>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

TipoDocumentoForm.propTypes = {
    crear: PropTypes.bool,
    lectura: PropTypes.object,
    editar: PropTypes.bool,
    bloqueo: PropTypes.bool,
    modulo: PropTypes.object,
    periodoD: PropTypes.object,
    listar: PropTypes.array,
    registroSerie: PropTypes.func,
    listaBodegas: PropTypes.array,
    eliminarSerie: PropTypes.func
};

const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;

export default reduxForm({
    form: 'FormTipoDocumento',
})(TipoDocumentoForm);
