import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import NavbarModulo from '../../components/NavbarModulo';
import DetalleSiembra from './DetalleSiembra';
import DetalleSiembraMod from './DetalleSiembraMod';
import DetalleSiembraVer from './DetalleSiembraVer';
import styled from 'styled-components';

import {
    renderField,
    AsyncSelectField,
    renderDayPickerB,
    renderNumber,
} from "../../Utils/renderField/renderField";

function SiembraForm(props) {
    const {
        crear,
        modulo,
        periodoD,
        registroSiembra,
        listarFases,
        agregarLinea,
        detalleCon,
        modificarSiembra,
        eliminarLinea,
        detalleS,
        nuevaLineaSiembra,
        eliminarLineaSiembra
    } = props;

    
    const estilo = {
        overflow: "auto",
        height: "200px",

    };

    const editar = window.location.href.includes('editar');
    let titulo = editar ? 'Modificar siembra' : 'Nueva siembra';
    if (crear === false && editar === false) {
        titulo = 'Ver siembra'
    }

    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    
    return (
        <div>
            <NavbarModulo
                periodoD = {periodoD}
                modulo = {modulo}
                titulo = {titulo}
            />
            <div className="mb-4 card card-small text-white bg-dark mb-3">
                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                    <div className="d-flex flex-column flex-1 mx-4">
                        {(crear === false && editar === false) &&
                            <div>
                                <div className='row mb-2'>
                                    <div className='col-9'>
                                        <Field
                                            name="descripcion"
                                            placeholder="Descripcion"
                                            component={renderField}
                                            className="form-control"
                                            msj="Descripción"
                                            disabled={true}
                                        />
                                    </div>
                                    <div className='col-3'>
                                        <Field
                                            name="fecha"
                                            component={renderDayPickerB}
                                            msj="Fecha inicio"
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {(crear === true) &&
                            <div>
                                <div className='row mb-2'>
                                    <div className='col-9'>
                                        <Field
                                            name="descripcion"
                                            placeholder="Descripcion"
                                            component={renderField}
                                            className="form-control"
                                            msj="Descripción"
                                        />
                                    </div>
                                    <div className='col-3'>
                                        <Field
                                            name="fecha"
                                            component={renderDayPickerB}
                                            msj="Fecha inicio"
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {(editar === true) &&
                            <div>
                                <div className='row mb-2'>
                                    <div className='col-9'>
                                        <Field
                                            name="descripcion"
                                            placeholder="Descripcion"
                                            component={renderField}
                                            className="form-control"
                                            msj="Descripción"
                                        />
                                    </div>
                                    <div className='col-3'>
                                        <Field
                                            name="fecha"
                                            component={renderDayPickerB}
                                            msj="Fecha inicio"
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        
                        {(crear === true || editar === true) &&
                            <div>
                                <h5 className="p-2 bg-secondary title mt-1">Detalle siembra</h5>
                                <div className='row mb-2'>
                                    <div className='col-6'>
                                        <Field
                                            name="variedad"
                                            placeholder="Descripcion"
                                            component={renderField}
                                            className="form-control"
                                            msj="Variedad"
                                        />
                                    </div>
                                    <div className='col-6'>
                                        <Field
                                            name="fase"
                                            placeholder="Fase..."
                                            className="form-control-find"
                                            loadOptions={listarFases}
                                            component={AsyncSelectField}
                                            msj="Fase"
                                        />
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <div className='col-2'>
                                        <Field
                                            name="metros"
                                            placeholder="Metros cuadrados"
                                            className="form-control"
                                            component={renderNumber}
                                            msj="Metros cuadrados"
                                        />
                                    </div>
                                    <div className='col-2'>
                                        <Field
                                            name="kilos"
                                            placeholder="Kilos metro cuadrado"
                                            className="form-control"
                                            component={renderNumber}
                                            msj="Kilos metro cuadrado"
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {(crear === true) &&
                            <Contenido>
                                <button
                                    className='btn mr-2 mb-3 btn-primary'
                                    onClick={() => agregarLinea(periodoD)}
                                >
                                    Agregar linea
                                </button>
                            </Contenido>
                        }
                        {(editar === true) &&
                            <Contenido>
                                <button
                                    className='btn mr-2 mb-3 btn-primary'
                                    onClick={() => nuevaLineaSiembra(periodoD)}
                                >
                                    Agregar linea
                                </button>
                            </Contenido>
                        }
                        {(crear === false && editar === false) &&
                            <div>
                                <h5 className="p-2 bg-secondary title mt-1">Detalle siembra</h5>
                                <div style={estilo}>
                                    <DetalleSiembraVer
                                        detalle={detalleS}
                                    />
                                </div>
                            </div>
                        }
                        <br/>
                        {(crear === true) &&
                            <div style={estilo}>
                                <DetalleSiembra
                                    detalle={detalleCon}
                                    eliminarLinea={eliminarLinea}
                                />
                            </div>
                        }
                        {(editar === true) &&
                            <div style={estilo}>
                                <DetalleSiembraMod
                                    detalle={detalleS}
                                    eliminarLinea={eliminarLineaSiembra}
                                />
                            </div>
                        }
                        <div className='d-flex flex-row mt-3'>
                            <br />
                            {crear == true &&
                                <button
                                    className='btn mr-2 mb-3 btn-primary'
                                    onClick={() => registroSiembra(periodoD)}
                                >
                                    Guardar
                                </button>
                            }
                            {editar == true &&
                                <button
                                    className='btn mr-2 mb-3 btn-primary'
                                    onClick={() => modificarSiembra(periodoD)}
                                >
                                    Modificar
                                </button>
                            }
                            <a
                                href = {`/#/produccion/${id_emp[5]}/siembra/${id_emp[7]}`}
                                className='btn btn-secondary mb-3'
                            >
                                Regresar
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

SiembraForm.propTypes = {
    crear: PropTypes.bool,
    editar: PropTypes.bool,
    bloqueo: PropTypes.bool,
    modulo: PropTypes.object,
    periodoD: PropTypes.object,
    detalleInsumo: PropTypes.object,
    eliminarLineaProducto: PropTypes.func,
    periodoD: PropTypes.object,
    leerProductos: PropTypes.func,
    pro: PropTypes.object,
};

const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;

export default reduxForm({
    form: 'FormSiembraProduccion',
})(SiembraForm);
