import React from 'react';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import * as BsIcons from "react-icons/bs";

export const SidebarData = [
    {
        title: 'Inicio',
        path: '/visitador_medico',
        icon: <BsIcons.BsGrid1X2Fill />,
    },
    {
        title: 'Parámetros',
        path: '#',
        icon: <FaIcons.FaTasks />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav: [
            {
                title: 'Periodos',
                path: '/visitador_medico',
                path_s: 'periodos',
                icon: <BsIcons.BsFillCalendarDateFill />,
            },
            {
                title: 'Tipo',
                path: '/visitador_medico',
                path_s: 'tipo',
                icon: <FaIcons.FaTasks />,
            },
            {
                title: 'Cuenta contable',
                path: '/visitador_medico',
                path_s: 'cuenta_contable',
                icon: <FaIcons.FaTasks />,
            },
            {
                title: 'Centro de costo',
                path: '/visitador_medico',
                path_s: 'centro_costo',
                icon: <FaIcons.FaTasks />,
            },
            {
                title: 'Departamento',
                path: '/visitador_medico',
                path_s: 'departamento',
                icon: <FaIcons.FaTasks />,
            },
            {
                title: 'Producto',
                path: '/visitador_medico',
                path_s: 'producto',
                icon: <FaIcons.FaListUl />,
            },
            {
                title: 'Visitadores médicos',
                path: '/visitador_medico',
                path_s: 'visitador',
                icon: <FaIcons.FaListUl />,
            },
        ]
    },
    {
        title: 'Registros',
        path: '#',
        icon: <FaIcons.FaFileExcel />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav: [
            {
                title: 'Subir compras',
                path: '/visitador_medico',
                path_s: 'seleccionar_periodo_compra',
                icon: <FaIcons.FaArrowUp />,
            },
            {
                title: 'Ver compras',
                path: '/visitador_medico',
                path_s: 'seleccionar_periodo_compra_consulta',
                icon: <BsIcons.BsCartCheckFill />,
            },
            {
                title: 'Liquidaciones',
                path: '/visitador_medico',
                path_s: 'seleccionar_periodo_liquidacion',
                icon: <FaIcons.FaArrowUp />,
            },
            {
                title: 'Otros gastos',
                path: '/visitador_medico',
                path_s: 'seleccionar_periodo_otros_gastos',
                icon: <FaIcons.FaArrowUp />,
            },
        ]
    },
    {
        title: 'Generar poliza',
        path: '/visitador_medico/generar_poliza',
        icon: <FaIcons.FaChalkboard />,
    },
    {
        title: 'Informes',
        label: 'informes',
        path: '/visitador/informes',
        icon: <FaIcons.FaClipboard />,
    }
]
