import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";

const LISTADO_MONEDAS = 'LISTADO_MONEDAS';
const GUARDAR_MONEDA = 'GUARDAR_MONEDA';
const PAGE = 'PAGE';
const LOADER = 'LOADER';
const MODULO = 'MODULO';

const setPage = page => ({
    type: PAGE,
    page,
});

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listar = (page = 1) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { page, id };
    dispatch(setLoader(true));
    api.get('/monedas', params).then((response)=>{
        dispatch({ type: LISTADO_MONEDAS, data: response });
        dispatch(setPage(page));
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar monedas',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const registroMoneda = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormMonedas.values;
    const formData = {
        moneda: data.moneda,
        simbolo: data.simbolo,
        cambio: data.cambio,
        empresa: id_emp[5]
    }
    dispatch(setLoader(true));
    api.post('/monedas/', formData).then(() => {
        NotificationManager.success(
            'Moneda creada',
            'Exito',
            3000
        );
        let ruta = `/ajustes/${id_emp[5]}/monedas`
        dispatch(push(ruta));
    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const modificarMoneda = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const datos = getStore().form.FormMonedas.values;
    const id = datos.id;
    const formData = {
        id: id,
        moneda: datos.moneda,
        simbolo: datos.simbolo,
        cambio: datos.cambio
    }
    dispatch(setLoader(true));
    api.put(`/monedas/${id}/`, formData).then(() => {
        NotificationManager.success(
            'Moneda modificada correctamente',
            'Exito',
            3000
        );
        let ruta = `/ajustes/${id_emp[5]}/monedas`
        dispatch(push(ruta));
    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/monedas/${id}`).then((response) => {
        dispatch({type: GUARDAR_MONEDA, lectura: response });
        dispatch(initializeForm('FormMonedas', response ));
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar la moneda',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const actions = {
    listar,
    registroMoneda,
    modificarMoneda,
    leer,
    getModulo
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_MONEDAS]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [GUARDAR_MONEDA]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
};

export const initialState = {
    loader: false,
    lectura: [],
    modulo: [],
    data: {
        results: [],
        count: 0,
    },
    page: 1,
};

export default handleActions(reducers, initialState)