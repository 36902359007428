import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { logOut, getMe, getModulo } from "./redux/modules/cuenta/login";

import Navbar from "./common/components/layout/Navbar/NavbarFinanzasComprasVentas";
import { VerifyLogin } from "./common/components/layout";
import styled from "styled-components";

const StyledMain = styled.main`
    background-color: #121212;
    overflow: auto;
    min-height: 100vh;
`;

class PrivateRouteBaseComprasVentas extends Component {
    // componentDidMount = () => {
    //     const { getModulo } = this.props;
    //     let ruta = window.location.href;
    //     let modulo = ruta.split('/');
    //     getModulo(modulo[5],modulo[4]);
    // }
    isAuthenticated = () => {
        const token = localStorage.getItem("token");
        const {
            getMe,
            login: { me },
        } = this.props;
        if (!!token && !!me.username) {
            return true;
        } else if (token) {
            getMe();
            return "Verifying";
        }
        return false;
    };

    render() {
        const {
            component: Component,
            logOut,
            login: { me, empresaData },
            ...rest
        } = this.props;
        const isAuthenticated = this.isAuthenticated();

        return (
            <Route
                {...rest}
                render={(props) =>
                    isAuthenticated ? (
                        isAuthenticated === true ? (
                            <div>
                                <StyledMain className="main-content p-0">
                                    <div className="main-navbar fondo sticky-top">
                                        <div className="p-0">
                                            <Navbar
                                                logOut={logOut}
                                                user={me}
                                                empresaData={empresaData}
                                            />
                                        </div>
                                    </div>
                                    <div className="main-content-container px-4 container-fluid">
                                        {localStorage.getItem(
                                            "fnzEmpresaId"
                                        ) ? null : (
                                            <Redirect to="/tools/finanzas" />
                                        )}
                                        <Component {...props} />
                                    </div>
                                </StyledMain>
                            </div>
                        ) : (
                            <VerifyLogin />
                        )
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: props.location },
                            }}
                        />
                    )
                }
            />
        );
    }
}

const mstp = (state) => ({ ...state });

const mdtp = { logOut, getMe, getModulo };

const ProtectedRoute = connect(mstp, mdtp)(PrivateRouteBaseComprasVentas);

export default ProtectedRoute;
