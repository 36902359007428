import React from "react";
import { useController } from "react-hook-form";
import {
    NumberField,
    Label,
    Group,
    Input,
    Button,
} from "react-aria-components";
import { Text } from "react-aria-components";
import { useTextField } from "react-aria";
import "./FicNumber.css";

// export default function FicInput({ label, description, ...props }) {
export default function FicNumber({
    errorMsj,
    componentStyle,
    label,
    description,
    control,
    name,
    nonForm,
    onBlur,
    ...props
}) {
    // Creado con la intencion de usarse con react-hook-form, (https://react-hook-form.com/api/usecontroller)
    if (nonForm) {
        return (
            <NumberField
                // {...fieldState}
                minValue={0}
                {...props}
            >
                <Label>{label}</Label>
                <Group onBlur={onBlur}>
                    <Button
                        slot="decrement"
                        className="react-aria-Button btn-minus"
                    >
                        -
                    </Button>
                    <Input />
                    <Button
                        slot="increment"
                        className="react-aria-Button btn-plus"
                    >
                        +
                    </Button>
                </Group>
                {description && <Text slot="description">{description}</Text>}
                {errorMsj && <Text slot="errorMessage">{errorMsj}</Text>}
            </NumberField>
        );
    }

    const { field, fieldState } = useController({ control, name });
    return (
        <NumberField
            // {...fieldState}
            minValue={0}
            {...field}
            {...props}
            onChange={(e) => {
                field.onChange(e);
            }}
            isInvalid={fieldState.invalid}
        >
            <Label>{label}</Label>
            <Group>
                <Button
                    slot="decrement"
                    className="react-aria-Button btn-minus"
                >
                    -
                </Button>
                <Input />
                <Button slot="increment" className="react-aria-Button btn-plus">
                    +
                </Button>
            </Group>
            {description && <Text slot="description">{description}</Text>}
            {fieldState.error && (
                <Text slot="errorMessage">{fieldState.error.message}</Text>
            )}
        </NumberField>
    );
}
