import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";

const LISTADO_TIPOS = 'LISTADO_TIPOS';
const GUARDAR_TIPO = 'GUARDAR_TIPO';
const LOADER = 'LOADER';
const MODULO = 'MODULO';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    api.get('/tipo_producto', params).then((response)=>{
        dispatch({ type: LISTADO_TIPOS, data: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar Tipos de producto',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const registroTiposProducto = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormTipoProducto.values;
    const formData = {
        tipo: data.tipo,
        empresa: id_emp[5]
    }
    dispatch(setLoader(true));
    api.post('/tipo_producto/', formData).then((response) => {
        NotificationManager.success(
        'Tipo de producto creado',
        'Exito',
        3000
    );
    let ruta = `/punto_venta/${id_emp[5]}/tipo_producto`
    dispatch(push(ruta));
    }).catch((error) => {
    NotificationManager.error(
        error.msj,
        'Error',
        2000
    );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const modificarTiposProducto = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const datos = getStore().form.FormTipoProducto.values;
    const id = datos.id;
    const formData = {
        id: id,
        tipo: datos.tipo,
    }
    dispatch(setLoader(true));
    api.put(`/tipo_producto/${id}/`, formData).then((response) => {
        NotificationManager.success(
            'Tipo de producto modificada correctamente',
            'Exito',
            3000
        );
        let ruta = `/punto_venta/${id_emp[5]}/tipo_producto`
        dispatch(push(ruta));
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al modificar tipo de producto',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/tipo_producto/${id}`).then((response) => {
        dispatch({type: GUARDAR_TIPO, lectura: response });
        dispatch(initializeForm('FormTipoProducto', response ));
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar tipo de producto',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const actions = {
    listar,
    registroTiposProducto,
    modificarTiposProducto,
    leer,
    getModulo
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_TIPOS]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [GUARDAR_TIPO]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
};

export const initialState = {
    loader: false,
    lectura: [],
    modulo: [],
    data: [],
};

export default handleActions(reducers, initialState)
