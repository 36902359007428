import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';
import moment from 'moment';

const LISTADO_ENVIO = 'LISTADO_INGRESO';
const LOADER = 'LOADER';
const MODULO = 'MODULO';
const PERIODO_D = 'PERIODO_D';
const DETALLE_PRODUCTOS = 'DETALLE_PRODUCTOS';
const NUMERO_SERIE = 'NUMERO_SERIE';
const PARAMETRO = 'PARAMETRO';
const CLIENTE = 'CLIENTE';
const DETALLE_ENVIO = 'DETALLE_ENVIO';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setLoaderC = loader => ({
    type: LOADER,
    loader,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let periodo = id_emp[7];
    //const params = { page, id, periodo };
    const params = { id, periodo };
    dispatch(setLoader(true));
    api.get('/envio_produccion', params).then((response) => {
        dispatch({ type: LISTADO_ENVIO, data: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar envios',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/envio_produccion/${id}`).then((response) => {
        response.fechaRegistro = response.fecha;
        dispatch(initializeForm('FormEnvioProduccion', response));
        dispatch(detalleEnvio(response.id));
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar el envio',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = { id, modulo }
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response) => {
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const leerPeriodoD = (id, id_periodo) => (dispatch) => {
    const params = { id, id_periodo };
    api.get('/periodos/periodo', params).then((response) => {
        dispatch({ type: PERIODO_D, periodoD: response.periodo });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar periodo',
            'Error',
            2000
        );
    })
}

export const listarIngresos = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/ingreso_produccion/empresa_ingreso_produccion', params).then(data => {
        const list_ing = [];
        if (data) {
            data.forEach(item => {
                list_ing.push({
                    value: item.id,
                    label: item.serie + ' - ' + item.numero
                })
            })
        }
        return list_ing;
    }).catch((error) => {
        return [];
    })
}

export const registroEnvio = (periodoD, numeroS, cliente) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormEnvioProduccion.values;
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fechaRegistro)
    if (fecha >= fechaI && fecha <= fechaF) {
        if (data.piloto === undefined || data.piloto === null) {
            Swal.fire({
                title: 'Envío',
                text: 'Debe de seleccionar piloto',
                type: 'error',
            })
        } else {
            const formData = {
                fecha: data.fechaRegistro,
                serie: data.serie.label,
                numero: numeroS,
                cliente: cliente,
                ingreso: data.ingreso.value,
                observaciones: data.observaciones,
                camion: data.camion,
                piloto: data.piloto.value,
                empresa: id_emp[5],
                periodo: id_emp[7]
            }
            dispatch(setLoader(true));
            api.post('/envio_produccion/', formData).then((response) => {
                NotificationManager.success(
                    'Envío guardado correctamente',
                    'Exito',
                    3000
                );
                Swal.fire({
                    title: '¿Imprimir?',
                    text: '¡Desea imprimir envío!',
                    type: 'question',
                    showCancelButton: true,
                    confirmButtonText: '¡Sí, imprimir!',
                    cancelButtonText: 'No, cancelar',
                    reverseButtons: true
                }).then((result) => {
                    if (result.value) {
                        dispatch(imprimirEnvio(response.encabezado.id));
                        let ruta = `/produccion/${id_emp[5]}/envio/${id_emp[7]}`
                        dispatch(push(ruta));
                    } else {
                        window.location.reload(true);
                    }
                });
            }).catch((error) => {
                NotificationManager.error(
                    error.msj,
                    'Error',
                    8000
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }
    } else {
        Swal.fire({
            title: 'Envío',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })
    }
}

const leerDetalleProductos = id => (dispatch) => {
    const params = { id };
    api.get('/ingreso_produccion_detalle/detalle_produccion_envio', params).then((response) => {
        dispatch({ type: DETALLE_PRODUCTOS, detalleProducto: response });
    }).catch((error) => {
        NotificationManager.error(
            error.body.msj,
            'Error',
            6000
        );
        dispatch({ type: DETALLE_PRODUCTOS, detalleProducto: [] });;
    })
};

export const parametrosEmpresa = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id }
    dispatch(setLoader(true));
    api.get('/empresa/empresa_parametros', params).then((response) => {
        dispatch({ type: PARAMETRO, datos: response });
    }).catch((error) => {

        NotificationManager.error(
            'Ocurrió un error al consultar parametros',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const detalleIngreso = (id) => (dispatch) => {
    if (id === undefined) {
        dispatch(leerDetalleProductos('00000000-0000-0000-0000-000000000000'));
    } else {
        return api.get(`/ingreso_produccion/${id}`).then(data => {
            dispatch({ type: CLIENTE, cli: data });
            dispatch(leerDetalleProductos(data.id));
        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al consultar',
                'Error',
                8000
            );
        })

    }
}

export const listarSeries = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let tipo = 'ENV';
    const params = { id, search, tipo }
    return api.get('/serie_prd', params).then(data => {
        const list_serie = [];
        if (data) {
            data.forEach(item => {
                list_serie.push({
                    value: item.id,
                    label: item.serie
                })
            })
        }
        return list_serie;
    }).catch((error) => {
        return [];
    })
}

export const numeroSerie = (id) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let idEmpre = id_emp[5];
    let tipo = 'ENV';
    const params = { id, idEmpre, tipo }
    dispatch(setLoaderC(true));
    return api.get('/serie_prd/serie/', params).then((response) => {
        return response;
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar numero de serie',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoaderC(false));
    });

}

export const imprimirEnvio = id => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let idE = id_emp[5];
    let idIng = id
    let reporte = 'imprimirEnv';
    const params = { idE, reporte, idIng };
    dispatch(setLoader(true));
    api.getPdf('/envio_produccion/', params).then((response) => {
        if (response.headers['content-type'] == 'application/pdf') {
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'reporte_liquidacion_gastos.pdf';
            window.open(link, '_blank');
            return false;
        }
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al generar reporte',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const detalleEnvio = id => (dispatch) => {
    api.get(`/envio_produccion_detalle/${id}`).then((response) => {
        dispatch({ type: DETALLE_ENVIO, detalleEnv: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};

export const modificarEnvio = (periodoD) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormEnvioProduccion.values;
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fechaRegistro);
    let id = data.id;
    if (fecha >= fechaI && fecha <= fechaF) {
        let piloto = data.piloto;
        if (piloto === undefined) {
            piloto = data.piloto_envio;
        } else {
            piloto = data.piloto.value;
        }
        const formData = {
            id: id,
            fecha: data.fechaRegistro,
            serie: data.serie,
            numero: data.numero,
            observaciones: data.observaciones,
            camion: data.camion,
            piloto: piloto,
        }
        dispatch(setLoader(true));
        api.put(`/envio_produccion/${id}/`, formData).then(() => {
            NotificationManager.success(
                'Envío modificado correctamente',
                'Exito',
                3000
            );
            let ruta = `/produccion/${id_emp[5]}/envio/${id_emp[7]}`
            dispatch(push(ruta));
        }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                0
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    } else {
        Swal.fire({
            title: 'Envío',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })
    }
}

export const listarPilotos = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/piloto/buscar_piloto', params).then(data => {
        const list_ing = [];
        if (data) {
            data.forEach(item => {
                list_ing.push({
                    value: item.id,
                    label: item.nombre
                })
            })
        }
        return list_ing;
    }).catch((error) => {
        return [];
    })
}

export const anular = (id) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let idE = id_emp[5];
    let idEnvio = id;
    const params = { idE, idEnvio };
    dispatch(setLoader(true));
    api.put('/envio_produccion/anular/', params).then(() => {
        NotificationManager.success(
            'Envío anulado correctamente',
            'Exito',
            3000
        );
        let page = 1;
        dispatch(listar(page));
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al anular envío',
            'Error',
            6000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const eliminarEnvio = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`/envio_produccion/${id}`).then(() => {
        dispatch(listar(1));
        NotificationManager.success(
            'Envío borrado correctamente',
            'Exito',
            3000
        );
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar envío',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const actions = {
    listar,
    leer,
    getModulo,
    leerPeriodoD,
    registroEnvio,
    modificarEnvio,
    listarIngresos,
    parametrosEmpresa,
    detalleIngreso,
    listarSeries,
    numeroSerie,
    leerDetalleProductos,
    imprimirEnvio,
    listarPilotos,
    anular,
    eliminarEnvio
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_ENVIO]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [PERIODO_D]: (state, { periodoD }) => {
        return {
            ...state,
            periodoD,
        };
    },
    [PARAMETRO]: (state, { datos }) => {
        return {
            ...state,
            datos,
        };
    },
    [DETALLE_PRODUCTOS]: (state, { detalleProducto }) => {
        return {
            ...state,
            detalleProducto,
        };
    },
    [CLIENTE]: (state, { cli }) => {
        return {
            ...state,
            cli,
        };
    },
    [NUMERO_SERIE]: (state, { numeroS }) => {
        return {
            ...state,
            numeroS,
        };
    },
    [DETALLE_ENVIO]: (state, { detalleEnv }) => {
        return {
            ...state,
            detalleEnv,
        };
    },
};

export const initialState = {
    loader: false,
    lectura: [],
    modulo: [],
    periodoD: [],
    cli: [],
    detalleProducto: [],
    detalleEnv: [],
    numeroS: [],
    datos: [],
    data: [],
    page: 1,
};

export default handleActions(reducers, initialState)
