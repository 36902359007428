import { handleActions, createAction } from 'redux-actions';
import { NotificationManager } from "react-notifications";
import { api } from "api";



const LISTAR_AUTOS = createAction("LISTAR_AUTOS")

export const listarAutos = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/'); 
    let id = id_emp[5];
    const params = {id}
    api.get('/auto_ventas/ingresos', params).then((response)=>{
        dispatch(LISTAR_AUTOS(response));
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar autos',
            'Error',
            2000
        );
    })
}

export const actions = {
    listarAutos
};

export const reducers = {
    [LISTAR_AUTOS]: (state, action) => {
        return {
            ...state,
            listaDeAutos: action.payload
        }
    },
};

export const initialState = {
    listaDeAutos: [],
};

export default handleActions(reducers, initialState)