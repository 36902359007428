import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';

import {
    renderNumber,
    SelectField,
    renderField
} from "../../Utils/renderField/renderField";

function TrasladoForm(props) {
    const { traslado, listarPeriodos, periodos } = props;

    const handleOnChange = (e,value) => {
        if (value.length == 4) {
            listarPeriodos(value)
        }
        
    }
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    return (
        <div>
            <br />
            <div className="mb-4 card card-small text-white bg-dark mb-3">
                <div className="border-bottom card-header"><h6 className="m-0">Traslado de pólizas</h6></div>
                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                    <div className="d-flex flex-column flex-1 mx-4">
                        <div className='row mb-2'>
                            <div className='col-2'>
                                <Field
                                    name="anio"
                                    placeholder="Año"
                                    className="form-control"
                                    component={renderNumber}
                                    onChange={handleOnChange}
                                    msj="Año"
                                />
                            </div>
                            <div className='col-6'>
                            {periodos &&
                                <div className="form-floating">
                                    <Field
                                        name="periodo"
                                        options={periodos}
                                        component={SelectField}
                                        className="form-select"
                                        msj="Periodo"
                                    />
                                </div>
                            }
                            </div>
                        </div>
                        <div className='d-flex flex-row mt-3'>

                            <button
                                className='btn mr-2 mb-3 btn-primary'
                                type='submit'
                                onClick={traslado}
                            >
                                Trasladar pólizas
                            </button>

                            <a
                                href={`/#/libros_contables/${id_emp[5]}/`}
                                className='btn btn-secondary mb-3'
                            >
                                Regresar
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

TrasladoForm.propTypes = {
    subirProductos: PropTypes.func,
    listarEmpresas: PropTypes.func,
};

export default reduxForm({
    form: 'FormTraslado'
})(TrasladoForm);
