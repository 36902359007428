import React, { Component } from 'react';
import Formulario from './SaldosInicialesForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";

class Saldos extends Component{
    state = {
        crear: true,
    }

    componentDidMount = () => {
        const { match, leerSaldo, listarProveedores, listarPaises, 
            getModulo, parametrosEmpresa } = this.props;
        const id = match.params.id;
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        if (id){
            this.setState({crear: false});
            leerSaldo(id);
        }
        listarProveedores();
        listarPaises();
        parametrosEmpresa();
        getModulo(modulo[5],modulo[4]);
    }
    
    render(){
        const { registroCompraSaldo, modificarCompraSaldo, loader, lectura, listaP,
            listarProveedores, registroProveedorSaldo, estable, modulo, datos } = this.props;
        const { crear } = this.state;
        const funcionEnvio = crear ? registroCompraSaldo : modificarCompraSaldo;
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    onSubmit = {funcionEnvio}
                    crear = {crear}
                    lectura = {lectura}
                    obtenerPaises = { listaP }
                    listarProveedores = {listarProveedores}
                    registroProveedorSaldo = {registroProveedorSaldo}
                    listaEs = {estable}
                    modulo = {modulo}
                    datos = {datos}
                />
            </LoadMask>
        );
    }
}

export default Saldos;
