import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';
import moment from 'moment';

const LISTADO_ENVIO = 'LISTADO_INGRESO';
const LOADER = 'LOADER';
const MODULO = 'MODULO';
const PERIODO_D = 'PERIODO_D';
const DETALLE_PRODUCTOS = 'DETALLE_PRODUCTOS';
const NUMERO_SERIE = 'NUMERO_SERIE';
const PARAMETRO = 'PARAMETRO';
const CLIENTE = 'CLIENTE';
const DETALLE_ENVIO = 'DETALLE_ENVIO';
const NOTA = 'NOTA';
const TIPO_ENVIO = 'TIPO_ENVIO';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setLoaderC = loader => ({
    type: LOADER,
    loader,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let periodo = id_emp[7];
    //const params = { page, id, periodo };
    const params = { id, periodo };
    api.get('/nota_cobro_produccion', params).then((response)=>{
        dispatch({ type: LISTADO_ENVIO, data: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar notas de cobro',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/nota_cobro_produccion/${id}`).then((response) => {
        response.fechaRegistro = response.fecha;
        response.envio = response.envioD;
        dispatch(initializeForm('FormNotaCobro', response ));
        dispatch({ type: NOTA, nota: response.en_dolares });
        dispatch(leerDetalleProductos(response.id));
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar nota de cobro',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const leerPeriodoD = (id, id_periodo) => (dispatch) => {
    const params = { id, id_periodo };
    api.get('/periodos/periodo', params).then((response) => {
        dispatch({ type: PERIODO_D, periodoD: response.periodo });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar periodo',
            'Error',
            2000
        );
    })
}

export const listarEnvios = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id, search}
    return api.get('/envio_produccion/empresa_envio_produccion_nota_cobro',params).then(data=>{
        const list_envios = [];
        if(data){
            data.forEach(item=>{
                list_envios.push({
                    value: item.id,
                    label: item.serie + ' - ' + item.numero + ' [ ' + item.tipo +' ]'
                })
            })
        }
        return list_envios ;
    }).catch((error)=>{
        return [];
    })
}

export const registroNota = (periodoD, idCliente, detalleN, numeroS, exportacion, tipoCambio, tipoEnvio) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormNotaCobro.values;
    
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fechaRegistro)
    if (fecha>=fechaI && fecha<=fechaF){
        const formData = {
            fecha: data.fechaRegistro,
            serie: data.serie.label,
            numero: numeroS,    
            observaciones: data.observaciones,
            envio: data.envio.value,
            idCliente: tipoEnvio === 'DEV' ? data.cliente_documento.value : idCliente,
            detalleNota: detalleN,
            tipo_cambio: tipoCambio,
            en_dolares: exportacion ? true : false,
            empresa: id_emp[5],
            periodo: id_emp[7]
        }
        dispatch(setLoader(true));
        api.post('/nota_cobro_produccion/', formData).then((response) => {
            NotificationManager.success(
                'Nota guardada correctamente',
                'Exito',
                3000
            );
            Swal.fire({
                title: '¿Imprimir?',
                text: '¡Desea imprimir nota de cobro!',
                type: 'question',
                showCancelButton: true,
                confirmButtonText: '¡Sí, imprimir!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    dispatch(imprimirNota(response.id));
                    let ruta = `/produccion/${id_emp[5]}/nota_cobro/${id_emp[7]}`
                    dispatch(push(ruta))
                }else {
                    window.location.reload(true);
                }
            });
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }else {
        Swal.fire({
            title: 'Nota de cobro',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })        
    }
}

const leerDetalleProductos = id => (dispatch) => {
    api.get(`/nota_cobro_detalle/${id}`).then((response)=>{
        dispatch({ type: DETALLE_PRODUCTOS, detalleProducto: response });
    }).catch((error)=>{
        NotificationManager.error(
            error.body.msj,
            'Error',
            6000
        );
        dispatch({ type: DETALLE_PRODUCTOS, detalleProducto: [] });;
    })
};

export const parametrosEmpresa = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id}
    dispatch(setLoader(true));
    api.get('/empresa/empresa_parametros', params).then((response) => {
        dispatch({type: PARAMETRO, datos: response });
    }).catch((error)=>{
        
        NotificationManager.error(
            'Ocurrió un error al consultar parametros',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

let detalle = [];
export const detalleEnvio = (id) => (dispatch) => {
    let params = {id}
    return api.get('/envio_produccion_detalle/detalle_envio', params).then((response)=>{
        dispatch({type: CLIENTE, cli: response });
        detalle=[]
        response.detalle.forEach(data => {
            detalle.push({
                id: data.id,
                cantidad: data.cantidad,
                sku: data.sku,
                producto: data.producto,
                productoN: data.productoN,
                unidad: data.unidad,
                insumo: data.insumo,
                cantidad_insumo: data.cantidad_insumo,
                precio: data.precio,
                total: data.total
            })
        });
        dispatch({ type: DETALLE_ENVIO, detalleEnv: detalle });
        dispatch({ type: TIPO_ENVIO, tipoEnvio: response.tipo });
        
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
}

export const modificarNota = (periodoD) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormEnvioProduccion.values;
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fechaRegistro);
    let id = data.id;
    if (fecha>=fechaI && fecha<=fechaF){
        if (data.observaciones === undefined || data.observaciones === ''){
            Swal.fire({
                title: 'Envío',
                text: 'Debe de ingresar observación',
                type: 'error',
            })    
        }else {
            const formData = {
                id: id,
                fecha: data.fechaRegistro,
                serie: data.serie,
                numero: data.numero,
                observaciones: data.observaciones,
                camion: data.camion,
                piloto: data.piloto,
            }
            dispatch(setLoader(true));
            api.put(`/nota_cobro_produccion/${id}/`, formData).then(() => {
                NotificationManager.success(
                    'Envío modificado correctamente',
                    'Exito',
                    3000
                );
                let ruta = `/produccion/${id_emp[5]}/envio/${id_emp[7]}`
                dispatch(push(ruta));
            }).catch((error) => {
                NotificationManager.error(
                    error.msj,
                    'Error',
                    0
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }
    }else {
        Swal.fire({
            title: 'Envío',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })        
    }
}

export const anular = (id) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let idE = id_emp[5];
    let idNota = id;
    const params = { idE, idNota };
    dispatch(setLoader(true));
    api.put('/nota_cobro_produccion/anular/', params).then(() => {
        NotificationManager.success(
            'Nota de cobro anulada correctamente',
            'Exito',
            3000
        );
        let page = 1;
        dispatch(listar(page));
    }).catch((error) => {

        if (error.abono) {
            Swal.fire({
                title: 'Nota de cobro',
                text: error.abono,
                type: 'error',
            })    
        }else{
            NotificationManager.error(
                'Ocurrió un error al anular nota de cobro',
                'Error',
                6000
            );
        }

    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const imprimirNota = id => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let idE = id_emp[5];
    let idIng = id
    let reporte = 'imprimirNota';
    const params = { idE, reporte, idIng };
    dispatch(setLoader(true));
    api.getPdf('/nota_cobro_produccion/', params).then((response) => {
        if (response.headers['content-type'] == 'application/pdf') {
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'reporte_nota_cobro.pdf';
            window.open(link, '_blank');
            return false;
        }
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al generar reporte',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const numeroSerie = (id) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let idEmpre = id_emp[5];
    let tipo = 'NTA';
    const params = { id, idEmpre, tipo }
    dispatch(setLoaderC(true));
    return api.get('/serie_prd/serie/', params).then((response) => {
        return response;
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar numero de serie',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoaderC(false));
    });

}

export const listarSeries = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let tipo = 'NTA';
    const params = { id, search, tipo }
    return api.get('/serie_prd', params).then(data => {
        const list_serie = [];
        if (data) {
            data.forEach(item => {
                list_serie.push({
                    value: item.id,
                    label: item.serie
                })
            })
        }
        return list_serie;
    }).catch((error) => {
        return [];
    })
}

const vaciar = () => (dispatch) => {
    detalle = [];
    dispatch(initializeForm('FormNotaCobro'));
    dispatch({ type: DETALLE_ENVIO, detalleEnv: detalle });
};

const eliminarNota = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`/nota_cobro_produccion/${id}`).then(() => {
        dispatch(listar(1));
        NotificationManager.success(
            'Nota de cobro borrada correctamente',
            'Exito',
            3000
        );
    }).catch((error) => {
        
        if (error.abono) {
            Swal.fire({
                title: 'Nota de cobro',
                text: error.abono,
                type: 'error',
            })    
        }else{
            NotificationManager.error(
                'Ocurrió un error en eliminar nota de cobro',
                'Error',
                6000
            );
        }
        
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const tipoCambio = (periodoD, exportacion) => (dispatch, getStore) => {
    const data = getStore().form.FormNotaCobro.values;
    if (data === undefined) {
        Swal.fire({
            title: 'Nota de cobro',
            text: 'Debe de ingresar datos de la nota de cobro',
            type: 'error',
        })
    } else {   
        if (exportacion){
            let fechaI = moment(periodoD.fecha_inicio);
            let fechaF = moment(periodoD.fecha_fin);
            let fecha = moment(data.fechaRegistro)
            if (fecha>=fechaI && fecha<=fechaF){
                if (data.fechaRegistro === undefined) {
                    Swal.fire({
                        title: 'Nota de cobro',
                        text: 'Debe de ingresar fecha de la nota de cobro',
                        type: 'error',
                    })
                } else {
                    let fecha = data.fechaRegistro;
                    let ruta = window.location.href;
                    let id_emp = ruta.split('/');
                    let idEmpre = id_emp[5];
                    const params = { fecha, idEmpre }
                    dispatch(setLoaderC(true));
                    return api.get('/tipo_cambio/tipo_cambio_fecha/', params).then((response) => {
                        if (parseFloat(response) === 0) {
                            Swal.fire({
                                title: 'No hay tipo de cambio del dia' + ' ' + moment(fecha).format("DD/MM/YYYY"),
                                html:
                                    '<div class="col-sm-12 mt-4">' +
                                    '<h4 style="color: white;">¿Desea ingresarlo?</h4>' +
                                    '</div>',
                                type: 'info',
                                background: "black",
                                showCancelButton: true,
                                confirmButtonText: 'Si',
                                cancelButtonText: 'No',
                                confirmButtonColor: "#4040ff",
                                cancelButtonColor: "#646464",
                                reverseButtons: true,
                                customClass: {
                                    title: 'sweet_titleImportant',
                                },
                            }).then((result) => {
                                if (result.value) {
                                    Swal.fire({
                                        title: 'Ingrese tipo de cambio',
                                        html:
                                            '<div class="col-sm-12 mt-3">' +
                                            '<h4 style="color: white">' + moment(fecha).format("DD/MM/YYYY") + '</h4>' +
                                            '<input type="number" min="1" id="cambio" class="swal2-input form-control">' +
                                            '</div>',
                                        type: 'info',
                                        background: "black",
                                        showCancelButton: true,
                                        confirmButtonText: 'Si',
                                        cancelButtonText: 'No',
                                        confirmButtonColor: "#4040ff",
                                        cancelButtonColor: "#646464",
                                        reverseButtons: true,
                                        customClass: {
                                            title: 'sweet_titleImportant',
                                        },
                                    }).then((result) => {
                                        if (result.value) {
                                            let tcambio = document.getElementById('cambio').value
                                            const formData = {
                                                fechaT: fecha,
                                                tipoCambio: tcambio,
                                                empresa: id_emp[5],
                                                tipo: 'manual'
                                            }
                                            api.post('/tipo_cambio/', formData).then(response => {
                                                NotificationManager.success(
                                                    'Tipo de cambio guardado',
                                                    'Exito',
                                                    4000
                                                );

                                            }).catch((error) => {
                                                NotificationManager.error(
                                                    error.msj,
                                                    'Error',
                                                    0
                                                );
                                            })
                                        }
                                    });
                                }
                            });
                        } else {
                            return response;
                        }
                    }).catch((error) => {
                        NotificationManager.error(
                            'Ocurrió un error al consultar tipo de cambio',
                            'Error',
                            2000
                        );
                    }).finally(() => {
                        dispatch(setLoaderC(false));
                    });
                }    
            }else{
                Swal.fire({
                    title: 'Nota de cobro',
                    text: 'Fecha fuera del periodo',
                    type: 'error',
                })        
            }
        }
    }
}

export const listarClientes = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/cliente/empresa_clientes', params).then(data => {
        const list_cliente = [];
        if (data) {
            data.forEach(item => {
                list_cliente.push({
                    value: item.id,
                    label: item.identificador + ' ' + item.nombre
                })
            })
        }
        return list_cliente;
    }).catch((error) => {

        return [];
    })
}

export const actions = {
    listar,
    leer,
    getModulo,
    leerPeriodoD,
    registroNota,
    modificarNota,
    listarEnvios,
    parametrosEmpresa,
    detalleEnvio,
    leerDetalleProductos,
    anular,
    imprimirNota,
    numeroSerie,
    listarSeries,
    vaciar,
    eliminarNota,
    tipoCambio,
    listarClientes
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_ENVIO]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [PERIODO_D]: (state, { periodoD }) => {
        return {
            ...state,
            periodoD,
        };
    },
    [PARAMETRO]: (state, { datos }) => {
        return {
            ...state,
            datos,
        };
    },
    [DETALLE_PRODUCTOS]: (state, { detalleProducto }) => {
        return {
            ...state,
            detalleProducto,
        };
    },
    [CLIENTE]: (state, { cli }) => {
        return {
            ...state,
            cli,
        };
    },
    [NUMERO_SERIE]: (state, { numeroS }) => {
        return {
            ...state,
            numeroS,
        };
    },
    [DETALLE_ENVIO]: (state, { detalleEnv }) => {
        return {
            ...state,
            detalleEnv,
        };
    },
    [NOTA]: (state, { nota }) => {
        return {
            ...state,
            nota,
        };
    },
    [TIPO_ENVIO]: (state, { tipoEnvio }) => {
        return {
            ...state,
            tipoEnvio,
        };
    },
};

export const initialState = {
    loader: false,
    lectura: [],
    modulo: [],
    periodoD: [],
    cli: [],
    nota: [], 
    detalleProducto: [],
    detalleEnv: [],
    numeroS: [],
    datos: [],
    data: [],
    page: 1,
    tipoEnvio: []
};

export default handleActions(reducers, initialState)

    