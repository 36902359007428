import { handleActions } from 'redux-actions';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';
const valEmp = __CONFIG__.empresa;

const COLABORADOR = 'COLABORADOR';
const LOADER = 'LOADER';
const MODULO = 'MODULO';
const PUESTO = 'PUESTO';
const HISTORIAL = 'HISTORIAL';

const setLoader = loader => ({
    type: LOADER,
    loader,
});


export const listarColaborador = (pais) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params;
    let id = ''
    if (valEmp === 'fage'){
        id = id_emp[6];
        params = { id, pais };
    }else{
        id = id_emp[5];
        params = { id, pais };
    }
    api.get('/colaborador/empresa_colaborador_pais', params).then(data => {
        const lista = [];
        if (data) {
            data.forEach(item => {
                lista.push({
                    value: item.id,
                    label: item.codigo+' - ' + item.nombre
                })
            })
        }
        dispatch({type: COLABORADOR, emp: lista })
    }).catch((error) => {
        return [];
    })
}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = { id, modulo }
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response) => {
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const listarPaises = () => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let empresa = '';
    if (valEmp === 'fage'){
        empresa = id_emp[6];
    }else{
        empresa = id_emp[5];
    }
    return api.get(`/pais_colaborador/pais_empresa/?id=${empresa}`).then((data)=>{
        const pais = [];
        data.results.forEach(item=>{
            pais.push({
                value: item.id,
                label: item.pais
            })
        })
        return pais;
    }).catch(error=>{
        NotificationManager.error(
            'Ocurrió un error al listar paises',
            'Error',
            0
        );
    })
}

export const datosColaborador = (emp) => (dispatch) => {
    api.get(`/colaborador/${emp}`).then((response) => {
        dispatch({type: PUESTO, cargo: response.cargo })
        dispatch(historial(response.empresa, response.id))
    }).catch((error) => {
        return [];
    })
}

export const guardarSalario = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormAumentoSalarioColaborador.values;
    let id_empresa = '';
    if (valEmp === 'fage'){
        id_empresa = id_emp[6]
    }else{
        id_empresa = id_emp[5]
    }
    if (data === undefined) {
        Swal.fire({
            title: 'Aumento de salario',
            text: 'Debe de seleccionar colaborador',
            type: 'error',
        })
    } else {
        if (data.fecha_inicio === undefined || data.fecha_inicio === '') {
            Swal.fire({
                title: 'Aumento de salario',
                text: 'Debe de ingresar fecha de inicio',
                type: 'error',
            })
        } else {
            if (data.sueldo_base === undefined || data.sueldo_base === 0.00) {
                Swal.fire({
                    title: 'Aumento de salario',
                    text: 'Debe de ingresar salario',
                    type: 'error',
                })
            } else {
                if (data.bonificacion === undefined || data.bonificacion === 0.00) {
                    Swal.fire({
                        title: 'Aumento de salario',
                        text: 'Debe de ingresar bonificación',
                        type: 'error',
                    })
                } else {
                    const formData = {
                        colaborador: data.colaborador.value,
                        fecha_inicio: data.fecha_inicio,
                        sueldo: data.sueldo_base,
                        bonificacion: data.bonificacion,
                        empresa: id_empresa
                    }
                    dispatch(setLoader(true));
                    api.post('/rrhh_historial_salario/', formData).then((response) => {
                        dispatch(historial(response.empresa, response.idColaborador))
                        NotificationManager.success(
                            'Nuevo salario guardado',
                            'Exito',
                            2000
                        );
                        setTimeout(() => {
                            window.location.reload(true);
                        }, 700);
                        
                    }).catch((error) => {
                        NotificationManager.error(
                            error.msj,
                            'Error',
                            2000
                        );
                    }).finally(() => {
                        dispatch(setLoader(false));
                    });
                }
            }
        }
    }
}

export const historial = (empresa, idCol) => (dispatch) => {
    const params = { empresa, idCol }
    dispatch(setLoader(true));
    api.get('/rrhh_historial_salario/', params).then((response) => {
        dispatch({ type: HISTORIAL, historialSal: response });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en mostrar historial',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const eliminarLinea = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`/rrhh_historial_salario/${id}`).then((response) => {
        dispatch(historial(response.empresa, response.idColaborador))
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar salario',
            'Error',
            3000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const limpiarDatos = () => (dispatch) => {
    dispatch({ type: PUESTO, cargo: [] });
    dispatch({ type: COLABORADOR, emp: [] })
    dispatch({ type: HISTORIAL, historialSal: [] });
};

export const actions = {
    listarColaborador,
    getModulo,
    listarPaises,
    guardarSalario,
    datosColaborador,
    historial,
    eliminarLinea,
    limpiarDatos
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [COLABORADOR]: (state, { emp }) => {
        return {
            ...state,
            emp,
        };
    },
    [PUESTO]: (state, { cargo }) => {
        return {
            ...state,
            cargo,
        };
    },
    [HISTORIAL]: (state, { historialSal }) => {
        return {
            ...state,
            historialSal,
        };
    },
};

export const initialState = {
    loader: false,
    modulo: [],
    emp: [],
    cargo: [],
    historialSal: []
};

export default handleActions(reducers, initialState)
