import React from 'react';
import PropTypes from 'prop-types';
const defaultA = require("assets/img/buildings.jpg");

function EmpresaCard(props) {
  const {
    logo,
    name,
    id,
    estab,
    tipo_cambio,
  } = props;

  const img = logo;
  return (
    <div className="card company" >
      <div className='text-center imageBox'>
      <img className='imageLogo' src={logo ? img : defaultA} alt="Card image"/>
      </div>
      
      <div className="card-body">
        <h6 className="titleCompany">{name}</h6>
        <a href={`/#/empresas/${id}/modulos`} className="btn-outline-dark botonSeleccionar">Seleccionar</a>
      </div>
    </div>
  );
}

EmpresaCard.propTypes = {
  logo: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default (EmpresaCard);
