import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/recursos_humanos/tipo_descuento';
import TipoDescuento from './TipoDescuento';

const ms2p = (state) => {
  return {
    ...state.tipo_descuento_rrhh,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(TipoDescuento);
