import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';
import moment from 'moment';

const LISTADO_INGRESO = 'LISTADO_INGRESO';
const GUARDAR_INGRESO = 'GUARDAR_INGRESO';
const LOADER = 'LOADER';
const MODULO = 'MODULO';
const PERIODO_D = 'PERIODO_D';
const DETALLE = 'DETALLE';
const DETALLE_INSUMOS = 'DETALLE_INSUMOS';
const CORRELATIVO = 'CORRELATIVO';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let periodo = id_emp[7];
    let motivo = 'ING';
    const params = { id, periodo, motivo };
    dispatch(setLoader(true));
    api.get('/movimiento_insumos', params).then((response) => {
        dispatch({ type: LISTADO_INGRESO, data: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar compras de cajas',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/movimiento_insumos/${id}`).then((response) => {
        dispatch({ type: GUARDAR_INGRESO, lectura: response });
        response.fechaRegistro = response.fecha;
        response.descripcion = response.observaciones;
        localStorage.setItem('id_detalle_ingreso_cajas', response.id);
        dispatch(initializeForm('FormIngresoCajasProduccion', response));
        dispatch(leerDetalleIngreso(response.id));
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar compra',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = { id, modulo }
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response) => {
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const leerPeriodoD = (id, id_periodo) => (dispatch) => {
    const params = { id, id_periodo };
    api.get('/periodos/periodo', params).then((response) => {
        dispatch({ type: PERIODO_D, periodoD: response.periodo });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar periodo',
            'Error',
            2000
        );
    })
}

export const listarFases = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/fase_prd/buscar_fase', params).then(data => {
        const list_fase = [];
        if (data) {
            data.forEach(item => {
                list_fase.push({
                    value: item.id,
                    label: item.descripcion
                })
            })
        }
        return list_fase;
    }).catch((error) => {
        return [];
    })
}

export const registroIngreso = (periodoD, numeroS) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormIngresoCajasProduccion.values;

    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fecha)
    if (fecha >= fechaI && fecha <= fechaF) {

        const formData = {
            fecha: data.fecha,
            numero: numeroS,
            descripcion: data.descripcion,
            detalleDev: data.detalleD,
            motivo: 'ING',
            cliente: '',
            piloto: '',
            empresa: id_emp[5],
            periodo: id_emp[7]
        }
        dispatch(setLoader(true));
        api.post('/movimiento_insumos/', formData).then(() => {
            NotificationManager.success(
                'Compra de cajas guardada correctamente',
                'Exito',
                3000
            );
            let ruta = `/produccion/${id_emp[5]}/compra_cajas/${id_emp[7]}`
            dispatch(push(ruta));
        }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                0
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });

    } else {
        Swal.fire({
            title: 'Compra de cajas',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })
    }
}

export const modificarIngreso = (periodoD) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormIngresoCajasProduccion.values;

    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fecha);
    let id = data.id;
    if (fecha >= fechaI && fecha <= fechaF) {
        const formData = {
            id: id,
            descripcion: data.descripcion,
            fecha: data.fecha,
            cliente: '',
            piloto: ''
        }
        dispatch(setLoader(true));
        api.put(`/movimiento_insumos/${id}/`, formData).then(() => {
            NotificationManager.success(
                'Compra de cajas modificada correctamente',
                'Exito',
                3000
            );
            let ruta = `/produccion/${id_emp[5]}/compra_cajas/${id_emp[7]}`
            dispatch(push(ruta));
        }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                0
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    } else {
        Swal.fire({
            title: 'Compra de cajas',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })
    }
}

export const nuevaLineaInsumo = () => (dispatch, getStore) => {
    const data = getStore().form.FormIngresoCajasProduccion.values;
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const detalle = localStorage.getItem('id_detalle_ingreso_cajas');
    if (data.cantidad === undefined || parseInt(data.cantidad) === 0) {
        Swal.fire({
            title: 'Compra de cajas',
            text: 'Debe de ingresar cantidad',
            type: 'error',
        })
    } else {
        if (data.insumo === undefined || data.insumo === null) {
            Swal.fire({
                title: 'Compra de cajas',
                text: 'Debe de seleccionar insumo',
                type: 'error',
            })
        } else {
            const formData = {
                cantidad: data.cantidad,
                idInsumo: data.insumo.value,
                idFase: '',
                idInvernadero: '',
                detalle: detalle,
                empresa: id_emp[5]
            }
            dispatch(setLoader(true));
            api.post('/movimiento_insumos/', formData).then((response) => {
                response.fecha = response.encabezado.fecha;
                response.numero = response.encabezado.numero;
                response.descripcion = response.encabezado.observaciones;
                response.id = response.encabezado.id;
                dispatch(initializeForm('FormIngresoCajasProduccion', response));
                dispatch(leerDetalleIngreso(response.idDetalle));
                NotificationManager.success(
                    'Insumo ingresado correctamente',
                    'Exito',
                    3000
                );
            }).catch((error) => {
                NotificationManager.error(
                    error.msj,
                    'Error',
                    2000
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }
    }

}

const eliminarLineaInsumo = id => (dispatch) => {
    const detalle = localStorage.getItem('id_detalle_ingreso_cajas');
    dispatch(setLoader(true));
    api.eliminar(`/movimiento_insumos_detalle/${id}`).then(() => {
        dispatch(leerDetalleIngreso(detalle));
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar el insumo',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

let detalle = [];
export const agregarLinea = (periodoD) => (dispatch, getStore) => {
    const data = getStore().form.FormIngresoCajasProduccion.values;

    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fecha);
    if (fecha >= fechaI && fecha <= fechaF) {
        if (data.cantidad === undefined || parseInt(data.cantidad) === 0) {
            Swal.fire({
                title: 'Compra de cajas',
                text: 'Debe de ingresar cantidad',
                type: 'error',
            })
        } else {
            if (data.insumo === undefined || data.insumo === null) {
                Swal.fire({
                    title: 'Compra de cajas',
                    text: 'Debe de seleccionar insumo',
                    type: 'error',
                })
            } else {
                detalle.push({
                    id: data.insumo.value + '/' + data.cantidad,
                    idInsumo: data.insumo.value,
                    insumo: data.insumo.label,
                    cantidad: data.cantidad,
                    invernadero: ''
                })
                    
                data.cantidad = 0;
                data.detalleD = detalle;
                NotificationManager.success(
                    'Insumo ingresado correctamente',
                    'Exito',
                    3000
                );

                dispatch(actualizar());
                }
        }
    } else {
        Swal.fire({
            title: 'Compra de cajas',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })
    }
}


const eliminarLinea = (id) => (dispatch) => {
    let d = id.split('/');
    detalle.forEach((element, index) => {
        if (element.idInsumo === d[0] && element.cantidad === d[1]) {
            detalle.splice(index, 1);
        }
    })
    dispatch(actualizar());
};

const vaciar = () => (dispatch) => {
    detalle = [];
    dispatch(initializeForm('FormIngresoCajasProduccion'));
    dispatch({ type: DETALLE, detallePro: detalle });
};

export const listarInsumos = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/insumo_prd/buscar_insumo', params).then(data => {
        const list_insumo = [];
        if (data) {
            data.forEach(item => {
                list_insumo.push({
                    value: item.id,
                    label: item.descripcion
                })
            })
        }
        return list_insumo;
    }).catch((error) => {
        return [];
    })
}

const actualizar = () => (dispatch, getStore) => {
    const data = getStore().form.FormIngresoCajasProduccion.values;
    dispatch(initializeForm('FormIngresoCajasProduccion', data));
    dispatch({ type: DETALLE, detallePro: data.detalleD })
};

const leerDetalleIngreso = id => (dispatch) => {
    api.get(`/movimiento_insumos_detalle/${id}`).then((response) => {
        dispatch({ type: DETALLE_INSUMOS, detalleInsumo: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};

const eliminarIngreso = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`/movimiento_insumos/${id}`).then(() => {
        dispatch(listar(1));
        NotificationManager.success(
            'Compra borrada correctamente',
            'Exito',
            3000
        );
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar compra',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const numeroIngreso = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let tipo = 'ING'
    const params = { id, tipo };
    api.get('/movimiento_insumos/correlativo', params).then((response) => {
        let numero = parseInt(response.numero)+1
        dispatch({ type: CORRELATIVO, numeroIng: numero })
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar correlativo',
            'Error',
            2000
        );
    })
}

export const actions = {
    listar,
    leer,
    getModulo,
    leerPeriodoD,
    registroIngreso,
    modificarIngreso,
    agregarLinea,
    listarFases,
    eliminarLineaInsumo,
    nuevaLineaInsumo,
    eliminarLinea,
    vaciar,
    listarInsumos,
    actualizar,
    listarInsumos,
    numeroIngreso,
    eliminarIngreso
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_INGRESO]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [GUARDAR_INGRESO]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [PERIODO_D]: (state, { periodoD }) => {
        return {
            ...state,
            periodoD,
        };
    },
    [DETALLE]: (state, { detallePro }) => {
        return {
            ...state,
            detallePro,
        };
    },
    [DETALLE_INSUMOS]: (state, { detalleInsumo }) => {
        return {
            ...state,
            detalleInsumo,
        };
    },
    [CORRELATIVO]: (state, { numeroIng }) => {
        return {
            ...state,
            numeroIng,
        };
    },
};

export const initialState = {
    loader: false,
    lectura: [],
    modulo: [],
    periodoD: [],
    detallePro: [],
    detalleInsumo: [],
    data: [],
    page: 1,
    numeroIng: 0
};

export default handleActions(reducers, initialState)
