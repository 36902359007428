import React, { Component } from 'react';
import Formulario from './CuentasContablesForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";

class CuentasContables extends Component{
    state = {
        crear: true,
    }

    componentDidMount = () => {
        const { listarCuentas,match, leer, getModulo } = this.props;
        const id = match.params.id;
        if (id){
            this.setState({crear: false});
            leer(id);
        }
        listarCuentas();
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        getModulo(modulo[5],modulo[4]);
    }
    
    render(){
        const { registroCuenta, modificarCuenta, nivel, lectura, loader, modulo } = this.props;
        const { crear } = this.state;
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    niveles = { nivel }
                    crear = {crear}
                    lectura = {lectura}
                    modulo = {modulo}
                    registroCuenta = {registroCuenta}
                    modificarCuenta = {modificarCuenta}
                />
            </LoadMask>
        );
    }
}

export default CuentasContables
