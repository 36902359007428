import React, { Component } from 'react';
import styled from 'styled-components';
import { SidebarDataUserS } from '../Sidebar/SideBarDataUserS';
import MenuItemsUser from './NavbarMenuUser';

const defaultAvatar = require("assets/img/avatar-placeholder.png");


class NavbarRegistro extends Component {
    constructor(props) {
        super(props);
        this.state = { dropdownOpen: false };
    }

    render() {
        const { logOut, user } = this.props;
        return (
            <NavBar>
                <div className="imageBoxLogo d-table m-auto">
                    <Image
                        src={require('assets/img/SIFW-LOGO-Red.png')}
                        alt="Logo" />
                </div>
                <div className="titleNav">
                </div>
                <NavBarMenuUser>
                    <ul className="borderUser flex-row navbar-nav">
                        {SidebarDataUserS.map((menu, index) => {
                            return <MenuItemsUser
                                items={menu}
                                key={index}
                                img={(user.avatar) ? __CONFIG__.api_image + user.avatar : defaultAvatar}
                                user={user.username}
                                logOut={logOut}
                            />;
                        })}
                    </ul>
                </NavBarMenuUser>
            </NavBar>
        );
    }
}

export default NavbarRegistro;

const NavBar = styled.div`
    background-color: #333;
    height: 61px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const NavBarMenuUser = styled.div`
    background-color: black;
    height: 61px;
    width: 450px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const Image = styled.img`
    max-width: 100%;
    max-height: 100%;
    display: inline-block;
    position: absolute;
    @media (max-width: 402px) {
        display: none;
        
    }
`;

