import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/nomina/bono14';
import Bono from './Bono';

const ms2p = (state) => {
  return {
    ...state.calculo_bono,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Bono);