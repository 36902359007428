import React, { Component } from 'react';
import Grid from '../../Utils/GridProduccion';
import { standardActions } from '../../Utils/GridProduccion/StandardActions';

class DetalleIngresoMod extends Component{
    
    render(){
        const { loader, detalle, eliminarLineaProducto } = this.props;
        return(
            <React.Fragment>
                {detalle &&
                <Grid 
                    hover 
                    striped 
                    data={detalle} 
                    loading={loader}
                    footer
                    headerStyle={ { background: 'black' } } 
                >
                <TableHeaderColumn
                    isKey
                    dataField="cantidad"
                    dataAlign='right'
                    width="200"
                    headerAlign="center"
                    dataSort
                    dataFormat={(cell, row)=>{
                        return new Intl.NumberFormat("en-US",{minimumFractionDigits: 6}).format(cell);
                    }}
                    thStyle={
                        {color: 'white'}}
                    tdStyle={
                        {color: 'white'}}
                >
                    Cantidad
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="sku"
                    width="200"
                    dataSort
                    thStyle={
                        {color: 'white'}}
                    tdStyle={
                        {color: 'white'}}
                    >
                    SKU
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="productoN"
                    dataSort
                    thStyle={
                        {color: 'white'}}
                    tdStyle={
                        {color: 'white'}}
                    >
                    Producto
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="unidad"
                    dataAlign='right'
                    width="200"
                    headerAlign="center"
                    dataSort
                    dataFormat={(cell, row)=>{
                        return new Intl.NumberFormat("en-US",{minimumFractionDigits: 0}).format(cell);
                    }}
                    thStyle={
                        {color: 'white'}}
                    tdStyle={
                        {color: 'white'}}
                >
                    Unidad
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="faseN"
                    dataSort
                    thStyle={
                        {color: 'white'}}
                    tdStyle={
                        {color: 'white'}}
                    >
                    Fase
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="cantidad_insumo"
                    dataAlign='right'
                    width="200"
                    headerAlign="center"
                    dataSort
                    dataFormat={(cell, row)=>{
                        return new Intl.NumberFormat("en-US",{minimumFractionDigits: 0}).format(cell);
                    }}
                    thStyle={
                        {color: 'white'}}
                    tdStyle={
                        {color: 'white'}}
                >
                    Cajas
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="descripcionCaja"
                    width="200"
                    dataSort
                    thStyle={
                        {color: 'white'}}
                    tdStyle={
                        {color: 'white'}}
                    >
                    Descripción caja
                </TableHeaderColumn>
                <TableHeaderColumn
                    thStyle={
                    {color: 'white'}}
                    dataField="id"
                    width="150"
                    dataAlign="center"
                    dataSort
                    dataFormat={standardActions({
                    eliminar: eliminarLineaProducto })} 
                >
                    Acciones
                </TableHeaderColumn>
                </Grid>
                }
            </React.Fragment>
        );
    }
}

export default DetalleIngresoMod;
