import { NotificationManager } from "react-notifications";
import { api } from "api";

export const reporteAntiguedadDeSaldos = (data) => {
    let ruta = window.location.href;
    let empresa = ruta.split("/")[5];
    let reporte = "antiguedad_de_saldos";
    const params = { empresa, reporte, ...data };
    api.getPdf("/laboratorio/ingreso_de_patologia/reportes", params)
        .then((response) => {
            if (response.headers["content-type"] == "application/pdf") {
                var blob = new Blob([response.text], {
                    type: "application/pdf;charset=utf-8",
                });
                var link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = "antiguedad_de_saldos.pdf";
                window.open(link, "_blank");
                window.URL.revokeObjectURL(link);

                return false;
            }
        })
        .catch((error) => {
            if (error.status === 404) {
                NotificationManager.info(error.body.msj, "Informacion", 5000);
            }
            if (error.status === 500) {
                NotificationManager.error(
                    "Ocurrió un error al generar reporte",
                    "Error",
                    2000
                );
            }
        });
};
export const reporteEstadoDeCuenta = (data) => {
    let ruta = window.location.href;
    let empresa = ruta.split("/")[5];
    let reporte = "estado_de_cuenta";
    const params = { empresa, reporte, ...data };
    api.getPdf("/laboratorio/ingreso_de_patologia/reportes", params)
        .then((response) => {
            if (response.headers["content-type"] == "application/pdf") {
                var blob = new Blob([response.text], {
                    type: "application/pdf;charset=utf-8",
                });
                var link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = "estado_de_cuenta.pdf";
                window.open(link, "_blank");
                window.URL.revokeObjectURL(link);

                return false;
            }
        })
        .catch((error) => {
            if (error.status === 404) {
                NotificationManager.info(error.body.msj, "Informacion", 5000);
            }
            if (error.status === 500) {
                NotificationManager.error(
                    "Ocurrió un error al generar reporte",
                    "Error",
                    2000
                );
            }
        });
};

export const getClientes = (search) => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let empresa = id_emp[5];
    const params = { empresa, search };
    return api
        .get("laboratorio/clientes", params)
        .then((response) => {
            return response.map((item) => ({
                value: item.id,
                label: `${item.nombre}${item.afiliada ? " <Afiliada>" : ""}`,
            }));
        })
        .catch((error) => {
            return [];
        });
};
