import React, { Component } from 'react';
import Formulario from './PolizasForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";

class Polizas extends Component{
    state = {
        crear: true
    }

    componentDidMount = () => {
        const { listarCuentas, listarCC, match, leer, listarNiveles, 
            leerDetalle, getModulo, parametrosEmpresa, leerPeriodoD } = this.props;
        localStorage.removeItem('idPoliza');
        const id = match.params.id;
        if (id){
            this.setState({crear: false});
            leer(id);
        }else{
            listarCuentas();
            listarCC();
            listarNiveles();
            leerDetalle('00000000-0000-0000-0000-000000000000');
        }
        parametrosEmpresa();
        let ruta = window.location.href;
        let periodo = ruta.split('/');
        leerPeriodoD(periodo[5], periodo[7]);
        getModulo(periodo[5],periodo[4]);
    }

    render(){
        const { 
            registroPoliza, 
            modificarPoliza, 
            listarCuentas, 
            lectura, 
            loader, 
            listarCC, 
            registroPolizaEnc, 
            detalle, 
            eliminarLinea, 
            registroPolizaEncSCC, 
            nivel, 
            registroCuenta, 
            loaderC, 
            modulo,
            datos,
            periodoD,
            reporteCuentas } = this.props;
        const { crear } = this.state;
        const funcionEnvio = crear ? registroPoliza : modificarPoliza;
        return(
            <div>
                <LoadMask loading={loader} dark>
                    <Formulario
                        funcion = {funcionEnvio}
                        listarCuentas = { listarCuentas }
                        lectura = {lectura} 
                        crear = {crear}
                        listarCC = {listarCC}
                        registroEnc = {registroPolizaEnc}
                        detallePoliza = {detalle}
                        eliminarLinea = {eliminarLinea}
                        registroSCC = {registroPolizaEncSCC}
                        niveles = { nivel }
                        registroCuenta = {registroCuenta}
                        reporteCuentas = {reporteCuentas}
                        loaderC = {loaderC}
                        datos = {datos}
                        periodoD = {periodoD}
                        modulo = {modulo}
                    />
                </LoadMask>
            </div>
        );
    }
}

export default Polizas;
