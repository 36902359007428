import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import LoginForm from './LoginForm';
import LoadMask from "Utils/LoadMask/LoadMask";

class Login extends Component {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
    };

    render() {
        const { onSubmit, loader } = this.props;

        if (localStorage.getItem('token')) {
            return (<Redirect to="/" />);
        }
        const test = __CONFIG__.test;

        return (
            <section className="vh-100">
                <div className="container-fluid h-custom">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col-md-9 col-lg-6 col-xl-5">
                            <img className="img-fluid"
                                src={require('assets/img/SIFW-LOGO-Red.png')}
                                alt="Logo" />
                        </div>
                        <div className="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
                            <div className="d-flex flex-row align-items-center justify-content-center justify-content-lg-start titleLogin">
                                <h3>Iniciar sesión</h3>
                            </div>
                                <LoadMask loading={loader} dark>
                                    <LoginForm onSubmit={onSubmit} />
                                </LoadMask>
                            
                        </div>
                    </div>
                </div>
                <div
                    className="d-flex flex-column flex-md-row text-center text-md-start justify-content-between py-4 px-4 px-xl-5 bg-foo">
                    <div className="text-white mb-3 mb-md-0">
                        Copyright&copy; 2021 ACONSI - Todos los derechos reservados.
                    </div>
                    
                    <div>
                        <Link className="mr-3" to="/cambiar-password" style={{ textDecoration: 'none', color: 'blue' }}>¿Olvidó su contraseña?</Link>
                        {test === true &&
                             <span>¿Desea crear cuenta?&nbsp;<Link to="/registro" style={{ textDecoration: 'none', color: 'blue' }}>Registrate aquí</Link></span>
                        }
                    </div>
                </div>
            </section>
        );
    }
}

export default Login;
