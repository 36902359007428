import React, { Component } from 'react';
import Grid from '../../Utils/GridFiltro';
import PropTypes from 'prop-types';
import { standardActions } from '../../Utils/Grid/StandardActions';
import NavbarModulo from '../../components/NavbarModulo';
import NavbarModuloLF from '../../components/NavbarModuloFage';
import moment from 'moment';

class ColaboradorList extends Component{
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: true
    };

    componentDidMount = () => {
        const { listar, getModulo, parametrosEmpresa } = this.props;
        listar();
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        getModulo(modulo[5],modulo[4]);
        parametrosEmpresa();
    }
    render(){
        const { data, loader, modulo, datos } = this.props;
        let ruta = window.location.href;
        let datosRt = ruta.split('/');
        return(
            <React.Fragment>
                <NavbarModulo
                    pais = {datos.paisNombre}
                    modulo={modulo}
                />
                <br />
                <h3 className="p-2 bg-secondary title">Baja de Colaboradores</h3>
                <br />
                <div className="d-flex flex-row justify-content-start mb-2">
                    {datos.codigoPais  !== 'GTM' &&
                        <a
                            href={`/#/rr_hh/${datosRt[5]}/baja/${datosRt[7]}/crear`}
                            className='btn btn-primary'
                        >
                            Crear baja de colaborador
                        </a>
                    }
                </div>
                {data &&
                    <Grid 
                        hover 
                        striped 
                        data={data}
                        loading={loader} 
                        headerStyle={ { background: 'black' } } 
                        //onPageChange={onPageChange}
                        //onSortChange={onSortChange} 
                    >
                        <TableHeaderColumn
                            isKey
                            dataField="codigo"
                            width="150"
                            dataFormat={(cell, row)=>{
                                return new Intl.NumberFormat("en-US",{minimumFractionDigits: 0}).format(Math.abs(cell));
                            }}
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Código
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="nombre"
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Nombre
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="fecha_baja"
                            dataSort
                            width="150"
                            dataAlign="right"
                            dataFormat={(cell, row)=>{
                                return moment(cell).format("DD/MM/YYYY");
                            }}
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Fecha de baja
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataAlign="center"
                            dataField="activo"
                            width="80"
                            dataFormat={(cell, row)=>{
                                if (cell === true){
                                    return 'A';
                                }else{
                                    return 'I';
                                }
                            }}
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Estado
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            thStyle={
                            {color: 'white'}}
                            dataField="id"
                            width="150"
                            dataAlign="center"
                            dataSort
                            dataFormat={standardActions({ 
                                ver_a: "ingreso",
                                empresa: datosRt[5],
                                periodo: datosRt[7],
                                modulo: datosRt[4],
                            })} 
                                
                        >
                            Acciones
                        </TableHeaderColumn>
                    </Grid>
                }
            </React.Fragment>
        );
    }
}

export default ColaboradorList;
