import React, { useState, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Radio } from "react-aria-components";
import {
    FicImageDrop,
    FicComboBox,
    FicInput,
    FicRadio,
    FicCheckbox,
    FicTextArea,
    FicNumber,
} from "../../Utils/FormInputComponents/index";
import { useEffect } from "react";
import "../../../../../style/autoVentas/IngresosForm.css";
import GenBtn from "../../components/GenBtn";
import styled from "styled-components";
import DialogModal from "../../components/DialogModal";

const DialogContent = styled.div`
    height: 205px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;
`;

const schema = yup.object({
    codigo: yup.string().required("El codigo es requerido"),
    marca: yup.mixed().required("La marca es requerida"),
    linea: yup.mixed().required("La linea es requerida"),
    tipo_carro: yup.mixed().required("El tipo de carro es requerido"),
    combustible: yup.mixed().required("El combustible es requerido"),
    modelo: yup
        .number()
        .typeError("Debe de ser un numero")
        .positive("Debe de ser un numero positivo")
        .test(
            "len",
            "Debe de tener 4 digitos (ej. 2014)",
            (val) => val.toString().length === 4
        )
        .required("El modelo es requerido"),
    num_serie: yup.string().required("El numero de serie es requerido"),
    placa: yup.string().required("La placa es requerida"),
    motor: yup.string().required("El motor es requerido"),
    millas: yup
        .number()
        .typeError("Debe de ser un numero")
        .positive("Debe de ser un numero positivo")
        .required("Las millas son requeridas"),
    num_vin: yup.string().required("El numero de VIN es requerido"),
    num_chasis: yup.string().required("El numero de chasis es requerido"),
    num_motor: yup.string().required("El numero de motor es requerido"),
    color: yup.string().required("El color es requerido"),
    costo_vehiculo: yup
        .number()
        .typeError("Debe de ser un numero")
        .positive("Debe de ser un numero positivo")
        .required("El costo del vehiculo es requerido"),
    nombre_proveedor: yup.string().when("modalidad", {
        is: "CONSIGNACION",
        then: (field) => field.required("El nombre del proveedor es requerido"),
    }),
    num_telefono_proveedor: yup.string().when("modalidad", {
        is: "CONSIGNACION",
        then: (field) =>
            field.required("El numero de telefono del proveedor es requerido"),
    }),
});

function IngresosForm(props) {
    const {
        crearIngreso,
        listarMarcas,
        listarLineas,
        listarCombustibles,
        reporteRecepcionDeVehiculos,
        listarTipos,
    } = props;

    const {
        handleSubmit,
        reset,
        control,
        formState: { errors },
        watch,
        setValue,
    } = useForm({
        resolver: yupResolver(schema),
        mode: "all",
        defaultValues: {
            codigo: "",
            marca: null,
            linea: null,
            tipo_carro: null,
            combustible: null,
            modelo: "",
            num_serie: "",
            placa: "",
            motor: "",
            millas: "",
            num_vin: "",
            num_chasis: "",
            num_motor: "",
            color: "",
            costo_vehiculo: "",
            modalidad: "PROPIO",
        },
        // defaultValues:{ // valores de prueba
        //     nombre: "test",
        //     codigo: "2313",
        //     marca: null,
        //     linea: null,
        //     tipo_carro: null,
        //     combustible: null,
        //     modelo: "321",
        //     num_serie: "213",
        //     placa: "321",
        //     motor: "123",
        //     millas: '123',
        //     num_vin: "21231",
        //     num_chasis: '3213',
        //     num_motor: "4213",
        //     color: "42134",
        //     costo_vehiculo: "32132",
        //     modalidad: "PROPIO"
        // }
    });

    const [lineaKey, setLineaKey] = useState(1);
    const [ingresoId, setIngresoId] = useState("");
    const modalidad = watch("modalidad");
    const marca = watch("marca");
    const dialogRef = useRef(null);

    async function onSubmit(data) {
        await crearIngreso(data).then((data) => {
            setIngresoId(data.id);
            if (data) {
                dialogRef.current.showModal();
                reset();
            }
        });
    }

    useEffect(() => {
        setValue("linea", null);
        setLineaKey(lineaKey + 1); // cambia el 'key' del select para que se actualice
    }, [marca]);

    return (
        <React.Fragment>
            <br />
            <h3
                className="p-2  title rounded shadow"
                style={{
                    background:
                        "linear-gradient(to top right, #790021, #2d2d2d)",
                }}
            >
                Crear ingreso - Vehiculos
            </h3>
            <br />
            <div className="d-flex flex-row justify-content-start mb-2">
                <GenBtn
                    BtnColor="#787878"
                    style={{ width: "100px", height: "40px" }}
                    onClick={() => {
                        location.href = location.href.slice(0, -6);
                    }}
                >
                    Regresar
                </GenBtn>
            </div>
            <form
                encType="multipart/form-data"
                onSubmit={handleSubmit(onSubmit)}
                className="form-container"
            >
                <div className="propio-data-container">
                    <div className="modalidad-imagen-container">
                        <Controller
                            name="foto"
                            control={control}
                            render={({ field: { onChange } }) => (
                                <FicImageDrop handleFileChange={onChange} />
                            )}
                        />
                        <div className="modalidad-radio-container">
                            <div className="modalidad-radio-title">
                                Modalidad
                            </div>
                            <FicRadio control={control} name="modalidad">
                                <Radio value="PROPIO">Propio</Radio>
                                <Radio value="CONSIGNACION">Consignación</Radio>
                            </FicRadio>
                        </div>
                    </div>
                    <div className="inputs-container">
                        <FicInput
                            label="Codigo"
                            name="codigo"
                            // placeholder="Codigo"
                            control={control}
                            allCaps
                        />
                        <FicComboBox
                            label="Marca"
                            name="marca"
                            control={control}
                            loadOptions={listarMarcas}
                            // placeholder="Marca..."
                            // onChange={handleMarcaChange}
                        />
                        <FicComboBox
                            label="Linea"
                            key={lineaKey}
                            name="linea"
                            control={control}
                            loadOptions={(search) =>
                                listarLineas(search, marca && marca.value)
                            }
                            // placeholder="Linea..."
                        />
                        <FicComboBox
                            label="Tipo de carro"
                            name="tipo_carro"
                            control={control}
                            loadOptions={listarTipos}
                            // placeholder="Tipo..."
                        />
                        <FicComboBox
                            label="Combustible"
                            name="combustible"
                            control={control}
                            loadOptions={listarCombustibles}
                            // placeholder="Combustible..."
                        />
                        <FicNumber
                            formatOptions={{
                                maximumFractionDigits: 0,
                            }}
                            label="Modelo"
                            name="modelo"
                            // placeholder="Modelo"

                            control={control}
                        />
                        <FicInput
                            label="Numero de serie"
                            name="num_serie"
                            // placeholder="Serie"
                            control={control}
                        />
                        <FicInput
                            label="Placa"
                            name="placa"
                            // placeholder="Placa"

                            control={control}
                        />
                        <FicInput
                            label="Motor"
                            name="motor"
                            // placeholder="Motor"
                            control={control}
                        />
                        <FicNumber
                            formatOptions={{
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 1,
                            }}
                            label="Millas"
                            name="millas"
                            // placeholder="Millas"

                            control={control}
                        />
                        <FicInput
                            label="Numero de VIN"
                            name="num_vin"
                            // placeholder="Numero de VIN"
                            control={control}
                        />
                        <FicInput
                            label="Numero de chasis"
                            name="num_chasis"
                            // placeholder="Numero de chasis"
                            control={control}
                        />
                        <FicInput
                            label="Numero de motor"
                            name="num_motor"
                            // placeholder="Numero de motor"
                            control={control}
                        />
                        <FicInput
                            label="Color"
                            name="color"
                            // placeholder="Color"
                            control={control}
                        />
                        <FicNumber
                            formatOptions={{
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }}
                            label="Costo de vehiculo"
                            name="costo_vehiculo"
                            // placeholder="Ingrese el numero de telefono"
                            control={control}
                        />
                    </div>
                </div>
                {modalidad == "CONSIGNACION" && (
                    <div className="consignacion-data-container">
                        <div className="datos-proveedor-container">
                            <h4>Datos del proveedor</h4>
                            <div className="datos-proveedor">
                                <FicInput
                                    name="nombre_proveedor"
                                    placeholder="Nombre"
                                    control={control}
                                />
                                <FicInput
                                    name="num_telefono_proveedor"
                                    placeholder="Numero de telefono"
                                    control={control}
                                />
                            </div>
                        </div>
                        <div className="data-wrapper">
                            <div className="c-card-container">
                                <div className="c-card">
                                    <div className="c-card-header">
                                        DOCUMENTOS
                                    </div>
                                    <div className="c-card-body">
                                        <FicCheckbox
                                            control={control}
                                            name="calcomania"
                                            label="Calcomania"
                                        />
                                        <FicCheckbox
                                            control={control}
                                            name="titulo_propiedad"
                                            label="Titulo propiedad"
                                        />
                                        <FicCheckbox
                                            control={control}
                                            name="tajeta_circulacion"
                                            label="Tarjeta de circulacion"
                                        />
                                    </div>
                                </div>
                                <div className="c-card">
                                    <div className="c-card-header">
                                        EQUIPO DE EMERGENCIAS
                                    </div>
                                    <div className="c-card-body">
                                        <FicCheckbox
                                            control={control}
                                            name="botiquin"
                                            label="Botiquín"
                                        />
                                    </div>
                                </div>
                                <div className="c-card">
                                    <div className="c-card-header">
                                        HERRAMIENTAS
                                    </div>
                                    <div className="c-card-body">
                                        <FicCheckbox
                                            control={control}
                                            name="triangulos_reflectores"
                                            label="Triangulos reflectores"
                                        />
                                        <FicCheckbox
                                            control={control}
                                            name="triket"
                                            label="Triket"
                                        />
                                        <FicCheckbox
                                            control={control}
                                            name="caja_de_herramientas"
                                            label="Caja de herramientas"
                                        />
                                        <FicCheckbox
                                            control={control}
                                            name="llave_chuchos"
                                            label="Llave de chuchos o cruz"
                                        />
                                        <FicCheckbox
                                            control={control}
                                            name="llanta_de_repuesto"
                                            label="Llanta de repuesto"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="observaciones-container">
                                <h4>Observaciones</h4>
                                <FicTextArea
                                    name="observaciones"
                                    control={control}
                                />
                            </div>
                        </div>
                    </div>
                )}
                <GenBtn
                    type="submit"
                    className="guardar-ingreso-btn"
                    style={{ marginTop: "15px" }}
                >
                    Guardar
                </GenBtn>
            </form>

            <DialogModal dialogRef={dialogRef} noHeader={true}>
                <DialogContent>
                    <div
                        style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "#bd0034",
                        }}
                    >
                        Desea generar el documento de ingreso?
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-around",
                            marginTop: "15px",
                            width: "100%",
                        }}
                    >
                        <GenBtn
                            textColor="hsl(4, 100%, 39%)"
                            background="hsla(4, 100%, 39%, 0.2)"
                            onPress={() => {
                                dialogRef.current.close();
                            }}
                        >
                            Cerrar
                        </GenBtn>
                        <GenBtn
                            onPress={() => {
                                reporteRecepcionDeVehiculos(ingresoId);
                                setTimeout(() => {
                                    dialogRef.current.close();
                                }, 3000);
                            }}
                            autofocus="autofocus"
                        >
                            Generar
                        </GenBtn>
                    </div>
                </DialogContent>
            </DialogModal>
        </React.Fragment>
    );
}

export default IngresosForm;
