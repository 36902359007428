import React, { useState } from "react";
import "./FicFileDrop.css";
import * as Fa from "react-icons/fa";
import { useController } from "react-hook-form";

const FileInput = ({
    name,
    control,
    nonForm,
    errorMsj,
    labelText,
    style,
    onChange,
    ...props
}) => {
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setSelectedFile(file);
    };

    if (nonForm) {
        return (
            <React.Fragment>
                <label
                    htmlFor="file-upload"
                    className="custom-file-upload"
                    style={style}
                >
                    <Fa.FaFileUpload />
                    &nbsp;
                    {selectedFile
                        ? selectedFile.name
                        : labelText
                        ? labelText
                        : "Subir archivo"}
                    <input
                        id="file-upload"
                        onChange={(e) => {
                            handleFileChange(e);
                            onChange(e.target.files[0]);
                        }}
                        {...props}
                    />
                </label>
                {errorMsj && (
                    <span className="input-invalid-error-msj">{errorMsj}</span>
                )}
            </React.Fragment>
        );
    }

    const { field, fieldState } = useController({ name, control });

    const { onChange: fieldOnChange, value, ...rest } = field;

    return (
        <React.Fragment>
            <label
                htmlFor="file-upload"
                className="custom-file-upload"
                style={style}
            >
                <Fa.FaFileUpload />
                &nbsp;
                {field.value
                    ? field.value.name
                    : labelText
                    ? labelText
                    : "Subir archivo"}
                <input
                    id="file-upload"
                    {...rest}
                    onChange={(e) => {
                        setSelectedFile(e.target.files[0]);
                        fieldOnChange(e.target.files[0]);
                        onChange ? onChange(e) : "";
                    }}
                    {...props}
                />
            </label>
            {fieldState.error && (
                <span className="input-invalid-error-msj">
                    {fieldState.error.message}
                </span>
            )}
        </React.Fragment>
    );
};

export default FileInput;
