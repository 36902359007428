import React from "react";
import {
    Button,
    Dialog,
    DialogTrigger,
    Heading,
    Modal,
} from "react-aria-components";
import "./FicModal.css";
import GenBtn from "../../components/GenBtn";

export default function FicModal({
    triggerText,
    triggerButton,
    children,
    ...props
}) {
    const title = props.title ? props.title : "";
    return (
        <DialogTrigger>
            {triggerButton ? triggerButton : <GenBtn>{triggerText}</GenBtn>}
            <Modal {...props}>
                <Dialog className="fic-dialog">
                    {({ close }) => (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                position: "relative",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    gap: "2rem",
                                    padding: "0.5rem 1rem",
                                    borderBottom: "1px solid #bd0034",
                                    minWidth: "400px",
                                }}
                            >
                                <Heading slot="title">{title}</Heading>
                                <GenBtn
                                    style={{
                                        position: "absolute",
                                        top: "2%",
                                        right: "2%",
                                        border: "none",
                                        background: "transparent",
                                        width: "15px",
                                        height: "25px",
                                        fontSize: "18px",
                                    }}
                                    onPress={close}
                                    textColor="hsl(0, 100%, 50%)"
                                >
                                    &times;
                                </GenBtn>
                            </div>
                            <div
                                style={{
                                    padding: "1rem",
                                }}
                            >
                                {children}
                            </div>
                        </div>
                    )}
                </Dialog>
            </Modal>
        </DialogTrigger>
    );
}
