import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';
const LOADER = 'LOADER';

// ------------------------------------
// Pure Actions
// ------------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

// ------------------------------------
// Actions
// ------------------------------------

export const onSubmit = (term) => (dispatch, getStore) => {
    const formData = getStore().form.register.values;
    let ruta = window.location.href;
    let url = ruta.split('/');
    let urlToken = url[0]+'/'+url[1]+'/'+url[2]+'/';
    if (term === false){
        Swal.fire({
            title: 'Registro de cuenta',
            text: 'Debe de aceptar los términos y condiciones',
            type: 'error',
        })
    }else{
        const data = {
            first_name: formData.first_name,
            last_name: formData.last_name,
            username: formData.username,
            password: formData.password,
            url: urlToken
        }
        dispatch(setLoader(true))
        api.post('user', data).then(() => {
            NotificationManager.success('Cuenta creada con éxito, debe de activar su cuenta para iniciar sesión', 'Éxito', 15000);
            dispatch(push("/login"));
        }).catch((error) => {
            const jsonString = error.msj.replace(/'/g, '"');
            const texto = jsonString.match(/string="([^"]*)"/);
            NotificationManager.error(texto[1], 'ERROR', 5000);
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
    
};

export const onSubmitR = (term) => (dispatch, getStore) => {
    const formData = getStore().form.registerR.values;
    let ruta = window.location.href;
    let url = ruta.split('/');
    let urlToken = url[0]+'/'+url[1]+'/'+url[2]+'/';
    if (term === false){
        Swal.fire({
            title: 'Registro de cuenta',
            text: 'Debe de aceptar los términos y condiciones',
            type: 'error',
        })
    }else{
        const data = {
            first_name: formData.first_name,
            last_name: formData.last_name,
            username: formData.username,
            password: formData.password,
            url: urlToken
        }
        dispatch(setLoader(true))
        api.post('user', data).then(() => {
            NotificationManager.success('Cuenta creada con éxito, debe de activar su cuenta para iniciar sesión', 'Éxito', 15000);
            localStorage.removeItem('token');
            dispatch(push("/login"));
        }).catch((error) => {
            const jsonString = error.msj.replace(/'/g, '"');
            const texto = jsonString.match(/string="([^"]*)"/);
            NotificationManager.error(texto[1], 'ERROR', 5000);
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
    
};



const activar = () => () => {
    let ruta = window.location.href;
    let url = ruta.split('/');
    let id = url[5];
    let token = url[6].split('=');
    const data = {
        token: token[1],
        id: id
    }
    api.put('/user/activar_user/', data).then(() => {
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al activar usuario',
            'Error',
            0
        );
    })
}


export const logOut = () => () => {
    localStorage.removeItem('token');
};


export const actions = {
    onSubmit,
    logOut,
    activar,
    onSubmitR
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
};

export const initialState = {
    loader: false,
};

export default handleActions(reducers, initialState);
