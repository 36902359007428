import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';
import moment from 'moment';

const LISTADO_DISTRIBUCION = 'LISTADO_DISTRIBUCION';
const LOADER = 'LOADER';
const MODULO = 'MODULO';
const PERIODO_D = 'PERIODO_D';
const DETALLE = 'DETALLE';
const DETALLE_INSUMOS = 'DETALLE_INSUMOS';
const CORRELATIVO = 'CORRELATIVO';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let periodo = id_emp[7];
    let motivo = 'TRAS';
    const params = { id, periodo, motivo };
    dispatch(setLoader(true));
    api.get('/movimiento_insumos', params).then((response) => {
        dispatch({ type: LISTADO_DISTRIBUCION, data: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar distribuciones',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/movimiento_insumos/${id}`).then((response) => {
        response.fechaRegistro = response.fecha;
        response.descripcion = response.observaciones;
        localStorage.setItem('id_detalle_distribucion', response.id);
        dispatch(initializeForm('FormDistribucionCajasProduccion', response));
        dispatch(leerDetalleDistribucion(response.id));
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar distribución',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = { id, modulo }
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response) => {
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const leerPeriodoD = (id, id_periodo) => (dispatch) => {
    const params = { id, id_periodo };
    api.get('/periodos/periodo', params).then((response) => {
        dispatch({ type: PERIODO_D, periodoD: response.periodo });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar periodo',
            'Error',
            2000
        );
    })
}

export const numeroDistribucion = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let tipo = 'TRAS'
    const params = { id, tipo };
    api.get('/movimiento_insumos/correlativo', params).then((response) => {
        let numero = parseInt(response.numero)+1
        dispatch({ type: CORRELATIVO, numeroDist: numero })
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar correlativo',
            'Error',
            2000
        );
    })
}

export const listarInvernaderos = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/invernaderos', params).then(data => {
        const list_fase = [];
        if (data) {
            data.forEach(item => {
                list_fase.push({
                    value: item.id,
                    label: item.nombre
                })
            })
        }
        return list_fase;
    }).catch((error) => {
        return [];
    })
}

export const registroDistribucion = (periodoD, numeroS) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormDistribucionCajasProduccion.values;

    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fecha)
    if (fecha >= fechaI && fecha <= fechaF) {
        const formData = {
            fecha: data.fecha,
            numero: numeroS,
            descripcion: data.descripcion,
            cliente: '',
            piloto: '',
            detalleDev: data.detalleD,
            motivo: 'TRAS',
            empresa: id_emp[5],
            periodo: id_emp[7]
        }
        dispatch(setLoader(true));
        api.post('/movimiento_insumos/', formData).then(() => {
            NotificationManager.success(
                'Distribución de cajas guardado correctamente',
                'Exito',
                3000
            );
            let ruta = `/produccion/${id_emp[5]}/distribucion_cajas/${id_emp[7]}`
            dispatch(push(ruta));
        }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                0
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });


    } else {
        Swal.fire({
            title: 'Distribución de cajas',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })
    }
}

export const modificarDistribucion = (periodoD) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormDistribucionCajasProduccion.values;

    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fecha);
    let id = data.id;
    if (fecha >= fechaI && fecha <= fechaF) {
        const formData = {
            id: id,
            descripcion: data.descripcion,
            fecha: data.fecha,
            cliente: '',
            piloto: ''
        }
        dispatch(setLoader(true));
        api.put(`/movimiento_insumos/${id}/`, formData).then(() => {
            NotificationManager.success(
                'Distribución de cajas modificado correctamente',
                'Exito',
                3000
            );
            let ruta = `/produccion/${id_emp[5]}/distribucion_cajas/${id_emp[7]}`
            dispatch(push(ruta));
        }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                0
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    } else {
        Swal.fire({
            title: 'Distribución de cajas',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })
    }
}

export const nuevaLineaInsumo = () => (dispatch, getStore) => {
    const data = getStore().form.FormDistribucionCajasProduccion.values;
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const detalle = localStorage.getItem('id_detalle_distribucion');
    if (data.cantidad === undefined || parseInt(data.cantidad) === 0) {
        Swal.fire({
            title: 'Distribución de cajas',
            text: 'Debe de ingresar cantidad',
            type: 'error',
        })
    } else {
        if (data.insumo === undefined || data.insumo === null) {
            Swal.fire({
                title: 'Distribución de cajas',
                text: 'Debe de seleccionar insumo',
                type: 'error',
            })
        } else {
            if (data.invernadero === undefined || data.invernadero === null) {
                Swal.fire({
                    title: 'Distribución de cajas',
                    text: 'Debe de seleccionar invernadero',
                    type: 'error',
                })
            } else {
                let datos_i = data.insumo.value;
                let datos_insumo = datos_i.split('#');
                let exis = parseInt(datos_insumo[1].trim())
                if (parseFloat(data.cantidad)>exis){
                    Swal.fire({
                        title: 'Distribución de cajas',
                        text: 'Cantidad ingresada es mayor a la existencia de cajas',
                        type: 'error',
                    })
                }else{
                    const formData = {
                        cantidad: data.cantidad,
                        idInsumo: datos_insumo[0],
                        idInvernadero: data.invernadero.value,
                        detalle: detalle,
                        empresa: id_emp[5]
                    }
                    dispatch(setLoader(true));
                    api.post('/movimiento_insumos/', formData).then((response) => {
                        response.fecha = response.encabezado.fecha;
                        response.numero = response.encabezado.numero;
                        response.descripcion = response.encabezado.observaciones;
                        response.id = response.encabezado.id;
                        dispatch(initializeForm('FormDistribucionCajasProduccion', response));
                        dispatch(leerDetalleDistribucion(response.idDetalle));
                        
                        NotificationManager.success(
                            'Insumo ingresado correctamente',
                            'Exito',
                            3000
                        );
                    }).catch((error) => {
                        NotificationManager.error(
                            error.msj,
                            'Error',
                            2000
                        );
                    }).finally(() => {
                        dispatch(setLoader(false));
                    });
                }
            }
        }
    }

}

const eliminarLineaInsumo = id => (dispatch) => {
    const detalle = localStorage.getItem('id_detalle_distribucion');
    dispatch(setLoader(true));
    api.eliminar(`/movimiento_insumos_detalle/${id}`).then(() => {
        dispatch(leerDetalleDistribucion(detalle));
        dispatch(existenciaBodega());
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar el insumo',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

let detalle = [];
export const agregarLinea = (periodoD) => (dispatch, getStore) => {
    const data = getStore().form.FormDistribucionCajasProduccion.values;
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fecha);
    if (fecha >= fechaI && fecha <= fechaF) {
        if (data.cantidad === undefined || parseInt(data.cantidad) === 0) {
            Swal.fire({
                title: 'Distribución de cajas',
                text: 'Debe de ingresar cantidad',
                type: 'error',
            })
        } else {
            if (data.insumo === undefined || data.insumo === null) {
                Swal.fire({
                    title: 'Distribución de cajas',
                    text: 'Debe de seleccionar insumo',
                    type: 'error',
                })
            } else {
                if (data.invernadero === undefined || data.invernadero === null) {
                    Swal.fire({
                        title: 'Distribución de cajas',
                        text: 'Debe de seleccionar invernadero',
                        type: 'error',
                    })
                } else {
                    let datos_i = data.insumo.value;
                    let datos_insumo = datos_i.split('#');
                    let exis = parseInt(datos_insumo[1].trim())
                    if (parseFloat(data.cantidad)>exis){
                        Swal.fire({
                            title: 'Distribución de cajas',
                            text: 'Cantidad ingresada es mayor a la existencia de cajas',
                            type: 'error',
                        })
                    }else{
                        detalle.push({
                            id: datos_insumo[0] + '/' + data.invernadero.value + '/' + data.cantidad,
                            idInsumo: datos_insumo[0],
                            insumo: data.insumo.label,
                            invernadero: data.invernadero.value,
                            nombreInver: data.invernadero.label,
                            cantidad: data.cantidad,
                        })
                        data.cantidad = 0;
                        data.detalleD = detalle;
                        NotificationManager.success(
                            'Insumo ingresado correctamente',
                            'Exito',
                            3000
                        );
                        dispatch(actualizar());
                    }
                }
            }
        }
    } else {
        Swal.fire({
            title: 'Distribución de cajas',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })
    }
}


const eliminarLinea = (id) => (dispatch) => {
    let d = id.split('/');
    detalle.forEach((element, index) => {
        if (element.idInsumo === d[0] && element.invernadero === d[1] && element.cantidad === d[2]) {
            detalle.splice(index, 1);
        }
    })
    dispatch(actualizar());
};

const vaciar = () => (dispatch) => {
    detalle = [];
    dispatch(initializeForm('FormDevolucionCajasProduccion'));
    dispatch({ type: DETALLE, detallePro: detalle });
};

export const listarInsumos = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/insumo_prd/buscar_insumo_cajas', params).then(data => {
        const list_insumo = [];
        if (data) {
            data.forEach(item => {
                list_insumo.push({
                    value: item.id + '#' + item.cajas,
                    label: item.descripcion + ' ' + 'Existencia: ' + new Intl.NumberFormat("en-US", { minimumFractionDigits: 0 }).format(item.cajas)
                })
            })
        }
        return list_insumo;
    }).catch((error) => {
        return [];
    })
}

const actualizar = () => (dispatch, getStore) => {
    const data = getStore().form.FormDistribucionCajasProduccion.values;
    dispatch(initializeForm('FormDistribucionCajasProduccion', data));
    dispatch({ type: DETALLE, detallePro: data.detalleD })
};

const leerDetalleDistribucion = id => (dispatch) => {
    api.get(`/movimiento_insumos_detalle/${id}`).then((response) => {
        dispatch({ type: DETALLE_INSUMOS, detalleInsumo: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};

const eliminarDevolucion = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`/movimiento_insumos/${id}`).then(() => {
        dispatch(listar(1));
        NotificationManager.success(
            'Devolución borrado correctamente',
            'Exito',
            3000
        );
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar devolución',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const actions = {
    listar,
    leer,
    getModulo,
    leerPeriodoD,
    registroDistribucion,
    modificarDistribucion,
    agregarLinea,
    listarInvernaderos,
    eliminarLineaInsumo,
    nuevaLineaInsumo,
    eliminarLinea,
    vaciar,
    listarInsumos,
    actualizar,
    numeroDistribucion,
    eliminarDevolucion
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_DISTRIBUCION]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [PERIODO_D]: (state, { periodoD }) => {
        return {
            ...state,
            periodoD,
        };
    },
    [DETALLE]: (state, { detallePro }) => {
        return {
            ...state,
            detallePro,
        };
    },
    [DETALLE_INSUMOS]: (state, { detalleInsumo }) => {
        return {
            ...state,
            detalleInsumo,
        };
    },
    [CORRELATIVO]: (state, { numeroDist }) => {
        return {
            ...state,
            numeroDist,
        };
    },
};

export const initialState = {
    loader: false,
    modulo: [],
    periodoD: [],
    detallePro: [],
    detalleInsumo: [],
    data: [],
    page: 1,
    numeroDist: 0
};

export default handleActions(reducers, initialState)
