import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import { validate, validators } from 'validate-redux-form';
import PropTypes from 'prop-types';
import NavbarModulo from '../../components/NavbarModulo';
import {
    renderField,
    renderNumber,
    SelectField
} from "../../Utils/renderField/renderField";

function SubProyectosForm(props) {
    const { handleSubmit, crear, obtenerProyectos, lectura, modulo } = props;
    const editar = window.location.href.includes('editar');
    let titulo = editar ? 'Modificar Subproyecto' : 'Crear Subproyecto';
    let disabled = false;
    if (crear === false && editar === false) {
        disabled = true;
        titulo = 'Ver Subproyecto'
    }
    let autoFocus = true;
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    return (
        <div>
            <NavbarModulo
                modulo={modulo}
                titulo={titulo}
            />
            <br />
            <div className="mb-4 card card-small text-white bg-dark mb-3">
                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                    <div className="d-flex flex-column flex-1 mx-4">
                        <div className='mb-2'>
                            <div className='mb-2 w-25'>
                            {obtenerProyectos &&
                                <div className="form-floating">
                                    <Field
                                        autoFocus={autoFocus}
                                        name="proyecto"
                                        options={obtenerProyectos}
                                        crear={crear}
                                        dato={crear === true ? 0 : lectura.proyecto}
                                        component={SelectField}
                                        className="form-select"
                                        disabled={disabled}
                                        msj="Proyecto"
                                    />
                                </div>
                            }
                            </div>
                            <div className='mb-2 w-25'>
                                <Field
                                    name="codigo"
                                    placeholder="Codigo"
                                    className="form-control"
                                    component={renderNumber}
                                    disabled={editar ? true : disabled}
                                    msj="Código"
                                />
                                    
                            </div>

                            <div className='mb-2 w-50'>
                                <Field
                                    name="subproyecto"
                                    placeholder="Subproyecto"
                                    className="form-control"
                                    component={renderField}
                                    disabled={disabled}
                                    msj="Subproyecto"
                                />
                            </div>

                            <div className='mb-2 w-50'>
                                <Field
                                    name="descripcion"
                                    placeholder="Descripción"
                                    className="form-control"
                                    component={renderField}
                                    disabled={disabled}
                                    msj="Descripción"
                                />
                            </div>
                            <div className='d-flex flex-row mt-3'>
                                {disabled == false &&
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={handleSubmit}
                                    >
                                        {editar ? 'Modificar' : 'Guardar'}
                                    </button>
                                }
                                <a
                                    href={`/#/inmobiliaria/${id_emp[5]}/subproyectos`}
                                    className='btn btn-secondary mb-3'
                                >
                                    Regresar
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

SubProyectosForm.propTypes = {
    handleSubmit: PropTypes.func,
    crear: PropTypes.bool,
    lectura: PropTypes.object,
    obtenerProyectos: PropTypes.array,
    modulo: PropTypes.object
};

export default reduxForm({
    form: 'FormSubProyecto',
    validate: (data) => {
        return validate(data, {
            codigo: validators.exists()('Este campo es requerido'),
            proyecto: validators.exists()('Este campo es requerido'),
            subproyecto: validators.exists()('Este campo es requerido'),
        });
    },
})(SubProyectosForm);
