import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';

import {
    renderField,
    renderDayPickerB,
    SelectField,
    renderCurrencyFloat,
    renderCurrencyFloatG,
    renderFieldCheck,
} from "../../Utils/renderField/renderField";

function FacturaForm(props) {
    const {
        crear,
        lectura,
        bloqueo,
        editar,
        tipos,
    } = props;

    return (
        <div>
            <div className='row mb-2'>
                {((crear === false && editar == false)) &&
                    <div className='col-3'>
                        <Field
                            name="nit_proveedor"
                            placeholder="Identificador"
                            className="form-control"
                            component={renderField}
                            disabled={true}
                            msj="Identificador"
                        />
                    </div>
                }
                {((crear === false && editar == false)) &&

                    <div className='col-6'>
                        <Field
                            name="proveedorN"
                            placeholder="Proveedor"
                            className="form-control"
                            component={renderField}
                            disabled={true}
                            msj="Proveedor"
                        />
                    </div>
                }
            </div>
            <fieldset>
                <div className='row mb-2'>
                    {(crear === false && editar == false) &&
                        <div className='col-3'>
                            <Field
                                disabled={true}
                                name="en_dolares"
                                label="Compra en dolares"
                                component={renderFieldCheck}
                            />
                        </div>
                    }
                    {(crear === false && editar == false) &&
                        <div className='col-3'>
                            <Field
                                disabled={true}
                                name="afecta_iva"
                                label="Afecta iva"
                                component={renderFieldCheck}
                            />
                        </div>
                    }
                    {(crear === false && editar == false) &&
                        <div className='col-3'>
                            <Field
                                name="afecta_combustible"
                                disabled={true}
                                label="Combustible"
                                component={renderFieldCheck}
                            />
                        </div>
                    }
                </div>
            </fieldset>
            <div className='row mb-2'>
                {crear === false && editar == false &&
                    <div className='col-3'>
                        {tipos &&
                            <div className="form-floating">
                                <Field
                                    name="tipo"
                                    options={tipos}
                                    crear={crear}
                                    dato={lectura.tipo_documento}
                                    component={SelectField}
                                    className="form-select"
                                    disabled={bloqueo}
                                    msj="Tipo de documento"
                                />
                            </div>
                        }
                    </div>
                }

                <div className='col-3'>
                    <Field
                        disabled={bloqueo}
                        name="fecha"
                        component={renderDayPickerB}
                        msj="Fecha"
                    />
                </div>
                <div className='col-3'>
                    <Field
                        name="serie"
                        placeholder="Serie"
                        className="form-control"
                        component={renderField}
                        disabled={bloqueo}
                        msj="Serie"
                    />
                </div>
                <div className='col-3'>
                    <Field
                        name="numero"
                        placeholder="Numero"
                        className="form-control"
                        component={renderField}
                        disabled={bloqueo}
                        msj="Numero"
                    />
                </div>
            </div>
            {(crear === false && editar === false && lectura.afecta_combustible === true) &&
                <div>
                    <div className='row mb-2'>
                        <div className='col-3'>
                            <div className="form-floating">
                                <Field
                                    name="g_super"
                                    placeholder="Galones super"
                                    className="form-control"
                                    component={renderCurrencyFloatG}
                                    disabled={true}
                                    msj="Galones super"
                                />
                            </div>
                        </div>
                        <div className='col-3'>
                            <div className="form-floating">
                                <Field
                                    name="c_super"
                                    placeholder="Costo"
                                    className="form-control"
                                    component={renderCurrencyFloatG}
                                    disabled={true}
                                    msj="Costo"
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-3'>
                            <div className="form-floating">
                                <Field
                                    name="g_regular"
                                    placeholder="Galones regular"
                                    className="form-control"
                                    component={renderCurrencyFloatG}
                                    disabled={true}
                                    msj="Galones Regular"
                                />
                            </div>
                        </div>
                        <div className='col-3'>
                            <div className="form-floating">
                                <Field
                                    name="c_regular"
                                    placeholder="Costo"
                                    className="form-control"
                                    component={renderCurrencyFloatG}
                                    disabled={true}
                                    msj="Costo"
                                />
                            </div>
                        </div>
                        <div className='col-3'>
                            <div className="form-floating">
                                <Field
                                    name="otros"
                                    placeholder="Otros"
                                    className="form-control"
                                    component={renderCurrencyFloat}
                                    disabled={true}
                                    msj="Otros"
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-3'>
                            <div className="form-floating">
                                <Field
                                    name="c_diesel"
                                    placeholder="Galones diesel"
                                    className="form-control"
                                    component={renderCurrencyFloatG}
                                    disabled={true}
                                    msj="Galones diesel"
                                />
                            </div>
                        </div>
                        <div className='col-3'>
                            <div className="form-floating">
                                <Field
                                    name="c_diesel"
                                    placeholder="Costo"
                                    className="form-control"
                                    component={renderCurrencyFloatG}
                                    disabled={true}
                                    msj="Costo"
                                />
                            </div>
                        </div>
                        <div className='col-3'>
                            <div className="form-floating">
                                <Field
                                    name="idp"
                                    placeholder="IDP"
                                    className="form-control"
                                    component={renderCurrencyFloat}
                                    disabled={true}
                                    msj="IDP"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }

            {lectura.afecta_combustible === false &&
                <div className='row mb-2'>
                    <div className='col-4'>
                        <Field
                            name="bienes"
                            placeholder="Bienes"
                            className="form-control"
                            component={renderCurrencyFloat}
                            disabled={bloqueo}
                            msj="Bienes"
                        />
                    </div>
                    <div className='col-4'>
                        <Field
                            name="servicios"
                            placeholder="Servicios"
                            className="form-control"
                            component={renderCurrencyFloat}
                            disabled={bloqueo}
                            msj="Servicios"
                        />
                    </div>
                    <div className='col-4'>
                        <Field
                            name="exento"
                            placeholder="No afecto"
                            className="form-control"
                            component={renderCurrencyFloat}
                            disabled={bloqueo}
                            msj="No afecto"
                        />
                    </div>
                </div>
            }
            {crear === false && editar === false &&
                <div className='row mb-2'>
                    <div className='col-4'>
                        <Field
                            name="base"
                            placeholder="Total Liquidacion"
                            className="form-control"
                            component={renderCurrencyFloat}
                            disabled={true}
                            msj="Base"
                        />
                    </div>
                    <div className='col-4'>
                        <Field
                            name="iva"
                            placeholder="Iva"
                            className="form-control"
                            component={renderCurrencyFloat}
                            disabled={true}
                            msj="Iva"
                        />
                    </div>
                    <div className='col-4'>
                        <Field
                            name="total"
                            placeholder="Total"
                            className="form-control"
                            component={renderCurrencyFloat}
                            disabled={true}
                            msj="Total"
                        />
                    </div>
                </div>
            }
            {(crear === true || editar == true) &&
                <div className='row mb-2'>
                    <div className='col-4'>
                        <Field
                            name="base"
                            placeholder="Base"
                            className="form-control"
                            component={renderCurrencyFloat}
                            disabled={bloqueo}
                            msj="Base"
                        />
                    </div>
                    <div className='col-4'>

                        <Field
                            name="iva"
                            placeholder="Iva"
                            className="form-control"
                            component={renderCurrencyFloat}
                            disabled={bloqueo}
                            msj="Iva"
                        />
                    </div>
                    <div className='col-4'>
                        <Field
                            name="total"
                            placeholder="Total"
                            className="form-control"
                            component={renderCurrencyFloat}
                            disabled={bloqueo}
                            msj="Total"
                        />
                    </div>
                </div>
            }
            <div className='mb-2'>
                <Field
                    id="descripcion"
                    name="descripcion"
                    placeholder="Descripcion"
                    component={renderField}
                    className="form-control"
                    disabled={bloqueo}
                    msj="Descripcion"
                />
            </div>
        </div>
    );
}

FacturaForm.propTypes = {
    listarProveedores: PropTypes.object,
    crear: PropTypes.bool,
    lectura: PropTypes.object,
    tipos: PropTypes.object,
    editar: PropTypes.bool,
    bloqueo: PropTypes.bool,
    listarProveedoresD: PropTypes.object,
    registroProveedor: PropTypes.func,
};

export default reduxForm({
    form: 'FormConsultaFactura',
})(FacturaForm);
