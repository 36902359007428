import React from "react";
import FicModal from "../../../Utils/FormInputComponents/FicModal";
import {
    FicDateRangePicker,
    FicDatePicker,
    FicComboBox,
} from "../../../Utils/FormInputComponents";
import GenBtn from "../../../components/GenBtn";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { reporteEstadoDeCuenta } from "./actions";
import { getClientes } from "./actions";
import InfoPopover from "../../../components/Popover";

const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const ButtonContainer = styled.div`
    display: flex;
    width: 100%;
    gap: 2rem;

    & > :nth-child(1) {
        flex-grow: 4;
    }
`;

const schema = yup.object({});

export default function Modal() {
    const { control, reset, handleSubmit } = useForm({
        mode: "onSubmit",
        resolver: yupResolver(schema),
        defaultValues: {
            fechaAl: null,
            cliente: null,
        },
    });

    const onSubmit = (formData) => {
        let data = {};
        if (
            formData.rangoFechas &&
            Object.keys(formData.rangoFechas).length !== 0
        ) {
            const fecha_inicial = formData.rangoFechas.start;
            const fecha_final = formData.rangoFechas.end;
            data = { fecha_inicial, fecha_final };
        }
        if (formData.cliente) {
            data.cliente = formData.cliente.value;
        }
        reporteEstadoDeCuenta(data);
        reset();
    };

    return (
        <FicModal title="Estado de cuenta" triggerText="Estado de cuenta">
            <Form onSubmit={handleSubmit(onSubmit)}>
                <FicComboBox
                    componentStyle={{ flexGrow: "1", maxWidth: "400px" }}
                    control={control}
                    label="Cliente"
                    name="cliente"
                    loadOptions={getClientes}
                />
                <FicDateRangePicker
                    label="Rango de fechas"
                    control={control}
                    name="rangoFechas"
                />
                <ButtonContainer>
                    <GenBtn type="submit">Generar</GenBtn>
                    <InfoPopover style={{ maxWidth: "450px" }}>
                        <span>
                            <strong style={{ fontWeight: "bold" }}>
                                Cliente (opcional):
                            </strong>{" "}
                            Seleccionar un agente para filtrar por un solo
                            agente, dejar en blanco para mostrar todos.
                            <hr />
                            <strong style={{ fontWeight: "bold" }}>
                                Rango de fechas (opcional):
                            </strong>{" "}
                            Seleccionar un rango de fechas o dejar en blanco
                            para mostrar todos.
                        </span>
                    </InfoPopover>
                </ButtonContainer>
            </Form>
        </FicModal>
    );
}
