import React from "react";
import styled from "styled-components";

const BannerH1 = styled.h1`
    background: linear-gradient(to top right, #790021, #2d2d2d);
    color: white;
    margin: 5vmin 0;
    text-align: center;
    padding: 0.525rem;
    border-radius: 5px;
`;

export default function Banner({ children }) {
    return <BannerH1>{children}</BannerH1>;
}
