import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
    FicInput,
    FicComboBox,
    FicNumber,
    FicDatePicker,
    FicTextArea,
} from "../../Utils/FormInputComponents";
import Banner from "../common/Banner";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { validar } from "../../components/ValidarNit";
import GenBtn from "../../components/GenBtn";
import InputsContainer from "../common/InputsContainer";
import { getEntry, editar } from "./actions";
import { withRouter } from "react-router";
import LoadMask from "../../Utils/LoadMask/LoadMask";
import {
    today,
    getLocalTimeZone,
    parseDate,
    startOfMonth,
    endOfMonth,
    CalendarDate,
} from "@internationalized/date";

const idName = "ingresoId";

const schema = yup.object({});

function Ver({ history, ...props }) {
    const [isLoading, setIsLoading] = useState(false);
    const params = props.match.params;
    const entryId = params[idName];

    const { control, reset, handleSubmit, watch, setValue, formState } =
        useForm({
            mode: "onChange",
            resolver: yupResolver(schema),
            defaultValues: async () => {
                setIsLoading(true);
                let formData = await getEntry(entryId);
                formData.tipo = formData.tipo;
                formData.paciente = formData.paciente.nombre;
                formData.clinica = formData.clinica.nombre;
                setIsLoading(false);

                return formData;
            },
        });
    const tipo = watch("tipo");
    const onSubmit = (data) => {};

    return (
        <React.Fragment>
            <Banner>Ver</Banner>
            <GenBtn
                onPress={() => history.goBack()}
                style={{ margin: "5px 0" }}
            >
                Regresar
            </GenBtn>
            <LoadMask loading={isLoading} color="#bd0034" blur={true}>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    style={{ display: "flex", flexDirection: "column" }}
                >
                    <FicInput
                        control={control}
                        label="Numero de patologia"
                        name="numero_patologia"
                        style={{ alignSelf: "center" }}
                        isDisabled
                    />
                    <div style={{ backgroundColor: "#2b2b2b" }}>
                    <InputsContainer>
                            <FicInput
                                control={control}
                                label="Tipo"
                                name="tipo"
                                nonAsync
                                isDisabled
                            />
                            {tipo === "inmunohistoquimica" && (
                                <FicInput
                                    control={control}
                                    label="Patologia"
                                    name="patologia"
                                    isDisabled
                                />
                            )}
                            <FicInput
                                control={control}
                                label="Paciente"
                                name="paciente"
                                isDisabled
                            />
                            {tipo === "afiliada" && (
                                <FicInput
                                    control={control}
                                    label="Afiliacion"
                                    name="afiliacion"
                                    isDisabled
                                />
                            )}
                            <FicNumber
                                control={control}
                                label="Edad"
                                name="edad"
                                isDisabled
                            />
                            <FicInput
                                control={control}
                                label="Clinica"
                                name="clinica"
as                                isDisabled
                            />

                            {tipo === "afiliada" && (
                                <FicInput
                                    control={control}
                                    label="IGSS"
                                    name="igss"
                                    isDisabled
                                />
                            )}
                            <FicInput
                                control={control}
                                label="Medico"
                                name="medico"
                                isDisabled
                            />
                            <FicDatePicker
                                control={control}
                                label="Fecha recibido"
                                name="fecha_recibido"
                                isDisabled
                            />
                            {/* <FicNumber
                                control={control}
                                label="Costo del examen"
                                name="costo"
                                formatOptions={{ minimumFractionDigits: 2 }}
                            /> */}
                        </InputsContainer>
                        <FicTextArea
                            style={{ padding: "0 2rem 1rem 2rem" }}
                            control={control}
                            label="Descripcion macroscopica"
                            name="descripcion_macroscopica"
                            isDisabled
                        />
                        <FicTextArea
                            style={{ padding: "0 2rem 1rem 2rem" }}
                            control={control}
                            label="Especimen"
                            name="especimen"
                            isDisabled
                        />
                    </div>
                    <GenBtn style={{ margin: "1rem 0" }} type="submit">
                        Guardar
                    </GenBtn>
                </form>
            </LoadMask>
        </React.Fragment>
    );
}

export default withRouter(Ver);
