import { NotificationManager } from "react-notifications";
import { api } from "api";
import { getPeriodoId } from "../utils";

const baseurl = "laboratorio/ingreso_de_patologia";
const writeSuccessMsg = "Patologia agregada";
const editSuccessMsg = "Patologia editada";

export const getList = () => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let empresa = id_emp[5];
    const periodo = getPeriodoId();
    const params = { empresa, periodo };
    return api
        .get(baseurl, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return [];
        });
};

export const getEntry = (id) => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let empresa = id_emp[5];
    const params = { empresa };
    return api
        .get(baseurl + `/${id}`, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return [];
        });
};

export const crear = (data) => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    const periodo = getPeriodoId();
    if (!periodo) {
        NotificationManager.error(
            "No hay un periodo seleccionado",
            "Error",
            10000
        );
        return;
    }
    const formData = {
        ...data,
        empresa: id_emp[5],
        periodo,
    };
    return api
        .post(baseurl, formData)
        .then(() => {
            NotificationManager.success(writeSuccessMsg, "Exito", 10000);
            return true;
        })
        .catch((error) => {
            try {
                error.non_field_errors.map((err) => {
                    NotificationManager.error(err, "Error", 10000);
                });
                return false;
            } catch (exception) {}
            try {
                Object.keys(error).forEach((field) => {
                    error[field].forEach((errorMessage) => {
                        NotificationManager.error(
                            `${field}: ${errorMessage}`,
                            "Error",
                            10000
                        );
                    });
                });
                return false;
            } catch (exception) {
                NotificationManager.error("", "Error", 10000);
                return false;
            }
        })
        .catch((error) => {
            NotificationManager.error("", "Error", 2000);
            return false;
        });
};

export const editar = (data, id) => {
    let ruta = window.location.href;
    let empresa = ruta.split("/")[5];

    const formData = {
        ...data,
        empresa,
    };
    return api
        .put(baseurl + `/${id}`, formData)
        .then(() => {
            NotificationManager.success(editSuccessMsg, "Exito", 3000);
            return true;
        })
        .catch((error) => {
            NotificationManager.error(error.msj, "Error", 2000);
            return false;
        });
};

export const getPacientes = (search) => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let empresa = id_emp[5];
    const params = { empresa, search };
    return api
        .get("laboratorio/pacientes", params)
        .then((response) => {
            return response.map((item) => {
                let label = `${item.nombre}`;
                if (item.dpi) {
                    label += ` | DPI: ${item.dpi}`;
                }
                if (item.afiliacion) {
                    label += ` | Afiliacion: ${item.afiliacion}`;
                }

                return {
                    value: item.id,
                    label: label,
                };
            });
        })
        .catch((error) => {
            return [];
        });
};

export const getUnidadesIgss = (search) => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let empresa = id_emp[5];
    const params = { empresa, search };
    return api
        .get("laboratorio/unidad-igss", params)
        .then((response) => {
            return response.map((item) => ({
                value: item.id,
                label: `${item.nombre}`,
            }));
        })
        .catch((error) => {
            return [];
        });
};

export const getPatologias = (search) => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let empresa = id_emp[5];
    const params = { empresa, search };
    return api
        .get(baseurl, params)
        .then((response) => {
            return response.map((item) => ({
                value: item.id,
                label: `${item.numero_patologia} | tipo:${item.tipo} | paciente:${item.paciente.nombre}`,
            }));
        })
        .catch((error) => {
            return [];
        });
};
export const getClientes = (search, tipo) => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let empresa = id_emp[5];
    const params = { empresa, search, tipo };
    return api
        .get("laboratorio/clientes/clientes_por_tipo", params)
        .then((response) => {
            return response.map((item) => ({
                value: item.id,
                label: `${item.nombre} `,
            }));
        })
        .catch((error) => {
            return [];
        });
};

export async function getNumeroPatologia(serie) {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let empresa = id_emp[5];
    const params = { empresa, serie };
    if (serie === null || serie === undefined) {
        return { numero_patologia: "" };
    }
    return api
        .get("laboratorio/ingreso_de_patologia/siguiente_numero", params)
        .then((response) => {
            return response;
        })
        .catch((error) => {});
}

export async function getSeriePatologia(search) {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let empresa = id_emp[5];
    const params = { empresa, search };
    return api
        .get("laboratorio/series/listado_de_series_o_actual", params)
        .then((response) => {
            return response;
        })
        .catch((error) => {});
}

export async function getSeriePatologiaComboBox(search) {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let empresa = id_emp[5];
    const params = { empresa, search };
    return api
        .get("laboratorio/series/listado_de_series_o_actual", params)
        .then((response) => {
            return response.map((item) => ({
                value: item.id,
                label: `${item.nombre}`,
            }));
        })
        .catch((error) => {});
}

export async function getSiguienteSubSeriePatologia(numero_patologia, serie) {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let empresa = id_emp[5];
    const params = { empresa, numero_patologia, serie };
    return api
        .get("laboratorio/ingreso_de_patologia/siguiente_subserie", params)
        .then((response) => {
            return response;
        })
        .catch((error) => {});
}
