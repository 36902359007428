import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/recursos_humanos/bajaColaborador';
import Colaborador from './ColaboradorList';


const ms2p = (state) => {
  return {
    ...state.baja_rh,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Colaborador);
