import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import Grid from '../../Utils/GridVDocumento';
import moment from 'moment';
import styled from 'styled-components';
import TablaDetalle from './DepositoReciboDetalle';
import TablaVerDetalle from './DepositoReciboVerDetalle';
import {
    renderDayPickerB,
    renderField,
    renderNumber,
    AsyncSelectFieldIn,
    renderCurrencyFloat,
} from "../../Utils/renderField/renderField";

function DepositoReciboForm(props) {
    const { crear, lectura, editar, detalleDep, list_doc,
        doc, cuentaBan, loader, bloqueo, eliminarDocumento, detalleDocN,
        agregarLinea, periodoD } = props;

    let autoFocus = true;

    
    const estilo = {
        overflow: "auto",
        height: "200px",

    };
    
    const handleOnChange = value => {
        agregarLinea(periodoD, value.value);
    }

    return (
        <div>
            {crear === false && editar == false &&
                <div className='row mb-2'>
                    <div className='col-3'>
                        <Field
                            name="cuenta"
                            placeholder="Cuenta"
                            className="form-control"
                            component={renderField}
                            disabled={bloqueo}
                            msj="Cuenta"
                        />
                    </div>
                    <div className='col-3'>
                        <Field
                            name="banco"
                            placeholder="Banco"
                            className="form-control"
                            component={renderField}
                            disabled={bloqueo}
                            msj="Banco"
                        />
                    </div>
                    <div className='col-3'>
                        <Field
                            name="moneda"
                            placeholder="Moneda"
                            className="form-control"
                            component={renderField}
                            disabled={bloqueo}
                            msj="Moneda"
                        />
                    </div>
                    <div className='col-3'>
                        <Field
                            name="tipo"
                            placeholder="Tipo"
                            className="form-control"
                            component={renderField}
                            disabled={bloqueo}
                            msj="Tipo"
                        />
                    </div>
                </div>

            }
            {(crear === true) &&
                <div className='row mb-2'>
                    <div className='col-3'>
                        <div className="form-floating">
                            <input
                                placeholder="Cuenta"
                                type="text"
                                autocomplete="off"
                                name="cuenta"
                                className="form-control"
                                value={editar ? lectura.cuenta : cuentaBan.numero_cuenta}
                                disabled={true}
                            />
                            <label className='form-label' htmlFor="cuenta">
                                Cuenta
                            </label>
                        </div>
                    </div>
                    <div className='col-3'>
                        <div className="form-floating">
                            <input
                                placeholder="Banco"
                                type="text"
                                autocomplete="off"
                                name="banco"
                                className="form-control"
                                value={editar ? lectura.banco : cuentaBan.bancoNombre}
                                disabled={true}
                            />
                            <label className='form-label' htmlFor="banco">
                                Banco
                            </label>
                        </div>
                    </div>
                    <div className='col-3'>
                        <div className="form-floating">
                            <input
                                placeholder="Moneda"
                                type="text"
                                autocomplete="off"
                                name="moneda"
                                className="form-control"
                                value={editar? lectura.moneda : cuentaBan.monedaN}
                                disabled={true}
                            />
                            <label className='form-label' htmlFor="moneda">
                                Moneda
                            </label>
                        </div>
                    </div>
                    <div className='col-3'>
                        <div className="form-floating">
                            <input
                                placeholder="Tipo"
                                type="text"
                                autocomplete="off"
                                name="tipoCB"
                                className="form-control"
                                value={editar ? lectura.tipo : cuentaBan.tipo}
                                disabled={true}
                            />
                            <label className='form-label' htmlFor="tipoCB">
                                Tipo
                            </label>
                        </div>
                    </div>
                </div>
            }
            <div className='row mb-2'>
                <div className='col-3'>
                    <Field
                        autoFocus={autoFocus}
                        disabled={bloqueo}
                        name="fechaRegistro"
                        component={renderDayPickerB}
                        msj="Fecha Registro"
                    />
                </div>
                {crear === false && editar == false &&
                    <div className='col-3'>
                        <Field
                            name="numero"
                            placeholder="Numero depósito"
                            className="form-control"
                            component={renderNumber}
                            disabled={bloqueo}
                            msj="Numero depósito"
                        />
                    </div>
                }
                {(crear === true) &&
                    <div className='col-3'>
                        <Field
                            name="numero"
                            placeholder="Numero depósito"
                            className="form-control"
                            component={renderNumber}
                            disabled={bloqueo}
                            msj="Numero depósito"
                        />
                    </div>
                }
                {crear === false && editar == false &&
                    <div className='col-3'>
                        <Field
                            name="total"
                            placeholder="Total"
                            className="form-control"
                            component={renderCurrencyFloat}
                            disabled={bloqueo}
                            msj="Total"
                        />
                    </div>
                }
            </div>
            <div className='mb-2'>
                <Field
                    name="descripcion"
                    placeholder="Descripcion"
                    component={renderField}
                    className="form-control"
                    disabled={bloqueo}
                    msj="Referencia"
                />
            </div>
            {(crear === true) &&
                <div>
                    <h5 className="p-2 bg-secondary title mt-1">Cortes de caja</h5>
                    <div style={estilo}>
                        <Grid
                            hover
                            striped
                            data={doc}
                            loading={loader}
                            headerStyle={{ background: 'black' }}
                        //onSortChange={onSortChange} 
                        >
                            <TableHeaderColumn
                                isKey
                                dataField="fecha"
                                dataSort
                                dataFormat={(cell, row) => {
                                    return moment(cell).format("DD/MM/YYYY");
                                }}
                                thStyle={
                                    { color: 'white' }}
                                tdStyle={
                                    { color: 'white' }}
                            >
                                Fecha
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="numero"
                                dataSort
                                thStyle={
                                    { color: 'white' }}
                                tdStyle={
                                    { color: 'white' }}
                            >
                                Numero
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="total"
                                headerAlign="center"
                                dataAlign='right'
                                dataFormat={(cell, row) => {
                                    if (parseFloat(cell) === 0.00) {
                                        return ''
                                    } else {
                                        return new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(cell);
                                    }
                                }}
                                dataSort
                                thStyle={
                                    { color: 'white' }}
                                tdStyle={
                                    { color: 'white' }}
                            >
                                Total
                            </TableHeaderColumn>
                        </Grid>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-8 mt-2'>
                            <Field
                                name="documento"
                                placeholder="Corte de caja..."
                                className="form-control-find"
                                loadOptions={list_doc}
                                component={AsyncSelectFieldIn}
                                onChange={handleOnChange}
                                msj="Corte de caja"
                            />
                        </div>
                    </div>
                    {(crear === true) &&
                        <div>
                            <h5 className="p-2 bg-secondary title mt-1">Corte de caja agregado</h5>
                            <div style={estilo}>
                                <TablaDetalle
                                    detalle={detalleDocN}
                                    eliminarDocumento={eliminarDocumento}
                                />
                            </div>
                        </div>
                    }
                </div>
            }
            {crear === false && editar === false &&
                    <div>
                        <h5 className="p-2 bg-secondary title mt-1">Corte de caja agregado</h5>
                        <div style={estilo}>
                            <TablaVerDetalle
                                detalle={detalleDep}
                            />
                        </div>
                    </div>
            }
        </div>

    );
}

DepositoReciboForm.propTypes = {
    funcionEnvio: PropTypes.func,
    crear: PropTypes.bool,
    editar: PropTypes.bool,
    lectura: PropTypes.object,
    bancos: PropTypes.object,
    listaM: PropTypes.object,
    listarClientes: PropTypes.object,
    leerDocumentos: PropTypes.func,
    doc: PropTypes.object,
    loader: PropTypes.bool,
    bloqueo: PropTypes.bool,
    detalleDoc: PropTypes.object,
    eliminarDocumento: PropTypes.func,
    cuentaBan: PropTypes.object,
    fechaInicio: PropTypes.string,
    fechaFinal: PropTypes.string,
    detalleDep: PropTypes.object
};

export default reduxForm({
    form: 'FormReciboDep',
})(DepositoReciboForm);

