import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from '../../components/Modal';
import { Field, reduxForm } from 'redux-form/immutable';
import {
    renderNumber,
} from "../../Utils/renderField/renderField";
const valEmp = __CONFIG__.empresa;

function FormSeleccionarPeriodo() {
    
    const [id, setId] = useState("");
    
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    
    const handleChange = value => {
        if (value.length === 4) {
            setId(value);
        }
        if (value.length === 0) {
            setId('');
        }
    }
    
    const [estadoModal, cambiarEstadoModal] = useState(true);
    

    return (
        <div className='container'>
            <Modal
                estado={estadoModal}
                cambiarEstado={cambiarEstadoModal}
                titulo="Ingresar año"
                botonCerrar="false"
            >
                <Contenido>
                    <div className='col-6 mb-3'>
                        <div className="form-floating">
                            <Field
                                name="year"
                                component={renderNumber}
                                className="form-control"
                                onChange={(e, value) => {
                                    handleChange(value);
                                }}
                                msj="Año"
                            />
                        </div>
                    </div>
                {valEmp === 'fage' &&
                    <div>
                        {id &&
                            <a
                                href={`/#/lfg/rr_hh/${id_emp[6]}/isr/${id}`}
                                className='btn mr-2 mb-3 btn-primary'
                            >
                                Seleccionar
                            </a>
                        }
                    </div>
                }
                {valEmp === '' &&
                    <div>
                        {id &&
                            <a
                                href={`/#/rr_hh/${id_emp[5]}/isr/${id}`}
                                className='btn mr-2 mb-3 btn-primary'
                            >
                                Seleccionar
                            </a>
                        }
                    </div>
                }
                </Contenido>
            </Modal>
        </div>
    );

}

export default reduxForm({
    form: 'FormSeleccionarAnio',
})(FormSeleccionarPeriodo);


const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;