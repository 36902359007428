import React, { Component } from 'react';
import Formulario from './EntradasForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";

class Entradas extends Component{
    state = {
        crear: true,
    }

    componentDidMount = () => {
        const { match, leer, getModulo,leerPeriodoD, listarBodegas, listarProductos,
        leerDetalleProductos, parametrosEmpresa } = this.props;
        const id = match.params.id;
        if (id){
            this.setState({crear: false});
            leer(id);
        }
        let ruta = window.location.href;
        let periodo = ruta.split('/');
        leerPeriodoD(periodo[5], periodo[7]);
        getModulo(periodo[5], periodo[4]);
        listarBodegas(),
        listarProductos();
        parametrosEmpresa();
        leerDetalleProductos('00000000-0000-0000-0000-000000000000');
        localStorage.removeItem('id_detalle_inventario_entrada');
    }
    
    render(){
        const { loader, modulo, listaBodegas, lectura, periodoD,
            listarProductos, detalleProducto, registroProductoInventario,
            eliminarLineaProducto, confirmarEntrada, datos } = this.props;
        const { crear } = this.state;
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    crear = {crear}
                    modulo = {modulo}
                    lectura = {lectura}
                    periodoD = {periodoD}
                    obtenerBodegas = {listaBodegas}
                    listarProductos = {listarProductos}
                    detalleProducto = {detalleProducto}
                    registroProductoInventario = {registroProductoInventario}
                    eliminarLineaProducto = {eliminarLineaProducto}
                    confirmarEntrada = {confirmarEntrada}
                    datos = {datos}
                />
            </LoadMask>
        );
    }
}

export default Entradas
