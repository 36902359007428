import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import Dropdown from './SubMenuNavbarUser';
import styled from 'styled-components';

const MenuItems = ({ items, img, user, logOut }) => {
  const [dropdown, setDropdown] = useState(false);
  let depthLevel = 0;
  return (
    <li className="menu-itemsS">
      {items.subNav ? (
        <div>
          <button
            type="button" 
            aria-expanded={dropdown ? "true" : "false"}
            onClick={() => setDropdown((prev) => !prev)}
          >
            <Imagen className="user-avatar rounded-circle mr-3"
                src={img}
                alt="User Avatar" />
                <span className="d-none d-md-inline-block">
                <Titulo>
                    {user}
                </Titulo>
                </span>
            
            <SidebarLabel>
              {items.title}{" "}
              {depthLevel > 0 ? <span>&raquo;</span> : <span className="arrow" />}
            </SidebarLabel>
          </button>
          <Dropdown 
            dropdown={dropdown} 
            submenus={items.subNav}
            logOut={logOut}
           />
        </div>
      ) : (
        <SidebarLink to={items.path}>
            <div>
              {items.icon}
              <SidebarLabel>
                {items.title}
              </SidebarLabel>
            </div>
        </SidebarLink>
      )}
    </li>
  );
};

export default MenuItems;

const SidebarLink = styled(Link)`
    display: flex;
    color: #fff;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    list-style: none;
    height: 40px;
    text-decoration: none;
    font-size: 17px;

    &:hover {
        background: black;
        border-left: 4px solid #632ce4;
        cursor: pointer;
        color: #007bff;
        text-decoration: none;
    }
`;
const Titulo = styled.span`
    font-size: 14px;
`;
const SidebarLabel = styled.span`
    margin-left: 16px;
`;

const Imagen = styled.img`
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50% !important
`;