import React from "react";
import { useButton } from "react-aria";
import { useRef } from "react";
import { Button, OverlayArrow, TooltipTrigger } from "react-aria-components";
import styled from "styled-components";
import CustomToolTip from "./ToolTip";

function adjustOpacity(rgbaColor, opacity) {
    if (!rgbaColor) return null;
    const colorParts = rgbaColor.match(
        /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(1|0?\.\d+))?\)$/
    );
    if (!colorParts) return null;

    return `rgba(${colorParts[1]}, ${colorParts[2]}, ${colorParts[3]}, ${opacity})`;
}

export default function GenBtn({
    textColor,
    background,
    className,
    title,
    isDisabled,
    delay,
    closeDelay,
    trigger,
    isOpen,
    defaultOpen,
    toolTipText,
    children,
    placement,
    buttonColor,
    ...props
}) {
    const StyledButton = styled(Button)`
        padding: 7px 12px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        /* background-color: blue; */
        box-shadow: inset 0 0 0px #000000;
        transition: box-shadow 250ms, border 250ms;
        font-weight: 700;
        color: ${textColor || buttonColor || "#009c03"};
        border: 1px solid ${textColor || buttonColor || "#009c03"};
        background: ${background ||
        adjustOpacity(buttonColor, 0.2) ||
        "hsla(121, 100%, 31%, 0.2)"};
        transition: box-shadow 400ms ease;

        &[data-hovered] {
            border-color: #969696;
            box-shadow: 0 0 0 1px #969696;
        }
        &[data-focused] {
            border-color: #bababa;
            box-shadow: 0 0 0 2px #bababa;
        }
        &[data-pressed] {
            border-color: #464646;
            box-shadow: inset 0 0 10px #000000;
            transition: box-shadow 20ms, border 15ms, text-shadow 15ms;
        }

        &[data-disabled] {
            border-color: #505050;
            background-color: hsl(0, 0%, 31%, 0.2);
            color: rgb(111, 111, 111);
        }
    `;

    if (toolTipText) {
        return (
            <TooltipTrigger
                isDisabled={isDisabled}
                delay={delay || 150}
                closeDelay={closeDelay}
                trigger={trigger}
                isOpen={isOpen}
                defaultOpen={defaultOpen}
            >
                <StyledButton isDisabled={isDisabled} {...props}>
                    {children}
                </StyledButton>
                <CustomToolTip placement={placement}>
                    <OverlayArrow>
                        <svg width={8} height={8} viewBox="0 0 8 8">
                            <path d="M0 0 L4 4 L8 0" />
                        </svg>
                    </OverlayArrow>
                    {toolTipText}
                </CustomToolTip>
            </TooltipTrigger>
        );
    }

    return (
        <StyledButton isDisabled={isDisabled} {...props}>
            {children}
        </StyledButton>
    );
}
