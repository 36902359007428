import { handleActions } from 'redux-actions';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';

const GUARDAR_POLIZA = 'GUARDAR_POLIZA';
const LISTADO_POLIZAS = 'LISTADO_POLIZAS';
const DETALLE_POLIZA = 'DETALLE_POLIZA';
const LOADER = 'LOADER';
const LOADERC = 'LOADERC';
const NIVEL = 'NIVEL';
const MODULO = 'MODULO';
const PARAMETRO = 'PARAMETRO';
const PERIODO_D = 'PERIODOD';
const LISTADO_PERIODOS = 'LISTADO_PERIODOS';
const LISTADO_CUENTAS = 'LISTADO_CUENTAS';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listarCuentas = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id, search}
    return api.get('/cuentas/empresa_cuentas/',params).then(data=>{
        const list_cuentas = [];
        if(data){
            data.forEach(item=>{
                if (item.tipo === 'A'){
                    list_cuentas.push({
                        value: item.id,
                        label: item.cuenta + ' ' + item.nombre,
                        isDisabled: true
                    })
                }else{
                    list_cuentas.push({
                        value: item.id,
                        label: item.cuenta + ' ' + item.nombre
                    })
                }
            })
        }
        return list_cuentas;
    }).catch((error)=>{
        return [];
    })
}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        if (error.status === 401){
            NotificationManager.error(
              error.body.msj,
              'Error',
              0
            );
        }
        if (error.status === 400){
            NotificationManager.error(
              'Ocurrió un error en buscar modulo',
              'Error',
              0
            );
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const parametrosEmpresa = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id}
    dispatch(setLoader(true));
    api.get('/empresa/empresa_parametros', params).then((response) => {
        dispatch({type: PARAMETRO, datos: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar parametros',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const leerPeriodoD = (id, id_periodo) => (dispatch) => {
    const params = { id, id_periodo };
    api.get('/periodos/periodo', params).then((response) => {
        dispatch({ type: PERIODO_D, periodoD: response.periodo });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar periodo',
            'Error',
            2000
        );
    })
}

export const listarPeriodos = (year) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let params = { id, year };
    dispatch(setLoader(true));
    api.get('/periodos/periodo_empresa_year', params).then((response)=>{
        let periodo = [];
        response.forEach(item=>{
            periodo.push({
                value: item.id,
                label: item.periodo
            })
        })
        dispatch({ type: LISTADO_PERIODOS, periodos: periodo });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar los periodos',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const traslado = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const data = getStore().form.FormIntercambioCuentas.values;

    if (data === undefined) {
        Swal.fire({
            title: 'Intercambio de cuentas',
            text: 'Debe de seleccionar cuentas contables para cambiar',
            type: 'error',
        })
    } else {
        if (data.anio === undefined || parseInt(data.anio) === 0) {
            Swal.fire({
                title: 'Intercambio de cuentas',
                text: 'Debe de ingresar año',
                type: 'error',
            })
        } else {
            if (data.periodo === undefined || data.periodo === '') {
                Swal.fire({
                    title: 'Intercambio de cuentas',
                    text: 'Debe de seleccionar periodo',
                    type: 'error',
                })
            } else {
                if (data.cuenta_de === undefined || data.cuenta_de === '') {
                    Swal.fire({
                        title: 'Intercambio de cuentas',
                        text: 'Debe de seleccionar cuenta contable de',
                        type: 'error',
                    })
                } else {
                    if (data.cuenta_a === undefined || data.cuenta_a === '') {
                        Swal.fire({
                            title: 'Intercambio de cuentas',
                            text: 'Debe de seleccionar cuenta contable a',
                            type: 'error',
                        })
                    } else {
                        Swal.fire({
                            title: 'Intercambio de cuentas',
                            text: '¿Está seguro de cambiar las cuentas contables? No se podrá revertir esta acción',
                            type: 'info',
                            showCancelButton: true,
                            confirmButtonText: '¡Sí, cambiar!',
                            cancelButtonText: 'No, cancelar',
                            reverseButtons: true
                        }).then((result) => {
                            if (result.value) {
                                const formData = {
                                    periodo: data.periodo,
                                    year: data.anio,
                                    cuenta_de: data.cuenta_de.value,
                                    cuenta_a: data.cuenta_a.value,
                                    descripcion: data.descripcion,
                                    empresa: id
                                }
                                dispatch(setLoader(true));
                                api.post('/lc_intercambio_cuenta/', formData).then((response) => {
                                    NotificationManager.success(
                                        response.msj,
                                        'Exito',
                                        0
                                    );
                                }).catch((error) => {
                                    NotificationManager.error(
                                        error.msj,
                                        'Error',
                                        0
                                    );
                                }).finally(() => {
                                    dispatch(setLoader(false));
                                });
                            }
                        })                       
                    }
                }
            }
        }
    }
}

export const actions = {
    listarCuentas,
    getModulo,
    parametrosEmpresa,
    leerPeriodoD,
    listarPeriodos,
    traslado,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [GUARDAR_POLIZA]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
    [LISTADO_POLIZAS]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [DETALLE_POLIZA]: (state, { detalle }) => {
        return {
            ...state,
            detalle,
        };
    },
    [NIVEL]: (state, { nivel }) => {
        return {
            ...state,
            nivel,
        };
    },
    [LOADERC]: (state, { loaderC }) => {
        return {
            ...state,
            loaderC,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [PARAMETRO]: (state, { datos }) => {
        return {
            ...state,
            datos,
        };
    },
    [PERIODO_D]: (state, { periodoD }) => {
        return {
            ...state,
            periodoD,
        };
    },
    [LISTADO_PERIODOS]: (state, { periodos }) => {
        return {
            ...state,
            periodos,
        };
    },
    [LISTADO_CUENTAS]: (state, { listarCuentasContable }) => {
        return {
            ...state,
            listarCuentasContable,
        };
    },
};

export const initialState = {
    loader: false,
    loaderC: false,
    cuentas: null,
    lectura: {
        polizaDetalle_polizaEncabezado: []
    },
    detalle: [],
    nivel: [],
    modulo: [],
    datos: [],
    periodoD: [],
    data: [],
    page: 1,
    periodos: [],
    listarCuentasContable: []
};

export default handleActions(reducers, initialState)