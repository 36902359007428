import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { withRouter } from "react-router";
import { FicInput, FicCheckbox } from "../../Utils/FormInputComponents";
import { setSettingsLaboratorio } from "../../../../redux/modules/laboratorio/actions";
import Banner from "../common/Banner";
import { validar } from "../../components/ValidarNit";
import GenBtn from "../../components/GenBtn";
import LoadMask from "../../Utils/LoadMask/LoadMask";
import CustomToolTip from "../../components/ToolTip";
import InfoPopover from "../../components/Popover";
import useUpdateEffect from "../../../../hooks/useUpdateEffect";
import { getEntry, editar, crear } from "./actions";
import { getSettings } from "../utils";

const SettingsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    border-radius: 5px;
    background-color: #2b2b2b;
`;

const SettingGroup = styled.div`
    display: flex;
    align-items: flex-end;
    gap: 1rem;
`;

const schema = yup.object({
    manual_series: yup.boolean(),
    with_year: yup.boolean(),
});

function Editar({ history, setSettingsLaboratorio, ...props }) {
    const [isLoading, setIsLoading] = useState(false);
    const [entryId, setEntryId] = useState(null);
    const [loadedSerie, setLoadedSerie] = useState("");

    const { control, reset, handleSubmit, formState, watch, setValue } =
        useForm({
            mode: "onChange",
            resolver: yupResolver(schema),
            defaultValues: async () => {
                setIsLoading(true);
                const formData = await getEntry();
                if (formData) {
                    setEntryId(formData.id);
                    setLoadedSerie(formData.serie);
                }
                setIsLoading(false);
                return formData;
            },
        });

    const onSubmit = (data) => {
        delete data.serie;
        const setData = async (settingsData) => {
            setSettingsLaboratorio(settingsData);
        };
        if (entryId) {
            editar(data, entryId).then((settings) => setData(settings));
        } else {
            crear(data).then((settings) => setData(settings));
            // reset(data);
        }
    };
    const manual_series = watch("manual_series");

    useUpdateEffect(() => {
        if (manual_series) {
            setValue("serie", loadedSerie);
        }
    }, [manual_series]);

    return (
        <React.Fragment>
            <Banner>Ajustes</Banner>
            <LoadMask loading={isLoading} color="#bd0034" blur={true}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <SettingsContainer>
                        <SettingGroup>
                            <FicCheckbox
                                control={control}
                                label="Serie manual?"
                                name="manual_series"
                            />
                            <InfoPopover style={{ padding: "0.5rem" }}>
                                <div>
                                    Si se activa esta opción, las series se
                                    deberan crear de forma manual.
                                </div>
                            </InfoPopover>
                        </SettingGroup>
                        {!manual_series && (
                            <React.Fragment>
                                <SettingGroup>
                                    <FicCheckbox
                                        control={control}
                                        label="Serie con año?"
                                        name="with_year"
                                    />
                                    <InfoPopover style={{ padding: "0.5rem" }}>
                                        <div>
                                            Si se activa esta opción, las series
                                            se actualizaran conforme al año.
                                        </div>
                                    </InfoPopover>
                                </SettingGroup>
                            </React.Fragment>
                        )}
                    </SettingsContainer>
                    <GenBtn style={{ margin: "1rem 0" }} type="submit">
                        Guardar
                    </GenBtn>
                </form>
            </LoadMask>
        </React.Fragment>
    );
}

export default connect(null, { setSettingsLaboratorio })(withRouter(Editar));
