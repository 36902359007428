import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import moment from 'moment';
import Swal from 'sweetalert2';
import { validar } from "../../../common/components/components/ValidarNit";
const valEmp = __CONFIG__.empresa;

const LISTADO_EMP = 'LISTADO_EMP';
const LOADER = 'LOADER';
const MODULO = 'MODULO';
const LISTADO_PAISES = 'LISTADO_PAISES';
const LISTADO_DEPTO = 'LISTADO_DEPTO';
const LISTADO_MUNICIPIO = 'LISTADO_MUNICIPIO';
const GUARDAR_COL = 'GUARDAR_COL';
const LISTADO_DEPARTAMENTOS = 'LISTADO_DEPARTAMENTOS';
const LISTADO_PUESTOS = 'LISTADO_PUESTOS';
const DETALLE = 'DETALLE';
const DETALLE_FAMILIA = 'DETALLE_FAMILIA';
const PARAMETRO = 'PARAMETRO';
const LISTADO_DOCUMENTOS = 'LISTADO_DOCUMENTOS'

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params;
    let id = '';
    let id_pais = '';
    if (valEmp === 'fage') {
        id = id_emp[6];
        id_pais = id_emp[8];
        //params = { page, id, id_pais };
        params = { id, id_pais };
    } else {
        id = id_emp[5];
        id_pais = id_emp[7];
        //params = { page, id, id_pais };
        params = { id, id_pais };
    }
    dispatch(setLoader(true));
    api.get('/colaborador', params).then((response) => {
        dispatch({ type: LISTADO_EMP, data: response });
        
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar departamentos',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const emailValidation = value =>
value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
    'Dirección de correo electrónico no válido' : true

export const registroColaborador = (data = {}, attachments = []) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormColaborador.values;
    let idempresa = '';
    let idpais = '';
    if (valEmp === 'fage') {
        idempresa = id_emp[6];
        idpais = id_emp[8]
    } else {
        idempresa = id_emp[5];
        idpais = id_emp[7];
    }
    
    if (data === undefined) {
        Swal.fire({
            title: 'Colaborador',
            text: 'Debe de ingresar datos de colaborador',
            type: 'error',
        })
    } else {
        
        if (data.correo) {
            let correo = emailValidation(data.correo);
            if (correo === true){
                if (data.codigo === undefined || data.codigo === '') {
                    Swal.fire({
                        title: 'Colaborador',
                        text: 'Debe de ingresar codigo de colaborador',
                        type: 'error',
                    })
                } else {
                    if (data.documento === undefined || data.documento === '') {
                        Swal.fire({
                            title: 'Colaborador',
                            text: 'Debe de ingresar numero documento de colaborador',
                            type: 'error',
                        })
                    } else {
                        if (data.validar_dpi === 'S' && data.codigoPais === 'GTM') {
                            let valid = validar.valCui(data.documento);
                            if (valid[0].validar === false) {
                                Swal.fire({
                                    title: 'Colaborador',
                                    text: 'DPI con formato inválido',
                                    type: 'error',
                                })
                            }else{
                                if (data.validar_nit === 'S') {
                                    let valid = validar.valNit(data.nitCol);
                                    if (valid === false){
                                        Swal.fire({
                                            title: 'Colaborador',
                                            text: 'Ingrese Nit válido',
                                            type: 'error',
                                        })
                                    }else{
                                        if (data.ocupacion_colaborador === undefined || data.ocupacion_colaborador === '') {
                                            Swal.fire({
                                                title: 'Colaborador',
                                                text: 'Debe de ingresar ocupación',
                                                type: 'error',
                                            })
                                        }else {
                                            if (data.sueldo_base === undefined || parseFloat(data.sueldo_base) === 0.00) {
                                                Swal.fire({
                                                    title: 'Colaborador',
                                                    text: 'Debe de ingresar sueldo base',
                                                    type: 'error',
                                                })
                                            }else {
                                                if (data.fecha_alta === undefined || data.fecha_alta === '') {
                                                    Swal.fire({
                                                        title: 'Colaborador',
                                                        text: 'Debe de ingresar fecha de alta',
                                                        type: 'error',
                                                    })
                                                }else {
                                                    if (data.departamento_trabajo === undefined || data.departamento_trabajo === '') {
                                                        Swal.fire({
                                                            title: 'Colaborador',
                                                            text: 'Debe de ingresar departamento colaborador',
                                                            type: 'error',
                                                        })
                                                    }else {
                                                        if (data.puesto === undefined || data.puesto === '') {
                                                            Swal.fire({
                                                                title: 'Colaborador',
                                                                text: 'Debe de ingresar puesto colaborador',
                                                                type: 'error',
                                                            })
                                                        }else {
                                                            if (data.bonificacion === undefined || parseFloat(data.bonificacion) === 0.00) {
                                                                Swal.fire({
                                                                    title: 'Colaborador',
                                                                    text: 'Debe de ingresar bonificación',
                                                                    type: 'error',
                                                                })
                                                            }else {
                                                                if (data.genero === undefined || parseInt(data.genero) === 0) {
                                                                    Swal.fire({
                                                                        title: 'Colaborador',
                                                                        text: 'Debe de seleccionar género',
                                                                        type: 'error',
                                                                    })
                                                                }else {
                                                                    if (data.fecha_nacimiento === undefined || data.fecha_nacimiento === '') {
                                                                        Swal.fire({
                                                                            title: 'Colaborador',
                                                                            text: 'Debe de ingresar fecha de nacimiento',
                                                                            type: 'error',
                                                                        })
                                                                    }else {
                                                                        const formData = {
                                                                            codigo: data.codigo,
                                                                            tipo_documento: data.tipo_documento,
                                                                            no_documento: data.documento,
                                                                            primer_nombre: data.primer_nombre,
                                                                            segundo_nombre: data.segundo_nombre,
                                                                            primer_apellido: data.primer_apellido,
                                                                            segundo_apellido: data.segundo_apellido,
                                                                            apellido_casada: data.apellido_casada,
                                                                            nit: data.nitCol,
                                                                            nacionalidad: data.nacionalidad,
                                                                            departamento: data.departamento,
                                                                            municipio: data.municipio,
                                                                            fecha_nacimiento: data.fecha_nacimiento,
                                                                            genero: parseInt(data.genero),
                                                                            estado_civil: parseInt(data.estado_civil),
                                                                            telefono: data.telefonoCol,
                                                                            igss: data.igss,
                                                                            tipo_licencia: data.tipo_licencia,
                                                                            numero_licencia: data.numero_licencia,
                                                                            correo: data.correo,
                                                                            etnia: parseInt(data.etnia),
                                                                            idioma: parseInt(data.idioma),
                                                                            direccion: data.direccionCol,
                                                                            discapacidad: parseInt(data.discapacidad),
                                                                            temporalidad: parseInt(data.temporalidad),
                                                                            tipo_contrato: parseInt(data.tipo_contrato),
                                                                            nivel_educativo: parseInt(data.nivel_educativo),
                                                                            jornada: parseInt(data.jornada_laboral),
                                                                            ocupacion: data.ocupacion_colaborador.value,
                                                                            jubilado: data.jubilado,
                                                                            titulo: data.titulo,
                                                                            empresa: idempresa,
                                                                            pais_colaborador: idpais,
                                                                            salario: data.sueldo_base,
                                                                            bonificacion: data.bonificacion,
                                                                            fecha_alta: data.fecha_alta,
                                                                            depto: data.departamento_trabajo,
                                                                            puesto: data.puesto,
                                                                            jefe: data.jefe_inmediato,
                                                                            lugar: data.lugar_trabajo,
                                                                            horario_trabajo: data.horario_trabajo,
                                                                            institucion: data.institucion,
                                                                            irtra: data.irtra,
                                                                            incentivo_mensual: data.incentivo_mensual,
                                                                            numero_cuenta: data.numero_cuenta,
                                                                            banco: data.banco,
                                                                            frecuencia_pago: data.frecuencia_pago,
                                                                            depreciacion: data.depreciacion_vehiculo,
                                                                            gasolina: data.gasolina,
                                                                            tipo_cuenta: data.tipo_cuenta,
                                                                            medico: data.medico,
                                                                            accidente: data.accidente,
                                                                            vehiculo: data.vehiculo,
                                                                            marca: data.marca,
                                                                            placa: data.placa,
                                                                            modelo: data.modelo,
                                                                            nombre_e: data.nombre_emergencia,
                                                                            telefono_e: data.telefono_emergencia,
                                                                            parentesco: data.parentesco,
                                                                            peso: data.peso,
                                                                            altura: data.altura,
                                                                            grupo_san: data.grupo_sanguineo,
                                                                            religion: data.religion,
                                                                            vivienda: data.vivienda,
                                                                            padece_enf: data.padece_enf,
                                                                            enfermedad: data.enfermedad,
                                                                            vacuna: data.vacuna,
                                                                            tatuajes: data.tatuajes,
                                                                            pasaporte: data.pasaporte,
                                                                            visa: data.visa,
                                                                            conyuge: data.conyuge,
                                                                            nietos: data.nietos,
                                                                            detalleD: data.detalleD
                                                                        }
    
                                                                        dispatch(setLoader(true));
                                                                        api.postAttachments('/colaborador/', formData, attachments).then(() => {
                                                                            NotificationManager.success(
                                                                                'Colaborador creado',
                                                                                'Exito',
                                                                                3000
                                                                            );
                                                                            let ruta = '';
                                                                            if (valEmp === 'fage') {
                                                                                ruta = `/lfg/rr_hh/${id_emp[6]}/ingreso/${id_emp[8]}`
                                                                                dispatch(push(ruta));
                                                                            } else {
                                                                                ruta = `/rr_hh/${id_emp[5]}/ingreso/${id_emp[7]}`
                                                                                dispatch(push(ruta));
                                                                            }
                                                                        }).catch((error) => {
                                                                            NotificationManager.error(
                                                                                error.msj,
                                                                                'Error',
                                                                                2000
                                                                            );
                                                                        }).finally(() => {
                                                                            dispatch(setLoader(false));
                                                                        });
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }    
                                            }
                                        }
                                    }
                                }else{
                                    if (data.ocupacion_colaborador === undefined || data.ocupacion_colaborador === '') {
                                        Swal.fire({
                                            title: 'Colaborador',
                                            text: 'Debe de ingresar ocupación',
                                            type: 'error',
                                        })
                                    }else {
                                        if (data.sueldo_base === undefined || parseFloat(data.sueldo_base) === 0.00) {
                                            Swal.fire({
                                                title: 'Colaborador',
                                                text: 'Debe de ingresar sueldo base',
                                                type: 'error',
                                            })
                                        }else {
                                            if (data.fecha_alta === undefined || data.fecha_alta === '') {
                                                Swal.fire({
                                                    title: 'Colaborador',
                                                    text: 'Debe de ingresar fecha de alta',
                                                    type: 'error',
                                                })
                                            }else {
                                                if (data.departamento_trabajo === undefined || data.departamento_trabajo === '') {
                                                    Swal.fire({
                                                        title: 'Colaborador',
                                                        text: 'Debe de ingresar departamento colaborador',
                                                        type: 'error',
                                                    })
                                                }else {
                                                    if (data.puesto === undefined || data.puesto === '') {
                                                        Swal.fire({
                                                            title: 'Colaborador',
                                                            text: 'Debe de ingresar puesto colaborador',
                                                            type: 'error',
                                                        })
                                                    }else {
                                                        if (data.bonificacion === undefined || parseFloat(data.bonificacion) === 0.00) {
                                                            Swal.fire({
                                                                title: 'Colaborador',
                                                                text: 'Debe de ingresar bonificación',
                                                                type: 'error',
                                                            })
                                                        }else {
                                                            if (data.genero === undefined || parseInt(data.genero) === 0) {
                                                                Swal.fire({
                                                                    title: 'Colaborador',
                                                                    text: 'Debe de seleccionar género',
                                                                    type: 'error',
                                                                })
                                                            }else {
                                                                if (data.fecha_nacimiento === undefined || data.fecha_nacimiento === '') {
                                                                    Swal.fire({
                                                                        title: 'Colaborador',
                                                                        text: 'Debe de ingresar fecha de nacimiento',
                                                                        type: 'error',
                                                                    })
                                                                }else {
                                                                    const formData = {
                                                                        codigo: data.codigo,
                                                                        tipo_documento: data.tipo_documento,
                                                                        no_documento: data.documento,
                                                                        primer_nombre: data.primer_nombre,
                                                                        segundo_nombre: data.segundo_nombre,
                                                                        primer_apellido: data.primer_apellido,
                                                                        segundo_apellido: data.segundo_apellido,
                                                                        apellido_casada: data.apellido_casada,
                                                                        nit: data.nitCol,
                                                                        nacionalidad: data.nacionalidad,
                                                                        departamento: data.departamento,
                                                                        municipio: data.municipio,
                                                                        fecha_nacimiento: data.fecha_nacimiento,
                                                                        genero: parseInt(data.genero),
                                                                        estado_civil: parseInt(data.estado_civil),
                                                                        telefono: data.telefonoCol,
                                                                        igss: data.igss,
                                                                        tipo_licencia: data.tipo_licencia,
                                                                        numero_licencia: data.numero_licencia,
                                                                        correo: data.correo,
                                                                        etnia: parseInt(data.etnia),
                                                                        idioma: parseInt(data.idioma),
                                                                        direccion: data.direccionCol,
                                                                        discapacidad: parseInt(data.discapacidad),
                                                                        temporalidad: parseInt(data.temporalidad),
                                                                        tipo_contrato: parseInt(data.tipo_contrato),
                                                                        nivel_educativo: parseInt(data.nivel_educativo),
                                                                        jornada: parseInt(data.jornada_laboral),
                                                                        ocupacion: data.ocupacion_colaborador.value,
                                                                        jubilado: data.jubilado,
                                                                        titulo: data.titulo,
                                                                        empresa: idempresa,
                                                                        pais_colaborador: idpais,
                                                                        salario: data.sueldo_base,
                                                                        bonificacion: data.bonificacion,
                                                                        fecha_alta: data.fecha_alta,
                                                                        depto: data.departamento_trabajo,
                                                                        puesto: data.puesto,
                                                                        jefe: data.jefe_inmediato,
                                                                        lugar: data.lugar_trabajo,
                                                                        horario_trabajo: data.horario_trabajo,
                                                                        institucion: data.institucion,
                                                                        irtra: data.irtra,
                                                                        incentivo_mensual: data.incentivo_mensual,
                                                                        numero_cuenta: data.numero_cuenta,
                                                                        banco: data.banco,
                                                                        frecuencia_pago: data.frecuencia_pago,
                                                                        depreciacion: data.depreciacion_vehiculo,
                                                                        gasolina: data.gasolina,
                                                                        tipo_cuenta: data.tipo_cuenta,
                                                                        medico: data.medico,
                                                                        accidente: data.accidente,
                                                                        vehiculo: data.vehiculo,
                                                                        marca: data.marca,
                                                                        placa: data.placa,
                                                                        modelo: data.modelo,
                                                                        nombre_e: data.nombre_emergencia,
                                                                        telefono_e: data.telefono_emergencia,
                                                                        parentesco: data.parentesco,
                                                                        peso: data.peso,
                                                                        altura: data.altura,
                                                                        grupo_san: data.grupo_sanguineo,
                                                                        religion: data.religion,
                                                                        vivienda: data.vivienda,
                                                                        padece_enf: data.padece_enf,
                                                                        enfermedad: data.enfermedad,
                                                                        vacuna: data.vacuna,
                                                                        tatuajes: data.tatuajes,
                                                                        pasaporte: data.pasaporte,
                                                                        visa: data.visa,
                                                                        conyuge: data.conyuge,
                                                                        nietos: data.nietos,
                                                                        detalleD: data.detalleD
                                                                    }
    
                                                                    dispatch(setLoader(true));
                                                                    api.postAttachments('/colaborador/', formData, attachments).then(() => {
                                                                        NotificationManager.success(
                                                                            'Colaborador creado',
                                                                            'Exito',
                                                                            3000
                                                                        );
                                                                        let ruta = '';
                                                                        if (valEmp === 'fage') {
                                                                            ruta = `/lfg/rr_hh/${id_emp[6]}/ingreso/${id_emp[8]}`
                                                                            dispatch(push(ruta));
                                                                        } else {
                                                                            ruta = `/rr_hh/${id_emp[5]}/ingreso/${id_emp[7]}`
                                                                            dispatch(push(ruta));
                                                                        }
                                                                    }).catch((error) => {
                                                                        NotificationManager.error(
                                                                            error.msj,
                                                                            'Error',
                                                                            2000
                                                                        );
                                                                    }).finally(() => {
                                                                        dispatch(setLoader(false));
                                                                    });
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        if (data.validar_dpi === 'N') {
                            if (data.validar_nit === 'S') {
                                let valid = validar.valNit(data.nitCol);
                                if (valid === false){
                                    Swal.fire({
                                        title: 'Colaborador',
                                        text: 'Ingrese Nit válido',
                                        type: 'error',
                                    })
                                }else{
                                    if ((data.ocupacion_colaborador === undefined || data.ocupacion_colaborador === '') && data.codigoPais === 'GTM') {
                                        Swal.fire({
                                            title: 'Colaborador',
                                            text: 'Debe de ingresar ocupación',
                                            type: 'error',
                                        })
                                    }else {
                                        if (data.sueldo_base === undefined || parseFloat(data.sueldo_base) === 0.00) {
                                            Swal.fire({
                                                title: 'Colaborador',
                                                text: 'Debe de ingresar sueldo base',
                                                type: 'error',
                                            })
                                        }else {
                                            if (data.fecha_alta === undefined || data.fecha_alta === '') {
                                                Swal.fire({
                                                    title: 'Colaborador',
                                                    text: 'Debe de ingresar fecha de alta',
                                                    type: 'error',
                                                })
                                            }else {
                                                if (data.departamento_trabajo === undefined || data.departamento_trabajo === '') {
                                                    Swal.fire({
                                                        title: 'Colaborador',
                                                        text: 'Debe de ingresar departamento colaborador',
                                                        type: 'error',
                                                    })
                                                }else {
                                                    if (data.puesto === undefined || data.puesto === '') {
                                                        Swal.fire({
                                                            title: 'Colaborador',
                                                            text: 'Debe de ingresar puesto colaborador',
                                                            type: 'error',
                                                        })
                                                    }else {
                                                        if (data.bonificacion === undefined || parseFloat(data.bonificacion) === 0.00) {
                                                            Swal.fire({
                                                                title: 'Colaborador',
                                                                text: 'Debe de ingresar bonificación',
                                                                type: 'error',
                                                            })
                                                        }else {
                                                            if (data.genero === undefined || parseInt(data.genero) === 0) {
                                                                Swal.fire({
                                                                    title: 'Colaborador',
                                                                    text: 'Debe de seleccionar género',
                                                                    type: 'error',
                                                                })
                                                            }else {
                                                                if (data.fecha_nacimiento === undefined || data.fecha_nacimiento === '') {
                                                                    Swal.fire({
                                                                        title: 'Colaborador',
                                                                        text: 'Debe de ingresar fecha de nacimiento',
                                                                        type: 'error',
                                                                    })
                                                                }else {
                                                                    const formData = {
                                                                        codigo: data.codigo,
                                                                        tipo_documento: data.tipo_documento,
                                                                        no_documento: data.documento,
                                                                        primer_nombre: data.primer_nombre,
                                                                        segundo_nombre: data.segundo_nombre,
                                                                        primer_apellido: data.primer_apellido,
                                                                        segundo_apellido: data.segundo_apellido,
                                                                        apellido_casada: data.apellido_casada,
                                                                        nit: data.nitCol,
                                                                        nacionalidad: data.nacionalidad,
                                                                        departamento: data.departamento,
                                                                        municipio: data.municipio,
                                                                        fecha_nacimiento: data.fecha_nacimiento,
                                                                        genero: parseInt(data.genero),
                                                                        estado_civil: parseInt(data.estado_civil),
                                                                        telefono: data.telefonoCol,
                                                                        igss: data.igss,
                                                                        tipo_licencia: data.tipo_licencia,
                                                                        numero_licencia: data.numero_licencia,
                                                                        correo: data.correo,
                                                                        etnia: parseInt(data.etnia),
                                                                        idioma: parseInt(data.idioma),
                                                                        direccion: data.direccionCol,
                                                                        discapacidad: parseInt(data.discapacidad),
                                                                        temporalidad: parseInt(data.temporalidad),
                                                                        tipo_contrato: parseInt(data.tipo_contrato),
                                                                        nivel_educativo: parseInt(data.nivel_educativo),
                                                                        jornada: parseInt(data.jornada_laboral),
                                                                        ocupacion: data.ocupacion_colaborador.value,
                                                                        jubilado: data.jubilado,
                                                                        titulo: data.titulo,
                                                                        empresa: idempresa,
                                                                        pais_colaborador: idpais,
                                                                        salario: data.sueldo_base,
                                                                        bonificacion: data.bonificacion,
                                                                        fecha_alta: data.fecha_alta,
                                                                        depto: data.departamento_trabajo,
                                                                        puesto: data.puesto,
                                                                        jefe: data.jefe_inmediato,
                                                                        lugar: data.lugar_trabajo,
                                                                        horario_trabajo: data.horario_trabajo,
                                                                        institucion: data.institucion,
                                                                        irtra: data.irtra,
                                                                        incentivo_mensual: data.incentivo_mensual,
                                                                        numero_cuenta: data.numero_cuenta,
                                                                        banco: data.banco,
                                                                        frecuencia_pago: data.frecuencia_pago,
                                                                        depreciacion: data.depreciacion_vehiculo,
                                                                        gasolina: data.gasolina,
                                                                        tipo_cuenta: data.tipo_cuenta,
                                                                        medico: data.medico,
                                                                        accidente: data.accidente,
                                                                        vehiculo: data.vehiculo,
                                                                        marca: data.marca,
                                                                        placa: data.placa,
                                                                        modelo: data.modelo,
                                                                        nombre_e: data.nombre_emergencia,
                                                                        telefono_e: data.telefono_emergencia,
                                                                        parentesco: data.parentesco,
                                                                        peso: data.peso,
                                                                        altura: data.altura,
                                                                        grupo_san: data.grupo_sanguineo,
                                                                        religion: data.religion,
                                                                        vivienda: data.vivienda,
                                                                        padece_enf: data.padece_enf,
                                                                        enfermedad: data.enfermedad,
                                                                        vacuna: data.vacuna,
                                                                        tatuajes: data.tatuajes,
                                                                        pasaporte: data.pasaporte,
                                                                        visa: data.visa,
                                                                        conyuge: data.conyuge,
                                                                        nietos: data.nietos,
                                                                        detalleD: data.detalleD
                                                                    }
    
                                                                    dispatch(setLoader(true));
                                                                    api.postAttachments('/colaborador/', formData, attachments).then(() => {
                                                                        NotificationManager.success(
                                                                            'Colaborador creado',
                                                                            'Exito',
                                                                            3000
                                                                        );
                                                                        let ruta = '';
                                                                        if (valEmp === 'fage') {
                                                                            ruta = `/lfg/rr_hh/${id_emp[6]}/ingreso/${id_emp[8]}`
                                                                            dispatch(push(ruta));
                                                                        } else {
                                                                            ruta = `/rr_hh/${id_emp[5]}/ingreso/${id_emp[7]}`
                                                                            dispatch(push(ruta));
                                                                        }
                                                                    }).catch((error) => {
                                                                        NotificationManager.error(
                                                                            error.msj,
                                                                            'Error',
                                                                            2000
                                                                        );
                                                                    }).finally(() => {
                                                                        dispatch(setLoader(false));
                                                                    });
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }else{
                                if ((data.ocupacion_colaborador === undefined || data.ocupacion_colaborador === '') && data.codigoPais === 'GTM'){
                                    Swal.fire({
                                        title: 'Colaborador',
                                        text: 'Debe de ingresar ocupación',
                                        type: 'error',
                                    })
                                }else {
                                    if (data.sueldo_base === undefined || parseFloat(data.sueldo_base) === 0.00) {
                                        Swal.fire({
                                            title: 'Colaborador',
                                            text: 'Debe de ingresar sueldo base',
                                            type: 'error',
                                        })
                                    }else {
                                        if (data.fecha_alta === undefined || data.fecha_alta === '') {
                                            Swal.fire({
                                                title: 'Colaborador',
                                                text: 'Debe de ingresar fecha de alta',
                                                type: 'error',
                                            })
                                        }else {
                                            if (data.departamento_trabajo === undefined || data.departamento_trabajo === '') {
                                                Swal.fire({
                                                    title: 'Colaborador',
                                                    text: 'Debe de ingresar departamento colaborador',
                                                    type: 'error',
                                                })
                                            }else {
                                                if (data.puesto === undefined || data.puesto === '') {
                                                    Swal.fire({
                                                        title: 'Colaborador',
                                                        text: 'Debe de ingresar puesto colaborador',
                                                        type: 'error',
                                                    })
                                                }else {
                                                    if (data.bonificacion === undefined || parseFloat(data.bonificacion) === 0.00) {
                                                        Swal.fire({
                                                            title: 'Colaborador',
                                                            text: 'Debe de ingresar bonificación',
                                                            type: 'error',
                                                        })
                                                    }else {
                                                        if (data.genero === undefined || parseInt(data.genero) === 0) {
                                                            Swal.fire({
                                                                title: 'Colaborador',
                                                                text: 'Debe de seleccionar género',
                                                                type: 'error',
                                                            })
                                                        }else {
                                                            if (data.fecha_nacimiento === undefined || data.fecha_nacimiento === '') {
                                                                Swal.fire({
                                                                    title: 'Colaborador',
                                                                    text: 'Debe de ingresar fecha de nacimiento',
                                                                    type: 'error',
                                                                })
                                                            }else {
                                                                const formData = {
                                                                    codigo: data.codigo,
                                                                    tipo_documento: data.tipo_documento,
                                                                    no_documento: data.documento,
                                                                    primer_nombre: data.primer_nombre,
                                                                    segundo_nombre: data.segundo_nombre,
                                                                    primer_apellido: data.primer_apellido,
                                                                    segundo_apellido: data.segundo_apellido,
                                                                    apellido_casada: data.apellido_casada,
                                                                    nit: data.nitCol,
                                                                    nacionalidad: data.nacionalidad,
                                                                    departamento: data.departamento,
                                                                    municipio: data.municipio,
                                                                    fecha_nacimiento: data.fecha_nacimiento,
                                                                    genero: parseInt(data.genero),
                                                                    estado_civil: parseInt(data.estado_civil),
                                                                    telefono: data.telefonoCol,
                                                                    igss: data.igss,
                                                                    tipo_licencia: data.tipo_licencia,
                                                                    numero_licencia: data.numero_licencia,
                                                                    correo: data.correo,
                                                                    etnia: parseInt(data.etnia),
                                                                    idioma: parseInt(data.idioma),
                                                                    direccion: data.direccionCol,
                                                                    discapacidad: parseInt(data.discapacidad),
                                                                    temporalidad: parseInt(data.temporalidad),
                                                                    tipo_contrato: parseInt(data.tipo_contrato),
                                                                    nivel_educativo: parseInt(data.nivel_educativo),
                                                                    jornada: parseInt(data.jornada_laboral),
                                                                    ocupacion: data.codigoPais === 'GTM' ? data.ocupacion_colaborador.value : '',
                                                                    jubilado: data.jubilado,
                                                                    titulo: data.titulo,
                                                                    empresa: idempresa,
                                                                    pais_colaborador: idpais,
                                                                    salario: data.sueldo_base,
                                                                    bonificacion: data.bonificacion,
                                                                    fecha_alta: data.fecha_alta,
                                                                    depto: data.departamento_trabajo,
                                                                    puesto: data.puesto,
                                                                    jefe: data.jefe_inmediato,
                                                                    lugar: data.lugar_trabajo,
                                                                    horario_trabajo: data.horario_trabajo,
                                                                    institucion: data.institucion,
                                                                    irtra: data.irtra,
                                                                    incentivo_mensual: data.incentivo_mensual,
                                                                    numero_cuenta: data.numero_cuenta,
                                                                    banco: data.banco,
                                                                    frecuencia_pago: data.frecuencia_pago,
                                                                    depreciacion: data.depreciacion_vehiculo,
                                                                    gasolina: data.gasolina,
                                                                    tipo_cuenta: data.tipo_cuenta,
                                                                    medico: data.medico,
                                                                    accidente: data.accidente,
                                                                    vehiculo: data.vehiculo,
                                                                    marca: data.marca,
                                                                    placa: data.placa,
                                                                    modelo: data.modelo,
                                                                    nombre_e: data.nombre_emergencia,
                                                                    telefono_e: data.telefono_emergencia,
                                                                    parentesco: data.parentesco,
                                                                    peso: data.peso,
                                                                    altura: data.altura,
                                                                    grupo_san: data.grupo_sanguineo,
                                                                    religion: data.religion,
                                                                    vivienda: data.vivienda,
                                                                    padece_enf: data.padece_enf,
                                                                    enfermedad: data.enfermedad,
                                                                    vacuna: data.vacuna,
                                                                    tatuajes: data.tatuajes,
                                                                    pasaporte: data.pasaporte,
                                                                    visa: data.visa,
                                                                    conyuge: data.conyuge,
                                                                    nietos: data.nietos,
                                                                    detalleD: data.detalleD
                                                                }
    
                                                                dispatch(setLoader(true));
                                                                api.postAttachments('/colaborador/', formData, attachments).then(() => {
                                                                    NotificationManager.success(
                                                                        'Colaborador creado',
                                                                        'Exito',
                                                                        3000
                                                                    );
                                                                    let ruta = '';
                                                                    if (valEmp === 'fage') {
                                                                        ruta = `/lfg/rr_hh/${id_emp[6]}/ingreso/${id_emp[8]}`
                                                                        dispatch(push(ruta));
                                                                    } else {
                                                                        ruta = `/rr_hh/${id_emp[5]}/ingreso/${id_emp[7]}`
                                                                        dispatch(push(ruta));
                                                                    }
                                                                }).catch((error) => {
                                                                    NotificationManager.error(
                                                                        error.msj,
                                                                        'Error',
                                                                        2000
                                                                    );
                                                                }).finally(() => {
                                                                    dispatch(setLoader(false));
                                                                });
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }else{
                Swal.fire({
                    title: 'Colaborador',
                    text: 'Dirección de correo electrónico no válido',
                    type: 'error',
                })
            }
        }else {
            if (data.codigo === undefined || data.codigo === '') {
                Swal.fire({
                    title: 'Colaborador',
                    text: 'Debe de ingresar codigo de colaborador',
                    type: 'error',
                })
            } else {
                if (data.documento === undefined || data.documento === '') {
                    Swal.fire({
                        title: 'Colaborador',
                        text: 'Debe de ingresar numero documento de colaborador',
                        type: 'error',
                    })
                } else {
                    if (data.validar_dpi === 'S' && data.codigoPais === 'GTM') {
                        let valid = validar.valCui(data.documento);
                        if (valid[0].validar === false) {
                            Swal.fire({
                                title: 'Colaborador',
                                text: 'DPI con formato inválido',
                                type: 'error',
                            })
                        }else{
                            if (data.validar_nit === 'S' && data.codigoPais === 'GTM') {
                                let valid = validar.valNit(data.nitCol);
                                if (valid === false){
                                    Swal.fire({
                                        title: 'Colaborador',
                                        text: 'Ingrese Nit válido',
                                        type: 'error',
                                    })
                                }else{
                                    if (data.ocupacion_colaborador === undefined || data.ocupacion_colaborador === '') {
                                        Swal.fire({
                                            title: 'Colaborador',
                                            text: 'Debe de ingresar ocupación',
                                            type: 'error',
                                        })
                                    }else {
                                        if (data.sueldo_base === undefined || parseFloat(data.sueldo_base) === 0.00) {
                                            Swal.fire({
                                                title: 'Colaborador',
                                                text: 'Debe de ingresar sueldo base',
                                                type: 'error',
                                            })
                                        }else {
                                            if (data.fecha_alta === undefined || data.fecha_alta === '') {
                                                Swal.fire({
                                                    title: 'Colaborador',
                                                    text: 'Debe de ingresar fecha de alta',
                                                    type: 'error',
                                                })
                                            }else {
                                                if (data.departamento_trabajo === undefined || data.departamento_trabajo === '') {
                                                    Swal.fire({
                                                        title: 'Colaborador',
                                                        text: 'Debe de ingresar departamento colaborador',
                                                        type: 'error',
                                                    })
                                                }else {
                                                    if (data.puesto === undefined || data.puesto === '') {
                                                        Swal.fire({
                                                            title: 'Colaborador',
                                                            text: 'Debe de ingresar puesto colaborador',
                                                            type: 'error',
                                                        })
                                                    }else {
                                                        if (data.bonificacion === undefined || parseFloat(data.bonificacion) === 0.00) {
                                                            Swal.fire({
                                                                title: 'Colaborador',
                                                                text: 'Debe de ingresar bonificación',
                                                                type: 'error',
                                                            })
                                                        }else {
                                                            if (data.genero === undefined || parseInt(data.genero) === 0) {
                                                                Swal.fire({
                                                                    title: 'Colaborador',
                                                                    text: 'Debe de seleccionar género',
                                                                    type: 'error',
                                                                })
                                                            }else {
                                                                if (data.fecha_nacimiento === undefined || data.fecha_nacimiento === '') {
                                                                    Swal.fire({
                                                                        title: 'Colaborador',
                                                                        text: 'Debe de ingresar fecha de nacimiento',
                                                                        type: 'error',
                                                                    })
                                                                }else {
                                                                    const formData = {
                                                                        codigo: data.codigo,
                                                                        tipo_documento: data.tipo_documento,
                                                                        no_documento: data.documento,
                                                                        primer_nombre: data.primer_nombre,
                                                                        segundo_nombre: data.segundo_nombre,
                                                                        primer_apellido: data.primer_apellido,
                                                                        segundo_apellido: data.segundo_apellido,
                                                                        apellido_casada: data.apellido_casada,
                                                                        nit: data.nitCol,
                                                                        nacionalidad: data.nacionalidad,
                                                                        departamento: data.departamento,
                                                                        municipio: data.municipio,
                                                                        fecha_nacimiento: data.fecha_nacimiento,
                                                                        genero: parseInt(data.genero),
                                                                        estado_civil: parseInt(data.estado_civil),
                                                                        telefono: data.telefonoCol,
                                                                        igss: data.igss,
                                                                        tipo_licencia: data.tipo_licencia,
                                                                        numero_licencia: data.numero_licencia,
                                                                        correo: data.correo,
                                                                        etnia: parseInt(data.etnia),
                                                                        idioma: parseInt(data.idioma),
                                                                        direccion: data.direccionCol,
                                                                        discapacidad: parseInt(data.discapacidad),
                                                                        temporalidad: parseInt(data.temporalidad),
                                                                        tipo_contrato: parseInt(data.tipo_contrato),
                                                                        nivel_educativo: parseInt(data.nivel_educativo),
                                                                        jornada: parseInt(data.jornada_laboral),
                                                                        ocupacion: data.ocupacion_colaborador.value,
                                                                        jubilado: data.jubilado,
                                                                        titulo: data.titulo,
                                                                        empresa: idempresa,
                                                                        pais_colaborador: idpais,
                                                                        salario: data.sueldo_base,
                                                                        bonificacion: data.bonificacion,
                                                                        fecha_alta: data.fecha_alta,
                                                                        depto: data.departamento_trabajo,
                                                                        puesto: data.puesto,
                                                                        jefe: data.jefe_inmediato,
                                                                        lugar: data.lugar_trabajo,
                                                                        horario_trabajo: data.horario_trabajo,
                                                                        institucion: data.institucion,
                                                                        irtra: data.irtra,
                                                                        incentivo_mensual: data.incentivo_mensual,
                                                                        numero_cuenta: data.numero_cuenta,
                                                                        banco: data.banco,
                                                                        frecuencia_pago: data.frecuencia_pago,
                                                                        depreciacion: data.depreciacion_vehiculo,
                                                                        gasolina: data.gasolina,
                                                                        tipo_cuenta: data.tipo_cuenta,
                                                                        medico: data.medico,
                                                                        accidente: data.accidente,
                                                                        vehiculo: data.vehiculo,
                                                                        marca: data.marca,
                                                                        placa: data.placa,
                                                                        modelo: data.modelo,
                                                                        nombre_e: data.nombre_emergencia,
                                                                        telefono_e: data.telefono_emergencia,
                                                                        parentesco: data.parentesco,
                                                                        peso: data.peso,
                                                                        altura: data.altura,
                                                                        grupo_san: data.grupo_sanguineo,
                                                                        religion: data.religion,
                                                                        vivienda: data.vivienda,
                                                                        padece_enf: data.padece_enf,
                                                                        enfermedad: data.enfermedad,
                                                                        vacuna: data.vacuna,
                                                                        tatuajes: data.tatuajes,
                                                                        pasaporte: data.pasaporte,
                                                                        visa: data.visa,
                                                                        conyuge: data.conyuge,
                                                                        nietos: data.nietos,
                                                                        detalleD: data.detalleD
                                                                    }

                                                                    dispatch(setLoader(true));
                                                                    api.postAttachments('/colaborador/', formData, attachments).then(() => {
                                                                        NotificationManager.success(
                                                                            'Colaborador creado',
                                                                            'Exito',
                                                                            3000
                                                                        );
                                                                        let ruta = '';
                                                                        if (valEmp === 'fage') {
                                                                            ruta = `/lfg/rr_hh/${id_emp[6]}/ingreso/${id_emp[8]}`
                                                                            dispatch(push(ruta));
                                                                        } else {
                                                                            ruta = `/rr_hh/${id_emp[5]}/ingreso/${id_emp[7]}`
                                                                            dispatch(push(ruta));
                                                                        }
                                                                    }).catch((error) => {
                                                                        NotificationManager.error(
                                                                            error.msj,
                                                                            'Error',
                                                                            2000
                                                                        );
                                                                    }).finally(() => {
                                                                        dispatch(setLoader(false));
                                                                    });
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }    
                                        }
                                    }
                                }
                            }else{
                                if ((data.ocupacion_colaborador === undefined || data.ocupacion_colaborador === '') && data.codigoPais === 'GTM') {
                                    Swal.fire({
                                        title: 'Colaborador',
                                        text: 'Debe de ingresar ocupación',
                                        type: 'error',
                                    })
                                }else {
                                    if (data.sueldo_base === undefined || parseFloat(data.sueldo_base) === 0.00) {
                                        Swal.fire({
                                            title: 'Colaborador',
                                            text: 'Debe de ingresar sueldo base',
                                            type: 'error',
                                        })
                                    }else {
                                        if (data.fecha_alta === undefined || data.fecha_alta === '') {
                                            Swal.fire({
                                                title: 'Colaborador',
                                                text: 'Debe de ingresar fecha de alta',
                                                type: 'error',
                                            })
                                        }else {
                                            if (data.departamento_trabajo === undefined || data.departamento_trabajo === '') {
                                                Swal.fire({
                                                    title: 'Colaborador',
                                                    text: 'Debe de ingresar departamento colaborador',
                                                    type: 'error',
                                                })
                                            }else {
                                                if (data.puesto === undefined || data.puesto === '') {
                                                    Swal.fire({
                                                        title: 'Colaborador',
                                                        text: 'Debe de ingresar puesto colaborador',
                                                        type: 'error',
                                                    })
                                                }else {
                                                    if (data.bonificacion === undefined || parseFloat(data.bonificacion) === 0.00) {
                                                        Swal.fire({
                                                            title: 'Colaborador',
                                                            text: 'Debe de ingresar bonificación',
                                                            type: 'error',
                                                        })
                                                    }else {
                                                        if (data.genero === undefined || parseInt(data.genero) === 0) {
                                                            Swal.fire({
                                                                title: 'Colaborador',
                                                                text: 'Debe de seleccionar género',
                                                                type: 'error',
                                                            })
                                                        }else {
                                                            if (data.fecha_nacimiento === undefined || data.fecha_nacimiento === '') {
                                                                Swal.fire({
                                                                    title: 'Colaborador',
                                                                    text: 'Debe de ingresar fecha de nacimiento',
                                                                    type: 'error',
                                                                })
                                                            }else {
                                                                const formData = {
                                                                    codigo: data.codigo,
                                                                    tipo_documento: data.tipo_documento,
                                                                    no_documento: data.documento,
                                                                    primer_nombre: data.primer_nombre,
                                                                    segundo_nombre: data.segundo_nombre,
                                                                    primer_apellido: data.primer_apellido,
                                                                    segundo_apellido: data.segundo_apellido,
                                                                    apellido_casada: data.apellido_casada,
                                                                    nit: data.nitCol,
                                                                    nacionalidad: data.nacionalidad,
                                                                    departamento: data.departamento,
                                                                    municipio: data.municipio,
                                                                    fecha_nacimiento: data.fecha_nacimiento,
                                                                    genero: parseInt(data.genero),
                                                                    estado_civil: parseInt(data.estado_civil),
                                                                    telefono: data.telefonoCol,
                                                                    igss: data.igss,
                                                                    tipo_licencia: data.tipo_licencia,
                                                                    numero_licencia: data.numero_licencia,
                                                                    correo: data.correo,
                                                                    etnia: parseInt(data.etnia),
                                                                    idioma: parseInt(data.idioma),
                                                                    direccion: data.direccionCol,
                                                                    discapacidad: parseInt(data.discapacidad),
                                                                    temporalidad: parseInt(data.temporalidad),
                                                                    tipo_contrato: parseInt(data.tipo_contrato),
                                                                    nivel_educativo: parseInt(data.nivel_educativo),
                                                                    jornada: parseInt(data.jornada_laboral),
                                                                    ocupacion: data.ocupacion_colaborador.value,
                                                                    jubilado: data.jubilado,
                                                                    titulo: data.titulo,
                                                                    empresa: idempresa,
                                                                    pais_colaborador: idpais,
                                                                    salario: data.sueldo_base,
                                                                    bonificacion: data.bonificacion,
                                                                    fecha_alta: data.fecha_alta,
                                                                    depto: data.departamento_trabajo,
                                                                    puesto: data.puesto,
                                                                    jefe: data.jefe_inmediato,
                                                                    lugar: data.lugar_trabajo,
                                                                    horario_trabajo: data.horario_trabajo,
                                                                    institucion: data.institucion,
                                                                    irtra: data.irtra,
                                                                    incentivo_mensual: data.incentivo_mensual,
                                                                    numero_cuenta: data.numero_cuenta,
                                                                    banco: data.banco,
                                                                    frecuencia_pago: data.frecuencia_pago,
                                                                    depreciacion: data.depreciacion_vehiculo,
                                                                    gasolina: data.gasolina,
                                                                    tipo_cuenta: data.tipo_cuenta,
                                                                    medico: data.medico,
                                                                    accidente: data.accidente,
                                                                    vehiculo: data.vehiculo,
                                                                    marca: data.marca,
                                                                    placa: data.placa,
                                                                    modelo: data.modelo,
                                                                    nombre_e: data.nombre_emergencia,
                                                                    telefono_e: data.telefono_emergencia,
                                                                    parentesco: data.parentesco,
                                                                    peso: data.peso,
                                                                    altura: data.altura,
                                                                    grupo_san: data.grupo_sanguineo,
                                                                    religion: data.religion,
                                                                    vivienda: data.vivienda,
                                                                    padece_enf: data.padece_enf,
                                                                    enfermedad: data.enfermedad,
                                                                    vacuna: data.vacuna,
                                                                    tatuajes: data.tatuajes,
                                                                    pasaporte: data.pasaporte,
                                                                    visa: data.visa,
                                                                    conyuge: data.conyuge,
                                                                    nietos: data.nietos,
                                                                    detalleD: data.detalleD
                                                                }

                                                                dispatch(setLoader(true));
                                                                api.postAttachments('/colaborador/', formData, attachments).then(() => {
                                                                    NotificationManager.success(
                                                                        'Colaborador creado',
                                                                        'Exito',
                                                                        3000
                                                                    );
                                                                    let ruta = '';
                                                                    if (valEmp === 'fage') {
                                                                        ruta = `/lfg/rr_hh/${id_emp[6]}/ingreso/${id_emp[8]}`
                                                                        dispatch(push(ruta));
                                                                    } else {
                                                                        ruta = `/rr_hh/${id_emp[5]}/ingreso/${id_emp[7]}`
                                                                        dispatch(push(ruta));
                                                                    }
                                                                }).catch((error) => {
                                                                    NotificationManager.error(
                                                                        error.msj,
                                                                        'Error',
                                                                        2000
                                                                    );
                                                                }).finally(() => {
                                                                    dispatch(setLoader(false));
                                                                });
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    if (data.validar_dpi === 'N') {
                        if (data.validar_nit === 'S') {
                            let valid = validar.valNit(data.nitCol);
                            if (valid === false){
                                Swal.fire({
                                    title: 'Colaborador',
                                    text: 'Ingrese Nit válido',
                                    type: 'error',
                                })
                            }else{
                                if (data.ocupacion_colaborador === undefined || data.ocupacion_colaborador === '') {
                                    Swal.fire({
                                        title: 'Colaborador',
                                        text: 'Debe de ingresar ocupación',
                                        type: 'error',
                                    })
                                }else {
                                    if (data.sueldo_base === undefined || parseFloat(data.sueldo_base) === 0.00) {
                                        Swal.fire({
                                            title: 'Colaborador',
                                            text: 'Debe de ingresar sueldo base',
                                            type: 'error',
                                        })
                                    }else {
                                        if (data.fecha_alta === undefined || data.fecha_alta === '') {
                                            Swal.fire({
                                                title: 'Colaborador',
                                                text: 'Debe de ingresar fecha de alta',
                                                type: 'error',
                                            })
                                        }else {
                                            if (data.departamento_trabajo === undefined || data.departamento_trabajo === '') {
                                                Swal.fire({
                                                    title: 'Colaborador',
                                                    text: 'Debe de ingresar departamento colaborador',
                                                    type: 'error',
                                                })
                                            }else {
                                                if (data.puesto === undefined || data.puesto === '') {
                                                    Swal.fire({
                                                        title: 'Colaborador',
                                                        text: 'Debe de ingresar puesto colaborador',
                                                        type: 'error',
                                                    })
                                                }else {
                                                    if (data.bonificacion === undefined || parseFloat(data.bonificacion) === 0.00) {
                                                        Swal.fire({
                                                            title: 'Colaborador',
                                                            text: 'Debe de ingresar bonificación',
                                                            type: 'error',
                                                        })
                                                    }else {
                                                        if (data.genero === undefined || parseInt(data.genero) === 0) {
                                                            Swal.fire({
                                                                title: 'Colaborador',
                                                                text: 'Debe de seleccionar género',
                                                                type: 'error',
                                                            })
                                                        }else {
                                                            if (data.fecha_nacimiento === undefined || data.fecha_nacimiento === '') {
                                                                Swal.fire({
                                                                    title: 'Colaborador',
                                                                    text: 'Debe de ingresar fecha de nacimiento',
                                                                    type: 'error',
                                                                })
                                                            }else {
                                                                const formData = {
                                                                    codigo: data.codigo,
                                                                    tipo_documento: data.tipo_documento,
                                                                    no_documento: data.documento,
                                                                    primer_nombre: data.primer_nombre,
                                                                    segundo_nombre: data.segundo_nombre,
                                                                    primer_apellido: data.primer_apellido,
                                                                    segundo_apellido: data.segundo_apellido,
                                                                    apellido_casada: data.apellido_casada,
                                                                    nit: data.nitCol,
                                                                    nacionalidad: data.nacionalidad,
                                                                    departamento: data.departamento,
                                                                    municipio: data.municipio,
                                                                    fecha_nacimiento: data.fecha_nacimiento,
                                                                    genero: parseInt(data.genero),
                                                                    estado_civil: parseInt(data.estado_civil),
                                                                    telefono: data.telefonoCol,
                                                                    igss: data.igss,
                                                                    tipo_licencia: data.tipo_licencia,
                                                                    numero_licencia: data.numero_licencia,
                                                                    correo: data.correo,
                                                                    etnia: parseInt(data.etnia),
                                                                    idioma: parseInt(data.idioma),
                                                                    direccion: data.direccionCol,
                                                                    discapacidad: parseInt(data.discapacidad),
                                                                    temporalidad: parseInt(data.temporalidad),
                                                                    tipo_contrato: parseInt(data.tipo_contrato),
                                                                    nivel_educativo: parseInt(data.nivel_educativo),
                                                                    jornada: parseInt(data.jornada_laboral),
                                                                    ocupacion: data.ocupacion_colaborador.value,
                                                                    jubilado: data.jubilado,
                                                                    titulo: data.titulo,
                                                                    empresa: idempresa,
                                                                    pais_colaborador: idpais,
                                                                    salario: data.sueldo_base,
                                                                    bonificacion: data.bonificacion,
                                                                    fecha_alta: data.fecha_alta,
                                                                    depto: data.departamento_trabajo,
                                                                    puesto: data.puesto,
                                                                    jefe: data.jefe_inmediato,
                                                                    lugar: data.lugar_trabajo,
                                                                    horario_trabajo: data.horario_trabajo,
                                                                    institucion: data.institucion,
                                                                    irtra: data.irtra,
                                                                    incentivo_mensual: data.incentivo_mensual,
                                                                    numero_cuenta: data.numero_cuenta,
                                                                    banco: data.banco,
                                                                    frecuencia_pago: data.frecuencia_pago,
                                                                    depreciacion: data.depreciacion_vehiculo,
                                                                    gasolina: data.gasolina,
                                                                    tipo_cuenta: data.tipo_cuenta,
                                                                    medico: data.medico,
                                                                    accidente: data.accidente,
                                                                    vehiculo: data.vehiculo,
                                                                    marca: data.marca,
                                                                    placa: data.placa,
                                                                    modelo: data.modelo,
                                                                    nombre_e: data.nombre_emergencia,
                                                                    telefono_e: data.telefono_emergencia,
                                                                    parentesco: data.parentesco,
                                                                    peso: data.peso,
                                                                    altura: data.altura,
                                                                    grupo_san: data.grupo_sanguineo,
                                                                    religion: data.religion,
                                                                    vivienda: data.vivienda,
                                                                    padece_enf: data.padece_enf,
                                                                    enfermedad: data.enfermedad,
                                                                    vacuna: data.vacuna,
                                                                    tatuajes: data.tatuajes,
                                                                    pasaporte: data.pasaporte,
                                                                    visa: data.visa,
                                                                    conyuge: data.conyuge,
                                                                    nietos: data.nietos,
                                                                    detalleD: data.detalleD
                                                                }

                                                                dispatch(setLoader(true));
                                                                api.postAttachments('/colaborador/', formData, attachments).then(() => {
                                                                    NotificationManager.success(
                                                                        'Colaborador creado',
                                                                        'Exito',
                                                                        3000
                                                                    );
                                                                    let ruta = '';
                                                                    if (valEmp === 'fage') {
                                                                        ruta = `/lfg/rr_hh/${id_emp[6]}/ingreso/${id_emp[8]}`
                                                                        dispatch(push(ruta));
                                                                    } else {
                                                                        ruta = `/rr_hh/${id_emp[5]}/ingreso/${id_emp[7]}`
                                                                        dispatch(push(ruta));
                                                                    }
                                                                }).catch((error) => {
                                                                    NotificationManager.error(
                                                                        error.msj,
                                                                        'Error',
                                                                        2000
                                                                    );
                                                                }).finally(() => {
                                                                    dispatch(setLoader(false));
                                                                });
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }else{
                            if ((data.ocupacion_colaborador === undefined || data.ocupacion_colaborador === '') && data.codigoPais === 'GTM') {
                                Swal.fire({
                                    title: 'Colaborador',
                                    text: 'Debe de ingresar ocupación',
                                    type: 'error',
                                })
                            }else {
                                if (data.sueldo_base === undefined || parseFloat(data.sueldo_base) === 0.00) {
                                    Swal.fire({
                                        title: 'Colaborador',
                                        text: 'Debe de ingresar sueldo base',
                                        type: 'error',
                                    })
                                }else {
                                    if (data.fecha_alta === undefined || data.fecha_alta === '') {
                                        Swal.fire({
                                            title: 'Colaborador',
                                            text: 'Debe de ingresar fecha de alta',
                                            type: 'error',
                                        })
                                    }else {
                                        if (data.departamento_trabajo === undefined || data.departamento_trabajo === '') {
                                            Swal.fire({
                                                title: 'Colaborador',
                                                text: 'Debe de ingresar departamento colaborador',
                                                type: 'error',
                                            })
                                        }else {
                                            if (data.puesto === undefined || data.puesto === '') {
                                                Swal.fire({
                                                    title: 'Colaborador',
                                                    text: 'Debe de ingresar puesto colaborador',
                                                    type: 'error',
                                                })
                                            }else {
                                                if (data.bonificacion === undefined || parseFloat(data.bonificacion) === 0.00) {
                                                    Swal.fire({
                                                        title: 'Colaborador',
                                                        text: 'Debe de ingresar bonificación',
                                                        type: 'error',
                                                    })
                                                }else {
                                                    if (data.genero === undefined || parseInt(data.genero) === 0) {
                                                        Swal.fire({
                                                            title: 'Colaborador',
                                                            text: 'Debe de seleccionar género',
                                                            type: 'error',
                                                        })
                                                    }else {
                                                        if (data.fecha_nacimiento === undefined || data.fecha_nacimiento === '') {
                                                            Swal.fire({
                                                                title: 'Colaborador',
                                                                text: 'Debe de ingresar fecha de nacimiento',
                                                                type: 'error',
                                                            })
                                                        }else {
                                                            const formData = {
                                                                codigo: data.codigo,
                                                                tipo_documento: data.tipo_documento,
                                                                no_documento: data.documento,
                                                                primer_nombre: data.primer_nombre,
                                                                segundo_nombre: data.segundo_nombre,
                                                                primer_apellido: data.primer_apellido,
                                                                segundo_apellido: data.segundo_apellido,
                                                                apellido_casada: data.apellido_casada,
                                                                nit: data.nitCol,
                                                                nacionalidad: data.nacionalidad,
                                                                departamento: data.departamento,
                                                                municipio: data.municipio,
                                                                fecha_nacimiento: data.fecha_nacimiento,
                                                                genero: parseInt(data.genero),
                                                                estado_civil: parseInt(data.estado_civil),
                                                                telefono: data.telefonoCol,
                                                                igss: data.igss,
                                                                tipo_licencia: data.tipo_licencia,
                                                                numero_licencia: data.numero_licencia,
                                                                correo: data.correo,
                                                                etnia: parseInt(data.etnia),
                                                                idioma: parseInt(data.idioma),
                                                                direccion: data.direccionCol,
                                                                discapacidad: parseInt(data.discapacidad),
                                                                temporalidad: parseInt(data.temporalidad),
                                                                tipo_contrato: parseInt(data.tipo_contrato),
                                                                nivel_educativo: parseInt(data.nivel_educativo),
                                                                jornada: parseInt(data.jornada_laboral),
                                                                ocupacion: data.codigoPais === 'GTM' ? data.ocupacion_colaborador.value : '',
                                                                jubilado: data.jubilado,
                                                                titulo: data.titulo,
                                                                empresa: idempresa,
                                                                pais_colaborador: idpais,
                                                                salario: data.sueldo_base,
                                                                bonificacion: data.bonificacion,
                                                                fecha_alta: data.fecha_alta,
                                                                depto: data.departamento_trabajo,
                                                                puesto: data.puesto,
                                                                jefe: data.jefe_inmediato,
                                                                lugar: data.lugar_trabajo,
                                                                horario_trabajo: data.horario_trabajo,
                                                                institucion: data.institucion,
                                                                irtra: data.irtra,
                                                                incentivo_mensual: data.incentivo_mensual,
                                                                numero_cuenta: data.numero_cuenta,
                                                                banco: data.banco,
                                                                frecuencia_pago: data.frecuencia_pago,
                                                                depreciacion: data.depreciacion_vehiculo,
                                                                gasolina: data.gasolina,
                                                                tipo_cuenta: data.tipo_cuenta,
                                                                medico: data.medico,
                                                                accidente: data.accidente,
                                                                vehiculo: data.vehiculo,
                                                                marca: data.marca,
                                                                placa: data.placa,
                                                                modelo: data.modelo,
                                                                nombre_e: data.nombre_emergencia,
                                                                telefono_e: data.telefono_emergencia,
                                                                parentesco: data.parentesco,
                                                                peso: data.peso,
                                                                altura: data.altura,
                                                                grupo_san: data.grupo_sanguineo,
                                                                religion: data.religion,
                                                                vivienda: data.vivienda,
                                                                padece_enf: data.padece_enf,
                                                                enfermedad: data.enfermedad,
                                                                vacuna: data.vacuna,
                                                                tatuajes: data.tatuajes,
                                                                pasaporte: data.pasaporte,
                                                                visa: data.visa,
                                                                conyuge: data.conyuge,
                                                                nietos: data.nietos,
                                                                detalleD: data.detalleD
                                                            }

                                                            dispatch(setLoader(true));
                                                            api.postAttachments('/colaborador/', formData, attachments).then(() => {
                                                                NotificationManager.success(
                                                                    'Colaborador creado',
                                                                    'Exito',
                                                                    3000
                                                                );
                                                                let ruta = '';
                                                                if (valEmp === 'fage') {
                                                                    ruta = `/lfg/rr_hh/${id_emp[6]}/ingreso/${id_emp[8]}`
                                                                    dispatch(push(ruta));
                                                                } else {
                                                                    ruta = `/rr_hh/${id_emp[5]}/ingreso/${id_emp[7]}`
                                                                    dispatch(push(ruta));
                                                                }
                                                            }).catch((error) => {
                                                                NotificationManager.error(
                                                                    error.msj,
                                                                    'Error',
                                                                    2000
                                                                );
                                                            }).finally(() => {
                                                                dispatch(setLoader(false));
                                                            });
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

export const modificarColaborador = (data = {}, attachments = []) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormColaborador.values;
    
    const id = data.id;
    let ocupacion = data.ocupacion_colaborador;
    if (ocupacion === undefined) {
        ocupacion = data.ocupacion;
    } else {
        ocupacion = data.ocupacion_colaborador.value;
    }
    let idempresa = '';
    if (valEmp === 'fage') {
        idempresa = id_emp[6];
    } else {
        idempresa = id_emp[5];
    }
    const formData = {
        id: id,
        codigo: data.codigo,
        tipo_documento: data.tipo_documento,
        no_documento: data.documento,
        primer_nombre: data.primer_nombre,
        segundo_nombre: data.segundo_nombre,
        primer_apellido: data.primer_apellido,
        segundo_apellido: data.segundo_apellido,
        apellido_casada: data.apellido_casada,
        nit: data.nitCol,
        nacionalidad: data.nacionalidad,
        departamento: data.departamento,
        municipio: data.municipio,
        fecha_nacimiento: data.fecha_nacimiento,
        genero: parseInt(data.genero),
        estado_civil: parseInt(data.estado_civil),
        telefono: data.telefonoCol,
        igss: data.igss,
        tipo_licencia: data.tipo_licencia,
        numero_licencia: data.numero_licencia,
        correo: data.correo,
        etnia: parseInt(data.etnia),
        idioma: parseInt(data.idioma),
        direccion: data.direccionCol,
        discapacidad: parseInt(data.discapacidad),
        temporalidad: parseInt(data.temporalidad),
        tipo_contrato: parseInt(data.tipo_contrato),
        nivel_educativo: parseInt(data.nivel_educativo),
        jornada: parseInt(data.jornada_laboral),
        ocupacion: ocupacion,
        titulo: data.titulo,
        empresa: idempresa,
        jubilado: data.jubilado,
        irtra: data.irtra,
        incentivo_mensual: data.incentivo_mensual,
        depreciacion: data.depreciacion_vehiculo,
        gasolina: data.gasolina,
        medico: data.medico,
        accidente: data.accidente,
        vehiculo: data.vehiculo,
        marca: data.marca,
        placa: data.placa,
        modelo: data.modelo,
        nombre_e: data.nombre_emergencia,
        telefono_e: data.telefono_emergencia,
        parentesco: data.parentesco,
        peso: data.peso,
        altura: data.altura,
        grupo_san: data.grupo_sanguineo,
        religion: data.religion,
        vivienda: data.vivienda,
        padece_enf: data.padece_enf,
        enfermedad: data.enfermedad,
        vacuna: data.vacuna,
        tatuajes: data.tatuajes,
        pasaporte: data.pasaporte,
        visa: data.visa,
        conyuge: data.conyuge,
        nietos: data.nietos,
        depto: data.departamento_trabajo,
        puesto: data.puesto,
        jefe: data.jefe_inmediato,
        lugar: data.lugar_trabajo,
        horario_trabajo: data.horario_trabajo,
        numero_cuenta: data.numero_cuenta,
        banco: data.banco,
        frecuencia_pago: data.frecuencia_pago,
        tipo_cuenta: data.tipo_cuenta,
        sueldo: data.sueldo_base,
        bonificacion: data.bonificacion,
        fecha_alta: data.fecha_alta
    }
    
    dispatch(setLoader(true));
    api.putAttachments(`/colaborador/${id}/`, formData, attachments).then(() => {
        NotificationManager.success(
            'Colaborador modificado correctamente',
            'Exito',
            3000
        );
        let ruta = '';
        if (valEmp === 'fage') {
            ruta = `/lfg/rr_hh/${id_emp[6]}/ingreso/${id_emp[8]}`
            dispatch(push(ruta));
        } else {
            ruta = `/rr_hh/${id_emp[5]}/ingreso/${id_emp[7]}`
            dispatch(push(ruta));
        }
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al modificar colaborador',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/colaborador/${id}`).then((response) => {
        response.documento = response.dpi;
        response.tipo_documento = response.idDocumento;
        response.titulo = response.titulo_profesion;
        response.sueldo_base = response.sueldoBase;
        response.jefe_inmediato = response.jefe;
        response.lugar_trabajo = response.lugarTrabajo;
        response.horario_trabajo = response.horarioTrabajo;
        response.idioma = parseInt(response.idioma);
        response.marca = response.marca_vehiculo;
        response.placa = response.placa_vehiculo;
        response.modelo = response.modelo_vehiculo;
        response.parentesco = response.parentesco_emergencia;
        response.frecuencia_pago = response.frecuenciaPago;
        response.numero_cuenta = response.numeroCuenta;
        response.tipo_cuenta = response.tipoCuenta;
        response.vacuna = response.vacuna_covid;
        response.tatuajes = response.posee_tatuajes;
        response.vivienda = response.casa;
        response.padece_enf = response.padece_enfermedad;
        response.nitCol = response.nit;
        response.telefonoCol = response.telefono;
        response.direccionCol = response.direccion;
        response.municipio = response.lugar_nacimiento;
        localStorage.setItem('id_detalle_familia', response.id);
        if (response.nacionalidad){
            dispatch(listarDepto(response.nacionalidad))
            if (response.departamento != null){
                dispatch(listarMunicipio(response.departamento))
            }
        }
        dispatch(leerDetalleFamilia(response.id));
        dispatch(initializeForm('FormColaborador', response));
        dispatch({ type: GUARDAR_COL, lectura: response });
    }).catch((error) => {

        NotificationManager.error(
            'Ocurrió un error al consultar colaborador',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

const leerDetalleFamilia = id => (dispatch) => {
    api.get(`/colaborador_familia/${id}`).then((response) => {
        dispatch({ type: DETALLE_FAMILIA, detalleFa: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};

export const getModulo = (id, modulo) => (dispatch) => {
    const params = { id, modulo }
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response) => {
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const listarPaises = () => (dispatch) => {
    return api.get("/paises").then(response => {
        const pais = [];
        response.results.forEach(item => {
            pais.push({
                value: item.id,
                label: item.pais.toUpperCase()
            })
        })
        dispatch({ type: LISTADO_PAISES, listaP: pais });
    }).catch(error => {
        NotificationManager.error(
            'Ocurrió un error al listar paises',
            'Error',
            8000
        );
    })
}

export const listarOcupacion = (search) => () => {
    const params = { search }
    return api.get('/ocupacion', params).then(data => {
        const ocupacion = [];
        if (data) {
            data.forEach(item => {
                ocupacion.push({
                    value: item.id,
                    label: item.codigo + ' - ' + item.descripcion
                })
            })
        }
        return ocupacion;
    }).catch((error) => {
        return [];
    })
}

const listarDepto = (id) => (dispatch) => {
    let params = { id }
    return api.get("/depto", params).then(response => {
        const depto = [];
        response.forEach(item => {
            depto.push({
                value: item.id,
                label: item.descripcion
            })
        })
        dispatch({ type: LISTADO_DEPTO, listaDepto: depto });
    }).catch(error => {
        NotificationManager.error(
            'Ocurrió un error al listar departamentos',
            'Error',
            8000
        );
    })
}

const listarMunicipio = (id) => (dispatch) => {
    let params = { id }
    return api.get("/municipio", params).then(response => {
        const municipio = [];
        response.forEach(item => {
            municipio.push({
                value: item.id,
                label: item.descripcion
            })
        })
        dispatch({ type: LISTADO_MUNICIPIO, listaMuni: municipio });
    }).catch(error => {
        NotificationManager.error(
            'Ocurrió un error al listar municipios',
            'Error',
            8000
        );
    })
}

const listarDepartamentos = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = '';
    let params = '';
    let reporte = 'rep';
    if (valEmp === 'fage') {
        id = id_emp[6];
        params = { id, reporte }
    } else {
        id = id_emp[5];
        params = { id, reporte }
    }
    return api.get("/departamento", params).then(response => {
        const depto = [];
        response.forEach(item => {
            depto.push({
                value: item.id,
                label: item.descripcion
            })
        })
        dispatch({ type: LISTADO_DEPARTAMENTOS, listaDepartamento: depto });
    }).catch(error => {
        NotificationManager.error(
            'Ocurrió un error al listar departamentos',
            'Error',
            8000
        );
    })
}

const listarPuestos = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = '';
    let params = '';
    let reporte = 'rep';
    if (valEmp === 'fage') {
        id = id_emp[6];
        params = { id, reporte };
    } else {
        id = id_emp[5];
        params = { id, reporte };
    }
    return api.get("/puesto", params).then(response => {
        const puesto = [];
        response.forEach(item => {
            puesto.push({
                value: item.id,
                label: item.descripcion
            })
        })
        dispatch({ type: LISTADO_PUESTOS, listaPuesto: puesto });
    }).catch(error => {
        NotificationManager.error(
            'Ocurrió un error al listar puestos',
            'Error',
            8000
        );
    })
}

export const imprimirAlta = id => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params = '';
    let idE = '';
    let idCol = '';
    let reporte = '';
    if (valEmp === 'fage') {
        idE = id_emp[6];
        idCol = id
        reporte = 'imprimirAlta';
        params = { idE, reporte, idCol };
    } else {
        idE = id_emp[5];
        idCol = id
        reporte = 'imprimirAlta';
        params = { idE, reporte, idCol };
    }
    dispatch(setLoader(true));
    api.getPdf('/colaborador', params).then((response) => {
        if (response.headers['content-type'] == 'application/pdf') {
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'reporte_alta_colaborador.pdf';
            window.open(link, '_blank');
            return false;
        }
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al generar reporte',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

let detalle = [];
export const agregarLinea = () => (dispatch, getStore) => {
    const data = getStore().form.FormColaborador.values;
    if (data.nombre_hijo === undefined || data.nombre_hijo === null) {
        Swal.fire({
            title: 'Colaborador',
            text: 'Debe de ingresar nombre',
            type: 'error',
        })
    } else {
        if (data.genero_hijo === undefined || data.genero_hijo === null) {
            Swal.fire({
                title: 'Colaborador',
                text: 'Debe de seleccionar género',
                type: 'error',
            })
        } else {
            if (data.fecha_nacimiento_hijo === undefined || data.fecha_nacimiento_hijo === null) {
                Swal.fire({
                    title: 'Colaborador',
                    text: 'Debe de ingresar fecha de nacimiento',
                    type: 'error',
                })
            } else {

                const fecha = new Date();
                const fecha_nac = new Date(data.fecha_nacimiento_hijo);
                const edad = Math.abs(moment.duration(fecha_nac - fecha).years());

                detalle.push({
                    id: data.nombre_hijo,
                    nombre: data.nombre_hijo,
                    fecha_nac: data.fecha_nacimiento_hijo,
                    genero: data.genero_hijo,
                    edad: edad
                })

                data.nombre_hijo = '';
                data.fecha_nacimiento_hijo = '';
                data.detalleD = detalle;
                NotificationManager.success(
                    'Dato ingresado correctamente',
                    'Exito',
                    3000
                );
                dispatch(actualizar());
            }
        }
    }

}

const actualizar = () => (dispatch, getStore) => {
    const data = getStore().form.FormColaborador.values;
    dispatch(initializeForm('FormColaborador', data));
    dispatch({ type: DETALLE, detalle: data.detalleD })
};

const vaciar = () => (dispatch) => {
    detalle = [];
    dispatch(initializeForm('FormColaborador'));
    dispatch({ type: DETALLE, detalle: detalle });
};

const eliminarLineaFamilia = id => (dispatch) => {
    const detalle = localStorage.getItem('id_detalle_familia');
    dispatch(setLoader(true));
    api.eliminar(`/colaborador_familia/${id}`).then(() => {
        dispatch(leerDetalleFamilia(detalle));
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const agregarNuevaLineaFamilia = () => (dispatch, getStore) => {
    const data = getStore().form.FormColaborador.values;

    if (data.nombre_hijo === undefined || data.nombre_hijo === null) {
        Swal.fire({
            title: 'Colaborador',
            text: 'Debe de ingresar nombre',
            type: 'error',
        })
    } else {
        if (data.fecha_nacimiento_hijo === undefined || data.fecha_nacimiento_hijo === null) {
            Swal.fire({
                title: 'Colaborador',
                text: 'Debe de ingresar fecha de nacimiento',
                type: 'error',
            })
        } else {

            const fecha = new Date();
            const fecha_nac = new Date(data.fecha_nacimiento_hijo);
            const edad = Math.abs(moment.duration(fecha_nac - fecha).years());

            const formData = {
                id: localStorage.getItem('id_detalle_familia'),
                nombre: data.nombre_hijo,
                fecha_nac: data.fecha_nacimiento_hijo,
                genero: data.genero_hijo,
                edad: edad
            }

            dispatch(setLoader(true));
            api.post('/colaborador_familia/', formData).then((response) => {

                dispatch(leerDetalleFamilia(response.id));
                NotificationManager.success(
                    'Dato ingresado correctamente',
                    'Exito',
                    3000
                );
                dispatch(leerDetalleProductos(detalle));
            }).catch((error) => {
                dispatch(setLoader(false));
            });
        }
    }
}

export const parametrosEmpresa = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params = '';
    let id = '';
    let pais = '';
    
    if (valEmp === 'fage') {
        id = id_emp[6];
        pais = id_emp[8];
        params = { id, pais }
    } else {
        id = id_emp[5];
        pais = id_emp[7];
        params = { id, pais }
    }
    dispatch(setLoader(true));
    api.get('/empresa/empresa_parametrosRH', params).then((response) => {
        dispatch(initializeForm('FormColaborador', response));
        dispatch({ type: PARAMETRO, datos: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar parametros',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

const eliminarLinea = (id) => (dispatch) => {
    detalle.forEach((element, index) => {
        if (element.id === id ) {
            detalle.splice(index, 1);
        }
    })
    dispatch(actualizar());
};

export const listarDocumentos = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params;
    let id;
    let pais;
    let page = 1;
    if (valEmp === 'fage'){
        id = id_emp[6];
        pais = id_emp[8];
        params = { page, id, pais };
    }else{
        id = id_emp[5];
        pais = id_emp[7];
        params = { page, id, pais };
    }
    api.get('/pais_colaborador/listar_documentos', params).then((response)=>{
        const docs = [];
        response.forEach(item => {
            docs.push({
                value: item.id,
                label: item.documento
            })
        })
        dispatch({ type: LISTADO_DOCUMENTOS, documentos: docs });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar documentos',
            'Error',
            2000
        );
    })
}

export const actions = {
    listar,
    registroColaborador,
    modificarColaborador,
    leer,
    getModulo,
    listarPaises,
    listarOcupacion,
    listarDepto,
    listarMunicipio,
    listarDepartamentos,
    listarPuestos,
    imprimirAlta,
    agregarLinea,
    vaciar,
    eliminarLineaFamilia,
    agregarNuevaLineaFamilia,
    parametrosEmpresa,
    eliminarLinea,
    listarDocumentos
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_EMP]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [LISTADO_PAISES]: (state, { listaP }) => {
        return {
            ...state,
            listaP,
        };
    },
    [LISTADO_DEPTO]: (state, { listaDepto }) => {
        return {
            ...state,
            listaDepto,
        };
    },
    [LISTADO_MUNICIPIO]: (state, { listaMuni }) => {
        return {
            ...state,
            listaMuni,
        };
    },
    [GUARDAR_COL]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
    [LISTADO_DEPARTAMENTOS]: (state, { listaDepartamento }) => {
        return {
            ...state,
            listaDepartamento,
        };
    },
    [LISTADO_PUESTOS]: (state, { listaPuesto }) => {
        return {
            ...state,
            listaPuesto,
        };
    },
    [DETALLE]: (state, { detalle }) => {
        return {
            ...state,
            detalle,
        };
    },
    [DETALLE_FAMILIA]: (state, { detalleFa }) => {
        return {
            ...state,
            detalleFa,
        };
    },
    [PARAMETRO]: (state, { datos }) => {
        return {
            ...state,
            datos,
        };
    },
    [LISTADO_DOCUMENTOS]: (state, { documentos }) => {
        return {
            ...state,
            documentos,
        };
    },
};

export const initialState = {
    loader: false,
    modulo: [],
    listaP: [],
    ocupacion: [],
    listaDepto: [],
    listaMuni: [],
    lectura: [],
    listaDepartamento: [],
    detalle: [],
    detalleFa: [],
    datos: [],
    documentos: [],
    listaPuesto: [],
    data: []
};

export default handleActions(reducers, initialState)
