import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';
import moment from 'moment';

const LISTADO_SALIDA = 'LISTADO_SALIDA';
const GUARDAR_SALIDA = 'GUARDAR_SALIDA';
const LOADER = 'LOADER';
const MODULO = 'MODULO';
const PERIODO_D = 'PERIODO_D';
const DETALLE = 'DETALLE';
const DETALLE_INSUMOS = 'DETALLE_INSUMOS';
const CORRELATIVO = 'CORRELATIVO';
const INSUMOS = 'INSUMOS';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let periodo = id_emp[7];
    let motivo = 'SAL';
    const params = { id, periodo, motivo };
    dispatch(setLoader(true));
    api.get('/movimiento_insumos', params).then((response) => {
        dispatch({ type: LISTADO_SALIDA, data: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar bajas',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/movimiento_insumos/${id}`).then((response) => {
        dispatch({ type: GUARDAR_SALIDA, lectura: response });
        response.fechaRegistro = response.fecha;
        response.descripcion = response.observaciones;
        localStorage.setItem('id_detalle_baja_cajas', response.id);
        dispatch(initializeForm('FormBajaCajasProduccion', response));
        dispatch(leerDetalleBaja(response.id));
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar baja',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = { id, modulo }
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response) => {
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const leerPeriodoD = (id, id_periodo) => (dispatch) => {
    const params = { id, id_periodo };
    api.get('/periodos/periodo', params).then((response) => {
        dispatch({ type: PERIODO_D, periodoD: response.periodo });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar periodo',
            'Error',
            2000
        );
    })
}

export const listarFases = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/fase_prd/buscar_fase', params).then(data => {
        const list_fase = [];
        if (data) {
            data.forEach(item => {
                list_fase.push({
                    value: item.id,
                    label: item.descripcion
                })
            })
        }
        return list_fase;
    }).catch((error) => {
        return [];
    })
}

export const registroBaja = (periodoD, numeroS) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormBajaCajasProduccion.values;

    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fecha)
    if (fecha >= fechaI && fecha <= fechaF) {

        const formData = {
            fecha: data.fecha,
            numero: numeroS,
            descripcion: data.descripcion,
            detalleDev: data.detalleD,
            motivo: 'SAL',
            cliente: '',
            piloto: '',
            empresa: id_emp[5],
            periodo: id_emp[7]
        }
        dispatch(setLoader(true));
        api.post('/movimiento_insumos/', formData).then(() => {
            NotificationManager.success(
                'Baja de cajas guardado correctamente',
                'Exito',
                3000
            );
            let ruta = `/produccion/${id_emp[5]}/baja_cajas/${id_emp[7]}`
            dispatch(push(ruta));
        }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                0
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });

    } else {
        Swal.fire({
            title: 'Baja de cajas',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })
    }
}

export const modificarBaja = (periodoD) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormBajaCajasProduccion.values;

    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fecha);
    let id = data.id;
    if (fecha >= fechaI && fecha <= fechaF) {
        const formData = {
            id: id,
            descripcion: data.descripcion,
            fecha: data.fecha,
            cliente: '',
            piloto: ''
        }
        dispatch(setLoader(true));
        api.put(`/movimiento_insumos/${id}/`, formData).then(() => {
            NotificationManager.success(
                'Baja de cajas modificado correctamente',
                'Exito',
                3000
            );
            let ruta = `/produccion/${id_emp[5]}/baja_cajas/${id_emp[7]}`
            dispatch(push(ruta));
        }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                0
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    } else {
        Swal.fire({
            title: 'Baja de cajas',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })
    }
}

export const nuevaLineaInsumo = () => (dispatch, getStore) => {
    const data = getStore().form.FormBajaCajasProduccion.values;
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const detalle = localStorage.getItem('id_detalle_baja_cajas');
    if (data.cantidad === undefined || parseInt(data.cantidad) === 0) {
        Swal.fire({
            title: 'Baja de cajas',
            text: 'Debe de ingresar cantidad',
            type: 'error',
        })
    } else {
        if (data.insumo === undefined || data.insumo === null) {
            Swal.fire({
                title: 'Baja de cajas',
                text: 'Debe de seleccionar insumo',
                type: 'error',
            })
        } else {
            if (data.invernadero === undefined || data.invernadero === null) {
                Swal.fire({
                    title: 'Distribución de cajas',
                    text: 'Debe de seleccionar invernadero',
                    type: 'error',
                })
            } else {
                let datos_i = data.insumo.value;
                let datos_insumo = datos_i.split('#');
                let exis = parseInt(datos_insumo[1].trim())
                if (parseFloat(data.cantidad)>exis){
                    Swal.fire({
                        title: 'Baja de cajas',
                        text: 'Cantidad ingresada es mayor a la existencia de cajas',
                        type: 'error',
                    })
                }else{
                    const formData = {
                        cantidad: data.cantidad,
                        idInsumo: datos_insumo[0],
                        idInvernadero: data.invernadero.value,
                        detalle: detalle,
                        empresa: id_emp[5]
                    }
                    dispatch(setLoader(true));
                    api.post('/movimiento_insumos/', formData).then((response) => {
                        response.fecha = response.encabezado.fecha;
                        response.numero = response.encabezado.numero;
                        response.descripcion = response.encabezado.observaciones;
                        response.id = response.encabezado.id;
                        dispatch(initializeForm('FormBajaCajasProduccion', response));
                        dispatch(leerDetalleBaja(response.idDetalle));
                        NotificationManager.success(
                            'Insumo ingresado correctamente',
                            'Exito',
                            3000
                        );
                    }).catch((error) => {
                        NotificationManager.error(
                            error.msj,
                            'Error',
                            2000
                        );
                    }).finally(() => {
                        dispatch(setLoader(false));
                    });
                }
            }
        }
    }

}

const eliminarLineaInsumo = id => (dispatch) => {
    const detalle = localStorage.getItem('id_detalle_baja_cajas');
    dispatch(setLoader(true));
    api.eliminar(`/movimiento_insumos_detalle/${id}`).then(() => {
        dispatch(leerDetalleBaja(detalle));
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar el insumo',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

let detalle = [];
export const agregarLinea = (periodoD) => (dispatch, getStore) => {
    const data = getStore().form.FormBajaCajasProduccion.values;

    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fecha);
    if (fecha >= fechaI && fecha <= fechaF) {
        if (data.cantidad === undefined || parseInt(data.cantidad) === 0) {
            Swal.fire({
                title: 'Baja de cajas',
                text: 'Debe de ingresar cantidad',
                type: 'error',
            })
        } else {
            if (data.insumo === undefined || data.insumo === null) {
                Swal.fire({
                    title: 'Baja de cajas',
                    text: 'Debe de seleccionar insumo',
                    type: 'error',
                })
            } else {
                if (data.invernadero === undefined || data.invernadero === null) {
                    Swal.fire({
                        title: 'Distribución de cajas',
                        text: 'Debe de seleccionar invernadero',
                        type: 'error',
                    })
                } else {
                    let datos_i = data.insumo.value;
                    let datos_insumo = datos_i.split('#');
                    let exis = parseInt(datos_insumo[1].trim())
                    if (parseFloat(data.cantidad)>exis){
                        Swal.fire({
                            title: 'Distribución de cajas',
                            text: 'Cantidad ingresada es mayor a la existencia de cajas',
                            type: 'error',
                        })
                    }else{
                        let desc = data.insumo.label.split(' -- ');
                        detalle.push({
                            id: datos_insumo[0] + '/' + data.cantidad,
                            idInsumo: datos_insumo[0],
                            insumo: desc[0].trim(),
                            cantidad: data.cantidad,
                            invernadero: data.invernadero.value,
                            nombreInver: data.invernadero.label,
                        })
                            
                        data.cantidad = 0;
                        data.detalleD = detalle;
                        NotificationManager.success(
                            'Insumo ingresado correctamente',
                            'Exito',
                            3000
                        );

                        dispatch(actualizar());
                    }
                }
            }
        }
    } else {
        Swal.fire({
            title: 'Baja de cajas',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })
    }
}


const eliminarLinea = (id) => (dispatch) => {
    let d = id.split('/');
    detalle.forEach((element, index) => {
        if (element.idInsumo === d[0] && element.cantidad === d[1]) {
            detalle.splice(index, 1);
        }
    })
    dispatch(actualizar());
};

const vaciar = () => (dispatch) => {
    detalle = [];
    dispatch(initializeForm('FormBajaCajasProduccion'));
    dispatch({ type: DETALLE, detallePro: detalle });
};

export const listarInsumos = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/insumo_prd/buscar_insumo_cajas_invernadero', params).then(data => {
        const list_insumo = [];
        if (data) {
            data.forEach(item => {
                list_insumo.push({
                    value: item.id + '#' + item.cajas,
                    label: item.descripcion + ' ' + 'Existencia: ' + new Intl.NumberFormat("en-US", { minimumFractionDigits: 0 }).format(item.cajas)
                })
            })
        }
        return list_insumo;
    }).catch((error) => {
        return [];
    })
}

export const leerInsumos = (invernadero) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id, invernadero }
    api.get('/insumo_prd/buscar_insumo_cajas_invernadero/',params).then((response) => {
        const insumos = [];
        if (response) {
            const saldos = response.filter((item) => parseInt(item.cajas) >= 1);
            saldos.forEach(item=>{
                insumos.push({
                    value: item.id + '#' + item.cajas,
                    label: item.descripcion + ' -- ' + 'Existencia: ' + new Intl.NumberFormat("en-US", { minimumFractionDigits: 0 }).format(item.cajas)
                })
            })
        }
        dispatch({type: INSUMOS, insumo: insumos })
    })
}

const actualizar = () => (dispatch, getStore) => {
    const data = getStore().form.FormBajaCajasProduccion.values;
    dispatch(initializeForm('FormBajaCajasProduccion', data));
    dispatch({ type: DETALLE, detallePro: data.detalleD })
};

const leerDetalleBaja = id => (dispatch) => {
    api.get(`/movimiento_insumos_detalle/${id}`).then((response) => {
        dispatch({ type: DETALLE_INSUMOS, detalleInsumo: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};

const eliminarBaja = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`/movimiento_insumos/${id}`).then(() => {
        dispatch(listar(1));
        NotificationManager.success(
            'Baja borrado correctamente',
            'Exito',
            3000
        );
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const numeroBaja = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let tipo = 'SAL'
    const params = { id, tipo };
    api.get('/movimiento_insumos/correlativo', params).then((response) => {
        let numero = parseInt(response.numero)+1
        dispatch({ type: CORRELATIVO, numeroB: numero })
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar correlativo',
            'Error',
            2000
        );
    })
}

export const listarInvernaderos = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/invernaderos', params).then(data => {
        const list_fase = [];
        if (data) {
            data.forEach(item => {
                list_fase.push({
                    value: item.id,
                    label: item.nombre
                })
            })
        }
        return list_fase;
    }).catch((error) => {
        return [];
    })
}

export const actions = {
    listar,
    leer,
    getModulo,
    leerPeriodoD,
    registroBaja,
    modificarBaja,
    agregarLinea,
    listarFases,
    eliminarLineaInsumo,
    nuevaLineaInsumo,
    eliminarLinea,
    vaciar,
    listarInsumos,
    actualizar,
    listarInsumos,
    numeroBaja,
    listarInvernaderos,
    eliminarBaja,
    leerInsumos
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_SALIDA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [GUARDAR_SALIDA]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [PERIODO_D]: (state, { periodoD }) => {
        return {
            ...state,
            periodoD,
        };
    },
    [DETALLE]: (state, { detallePro }) => {
        return {
            ...state,
            detallePro,
        };
    },
    [DETALLE_INSUMOS]: (state, { detalleInsumo }) => {
        return {
            ...state,
            detalleInsumo,
        };
    },
    [CORRELATIVO]: (state, { numeroB }) => {
        return {
            ...state,
            numeroB,
        };
    },
    [INSUMOS]: (state, { insumo }) => {
        return {
            ...state,
            insumo,
        };
    }
};

export const initialState = {
    loader: false,
    lectura: [],
    modulo: [],
    periodoD: [],
    detallePro: [],
    detalleInsumo: [],
    data: [],
    page: 1,
    numeroB: 0,
    insumo: []
};

export default handleActions(reducers, initialState)
