import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';
import { validar } from "../../../common/components/components/ValidarNit";
import moment from 'moment';

const LISTADO_INGRESO = 'LISTADO_INGRESO';
const GUARDAR_INGRESO = 'GUARDAR_INGRESO';
const LOADER = 'LOADER';
const MODULO = 'MODULO';
const PERIODO_D = 'PERIODO_D';
const LISTADO_BODEGAS = 'LISTADO_BODEGAS';
const DETALLE_PRODUCTOS = 'DETALLE_PRODUCTOS';
const DETALLE = 'DETALLE';
const PARAMETRO = 'PARAMETRO';
const LISTADO_PAISES = 'LISTADO_PAISES';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setLoaderC = loader => ({
    type: LOADER,
    loader,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let periodo = id_emp[7];
    //const params = { page, id, periodo };
    const params = { id, periodo };
    dispatch(setLoader(true));
    api.get('/ingreso_produccion', params).then((response) => {
        dispatch({ type: LISTADO_INGRESO, data: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar entradas',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/ingreso_produccion/${id}`).then((response) => {
        dispatch({ type: GUARDAR_INGRESO, lectura: response });
        response.fechaRegistro = response.fecha
        response.nombreCli = response.clienteN
        localStorage.setItem('id_detalle_ingreso_produccion', response.id);
        dispatch(initializeForm('FormIngresoProduccion', response));
        dispatch(leerDetalleProductos(response.id));
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar la entrada',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = { id, modulo }
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response) => {
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const leerPeriodoD = (id, id_periodo) => (dispatch) => {
    const params = { id, id_periodo };
    api.get('/periodos/periodo', params).then((response) => {
        dispatch({ type: PERIODO_D, periodoD: response.periodo });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar periodo',
            'Error',
            2000
        );
    })
}

export const listarClientes = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/cliente/empresa_clientes', params).then(data => {
        const list_cliente = [];
        if (data) {
            data.forEach(item => {
                list_cliente.push({
                    value: item.id,
                    label: item.identificador + ' ' + item.nombre
                })
            })
        }
        return list_cliente;
    }).catch((error) => {

        return [];
    })
}

export const listarProductos = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/producto/empresa_productos_cod', params).then(data => {
        const list_producto = [];
        if (data) {
            data.forEach(item => {
                if (item.tipo === 'P') {
                    list_producto.push({
                        value: item.id,
                        label: item.SKU + ' / ' + item.producto
                    })
                }
            })
        }
        return list_producto;
    }).catch((error) => {
        return [];
    })
}

export const listarFases = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/fase_prd/buscar_fase', params).then(data => {
        const list_fase = [];
        if (data) {
            data.forEach(item => {
                list_fase.push({
                    value: item.id,
                    label: item.descripcion
                })
            })
        }
        return list_fase;
    }).catch((error) => {
        return [];
    })
}

export const registroProduccion = (periodoD, numeroS) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormIngresoProduccion.values;
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fechaRegistro)
    if (fecha >= fechaI && fecha <= fechaF) {
        const formData = {
            fecha: data.fechaRegistro,
            serie: data.serie.label,
            numero: numeroS,
            cliente: data.cliente_documento.value,
            detallePro: data.detalleD,
            exportacion: data.exportacion,
            empresa: id_emp[5],
            periodo: id_emp[7]
        }
        dispatch(setLoader(true));
        api.post('/ingreso_produccion/', formData).then((response) => {
            NotificationManager.success(
                'Ingreso de producción guardado correctamente',
                'Exito',
                3000
            );
            Swal.fire({
                title: '¿Imprimir?',
                text: '¡Desea imprimir ingreso de producción!',
                type: 'question',
                showCancelButton: true,
                confirmButtonText: '¡Sí, imprimir!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    dispatch(imprimirIngreso(response.encabezado.id));
                    let ruta = `/produccion/${id_emp[5]}/ingreso/${id_emp[7]}`
                    dispatch(push(ruta));
                } else {
                    window.location.reload(true);
                }
            });

        }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                0
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    } else {
        Swal.fire({
            title: 'Ingreso de producción',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })
    }
}

export const modificarProduccion = (periodoD) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormIngresoProduccion.values;
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fechaRegistro);
    let id = data.id;
    if (fecha >= fechaI && fecha <= fechaF) {
        let cliente = data.cliente_documento;
        if (cliente === undefined) {
            cliente = data.cliente;
        } else {
            cliente = data.cliente_documento.value;
        }
        const formData = {
            id: id,
            fecha: data.fechaRegistro,
            serie: data.serie,
            numero: data.numero,
            exportacion: data.exportacion,
            cliente: cliente,
        }
        dispatch(setLoader(true));
        api.put(`/ingreso_produccion/${id}/`, formData).then(() => {
            NotificationManager.success(
                'Ingreso de producción modificado correctamente',
                'Exito',
                3000
            );
            let ruta = `/produccion/${id_emp[5]}/ingreso/${id_emp[7]}`
            dispatch(push(ruta));
        }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                0
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    } else {
        Swal.fire({
            title: 'Ingreso de producción',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })
    }
}

export const nuevaLineaProduccion = () => (dispatch, getStore) => {
    const data = getStore().form.FormIngresoProduccion.values;
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const detalle = localStorage.getItem('id_detalle_ingreso_produccion');
    if (data.cantidad === undefined || data.cantidad === 0.000000) {
        Swal.fire({
            title: 'Ingreso de producción',
            text: 'Debe de ingresar cantidad',
            type: 'error',
        })
    } else {
        if (data.producto === undefined || data.producto === null) {
            Swal.fire({
                title: 'Ingreso de producción',
                text: 'Debe de seleccionar producto',
                type: 'error',
            })
        } else {
            if (data.costo === undefined || data.costo === 0.00) {
                Swal.fire({
                    title: 'Ingreso de producción',
                    text: 'Debe de ingresar precio costo',
                    type: 'error',
                })
            } else {
                if (data.fase === undefined || data.fase === null) {
                    Swal.fire({
                        title: 'Ingreso de producción',
                        text: 'Debe de seleccionar llave',
                        type: 'error',
                    })
                } else {
                    if (data.insumo === undefined || data.insumo === null) {
                        
                        const formData = {
                            cantidad: data.cantidad,
                            idPro: data.producto.value,
                            idFase: data.fase.value,
                            unidad: data.unidad ? data.unidad : 0,
                            idConsumo: '',
                            cantidad_insumo: 0,
                            costo: data.costo,
                            detalle: detalle,
                            serie: data.serie,
                            numero: data.numero,
                            empresa: id_emp[5]
                        }
                        dispatch(setLoader(true));
                        api.post('/ingreso_produccion/', formData).then((response) => {
                            response.serie = response.encabezado.serie;
                            response.numero = response.encabezado.numero;
                            response.fechaRegistro = response.encabezado.fecha;
                            response.nombreCli = response.encabezado.clienteN;
                            response.cliente = response.encabezado.cliente;
                            dispatch(initializeForm('FormIngresoProduccion', response));
                            dispatch(leerDetalleProductos(response.id));
                            NotificationManager.success(
                                'Producto ingresado correctamente',
                                'Exito',
                                3000
                            );
                            dispatch(leerDetalleProductos(detalle));
                        }).catch((error) => {
                            NotificationManager.error(
                                error.msj,
                                'Error',
                                2000
                            );
                        }).finally(() => {
                            dispatch(setLoader(false));
                        });
                    } else {
                        if (data.cantidad_insumo === undefined || data.cantidad_insumo === 0.00) {
                            Swal.fire({
                                title: 'Ingreso de producción',
                                text: 'Debe de ingresar cantidad de cajas',
                                type: 'error',
                            })
                        } else {
                            const formData = {
                                cantidad: data.cantidad,
                                idPro: data.producto.value,
                                idFase: data.fase.value,
                                unidad: data.unidad ? data.unidad : 0,
                                idConsumo: data.insumo.value,
                                cantidad_insumo: data.cantidad_insumo,
                                costo: data.costo,
                                detalle: detalle,
                                serie: data.serie,
                                numero: data.numero,
                                empresa: id_emp[5]
                            }
                            dispatch(setLoader(true));
                            api.post('/ingreso_produccion/', formData).then((response) => {
                                response.serie = response.encabezado.serie;
                                response.numero = response.encabezado.numero;
                                response.fechaRegistro = response.encabezado.fecha;
                                response.nombreCli = response.encabezado.clienteN;
                                response.cliente = response.encabezado.cliente;
                                dispatch(initializeForm('FormIngresoProduccion', response));
                                dispatch(leerDetalleProductos(response.id));
                                NotificationManager.success(
                                    'Producto ingresado correctamente',
                                    'Exito',
                                    3000
                                );
                                dispatch(leerDetalleProductos(detalle));
                            }).catch((error) => {
                                NotificationManager.error(
                                    error.msj,
                                    'Error',
                                    2000
                                );
                            }).finally(() => {
                                dispatch(setLoader(false));
                            });

                        }
                    }
                }
            }
        }
    }
}

const leerDetalleProductos = id => (dispatch) => {
    api.get(`/ingreso_produccion_detalle/${id}`).then((response) => {
        dispatch({ type: DETALLE_PRODUCTOS, detalleProducto: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};

const eliminarLineaProducto = id => (dispatch) => {
    const detalle = localStorage.getItem('id_detalle_ingreso_produccion');
    dispatch(setLoader(true));
    api.eliminar(`/ingreso_produccion_detalle/${id}`).then(() => {
        dispatch(leerDetalleProductos(detalle));
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar el producto',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

let detalle = [];
export const agregarLinea = (periodoD) => (dispatch, getStore) => {
    const data = getStore().form.FormIngresoProduccion.values;
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fechaRegistro);
    let insumo = '';
    let cantidad_insumos = 0;
    if (fecha >= fechaI && fecha <= fechaF) {
        if (data.cantidad === undefined || data.cantidad === 0.000000) {
            Swal.fire({
                title: 'Ingreso de producción',
                text: 'Debe de ingresar cantidad',
                type: 'error',
            })
        } else {
            if (data.producto === undefined || data.producto === null) {
                Swal.fire({
                    title: 'Ingreso de producción',
                    text: 'Debe de seleccionar producto',
                    type: 'error',
                })
            } else {
                if (data.costo === undefined || data.costo === 0.00) {
                    Swal.fire({
                        title: 'Ingreso de producción',
                        text: 'Debe de ingresar precio costo',
                        type: 'error',
                    })
                } else {
                    if (data.fase === undefined || data.fase === null) {
                        Swal.fire({
                            title: 'Ingreso de producción',
                            text: 'Debe de seleccionar llave',
                            type: 'error',
                        })
                    } else {
                        if (data.insumo === undefined || data.insumo === null) {
                            insumo = '';
                            cantidad_insumos = 0;
                            detalle.push({
                                id: data.producto.value + '/' + data.cantidad,
                                cantidad: data.cantidad,
                                precio: data.precio,
                                idPro: data.producto.value,
                                producto: data.producto.label,
                                fase: data.fase.label,
                                idFase: data.fase.value,
                                unidad: data.unidad ? data.unidad : 0,
                                idConsumo: insumo,
                                cantidad_insumo: cantidad_insumos,
                                costo: data.costo,
                            })
                            data.unidad = 0;
                            data.precio = 0;
                            data.cantidad = 0;
                            data.cantidad_insumo = 0
                            data.detalleD = detalle;
                            data.costo = 0;
                            NotificationManager.success(
                                'Producto ingresado correctamente',
                                'Exito',
                                3000
                            );

                            dispatch(actualizar());
                        } else {
                            insumo = data.insumo.value;
                            if (data.cantidad_insumo === undefined || data.cantidad_insumo === 0.00) {
                                Swal.fire({
                                    title: 'Ingreso de producción',
                                    text: 'Debe de ingresar cantidad de cajas',
                                    type: 'error',
                                })
                            } else {
                                cantidad_insumos = data.cantidad_insumo;

                                detalle.push({
                                    id: data.producto.value + '/' + data.cantidad,
                                    cantidad: data.cantidad,
                                    precio: data.precio,
                                    idPro: data.producto.value,
                                    producto: data.producto.label,
                                    fase: data.fase.label,
                                    idFase: data.fase.value,
                                    unidad: data.unidad ? data.unidad : 0,
                                    idConsumo: insumo,
                                    insumo: data.insumo.label,
                                    cantidad_insumo: cantidad_insumos,
                                    costo: data.costo,
                                })
                                data.unidad = 0;
                                data.precio = 0;
                                data.cantidad = 0;
                                data.cantidad_insumo = 0
                                data.detalleD = detalle;
                                data.costo = 0;
                                NotificationManager.success(
                                    'Producto ingresado correctamente',
                                    'Exito',
                                    3000
                                );

                                dispatch(actualizar());
                            }
                        }
                    }
                }
            }
        }

    } else {
        Swal.fire({
            title: 'Ingreso de producción',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })
    }
}


const eliminarLinea = (id) => (dispatch, getStore) => {
    //const data = getStore().form.FormIngresoProduccion.values;
    let d = id.split('/');
    detalle.forEach((element, index) => {
        if (element.idPro === d[0] && element.cantidad === d[1]) {
            detalle.splice(index, 1);
        }
    })
    //dispatch(initializeForm('FormIngresoProduccion', data));
    //dispatch({ type: DETALLE, detallePro: detalle });
    dispatch(actualizar());
};

const vaciar = () => (dispatch) => {
    detalle = [];
    dispatch(initializeForm('FormIngresoProduccion'));
    dispatch({ type: DETALLE, detallePro: detalle });
};

export const parametrosEmpresa = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id }
    dispatch(setLoader(true));
    api.get('/empresa/empresa_parametros', params).then((response) => {
        dispatch({ type: PARAMETRO, datos: response });
    }).catch((error) => {

        NotificationManager.error(
            'Ocurrió un error al consultar parametros',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

const listarPaises = () => (dispatch) => {
    return api.get("/paises").then(response => {
        const pais = [];
        response.results.forEach(item => {
            pais.push({
                value: item.id,
                label: item.pais
            })
        })
        dispatch({ type: LISTADO_PAISES, listaP: pais });
    }).catch(error => {
        NotificationManager.error(
            'Ocurrió un error al listar paises',
            'Error',
            2000
        );
    })
}

export const registroCliente = (datos) => (dispatch, getStore) => {
    const data = getStore().form.FormIngresoProduccion.values;
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    if (datos.validar_nit === 'S') {
        if (data.identificador === 'CF') {
            const formData = {
                identificador: data.identificador,
                nombre: data.nombre,
                direccion: data.direccion,
                telefono: data.telefono,
                correo: data.correo,
                limite: data.limite_credito,
                tipo_cliente: data.tipo_cliente,
                dias_credito: data.dias_credito,
                pais: data.pais,
                modulo: 'PRD',
                empresa: id_emp[5]
            }
            dispatch(setLoader(true));
            api.post('/cliente/', formData).then(() => {
                NotificationManager.success(
                    'Cliente creado',
                    'Exito',
                    3000
                );
            }).catch((error) => {
                NotificationManager.error(
                    error.msj,
                    'Error',
                    0
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        } else {
            let valid = validar.valNit(data.identificador);
            if (valid === false) {
                Swal.fire({
                    title: 'Cliente',
                    text: 'Ingrese Nit válido',
                    type: 'error',
                })
            } else {
                const formData = {
                    identificador: data.identificador,
                    nombre: data.nombre,
                    direccion: data.direccion,
                    telefono: data.telefono,
                    correo: data.correo,
                    limite: data.limite_credito,
                    tipo_cliente: data.tipo_cliente,
                    dias_credito: data.dias_credito,
                    pais: data.pais,
                    modulo: 'PRD',
                    empresa: id_emp[5]
                }
                dispatch(setLoader(true));
                api.post('/cliente/', formData).then((response) => {
                    NotificationManager.success(
                        'Cliente creado',
                        'Exito',
                        3000
                    );
                }).catch((error) => {
                    NotificationManager.error(
                        error.msj,
                        'Error',
                        2000
                    );
                }).finally(() => {
                    dispatch(setLoader(false));
                });
            }
        }
    } else {
        const formData = {
            identificador: data.identificador,
            nombre: data.nombre,
            direccion: data.direccion,
            telefono: data.telefono,
            correo: data.correo,
            limite: data.limite_credito,
            tipo_cliente: data.tipo_cliente,
            dias_credito: data.dias_credito,
            pais: data.pais,
            modulo: 'PRD',
            empresa: id_emp[5]
        }
        dispatch(setLoader(true));
        api.post('/cliente/', formData).then((response) => {
            NotificationManager.success(
                'Cliente creado',
                'Exito',
                3000
            );
        }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                2000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
}

export const listarInsumos = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/insumo_prd/buscar_insumo', params).then(data => {
        const list_insumo = [];
        if (data) {
            data.forEach(item => {
                list_insumo.push({
                    value: item.id,
                    label: item.descripcion
                })
            })
        }
        return list_insumo;
    }).catch((error) => {
        return [];
    })
}

export const listarSeries = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let tipo = 'ING';
    const params = { id, search, tipo }
    return api.get('/serie_prd', params).then(data => {
        const list_serie = [];
        if (data) {
            data.forEach(item => {
                list_serie.push({
                    value: item.id,
                    label: item.serie
                })
            })
        }
        return list_serie;
    }).catch((error) => {
        return [];
    })
}

export const numeroSerie = (id) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let idEmpre = id_emp[5];
    let tipo = 'ING'
    const params = { id, idEmpre, tipo }
    dispatch(setLoaderC(true));
    return api.get('/serie_prd/serie/', params).then((response) => {
        return response;
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar numero de serie',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoaderC(false));
    });

}


export const imprimirIngreso = id => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let idE = id_emp[5];
    let idIng = id
    let reporte = 'imprimirIng';
    const params = { idE, reporte, idIng };
    dispatch(setLoader(true));
    api.getPdf('/ingreso_produccion/', params).then((response) => {
        if (response.headers['content-type'] == 'application/pdf') {
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'reporte_liquidacion_gastos.pdf';
            window.open(link, '_blank');
            return false;
        }
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al generar reporte',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const actualizar = () => (dispatch, getStore) => {
    const data = getStore().form.FormIngresoProduccion.values;
    dispatch(initializeForm('FormIngresoProduccion', data));
    dispatch({ type: DETALLE, detallePro: data.detalleD })
};

export const actions = {
    listar,
    leer,
    getModulo,
    leerPeriodoD,
    listarClientes,
    listarProductos,
    registroProduccion,
    modificarProduccion,
    agregarLinea,
    listarFases,
    eliminarLineaProducto,
    nuevaLineaProduccion,
    eliminarLinea,
    vaciar,
    parametrosEmpresa,
    listarPaises,
    registroCliente,
    listarInsumos,
    listarSeries,
    numeroSerie,
    imprimirIngreso,
    actualizar
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_INGRESO]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [GUARDAR_INGRESO]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [PERIODO_D]: (state, { periodoD }) => {
        return {
            ...state,
            periodoD,
        };
    },
    [LISTADO_BODEGAS]: (state, { listaBodegas }) => {
        return {
            ...state,
            listaBodegas,
        };
    },
    [DETALLE_PRODUCTOS]: (state, { detalleProducto }) => {
        return {
            ...state,
            detalleProducto,
        };
    },
    [DETALLE]: (state, { detallePro }) => {
        return {
            ...state,
            detallePro,
        };
    },
    [PARAMETRO]: (state, { datos }) => {
        return {
            ...state,
            datos,
        };
    },
    [LISTADO_PAISES]: (state, { listaP }) => {
        return {
            ...state,
            listaP,
        };
    },
};

export const initialState = {
    loader: false,
    lectura: [],
    modulo: [],
    periodoD: [],
    detalleProducto: [],
    detallePro: [],
    listaBodegas: [],
    listaP: [],
    datos: [],
    data: [],
    page: 1,
};

export default handleActions(reducers, initialState)
