import { NotificationManager } from "react-notifications";
import { api } from "api";

const baseurl = "laboratorio/permisos";
const writeSuccessMsg = "Permisos modificados";
const editSuccessMsg = "Permisos modificados";

export const getList = () => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let empresa = id_emp[5];
    const params = { empresa };
    return api
        .get(baseurl, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return [];
        });
};

export const getEntry = (id) => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let empresa = id_emp[5];
    const params = { empresa };
    return api
        .get(baseurl + `/${id}`, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return [];
        });
};

export const crear = (data) => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    const formData = {
        ...data,
        empresa: id_emp[5],
    };
    return api
        .post(baseurl, formData)
        .then(() => {
            NotificationManager.success(writeSuccessMsg, "Exito", 10000);
        })
        .catch((error) => {
            try {
                error.non_field_errors.map((err) => {
                    NotificationManager.error(err, "Error", 10000);
                });
                return;
            } catch (exception) {
                if (typeof error === "object") {
                    Object.keys(error).forEach((field) => {
                        error[field].forEach((errorMessage) => {
                            NotificationManager.error(
                                `${field}: ${errorMessage}`,
                                "Error",
                                10000
                            );
                        });
                    });
                } else {
                    NotificationManager.error("", "Error", 10000);
                }
            }
        })
        .catch((error) => {
            NotificationManager.error("", "Error", 2000);
        });
};

export const editar = (data) => {
    let ruta = window.location.href;
    let empresa = ruta.split("/")[5];
    const formData = {
        ...data,
        empresa,
    };
    return api
        .put(baseurl + "/asignar", formData)
        .then(() => {
            NotificationManager.success(editSuccessMsg, "Exito", 3000);
            return true;
        })
        .catch((error) => {
            NotificationManager.error(error.msj, "Error", 2000);
            return false;
        });
};

export const getUsers = (search) => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let id = id_emp[5];
    const params = { id, search };
    return api
        .get("user/usuario_empresa", params)
        .then((response) => {
            return response.results.map((item) => ({
                value: item.usuario.id,
                label: `${item.usuario.username} (${item.usuario.first_name} ${item.usuario.last_name})`,
            }));
        })
        .catch((error) => {
            return [];
        });
};

export const getPermisos = (usuario) => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let empresa = id_emp[5];
    const params = { empresa, usuario };
    return api
        .get(`laboratorio/permisos`, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return [];
        });
};
