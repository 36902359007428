import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const MenuItems = ({ items }) => {
    const [dropdown, setDropdown] = useState(false);
    let depthLevel = 0;
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let path = items.path.split("/");
    let u = path[2] === undefined ? "" : path[2];
    
    let nueva_ruta = "/" + id_emp[4] + "/" + id_emp[5] + "/" + u;

    const RenderDropdownItems = () => {
        const dropdownRef = useRef(null);

        useEffect(() => {
            const closeDropdown = (e) => {
                if (
                    dropdownRef.current &&
                    !dropdownRef.current.contains(e.target)
                ) {
                    setDropdown(false);
                }
            };
            if (dropdown) {
                window.addEventListener("click", closeDropdown);
            }
            return () => {
                window.removeEventListener("click", closeDropdown);
            };
        }, [dropdown]);

        return (
            <ul
                className={`dropdownS ${dropdown ? "show" : ""}`}
                ref={dropdownRef}
            >
                {items.subNav.map((submenu, index) => (
                    <li key={index} className="menu-itemsS">
                        <SidebarLinkS
                            to={`${submenu.path}/${id_emp[5]}/${submenu.path_s}`}
                            onClick={() => setDropdown(false)}
                        >
                            <div>
                                {submenu.icon}
                                <SidebarLabelS>{submenu.title}</SidebarLabelS>
                            </div>
                        </SidebarLinkS>
                    </li>
                ))}
            </ul>
        );
    };

    return (
        <li className="menu-itemsS">
            {items.subNav ? (
                <div>
                    <button
                        type="button"
                        aria-expanded={dropdown ? "true" : "false"}
                        onClick={() => setDropdown((prev) => !prev)}
                    >
                        {items.icon}
                        <SidebarLabel>
                            {items.title}{" "}
                            {depthLevel > 0 ? (
                                <span>&raquo;</span>
                            ) : (
                                <span className="arrow" />
                            )}
                        </SidebarLabel>
                    </button>
                    <RenderDropdownItems />
                </div>
            ) : (
                <SidebarLink to={nueva_ruta}>
                    <div>
                        {items.icon}
                        <SidebarLabel>{items.title}</SidebarLabel>
                    </div>
                </SidebarLink>
            )}
        </li>
    );
};

export default MenuItems;

const SidebarLink = styled(Link)`
    display: flex;
    color: #fff;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    list-style: none;
    height: 40px;
    text-decoration: none;
    font-size: 17px;

    &:hover {
        background: black;
        border-left: 4px solid #632ce4;
        cursor: pointer;
        color: #007bff;
        text-decoration: none;
    }
`;

const SidebarLabel = styled.span`
    margin-left: 16px;
`;

const SidebarLinkS = styled(Link)`
    display: flex;
    color: #fff;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    list-style: none;
    height: 40px;
    text-decoration: none;
    font-size: 17px;

    &:hover {
        background: black;
        border-left: 4px solid #632ce4;
        cursor: pointer;
        color: #007bff;
        text-decoration: none;
    }
`;

const SidebarLabelS = styled.span`
    margin-left: 16px;
`;
