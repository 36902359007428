import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';
import moment from 'moment';

const LISTADO_ABONO = 'LISTADO_ABONO';
const LISTADO_PROVEEDOR = 'LISTADO_PROVEEDOR';
const GUARDAR_ABONO = 'GUARDAR_ABONO';
const GUARDAR_POLIZA = 'GUARDAR_POLIZA';
const DETALLE_POLIZA = 'DETALLE_POLIZA';
const LISTADO_DOCUMENTOS = 'LISTADO_DOCUMENTOS';
const LISTADO_MONEDAS = 'LISTADO_MONEDAS';
const DETALLE_DOCUMENTOS = 'DETALLE_DOCUMENTOS';
const LOADER = 'LOADER';
const LOADERC = 'LOADERC';
const NIVEL = 'NIVEL';
const MODULO = 'MODULO';
const PERIODO_D = 'PERIODOD';
const PARAMETRO = 'PARAMETRO';
const DOCUMENTOS = 'DOCUMENTOS';
const DETALLE = 'DETALLE';
const ANTICIPOS = 'ANTICIPOS';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setLoaderC = loaderC => ({
    type: LOADERC,
    loaderC,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let id_period = id_emp[7];
    //const params = { page, id, id_period };
    const params = { id, id_period };
    dispatch(setLoader(true));
    api.get('/anticipo_cxp', params).then((response) => {
        dispatch({ type: LISTADO_ABONO, data: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar anticipos',
            'Error',
            3000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const listarProveedores = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let tipo = 'compras';
    const params = { id, search, tipo }
    return api.get('/proveedor/empresa_proveedores', params).then(data => {
        const list_proveedor = [];
        if (data) {
            data.forEach(item => {
                list_proveedor.push({
                    value: item.id,
                    label: item.identificador + ' ' + item.nombre
                })
            })
        }
        return list_proveedor;
    }).catch((error) => {
        
        return [];
    })
}

export const listarCuentas = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/cuentas/empresa_cuentas/', params).then(data => {
        const list_cuentas = [];
        if (data) {
            data.forEach(item => {
                if (item.tipo === 'A') {
                    list_cuentas.push({
                        value: item.id,
                        label: item.cuenta + ' ' + item.nombre,
                        isDisabled: true
                    })
                } else {
                    list_cuentas.push({
                        value: item.id,
                        label: item.cuenta + ' ' + item.nombre
                    })
                }
            })
        }
        return list_cuentas;
    }).catch((error) => {
        
        return [];
    })
}

export const listarCC = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/centrocosto/empresa_centro_costo/', params).then(data => {
        const list_cc = [];
        if (data) {
            data.forEach(item => {
                list_cc.push({
                    value: item.id,
                    label: item.codigo + ' ' + item.descripcion
                })
            })
        }
        return list_cc;
    }).catch((error) => {
        
        return [];
    })
}

const leerDetalle = id => (dispatch) => {
    api.get(`/poliza_detalle/${id}`).then((response) => {
        dispatch({ type: DETALLE_POLIZA, detalle: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            3000
        );
    })
};

const leerDetalleMod = id => (dispatch) => {
    api.get(`/poliza_detalle/${id}`).then((response) => {
        response.polizaDetalle_polizaEncabezado = response
        dispatch({ type: GUARDAR_POLIZA, lectura: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            3000
        );
    })
};


export const listarNiveles = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    dispatch(setLoader(true));
    api.get('/niveles/nivel_cuenta', params).then((response) => {
        dispatch({ type: NIVEL, nivel: response });
    }).catch((error) => {
        
        NotificationManager.error(
            'Ocurrió un error al listar nivel',
            'Error',
            3000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const eliminarLinea = id => (dispatch) => {
    const poliza = localStorage.getItem('idPolizaAnticipoPro');
    dispatch(setLoader(true));
    api.eliminar(`poliza_detalle/${id}`).then(() => {
        const editar = window.location.href.includes('editar');
        if (editar) {
            dispatch(leerDetalleMod(poliza));
        } else {
            dispatch(leerDetalle(poliza));
        }
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar linea de poliza',
            'Error',
            3000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const registroCuenta = (formato) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.AnticipoProveedorFormTabs.values;
    let cuenta = data.cuenta
    let f = formato.split('-');
    let cont = 0;
    let formatoCuenta = '';
    let digito = '';

    if (f.length === 3) {
        let nivel1 = f[0].length
        digito = cuenta.substr(cont, nivel1);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel2 = f[1].length;
        cont = cont + nivel1;
        digito = cuenta.substr(cont, nivel2);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel3 = f[2].length;
        cont = cont + nivel2;
        digito = cuenta.substr(cont, nivel3);
        formatoCuenta = formatoCuenta + digito;

    }

    if (f.length === 4) {
        let nivel1 = f[0].length
        digito = cuenta.substr(cont, nivel1);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel2 = f[1].length;
        cont = cont + nivel1;
        digito = cuenta.substr(cont, nivel2);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel3 = f[2].length;
        cont = cont + nivel2;
        digito = cuenta.substr(cont, nivel3);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel4 = f[3].length;
        cont = cont + nivel3;
        digito = cuenta.substr(cont, nivel4);
        formatoCuenta = formatoCuenta + digito;
    }

    if (f.length === 5) {
        let nivel1 = f[0].length
        digito = cuenta.substr(cont, nivel1);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel2 = f[1].length;
        cont = cont + nivel1;
        digito = cuenta.substr(cont, nivel2);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel3 = f[2].length;
        cont = cont + nivel2;
        digito = cuenta.substr(cont, nivel3);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel4 = f[3].length;
        cont = cont + nivel3;
        digito = cuenta.substr(cont, nivel4);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel5 = f[4].length;
        cont = cont + nivel4;
        digito = cuenta.substr(cont, nivel5);
        formatoCuenta = formatoCuenta + digito;
    }

    if (f.length === 6) {
        let nivel1 = f[0].length
        digito = cuenta.substr(cont, nivel1);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel2 = f[1].length;
        cont = cont + nivel1;
        digito = cuenta.substr(cont, nivel2);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel3 = f[2].length;
        cont = cont + nivel2;
        digito = cuenta.substr(cont, nivel3);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel4 = f[3].length;
        cont = cont + nivel3;
        digito = cuenta.substr(cont, nivel4);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel5 = f[4].length;
        cont = cont + nivel4;
        digito = cuenta.substr(cont, nivel5);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel6 = f[5].length;
        cont = cont + nivel5;
        digito = cuenta.substr(cont, nivel6);
        formatoCuenta = formatoCuenta + digito;
    }
    let idPoliza = localStorage.getItem('idPolizaAnticipoPro')
    const formData = {
        cuenta: formatoCuenta,
        nombre: data.nombre,
        tipo: data.tipoC,
        naturaleza: data.naturaleza,
        empresa: id_emp[5],
        poliza: idPoliza
    }
    dispatch(setLoaderC(true));
    api.post('/cuentas/', formData).then((response) => {
        if (idPoliza) {
            NotificationManager.success(
                'Cuenta creada' + ' ' + response.cuenta.cuenta + ' ' + response.cuenta.nombre,
                'Exito',
                0
            );
            response.cuenta = '';
            response.nombre = '';
            response.fecha = response.poliza.fecha;
            response.tipo = response.poliza.tipo;
            response.numero_poliza = response.poliza.numero_poliza;
            response.descripcion = response.poliza.descripcion;
            listarCuentas();

        } else {
            NotificationManager.success(
                'Cuenta creada' + ' ' + response.cuenta + ' ' + response.nombre,
                'Exito',
                0
            );
            response.cuenta = '';
            response.nombre = '';
            listarCuentas();
        }

    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            3000
        );
    }).finally(() => {
        dispatch(setLoaderC(false));
    });
}

export const reporteCuentas = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let reporte = 'reporte';
    const params = { id, reporte };
    dispatch(setLoaderC(true));;
    api.getPdf('/cuentas', params).then((response) => {
        if (response.headers['content-type'] == 'application/pdf') {
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'reporte_cuentas_contables.pdf';
            window.open(link, '_blank');
            return false;
        }
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al generar reporte',
            'Error',
            3000
        );
    }).finally(() => {
        dispatch(setLoaderC(false));
    });
}

export const registroAnticipo = (periodoD,datos) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormAnticipoProveedor.values;
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fecha_registro)
    let poliza = '';
    if (fecha >= fechaI && fecha <= fechaF) {
        if (datos.contabilidad === 'S') {
            poliza = 'anticipo';
        }
        if (datos.contabilidad === 'N') {
            poliza = '';
        }
        const formData = {
            fecha_registro: data.fecha_registro,
            tipo_cargo: data.tipo,
            monto: data.monto,
            descripcion: data.descripcion,
            proveedor: data.proveedor_documento.value,
            documento: data.documento.value,
            moneda: localStorage.getItem('monedaAntPro'),
            tipo_cambio: localStorage.getItem('TC_antPro'),
            detalleAnticipo: data.detalleD,
            modulo: 'CMP',
            poliza: poliza,
            empresa: id_emp[5],
            periodo: id_emp[7]
        }

        dispatch(setLoader(true));
        api.post('/anticipo_cxp/', formData).then((response) => {
            NotificationManager.success(
                'Anticipo guardado correctamente',
                'Exito',
                3000
            );
            response.fecha_registro = response.documento.fecha_registro;
            response.serie = response.documento.serie;
            response.numero = response.documento.numero;
            response.descripcion = response.documento.descripcion;
            response.monto = response.documento.monto;
            response.totalAbono = response.totalA;
            response.proveedor = response.documento.proveedor
            response.proveedorN = response.documento.proveedorN;
            localStorage.setItem('idPolizaAnticipoPro', response.idPoliza);
            localStorage.setItem('conf_AnticipoPro', 'T');
            const poliza = localStorage.getItem('idPolizaAnticipoPro');
            localStorage.setItem('detalleID_AnticipoPro', response.detalle);
            dispatch(initializeForm('FormAnticipoProveedor', response));

            if (poliza) {
                dispatch(leerDetalle(poliza));
            }


        }).catch((error) => {
            localStorage.setItem('conf_AnticipoPro', 'F')
            NotificationManager.error(
                error.msj,
                'Error',
                0
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });

    } else {
        localStorage.setItem('conf_AnticipoPro', 'F')
        Swal.fire({
            title: 'Anticipo de proveedor',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })
    }

}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/anticipo_cxp/${id}`).then((response) => {
        response.fecha_registro = response.fecha_registro;
        response.proveedor = response.proveedor;
        response.diferencia = parseFloat(response.monto)-parseFloat(response.totalDetalleAbono);
        response.totalAbono = parseFloat(response.totalDetalleAbono);
        dispatch({ type: GUARDAR_ABONO, lecturaA: response });
        dispatch(leerDetalleDocumentos(response.id));
        dispatch(initializeForm('FormAnticipoProveedor', response));
        dispatch(leerDetalleMod(response.poliza));

    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar anticipo',
            'Error',
            3000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const registroPolizaDetalle = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.AnticipoProveedorFormTabs.values;
    const poliza = localStorage.getItem('idPolizaAnticipoPro');
    const formData = {
        empresa: id_emp[5],
        periodo: id_emp[7],
        cc: data.cc.value,
        cuenta: data.cuenta.value,
        debe: data.debe,
        haber: data.haber,
        poliza: poliza
    }
    if (formData.debe === undefined && formData.haber === undefined) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'Debe de ingresar valor en debe o haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) === 0.00 && parseFloat(formData.haber) === 0.00) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'No puede llevar valores a 0.00 en debe o haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) >= 0.01 && parseFloat(formData.haber) >= 0.01) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'No puede llevar valores en debe y haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) >= 0.01) {
        formData.haber = 0.00;
        dispatch(setLoader(true));
        api.post('/polizas/encabezado_polizas/', formData).then((response) => {
            const poliza = localStorage.getItem('idPolizaAnticipoPro')
            if (poliza === null) {
                response.debe = 0.00;
                response.haber = 0.00;
                dispatch(initializeForm('AnticipoProveedorFormTabs', response));
                dispatch(leerDetalle(response.detalle.encabezado));
            } else {
                const editar = window.location.href.includes('editar');
                if (editar) {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('AnticipoProveedorFormTabs', response));
                    dispatch(leerDetalleMod(poliza));
                } else {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('AnticipoProveedorFormTabs', response));
                    dispatch(leerDetalle(poliza));
                }
            }
        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al registrar la poliza',
                'Error',
                3000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    } else if (parseFloat(formData.haber) >= 0.01) {
        formData.debe = 0.00;
        dispatch(setLoader(true));
        api.post('/polizas/encabezado_polizas/', formData).then((response) => {
            localStorage.removeItem('tipo_poliza');
            const poliza = localStorage.getItem('idPolizaAnticipoPro')
            if (poliza === null) {
                response.debe = 0.00;
                response.haber = 0.00;
                dispatch(initializeForm('AnticipoProveedorFormTabs', response));
                dispatch(leerDetalle(response.encabezado));
            } else {
                const editar = window.location.href.includes('editar');
                if (editar) {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('AnticipoProveedorFormTabs', response));
                    dispatch(leerDetalleMod(poliza));
                } else {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('AnticipoProveedorFormTabs', response));
                    dispatch(leerDetalle(poliza));
                }
            }

        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al registrar la poliza',
                'Error',
                3000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
}

export const registroPolizaDetalleSCC = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.AnticipoProveedorFormTabs.values;
    const poliza = localStorage.getItem('idPolizaAnticipoPro');
    const formData = {
        empresa: id_emp[5],
        periodo: id_emp[7],
        cc: 0,
        cuenta: data.cuenta.value,
        debe: data.debe,
        haber: data.haber,
        poliza: poliza
    }
    if (formData.debe === undefined && formData.haber === undefined) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'Debe de ingresar valor en debe o haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) === 0.00 && parseFloat(formData.haber) === 0.00) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'No puede llevar valores a 0.00 en debe o haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) >= 0.01 && parseFloat(formData.haber) >= 0.01) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'No puede llevar valores en debe y haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) >= 0.01) {
        formData.haber = 0.00;
        dispatch(setLoader(true));
        api.post('/polizas/encabezado_polizas/', formData).then((response) => {
            const poliza = localStorage.getItem('idPolizaAnticipoPro')
            if (poliza === null) {
                response.debe = 0.00;
                response.haber = 0.00;
                dispatch(initializeForm('AbonoProveedorFormTabs', response));
                dispatch(leerDetalle(response.detalle.encabezado));
            } else {
                const editar = window.location.href.includes('editar');
                if (editar) {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('AnticipoProveedorFormTabs', response));
                    dispatch(leerDetalleMod(poliza));
                } else {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('AnticipoProveedorFormTabs', response));
                    dispatch(leerDetalle(poliza));
                }
            }

        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al registrar la poliza',
                'Error',
                3000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    } else if (parseFloat(formData.haber) >= 0.01) {
        formData.debe = 0.00;
        api.post('/polizas/encabezado_polizas/', formData).then((response) => {
            localStorage.removeItem('tipo_poliza');
            const poliza = localStorage.getItem('idPolizaAnticipoPro')
            if (poliza === null) {
                response.debe = 0.00;
                response.haber = 0.00;
                dispatch(initializeForm('AnticipoProveedorFormTabs', response));
                dispatch(leerDetalle(response.detalle.encabezado));
            } else {
                const editar = window.location.href.includes('editar');
                if (editar) {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('AnticipoProveedorFormTabs', response));
                    dispatch(leerDetalleMod(poliza));
                } else {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('AnticipoProveedorFormTabs', response));
                    dispatch(leerDetalle(poliza));
                }
            }

        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al registrar la poliza',
                'Error',
                3000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
}

export const confirmarPoliza = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let dif = localStorage.getItem('dif');
    if (parseFloat(dif) === 0.00) {
        localStorage.removeItem('idPolizaAnticipoPro');
        localStorage.removeItem('dif');
        NotificationManager.success(
            'Póliza guardada correctamente',
            'Exito',
            3000
        );
        const editar = window.location.href.includes('editar');
        if (editar) {
            let ruta = `/compras/${id_emp[5]}/anticipos/${id_emp[7]}`
            dispatch(push(ruta));
        } else {
            window.location.reload(true);
        }

    } else {
        Swal.fire({
            title: 'Modificar poliza',
            text: `Poliza con diferencia: ${new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(dif)}`,
            type: 'error',
        })
    }
}

const eliminarAnticipo = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`anticipo_cxp/${id}`).then(() => {
        dispatch(listar(1));
        NotificationManager.success(
            'Anticipo borrado correctamente',
            'Exito',
            3000
        );
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar anticipo',
            'Error',
            3000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const leerAnticipos = (id, fecha_fin, moneda) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let idEmpre = id_emp[5];
    let fechaF = fecha_fin;
    const params = { id, idEmpre, fechaF, moneda }
    dispatch(setLoaderC(true));
    api.get('/egresos/anticipos_proveedor/', params).then((response) => {
        const saldos = response.filter((item) => parseFloat(item.total) >= 0.01);
        dispatch({ type: LISTADO_DOCUMENTOS, doc: saldos });
        const anticipos = [];
        response.forEach(item => {
            if (parseFloat(item.total) >= 0.01) {
                anticipos.push({
                    value: item.id + ' # ' + item.total + ' # ' + item.tipo + ' # ' + item.numero_cheque,
                    label: moment(item.fecha).format("DD/MM/YYYY") + ' --- ' + item.numero_cheque + ' -- ' + item.tipoDoc + ' ' + 'Total: ' + new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(item.total),
                })
            }
        })
        dispatch({ type: ANTICIPOS, list_ant: anticipos })
        api.get('/compra/documentos_proveedor/', params).then((response) => {
            const documento = [];
            response.detalle.forEach(item => {
                if (parseFloat(item.total) >= 0.01) {
                    documento.push({
                        value: item.id + ' # ' + item.total + ' # ' + item.tipo + ' # ' + item.fecha + ' # ' + item.serie + ' # ' + item.numero,
                        label: moment(item.fecha).format("DD/MM/YYYY") + ' --- ' +item.serie + ' / ' + item.numero + ' -- ' + ' Saldo: ' + new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(item.total),
                    })
                }
            })
            dispatch({ type: DOCUMENTOS, list_doc: documento })
        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al consultar documentos del proveedor',
                'Error',
                0
            );
        }).finally(() => {
            dispatch(setLoaderC(false));
        });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar documentos del proveedor',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoaderC(false));
    });

}

export const listarMonedas = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    api.get('/monedas/moneda_empresa', params).then((response) => {
        const moneda = [];
        response.forEach(item => {
            moneda.push({
                value: item.id,
                label: item.moneda + ' - ' + item.simbolo
            })
        })
        dispatch({ type: LISTADO_MONEDAS, monedas: moneda });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar monedas',
            'Error',
            3000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const leerDetalleDocumentos = id => (dispatch) => {
    api.get(`/anticipo_cxp_detalle/${id}`).then((response) => {
        dispatch({ type: DETALLE_DOCUMENTOS, detalleDoc: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};


export const leerPeriodoD = (id, id_periodo) => (dispatch) => {
    const params = { id, id_periodo };
    api.get('/periodos/periodo', params).then((response) => {
        dispatch({ type: PERIODO_D, periodoD: response.periodo });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar periodo',
            'Error',
            2000
        );
    })
}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = { id, modulo }
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response) => {
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const parametrosEmpresa = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id }
    dispatch(setLoader(true));
    api.get('/empresa/empresa_parametros', params).then((response) => {
        dispatch({ type: PARAMETRO, datos: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar parametros',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

let detalle = [];
export const agregarLinea = (periodoD) => (dispatch, getStore) => {
    const data = getStore().form.FormAnticipoProveedor.values;
    
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fecha_registro);

    if (fecha >= fechaI && fecha <= fechaF) {
        let docu = data.documento.value;
        let documento = docu.split('#');
        let ant = data.anticipo.value;
        let anticipo = ant.split('#');
        
        let factura = data.documento.value.split('#')
        let pro = data.proveedor_documento;
        if (pro === undefined) {
            pro = data.proveedor;
        } else {
            pro = data.proveedor_documento.value;
        }
        if (data.abono === 0.00) {
            Swal.fire({
                title: 'Anticipo de proveedor',
                text: 'Monto del documento no puede ser 0.00',
                type: 'error',
            })
        } else {
            if (detalle.length > 0) {
                const fac = detalle.find(item => item.factura === factura[0].trim());
                if (fac){
                    const proveedor = detalle.find(item => item.proveedor === data.proveedor_documento.value);
                    if (proveedor){
                        detalle.push({
                            id: data.documento.value,
                            factura: factura[0].trim(),
                            proveedor: data.proveedor_documento.value,
                            td: documento[2].trim(),
                            abono: data.abono,
                            fecha: documento[3].trim(),
                            documentoFac: documento[4].trim()+' / '+documento[5].trim(),
                            documento: anticipo[3].trim(),
                            monto: anticipo[1].trim(),
                            idAnticipo: anticipo[0].trim(),
                            saldo: parseFloat(anticipo[1] - parseFloat(data.abono)).toFixed(2)
                        })
                        data.detalleD = detalle;
                        data.abono = 0;
                        NotificationManager.success(
                            'Documento ingresado correctamente',
                            'Exito',
                            3000
                        );
                        dispatch(actualizar());
                    }else{
                        Swal.fire({
                            title: 'Anticipo de proveedor',
                            text: 'No puede cambiar de proveedor',
                            type: 'error',
                        })
                    }
                }else{
                    Swal.fire({
                        title: 'Anticipo de proveedor',
                        text: 'No puede cambiar de factura',
                        type: 'error',
                    })
                }
            } else {
                detalle.push({
                    id: data.documento.value,
                    factura: factura[0].trim(),
                    proveedor: data.proveedor_documento.value,
                    td: documento[2].trim(),
                    abono: data.abono,
                    fecha: documento[3].trim(),
                    documentoFac: documento[4].trim()+' / '+documento[5].trim(),
                    documento: anticipo[3].trim(),
                    monto: anticipo[1].trim(),
                    idAnticipo: anticipo[0].trim(),
                    saldo: parseFloat(anticipo[1] - parseFloat(data.abono)).toFixed(2)
                })
                data.detalleD = detalle;
                data.abono = 0;
                NotificationManager.success(
                    'Documento ingresado correctamente',
                    'Exito',
                    3000
                );
                dispatch(actualizar());
            }

        }
    }else {
        Swal.fire({
            title: 'Anticipo de proveedor',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })
    }
}

const vaciar = () => (dispatch) => {
    detalle = [];
    dispatch(initializeForm('FormAnticipoProveedor'));
    dispatch({ type: DETALLE, detalleDocN: detalle });
};

const actualizar = () => (dispatch, getStore) => {
    const data = getStore().form.FormAnticipoProveedor.values;
    dispatch(initializeForm('FormAnticipoProveedor', data));
    dispatch({ type: DETALLE, detalleDocN: data.detalleD });
};

export const tipoCambio = (periodoD) => (dispatch, getStore) => {
    const data = getStore().form.FormAnticipoProveedor.values;
    
    if (data === undefined) {
        Swal.fire({
            title: 'Abono de proveedor',
            text: 'Debe de ingresar datos de abono de proveedor',
            type: 'error',
        })
    } else {
        let fechaI = moment(periodoD.fecha_inicio);
        let fechaF = moment(periodoD.fecha_fin);
        let fecha = moment(data.fecha_registro)
        if (fecha >= fechaI && fecha <= fechaF) {
            if (data.fecha_registro === undefined) {
                Swal.fire({
                    title: 'Anticipo de cliente',
                    text: 'Debe de ingresar fecha del abono de proveedor',
                    type: 'error',
                })
            } else {
                let fecha = data.fecha_registro;
                let ruta = window.location.href;
                let id_emp = ruta.split('/');
                let idEmpre = id_emp[5];
                const params = { fecha, idEmpre }
                dispatch(setLoaderC(true));
                return api.get('/tipo_cambio/tipo_cambio_fecha/', params).then((response) => {
                    if (parseFloat(response) === 0) {
                        Swal.fire({
                            title: 'No hay tipo de cambio del dia' + ' ' + moment(fecha).format("DD/MM/YYYY"),
                            html:
                                '<div class="col-sm-12 mt-4">' +
                                '<h4 style="color: white;">¿Desea ingresarlo?</h4>' +
                                '</div>',
                            type: 'info',
                            background: "black",
                            showCancelButton: true,
                            confirmButtonText: 'Si',
                            cancelButtonText: 'No',
                            confirmButtonColor: "#4040ff",
                            cancelButtonColor: "#646464",
                            reverseButtons: true,
                            customClass: {
                                title: 'sweet_titleImportant',
                            },
                        }).then((result) => {
                            if (result.value) {
                                Swal.fire({
                                    title: 'Ingrese tipo de cambio',
                                    html:
                                        '<div class="col-sm-12 mt-3">' +
                                        '<h4 style="color: white">' + moment(fecha).format("DD/MM/YYYY") + '</h4>' +
                                        '<input type="number" min="1" id="cambio" class="swal2-input form-control">' +
                                        '</div>',
                                    type: 'info',
                                    background: "black",
                                    showCancelButton: true,
                                    confirmButtonText: 'Si',
                                    cancelButtonText: 'No',
                                    confirmButtonColor: "#4040ff",
                                    cancelButtonColor: "#646464",
                                    reverseButtons: true,
                                    customClass: {
                                        title: 'sweet_titleImportant',
                                    },
                                }).then((result) => {
                                    if (result.value) {
                                        let tcambio = document.getElementById('cambio').value
                                        const formData = {
                                            fechaT: fecha,
                                            tipoCambio: tcambio,
                                            empresa: id_emp[5],
                                            tipo: 'manual'
                                        }
                                        api.post('/tipo_cambio/', formData).then(response => {
                                            NotificationManager.success(
                                                'Tipo de cambio guardado',
                                                'Exito',
                                                4000
                                            );

                                        }).catch((error) => {
                                            NotificationManager.error(
                                                error.msj,
                                                'Error',
                                                0
                                            );
                                        })
                                    }
                                });
                            }
                        });
                    } else {
                        return response;
                    }
                }).catch((error) => {
                    NotificationManager.error(
                        'Ocurrió un error al consultar tipo de cambio',
                        'Error',
                        2000
                    );
                }).finally(() => {
                    dispatch(setLoaderC(false));
                });
            }
        } else {
            Swal.fire({
                title: 'Anticipo de proveedor',
                text: 'Fecha fuera del periodo',
                type: 'error',
            })
        }
    }
}

const eliminarLineaAbonoNuevo = (id) => (dispatch, getStore) => {
    const data = getStore().form.FormAnticipoProveedor.values;
    let totAbono = parseFloat(data.monto);
    let totDoc = 0.00;
    
    detalle.forEach((element, index) => {
        if (element.id === id) {
            detalle.splice(index, 1);
        }
    })
    detalle.forEach(item => totDoc += parseFloat(item.abono));
    data.totalAbono = parseFloat(totDoc);
    data.diferencia = parseFloat(totAbono - parseFloat(totDoc));
    data.detalleD = detalle;
    dispatch(actualizar());
};

const limpiarAnticipos = () => (dispatch) => {
    dispatch({ type: LISTADO_DOCUMENTOS, doc: [] });
};

export const actions = {
    listar,
    registroAnticipo,
    leer,
    listarProveedores,
    leerDetalle,
    eliminarLinea,
    listarCuentas,
    listarCC,
    listarNiveles,
    registroCuenta,
    reporteCuentas,
    registroPolizaDetalle,
    confirmarPoliza,
    registroPolizaDetalleSCC,
    eliminarAnticipo,
    listarMonedas,
    leerDetalleDocumentos,
    leerPeriodoD,
    getModulo,
    parametrosEmpresa,
    agregarLinea,
    vaciar,
    actualizar,
    tipoCambio,
    leerAnticipos,
    eliminarLineaAbonoNuevo,
    limpiarAnticipos
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_ABONO]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [DETALLE_POLIZA]: (state, { detalle }) => {
        return {
            ...state,
            detalle,
        };
    },
    [LISTADO_PROVEEDOR]: (state, { pro }) => {
        return {
            ...state,
            pro,
        };
    },
    [NIVEL]: (state, { nivel }) => {
        return {
            ...state,
            nivel,
        };
    },
    [LOADERC]: (state, { loaderC }) => {
        return {
            ...state,
            loaderC,
        };
    },
    [GUARDAR_POLIZA]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
    [GUARDAR_ABONO]: (state, { lecturaA }) => {
        return {
            ...state,
            lecturaA,
        };
    },
    [LISTADO_DOCUMENTOS]: (state, { doc }) => {
        return {
            ...state,
            doc,
        };
    },
    [LISTADO_MONEDAS]: (state, { monedas }) => {
        return {
            ...state,
            monedas,
        };
    },
    [DETALLE_DOCUMENTOS]: (state, { detalleDoc }) => {
        return {
            ...state,
            detalleDoc,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [PERIODO_D]: (state, { periodoD }) => {
        return {
            ...state,
            periodoD,
        };
    },
    [PARAMETRO]: (state, { datos }) => {
        return {
            ...state,
            datos,
        };
    },
    [DOCUMENTOS]: (state, { list_doc }) => {
        return {
            ...state,
            list_doc,
        };
    },
    [DETALLE]: (state, { detalleDocN }) => {
        return {
            ...state,
            detalleDocN,
        };
    },
    [ANTICIPOS]: (state, { list_ant }) => {
        return {
            ...state,
            list_ant,
        };
    },
};

export const initialState = {
    loader: false,
    tipos: [],
    pro: [],
    lectura: {
        polizaDetalle_polizaEncabezado: []
    },
    lecturaA: [],
    doc: [],
    periodoS: null,
    detalleDoc: [],
    nivel: [],
    monedas: [],
    loaderC: false,
    detalle: [],
    detalleDocN: [],
    listaEs: [],
    modulo: [],
    list_doc: [],
    periodoD: [],
    datos: [],
    data: [],
    list_ant: [],
    page: 1,
};

export default handleActions(reducers, initialState)