import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import './acciones.css';
import Swal from 'sweetalert2';
import moment from 'moment';

class Acciones extends Component {
    constructor(props) {
        super(props);
    }

    eliminar = (id) => {
        return () => {
            Swal.fire({
                title: '¿Eliminar?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.eliminar(id);
                }
            });
        }
    };

    eliminar_documento_rh = (id, documento) => {
        return () => {
            Swal.fire({
                title: '¿Eliminar documento: '+ documento + '?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.eliminar_documento_rh(id);
                }
            });
        }
    };

    eliminarLinea = (id, sueldo) => {
        return () => {
            Swal.fire({
                title: '¿Eliminar salario de ' + sueldo+ ' ?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.eliminarLinea(id);
                }
            });
        }
    };

    eliminarRegistro = (id, fechaC) => {
        return () => {
            Swal.fire({
                title: '¿Eliminar registro fecha de corte ' + moment(fechaC).format("DD/MM/YYYY") + ' ?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.eliminarRegistro(id);
                }
            });
        }
    };

    eliminarCargo = (id, departamento, puesto) => {
        return () => {
            Swal.fire({
                title: '¿Eliminar departamento ' + departamento + ' puesto ' + puesto +' ?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.eliminarCargo(id);
                }
            });
        }
    };


    render() {
        const { id, ver, editar, eliminar, eliminar_documento_rh, documento_rh, fechaF, eliminarLinea,
            sueldo, cantLineas, fechaC, eliminarRegistro, eliminarCargo, departamento, puesto } = this.props;

        return (
            <div className="d-flex justify-content-center">
                {(ver !== undefined) && (
                    <Link style={{color: "white"}} to={`${ver}/${id}/`} className="px-2" ><i className="material-icons">remove_red_eye</i></Link>
                )}
                {(editar !== undefined) && (
                    <Link style={{color: "white"}} to={`${editar}/${id}/editar`} ><i className="material-icons">edit</i></Link>
                )}
                {(eliminar_documento_rh !== undefined) && (
                    <a className="px-2" style={{cursor: "pointer", color: "white"}} onClick={this.eliminar_documento_rh(id, documento_rh)}><i className="material-icons">delete</i></a>
                )}
                {(eliminar !== undefined) && (
                    <a className="px-2" style={{cursor: "pointer", color: "white"}} onClick={this.eliminar(id)}><i className="material-icons">delete</i></a>
                )}
                {(eliminarLinea !== undefined && fechaF === null && cantLineas >=2) && (
                    <a className="px-2" style={{cursor: "pointer", color: "white"}} onClick={this.eliminarLinea(id, sueldo)}><i className="material-icons">delete</i></a>
                )}
                 {(eliminarRegistro !== undefined) && (
                    <a className="px-2" style={{cursor: "pointer", color: "white"}} onClick={this.eliminarRegistro(id, fechaC)}><i className="material-icons">delete</i></a>
                )}
                {(eliminarCargo !== undefined) && (
                    <a className="px-2" style={{cursor: "pointer", color: "white"}} onClick={this.eliminarCargo(id, departamento, puesto)}><i className="material-icons">delete</i></a>
                )}
            </div>
        );
    }
}
Acciones.propTypes = {
};

export function standardActions(acciones) {
    return (cell, row) => {
        return ( <Acciones id={cell} documento_rh={row.documento} fechaF={row.fecha_fin} 
            sueldo={row.sueldo_base} cantLineas={row.lineas} fechaC={row.fecha_corte}
            departamento={row.departamentoN} puesto={row.puestoN} {...acciones}/> )
    };
}
