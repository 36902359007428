export {
    renderField,
    renderFieldPoliza,
    renderTextArea,
    renderSearchSelect,
    renderSearchCreateSelect,
    renderSelectField,
    renderNumber,
    renderCurrencyPoliza,
    renderCurrency,
    renderSwitch,
    renderCurrencyFloat,
} from './renderField'