import React, { Component } from 'react';
import Formulario from './FaltaForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";
const valEmp = __CONFIG__.empresa;

class Falta extends Component{
    state = {
        crear: true,
    }

    componentDidMount = () => {
        const { match, getModulo, listarColaborador, leer,
        limpiarDatos } = this.props;
        const id = match.params.id;
        if (id){
            this.setState({crear: false});
            leer(id);
        }
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        if (valEmp === 'fage'){
            getModulo(modulo[6],modulo[5])
        }else{
            getModulo(modulo[5],modulo[4]);
        }
        listarColaborador();
        limpiarDatos();
    }
    
    render(){
        const { loader, modulo, emp, registroFalta, tipo,
            datosColaborador, cargo, lectura, modificarFalta } = this.props;
        const { crear } = this.state;
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    crear = {crear}
                    modulo = {modulo}
                    emp = {emp}
                    registroFalta = {registroFalta}
                    cargo = {cargo}
                    tipo = {tipo}
                    datosColaborador = {datosColaborador}
                    lectura = {lectura}
                    modificarFalta = {modificarFalta}
                />
            </LoadMask>
        );
    }
}

export default Falta;