import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import NavbarModulo from '../../components/NavbarModulo';
import NavbarModuloLF from '../../components/NavbarModuloFage';
import {
    renderField,
    renderCurrencyFloat,
    AsyncSelectFieldIn,
    SelectField,
    renderDayPickerB
} from "../../Utils/renderField/renderField";

const valEmp = __CONFIG__.empresa;

function DescuentosForm(props) {
    const { crear, modulo, 
        periodoId, emp, registroDescuento,
        lectura, modificarDescuento, tipos } = props;

    const editar = window.location.href.includes('editar');
    let titulo = editar ? 'Modificar descuento' : 'Crear descuento';
    let disabled = false;
    if (crear === false && editar === false) {
        disabled = true;
        titulo = 'Ver descuento'
    }
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    
    const tipoD = [
        { label: "1RA. QUINCENA", value: "1Q" },
        { label: "2DA. QUINCENA", value: "2Q" },
    ];

    return (
        <div>
            {valEmp === 'fage' &&
                <NavbarModuloLF
                    modulo={modulo}
                    titulo={titulo}
                />
            }
            {valEmp === '' &&
                <NavbarModulo
                    modulo={modulo}
                    titulo={titulo}
                />
            }
            <br />
            <div className="mb-4 card card-small text-white bg-dark mb-3">
                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                    <div className="d-flex flex-column flex-1 mx-4">
                        {crear === true &&
                            <div className='row mb-2'>
                                <div className='col-6'>
                                    <div className="form-floating">
                                        <Field
                                            name="tipo"
                                            options={tipos}
                                            dato={0}
                                            component={SelectField}
                                            className="form-select"
                                            msj="Tipo de descuento"
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {crear === false && editar === false && 
                            <div className='row mb-2'>
                                <div className='col-6'>
                                    <div className="form-floating">
                                        <Field
                                            name="tipo_descuento"
                                            options={tipos}
                                            disabled={true}
                                            dato={lectura.tipo_descuento}
                                            component={SelectField}
                                            className="form-select"
                                            msj="Tipo de descuento"
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {editar === true &&
                            <div className='row mb-2'>
                                <div className='col-6'>
                                    <div className="form-floating">
                                        <Field
                                            name="tipo_descuento"
                                            options={tipos}
                                            dato={lectura.tipo_descuento}
                                            component={SelectField}
                                            className="form-select"
                                            msj="Tipo de descuento"
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {crear === true &&
                            <div className='row mb-2'>
                                <div>
                                    <Field
                                        name="colaborador_descuento"
                                        placeholder="Colaborador..."
                                        className="form-control-find"
                                        component={AsyncSelectFieldIn}
                                        loadOptions={emp}
                                        msj="Colaborador"
                                    />
                                </div>
                            </div>
                        }
                        {crear === false && editar === false &&
                            <div className='row mb-2'>
                                <div className='col-12'>
                                    <Field
                                        name="nombre"
                                        disabled={true}
                                        placeholder="Descripcion"
                                        component={renderField}
                                        className="form-control"
                                        msj="Colaborador"
                                    />
                                </div>
                            </div>
                        }
                        {editar === true &&
                            <div className='row mb-2'>
                                <div className='col-12'>
                                    <Field
                                        name="nombre"
                                        disabled={true}
                                        placeholder="Descripcion"
                                        component={renderField}
                                        className="form-control"
                                        msj="Colaborador"
                                    />
                                </div>
                            </div>
                        }
                        {crear === true &&
                            <div className='row mb-2'>
                                <div className='col-3'>
                                    <Field
                                        name="fecha_inicio"
                                        placeholder="Fecha inicio"
                                        className="form-control-find"
                                        component={renderDayPickerB}
                                        msj="Fecha inicio"
                                    />
                                </div>
                                <div className='col-3'>
                                    <div className="form-floating">
                                        <Field
                                            name="monto"
                                            placeholder="Monto"
                                            className="form-control"
                                            component={renderCurrencyFloat}
                                            msj="Monto"
                                        />
                                    </div>
                                </div>
                                <div className='col-3'>
                                    <div className="form-floating">
                                        <Field
                                            name="forma_desc"
                                            disabled={disabled}
                                            options={tipoD}
                                            crear={crear}
                                            dato={crear === true ? 0 : ''}
                                            component={SelectField}
                                            className="form-select"
                                            msj="Frecuencia de pago"
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {editar === true &&
                            <div className='row mb-2'>
                                <div className='col-3'>
                                    <Field
                                        name="fecha_inicial"
                                        placeholder="Fecha inicio"
                                        className="form-control-find"
                                        component={renderDayPickerB}
                                        msj="Fecha inicio"
                                    />
                                </div>
                                <div className='col-3'>
                                    <div className="form-floating">
                                        <Field
                                            name="monto"
                                            placeholder="Monto"
                                            className="form-control"
                                            component={renderCurrencyFloat}
                                            msj="Monto"
                                        />
                                    </div>
                                </div>
                                <div className='col-3'>
                                    <div className="form-floating">
                                        <Field
                                            name="forma_desc"
                                            disabled={disabled}
                                            options={tipoD}
                                            crear={crear}
                                            dato={lectura.forma_descuento}
                                            component={SelectField}
                                            className="form-select"
                                            msj="Frecuencia de pago"
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {crear === false && editar === false &&
                            <div className='row mb-2'>
                                <div className='col-3'>
                                    <Field
                                        name="fecha_inicial"
                                        disabled={true}
                                        placeholder="Fecha inicio"
                                        className="form-control-find"
                                        component={renderDayPickerB}
                                        msj="Fecha inicio"
                                    />
                                </div>
                                <div className='col-3'>
                                    <div className="form-floating">
                                        <Field
                                            name="monto"
                                            disabled={true}
                                            placeholder="Monto"
                                            className="form-control"
                                            component={renderCurrencyFloat}
                                            msj="Monto"
                                        />
                                    </div>
                                </div>
                                <div className='col-3'>
                                    <div className="form-floating">
                                        <Field
                                            name="forma_descuento"
                                            disabled={disabled}
                                            options={tipoD}
                                            crear={crear}
                                            dato={lectura.forma_descuento}
                                            component={SelectField}
                                            className="form-select"
                                            msj="Frecuencia de pago"
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        <div className='row mb-2'>
                            <div className='col-md-12'>
                                <Field
                                    name="descripcion"
                                    disabled={disabled}
                                    placeholder="Descripcion"
                                    component={renderField}
                                    className="form-control"
                                    msj="Descripcion"
                                />
                            </div>
                        </div>
                        <div className='d-flex flex-row mt-3'>
                            {crear == true && editar == false &&
                                <button
                                    className='btn mr-2 mb-3 btn-primary'
                                    type='submit'
                                    onClick={() => registroDescuento(periodoId)}
                                >
                                    Guardar
                                </button>
                            }
                            {editar == true && crear == false &&
                                <button
                                    className='btn mr-2 mb-3 btn-primary'
                                    type='submit'
                                    onClick={() => modificarDescuento()}
                                >
                                    Modificar
                                </button>
                            }
                            {valEmp === 'fage' &&
                                <a
                                    href={`/#/lfg/rr_hh/${id_emp[6]}/descuentos_fijos`}
                                    className='btn btn-secondary mb-3'
                                >
                                    Regresar
                                </a>
                            }
                            {valEmp === '' &&
                                <a
                                    href={`/#/rr_hh/${id_emp[5]}/descuentos_fijos`}
                                    className='btn btn-secondary mb-3'
                                >
                                    Regresar
                                </a>
                            }
                        </div>
                    </div>
                </div>
            </div>

        </div >
    );
}

DescuentosForm.propTypes = {
    handleSubmit: PropTypes.func,
    crear: PropTypes.bool,
    modulo: PropTypes.object,
};

export default reduxForm({
    form: 'FormDescuentoFijoRH'
    
})(DescuentosForm);
