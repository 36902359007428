import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';
import moment from 'moment';

const LISTADO_NC = 'LISTADO_NC';
const NOTA_CREDITO = 'NOTA_CREDITO';
const DETALLE_NC = 'DETALLE_NC';
const LOADER = 'LOADER';
const MODULO = 'MODULO';
const PERIODO_D = 'PERIODO_D';
const PARAMETRO = 'PARAMETRO';
const LOADERC = 'LOADERC';
const LISTADO_DOCUMENTOS = 'LISTADO_DOCUMENTOS';
const DETALLE_DOCUMENTOS = 'DETALLE_DOCUMENTOS';
const DOCUMENTOS = 'DOCUMENTOS';
const LISTADO_MONEDAS = 'LISTADO_MONEDAS';
const DETALLE_POLIZA = 'DETALLE_POLIZA';
const LISTADO_TIPOS_DOCU = 'LISTADO_TIPOS_DOCU';
const NIVEL = 'NIVEL';
const DETALLE = 'DETALLE';
const GUARDAR_POLIZA = 'GUARDAR_POLIZA';
const ESTABLECIMIENTO = 'ESTABLECIMIENTO';
const LISTADO_ESTABLECIMIENTOS = 'LISTADO_ESTABLECIMIENTOS';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setLoaderC = loaderC => ({
    type: LOADERC,
    loaderC,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let id_period = id_emp[7];
    let tipo = 'NC'
    //const params = { page, id, id_period, tipo };
    const params = { id, id_period, tipo };
    dispatch(setLoader(true));
    api.get('/nota_credito_debito', params).then((response) => {
        dispatch({ type: LISTADO_NC, data: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar notas de crédito',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leerPeriodoD = (id, id_periodo) => (dispatch) => {
    const params = { id, id_periodo };
    api.get('/periodos/periodo', params).then((response) => {
        dispatch({ type: PERIODO_D, periodoD: response.periodo });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar periodo',
            'Error',
            2000
        );
    })
}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const parametrosEmpresa = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id}
    dispatch(setLoader(true));
    api.get('/empresa/empresa_parametros', params).then((response) => {
        dispatch({type: PARAMETRO, datos: response });
    }).catch((error)=>{
        
        NotificationManager.error(
            'Ocurrió un error al consultar parametros',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const listarTiposDocu = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    api.get('/tiposFac', params).then((response) => {
        const tiposD = [];
        response.results.forEach(item => {
            if (item.tipo_documento === 'ABONO'){
                tiposD.push({
                    value: item.id,
                    label: item.tipo + ' - ' + item.descripcion,
                    search: item.categoria
                })
            }
        })
        dispatch({ type: LISTADO_TIPOS_DOCU, tipos: tiposD });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar tipos de documento',
            'Error',
            3000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const listarClientes = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id, search}
    return api.get('/cliente/empresa_clientes',params).then(data=>{
        const list_cliente = [];
        if(data){
            data.forEach(item=>{
                list_cliente.push({
                    value: item.id,
                    label: item.identificador + ' ' + item.nombre
                })
            })
        }
        return list_cliente ;
    }).catch((error)=>{
        
        return [];
    })
}

export const listarCuentas = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/cuentas/empresa_cuentas/', params).then(data => {
        const list_cuentas = [];
        if (data) {
            data.forEach(item => {
                if (item.tipo === 'A') {
                    list_cuentas.push({
                        value: item.id,
                        label: item.cuenta + ' ' + item.nombre,
                        isDisabled: true
                    })
                } else {
                    list_cuentas.push({
                        value: item.id,
                        label: item.cuenta + ' ' + item.nombre
                    })
                }
            })
        }
        return list_cuentas;
    }).catch((error) => {
        
        return [];
    })
}

export const listarCC = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/centrocosto/empresa_centro_costo/', params).then(data => {
        const list_cc = [];
        if (data) {
            data.forEach(item => {
                list_cc.push({
                    value: item.id,
                    label: item.codigo + ' ' + item.descripcion
                })
            })
        }
        return list_cc;
    }).catch((error) => {
        
        return [];
    })
}

export const listarNiveles = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    dispatch(setLoader(true));
    api.get('/niveles/nivel_cuenta', params).then((response) => {
        dispatch({ type: NIVEL, nivel: response });
    }).catch((error) => {
        
        NotificationManager.error(
            'Ocurrió un error al listar nivel',
            'Error',
            3000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const eliminarLinea = id => (dispatch) => {
    const poliza = localStorage.getItem('idNC_cliente');
    dispatch(setLoader(true));
    api.eliminar(`poliza_detalle/${id}`).then(() => {
        const editar = window.location.href.includes('editar');
        if (editar) {
            dispatch(leerDetallePolizaMod(poliza));
        } else {
            dispatch(leerDetallePoliza(poliza));
        }
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar linea de poliza',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const registroCuenta = (formato) => (dispatch, getStore) => {
    const data = getStore().form.NotaCreditoClienteCxCFormTabs.values;
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let cuenta = data.cuenta
    let f = formato.split('-');
    let cont = 0;
    let formatoCuenta = '';
    let digito = '';

    if (f.length === 3) {
        let nivel1 = f[0].length
        digito = cuenta.substr(cont, nivel1);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel2 = f[1].length;
        cont = cont + nivel1;
        digito = cuenta.substr(cont, nivel2);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel3 = f[2].length;
        cont = cont + nivel2;
        digito = cuenta.substr(cont, nivel3);
        formatoCuenta = formatoCuenta + digito;

    }

    if (f.length === 4) {
        let nivel1 = f[0].length
        digito = cuenta.substr(cont, nivel1);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel2 = f[1].length;
        cont = cont + nivel1;
        digito = cuenta.substr(cont, nivel2);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel3 = f[2].length;
        cont = cont + nivel2;
        digito = cuenta.substr(cont, nivel3);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel4 = f[3].length;
        cont = cont + nivel3;
        digito = cuenta.substr(cont, nivel4);
        formatoCuenta = formatoCuenta + digito;
    }

    if (f.length === 5) {
        let nivel1 = f[0].length
        digito = cuenta.substr(cont, nivel1);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel2 = f[1].length;
        cont = cont + nivel1;
        digito = cuenta.substr(cont, nivel2);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel3 = f[2].length;
        cont = cont + nivel2;
        digito = cuenta.substr(cont, nivel3);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel4 = f[3].length;
        cont = cont + nivel3;
        digito = cuenta.substr(cont, nivel4);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel5 = f[4].length;
        cont = cont + nivel4;
        digito = cuenta.substr(cont, nivel5);
        formatoCuenta = formatoCuenta + digito;
    }

    if (f.length === 6) {
        let nivel1 = f[0].length
        digito = cuenta.substr(cont, nivel1);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel2 = f[1].length;
        cont = cont + nivel1;
        digito = cuenta.substr(cont, nivel2);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel3 = f[2].length;
        cont = cont + nivel2;
        digito = cuenta.substr(cont, nivel3);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel4 = f[3].length;
        cont = cont + nivel3;
        digito = cuenta.substr(cont, nivel4);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel5 = f[4].length;
        cont = cont + nivel4;
        digito = cuenta.substr(cont, nivel5);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel6 = f[5].length;
        cont = cont + nivel5;
        digito = cuenta.substr(cont, nivel6);
        formatoCuenta = formatoCuenta + digito;
    }
    let idPoliza = localStorage.getItem('idPoliza_cxc')
    const formData = {
        cuenta: formatoCuenta,
        nombre: data.nombre,
        tipo: data.tipoC,
        naturaleza: data.naturaleza,
        empresa: id_emp[5],
        poliza: idPoliza
    }
    dispatch(setLoaderC(true));
    api.post('/cuentas/', formData).then((response) => {
        if (idPoliza) {
            NotificationManager.success(
                'Cuenta creada' + ' ' + response.cuenta.cuenta + ' ' + response.cuenta.nombre,
                'Exito',
                0
            );
            response.cuenta = '';
            response.nombre = '';
            response.fecha = response.poliza.fecha;
            response.tipo = response.poliza.tipo;
            response.numero_poliza = response.poliza.numero_poliza;
            response.descripcion = response.poliza.descripcion;
            listarCuentas();

        }else{
            NotificationManager.success(
                'Cuenta creada' + ' ' + response.cuenta + ' ' + response.nombre,
                'Exito',
                0
            );
            response.cuenta = '';
            response.nombre = '';
            listarCuentas();
        }

    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoaderC(false));
    });
}

export const reporteCuentas = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let reporte = 'reporte';
    const params = { id, reporte };
    dispatch(setLoaderC(true));;
    api.getPdf('/cuentas', params).then((response) => {
        if (response.headers['content-type'] == 'application/pdf') {
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'reporte_cuentas_contables.pdf';
            window.open(link, '_blank');
            return false;
        }
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al generar reporte',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoaderC(false));
    });
}

export const listarMonedas = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    api.get('/monedas/moneda_empresa', params).then((response) => {
        const moneda = [];
        response.forEach(item => {
            moneda.push({
                value: item.id,
                label: item.moneda + ' - ' + item.simbolo
            })
        })
        dispatch({ type: LISTADO_MONEDAS, monedas: moneda });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar monedas',
            'Error',
            3000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leerDocumentos = (id, fecha_fin, tipoM) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let idEmpre = id_emp[5];
    let fechaF = fecha_fin;
    let tipo = 'abono';
    const params = {id, idEmpre, fechaF, tipo, tipoM }
    dispatch(setLoaderC(true));
    api.get('/ingresos/documentos_cliente/',params).then((response) => {
        const saldos = response.detalle.filter((item) => parseFloat(item.total) >= 0.01)
        dispatch({ type: LISTADO_DOCUMENTOS, doc: saldos });
        const documento = [];
        response.detalle.forEach(item => {
            if (parseFloat(item.total) >= 0.01) {
                documento.push({
                    value: item.id + ' # ' + item.total + ' # ' + item.fecha + ' # ' + item.serie + ' # ' + item.numero + ' # ' + item.dolares + ' # ' + item.tipoFactura,
                    label: moment(item.fecha).format("DD/MM/YYYY") + ' --- ' + item.serie + ' / ' + item.numero + ' -- ' + ' Saldo: ' + new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(item.total),
                })
            }
        })
        
        dispatch({ type: DOCUMENTOS, list_doc: documento })
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar documentos del cliente',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoaderC(false));
    });

}

const leerDetalleDocumentos = id => (dispatch) => {
    api.get(`/abono_cxc_detalle/${id}`).then((response)=>{
        dispatch({ type: DETALLE_DOCUMENTOS, detalleDoc: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};

const leerDetalle = id => (dispatch) => {
    api.get(`/poliza_detalle/${id}`).then((response) => {
        dispatch({ type: DETALLE_POLIZA, detalle: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            3000
        );
    })
};

let detalle = [];
export const agregarLinea = (periodoD) => (dispatch, getStore) => {
    const data = getStore().form.FormNotaCreditoCxCCliente.values;

    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fecha_registro);

    if (fecha >= fechaI && fecha <= fechaF) {
        let docu = data.documento.value;
        let documento = docu.split('#');
        let saldo = parseFloat(documento[1].trim());
        let abono = parseFloat(data.abono);
        let totAbono = 0.00;
        if (data.monto === undefined){
            totAbono = 0.00
        }else{
            totAbono = parseFloat(data.monto);
        }
        let dif = parseFloat(data.diferencia);
        let totDoc = 0.00;

        let cli = data.cliente_documento;
        if (cli === undefined) {
            cli = data.cliente;
        } else {
            cli = data.cliente_documento.value;
        }
        if (totAbono === 0.00){
            Swal.fire({
                title: 'Nota de crédito',
                text: 'Monto del documento no puede ser 0.00',
                type: 'error',
            })
        }else{
            if (abono > totAbono) {
                Swal.fire({
                    title: 'Nota de crédito',
                    text: 'El abono no puede ser mayor al monto del documento',
                    type: 'error',
                })
            } else {
                if (abono === 0 || data.abono === undefined) {
                    Swal.fire({
                        title: 'Nota de crédito',
                        text: 'El abono no puede ser 0.00',
                        type: 'error',
                    })
                } else {
                    if (abono > saldo) {
                        Swal.fire({
                            title: 'Nota de crédito',
                            text: 'El abono es mayor al saldo del documento',
                            type: 'error',
                        })
                    } else {
                        if (detalle.length === 0) {
                            const dato = detalle.find(item => item.id === documento[0].trim());

                            if (dato) {
                                NotificationManager.error(
                                    'Documento ya fue ingresado',
                                    'Error',
                                    3000
                                );
                            } else {
                                if (abono > dif) {
                                    Swal.fire({
                                        title: 'Nota de crédito',
                                        text: 'El abono es mayor a la diferencia',
                                        type: 'error',
                                    })
                                } else {
                                    detalle.push({
                                        id: documento[0].trim(),
                                        abono: data.abono,
                                        fecha: documento[2].trim(),
                                        serie: documento[3].trim(),
                                        numero: documento[4].trim(),
                                        monto: documento[1].trim(),
                                        dolares: documento[5].trim(),
                                        saldo: parseFloat(documento[1] - parseFloat(data.abono)).toFixed(2),
                                        tipoFactura: documento[6].trim()
                                    })
                                    detalle.forEach(item => totDoc += parseFloat(item.abono));
                                    data.totalAbono = parseFloat(totDoc);
                                    data.diferencia = parseFloat(parseFloat(totAbono - parseFloat(totDoc))).toFixed(2);
                                    data.detalleD = detalle;
                                    data.abono = 0;
                                    NotificationManager.success(
                                        'Documento ingresado correctamente',
                                        'Exito',
                                        3000
                                    );
                                    dispatch(actualizar());
                                }
                            }
                        } else {
                            Swal.fire({
                                title: 'Nota de crédito',
                                text: 'Solamente puede ingresar una factura',
                                type: 'error',
                            })
                        }

                    }
                }
            }
        }
    }else {
        Swal.fire({
            title: 'Nota de crédito',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })
    }
}

const actualizar = () => (dispatch, getStore) => {
    const data = getStore().form.FormNotaCreditoCxCCliente.values;
    dispatch(initializeForm('FormNotaCreditoCxCCliente', data));
    dispatch({ type: DETALLE, detalleDocN: data.detalleD });
};

export const registroNotaCredito = (periodoD, datos) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormNotaCreditoCxCCliente.values;
    
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fecha_registro);
    let poliza = '';
    if (fecha>=fechaI && fecha<=fechaF){
        if (parseFloat(data.diferencia) === 0){
            let factDolares = ''
            let tipoCambio = localStorage.getItem('TC_NC_cxc')
            data.detalleD.forEach((e) => {
                factDolares = e.dolares
            });
            if (factDolares === 'true' && parseFloat(tipoCambio) === 1) {
                localStorage.setItem('conf_NCCL_cxc', 'F');
                Swal.fire({
                    title: 'Nota de crédito',
                    text: 'Tipo de cambio no puede ser 1.000000',
                    type: 'error',
                })
            } else {
                if (datos.contabilidad === 'S') {
                    poliza = 'NC';
                }
                if (datos.contabilidad === 'N') {
                    poliza = '';
                }

                const formData = {
                    fecha_registro: data.fecha_registro,
                    monto: parseFloat(data.monto),
                    descripcion: data.descripcion,
                    cliente: data.cliente_documento.value,
                    tipo_cambio: data.tipoCambio,
                    detalleAbono: data.detalleD,
                    serie: data.serie,
                    numero: data.numero,
                    establecimiento: id_emp[9],
                    tipo: 'NC',
                    poliza: poliza,
                    modulo: 'FAC',
                    empresa: id_emp[5],
                    periodo: id_emp[7],
                    tipoCambio: tipoCambio,
                    moneda: localStorage.getItem('moneda_TC_cxc'),
                    dolares: factDolares,
                }

                dispatch(setLoader(true));
                api.post('/nota_credito_debito/nota_credito_cxc/', formData).then((response) => {
                    NotificationManager.success(
                        'Nota de crédito creada correctamente',
                        'Exito',
                        3000
                    );

                    response.fecha_registro = response.encabezado.fecha_registro;
                    response.monto = response.encabezado.abono;
                    response.descripcion = response.encabezado.descripcion;
                    response.tipCambio = response.encabezado.tipCambio;
                    dispatch(initializeForm('FormNotaCreditoCxCCliente', response));
                    dispatch(leerDetallePoliza(response.idPoliza));
                    if (response.idPoliza) {
                        localStorage.setItem('conf_NCCL_cxc', 'T');
                        localStorage.setItem('idNC_cliente_poliza_cxc', response.idPoliza);
                        dispatch(leerDetalle(response.idPoliza));
                    }

                }).catch((error) => {
                    localStorage.setItem('conf_NCCL_cxc', 'F')
                    NotificationManager.error(
                        error.msj,
                        'Error',
                        2000
                    );
                }).finally(() => {
                    dispatch(setLoader(false));
                });
            }
        }else{
            localStorage.setItem('conf_NCCL_cxc','F')
            Swal.fire({
                title: 'Nota de crédito',
                text: `Abono con diferencia: ${new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(data.diferencia)}`,
                type: 'error',
            })    
        }
        
    }else {
        localStorage.setItem('conf_NCCL_cxc','F')
        Swal.fire({
            title: 'Nota de crédito',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })    
    }
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/nota_credito_debito/${id}`).then((response) => {
        if (response.estatus === '**ANULADO**'){
            response.anulado = true;
        }
        dispatch({ type: NOTA_CREDITO, lectura: response });
        dispatch(initializeForm('FormNotaCreditoCxCCliente', response ));
        dispatch(leerDetalleNC(response.documento, response.monto, response.abono, response.saldo));
        localStorage.setItem('idNC_cliente_poliza_cxc', response.poliza);
        dispatch(leerDetallePolizaMod(response.poliza));
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar nota de crédito',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

const leerDetalleNC = (documento, monto, abono, saldo ) => (dispatch) => {
    api.get(`/factura/${documento}`).then((response)=>{
        let detalleNota = [];
        detalleNota.push({
            fecha: response.fecha_registro,
            serie: response.serie,
            numero: response.numero,
            monto: monto,
            abono: abono,
            saldo: saldo
        })
       
        dispatch({ type: DETALLE_NC, detalleNC: detalleNota });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};

const leerDetallePoliza = id => (dispatch) => {
    api.get(`/poliza_detalle/${id}`).then((response) => {
        dispatch({ type: DETALLE_POLIZA, detalle: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};


const leerDetallePolizaMod = id => (dispatch) => {
    api.get(`/poliza_detalle/${id}`).then((response)=>{
        response.polizaDetalle_polizaEncabezado = response
        dispatch({ type: GUARDAR_POLIZA, lecturaP: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};

export const registroPolizaDetalle = () => (dispatch, getStore) => {
    const data = getStore().form.NotaCreditoClienteCxCFormTabs.values;
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const poliza = localStorage.getItem('idNC_cliente_poliza_cxcc');
    const formData = {
        empresa: id_emp[5],
        periodo: id_emp[7],
        cc: data.cc.value,
        cuenta: data.cuenta.value,
        debe: data.debe,
        haber: data.haber,
        poliza: poliza
    }
    if (formData.debe === undefined && formData.haber === undefined) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'Debe de ingresar valor en debe o haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) === 0.00 && parseFloat(formData.haber) === 0.00) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'No puede llevar valores a 0.00 en debe o haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) >= 0.01 && parseFloat(formData.haber) >= 0.01) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'No puede llevar valores en debe y haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) >= 0.01) {
        formData.haber = 0.00;
        dispatch(setLoader(true));
        api.post('/polizas/encabezado_polizas/', formData).then((response) => {
            const poliza = localStorage.getItem('idNC_cliente_poliza_cxc')
            if (poliza === null) {
                response.debe = 0.00;
                response.haber = 0.00;
                dispatch(initializeForm('NotaCreditoClienteCxCFormTabs', response));
                dispatch(leerDetallePoliza(response.detalle.encabezado));
            } else {
                const editar = window.location.href.includes('editar');
                if (editar) {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('NotaCreditoClienteCxCFormTabs', response));
                    dispatch(leerDetallePolizaMod(poliza));
                } else {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('NotaCreditoClienteCxCFormTabs', response));
                    dispatch(leerDetallePoliza(poliza));
                }
            }
        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al registrar la poliza',
                'Error',
                0
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    } else if (parseFloat(formData.haber) >= 0.01) {
        formData.debe = 0.00;
        dispatch(setLoader(true));
        api.post('/polizas/encabezado_polizas/', formData).then((response) => {
            localStorage.removeItem('tipo_poliza');
            const poliza = localStorage.getItem('idNC_cliente_poliza_cxc')
            if (poliza === null) {
                response.debe = 0.00;
                response.haber = 0.00;
                dispatch(initializeForm('NotaCreditoClienteCxCFormTabs', response));
                dispatch(leerDetallePoliza(response.encabezado));
            } else {
                const editar = window.location.href.includes('editar');
                if (editar) {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('NotaCreditoClienteCxCFormTabs', response));
                    dispatch(leerDetallePolizaMod(poliza));
                } else {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('NotaCreditoClienteCxCFormTabs', response));
                    dispatch(leerDetallePoliza(poliza));
                }
            }

        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al registrar la poliza',
                'Error',
                0
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
}

export const registroPolizaDetalleSCC = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.NotaCreditoClienteCxCFormTabs.values;
    const poliza = localStorage.getItem('idNC_cliente_poliza_cxc');
    const formData = {
        empresa: id_emp[5],
        periodo: id_emp[7],
        cc: 0,
        cuenta: data.cuenta.value,
        debe: data.debe,
        haber: data.haber,
        poliza: poliza
    }
    if (formData.debe === undefined && formData.haber === undefined) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'Debe de ingresar valor en debe o haber',
            type: 'error',
        })
    }else if (parseFloat(formData.debe) === 0.00 && parseFloat(formData.haber) === 0.00) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'No puede llevar valores a 0.00 en debe o haber',
            type: 'error',
        })
    }else if (parseFloat(formData.debe) >= 0.01 && parseFloat(formData.haber) >= 0.01) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'No puede llevar valores en debe y haber',
            type: 'error',
        })
    }else if (parseFloat(formData.debe) >= 0.01) {
        formData.haber = 0.00;
        dispatch(setLoader(true));
        api.post('/polizas/encabezado_polizas/', formData).then((response) => {
            const poliza = localStorage.getItem('idNC_cliente_poliza_cxc')
            if (poliza === null) {
                response.debe = 0.00;
                response.haber = 0.00;
                dispatch(initializeForm('NotaCreditoClienteCxCFormTabs', response));
                dispatch(leerDetallePoliza(response.detalle.encabezado));
            } else {
                const editar = window.location.href.includes('editar');
                if (editar) {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('NotaCreditoClienteCxCFormTabs', response));
                    dispatch(leerDetallePolizaMod(poliza));
                } else {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('NotaCreditoClienteCxCFormTabs', response));
                    dispatch(leerDetallePoliza(poliza));
                }
            }

        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al registrar la poliza',
                'Error',
                0
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }else if (parseFloat(formData.haber) >= 0.01) {
        formData.debe = 0.00;
        api.post('/polizas/encabezado_polizas/', formData).then((response) => {
            localStorage.removeItem('tipo_poliza');
            const poliza = localStorage.getItem('idNC_cliente_poliza_cxc')
            if (poliza === null) {
                response.debe = 0.00;
                response.haber = 0.00;
                dispatch(initializeForm('NotaCreditoClienteCxCFormTabs', response));
                dispatch(leerDetallePoliza(response.detalle.encabezado));
            } else {
                const editar = window.location.href.includes('editar');
                if (editar) {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('NotaCreditoClienteCxCFormTabs', response));
                    dispatch(leerDetallePolizaMod(poliza));
                } else {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('NotaCreditoClienteCxCFormTabs', response));
                    dispatch(leerDetallePoliza(poliza));
                }
            }

        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al registrar la poliza',
                'Error',
                0
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
}



export const confirmarPoliza = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let dif = localStorage.getItem('dif');
    if (parseFloat(dif) === 0.00) {
        localStorage.removeItem('idNC_cliente_cxc');
        localStorage.removeItem('dif');
        NotificationManager.success(
            'Póliza guardada correctamente',
            'Exito',
            3000
        );
        const editar = window.location.href.includes('editar');
        if (editar){
            ruta = `/facturacion/${id_emp[5]}/nota_credito/${id_emp[7]}`
            dispatch(push(ruta));
        }else{
            window.location.reload(true);
        }
        
    } else {
        Swal.fire({
            title: 'Modificar poliza',
            text: `Poliza con diferencia: ${new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(dif)}`,
            type: 'error',
        })
    }
}

const listarEstablecimientos = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    return api.get('/sucursal', params).then((data)=>{
        const estable = [];
        data.results.forEach(item=>{
            estable.push({
                value: item.id,
                label: item.codigo + ' - ' + item.nombre_comercial
            })
        })
        if (estable.length === 1 ){
            estable.forEach(item => {
                dispatch({ type: LISTADO_ESTABLECIMIENTOS, listaEs: item.value });
            })
            dispatch({ type: LISTADO_ESTABLECIMIENTOS, listaEs: data.results[0] });
        }else{
            dispatch({ type: LISTADO_ESTABLECIMIENTOS, listaEs: estable });
        }
        
    }).catch(error=>{
        
        NotificationManager.error(
            'Ocurrió un error al listar establecimientos',
            'Error',
            0
        );
    })
}

export const leerEstablecimiento = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/sucursal/${id}`).then((response) => {
        dispatch({type: ESTABLECIMIENTO, estable: response });
        
    }).catch((error)=>{
        
        NotificationManager.error(
            'Ocurrió un error al consultar el establecimiento',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}



const eliminarDocumento = (id) => (dispatch) => {
    detalle.forEach((element, index) => {
        if (element.id === id) {
            detalle.splice(index, 1);
        }
    })
    dispatch(actualizar());
};

export const tipoCambio = (periodoD) => (dispatch, getStore) => {
    const data = getStore().form.FormNotaCreditoCxCCliente.values;
    if (data === undefined) {
        Swal.fire({
            title: 'Nota de crédito',
            text: 'Debe de ingresar datos de la nota de crédito',
            type: 'error',
        })
    } else {   
        let fechaI = moment(periodoD.fecha_inicio);
        let fechaF = moment(periodoD.fecha_fin);
        let fecha = moment(data.fecha_registro)
        if (fecha>=fechaI && fecha<=fechaF){
            if (data.fecha_registro === undefined) {
                Swal.fire({
                    title: 'Nota de crédito',
                    text: 'Debe de ingresar fecha de la nota de crédito',
                    type: 'error',
                })
            } else {
                let fecha = data.fecha_registro;
                let ruta = window.location.href;
                let id_emp = ruta.split('/');
                let idEmpre = id_emp[5];
                const params = { fecha, idEmpre }
                dispatch(setLoaderC(true));
                return api.get('/tipo_cambio/tipo_cambio_fecha/', params).then((response) => {
                    if (parseFloat(response) === 0) {
                        Swal.fire({
                            title: 'No hay tipo de cambio del dia' + ' ' + moment(fecha).format("DD/MM/YYYY"),
                            html:
                                '<div class="col-sm-12 mt-4">' +
                                '<h4 style="color: white;">¿Desea ingresarlo?</h4>' +
                                '</div>',
                            type: 'info',
                            background: "black",
                            showCancelButton: true,
                            confirmButtonText: 'Si',
                            cancelButtonText: 'No',
                            confirmButtonColor: "#4040ff",
                            cancelButtonColor: "#646464",
                            reverseButtons: true,
                            customClass: {
                                title: 'sweet_titleImportant',
                            },
                        }).then((result) => {
                            if (result.value) {
                                Swal.fire({
                                    title: 'Ingrese tipo de cambio',
                                    html:
                                        '<div class="col-sm-12 mt-3">' +
                                        '<h4 style="color: white">' + moment(fecha).format("DD/MM/YYYY") + '</h4>' +
                                        '<input type="number" min="1" id="cambio" class="swal2-input form-control">' +
                                        '</div>',
                                    type: 'info',
                                    background: "black",
                                    showCancelButton: true,
                                    confirmButtonText: 'Si',
                                    cancelButtonText: 'No',
                                    confirmButtonColor: "#4040ff",
                                    cancelButtonColor: "#646464",
                                    reverseButtons: true,
                                    customClass: {
                                        title: 'sweet_titleImportant',
                                    },
                                }).then((result) => {
                                    if (result.value) {
                                        let tcambio = document.getElementById('cambio').value
                                        const formData = {
                                            fechaT: fecha,
                                            tipoCambio: tcambio,
                                            empresa: id_emp[5],
                                            tipo: 'manual'
                                        }
                                        api.post('/tipo_cambio/', formData).then(response => {
                                            NotificationManager.success(
                                                'Tipo de cambio guardado',
                                                'Exito',
                                                4000
                                            );

                                        }).catch((error) => {
                                            NotificationManager.error(
                                                error.msj,
                                                'Error',
                                                0
                                            );
                                        })
                                    }
                                });
                            }
                        });
                    } else {
                        return response;
                    }
                }).catch((error) => {
                    NotificationManager.error(
                        'Ocurrió un error al consultar tipo de cambio',
                        'Error',
                        2000
                    );
                }).finally(() => {
                    dispatch(setLoaderC(false));
                });
            }    
        }else{
            Swal.fire({
                title: 'Nota de crédito',
                text: 'Fecha fuera del periodo',
                type: 'error',
            })        
        }
    }
}

const vaciar = () => (dispatch) => {
    detalle = [];
    dispatch(initializeForm('FormNotaCreditoCxCCliente'));
    dispatch({ type: DETALLE, detalleDocN: detalle });
};

const limpiarDocumentos = () => (dispatch) => {
    dispatch({ type: LISTADO_DOCUMENTOS, doc: [] });
    dispatch({ type: DOCUMENTOS, list_doc: [] })
};

export const actions = {
    leer,
    listar,
    leerPeriodoD,
    getModulo,
    parametrosEmpresa,
    listarTiposDocu,
    listarClientes,
    listarCuentas,
    listarCC,
    listarNiveles,
    listarMonedas,
    leerDocumentos,
    leerDetalleDocumentos,
    leerDetalle,
    agregarLinea,
    actualizar,
    registroNotaCredito,
    eliminarLinea,
    eliminarDocumento,
    registroCuenta,
    reporteCuentas,
    registroPolizaDetalle,
    confirmarPoliza,
    registroPolizaDetalleSCC,
    listarEstablecimientos,
    leerEstablecimiento,
    tipoCambio,
    vaciar,
    limpiarDocumentos
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_NC]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [NOTA_CREDITO]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
    [DETALLE_NC]: (state, { detalleNC }) => {
        return {
            ...state,
            detalleNC,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [PERIODO_D]: (state, { periodoD }) => {
        return {
            ...state,
            periodoD,
        };
    },
    [PARAMETRO]: (state, { datos }) => {
        return {
            ...state,
            datos,
        };
    },
    [LOADERC]: (state, { loaderC }) => {
        return {
            ...state,
            loaderC,
        };
    },
    [LISTADO_DOCUMENTOS]: (state, { doc }) => {
        return {
            ...state,
            doc,
        };
    },
    [LISTADO_MONEDAS]: (state, { monedas }) => {
        return {
            ...state,
            monedas,
        };
    },
    [DETALLE_DOCUMENTOS]: (state, { detalleDoc }) => {
        return {
            ...state,
            detalleDoc,
        };
    },
    [DETALLE_POLIZA]: (state, { detalle }) => {
        return {
            ...state,
            detalle,
        };
    },
    [LISTADO_TIPOS_DOCU]: (state, { tipos }) => {
        return {
            ...state,
            tipos,
        };
    },
    [NIVEL]: (state, { nivel }) => {
        return {
            ...state,
            nivel,
        };
    },
    [DOCUMENTOS]: (state, { list_doc }) => {
        return {
            ...state,
            list_doc,
        };
    },
    [DETALLE]: (state, { detalleDocN }) => {
        return {
            ...state,
            detalleDocN,
        };
    },
    [GUARDAR_POLIZA]: (state, { lecturaP }) => {
        return {
            ...state,
            lecturaP,
        };
    },
    [ESTABLECIMIENTO]: (state, { estable }) => {
        return {
            ...state,
            estable,
        };
    },
    [LISTADO_ESTABLECIMIENTOS]: (state, { listaEs }) => {
        return {
            ...state,
            listaEs,
        };
    },
};

export const initialState = {
    loader: false,
    lectura: [],
    modulo: [],
    periodoD: [],
    tipos: [],
    cli: [],
    data: [],
    datos: [],
    loaderC: false,
    monedas: [],
    detalle: [],
    doc: [],
    nivel: [],
    estable: [],
    listaEs: [],
    detalleDoc: [],
    detalleNC: [],
    list_doc: [],
    detalleDocN: [],
    lecturaP: {
        polizaDetalle_polizaEncabezado: []
    },
    data: [],
    page: 1,
};

export default handleActions(reducers, initialState)