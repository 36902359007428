import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import { validate, validators } from 'validate-redux-form';
import PropTypes from 'prop-types';
import NavbarModulo from '../../components/NavbarModulo';
import Modal from '../../../components/components/ModalA';
import FacturacionPosDetalle from './FacturacionPosDetalle';

// Elementos del formulario
import {
  renderField,
  renderNumber,
  renderFieldCheck,
  renderFieldCheckB,
  renderFieldRadio,
  AsyncSelectField,
  AsyncSelectFieldIn,
  renderCurrencyPoliza,
  renderCurrencyFloat,
  renderCurrencyFloatA,
  SelectField,

} from "../../Utils/renderField/renderField";
import "../../../../../style/PuntoVenta/index.css";
import { FaArrowAltCircleLeft, FaPlusCircle, FaSave, FaTimes } from 'react-icons/fa';
import { productosList } from './dataproductos';
import { useEffect } from 'react';

function FacturaPosForm(props) {

  console.log(productosList);

  const {
    // crear,
    // lectura,
    // handleSubmit,
    modulo,
    listarProductos,
    listarClientes,
    data,
    // detalleProducto,
    // eliminarProducto,
    registroProductoInventario,
    periodoD,

  } = props;

  const [productos, setProductos] = useState([]);
  const [detalle, setDetalle] = useState([]);
  const [image, setImage] = useState('');
  const [isActiveList, setIsActiveList] = useState(false)
  const [focus, setFocus] = useState(true)
  const [filter, setFilter] = useState('');
  const [pagos, setPagos] = useState(
    [
      { id: "1", tipo: "Efectivo", autorizacion: "0", importe: "125.00" },
      { id: "2", tipo: "Tarjeta Credito", autorizacion: "1234566", importe: "125.00" },
      { id: "3", tipo: "Tarjeta Débito", autorizacion: "1234566", importe: "125.00" },
      { id: "4", tipo: "Transferencia", autorizacion: "1234566", importe: "125.00" },
    ]
  );

  useEffect(() => {
    setProductos(productosList);
  }, [])

  const filteredProducts = productos.filter((product) =>
    product.nombre.toLowerCase().includes(filter.toLowerCase())
  );

  const deleteItemProduct = (id) => {
    const newDetails = detalle.filter((item) => item.id !== id)
    setDetalle(newDetails);
  }

  const deleteItemPago = (id) => {
    const newPagos = pagos.filter((item) => item.id !== id)
    setPagos(newPagos);
  }


  // Agregar producto al carrito
  const productSelected = (id) => {
    if (id) {

      const [item] = detalle.filter((item) => item.id === id)
      if (!item) {
        const [product] = productos.filter((item) => item.id === id)
        const newItem = {
          id: product.id,
          nombre: product.nombre,
          cantidad: 1,
          precio: product.precio,
          total: product.precio * 1
        }
        setImage("")
        setDetalle([newItem, ...detalle])
      } else {
        item.cantidad = item.cantidad + 1
        item.total = item.cantidad * item.precio
      }
    }
    // return
  }

  const imageSelected = (id) => {
    const [product] = productos.filter((item) => item.id === id)
    if (!id) {
      return
    }
    setImage(product)
  }


  const editar = window.location.href.includes('editar');
  let crear = true;
  let imagenActive = true;
  let titulo = editar ? 'Modificar Venta' : 'Crear Venta';
  let disabled = false;
  if (crear === false && editar === false) {
    disabled = false;
    titulo = 'Ver Venta';
  }
  let autoFocus = true;
  let ruta = window.location.href;
  let id_emp = ruta.split('/');

  const formaPago = [
    {
      value: 'EF',
      label: 'EFECTIVO'
    },
    {
      value: 'TD',
      label: 'TARJETA DEBITO'
    },
    {
      value: 'TC',
      label: 'TARJETA CREDITO'
    },
    {
      value: 'TE',
      label: 'TRANSFERENCIA'
    },
  ];

  const heithPos = {
    height: "60vh",
  }
  const estilo = {
    background: "#333",
  };
  // const estilo = {
  //   overflow: "auto",
  //   height: "200px",
  // };

  return (
    <div>
      <NavbarModulo
        modulo={modulo}
        titulo={titulo}
      />
      <div className='row h-100 m-0 mt-2'>
        {/* Cuando los buttons van dentro de la seccion de productos se colca "clase: pb-4" de lo contrario mo se coloca nada */}
        <div className='row m-0 text-white rounded pb-4' style={estilo} >
          {/* BUSCAR PRODUCTO Y DETALLE PRODUCTOS */}
          <div className="col-12 col-lg-8 mt-2">
            <div className="card p-3 mt-2 bg-dark text-white h-100 border rounded">
              <h4 className="text-white fs-6 d-flex justify-content-around align-items-center mt-2">PUNTO DE VENTA</h4>
              <div className="row">
                {/* Input para ingresar producto */}
                <div className="col-12 col-md-12">
                  <label className="fw-bold">Digite el producto a vender
                  </label>
                  <input
                    autoFocus={focus}
                    onFocus={(e) => focus}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        if (e.target.value === "") {
                          return (
                            setIsActiveList(false),
                            setFilter(""),
                            setFocus(true),
                            imageSelected(null)
                          )
                        }
                        if (filteredProducts.length < 1) {
                          return (
                            setIsActiveList(false),
                            setFilter(""),
                            setFocus(true),
                            imageSelected(null)
                          )
                        }
                        setFilter(e.target.value);
                        if (filteredProducts) {
                          productSelected(filteredProducts[0].id);
                          setIsActiveList(false)
                        }
                        setFocus(true)
                        e.target.value = "";
                        setFilter("");
                        imageSelected(null)
                      }
                    }}
                    value={filter}
                    onChange={(e) => {
                      if (e.target.value.length < 1) {
                        return (
                          setFilter(""),
                          setIsActiveList(false)
                        )
                      }
                      setFilter(e.target.value)
                      setIsActiveList(true)
                    }}
                    type="text"
                    placeholder="Ingrese el código de barras o el nombre del producto"
                    className="form-control form-control-sm mt-2"
                    id="iptCodigoVenta"
                    name="iptCodigoVenta"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                  />
                </div>

                {/* Select producto */}
                {isActiveList &&
                  <div className="col-12 col-md-12">
                    <div className="row">
                      <div className="col-12 col-lg-8">
                        {filteredProducts &&
                          <select
                            height={200}
                            multiple={true}
                            onChange={(e) => {
                              imageSelected(e.target.value);
                              setIsActiveList(true)
                            }}

                            onKeyDown={(e) => {
                              if (e.target.value === "") {
                                return
                              }
                              if (e.key === "Enter") {
                                productSelected(e.target.value);
                                e.target.value = "";
                                setFocus(true);
                                setFilter("");
                                setIsActiveList(false);
                              }
                            }}
                            className="form-select bg-dark text-white"
                            aria-label="Default select example"
                          >
                            {filteredProducts.map((item) => (
                              <option key={item.id} value={item.id}> {`${item.id} - ${item.nombre}`}</option>
                            ))}
                          </select>
                        }
                      </div>
                      <div className="col-12 col-lg-4 text-center rounded p-2">
                        <img width={150} height={150} src={image.url} className="border-0 rounded text-center" alt={image.nombre} />
                      </div>
                    </div>
                  </div>
                }

                {/* Listado de productos al carrito*/}
                {!isActiveList &&
                  <div className="col-12 mt-2">
                    <div className="row">
                      <div className="col-12 table-responsive">
                        <h4 className="text-md-center title">
                        <span>Detalle productos</span>
                      </h4>
                        <table id="tbl_ListadoProductos" className="table table-striped table-hover table-dark text-nowrap table-bordered">
                          <thead className="bg-main">
                            <tr>
                              <th>Codigo</th>
                              <th>Descripcion</th>
                              <th className="text-center">Cantidad</th>
                              <th className="text-center">Precio</th>
                              <th className="text-center">Total</th>
                              <th>Acciones</th>
                            </tr>
                          </thead>
                          <tbody className="small text-left">
                            {detalle.map((item) => (
                              <tr key={item.id}>
                                <td>{item.id}</td>
                                <td>{item.nombre}</td>
                                <td className="text-center">{item.cantidad}</td>
                                <td className="text-center">{item.precio}</td>
                                <td className="text-center">{item.total}</td>
                                <td className="text-center">
                                  <button className="btn-success text-lg bg-dark text-white px-2" onClick={(e) => deleteItemProduct(item.id)} >X</button>
                                </td>
                              </tr>
                            ))}
                            {detalle.length < 1 &&
                              <tr>
                                <td colSpan={6} className="text-center">No hay productos</td>
                              </tr>
                            }
                          </tbody>
                        </table>
                      </div>
                      <br />
                      {/* Resumen totales */}
                      <div className="col-12 col-lg-12 col-md-12 d-flex justify-content-end align-items-center mt-2">
                        <div className="float-right">
                          <div className="row">
                            <div className="">
                              <div className="row">
                                <div className="col-12 col-md-12 d-flex justify-content-between">
                                  <span>SUBTOTAL</span>
                                  <span className="float-right" id="resumen_subtotal">Q/ 0.00</span>
                                </div>
                                <div className="col-12 col-md-12 d-flex justify-content-between">
                                  <span>IVA</span>
                                  <span className="float-right" id="resumen_total_igv">Q/ 0.00</span>
                                </div>
                                <hr className="col-11 col-md-11 mr-1" />
                                <div className="col-12 col-md-12 d-flex justify-content-between fw-bold">
                                  <span>TOTAL</span>
                                  <span className="float-right " id="resumen_total_venta">Q/ 0.00</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }

                {/* Botones */}
                <div className='col-12 col-lg-12 mt-2'>
                  <br></br>
                  <br></br>
                  <br></br>
                  <div className="col-12">
                    <div className="d-flex justify-content-center gap-3">
                      <button
                        className='btn mr-2 btn-primary'
                      >
                        Guardar
                      </button>
                      <button
                        className="btn mr-2 btn-secondary">
                        Cancelar
                      </button>
                      <a href={`/#/punto_venta/${id_emp[5]}/`}
                        className='btn mr-2 btn-danger text-lg'
                      >
                        Salir
                      </a>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

          {/* Datos del cliente y formas de pago */}
          <div className="col-12 col-lg-4 mt-2">
            <div className="card p-3 mt-2 bg-dark text-white h-100 border rounded">
              <div className="row">
                {/* Cuadre de caja */}
                <div className="col-12 text-center">
                  <div className="col-12 d-flex justify-content-center text-center">
                    <h4 className='text-secondary'>
                      Saldo en caja
                      <span className='text-white'> Q 2,500.00 </span>
                      <br></br>
                      <button className='btn btn-secondary'>Realizar cuadre</button>
                    </h4>
                  </div>
                </div>

                {/* Datos del cliente */}
                <div className="col-12">
                  <div className="col-12 p-3">
                    <h4 className='text-secondary'>
                      <span>Datos del cliente  </span>
                      <button className="btn btn-secondary"> Nuevo</button>
                    </h4>
                    <div className="row border rounded ">
                      <label htmlFor="nitcliente" className="col-12 text-center col-lg-4 col-form-label text-lg-end">Nit del cliente:</label>
                      <div className="col-12 col-lg-8 align-content-center">
                        <input type="text" className="text-center text-lg-left form-control bg-dark border-0 text-white fw-bold" name="nitcliente" value="1234594-9" />
                      </div>
                    </div>
                    <div className="row border rounded text-center bg-secondary text-white">
                      <label htmlFor="nombrecliente" className="col-12 col-lg-4 col-form-label text-center text-lg-end">Nombre:</label>
                      <div className="col-12 text-center col-lg-8">
                        <input type="text" readOnly className="text-center text-lg-left form-control-plaintext text-white fw-bold" name="nombrecliente" value="Juan Carlos Pineda" />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Formas de pago */}
                {/* <!-- LISTADO Y RESUMEN FORMA PAGO --> */}
                <div className="col-12 mt-3">
                  <div className="col-12 table-responsive">
                    <h4 className='text-secondary'>
                      <span>Forma de pago </span>
                      <button className="btn btn-secondary"> Agregar</button>
                    </h4>

                    <table id="tbl_ListadoProductos" className="table table-striped table-hover table-dark text-nowrap table-bordered">
                      <thead className="bg-main text-center">
                        <tr>
                          <th>Tipo</th>
                          <th>Autorozación</th>
                          <th>Total</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody className="small text-center">
                        {pagos.map((item) => (
                          <tr key={item.id}>
                            <td>{item.tipo}</td>
                            <td>{item.autorizacion}</td>
                            <td className="text-center">{item.importe}</td>
                            <td className="text-center">
                              <button className="btn-secondary text-lg bg-dark text-white px-1" onClick={(e) => deleteItemPago(item.id)} >X</button>
                            </td>
                          </tr>
                        ))}
                        {pagos.length < 1 &&
                          <tr>
                            <td colSpan={6} className="text-center">No hay pagos</td>
                          </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                  {/* Totales */}
                  <div className="col-12 col-lg-12 col-md-12 d-flex justify-content-center align-items-center mt-2">
                    <div className="float-center">
                      <div className="row">
                        <hr className="m-1" />
                        <div className="col-12 col-md-12 d-flex justify-content-between fw-bold">
                          <span>TOTAL</span>
                          <span className="float-right " id="resumen_total_venta">Q/ 0.00</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Botones */}
          {/* <div className='col-12 col-lg-12 mt-2   '>
            <div className="card p-3 my-3 bg-dark text-white border rounded">
              <div className="d-flex justify-content-center gap-3">
                <button
                  className='btn mr-2 btn-primary'
                >
                  Guardar
                </button>
                <button
                  className="btn mr-2 btn-secondary">
                  Cancelar
                </button>
                <a href={`/#/`}
                  className='btn mr-2 btn-danger text-lg'
                >
                  Salir
                </a>
              </div>
            </div>
          </div> */}

        </div>
      </div>
    </div>
  );
}

FacturaPosForm.propTypes = {
  handleSubmit: PropTypes.func,
  crear: PropTypes.bool,
  lectura: PropTypes.object,
  modulo: PropTypes.object,
  listarBodegas: PropTypes.func,
  leerProductos: PropTypes.func,
  listarProductos: PropTypes.object,
  data: PropTypes.object,
  detalleProducto: PropTypes.object,
  eliminarLineaProducto: PropTypes.func,
  periodoD: PropTypes.object,
  registroProductoInventario: PropTypes.func,

};

export default reduxForm({
  form: 'FormBodega',
  validate: (data) => {
    return validate(data, {
      numero: validators.exists()('Este campo es requerido'),
      bodega: validators.exists()('Este campo es requerido'),
    });
  },
})(FacturaPosForm);
