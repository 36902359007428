import React, { useEffect, useRef, useState } from "react";
import FormDialogModal from "../../components/FormDialogModal";
import DataTableWSearch from "../../components/DataTableWSearch";
import { FicComboBox, FicInput } from "../../Utils/FormInputComponents";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import GenBtn from "../../components/GenBtn";
import * as Fa from "react-icons/fa";

const schema = yup
    .object({
        marca: yup.mixed().required("La marca es requerida"),
        linea: yup.string().required("La linea es requerida"),
    })
    .required();

function LineaList(props) {
    const { listarMarcas, listarLineas, crearLinea, editarLinea, data } = props;

    const [marcaFieldData, setMarcaFieldData] = useState();
    const [nombreFieldData, setNombreFieldData] = useState();
    const [lineaId, setLineaId] = useState();
    const dialogRef = useRef(null);
    const editDialogRef = useRef(null);

    const {
        handleSubmit,
        reset,
        control,
        formState: { errors },
        setFocus,
    } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
        defaultValues: {
            marca: null,
            linea: "",
        },
    });

    const {
        handleSubmit: editSubmit,
        reset: editReset,
        control: editControl,
        formState: { errors: editErrors },
    } = useForm({
        mode: "onChange",
        defaultValues: {
            marca: "",
        },
    });

    async function onSubmit(formData) {
        await crearLinea(formData);
        reset();
        listarLineas();
        setFocus("marca");
    }

    async function onEditSubmit(formData) {
        await editarLinea(formData, lineaId);
        listarLineas();
        editDialogRef.current.close();
        editReset();
    }

    useEffect(() => {
        listarLineas();
    }, []);

    const columns = [
        {
            name: "Marca",
            selector: (row) => row.marca,
            sortable: true,
        },
        {
            name: "Nombre",
            selector: (row) => row.nombre,
            sortable: true,
        },
        {
            cell: (row) => (
                <GenBtn
                    key={row.nombre}
                    textColor="hsl(0, 0%, 80%)"
                    background="hsla(0, 100%, 100%, 0.2)"
                    onPress={(e) => {
                        editDialogRef.current.showModal();
                        setNombreFieldData(row.nombre);
                        setMarcaFieldData(row.marca);
                        setLineaId(row.id);
                    }}
                >
                    <Fa.FaPen />
                </GenBtn>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    return (
        <React.Fragment>
            <br />
            <h3
                className="p-2  title rounded shadow"
                style={{
                    background:
                        "linear-gradient(to top right, #790021, #2d2d2d)",
                }}
            >
                Linea
            </h3>
            <br />
            <div className="d-flex flex-row justify-content-start mb-2">
                <GenBtn onPress={() => dialogRef.current.showModal()}>
                    Agregar
                </GenBtn>
            </div>

            <DataTableWSearch
                columns={columns}
                data={data}
                fieldToFilter="nombre"
                filterPlaceHolder="Buscar por nombre"
            />

            <FormDialogModal
                dialogRef={dialogRef}
                onSubmit={handleSubmit(onSubmit)}
                title={"Modificar linea"}
            >
                <div
                    style={{
                        display: "flex",
                        overflow: "visible",
                        flexDirection: "column",
                        gap: "15px",
                        width: "25rem",
                        height: "18rem",
                        padding: "10px",
                    }}
                >
                    <div className="pr-3 pl-3 pb-2 pt-3 g-3">
                        <FicComboBox
                            componentStyle={{ marginBottom: "3rem" }}
                            name="marca"
                            placeholder="Marca..."
                            loadOptions={listarMarcas}
                            control={control}
                        />

                        <FicInput
                            name="linea"
                            placeholder="Linea"
                            control={control}
                        />
                    </div>
                    <div className="d-flex justify-content-end mt-auto">
                        <GenBtn
                            type="submit"
                            style={{
                                width: "90px",
                                height: "40px",
                                marginBottom: "8px",
                            }}
                        >
                            Guardar
                        </GenBtn>
                    </div>
                </div>
            </FormDialogModal>

            <FormDialogModal
                style={{ overflow: "visible", minHeight: "18rem" }}
                dialogRef={editDialogRef}
                onSubmit={editSubmit(onEditSubmit)}
                title={"Agregar marca"}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "15px",
                        width: "25rem",
                        height: "10rem",
                        padding: "15px",
                    }}
                >
                    <div className="pr-3 pl-3 pb-2 pt-3 g-3">
                        <FicComboBox
                            componentStyle={{ marginBottom: "3rem" }}
                            name="marca"
                            placeholder={`${marcaFieldData}`}
                            loadOptions={listarMarcas}
                            control={editControl}
                            error={
                                editErrors &&
                                editErrors.marca &&
                                editErrors.marca.message
                            }
                        />

                        <FicInput
                            name="linea"
                            placeholder={`${nombreFieldData}`}
                            control={editControl}
                        />
                    </div>
                    <div className="d-flex justify-content-end mt-auto">
                        <GenBtn
                            type="submit"
                            style={{
                                width: "90px",
                                height: "40px",
                                marginBottom: "8px",
                            }}
                        >
                            Guardar
                        </GenBtn>
                    </div>
                </div>
            </FormDialogModal>
        </React.Fragment>
    );
}

export default LineaList;
