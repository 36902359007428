import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
    FicInput,
    FicComboBox,
    FicNumber,
    FicDatePicker,
    FicTextArea,
} from "../../Utils/FormInputComponents";
import Banner from "../common/Banner";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { validar } from "../../components/ValidarNit";
import GenBtn from "../../components/GenBtn";
import InputsContainer from "../common/InputsContainer";
import {
    getEntry,
    editar,
    getPacientes,
    getPatologias,
    getClientes,
    getUnidadesIgss,
} from "./actions";
import { withRouter } from "react-router";
import LoadMask from "../../Utils/LoadMask/LoadMask";
import {
    today,
    getLocalTimeZone,
    parseDate,
    startOfMonth,
    endOfMonth,
    CalendarDate,
} from "@internationalized/date";
import { getPeriodoDates } from "../utils";
import OptionsBar from "../common/OptionsBar";

const idName = "ingresoId";

const TIPO_OPTIONS = [
    { value: "privada", label: "Privada" },
    { value: "afiliada", label: "Afiliada" },
    { value: "citologia", label: "Citologia" },
    { value: "inmunohistoquimica", label: "Inmunohistoquimica" },
];

const CLINICA_OPTIONS = [
    { value: "FISIOLAB", label: "FISIOLAB" },
    { value: "LISANDRO SALAZAR", label: "LISANDRO SALAZAR" },
];

const TIPO_VALUES = {
    Privada: { value: "privada", label: "Privada" },
    afiliada: { value: "afiliada", label: "Afiliada" },
    citologia: { value: "citologia", label: "Citologia" },
};

const schema = yup.object({
    tipo: yup.mixed().required("El tipo es requerido"),
    paciente: yup.mixed().required("El paciente es requerido"),
    edad: yup.number().required("La edad es requerida"),
    clinica: yup.mixed().required("La clinica es requerida"),
    medico: yup.string().required("El medico es requerido"),
    fecha_recibido: yup.mixed().required("La fecha recibido es requerida"),
    costo: yup.number().required("El costo es requerido"),
    especimen: yup.string().required("El especimen es requerido"),
});

function Editar({ history, ...props }) {
    const [isLoading, setIsLoading] = useState(false);
    const params = props.match.params;
    const entryId = params[idName];
    const { startOfMonthPeriodo, endOfMonthPeriodo } = getPeriodoDates();

    const { control, reset, handleSubmit, watch, setValue, formState } =
        useForm({
            mode: "onChange",
            resolver: yupResolver(schema),
            defaultValues: async () => {
                setIsLoading(true);
                let formData = await getEntry(entryId);
                formData.tipo = TIPO_VALUES[formData.tipo];
                formData.clinica = {
                    value: formData.clinica.id,
                    label: formData.clinica.nombre,
                };
                formData.periodo = formData.periodo.id;
                setIsLoading(false);

                return formData;
            },
        });

    const tipo = watch("tipo") ? watch("tipo").value : null;
    useEffect(() => {
        if (tipo === "afiliada") {
            setValue("clinica", null);
        }
    }, [tipo]);

    const onSubmit = (data) => {
        if (typeof data.clinica === "object") {
            data.clinica = data.clinica.value;
        }
        data.tipo = data.tipo.value;
        if (data.tipo === "inmunohistoquimica") {
            data.patologia = data.patologia.value;
            data.paciente = null;
        }
        if (data.tipo !== "inmunohistoquimica") {
            data.paciente = data.paciente.value;
            data.patologia = null;
        }
        if (data.igss) {
            data.igss = data.igss.value;
        }
        if (data.numero_patologia) {
            delete data.numero_patologia;
        }

        const shouldGoBack = editar(data, entryId);
        if (shouldGoBack) {
            history.goBack();
        }
    };

    return (
        <React.Fragment>
            <OptionsBar />
            <Banner>Editar</Banner>
            <GenBtn
                onPress={() => history.goBack()}
                style={{ margin: "5px 0" }}
            >
                Regresar
            </GenBtn>
            <LoadMask loading={isLoading} color="#bd0034" blur={true}>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    style={{ display: "flex", flexDirection: "column" }}
                >
                    <FicInput
                        control={control}
                        label="Numero de patologia"
                        name="numero_patologia"
                        style={{ alignSelf: "center" }}
                        isDisabled
                    />
                    <div style={{ backgroundColor: "#2b2b2b" }}>
                    <InputsContainer>
                            <FicComboBox
                                control={control}
                                label="Tipo"
                                name="tipo"
                                nonAsync
                                options={TIPO_OPTIONS}
                            />
                            {tipo === "inmunohistoquimica" && (
                                <FicComboBox
                                    control={control}
                                    label="Patologia"
                                    name="patologia"
                                    loadOptions={getPatologias}
                                />
                            )}
                            <FicInput
                                control={control}
                                label="Paciente"
                                name="paciente"
                            />
                            {tipo === "afiliada" && (
                                <FicInput
                                    control={control}
                                    label="Afiliacion"
                                    name="afiliacion"
                                />
                            )}
                            <FicNumber
                                control={control}
                                label="Edad"
                                name="edad"
                            />
                            <FicComboBox
                                control={control}
                                label="Clinica"
                                name="clinica"
                                loadOptions={(arg) => getClientes(arg, tipo)}
                            />

                            {tipo === "afiliada" && (
                                <FicComboBox
                                    control={control}
                                    label="IGSS"
                                    name="igss"
                                    loadOptions={getUnidadesIgss}
                                />
                            )}
                            <FicInput
                                control={control}
                                label="Medico"
                                name="medico"
                            />
                            <FicDatePicker
                                control={control}
                                label="Fecha recibido"
                                name="fecha_recibido"
                                minValue={startOfMonthPeriodo}
                                maxValue={endOfMonthPeriodo}
                            />
                            {/* <FicNumber
                                control={control}
                                label="Costo del examen"
                                name="costo"
                                formatOptions={{ minimumFractionDigits: 2 }}
                            /> */}
                        </InputsContainer>
                        <FicTextArea
                            style={{ padding: "0 2rem 1rem 2rem" }}
                            control={control}
                            label="Descripcion macroscopica"
                            name="descripcion_macroscopica"
                        />
                        <FicTextArea
                            style={{ padding: "0 2rem 1rem 2rem" }}
                            control={control}
                            label="Especimen"
                            name="especimen"
                        />
                    </div>
                    <GenBtn style={{ margin: "1rem 0" }} type="submit">
                        Guardar
                    </GenBtn>
                </form>
            </LoadMask>
        </React.Fragment>
    );
}

export default withRouter(Editar);
