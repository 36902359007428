import React from 'react';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import * as BsIcons from "react-icons/bs";

export const SidebarData = [
    {
        title: 'Inicio',
        path: '/tools',
        icon: <BsIcons.BsGrid1X2Fill />,
    },
    {
        title: 'Subir archivos',
        path: '#',
        icon: <FaIcons.FaFileExcel />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav: [
            {
                title: 'Cuentas contables',
                path: '/tools',
                path_s: 'cuenta_contable',
                icon: <FaIcons.FaTasks />,
            },
            {
                title: 'Productos',
                path: '/tools',
                path_s: 'producto',
                icon: <FaIcons.FaDiceD6 />,
            },
            {
                title: 'Productos - actualizar clasificacion',
                path: '/tools',
                path_s: 'producto_clasificacion',
                icon: <FaIcons.FaDiceD6 />,
            },
            {
                title: 'Proveedores',
                path: '/tools',
                path_s: 'proveedor',
                icon: <FaIcons.FaBuilding />,
            },
            {
                title: 'RRHH - Ocupaciones',
                path: '/tools',
                path_s: 'ocupaciones',
                icon: <FaIcons.FaTasks />,
            },
            {
                title: 'Ajustes - Paises',
                path: '/tools',
                path_s: 'paises',
                icon: <FaIcons.FaTasks />,
            },
        ]
    },
    {
        title: 'Consulta de documentos',
        path: '/tools/seleccionar_empresa',
        icon: <FaIcons.FaSearch />,
    },
    {
        title: 'Empresas',
        path: '/tools/empresas',
        icon: <FaIcons.FaBuilding />,
    },
    /*{
        title: 'Actualizar Saldos facturas',
        path: '/tools/saldos',
        icon: <FaIcons.FaTasks />,
    },*/
    /*    
    {
        title: 'Lineas poliza',
        path: '/tools/lineas_poliza',
        icon: <FaIcons.FaTasks />,
    },*/
    {
        title: 'Finanzas',
        path: '/tools/finanzas',
        icon: <FaIcons.FaCoins />,
    },
]
