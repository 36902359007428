import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/produccion/gasto';
import Gasto from './Gasto';

const ms2p = (state) => {
  return {
    ...state.gasto_prd,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Gasto);
