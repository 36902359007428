import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/compras/tipoDocumento';
import TipoDocumento from './TipoDocumento';


const ms2p = (state) => {
  return {
    ...state.tipo,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(TipoDocumento);
