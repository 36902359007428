import React, { Component } from 'react';
import Grid from '../../Utils/GridFiltro';

class EmpresasList extends Component{
    componentDidMount = () => {
        const { listarEmpresa } = this.props;
        listarEmpresa();
    }
    render(){
        const { empresa, loader } = this.props;
        
        return(
            <React.Fragment>
                <br />
                <h3 className="p-2 bg-secondary title">Empresas</h3>
                <br />
                {empresa &&
                    <Grid 
                        hover 
                        striped 
                        data={empresa} 
                        loading={loader} 
                        headerStyle={ { background: 'black' } } 
                        //onPageChange={listar} 
                        //onSortChange={onSortChange} 
                    >
                        <TableHeaderColumn
                            isKey
                            dataField="id"
                            width="500"
                            dataSort
                            thStyle={ 
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            ID
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="razon_social"
                            width="500"
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Razon Social
                        </TableHeaderColumn>
                    </Grid>
                }
            </React.Fragment>
        );
    }
}

export default EmpresasList;
