import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import NavbarModulo from '../../components/NavbarModulo';
import TablaDetalle from './DetallePoliza';

import {
    renderDayPickerC,
    AsyncSelectField,
    renderField
} from "../../Utils/renderField/renderField";

function PolizaParqueoForm(props) {
    const {
        crear,
        modulo,
        listarCuentas,
        guardarPoliza,
        generarPoliza,
        detalle_poliza,
        periodoD
    } = props;

    let autoFocus = true;


    let ruta = window.location.href;
    let id_empresa = ruta.split('/');
    
    let titulo = 'Poliza de parqueo';
    
    return (
        <div>
            <NavbarModulo
                periodoD={periodoD}
                modulo={modulo}
                titulo={titulo}
            />
            <br />
            <div className="mb-4 card card-small text-white bg-dark mb-3">
                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                    <div className="d-flex flex-column flex-1 mx-4">
                        <div className='row mb-2'>
                            <div className='col-2'>
                                <Field
                                    autoFocus={autoFocus}
                                    name="fecha_inicio"
                                    component={renderDayPickerC}
                                    msj="Fecha inicio"
                                />
                            </div>
                            <div className='col-5'>
                                <Field
                                    autoFocus={true}
                                    name="cuenta_debe"
                                    placeholder="Cuenta contable debe..."
                                    className="form-control-find"
                                    component={AsyncSelectField}
                                    loadOptions={listarCuentas}
                                    msj="Cuenta debe"
                                />
                            </div>
                            <div className='col-5'>
                                <Field
                                    autoFocus={true}
                                    name="cuenta_haber"
                                    placeholder="Cuenta contable haber..."
                                    className="form-control-find"
                                    component={AsyncSelectField}
                                    loadOptions={listarCuentas}
                                    msj="Cuenta haber"
                                />
                            </div>
                        </div>
                        {crear &&
                            <div className='d-flex flex-row mt-3'>
                                <button
                                    className='btn mr-2 mb-3 btn-primary'
                                    onClick={() => generarPoliza()}
                                >
                                    Generar poliza
                                </button>
                                <a
                                    href = {`/#/facturacion/${id_empresa[5]}/poliza_parqueo/${id_empresa[7]}`}
                                    className='btn btn-secondary mb-3'
                                >
                                    Regresar
                                </a>
                            </div>
                        }
                        <TablaDetalle
                            detallePoliza={detalle_poliza}
                        />
                        <br />
                        {detalle_poliza.length > 0 &&
                            <div className='row mb-3'>
                                <div className='mb-2'>
                                    <Field
                                        name="descripcion"
                                        placeholder="Descripcion"
                                        component={renderField}
                                        className="form-control"
                                        msj="Descripcion"
                                    />
                                </div>
                            </div>
                        }
                        {detalle_poliza.length > 0 &&
                            <div className='d-flex flex-row mt-3'>
                                <button
                                    className='btn mr-2 mb-3 btn-primary'
                                    onClick={() => guardarPoliza(detalle_poliza)}
                                >
                                    Guardar poliza
                                </button>
                                <a
                                    href = {`/#/facturacion/${id_empresa[5]}/poliza_parqueo/${id_empresa[7]}`}
                                    className='btn btn-secondary mb-3'
                                >
                                    Regresar
                                </a>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
    
PolizaParqueoForm.propTypes = {
    crear: PropTypes.bool,
    datos: PropTypes.object,
    periodoD: PropTypes.object,
    modulo: PropTypes.object,
};
    
    
export default reduxForm({
    form: 'FormPolizaParqueo',
})(PolizaParqueoForm);

