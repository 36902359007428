import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

const Dropdown = ({ submenus, dropdown, logOut }) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    return (
      <ul className={`dropdownS ${dropdown ? "show" : ""}`}>
        {submenus.map((submenu, index) => (
            <li key={index} className="menu-itemsS">
                {submenu.title === 'Salir' ?
                    <SidebarLinkB
                        type="button"
                        onClick={logOut}
                        >
                        <div>
                            {submenu.icon}
                            <SidebarLabel>
                                {submenu.title}
                            </SidebarLabel>
                        </div>
                    </SidebarLinkB>
                : 
                    <SidebarLink to={`${submenu.path}/${id_emp[5]}/${submenu.path_s}`}>
                        <div>
                            {submenu.icon}
                            <SidebarLabel>
                                {submenu.title}
                            </SidebarLabel>
                        </div>
                    </SidebarLink>
                }
            </li>
        ))}
      </ul>
    );
  };

export default Dropdown;

const SidebarLink = styled(Link)`
    display: flex;
    color: #fff;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    list-style: none;
    height: 45px;
    text-decoration: none;
    font-size: 17px;

    &:hover {
        background: black;
        border-left: 4px solid #632ce4;
        cursor: pointer;
        color: #007bff;
        text-decoration: none;
    }
`;

const SidebarLinkB = styled.button`
    display: flex;
    color: #fff;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    list-style: none;
    height: 45px;
    text-decoration: none;
    font-size: 17px;
    font-weight: bold;
    &:hover {
        background: black;
        border-left: 4px solid #632ce4;
        cursor: pointer;
        color: #007bff;
        font-weight: bold;
    }
`;

const SidebarLabel = styled.span`
    margin-left: 16px;
`;