import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import Modal from '../../components/ModalA'
import DetalleFactura from './DetalleFactura';
import DetalleFacturaVer from './DetalleFacturaVer';
import styled from 'styled-components';

import {
    renderField,
    renderDayPickerB,
    SelectField,
    AsyncSelectField,
    renderCurrencyFloat,
    renderNumber
} from "../../Utils/renderField/renderField";

function FacturaEspecialForm(props) {
    const {
        crear,
        lectura,
        bloqueo,
        editar,
        detalleFactura,
        listarProveedores,
        registroProveedor,
        obtenerPaises,
        detalleCon,
        agregarLinea,
        datos,
        estable,
        periodoD,
        eliminarLinea
    } = props;

    let autoFocus = true;

    const [cantidad, setCantidad] = useState(0.00);
    const [total, setTotal] = useState(0.0);

    const [estadoModal, cambiarEstadoModal] = useState(false);

    const tiposCL = [
        {
            value: 'P',
            label: 'PERSONA'
        },
    ];

    const tiposDet = [
        {
            value: 'B',
            label: 'BIENES'
        },
        {
            value: 'S',
            label: 'SERVICIOS'
        },
    ];

    const tiposDoc = [
        {
            value: 'NIT',
            label: 'NIT'
        },
        {
            value: 'DPI',
            label: 'DPI'
        },
    ];

    const estilo = {
        overflow: "auto",
        height: "200px",

    };

    return (
        <div>
            {crear === false && editar == false &&
                <div className='row mb-2'>
                    <div className='col-3'>
                        <Field
                            name="codigo"
                            placeholder="Codigo establecimiento"
                            className="form-control"
                            component={renderField}
                            disabled={true}
                            msj="Código establecimiento"
                        />
                    </div>
                    <div className='col-6'>
                        <Field
                            name="establecimiento"
                            placeholder="Nombre establecimiento"
                            className="form-control"
                            component={renderField}
                            disabled={true}
                            msj="Nombre establecimiento"
                        />
                    </div>
                    <div className='col-3'>
                        <Field
                            name="correlativo"
                            placeholder="Documento interno"
                            className="form-control"
                            component={renderField}
                            disabled={true}
                            msj="Documento interno"
                        />
                    </div>
                </div>
            }
            {editar === true &&
                <div className='row mb-2'>
                    <div className='col-3'>
                        <Field
                            name="codigo"
                            placeholder="Codigo establecimiento"
                            className="form-control"
                            component={renderField}
                            disabled={true}
                            msj="Código establecimiento"
                        />
                    </div>
                    <div className='col-6'>
                        <Field
                            name="establecimiento"
                            placeholder="Nombre establecimiento"
                            className="form-control"
                            component={renderField}
                            disabled={true}
                            msj="Nombre establecimiento"
                        />
                    </div>
                    <div className='col-3'>
                        <Field
                            name="correlativo"
                            placeholder="Documento interno"
                            className="form-control"
                            component={renderField}
                            disabled={true}
                            msj="Documento interno"
                        />
                    </div>
                </div>
            }
            {crear === true &&
                <div className='row mb-2'>
                    <div className='col-3'>
                        <div className="form-floating">
                            <input
                                placeholder="Codigo establecimiento"
                                type="text"
                                autocomplete="off"
                                name="codigo"
                                className="form-control"
                                value={estable.codigo}
                                disabled={true}
                            />
                            <label className='form-label' htmlFor="codigo">
                                Código establecimiento
                            </label>
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className="form-floating">
                            <input
                                placeholder="Nombre establecimiento"
                                type="text"
                                autocomplete="off"
                                name="nombre"
                                className="form-control"
                                value={estable.nombre_comercial}
                                disabled={true}
                            />
                            <label className='form-label' htmlFor="nombre">
                                Nombre establecimiento
                            </label>
                        </div>
                    </div>
                </div>
            }
            {crear === false && editar == false &&
                <div className='row mb-2'>
                    <div className='col-3'>
                        <Field
                            autoFocus={autoFocus}
                            disabled={true}
                            name="fechaRegistro"
                            component={renderDayPickerB}
                            msj="Fecha Registro"
                        />
                    </div>
                    <div className='col-6'>
                        <Field
                            name="nombrePro"
                            placeholder="Proveedor"
                            className="form-control"
                            component={renderField}
                            disabled={true}
                            msj="Proveedor"
                            />
                    </div>
                </div>
            }
            {crear === true &&
                <div className='row mb-2'>
                    <div className='col-3'>
                        <Field
                            autoFocus={autoFocus}
                            disabled={bloqueo}
                            name="fechaRegistro"
                            component={renderDayPickerB}
                            msj="Fecha Registro"
                        />
                    </div>
                    {((crear === false && editar == false) || editar === true) &&

                        <div className='col-6'>
                            <Field
                                name="nombrePro"
                                placeholder="Proveedor"
                                className="form-control"
                                component={renderField}
                                disabled={true}
                                msj="Proveedor"
                            />
                        </div>
                    }
                    {(crear === true) &&
                        <div className='col-6'>
                            <Field
                                name="proveedor_documento"
                                placeholder="Proveedor..."
                                className="form-control-find"
                                component={AsyncSelectField}
                                loadOptions={listarProveedores}
                                msj="Proveedor"
                            />
                        </div>
                    }
                    {(crear === true) &&
                        <div className='col-3 mt-2'>
                            <button
                                className='btn mr-2 mb-3 btn-secondary'
                                onClick={() => cambiarEstadoModal(!estadoModal)}
                            >
                                Crear nuevo proveedor
                            </button>
                        </div>
                    }
                </div>
            }
            {editar === true &&
                <div className='row mb-2'>
                    <div className='col-3'>
                        <Field
                            autoFocus={autoFocus}
                            disabled={true}
                            name="fechaRegistro"
                            component={renderDayPickerB}
                            msj="Fecha Registro"
                        />
                    </div>
                    <div className='col-6'>
                        <Field
                            name="nombrePro"
                            placeholder="Proveedor"
                            className="form-control"
                            component={renderField}
                            disabled={true}
                            msj="Proveedor"
                        />
                    </div>
                </div>
            }
            {editar == true &&
                <div className='row mb-2'>
                    <div className='col-3'>
                        <div className='mb-2'>
                            <Field
                                name="serie"
                                placeholder="Serie"
                                component={renderField}
                                className="form-control"
                                msj="Serie"
                                disabled={true}
                            />
                        </div>
                    </div>
                    <div className='col-3'>
                        <div className='mb-2'>
                            <Field
                                name="numero"
                                placeholder="Número"
                                component={renderField}
                                className="form-control"
                                msj="Número"
                                disabled={true}
                            />
                        </div>
                    </div>
                    <div className='col-6'>
                        <Field
                            name="autorizacion"
                            disabled={true}
                            placeholder="Numero autorización"
                            className="form-control"
                            component={renderField}
                            msj="Numero autorización"
                        />
                    </div>
                </div>
            }
            {crear === false && editar == false &&
                <div className='row mb-2'>
                    <div className='col-3'>
                        <div className='mb-2'>
                            <Field
                                name="serie"
                                placeholder="Serie"
                                component={renderField}
                                className="form-control"
                                msj="Serie"
                                disabled={true}
                            />
                        </div>
                    </div>
                    <div className='col-3'>
                        <div className='mb-2'>
                            <Field
                                name="numero"
                                placeholder="Número"
                                component={renderField}
                                className="form-control"
                                msj="Número"
                                disabled={true}
                            />
                        </div>
                    </div>
                    <div className='col-6'>
                        <Field
                            name="autorizacion"
                            disabled={true}
                            placeholder="Numero autorización"
                            className="form-control"
                            component={renderField}
                            msj="Numero autorización"
                        />
                    </div>
                </div>
            }
            <div className='row mb-2'>
                <div className='col-12'>
                    <Field
                        name="observaciones"
                        placeholder="Descripción"
                        component={renderField}
                        className="form-control"
                        disabled={bloqueo}
                        msj="Descripción"
                    />
                </div>
            </div>
            {crear == true &&
                <div className='row'>
                    <div className='col-3'>
                        <div className="form-floating">
                            <Field
                                name="tipo_detalle"
                                options={tiposDet}
                                crear={crear}
                                dato={crear === true ? 0 : lectura.tipo}
                                component={SelectField}
                                className="form-select"
                                msj="Tipo"
                            />
                        </div>
                    </div>
                </div>
            }
            {((crear === false && editar === false) || editar === true) &&
                <div className='row'>
                    <div className='col-3'>
                        <div className="form-floating">
                            <Field
                                name="tipo_detalle"
                                options={tiposDet}
                                crear={crear}
                                disabled={true}
                                dato={lectura.tipo}
                                component={SelectField}
                                className="form-select"
                                msj="Tipo"
                            />
                        </div>
                    </div>
                </div>
            }
            {(crear === true) &&
                <div>
                    <h5 className="p-2 bg-secondary title mt-2">Detalle factura</h5>
                    <div className='row mb-2'>
                        <div className='col-12'>
                            <Field
                                name="descripcion"
                                placeholder="Descripción"
                                component={renderField}
                                className="form-control"
                                disabled={bloqueo}
                                msj="Descripción"
                            />
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-3'>
                            <Field
                                name="cantidad"
                                placeholder="Cantidad"
                                className="form-control"
                                component={renderCurrencyFloat}
                                disabled={bloqueo}
                                onChange={(e, value) => {
                                    if (value) {
                                        setCantidad(value);
                                    }
                                }}
                                msj="Cantidad"
                            />
                        </div>
                        <div className='col-3'>
                            <Field
                                name="precio"
                                placeholder="Valor Unitario"
                                className="form-control"
                                component={renderCurrencyFloat}
                                onChange={(e, value) => {
                                    if (value) {
                                        let total = parseFloat(parseFloat(value) * parseFloat(cantidad)).toFixed(2);
                                        setTotal(total);
                                    }
                                }}
                                msj="Valor Unitario"
                            />
                        </div>
                        <div className='col-3'>
                            <div className="form-floating">
                                <NumberFormat
                                    className={classNames('form-control')}
                                    decimalScale={2}
                                    disabled={true}
                                    placeholder="Base"
                                    fixedDecimalScale
                                    thousandSeparator
                                    value={total}
                                    name="total"
                                />
                                <label className='form-label' htmlFor="total">
                                    Total
                                </label>
                            </div>
                        </div>
                    </div>
                    <Contenido>
                        <button
                            className='btn mr-2 mb-3 btn-primary'
                            onClick={() => agregarLinea(periodoD, total)}
                        >
                            Agregar linea
                        </button>
                    </Contenido>
                </div>
            }
            <br/>
            {(crear === true) &&
                <div style={estilo}>
                    <DetalleFactura
                        detalle={detalleCon}
                        eliminarLinea={eliminarLinea}
                    />
                </div>
            }
            {((crear === false && editar === false) || editar === true) &&
                <div style={estilo}>
                    <DetalleFacturaVer
                        detalle={detalleFactura}
                    />
                </div>
            }
            <br />
            {((crear === false && editar === false) || editar === true) &&
                <div>
                    <div className='row mb-2'>
                        <div className='col-2'>

                        </div>
                        <div className='col-2'>
                            <Field
                                name="base"
                                disabled={true}
                                placeholder="Base"
                                className="form-control"
                                component={renderCurrencyFloat}
                                msj="Base"
                            />
                        </div>
                        <div className='col-2'>
                            <Field
                                name="isr"
                                disabled={true}
                                placeholder="ISR"
                                className="form-control"
                                component={renderCurrencyFloat}
                                msj="ISR"
                            />
                        </div>
                        <div className='col-2'>
                            <Field
                                name="iva"
                                disabled={true}
                                placeholder="Iva"
                                className="form-control"
                                component={renderCurrencyFloat}
                                msj="Iva"
                            />
                        </div>
                        <div className='col-2'>
                            <Field
                                name="total"
                                disabled={true}
                                placeholder="Total"
                                className="form-control"
                                component={renderCurrencyFloat}
                                msj="Total"
                            />
                        </div>
                        <div className='col-3'>

                        </div>
                    </div>
                </div>
            }
                    
            <Modal
                estado={estadoModal}
                cambiarEstado={cambiarEstadoModal}
                titulo="Crear proveedor"
                botonCerrar="true"
            >
                <div className="d-flex flex-column flex-1 mx-4">

                    <div className='row mb-2'>
                        <div className='col-4'>
                            <Field
                                autoFocus={autoFocus}
                                name="identificador"
                                placeholder="Identificador"
                                className="form-control"
                                component={renderField}
                                msj="Identificador"
                            />
                        </div>
                        <div className='col-4'>
                            <div className="form-floating">
                                <Field
                                    name="tipo_documento"
                                    options={tiposDoc}
                                    component={SelectField}
                                    className="form-select"
                                    msj="Tipo documento"
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='mb-2 col-8'>
                            <Field
                                name="nombre"
                                placeholder="Nombre"
                                className="form-control"
                                component={renderField}
                                msj="Nombre"
                            />
                        </div>
                        {obtenerPaises &&
                            <div className='col-4'>
                                <div className="form-floating">
                                    <Field
                                        name="pais"
                                        options={obtenerPaises}
                                        crear={crear}
                                        component={SelectField}
                                        className="form-select"
                                        msj="Pais"
                                    />
                                </div>
                            </div>
                        }
                    </div>

                    <div className='mb-2 w-75'>
                        <Field
                            name="direccion"
                            placeholder="Direccion"
                            className="form-control"
                            component={renderField}
                            msj="Dirección"
                        />
                    </div>
                    <div className='row'>
                        <div className='col-4'>
                            <Field
                                name="telefono"
                                placeholder="Telefono"
                                className="form-control"
                                component={renderField}
                                msj="Telefono"
                            />
                        </div>
                        <div className='col-4'>
                            <Field
                                name="correo"
                                placeholder="Correo electrónico"
                                className="form-control"
                                component={renderField}
                                msj="Correo electronico"
                            />
                        </div>
                        <div className='col-4'>
                            <Field
                                name="dias_credito"
                                placeholder="Dias credito"
                                className="form-control"
                                component={renderNumber}
                                msj="Dias crédito"
                            />
                        </div>
                    </div>
                    <div className='row mt-2'>
                        <div className='col-4'>
                            <Field
                                name="limite_credito"
                                placeholder="Limite credito"
                                className="form-control"
                                component={renderNumber}
                                msj="Limite crédito"
                            />
                        </div>
                        <div className='col-4'>
                            <div className="form-floating">
                                <Field
                                    name="tipo_proveedor"
                                    options={tiposCL}
                                    component={SelectField}
                                    className="form-select"
                                    msj="Tipo cliente"
                                />
                            </div>
                        </div>
                    </div>
                    <div className='d-flex flex-row mt-3'>

                        <button
                            className='btn mr-2 mb-3 btn-primary'
                            onClick={() => registroProveedor(datos)}
                        >
                            Guardar
                        </button>

                        <button
                            className='btn mr-2 mb-3 btn-secondary'
                            onClick={() => cambiarEstadoModal(false)}
                        >
                            Salir
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

FacturaEspecialForm.propTypes = {
    crear: PropTypes.bool,
    lectura: PropTypes.object,
    tipos: PropTypes.object,
    editar: PropTypes.bool,
    bloqueo: PropTypes.bool,
    obtenerPaises: PropTypes.object,
    detalleCon: PropTypes.object,
    detalleFactura: PropTypes.object,
    impuesto: PropTypes.object,
    datos: PropTypes.object,
    estable: PropTypes.object
};

const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;

export default reduxForm({
    form: 'FormFacturaEspecialFEL',
})(FacturaEspecialForm);
