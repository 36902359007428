import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/compras/anticipos';
import Anticipo from './AnticipoList';


const ms2p = (state) => {
  return {
    ...state.anticipo_cmp,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Anticipo);