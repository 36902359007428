import React, { Component } from 'react';
import Formulario from './NominaForm';
const valEmp = __CONFIG__.empresa;
import { withRouter } from 'react-router-dom';

class Nomina extends Component{
    
    componentDidMount = () => {
        const { getModulo, listar, leerPeriodoNomina, history } = this.props;
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        if (valEmp === 'fage'){
            getModulo(modulo[6], modulo[5])
            leerPeriodoNomina(modulo[6], modulo[8]);
        }else{
            getModulo(modulo[5],modulo[4]);
            leerPeriodoNomina(modulo[5],modulo[7]);
        }
        listar(history);
    }
    render(){
        const { loader, data, modulo, periodoN, correlativo, fecha_inicio, 
            fecha_fin, frecuencia, periodoD, periodoG,  
            periodoId, reporteNomina, mostrarDescuentos, confirmarNomina, revisarNomina, reporteDescuentos,
            actualizarNomina, estado } = this.props;
        
        return(
            <Formulario
                loader = {loader}
                modulo = {modulo}
                data = {data}
                periodoN = {periodoN}
                correlativo = {correlativo}
                fecha_inicio = {fecha_inicio}
                fecha_fin = {fecha_fin}
                frecuencia = {frecuencia}
                periodoD = {periodoD}
                periodoG = {periodoG}
                periodoId = {periodoId}
                reporteNomina = {reporteNomina}
                mostrarDescuentos = {mostrarDescuentos}
                confirmarNomina = {confirmarNomina}
                revisarNomina = {revisarNomina}
                reporteDescuentos = {reporteDescuentos}
                actualizarNomina = {actualizarNomina}
                estado = {estado}
            />
        );
    }
}
export default withRouter(Nomina);
