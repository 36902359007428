import React, { useState, useEffect } from 'react';
import { reduxForm } from 'redux-form';
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import LoadMask from "../../Utils/LoadMask/LoadMask";
import DetallePoliza from './PolizasListLC';
import NavbarModulo from '../../components/NavbarModulo';

function PolizasListTab(props) {
    const { data, loader, eliminar, periodoD, modulo, titulo, listar } = props;

    const [activeTab, setActiveTab] = useState(localStorage.getItem("activeTab"));
    
    useEffect(() => {
        const { leerPeriodoD, getModulo } = props;
        let ruta = window.location.href;
        let periodo = ruta.split('/');
        leerPeriodoD(periodo[5], periodo[7]);
        getModulo(periodo[5],periodo[4]);
        localStorage.removeItem("activeTab");
    }, []);

    let ruta = window.location.href;
    let datos = ruta.split('/');
    
    return (
        <React.Fragment>
            <NavbarModulo
                periodoD={periodoD}
                modulo={modulo}
                titulo={titulo}
            />
            <div className="py-1">
                <div className="mb-1">
                    <div className="p-0 px-3 pt-0">
                        <div className="d-flex flex-row justify-content-start mt-2">
                            <a
                                href={`/#/libros_contables/${datos[5]}/polizas/${datos[7]}/crear`}
                                className='btn btn-primary'
                            >
                                Crear Póliza
                            </a>
                        </div>
                        <br />
                        <Tabs
                            activeKey={activeTab} 
                            tabBarPosition="top"
                            renderTabBar={() => <ScrollableInkTabBar />}
                            renderTabContent={() => <TabContent />}
                            onChange={(activeKey) => {
                                if (activeKey === "CNT") {
                                    localStorage.setItem("activeTab", activeKey);
                                    setActiveTab(activeKey);
                                    listar("CNT");
                                }else if (activeKey === "BAN") {
                                    localStorage.setItem("activeTab", activeKey);
                                    setActiveTab(activeKey);
                                    listar("BAN");
                                }else if (activeKey === "CXP") {
                                    localStorage.setItem("activeTab", activeKey);
                                    setActiveTab(activeKey);
                                    listar("CXP");
                                }else if (activeKey === "CXC") {
                                    localStorage.setItem("activeTab", activeKey);
                                    setActiveTab(activeKey);
                                    listar("CXC");
                                }
                            }}
                            >
                            <TabPane tab="CONTABILIDAD" key="CNT">
                                <div className="py-2 px-3">
                                    <LoadMask loading={loader} dark>
                                        <DetallePoliza
                                            data = {data}
                                            listar = {listar}
                                            loader = {loader}
                                            eliminar = {eliminar}
                                        />                                        
                                        </LoadMask>
                                        <br />
                                </div>
                            </TabPane>
                            <TabPane tab="BAN" key="BAN">
                                <div className="py-2 px-3">
                                    <LoadMask loading={loader} dark>
                                        <DetallePoliza
                                            data = {data}
                                            listar = {listar}
                                            loader = {loader}
                                            eliminar = {eliminar}
                                        />                                        
                                        </LoadMask>
                                        <br />
                                </div>
                            </TabPane>
                            <TabPane tab="CXP" key="CXP">
                                <div className="py-2 px-3">
                                    <LoadMask loading={loader} dark>
                                        <DetallePoliza
                                            data = {data}
                                            listar = {listar}
                                            loader = {loader}
                                            eliminar = {eliminar}
                                        />                                        
                                        </LoadMask>
                                        <br />
                                </div>
                            </TabPane>
                            <TabPane tab="CXC" key="CXC">
                                <div className="py-2 px-3">
                                    <LoadMask loading={loader} dark>
                                        <DetallePoliza
                                            data = {data}
                                            listar = {listar}
                                            loader = {loader}
                                            eliminar = {eliminar}
                                        />                                        
                                        </LoadMask>
                                        <br />
                                </div>
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};

export default reduxForm({
    form: 'PolizasListTab', //identificador unico
})(PolizasListTab)