import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
const valEmp = __CONFIG__.empresa;

const LISTADO_CLAS = 'LISTADO_CLAS';
const LOADER = 'LOADER';
const MODULO = 'MODULO';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params;
    let id = ''
    if (valEmp === 'fage'){
        id = id_emp[6];
        params = { id };
    }else{
        id = id_emp[5];
        params = { id };
    }
    dispatch(setLoader(true));
    api.get('/clasificacion', params).then((response)=>{
        dispatch({ type: LISTADO_CLAS, data: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar clasificaciones',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const registroClasificacion = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormClasificacion.values;
    let id_empresa = '';
    if (valEmp === 'fage'){
        id_empresa = id_emp[6]
    }else{
        id_empresa = id_emp[5]
    }
    const formData = {
        descripcion_corta: data.descripcion_corta,
        descripcion_larga: data.descripcion_larga,
        empresa: id_empresa
    }
    dispatch(setLoader(true));
    api.post('/clasificacion/', formData).then(() => {
        NotificationManager.success(
        'Clasificación creada',
        'Exito',
        3000
    );
    let ruta = '';
    if (valEmp === 'fage'){
        ruta = `/lfg/registro_sanitario/${id_emp[6]}/clasificacion`
        dispatch(push(ruta));
    }else{
        ruta = `/registro_sanitario/${id_emp[5]}/clasificacion`
        dispatch(push(ruta));
    }
    }).catch((error) => {
    NotificationManager.error(
        error.msj,
        'Error',
        2000
    );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const modificarClasificacion = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const datos = getStore().form.FormClasificacion.values;
    const id = datos.id;
    const formData = {
        id: id,
        descripcion_corta: datos.descripcion_corta,
        descripcion_larga: datos.descripcion_larga,
    }
    dispatch(setLoader(true));
    api.put(`/clasificacion/${id}/`, formData).then(() => {
        NotificationManager.success(
            'Clasificación modificada correctamente',
            'Exito',
            3000
        );
        let ruta = '';
        if (valEmp === 'fage'){
            ruta = `/lfg/registro_sanitario/${id_emp[6]}/clasificacion`
            dispatch(push(ruta));
        }else{
            ruta = `/registro_sanitario/${id_emp[5]}/clasificacion`
            dispatch(push(ruta));
        }
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al modificar linea',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/clasificacion/${id}`).then((response) => {
        dispatch(initializeForm('FormClasificacion', response ));
    }).catch((error)=>{
        
        NotificationManager.error(
            'Ocurrió un error al consultar clasificacion',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const actions = {
    listar,
    registroClasificacion,
    modificarClasificacion,
    leer,
    getModulo
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_CLAS]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
};

export const initialState = {
    loader: false,
    modulo: [],
    data: []
};

export default handleActions(reducers, initialState)
 