import React, { Component } from 'react';
import Grid from '../../Utils/GridVDocumento';
import { standardActions } from '../../Utils/GridVDocumento/StandardActions';

class DetalleListadoInvernaderoList extends Component{
    
    render(){
        const { loader, eliminarLineaFase,  detalle } = this.props;
        return(
            <React.Fragment>
                {detalle &&
                <Grid 
                    hover 
                    striped 
                    data={detalle} 
                    loading={loader}
                    headerStyle={ { background: 'black' } } 
                >
                    <TableHeaderColumn
                        isKey
                        dataField="fase"
                        dataAlign='left'
                        headerAlign="center"
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Llave
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        thStyle={
                        {color: 'white'}}
                        dataField="id"
                        width="150"
                        dataAlign="center"
                        dataSort
                        dataFormat={standardActions({
                            eliminar: eliminarLineaFase })} 
                    >
                        Acciones
                    </TableHeaderColumn>
                </Grid>
                }
            </React.Fragment>
        );
    }
}

export default DetalleListadoInvernaderoList;
