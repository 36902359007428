import { handleActions } from 'redux-actions';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';
import { initialize } from 'redux-form';

const LOADER = 'LOADER';
const MODULO = 'MODULO';
const PERIODO_D = 'PERIODO_D';
const POLIZAS = 'POLIZAS';
const DETALLE_POLIZA = 'DETALLE_POLIZA';  

const setLoader = loader => ({
    type: LOADER,
    loader,
});


export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const leerPeriodoD = (id, id_periodo) => (dispatch) => {
    const params = { id, id_periodo };
    api.get('/periodos/periodo', params).then((response) => {
        dispatch({ type: PERIODO_D, periodoD: response.periodo });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar periodo',
            'Error',
            2000
        );
    })
}

const limpiar = () => (dispatch) => {
    dispatch({ type: DETALLE_POLIZA, detalle_poliza: [] });
    dispatch({ type: POLIZAS, polizas: [] });
};

export const listarPolizas = (id, id_periodo) => (dispatch) => {
    const params = { id, id_periodo };
    api.get('/parqueo', params).then((response) => {
        dispatch({ type: POLIZAS, polizas: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar periodo',
            'Error',
            2000
        );
    })
}

export const listarCuentas = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/cuentas/empresa_cuentas/', params).then(data => {
        const list_cuentas = [];
        if (data) {
            data.forEach(item => {
                if (item.tipo === 'A') {
                    list_cuentas.push({
                        value: item.id,
                        label: item.cuenta + ' ' + item.nombre,
                        isDisabled: true
                    })
                } else {
                    list_cuentas.push({
                        value: item.id,
                        label: item.cuenta + ' ' + item.nombre
                    })
                }
            })
        }
        return list_cuentas;
    }).catch((error) => {
        
        return [];
    })
}

const generarPoliza = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormPolizaParqueo.values;
    dispatch({ type: DETALLE_POLIZA, detalle_poliza: [] });
    if (data === undefined) {
        Swal.fire({
            title: 'Póliza',
            text: 'Debe de seleccionar una cuenta',
            type: 'error',
        })
    }else {
        if (data.cuenta_debe === undefined || data.cuenta_debe === undefined) {
            Swal.fire({
                title: 'Póliza',
                text: 'Debe de seleccionar cuenta debe',
                type: 'error',
            })
        }else{
            if (data.cuenta_haber === undefined || data.cuenta_haber === undefined) {
                Swal.fire({
                    title: 'Póliza',
                    text: 'Debe de seleccionar cuenta haber',
                    type: 'error',
                })
            }else{
                const formData = {
                    fecha_inicio: data.fecha_inicio,
                    cuenta_debe: data.cuenta_debe.value,
                    cuenta_haber: data.cuenta_haber.value,
                    descripcion: data.descripcion,
                    tipo:  'DR',
                    modulo: 'FAC',
                    empresa: id_emp[5],
                    periodo: id_emp[7],
                }
                dispatch(setLoader(true));
                api.post('/parqueo/generar_poliza/', formData).then((response) => {
                    dispatch({ type: DETALLE_POLIZA, detalle_poliza: response });
                }).catch((error) => {
                    if (parseInt(error.sel) === 1) {
                        dispatch({ type: DETALLE_POLIZA, detalle_poliza: [] });
                        Swal.fire({
                            title: '¿Póliza?',
                            text: '¡Ya existe una póliza para esta fecha!\nSi vuelve a generar la póliza, la anterior se borrará.',
                            type: 'question',
                            showCancelButton: true,
                            confirmButtonText: '¡Sí, volver a generar!',
                            cancelButtonText: 'No, cancelar',
                            reverseButtons: true
                        }).then((result) => {
                            if (result.value) {
                                let poliza = 'generar';
                                const formData = {
                                    poliza: poliza,
                                    fecha_inicio: data.fecha_inicio,
                                    cuenta_debe: data.cuenta_debe.value,
                                    cuenta_haber: data.cuenta_haber.value,
                                    descripcion: data.descripcion,
                                    tipo:  'DR',
                                    modulo: 'FAC',
                                    empresa: id_emp[5],
                                    periodo: id_emp[7],
                                }
                                dispatch(setLoader(true));
                                api.post('/parqueo/generar_poliza/', formData).then((response)=>{
                                    dispatch({ type: DETALLE_POLIZA, detalle_poliza: response });
                                }).catch((error)=>{
                                    NotificationManager.error(
                                        'Ocurrió un error al generar reporte',
                                        'Error',
                                        2000
                                    );
                                }).finally(() => {
                                    dispatch(setLoader(false));
                                });
                            }else {
                                Swal.fire(
                                    'Cancelado',
                                    'No se generó la póliza',
                                    'error'
                                )
                            }
                        });
                    }else{
                        NotificationManager.error(
                            error.msj,
                            'Error',
                            0
                        );
                    }
                }).finally(() => {
                    dispatch(setLoader(false));
                });
            }
        }
    }
}

const guardarPoliza = (poliza) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormPolizaParqueo.values;
    if (poliza.length === 0) {
        Swal.fire({
            title: 'Póliza',
            text: 'Debe de crear una poliza',
            type: 'error',
        })
    }else {
        if (data.descripcion === undefined || data.descripcion === undefined) {
            Swal.fire({
                title: 'Póliza',
                text: 'Debe de ingresar una descripcion',
                type: 'error',
            })
        }else{
            const formData = {
                fecha_inicio: data.fecha_inicio,
                descripcion: data.descripcion,
                poliza: poliza,
                tipo:  'DR',
                modulo: 'FAC',
                empresa: id_emp[5],
                periodo: id_emp[7],
            }
            dispatch(setLoader(true));
            api.post('/parqueo/guardar_poliza/', formData).then(() => {
                NotificationManager.success(
                    'Poliza guardada correctamente',
                    'Exito',
                    3000
                );
                dispatch({ type: DETALLE_POLIZA, detalle_poliza: [] });
                dispatch(initialize('FormPolizaParqueo', {}));
            }).catch((error) => {
                NotificationManager.error(
                    error.msj,
                    'Error',
                    0
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }
    }
}

export const actions = {
    leerPeriodoD,
    getModulo,
    limpiar,
    listarPolizas,
    listarCuentas,
    generarPoliza,
    guardarPoliza
};


export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [PERIODO_D]: (state, { periodoD }) => {
        return {
            ...state,
            periodoD,
        };
    },
    [POLIZAS]: (state, { polizas }) => {
        return {
            ...state,
            polizas,
        };
    },
    [DETALLE_POLIZA]: (state, { detalle_poliza }) => {
        return {
            ...state,
            detalle_poliza,
        };
    },
};

export const initialState = {
    loader: false,
    modulo: [],
    periodoD: [],
    data: [],
    polizas: [],
    detalle_poliza: [],
};

export default handleActions(reducers, initialState)
