import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import { validate, validators } from 'validate-redux-form';
import PropTypes from 'prop-types';
import NavbarModulo from '../../components/NavbarModulo';
import {
    renderField,
    SelectField
} from "../../Utils/renderField/renderField";

function MonedasForm(props) {
    const { handleSubmit, crear, modulo, lectura } = props;

    const editar = window.location.href.includes('editar');
    let titulo = editar ? 'Modificar moneda' : 'Crear moneda';
    let disabled = false;
    if (crear === false && editar === false) {
        disabled = true;
        titulo = 'Ver moneda'
    }
    let autoFocus = true;
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const cambio = [
        {
          value: 'S',
          label: 'S'
        },
        {
          value: 'N',
          label: 'N'
        },
    ];
    return (
        <div>
            <NavbarModulo
                modulo={modulo}
                titulo={titulo}
            />
            <form onSubmit={handleSubmit}>
                <br />
                {crear === true &&
                    <h5 className="p-2 bg-secondary title mt-1">Nota: Primera moneda en agregar se establecerá como principal</h5>
                }
                <div className="mb-4 card card-small text-white bg-dark mb-3">
                    <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                        <div className="d-flex flex-column flex-1 mx-4">
                            <div className='row'>
                                <div className='col-3'>
                                    <Field
                                        autoFocus={autoFocus}
                                        name="moneda"
                                        placeholder="Banco"
                                        className="form-control"
                                        component={renderField}
                                        disabled={disabled}
                                        msj="Moneda"
                                    />
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="simbolo"
                                        placeholder="Simbolo"
                                        className="form-control"
                                        component={renderField}
                                        disabled={disabled}
                                        msj="Simbolo"
                                    />
                                </div>
                                <div className='col-4'>
                                    <div className="form-floating">
                                        <Field
                                            name="cambio"
                                            options={cambio}
                                            crear={crear}
                                            dato={crear === true ? 0 : lectura.cambio}
                                            component={SelectField}
                                            className="form-select"
                                            disabled={disabled}
                                            msj="Cambio"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex flex-row mt-3'>
                                {disabled == false &&
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        type='submit'
                                    >
                                        {editar ? 'Modificar' : 'Guardar'}
                                    </button>
                                }
                                <a
                                    href={`/#/ajustes/${id_emp[5]}/monedas`}
                                    className='btn btn-secondary mb-3'
                                >
                                    Regresar
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

MonedasForm.propTypes = {
    handleSubmit: PropTypes.func,
    crear: PropTypes.bool,
    lectura: PropTypes.object,
    modulo: PropTypes.object,
};

export default reduxForm({
    form: 'FormMonedas',
    validate: (data) => {
        return validate(data, {
            moneda: validators.exists()('Este campo es requerido'),
        });
    },
})(MonedasForm);
