import React, { Component } from 'react';
import Grid from '../../Utils/GridVDocumento';
import { standardActions } from '../../Utils/GridVDocumento/StandardActions';

class DetalleSerieList extends Component{
    
    render(){
        const { loader, eliminarLinea,  detalle } = this.props;
        return(
            <React.Fragment>
                {detalle &&
                <Grid 
                    hover 
                    striped 
                    data={detalle} 
                    loading={loader}
                    headerStyle={ { background: 'black' } } 
                >
                    <TableHeaderColumn
                        isKey
                        dataField="tipo_registro"
                        dataAlign='left'
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Tipo registro
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="serie"
                        dataAlign='left'
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Serie
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="tipo_movimiento"
                        dataAlign='left'
                        dataFormat={(cell, row)=>{
                            if (cell === 'INV'){
                                return 'INVENTARIO'
                            }
                            if (cell === 'AGR'){
                                return 'AGRICOLA'
                            }
                            if (cell === ''){
                                return ''
                            }
                        }}
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Movimiento
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="bodega"
                        dataAlign='left'
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Bodega
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        thStyle={
                        {color: 'white'}}
                        dataField="id"
                        width="150"
                        dataAlign="center"
                        dataSort
                        dataFormat={standardActions({
                            eliminar: eliminarLinea })} 
                    >
                        Acciones
                    </TableHeaderColumn>
                </Grid>
                }
            </React.Fragment>
        );
    }
}

export default DetalleSerieList;
