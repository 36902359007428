import React, { useState, useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import Tabs, { TabPane } from 'rc-tabs';
import PropTypes from 'prop-types';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import Documento from './FacturaEspecialForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";
import Poliza from "../../components/PolizaFormA";
import Modal from '../../components/ModalPoliza';
import ModalCuenta from '../../components/Modal';
import styled from 'styled-components';
import NavbarModulo from '../../components/NavbarModulo';

import {
    renderField,
    SelectField,
    AsyncSelectField,
    renderCurrencyPoliza,
    renderNumber
} from "../../Utils/renderField/renderField";

function FacturacionFormTab(props) {
    const { 
        crear, lectura, tipos, modulo, periodoD, listarProveedores, eliminarLineaPoliza, 
        loader, detalle, eliminarLinea, datos, detalleCon, agregarLinea, lecturaP,
        listarCuentas, listarCC, nivel, registroCuenta, impuesto, detalleFactura,
        reporteCuentas, loaderC, registroFactura, registroProveedor, listaP, estable,
        modificarFactura, registroPolizaDetalle, confirmarPoliza, registroPolizaDetalleSCC
        } = props;

    useEffect(() => {
        const { match, leer, listarPaises, leerPeriodoD, vaciarDetalle,
            getModulo, listarCuentas, listarCC, listarNiveles, leerDetallePoliza,
            leerEstablecimiento, parametrosEmpresa, listarImpuestos } = props;
        const id = match.params.id;
        const id_est = match.params.estable;
        
        if (id) {
            setCrear(false);
            leer(id);
        }
        if (id === undefined){
            leerEstablecimiento(id_est);
        }
        let ruta = window.location.href;
        let periodo = ruta.split('/');
        leerPeriodoD(periodo[5], periodo[7]);
        getModulo(periodo[5], periodo[4]);
        parametrosEmpresa();
        listarImpuestos();
        listarCuentas();
        listarPaises();
        listarCC();
        listarNiveles();
        vaciarDetalle();
        leerDetallePoliza('00000000-0000-0000-0000-000000000000');
        localStorage.removeItem('idPolizaFacturaEspecial');
    }, [])
    const [crearC, setCrear] = useState(true);
    const [estadoModal, cambiarEstadoModal] = useState(false);
    const [estadoModalCC, cambiarEstadoModalCC] = useState(false);
    const [estadoModalCuenta, cambiarEstadoModalCuenta] = useState(false);

    const editar = window.location.href.includes('editar');
    let titulo = editar ? 'Modificar Factura especial FEL' : 'Crear Factura especial FEL';
    let disabled = false;
    if (crearC === false && editar === false) {
        disabled = true;
        titulo = 'Ver Factura especial FEL'
    }
    
    /* crear cuenta contable*/
    const getNivel = () => {
        let formato = ''
        if (nivel.length > 0) {
            for (let a = 0; a < nivel[0].nivel_uno; a++) {
                formato = formato + '#'
            }
            for (let b = 0; b < nivel[0].nivel_dos; b++) {
                if (b === 0) {
                    formato = formato + '-' + '#'
                } else {
                    formato = formato + '#'
                }
            }
            for (let c = 0; c < nivel[0].nivel_tres; c++) {
                if (c === 0) {
                    formato = formato + '-' + '#'
                } else {
                    formato = formato + '#'
                }
            }
            for (let d = 0; d < nivel[0].nivel_cuatro; d++) {
                if (d === 0) {
                    formato = formato + '-' + '#';
                } else {
                    formato = formato + '#';
                }
            }
            for (let e = 0; e < nivel[0].nivel_cinco; e++) {
                if (e === 0) {
                    formato = formato + '-' + '#';
                } else {
                    formato = formato + '#';
                }
            }
            for (let f = 0; f < nivel[0].nivel_seis; f++) {
                formato = formato + '#';
            }
        }

        return formato
    };

    const tipo = [
        {
            value: 'A',
            label: 'Acumulativa'
        },
        {
            value: 'D',
            label: 'Detalle'
        },
    ];

    const naturaleza = [
        {
            value: 'ACTIVO',
            label: 'Activo'
        },
        {
            value: 'PASIVO',
            label: 'Pasivo'
        },
        {
            value: 'CAPITAL',
            label: 'Capital'
        },
        {
            value: 'INGRESOS',
            label: 'Ingresos'
        },
        {
            value: 'COSTOS',
            label: 'Costos'
        },
        {
            value: 'GASTOS',
            label: 'Gastos'
        },
    ];

    let autoFocus = true;
    
    const [key, setKey] = useState('PRINCIPAL_TOP');
    let poliza = localStorage.getItem('idPolizaFacturaEspecial');
    
    let ruta = window.location.href;
    let id_empresa = ruta.split('/');
    let dif_poliza = localStorage.getItem('dif');
    
    return (
        <React.Fragment>
             <NavbarModulo
                periodoD={periodoD}
                modulo={modulo}
                titulo={titulo}
            />
            <div className="py-1">
                <div className="mb-1">
                    <div className="p-0 px-3 pt-0">
                        {datos.contabilidad === 'S' && datos.fel === 'S' &&
                            <Tabs
                                activeKey={key}
                                onChange={(k) => setKey(k)}
                                tabBarPosition="top"
                                renderTabBar={() => <ScrollableInkTabBar />}
                                renderTabContent={() => <TabContent />}
                            >
                                <TabPane tab="Documento" key="PRINCIPAL_TOP">
                                    <div className="py-2 px-3">
                                        <div className="mb-4 card card-small text-white bg-dark mb-1">
                                            <div className="p-0 pt-4 d-flex flex-column flex-md-row">
                                                <div className="d-flex flex-column flex-1 mx-4">
                                                    <LoadMask loading={loader} dark>
                                                        <Documento
                                                            crear={crearC}
                                                            lectura={lectura}
                                                            bloqueo={disabled}
                                                            editar={editar}
                                                            tipos={tipos}
                                                            obtenerPaises={listaP}
                                                            fechaInicio={periodoD.fecha_inicio}
                                                            fechaFinal={periodoD.fecha_fin}
                                                            impuesto={impuesto}
                                                            registroProveedor={registroProveedor}
                                                            listarProveedores={listarProveedores}
                                                            datos = {datos}
                                                            detalleFactura = {detalleFactura}
                                                            periodoD = {periodoD}
                                                            agregarLinea = {agregarLinea}
                                                            detalleCon = {detalleCon}
                                                            estable = {estable}
                                                            eliminarLinea = {eliminarLinea}
                                                        />
                                                    </LoadMask>
                                                    <br/>
                                                    {disabled == false && !poliza &&
                                                        <div className='mb-2'>
                                                            {crearC === true &&
                                                                <button
                                                                    className='btn mr-2 mb-3 btn-primary'
                                                                    onClick={() => {
                                                                        registroFactura(periodoD, datos);
                                                                        if (localStorage.getItem('conf_fact_esp') === 'T'){
                                                                            setKey('SEGUNDO_TOP');
                                                                        }
                                                                    }}
                                                                >
                                                                    Guardar factura
                                                                </button>
                                                            }
                                                            {editar === true &&
                                                                <button
                                                                    className='btn mr-2 mb-3 btn-primary'
                                                                    onClick={() => setKey('SEGUNDO_TOP')}
                                                                >
                                                                    Modificar Poliza
                                                                </button>
                                                            }
                                                            <a
                                                                href={`/#/compras/${id_empresa[5]}/factura_especial_fel/${id_empresa[7]}`}
                                                                className='btn btn-secondary mb-3'
                                                            >
                                                                Regresar
                                                            </a>
                                                        </div>
                                                    
                                                    }
                                                    {disabled == false && poliza && editar &&
                                                        <div className='mb-2'>
                                                            <button
                                                                className='btn mr-2 mb-2 btn-primary'
                                                                onClick={() => setKey('SEGUNDO_TOP')}
                                                            >
                                                                Modificar Poliza
                                                            </button>
                                                            <a
                                                                href={`/#/compras/${id_empresa[5]}/factura_especial_fel/${id_empresa[7]}`}
                                                                className='btn btn-secondary mb-2'
                                                            >
                                                                Regresar
                                                            </a>
                                                        </div>
                                                    }
                                                    {disabled == false && poliza && crearC &&
                                                        <div className='d-flex flex-row mt-2'>
                                                            {poliza &&
                                                                <button
                                                                    className='btn mr-2 mb-3 btn-primary'
                                                                    onClick={() => setKey('SEGUNDO_TOP')}
                                                                >
                                                                    Crear Poliza
                                                                </button>
                                                            }
                                                        </div>
                                                    }
                                                    {crearC === false && editar === false  &&
                                                        <div className='d-flex flex-row mt-2'>
                                                            <a
                                                                href={`/#/compras/${id_empresa[5]}/factura_especial_fel/${id_empresa[7]}`}
                                                                className='btn btn-secondary mb-3'
                                                            >
                                                                Regresar
                                                            </a>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane tab="Póliza" key="SEGUNDO_TOP">
                                    <div className="py-2 px-3">
                                        <div className="mb-4 card card-small bg-dark mb-3">
                                            <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                                                <div className="d-flex flex-column flex-1 mx-3">
                                                    {disabled == false && datos.centro_costo === 'N' &&
                                                        <Contenido>
                                                            <button
                                                                className='btn mr-2 mb-3 btn-primary'
                                                                onClick={() => cambiarEstadoModal(!estadoModal)}
                                                            >
                                                                Agregar Linea
                                                            </button>
                                                        </Contenido>
                                                    }

                                                    {disabled == false && datos.centro_costo === 'S' &&
                                                        <Contenido>
                                                            <button
                                                                className='btn mr-2 mb-3 btn-primary'
                                                                onClick={() => cambiarEstadoModalCC(!estadoModalCC)}
                                                            >
                                                                Agregar Linea
                                                            </button>
                                                        </Contenido>
                                                    }
                                                    <Poliza
                                                        listarCuentas={listarCuentas}
                                                        lectura={lecturaP}
                                                        crear={crearC}
                                                        editar={editar}
                                                        listarCC={listarCC}
                                                        detallePoliza={detalle}
                                                        eliminarLinea={eliminarLineaPoliza}
                                                        niveles={nivel}
                                                        registroCuenta={registroCuenta}
                                                        reporteCuentas={reporteCuentas}
                                                        loaderC={loaderC}
                                                    />
                                                    <br />
                                                    <div className='d-flex flex-row mt-3'>
                                                        {disabled == false &&
                                                            <button
                                                                className='btn mr-2 mb-3 btn-primary'
                                                                onClick={() => {
                                                                    //function expression
                                                                    const funcion2 = confirmarPoliza;
                                                                    funcion2();
                                                                }}

                                                            >
                                                                {editar ? 'Modificar' : 'Confirmar poliza'}
                                                            </button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPane>
                            </Tabs>
                        }
                        {datos.contabilidad === 'N' && datos.fel === 'S' &&
                            <div className="mb-4 card card-small text-white bg-dark mb-1">
                                <div className="p-0 pt-4 d-flex flex-column flex-md-row">
                                    <div className="d-flex flex-column flex-1 mx-4">
                                        <LoadMask loading={loader} dark>
                                            <Documento
                                                crear={crearC}
                                                lectura={lectura}
                                                bloqueo={disabled}
                                                editar={editar}
                                                tipos={tipos}
                                                obtenerPaises={listaP}
                                                fechaInicio={periodoD.fecha_inicio}
                                                fechaFinal={periodoD.fecha_fin}
                                                impuesto={impuesto}
                                                registroProveedor={registroProveedor}
                                                listarProveedores={listarProveedores}
                                                datos = {datos}
                                                detalleFactura = {detalleFactura}
                                                periodoD = {periodoD}
                                                agregarLinea = {agregarLinea}
                                                detalleCon = {detalleCon}
                                                estable = {estable}
                                                eliminarLinea = {eliminarLinea}
                                            />
                                        </LoadMask>
                                        <br/>
                                        {disabled == false &&
                                            <div className='mb-2'>
                                                {crearC === true &&
                                                    <button
                                                        className='btn mr-2 mb-3 btn-primary'
                                                        onClick={() => {
                                                            registroFactura(periodoD, datos);
                                                        }}
                                                    >
                                                        Guardar factura
                                                    </button>
                                                }
                                                {editar === true &&
                                                    <button
                                                        className='btn mr-2 mb-3 btn-primary'
                                                        onClick={() => setKey('SEGUNDO_TOP')}
                                                    >
                                                        Modificar Poliza
                                                    </button>
                                                }
                                                <a
                                                    href={`/#/compras/${id_empresa[5]}/factura_especial_fel/${id_empresa[7]}`}
                                                    className='btn btn-secondary mb-3'
                                                >
                                                    Regresar
                                                </a>
                                            </div>
                                        }
                                        {crearC === false && editar === false  &&
                                            <div className='d-flex flex-row mt-2'>
                                                <a
                                                    href={`/#/compras/${id_empresa[5]}/factura_especial_fel/${id_empresa[7]}`}
                                                    className='btn btn-secondary mb-3'
                                                >
                                                    Regresar
                                                </a>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                        {datos.contabilidad === 'N' && datos.fel === 'N' &&
                            <div className="mb-4 card card-small text-white bg-dark mb-1">
                                <div className="p-0 pt-4 d-flex flex-column flex-md-row">
                                    <div className="d-flex flex-column flex-1 mx-4">
                                        <LoadMask loading={loader} dark>
                                            <Documento
                                                crear={crearC}
                                                lectura={lectura}
                                                bloqueo={disabled}
                                                editar={editar}
                                                tipos={tipos}
                                                obtenerPaises={listaP}
                                                fechaInicio={periodoD.fecha_inicio}
                                                fechaFinal={periodoD.fecha_fin}
                                                impuesto={impuesto}
                                                registroCliente={registroCliente}
                                                listarClientes={listarClientes}
                                                datos = {datos}
                                                estable = {estable}
                                            />
                                        </LoadMask>
                                        <br/>
                                        {disabled == false &&
                                            <div>
                                                <button
                                                    className='btn mr-2 mb-3 btn-primary'
                                                    onClick={() => {
                                                        //function expression
                                                        //const funcion1 = setKey('SEGUNDO_TOP');
                                                        const funcion1 = () => registroFactura(impuesto);
                                                        const funcion2 = () => modificarFactura(impuesto);
                                                        //function call:ejecuta
                                                        if (crearC === true){
                                                            funcion1();
                                                        }
                                                        if (crearC === false){
                                                            funcion2();
                                                        }
                                                    }}
                                                    
                                                >
                                                    {editar ? 'Modificar' : 'Guardar documento'}
                                                </button>
                                                <a
                                                    href={`/#/facturacion/${id_empresa[5]}/facturas/${id_empresa[7]}`}
                                                    className='btn btn-secondary mb-3'
                                                >
                                                    Regresar
                                                </a>
                                            </div>
                                        
                                        }
                                        {crearC === false && editar === false  &&
                                            <div className='d-flex flex-row mt-2'>
                                                <a
                                                    href={`/#/facturacion/${id_empresa[5]}/facturas/${id_empresa[7]}`}
                                                    className='btn btn-secondary mb-3'
                                                >
                                                    Regresar
                                                </a>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <Modal
                estado={estadoModal}
                cambiarEstado={cambiarEstadoModal}
                titulo="Ingresar Detalle poliza"
                botonCerrar="true"
            >
                <div className='row mb-3'>
                    <div className='col-6'>
                        <Field
                            name="cuenta"
                            placeholder="Cuenta contable..."
                            className="form-control-find"
                            component={AsyncSelectField}
                            loadOptions={listarCuentas}
                            msj="Cuenta"
                        />
                        <button
                            className='btn ml-2 btn-dark'
                            onClick={() => cambiarEstadoModalCuenta(!estadoModalCuenta)}
                        >
                            Crear cuenta
                        </button>
                    </div>
                    <div className='col-3 mt-1 w-25'>
                        <Field
                            name='debe'
                            component={renderCurrencyPoliza}
                            className="form-control"
                            placeholder="Debe"
                            msj="Debe"
                        />
                    </div>
                    <div className='col-3 mt-1'>
                        <Field
                            name='haber'
                            component={renderCurrencyPoliza}
                            className="form-control"
                            placeholder="Haber"
                            msj="Haber"
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-9'>
                    </div>
                    <div className='col-3 title'>
                        Diferencia: {dif_poliza}
                    </div>
                </div>
                <Contenido>
                    <div className='row'>
                        <div className='col-6'>
                            <button
                                className='btn ml-3 mb-3 btn-primary'
                                onClick={registroPolizaDetalleSCC}
                            >
                                Agregar
                            </button>
                        </div>
                        <div className='col-6'>
                            <button
                                className='btn mb-3 btn btn-secondary'
                                onClick={() => cambiarEstadoModal(false)}
                            >
                                Cancelar
                            </button>
                        </div>
                    </div>
                </Contenido>
            </Modal>
            <Modal
                estado={estadoModalCC}
                cambiarEstado={cambiarEstadoModalCC}
                titulo="Ingresar Detalle poliza"
                botonCerrar="true"
            >
                <div className='row mb-3'>
                    <div className='col-6'>
                        <Field
                            autoFocus={true}
                            name="cuenta"
                            placeholder="Cuenta contable..."
                            className="form-control-find"
                            component={AsyncSelectField}
                            loadOptions={listarCuentas}
                            msj="Cuenta"
                        />
                        <button
                            className='btn ml-2 btn-dark'
                            onClick={() => cambiarEstadoModalCuenta(!estadoModalCuenta)}
                        >
                            Crear cuenta
                        </button>
                    </div>

                    <div className='col-2'>
                        <Field
                            name="cc"
                            placeholder="C.C..."
                            className="form-control-find"
                            component={AsyncSelectField}
                            loadOptions={listarCC}
                            msj="Centro Costo"
                        />
                    </div>
                    <div className='col-2 mt-1 w-25'>
                        <Field
                            name='debe'
                            component={renderCurrencyPoliza}
                            className="form-control"
                            placeholder="Debe"
                            msj="Debe"
                        />
                    </div>
                    <div className='col-2 mt-1'>
                        <Field
                            name='haber'
                            component={renderCurrencyPoliza}
                            className="form-control"
                            placeholder="Haber"
                            msj="Haber"
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-9'>
                    </div>
                    <div className='col-3 title'>
                        Diferencia: {dif_poliza}
                    </div>
                </div>
                <Contenido>
                    <div className='row'>
                        <div className='col-6'>
                            <button
                                className='btn ml-3 mb-3 btn-primary'
                                onClick={registroPolizaDetalle}
                            >
                                Agregar
                            </button>
                        </div>
                        <div className='col-6'>
                            <button
                                className='btn mr-2 mb-3 btn btn-secondary'
                                onClick={() => cambiarEstadoModalCC(false)}
                            >
                                Cancelar
                            </button>
                        </div>
                    </div>
                </Contenido>
            </Modal>
            <ModalCuenta
                estado={estadoModalCuenta}
                cambiarEstado={cambiarEstadoModalCuenta}
                titulo="Crear cuenta contable"
                botonCerrar="false"
            >
                <div className="d-flex flex-column flex-1 mx-4">
                    <div className='mb-2 w-50'>
                        <Field
                            autoFocus={autoFocus}
                            type="number"
                            id="cuenta"
                            name="cuenta"
                            placeholder="Cuenta"
                            className="form-control"
                            numberFormat={getNivel()}
                            component={renderNumber}
                            msj="Cuenta"
                        />
                    </div>
                    <div className='mb-2 w-100'>
                        <Field
                            id="nombre"
                            name="nombre"
                            placeholder="Nombre Cuenta"
                            className="form-control"
                            component={renderField}
                            msj="Nombre Cuenta"
                        />
                    </div>
                    <div className='mb-2 w-50'>
                        <div className="form-floating">
                            <Field
                                name="tipoC"
                                options={tipo}
                                crear={crear}
                                dato={crear === true ? 0 : lectura.tipo}
                                component={SelectField}
                                className="form-select"
                                msj="Tipo"
                            />
                        </div>
                    </div>
                    <div className='mb-3 w-50'>
                        <div className="form-floating">
                            <Field
                                id="naturaleza"
                                name="naturaleza"
                                options={naturaleza}
                                crear={crear}
                                dato={crear === true ? 0 : lectura.naturaleza}
                                component={SelectField}
                                className="form-select"
                                msj="Naturaleza"
                            />
                        </div>
                    </div>
                    <LoadMask loading={loaderC} dark>
                        <Contenido>
                            <div className='row'>
                                <button
                                    className='btn mr-2 mb-3 btn-primary'
                                    onClick={() => registroCuenta(getNivel())}
                                >
                                    Guardar
                                </button>
                                <button
                                    className='btn mr-2 mb-3 btn-dark'
                                    onClick={reporteCuentas}
                                >
                                    Generar reporte
                                </button>
                                <button
                                    className='btn mr-2 mb-3 btn-secondary'
                                    onClick={() => cambiarEstadoModalCuenta(false)}
                                >
                                    Cancelar
                                </button>
                            </div>
                        </Contenido>
                    </LoadMask>
                </div>
            </ModalCuenta>
        </React.Fragment>
    )

}
FacturacionFormTab.propTypes = {
    handleSubmit: PropTypes.func,
    crear: PropTypes.bool,
    lectura: PropTypes.object,
    tipos: PropTypes.array,
    listarClientes: PropTypes.func,
    loader: PropTypes.bool,
    detalle: PropTypes.array,
    eliminarLinea: PropTypes.func,
    listarCuentas: PropTypes.func,
    listarCC: PropTypes.func,
    nivel: PropTypes.object,
    registroCuenta: PropTypes.func,
    reporteCuentas: PropTypes.func,
    loaderC: PropTypes.bool,
    prov: PropTypes.array,
    registroFactura: PropTypes.func,
    modificarFactura: PropTypes.func,
    registroPolizaDetalle: PropTypes.func,
    confirmarPoliza: PropTypes.func,
    registroPolizaDetalleSCC: PropTypes.func,
    leerEstablecimiento: PropTypes.func,
    registroCliente: PropTypes.func,
    listarImpuestos: PropTypes.func,
    impuesto: PropTypes.object,
    parametrosEmpresa: PropTypes.func,
    periodoD: PropTypes.object,
    modulo: PropTypes.object,
    datos: PropTypes.object,
    listarImpuestos: PropTypes.func,
};

export default reduxForm({
    form: 'FacturacionFormTabs', //identificador unico
})(FacturacionFormTab)

const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;