import React, { Component } from 'react';
import Formulario from './RegistroCoempaqueForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";

class RegistroCoempaque extends Component{
    state = {
        crear: true,
    }

    componentDidMount = () => {
        const { match, leer, getModulo, getPais, listarLicencias, listarVias,
            listarAcondicionadores, listarFabricante, listarProductos,
            listarPaisesOrigen, listarTiposRegistro, listarELZM,
            listarRegistrosSanitarios, limpiarDatos
        } = this.props;
        const id = match.params.id;
        if (id){
            this.setState({crear: false});
            leer(id);
            listarProductos();
            listarFabricante();
            listarAcondicionadores();
            listarELZM();
        }
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        getModulo(modulo[5],modulo[4])
        getPais(modulo[7]);
        listarVias();
        listarELZM();
        listarPaisesOrigen();
        listarLicencias();
        listarTiposRegistro();
        listarRegistrosSanitarios();
        limpiarDatos();
        localStorage.removeItem('id_detalle_registro_co_rs');
    }
    
    render(){
        const { registroRC, modificarRC, loader, modulo, listaPro, paisN,
            listaAcond, lectura, listaFab, listarRegistrosSanitarios,
            listaCom, listaPaisOrigen, listaVia, lic, tipoRegistro,
            codigoPais, datoRegistro, listRegistroProducto, descripcionProducto,
            buscarProductos, buscarFabricante, buscarAcondicionador,
            registroProducto, listaELZM, agregarLinea, detallePro, eliminarLinea,
            detalleRegistro, agregarLineaRegistro, eliminarLineaRegistro } = this.props;
        const { crear } = this.state;
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    crear = {crear}
                    modulo = {modulo}
                    obtenerProductos = {listaPro}
                    obtenerFabricantes = {listaFab}
                    obtenerAcondicionadores = {listaAcond}
                    lectura = {lectura}
                    registroRS = {registroRC}
                    modificarRS = {modificarRC}
                    paisN = {paisN}
                    obtenerComercializador = {listaCom}
                    obtenerPaises = {listaPaisOrigen}
                    obtenerLicencias = {lic}
                    obtenerVias = {listaVia}
                    obtenerTipoRegistro = {tipoRegistro}
                    codigoPais = {codigoPais}
                    datoRegistro = {datoRegistro}
                    listRegistroProducto = {listRegistroProducto}
                    descripcionProducto = {descripcionProducto}
                    buscarProductos = {buscarProductos}
                    buscarFabricante = {buscarFabricante}
                    buscarAcondicionador = {buscarAcondicionador}
                    registroProducto = {registroProducto}
                    listaELZM = {listaELZM}
                    listarRegistrosSanitarios = {listarRegistrosSanitarios}
                    agregarLinea = {agregarLinea}
                    detallePro = {detallePro}
                    eliminarLinea = {eliminarLinea}
                    detalleRegistro = {detalleRegistro}
                    agregarLineaRegistro = {agregarLineaRegistro}
                    eliminarLineaRegistro = {eliminarLineaRegistro}
                />
            </LoadMask>
        );
    }
}


export default RegistroCoempaque;
