import React, { Component } from 'react';
import Formulario from './FormSeleccionarPeriodo';


class SeleccionarPeriodo extends Component{

    render(){
        
        return(
            <Formulario />
           
        );
    } 
}

export default SeleccionarPeriodo;