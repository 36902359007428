import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';
import moment from 'moment';

const LISTADO_RECIBO = 'LISTADO_RECIBO';
const LOADER = 'LOADER';
const MODULO = 'MODULO';
const PERIODO_D = 'PERIODO_D';
const NUMERO_SERIE = 'NUMERO_SERIE';
const PARAMETRO = 'PARAMETRO';
const CLIENTE = 'CLIENTE';
const DETALLE = 'DEATALLE';
const MONEDA = 'MONEDA';
const DOCUMENTOS = 'DOCUMENTOS';
const LISTADO_DOCUMENTOS = 'LISTADO_DOCUMENTOS';
const DETALLE_DOCUMENTOS = 'DETALLE_DOCUMENTOS';
const LECTURA_RECIBO = 'LECTURA_RECIBO';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setLoaderC = loader => ({
    type: LOADER,
    loader,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let periodo = id_emp[7];
    const params = { id, periodo };
    api.get('/recibo_caja_prd', params).then((response)=>{
        dispatch({ type: LISTADO_RECIBO, data: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar recibos de caja',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const leerDetalleRecibo = id => (dispatch) => {
    api.get(`/recibo_caja_detalle_prd/${id}`).then((response)=>{
        dispatch({ type: DETALLE_DOCUMENTOS, detalleDocumentos: response });
    }).catch((error)=>{
        NotificationManager.error(
            error.body.msj,
            'Error',
            6000
        );
        dispatch({ type: DETALLE_DOCUMENTOS, detalleDocumentos: [] });;
    })
};


export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/recibo_caja_prd/${id}`).then((response) => {
        response.fechaRegistro = response.fecha;
        dispatch({ type: LECTURA_RECIBO, lectura: response });
        dispatch(initializeForm('FormReciboCajaProduccion', response ));
        dispatch(leerDetalleRecibo(response.id));
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar recibo de caja',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const leerPeriodoD = (id, id_periodo) => (dispatch) => {
    const params = { id, id_periodo };
    api.get('/periodos/periodo', params).then((response) => {
        dispatch({ type: PERIODO_D, periodoD: response.periodo });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar periodo',
            'Error',
            2000
        );
    })
}

export const registroRecibo = (periodoD, numeroS) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormReciboCajaProduccion.values;
    
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fechaRegistro)
    if (fecha>=fechaI && fecha<=fechaF){
        const formData = {
            fecha: data.fechaRegistro,
            serie: data.serie.label,
            numero: numeroS,    
            observaciones: data.observaciones,
            idCliente: data.cliente_documento.value,
            detalleRecibo: data.detalleD,
            tipoPago : data.tipo_pago,
            total : data.totalAbono,
            empresa: id_emp[5],
            periodo: id_emp[7]
        }
        dispatch(setLoader(true));
        api.post('/recibo_caja_prd/', formData).then((response) => {
            NotificationManager.success(
                'Recibo de caja guardado correctamente',
                'Exito',
                3000
            );
            Swal.fire({
                title: '¿Imprimir?',
                text: '¡Desea imprimir recibo de caja!',
                type: 'question',
                showCancelButton: true,
                confirmButtonText: '¡Sí, imprimir!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    dispatch(imprimirRecibo(response.id));
                    let ruta = `/produccion/${id_emp[5]}/recibo_caja/${id_emp[7]}`
                    dispatch(push(ruta))
                }else {
                    window.location.reload(true);
                }
            });
        }).catch((error)=>{
            NotificationManager.error(
                error.msj,
                'Error',
                8000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }else {
        Swal.fire({
            title: 'Recibo de caja',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })        
    }
}


export const parametrosEmpresa = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id}
    dispatch(setLoader(true));
    api.get('/empresa/empresa_parametros', params).then((response) => {
        dispatch({type: PARAMETRO, datos: response });
    }).catch((error)=>{
        
        NotificationManager.error(
            'Ocurrió un error al consultar parametros',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const anular = (id) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let idE = id_emp[5];
    let idRecibo = id;
    const params = { idE, idRecibo };
    dispatch(setLoader(true));
    api.put('/recibo_caja_prd/anular/', params).then(() => {
        NotificationManager.success(
            'Recibo de caja anulado correctamente',
            'Exito',
            3000
        );
        dispatch(listar());
    }).catch((error) => {

        if (error.abono) {
            Swal.fire({
                title: 'Recibo de caja',
                text: error.abono,
                type: 'error',
            })    
        }else{
            NotificationManager.error(
                'Ocurrió un error al anular recibo de caja',
                'Error',
                6000
            );
        }

    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const imprimirRecibo = id => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let idE = id_emp[5];
    let idRecibo = id
    let reporte = 'imprimirRecibo';
    const params = { idE, reporte, idRecibo };
    dispatch(setLoader(true));
    api.getPdf('/recibo_caja_prd/', params).then((response) => {
        if (response.headers['content-type'] == 'application/pdf') {
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'recibo_caja.pdf';
            window.open(link, '_blank');
            return false;
        }
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al generar reporte',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const numeroSerie = (id) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let idEmpre = id_emp[5];
    let tipo = 'RECIBO';
    const params = { id, idEmpre, tipo }
    dispatch(setLoaderC(true));
    return api.get('/recibo_caja_prd/serie/', params).then((response) => {
        return response;
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar numero de serie',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoaderC(false));
    });

}

export const listarSeries = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let tipo = 'RECIBO';
    const params = { id, search, tipo }
    return api.get('/serie_prd', params).then(data => {
        const list_serie = [];
        if (data) {
            data.forEach(item => {
                list_serie.push({
                    value: item.id,
                    label: item.serie
                })
            })
        }
        return list_serie;
    }).catch((error) => {
        return [];
    })
}

const vaciar = () => (dispatch) => {
    detalle = [];
    dispatch(initializeForm('FormReciboCajaProduccion'));
    dispatch({ type: LISTADO_DOCUMENTOS, doc: detalle });
    dispatch({ type: DETALLE, detalleDocN: detalle });
};

export const listarClientes = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/cliente/empresa_clientes', params).then(data => {
        const list_cliente = [];
        if (data) {
            data.forEach(item => {
                list_cliente.push({
                    value: item.id,
                    label: item.identificador + ' ' + item.nombre
                })
            })
        }
        return list_cliente;
    }).catch((error) => {

        return [];
    })
}

export const leerDocumentos = (id, fecha_fin, moneda) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let idEmpre = id_emp[5];
    let fechaF = fecha_fin;
    let tipoM = moneda
    let tipo = 'abono';
    const params = { id, idEmpre, fechaF, tipo, tipoM }
    dispatch(setLoaderC(true));
    api.get('/ingresos/documentos_cliente/', params).then((response) => {
        const saldos = response.detalle.filter((item) => parseFloat(item.total) >= 0.01)
        dispatch({ type: LISTADO_DOCUMENTOS, doc: saldos });
        const documento = [];
        response.detalle.forEach(item => {
            if (parseFloat(item.total) >= 0.01) {
                documento.push({
                    value: item.id + ' # ' + item.total + ' # ' + item.tipo + ' # ' + item.fecha + ' # ' + item.serie + ' # ' + item.numero,
                    label: item.serie + ' / ' + item.numero + ' -- ' + ' Saldo: ' + new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(item.total),
                })
            }
        })
        dispatch({ type: DOCUMENTOS, list_doc: documento })
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar documentos del cliente',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoaderC(false));
    });

}

export const listarMonedas = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    api.get('/monedas/moneda_empresa', params).then((response) => {
        let monedaD = "";
        response.forEach(item => {
            if (item.cambio === 'N'){
                monedaD = item.id
            }
        })
        dispatch({ type: MONEDA, moneda: monedaD });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar monedas',
            'Error',
            3000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const actualizar = () => (dispatch, getStore) => {
    const data = getStore().form.FormReciboCajaProduccion.values;
    dispatch(initializeForm('FormReciboCajaProduccion', data));
    dispatch({ type: DETALLE, detalleDocN: data.detalleD });
};

let detalle = [];
export const agregarLinea = (periodoD) => (dispatch, getStore) => {
    const data = getStore().form.FormReciboCajaProduccion.values;

    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fechaRegistro);

    if (fecha >= fechaI && fecha <= fechaF) {
        let docu = data.documento.value;
        let documento = docu.split('#');
        
        let saldo = parseFloat(documento[1].trim());
        let abono = parseFloat(data.abono);
        let totAbono = 0.00;
        if (data.monto === undefined) {
            totAbono = 0.00
        } else {
            totAbono = parseFloat(data.monto);
        }
        //let GtotalAbono = parseFloat(data.totalDetalleAbono)+parseFloat(data.abono);
        let dif = parseFloat(data.diferencia);
        let totDoc = 0.00;

        let cli = data.cliente_documento;
        if (cli === undefined) {
            cli = data.cliente;
        } else {
            cli = data.cliente_documento.value;
        }
        if (totAbono === 0.00) {
            Swal.fire({
                title: 'Recibo de caja',
                text: 'Monto del documento no puede ser 0.00',
                type: 'error',
            })
        } else {
            if (abono > totAbono) {
                Swal.fire({
                    title: 'Recibo de caja',
                    text: 'El abono no puede ser mayor al monto del documento',
                    type: 'error',
                })
            } else {
                if (abono === 0 || data.abono === undefined) {
                    Swal.fire({
                        title: 'Recibo de caja',
                        text: 'El abono no puede ser 0.00',
                        type: 'error',
                    })
                } else {
                    if (abono > saldo) {
                        Swal.fire({
                            title: 'Recibo de caja',
                            text: 'El abono es mayor al saldo del documento',
                            type: 'error',
                        })
                    } else {
                        if (detalle.length > 0) {
                            const dato = detalle.find(item => item.id === documento[0].trim());

                            if (dato) {
                                NotificationManager.error(
                                    'Documento ya fue ingresado',
                                    'Error',
                                    3000
                                );
                            } else {
                                if (abono > dif) {
                                    Swal.fire({
                                        title: 'Recibo de caja',
                                        text: 'El abono es mayor a la diferencia',
                                        type: 'error',
                                    })
                                } else {
                                    detalle.push({
                                        id: documento[0].trim(),
                                        td: documento[2].trim(),
                                        abono: data.abono,
                                        fecha: documento[3].trim(),
                                        serie: documento[4].trim(),
                                        numero: documento[5].trim(),
                                        monto: documento[1].trim(),
                                        saldo: parseFloat(documento[1] - parseFloat(data.abono)).toFixed(2)
                                    })
                                    detalle.forEach(item => totDoc += parseFloat(item.abono));
                                    data.totalAbono = parseFloat(totDoc);
                                    data.diferencia = parseFloat(parseFloat(totAbono - parseFloat(totDoc))).toFixed(2);
                                    data.detalleD = detalle;
                                    data.abono = 0;
                                    NotificationManager.success(
                                        'Documento ingresado correctamente',
                                        'Exito',
                                        3000
                                    );
                                    dispatch(actualizar());
                                }
                            }
                        } else {
                            detalle.push({
                                id: documento[0].trim(),
                                td: documento[2].trim(),
                                abono: data.abono,
                                fecha: documento[3].trim(),
                                serie: documento[4].trim(),
                                numero: documento[5].trim(),
                                monto: documento[1].trim(),
                                saldo: parseFloat(documento[1] - parseFloat(data.abono)).toFixed(2)
                            })
                            detalle.forEach(item => totDoc += parseFloat(item.abono));
                            data.totalAbono = parseFloat(totDoc);
                            data.diferencia = parseFloat(parseFloat(totAbono - parseFloat(totDoc))).toFixed(2);
                            data.detalleD = detalle;
                            data.abono = 0;
                            NotificationManager.success(
                                'Documento ingresado correctamente',
                                'Exito',
                                3000
                            );
                            dispatch(actualizar());
                        }

                    }
                }
            }
        }
    } else {
        Swal.fire({
            title: 'Recibo de caja',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })
    }
}

const eliminarLineaAbonoNuevo = (id) => (dispatch, getStore) => {
    const data = getStore().form.FormReciboCajaProduccion.values;
    let totAbono = parseFloat(data.monto);
    let totDoc = 0.00;

    detalle.forEach((element, index) => {
        if (element.id === id) {
            detalle.splice(index, 1);
        }
    })
    detalle.forEach(item => totDoc += parseFloat(item.abono));
    data.totalAbono = parseFloat(totDoc);
    data.diferencia = parseFloat(totAbono - parseFloat(totDoc));
    data.detalleD = detalle;
    dispatch(actualizar());
};

export const actions = {
    listar,
    leer,
    getModulo,
    leerPeriodoD,
    registroRecibo,
    parametrosEmpresa,
    anular,
    imprimirRecibo,
    numeroSerie,
    listarSeries,
    vaciar,
    listarClientes,
    leerDocumentos,
    listarMonedas,
    agregarLinea,
    eliminarLineaAbonoNuevo
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_RECIBO]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [PERIODO_D]: (state, { periodoD }) => {
        return {
            ...state,
            periodoD,
        };
    },
    [PARAMETRO]: (state, { datos }) => {
        return {
            ...state,
            datos,
        };
    },
    [CLIENTE]: (state, { cli }) => {
        return {
            ...state,
            cli,
        };
    },
    [NUMERO_SERIE]: (state, { numeroS }) => {
        return {
            ...state,
            numeroS,
        };
    },
    [MONEDA]: (state, { moneda }) => {
        return {
            ...state,
            moneda,
        };
    },
    [DOCUMENTOS]: (state, { list_doc }) => {
        return {
            ...state,
            list_doc,
        };
    },
    [LISTADO_DOCUMENTOS]: (state, { doc }) => {
        return {
            ...state,
            doc,
        };
    },
    [DETALLE]: (state, { detalleDocN }) => {
        return {
            ...state,
            detalleDocN,
        };
    },
    [DETALLE_DOCUMENTOS]: (state, { detalleDocumentos }) => {
        return {
            ...state,
            detalleDocumentos,
        };
    },
    [LECTURA_RECIBO]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
};

export const initialState = {
    loader: false,
    lectura: [],
    modulo: [],
    periodoD: [],
    cli: [],
    numeroS: [],
    datos: [],
    data: [],
    page: 1,
    monedas: [],
    lis_doc: [],
    doc: [],
    detalleDocN: [],
    detalleDocumentos: [],
    lectura: [],
};

export default handleActions(reducers, initialState)

    