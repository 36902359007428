import React, { Component } from 'react';
import Formulario from './NotaCobroForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";

class NotaCobro extends Component{
    state = {
        crear: true,
    }

    componentDidMount() {
        const { match, leer, getModulo, leerPeriodoD, parametrosEmpresa, vaciar } = this.props;
        const id = match.params.id;
        if (id){
            this.setState({ crear: false });
            leer(id);
        }
        let ruta = window.location.href;
        let periodo = ruta.split('/');
        leerPeriodoD(periodo[5], periodo[7]);
        getModulo(periodo[5],periodo[4]);
        parametrosEmpresa();
        vaciar();
    }
    render(){
        const { registroNota, numeroSerie, nota,  listarClientes,
            loader, lectura, modulo, periodoD, detalleProducto, cli,
            listarEnvios, editarLinea, actualizar, listarSeries,
            datos, detalleEnvio, detalleEnv, tipoCambio, tipoEnvio} = this.props;
        const { crear } = this.state;
               
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    registroNota = {registroNota}
                    crear = {crear}
                    lectura = {lectura}
                    modulo = {modulo}
                    periodoD = {periodoD}
                    detalleProducto = {detalleProducto}
                    listarEnvios = {listarEnvios}
                    datos = {datos}
                    detalleEnvio = {detalleEnvio}
                    cli = {cli}
                    detalleEnv = {detalleEnv}
                    editarLinea={editarLinea}
                    actualizar={actualizar}
                    numeroSerie={numeroSerie}
                    listarSeries={listarSeries}
                    tipoCambio = {tipoCambio}
                    nota = {nota}
                    listarClientes = {listarClientes}
                    tipoEnvio = {tipoEnvio}
                />
           </LoadMask>
        );
    }
}

export default NotaCobro;