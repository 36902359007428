import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/auto_ventas/ingresos/ingresos';
import Ingreso from './Ingresos';


const ms2p = (state) => {
  return {
    ...state.auto_ventas_ingresos,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p,)(Ingreso);
