import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from '../../components/Modal';
import PropTypes from 'prop-types';
const valEmp = __CONFIG__.empresa;

function FormSeleccionarPais(props) {
    const {
        pais
    } = props;

    
    const [id, setId] = useState("");
       
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    
    const handleChange = e => {
        setId(e.target.value);
    }
    
    const [estadoModal, cambiarEstadoModal] = useState(true);
    

    return (
        <div className='container'>
            <Modal
                estado={estadoModal}
                cambiarEstado={cambiarEstadoModal}
                titulo="Seleccionar pais"
                botonCerrar="false"
            >
                {pais && 
                    <div className="form-floating">
                        <select className="form-select" id="pais"
                            value={id} onChange={handleChange} >
                            <option selected>Seleccionar...</option>
                            {pais.map(mes =>
                                <option key={mes.value} value={mes.value}>{mes.label}</option>)
                            }

                        </select>
                        <label className='form-label' htmlfor="periodo">Pais</label>
                    </div>
                }
                <br />
                {valEmp === 'fage' &&
                    <Contenido>
                        {id &&
                            <a
                                href={`/#/lfg/rr_hh/${id_emp[6]}/ingreso/${id}`}
                                className='btn mr-2 mb-3 btn-primary'
                            >
                                Seleccionar
                            </a>
                        }
                    </Contenido>
                }
                {valEmp === '' &&
                    <Contenido>
                        {id &&
                            <a
                                href={`/#/rr_hh/${id_emp[5]}/ingreso/${id}`}
                                className='btn mr-2 mb-3 btn-primary'
                            >
                                Seleccionar
                           </a>
                        }
                    </Contenido>
                }
            </Modal>
        </div>
    );

}
FormSeleccionarPais.propTypes = {
    pais: PropTypes.string.isRequired,
};

export default (FormSeleccionarPais);

const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;