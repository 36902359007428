import React from "react";
import { useButton } from "react-aria";
import { useRef } from "react";
import {
    Button,
    OverlayArrow,
    Tooltip,
    TooltipTrigger,
} from "react-aria-components";
import styled, { keyframes } from "styled-components";

const slideInAnimation = keyframes`
      
      from {
        transform: var(--origin);
        opacity: 0;
      }

      to {
        transform: translateY(0);
        opacity: 1;
      }
    `;
const StyledToolTip = styled(Tooltip)`
    --highlight-background: #92032b;
    --highlight-foreground: whitesmoke;
    box-shadow: 0 8px 20px rgba(0 0 0 / 0.1);
    border-radius: 4px;
    background: var(--highlight-background);
    /* backdrop-filter: blur(8px); */
    color: var(--highlight-foreground);
    forced-color-adjust: none;
    outline: none;
    padding: 2px 8px;
    max-width: 150px;
    border: 1px solid #121212;
    transform: translate3d(0, 0, 0);

    &[data-placement="top"] {
        margin-bottom: 8px;
        --origin: translateY(4px);
    }

    &[data-placement="bottom"] {
        margin-top: 8px;
        --origin: translateY(-4px);
        & .react-aria-OverlayArrow svg {
            transform: rotate(180deg);
        }
    }

    &[data-placement="right"] {
        margin-left: 8px;
        --origin: translateX(-4px);
        & .react-aria-OverlayArrow svg {
            transform: rotate(90deg);
        }
    }

    &[data-placement="left"] {
        margin-right: 8px;
        --origin: translateX(4px);
        & .react-aria-OverlayArrow svg {
            transform: rotate(-90deg);
        }
    }

    & .react-aria-OverlayArrow svg {
        display: block;
        stroke: #121212;
        fill: var(--highlight-background);
    }

    &[data-entering] {
        animation: ${slideInAnimation} 200ms;
    }

    &[data-exiting] {
        animation: ${slideInAnimation} 200ms reverse ease-in;
    }
`;

export default function CustomToolTip({ children, ...props }) {
    return (
        <StyledToolTip {...props}>
            <OverlayArrow>
                <svg width={8} height={8} viewBox="0 0 8 8">
                    <path d="M0 0 L4 4 L8 0" />
                </svg>
            </OverlayArrow>
            {children}
        </StyledToolTip>
    );
}
