import React, { Component } from 'react';
import Formulario from './PeriodoForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";
const valEmp = __CONFIG__.empresa;

class Periodo extends Component{
    state = {
        crear: true,
    }

    componentDidMount = () => {
        const { match, getModulo, listarFrecuencia, leerPeriodoD, leer } = this.props;
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        const id = match.params.id;
        if (id){
            this.setState({crear: false});
            leer(id);
            if (valEmp === 'fage'){
                getModulo(modulo[6], modulo[5])
            }else{
                getModulo(modulo[5],modulo[4]);
            }
            listarFrecuencia();
        }else{
            if (valEmp === 'fage'){
                getModulo(modulo[6], modulo[5])
                leerPeriodoD(modulo[6], modulo[9]);
            }else{
                getModulo(modulo[5],modulo[4]);
                leerPeriodoD(modulo[5],modulo[8]);
            }
            listarFrecuencia();
        }
        
    }

    render(){
        const { loader, modulo, data, frec, periodoD, periodoN, numeroPer, 
            registroPeriodo, lectura, modificarPeriodo } = this.props;
        const { crear } = this.state;
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    crear = {crear} 
                    registroPeriodo = {registroPeriodo}
                    modulo = {modulo}
                    data = {data}
                    frec = {frec}
                    periodoD = {periodoD}
                    periodoN = {periodoN}
                    numeroPer = {numeroPer}
                    lectura = {lectura}
                    modificarPeriodo = {modificarPeriodo}
                />
            </LoadMask>
        );
    }
}

export default Periodo;