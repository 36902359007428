import React, {
    useState,
    createContext,
    useContext,
    forwardRef,
    useImperativeHandle,
} from "react";
import { useEffect } from "react";

const SaldoContext = createContext();

export const SaldoProvider = forwardRef(({ saldoInicial, children }, ref) => {
    const [saldoDisponible, setSaldoDisponible] = useState(saldoInicial);

    useEffect(() => {
        setSaldoDisponible(saldoInicial);
    }, [saldoInicial]);

    useImperativeHandle(ref, () => ({
        resetSaldo: () => setSaldoDisponible(saldoInicial),
    }));

    return (
        <SaldoContext.Provider value={{ saldoDisponible, setSaldoDisponible }}>
            {children}
        </SaldoContext.Provider>
    );
});

export function useSaldo() {
    const context = useContext(SaldoContext);
    if (!context) {
        throw new Error("useMonto debe ser usado dentro de un MontoProvider");
    }
    return context;
}
