import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import { validate, validators } from 'validate-redux-form';
import PropTypes from 'prop-types';
import NavbarModulo from '../../components/NavbarModulo';
import {
    renderField,
} from "../../Utils/renderField/renderField";

function TipoObraForm(props) {
    const { handleSubmit, crear, modulo } = props;
    const editar = window.location.href.includes('editar');
    let titulo = editar ? 'Modificar tipo de obra' : 'Crear tipo de obra';
    let disabled = false;
    if (crear === false && editar === false) {
        disabled = true;
        titulo = 'Ver tipo de obra'
    }
    let autoFocus = true;
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    return (
        <div>
            <NavbarModulo
                modulo={modulo}
                titulo={titulo}
            />
            <br />
            <div className="mb-4 card card-small text-white bg-dark mb-3">
                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                    <div className="d-flex flex-column flex-1 mx-4">
                        <div className='mb-2'>
                            
                            <div className='mb-2 w-50'>
                                <Field
                                    autoFocus={autoFocus}
                                    name="descripcion"
                                    placeholder="Tipo de obra"
                                    className="form-control"
                                    component={renderField}
                                    disabled={disabled}
                                    msj="Tipo de obra"
                                />
                            </div>

                            <div className='d-flex flex-row mt-3'>
                                {disabled == false &&
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={handleSubmit}
                                    >
                                        {editar ? 'Modificar' : 'Guardar'}
                                    </button>
                                }
                                <a
                                    href={`/#/inmobiliaria/${id_emp[5]}/tipo_obra`}
                                    className='btn btn-secondary mb-3'
                                >
                                    Regresar
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

TipoObraForm.propTypes = {
    handleSubmit: PropTypes.func,
    crear: PropTypes.bool,
    lectura: PropTypes.object,
    modulo: PropTypes.object
};

export default reduxForm({
    form: 'FormTipoObra',
    validate: (data) => {
        return validate(data, {
            descripcion: validators.exists()('Este campo es requerido'),
        });
    },
})(TipoObraForm);
