import React, { useState, useRef, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import {
    NavBarMenuComponent,
    NavBarMenuItemComp,
    SeparatorComponent,
} from "../../Utils/Components/NavBarMenu";

const MenuItems = ({ items }) => {
    const SidebarLink = styled(NavLink)`
        position: relative;
        display: flex;
        align-items: center;
        font-size: large;
        gap: 0.25rem;
        border: none;
        /* border-radius: 6px; */
        background-color: #121212;
        padding: 0.571rem 1.143rem;
        color: #fff;
        ::after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #bd0034;
            height: 2px;
            width: 0;
            transition: width 150ms ease-in-out;
            margin: 0 auto;
        }
        &.active {
            color: #bd0034 !important;
        }

        &:hover {
            ::after {
                width: 100%;
            }

            color: #bd0034;
        }
    `;

    const StyledLink = styled(NavLink)`
        padding: 0.286rem 0.571rem;
        /* border-radius: 6px; */
        outline: none;
        cursor: default;
        color: #fff !important;
        font-size: 1.072rem;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        /* column-gap: 20px; */
        forced-color-adjust: none;
        width: 100%;
        height: 100%;

        &.active {
            color: #bd0034 !important;
        }
    `;

    const renderSubMenuItems = (items, id_emp) => {
        return items.subNav.map((submenu, index) => (
            <NavBarMenuItemComp
                key={index}
                // href={`/#${submenu.path}/${id_emp[5]}/${submenu.path_s}`}
            >
                <StyledLink to={`/${id_emp[4]}/${id_emp[5]}/${submenu.path_s}`}>
                    {submenu.icon}
                    <SeparatorComponent orientation="vertical" />
                    {submenu.title}
                </StyledLink>
            </NavBarMenuItemComp>
        ));
    };

    const renderMenuItem = () => {
        const ruta = window.location.href;
        const id_emp = ruta.split("/");
        const path = items.path.split("/");
        const u = path[2] === undefined ? "" : path[2];
        const nueva_ruta = `/${id_emp[4]}/${id_emp[5]}/${u}`;

        if (items.subNav) {
            const label = (
                <React.Fragment>
                    {items.icon}
                    <SeparatorComponent orientation="vertical" />
                    {items.title}
                </React.Fragment>
            );
            return (
                <NavBarMenuComponent label={label} withOpenIndicator>
                    {renderSubMenuItems(items, id_emp)}
                </NavBarMenuComponent>
            );
        } else {
            return (
                <SidebarLink to={nueva_ruta} exact>
                    <React.Fragment>
                        {items.icon}
                        <SeparatorComponent orientation="vertical" />
                        {items.title}
                    </React.Fragment>
                </SidebarLink>
            );
        }
    };

    return renderMenuItem(items);
};

export default MenuItems;
