
import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';
import moment from 'moment';

const LISTADO_ORDEN = 'LISTADO_ORDEN';
const LOADER = 'LOADER';
const MODULO = 'MODULO';
const PERIODO_D = 'PERIODOD';
const PARAMETRO = 'PARAMETRO';
const DETALLE = 'DETALLE';
const DETALLE_ORDEN = 'DETALLE_ORDEN';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let id_period = id_emp[7];
    const params = { id, id_period };
    dispatch(setLoader(true));
    api.get('/orden_servicio', params).then((response) => {
        dispatch({ type: LISTADO_ORDEN, data: response });
    
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar ordenes de servicio',
            'Error',
            3000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}


export const leerPeriodoD = (id, id_periodo) => (dispatch) => {
    const params = { id, id_periodo };
    api.get('/periodos/periodo', params).then((response) => {
        dispatch({ type: PERIODO_D, periodoD: response.periodo });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar periodo',
            'Error',
            2000
        );
    })
}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        if (error.status === 401){
            NotificationManager.error(
              error.body.msj,
              'Error',
              0
            );
        }
        if (error.status === 400){
            NotificationManager.error(
              'Ocurrió un error en buscar modulo',
              'Error',
              0
            );
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


export const parametrosEmpresa = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id }
    dispatch(setLoader(true));
    api.get('/empresa/empresa_parametros', params).then((response) => {
        dispatch({ type: PARAMETRO, datos: response });
    }).catch((error) => {

        NotificationManager.error(
            'Ocurrió un error al consultar parametros',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const listarClientes = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id, search}
    return api.get('/cliente/empresa_clientes',params).then(data=>{
        const list_cliente = [];
        if(data){
            data.forEach(item=>{
                list_cliente.push({
                    value: item.id,
                    label: item.identificador + ' ' + item.nombre
                })
            })
        }
        return list_cliente ;
    }).catch((error)=>{
        
        return [];
    })
}

let detalle = [];
const limpiarOrden = () => (dispatch) => {
    detalle = [];
    dispatch({ type: DETALLE, detalleOrden: detalle })
    dispatch({type: PRODUCTOS, pro: [] })
};


export const registroOrden = (periodoD) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormOrdenServicioINV.values;
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fecha)
    if (fecha>=fechaI && fecha<=fechaF){
        if (data.nombre_proyecto === undefined || data.nombre_proyecto === null) {
            Swal.fire({
                title: 'Orden de servicio',
                text: 'Debe de ingresar nombre de proyecto',
                type: 'error',
            })
        } else {
            if (data.cliente === undefined || data.cliente === null) {
                Swal.fire({
                    title: 'Orden de servicio',
                    text: 'Debe de selecionar un cliente',
                    type: 'error',
                })
            }else{
                const formData = {
                    fecha: data.fecha,
                    nombre_proyecto: data.nombre_proyecto,
                    observaciones: data.observaciones,
                    cliente: data.cliente.value,
                    empresa: id_emp[5],
                    periodo: id_emp[7]
                }
                dispatch(setLoader(true));
                api.post('/orden_servicio/', formData).then(() => {
                    NotificationManager.success(
                        'Orden de servicio guardada correctamente',
                        'Exito',
                        3000
                    );
                    let ruta = `/inventario/${id_emp[5]}/orden_servicio/${id_emp[7]}`
                    dispatch(push(ruta));
                }).catch((error) => {
                    NotificationManager.error(
                        error.msj,
                        'Error',
                        0
                    );
                }).finally(() => {
                    dispatch(setLoader(false));
                });
            }
        }
    }else {
        Swal.fire({
            title: 'Orden de servicio',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })    
    }
}

const leerDetalleOrden = id => (dispatch) => {
    api.get(`/orden_servicio_detalle/${id}`).then((response) => {
        dispatch({ type: DETALLE_ORDEN, detalleOrd: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/orden_servicio/${id}`).then((response) => {
        dispatch(leerDetalleOrden(response.id));
        dispatch(initializeForm('FormOrdenServicioINV', response));
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar orden de servicio',
            'Error',
            3000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const modificarOrden = (periodoD) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormOrdenServicioINV.values;
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fecha)
    let id = data.id;
    if (fecha>=fechaI && fecha<=fechaF){
        if (data.nombre_proyecto === undefined || data.nombre_proyecto === null) {
            Swal.fire({
                title: 'Orden de servicio',
                text: 'Debe de ingresar nombre de proyecto',
                type: 'error',
            })
        } else {
            if (data.cliente === undefined || data.cliente === null) {
                Swal.fire({
                    title: 'Orden de servicio',
                    text: 'Debe de selecionar un cliente',
                    type: 'error',
                })
            }else{
                let clientePro = data.cliente_proyecto;
                if (clientePro === undefined){
                    clientePro = data.cliente;
                }else{
                    clientePro = data.cliente_proyecto.value;
                }
                const formData = {
                    id: id,
                    fecha: data.fecha,
                    nombre_proyecto: data.nombre_proyecto,
                    observaciones: data.observaciones,
                    cliente: clientePro,
                }
                dispatch(setLoader(true));
                api.put(`/orden_servicio/${id}/`, formData).then(() => {
                    NotificationManager.success(
                        'Orden de servicio modificada correctamente',
                        'Exito',
                        3000
                    );
                    let ruta = `/inventario/${id_emp[5]}/orden_servicio/${id_emp[7]}`
                    dispatch(push(ruta));
                }).catch((error) => {
                    NotificationManager.error(
                        error.msj,
                        'Error',
                        0
                    );
                }).finally(() => {
                    dispatch(setLoader(false));
                });
            }
        }
    }else {
        Swal.fire({
            title: 'Orden de servicio',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })    
    }
}


export const actions = {
    listar,
    registroOrden,
    modificarOrden,
    leer,
    limpiarOrden,
    listarClientes,
    leerPeriodoD,
    getModulo,
    parametrosEmpresa,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_ORDEN]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [PERIODO_D]: (state, { periodoD }) => {
        return {
            ...state,
            periodoD,
        };
    },
    [PARAMETRO]: (state, { datos }) => {
        return {
            ...state,
            datos,
        };
    },
    [DETALLE]: (state, { detalleOrden }) => {
        return {
            ...state,
            detalleOrden,
        };
    },
    [DETALLE_ORDEN]: (state, { detalleOrd }) => {
        return {
            ...state,
            detalleOrd,
        };
    },
};

export const initialState = {
    loader: false,
    modulo: [],
    periodoD: [],
    data: [],
    detalleOrden: [],
    detalleOrd: [],

};

export default handleActions(reducers, initialState)