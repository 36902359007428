import React, { Component } from 'react';
import Grid from '../../Utils/GridFiltro';
import PropTypes from 'prop-types';
import { standardActions } from '../../Utils/GridProducto/StandardActions';
import { GrDocumentPdf } from 'react-icons/gr';
import { IconContext } from "react-icons";

class ProductoList extends Component{
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: true
    };

    componentDidMount = () => {
        const { listar, page } = this.props;
        listar(page);
    }
    render(){
        const { data, loader, eliminar, reporteProductos } = this.props;
        const estilo1 = {
            backgroundColor: "#ff413d", maxHeight: "35px", maxWidth: "35px"
        };
        let ruta = window.location.href;
        let id_emp = ruta.split('/');
        return(
            <React.Fragment>
                <br />
                <h3 className="p-2 bg-secondary title">Productos</h3>
                <br />
                <div className="d-flex flex-row justify-content-between mb-2">
                    <a
                        href={`/#/inventario/${id_emp[5]}/producto/crear`}
                        className='btn btn-primary'
                    >
                        Crear Producto
                    </a>
                    <button className='btn btn-dark float-right d-flex justify-content-center' onClick={reporteProductos} style={estilo1}
                        data-bs-toggle="tooltip" data-bs-placement="left" title="Generar reporte">
                            <i>
                                <IconContext.Provider value={{size: "1.25rem" }}>
                                    <GrDocumentPdf/>
                                </IconContext.Provider>
                            </i>
                    </button>
                </div>
                {data &&
                    <Grid 
                        hover 
                        striped 
                        data={data} 
                        loading={loader} 
                        headerStyle={ { background: 'black' } } 
                        //onPageChange={onPageChange}
                        //onSortChange={onSortChange} 
                    >
                        <TableHeaderColumn
                            isKey
                            dataField="SKU"
                            width="250"
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            SKU
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="producto"
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Producto
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="tipo"
                            width="150"
                            dataAlign='center'
                            dataSort
                            dataFormat={(cell, row)=>{
                                if (cell === 'P'){
                                    return 'PRODUCTO'
                                }
                                if (cell === 'C'){
                                    return 'COMBO'
                                }
                                if (cell === 'S'){
                                    return 'SERVICIO'
                                }
                            }}
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Tipo
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="imagen"
                            dataAlign='center'
                            width="200"
                            dataSort
                            dataFormat={(cell, row)=>{
                                if (cell === null){
                                    return ''
                                }else{
                                    return (<div><img src={cell} width="50" height="30"/></div>);
                                }
                            }}
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Imagen
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            thStyle={
                            {color: 'white'}}
                            dataField="id"
                            width="200"
                            dataAlign="center"
                            dataSort
                            dataFormat={standardActions({ 
                                editar: "producto", 
                                ver: "producto",
                                combo: 'combo'
                            })} 
                            
                        >
                            Acciones
                        </TableHeaderColumn>
                    </Grid>
                }
            </React.Fragment>
        );
    }
}

export default ProductoList;
