import React, { useEffect, useRef } from "react";
import "../../../../style/Finanzas/FinanzasHome.css";
import styled from "styled-components";
import { FicComboBox } from "../Utils/FormInputComponents";
import { Link } from "react-router-dom";
import { useState } from "react";

const Dialog = styled.dialog`
    /* display: flex;
    flex-direction: column; */
    gap: 0.725rem;
    background-color: #1e1e1e;
    border-radius: 5px;
    border: none;
    padding: 2.5rem 2.5rem;
    /* min-width: 22rem;
    max-width: 80ch; */
    width: clamp(22rem, 100%, 75rem);
    // height: 12rem;
    // height: fit-content;

    align-items: center;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.5);
    overflow: visible;

    &::backdrop {
        background: #121212;
    }
`;

const BackLink = styled(Link)`
    color: #bd0034;
    background-color: hsla(0, 100%, 50%, 0.1);
    border: 1px solid #bd0034;
    border-radius: 5px;
    padding: 0.5rem;
    text-decoration: none;
    margin: 1rem 0 1rem 0;
    min-width: 77.5px;
    width: 35%;
    text-align: center;
    transition: border 300ms ease-in;

    &:hover {
        color: hsl(343, 100%, 37%, 0.5);
        text-decoration: none;
        border: 1px solid hsla(0, 100%, 50%, 0.1);
        transition: border 300ms ease-out;
    }
`;
const AgregarEmpLink = styled(Link)`
    color: hsla(206, 100%, 50%);
    background-color: hsla(206, 100%, 50%, 0.1);
    border: 1px solid hsla(206, 100%, 50%);
    border-radius: 5px;
    padding: 0.5rem;
    text-decoration: none;
    margin: 1rem 0 1rem 0;
    min-width: 77.5px;
    width: 35%;
    text-align: center;
    transition: border 300ms ease-in;

    &:hover {
        text-decoration: none;
        border: 1px solid hsla(206, 100%, 50%, 0.1);
        transition: border 300ms ease-out;
    }
`;
const AgregarEmpLinkSmall = styled(Link)`
    color: hsla(206, 100%, 50%);
    background-color: hsla(206, 100%, 50%, 0.1);
    border: 1px solid hsla(206, 100%, 50%);
    border-radius: 5px;
    font-size: 1rem;
    text-decoration: none;
    width: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: border 300ms ease-in;

    &:hover {
        text-decoration: none;
        border: 1px solid hsla(206, 100%, 50%, 0.1);
        transition: border 300ms ease-out;
    }
`;

const DialogLinks = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    /* margin: 0 0 1rem 0;
    padding: 0 1rem; */
`;

const SelectionDivContainer = styled.div`
    margin: 2rem;
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
`;

function FinanzasHome(props) {
    const { listarEmpresasAgregadasComboBox, history } = props;
    const dialogRef = useRef(null);
    const [modalAbierto, setModalAbierto] = useState(false);
    const [empresa, setEmpresa] = useState({
        value: localStorage.getItem("fnzEmpresaId"),
        label: localStorage.getItem("fnzEmpresaName"),
    });

    useEffect(() => {
        if (!localStorage.getItem("fnzEmpresaId")) {
            dialogRef.current.showModal();
            setModalAbierto(false);
        } else {
            dialogRef.current.close();
            setModalAbierto(false);
        }

        // dialogRef.current.showModal();
        // setTimeout(() => {
        //     dialogRef.current.close();
        // }, 10000);
    }, [empresa]);

    const handleEmpresaSelection = (e) => {
        localStorage.setItem("fnzEmpresaId", e.value);
        localStorage.setItem("fnzEmpresaName", e.label);
        setEmpresa({ value: e.value, label: e.label });
        // if (history.length > 1 && modalAbierto) {
        //     history.goBack();
        // }
    };

    return (
        <div>
            <Dialog ref={dialogRef}>
                <h2 style={{ color: "#bd0034", textAlign: "center" }}>
                    Seleccione una empresa
                </h2>
                <FicComboBox
                    nonForm
                    onChange={handleEmpresaSelection}
                    loadOptions={listarEmpresasAgregadasComboBox}
                    name="empresa"
                    placeholder="Seleccione una empresa"
                    componentStyle={{
                        margin: "1rem",
                    }}
                    isClearable={false}
                />
                <DialogLinks>
                    <BackLink to="/tools/home">Regresar</BackLink>
                    <AgregarEmpLink to="/tools/finanzas/empresas">
                        Agregar empresa
                    </AgregarEmpLink>
                </DialogLinks>
            </Dialog>
            <SelectionDivContainer>
                <FicComboBox
                    // key={empresasSelectKey}
                    nonForm
                    name="empresa"
                    value={empresa}
                    loadOptions={listarEmpresasAgregadasComboBox}
                    onChange={handleEmpresaSelection}
                    placeholder="Seleccionar otra empresa"
                    componentStyle={{ maxWidth: "100ch" }}
                    label="Empresa"
                    isClearable={false}
                />
                {/* <AgregarEmpLinkSmall to="/tools/agregar-empresa">&#x2B;</AgregarEmpLinkSmall> */}
            </SelectionDivContainer>

            <div className="modulo-card-container">
                <div className="modulo-card-container">
                    <Link
                        to="/tools/finanzas/compras-ventas"
                        className="modulo-card"
                    >
                        <div className="modulo-card-contenido">
                            <h3 className="modulo-card-titulo">
                                COMPRAS Y VENTAS
                            </h3>
                            {/* <p className="modulo-card-descripcion">
                                Descripcion
                            </p> */}
                        </div>
                    </Link>
                </div>

                <div className="modulo-card-container">
                    <Link
                        to="/tools/finanzas/estados-financieros"
                        className="modulo-card"
                    >
                        <div className="modulo-card-contenido">
                            <h3 className="modulo-card-titulo">
                                ESTADOS FINANCIEROS
                            </h3>
                            {/* <p className="modulo-card-descripcion">
                                Descripcion
                            </p> */}
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default FinanzasHome;
