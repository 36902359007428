import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Radio } from "react-aria-components";
import {
    FicImageDrop,
    FicComboBox,
    FicInput,
    FicRadio,
    FicCheckbox,
    FicTextArea,
    FicNumber,
} from "../../Utils/FormInputComponents/index";
import { useEffect } from "react";
import "../../../../../style/autoVentas/IngresosForm.css";
import GenBtn from "../../components/GenBtn";
import LoadMask from "../../Utils/LoadMask/LoadMask";
import { withRouter } from "react-router";

const schema = yup.object({
    codigo: yup.string().required("El codigo es requerido"),
    marca: yup.mixed().required("La marca es requerida"),
    linea: yup.mixed().required("La linea es requerida"),
    tipo_carro: yup.mixed().required("El tipo de carro es requerido"),
    combustible: yup.mixed().required("El combustible es requerido"),
    modelo: yup
        .number()
        .typeError("Debe de ser un numero")
        .positive("Debe de ser un numero positivo")
        .test(
            "len",
            "Debe de tener 4 digitos (ej. 2014)",
            (val) => val.toString().length === 4
        )
        .required("El modelo es requerido"),
    numero_serie: yup.string().required("El numero de serie es requerido"),
    placa: yup.string().required("La placa es requerida"),
    motor: yup.string().required("El motor es requerido"),
    millas: yup
        .number()
        .typeError("Debe de ser un numero")
        .required("Las millas son requeridas"),
    num_vin: yup.string().required("El numero de VIN es requerido"),
    num_chasis: yup.string().required("El numero de chasis es requerido"),
    num_motor: yup.string().required("El numero de motor es requerido"),
    color: yup.string().required("El color es requerido"),
    costo_vehiculo: yup
        .number()
        .typeError("Debe de ser un numero")
        .positive("Debe de ser un numero positivo")
        .required("El costo del vehiculo es requerido"),
    nombre_proveedor: yup.string().when("modalidad", {
        is: "CONSIGNACION",
        then: (field) => field.required("El nombre del proveedor es requerido"),
    }),
    num_telefono_proveedor: yup.string().when("modalidad", {
        is: "CONSIGNACION",
        then: (field) =>
            field.required("El numero de telefono del proveedor es requerido"),
    }),
});

function IngresosFormEditar(props) {
    const {
        history,
        datosDelIngreso,
        actualizarIngreso,
        listarMarcas,
        listarLineas,
        listarCombustibles,
        listarTipos,
    } = props;

    const urlParams = props.match.params;
    const ingresoId = urlParams.ingresoId;
    const empresa = urlParams.empresa;
    const [loadingState, setLoadingState] = useState(true);
    const [lineaKey, setLineaKey] = useState(1);
    const [marcaChanges, setMarcaChanges] = useState(1);

    const {
        handleSubmit,
        reset,
        control,
        formState: { errors },
        setValue,
        watch,
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues: async () => {
            const formData = await datosDelIngreso(ingresoId);
            setLoadingState(false);
            return formData;
        },
    });

    const modalidad = watch("modalidad");
    const marca = watch("marca");

    async function onSubmit(data) {
        let shouldRedirect = await actualizarIngreso(data, ingresoId);
        if (shouldRedirect) {
            history.push(`/auto_ventas/${empresa}/ingresos`);
        }
    }

    useEffect(() => {
        setMarcaChanges(marcaChanges + 1);
        /*
         * Si se cambia la marca, se debe deseleccionar la linea, pero solo cuando
         * la marca se cambie despues de cargar los datos iniciales, de otra manera,
         * se deselecciona el dato inicial.
         */
        if (marcaChanges > 2) {
            setValue("linea", null);
            setLineaKey(lineaKey + 1); // cambia el 'key' del select para que se actualice
        }
    }, [marca]);

    return (
        <React.Fragment>
            <br />
            <h3
                className="p-2  title rounded shadow"
                style={{
                    background:
                        "linear-gradient(to top right, #790021, #2d2d2d)",
                }}
            >
                Editar ingreso
            </h3>
            <br />
            <div className="d-flex flex-row justify-content-start mb-2">
                <GenBtn
                    BtnColor="#787878"
                    style={{ width: "100px", height: "40px" }}
                    onPress={(e) => {
                        history.push(`/auto_ventas/${empresa}/ingresos`);
                    }}
                >
                    Regresar
                </GenBtn>
            </div>

            <LoadMask loading={loadingState} color="#bd0034" blur={true}>
                <form
                    encType="multipart/form-data"
                    onSubmit={handleSubmit(onSubmit)}
                    className="form-container"
                >
                    <div className="propio-data-container">
                        <div className="modalidad-imagen-container">
                            <Controller
                                name="foto"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <FicImageDrop
                                        value={value}
                                        handleFileChange={onChange}
                                    />
                                )}
                            />
                            <div class="modalidad-radio-container">
                                <div className="modalidad-radio-title">
                                    Modalidad
                                </div>
                                <FicRadio control={control} name="modalidad">
                                    <Radio value="PROPIO">Propio</Radio>
                                    <Radio value="CONSIGNACION">
                                        Consignación
                                    </Radio>
                                </FicRadio>
                            </div>
                        </div>
                        <div className="inputs-container">
                            <FicInput
                                label="Codigo"
                                name="codigo"
                                // placeholder="Codigo"
                                control={control}
                                isReadOnly={true}
                            />
                            <FicComboBox
                                label="Marca"
                                name="marca"
                                control={control}
                                loadOptions={listarMarcas}
                                // placeholder="Marca..."
                                // onChange={handleMarcaChange}
                            />
                            <FicComboBox
                                label="Linea"
                                key={lineaKey}
                                name="linea"
                                control={control}
                                loadOptions={(search) =>
                                    listarLineas(search, marca && marca.value)
                                }
                                // placeholder="Linea..."
                            />
                            <FicComboBox
                                label="Tipo de carro"
                                name="tipo_carro"
                                control={control}
                                loadOptions={listarTipos}
                                // placeholder="Tipo..."
                            />
                            <FicComboBox
                                label="Combustible"
                                name="combustible"
                                control={control}
                                loadOptions={listarCombustibles}
                                // placeholder="Combustible..."
                            />
                            <FicNumber
                                formatOptions={{
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }}
                                label="Modelo"
                                name="modelo"
                                // placeholder="Modelo"

                                control={control}
                            />
                            <FicInput
                                label="Numero de serie"
                                name="num_serie"
                                // placeholder="Serie"
                                control={control}
                            />
                            <FicInput
                                label="Placa"
                                name="placa"
                                // placeholder="Placa"
                                control={control}
                            />
                            <FicInput
                                label="Motor"
                                name="motor"
                                // placeholder="Motor"
                                control={control}
                            />
                            <FicNumber
                                formatOptions={{
                                    maximumFractionDigits: 2,
                                }}
                                label="Millas"
                                name="millas"
                                // placeholder="Millas"

                                control={control}
                            />
                            <FicInput
                                label="Numero de VIN"
                                name="num_vin"
                                // placeholder="Numero de VIN"
                                control={control}
                            />
                            <FicInput
                                label="Numero de chasis"
                                name="num_chasis"
                                // placeholder="Numero de chasis"
                                control={control}
                            />
                            <FicInput
                                label="Numero de motor"
                                name="num_motor"
                                // placeholder="Numero de motor"
                                control={control}
                            />
                            <FicInput
                                label="Color"
                                name="color"
                                // placeholder="Color"
                                control={control}
                            />
                            <FicNumber
                                formatOptions={{
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }}
                                label="Costo de vehiculo"
                                name="costo_vehiculo"
                                // placeholder="Costo de vehiculo"

                                control={control}
                            />
                        </div>
                    </div>
                    {modalidad == "CONSIGNACION" && (
                        <div className="consignacion-data-container">
                            <div class="datos-proveedor-container">
                                <h4>Datos del proveedor</h4>
                                <div class="datos-proveedor">
                                    <FicInput
                                        name="nombre_proveedor"
                                        placeholder="Nombre"
                                        control={control}
                                    />
                                    <FicInput
                                        name="num_telefono_proveedor"
                                        placeholder="Numero de telefono"
                                        control={control}
                                    />
                                </div>
                            </div>
                            <div class="data-wrapper">
                                <div class="c-card-container">
                                    <div class="c-card">
                                        <div class="c-card-header">
                                            DOCUMENTOS
                                        </div>
                                        <div class="c-card-body">
                                            <FicCheckbox
                                                control={control}
                                                name="calcomania"
                                                label="Calcomania"
                                            />
                                            <FicCheckbox
                                                control={control}
                                                name="titulo_propiedad"
                                                label="Titulo propiedad"
                                            />
                                            <FicCheckbox
                                                control={control}
                                                name="tajeta_circulacion"
                                                label="Tarjeta de circulacion"
                                            />
                                        </div>
                                    </div>
                                    <div class="c-card">
                                        <div class="c-card-header">
                                            EQUIPO DE EMERGENCIAS
                                        </div>
                                        <div class="c-card-body">
                                            <FicCheckbox
                                                control={control}
                                                name="botiquin"
                                                label="Botiquín"
                                            />
                                        </div>
                                    </div>
                                    <div class="c-card">
                                        <div class="c-card-header">
                                            HERRAMIENTAS
                                        </div>
                                        <div class="c-card-body">
                                            <FicCheckbox
                                                control={control}
                                                name="triangulos_reflectores"
                                                label="Triangulos reflectores"
                                            />
                                            <FicCheckbox
                                                control={control}
                                                name="triket"
                                                label="Triket"
                                            />
                                            <FicCheckbox
                                                control={control}
                                                name="caja_de_herramientas"
                                                label="Caja de herramientas"
                                            />
                                            <FicCheckbox
                                                control={control}
                                                name="llave_chuchos"
                                                label="Llave de chuchos o cruz"
                                            />
                                            <FicCheckbox
                                                control={control}
                                                name="llanta_de_repuesto"
                                                label="Llanta de repuesto"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div class="observaciones-container">
                                    <h4>Observaciones</h4>
                                    <FicTextArea
                                        textAreaStyle={{ height: "350px" }}
                                        name="observaciones"
                                        control={control}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    <GenBtn
                        type="submit"
                        className="guardar-ingreso-btn"
                        style={{ marginTop: "15px" }}
                    >
                        Guardar
                    </GenBtn>
                </form>
            </LoadMask>
        </React.Fragment>
    );
}

export default withRouter(IngresosFormEditar);
