import React, { Component, useReducer } from 'react';
import Grid from '../../Utils/GridImportacion';
import moment from 'moment';

class ImportacionVerDetalle extends Component{
    
    render(){
        const { loader, detalle } = this.props;
        return(
            <React.Fragment>
                {detalle &&
                    <Grid 
                    hover 
                    striped 
                    data={detalle} 
                    loading={loader}
                    footer
                    headerStyle={ { background: 'black' } } 
                    //onSortChange={onSortChange} 
                >
                    <TableHeaderColumn
                        isKey
                        dataField="fecha"
                        width="115"
                        dataFormat={(cell, row)=>{
                            return moment(cell).format("DD/MM/YYYY");
                        }}
                        dataSort
                        thStyle={ 
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Fecha
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="serie"
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Serie
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="numero"
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Numero
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="proveedor"
                        width="450"
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Proveedor
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataAlign='right'
                        headerAlign="center"
                        dataField="total"
                        dataFormat={(cell, row)=>{
                            if (parseInt(cell) === 0.00){
                                return ''
                            }else{
                                return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(cell);    
                            }
                        }}
                        width="200"
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Total $
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataAlign='right'
                        headerAlign="center"
                        dataField="total_quetzales"
                        dataFormat={(cell, row)=>{
                            if (parseInt(cell) === 0.00){
                                return ''
                            }else{
                                return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(cell);    
                            }
                        }}
                        width="200"
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Total Q
                    </TableHeaderColumn>
                </Grid>
                }
            </React.Fragment>
        );
    }
}

export default ImportacionVerDetalle;
