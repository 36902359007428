import React, { Component } from 'react';
import Grid from '../../Utils/GridProduccionA';
import { standardActions } from '../../Utils/GridProduccionA/StandardActions';

class DetalleProductoList extends Component{
    
    render(){
        const { loader, eliminarLinea, detalle } = this.props;
        return(
            <React.Fragment>
                {detalle &&
                <Grid 
                    hover 
                    striped 
                    data={detalle} 
                    loading={loader}
                    headerStyle={ { background: 'black' } } 
                >
                    <TableHeaderColumn
                        isKey
                        dataField="principioDesc"
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Principio activo
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="concentracionDesc"
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                        >
                        Concentración (mg)
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="unidad_dosis"
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                        >
                        Unidad de dosis
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        thStyle={
                        {color: 'white'}}
                        dataField="id"
                        width="150"
                        dataAlign="center"
                        dataSort
                        dataFormat={standardActions({
                        eliminar: eliminarLinea })} 
                    >
                        Acciones
                    </TableHeaderColumn>
                </Grid>
                }
            </React.Fragment>
        );
    }
}

export default DetalleProductoList;
