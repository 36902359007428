import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { NotificationManager } from "react-notifications";
import { initialize as initializeForm } from 'redux-form';
import { api } from "api";
import moment from 'moment';
import Swal from 'sweetalert2';
const valEmp = __CONFIG__.empresa;

const PERIODOS = 'PERIODOS';
const LOADER = 'LOADER';
const MODULO = 'MODULO';
const PERIODOS_NOMINA = 'PERIODOS_NOMINA';
const PERIODO_D = 'PERIODO_D';
const PERIODO_N = 'PERIODO_N';
const CORRELATIVO = 'CORRELATIVO';
const FECHA_INICIO = 'FECHA_INICIO';
const FECHA_FIN = 'FECHA_FIN';
const FRECUENCIA = 'FRECUENCIA';
const PERIODO_G = 'PERIODO_G';
const COLABORADOR = 'COLABORADOR';
const LISTADO_TIPOS_DESC = 'LISTADO_TIPOS_DESC';
const HORA_EXTRA = 'HORA_EXTRA';
const PERIODO_ID = 'PERIODO_ID';
const ESTADO = 'ESTADO';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params;
    let id = '';
    let idPeriodo = '';
    if (valEmp === 'fage') {
        id = id_emp[6];
        idPeriodo = id_emp[8];
        params = { id, idPeriodo };
    } else {
        id = id_emp[5];
        idPeriodo = id_emp[7];
        params = { id, idPeriodo };
    }
    dispatch(setLoader(true));
    api.get('/nomina_otros_ingresos', params).then((response) => {
        dispatch({ type: PERIODOS, data: response });
    }).catch((error) => {
        if (error.status === 404){
            NotificationManager.error(
                error.body.msj,
                'Error',
                0
            );
            history.goBack();
        }
        if (error.status === 400){
            NotificationManager.error(
                'Ocurrió un error al mostrar nomina',
                'Error',
                2000
            );
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const listarNominas = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params;
    let id_periodo = '';
    let id = ''
    if (valEmp === 'fage') {
        id = id_emp[6];
        id_periodo = id_emp[8];
        params = { id, id_periodo };
    } else {
        id = id_emp[5];
        id_periodo = id_emp[7];
        params = { id, id_periodo };
    }
    dispatch(setLoader(true));
    api.get('/periodos_nomina/listar_planillas_otros', params).then((response) => {
        dispatch({ type: PERIODOS_NOMINA, nomina: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar nominas',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = { id, modulo }
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response) => {
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        if (error.status === 401) {
            NotificationManager.error(
                error.body.msj,
                'Error',
                0
            );
        }
        if (error.status === 400) {
            NotificationManager.error(
                'Ocurrió un error en buscar modulo',
                'Error',
                0
            );
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const leerPeriodoD = (id, id_periodo) => (dispatch) => {
    const params = { id, id_periodo };
    api.get('/periodos/periodo', params).then((response) => {
        dispatch({ type: PERIODO_D, periodoD: response.periodo });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar periodo',
            'Error',
            2000
        );
    })
}


export const leerPeriodoNomina = (id, id_periodo) => (dispatch) => {
    const params = { id, id_periodo };
    api.get('/nomina/periodo', params).then((response) => {
        dispatch({ type: PERIODO_N, periodoN: response.periodo_nomina });
        dispatch({ type: CORRELATIVO, correlativo: response.numero });
        dispatch({ type: FECHA_INICIO, fecha_inicio: moment(response.fecha_inicio).format("DD/MM/YYYY") });
        dispatch({ type: FECHA_FIN, fecha_fin: moment(response.fecha_fin).format("DD/MM/YYYY") });
        dispatch({ type: FRECUENCIA, frecuencia: response.descripcion });
        dispatch({ type: PERIODO_G, periodoG: response.periodo });
        dispatch({ type: PERIODO_ID, periodoId: response.id });
        dispatch({ type: ESTADO, estado : response.estado })

        dispatch(leerPeriodoD(id, response.periodo))
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar periodo',
            'Error',
            2000
        );
    })
}

export const listarColaborador = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params;
    let empresa = ''
    if (valEmp === 'fage') {
        empresa = id_emp[6];
        params = { empresa };
    } else {
        empresa = id_emp[5];
        params = { empresa };
    }
    api.get('/nomina_otros_ingresos/colaborador', params).then(data => {
        const lista = [];
        if (data) {
            data.forEach(item => {
                lista.push({
                    value: item.id,
                    label: item.codigo + ' - ' + item.nombre
                })
            })
        }
        dispatch({ type: COLABORADOR, emp: lista })
    }).catch((error) => {
        return [];
    })
}

export const registroNomina = (periodoId) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id_empresa = '';
    if (valEmp === 'fage') {
        id_empresa = id_emp[6]
    } else {
        id_empresa = id_emp[5]
    }
    const data = getStore().form.FormNominaOtros.values;
    if (data === undefined) {
        Swal.fire({
            title: 'Nomina',
            text: 'Debe de seleccionar colaborador',
            type: 'error',
        })
    } else {
        if (data.bonificacion && data.comision && data.base ) {
            Swal.fire({
                title: 'Nomina',
                text: 'Debe de ingresar solamente bonificación, comisión o base',
                type: 'error',
            })
        } else {
            const formData = {
                colaborador: data.colaborador.value,
                base: data.base,
                bonificacion: data.bonificacion,
                comision: data.comision,
                descripcion: data.descripcion,
                empresa: id_empresa,
                periodo: periodoId,
            }
            dispatch(setLoader(true));
            api.post('/nomina_otros_ingresos/', formData).then((response) => {
                NotificationManager.success(
                    'Colaborador registrado correctamente',
                    'Exito',
                    3000
                );
                dispatch(listar());
                response.base = 0.00;
                response.bonificacion = 0.00;
                response.comision = 0.00;
                response.descripcion = '';
                dispatch(initializeForm('FormNominaOtros', response));
            }).catch((error) => {
                NotificationManager.error(
                    error.msj,
                    'Error',
                    2000
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }
    }
}

export const datosColaborador = (emp) => (dispatch) => {
    let params = {
        id_colaborador: emp,
    }
    api.get('/colaborador/empresa_colaborador_hora_extra_nomina', params).then((response) => {
        dispatch({ type: HORA_EXTRA, hora_extra: response.hora_extra })
    }).catch((error) => {
        return [];
    })
}

export const reporteNomina = (periodo) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = '';
    if (valEmp === 'fage') {
        id = id_emp[6]
    } else {
        id = id_emp[5]
    }
    let reporte = 'reporteNomina';
    const params = { id, reporte, periodo };
    dispatch(setLoader(true));
    api.getPdf('/nomina_otros_ingresos', params).then((response) => {
        if (response.headers['content-type'] == 'application/pdf') {
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'reporte_nomina_otros.pdf';
            window.open(link, '_blank');
            return false;
        }
    }).catch((error) => {
        if (error.status === 404) {
            NotificationManager.info(
                error.body.msj,
                'Información',
                5000
            );
        }
        if (error.status === 500) {
            NotificationManager.error(
                'Ocurrió un error al generar reporte',
                'Error',
                2000
            );
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const confirmarNomina = (periodo, periodoG) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = '';
    if (valEmp === 'fage') {
        id = id_emp[6]
    } else {
        id = id_emp[5]
    }
    const params = { id, periodo };
    dispatch(setLoader(true));
    api.getPdf('/nomina_otros_ingresos/confirmar_nomina', params).then(() => {
        NotificationManager.success(
            'Nomina guardada correctamente',
            'Exito',
            3000
        );
        if (valEmp === 'fage'){
            let ruta = `/lfg/nomina/${id_emp[6]}/listar_nomina_otros/${periodoG}`
            dispatch(push(ruta));
        }
        if (valEmp === ''){
            let ruta = `/nomina/${id_emp[5]}/listar_nomina_otros/${periodoG}`
            dispatch(push(ruta));
        }
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al guardar nomina',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const revisarNomina = (periodo, periodoG) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = '';
    if (valEmp === 'fage') {
        id = id_emp[6]
    } else {
        id = id_emp[5]
    }
    const params = { id, periodo };
    dispatch(setLoader(true));
    api.getPdf('/nomina_otros_ingresos/revisar_nomina', params).then(() => {
        NotificationManager.success(
            'Revisión de nomina guardada correctamente',
            'Exito',
            3000
        );
        if (valEmp === 'fage'){
            let ruta = `/lfg/nomina/${id_emp[6]}/listar_nomina_otros/${periodoG}`
            dispatch(push(ruta));
        }
        if (valEmp === ''){
            let ruta = `/nomina/${id_emp[5]}/listar_nomina_otros/${periodoG}`
            dispatch(push(ruta));
        }
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al guardar nomina',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}


export const actualizarNomina = (periodo) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = '';
    if (valEmp === 'fage') {
        id = id_emp[6]
    } else {
        id = id_emp[5]
    }
    const params = { id, periodo };
    dispatch(setLoader(true));
    api.getPdf('/nomina_otros_ingresos/actualizar_nomina', params).then((response) => {
        dispatch({ type: PERIODOS, data: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al actualizar nomina',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const eliminarLinea = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`nomina_otros_ingresos/${id}`).then(() => {
        dispatch(listar());
        NotificationManager.success(
            'Colaborador borrado correctamente',
            'Exito',
            3000
        );
    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


export const actions = {
    listar,
    getModulo,
    listarNominas,
    leerPeriodoNomina,
    leerPeriodoD,
    listarColaborador,
    registroNomina,
    datosColaborador,
    reporteNomina,
    confirmarNomina,
    revisarNomina,
    actualizarNomina,
    eliminarLinea,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [PERIODOS]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [PERIODOS_NOMINA]: (state, { nomina }) => {
        return {
            ...state,
            nomina,
        };
    },
    [PERIODO_N]: (state, { periodoN }) => {
        return {
            ...state,
            periodoN,
        };
    },
    [CORRELATIVO]: (state, { correlativo }) => {
        return {
            ...state,
            correlativo,
        };
    },
    [FECHA_INICIO]: (state, { fecha_inicio }) => {
        return {
            ...state,
            fecha_inicio,
        };
    },
    [FECHA_FIN]: (state, { fecha_fin }) => {
        return {
            ...state,
            fecha_fin,
        };
    },
    [FRECUENCIA]: (state, { frecuencia }) => {
        return {
            ...state,
            frecuencia,
        };
    },
    [PERIODO_D]: (state, { periodoD }) => {
        return {
            ...state,
            periodoD,
        };
    },
    [PERIODO_G]: (state, { periodoG }) => {
        return {
            ...state,
            periodoG,
        };
    },
    [COLABORADOR]: (state, { emp }) => {
        return {
            ...state,
            emp,
        };
    },
    [LISTADO_TIPOS_DESC]: (state, { tipos }) => {
        return {
            ...state,
            tipos,
        };
    },
    [HORA_EXTRA]: (state, { hora_extra }) => {
        return {
            ...state,
            hora_extra,
        };
    },
    [PERIODO_ID]: (state, { periodoId }) => {
        return {
            ...state,
            periodoId,
        };
    },
    [ESTADO]: (state, { estado }) => {
        return {
            ...state,
            estado,
        };
    },
};

export const initialState = {
    loader: false,
    modulo: [],
    data: [],
    periodoN: [],
    periodos_nomina: [],
    nomina: [],
    precio_inicio: [],
    fecha_fin: [],
    frecuencia: [],
    periodoD: [],
    periodoG: [],
    emp: [],
    tipos: [],
    hora_extra: 0,
    periodoId: [],
    estado: [],
};

export default handleActions(reducers, initialState)