import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import NavbarModulo from '../../components/NavbarModulo';
import TablaDetalle from './CorteCajaDetalle';
import TablaVerDetalle from './CorteCajaDetalleVer';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';

import {
    renderDayPickerC,
    renderCurrencyFloat,
    renderNumber
} from "../../Utils/renderField/renderField";

function CorteCajaForm(props) {
    const {
        crear,
        modulo,
        mostrarRecibos,
        totalEfectivo,
        totalCheque,
        recibos,
        corteCaja,
        detalleDocumentos,
        fechaCierre,
        fechaMes
    } = props;

    let autoFocus = true;


    let ruta = window.location.href;
    let id_empresa = ruta.split('/');
    
    let titulo = 'Crear corte de caja';
    if (crear === false) {
        titulo = 'Ver corte de caja'
    }
  
    const fechaCorte = (value) => {
        mostrarRecibos(value);
    }

    return (
        <div>
            <NavbarModulo
                modulo={modulo}
                titulo={titulo}
            />
            <br />
            <div className="mb-4 card card-small text-white bg-dark mb-3">
                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                    <div className="d-flex flex-column flex-1 mx-4">
                        {crear === false &&
                            <div className='row mb-2'>
                                <div className='col-2'>
                                    <Field
                                        autoFocus={autoFocus}
                                        name="fecha_inicio"
                                        component={renderDayPickerC}
                                        msj="Fecha inicio"
                                        readOnly={true}
                                    />
                                </div>
                                <div className='col-2'>
                                    <Field
                                        name="fecha_cierre"
                                        component={renderDayPickerC}
                                        msj="Fecha cierre"
                                        readOnly={true}
                                    />
                                </div>
                                <div className='col-2'>
                                    <Field
                                        name="total_efectivo"
                                        disabled={true}
                                        placeholder="Total"
                                        className="form-control"
                                        component={renderCurrencyFloat}
                                        msj="Total efectivo"
                                    />
                                </div>
                                <div className='col-2'>
                                    <Field
                                        name="total_cheques"
                                        disabled={true}
                                        placeholder="Total"
                                        className="form-control"
                                        component={renderCurrencyFloat}
                                        msj="Total cheques"
                                    />
                                </div>
                                <div className='col-2'>
                                    <Field
                                        name="numero"
                                        disabled={true}
                                        placeholder="Numero corte de caja"
                                        className="form-control"
                                        component={renderNumber}
                                        msj="Numero corte de caja"
                                    />
                                </div>
                            </div>
                        }
                        {crear &&
                            <div className='row mb-2'>
                                <div className='col-2'>
                                    <Field
                                        autoFocus={autoFocus}
                                        name="fecha_inicio"
                                        component={renderDayPickerC}
                                        msj="Fecha inicio"
                                        readOnly={true}
                                        minDate = {fechaCierre}
                                        maxDate = {fechaMes}
                                    />
                                </div>
                                <div className='col-2'>
                                    <Field
                                        name="fecha_cierre"
                                        component={renderDayPickerC}
                                        msj="Fecha cierre"
                                        onChange={(e) => fechaCorte(e.target.value)}
                                        readOnly={true}
                                        minDate = {fechaCierre}
                                        maxDate = {fechaMes}
                                    />
                                </div>
                                <div className='col-2'>
                                    <div className="form-floating">
                                        <NumberFormat
                                            className={classNames('form-control')}
                                            decimalScale={2}
                                            placeholder="Total efectivo"
                                            fixedDecimalScale
                                            thousandSeparator
                                            value={totalEfectivo}
                                            name="total_efectivo"
                                        />
                                        <label className='form-label' htmlFor="total">
                                            Total efectivo
                                        </label>
                                    </div>
                                </div>
                                <div className='col-2'>
                                    <div className="form-floating">
                                        <NumberFormat
                                            className={classNames('form-control')}
                                            decimalScale={2}
                                            placeholder="Total cheque"
                                            fixedDecimalScale
                                            thousandSeparator
                                            value={totalCheque}
                                            name="total_cheque"
                                        />
                                        <label className='form-label' htmlFor="total">
                                            Total cheque
                                        </label>
                                    </div>
                                </div>
                            </div>
                        }
                        <br/>
                        {crear &&
                            <TablaDetalle
                                detalle={recibos}
                            />
                        }
                        {crear === false &&
                            <TablaVerDetalle
                                detalle={detalleDocumentos}
                            />
                    
                        }
                        {crear &&
                            <div className='d-flex flex-row mt-3'>
                                <button
                                    className='btn mr-2 mb-3 btn-primary'
                                    onClick={() => corteCaja(totalEfectivo, totalCheque)}
                                >
                                    Aceptar y cerrar
                                </button>
                                <a
                                    href = {`/#/produccion/${id_empresa[5]}/corte_caja/${id_empresa[7]}`}
                                    className='btn btn-secondary mb-3'
                                >
                                    Regresar
                                </a>
                            </div>
                        }
                        {crear === false &&
                            <div className='d-flex flex-row mt-3'>
                                <a
                                    href = {`/#/produccion/${id_empresa[5]}/corte_caja/${id_empresa[7]}`}
                                    className='btn btn-secondary mb-3'
                                >
                                    Regresar
                                </a>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
    
CorteCajaForm.propTypes = {
    crear: PropTypes.bool,
    datos: PropTypes.object,
    periodoD: PropTypes.object,
    modulo: PropTypes.object,
};
    
    
export default reduxForm({
    form: 'FormCorteCajaProduccion',
})(CorteCajaForm);
    
