import React, { Component } from 'react';
import Formulario from './EmpresaForm';
import LoadMask from "../Utils/LoadMask/LoadMask";

class Empresa extends Component{
    state={
        crear: true,
        logo: null
    }

    componentDidMount = () => {
        const { match, leer, listarPaises } = this.props;
        const id = match.params.id;
        if (id){
            this.setState({crear: false});
            leer(id);
        }
        listarPaises();
    }

    setLogo = (logo) => {
        this.setState({logo});
    };
    
    registroEmpresa = (data) => {
        const { registroEmpresa } = this.props;
        registroEmpresa( {...data, logo: null},
        [{ "file": this.state.logo, "name": 'logo' },])
    }

    modificarEmpresa = (data) => {
        const { modificarEmpresa } = this.props;
        modificarEmpresa( {...data, logo: null},
        [{ "file": this.state.logo, "name": 'logo' },])
    }

       
    render(){
        const { listaM, listaP, lectura, loader } = this.props;
        const { crear } = this.state;
        const funcionEnvio = crear ? this.registroEmpresa : this.modificarEmpresa;
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    obtenerMonedas = { listaM }
                    obtenerPaises = { listaP }
                    crear = {crear}
                    lectura = {lectura}
                    onSubmit={funcionEnvio}
                    setLogo={this.setLogo} 
                />
           </LoadMask>
        );
    }
}

export default Empresa;