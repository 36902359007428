import React, { Component } from 'react';
import Formulario from './FormSeleccionarPeriodo';


class SeleccionarPeriodo extends Component{

    render(){
        const { listarPeriodos, periodos } = this.props;
        return(
            <Formulario
                listarPeriodos = {listarPeriodos}
                periodos = {periodos}
            />
           
        );
    } 
}

export default SeleccionarPeriodo;