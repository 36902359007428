import React, { useState, useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import Tabs, { TabPane } from 'rc-tabs';
import PropTypes from 'prop-types';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import Documento from './ComprasForm';
import Proyecto from './DetalleProyectoForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";
import Poliza from "../../components/PolizaFormA";
import Modal from '../../../components/components/ModalPoliza';
import ModalCuenta from '../../../components/components/Modal';
import styled from 'styled-components';
import DetalleMateriales from './DetalleProyectoList';
import DetalleMaterialesVer from './DetalleProyectoListVer';
import NavbarModulo from '../../components/NavbarModulo';
import Inventario from './InventarioForm';
import DetalleInventario from './DetalleInventario';
import DetalleInventarioVer from './DetalleInventarioVer';
import DetalleInventarioFarmVer from './DetalleInventarioFarmVer';
import DetalleInventarioFarm from './DetalleInventarioFarm';

import {
    renderField,
    SelectField,
    AsyncSelectField,
    renderCurrencyPoliza,
    renderNumber
} from "../../Utils/renderField/renderField";

function ComprasFormTab(props) {
    const {
        crear, lectura, tipos, datos, periodoD, modulo, listaBodegas, listarProductos,
        listarProveedores, loader, detalle, eliminarLinea, listarSubProyectos, listarMateriales,
        listarCuentas, listarCC, nivel, registroCuenta, listaProyectos, listaSubProyectos,
        reporteCuentas, loaderC, prov, registroCompra, registroProveedor, listaFases, 
        detalleProducto,eliminarLineaMaterial, eliminarLineaProducto, registroProductoInventario,
        detalleMaterial, registroDetalleMaterial, confirmarPoliza, listaP, impuesto, encabezado,
        modificarCompra, registroPolizaDetalle, registroPolizaDetalleSCC, tipoCambio, lectura_data,
        datosCmp } = props;

    useEffect(() => {
        const { match, leer, listarTiposDocu, getModulo,
            leerDetalle, listarNiveles, leerPeriodoD, 
            listarProyectos, listarFases, leerDetalleMateriales, parametrosEmpresa, listarBodegas,
            leerDetalleProductos, listarPaises, listarImpuestos } = props;
        const id = match.params.id;
        if (id) {
            setCrear(false);
            leer(id);
        }
        let ruta = window.location.href;
        let periodo = ruta.split('/');
        leerPeriodoD(periodo[5], periodo[7]);
        getModulo(periodo[5], periodo[4]);
        listarTiposDocu();
        parametrosEmpresa();
        listarImpuestos();
        listarNiveles();
        listarBodegas();
        listarProyectos();
        listarFases();
        listarPaises();
        leerDetalleMateriales('00000000-0000-0000-0000-000000000000');
        leerDetalleProductos('00000000-0000-0000-0000-000000000000');
        leerDetalle('00000000-0000-0000-0000-000000000000');
        localStorage.removeItem('idPolizaCompra');
        localStorage.removeItem('compra_id');
        localStorage.removeItem('proyecto_compra');
        localStorage.removeItem('combCompra');
        localStorage.removeItem('id_detalle_inventario');
        localStorage.removeItem('id_detalle_material_cmp');
        localStorage.removeItem('dolaresCompra');
        localStorage.removeItem('compra_id');
        localStorage.removeItem('TC_compra');
        localStorage.removeItem('fecha_compra');
    }, [])
    const [crearC, setCrear] = useState(true);
    const [estadoModal, cambiarEstadoModal] = useState(false);
    const [estadoModalCC, cambiarEstadoModalCC] = useState(false);
    const [estadoModalCuenta, cambiarEstadoModalCuenta] = useState(false);

    const editar = window.location.href.includes('editar');
    let titulo = editar ? 'Modificar Compra' : 'Crear Compra';
    let disabled = false;
    if (crearC === false && editar === false) {
        disabled = true;
        titulo = 'Ver Compra'
    }
    
    /* crear cuenta contable*/
    const getNivel = () => {
        let formato = ''
        if (nivel.length > 0) {
            for (let a = 0; a < nivel[0].nivel_uno; a++) {
                formato = formato + '#'
            }
            for (let b = 0; b < nivel[0].nivel_dos; b++) {
                if (b === 0) {
                    formato = formato + '-' + '#'
                } else {
                    formato = formato + '#'
                }
            }
            for (let c = 0; c < nivel[0].nivel_tres; c++) {
                if (c === 0) {
                    formato = formato + '-' + '#'
                } else {
                    formato = formato + '#'
                }
            }
            for (let d = 0; d < nivel[0].nivel_cuatro; d++) {
                if (d === 0) {
                    formato = formato + '-' + '#';
                } else {
                    formato = formato + '#';
                }
            }
            for (let e = 0; e < nivel[0].nivel_cinco; e++) {
                if (e === 0) {
                    formato = formato + '-' + '#';
                } else {
                    formato = formato + '#';
                }
            }
            for (let f = 0; f < nivel[0].nivel_seis; f++) {
                formato = formato + '#';
            }
        }

        return formato
    };

    const tipo = [
        {
            value: 'A',
            label: 'Acumulativa'
        },
        {
            value: 'D',
            label: 'Detalle'
        },
    ];

    const naturaleza = [
        {
            value: 'ACTIVO',
            label: 'Activo'
        },
        {
            value: 'PASIVO',
            label: 'Pasivo'
        },
        {
            value: 'CAPITAL',
            label: 'Capital'
        },
        {
            value: 'INGRESOS',
            label: 'Ingresos'
        },
        {
            value: 'COSTOS',
            label: 'Costos'
        },
        {
            value: 'GASTOS',
            label: 'Gastos'
        },
    ];

    let autoFocus = true;
    let ruta = window.location.href;
    let id_empresa = ruta.split('/');

    const [key, setKey] = useState('PRINCIPAL_TOP');
    let poliza = localStorage.getItem('idPolizaCompra');
    const estilo = {
        overflow: "auto",
        height: "200px",

    };
    const estiloFarm = {
        overflow: "auto",
        height: "200px",
    };
    let dif_poliza = localStorage.getItem('dif');
    return (
        <React.Fragment>
            <NavbarModulo
                periodoD={periodoD}
                modulo={modulo}
                titulo={titulo}
            />
            <div className="py-1">
                <div className="mb-1">
                    <div className="p-0 px-3 pt-0">
                        {datos.inmobiliaria === 'S' && datos.inventario === 'S' && datos.contabilidad === 'S' &&
                            <Tabs
                                activeKey={key}
                                onChange={(k) => setKey(k)}
                                tabBarPosition="top"
                                renderTabBar={() => <ScrollableInkTabBar />}
                                renderTabContent={() => <TabContent />}
                            >
                                <TabPane tab="Documento" key="PRINCIPAL_TOP">
                                    <div className="py-2 px-3">
                                        <div className="mb-4 card card-small text-white bg-dark mb-1">
                                            <div className="p-0 pt-4 d-flex flex-column flex-md-row">
                                                <div className="d-flex flex-column flex-1 mx-4">
                                                    <LoadMask loading={loader} dark>
                                                        <Documento
                                                            crear={crearC}
                                                            lectura={lectura}
                                                            lectura_data={lectura_data}
                                                            bloqueo={disabled}
                                                            editar={editar}
                                                            tipos={tipos}
                                                            fechaInicio={periodoD.fecha_inicio}
                                                            fechaFinal={periodoD.fecha_fin}
                                                            listarProveedores={listarProveedores}
                                                            registroProveedor={registroProveedor}
                                                            listarProveedoresD={prov}
                                                            datos={datos}
                                                            impuesto={impuesto}
                                                            obtenerPaises = { listaP }
                                                            tipoCambio = {tipoCambio}
                                                        />
                                                    </LoadMask>
                                                    <br />
                                                    {disabled === false && !poliza &&
                                                        <div className='mb-2'>
                                                            <button
                                                                className='btn mr-2 mb-2 btn-primary'
                                                                onClick={() => {
                                                                    //function expression
                                                                    const funcion2 = () => registroCompra(impuesto, periodoD, datos);
                                                                    const funcion3 = () => modificarCompra(impuesto, periodoD, datos);
                                                                    //function call:ejecuta
                                                                    if (crearC === true){
                                                                        funcion2();
                                                                        if (localStorage.getItem('valCompra') === 'T'){
                                                                            setKey('SEGUNDO_TOP');
                                                                        }
                                                                        
                                                                    }
                                                                    if (crearC === false){
                                                                        funcion3();
                                                                        if (localStorage.getItem('valCompra') === 'T'){
                                                                            setKey('SEGUNDO_TOP');
                                                                        }
                                                                    }
                                                                    
                                                                }}

                                                            >
                                                                {editar ? 'Modificar' : 'Guardar documento'}
                                                            </button>
                                                            <a
                                                                href={`/#/compras/${id_empresa[5]}/compras/${id_empresa[7]}`}
                                                                className='btn btn-secondary mb-2'
                                                            >
                                                                Regresar
                                                            </a>
                                                        </div>
                                                    }
                                                    {disabled === false && poliza && editar &&
                                                        <div className='mb-2'>
                                                            <button
                                                                className='btn mr-2 mb-2 btn-primary'
                                                                onClick={() => {
                                                                    //function expression
                                                                    const funcion2 = () => registroCompra(impuesto, periodoD, datos);
                                                                    const funcion3 = () => modificarCompra(impuesto, periodoD, datos);
                                                                    //function call:ejecuta
                                                                    if (crearC === true){
                                                                        funcion2();
                                                                        if (localStorage.getItem('valCompra') === 'T'){
                                                                            setKey('SEGUNDO_TOP');
                                                                        }
                                                                    }
                                                                    if (crearC === false){
                                                                        funcion3();
                                                                        if (localStorage.getItem('valCompra') === 'T'){
                                                                            setKey('SEGUNDO_TOP');
                                                                        }
                                                                    }
                                                                }}

                                                            >
                                                                {editar ? 'Modificar' : 'Guardar documento'}
                                                            </button>
                                                            <a
                                                                href={`/#/compras/${id_empresa[5]}/compras/${id_empresa[7]}`}
                                                                className='btn btn-secondary mb-2'
                                                            >
                                                                Regresar
                                                            </a>
                                                        </div>
                                                    }
                                                    {disabled === false && poliza && crearC &&
                                                        <div className='d-flex flex-row mt-2'>
                                                            {poliza &&
                                                                <button
                                                                    className='btn mr-2 mb-3 btn-primary'
                                                                    onClick={() => setKey('TERCER_TOP')}
                                                                >
                                                                    Crear Poliza
                                                                </button>
                                                            }
                                                        </div>
                                                    }
                                                    {crearC === false && editar === false &&
                                                        <div className='d-flex flex-row mt-2'>
                                                            <a
                                                                href={`/#/compras/${id_empresa[5]}/compras/${id_empresa[7]}`}
                                                                className='btn btn-secondary mb-3'
                                                            >
                                                                Regresar
                                                            </a>
                                                        </div>
                                                    }
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane tab="Inventario" key="SEGUNDO_TOP">
                                    <div className="py-2 px-3">
                                        <div className="mb-4 card card-small bg-dark mb-3">
                                            <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                                                <div className="d-flex flex-column flex-1 mx-3">
                                                    <LoadMask loading={loader} dark>
                                                        <Inventario
                                                            crear={crearC}
                                                            lectura={lectura}
                                                            bloqueo={disabled}
                                                            editar={editar}
                                                            obtenerBodegas={listaBodegas}
                                                            listarProductos={listarProductos}
                                                            datos={datos}
                                                        />
                                                        {(crearC === true || editar === true) &&
                                                            <Contenido>
                                                                <button
                                                                    className='btn mr-2 mb-3 btn-primary'
                                                                    onClick={() => registroProductoInventario(impuesto, crearC ? datosCmp : lectura_data, crearC ? 'N' : 'M')}
                                                                >
                                                                    Agregar linea
                                                                </button>
                                                            </Contenido>
                                                        }
                                                        {(crearC === true || editar === true) &&
                                                            <div style={estilo}>
                                                                <DetalleInventario
                                                                    detalle={detalleProducto}
                                                                    eliminarLineaProducto={eliminarLineaProducto}
                                                                />
                                                            </div>
                                                        }
                                                        {(crearC === false && editar === false) &&
                                                            <div style={estilo}>
                                                                <DetalleInventario
                                                                    detalle={detalleProducto}
                                                                />
                                                            </div>
                                                        }
                                                        <br />
                                                        <br />
                                                        <br />
                                                    </LoadMask>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane tab="Detalle proyecto" key="TERCERO_TOP">
                                    <div className="py-2 px-3">
                                        <div className="mb-4 card card-small bg-dark mb-3">
                                            <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                                                <div className="d-flex flex-column flex-1 mx-3">
                                                    <LoadMask loading={loader} dark>
                                                        <Proyecto
                                                            crear={crearC}
                                                            lectura={lectura}
                                                            bloqueo={disabled}
                                                            editar={editar}
                                                            tipos={tipos}
                                                            listarMateriales={listarMateriales}
                                                            obtenerSubProyectos={listaSubProyectos}
                                                            obtenerFases={listaFases}
                                                            listarSuProyectos={listarSubProyectos}
                                                            obtenerProyectos={listaProyectos}
                                                            listarProveedores={listarProveedores}
                                                            registroProveedor={registroProveedor}
                                                            listarProveedoresD={prov}
                                                            encabezado={encabezado}
                                                        />
                                                        {(crearC === true || editar === true) &&
                                                            <Contenido>
                                                                <button
                                                                    className='btn mr-2 mb-3 btn-primary'
                                                                    onClick={() => registroDetalleMaterial(impuesto)}
                                                                >
                                                                    Agregar linea
                                                                </button>
                                                            </Contenido>
                                                        }
                                                        {(crearC === true || editar === true) &&
                                                            <div>
                                                                <h5 className="p-2 bg-secondary title mt-1">Detalle de materiales</h5>
                                                                <div style={estilo}>
                                                                    <DetalleMateriales
                                                                        detalle={detalleMaterial}
                                                                        eliminarLineaMaterial={eliminarLineaMaterial}
                                                                    />
                                                                </div>
                                                            </div>
                                                        }
                                                        {(crearC === false && editar === false) &&
                                                            <div>
                                                                <h5 className="p-2 bg-secondary title mt-1">Detalle de materiales</h5>
                                                                <div style={estilo}>
                                                                    <DetalleMaterialesVer
                                                                        detalle={detalleMaterial}
                                                                    />
                                                                </div>
                                                            </div>
                                                            
                                                        }
                                                        <br/>
                                                        <br/>
                                                    </LoadMask>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane tab="Póliza" key="CUARTO_TOP">
                                    <div className="py-2 px-3">
                                        <div className="mb-4 card card-small bg-dark mb-3">
                                            <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                                                <div className="d-flex flex-column flex-1 mx-3">
                                                    {disabled === false && datos.centro_costo === 'N' &&
                                                        <Contenido>
                                                            <button
                                                                className='btn mr-2 mb-3 btn-primary'
                                                                onClick={() => cambiarEstadoModal(!estadoModal)}
                                                            >
                                                                Agregar Linea
                                                            </button>
                                                        </Contenido>
                                                    }

                                                    {disabled === false && datos.centro_costo === 'S' &&
                                                        <Contenido>
                                                            <button
                                                                className='btn mr-2 mb-3 btn-primary'
                                                                onClick={() => cambiarEstadoModalCC(!estadoModalCC)}
                                                            >
                                                                Agregar Linea
                                                            </button>
                                                        </Contenido>
                                                    }
                                                    <Poliza
                                                        listarCuentas={listarCuentas}
                                                        lectura={lectura}
                                                        crear={crearC}
                                                        editar={editar}
                                                        listarCC={listarCC}
                                                        detallePoliza={detalle}
                                                        eliminarLinea={eliminarLinea}
                                                        niveles={nivel}
                                                        registroCuenta={registroCuenta}
                                                        reporteCuentas={reporteCuentas}
                                                        loaderC={loaderC}
                                                    />
                                                    <br />
                                                    <div className='d-flex flex-row mt-3'>
                                                        {disabled === false &&
                                                            <button
                                                                className='btn mr-2 mb-3 btn-primary'
                                                                onClick={() => {
                                                                    //function expression
                                                                    const funcion2 = confirmarPoliza;
                                                                    funcion2();
                                                                }}

                                                            >
                                                                {editar ? 'Modificar' : 'Confirmar poliza'}
                                                            </button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPane>
                            </Tabs>
                        }
                        {datos.inmobiliaria === 'S' && datos.inventario === 'N' && datos.contabilidad === 'S' &&
                            <Tabs
                                activeKey={key}
                                onChange={(k) => setKey(k)}
                                tabBarPosition="top"
                                renderTabBar={() => <ScrollableInkTabBar />}
                                renderTabContent={() => <TabContent />}
                            >
                                <TabPane tab="Documento" key="PRINCIPAL_TOP">
                                    <div className="py-2 px-3">
                                        <div className="mb-4 card card-small text-white bg-dark mb-1">
                                            <div className="p-0 pt-4 d-flex flex-column flex-md-row">
                                                <div className="d-flex flex-column flex-1 mx-4">
                                                    <LoadMask loading={loader} dark>
                                                        <Documento
                                                            crear={crearC}
                                                            lectura={lectura}
                                                            lectura_data={lectura_data}
                                                            bloqueo={disabled}
                                                            editar={editar}
                                                            tipos={tipos}
                                                            fechaInicio={periodoD.fecha_inicio}
                                                            fechaFinal={periodoD.fecha_fin}
                                                            listarProveedores={listarProveedores}
                                                            registroProveedor={registroProveedor}
                                                            listarProveedoresD={prov}
                                                            datos={datos}
                                                            impuesto={impuesto}
                                                            obtenerPaises = { listaP }
                                                            tipoCambio = {tipoCambio}
                                                        />
                                                    </LoadMask>
                                                    <br />
                                                    {disabled == false && !poliza &&
                                                        <div className='mb-2'>
                                                            <button
                                                                className='btn mr-2 mb-2 btn-primary'
                                                                onClick={() => {
                                                                    //function expression
                                                                    const funcion2 = () => registroCompra(impuesto, periodoD, datos);
                                                                    const funcion3 = () => modificarCompra(impuesto, periodoD, datos);
                                                                    //function call:ejecuta
                                                                    if (crearC === true){
                                                                        funcion2();
                                                                        if (localStorage.getItem('valCompra') === 'T'){
                                                                            setKey('SEGUNDO_TOP');
                                                                        }
                                                                    }
                                                                    if (crearC === false){
                                                                        funcion3();
                                                                        if (localStorage.getItem('valCompra') === 'T'){
                                                                            setKey('SEGUNDO_TOP');
                                                                        }
                                                                    }
                                                                }}

                                                            >
                                                                {editar ? 'Modificar' : 'Guardar documento'}
                                                            </button>
                                                            <a
                                                                href={`/#/compras/${id_empresa[5]}/compras/${id_empresa[7]}`}
                                                                className='btn btn-secondary mb-2'
                                                            >
                                                                Regresar
                                                            </a>
                                                        </div>
                                                    }
                                                    {disabled == false && poliza && editar &&
                                                        <div className='mb-2'>
                                                            <button
                                                                className='btn mr-2 mb-2 btn-primary'
                                                                onClick={() => {
                                                                    //function expression
                                                                    const funcion2 = () => registroCompra(impuesto, periodoD, datos);
                                                                    const funcion3 = () => modificarCompra(impuesto, periodoD, datos);
                                                                    //function call:ejecuta
                                                                    if (crearC === true){
                                                                        funcion2();
                                                                        if (localStorage.getItem('valCompra') === 'T'){
                                                                            setKey('SEGUNDO_TOP');
                                                                        }
                                                                    }
                                                                    if (crearC === false){
                                                                        funcion3();
                                                                        if (localStorage.getItem('valCompra') === 'T'){
                                                                            setKey('SEGUNDO_TOP');
                                                                        }
                                                                    }
                                                                }}

                                                            >
                                                                {editar ? 'Modificar' : 'Guardar documento'}
                                                            </button>
                                                            <a
                                                                href={`/#/compras/${id_empresa[5]}/compras/${id_empresa[7]}`}
                                                                className='btn btn-secondary mb-2'
                                                            >
                                                                Regresar
                                                            </a>
                                                        </div>
                                                    }
                                                    {disabled == false && poliza && crearC &&
                                                        <div className='d-flex flex-row mt-2'>
                                                            {poliza &&
                                                                <button
                                                                    className='btn mr-2 mb-3 btn-primary'
                                                                    onClick={() => setKey('TERCER_TOP')}
                                                                >
                                                                    Crear Poliza
                                                                </button>
                                                            }
                                                        </div>
                                                    }
                                                    {crearC === false && editar === false &&
                                                        <div className='d-flex flex-row mt-2'>
                                                            <a
                                                                href={`/#/compras/${id_empresa[5]}/compras/${id_empresa[7]}`}
                                                                className='btn btn-secondary mb-3'
                                                            >
                                                                Regresar
                                                            </a>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane tab="Detalle proyecto" key="SEGUNDO_TOP">
                                    <div className="py-2 px-3">
                                        <div className="mb-4 card card-small bg-dark mb-3">
                                            <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                                                <div className="d-flex flex-column flex-1 mx-3">
                                                    <LoadMask loading={loader} dark>
                                                        <Proyecto
                                                            crear={crearC}
                                                            lectura={lectura}
                                                            bloqueo={disabled}
                                                            editar={editar}
                                                            tipos={tipos}
                                                            listarMateriales={listarMateriales}
                                                            obtenerSubProyectos={listaSubProyectos}
                                                            obtenerFases={listaFases}
                                                            listarSuProyectos={listarSubProyectos}
                                                            obtenerProyectos={listaProyectos}
                                                            listarProveedores={listarProveedores}
                                                            registroProveedor={registroProveedor}
                                                            listarProveedoresD={prov}
                                                            encabezado={encabezado}
                                                        />
                                                        {(crearC === true || editar === true) &&
                                                            <Contenido>
                                                                <button
                                                                    className='btn mr-2 mb-3 btn-primary'
                                                                    onClick={() => registroDetalleMaterial(impuesto)}
                                                                >
                                                                    Agregar linea
                                                                </button>
                                                            </Contenido>
                                                        }
                                                        {(crearC === true || editar === true) &&
                                                            <div>
                                                                <h5 className="p-2 bg-secondary title mt-1">Detalle de materiales</h5>
                                                                <div style={estilo}>
                                                                    <DetalleMateriales
                                                                        detalle={detalleMaterial}
                                                                        eliminarLineaMaterial={eliminarLineaMaterial}
                                                                    />
                                                                </div>
                                                            </div>
                                                            
                                                        }
                                                        {(crearC === false && editar === false) &&
                                                            <div>
                                                                <h5 className="p-2 bg-secondary title mt-1">Detalle de materiales</h5>
                                                                <div style={estilo}>
                                                                    <DetalleMaterialesVer
                                                                        detalle={detalleMaterial}
                                                                    />
                                                                </div>
                                                            </div>
                                                            
                                                        }
                                                        <br/>
                                                        <br/>
                                                    </LoadMask>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane tab="Póliza" key="TERCER_TOP">
                                    <div className="py-2 px-3">
                                        <div className="mb-4 card card-small bg-dark mb-3">
                                            <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                                                <div className="d-flex flex-column flex-1 mx-3">
                                                    {disabled == false && datos.centro_costo === 'N' &&
                                                        <Contenido>
                                                            <button
                                                                className='btn mr-2 mb-3 btn-primary'
                                                                onClick={() => cambiarEstadoModal(!estadoModal)}
                                                            >
                                                                Agregar Linea
                                                            </button>
                                                        </Contenido>
                                                    }

                                                    {disabled == false && datos.centro_costo === 'S' &&
                                                        <Contenido>
                                                            <button
                                                                className='btn mr-2 mb-3 btn-primary'
                                                                onClick={() => cambiarEstadoModalCC(!estadoModalCC)}
                                                            >
                                                                Agregar Linea
                                                            </button>
                                                        </Contenido>
                                                    }
                                                    <Poliza
                                                        listarCuentas={listarCuentas}
                                                        lectura={lectura}
                                                        crear={crearC}
                                                        editar={editar}
                                                        listarCC={listarCC}
                                                        detallePoliza={detalle}
                                                        eliminarLinea={eliminarLinea}
                                                        niveles={nivel}
                                                        registroCuenta={registroCuenta}
                                                        reporteCuentas={reporteCuentas}
                                                        loaderC={loaderC}
                                                    />
                                                    <br />
                                                    <div className='d-flex flex-row mt-3'>
                                                        {disabled == false &&
                                                            <button
                                                                className='btn mr-2 mb-3 btn-primary'
                                                                onClick={() => {
                                                                    //function expression
                                                                    const funcion2 = confirmarPoliza;
                                                                    funcion2();
                                                                }}

                                                            >
                                                                {editar ? 'Modificar' : 'Confirmar poliza'}
                                                            </button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPane>
                            </Tabs>
                        }
                        {datos.inmobiliaria === 'N' && datos.inventario === 'N' && datos.contabilidad === 'S' &&
                            <Tabs
                                activeKey={key}
                                onChange={(k) => setKey(k)}
                                tabBarPosition="top"
                                renderTabBar={() => <ScrollableInkTabBar />}
                                renderTabContent={() => <TabContent />}
                            >
                                <TabPane tab="Documento" key="PRINCIPAL_TOP">
                                    <div className="py-2 px-3">
                                        <div className="mb-4 card card-small text-white bg-dark mb-1">
                                            <div className="p-0 pt-4 d-flex flex-column flex-md-row">
                                                <div className="d-flex flex-column flex-1 mx-4">
                                                    <LoadMask loading={loader} dark>
                                                        <Documento
                                                            crear={crearC}
                                                            lectura={lectura}
                                                            lectura_data={lectura_data}
                                                            bloqueo={disabled}
                                                            editar={editar}
                                                            tipos={tipos}
                                                            fechaInicio={periodoD.fecha_inicio}
                                                            fechaFinal={periodoD.fecha_fin}
                                                            listarProveedores={listarProveedores}
                                                            registroProveedor={registroProveedor}
                                                            listarProveedoresD={prov}
                                                            datos={datos}
                                                            impuesto={impuesto}
                                                            obtenerPaises = { listaP }
                                                            tipoCambio = {tipoCambio}
                                                        />
                                                    </LoadMask>
                                                    <br />
                                                    {disabled == false && !poliza &&
                                                        <div className='mb-2'>
                                                            <button
                                                                className='btn mr-2 mb-2 btn-primary'
                                                                onClick={() => {
                                                                    //function expression
                                                                    const funcion2 = () => registroCompra(impuesto, periodoD, datos);
                                                                    const funcion3 = () => modificarCompra(impuesto, periodoD, datos);
                                                                     //function call:ejecuta
                                                                    if (crearC === true){
                                                                        funcion2();
                                                                        if (localStorage.getItem('valCompra') === 'T'){
                                                                            setKey('SEGUNDO_TOP');
                                                                        }
                                                                    }
                                                                    if (crearC === false){
                                                                        funcion3();
                                                                        if (localStorage.getItem('valCompra') === 'T'){
                                                                            setKey('SEGUNDO_TOP');
                                                                        }
                                                                    }
                                                                }}

                                                            >
                                                                {editar ? 'Modificar' : 'Guardar documento'}
                                                            </button>
                                                            <a
                                                                href={`/#/compras/${id_empresa[5]}/compras/${id_empresa[7]}`}
                                                                className='btn btn-secondary mb-2'
                                                            >
                                                                Regresar
                                                            </a>
                                                        </div>
                                                    }
                                                    {disabled == false && poliza && editar &&
                                                        <div className='mb-2'>
                                                            <button
                                                                className='btn mr-2 mb-2 btn-primary'
                                                                onClick={() => {
                                                                    //function expression
                                                                    const funcion2 = () => registroCompra(impuesto, periodoD, datos);
                                                                    const funcion3 = () => modificarCompra(impuesto, periodoD, datos);
                                                                    //function call:ejecuta
                                                                    if (crearC === true){
                                                                        funcion2();
                                                                        if (localStorage.getItem('valCompra') === 'T'){
                                                                            setKey('SEGUNDO_TOP');
                                                                        }
                                                                    }
                                                                    if (crearC === false){
                                                                        funcion3();
                                                                        if (localStorage.getItem('valCompra') === 'T'){
                                                                            setKey('SEGUNDO_TOP');
                                                                        }
                                                                    }
                                                                }}

                                                            >
                                                                {editar ? 'Modificar' : 'Guardar documento'}
                                                            </button>
                                                            <a
                                                                href={`/#/compras/${id_empresa[5]}/compras/${id_empresa[7]}`}
                                                                className='btn btn-secondary mb-2'
                                                            >
                                                                Regresar
                                                            </a>
                                                        </div>
                                                    }
                                                    {disabled == false && poliza && crearC &&
                                                        <div className='d-flex flex-row mt-2'>
                                                            {poliza &&
                                                                <button
                                                                    className='btn mr-2 mb-3 btn-primary'
                                                                    onClick={() => setKey('SEGUNDO_TOP')}
                                                                >
                                                                    Crear Poliza
                                                                </button>
                                                            }
                                                        </div>
                                                    }
                                                    {crearC === false && editar === false &&
                                                        <div className='d-flex flex-row mt-2'>
                                                            <a
                                                                href={`/#/compras/${id_empresa[5]}/compras/${id_empresa[7]}`}
                                                                className='btn btn-secondary mb-3'
                                                            >
                                                                Regresar
                                                            </a>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane tab="Póliza" key="SEGUNDO_TOP">
                                    <div className="py-2 px-3">
                                        <div className="mb-4 card card-small bg-dark mb-3">
                                            <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                                                <div className="d-flex flex-column flex-1 mx-3">
                                                    {disabled == false && datos.centro_costo === 'N' &&
                                                        <Contenido>
                                                            <button
                                                                className='btn mr-2 mb-3 btn-primary'
                                                                onClick={() => cambiarEstadoModal(!estadoModal)}
                                                            >
                                                                Agregar Linea
                                                            </button>
                                                        </Contenido>
                                                    }

                                                    {disabled == false && datos.centro_costo === 'S' &&
                                                        <Contenido>
                                                            <button
                                                                className='btn mr-2 mb-3 btn-primary'
                                                                onClick={() => cambiarEstadoModalCC(!estadoModalCC)}
                                                            >
                                                                Agregar Linea
                                                            </button>
                                                        </Contenido>
                                                    }
                                                    <Poliza
                                                        listarCuentas={listarCuentas}
                                                        lectura={lectura}
                                                        crear={crearC}
                                                        editar={editar}
                                                        listarCC={listarCC}
                                                        detallePoliza={detalle}
                                                        eliminarLinea={eliminarLinea}
                                                        niveles={nivel}
                                                        registroCuenta={registroCuenta}
                                                        reporteCuentas={reporteCuentas}
                                                        loaderC={loaderC}
                                                    />
                                                    <br />
                                                    <div className='d-flex flex-row mt-3'>
                                                        {disabled == false &&
                                                            <button
                                                                className='btn mr-2 mb-3 btn-primary'
                                                                onClick={() => {
                                                                    //function expression
                                                                    const funcion2 = confirmarPoliza;
                                                                    funcion2();
                                                                }}

                                                            >
                                                                {editar ? 'Modificar' : 'Confirmar poliza'}
                                                            </button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPane>
                            </Tabs>
                        }
                        {datos.inmobiliaria === 'N' && datos.inventario === 'S' && datos.contabilidad === 'S' &&
                            <Tabs
                                activeKey={key}
                                onChange={(k) => setKey(k)}
                                tabBarPosition="top"
                                renderTabBar={() => <ScrollableInkTabBar />}
                                renderTabContent={() => <TabContent />}
                            >
                                <TabPane tab="Documento" key="PRINCIPAL_TOP">
                                    <div className="py-2 px-3">
                                        <div className="mb-4 card card-small text-white bg-dark mb-1">
                                            <div className="p-0 pt-4 d-flex flex-column flex-md-row">
                                                <div className="d-flex flex-column flex-1 mx-4">
                                                    <LoadMask loading={loader} dark>
                                                        <Documento
                                                            crear={crearC}
                                                            lectura={lectura}
                                                            lectura_data={lectura_data}
                                                            bloqueo={disabled}
                                                            editar={editar}
                                                            tipos={tipos}
                                                            fechaInicio={periodoD.fecha_inicio}
                                                            fechaFinal={periodoD.fecha_fin}
                                                            listarProveedores={listarProveedores}
                                                            registroProveedor={registroProveedor}
                                                            listarProveedoresD={prov}
                                                            datos={datos}
                                                            impuesto={impuesto}
                                                            obtenerPaises = { listaP }
                                                            tipoCambio = {tipoCambio}
                                                        />
                                                    </LoadMask>
                                                    <br />
                                                    {disabled === false && !poliza &&
                                                        <div className='mb-2'>
                                                            <button
                                                                className='btn mr-2 mb-2 btn-primary'
                                                                onClick={() => {
                                                                    //function expression
                                                                    const funcion2 = () => registroCompra(impuesto, periodoD, datos);
                                                                    const funcion3 = () => modificarCompra(impuesto, periodoD, datos);
                                                                        //function call:ejecuta
                                                                    if (crearC === true){
                                                                        funcion2();
                                                                        if (localStorage.getItem('valCompra') === 'T'){
                                                                            setKey('SEGUNDO_TOP');
                                                                        }
                                                                    }
                                                                    if (crearC === false){
                                                                        funcion3();
                                                                        if (localStorage.getItem('valCompra') === 'T'){
                                                                            setKey('SEGUNDO_TOP');
                                                                        }
                                                                    }
                                                                }}

                                                            >
                                                                {editar ? 'Modificar' : 'Guardar documento'}
                                                            </button>
                                                            <a
                                                                href={`/#/compras/${id_empresa[5]}/compras/${id_empresa[7]}`}
                                                                className='btn btn-secondary mb-2'
                                                            >
                                                                Regresar
                                                            </a>
                                                        </div>
                                                    }
                                                    {disabled === false && poliza && editar &&
                                                        <div className='mb-2'>
                                                            <button
                                                                className='btn mr-2 mb-2 btn-primary'
                                                                onClick={() => {
                                                                    //function expression
                                                                    const funcion2 = () => registroCompra(impuesto, periodoD, datos);
                                                                    const funcion3 = () => modificarCompra(impuesto, periodoD, datos);
                                                                    //function call:ejecuta
                                                                    if (crearC === true){
                                                                        funcion2();
                                                                        if (localStorage.getItem('valCompra') === 'T'){
                                                                            setKey('SEGUNDO_TOP');
                                                                        }
                                                                    }
                                                                    if (crearC === false){
                                                                        funcion3();
                                                                        if (localStorage.getItem('valCompra') === 'T'){
                                                                            setKey('SEGUNDO_TOP');
                                                                        }
                                                                    }
                                                                }}

                                                            >
                                                                {editar ? 'Modificar' : 'Guardar documento'}
                                                            </button>
                                                            <a
                                                                href={`/#/compras/${id_empresa[5]}/compras/${id_empresa[7]}`}
                                                                className='btn btn-secondary mb-2'
                                                            >
                                                                Regresar
                                                            </a>
                                                        </div>
                                                    }
                                                    {disabled === false && poliza && crearC &&
                                                        <div className='d-flex flex-row mt-2'>
                                                            {poliza &&
                                                                <button
                                                                    className='btn mr-2 mb-3 btn-primary'
                                                                    onClick={() => setKey('TERCER_TOP')}
                                                                >
                                                                    Crear Poliza
                                                                </button>
                                                            }
                                                        </div>
                                                    }
                                                    {crearC === false && editar === false &&
                                                        <div className='d-flex flex-row mt-2'>
                                                            <a
                                                                href={`/#/compras/${id_empresa[5]}/compras/${id_empresa[7]}`}
                                                                className='btn btn-secondary mb-3'
                                                            >
                                                                Regresar
                                                            </a>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane tab="Inventario" key="SEGUNDO_TOP">
                                    <div className="py-2 px-3">
                                        <div className="mb-4 card card-small bg-dark mb-3">
                                            <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                                                <div className="d-flex flex-column flex-1 mx-3">
                                                    <LoadMask loading={loader} dark>
                                                        <Inventario
                                                            crear={crearC}
                                                            lectura={lectura}
                                                            bloqueo={disabled}
                                                            editar={editar}
                                                            obtenerBodegas={listaBodegas}
                                                            listarProductos={listarProductos}
                                                            datos={datos}
                                                        />
                                                        {(crearC === true || editar === true) &&
                                                            <Contenido>
                                                                <button
                                                                    className='btn mr-2 mb-3 btn-primary'
                                                                    onClick={() => registroProductoInventario(impuesto, crearC ? datosCmp : lectura_data, crearC ? 'N' : 'M')}
                                                                >
                                                                    Agregar linea
                                                                </button>
                                                            </Contenido>
                                                        }
                                                        {(crearC === true || editar === true) && datos.farmaceutica === 'N' &&
                                                            <div style={estilo}>
                                                                <DetalleInventario
                                                                    detalle={detalleProducto}
                                                                    eliminarLineaProducto={eliminarLineaProducto}
                                                                />
                                                            </div>
                                                        }
                                                        {(crearC === true || editar === true) && datos.farmaceutica === 'S' &&
                                                            <div style={estiloFarm}>
                                                                <DetalleInventarioFarm
                                                                    detalle={detalleProducto}
                                                                    eliminarLineaProducto={eliminarLineaProducto}
                                                                />
                                                            </div>
                                                        }
                                                        {(crearC === false && editar === false) && datos.farmaceutica === 'N' &&
                                                            <div style={estilo}>
                                                                <DetalleInventarioVer
                                                                    detalle={detalleProducto}
                                                                />
                                                            </div>
                                                        }
                                                        {(crearC === false && editar === false) && datos.farmaceutica === 'S' &&
                                                            <div style={estiloFarm}>
                                                                <DetalleInventarioFarmVer
                                                                    detalle={detalleProducto}
                                                                />
                                                            </div>
                                                        }
                                                        <br />
                                                        <br />
                                                        <br />
                                                    </LoadMask>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane tab="Póliza" key="TERCERO_TOP">
                                    <div className="py-2 px-3">
                                        <div className="mb-4 card card-small bg-dark mb-3">
                                            <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                                                <div className="d-flex flex-column flex-1 mx-3">
                                                    {disabled === false && datos.centro_costo === 'N' &&
                                                        <Contenido>
                                                            <button
                                                                className='btn mr-2 mb-3 btn-primary'
                                                                onClick={() => cambiarEstadoModal(!estadoModal)}
                                                            >
                                                                Agregar Linea
                                                            </button>
                                                        </Contenido>
                                                    }

                                                    {disabled === false && datos.centro_costo === 'S' &&
                                                        <Contenido>
                                                            <button
                                                                className='btn mr-2 mb-3 btn-primary'
                                                                onClick={() => cambiarEstadoModalCC(!estadoModalCC)}
                                                            >
                                                                Agregar Linea
                                                            </button>
                                                        </Contenido>
                                                    }
                                                    <Poliza
                                                        listarCuentas={listarCuentas}
                                                        lectura={lectura}
                                                        crear={crearC}
                                                        editar={editar}
                                                        listarCC={listarCC}
                                                        detallePoliza={detalle}
                                                        eliminarLinea={eliminarLinea}
                                                        niveles={nivel}
                                                        registroCuenta={registroCuenta}
                                                        reporteCuentas={reporteCuentas}
                                                        loaderC={loaderC}
                                                    />
                                                    <br />
                                                    <div className='d-flex flex-row mt-3'>
                                                        {disabled === false &&
                                                            <button
                                                                className='btn mr-2 mb-3 btn-primary'
                                                                onClick={() => {
                                                                    //function expression
                                                                    const funcion2 = confirmarPoliza;
                                                                    funcion2();
                                                                }}

                                                            >
                                                                {editar ? 'Modificar' : 'Confirmar poliza'}
                                                            </button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPane>
                            </Tabs>

                        }
                        {datos.inmobiliaria === 'N' && datos.inventario === 'N' && datos.contabilidad === 'N' &&
                            <div className="py-2 px-3">
                                <div className="mb-4 card card-small text-white bg-dark mb-1">
                                    <div className="p-0 pt-4 d-flex flex-column flex-md-row">
                                        <div className="d-flex flex-column flex-1 mx-4">
                                            <LoadMask loading={loader} dark>
                                                <Documento
                                                    crear={crearC}
                                                    lectura={lectura}
                                                    lectura_data={lectura_data}
                                                    bloqueo={disabled}
                                                    editar={editar}
                                                    tipos={tipos}
                                                    fechaInicio={periodoD.fecha_inicio}
                                                    fechaFinal={periodoD.fecha_fin}
                                                    listarProveedores={listarProveedores}
                                                    registroProveedor={registroProveedor}
                                                    listarProveedoresD={prov}
                                                    datos={datos}
                                                    impuesto={impuesto}
                                                    obtenerPaises = { listaP }
                                                    tipoCambio = {tipoCambio}
                                                />
                                            </LoadMask>
                                            <br />
                                            {disabled === false && !poliza &&
                                                <div className='mb-2'>
                                                    <button
                                                        className='btn mr-2 mb-2 btn-primary'
                                                        onClick={() => {
                                                            //function expression
                                                            const funcion2 = () => registroCompra(impuesto, periodoD, datos);
                                                            const funcion3 = () => modificarCompra(impuesto, periodoD, datos);
                                                            //function call:ejecuta
                                                            if (crearC === true){
                                                                funcion2();
                                                            }
                                                            if (crearC === false){
                                                                funcion3();
                                                            }
                                                        }}

                                                    >
                                                        {editar ? 'Modificar' : 'Guardar documento'}
                                                    </button>
                                                    <a
                                                        href={`/#/compras/${id_empresa[5]}/compras/${id_empresa[7]}`}
                                                        className='btn btn-secondary mb-2'
                                                    >
                                                        Regresar
                                                    </a>
                                                </div>
                                            }
                                            {crearC === false && editar === false &&
                                                <div className='d-flex flex-row mt-2'>
                                                    <a
                                                        href={`/#/compras/${id_empresa[5]}/compras/${id_empresa[7]}`}
                                                        className='btn btn-secondary mb-3'
                                                    >
                                                        Regresar
                                                    </a>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {datos.inmobiliaria === 'N' && datos.inventario === 'S' && datos.contabilidad === 'N' &&
                            <Tabs
                                activeKey={key}
                                onChange={(k) => setKey(k)}
                                tabBarPosition="top"
                                renderTabBar={() => <ScrollableInkTabBar />}
                                renderTabContent={() => <TabContent />}
                            >
                            <TabPane tab="Documento" key="PRINCIPAL_TOP">
                                <div className="py-2 px-3">
                                    <div className="mb-4 card card-small text-white bg-dark mb-1">
                                        <div className="p-0 pt-4 d-flex flex-column flex-md-row">
                                            <div className="d-flex flex-column flex-1 mx-4">
                                                <LoadMask loading={loader} dark>
                                                    <Documento
                                                        crear={crearC}
                                                        lectura={lectura}
                                                        lectura_data={lectura_data}
                                                        bloqueo={disabled}
                                                        editar={editar}
                                                        tipos={tipos}
                                                        fechaInicio={periodoD.fecha_inicio}
                                                        fechaFinal={periodoD.fecha_fin}
                                                        listarProveedores={listarProveedores}
                                                        registroProveedor={registroProveedor}
                                                        listarProveedoresD={prov}
                                                        datos={datos}
                                                        impuesto={impuesto}
                                                        obtenerPaises = { listaP }
                                                        tipoCambio = {tipoCambio}
                                                    />
                                                </LoadMask>
                                                <br />
                                                {disabled === false && !poliza &&
                                                    <div className='mb-2'>
                                                        <button
                                                            className='btn mr-2 mb-2 btn-primary'
                                                            onClick={() => {
                                                                //function expression
                                                                const funcion2 = () => registroCompra(impuesto, periodoD, datos);
                                                                const funcion3 = () => modificarCompra(impuesto, periodoD, datos);
                                                                //function call:ejecuta
                                                                if (crearC === true){
                                                                    funcion2();
                                                                    if (localStorage.getItem('valCompra') === 'T'){
                                                                        setKey('SEGUNDO_TOP');
                                                                    }
                                                                }
                                                                if (crearC === false){
                                                                    funcion3();
                                                                }
                                                            }}

                                                        >
                                                            {editar ? 'Modificar' : 'Guardar documento'}
                                                        </button>
                                                        <a
                                                            href={`/#/compras/${id_empresa[5]}/compras/${id_empresa[7]}`}
                                                            className='btn btn-secondary mb-2'
                                                        >
                                                            Regresar
                                                        </a>
                                                    </div>
                                                }
                                                {disabled === false && poliza && crearC &&
                                                    <div className='mb-2'>
                                                        <button
                                                            className='btn mr-2 mb-2 btn-primary'
                                                            onClick={() => setKey('SEGUNDO_TOP')}
                                                        >
                                                              Ingresar inventario
                                                        </button>
                                                        <a
                                                            href={`/#/compras/${id_empresa[5]}/compras/${id_empresa[7]}`}
                                                            className='btn btn-secondary mb-2'
                                                        >
                                                            Regresar
                                                        </a>
                                                    </div>
                                                }
                                                {crearC === false && editar === false &&
                                                    <div className='d-flex flex-row mt-2'>
                                                        <a
                                                            href={`/#/compras/${id_empresa[5]}/compras/${id_empresa[7]}`}
                                                            className='btn btn-secondary mb-3'
                                                        >
                                                            Regresar
                                                        </a>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPane>
                            <TabPane tab="Inventario" key="SEGUNDO_TOP">
                                <div className="py-2 px-3">
                                    <div className="mb-4 card card-small bg-dark mb-3">
                                        <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                                            <div className="d-flex flex-column flex-1 mx-3">
                                                <LoadMask loading={loader} dark>
                                                    <Inventario
                                                        crear={crearC}
                                                        lectura={lectura}
                                                        bloqueo={disabled}
                                                        editar={editar}
                                                        obtenerBodegas={listaBodegas}
                                                        listarProductos={listarProductos}
                                                        datos={datos}
                                                    />
                                                        {(crearC === true || editar === true) &&
                                                            <Contenido>
                                                                <button
                                                                    className='btn mr-2 mb-3 btn-primary'
                                                                    onClick={() => registroProductoInventario(impuesto, crearC ? datosCmp : lectura_data, crearC ? 'N' : 'M')}
                                                                >
                                                                    Agregar linea
                                                                </button>
                                                            </Contenido>
                                                        }
                                                        {(crearC === true || editar === true) && datos.farmaceutica === 'N' &&
                                                            <div style={estilo}>
                                                                <DetalleInventario
                                                                    detalle={detalleProducto}
                                                                    eliminarLineaProducto={eliminarLineaProducto}
                                                                />
                                                            </div>
                                                        }
                                                        {(crearC === true || editar === true) && datos.farmaceutica === 'S' &&
                                                            <div style={estiloFarm}>
                                                                <DetalleInventarioFarm
                                                                    detalle={detalleProducto}
                                                                    eliminarLineaProducto={eliminarLineaProducto}
                                                                />
                                                            </div>
                                                        }
                                                        {(crearC === false && editar === false) && datos.farmaceutica === 'N' &&
                                                            <div style={estilo}>
                                                                <DetalleInventarioVer
                                                                    detalle={detalleProducto}
                                                                />
                                                            </div>
                                                        }
                                                        {(crearC === false && editar === false) && datos.farmaceutica === 'S' &&
                                                            <div style={estiloFarm}>
                                                                <DetalleInventarioFarmVer
                                                                    detalle={detalleProducto}
                                                                />
                                                            </div>
                                                        }
                                                        <br />
                                                        <br />
                                                        <br />
                                                    </LoadMask>
                                                    <div className='mb-2'>
                                                        {detalleProducto.length >= 1 && crearC === true &&
                                                            <button
                                                                className='btn mr-2 mb-2 btn-primary'
                                                                onClick={() => {
                                                                    window.location.reload(true);
                                                                }}

                                                            >
                                                                Confirmar inventario
                                                            </button>
                                                        }
                                                        {detalleProducto.length >= 1 && editar === true &&
                                                            <a
                                                                href={`/#/compras/${id_empresa[5]}/compras/${id_empresa[7]}`}
                                                                className='btn mr-2 mb-2 btn-primary'
                                                            >
                                                            Confirmar inventario
                                                            </a>
                                                        }
                                                        {detalleProducto.length == 0 && crearC === true &&
                                                            <button
                                                                className='btn mr-2 mb-2 btn-primary'
                                                                onClick={() => {
                                                                    window.location.reload(true);
                                                                }}

                                                            >
                                                                Nueva compra
                                                            </button>
                                                        }
                                                        <a
                                                            href={`/#/compras/${id_empresa[5]}/compras/${id_empresa[7]}`}
                                                            className='btn btn-secondary mb-2'
                                                        >
                                                            Regresar
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPane>
                            </Tabs>
                        }
                    </div>
                </div>
            </div>
            <Modal
                estado={estadoModal}
                cambiarEstado={cambiarEstadoModal}
                titulo="Ingresar Detalle poliza"
                botonCerrar="true"
            >
                <div className='row mb-3'>
                    <div className='col-6'>
                        <Field
                            name="cuenta"
                            placeholder="Cuenta contable..."
                            className="form-control-find"
                            component={AsyncSelectField}
                            loadOptions={listarCuentas}
                            msj="Cuenta"
                        />
                        <button
                            className='btn ml-2 btn-dark'
                            onClick={() => cambiarEstadoModalCuenta(!estadoModalCuenta)}
                        >
                            Crear cuenta
                        </button>
                    </div>
                    <div className='col-3 mt-1 w-25'>
                        <Field
                            name='debe'
                            component={renderCurrencyPoliza}
                            className="form-control"
                            placeholder="Debe"
                            msj="Debe"
                        />
                    </div>
                    <div className='col-3 mt-1'>
                        <Field
                            name='haber'
                            component={renderCurrencyPoliza}
                            className="form-control"
                            placeholder="Haber"
                            msj="Haber"
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-9'>
                    </div>
                    <div className='col-3 title'>
                        Diferencia: {dif_poliza}
                    </div>
                </div>
                <Contenido>
                    <div className='row'>
                        <div className='col-6'>
                            <button
                                className='btn ml-3 mb-3 btn-primary'
                                onClick={registroPolizaDetalleSCC}
                            >
                                Agregar
                            </button>
                        </div>
                        <div className='col-6'>
                            <button
                                className='btn mb-3 btn btn-secondary'
                                onClick={() => cambiarEstadoModal(false)}
                            >
                                Cancelar
                            </button>
                        </div>
                    </div>
                </Contenido>
            </Modal>
            <Modal
                estado={estadoModalCC}
                cambiarEstado={cambiarEstadoModalCC}
                titulo="Ingresar Detalle poliza"
                botonCerrar="true"
            >
                <div className='row mb-3'>
                    <div className='col-6'>
                        <Field
                            autoFocus={true}
                            name="cuenta"
                            placeholder="Cuenta contable..."
                            className="form-control-find"
                            component={AsyncSelectField}
                            loadOptions={listarCuentas}
                            msj="Cuenta"
                        />
                        <button
                            className='btn ml-2 btn-dark'
                            onClick={() => cambiarEstadoModalCuenta(!estadoModalCuenta)}
                        >
                            Crear cuenta
                        </button>
                    </div>

                    <div className='col-2'>
                        <Field
                            name="cc"
                            placeholder="C.C..."
                            className="form-control-find"
                            component={AsyncSelectField}
                            loadOptions={listarCC}
                            msj="Centro Costo"
                        />
                    </div>
                    <div className='col-2 mt-1 w-25'>
                        <Field
                            name='debe'
                            component={renderCurrencyPoliza}
                            className="form-control"
                            placeholder="Debe"
                            msj="Debe"
                        />
                    </div>
                    <div className='col-2 mt-1'>
                        <Field
                            name='haber'
                            component={renderCurrencyPoliza}
                            className="form-control"
                            placeholder="Haber"
                            msj="Haber"
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-9'>
                    </div>
                    <div className='col-3 title'>
                        Diferencia: {dif_poliza}
                    </div>
                </div>
                <Contenido>
                    <div className='row'>
                        <div className='col-6'>
                            <button
                                className='btn ml-3 mb-3 btn-primary'
                                onClick={registroPolizaDetalle}
                            >
                                Agregar
                            </button>
                        </div>
                        <div className='col-6'>
                            <button
                                className='btn mr-2 mb-3 btn btn-secondary'
                                onClick={() => cambiarEstadoModalCC(false)}
                            >
                                Cancelar
                            </button>
                        </div>
                    </div>
                </Contenido>
            </Modal>
            <ModalCuenta
                estado={estadoModalCuenta}
                cambiarEstado={cambiarEstadoModalCuenta}
                titulo="Crear cuenta contable"
                botonCerrar="false"
            >
                <div className="d-flex flex-column flex-1 mx-4">
                    <div className='mb-2 w-50'>
                        <Field
                            autoFocus={autoFocus}
                            type="number"
                            id="cuenta"
                            name="cuenta"
                            placeholder="Cuenta"
                            className="form-control"
                            numberFormat={getNivel()}
                            component={renderNumber}
                            msj="Cuenta"
                        />
                    </div>
                    <div className='mb-2 w-100'>
                        <Field
                            id="nombre"
                            name="nombre"
                            placeholder="Nombre Cuenta"
                            className="form-control"
                            component={renderField}
                            msj="Nombre Cuenta"
                        />
                    </div>
                    <div className='mb-2 w-50'>
                        <div className="form-floating">
                            <Field
                                name="tipoC"
                                options={tipo}
                                crear={crear}
                                dato={crear === true ? 0 : lectura.tipo}
                                component={SelectField}
                                className="form-select"
                                msj="Tipo"
                            />
                        </div>
                    </div>
                    <div className='mb-3 w-50'>
                        <div className="form-floating">
                            <Field
                                id="naturaleza"
                                name="naturaleza"
                                options={naturaleza}
                                crear={crear}
                                dato={crear === true ? 0 : lectura.naturaleza}
                                component={SelectField}
                                className="form-select"
                                msj="Naturaleza"
                            />
                        </div>
                    </div>
                    <LoadMask loading={loaderC} dark>
                        <Contenido>
                            <div className='row'>
                                <button
                                    className='btn mr-2 mb-3 btn-primary'
                                    onClick={() => registroCuenta(getNivel())}
                                >
                                    Guardar
                                </button>
                                <button
                                    className='btn mr-2 mb-3 btn-dark'
                                    onClick={reporteCuentas}
                                >
                                    Generar reporte
                                </button>
                                <button
                                    className='btn mr-2 mb-3 btn-secondary'
                                    onClick={() => cambiarEstadoModalCuenta(false)}
                                >
                                    Cancelar
                                </button>
                            </div>
                        </Contenido>
                    </LoadMask>
                </div>
            </ModalCuenta>
        </React.Fragment>
    )

}
ComprasFormTab.propTypes = {
    handleSubmit: PropTypes.func,
    crear: PropTypes.bool,
    lectura: PropTypes.object,
    tipos: PropTypes.array,
    listarProveedores: PropTypes.func,
    loader: PropTypes.bool,
    detalle: PropTypes.array,
    eliminarLinea: PropTypes.func,
    listarCuentas: PropTypes.func,
    listarCC: PropTypes.func,
    nivel: PropTypes.object,
    registroCuenta: PropTypes.func,
    reporteCuentas: PropTypes.func,
    loaderC: PropTypes.bool,
    prov: PropTypes.array,
    registroCompra: PropTypes.func,
    modificarCompra: PropTypes.func,
    registroPolizaDetalle: PropTypes.func,
    confirmarPoliza: PropTypes.func,
    registroPolizaDetalleSCC: PropTypes.func,
    listarProveedoresD: PropTypes.func,
    registroProveedor: PropTypes.func,
    listaProyectos: PropTypes.array,
    listarSubProyectos: PropTypes.func,
    listaSubProyectos: PropTypes.array,
    listarFases: PropTypes.func,
    listaFases: PropTypes.array,
    listarMateriales: PropTypes.func,
    leerDetalleMateriales: PropTypes.func,
    registroDetalleMaterial: PropTypes.func,
    eliminarLineaProducto: PropTypes.func,
    parametrosEmpresa: PropTypes.func,
    periodoD: PropTypes.object,
    modulo: PropTypes.object,
    listaBodegas: PropTypes.array,
    listarProductos: PropTypes.func,
    registroProductoInventario: PropTypes.func,
    leerDetalleProductos: PropTypes.func,
    eliminarLineaMaterial: PropTypes.func,
    listarPaises: PropTypes.func,
    listarImpuestos: PropTypes.func,
    impuesto: PropTypes.object,
};

export default reduxForm({
    form: 'ComprasFormTabs', //identificador unico
})(ComprasFormTab)

const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;