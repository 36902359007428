import React  from 'react'
import "./FicRadio.css"
import { RadioGroup, Label } from 'react-aria-components';
import { useController } from 'react-hook-form';

export default function FicRadio({label, children, control, name, nonForm, ...props}) {
    // Los 'children' deben de ser "Radio" de 'react-aria-components' 
    //https://react-spectrum.adobe.com/react-aria/useRadioGroup.html
    
    if(nonForm) {
        return (
            <RadioGroup {...props}>
                <Label>{label}</Label>
                {children}
            </RadioGroup>
        )
    }

    const {field, fieldState} = useController({control, name})
    return (
        <RadioGroup {...field} {...props}>
            <Label>{label}</Label>
            {children}
        </RadioGroup>
    )
}