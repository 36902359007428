import React, { Component } from 'react';
import Grid from '../../Utils/GridFiltro';
import PropTypes from 'prop-types';
import { standardActions } from '../../Utils/Grid/StandardActions';
const valEmp = __CONFIG__.empresa;

class PuestoList extends Component{
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: true
    };

    componentDidMount = () => {
        const { listar} = this.props;
        listar();
    }
    render(){
        const { data, loader, eliminar } = this.props;
        let ruta = window.location.href;
        let id_emp = ruta.split('/');
        return(
            <React.Fragment>
                <br />
                <h3 className="p-2 bg-secondary title">Puestos</h3>
                <br />
                {valEmp === 'fage' &&
                    <div className="d-flex flex-row justify-content-start mb-2">
                        <a
                            href={`/#/lfg/rr_hh/${id_emp[6]}/puesto/crear`}
                            className='btn btn-primary'
                        >
                            Crear puesto
                        </a>
                    </div>
                }
                {valEmp === '' &&
                    <div className="d-flex flex-row justify-content-start mb-2">
                    <a
                            href={`/#/rr_hh/${id_emp[5]}/puesto/crear`}
                            className='btn btn-primary'
                        >
                            Crear puesto
                        </a>
                    </div>
                }
                {data &&
                    <Grid 
                        hover 
                        striped 
                        data={data}
                        loading={loader} 
                        headerStyle={ { background: 'black' } } 
                        //onPageChange={onPageChange}
                        //onSortChange={onSortChange} 
                    >
                        <TableHeaderColumn
                            isKey
                            dataField="descripcion"
                            dataAlign='left'
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Descripción
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            thStyle={
                            {color: 'white'}}
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            dataFormat={standardActions({ 
                                editar: "puesto", 
                                ver: "puesto",
                            })} 
                            
                        >
                            Acciones
                        </TableHeaderColumn>
                    </Grid>
                }
            </React.Fragment>
        );
    }
}

export default PuestoList;
