import React, { Component } from 'react';
import Grid from '../../Utils/GridFiltro';
import PropTypes from 'prop-types';
import NavbarModulo from '../../components/NavbarModulo';
import { standardActions } from '../../Utils/GridFiltro/StandardActions';
import moment from 'moment';

class RegistroCoempaqueList extends Component{
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        loading: true
    };

    componentDidMount = () => {
        const { listar, getModulo, getPais } = this.props;
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        getModulo(modulo[5],modulo[4])
        getPais(modulo[7]);
        listar(modulo[7]);
    }
    render(){
        const { data, loader, modulo, paisN, eliminarRegistro } = this.props;
        let ruta = window.location.href;
        let id_emp = ruta.split('/');
        return(
            <React.Fragment>
                <NavbarModulo
                    pais = {paisN}
                    modulo={modulo}
                />
                <br />
                <h3 className="p-2 bg-secondary title">Registros coempaques</h3>
                <br />
                <div className="d-flex flex-row justify-content-start mb-2">
                    <a
                        href={`/#/registro_sanitario/${id_emp[5]}/registro_coempaque/${id_emp[7]}/crear`}
                        className='btn btn-primary'
                    >
                        Crear registro coempaque
                    </a>
                </div>
                {data &&
                    <Grid 
                        hover 
                        striped 
                        data={data} 
                        loading={loader} 
                        headerStyle={ { background: 'black' } } 
                        //onPageChange={onPageChange}
                        //onSortChange={onSortChange} 
                    >
                        <TableHeaderColumn
                            isKey
                            dataField="productoNombre"
                            width="350"
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Producto
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="numero_autorizacion"
                            width="200"
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Número de autorización
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="fabricanteNombre"
                            width="350"
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Fabricante
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="fecha_vencimiento"
                            headerAlign="center"
                            dataAlign="right"
                            width="150"
                            dataFormat={(cell, row)=>{
                                if (cell === null){
                                    return 'N/A';
                                }else{
                                    return moment(cell).format("DD/MM/YYYY");
                                }
                            }}
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Fecha vencimiento
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            thStyle={
                            {color: 'white'}}
                            dataField="id"
                            width="100"
                            dataAlign="center"
                            dataSort
                            dataFormat={standardActions({ 
                                ver_a: "registro_coempaque",
                                editar_a: "registro_coempaque",
                                eliminar_registro_coempaque: eliminarRegistro,
                                empresa: id_emp[5],
                                periodo: id_emp[7],
                                modulo: id_emp[4],
                            })} 
                        >
                            Acciones
                        </TableHeaderColumn>
                    </Grid>
                }
            </React.Fragment>
        );
    }
}

export default RegistroCoempaqueList;
