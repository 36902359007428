import React from 'react';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import * as BsIcons from "react-icons/bs";

export const SidebarDataUserRegistroMarca = [
    {
        title: '',
        path: '#',
        icon: <FaIcons.FaBook />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav: [
            {
                title: 'Perfil',
                path: '/registro_marca',
                path_s: 'editar-perfil',
                icon: <BsIcons.BsFillPersonFill />,
            },
            {
                title: 'Cambiar contraseña',
                path: '/registro_marca',
                path_s: 'cambiar-pass',
                icon: <RiIcons.RiLockPasswordFill />,
            },
            {
                title: 'Salir',
                path: '#',
                icon: <BsIcons.BsFillDoorOpenFill />,
            }
        ]
    }
]

