import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';
const valEmp = __CONFIG__.empresa;

const LISTADO_REGISTRO = 'LISTADO_REGISTRO';
const GUARDAR_REGISTRO = 'GUARDAR_REGISTRO';
const LOADER = 'LOADER';
const MODULO = 'MODULO';
const LISTADO_PRODUCTO = 'LISTADO_PRODUCTO';
const LISTADO_FABRICANTE = 'LISTADO_FABRICANTE';
const LISTADO_ACONDICIONADOR = 'LISTADO_ACONDICIONADOR';
const LISTADO_CLASIFICACION = 'LISTADO_CLASIFICACION';
const PAIS = 'PAIS';
const LISTADO_PAISES = 'LISTADO_PAISES';
const LISTADO_COMERCIALIZADOR = 'LISTADO_COMERCIALIZADOR';
const LISTADO_PAISES_ORIGEN = 'LISTADO_PAISES_ORIGEN';
const LISTADO_LICENCIAS = 'LISTADO_LICENCIAS';
const LISTADO_VIA = 'LISTADO_VIA';
const LISTADO_TIPOS_REGISTRO = 'LISTADO_TIPOS_REGISTRO';
const CODIGO_PAIS = 'CODIGO_PAIS';
const LISTADO_REGISTRO_PRODUCTO = 'LISTADO_REGISTRO_PRODUCTO';
const CONSULTA_REGISTRO = 'CONSULTA_REGISTRO';
const LISTADO_ELZM = 'LISTADO_ELZM';
const LISTADO_REGISTRO_SANITARIO = 'LISTADO_REGISTRO_SANITARIO';
const DETALLE = 'DETALLE';
const DETALLE_REGISTROS = 'DETALLE_REGISTROS';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listar = (pais) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let params = { id, pais };
    
    dispatch(setLoader(true));
    api.get('/rs_registro_coempaque', params).then((response)=>{
        dispatch({ type: LISTADO_REGISTRO, data: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar productos',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const registroRC = (descripcionProducto, paisN) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormRegistroCoempaque.values;
    
    let id_empresa = id_emp[5]
    let pais = id_emp[7]

    if (data === undefined) {
        Swal.fire({
            title: 'Registro sanitario',
            text: 'Debe de seleccionar un producto',
            type: 'error',
        })
    } else {
        if (data.producto === undefined || data.producto === '') {
            Swal.fire({
                title: 'Registro sanitario',
                text: 'Debe de seleccionar un producto',
                type: 'error',
            })
        } else {
            if (data.fabricante === undefined || data.fabricante === '') {
                Swal.fire({
                    title: 'Registro sanitario',
                    text: 'Debe de seleccionar un fabricante',
                    type: 'error',
                })
            } else {
                if (data.acondicionador === undefined || data.acondicionador === '') {
                    Swal.fire({
                        title: 'Registro sanitario',
                        text: 'Debe de seleccionar un acondicionador',
                        type: 'error',
                    })
                } else {
                    if (data.fecha_autorizacion === undefined || data.fecha_autorizacion === '') {
                        Swal.fire({
                            title: 'Registro sanitario',
                            text: 'Debe de ingresar una fecha de autorización',
                            type: 'error',
                        })
                    } else {
                        if ((data.fecha_vencimiento === undefined || data.fecha_vencimiento === '') && paisN !== 'PAN') {
                            Swal.fire({
                                title: 'Registro sanitario',
                                text: 'Debe de ingresar una fecha de vencimiento',
                                type: 'error',
                            })
                        } else {
                            if (data.presentacion === undefined || data.presentacion === ''){
                                Swal.fire({
                                    title: 'Registro sanitario',
                                    text: 'Debe de ingresar una presentación',
                                    type: 'error',
                                })
                            }else{
                                if (data.presentacion.length > 1000){
                                    Swal.fire({
                                        title: 'Registro sanitario',
                                        text: 'La presentación no puede tener más de 1000 caracteres',
                                        type: 'error',
                                    })
                                }else{
                                    if (data.tipo_registro === undefined || data.tipo_registro === ''){
                                        Swal.fire({
                                            title: 'Registro sanitario',
                                            text: 'Debe de seleccionar un tipo de registro',
                                            type: 'error',
                                        })
                                    }else{
                                        if (data.autorizacion === undefined || data.autorizacion === ''){
                                            Swal.fire({
                                                title: 'Registro sanitario',
                                                text: 'Debe de seleccionar si requiere autorización de primer lote',
                                                type: 'error',
                                            })
                                        }else{
                                            if (data.producto_comercializado === undefined || data.producto_comercializado === ''){
                                                Swal.fire({
                                                    title: 'Registro sanitario',
                                                    text: 'Debe de seleccionar si el producto es comercializado en el pais',
                                                    type: 'error',
                                                })
                                            }else{
                                                const formData = {
                                                    producto: data.producto.value,
                                                    fabricante: data.fabricante.value,
                                                    acondicionador: data.acondicionador.value,
                                                    fecha_autorizacion: data.fecha_autorizacion,
                                                    fecha_vencimiento: data.fecha_vencimiento,
                                                    pais: pais,
                                                    comercializador: data.comercializador,
                                                    condicion: data.condicion,
                                                    presentacion: data.presentacion,
                                                    titular: data.titular,
                                                    via: data.via,
                                                    vida_util: data.vida_util,
                                                    pais_fabricante: data.pais_fabricacion,
                                                    licencia: data.licencia,
                                                    tipo_registro: data.tipo_registro,
                                                    autorizacion: data.autorizacion,
                                                    comercializado: data.producto_comercializado,
                                                    descripcionProducto: descripcionProducto,
                                                    analisis: data.analisis,
                                                    empresa: id_empresa,
                                                    elzm: data.elzm,
                                                    fecha: data.fecha,
                                                    control_estatal: data.control_estatal, 
                                                    observacion: data.observacion,
                                                    numero_autorizacion: data.numero_autorizacion,
                                                    detalleD: data.detalleD
                                                }
                                                dispatch(setLoader(true));
                                                api.post('/rs_registro_coempaque/', formData).then(() => {
                                                    NotificationManager.success(
                                                        'Registro coempaque creado correctamente',
                                                        'Exito',
                                                        3000
                                                    );
                                                    let ruta = `/registro_sanitario/${id_emp[5]}/registro_coempaque/${id_emp[7]}`
                                                    dispatch(push(ruta));
                                                }).catch((error) => {
                                                    NotificationManager.error(
                                                        error.msj,
                                                        'Error',
                                                        2000
                                                    );
                                                }).finally(() => {
                                                    dispatch(setLoader(false));
                                                });
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

export const modificarRC = (paisN) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const datos = getStore().form.FormRegistroCoempaque.values;
    
    const id = datos.id;
    if (datos === undefined) {
        Swal.fire({
            title: 'Registro sanitario',
            text: 'Debe de seleccionar un producto',
            type: 'error',
        })
    } else {
        if (datos.producto === undefined || datos.producto === '') {
            Swal.fire({
                title: 'Registro sanitario',
                text: 'Debe de seleccionar un producto',
                type: 'error',
            })
        } else {
            if (datos.fabricante === undefined || datos.fabricante === '') {
                Swal.fire({
                    title: 'Registro sanitario',
                    text: 'Debe de seleccionar un fabricante',
                    type: 'error',
                })
            } else {
                if (datos.acondicionador === undefined || datos.acondicionador === '') {
                    Swal.fire({
                        title: 'Registro sanitario',
                        text: 'Debe de seleccionar un acondicionador',
                        type: 'error',
                    })
                } else {
                    if (datos.fecha_autorizacion === undefined || datos.fecha_autorizacion === '') {
                        Swal.fire({
                            title: 'Registro sanitario',
                            text: 'Debe de ingresar una fecha de resolución',
                            type: 'error',
                        })
                    } else {
                        if ((datos.fecha_vencimiento === undefined || datos.fecha_vencimiento === '') && paisN !== 'PAN') {
                            Swal.fire({
                                title: 'Registro sanitario',
                                text: 'Debe de ingresar una fecha de vencimiento',
                                type: 'error',
                            })
                        } else {
                            if (datos.presentacion === undefined || datos.presentacion === ''){
                                Swal.fire({
                                    title: 'Registro sanitario',
                                    text: 'Debe de ingresar una presentación',
                                    type: 'error',
                                })
                            }else{
                                if (datos.presentacion.length > 1000) {
                                    Swal.fire({
                                        title: 'Registro sanitario',
                                        text: 'La presentación no puede tener más de 1000 caracteres',
                                        type: 'error',
                                    })
                                } else {
                                    if (datos.tipo_registro === undefined || datos.tipo_registro === '' || datos.tipo_registro === null) {
                                        Swal.fire({
                                            title: 'Registro sanitario',
                                            text: 'Debe de seleccionar un tipo de registro',
                                            type: 'error',
                                        })
                                    } else {
                                        if (datos.autorizacion === undefined || datos.autorizacion === '' || datos.autorizacion === null) {
                                            Swal.fire({
                                                title: 'Registro sanitario',
                                                text: 'Debe de seleccionar si requiere autorización de primer lote',
                                                type: 'error',
                                            })
                                        } else {
                                            if (datos.producto_comercializado === undefined || datos.producto_comercializado === '' || datos.producto_comercializado === null) {
                                                Swal.fire({
                                                    title: 'Registro sanitario',
                                                    text: 'Debe de seleccionar si el producto es comercializado en el pais',
                                                    type: 'error',
                                                })
                                            } else {
                                                let pais_fabricante = datos.pais_fabricacion;
                                                if (pais_fabricante === undefined) {
                                                    pais_fabricante = datos.pais_fabricante;
                                                } else {
                                                    pais_fabricante = datos.pais_fabricacion;
                                                }

                                                let via_admin = datos.via;
                                                if (via_admin === undefined) {
                                                    via_admin = datos.via_administracion;
                                                } else {
                                                    via_admin = datos.via;
                                                }

                                                let condicion = datos.condicion;
                                                if (condicion === undefined) {
                                                    condicion = datos.condicion_venta;
                                                } else {
                                                    condicion = datos.condicion;
                                                }

                                                let comercial = datos.producto_comercializado;
                                                if (comercial === undefined) {
                                                    comercial = datos.comercializado;
                                                } else {
                                                    comercial = datos.producto_comercializado;
                                                }

                                                const formData = {
                                                    id: id,
                                                    producto: datos.producto,
                                                    fabricante: datos.fabricante,
                                                    acondicionador: datos.acondicionador,
                                                    clasificacion: datos.clasificacion,
                                                    numero_autorizacion: datos.numero_autorizacion,
                                                    fecha_autorizacion: datos.fecha_autorizacion,
                                                    fecha_vencimiento: datos.fecha_vencimiento,
                                                    comercializador: datos.comercializador,
                                                    condicion: condicion,
                                                    fabricante: datos.fabricante,
                                                    presentacion: datos.presentacion,
                                                    titular: datos.titular,
                                                    via: via_admin,
                                                    vida_util: datos.vida_util,
                                                    pais_fabricante: pais_fabricante,
                                                    licencia: datos.licencia,
                                                    tipo_registro: datos.tipo_registro,
                                                    autorizacion: datos.autorizacion,
                                                    comercializado: comercial,
                                                    analisis: datos.analisis,
                                                    descripcionProducto: datos.descripcion_producto,
                                                    elzm: datos.elzm,
                                                    fecha: datos.fecha,
                                                    control_estatal: datos.control_estatal,
                                                    observacion: datos.observacion,
                                                }
                                                dispatch(setLoader(true));
                                                api.put(`/rs_registro_coempaque/${id}/`, formData).then(() => {
                                                    NotificationManager.success(
                                                        'Registro coempaque modificado correctamente',
                                                        'Exito',
                                                        3000
                                                    );
                                                    let ruta = `/registro_sanitario/${id_emp[5]}/registro_coempaque/${id_emp[7]}`
                                                    dispatch(push(ruta));
                                                }).catch((error) => {
                                                    NotificationManager.error(
                                                        'Ocurrió un error al modificar registro coempaque',
                                                        'Error',
                                                        2000
                                                    );
                                                }).finally(() => {
                                                    dispatch(setLoader(false));
                                                });
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/rs_registro_coempaque/${id}`).then((response) => {
        response.presentacion = response.presentacion_larga;
        let ruta = window.location.href;
        let id_emp = ruta.split('/');
        let producto = response.producto;
        let fabricante = response.fabricante;
        let id = id_emp[5];
        let params = { id, producto, fabricante };
        
        dispatch(setLoader(true));
        api.get('/rs_registro_coempaque/consultaRegistroSanitario/', params).then((response)=>{
            dispatch({ type: CONSULTA_REGISTRO, registroProducto: response.registro });
        }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                2000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
        localStorage.setItem('id_detalle_registro_co_rs', response.id);
        dispatch(initializeForm('FormRegistroCoempaque', response ));
        dispatch(leerDetalleRegistros(response.id));
        dispatch({ type: GUARDAR_REGISTRO, lectura: response });
    }).catch((error)=>{
        
        NotificationManager.error(
            'Ocurrió un error al consultar registro coempaque',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const listarProductos = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params;
    let id = ''
    if (valEmp === 'fage'){
        id = id_emp[6];
        params = { id };
    }else{
        id = id_emp[5];
        params = { id };
    }
    return api.get("/rs_producto", params).then(response => {
        const producto = [];
        response.forEach(item => {
            producto.push({
                value: item.id,
                label: item.descripcion
            })
        })
        dispatch({ type: LISTADO_PRODUCTO, listaPro: producto });
    }).catch(error => {
        NotificationManager.error(
            'Ocurrió un error al listar productos',
            'Error',
            8000
        );
    })
}

const buscarProductos = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params;
    let id = ''
    if (valEmp === 'fage'){
        id = id_emp[6];
        params = { id, search };
    }else{
        id = id_emp[5];
        params = { id, search };
    }
    return api.get("/rs_producto/productos_rs", params).then(response => {
        const producto = [];
        response.forEach(item => {
            producto.push({
                value: item.id,
                label: item.descripcion
            })
        })
        return producto;
    }).catch(error => {
        NotificationManager.error(
            'Ocurrió un error al listar productos',
            'Error',
            8000
        );
    })
}

const listarFabricante = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params;
    let id = ''
    if (valEmp === 'fage'){
        id = id_emp[6];
        params = { id };
    }else{
        id = id_emp[5];
        params = { id };
    }
    return api.get("/fabricante", params).then(response => {
        const fabricante = [];
        response.forEach(item => {
            fabricante.push({
                value: item.id,
                label: item.descripcion
            })
        })
        dispatch({ type: LISTADO_FABRICANTE, listaFab: fabricante });
    }).catch(error => {
        NotificationManager.error(
            'Ocurrió un error al listar fabricantes',
            'Error',
            8000
        );
    })
}

const buscarFabricante = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params;
    let id = ''
    if (valEmp === 'fage'){
        id = id_emp[6];
        params = { id, search };
    }else{
        id = id_emp[5];
        params = { id, search };
    }
    return api.get("/fabricante/fabricante_rs", params).then(response => {
        const fabricante = [];
        response.forEach(item => {
            fabricante.push({
                value: item.id,
                label: item.descripcion
            })
        })
        return fabricante;
    }).catch(error => {
        NotificationManager.error(
            'Ocurrió un error al listar fabricantes',
            'Error',
            8000
        );
    })
}

const listarAcondicionadores = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params;
    let id = ''
    if (valEmp === 'fage'){
        id = id_emp[6];
        params = { id };
    }else{
        id = id_emp[5];
        params = { id };
    }
    return api.get("/acondicionador", params).then(response => {
        const acond = [];
        response.forEach(item => {
            acond.push({
                value: item.id,
                label: item.descripcion
            })
        })
        dispatch({ type: LISTADO_ACONDICIONADOR, listaAcond: acond });
    }).catch(error => {
        NotificationManager.error(
            'Ocurrió un error al listar acondicionadores',
            'Error',
            8000
        );
    })
}

const buscarAcondicionador = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params;
    let id = ''
    if (valEmp === 'fage'){
        id = id_emp[6];
        params = { id, search };
    }else{
        id = id_emp[5];
        params = { id, search };
    }
    return api.get("/acondicionador/acondicionador_rs", params).then(response => {
        const fabricante = [];
        response.forEach(item => {
            fabricante.push({
                value: item.id,
                label: item.descripcion
            })
        })
        return fabricante;
    }).catch(error => {
        NotificationManager.error(
            'Ocurrió un error al listar acondicionadores',
            'Error',
            8000
        );
    })
}

export const getPais = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/pais_registro/${id}`).then((response)=>{
        dispatch({ type: PAIS, paisN: response.paisNombre });
        dispatch({ type: CODIGO_PAIS, codigoPais: response.codigoPais });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar pais',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


const listarPaises = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params;
    let id;
    if (valEmp === 'fage'){
        id = id_emp[6];
        params = { id };
    }else{
        id = id_emp[5];
        params = { id };
    }
    return api.get("/pais_registro", params).then(response => {
        const paisR = [];
        response.forEach(item => {
            paisR.push({
                value: item.id,
                label: item.paisNombre.toUpperCase()
            })
        })
        dispatch({ type: LISTADO_PAISES, pais: paisR });
    }).catch(error => {
        NotificationManager.error(
            'Ocurrió un error al listar paises',
            'Error',
            8000
        );
    })
}

const listarComercializador = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params;
    let id = ''
    if (valEmp === 'fage') {
        id = id_emp[6];
        params = { id };
    } else {
        id = id_emp[5];
        params = { id };
    }
    return api.get("/comercializador", params).then(response => {
        const fabricante = [];
        response.forEach(item => {
            fabricante.push({
                value: item.id,
                label: item.comercializador
            })
        })
        dispatch({ type: LISTADO_COMERCIALIZADOR, listaCom: fabricante });
    }).catch(error => {
        NotificationManager.error(
            'Ocurrió un error al listar comercializadores',
            'Error',
            8000
        );
    })
}

const listarPaisesOrigen = () => (dispatch) => {
    return api.get("/paises").then(response => {
        const pais = [];
        response.results.forEach(item => {
            pais.push({
                value: item.id,
                label: item.pais.toUpperCase()
            })
        })
        dispatch({ type: LISTADO_PAISES_ORIGEN, listaPaisOrigen: pais });
    })
}

const listarLicencias = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params;
    let id;
    if (valEmp === 'fage'){
        id = id_emp[6];
        params = { id };
    }else{
        id = id_emp[5];
        params = { id };
    }
    return api.get("/rs_licencia", params).then(response => {
        const licencia = [];
        response.forEach(item => {
            licencia.push({
                value: item.id,
                label: item.descripcion
            })
        })
        dispatch({ type: LISTADO_LICENCIAS, lic: licencia });
    }).catch(error => {
        NotificationManager.error(
            'Ocurrió un error al listar paises',
            'Error',
            8000
        );
    })
}

const listarVias = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params;
    let id = ''
    if (valEmp === 'fage') {
        id = id_emp[6];
        params = { id };
    } else {
        id = id_emp[5];
        params = { id };
    }
    return api.get("/via_admin", params).then(response => {
        const via = [];
        response.forEach(item => {
            via.push({
                value: item.id,
                label: item.descripcion
            })
        })
        dispatch({ type: LISTADO_VIA, listaVia: via });
    }).catch(error => {
        NotificationManager.error(
            'Ocurrió un error al listar vias de administración',
            'Error',
            8000
        );
    })
}

const listarTiposRegistro = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params;
    let id;
    if (valEmp === 'fage'){
        id = id_emp[6];
        params = { id };
    }else{
        id = id_emp[5];
        params = { id };
    }
    return api.get("/rs_tipo_registro", params).then(response => {
        const licencia = [];
        response.forEach(item => {
            licencia.push({
                value: item.id,
                label: item.descripcion
            })
        })
        dispatch({ type: LISTADO_TIPOS_REGISTRO, tipoRegistro: licencia });
    }).catch(error => {
        NotificationManager.error(
            'Ocurrió un error al listar paises',
            'Error',
            8000
        );
    })
}

export const datoRegistro = (fabricante) => (dispatch, getStore) => {
    const datos = getStore().form.FormRegistroCoempaque.values; 
    if (datos === undefined){
        Swal.fire({
            title: 'Registro coempaque',
            text: 'Debe de seleccionar un producto',
            type: 'error',
        })
    }else{
        if (fabricante === undefined || fabricante === '0'){
            Swal.fire({
                title: 'Registro coempaque',
                text: 'Debe de seleccionar un fabricante',
                type: 'error',
            })
        }else{
            if (datos.producto === undefined || datos.producto === ''){
                Swal.fire({
                    title: 'Registro coempaque',
                    text: 'Debe de seleccionar un producto',
                    type: 'error',
                })
            }else{
                let producto = datos.producto.value;
                let ruta = window.location.href;
                let id_emp = ruta.split('/');
                let id = id_emp[5];
                let params = { id, producto, fabricante };
                
                dispatch(setLoader(true));
                api.get('/rs_registro_sanitario/consultaRegistroSanitario/', params).then((response)=>{
                    dispatch({ type: LISTADO_REGISTRO_PRODUCTO, listRegistroProducto: response.registro });
                }).catch((error) => {
                    NotificationManager.error(
                        error.msj,
                        'Error',
                        2000
                    );
                }).finally(() => {
                    dispatch(setLoader(false));
                });
            }
        }
    }
};

export const descripcionProducto = (id) => (dispatch) => {
    dispatch(setLoader(true));
    return api.get(`/rs_producto/${id}`).then((response)=>{
        return response.descripcion;
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar producto',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


const eliminarRegistro = id => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let pais = id_emp[7];
    dispatch(setLoader(true));
    api.eliminar(`/rs_registro_coempaque/${id}`).then(() => {
        dispatch(listar(pais));
        NotificationManager.success(
            'Registro sanitario borrado correctamente',
            'Exito',
            3000
        );
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar registro coempaque',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const listarELZM = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let params = { id };
    
    return api.get("/rs_elzm", params).then(response => {
        const elzm = [];
        response.forEach(item => {
            elzm.push({
                value: item.id,
                label: item.numero + ' -- ' + item.descripcion
            })
        })
        dispatch({ type: LISTADO_ELZM, listaELZM: elzm });
    }).catch(error => {
        NotificationManager.error(
            'Ocurrió un error al listar ELZM',
            'Error',
            8000
        );
    })
}

const listarRegistrosSanitarios = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let pais = id_emp[7];
    let params = { id, search, pais };
    return api.get("/rs_registro_sanitario/buscarRegistroSanitario", params).then(response => {
        const registros = [];
        response.forEach(item => {
            registros.push({
                value: item.id,
                label: item.productoNombre + ' -- ' + item.registro + ' -- ' + item.fabricanteNombre
            })
        })
        return registros;
    }).catch(error => {
        NotificationManager.error(
            'Ocurrió un error al registros sanitarios',
            'Error',
            8000
        );
    })
}

let detalle = [];
export const agregarLinea = () => (dispatch, getStore) => {
    const data = getStore().form.FormRegistroCoempaque.values;
    if (data.registro_sanitario === undefined || data.registro_sanitario === '') {
        Swal.fire({
            title: 'Registro coempaque',
            text: 'Debe de seleccionar registro sanitario',
            type: 'error',
        })
    } else {
        let datoRegistro = data.registro_sanitario.label.split('--');
        detalle.push({
            id: data.registro_sanitario.value,
            producto: datoRegistro[0].trim(),
            registro: datoRegistro[1].trim(),
            fabricante: datoRegistro[2].trim(),
        })
        data.detalleD = detalle;
        NotificationManager.success(
            'Registro sanitario ingresado correctamente',
            'Exito',
            3000
        );
        dispatch(actualizar());
    }
}

const actualizar = () => (dispatch, getStore) => {
    const data = getStore().form.FormRegistroCoempaque.values;
    dispatch(initializeForm('FormRegistroCoempaque', data));
    dispatch({ type: DETALLE, detallePro: data.detalleD })
};

const eliminarLinea = (id) => (dispatch) => {
    let d = id.split('/');
    detalle.forEach((element, index) => {
        if (element.id === d[0]) {
            detalle.splice(index, 1);
        }
    })
    dispatch(actualizar());
};

const limpiarDatos = () => (dispatch) => {
    detalle = [];
    dispatch(initializeForm('FormRegistroCoempaque'));
    dispatch({ type: CONSULTA_REGISTRO, registroProducto: '' });
    dispatch({ type: DETALLE, detallePro: detalle })
};

const leerDetalleRegistros = id => (dispatch) => {
    api.get(`rs_registro_coempaque_detalle/${id}`).then((response) => {
        dispatch({ type: DETALLE_REGISTROS, detalleRegistro: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};

export const agregarLineaRegistro = () => (dispatch, getStore) => {
    const data = getStore().form.FormRegistroCoempaque.values;
    const detalle = localStorage.getItem('id_detalle_registro_co_rs');
    if (data.registro_sanitario === undefined || data.registro_sanitario === '') {
        Swal.fire({
            title: 'Registro coempaque',
            text: 'Debe de seleccionar registro sanitario',
            type: 'error',
        })
    } else {
        const formData = {
            id: data.registro_sanitario.value,
            detalle: detalle,
        }
        dispatch(setLoader(true));
        api.post('/rs_registro_coempaque_detalle/', formData).then(() => {
            NotificationManager.success(
                'Registro sanitario ingresado correctamente',
                'Exito',
                3000
            );
            dispatch(leerDetalleRegistros(detalle));
        }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                2000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
}

const eliminarLineaRegistro = id => (dispatch) => {
    const detalle = localStorage.getItem('id_detalle_registro_co_rs');
    dispatch(setLoader(true));
    api.eliminar(`/rs_registro_coempaque_detalle/${id}`).then(() => {
        dispatch(leerDetalleRegistros(detalle));
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar registro sanitario',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const actions = {
    listar,
    registroRC,
    modificarRC,
    leer,
    getModulo,
    listarProductos,
    listarAcondicionadores,
    listarFabricante,
    getPais,
    listarPaises,
    listarComercializador,
    listarPaisesOrigen,
    listarLicencias,
    listarVias,
    listarTiposRegistro,
    datoRegistro,
    descripcionProducto,
    buscarProductos,
    buscarFabricante,
    buscarAcondicionador,
    eliminarRegistro,
    listarELZM,
    listarRegistrosSanitarios,
    agregarLinea,
    eliminarLinea,
    limpiarDatos,
    agregarLineaRegistro,
    eliminarLineaRegistro
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_REGISTRO]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [LISTADO_PRODUCTO]: (state, { listaPro }) => {
        return {
            ...state,
            listaPro,
        };
    },
    [LISTADO_FABRICANTE]: (state, { listaFab }) => {
        return {
            ...state,
            listaFab,
        };
    },
    [LISTADO_ACONDICIONADOR]: (state, { listaAcond }) => {
        return {
            ...state,
            listaAcond,
        };
    },
    [LISTADO_CLASIFICACION]: (state, { listaClasificacion }) => {
        return {
            ...state,
            listaClasificacion,
        };
    },
    [GUARDAR_REGISTRO]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
    [PAIS]: (state, { paisN }) => {
        return {
            ...state,
            paisN,
        };
    },
    [LISTADO_PAISES]: (state, { pais }) => {
        return {
            ...state,
            pais,
        };
    },
    [LISTADO_COMERCIALIZADOR]: (state, { listaCom }) => {
        return {
            ...state,
            listaCom,
        };
    },
    [LISTADO_PAISES_ORIGEN]: (state, { listaPaisOrigen }) => {
        return {
            ...state,
            listaPaisOrigen,
        };
    },
    [LISTADO_LICENCIAS]: (state, { lic }) => {
        return {
            ...state,
            lic,
        };
    },
    [LISTADO_VIA]: (state, { listaVia }) => {
        return {
            ...state,
            listaVia,
        };
    },
    [LISTADO_TIPOS_REGISTRO]: (state, { tipoRegistro }) => {
        return {
            ...state,
            tipoRegistro,
        };
    },
    [CODIGO_PAIS]: (state, { codigoPais }) => {
        return {
            ...state,
            codigoPais,
        };
    },
    [LISTADO_REGISTRO_PRODUCTO]: (state, { listRegistroProducto }) => {
        return {
            ...state,
            listRegistroProducto,
        };
    },
    [CONSULTA_REGISTRO]: (state, { registroProducto }) => {
        return {
            ...state,
            registroProducto,
        };
    },
    [LISTADO_ELZM]: (state, { listaELZM }) => {
        return {
            ...state,
            listaELZM,
        };
    },
    [LISTADO_REGISTRO_SANITARIO]: (state, { listReg }) => {
        return {
            ...state,
            listReg,
        };
    },
    [DETALLE]: (state, { detallePro }) => {
        return {
            ...state,
            detallePro,
        };
    },
    [DETALLE_REGISTROS]: (state, { detalleRegistro }) => {
        return {
            ...state,
            detalleRegistro,
        };
    }
};

export const initialState = {
    loader: false,
    modulo: [],
    lectura: [],
    listaPro: [],
    listaAcond: [],
    listaFab: [],
    listaClasificacion: [],
    data: [],
    paisN: '',
    pais: [],
    listaCom: [],
    listaPaisOrigen: [],
    lic: [],
    listaVia: [],
    tipoRegistro: [],
    codigoPais: '',
    listRegistroProducto: '',
    registroProducto: '',
    listaELZM : [],
    listReg: [],
    detallePro: [],
    detalleRegistro: [],
};

export default handleActions(reducers, initialState)
 