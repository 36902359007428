import React, { Component } from 'react';
import Formulario from './SubirOcupacionesForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";

class SubirOcupaciones extends Component{
    state={
        archivo: null
    }
    
    setArchivo = (archivo) => {
        this.setState({archivo});
    };
    
    subirOcupaciones = (data) => {
        const { subirOcupaciones } = this.props;
        subirOcupaciones( {...data, archivo: null},
        [{ "file": this.state.archivo, "name": 'archivo' },])
    }

    render(){
        const { loader } = this.props;
    
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    onSubmit = {this.subirOcupaciones}
                    setArchivo={this.setArchivo} 
                />
            </LoadMask>
        );
    }
}

export default SubirOcupaciones
