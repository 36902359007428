import React, { Component } from 'react';
import Grid from '../../../Utils/GridNotaCobro';
import Swal from 'sweetalert2';

class DetalleEnvio extends Component{
    
    render(){
        const { loader, detalle } = this.props;
        const cellEditProp = {
            mode: 'click'
        };
        function validarDosDecimales(value) {
            let decimal = 5;
            let splitVal = value.split('.');
            if (splitVal.length == 2 && splitVal[1].length == decimal) {
                return true;    
            }else{
                Swal.fire({
                    title: 'Factura',
                    text: 'Debe de ingresar 5 decimales en precio',
                    type: 'error',
                })   
                return 'Debe de ingresar 5 decimales en precio';
            }
        }
        return(
            <React.Fragment>
                {detalle &&
                <Grid 
                    hover 
                    striped 
                    data={detalle} 
                    loading={loader}
                    cellEdit={ cellEditProp }
                    footer
                    headerStyle={ { background: 'black' } } 
                >
                <TableHeaderColumn
                    dataField="cantidad"
                    dataAlign='right'
                    width="200"
                    headerAlign="center"
                    dataSort
                    editable={{ type:'number' }}
                    dataFormat={(cell, row)=>{
                        return new Intl.NumberFormat("en-US",{minimumFractionDigits: 6}).format(cell);
                    }}
                    thStyle={
                        {color: 'white'}}
                    tdStyle={
                        {color: 'white'}}
                >
                    Cantidad
                </TableHeaderColumn>
                <TableHeaderColumn
                    isKey
                    dataField="sku"
                    width="200"
                    dataSort
                    thStyle={
                        {color: 'white'}}
                    tdStyle={
                        {color: 'white'}}
                    >
                    SKU
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="productoN"
                    dataSort
                    editable={ false }
                    thStyle={
                        {color: 'white'}}
                    tdStyle={
                        {color: 'white'}}
                    >
                    Producto
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="unidad"
                    dataAlign='right'
                    width="200"
                    headerAlign="center"
                    dataSort
                    editable={ true }
                    dataFormat={(cell, row)=>{
                        return new Intl.NumberFormat("en-US",{minimumFractionDigits: 0}).format(cell);
                    }}
                    thStyle={
                        {color: 'white'}}
                    tdStyle={
                        {color: 'white'}}
                >
                    Unidad
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="cantidad_insumo"
                    dataAlign='right'
                    width="200"
                    headerAlign="center"
                    dataSort
                    editable={ false }
                    dataFormat={(cell, row)=>{
                        return new Intl.NumberFormat("en-US",{minimumFractionDigits: 0}).format(cell);
                    }}
                    thStyle={
                        {color: 'white'}}
                    tdStyle={
                        {color: 'white'}}
                    >
                        Cajas
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="precio"
                    dataAlign='right'
                    width="200"
                    headerAlign="center"
                    dataSort
                    editable={{ type:'number', validator: validarDosDecimales }}
                    dataFormat={(cell, row)=>{
                        return new Intl.NumberFormat("en-US",{minimumFractionDigits: 5}).format(cell);
                    }}
                    thStyle={
                        {color: 'white'}}
                    tdStyle={
                        {color: 'white'}}
                >
                    Precio
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataAlign='right'
                    width="200"
                    headerAlign="center"
                    dataSort
                    editable={ false }
                    dataFormat={(cell, row)=>{
                        if (parseInt(row.unidad) === 0){
                            return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(row.cantidad*row.precio);
                        }else{
                            return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(row.unidad*row.precio);
                        }
                    }}
                    thStyle={
                        {color: 'white'}}
                    tdStyle={
                        {color: 'white'}}
                >
                    Total
                </TableHeaderColumn>
                </Grid>
                }
            </React.Fragment>
        );
    }
}

export default DetalleEnvio;
