import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import NavbarModulo from '../../components/NavbarModulo';
import Grid from '../../Utils/GridVDocumento';
import moment from 'moment';
import styled from 'styled-components';
import TablaDetalle from './ReciboCajaDetalle';
import TablaVerDetalle from './ReciboCajaDetalleVer';

import {
    renderField,
    renderDayPickerB,
    AsyncSelectField,
    renderNumber,
    SelectField,
    AsyncSelectFieldIn,
    renderCurrencyFloat
} from "../../Utils/renderField/renderField";
import propTypes from 'prop-types';

function ReciboCajaForm(props) {
    const {
        crear,
        periodoD,
        modulo,
        registroRecibo,
        numeroSerie,
        listarSeries,
        lectura,
        listarClientes,
        leerDocumentos,
        fechaFinal,
        moneda,
        list_doc,
        doc,
        loader,
        agregarLinea,
        detalleDocN,
        detalleDocumentos,
        eliminarLineaAbonoNuevo
    } = props;

    let autoFocus = true;


    const estilo = {
        overflow: "auto",
        height: "200px",
    };
    let ruta = window.location.href;
    let id_empresa = ruta.split('/');

    const editar = window.location.href.includes('editar');
    let titulo = editar ? 'Modificar recibo de caja' : 'Crear recibo de caja';
    let disabled = false;
    if (crear === false && editar === false) {
        disabled = true;
        titulo = 'Ver recibo de caja'
    }
    const [numeroSerieR, setNumeroSerieR] = useState('');
    
    let ns;
    const handleOnChangeS = value => {
        ns = numeroSerie(value.label);
        ns.then(d => setNumeroSerieR(d))
    };
    
    const tipo = [
        { label: "EFECTIVO", value: "E" },
        { label: "CHEQUE", value: "C" },
    ];
      
    const handleOnChange = value => {
        leerDocumentos(value.value, fechaFinal, moneda);
    };

    return (
        <div>
            <NavbarModulo
                periodoD={periodoD}
                modulo={modulo}
                titulo={titulo}
            />
            <br />
            <div className="mb-4 card card-small text-white bg-dark mb-3">
                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                    <div className="d-flex flex-column flex-1 mx-4">
                        {(crear === false && editar === false) &&
                            <div className='row mb-2'>
                                <div className='col-3'>
                                    <Field
                                        autoFocus={autoFocus}
                                        disabled={disabled}
                                        name="fechaRegistro"
                                        component={renderDayPickerB}
                                        msj="Fecha registro"
                                    />
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="serie"
                                        placeholder="Nombre"
                                        className="form-control"
                                        component={renderField}
                                        disabled={disabled}
                                        msj="Serie"
                                    />
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="numero"
                                        placeholder="Dias credito"
                                        className="form-control"
                                        component={renderNumber}
                                        disabled={disabled}
                                        msj="Numero"
                                    />
                                </div>
                                <div className='col-3'>
                                    <div className="form-floating">
                                        <Field
                                            name="tipo_pago"
                                            options={tipo}
                                            crear={crear}
                                            dato={crear === true ? 0 : lectura.tipo_pago}
                                            component={SelectField}
                                            className="form-select"
                                            disabled={disabled}
                                            msj="Tipo de pago"
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {crear === true &&
                            <div className='row mb-2'>
                                <div className='col-3'>
                                    <Field
                                        autoFocus={autoFocus}
                                        disabled={disabled}
                                        name="fechaRegistro"
                                        component={renderDayPickerB}
                                        msj="Fecha registro"
                                    />
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="serie"
                                        placeholder="Serie..."
                                        className="form-control-find"
                                        loadOptions={listarSeries}
                                        component={AsyncSelectField}
                                        onChange={handleOnChangeS}
                                        msj="Serie"
                                    />
                                </div>
                                <div className='col-3'>
                                    <div className="form-floating">
                                        <input
                                            placeholder="Numero"
                                            type="number"
                                            autocomplete="off"
                                            name="numero"
                                            className="form-control"
                                            value={numeroSerieR}
                                            onChange={e => setNumeroSerieR(e.target.value)}
                                        />
                                        <label className='form-label' htmlFor="cuenta">
                                            Numero
                                        </label>
                                    </div>
                                </div>
                                <div className='col-3'>
                                    <div className="form-floating">
                                        <Field
                                            name="tipo_pago"
                                            options={tipo}
                                            crear={crear}
                                            dato={crear === true ? 0 : lectura.tipo_documento}
                                            component={SelectField}
                                            className="form-select"
                                            disabled={disabled}
                                            msj="Tipo de pago"
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {(crear === true) &&
                            <div>
                                <div className='row mb-2'>
                                    <div className='col-9'>
                                        <Field
                                            name="cliente_documento"
                                            placeholder="Cliente..."
                                            className="form-control-find"
                                            component={AsyncSelectField}
                                            loadOptions={listarClientes}
                                            onChange={handleOnChange}
                                            msj="Cliente"
                                        />
                                    </div>
                                    <div className='col-3'>
                                        <Field
                                            name="monto"
                                            placeholder="Monto"
                                            className="form-control"
                                            component={renderCurrencyFloat}
                                            msj="Monto"
                                        />
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <div className='col-12'>
                                        <Field
                                            name="observaciones"
                                            disabled={disabled}
                                            placeholder="Observaciones"
                                            className="form-control"
                                            component={renderField}
                                            msj="Observaciones"
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {(crear === false && editar === false) &&
                            <div>
                                <div className='row mb-2'>
                                    <div className='col-9'>
                                        <Field
                                            name="clienteN"
                                            disabled={disabled}
                                            placeholder="Cliente"
                                            className="form-control"
                                            component={renderField}
                                            msj="Cliente"
                                        />
                                    </div>
                                    <div className='col-3'>
                                        <Field
                                            name="total"
                                            placeholder="Total"
                                            className="form-control"
                                            component={renderCurrencyFloat}
                                            msj="Total"
                                        />
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <div className='col-12'>
                                        <Field
                                            name="observaciones"
                                            disabled={disabled}
                                            placeholder="Observaciones"
                                            className="form-control"
                                            component={renderField}
                                            msj="Observaciones"
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        <br />
                        {(crear === true) &&
                            <div>
                                <h5 className="p-2 bg-secondary title mt-1">Documentos con saldo</h5>
                                <div style={estilo}>
                                    <Grid
                                        hover
                                        striped
                                        data={doc}
                                        loading={loader}
                                        headerStyle={{ background: 'black' }}

                                    >
                                        <TableHeaderColumn
                                            isKey
                                            dataField="fecha"
                                            dataSort
                                            dataFormat={(cell, row) => {
                                                return moment(cell).format("DD/MM/YYYY");
                                            }}
                                            thStyle={
                                                { color: 'white' }}
                                            tdStyle={
                                                { color: 'white' }}
                                        >
                                            Fecha
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="serie"
                                            dataSort
                                            thStyle={
                                                { color: 'white' }}
                                            tdStyle={
                                                { color: 'white' }}
                                        >
                                            Serie
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="numero"
                                            dataSort
                                            thStyle={
                                                { color: 'white' }}
                                            tdStyle={
                                                { color: 'white' }}
                                        >
                                            Numero
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="clienteN"
                                            width="350"
                                            dataSort
                                            thStyle={
                                                { color: 'white' }}
                                            tdStyle={
                                                { color: 'white' }}
                                        >
                                            Cliente
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="total"
                                            headerAlign="center"
                                            dataAlign='right'
                                            dataFormat={(cell, row) => {
                                                if (parseFloat(cell) === 0.00) {
                                                    return ''
                                                } else {
                                                    return new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(cell);
                                                }
                                            }}
                                            dataSort
                                            thStyle={
                                                { color: 'white' }}
                                            tdStyle={
                                                { color: 'white' }}
                                        >
                                            Total
                                        </TableHeaderColumn>
                                    </Grid>
                                </div>
                                <br />
                                <div className='row mb-2'>
                                    <div className='col-8 mt-2'>
                                        <Field
                                            name="documento"
                                            placeholder="Documento..."
                                            className="form-control-find"
                                            loadOptions={list_doc}
                                            component={AsyncSelectFieldIn}
                                            msj="Documento"
                                        />
                                    </div>
                                    <div className='col-3 mt-2'>
                                        <div className="form-floating">
                                            <Field
                                                name="abono"
                                                placeholder="Abono"
                                                className="form-control"
                                                component={renderCurrencyFloat}
                                                disabled={false}
                                                msj="Abono"
                                            />
                                        </div>
                                    </div>
                                </div>
                                {(crear === true) &&
                                    <Contenido>
                                        <button
                                            className='btn mr-2 mb-3 btn-primary'
                                            onClick={() => agregarLinea(periodoD)}
                                        >
                                            Agregar documento
                                        </button>
                                        <div className='row mb-2'>
                                            <div className='col-6'>
                                                <div className="form-floating">
                                                    <Field
                                                        name="diferencia"
                                                        placeholder="Diferencia"
                                                        className="form-control"
                                                        component={renderCurrencyFloat}
                                                        disabled={true}
                                                        msj="Diferencia"
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-6'>
                                                <div className="form-floating">
                                                    <Field
                                                        name="totalAbono"
                                                        placeholder="Total Abono"
                                                        className="form-control"
                                                        component={renderCurrencyFloat}
                                                        disabled={true}
                                                        msj="Total Abono"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </Contenido>
                                }
                                {(crear === true) &&
                                    <div>
                                        <h5 className="p-2 bg-secondary title mt-1">Documentos agregados</h5>
                                        <div style={estilo}>
                                            <TablaDetalle
                                                detalle={detalleDocN}
                                                eliminarDocumento={eliminarLineaAbonoNuevo}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                        <br />
                        {crear === false && editar === false &&
                            <div>
                                <h5 className="p-2 bg-secondary title mt-1">Documentos agregados</h5>
                                <div style={estilo}>
                                    <TablaVerDetalle
                                        detalle={detalleDocumentos}
                                    />
                                </div>
                            </div>
                        }
                        {(crear === true) &&
                            <div className='mb-2'>
                                <button
                                    className='btn mr-2 mb-2 btn-primary'
                                    onClick={() => registroRecibo(periodoD, numeroSerieR)}
                                >
                                    {editar ? 'Modificar' : 'Guardar recibo'}
                                </button>
                                <a
                                    href={`/#/produccion/${id_empresa[5]}/recibo_caja/${id_empresa[7]}`}
                                    className='btn btn-secondary mb-2'
                                >
                                    Regresar
                                </a>
                            </div>
                        }
                        {(crear === false && editar === false) &&
                            <div className='mb-2'>
                                <a
                                    href={`/#/produccion/${id_empresa[5]}/recibo_caja/${id_empresa[7]}`}
                                    className='btn btn-secondary mb-2'
                                >
                                    Regresar
                                </a>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

ReciboCajaForm.propTypes = {
    crear: PropTypes.bool,
    editar: PropTypes.bool,
    bloqueo: PropTypes.bool,
    fechaInicio: PropTypes.string,
    fechaFinal: PropTypes.string,
    datos: PropTypes.object,
    periodoD: PropTypes.object,
    modulo: PropTypes.object,
    cli: propTypes.object,
    numeroSerie: propTypes.func,
};


export default reduxForm({
    form: 'FormReciboCajaProduccion',
})(ReciboCajaForm);


const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;