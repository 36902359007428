import React from "react";
import FicModal from "../../../Utils/FormInputComponents/FicModal";
import {
    FicDateRangePicker,
    FicDatePicker,
    FicComboBox,
    FicCheckbox,
} from "../../../Utils/FormInputComponents";
import GenBtn from "../../../components/GenBtn";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { reporteAntiguedadDeSaldos } from "./actions";
import { getClientes } from "./actions";
import InfoPopover from "../../../components/Popover";

const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const ButtonContainer = styled.div`
    display: flex;
    width: 100%;
    gap: 2rem;

    & > :nth-child(1) {
        flex-grow: 4;
    }
`;

const schema = yup.object({});

export default function Modal() {
    const { control, reset, handleSubmit } = useForm({
        mode: "onSubmit",
        resolver: yupResolver(schema),
        defaultValues: {
            fechaAl: null,
            cliente: null,
            detallado: false,
        },
    });

    const onSubmit = (formData) => {
        if (formData.cliente) {
            formData.cliente = formData.cliente.value;
        }
        reporteAntiguedadDeSaldos(formData);
        reset();
    };

    return (
        <FicModal
            title="Antiguedad de saldos"
            triggerText="Antiguedad de saldos"
        >
            <Form onSubmit={handleSubmit(onSubmit)}>
                <FicComboBox
                    componentStyle={{ flexGrow: "1", maxWidth: "400px" }}
                    control={control}
                    label="Cliente"
                    name="cliente"
                    loadOptions={getClientes}
                />
                <FicDatePicker
                    label="Fecha al"
                    control={control}
                    name="fechaAl"
                />
                <FicCheckbox
                    label="Detallado?"
                    control={control}
                    name="detallado"
                />
                <ButtonContainer>
                    <GenBtn type="submit">Generar</GenBtn>
                    <InfoPopover style={{ maxWidth: "450px" }}>
                        <span>
                            <strong style={{ fontWeight: "bold" }}>
                                Cliente (opcional):
                            </strong>{" "}
                            Seleccionar un agente para filtrar por un solo
                            agente, dejar en blanco para mostrar todos.
                            <hr />
                            <strong style={{ fontWeight: "bold" }}>
                                Fecha al (opcional):
                            </strong>{" "}
                            Seleccionar una fecha o dejar en blanco para mostrar
                            todos.
                        </span>
                    </InfoPopover>
                </ButtonContainer>
            </Form>
        </FicModal>
    );
}
