import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";

const LISTADO_SUBPROYECTO = 'LISTADO_SUBPROYECTO';
const LISTADO_PROYECTOS = 'LISTADO_PROYECTOS';
const GUARDAR_SUBPROYECTO = 'GUARDAR_SUBPROYECTO';
const LOADER = 'LOADER';
const MODULO = 'MODULO';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    //const params = { page, id };
    const params = { id };
    dispatch(setLoader(true));
    api.get('/subproyecto', params).then((response)=>{
        dispatch({ type: LISTADO_SUBPROYECTO, data: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar subproyecto',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const registroSubProyecto = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormSubProyecto.values;
    const formData = {
        codigo: data.codigo,
        proyecto: data.proyecto,
        subproyecto: data.subproyecto,
        descripcion: data.descripcion,
        empresa: id_emp[5]
    }
    dispatch(setLoader(true));
    api.post('/subproyecto/', formData).then((response) => {
        NotificationManager.success(
        'Subproyecto creado',
        'Exito',
        3000
    );
    let ruta = `/inmobiliaria/${id_emp[5]}/subproyectos`
    dispatch(push(ruta));
    }).catch((error) => {
    NotificationManager.error(
        error.msj,
        'Error',
        2000
    );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const modificarSubProyecto = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const datos = getStore().form.FormSubProyecto.values;
    const id = datos.id;
    const formData = {
        id: id,
        proyecto: datos.proyecto,
        codigo: datos.codigo,
        subproyecto: datos.subproyecto,
        descripcion: datos.descripcion
    }
    dispatch(setLoader(true));
    api.put(`/subproyecto/${id}/`, formData).then((response) => {
        NotificationManager.success(
            'Subproyecto modificado correctamente',
            'Exito',
            3000
        );
        let ruta = `/inmobiliaria/${id_emp[5]}/subproyectos`
        dispatch(push(ruta));
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al modificar subproyecto',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/subproyecto/${id}`).then((response) => {
        dispatch({type: GUARDAR_SUBPROYECTO, lectura: response });
        dispatch(initializeForm('FormSubProyecto', response ));
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar subproyecto',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

const listarProyectos = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    return api.get('/proyecto', params).then((response)=>{
        const tipos = [];
        response.forEach(item=>{
            tipos.push({
                value: item.id,
                label: item.proyecto
            })
        })
        dispatch({ type: LISTADO_PROYECTOS, listaProyectos: tipos });
    }).catch(error=>{
        NotificationManager.error(
            'Ocurrió un error al listar los proyectos',
            'Error',
            0
        );
    })
}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const actions = {
    listar,
    registroSubProyecto,
    modificarSubProyecto,
    leer,
    listarProyectos,
    getModulo
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_SUBPROYECTO]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [GUARDAR_SUBPROYECTO]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
    [LISTADO_PROYECTOS]: (state, { listaProyectos }) => {
        return {
            ...state,
            listaProyectos,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
};

export const initialState = {
    loader: false,
    lectura: [],
    modulo: [],
    data: [],
    listarProyectos: [],
};

export default handleActions(reducers, initialState)
