import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import Modal from '../../components/ModalA'

import {
    renderField,
    SelectField,
    AsyncSelectField,
} from "../../Utils/renderField/renderField";

function DetalleProyectoForm(props) {
    const {
        crear,
        lectura,
        bloqueo,
        editar,
        obtenerProyectos,
        listarSuProyectos,
        obtenerSubProyectos,
        obtenerFases,
        listarMateriales,
        encabezado
    } = props;

    const [cantidad, setCantidad] = useState(0.00);
    const [total, setTotal] = useState(0.0);
    const [proyecto, setProyecto] = useState(0);
    
    const [estadoModal, cambiarEstadoModal] = useState(false);

    const handleChangeProyecto = (e) => {
        setProyecto(e.target.value);
        listarSuProyectos(e.target.value);
        localStorage.setItem('proyecto_compra', e.target.value);
    }
    
    return (
        <div>
            {(crear === false && editar === false) &&
                <div className='row mb-4'>
                    <div className='col-6'>
                        <Field
                            name="Proyecto"
                            options={obtenerProyectos}
                            crear={crear}
                            dato={encabezado.proyecto}
                            component={SelectField}
                            className="form-select"
                            msj="Proyecto"
                        />
                    </div>
                    <div className='col-3'>
                        {obtenerSubProyectos.length > 0 &&
                            <Field
                                name="subproyecto"
                                options={obtenerSubProyectos}
                                crear={crear}
                                dato={encabezado.subproyecto}
                                component={SelectField}
                                className="form-select"
                                msj="Subproyecto"
                            />
                        }
                    </div>
                    <div className='col-3'>
                        <Field
                            name="fase"
                            options={obtenerFases}
                            crear={crear}
                            dato={encabezado.fase}
                            component={SelectField}
                            className="form-select"
                            msj="Fase"
                        />
                    </div>
                </div>
            }
            {(crear === true || editar == true) &&
                <div className='row mb-2'>
                    <div className='col-6'>
                        <div className="form-floating">
                            <select className="form-select" id="proyecto" name="proyecto"
                                value={editar === true ? encabezado.proyecto : proyecto} onChange={handleChangeProyecto} >
                                <option selected>Seleccionar...</option>
                                {obtenerProyectos.map(
                                    pro => <option key={pro.value} value={pro.value}
                                    >{pro.label}
                                    </option>)
                                }
                            </select>
                            <label className='form-label' htmlFor="proyecto">Proyecto</label>
                        </div>
                    </div>
                    <div className='col-3'>
                        {obtenerSubProyectos.length > 0 &&
                            <Field
                                name="subproyecto"
                                options={obtenerSubProyectos}
                                crear={crear}
                                dato={crear === true ? 0 : encabezado.subproyecto}
                                component={SelectField}
                                className="form-select"
                                msj="Subproyecto"
                            />
                        }
                    </div>
                    <div className='col-3'>
                        <Field
                            name="fase"
                            options={obtenerFases}
                            crear={crear}
                            dato={crear === true ? 0 : encabezado.fase}
                            component={SelectField}
                            className="form-select"
                            msj="Fase"
                        />
                    </div>
                
                </div>
            }
            {(crear === true || editar == true) &&
                <div className='row mb-2'>
                    <div className='col-6'>
                        <Field
                            name="material"
                            placeholder="Material..."
                            className="form-control-find"
                            component={AsyncSelectField}
                            loadOptions={listarMateriales}
                            msj="Material"
                        />
                    </div>
                    <div className='col-2'>
                        <Field
                            name="cantidad"
                            placeholder="Cantidad"
                            className="form-control"
                            component={renderField}
                            disabled={bloqueo}
                            onChange={(e, value) => {
                                if (value) {
                                    setCantidad(value);
                                }
                            }}
                            msj="Cantidad"
                        />
                    </div>
                    <div className='col-2'>
                        <Field
                            name="precio"
                            placeholder="Precio Unitario"
                            className="form-control"
                            component={renderField}
                            onChange={(e, value) => {
                                if (value) {
                                    let total = parseFloat(parseFloat(value)*parseFloat(cantidad)).toFixed(2);
                                    setTotal(total);
                                    localStorage.setItem('total_material', total)
                                }
                            }}
                            msj="Precio Unitario"
                        />
                    </div>
                    <div className='col-2'>
                        <div className="form-floating">
                            <NumberFormat
                                className={classNames('form-control')}
                                decimalScale={2}
                                disabled={true}
                                placeholder="Base"
                                fixedDecimalScale
                                thousandSeparator
                                value={total}
                                name="total"
                            />
                            <label className='form-label' htmlFor="total">
                                Total
                            </label>
                        </div>
                    </div>
                </div>
            }
            <Modal
                estado={estadoModal}
                cambiarEstado={cambiarEstadoModal}
                titulo="Crear material"
                botonCerrar="true"
            >
                <div className="d-flex flex-column flex-1 mx-4">
                </div>
            </Modal>
        </div>
    );
}

DetalleProyectoForm.propTypes = {
    crear: PropTypes.bool,
    lectura: PropTypes.object,
    editar: PropTypes.bool,
    bloqueo: PropTypes.bool,
    registroProveedor: PropTypes.func,
    obtenerProyectos: PropTypes.array,
    listarSuProyectos: PropTypes.func,
    obtenerSubProyectos: PropTypes.array,
    obtenerFases: PropTypes.array,
    listarMateriales: PropTypes.object
};

export default reduxForm({
    form: 'FormDetallePro',
})(DetalleProyectoForm);
