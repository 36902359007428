import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import Consulta from './ConsultaFactura';
import NavbarModulo from '../../components/NavbarModulo';

import {
    renderField,
} from "../../Utils/renderField/renderField";

function BuscarFacturaForm(props) {
    const { buscarFactura, factura, modulo } = props;

    let titulo = 'Buscar factura';
    localStorage.setItem('tituloForm', titulo);
    let autoFocus = true;
    const estilo = {
        overflow: "auto",
        height: "100px",
    };
    return (
        <div>
            <NavbarModulo
                modulo={modulo}
                titulo={titulo}
            />
            <br />
            <div className="mb-4 card card-small text-white bg-dark mb-3">
                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                    <div className="d-flex flex-column flex-1 mx-4">
                        <div className='mb-2 row'>
                            <div className='col-3'>
                                <Field
                                    autoFocus={autoFocus}
                                    name="serie"
                                    placeholder="Serie"
                                    className="form-control"
                                    component={renderField}
                                    msj="Serie"
                                />
                            </div>
                            <div className='col-3'>
                                <Field
                                    name="numero"
                                    placeholder="Numero"
                                    className="form-control"
                                    component={renderField}
                                    msj="Numero"
                                />
                            </div>
                            <div className='col-3 mt-2'>
                                <button
                                    className='btn mr-2 mb-3 btn-primary'
                                    onClick={buscarFactura}
                                >
                                    Buscar
                                </button>
                            </div>
                        </div>
                        <br/>
                        <div style={estilo}>
                            <Consulta
                                detalle = {factura}
                            />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

BuscarFacturaForm.propTypes = {
    handleSubmit: PropTypes.func,
    crear: PropTypes.bool,
    lectura: PropTypes.object,
    buscarFactura: PropTypes.func,
    factura: PropTypes.array
};

export default reduxForm({
    form: 'FormBuscarFactura',
})(BuscarFacturaForm);

