import React, { useEffect, useState } from "react";
import { DropZone, Text, FileTrigger } from "react-aria-components";
import GenBtn from "../../components/GenBtn";
import useUpdateEffect from "../../../../hooks/useUpdateEffect";
import styled from "styled-components";
import { ICListBox, ICItem } from "./ListBox";
import { useListData } from "react-stately";
import { useController } from "react-hook-form";
import * as Fa from "react-icons/fa";
import { useRef } from "react";

const StyledImg = styled.img`
    display: block;
    /* width: 100%; */
    object-fit: cover;
    max-height: 125px;
    max-width: 125px;
    height: auto;
    margin: 0 auto;
`;
const MainContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    background-color: #212121;
    border-radius: 0.5rem;
    margin-top: 1.2rem;
`;

const StyledText = styled(Text)`
    position: absolute;
    top: -32%;
    left: 0;
    color: #e0e0e0;
`;

export default function FicImageDropzone({
    label,
    description,
    errorMsj,
    nonForm,
    control,
    name,
    style,
    isDisabled,
    ...props
}) {
    const { field, fieldState } = useController({ control, name });
    const { onChange: fieldOnChange, ...rest } = field;
    const shouldUpdateWithFieldValues = useRef(true);

    const StyledDropzone = styled(DropZone)`
        position: relative;
        background-color: #212121;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 100%;
        padding: 1rem;
        border-radius: 0.5rem;
        border: ${isDisabled ? "0px" : "2px"} dashed
            ${fieldState.error ? " rgb(218, 27, 27)" : "#505050"};
        margin-bottom: 5px;
        [slot="description"] {
            font-size: 12px;
            color: #9b9b9b;
            margin-left: 0.325rem;
        }

        [slot="errorMessage"] {
            text-overflow: ellipsis;
            font-size: 12px;
            position: absolute;
            top: 105%;
            left: 0;
            display: block;
            color: rgb(218, 27, 27);
            text-shadow: 0px 0px 3px black;
            text-align: left;
            padding: 0 8px;
        }

        &[data-drop-target="true"] {
            border-color: ${isDisabled ? "" : "#bababa"};
        }
    `;

    let data = useListData({
        initialItems: [],
        getKey: (item) => item.name,
    });

    useEffect(() => {
        function setDefaultValues() {
            field.value.forEach((file) => {
                data.getItem(file.name) && data.remove(file.name);
                data.append(file);
            });
            shouldUpdateWithFieldValues.current = false;
        }
        if (field.value !== undefined && shouldUpdateWithFieldValues.current) {
            setDefaultValues();
        }
    }, [field.value]);

    function setFieldValue() {
        if (shouldUpdateWithFieldValues.current) return;
        fieldOnChange(data.items);
    }

    useUpdateEffect(() => {
        setFieldValue();
    }, [data.items]);

    return (
        <MainContainer style={style}>
            <StyledDropzone
                getDropOperation={(types) =>
                    types.has("image/png") || types.has("image/jpeg")
                        ? "copy"
                        : "cancel"
                }
                onDrop={async (e) => {
                    if (shouldUpdateWithFieldValues.current)
                        shouldUpdateWithFieldValues.current = false;
                    if (isDisabled) return;
                    let droppedFiles = e.items.filter(
                        (file) =>
                            file.kind === "file" &&
                            file.type.startsWith("image")
                    );
                    droppedFiles = await Promise.all(
                        droppedFiles.map((file) => file.getFile())
                    );
                    // let filenames = files.map((file) => file.name);
                    droppedFiles.forEach((file) => {
                        data.getItem(file.name) && data.remove(file.name);
                        data.append(file);
                        // testData.append(file);
                    });
                }}
                {...props}
            >
                {label && <StyledText slot="label">{label}</StyledText>}
                {!isDisabled && (
                    <div style={{ display: "flex", gap: "0.5rem" }}>
                        <FileTrigger
                            allowsMultiple
                            accepedFileTypes={["image/png", "image/jpeg"]}
                            onSelect={(e) => {
                                if (shouldUpdateWithFieldValues.current)
                                    shouldUpdateWithFieldValues.current = false;
                                let files = Array.from(e);
                                files.forEach((file) => {
                                    data.getItem(file.name) &&
                                        data.remove(file.name);
                                    data.append(file);
                                    // testData.append(file);
                                });
                            }}
                            {...rest}
                        >
                            <GenBtn isDisabled={isDisabled}>
                                Seleccionar archivos
                            </GenBtn>
                        </FileTrigger>
                        <GenBtn
                            textColor="hsl(4, 100%, 50%)"
                            background="hsla(4, 100%, 50%, 0.2)"
                            onPress={() => {
                                // data.remove(data.selectedKeys);
                                data.removeSelectedItems();
                            }}
                            toolTipText={<div>Remover seleccion</div>}
                            isDisabled={
                                data.selectedKeys.size === 0 || isDisabled
                            }
                        >
                            <Fa.FaTrash />
                        </GenBtn>
                    </div>
                )}
                {description && <Text slot="description">{description}</Text>}
                {fieldState.error && (
                    <Text slot="errorMessage">{fieldState.error.message}</Text>
                )}
            </StyledDropzone>
            <ICListBox
                style={{ alignSelf: "center" }}
                slot="label"
                items={data.items}
                disabledKeys={
                    isDisabled ? data.items.map((item) => item.name) : []
                }
                orientation="horizontal"
                renderEmptyState={() => (
                    <div
                        style={{
                            color: "#fff",
                            textShadow: "#000000 1px 0 10px",
                        }}
                    >
                        {isDisabled ? "" : "Seleccione o arrastre imagenes"}
                    </div>
                )}
                selectionMode="multiple"
                selectedKeys={data.selectedKeys}
                onSelectionChange={data.setSelectedKeys}
            >
                {(file) => (
                    <ICItem id={file.name}>
                        <StyledImg src={URL.createObjectURL(file)} />
                    </ICItem>
                )}
            </ICListBox>
        </MainContainer>
    );
}

{
    /* <DropZone
onDrop={(e) => {
    let files = e.items.filter((file) => file.kind === "file");
    let filenames = files.map((file) => file.name);
    setFiles(filenames.join(", "));
}}
>
<FileTrigger
    allowsMultiple
    onSelect={(e) => {
        let files = Array.from(e);
        let filenames = files.map((file) => file.name);
        setFiles(filenames.join(", "));
    }}
>
    <GenBtn>Select files</GenBtn>
</FileTrigger>
<Text slot="label" style={{ display: "block" }}>
    {files || "Drop files here"}
</Text>
</DropZone> */
}
