import { NotificationManager } from "react-notifications";
import { api } from "api";
import { getPeriodoId } from "../utils";

const baseurl = "laboratorio/abonos";
const writeSuccessMsg = "Abono agregado";
const editSuccessMsg = "Abono editado";

export const getList = () => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let empresa = id_emp[5];
    const periodo = getPeriodoId();
    const params = { empresa, periodo };
    return api
        .get(baseurl, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return [];
        });
};

export const getEntry = (id) => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let empresa = id_emp[5];
    const params = { empresa };
    return api
        .get(baseurl + `/${id}`, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return [];
        });
};

export const crear = (data) => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    const periodo = getPeriodoId();
    const formData = {
        ...data,
        empresa: id_emp[5],
        periodo,
    };

    if (!periodo) {
        NotificationManager.error(
            "No hay periodo seleccionado",
            "Error",
            10000
        );
        return;
    }

    return api
        .post(baseurl, formData)
        .then(() => {
            NotificationManager.success(writeSuccessMsg, "Exito", 10000);
        })
        .catch((error) => {
            try {
                error.non_field_errors.map((err) => {
                    NotificationManager.error(err, "Error", 10000);
                });
                return;
            } catch (exception) {
                console.log(error);
                if (typeof error === "object") {
                    Object.keys(error).forEach((field) => {
                        error[field].forEach((errorMessage) => {
                            NotificationManager.error(
                                `${field}: ${errorMessage}`,
                                "Error",
                                10000
                            );
                        });
                    });
                } else {
                    NotificationManager.error("", "Error", 10000);
                }
            }
        })
        .catch((error) => {
            NotificationManager.error("Ocurrio un error", "Error", 2000);
            return false;
        });
};

export const editar = (data, id) => {
    let ruta = window.location.href;
    let empresa = ruta.split("/")[5];
    const formData = {
        ...data,
        empresa,
    };
    return api
        .put(baseurl + `/${id}`, formData)
        .then(() => {
            NotificationManager.success(editSuccessMsg, "Exito", 3000);
            return true;
        })
        .catch((error) => {
            NotificationManager.error(error.msj, "Error", 2000);
            return false;
        });
};
export const eliminar = (id) => {
    let ruta = window.location.href;
    let empresa = ruta.split("/")[5];
    return api
        .eliminar(baseurl + `/${id}` + `/?empresa=${empresa}`)
        .then(() => {
            NotificationManager.success(
                "El abono se elimino correctamente",
                "Exito",
                3000
            );
            return true;
        })
        .catch((error) => {
            NotificationManager.error(error.msj, "Error", 2000);
            return false;
        });
};

export const getTiposAbono = (search) => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let empresa = id_emp[5];
    const params = { empresa, search };
    return api
        .get("laboratorio/tipo_abono", params)
        .then((response) => {
            return response.map((item) => ({
                value: item.id,
                label: `${item.nombre}`,
            }));
        })
        .catch((error) => {
            return [];
        });
};

export const getClientes = (search) => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let empresa = id_emp[5];
    const params = { empresa, search };
    return api
        .get("laboratorio/clientes", params)
        .then((response) => {
            return response.map((item) => ({
                value: item.id,
                label: `${item.nombre}`,
            }));
        })
        .catch((error) => {
            return [];
        });
};
export const getPatologias = (cliente) => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let empresa = id_emp[5];
    const periodo = getPeriodoId();
    const con_saldo = true;
    const params = { empresa, cliente, con_saldo, periodo };
    return api
        .get("laboratorio/ingreso_de_patologia", params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return [];
        });
};
