import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import NavbarModulo from '../../components/NavbarModulo';
import VisitadorDetalle from './VisitadorList';
import styled from 'styled-components';
import classNames from 'classnames';

import {
    AsyncSelectFieldIn,
} from "../../Utils/renderField/renderField";

function VisitadorForm(props) {
    const { modulo, emp, agregarVisitador, data, eliminar, cargo,
        datosColaborador
    } = props;
    let titulo = 'Visitador médico';
    
    let ruta = window.location.href;
    let id_emp = ruta.split('/');

    const handleOnChangeEmp = value => {
        datosColaborador(value.value);
    };

    return (
        <div>
            <NavbarModulo
                modulo={modulo}
                titulo={titulo}
            />
           
            <br />
            <div className="mb-4 card card-small text-white bg-dark mb-3">
                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                    <div className="d-flex flex-column flex-1 mx-4">
                        <div className='mb-2'>
                            <div className='row mb-2'>
                                <div className='col-6'>
                                    <Field
                                        name="colaborador"
                                        placeholder="Colaborador..."
                                        className="form-control-find"
                                        component={AsyncSelectFieldIn}
                                        onChange={handleOnChangeEmp}
                                        loadOptions={emp}
                                        msj="Colaborador"
                                    />
                                </div>
                                <div className='col-6'>
                                    <div className="form-floating">
                                        <input
                                            className = {classNames('form-control')}
                                            type="text"
                                            value = {cargo}
                                            disabled = {true}
                                        />
                                        <label className='form-label'>Puesto</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Contenido>
                            <button
                                className='btn mr-2 mb-3 btn-primary'
                                onClick={() => agregarVisitador()}
                            >
                                Agregar visitador
                            </button>
                        </Contenido>
                        <br/>
                        <VisitadorDetalle
                            detalle = {data}
                            eliminar = {eliminar}
                        />

                        <div className='d-flex flex-row mt-3 ml-2'>
                            <a
                                href={`/#/visitador_medico/${id_emp[5]}`}
                                className='btn btn-secondary mb-3'
                            >
                                Regresar
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

VisitadorForm.propTypes = {
    handleSubmit: PropTypes.func,
    crear: PropTypes.bool,
    lectura: PropTypes.object,
    modulo: PropTypes.object
};

export default reduxForm({
    form: 'FormVisitadorVM',
})(VisitadorForm);

const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;