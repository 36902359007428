import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
    FicComboBox,
    FicInput,
    FicTextArea,
    FicDatePickerSI,
    FicNumber,
} from "../../Utils/FormInputComponents/index";
import DialogModal from "../../components/DialogModal";
import "../../../../../style/autoVentas/VentasForm.css";
import GenBtn from "../../components/GenBtn";
import { validar } from "../../components/ValidarNit";
import { withRouter } from "react-router";
import styled, { keyframes } from "styled-components";
import LoadMask from "../../Utils/LoadMask/LoadMask";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const Overlay = styled.div`
    font-size: 1.5rem;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
    border-radius: 1rem;
    animation: ${({ isActive }) => (isActive ? fadeIn : fadeOut)} 0.3s
        ease-in-out forwards;
    pointer-events: ${({ isActive }) => (isActive ? "auto" : "none")};
    cursor: ${({ isActive }) => (isActive ? "default" : "initial")};
    z-index: 1;
`;

const FicComboBoxStyled = styled(FicComboBox)`
    & > .rs__menu {
        z-index: 3;
    }
`;

const DialogContent = styled.div`
    height: 205px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;
`;

const schema = yup.object({
    fecha_venta: yup.string().required("La fecha es requerida"),
    vendedor: yup.mixed().required("El vendedor es requerido"),
    comprador_nombre: yup.string().required("El comprador es requerido"),
    comprador_dpi: yup
        .number()
        .typeError("Debe de ser un numero")
        .test("dpi-validation", "El dpi no es valido", function (value) {
            return validar.valCui(value.toString())[0].validar;
        })
        .required("El numero de dpi es requerido"),
    comprador_domicilio: yup.string().required("El domicilio es requerido"),
    comprador_departamento: yup
        .string()
        .required("El departamento es requerido"),
    comprador_profesion: yup.string().required("La profesion es requerida"),
    comprador_num_telefono: yup
        .number()
        .typeError("Debe de ser un numero")
        .required("El numero de telefono es requerido"),
    comprador_nit: yup
        .string()
        .test("dpi-validation", "El NIT no es valido", function (value) {
            return validar.valNit(value);
        })
        .required("El nit es requerido"),
    comprador_edad: yup
        .number()
        .positive("La edad debe ser mayor a 0")
        .typeError("Debe de ser un numero")
        .required("La edad es requerida"),
    comprador_estado_civil: yup
        .string()
        .required("El estado civil es requerido"),
    valor_venta: yup
        .number()
        .positive("El valor de venta debe ser mayor a 0")
        .typeError("Debe de ser un numero")
        .required("El valor de venta es requerido"),
});

function VendedoresFormEditar(props) {
    const {
        datosDeVenta,
        editarVenta,
        listarVendedores,
        reporteVentaDeVehiculos,
        history,
    } = props;

    const dialogRef = useRef(null);
    const urlParams = props.match.params;
    const empresa = urlParams.empresa;
    const ventaId = urlParams.ventaId;
    const [loadingState, setLoadingState] = useState(true);

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues: async () => {
            let formData = await datosDeVenta(ventaId);
            setLoadingState(false);
            const parts = formData.fecha_venta.split("-");

            const yyyy = parts[2];
            const mm = parts[1];
            const dd = parts[0];

            const formattedDateString = `${yyyy}-${mm}-${dd}`;
            formData.fecha_venta = formattedDateString;
            return formData;
        },
    });

    async function onSubmit(data) {
        // await crearVenta(data).then((data) => {
        //     setVentaId(data.id);
        //     if (data) {
        //         dialogRef.current.showModal();
        //     }
        // });
        const {
            codigo,
            placa,
            tipo_carro,
            modelo,
            linea,
            num_serie,
            carro,
            ...filteredData
        } = data;

        let shouldShowModal = await editarVenta(filteredData, ventaId);
        if (shouldShowModal) {
            dialogRef.current.showModal();
        }
    }

    return (
        <React.Fragment>
            <br />
            <h3
                className="p-2  title rounded shadow"
                style={{
                    background:
                        "linear-gradient(to top right, #790021, #2d2d2d)",
                }}
            >
                Ver Venta
            </h3>
            <br />
            <div className="d-flex flex-row justify-content-start mb-2">
                <GenBtn
                    BtnColor="#787878"
                    style={{ width: "100px", height: "40px" }}
                    onPress={(e) => {
                        history.push(`/auto_ventas/${empresa}/ventas`);
                    }}
                >
                    Regresar
                </GenBtn>
            </div>
            <LoadMask loading={loadingState} color="#bd0034" blur={true}>
                <form
                    encType="multipart/form-data"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <div style={{ color: "#bd0034" }}>DATOS DEL VEHICULO</div>
                    <div className="ventas-form__auto-data">
                        <div className="ventas-form__inputs-container">
                            <FicInput
                                label="Placa"
                                name="placa"
                                control={control}
                                isDisabled={true}
                            />
                            <FicInput
                                label="Codigo"
                                name="codigo"
                                control={control}
                                isDisabled={true}
                            />
                            <FicInput
                                label="Tipo de carro"
                                name="tipo_carro"
                                control={control}
                                isDisabled={true}
                            />
                            <FicInput
                                label="Modelo"
                                name="modelo"
                                control={control}
                                isDisabled={true}
                            />
                            <FicInput
                                label="Linea"
                                name="linea"
                                control={control}
                                isDisabled={true}
                            />

                            <FicInput
                                label="Numero de serie"
                                name="num_serie"
                                control={control}
                                isDisabled={true}
                            />
                            <FicInput
                                label="Modalidad"
                                name="modalidad"
                                control={control}
                                isDisabled={true}
                            />
                        </div>
                    </div>

                    <FicInput
                        componentStyle={{
                            marginTop: "15px",
                            marginBottom: "15px",
                        }}
                        control={control}
                        name="carro"
                        label="Carro"
                        isDisabled={true}
                    />

                    <div className="ventas-form__form-container">
                        <div style={{ color: "#bd0034" }}>
                            DATOS DE LA VENTA
                        </div>
                        <div className="ventas-form__inputs-container">
                            <FicDatePickerSI
                                control={control}
                                name="fecha_venta"
                                label="Fecha"
                            />
                            <FicComboBox
                                componentStyle={{ zIndex: "0" }}
                                control={control}
                                name="vendedor"
                                label="Vendedor"
                                loadOptions={listarVendedores}
                            />
                            <FicInput
                                label="Comprador"
                                name="comprador_nombre"
                                control={control}
                            />
                            <FicInput
                                label="Numero de DPI"
                                name="comprador_dpi"
                                control={control}
                            />
                            <FicInput
                                label="Domicilio fiscal"
                                name="comprador_domicilio"
                                control={control}
                            />
                            <FicInput
                                label="Departamento"
                                name="comprador_departamento"
                                control={control}
                            />
                            <FicInput
                                label="Profesion u oficio"
                                name="comprador_profesion"
                                control={control}
                            />
                            <FicInput
                                label="Numero de Telefono"
                                name="comprador_num_telefono"
                                control={control}
                            />
                            <FicInput
                                label="NIT"
                                name="comprador_nit"
                                control={control}
                            />
                            <FicInput
                                label="Edad"
                                name="comprador_edad"
                                control={control}
                            />
                            <FicInput
                                label="Estado civil"
                                name="comprador_estado_civil"
                                control={control}
                            />
                            <FicNumber
                                formatOptions={{
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }}
                                label="Valor de venta"
                                name="valor_venta"
                                control={control}
                            />
                            <FicTextArea
                                className="ventas-form__textarea"
                                label="Observaciones"
                                name="observaciones"
                                control={control}
                                componentStyle={{
                                    minHeight: "36.7px",
                                    gridColumn: "span 2",
                                }}
                            />
                        </div>
                        <GenBtn
                            type="submit"
                            className="ventas-form__guardar-ingreso-btn"
                            style={{ marginTop: "15px" }}
                        >
                            Guardar
                        </GenBtn>
                    </div>
                </form>
            </LoadMask>

            <DialogModal dialogRef={dialogRef} noHeader={true}>
                <DialogContent>
                    <div
                        style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "#bd0034",
                        }}
                    >
                        Desea generar el finiquito?
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-around",
                            marginTop: "15px",
                            width: "100%",
                        }}
                    >
                        <GenBtn
                            textColor="hsl(4, 100%, 39%)"
                            background="hsla(4, 100%, 39%, 0.2)"
                            onPress={() => {
                                dialogRef.current.close();
                                setTimeout(() => {
                                    history.push(
                                        `/auto_ventas/${empresa}/ventas`
                                    );
                                }, 350);
                            }}
                        >
                            No
                        </GenBtn>
                        <GenBtn
                            onPress={() => {
                                reporteVentaDeVehiculos(ventaId);
                                setTimeout(() => {
                                    history.push(
                                        `/auto_ventas/${empresa}/ventas`
                                    );
                                }, 3000);
                            }}
                            autofocus="autofocus"
                        >
                            Generar
                        </GenBtn>
                    </div>
                </DialogContent>
            </DialogModal>
        </React.Fragment>
    );
}

export default withRouter(VendedoresFormEditar);
