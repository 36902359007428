import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/registro_marca/principioActivo';
import PrincipioActivo from './PrincipioActivo';


const ms2p = (state) => {
  return {
    ...state.principioActivo_rm,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(PrincipioActivo);