import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BootstrapTable } from 'react-bootstrap-table';
import LoadMask from "../LoadMask/LoadMask";


export default class Grid extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false
    };

    render() {
        const {
            loading,
            data,
            page,
            remote=true,
            expandableRow,
            expandComponent,
            cellEditProp,
            afterSave,
            onPageChange,
            onSortChange,
            pagination,
            expanding,
            onExpand,
            trClassName,
            ...other
        } = this.props;
        const options = {
            sizePerPage: 10,
            hideSizePerPage: true,
            paginationSize: 5,
            alwaysShowAllBtns: true,
            noDataText: loading ? 'Cargando...' : <span className='title'>No hay datos</span>,
            page,
            onPageChange: onPageChange ? onPageChange : () => {},
            onSortChange: onSortChange ? onSortChange : () => {},
            onExpand:onExpand
        };

        const paginar = !(pagination === false);

        const footerData = [
            [
                {
                    label: 'Total value',
                    columnIndex: 0,
                    align: 'right',
                    formatter: (tableData) => {
                        const cant = tableData.reduce(function (resultado, elemento) {
                            return parseInt(resultado) + 1;
                        }, 0);
                        return (
                            <strong className='title'>{cant}</strong>
                        );
                    }
                },
                {
                    label: <strong className='title'>Totales:</strong>,
                    columnIndex: 1,
                    align: 'center',
                },
                {
                    label: 'Total value',
                    columnIndex: 2,
                    align: 'right',
                    formatter: (tableData) => {
                        const sueldo = tableData.reduce(function (resultado, elemento) {
                            return parseFloat(resultado) + parseFloat(elemento.base);
                        }, 0.00);
                        return (
                            <strong className='title'>{new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(sueldo)}</strong>
                        );
                    }
                },
                {
                    label: 'Total value',
                    columnIndex: 3,
                    align: 'right',
                    formatter: (tableData) => {
                        const bon = tableData.reduce(function (resultado, elemento) {
                            return parseFloat(resultado) + parseFloat(elemento.bonificacion);
                        }, 0.00);
                        return (
                            <strong className='title'>{new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(bon)}</strong>
                        );
                    }
                },
                {
                    label: 'Total value',
                    columnIndex: 4,
                    align: 'right',
                    formatter: (tableData) => {
                        const total = tableData.reduce(function (resultado, elemento) {
                            return parseFloat(resultado) + parseFloat(elemento.comision);
                        }, 0.00);
                        return (
                            <strong className='title'>{new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(total)}</strong>
                        );
                    }
                },
                {
                    label: 'Total value',
                    columnIndex: 5,
                    align: 'right',
                    formatter: (tableData) => {
                        const totalIgss = tableData.reduce(function (resultado, elemento) {
                            return parseFloat(resultado) + (parseFloat(elemento.igss)*-1);
                        }, 0.00);
                        return (
                            <strong className='title'>{new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(totalIgss)}</strong>
                        );
                    }
                },
                {
                    label: 'Total value',
                    columnIndex: 6,
                    align: 'right',
                    formatter: (tableData) => {
                        const totalISR = tableData.reduce(function (resultado, elemento) {
                            return parseFloat(resultado) + (parseFloat(elemento.judicial)*-1);
                        }, 0.00);
                        return (
                            <strong className='title'>{new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(totalISR)}</strong>
                        );
                    }
                },
                {
                    label: 'Total value',
                    columnIndex: 7,
                    align: 'right',
                    formatter: (tableData) => {
                        const totalJudicial = tableData.reduce(function (resultado, elemento) {
                            return parseFloat(resultado) + (parseFloat(elemento.total_descuentos));
                        }, 0.00);
                        return (
                            <strong className='title'>{new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(totalJudicial)}</strong>
                        );
                    }
                },
                {
                    label: 'Total value',
                    columnIndex: 8,
                    align: 'right',
                    formatter: (tableData) => {
                        const totalJudicial = tableData.reduce(function (resultado, elemento) {
                            return parseFloat(resultado) + parseFloat(elemento.total_recibir);
                        }, 0.00);
                        return (
                            <strong className='title'>{new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(totalJudicial)}</strong>
                        );
                    }
                }
            ]
        ];


        return (
            <div>
                <LoadMask loading={loading} dark blur>
                    <BootstrapTable
                        expandableRow={expandableRow}
                        expandComponent={expandComponent}
                        trClassName={trClassName}
                        cellEdit={cellEditProp}
                        data={loading ? [] : data}
                        afterSaveCell={afterSave}
                        remote={remote}
                        footerData={footerData}
                        fetchInfo={{ dataTotalSize: data.count }}
                        options={options}
                        {...other}
                    />
                </LoadMask>
            </div>

        )
    }
}
