import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/auto_ventas/linea';
import LineaList from './LineaList';


const ms2p = (state) => {
  return {
    ...state.auto_ventas_linea,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(LineaList);
