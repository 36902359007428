import React, { useEffect, useRef, useState } from "react";
import "../../../../../../style/Finanzas/FinanzasHome.css";
import styled from "styled-components";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import DataTableWSearch from "../../../components/DataTableWSearch";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import {
    FicInput,
    FicCheckbox,
    FicFileDrop,
} from "../../../Utils/FormInputComponents";
import DialogModal from "../../../components/DialogModal";
import GenBtn from "../../../components/GenBtn";
import { validar } from "../../../components/ValidarNit";

const AgregarContribuyenteBtn = styled.button`
    color: hsla(206, 100%, 50%);
    background-color: hsla(206, 100%, 50%, 0.1);
    border: 1px solid hsla(206, 100%, 50%);
    border-radius: 5px;
    padding: 0.5rem;
    text-decoration: none;
    margin: 1rem 0 1rem 0;
    min-width: 77.5px;
    width: 15%;
    text-align: center;
    transition: border 300ms ease-in;

    &:hover {
        text-decoration: none;
        border: 1px solid hsla(206, 100%, 50%, 0.1);
        transition: border 300ms ease-out;
    }
`;

const Container = styled.div`
    margin-top: 2rem;
    height: 50vh;
    width: 100%;
    // background-color: #1e1e1e;
`;

const StyledFormContainer = styled.form`
    margin: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
`;

const FormContainer = styled.form`
    border-top: 1px dashed #bd0035;
    display: flex;
    margin-top: 1%;
    margin-bottom: 1%;
    gap: 1%;
    align-items: center;
`;

const schema = yup.object().shape({
    razon_social: yup.string().required("El nombre es requerido"),
    // nit: yup
    //     .string()
    //     .test("dpi-validation", "El NIT no es valido", function (value) {
    //         return validar.valNit(value);
    //     })
    //     .required("El nit es requerido"),
    exterior: yup.boolean(),
    nit: yup.string().when("exterior", {
        is: false,
        then: (field) =>
            field.test(
                "dpi-validation",
                "El NIT no es valido",
                function (value) {
                    return validar.valNit(value);
                }
            ),
    }),
});

const archivoSchema = yup.object({
    contribuyente_excel: yup.mixed().required("La nomenclatura es requerida"),
});

function Contribuyentes(props) {
    const {
        listarContribuyentes,
        guardarContribuyente,
        subirArchivoContribuyentes,
    } = props;
    const [fileExists, setFileExists] = useState(false);
    const dialogRef = useRef(null);
    const [tableData, setTableData] = useState([]);
    const [archivoIsSending, setArchivoIsSending] = useState(false);

    const defaultValues = {
        nombre: "",
        nit: "",
        exterior: false,
    };

    const {
        handleSubmit,
        formState: { errors },
        control,
        reset,
        watch,
    } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: defaultValues,
    });

    const {
        handleSubmit: archivoHandleSubmit,
        formState: { errors: archivoErrors },
        control: archivoControl,
        watch: archivoWatch,
    } = useForm({
        mode: "onChange",
        resolver: yupResolver(archivoSchema),
    });

    async function fetchData() {
        listarContribuyentes().then((data) => {
            if (data) {
                setTableData(data);
            }
        });
    }

    useEffect(() => {
        fetchData();
    }, []);

    const file = archivoWatch("contribuyente_excel");
    useEffect(() => {
        file ? setFileExists(true) : setFileExists(false);
    }, [file]);

    async function onSubmit(data) {
        await guardarContribuyente(data);
        fetchData();
    }

    async function onSubmitExcel(data) {
        setArchivoIsSending(true);
        const shouldReset = await subirArchivoContribuyentes(data);
        setArchivoIsSending(false);
        if (shouldReset) {
            reset();
            setFileExists(false);
        }
    }

    const columns = [
        {
            name: "NIT",
            selector: (row) => row.nit,
            sortable: true,
        },
        {
            name: "contribuyente",
            selector: (row) => row.razon_social,
            sortable: true,
        },
    ];

    return (
        <Container>
            <AgregarContribuyenteBtn
                onClick={() => dialogRef.current.showModal()}
            >
                Agregar contribuyente
            </AgregarContribuyenteBtn>
            <LoadMask blur loading={archivoIsSending} color="#bd0034">
                <FormContainer
                    encType="multipart/form-data"
                    onSubmit={archivoHandleSubmit(onSubmitExcel)}
                >
                    <FicFileDrop
                        control={archivoControl}
                        name="contribuyente_excel"
                        type="file"
                        style={{ height: "39px", margin: "0.5rem" }}
                        labelText="Subir archivo de contribuyentes"
                        accept=".xlsx, .xls, .csv"
                    />
                    {fileExists && <GenBtn type="submit">Guardar</GenBtn>}
                </FormContainer>
            </LoadMask>
            {/* <AgregarContribuyenteBtn onClick={() => fetchData()}>Refresh</AgregarContribuyenteBtn> */}
            <DialogModal
                dialogRef={dialogRef}
                title="Agregar contribuyente"
                style={{
                    width: "30rem",
                    minWidth: "255px",
                    overflow: "visible",
                }}
            >
                <StyledFormContainer onSubmit={handleSubmit(onSubmit)}>
                    <FicCheckbox
                        name="exterior"
                        control={control}
                        label="Exterior"
                    />
                    <FicInput
                        name="nit"
                        label="NIT"
                        placeholder="NIT"
                        control={control}
                    />
                    <FicInput
                        name="razon_social"
                        label="Nombre"
                        placeholder="Nombre"
                        control={control}
                    />
                    <GenBtn type="submit">Agregar</GenBtn>
                </StyledFormContainer>
            </DialogModal>

            <DataTableWSearch
                title="Listado de contribuyentes"
                data={tableData}
                columns={columns}
                fieldToFilter="nombre"
                filterPlaceHolder="Buscar por contribuyente"
            />
        </Container>
    );
}

export default Contribuyentes;
