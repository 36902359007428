import React, { Component } from 'react';
import Formulario from './DocumentoForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";

class Documento extends Component{

    componentDidMount = () => {
        const { limpiar, leerEmpresa } = this.props;
        limpiar();
        leerEmpresa();
    }

    render(){
        const { buscarDocumento, loader, listarDocumentos, doc,
            leerDocumento, moneda, detalle, empresaNom } = this.props;
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    buscarDocumento = {buscarDocumento}
                    listarDocumentos = {listarDocumentos}
                    leerDocumento = {leerDocumento}
                    doc = {doc}
                    moneda = {moneda}
                    detalle = {detalle}
                    empresaNom = {empresaNom}
                />
            </LoadMask>
        );
    }
}

export default Documento;
