import React from "react";
import styled from "styled-components";
import BareDialog from "./BareDialogModal";
import "./FormDialogModal.css";
import { useButton } from "react-aria";
import { useRef } from "react";

export function FormDialogCloseBtn(props) {
    let ref = useRef();
    let { buttonProps, isPressed } = useButton(props, ref);
    let { children } = props;

    return (
        <button
            {...buttonProps}
            type="button"
            ref={ref}
            aria-label="Close"
            formMethod="dialog"
            className={
                "close-btn" +
                " " +
                (props.className || "") +
                " " +
                (isPressed ? "close-btn-pressed" : "")
            }
        >
            {children}
        </button>
    );
}

export default function DialogModal({
    dialogRef,
    title,
    children,
    style,
    noHeader,
    noClosingOverlay,
}) {
    const handleClose = () => {
        dialogRef.current.setAttribute("closing", "");
        dialogRef.current.addEventListener(
            "animationend",
            () => {
                dialogRef.current.removeAttribute("closing");
                dialogRef.current.close();
            },
            { once: true }
        );
    };

    return (
        <BareDialog
            id="modal-container"
            dialogRef={dialogRef}
            className={"DFModal"}
            style={style}
            noClosingOverlay={noClosingOverlay}
        >
            <div className={`form-M`}>
                {!noHeader && (
                    <div class={`modal-H`}>
                        <div className="title-H">{title}</div>
                        <FormDialogCloseBtn onPress={handleClose}>
                            &times;
                        </FormDialogCloseBtn>
                    </div>
                )}
                {children}
            </div>
        </BareDialog>
    );
}
