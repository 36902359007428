import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import Modal from '../../../components/components/ModalC';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import TablaDetalle from './ImportacionDetalle';
import TablaVerDetalle from './ImportacionVerDetalle';
import styled from 'styled-components';

import {
    renderField,
    renderDayPickerB,
    SelectField,
    AsyncSelectField,
    renderCurrencyFloat,
    renderCurrencyFloatTC,
    renderNumber,
    renderFieldCheckB,
    renderFieldCheck
} from "../../Utils/renderField/renderField";

function ImportacionForm(props) {
    const {
        crear,
        lectura,
        bloqueo,
        editar,
        listarProveedores,
        registroProveedorExt,
        fechaFinal,
        impuesto,
        obtenerPaises,
        registroImportacion,
        periodoD,
        detalleImp,
        eliminarDocumentoImportacion,
    } = props;

    let autoFocus = true;
    
    const [tcambio, setTCambio] = useState(0.000000);
     
    const [fob, setFob] = useState(0.00);
    const [flete, setFlete] = useState(0.00);
    const [seguro, setSeguro] = useState(0.00);
    const [otros, setOtros] = useState(0.00);
    const [totalGasto, setTotalGasto] = useState(0.00);

    const [dai, setDai] = useState(0.00);
    const [editarD, setEditarD] = useState(editar);
    
    const [modificarT, setModificarT] = useState(false);
    const [modificarD, setModificarD] = useState(false);
    
    const [montoD, setMontoD] = useState(0.00);
    const [totalQ, setTotalQ] = useState(0.00);
    const [tIva, setTIva] = useState(0.00);

    const [estadoModal, cambiarEstadoModal] = useState(false);
    
    const [iva, setIva] = useState(true);
    
    const onCheckboxClickedIva = (idx, isChecked) => {
        setIva(isChecked);
    }

    const tipoProveedor = [
        {
            value: 'E',
            label: 'EXTERIOR'
        },
    ];
    const estilo = {
        overflow: "auto",
        height: "200px",

    };
    let porIva = parseFloat(parseFloat(impuesto.porcentaje_iva)/100).toFixed(2);
    
    return (
        <div>
            <div className='row mb-2'>
                <div className='col-3'>
                    <div className="form-floating">
                        <Field
                            disabled={bloqueo}
                            autoFocus={autoFocus}
                            name="declaracion"
                            placeholder="Declaracion"
                            className="form-control"
                            component={renderField}
                            msj="Declaracion"
                        />
                    </div>
                </div>
                <div className='col-3'>
                    <Field
                        disabled={bloqueo}
                        name="fechaRegistro"
                        component={renderDayPickerB}
                        msj="Fecha Registro"
                        maxDate={fechaFinal}
                    />
                </div>
                {crear === true &&
                    <div className='col-3'>
                        <Field
                            disabled={bloqueo}
                            name="fechaImportacion"
                            component={renderDayPickerB}
                            msj="Fecha Importacion"
                            maxDate={fechaFinal}
                        />
                    </div>
                }
                {editar == true &&
                    <div className='col-3'>
                        <Field
                            disabled={bloqueo}
                            name="fechaImportacion"
                            component={renderDayPickerB}
                            msj="Fecha Importacion"
                            maxDate={fechaFinal}
                        />
                    </div>
                }
                {(crear === false && editar == false) &&
                    <div className='col-3'>
                        <Field
                            disabled={bloqueo}
                            name="fechaImportacion"
                            component={renderDayPickerB}
                            msj="Fecha Importacion"
                        />
                    </div>
                }
                {(crear === false && editar == false) ? (
                        <div className='col-3'>
                            <Field
                                disabled={true}
                                name="afecta_iva"
                                label="Afecta iva"
                                component={renderFieldCheck}
                            />
                        </div>
                    ) :
                        (crear === true && editar == false) ? (
                            <div className='col-3'>
                                <Field
                                    name="afecta_iva"
                                    checked={iva}
                                    label="Afecta iva"
                                    component={renderFieldCheckB}
                                    onChange={onCheckboxClickedIva}
                                />
                            </div>
                            
                        ):
                            <div className='col-3'>
                                <Field
                                    name="afecta_iva"
                                    checked={iva}
                                    label="Afecta iva"
                                    component={renderFieldCheck}
                                    onChange={onCheckboxClickedIva}
                                />
                            </div>
                    }
            </div>
            <div className='row mb-2'>
                {(crear === false && editar == false) &&
                    <div className='col-3'>
                        <Field
                            disabled={bloqueo}
                            name="tipoCambio"
                            component={renderCurrencyFloatTC}
                            msj="Tipo de cambio"
                        />
                    </div> 
                }
                {crear === true &&
                    <div className='col-3'>
                        <Field
                            disabled={bloqueo}
                            name="tipo_cambio"
                            placeholder="Tipo de cambio"
                            className="form-control"
                            component={renderCurrencyFloatTC}
                            msj="Tipo de cambio"
                            onChange={(e, value) => {
                                if (iva) {
                                    if (value) {
                                        setTCambio(value);
                                        let tot = editar ? lectura.totalGasto : totalGasto;
                                        let totalG = parseFloat(parseFloat(tot)*parseFloat(value)).toFixed(2);
                                        setTotalGasto(tot);
                                        let tGD = parseFloat(parseFloat(totalG)+parseFloat(dai)).toFixed(2);
                                        let Tiva = parseFloat(parseFloat(tGD) * porIva).toFixed(2);
                                        setTIva(Tiva);
                                        localStorage.setItem('tipoCambio',value)
                                        localStorage.setItem('ivaImport', Tiva);
                                        localStorage.setItem('total_gastoQ', totalG);
                                        localStorage.setItem('total_gastoImp', tot);
                                    }
                                }
                                if (iva === false) {
                                    if (value) {
                                        setTCambio(value);
                                        setTIva(0.00);
                                        let totalG = parseFloat(parseFloat(totalGasto)*parseFloat(value)).toFixed(2);
                                        localStorage.setItem('tipoCambio',value);
                                        localStorage.setItem('ivaImport', 0.00);
                                        localStorage.setItem('total_gastoQ', totalG);
                                    }
                                }
                            }}
                        />
                    </div>
                }
                {editar === true &&
                    <div className='col-3'>
                        <div className="form-floating">
                            <Field
                                disabled={bloqueo}
                                placeholder="Tipo de cambio"
                                name="tipo_cambio"
                                component={renderCurrencyFloatTC}
                                msj="Tipo de cambio"
                                onChange={(e, value) => {
                                    if (iva) {
                                        if (value) {
                                            setTCambio(value);
                                            if (modificarT === false){
                                                setEditarD(false);
                                                let tot = editar ? lectura.totalGasto : totalGasto;
                                                let totalG = parseFloat(parseFloat(tot)*parseFloat(value)).toFixed(2);
                                                setTotalGasto(tot);
                                                let tGD = parseFloat(parseFloat(totalG)+parseFloat(lectura.dai)).toFixed(2);
                                                let Tiva = parseFloat(parseFloat(tGD) * porIva).toFixed(2);
                                                setTIva(Tiva);
                                                localStorage.setItem('tipoCambio',value)
                                                localStorage.setItem('ivaImport', Tiva);
                                                localStorage.setItem('total_gastoQ', totalG);
                                                localStorage.setItem('total_gastoImp', tot);
                                            }
                                            if (modificarT === true){
                                                setEditarD(false);
                                                let totalG = parseFloat(parseFloat(totalGasto)*parseFloat(value)).toFixed(2);
                                                let tGD = parseFloat(parseFloat(totalG)+parseFloat(dai)).toFixed(2);
                                                let Tiva = parseFloat(parseFloat(tGD) * porIva).toFixed(2);
                                                setTIva(Tiva);
                                                localStorage.setItem('tipoCambio',value);
                                                localStorage.setItem('ivaImport', Tiva);
                                                localStorage.setItem('total_gastoQ', totalG);
                                            }
                                            
                                        }
                                    }
                                    if (iva === false) {
                                        if (value) {
                                            setTCambio(value);
                                            let totalG = parseFloat(parseFloat(totalGasto)*parseFloat(value)).toFixed(2);
                                            setTotalGasto(totalG)
                                            setTIva(0.00);
                                            localStorage.setItem('tipoCambio',value)
                                            localStorage.setItem('ivaImport', 0.00);
                                            localStorage.setItem('total_gastoQ', totalG);
                                        }
                                    }
                                }}
                            />
                        </div>
                    </div>
                }
                <div className='col-3'>
                    <div className="form-floating">
                        <Field
                            disabled={bloqueo}
                            name="fob"
                            placeholder="Total FOB USD"
                            className="form-control"
                            component={renderCurrencyFloat}
                            msj="Total FOB USD"
                            onChange={(e, value) => {
                                if (iva) {
                                    if (value) {
                                        setEditarD(false);
                                        setFob(value);
                                        let fleteE = editar ? lectura.flete : 0.00;
                                        setFlete(fleteE);
                                        let seguroE = editar ? lectura.seguro : 0.00;
                                        setSeguro(seguroE);
                                        let otrosE = editar ? lectura.otros : 0.00;
                                        setOtros(otrosE)
                                        let total = parseFloat(parseFloat(value) + parseFloat(flete) + parseFloat(seguro) + parseFloat(otros)).toFixed(2);
                                        setTotalGasto(total);
                                        setModificarT(true);
                                        setModificarD(true);
                                        let tipoCambio = editar ? lectura.tipo_cambio : tcambio;
                                        let totalG = parseFloat((parseFloat(total)*parseFloat(tipoCambio))).toFixed(2);
                                        let iva = parseFloat((parseFloat(totalG) * porIva)).toFixed(2);
                                        setTIva(iva);
                                        localStorage.setItem('ivaImport', iva);
                                        localStorage.setItem('total_gastoImp', total);
                                        localStorage.setItem('total_gastoQ', totalG);
                                    }
                                }
                                if (iva === false) {
                                    if (value) {
                                        setEditarD(false);
                                        setFob(value);
                                        let fleteE = editar ? lectura.flete : 0.00;
                                        setFlete(fleteE);
                                        let seguroE = editar ? lectura.seguro : 0.00;
                                        setSeguro(seguroE);
                                        let otrosE = editar ? lectura.otros : 0.00;
                                        setOtros(otrosE)
                                        let total = parseFloat(parseFloat(value) + parseFloat(flete) + parseFloat(seguro) + parseFloat(otros)).toFixed(2);
                                        setTotalGasto(total);
                                        setModificarT(true);
                                        setModificarD(true);
                                        let tipoCambio = editar ? lectura.tipo_cambio : tcambio;
                                        let totalG = parseFloat((parseFloat(total)*parseFloat(tipoCambio))).toFixed(2);
                                        setTIva(0.00);
                                        localStorage.setItem('ivaImport', 0.00);
                                        localStorage.setItem('total_gastoImp', total);
                                        localStorage.setItem('total_gastoQ', totalG);
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
                <div className='col-3'>
                    <div className="form-floating">
                        <Field
                            disabled={bloqueo}
                            placeholder="Flete USD"
                            name="flete"
                            component={renderCurrencyFloat}
                            msj="Flete USD"
                            onChange={(e, value) => {
                                if (iva) {
                                    if (value) {
                                        if (modificarD === false){
                                            setEditarD(false);
                                            setFlete(value);
                                            let fobE = editar ? lectura.fob : 0.00;
                                            setFob(fobE);
                                            let seguroE = editar ? lectura.seguro : 0.00;
                                            setSeguro(seguroE);
                                            let otrosE = editar ? lectura.otros : 0.00;
                                            setOtros(otrosE)
                                            let total = parseFloat((parseFloat(value) + parseFloat(fob) + parseFloat(seguro) + parseFloat(otros))).toFixed(2);
                                            setTotalGasto(total);
                                            setModificarT(true);
                                            setModificarD(true);
                                            let tipoCambio = editar ? lectura.tipo_cambio : tcambio;
                                            let totalG = parseFloat((parseFloat(total)*parseFloat(tipoCambio))).toFixed(2);
                                            let iva = parseFloat(parseFloat(totalG * porIva)).toFixed(2);
                                            setTIva(iva);
                                            localStorage.setItem('ivaImport', iva);
                                            localStorage.setItem('total_gastoImp', total);
                                            localStorage.setItem('total_gastoQ', totalG);
                                        }
                                        if (modificarD === true){
                                            setEditarD(false);
                                            setFlete(value);
                                            localStorage.setItem('flete', value);
                                            let total = parseFloat((parseFloat(value) + parseFloat(fob) + parseFloat(seguro) + parseFloat(otros))).toFixed(2);
                                            setTotalGasto(total);
                                            setModificarT(true);
                                            setModificarD(true);
                                            let tipoCambio = editar ? lectura.tipo_cambio : tcambio;
                                            let totalG = parseFloat((parseFloat(total)*parseFloat(tipoCambio))).toFixed(2);
                                            let iva = parseFloat(parseFloat(totalG * porIva)).toFixed(2);
                                            setTIva(iva);
                                            localStorage.setItem('ivaImport', iva);
                                            localStorage.setItem('total_gastoImp', total);
                                            localStorage.setItem('total_gastoQ', totalG);
                                        }
                                    }
                                }
                                if (iva === false) {
                                    if (value) {
                                        setEditarD(false);
                                        setFlete(value);
                                        let fobE = editar ? lectura.fob : 0.00;
                                        setFob(fobE);
                                        let seguroE = editar ? lectura.seguro : 0.00;
                                        setSeguro(seguroE);
                                        let otrosE = editar ? lectura.otros : 0.00;
                                        setOtros(otrosE)
                                        let total = parseFloat((parseFloat(value) + parseFloat(fob) + parseFloat(seguro) + parseFloat(otros))).toFixed(2);
                                        setTotalGasto(total);
                                        setModificarT(true);
                                        setModificarD(true);
                                        let tipoCambio = editar ? lectura.tipo_cambio : tcambio;
                                        let totalG = parseFloat((parseFloat(total)*parseFloat(tipoCambio))).toFixed(2);
                                        setTIva(0.00);
                                        localStorage.setItem('ivaImport', 0.00);
                                        localStorage.setItem('total_gastoImp', total);
                                        localStorage.setItem('total_gastoQ', totalG);
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
                <div className='col-3'>
                    <div className="form-floating">
                        <Field
                            disabled={bloqueo}
                            placeholder="Seguro USD"
                            name="seguro"
                            component={renderCurrencyFloat}
                            msj="Seguro USD"
                            onChange={(e, value) => {
                                if (iva) {
                                    if (value) {
                                        if (modificarD === false){
                                            setEditarD(false);
                                            setSeguro(value);
                                            let fobE = editar ? lectura.fob : 0.00;
                                            setFob(fobE);
                                            let fleteE = editar ? lectura.flete : 0.00;
                                            setFlete(fleteE);
                                            let otrosE = editar ? lectura.otros : 0.00;
                                            setOtros(otrosE)
                                            let total = parseFloat(parseFloat(value) + parseFloat(fob) + parseFloat(flete) + parseFloat(otros)).toFixed(2);
                                            setTotalGasto(total);
                                            setModificarT(true);
                                            setModificarD(true);
                                            let tipoCambio = editar ? lectura.tipo_cambio : tcambio;
                                            let totalG = parseFloat((parseFloat(total)*parseFloat(tipoCambio))).toFixed(2);
                                            let iva = parseFloat(parseFloat(totalG) * porIva).toFixed(2);
                                            setTIva(iva);
                                            localStorage.setItem('ivaImport', iva);
                                            localStorage.setItem('total_gastoImp', total);
                                            localStorage.setItem('total_gastoQ', totalG);
                                        }
                                        if (modificarD === true){
                                            setEditarD(false);
                                            setSeguro(value);
                                            let total = parseFloat(parseFloat(value) + parseFloat(fob) + parseFloat(flete) + parseFloat(otros)).toFixed(2);
                                            setTotalGasto(total);
                                            setModificarT(true);
                                            setModificarD(true);
                                            let tipoCambio = editar ? lectura.tipo_cambio : tcambio;
                                            let totalG = parseFloat((parseFloat(total)*parseFloat(tipoCambio))).toFixed(2);
                                            let iva = parseFloat(parseFloat(totalG) * porIva).toFixed(2);
                                            setTIva(iva);
                                            localStorage.setItem('ivaImport', iva);
                                            localStorage.setItem('total_gastoImp', total);
                                            localStorage.setItem('total_gastoQ', totalG);
                                        }
                                    }
                                }
                                if (iva === false) {
                                    if (value) {
                                        setEditarD(false);
                                        setSeguro(value);
                                        let fobE = editar ? lectura.fob : 0.00;
                                        setFob(fobE);
                                        let fleteE = editar ? lectura.flete : 0.00;
                                        setFlete(fleteE);
                                        let otrosE = editar ? lectura.otros : 0.00;
                                        setOtros(otrosE)
                                        let total = parseFloat(parseFloat(value) + parseFloat(fob) + parseFloat(flete) + parseFloat(otros)).toFixed(2);
                                        setTotalGasto(total);
                                        setModificarT(true);
                                        setModificarD(true);
                                        let tipoCambio = editar ? lectura.tipo_cambio : tcambio;
                                        let totalG = parseFloat((parseFloat(total)*parseFloat(tipoCambio))).toFixed(2);
                                        setTIva(0.00);
                                        localStorage.setItem('ivaImport', 0.00);
                                        localStorage.setItem('total_gastoImp', total);
                                        localStorage.setItem('total_gastoQ', totalG);
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
                
            </div>
            <div className='row mb-2'>
                <div className='col-3'>
                    <div className="form-floating">
                        <Field
                            disabled={bloqueo}
                            placeholder="Otros USD"
                            name="otros"
                            component={renderCurrencyFloat}
                            msj="Otros USD"
                            onChange={(e, value) => {
                                if (iva) {
                                    if (value) {
                                        if (modificarD === false){
                                            setEditarD(false);
                                            setOtros(value);
                                            let fobE = editar ? lectura.fob : 0.00;
                                            setFob(fobE);
                                            let fleteE = editar ? lectura.flete : 0.00;
                                            setFlete(fleteE);
                                            let seguroE = editar ? lectura.seguro : 0.00;
                                            setSeguro(seguroE)
                                            let total = parseFloat((parseFloat(value) + parseFloat(fob) + parseFloat(flete) + parseFloat(seguro))).toFixed(2);
                                            setTotalGasto(total);
                                            setModificarT(true);
                                            setModificarD(true);
                                            let tipoCambio = editar ? lectura.tipo_cambio : tcambio;
                                            let totalG = parseFloat((parseFloat(total)*parseFloat(tipoCambio))).toFixed(2);
                                            let iva = parseFloat(parseFloat(totalG * porIva)).toFixed(2);
                                            setTIva(iva);
                                            localStorage.setItem('ivaImport', iva);
                                            localStorage.setItem('total_gastoImp', total);
                                            localStorage.setItem('total_gastoQ', totalG);
                                        }
                                        if (modificarD === true){
                                            setEditarD(false);
                                            setOtros(value);
                                            let total = parseFloat((parseFloat(value) + parseFloat(fob) + parseFloat(flete) + parseFloat(seguro))).toFixed(2);
                                            setTotalGasto(total);
                                            setModificarT(true);
                                            setModificarD(true);
                                            let tipoCambio = editar ? lectura.tipo_cambio : tcambio;
                                            let totalG = parseFloat((parseFloat(total)*parseFloat(tipoCambio))).toFixed(2);
                                            let iva = parseFloat(parseFloat(totalG * porIva)).toFixed(2);
                                            setTIva(iva);
                                            localStorage.setItem('ivaImport', iva);
                                            localStorage.setItem('total_gastoImp', total);
                                            localStorage.setItem('total_gastoQ', totalG);
                                        }
                                    }
                                }
                                if (iva === false) {
                                    if (value) {
                                        setEditarD(false);
                                        setOtros(value);
                                        let fobE = editar ? lectura.fob : 0.00;
                                        setFob(fobE);
                                        let fleteE = editar ? lectura.flete : 0.00;
                                        setFlete(fleteE);
                                        let seguroE = editar ? lectura.seguro : 0.00;
                                        setOtros(seguroE)
                                        let total = parseFloat((parseFloat(value) + parseFloat(fob) + parseFloat(flete) + parseFloat(seguro))).toFixed(2);
                                        setTotalGasto(total);
                                        setModificarT(true);
                                        setModificarD(true);
                                        let tipoCambio = editar ? lectura.tipo_cambio : tcambio;
                                        let totalG = parseFloat((parseFloat(total)*parseFloat(tipoCambio))).toFixed(2);
                                        setTIva(0.00);
                                        localStorage.setItem('ivaImport', 0.00);
                                        localStorage.setItem('total_gastoImp', total);
                                        localStorage.setItem('total_gastoQ', totalG);
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
                {(crear === false && editar == false) &&
                    <div className='col-3'>
                        <Field
                            disabled={bloqueo}
                            name="dai"
                            component={renderCurrencyFloat}
                            msj="DAI Q"
                        />
                    </div>
                }
                {(crear === true || editar == true) &&
                    <div className='col-3'>
                        <div className="form-floating">
                            <Field
                                placeholder="DAI Q"
                                name="dai"
                                component={renderCurrencyFloat}
                                msj="DAI Q"
                                onChange={(e, value) => {
                                    if (iva) {
                                        if (value) {
                                            setDai(value);
                                            if (modificarD === false){
                                                setEditarD(false);
                                                localStorage.setItem('dai', value);
                                                let tipoCambio = editar ? lectura.tipo_cambio : tcambio;
                                                let tot = editar ? lectura.totalGasto : totalGasto;
                                                setTotalGasto(tot)
                                                let total = parseFloat((parseFloat(totalGasto)*parseFloat(tipoCambio))+parseFloat(value)).toFixed(2);
                                                let Tiva = parseFloat(parseFloat(total) * porIva).toFixed(2);
                                                setTIva(Tiva);
                                                localStorage.setItem('ivaImport', Tiva);
                                            }
                                            if (modificarD === true){
                                                setEditarD(false);
                                                localStorage.setItem('dai', value);
                                                let total = parseFloat((parseFloat(totalGasto)*parseFloat(tcambio))+parseFloat(value)).toFixed(2);
                                                let Tiva = parseFloat(parseFloat(total) * porIva).toFixed(2);
                                                setTIva(Tiva);
                                                localStorage.setItem('ivaImport', Tiva);
                                            }
                                        }
                                    }
                                    if (iva === false) {
                                        if (value) {
                                            setDai(value);
                                            localStorage.setItem('dai', value);
                                            setTIva(0.00);
                                            localStorage.setItem('ivaImport', 0.00);

                                        }
                                    }
                                }}
                            />
                        </div>
                    </div>
                }
                {crear === false && editar == false &&
                    <div className='col-3'>
                        <Field
                            disabled={bloqueo}
                            name="totalGasto"
                            component={renderCurrencyFloat}
                            msj="Total gasto USD"
                        />
                    </div>
                }
                {(crear === true || editar == true) &&
                    <div className='col-3'>
                        <div className="form-floating">
                            <NumberFormat
                                className={classNames('form-control')}
                                decimalScale={2}
                                disabled={true}
                                placeholder="Total Gasto USD"
                                fixedDecimalScale
                                thousandSeparator
                                value={editarD === true ? lectura.totalGasto : totalGasto}
                                name="totalGasto"
                            />
                            <label className='form-label' htmlFor="totalGasto">
                                Total gasto USD
                            </label>
                        </div>
                    </div>
                }
                {crear === false && editar == false &&
                    <div className='col-3'>
                        <Field
                            disabled={bloqueo}
                            name="iva"
                            component={renderCurrencyFloat}
                            msj="Iva Q"
                        />
                    </div>
                }
                {(crear === true || editar == true) &&
                    <div className='col-3'>
                        <div className="form-floating">
                            <NumberFormat
                                className={classNames('form-control')}
                                decimalScale={2}
                                disabled={true}
                                placeholder="Iva Q"
                                fixedDecimalScale
                                thousandSeparator
                                value={editarD === true ? lectura.iva : tIva}
                                name="iva"
                            />
                            <label className='form-label' htmlFor="iva">
                                IVA Q
                            </label>
                        </div>
                    </div>
                }
            </div>
            
            <div className='mb-2'>
                <Field
                    name="observaciones"
                    placeholder="Descripcion"
                    component={renderField}
                    className="form-control"
                    disabled={bloqueo}
                    msj="Observaciones"
                />
            </div>
            <h5 className="p-2 bg-secondary title mt-1">Documentos</h5>
            {(crear === true || editar == true) &&
                <div>
                    <div className='row mb-2'>
                        <fieldset>
                            <div className='row'>
                                <div className='w-50'>
                                    <Field
                                        name="proveedor_documento"
                                        placeholder="Proveedor..."
                                        className="form-control-find"
                                        component={AsyncSelectField}
                                        loadOptions={listarProveedores}
                                        msj="Proveedor"
                                    />
                                </div>
                                <div className='col-3 mt-2'>
                                    <button
                                        className='btn mr-2 mb-3 btn-secondary'
                                        onClick={() => cambiarEstadoModal(!estadoModal)}
                                    >
                                        Crear nuevo proveedor
                                    </button>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-3'>
                            <div className="form-floating">
                                <Field
                                    name="fechaD"
                                    component={renderDayPickerB}
                                    msj="Fecha"
                                    maxDate={fechaFinal}
                                    minDate={'2000-01-01'}
                                />
                            </div>
                        </div>
                        <div className='col-3'>
                            <div className="form-floating">
                                <Field
                                    name="serie"
                                    placeholder="Serie"
                                    component={renderField}
                                    msj="Serie"
                                />
                            </div>
                        </div>
                        <div className='col-3'>
                            <div className="form-floating">
                                <Field
                                    name="numero"
                                    placeholder="Numero"
                                    component={renderField}
                                    msj="Numero"
                                />
                            </div>
                        </div>
                        <div className='col-3'>
                            <div className="form-floating">
                                <Field
                                    name="pedido"
                                    placeholder="Pedido"
                                    component={renderField}
                                    msj="Pedido"
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-3'>
                            <div className="form-floating">
                                <Field
                                    disabled={bloqueo}
                                    placeholder="monto USD"
                                    name="monto"
                                    component={renderCurrencyFloat}
                                    msj="Monto USD"
                                    onChange={(e, value) => {
                                        if (value) {
                                            setMontoD(value);
                                            localStorage.setItem('montoD', value);
                                            let tipoCambio = editar ? lectura.tipo_cambio : tcambio;
                                            let tcamb = localStorage.getItem('TcambioImp');
                                            if (tcamb === null){
                                                let total = (parseFloat(value)*parseFloat(tcambio)).toFixed(2) ;
                                                setTotalQ(total)
                                                localStorage.setItem('totalQ', total);
                                            }
                                            else{
                                                let total = (parseFloat(value)*tipoCambio).toFixed(2) ;
                                                setTotalQ(total)
                                                localStorage.setItem('totalQ', total);
                                            }
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div className='col-3'>
                            <div className="form-floating">
                                <NumberFormat
                                    className={classNames('form-control')}
                                    decimalScale={2}
                                    disabled={true}
                                    placeholder="Total Q"
                                    fixedDecimalScale
                                    thousandSeparator
                                    value={montoD >= 0.01 ? totalQ : 0.00}
                                    name="totalQ"
                                />
                                <label className='form-label' htmlFor="totalQ">
                                    Total Q
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                }
                <br />
            {(crear === true || editar === true) &&
                <Contenido>
                    <button
                        className='btn mr-2 mb-3 btn-primary'
                        onClick={() => {
                            registroImportacion(periodoD);
                            setTotalQ(0.00)
                        }}
                    >
                        Agregar documento
                    </button>
                </Contenido>
            }
            {crear === false && editar === false &&
                <div style={estilo}>
                    <TablaVerDetalle
                        detalle={detalleImp}
                        eliminarLinea={eliminarDocumentoImportacion}
                    />
                </div>
            }
            {(crear === true || editar === true) &&
                <div style={estilo}>
                    <TablaDetalle
                        detalle={detalleImp}
                        eliminarDocumento={eliminarDocumentoImportacion}
                    />
                </div>
            }
            <Modal
                estado={estadoModal}
                cambiarEstado={cambiarEstadoModal}
                titulo="Crear proveedor"
                botonCerrar="true"
            >
                <div className="d-flex flex-column flex-1 mx-4">
                    <div className='mb-2 w-25'>
                        <Field
                            autoFocus={autoFocus}
                            name="identificadorPro"
                            placeholder="Identificador"
                            className="form-control"
                            component={renderField}
                            msj="Identificador"
                        />
                    </div>
                    <div className='mb-2 w-50'>
                        <Field
                            name="nombre"
                            placeholder="Nombre"
                            className="form-control"
                            component={renderField}
                            msj="Nombre"
                        />
                    </div>
                    <div className='mb-2 w-75'>
                        <Field
                            name="direccion"
                            placeholder="Direccion"
                            className="form-control"
                            component={renderField}
                            msj="Dirección"
                        />
                    </div>
                    <div className='row'>
                        <div className='col-4'>
                            <Field
                                name="telefono"
                                placeholder="Telefono"
                                className="form-control"
                                component={renderField}
                                msj="Telefono"
                            />
                        </div>
                        <div className='col-4'>
                            <Field
                                name="correo"
                                placeholder="Correo electrónico"
                                className="form-control"
                                component={renderField}
                                msj="Correo electronico"
                            />
                        </div>
                        <div className='col-4'>
                            <Field
                                name="dias_credito"
                                placeholder="Dias credito"
                                className="form-control"
                                component={renderNumber}
                                msj="Dias crédito"
                            />
                        </div>
                    </div>
                    <div className='row mt-2'>
                        <div className='col-4'>
                            <Field
                                name="limite_credito"
                                placeholder="Limite credito"
                                className="form-control"
                                component={renderNumber}
                                msj="Limite crédito"
                            />
                        </div>
                        <div className='col-4'>
                            <div className="form-floating">
                                <Field
                                    name="tipo_proveedor"
                                    options={tipoProveedor}
                                    dato={crear === true ? 0 : lectura.tipo_proveedor}
                                    component={SelectField}
                                    className="form-select"
                                    msj="Tipo proveedor"
                                />
                            </div>
                        </div>
                        <div className='col-4'>
                            {obtenerPaises &&
                                <div className="form-floating">
                                    <Field
                                        name="pais"
                                        options={obtenerPaises}
                                        crear={crear}
                                        dato={crear === true ? 0 : lectura.pais}
                                        component={SelectField}
                                        className="form-select"
                                        msj="Pais"
                                    />
                                </div>
                            }
                        </div>
                    </div>
                    <div className='d-flex flex-row mt-3'>

                        <button
                            className='btn mr-2 mb-3 btn-primary'
                            onClick={registroProveedorExt}
                        >
                            Guardar
                        </button>

                        <button
                            className='btn mr-2 mb-3 btn-secondary'
                            onClick={() => cambiarEstadoModal(false)}
                        >
                            Salir
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

ImportacionForm.propTypes = {
    listarProveedores: PropTypes.object,
    crear: PropTypes.bool,
    lectura: PropTypes.object,
    tipos: PropTypes.object,
    editar: PropTypes.bool,
    bloqueo: PropTypes.bool,
    listarCuentas: PropTypes.object,
    listarProveedoresD: PropTypes.object,
    registroProveedorExt: PropTypes.func,
    tipoCambio: PropTypes.func,
    fechaInicio: PropTypes.string,
    fechaFinal: PropTypes.string,
};

export default reduxForm({
    form: 'FormImportacion',
})(ImportacionForm);

const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;