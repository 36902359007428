import React, { Component } from 'react';
import Formulario from './FormSeleccionarPeriodo';


class SeleccionarPeriodo extends Component{

    componentWillMount = () => {
        const { periodosList,listarEstablecimientos } = this.props;
        periodosList();
        listarEstablecimientos();
    }
    render(){
        const { periodoL, listaEs } = this.props;
        return(
           
            <Formulario
                periodoL={ periodoL } 
                listaEs = { listaEs }
            />
           
        );
    } 
}

export default SeleccionarPeriodo;