import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import NavbarModulo from '../../components/NavbarModulo';
import DetallePoliza from './DetallePoliza';

import {
    renderNumber,
    SelectField,
} from "../../Utils/renderField/renderField";

function GenerarPolizaForm(props) {
    const { modulo, listarPeriodos, listaPeriodo,
        generarPoliza, detallePoliza } = props;

    let titulo = 'Generar póliza';
    let autoFocus = true;
    let ruta = window.location.href;
    let id_emp = ruta.split('/');

    const handleOnChange = (e, value) => {
        if (value.length == 4) {
            listarPeriodos(value)
        }

    }

    return (
        <div>
            <NavbarModulo
                modulo={modulo}
                titulo={titulo}
            />
            <div className="mb-4 card card-small text-white bg-dark mb-3">
                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                    <div className="d-flex flex-column flex-1 mx-4">
                        <div className='row mb-2'>
                            <div className='col-2'>
                                <Field
                                    autoFocus={autoFocus}
                                    name="anio"
                                    placeholder="Año"
                                    className="form-control"
                                    component={renderNumber}
                                    onChange={handleOnChange}
                                    msj="Año"
                                />
                            </div>
                            {listaPeriodo &&
                                <div className='col-4'>
                                    <Field
                                        name="periodo"
                                        options={listaPeriodo}
                                        component={SelectField}
                                        className="form-select"
                                        msj="Periodos"
                                    />
                                </div>
                            }
                        </div>
                        <div className='d-flex flex-row mt-3'>
                            <button
                                className='btn mr-2 mb-3 btn-primary'
                                type='submit'
                                onClick={generarPoliza}
                            >
                                Generar póliza
                            </button>
                            <a
                                href={`/#/visitador_medico/${id_emp[5]}`}
                                className='btn btn-secondary mb-3'
                            >
                                Regresar
                            </a>
                        </div>
                        <DetallePoliza
                            detalle={detallePoliza}
                        />
                        <br/>
                    </div>
                </div>
            </div>
        </div >
    );
}

GenerarPolizaForm.propTypes = {
    modulo: PropTypes.object,
};

export default reduxForm({
    form: 'FormGenerarPolizaVM',
})(GenerarPolizaForm);
