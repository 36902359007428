import React, { useState, useEffect } from "react";
import DataTableBase from "../../components/DataTableWSearch";
import GenBtn from "../../components/GenBtn";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Banner from "../common/Banner";
import { getVehiculos } from "./actions";
import * as Fa from "react-icons/fa";

function Vehiculos({ history, ...props }) {
    const [tableData, setTableData] = useState();

    const columns = [
        {
            name: "Placa",
            selector: (row) => row.placa,
            sortable: false,
        },
        {
            name: "Linea",
            selector: (row) => row.linea,
            sortable: true,
        },
        {
            name: "Modelo",
            selector: (row) => row.modelo,
            sortable: true,
        },
        {
            name: "Chasis",
            selector: (row) => row.chasis,
            sortable: true,
        },
        // {
        //     name: "Email",
        //     selector: (row) => row.email,
        //     sortable: true,
        // },
        {
            cell: (row) => (
                <GenBtn
                    key={row.id}
                    textColor="hsl(0, 0%, 80%)"
                    background="hsla(0, 100%, 100%, 0.2)"
                    onPress={(e) => {
                        history.push(
                            `${history.location.pathname}/editar/${row.id}`
                        );
                    }}
                >
                    <Fa.FaPen />
                </GenBtn>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            cell: (row) => (
                <GenBtn
                    textColor="hsl(50, 50%, 80%)"
                    background="hsla(50, 50%, 80%, 0.2)"
                    onPress={(e) => {
                        history.push(
                            `${history.location.pathname}/ver/${row.id}`
                        );
                    }}
                >
                    <Fa.FaEye />
                </GenBtn>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    async function fetchData() {
        const data = await getVehiculos();
        setTableData(data);
    }
    useEffect(() => {
        fetchData();
    }, []);

    return (
        <main>
            <Banner>Vehiculos</Banner>
            <GenBtn onPress={() => (location.href += "/crear")}>Crear</GenBtn>
            <DataTableBase data={tableData} columns={columns} />
        </main>
    );
}

export default withRouter(Vehiculos);
