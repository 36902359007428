import { connect } from 'react-redux';
import { actions } from '../../../redux/modules/visitador_medico/compras';
import VisitadorMedico from './VisitadorMedico';


const ms2p = (state) => {
  return {
    ...state.vm_carga_compras,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(VisitadorMedico);
