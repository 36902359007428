import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/produccion/tipoGasto';
import TipoGastoList from './tipoGastoList';


const ms2p = (state) => {
    return {
        ...state.tipo_gasto_prd,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(TipoGastoList);