import React, { useState, useEffect } from "react";
import DataTableBase from "../../components/DataTableWSearch";
import GenBtn from "../../components/GenBtn";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Banner from "../common/Banner";
import { getList, eliminar } from "./actions";
import * as Fa from "react-icons/fa";
import LoadMask from "../../Utils/LoadMask/LoadMask";
import FicModal from "../../Utils/FormInputComponents/FicModal";
import OptionsBar from "../common/OptionsBar";
import { getPeriodo } from "../utils";

const BannerText = "Abonos";

const ExpandedRowUl = styled.ul`
    padding: 1rem;
`;

const ExpandedRowLi = styled.li`
    display: flex;
    list-style-type: none;
    ::before {
        content: "• ";
        color: #00e05d;
    }
`;

function Listado({ history, ...props }) {
    const [tableData, setTableData] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [eliminarModal, setEliminarModal] = useState(false);
    const periodo = getPeriodo();

    const ExpandedComponent = ({ data }) => {
        return (
            <ExpandedRowUl>
                {data.ingresos_patologia.map((item) => (
                    <ExpandedRowLi>
                        &nbsp;
                        <div style={{ color: "#cf174b" }}>
                            {item.ingreso_patologia}
                        </div>
                        &nbsp;|&nbsp; Monto:&nbsp;
                        <div style={{ color: "#cf174b" }}>
                            {item.monto_abono}
                        </div>
                    </ExpandedRowLi>
                ))}
            </ExpandedRowUl>
        );
    };

    const columns = [
        {
            name: "Fecha de registro",
            selector: (row) => row.fecha_registro,
            sortable: true,
        },
        {
            name: "Serie y numero",
            selector: (row) => `${row.serie}/${row.numero}`,
            sortable: true,
        },
        {
            name: "Referencia",
            selector: (row) => row.referencia,
            sortable: false,
        },
        {
            name: "Monto",
            selector: (row) => row.monto,
            sortable: false,
        },

        {
            cell: (row) => (
                // <GenBtn
                //     key={row.id}
                //     textColor="hsl(0, 0%, 80%)"
                //     background="hsla(0, 100%, 100%, 0.2)"
                //     toolTipText="Editar"
                //     onPress={(e) => {
                //         history.push(
                //             `${history.location.pathname}/editar/${row.id}`
                //         );
                //     }}
                // >
                //     <Fa.FaTrash />
                // </GenBtn>
                <FicModal
                    title="Eliminar abono"
                    isOpen={eliminarModal}
                    onOpenChange={setEliminarModal}
                    triggerButton={
                        <GenBtn
                            onPress={() => setEliminarModal(true)}
                            buttonColor="rgb(255, 0, 0)"
                            toolTipText="Eliminar"
                        >
                            <Fa.FaTrash />
                        </GenBtn>
                    }
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <p>¿Está seguro que desea eliminar el abono?</p>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-around",
                                gap: "2rem",
                            }}
                        >
                            <GenBtn
                                onPress={(e) => {
                                    setEliminarModal(false);
                                }}
                            >
                                Cancelar
                            </GenBtn>
                            <GenBtn
                                onPress={async (e) => {
                                    await eliminar(row.id);
                                    setEliminarModal(false);
                                    fetchData();
                                }}
                                buttonColor="rgb(255, 0, 0)"
                            >
                                Eliminar
                            </GenBtn>
                        </div>
                    </div>
                </FicModal>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        // {
        //     cell: (row) => (
        //         <GenBtn
        //             textColor="hsl(50, 50%, 80%)"
        //             background="hsla(50, 50%, 80%, 0.2)"
        //             toolTipText="Ver"
        //             onPress={(e) => {
        //                 history.push(
        //                     `${history.location.pathname}/ver/${row.id}`
        //                 );
        //             }}
        //         >
        //             <Fa.FaEye />
        //         </GenBtn>
        //     ),
        //     ignoreRowClick: true,
        //     allowOverflow: true,
        //     button: true,
        // },
    ];

    async function fetchData() {
        const data = await getList();
        setTableData(data);
        setIsLoading(false);
    }
    useEffect(() => {
        fetchData();
    }, [periodo]);

    return (
        <main>
            <OptionsBar />
            <Banner>{BannerText}</Banner>
            <GenBtn onPress={() => (location.href += "/crear")}>Crear</GenBtn>
            <LoadMask loading={isLoading} color="#bd0034" blur={true}>
                <DataTableBase
                    data={tableData}
                    columns={columns}
                    expandableRows
                    expandableRowsComponent={ExpandedComponent}
                />
            </LoadMask>
        </main>
    );
}

export default withRouter(Listado);
