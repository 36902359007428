import PropTypes from 'prop-types';
import React from 'react';
import { reduxForm } from 'redux-form';
import { Link, Redirect } from 'react-router-dom';
import RegistroForm from './RegisterForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";

function Register(props) {
    const { onSubmit, loader } = props;
    if (localStorage.getItem('token')) {
        return (<Redirect to="/" />);
    }
    const test = __CONFIG__.test;
    return (
        test === true ? (
            <div className="blue-gradient-bg">
                <div className="d-flex flex-column align-items-center pt-3 bienvenida title">
                    <img className="mt-5 imageReg"
                        src={require('assets/img/SIFW-LOGO-Red.png')}
                        alt="Logo" />
                </div>
                <br />
                <div className="login-wrapper">
                    <div className="card2 card border-0 px-4 py-5 bg-dark col-lg-6 col-md-4 col-11">
                        <h4 className="text-center pv mb-4 title">Registro de Cuenta</h4>
                        <LoadMask loading={loader} dark>
                            <RegistroForm onSubmit={onSubmit} />
                        </LoadMask>
                        <span>¿Ya tienes cuenta?&nbsp;<Link to="/login" style={{ textDecoration: 'none', color: '#fff' }}>Ingresa aquí</Link></span>
                    </div>
                </div>
            </div>
        ) : (
            <Redirect to="/pro/login_registro" />
        )
    );

}
Register.propTypes = {
    onSubmit: PropTypes.func,
    loader: PropTypes.bool,
};
export default reduxForm({
    form: 'Registro', //identificador unico
})(Register)

