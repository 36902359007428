import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import ParametrosContabilidad from './ParametrosContabilidad';
import ParametrosCompras from './ParametrosCompras';
import ParametrosFacturacion from './ParametrosFacturacion';
const estilo = {
    color: "white",
};
class ParametrosForm extends Component {
    
    render() {
        const { handleSubmit, leer, compras, funcionC, facturacion,
            funcionF, lectura, conta, compra, factura } = this.props;
        
        return (
            <React.Fragment>
            { lectura >=2 &&
                <div className="py-4">
                    <div className="mb-4">
                        <div className="p-0 px-3 pt-3">
                            <Tabs
                                defaultActiveKey="PRINCIPAL_TOP"
                                tabBarPosition="top"
                                onChange={this.callback}
                                renderTabBar={() => <ScrollableInkTabBar />}
                                renderTabContent={() => <TabContent />}
                            >
                                {conta === '01' &&
                                    <TabPane tab="Contabilidad" key="PRINCIPAL_TOP">
                                        <div className="py-4 px-3">
                                            <ParametrosContabilidad 
                                                leerParametro={leer}
                                                handleSubmit={handleSubmit}
                                            />         
                                        </div>
                                    </TabPane>
                                }
                                {compra === '02' &&
                                    <TabPane tab="Compras y CXP" key="SEGUNDO_TOP">
                                        <div className="py-4 px-3">
                                            <ParametrosCompras
                                                leerParametroCompras={compras}
                                                enviar={funcionC}
                                            />      
                                        </div>
                                    </TabPane>
                                }
                                {factura === '04' &&
                                    <TabPane tab="Facturación y CXC" key="TERCER_TOP">
                                        <div className="py-4 px-3">
                                            <ParametrosFacturacion
                                                leerParametroFacturacion={facturacion}
                                                enviar={funcionF}
                                            />      
                                        </div>
                                    </TabPane>
                                }
                            </Tabs>
                        </div>
                    </div>
                </div>
            }
            {lectura === 1 &&
                <div className="py-4">
                    <h2 style={estilo}>No tiene modulos asignados</h2>
                </div>
            }
           </React.Fragment>
        )
    }
}

export default reduxForm({
    form: 'parametrosForm', //identificador unico
})(ParametrosForm)
