import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/facturacion/clientes';
import Cliente from './ClientesList';

const ms2p = (state) => {
  return {
    ...state.clientes,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Cliente);
