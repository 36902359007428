import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
    FicComboBox,
    FicInput,
    FicTextArea,
    FicDatePicker,
    FicNumber,
} from "../../Utils/FormInputComponents/index";
import DialogModal from "../../components/DialogModal";
import { useEffect } from "react";
import "../../../../../style/autoVentas/VentasForm.css";
import GenBtn from "../../components/GenBtn";
import { validar } from "../../components/ValidarNit";
import { withRouter } from "react-router";
import styled, { keyframes } from "styled-components";
import LoadMask from "../../Utils/LoadMask/LoadMask";

import { isWeekend, today } from "@internationalized/date";
import { useLocale } from "@react-aria/i18n";
import { getLocalTimeZone } from "@internationalized/date";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const Overlay = styled.div`
    font-size: 1.5rem;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
    border-radius: 1rem;
    animation: ${({ isActive }) => (isActive ? fadeIn : fadeOut)} 0.3s
        ease-in-out forwards;
    pointer-events: ${({ isActive }) => (isActive ? "auto" : "none")};
    cursor: ${({ isActive }) => (isActive ? "default" : "initial")};
    z-index: 20;
`;

const DialogContent = styled.div`
    height: 205px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;
`;

const schema = yup.object({
    carro: yup.mixed().required("La fecha es requerida"),
    fecha_venta: yup.string().required("La fecha es requerida"),
    vendedor: yup.mixed().required("El vendedor es requerido"),
    comprador_nombre: yup.string().required("El comprador es requerido"),
    comprador_dpi: yup
        .number()
        .typeError("Debe de ser un numero")
        .test("dpi-validation", "El dpi no es valido", function (value) {
            return validar.valCui(value.toString())[0].validar;
        })
        .required("El numero de dpi es requerido"),
    comprador_domicilio: yup.string().required("El domicilio es requerido"),
    comprador_departamento: yup
        .string()
        .required("El departamento es requerido"),
    comprador_profesion: yup.string().required("La profesion es requerida"),
    comprador_num_telefono: yup
        .number()
        .typeError("Debe de ser un numero")
        .required("El numero de telefono es requerido"),
    comprador_nit: yup
        .string()
        .test("dpi-validation", "El NIT no es valido", function (value) {
            return validar.valNit(value);
        })
        .required("El nit es requerido"),
    comprador_edad: yup
        .number()
        .positive("La edad debe ser mayor a 0")
        .typeError("Debe de ser un numero")
        .required("La edad es requerida"),
    comprador_estado_civil: yup
        .string()
        .required("El estado civil es requerido"),
    valor_venta: yup
        .number()
        .positive("El valor de venta debe ser mayor a 0")
        .typeError("Debe de ser un numero")
        .required("El valor de venta es requerido"),
});

function VendedoresForm(props) {
    const {
        crearVenta,
        datosDelIngreso,
        listadoDeIngresos,
        listarVendedores,
        reporteVentaDeVehiculos,
        history,
    } = props;

    const urlParams = props.match.params;
    const empresa = urlParams.empresa;
    const [ventaId, setVentaId] = useState("");
    const [loadingState, setLoadingState] = useState(false);
    const [fetchedValues, setFetchedValues] = useState(null);
    const [carroKey, setCarroKey] = useState(1);
    const dialogRef = useRef(null);
    const defaultValues = {
        fecha_venta: null,
        vendedor: null,
        carro: null,
        comprador_nombre: "",
        comprador_dpi: "",
        comprador_domicilio: "",
        comprador_departamento: "",
        comprador_profesion: "",
        comprador_num_telefono: "",
        comprador_nit: "",
        comprador_edad: "",
        comprador_estado_civil: "",
        valor_venta: "",
        observaciones: "",
    };

    const {
        handleSubmit,
        reset,
        control,
        formState: { errors },
        watch,
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues: defaultValues,
    });

    const {
        handleSubmit: handleSubmitAutoData,
        control: controlAutoData,
        formState: { errorsAutoData },
        reset: resetAutoData,
    } = useForm({
        mode: "all",
        defaultValues: {
            placa: "",
            codigo: "",
            tipo_carro: "",
            modelo: "",
            linea: "",
            numero_serie: "",
        },
        values: fetchedValues,
    });

    const carro = watch("carro");

    async function fetchData(ingresoId) {
        setLoadingState(true);
        const formData = await datosDelIngreso(ingresoId);
        setFetchedValues(formData);
        setLoadingState(false);
    }

    useEffect(() => {
        if (carro) {
            fetchData(carro.value);
        }
        if (!carro) {
            resetAutoData({
                placa: "",
                codigo: "",
                tipo_carro: "",
                modelo: "",
                linea: "",
                numero_serie: "",
            });
        }
        // carro? fetchData(carro.value) : reset(defaultValues)
    }, [carro]);

    async function onSubmit(data) {
        await crearVenta(data).then((data) => {
            setVentaId(data.id);
            if (data) {
                dialogRef.current.showModal();
            }
        });

        reset(defaultValues);
        setCarroKey((a) => a + 1);
    }

    return (
        <React.Fragment>
            <br />
            <h3
                className="p-2  title rounded shadow"
                style={{
                    background:
                        "linear-gradient(to top right, #790021, #2d2d2d)",
                }}
            >
                Agregar Venta
            </h3>
            <br />
            <div className="d-flex flex-row justify-content-start mb-2">
                <GenBtn
                    BtnColor="#787878"
                    style={{ width: "100px", height: "40px" }}
                    onPress={(e) => {
                        history.push(`/auto_ventas/${empresa}/ventas`);
                    }}
                >
                    Regresar
                </GenBtn>
            </div>
            <LoadMask loading={loadingState} color="#bd0034" blur={true}>
                <form
                    className="ventas-form__auto-data"
                    onSubmit={handleSubmitAutoData(() => {})}
                >
                    <div style={{ color: "#bd0034" }}>DATOS DEL VEHICULO</div>
                    <div className="ventas-form__inputs-container">
                        <FicInput
                            label="Placa"
                            name="placa"
                            control={controlAutoData}
                            isDisabled={true}
                        />
                        <FicInput
                            label="Codigo"
                            name="codigo"
                            control={controlAutoData}
                            isDisabled={true}
                        />
                        <FicInput
                            label="Tipo de carro"
                            name="tipo_carro"
                            control={controlAutoData}
                            isDisabled={true}
                        />
                        <FicInput
                            label="Modelo"
                            name="modelo"
                            control={controlAutoData}
                            isDisabled={true}
                        />
                        <FicInput
                            label="Linea"
                            name="linea"
                            control={controlAutoData}
                            isDisabled={true}
                        />

                        <FicInput
                            label="Numero de serie"
                            name="num_serie"
                            control={controlAutoData}
                            isDisabled={true}
                        />
                        <FicInput
                            label="Modalidad"
                            name="modalidad"
                            control={controlAutoData}
                            isDisabled={true}
                        />
                    </div>
                </form>
            </LoadMask>

            <form
                encType="multipart/form-data"
                onSubmit={handleSubmit(onSubmit)}
            >
                <FicComboBox
                    key={carroKey}
                    componentStyle={{
                        marginTop: "15px",
                        marginBottom: "15px",
                    }}
                    control={control}
                    name="carro"
                    label="Carro"
                    loadOptions={listadoDeIngresos}
                />
                <div className="ventas-form__form-container">
                    <Overlay isActive={carro ? false : true}>
                        Seleccione un carro para continuar
                    </Overlay>
                    <div style={{ color: "#bd0034" }}>DATOS DE LA VENTA</div>
                    <div className="ventas-form__inputs-container">
                        <FicDatePicker
                            control={control}
                            name="fecha_venta"
                            label="Fecha"
                        />
                        <FicComboBox
                            componentStyle={{ zIndex: "3" }}
                            control={control}
                            name="vendedor"
                            label="Vendedor"
                            loadOptions={listarVendedores}
                        />
                        <FicInput
                            label="Comprador"
                            name="comprador_nombre"
                            control={control}
                        />
                        <FicInput
                            label="Numero de DPI"
                            name="comprador_dpi"
                            control={control}
                        />
                        <FicInput
                            label="Domicilio fiscal"
                            name="comprador_domicilio"
                            control={control}
                        />
                        <FicInput
                            label="Departamento"
                            name="comprador_departamento"
                            control={control}
                        />
                        <FicInput
                            label="Profesion u oficio"
                            name="comprador_profesion"
                            control={control}
                        />
                        <FicInput
                            label="Numero de Telefono"
                            name="comprador_num_telefono"
                            control={control}
                        />
                        <FicInput
                            label="NIT"
                            name="comprador_nit"
                            control={control}
                        />
                        <FicInput
                            label="Edad"
                            name="comprador_edad"
                            control={control}
                        />
                        <FicInput
                            label="Estado civil"
                            name="comprador_estado_civil"
                            control={control}
                        />
                        {/* <FicInput
                            label="Valor de venta"
                            name="valor_venta"
                            type="number"
                            control={control}
                        /> */}
                        <FicNumber
                            formatOptions={{
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }}
                            label="Valor de venta"
                            name="valor_venta"
                            control={control}
                        />

                        <FicTextArea
                            label="Observaciones"
                            name="observaciones"
                            control={control}
                            style={{
                                minHeight: "36.7px",
                                gridColumn: "1/-1",
                            }}
                        />
                    </div>
                    <GenBtn
                        type="submit"
                        className="ventas-form__guardar-ingreso-btn"
                        style={{ marginTop: "15px" }}
                    >
                        Guardar
                    </GenBtn>
                </div>
            </form>

            <DialogModal dialogRef={dialogRef} noHeader={true}>
                <DialogContent>
                    <div
                        style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "#bd0034",
                        }}
                    >
                        Desea generar el finiquito?
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-around",
                            marginTop: "15px",
                            width: "100%",
                        }}
                    >
                        <GenBtn
                            textColor="hsl(4, 100%, 39%)"
                            background="hsla(4, 100%, 39%, 0.2)"
                            onPress={() => {
                                dialogRef.current.close();
                            }}
                        >
                            Cerrar
                        </GenBtn>
                        <GenBtn
                            onPress={() => {
                                reporteVentaDeVehiculos(ventaId);
                                setTimeout(() => {
                                    dialogRef.current.close();
                                }, 3000);
                            }}
                            autofocus="autofocus"
                        >
                            Generar
                        </GenBtn>
                    </div>
                </DialogContent>
            </DialogModal>
        </React.Fragment>
    );
}

export default withRouter(VendedoresForm);
