import React, { Component } from 'react';
import Formulario from './BuscarFacturaForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";

class BuscarFactura extends Component{

    componentDidMount = () => {
        const { getModulo } = this.props;
        let ruta = window.location.href;
        let periodo = ruta.split('/');
        getModulo(periodo[5], periodo[4]);
    }

    render(){
        const { loader, buscarFactura, factura, modulo } = this.props;
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    buscarFactura = {buscarFactura}
                    factura = {factura}
                    modulo = {modulo}
                />
            </LoadMask>
        );
    }
}

export default BuscarFactura;
