import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/visitador_medico/centro_costo';
import CentroCosto from './CentroCosto';


const ms2p = (state) => {
  return {
    ...state.vm_centro_costo,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(CentroCosto);

