import React from 'react';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import * as BsIcons from "react-icons/bs";

export const SidebarData = [
    {
        title: 'Inicio',
        path: '/rr_hh',
        icon: <BsIcons.BsGrid1X2Fill />,
    },
    {
        title: 'Catalogos',
        path: '#',
        icon: <FaIcons.FaBook />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav: [
            {
                title: 'Departamentos',
                path: '/rr_hh',
                path_s: 'departamento',
                icon: <FaIcons.FaListUl />,
            },
            {
                title: 'Puestos',
                path: '/rr_hh',
                path_s: 'puesto',
                icon: <FaIcons.FaGripHorizontal />,
            },
            {
                title: 'Pais',
                path: '/rr_hh',
                path_s: 'pais',
                icon: <FaIcons.FaIdBadge />,
            },
        ]
    },
    {
        title: 'Colaboradores',
        path: '#',
        icon: <FaIcons.FaIdCardAlt />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav: [
            {
                title: 'Ficha de ingreso',
                path: '/rr_hh',
                path_s: 'seleccionar',
                icon: <RiIcons.RiUserAddFill />,
            },
            {
                title: 'Baja de colaborador',
                path: '/rr_hh',
                path_s: 'seleccionar_pais_baja',
                icon: <RiIcons.RiUserUnfollowFill />,
            },
            {
                title: 'Aumento de salario',
                path: '/rr_hh',
                path_s: 'aumento_salario',
                icon: <BsIcons.BsCash />,
            },
            {
                title: 'Promoción de puesto',
                path: '/rr_hh',
                path_s: 'cambio_puesto',
                icon: <BsIcons.BsPersonBadge />,
            },
            {
                title: 'Liquidación laboral',
                path: '/rr_hh',
                path_s: 'liquidacion_laboral',
                icon: <RiIcons.RiUserUnfollowFill />,
            },
            {
                title: 'Vacaciones',
                path: '/rr_hh',
                path_s: 'vacaciones',
                icon: <FaIcons.FaBriefcase />,
            },
            {
                title: 'Corte de vacaciones',
                path: '/rr_hh',
                path_s: 'corte_vacaciones',
                icon: <BsIcons.BsFillKeyFill/>,
            },
        ]
    },
    {
        title: 'Descuentos',
        path: '#',
        icon: <FaIcons.FaMinus />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav: [
            {
                title: 'ISR',
                path: '/rr_hh',
                path_s: 'seleccionar_periodo_isr',
                icon: <FaIcons.FaList />,
            },
            {
                title: 'Tipo de descuento',
                path: '/rr_hh',
                path_s: 'tipo_descuento_rr_hh',
                icon: <FaIcons.FaList />,
            },
            {
                title: 'Descuentos fijos',
                path: '/rr_hh',
                path_s: 'descuentos_fijos',
                icon: <FaIcons.FaList />,
            },
            {
                title: 'Préstamos',
                path: '/rr_hh',
                path_s: 'prestamos',
                icon: <FaIcons.FaList />,
            },
            {
                title: 'Faltas',
                path: '/rr_hh',
                path_s: 'seleccionar_periodo_faltas',
                icon: <FaIcons.FaList />,
            },
            {
                title: 'Judicial',
                path: '/rr_hh',
                path_s: 'judicial',
                icon: <FaIcons.FaList />,
            },
        ]
    }, 
    {
        title: 'Suspensiones',
        path: '#',
        icon: <FaIcons.FaMinus />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav: [
            {
                title: 'Suspensión de igss',
                path: '/rr_hh',
                path_s: 'baja_igss',
                icon: <FaIcons.FaArrowDown />,
            },
            {
                title: 'Alta de igss',
                path: '/rr_hh',
                path_s: 'alta_igss',
                icon: <FaIcons.FaArrowUp />,
            },
        ]
    }, 
    {
        title: 'Informes',
        path: '/rr_hh/informes',
        icon: <FaIcons.FaClipboard />,
    }
]
