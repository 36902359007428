import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/compras/ordenCompra';
import OrdenCompra from './OrdenCompra';

const ms2p = (state) => {
  return {
    ...state.cmp_orden_compra,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(OrdenCompra);
