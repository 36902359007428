import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import NavbarModulo from '../../components/NavbarModulo';
import DetalleListado from './DetalleListadoPrecios';
import DetalleListadoVer from './DetalleListadoPreciosVer';
import styled from 'styled-components';

import {
    renderField,
    AsyncSelectField,
    renderCurrencyFloatTC
} from "../../Utils/renderField/renderField";

function ListadoPreciosForm(props) {
    const {
        crear,
        listarProductos,
        modulo,
        periodoD,
        detalleProducto,
        registroListado,
        eliminarLineaProducto,
        modificarListado
    } = props;

    const estilo = {
        overflow: "auto",
        height: "500px",

    };
    const editar = window.location.href.includes('editar');
    let titulo = editar ? 'Modificar listado de precios' : 'Nuevo listado de precios';
    let disabled = false;
    if (crear === false && editar === false) {
        disabled = true;
        titulo = 'Ver listado de precios'
    }
    let ruta = window.location.href;
    let id_emp = ruta.split('/');

    return (
        <div>
            <NavbarModulo
                periodoD = {periodoD}
                modulo = {modulo}
                titulo = {titulo}
            />
            <div className="mb-4 card card-small text-white bg-dark mb-3">
                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                    <div className="d-flex flex-column flex-1 mx-4">
                        <div className='row mb-2'>
                            <div className='col-8'>
                                <Field
                                    name='nombre_lista'
                                    disabled={disabled}
                                    component={renderField}
                                    className="form-control"
                                    placeholder="Nombre listado"
                                    msj="Nombre listado"
                                />
                            </div>
                        </div>
                        {(crear === true || editar === true) &&
                            <div>
                                <div className='row mb-2'>
                                    <div className='col-10'>
                                        <Field
                                            name="producto"
                                            placeholder="Producto..."
                                            className="form-control-find"
                                            loadOptions={listarProductos}
                                            component={AsyncSelectField}
                                            msj="Producto"
                                        />
                                    </div>
                                    <div className='col-2'>
                                        <Field
                                            name="precio"
                                            placeholder="Precio"
                                            className="form-control"
                                            component={renderCurrencyFloatTC}
                                            msj="Precio"
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {(crear === true || editar === true) &&
                            <Contenido>
                                <button
                                    className='btn mr-2 mb-3 btn-primary'
                                    onClick={() => registroListado()}
                                >
                                    Agregar linea
                                </button>
                            </Contenido>
                        }
                        <br/>
                        {(crear === true || editar === true) &&
                            <div style={estilo}>
                                <DetalleListado
                                    detalle={detalleProducto}
                                    eliminarLineaProducto={eliminarLineaProducto}
                                />
                            </div>
                        }
                        {(crear === false && editar === false) &&
                            <div style={estilo}>
                                <DetalleListadoVer
                                    detalle={detalleProducto}
                                    eliminarLineaProducto={eliminarLineaProducto}
                                />
                            </div>
                        }
                        <br />
                        <div className='d-flex flex-row mt-3'>
                            {disabled == false &&
                                <button
                                className='btn mr-2 mb-3 btn-primary'
                                onClick={modificarListado}
                                >
                                {editar ? 'Modificar' : 'Guardar'}
                                </button>
                            }
                            <a
                                href = {`/#/facturacion/${id_emp[5]}/listado_precios`}
                                className='btn btn-secondary mb-3'
                            >
                                Regresar
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

ListadoPreciosForm.propTypes = {
    crear: PropTypes.bool,
    lectura: PropTypes.object,
    editar: PropTypes.bool,
    bloqueo: PropTypes.bool,
    modulo: PropTypes.object,
    periodoD: PropTypes.object,
    obtenerBodegas: PropTypes.array,
    listarProductos: PropTypes.object,
    detalleProducto: PropTypes.object,
    registroListado: PropTypes.func,
    modificarListado: PropTypes.func,
    eliminarLineaProducto: PropTypes.func,
    confirmarEntrada: PropTypes.func,
    periodoD: PropTypes.object,
};

const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;

export default reduxForm({
    form: 'FormListadoPrecios',
})(ListadoPreciosForm);
