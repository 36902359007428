import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from '../../components/Modal';
import PropTypes from 'prop-types';

function FormSeleccionarRegistro(props) {
    const {
        periodoL,
        datos
    } = props;

    const tipos = [
        {
            value: 'ENTRADAS',
            label: 'ENTRADAS'
        },
        {
            value: 'SALIDAS',
            label: 'SALIDAS'
        },
        {
            value: 'INGINVOC',
            label: 'INGRESO DE INVENTARIO OC'
        }
    ];

    const tiposCons = [
        {
            value: 'ENTRADAS',
            label: 'ENTRADAS'
        },
        {
            value: 'SALIDAS',
            label: 'SALIDAS'
        },
        {
            value: 'INGINVOC',
            label: 'INGRESO DE INVENTARIO OC'
        },
        {
            value: 'CONSMAT',
            label: 'CONSUMO DE MATERIALES OS'
        }
    ];

    const [id, setId] = useState("");
    
    const [idReg, setIdReg] = useState("");

    const handleChangeReg = e => {
        setIdReg(e.target.value);
    }
    
    const handleChange = e => {
        setId(e.target.value);
    }
    
    const [estadoModal, cambiarEstadoModal] = useState(true);
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    return (
        <div className='container'>
            <Modal
                estado={estadoModal}
                cambiarEstado={cambiarEstadoModal}
                titulo="Seleccionar movimiento inventario y periodo"
                botonCerrar="false"
            >
                {datos.ordenes_servicio === 'N' &&
                    <div className="form-floating mb-3">
                        <select className="form-select" id="tipo" name="tipo"
                            value={idReg} onChange={handleChangeReg} autoFocus >
                            <option selected>Seleccionar...</option>
                            {tipos.map(
                                tipo => <option key={tipo.value} value={tipo.value}
                                >{tipo.label}
                                </option>)
                            }
                            </select>
                        <label className='form-label' htmlFor="mes">Movimiento</label>
                    </div>
                }
                {datos.ordenes_servicio === 'S' &&
                    <div className="form-floating mb-3">
                        <select className="form-select" id="tipo" name="tipo"
                            value={idReg} onChange={handleChangeReg} autoFocus >
                            <option selected>Seleccionar...</option>
                            {tiposCons.map(
                                tipo => <option key={tipo.value} value={tipo.value}
                                >{tipo.label}
                                </option>)
                            }
                            </select>
                        <label className='form-label' htmlFor="mes">Movimiento</label>
                    </div>
                }
                {periodoL && idReg != '' &&
                    <div className="form-floating">
                        <select className="form-select" id="periodo"
                            value={id} onChange={handleChange} >
                            <option selected>Seleccionar...</option>
                            {periodoL.map(mes =>
                                <option key={mes.value} value={mes.value}>{mes.label}</option>)
                            }

                        </select>
                        <label className='form-label' htmlfor="periodo">Periodo</label>
                    </div>
                }
                <br />
                <Contenido>
                    {periodoL.length > 0 && idReg === 'ENTRADAS' &&
                        (id &&
                        <a
                            href={`/#/inventario/${id_emp[5]}/entradas/${id}`}
                            className='btn mr-2 mb-3 btn-primary'
                        >
                            Seleccionar
                        </a>
                        )
                    }
                    {periodoL.length > 0 && idReg === 'SALIDAS' &&
                        (id &&
                        <a
                            href={`/#/inventario/${id_emp[5]}/salidas/${id}`}
                            className='btn mr-2 mb-3 btn-primary'
                        >
                            Seleccionar
                        </a>
                        )
                    }
                    {periodoL.length > 0 && idReg === 'INGINVOC' &&
                        (id &&
                        <a
                            href={`/#/inventario/${id_emp[5]}/ingreso_inventario/${id}`}
                            className='btn mr-2 mb-3 btn-primary'
                        >
                            Seleccionar
                        </a>
                        )
                    }
                    {periodoL.length > 0 && idReg === 'CONSMAT' &&
                        (id &&
                        <a
                            href={`/#/inventario/${id_emp[5]}/consumo_producto/${id}`}
                            className='btn mr-2 mb-3 btn-primary'
                        >
                            Seleccionar
                        </a>
                        )
                    }
                </Contenido>
            </Modal>
        </div>
    );

}
FormSeleccionarRegistro.propTypes = {
    periodoL: PropTypes.string.isRequired,
};

export default (FormSeleccionarRegistro);

const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;