import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import Card from './EmpresaCard';
import LoadMask from "../Utils/LoadMask/LoadMask";

let foto = '';
let ruta = '/ruta';

const renderEmpresas = (empresa) => {
    return empresa.map((value, index) =>
        <Grid item sm={4} xs={12}>
            <Card 
                key={index} 
                logo={value.logo === null ? foto : value.logo}
                name = {value.razon_social}
                ruta = {ruta}
                id =  {value.id}
                valNit = {value.validar_nit}
                valDPI = {value.validar_dpi}
                conta = {value.contabilidad}
                inmo = {value.inmobiliaria}
                saldo = {value.saldos}
                estab = {value.empresa_sucursal}
                c_c = {value.centro_costo}
                tipo_cambio = {value.tipo_cambio}
            />
        </Grid>  
    );
}

class EmpresaList extends Component {
    componentDidMount = () => {
        const { getEmpresas } = this.props;
        getEmpresas();
    }
    render() {
        const { empresa, loader } = this.props;
        return (
            <LoadMask loading={loader} dark>
                <div className='formato'>
                    { empresa &&
                        <Grid container spacing={3}>
                            {renderEmpresas(empresa)}
                        </Grid>
                    }
                </div>
            </LoadMask>
        );
    }
}

export default EmpresaList;