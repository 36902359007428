import React, { Component } from "react";
import Formulario from "./JudicialForm";
import LoadMask from "../../Utils/LoadMask/LoadMask";
const valEmp = __CONFIG__.empresa;

class Judicial extends Component {
    state = {
        crear: true,
    };

    componentDidMount = () => {
        const { match, getModulo, listarColaborador, leer, limpiarDatos } = this.props;
        const id = match.params.id;
        if (id) {
            this.setState({ crear: false });
            leer(id);
        }
        let ruta = window.location.href;
        let modulo = ruta.split("/");
        if (valEmp === "fage") {
            getModulo(modulo[6], modulo[5]);
        } else {
            getModulo(modulo[5], modulo[4]);
        }
        listarColaborador();
        limpiarDatos();
    };

    render() {
        const {
            loader,
            modulo,
            emp,
            registroJudicial,
            tipo,
            datosColaborador,
            cargo,
        } = this.props;
        const { crear } = this.state;
        return (
            <LoadMask loading={loader} dark>
                <Formulario
                    crear={crear}
                    modulo={modulo}
                    emp={emp}
                    registroJudicial={registroJudicial}
                    cargo={cargo}
                    tipo={tipo}
                    datosColaborador={datosColaborador}
                />
            </LoadMask>
        );
    }
}

export default Judicial;
