import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/compras/facturaEspecialFEL';
import Facturas from './FacturaEspecialFormTabs';


const ms2p = (state) => {
  return {
    ...state.factura_especial_fel,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Facturas);
