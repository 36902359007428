import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/recursos_humanos/vacaciones';
import Vacaciones from './Vacaciones';


const ms2p = (state) => {
  return {
    ...state.vacaciones_rh,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Vacaciones);