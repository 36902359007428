import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/inventario/gastoProyecto';
import OrdenServicioList from './GastoProyectoList';

const ms2p = (state) => {
  return {
    ...state.inv_gasto_proyecto,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(OrdenServicioList);
