import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import Grid from '../../Utils/GridVDocumento';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';
import TablaDetalle from './AbonoDetalle';
import TablaDetalleMod from './AbonoDetalleMod';
import TablaVerDetalle from './AbonoDetalleVer';
import classNames from 'classnames';
import {
    renderDayPickerB,
    renderField,
    renderNumber,
    SelectField,
    AsyncSelectField,
    renderCurrencyFloat,
    AsyncSelectFieldIn,
    renderCurrencyFloatTC

} from "../../Utils/renderField/renderField";

function AbonoClienteForm(props) {
    const { crear, lectura, editar, nuevaLineaDocumento,
        leerDocumentos, doc, listarClientes, tipos, monedas,
        loader, bloqueo, detalleDoc, eliminarDocumento, eliminarLineaAbonoNuevo,
        fechaFinal, list_doc, periodoD, agregarLinea, detalleDocN,
        tipoCambio } = props;

    let autoFocus = true;

    const handleOnChange = value => {
        if (editar){
            leerDocumentos(value.value, fechaFinal, lectura.moneda);
        }else{
            leerDocumentos(value.value, fechaFinal, moneda);
        }
        
    };

    const estilo = {
        overflow: "auto",
        height: "200px",

    };
    
    const [tipoCambioD, setTipoCambioD] = useState(1.000000);
    const [moneda, setMoneda] = useState('');

    const handleChangeMoneda = (e) => {
        setMoneda(e.target.value);
        localStorage.setItem('monedaAbCl', e.target.value);
    };

    return (
        <div>
            <div className='row mb-2'>
                {crear === false && editar == false &&
                    <div className='col-3'>
                        {tipos &&
                            <div className="form-floating">
                                <Field
                                    autoFocus={autoFocus}
                                    name="tipo_abono"
                                    options={tipos}
                                    crear={crear}
                                    dato={lectura.tipo_abono}
                                    component={SelectField}
                                    className="form-select"
                                    disabled={bloqueo}
                                    msj="Tipo"
                                />
                            </div>
                        }
                    </div>
                }
                {(crear === true || editar == true) &&
                    <div className='col-3'>
                        {tipos &&
                            <div className="form-floating">
                                <Field
                                    name="tipo_abono"
                                    options={tipos}
                                    crear={crear}
                                    dato={crear === true ? 0 : lectura.tipo_abono}
                                    component={SelectField}
                                    className="form-select"
                                    disabled={bloqueo}
                                    msj="Tipo"
                                />
                            </div>
                        }
                    </div>
                }
                <div className='col-3'>
                    <Field
                        disabled={bloqueo}
                        name="fecha_registro"
                        component={renderDayPickerB}
                        msj="Fecha Registro"
                    />
                </div>
                {((crear === false && editar == false) || editar === true) &&
                    <div className='col-3'>
                        <Field
                            name="serie"
                            placeholder="Serie"
                            className="form-control"
                            component={renderField}
                            disabled={true}
                            msj="Serie"
                        />
                    </div>
                }
                {((crear === false && editar == false) || editar === true) &&
                    <div className='col-3'>
                        <Field
                            name="numero"
                            placeholder="Numero"
                            className="form-control"
                            component={renderNumber}
                            disabled={true}
                            msj="Número"
                        />
                    </div>
                }
                {(crear === true) &&
                    <div className='col-3'>
                        <Field
                            name="serie"
                            placeholder="Serie"
                            className="form-control"
                            component={renderField}
                            disabled={false}
                            msj="Serie"
                        />
                    </div>
                }
                {(crear === true && editar == false) &&
                    <div className='col-3'>
                        <Field
                            name="numero"
                            placeholder="Numero"
                            className="form-control"
                            component={renderNumber}
                            disabled={bloqueo}
                            msj="Número"
                        />
                    </div>
                }
            </div>
            <div className='row mb-2'>
                {(crear === false && editar === false) &&
                    <div className='col-3'>
                        <div className="form-floating">
                            <Field
                                name="moneda"
                                options={monedas}
                                crear={crear}
                                dato={crear === true ? 0 : lectura.moneda}
                                component={SelectField}
                                className="form-select"
                                disabled={bloqueo}
                                msj="Moneda"
                            />
                        </div>
                    </div>
                }
                {(crear === true) &&
                    <div className='col-3'>
                        <div className="form-floating">
                            <select className="form-select" id="tipo" name="tipo"
                                value={editar === true ? lectura.concepto : moneda} onChange={handleChangeMoneda}>
                                <option selected>Seleccionar...</option>
                                {monedas.map(
                                    tip => <option key={tip.value} value={tip.value}
                                    >{tip.label}
                                    </option>)
                                }
                            </select>
                            <label className='form-label' htmlFor="mes">Moneda</label>
                        </div>
                    </div>
                    
                }
                {(editar === true) &&
                    <div className='col-3'>
                        {monedas &&
                            <div className="form-floating">
                                <Field
                                    name="moneda"
                                    options={monedas}
                                    crear={crear}
                                    dato={crear === true ? 0 : lectura.moneda}
                                    component={SelectField}
                                    className="form-select"
                                    disabled={bloqueo}
                                    msj="Moneda"
                                />
                            </div>
                        }
                    </div>}
                {(crear === true) && (
                    <div className='col-3'>
                        <div className="form-floating">
                            <NumberFormat
                                className={classNames('form-control')}
                                decimalScale={6}
                                fixedDecimalScale
                                placeholder="Tipo de cambio"
                                value={tipoCambioD}
                                thousandSeparator
                                onValueChange={(values) => {
                                    setTipoCambioD(values.value)
                                    localStorage.setItem('TC_abCl', tipoCambioD)
                                }}
                            />
                            <label className='form-label' htmlFor="tipo">
                                Tipo de cambio
                            </label>
                        </div>
                    </div>
                )}
                {(crear === true) && (
                    <div className='col-3 mt-2'>
                        <button
                            className='btn mr-2 mb-3 btn-secondary'
                            onClick={() => {
                                let tc = tipoCambio(periodoD);
                                if (tc === undefined) {
                                    setTipoCambioD('1.000000');
                                } else {
                                    tc.then(d => {
                                        if (d === undefined) {
                                            setTipoCambioD('1.000000');
                                        } else {
                                            setTipoCambioD(d);
                                            localStorage.setItem('TC_abCl', d)

                                        }
                                    });
                                }
                            }}
                        >
                            Buscar tipo de cambio
                        </button>
                    </div>
                )}
                {(editar === true) && (

                    <div className='col-3'>
                        <div className="form-floating">
                            <Field
                                disabled={bloqueo}
                                placeholder="Tipo de cambio"
                                name="tipo_cambio"
                                component={renderCurrencyFloatTC}
                                msj="Tipo de cambio"
                                onChange={(e, value) => {
                                    localStorage.setItem('TC_abCl', value)
                                }}
                            />
                        </div>
                    </div>

                )}
                {(editar === true) && (
                    <div className='col-3 mt-2'>
                        <button
                            className='btn mr-2 mb-3 btn-secondary'
                            onClick={() => {
                                let tc = tipoCambio(periodoD, lectura.cambio);
                                if (tc === undefined) {
                                    setTipoCambioD('1.000000');
                                } else {
                                    tc.then(d => {
                                        if (d === undefined) {
                                            setTipoCambioD('1.000000');
                                        } else {
                                            setTipoCambioD(d);
                                            localStorage.setItem('TC_abCl', d)

                                        }
                                    });
                                }
                            }}
                        >
                            Buscar tipo de cambio
                        </button>
                    </div>
                )}
                {crear === false && editar == false &&
                    <div className='col-3'>
                        <Field
                            name="tipo_cambio"
                            placeholder="Tipo de cambio"
                            className="form-control"
                            component={renderCurrencyFloatTC}
                            disabled={bloqueo}
                            msj="Tipo de cambio"
                        />
                    </div>
                }
                <div className='col-3'>
                    <Field
                        name="monto"
                        placeholder="Monto"
                        className="form-control"
                        component={renderCurrencyFloat}
                        disabled={bloqueo}
                        msj="Monto"
                    />
                </div>
            </div>
            {((crear === false && editar == false) || editar === true) &&
                <div className='mb-2'>
                    <Field
                        name="clienteN"
                        placeholder="Cliente"
                        className="form-control"
                        component={renderField}
                        disabled={true}
                        msj="Cliente"
                    />
                </div>
            }
            {(crear === true) &&
                <div className='mb-2'>
                    <Field
                        name="cliente_documento"
                        placeholder="Cliente..."
                        className="form-control-find"
                        component={AsyncSelectField}
                        loadOptions={listarClientes}
                        onChange={handleOnChange}
                        msj="Cliente"
                    />
                </div>
            }
            {(editar === true) &&
                <div className='mb-2'>
                    <Field
                        name="cliente_documento"
                        placeholder="Cliente..."
                        className="form-control-find"
                        component={AsyncSelectField}
                        loadOptions={listarClientes}
                        onChange={handleOnChange}
                        msj="Clientes"
                    />
                </div>
            }
            <div className='mb-2'>
                <Field
                    name="descripcion"
                    placeholder="Descripcion"
                    component={renderField}
                    className="form-control"
                    disabled={bloqueo}
                    msj="Referencia"
                />
            </div>
            {(crear === true || editar === true) &&
                <div>
                    <h5 className="p-2 bg-secondary title mt-1">Documentos con saldo</h5>
                    <div style={estilo}>
                        <Grid
                            hover
                            striped
                            data={doc}
                            loading={loader}
                            headerStyle={{ background: 'black' }}

                        >
                            <TableHeaderColumn
                                isKey
                                dataField="fecha"
                                dataSort
                                dataFormat={(cell, row) => {
                                    return moment(cell).format("DD/MM/YYYY");
                                }}
                                thStyle={
                                    { color: 'white' }}
                                tdStyle={
                                    { color: 'white' }}
                            >
                                Fecha
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="serie"
                                dataSort
                                thStyle={
                                    { color: 'white' }}
                                tdStyle={
                                    { color: 'white' }}
                            >
                                Serie
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="numero"
                                dataSort
                                thStyle={
                                    { color: 'white' }}
                                tdStyle={
                                    { color: 'white' }}
                            >
                                Numero
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="clienteN"
                                width="350"
                                dataSort
                                thStyle={
                                    { color: 'white' }}
                                tdStyle={
                                    { color: 'white' }}
                            >
                                Cliente
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="total"
                                headerAlign="center"
                                dataAlign='right'
                                dataFormat={(cell, row) => {
                                    if (parseFloat(cell) === 0.00) {
                                        return ''
                                    } else {
                                        return new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(cell);
                                    }
                                }}
                                dataSort
                                thStyle={
                                    { color: 'white' }}
                                tdStyle={
                                    { color: 'white' }}
                            >
                                Total
                            </TableHeaderColumn>
                        </Grid>
                    </div>
                    <br />
                    <div className='row mb-2'>
                        <div className='col-8 mt-2'>
                            <Field
                                name="documento"
                                placeholder="Documento..."
                                className="form-control-find"
                                loadOptions={list_doc}
                                component={AsyncSelectFieldIn}
                                msj="Documento"
                            />
                        </div>
                        <div className='col-3 mt-2'>
                            <div className="form-floating">
                                <Field
                                    name="abono"
                                    placeholder="Abono"
                                    className="form-control"
                                    component={renderCurrencyFloat}
                                    disabled={false}
                                    msj="Abono"
                                />
                            </div>
                        </div>
                    </div>
                    {(crear === true) &&
                        <Contenido>
                            <button
                                className='btn mr-2 mb-3 btn-primary'
                                onClick={() => agregarLinea(periodoD)}
                            >
                                Agregar documento
                            </button>
                            <div className='row mb-2'>
                                <div className='col-6'>
                                    <div className="form-floating">
                                        <Field
                                            name="diferencia"
                                            placeholder="Diferencia"
                                            className="form-control"
                                            component={renderCurrencyFloat}
                                            disabled={true}
                                            msj="Diferencia"
                                        />
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className="form-floating">
                                        <Field
                                            name="totalAbono"
                                            placeholder="Total Abono"
                                            className="form-control"
                                            component={renderCurrencyFloat}
                                            disabled={true}
                                            msj="Total Abono"
                                        />
                                    </div>
                                </div>
                            </div>
                        </Contenido>
                    }
                    {(editar === true) &&
                        <Contenido>
                            <button
                                className='btn mr-2 mb-3 btn-primary'
                                onClick={() => nuevaLineaDocumento(periodoD)}
                            >
                                Agregar documento
                            </button>
                            <div className='row mb-2'>
                                <div className='col-6'>
                                    <div className="form-floating">
                                        <Field
                                            name="diferencia"
                                            placeholder="Diferencia"
                                            className="form-control"
                                            component={renderCurrencyFloat}
                                            disabled={true}
                                            msj="Diferencia"
                                        />
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className="form-floating">
                                        <Field
                                            name="totalAbono"
                                            placeholder="Total Abono"
                                            className="form-control"
                                            component={renderCurrencyFloat}
                                            disabled={true}
                                            msj="Total Abono"
                                        />
                                    </div>
                                </div>
                            </div>
                        </Contenido>
                    }
                    {(crear === true) &&
                        <div>
                            <h5 className="p-2 bg-secondary title mt-1">Documentos agregados</h5>
                            <div style={estilo}>
                                <TablaDetalle
                                    detalle={detalleDocN}
                                    eliminarDocumento={eliminarLineaAbonoNuevo}
                                />
                            </div>
                        </div>
                    }
                    {(editar === true) &&
                        <div>
                            <h5 className="p-2 bg-secondary title mt-1">Documentos agregados</h5>
                            <div style={estilo}>
                                <TablaDetalleMod
                                    detalle={detalleDoc}
                                    eliminarDocumento={eliminarDocumento}
                                />
                            </div>
                        </div>
                    }
                </div>
            }
            {crear === false && editar === false &&
                <div>
                    <h5 className="p-2 bg-secondary title mt-1">Documentos agregados</h5>
                    <div style={estilo}>
                        <TablaVerDetalle
                            detalle={detalleDoc}
                        />
                    </div>
                </div>
            }
        </div>

    );
}

AbonoClienteForm.propTypes = {
    funcionEnvio: PropTypes.func,
    crear: PropTypes.bool,
    editar: PropTypes.bool,
    lectura: PropTypes.object,
    bancos: PropTypes.object,
    listaM: PropTypes.object,
    listarClientes: PropTypes.object,
    leerDocumentos: PropTypes.func,
    doc: PropTypes.object,
    loader: PropTypes.bool,
    bloqueo: PropTypes.bool,
    detalleDoc: PropTypes.object,
    eliminarDocumento: PropTypes.func,
    cuentasBan: PropTypes.object,
    ck: PropTypes.int,
    tipos: PropTypes.array,
    monedas: PropTypes.array,
    modificarAbono: PropTypes.func,
    list_doc: PropTypes.object,
    periodoD: PropTypes.object,
    agregarLinea: PropTypes.func,
    eliminarLineaAbonoNuevo: PropTypes.func,
    nuevaLineaDocumento: PropTypes.func,
    detalleDocN: PropTypes.object
};

export default reduxForm({
    form: 'FormAbonoCliente',
})(AbonoClienteForm);

const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;