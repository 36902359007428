import React from 'react';
import * as FaIcons from "react-icons/fa";
import * as BsIcons from "react-icons/bs";
import * as BiIcons from "react-icons/bi";

export const SidebarDataAjustesTest = [
    {
        title: 'Inicio',
        path: '/ajustes',
        icon: <BsIcons.BsGrid1X2Fill />,
    },
    {
        title: 'Empresas',
        path: '/ajustes/empresas',
        icon: <FaIcons.FaBuilding />,
    },
    {
        title: 'Establecimientos',
        path: '/ajustes/establecimientos',
        icon: <FaIcons.FaBuilding />,
    },
    {
        title: 'Periodos',
        path: '/ajustes/periodos',
        icon: <BsIcons.BsFillCalendarDateFill />,
    },
    {
        title: 'Monedas',
        path: '/ajustes/monedas',
        icon: <FaIcons.FaCoins />,
    },
    {
        title: 'Impuestos',
        path: '/ajustes/impuestos',
        icon: <BiIcons.BiCoinStack />,
    },
    {
        title: 'Parametros',
        path: '/ajustes/parametros',
        icon: <BsIcons.BsTools />,
    },
    {
        title: 'Modulos',
        path: '/ajustes/ajuste_modulo',
        icon: <FaIcons.FaBox />,
    },
    {
        title: 'Usuarios',
        path: '/ajustes/usuarios',
        icon: <FaIcons.FaUserAlt />,
    }
]

