import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';

import {
    renderFilePickerRH,
    
} from "../../Utils/renderField/renderField";
import _ from 'lodash';

function ColaboradorDocumentosForm(props) {
    const { crear, editar, setCV, lectura, setContrato,
        setDPI, setAntPen, setAntPol, setConstLab,
        setConstEst, setTarjAli, setTarjSalud,
        setCarnetVac, setPruebaP, setPruebaC,
        setPoligrafo, setEstudioS } = props;
        
    let verCV = lectura.cv;
    let verDPI = lectura.d_dpi;
    let verContrato = lectura.contrato_laboral;
    let verAntPen = lectura.antecedentes_penales;
    let verAntPol = lectura.antecedentes_policiacos;
    let verConstLab = lectura.constancia_laboral;
    let verConstEst = lectura.constancia_estudio;
    let verTarjAli = lectura.tarjeta_manipulacion_alimentos;
    let verTarjSalud = lectura.tarjeta_salud;
    let verCarnetVac = lectura.carnet_vacunacion;
    let verPruebaP = lectura.prueba_psicometrica;
    let verPruebaC = lectura.prueba_confiabilidad;
    let verPoligrafo = lectura.poligrafo;
    let verEstS = lectura.estudio_socioeconomico;
     return (
        <div>
            {crear === true &&
                <div>
                    <div className='row mb-2'>
                        <div className="col-2">
                            <div className="text-center">
                                <label htmlFor="logo">CV</label>
                                <Field
                                    photo={''}
                                    setFile={setCV}
                                    name="cv"
                                    component={renderFilePickerRH}
                                />
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="text-center">
                                <label htmlFor="logo">DPI</label>
                                <Field
                                    photo={''}
                                    setFile={setDPI}
                                    name="dpi"
                                    component={renderFilePickerRH}
                                />
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="text-center">
                                <label htmlFor="logo">Contrato laboral</label>
                                <Field
                                    photo={''}
                                    setFile={setContrato}
                                    name="contrato"
                                    component={renderFilePickerRH}
                                />
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="text-center">
                                <label htmlFor="logo">Antecedentes penales</label>
                                <Field
                                    photo={''}
                                    setFile={setAntPen}
                                    name="antecedentes_penales"
                                    component={renderFilePickerRH}
                                />
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="text-center">
                                <label htmlFor="logo">Antecedentes policiacos</label>
                                <Field
                                    photo={''}
                                    setFile={setAntPol}
                                    name="antecedentes_policiacos"
                                    component={renderFilePickerRH}
                                />
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="text-center">
                                <label htmlFor="logo">Constancia laboral</label>
                                <Field
                                    photo={''}
                                    setFile={setConstLab}
                                    name="constancia_laboral"
                                    component={renderFilePickerRH}
                                />
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div className='row mb-2'>
                        <div className="col-2">
                            <div className="text-center">
                                <label htmlFor="logo">Constancia de estudio</label>
                                <Field
                                    photo={''}
                                    setFile={setConstEst}
                                    name="constancia_estudio"
                                    component={renderFilePickerRH}
                                />
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="text-center">
                                <label htmlFor="logo">Tarjeta Manipulación alimentos</label>
                                <Field
                                    photo={''}
                                    setFile={setTarjAli}
                                    name="tarjeta_alimentos"
                                    component={renderFilePickerRH}
                                />
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="text-center">
                                <label htmlFor="logo">Tarjeta de salud</label>
                                <Field
                                    photo={''}
                                    setFile={setTarjSalud}
                                    name="tarjeta_salud"
                                    component={renderFilePickerRH}
                                />
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="text-center">
                                <label htmlFor="logo">Carnet de vacunación</label>
                                <Field
                                    photo={''}
                                    setFile={setCarnetVac}
                                    name="carnet_vacunacion"
                                    component={renderFilePickerRH}
                                />
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="text-center">
                                <label htmlFor="logo">Prueba psicométrica</label>
                                <Field
                                    photo={''}
                                    setFile={setPruebaP}
                                    name="prueba_psico"
                                    component={renderFilePickerRH}
                                />
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="text-center">
                                <label htmlFor="logo">Prueba de confiabilidad</label>
                                <Field
                                    photo={''}
                                    setFile={setPruebaC}
                                    name="prueba_confiabilidad"
                                    component={renderFilePickerRH}
                                />
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div className='row mb-2'>
                        <div className="col-2">
                            <div className="text-center">
                                <label htmlFor="logo">Polígrafo</label>
                                <Field
                                    photo={''}
                                    setFile={setPoligrafo}
                                    name="poligrafo"
                                    component={renderFilePickerRH}
                                />
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="text-center">
                                <label htmlFor="logo">Estudio socioeconómico</label>
                                <Field
                                    photo={''}
                                    setFile={setEstudioS}
                                    name="estudio_socioeconomico"
                                    component={renderFilePickerRH}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }
            {editar === true &&
                <div>
                    <div className='row mb-2'>
                        <div className="col-2 mt-2">
                            <div className="text-center">
                                <label>CV: </label>
                                {verCV ?
                                    <div>
                                        <a className='btn btn-secondary sm' href={verCV} target="_blank">Ver archivo</a>
                                    </div>
                                : (
                                    <div>
                                        Sin archivo
                                    </div>
                                )}
                                
                            </div>
                                <Field
                                    photo={''}
                                    setFile={setCV}
                                    name="cv"
                                    component={renderFilePickerRH}
                                />
                        </div>
                        <div className="col-2 mt-2">
                            <div className="text-center">
                                <label>DPI: </label>
                                {verDPI ?
                                    <div>
                                        <a className='btn btn-secondary sm' href={verDPI} target="_blank">Ver archivo</a>
                                    </div>
                                : (
                                    <div>
                                        Sin archivo
                                    </div>
                                )}
                            </div>
                                <Field
                                    photo={''}
                                    setFile={setDPI}
                                    name="dpi"
                                    component={renderFilePickerRH}
                                />
                            
                        </div>
                        <div className="col-2 mt-2">
                            <div className="text-center">
                                <label>Contrato laboral: </label>
                                {verContrato ?
                                    <div>
                                        <a className='btn btn-secondary sm' href={verContrato} target="_blank">Ver archivo</a>
                                    </div>
                                : (
                                    <div>
                                        Sin archivo
                                    </div>
                                )}
                            </div>
                                <Field
                                    photo={''}
                                    setFile={setContrato}
                                    name="contrato"
                                    component={renderFilePickerRH}
                                />
                            
                        </div>
                        <div className="col-2 mt-2">
                            <div className="text-center">
                                <label>Antecedentes penales: </label>
                                {verAntPen ?
                                    <div>
                                        <a className='btn btn-secondary sm' href={verAntPen} target="_blank">Ver archivo</a>
                                    </div>
                                : (
                                    <div>
                                        Sin archivo
                                    </div>
                                )}
                            </div>
                                <Field
                                    photo={''}
                                    setFile={setAntPen}
                                    name="antecendentes_penales"
                                    component={renderFilePickerRH}
                                />
                            
                        </div>
                        <div className="col-2 mt-2">
                            <div className="text-center">
                                <label>Antecedentes policiacos: </label>
                                {verAntPol ?
                                    <div>
                                        <a className='btn btn-secondary sm' href={verAntPol} target="_blank">Ver archivo</a>
                                    </div>
                                : (
                                    <div>
                                        Sin archivo
                                    </div>
                                )}
                            </div>
                                <Field
                                    photo={''}
                                    setFile={setAntPol}
                                    name="antecedentes_policiacos"
                                    component={renderFilePickerRH}
                                />
                            
                        </div>
                        <div className="col-2 mt-2">
                            <div className="text-center">
                                <label>Constancia laboral: </label>
                                {verConstLab ?
                                    <div>
                                        <a className='btn btn-secondary sm' href={verConstLab} target="_blank">Ver archivo</a>
                                    </div>
                                : (
                                    <div>
                                        Sin archivo
                                    </div>
                                )}
                            </div>
                                <Field
                                    photo={''}
                                    setFile={setConstLab}
                                    name="constancia_laboral"
                                    component={renderFilePickerRH}
                                />
                            
                        </div>
                    </div>
                    <hr/>
                    <div className='row mb-2'>
                        <div className="col-2 mt-2">
                            <div className="text-center">
                                <label>Constancia de estudio: </label>
                                {verConstEst ?
                                    <div>
                                        <a className='btn btn-secondary sm' href={verConstEst} target="_blank">Ver archivo</a>
                                    </div>
                                : (
                                    <div>
                                        Sin archivo
                                    </div>
                                )}
                            </div>
                                <Field
                                    photo={''}
                                    setFile={setConstEst}
                                    name="constancia_estudio"
                                    component={renderFilePickerRH}
                                />
                        </div>
                        <div className="col-2 mt-2">
                            <div className="text-center">
                                <label>Tarjeta Manipulación alimentos: </label>
                                {verTarjAli ?
                                    <div>
                                        <a className='btn btn-secondary sm' href={verTarjAli} target="_blank">Ver archivo</a>
                                    </div>
                                : (
                                    <div>
                                        Sin archivo
                                    </div>
                                )}
                                
                            </div>
                            <Field
                                photo={''}
                                setFile={setTarjAli}
                                name="cv"
                                component={renderFilePickerRH}
                            />
                        </div>
                        <div className="col-2 mt-2">
                            <div className="text-center">
                                <label>Tarjeta de salud: </label>
                                {verTarjSalud ?
                                    <div>
                                        <a className='btn btn-secondary sm' href={verTarjSalud} target="_blank">Ver archivo</a>
                                    </div>
                                : (
                                    <div>
                                        Sin archivo
                                    </div>
                                )}
                                
                            </div>
                            <Field
                                photo={''}
                                setFile={setTarjSalud}
                                name="cv"
                                component={renderFilePickerRH}
                            />
                        </div>
                        <div className="col-2 mt-2">
                            <div className="text-center">
                                <label>Carnet de vacunación: </label>
                                {verCarnetVac ?
                                    <div>
                                        <a className='btn btn-secondary sm' href={verCarnetVac} target="_blank">Ver archivo</a>
                                    </div>
                                : (
                                    <div>
                                        Sin archivo
                                    </div>
                                )}
                                
                            </div>
                            <Field
                                photo={''}
                                setFile={setCarnetVac}
                                name="cv"
                                component={renderFilePickerRH}
                            />
                        </div>
                        <div className="col-2 mt-2">
                            <div className="text-center">
                                <label>Prueba psicométrica: </label>
                                {verPruebaP ?
                                    <div>
                                        <a className='btn btn-secondary sm' href={verCarnetVac} target="_blank">Ver archivo</a>
                                    </div>
                                : (
                                    <div>
                                        Sin archivo
                                    </div>
                                )}
                                
                            </div>
                            <Field
                                photo={''}
                                setFile={setPruebaP}
                                name="cv"
                                component={renderFilePickerRH}
                            />
                        </div>
                        <div className="col-2 mt-2">
                            <div className="text-center">
                                <label>Prueba de confiabilidad: </label>
                                {verPruebaC ?
                                    <div>
                                        <a className='btn btn-secondary sm' href={verCarnetVac} target="_blank">Ver archivo</a>
                                    </div>
                                : (
                                    <div>
                                        Sin archivo
                                    </div>
                                )}
                                
                            </div>
                            <Field
                                photo={''}
                                setFile={setPruebaC}
                                name="cv"
                                component={renderFilePickerRH}
                            />
                        </div>
                    </div>
                    <hr/>
                    <div className='row mb-2'>
                        <div className="col-2 mt-2">
                            <div className="text-center">
                                <label>Polígrafo: </label>
                                {verPoligrafo ?
                                    <div>
                                        <a className='btn btn-secondary sm' href={verConstEst} target="_blank">Ver archivo</a>
                                    </div>
                                : (
                                    <div>
                                        Sin archivo
                                    </div>
                                )}
                            </div>
                                <Field
                                    photo={''}
                                    setFile={setPoligrafo}
                                    name="constancia_estudio"
                                    component={renderFilePickerRH}
                                />
                        </div>
                        <div className="col-2 mt-2">
                            <div className="text-center">
                                <label>Estudio socioeconómico: </label>
                                {verEstS ?
                                    <div>
                                        <a className='btn btn-secondary sm' href={verConstEst} target="_blank">Ver archivo</a>
                                    </div>
                                : (
                                    <div>
                                        Sin archivo
                                    </div>
                                )}
                            </div>
                                <Field
                                    photo={''}
                                    setFile={setEstudioS}
                                    name="constancia_estudio"
                                    component={renderFilePickerRH}
                                />
                        </div>
                    </div>
                </div>
            }
            {crear === false && editar === false &&
                <div>
                    <div className='row mb-2'>
                        <div className="col-2">
                            <div className="text-center">
                                <label>CV: </label>
                                {verCV ?
                                    <div>
                                        <a className='btn btn-secondary sm' href={verCV} target="_blank">Ver archivo</a>
                                    </div>
                                : (
                                    <div>
                                        Sin archivo
                                    </div>
                                )}
                                
                            </div>            
                        </div>
                        <div className="col-2">
                            <div className="text-center">
                                <label>DPI: </label>
                                {verDPI ?
                                    <div>
                                        <a className='btn btn-secondary sm' href={verDPI} target="_blank">Ver archivo</a>
                                    </div>
                                : (
                                    <div>
                                        Sin archivo
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="text-center">
                                <label>Contrato laboral: </label>
                                {verContrato ?
                                    <div>
                                        <a className='btn btn-secondary sm' href={verContrato} target="_blank">Ver archivo</a>
                                    </div>
                                : (
                                    <div>
                                        Sin archivo
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="text-center">
                                <label>Antecedentes penales: </label>
                                {verAntPen ?
                                    <div>
                                        <a className='btn btn-secondary sm' href={verAntPen} target="_blank">Ver archivo</a>
                                    </div>
                                : (
                                    <div>
                                        Sin archivo
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="text-center">
                                <label>Antecedentes policiacos: </label>
                                {verAntPol ?
                                    <div>
                                        <a className='btn btn-secondary sm' href={verAntPol} target="_blank">Ver archivo</a>
                                    </div>
                                : (
                                    <div>
                                        Sin archivo
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="text-center">
                                <label>Constancia laboral: </label>
                                {verConstLab ?
                                    <div>
                                        <a className='btn btn-secondary sm' href={verConstLab} target="_blank">Ver archivo</a>
                                    </div>
                                : (
                                    <div>
                                        Sin archivo
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='row mt-5'>
                        <div className="col-2">
                            <div className="text-center">
                                <label>Constancia de estudio: </label>
                                {verConstEst ?
                                    <div>
                                        <a className='btn btn-secondary sm' href={verConstEst} target="_blank">Ver archivo</a>
                                    </div>
                                : (
                                    <div>
                                        Sin archivo
                                    </div>
                                )}
                                
                            </div>            
                        </div>
                        <div className="col-2">
                            <div className="text-center">
                                <label>Tarjeta Manipulación alimentos: </label>
                                {verTarjAli ?
                                    <div>
                                        <a className='btn btn-secondary sm' href={verTarjAli} target="_blank">Ver archivo</a>
                                    </div>
                                : (
                                    <div>
                                        Sin archivo
                                    </div>
                                )}
                                
                            </div>            
                        </div>
                        <div className="col-2">
                            <div className="text-center">
                                <label>Tarjeta de salud: </label>
                                {verTarjSalud ?
                                    <div>
                                        <a className='btn btn-secondary sm' href={verTarjSalud} target="_blank">Ver archivo</a>
                                    </div>
                                : (
                                    <div>
                                        Sin archivo
                                    </div>
                                )}
                                
                            </div>            
                        </div>
                        <div className="col-2">
                            <div className="text-center">
                                <label>Carnet de vacunación: </label>
                                {verCarnetVac ?
                                    <div>
                                        <a className='btn btn-secondary sm' href={verCarnetVac} target="_blank">Ver archivo</a>
                                    </div>
                                : (
                                    <div>
                                        Sin archivo
                                    </div>
                                )}
                                
                            </div>            
                        </div>
                        <div className="col-2">
                            <div className="text-center">
                                <label>Prueba psicométrica: </label>
                                {verPruebaP ?
                                    <div>
                                        <a className='btn btn-secondary sm' href={verPruebaP} target="_blank">Ver archivo</a>
                                    </div>
                                : (
                                    <div>
                                        Sin archivo
                                    </div>
                                )}
                                
                            </div>            
                        </div>
                        <div className="col-2">
                            <div className="text-center">
                                <label>Prueba de confiabilidad: </label>
                                {verPruebaC ?
                                    <div>
                                        <a className='btn btn-secondary sm' href={verPruebaC} target="_blank">Ver archivo</a>
                                    </div>
                                : (
                                    <div>
                                        Sin archivo
                                    </div>
                                )}
                                
                            </div>            
                        </div>
                    </div>
                    <div className='row mt-5'>
                        <div className="col-2">
                            <div className="text-center">
                                <label>Polígrafo: </label>
                                {verPoligrafo ?
                                    <div>
                                        <a className='btn btn-secondary sm' href={verPoligrafo} target="_blank">Ver archivo</a>
                                    </div>
                                : (
                                    <div>
                                        Sin archivo
                                    </div>
                                )}
                                
                            </div>            
                        </div>
                        <div className="col-2">
                            <div className="text-center">
                                <label>Estudio socioeconómico: </label>
                                {verEstS ?
                                    <div>
                                        <a className='btn btn-secondary sm' href={verEstS} target="_blank">Ver archivo</a>
                                    </div>
                                : (
                                    <div>
                                        Sin archivo
                                    </div>
                                )}
                                
                            </div>            
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

ColaboradorDocumentosForm.propTypes = {
    crear: PropTypes.bool,
    editar: PropTypes.bool,
};

export default reduxForm({
    form: 'FormColaborador',
})(ColaboradorDocumentosForm);
